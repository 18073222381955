import * as React from "react";
import * as MobX from "mobx";
import * as MobXReact from "mobx-react-lite";
import { ProjectDetailsDetailsContainer } from "./ProjectDetailsDetails.styles";
import { ProjectStatusSelect } from "Custom/Components/Status/ProjectStatusSelect";
import { ITab, Tabs } from "Custom/Components/Tabs/Tabs";
import { PropertyList } from "./Properties/PropertyList";
import { useHasRoutedTabs } from "Custom/Utils/useHasRoutedTabs";
import { ProjectDetailsViewModel } from "./ProjectDetailsViewModel";
import { Stores, StoresContext } from "Custom/Stores";
import * as RouterUtil from "Custom/Utils/routing";
import { Property } from "Custom/Models/Domain";
import { Typography } from "@material-ui/core";
import { Loader } from "Core/Components";
import { ProjectCommunicationTab } from "./Communication/ProjectCommunication";
import { ProjectDocumentsTab } from "./Documents/ProjectDocuments";
import { ProjectPaymentStatusSelect } from "Custom/Components/Status/ProjectPaymentStatusSelect";

const TAB_PROPERTY_LIST = "property_list";
const TAB_COMMUNICATION = "communication";
const TAB_DOCUMENTS = "documents";
const TAB_ACTIVITY = "activity";

interface IProps {
    viewModel: ProjectDetailsViewModel;
    onPropertyLoad: (properties: Property[]) => void;
}

export const ProjectDetailsDetails: React.FC<IProps> = ({ viewModel, onPropertyLoad }) => {
    const store = React.useContext<Stores>(StoresContext);

    const getTabOptions: () => ITab[] = () => [
        { key: TAB_PROPERTY_LIST, title: "Property list", hash: "properties" },
        { key: TAB_COMMUNICATION, title: "Project communication", hash: "communication" },
        { key: TAB_DOCUMENTS, title: "Project documents", hash: "documents" },
        /* { key: TAB_DOCUMENTS, title:  "Project documents", hash: "documents" }, */
        /* { key: TAB_ACTIVITY, title: "Activity", hash: "activity" }, */
    ];

    const routedTab = useHasRoutedTabs(getTabOptions());
    const [selectedTab, setSelectedTab] = React.useState(routedTab?.key || TAB_PROPERTY_LIST);
    const [tabKey, setTabKey] = React.useState(0);
    /*     const [documentCount, setDocumentCount] = React.useState(0); */
    //const [activityCount, setActivityCount] = React.useState(0);

    /*     React.useEffect(() => {
        if (viewModel !== undefined && viewModel.getDocumentCount !== undefined) {
            setTimeout(() => {
                setDocumentCount(viewModel.getDocumentCount);
            }, 800);
        } else {
            setDocumentCount(0);
        }
    }, [viewModel.getDocuments]); */

    const handleTabClick = (tab: ITab) => {
        setSelectedTab(tab.key);
        setTabKey(tabKey + 1);
    };

    const getTabKey = () => {
        return `projectdetailsdetails-tab-${selectedTab}-${tabKey}`;
    };

    const renderContent = () => {
        switch (selectedTab) {
            case TAB_PROPERTY_LIST:
                return <PropertyList key={getTabKey()} viewModel={viewModel} />;
            case TAB_COMMUNICATION:
                return <ProjectCommunicationTab key={getTabKey()} viewModel={viewModel} />;
            case TAB_DOCUMENTS:
                return <ProjectDocumentsTab key={getTabKey()} externalViewModel={viewModel} />;
            case TAB_ACTIVITY:
                return <div key={getTabKey()} />;
        }
    };

    const handleStatusChange = (status: number) => {
        let ret = viewModel.upsertProjectStatus(status);
    };

    const handlePaymentStatusChange = (paymentStatus: number) => {
        let ret = viewModel.upsertProjectPaymentStatus(paymentStatus);
    };

    const renderPage = () => {
        if (viewModel.IsLoading) return <Loader />;

        const allowEdit: boolean = RouterUtil.requireAdminLogin(store) === true;
        return (
            <ProjectDetailsDetailsContainer>
                <div className="info-status-address">
                    <div className="info-status">
                        <Typography variant="h1" style={{ marginBottom: "5px", fontSize: "24px" }}>
                            {viewModel.pageTitle}
                        </Typography>
                        <div className="status">
                            <span>Payment status:</span>
                            <ProjectPaymentStatusSelect
                                value={viewModel.getValue("paymentStatus")}
                                options={viewModel.projectPaymentStatuses}
                                onChange={handlePaymentStatusChange}
                                disabled={!allowEdit}
                            />
                        </div>
                        <div className="status">
                            <span>Project status:</span>
                            <ProjectStatusSelect value={viewModel.getValue("status")} options={viewModel.projectStatuses} onChange={handleStatusChange} disabled={!allowEdit} />
                        </div>
                    </div>

                    <h2 style={{ color: "#fff" }}>{viewModel.formattedClientAddress}</h2>
                </div>

                <Tabs tabs={getTabOptions()} onTabClick={handleTabClick} selected={selectedTab} minTabWidth={210} tabPadding="20px 0 15px" />

                <div className="content">{renderContent()}</div>
            </ProjectDetailsDetailsContainer>
        );
    };

    return MobXReact.useObserver(() => <>{renderPage()}</>);
};
