import { Box, Fade } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useObserver } from "mobx-react-lite";
import { Stores, StoresContext } from "Custom/Stores";
import { UnitsViewModel } from "./UnitsViewModel";
import { Loader } from "Core/Components";
import { ToolbarSearchSwitchWrapper, ToolbarUnitOptions } from "Custom/StylesAppSpecific/Controls/BoxAddWrapper";
import { Container } from "Custom/StylesAppSpecific/AppStyling";
import { ProjectListSearch } from "Custom/Views/Projects/ProjectListSearch";
import { useRouter } from "Core/Base";
import { AppUrls } from "Custom/Globals";
import { UnitListTabView } from "./UnitListTab";
import { UnitEditDataModel } from "./UnitEditDataModel";
import { ClickableImgCell } from "Custom/Components/Table/ClickableImgCell";
import EditLogo from "Custom/Content/editIcon.svg";
export interface UnitModalData {
    open: boolean;
    id: string;
    simId: string;
}

export const UnitsView: React.FunctionComponent = () => {
    const store = React.useContext<Stores>(StoresContext);
    const router = useRouter();
    const [viewModel] = useState(() => UnitsViewModel.Instance);
    const [editUnitOpen, setEditUnitOpen] = React.useState<UnitModalData>({ open: false, id: "0", simId: "0" });

    const reload = (): void => {
        const { path } = router.match;

        switch (path) {
            case AppUrls.Client.Main.Unit.Root:
            default: {
                const apiResult = viewModel.loadForMainViewUnitViewAsync();
                apiResult.then((result) => {});
                break;
            }
        }
    };

    //useEffect below only gets run on initial render
    useEffect(() => {
        reload();

        return () => {
            viewModel.cleanUp();
        };
    }, []);

    const setEditUnit = (id: string) => {
        const simId: string = viewModel.getSimIdFromId(id);
        setEditUnitOpen({ open: true, id: id, simId: simId });
    };

    const renderContent = () => {
        return <UnitListTabView setEditUnit={setEditUnit} />;
    };

    const setSearchString = (value: string) => {
        viewModel.setSearchString(value);
        viewModel.setPage(0);
    };

    const handleModalCloseClose = (saved: boolean) => {
        setEditUnitOpen({ open: false, id: "0", simId: "0" });

        if (saved === true) {
            reload();
        }
    };

    const handleCSVClick = (id: string) => {
        let ret = viewModel.downloadUnitsAsCsv();

        ret.then((ret) => {
            //alert("Back");
        });
    };

    const renderPage = () => {
        if (viewModel.IsLoading) return <Loader />;
        return (
            <Fade in timeout={1000}>
                <Container>
                    <UnitEditDataModel open={editUnitOpen.open} onClose={handleModalCloseClose} deviceId={editUnitOpen.id} simId={editUnitOpen.simId} />

                    <Box width="100%">
                        <ToolbarSearchSwitchWrapper>
                            <div className="search-field" style={{ width: "580px" }}>
                                <ProjectListSearch searchString={viewModel.getSearchString()} setSearchString={setSearchString} placeholder="Start typing to search units" />
                            </div>
                            <div className="download-csv">
                                <ClickableImgCell className="projectnote-addedit" logo={EditLogo} text={"Download units CSV"} onClick={handleCSVClick} id={"0"} />
                            </div>
                        </ToolbarSearchSwitchWrapper>
                    </Box>
                    <div className="content">{renderContent()}</div>
                </Container>
            </Fade>
        );
    };

    return useObserver(() => renderPage());
};
