import { ViewModelBase } from "Core/ViewModels/ViewModelBase";
import { FieldType } from "Core/Utils/Utils";
import { action, computed, observable } from "mobx";
import { ContactModel, ContactModelDTO } from "./Contact.Model";

import { ContractorContactModelDTO } from "Custom/Models/Domain/Contacts/ContractorContactModel";
import { ContactModelDTO as ClientContactModelDTO } from "Custom/Models/Domain/Contacts/ContactModel";
import { NewContactModel, NewContactModelDTO } from "Custom/Views/Projects/Contact";

import validator from "validator";

//extend viewmodel base and passing your model as the generic type
export class ContactViewModel extends ViewModelBase<ContactModel> {
    //Singleton instance of class
    private static _instance: ContactViewModel;
    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    @observable public errorMessage: string = "";

    constructor() {
        super(new ContactModel(), false);
        this.setDecorators(ContactModel);
    }

    @action
    setContact = (contact: ContactModel) => {
        this.model.copy(contact);
    };

    @action
    public toContractorContactModelDTO = (sourceId: string) => {
        return this.model.toContractorContactModelDTO(sourceId);
        /* const retVal: ContractorContactModelDTO = {
            firstName: this.model.firstName,
            lastName: this.model.lastName,
            position: this.model.position,
            email: this.model.email,
            phone: this.model.phone,
            phone2: this.model.phone2,
            rowVersion: this.model.rowVersion,
            isDeleted: this.model.isDeleted,
            createdDate: this.model.createdDate,
            createdBy: this.model.createdBy,
            id: this.model.id,
            contractorId: sourceId,
        };

        return retVal; */
    };

    @action
    public fromContractorContactModelDTO = (contact: ContractorContactModelDTO) => {
        const retVal = new ContactModel();
        retVal.firstName = contact.firstName;
        retVal.lastName = contact.lastName;
        retVal.position = contact.position;
        retVal.email = contact.email;
        retVal.phone = contact.phone;
        retVal.phone2 = contact.phone2;
        retVal.rowVersion = contact.rowVersion;
        retVal.isDeleted = contact.isDeleted;
        retVal.createdDate = contact.createdDate;
        retVal.createdBy = contact.createdBy;
        retVal.id = contact.id;
        retVal.sourceId = contact.contractorId;

        return retVal;
    };

    @action
    public toClientContactModelDTO = (sourceId: string): ClientContactModelDTO => {
        const retVal: ClientContactModelDTO = {
            firstName: this.model.firstName,
            lastName: this.model.lastName,
            position: this.model.position,
            email: this.model.email,
            phone: this.model.phone,
            phone2: this.model.phone2,
            rowVersion: this.model.rowVersion,
            isDeleted: this.model.isDeleted,
            createdDate: this.model.createdDate,
            createdBy: this.model.createdBy,
            id: this.model.id,
            clientId: sourceId,
        };

        return retVal;
    };

    @action
    public fromClientContactModelDTO = (contact: ClientContactModelDTO) => {
        const retVal = new ContactModel();
        retVal.firstName = contact.firstName;
        retVal.lastName = contact.lastName;
        retVal.position = contact.position;
        retVal.email = contact.email;
        retVal.phone = contact.phone;
        retVal.phone2 = contact.phone2;
        retVal.rowVersion = contact.rowVersion;
        retVal.isDeleted = contact.isDeleted;
        retVal.createdDate = contact.createdDate;
        retVal.createdBy = contact.createdBy;
        retVal.id = contact.id;
        retVal.sourceId = contact.clientId;
        return retVal;
    };

    @action
    public toNewContactModelDTO = (sourceId: string) => {
        const retVal: NewContactModelDTO = {
            firstName: this.model.firstName,
            lastName: this.model.lastName,
            position: this.model.position,
            email: this.model.email,
            phone: this.model.phone,
            phone2: this.model.phone2,
            rowVersion: this.model.rowVersion,
            isDeleted: this.model.isDeleted,
            createdDate: this.model.createdDate,
            createdBy: this.model.createdBy,
            id: this.model.id,
            clientId: sourceId,
        };

        return retVal;
    };

    @action
    public fromNewContactModelDTO = (contact: NewContactModelDTO) => {
        const retVal = new ContactModel();
        retVal.firstName = contact.firstName;
        retVal.lastName = contact.lastName;
        retVal.position = contact.position;
        retVal.email = contact.email;
        retVal.phone = contact.phone;
        retVal.phone2 = contact.phone2;
        retVal.rowVersion = contact.rowVersion;
        retVal.isDeleted = contact.isDeleted;
        retVal.createdDate = contact.createdDate;
        retVal.createdBy = contact.createdBy;
        retVal.id = contact.id;
        retVal.sourceId = contact.clientId;

        return retVal;
    };

    //isValid will check all fields to make sure they are in a valid state.
    public doSubmit = async (e: any) => {
        e.preventDefault();

        if (this.isModelValid()) {
            //Do stuff here
            this.errorMessage = "Form is valid";
        } else {
            this.errorMessage = "Form is not valid";
        }
    };

    public isFieldValid(fieldName: keyof FieldType<ContactModel>, value: any): boolean {
        let { isValid, errorMessage } = this.validateDecorators(fieldName);

        if (fieldName === "phone") {
            const temp: string = this.model.phone == null ? "" : this.model.phone;
            const temp2: string = this.model.phone2 == null ? "" : this.model.phone2;
            const tempEmail: string = this.model.email == null ? "" : this.model.email;
            if (validator.isEmpty(value) && temp.length === 0 && tempEmail.length === 0 && temp2.length === 0) {
                errorMessage = "Phone or Email required";
                isValid = false;
            } else if (value.length > 0 && value.length < 6) {
                errorMessage = "Phone number must be at least 6 characters long";
                isValid = false;
            }
        }

        if (fieldName === "phone2") {
            const temp: string = this.model.phone == null ? "" : this.model.phone;
            const temp2: string = this.model.phone2 == null ? "" : this.model.phone2;
            const tempEmail: string = this.model.email == null ? "" : this.model.email;
            if (validator.isEmpty(value) && temp.length === 0 && tempEmail.length === 0 && temp2.length === 0) {
                errorMessage = "Phone or Email required";
                isValid = false;
            } else if (value.length > 0 && value.length < 6) {
                errorMessage = "Phone number must be at least 6 characters long";
                isValid = false;
            }
        }

        if (fieldName === "email") {
            const temp = this.model.email == null ? "" : this.model.email;
            const tempPhone = this.model.phone == null ? "" : this.model.phone;
            const tempPhone2 = this.model.phone2 == null ? "" : this.model.phone2;
            if (validator.isEmpty(value) && temp.length === 0 && tempPhone.length === 0 && tempPhone2.length === 0) {
                errorMessage = "Email or Phone required";
                isValid = false;
            } else if (value.length > 0 && validator.isEmail(value) === false) {
                errorMessage = "Email address is not in the correct format";
                isValid = false;
            }
        }

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
