import { AddressModelDTO } from "../Domain";

export interface AddressResult {
    status: string;
    statusDetails: string;
    results: AddressResults;
}

export interface AddressResults {
    numItems: number;
    items: AddressItem[];
}

export interface ExtraRecord {
    extra_LATITUDE: number;
    extra_LONGITUDE: number;
}
export interface Extra {
    records: ExtraRecord[];
}
export interface AddressItem {
    sid: string;

    itemText: string;

    isExpandable: boolean;

    isComplete: boolean;

    selected: boolean;

    houseName1: string;

    houseName2: string;

    premise: string;

    flat: string;

    floor: string;

    streetName1: string;

    streetName2: string;

    districtName1: string;

    districtName2: string;

    town: string;

    county: string;

    postcode: string;

    //Currently not asking for these
    //label1: string;
    //label2: string;
    //label3: string;
    //label4: string;
    //label5: string;
    //label6: string;
    //label7: string;
    //label8: string;
    //label9: string;
    //department: string;
    //organisation: string;
    //pOBox: string;
    //dPID: string;
    country: string;
    //name: string;
    //uDPRN: string;
    //uAID: string;
    //dedupeKey: string;
    //extendedDedupeKey: string;
    extra: Extra;
}

export interface AddressRequest {
    Sid: string;
}

export const ConvertAddressItemToAddressModelDTO = (item: AddressItem): AddressModelDTO => {
    let buildingName: string = item.houseName1 == null ? "" : item.houseName1;
    let addressLine1: string = item.streetName1 == null ? "" : item.streetName1;
    let addressLine2: string = item.streetName2 == null ? "" : item.streetName2;

    if (item.premise != null && item.premise.length > 0) {
        addressLine1 = item.premise + " " + addressLine1;
    }

    if (item.houseName1 != null && item.houseName1.length > 0) {
        addressLine1 = item.houseName1 + ", " + addressLine1;
    }

    if (item.flat != null && item.flat.length > 0) {
        addressLine1 = item.flat + ", " + addressLine1;
    }

    if (item.streetName2 == null || item.streetName2.length == 0) {
        if ((item.houseName1 != null && item.houseName1.length > 0) || (item.flat != null && item.flat.length > 0)) {
            addressLine2 = item.streetName1;

            if (item.premise != null && item.premise.length > 0) {
                addressLine2 = item.premise + " " + addressLine2;
            }

            addressLine1 == "";
            if (item.houseName1 != null && item.houseName1.length > 0) {
                addressLine1 = item.houseName1;
            }

            if (item.flat != null && item.flat.length > 0) {
                addressLine1 = item.flat + ", " + addressLine1;
                addressLine1 = addressLine1.trim();

                if (addressLine1.endsWith(",") == true) {
                    addressLine1 = addressLine1.substring(0, addressLine1.length - 2);
                }
            }
        }
    }

    const retVal: AddressModelDTO = {
        buildingName: buildingName == null ? "" : buildingName,
        addressLine1: addressLine1 == null ? "" : addressLine1,
        addressLine2: addressLine2 == null ? "" : addressLine2,
        city: item.town == null ? "" : item.town,
        county: item.county == null ? "" : item.county,
        postcode: item.postcode == null ? "" : item.postcode,
        locationLatitude: item.extra ? item.extra.records[0].extra_LATITUDE : 0,
        locationLongitude: item.extra ? item.extra.records[0].extra_LONGITUDE : 0,
    };

    return retVal;
};
