import styled from "styled-components";
import { Typography } from "@material-ui/core";
import { defaultTheme as theme } from "../../../Custom/StylesAppSpecific/AppStyling";

// Typography accepts the "component" prop but isn't typed correctly so it breaks when using styled components
interface IDummyProps {
    component: React.ElementType;
    htmlFor: string;
}

interface IProps {
    disableinputpadding?: boolean;
}

export const ControlLabelContainer = styled(Typography)<IDummyProps & IProps>`
    width: 100%;
    font-weight: bold;

    > span.control-label {
        display: block;
    }

    > div {
        width: 100%;
    }

    > .MuiBox-root > .MuiBox-root {
        padding: 0;
    }
`;
