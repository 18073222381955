import styled from "styled-components";

export const CircularDisplayWrapper: any = styled.div`
    display: flex;
    flex: 1 0 0;
    flex-direction: column;
    width: 100%;

    justify-content: center;

    padding: 0;

    .circlesection {
        display: relative;
        margin-left: auto;
        margin-right: auto;
    }

    /* background-color: pink; */
`;
