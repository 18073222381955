import { TableCell, TableRow, Box, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { AcceptBtn } from "Custom/StylesAppSpecific/Controls/AcceptBtn";
import { RoofcareInput } from "Custom/StylesAppSpecific/Controls/RoofcareInput";
import { ContactViewModel, ContactULGrid } from "./";

import { useObserver } from "Core/Base";
import { ContactModel, ContactModelDTO } from "./Contact.Model";
import { useContactTableStyles } from "Custom/Views/Clients/ClientContacts.style";
import { AddEditBoxRow } from "./ContactModal.Styles";

import {
    ContactListButton,
    ContactListPaper,
    ButtonWrapper,
    ContactListButtonWrapper,
    ListWrapper,
    InternalList,
    ContactListPage,
} from "Custom/StylesAppSpecific/Controls/ContactBoxes";

interface IContactAddRowProps {
    title: string;
    contact?: ContactModel;
    addContact: (model: ContactModel) => void;
}

export const ContactAddRow: React.FunctionComponent<IContactAddRowProps> = ({ contact, addContact, title }) => {
    const tableClasses = useContactTableStyles();
    const [viewModel, setViewModel] = useState(() => new ContactViewModel());

    const isLoading: boolean = false;

    const submitRow = () => {
        if (viewModel.isModelValid()) {
            addContact(viewModel.model);
            setViewModel(new ContactViewModel());
        }
    };

    return useObserver(() => (
        <>
            <ContactListPage>
                <ListWrapper>
                    <Typography variant="caption" className="titlePanel">
                        Add {title}
                    </Typography>
                    <ContactListPaper variant="outlined">
                        <InternalList>
                            <AddEditBoxRow>
                                <Box>
                                    <RoofcareInput type="text" label="First Name" validateOnBlur={true} viewModel={viewModel} fieldName="firstName" shrink={true} />
                                </Box>
                                <Box>
                                    <RoofcareInput type="text" label="Last Name" validateOnBlur={true} viewModel={viewModel} fieldName="lastName" shrink={true} />
                                </Box>
                            </AddEditBoxRow>
                            <AddEditBoxRow>
                                <Box>
                                    <RoofcareInput type="email" label="Email" validateOnBlur={true} viewModel={viewModel} fieldName="email" shrink={true} />
                                </Box>
                            </AddEditBoxRow>
                            <AddEditBoxRow>
                                <Box>
                                    <RoofcareInput type="tel" label="Phone" validateOnBlur={true} viewModel={viewModel} fieldName="phone" shrink={true} />
                                </Box>
                                <Box>
                                    <RoofcareInput type="tel" label="Secondary Phone" validateOnBlur={true} viewModel={viewModel} fieldName="phone2" shrink={true} />
                                </Box>
                            </AddEditBoxRow>
                            <AddEditBoxRow>
                                <Box className="fullwidth">
                                    <RoofcareInput type="text" label="Position" validateOnBlur={true} viewModel={viewModel} fieldName="position" shrink={true} />
                                </Box>
                            </AddEditBoxRow>
                        </InternalList>
                    </ContactListPaper>
                </ListWrapper>
            </ContactListPage>

            <Box display="flex" flexDirection="column">
                <Typography variant="caption">&nbsp;</Typography>
                <ContactListButtonWrapper>
                    <ButtonWrapper>
                        <ContactListButton variant="contained" color="primary" size="small" onClick={submitRow} disabled={isLoading} aria-label="move selected right">
                            Add &gt;&gt;
                        </ContactListButton>
                    </ButtonWrapper>
                </ContactListButtonWrapper>
            </Box>
        </>
    ));
};
