import * as MobX from "mobx";
import { ModelBase } from "Core/Models/ModelBase";

export interface InstallationCommandDto {
    id: number;
    command: string;
    completed: boolean;
    simId: string;

    commandId: string;
    item: string;
    value: string;
    commandItem: string;

    timestamp: string;
    sent: string;
    isDeleted: boolean;
}

export interface InstallationCommand extends InstallationCommandDto {}

export class InstallationCommandResult extends ModelBase<InstallationCommandResult, InstallationCommand> {
    @MobX.observable public commands: MobX.IObservableArray<InstallationCommand> = MobX.observable([]);

    //fromDto is required but you can leave it blank
    @MobX.action
    fromDto(model: InstallationCommand): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
        // NOTE We don't have an object, we have an array of objects, so use fromDtoArr
    }

    @MobX.action
    fromDtoArr(model: InstallationCommandDto[]): void {
        this.commands.clear();
        this.commands.replace(model.slice(0));
    }

    //toDto is required but you can leave it blank
    toDto(model: InstallationCommandResult): void {}
}
