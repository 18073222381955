import { action, computed, observable } from "mobx";
import { ModelBase } from "Core/Models/ModelBase";
import { Address } from "Custom/Models/Domain/Properties/Address";

export class Installation extends ModelBase<Installation, InstallationDTO> {
    @observable public id?: number = 0;
    @observable public rowVersion?: string = "";
    @observable public deviceId: string = "";
    @observable public simId: string = "";
    @observable public name: string = "";
    @observable public number: string = "";
    @observable public lastUpdated: string | undefined | null;
    @observable public ipAddress: string | undefined | null;
    @observable public latitude: number | undefined | null;
    @observable public longitude: number | undefined | null;
    @observable public roofcareAddressId: string | undefined | null;
    @observable public createdDate: string | undefined | null;
    @observable public earliestValue: string | undefined | null;
    @observable public isDeleted: boolean = false;
    @observable public address: Address | undefined | null;
    @observable public contractorId: string | undefined | null;

    //fromDto is required but you can leave it blank
    @action
    fromDto(model: InstallationDTO): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }

        // TODO CMR Sort out the Address object
    }

    //toDto is required but you can leave it blank
    toDto(model: Installation): InstallationDTO {
        return {
            id: model.id,
            rowVersion: model.rowVersion,
            deviceId: model.deviceId,
            simId: model.simId,
            name: model.name,
            number: model.number,
            lastUpdated: model.lastUpdated,
            ipAddress: model.ipAddress,
            latitude: model.latitude,
            longitude: model.longitude,
            roofcareAddressId: model.roofcareAddressId,
            createdDate: model.createdDate,
            earliestValue: model.earliestValue,
            isDeleted: model.isDeleted,
            contractorId: model.contractorId,
        };
    }
}

export interface InstallationDTO {
    id?: number;
    rowVersion?: string;
    deviceId: string;
    simId: string;
    name: string;
    number: string;
    lastUpdated: string | undefined | null;
    ipAddress: string | undefined | null;
    latitude: number | undefined | null;
    longitude: number | undefined | null;
    createdDate: string | undefined | null;
    earliestValue: string | undefined | null;
    isDeleted: boolean;
    roofcareAddressId: string | undefined | null;
    contractorId: string | undefined | null;
}
