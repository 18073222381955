import * as MobX from "mobx";
import { ModelBase } from "Core/Models/ModelBase";
import { IsEmail, IsNotEmpty, ValidateIf, MinLength, MaxLength } from "class-validator";
import { generateID } from "Custom/Utils/utils";
import { ContactListItem } from "Custom/Views/Contacts/ContactListItem";
import { ContactType } from "Custom/Models/Domain/Contacts/ContactType";
import { ContractorContactModelDTO } from "Custom/Models/Domain";
import { ContactModelDTO as ClientContactModelDTO } from "Custom/Models/Domain/Contacts/ContactModel";
//Base class expects the model type and the DTO model type
//If you are not using a DTO just pass in undefined as the DTO
export class ContactModel extends ModelBase<ContactModel, ContactModelDTO> {
    public id: string = "";

    @MobX.observable
    @IsNotEmpty({ message: "First name is required." })
    @MaxLength(50, { message: "Maximum first name length is 50 characters" })
    public firstName: string = "";

    @MobX.observable
    @IsNotEmpty({ message: "Last name is required" })
    @MaxLength(50, { message: "Maximum last name length is 50 characters" })
    public lastName: string = "";

    @MobX.observable
    @IsNotEmpty({ message: "Position is required" })
    @MaxLength(50, { message: "Maximum position length is 50 characters" })
    public position: string = "";

    @MobX.observable
    //@IsNotEmpty({ message: "Email is required if phone is empty" })
    //@IsEmail({ message: "Not a valid email address" })
    //@MaxLength(256, { message: "Maximum email length is 256 characters" })
    public email: string | null = "";

    @MobX.observable
    //@IsNotEmpty({ message: "Phone is required if email is empty" })
    //@MaxLength(50, { message: "Maximum phone length is 50 characters" })
    //@MinLength(6, { message: "Minimum phone length is 6 characters" })
    public phone: string | null = "";

    @MobX.observable
    //@IsNotEmpty({ message: "Phone is required if email is empty" })
    //@MaxLength(50, { message: "Maximum phone length is 50 characters" })
    //@MinLength(6, { message: "Minimum phone length is 6 characters" })
    public phone2: string | null = "";

    public rowVersion: string = "";
    public isDeleted: boolean = false;
    public createdDate: string = "";
    public createdBy: string = "";
    public sourceId: string = "";
    public contactType: string = "";

    @MobX.observable generatedTempId: string = generateID();

    //fromDto is required but you can leave it blank
    @MobX.action
    fromDto(model: ContactModelDTO): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
    }

    @MobX.computed
    public get displayName(): string {
        return this.firstName + " " + this.lastName;
    }

    //toDto is required but you can leave it blank
    toDto(model: ContactModelDTO): void {
        model = this.getDto();
    }

    public getDto(): ContactModelDTO {
        const retVal: ContactModelDTO = {
            firstName: this.firstName,
            lastName: this.lastName,
            position: this.position,
            email: this.email,
            phone: this.phone,
            phone2: this.phone2,
            rowVersion: this.rowVersion,
            isDeleted: this.isDeleted,
            createdDate: this.createdDate,
            createdBy: this.createdBy,
            id: this.id,
            sourceId: this.sourceId,
            contactType: this.contactType,
        };
        return retVal;
    }

    public isEmailValid = () => {
        let retVal: boolean = true;

        return retVal;
    };

    public isPhoneValid = () => {
        let retVal: boolean = true;

        return retVal;
    };

    @MobX.action
    public copy = (model: ContactModel) => {
        this.firstName = model.firstName;
        this.lastName = model.lastName;
        this.position = model.position;
        this.email = model.email;
        this.phone = model.phone;
        this.phone2 = model.phone2;
        this.rowVersion = model.rowVersion;
        this.isDeleted = model.isDeleted;
        this.createdDate = model.createdDate;
        this.createdBy = model.createdBy;
        this.id = model.id;
        this.sourceId = model.sourceId;
        this.contactType = model.contactType;
    };

    @MobX.action
    public copyFromContactListItem = (model: ContactListItem) => {
        this.firstName = model.firstName;
        this.lastName = model.lastName;
        this.position = model.position;
        this.email = model.email;
        this.phone = model.phone;
        this.phone2 = model.phone2;
        this.rowVersion = model.rowVersion;
        this.isDeleted = model.isDeleted;
        this.createdDate = model.createdDate;
        this.createdBy = model.createdBy;
        this.id = model.sourceId!;
        this.sourceId = model.parentId;
        this.contactType = model.contactType === ContactType.Client ? "client" : "contractor";
    };

    public toContractorContactModelDTO = (contractorId: string) => {
        const retVal: ContractorContactModelDTO = {
            firstName: this.firstName,
            lastName: this.lastName,
            position: this.position,
            email: this.email,
            phone: this.phone,
            phone2: this.phone2,
            rowVersion: this.rowVersion,
            isDeleted: this.isDeleted,
            createdDate: this.createdDate,
            createdBy: this.createdBy,
            id: this.id,
            contractorId: contractorId,
        };

        return retVal;
    };

    public toClientContactModelDTO = (parentId: string): ClientContactModelDTO => {
        const retVal: ClientContactModelDTO = {
            firstName: this.firstName,
            lastName: this.lastName,
            position: this.position,
            email: this.email,
            phone: this.phone,
            phone2: this.phone2,
            rowVersion: this.rowVersion,
            isDeleted: this.isDeleted,
            createdDate: this.createdDate,
            createdBy: this.createdBy,
            id: this.id,
            clientId: parentId,
        };
        return retVal;
    };
}

export type ContactModelDTO = {
    firstName: string;
    lastName: string;
    position: string;
    email: string | null;
    phone: string | null;
    phone2: string | null;
    rowVersion: string;
    isDeleted: boolean;
    createdDate: string;
    createdBy: string;
    id: string;
    sourceId: string;
    contactType: string;
};
