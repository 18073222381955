import { GenericIdRequest } from "Custom/Models";
import { ViewModelBase } from "Core/ViewModels/ViewModelBase";
import { action, computed, observable, runInAction } from "mobx";
import { FieldType } from "Core/Utils/Utils";
import { ApiResult, ApiResultErrorType } from "Core/Models";
import { Server } from "Custom/Globals/AppUrls";
import { UnitBehaviourItemViewModel } from "Custom/Views/Admin/UnitBehaviour/UnitBehaviourItemViewModel";
import { UnitBehaviourAddEditDetailModel, UnitBehaviourAddEditDetailModelDTO } from "Custom/Views/Admin/UnitBehaviour/UnitBehaviourAddEditDetailModel";
import { UnitBehaviourItemModel, UnitBehaviourItemModelDTO } from "Custom/Views/Admin/UnitBehaviour/UnitBehaviourItemModel";
import { DeviceUnitBehaviourModel } from "./DeviceUnitBehaviourModel";
import { DeviceUnitBehaviourItemModel } from "./DeviceUnitBehaviourItemModel";
import { InstallationAddEdit } from "Custom/Models/Domain/Installations/InstallationAddEdit";

//extend viewmodel base and passing your model as the generic type
export class DeviceUnitBehaviourViewModel extends ViewModelBase<DeviceUnitBehaviourModel> {
    @observable public errorMessage: string = "";

    @observable public vmItems: UnitBehaviourItemViewModel[] = [];

    @observable public disableControls: boolean = false;

    @computed public get canAddUnitBehaviour(): boolean {
        return false; //return this.items.length < 4;
    }

    constructor() {
        //Pass in a new instance of your model
        //By passing in true as the second parameter, we make this model undoable which means we can use save and reset options on the model
        //If you make a change to the model you need to persist it with a saveModel() call
        //If you make changes to your model you can revert it back by calling resetModel()
        super(new DeviceUnitBehaviourModel(), false);
        //EN - Haven't figured out how to make this call work from the base model yet
        //This is only needed if you make use of the validation decorators
        this.setDecorators(UnitBehaviourAddEditDetailModel);

        for (let i: number = 0; i < 3; i++) {
            let newVM: UnitBehaviourItemViewModel = new UnitBehaviourItemViewModel();
            newVM.model.setCommandIndex(i);
            newVM.model.reading = i === 0 ? 1 : 5;
            this.vmItems.push(newVM);
        }
    }

    @action public setItems(loadingItems: DeviceUnitBehaviourItemModel[]) {
        this.model.setItems(loadingItems);
        this.vmItems = [];

        // #7655 don't allow edit
        this.disableControls = true;

        for (let i: number = 0; i < loadingItems.length; i++) {
            let newVM: UnitBehaviourItemViewModel = new UnitBehaviourItemViewModel();
            newVM.model = loadingItems[i].toUnitBehaviourItemModel();
            newVM.model.setCommandIndex(i);
            this.vmItems.push(newVM);
        }
    }

    @action public clear = () => {
        this.disableControls = false;
        this.model.clear();
        this.vmItems = [];
        for (let i: number = 0; i < 3; i++) {
            let newVM: UnitBehaviourItemViewModel = new UnitBehaviourItemViewModel();
            newVM.model.setCommandIndex(i);
            this.vmItems.push(newVM);

            let temp: DeviceUnitBehaviourItemModel = new DeviceUnitBehaviourItemModel();
            temp.fromDto(newVM.model.toSendDeviceDto());
            this.model.items.push(temp);
        }
    };

    @computed get getUnitBehaviourItemViewModels() {
        return this.vmItems.slice().filter((a) => a.model.isDeleted === false);
    }

    @action public isModelAndChildrenValid = (): boolean => {
        let retVal: boolean = this.isModelValid();

        if (retVal) {
            //Do stuff here
        } else {
            this.errorMessage = "Form is not valid";
        }

        for (let i: number = 0; i < this.vmItems.length; i++) {
            if (this.vmItems[i].isModelValidWithSiblings(i, this.vmItems) === false) {
                retVal = false;
                this.errorMessage = "Form is not valid";
            }
        }
        return retVal;
    };

    //isValid will check all fields to make sure they are in a valid state.
    public doSubmit = async (e: any) => {
        e.preventDefault();

        if (this.isModelValid()) {
            //Do stuff here
        } else {
            this.errorMessage = "Form is not valid";
        }
    };

    //This must be present in your viewmodel. Just return true if you dont need to validate anything.
    //keyof BlankModel & string lets you add type checking to the fieldName
    //I am using the validator package to make checking easier https://www.npmjs.com/package/validator
    public isFieldValid(fieldName: keyof FieldType<DeviceUnitBehaviourModel>, value: any): boolean {
        const { isValid, errorMessage } = this.validateDecorators(fieldName);

        //You need to this two properties after validation
        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public getItemsDTO = (): UnitBehaviourItemModelDTO[] => {
        const retVal: UnitBehaviourItemModelDTO[] = [];
        for (let i: number = 0; i < this.vmItems.length; i++) {
            const model = this.vmItems[i].model;
            if ((model.id === null || model.id === undefined) && model.isDeleted === true) {
                // then it has not gone to the DB but has been deleted, so don't send it to the DB
            } else {
                // it may (existing) or may not (new) have an id.  But isdeleted is only true if it has an id.
                // so we need to send it to soft delete it in the DB
                const dto: UnitBehaviourItemModelDTO = model.toSendDto();
                retVal.push(dto);
            }
        }

        return retVal;
    };

    @action
    public setModelData(model: InstallationAddEdit) {
        if (model.unitBehaviour !== null && model.unitBehaviour !== undefined) {
            this.model.id = model.unitBehaviour.id;
            this.model.isDeleted = model.unitBehaviour.isDeleted;
            this.model.name = model.unitBehaviour.name;
            this.model.rowVersion = model.unitBehaviour.rowVersion;
            this.model.createdBy = model.unitBehaviour.createdBy;
            this.model.createdDate = model.unitBehaviour.createdDate;
            this.model.unitBehaviourId = model.unitBehaviour.unitBehaviourId;

            // clear the three blank items
            this.model.clearItems();

            let loadingItems: DeviceUnitBehaviourItemModel[] = [];
            for (let i: number = 0; i < model.unitBehaviour.items.length; i++) {
                const item: DeviceUnitBehaviourItemModel = model.unitBehaviour.items[i];

                let temp: DeviceUnitBehaviourItemModel = new DeviceUnitBehaviourItemModel();
                temp.id = item.id;
                temp.rowVersion = item.rowVersion;
                temp.isDeleted = item.isDeleted;
                temp.createdBy = item.createdBy;
                temp.createdDate = item.createdDate;

                temp.name = item.name;
                temp.type = item.type;
                temp.value = item.value;
                temp.units = item.units;
                temp.deviceUnitBehaviourId = item.deviceUnitBehaviourId;
                temp.frequency = item.frequency;
                temp.reading = item.reading;
                temp.commandIndex = i;
                loadingItems.push(temp);
            }

            this.setItems(loadingItems);
        }
    }

    @action
    public loadAdminUnitBehavioursAsync = async (id: string): Promise<ApiResult<UnitBehaviourAddEditDetailModelDTO | undefined>> => {
        let apiResult: ApiResult<UnitBehaviourAddEditDetailModelDTO | undefined> = {
            wasSuccessful: false,
            errors: [],
            headers: "",
            payload: undefined,
        };

        let request: GenericIdRequest = {
            id: id,
        };

        apiResult = await this.Post<UnitBehaviourAddEditDetailModelDTO | undefined>(Server.Api.UnitBehaviour.getUnitBehaviourById, request);

        runInAction(() => {
            if (apiResult.wasSuccessful === true) {
                //this.showError = false;
                this.errorMessage = "";

                if (apiResult.payload !== null && apiResult.payload !== undefined) {
                    let payload: UnitBehaviourAddEditDetailModelDTO | undefined = apiResult.payload;

                    // #7655 don't allow edit
                    this.disableControls = true;

                    this.model.fromAdminDto(payload!);
                    this.vmItems = [];
                    const items: DeviceUnitBehaviourItemModel[] = this.model.getItems;

                    // Convert from admin unit behaviour to device unit behaviour, so
                    // splat the model id and the item link to the parent.
                    this.model.id = null;
                    /*                     for (let i: number = 0; i < items.length; i++) {
                        items[i].unitBehaviourId = null;
                    } */

                    for (let i: number = 0; i < items.length; i++) {
                        // Convert back to unitbehaviouritemmodel, since that is what the control needs.
                        let newVM = new UnitBehaviourItemViewModel();
                        newVM.model = items[i].toUnitBehaviourItemModel();
                        newVM.model.setCommandIndex(items[i].commandIndex);
                        this.vmItems.push(newVM);
                    }
                }
            } else {
                this.errorMessage = "Failed to store the value.";

                apiResult.wasSuccessful = false;
                apiResult.errors = [
                    {
                        type: ApiResultErrorType.Basic,
                        message: "Failed to store the value.",
                    },
                ];
            }
        });

        return apiResult;
    };

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
