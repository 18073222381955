import * as React from "react";
import * as MobXReact from "mobx-react-lite";
import * as MobX from "mobx";
import { PropertyDetailMapContainer, ShowingOnMapContainer } from "./PropertyDetailMap.styles";
import { IMarker, RoofcareMap } from "Custom/Components/Map/Map";
import { RoofcareTable, RoofcareTableWrapper } from "Custom/Components/Table/RoofcareTable";
import { PropertyDetailViewModel } from "./PropertyDetailViewModel";
import { ResetSearchIcon } from "Custom/Components/Table/ResetSearchIcon";
import { Checkbox, Paper, useTheme } from "@material-ui/core";
import { formatAddress, formatCPRAddress, formatDateTime } from "Custom/Utils/format";
import { Loader } from "Core/Components";
import { observe } from "mobx";
import { InstallationListItem, InstallationListItemDTO, Property, UnitStatus } from "Custom/Models/Domain";
import { UnitStatusBox } from "Custom/Components";
import { MapPopup } from "Custom/StylesAppSpecific/CommonMap.styles";
import { AppUrls } from "Custom/Globals";
import { useRouter } from "Core/Utils";

import * as Defaults from "Custom/StylesAppSpecific/TableOptions";

interface IProps {
    viewModel: PropertyDetailViewModel;
}

export const PropertyDetailMap: React.FC<IProps> = ({ viewModel }) => {
    const [sensorsRows, setSensorRows] = React.useState<undefined | number>(undefined);
    const theme = useTheme();
    const router = useRouter<{ propertyId: string; projectId: string }>();

    const tableOptions = Defaults.GetRoofcareTableOptions() as any;

    React.useEffect(() => {
        return observe(viewModel, "IsLoading", () => setSensorRows(viewModel.sensorsRows), true);

        /*         return () => {
            // Clean up after yourself
        }; */
    }, []);

    const handleShowToggle = (rowData: any) => {
        const device = viewModel.installations.find((i) => i.getValue("id") === rowData.id);

        if (device) {
            device.setValue("showOnMap", !rowData.show);
        }
    };

    const handleToggleShowingWarning = (status: UnitStatus) => {
        MobX.runInAction(() => {
            // TODO CMR viewModel.showingWarning[status] = !viewModel.showingWarning[status];
        });
    };

    const renderShowingOnMapControl = () => {
        return (
            <ShowingOnMapContainer>
                <span>Showing on map:</span>
                {/* <ColouredCheckbox
                    checked={viewModel.showingWarning[InstallationStatus.GreenWarning]}
                    customcolour={theme.app.colors.deviceWarning.green!}
                    onChange={() => handleToggleShowingWarning(InstallationStatus.GreenWarning)}
                />
                <span className="label">Green warning</span>
                <ColouredCheckbox
                    checked={viewModel.showingWarning[InstallationStatus.AmberWarning]}
                    customcolour={theme.app.colors.deviceWarning.amber!}
                    onChange={() => handleToggleShowingWarning(InstallationStatus.AmberWarning)}
                />
                <span className="label">Amber warning</span>
                <ColouredCheckbox
                    checked={viewModel.showingWarning[InstallationStatus.RedWarning]}
                    customcolour={theme.app.colors.deviceWarning.red!}
                    onChange={() => handleToggleShowingWarning(InstallationStatus.RedWarning)}
                />
                <span className="label">Red warning</span>
                <ColouredCheckbox
                    checked={viewModel.showingWarning[InstallationStatus.FloodWarning]}
                    customcolour={theme.app.colors.deviceWarning.flood!}
                    onChange={() => handleToggleShowingWarning(InstallationStatus.FloodWarning)}
                />
                <span className="label">Flood warning</span> */}
            </ShowingOnMapContainer>
        );
    };

    const renderPage = () => {
        if (viewModel.IsLoading) return <Loader />;

        return (
            <PropertyDetailMapContainer>
                <div className="section1">
                    <RoofcareMap
                        id="proertydetail-map"
                        /*      controls={[{ key: "showing-on-map", control: renderShowingOnMapControl() }]} */
                        boundsToFit={viewModel.mapBounds}
                        markers={viewModel.markers}
                        displayPopUp={displayInstallationDetails}
                    />
                </div>
                <div className="section2">
                    {sensorsRows !== undefined && (
                        <RoofcareTableWrapper paddingTop="0 !important">
                            <RoofcareTable
                                columns={[
                                    {
                                        title: "Show",
                                        field: "show",
                                        sorting: true,
                                        filtering: true,
                                        render: (rowData: any) => <Checkbox checked={rowData.show} onChange={() => handleShowToggle(rowData)} />,
                                    },
                                    { title: "Sensor", field: "name", sorting: true, filtering: true },
                                    /* { title: "Temperature", field: "sensor", sorting: true, filtering: true }, */
                                    { title: "Last reading:", field: "lastReading", sorting: true, filtering: true, render: (rowData: any) => formatDateTime(rowData.lastReading) },
                                    {
                                        title: "Status",
                                        field: "status",
                                        sorting: true,
                                        filtering: true,
                                        render: (rowData: any) => (
                                            <UnitStatusBox className={"device-status "} backgroundColor={rowData.status.statusColour} textColour={rowData.status.statusTextColour}>
                                                <div className="status-name">{rowData.status.name}</div>
                                            </UnitStatusBox>
                                        ),
                                    },
                                ]}
                                options={{
                                    thirdSortClick: false,
                                    pageSize: sensorsRows,
                                    pageSizeOptions: [sensorsRows],
                                    emptyRowsWhenPaging: false,
                                    headerStyle: {
                                        ...tableOptions.headerStyle,
                                    },
                                    rowStyle: {
                                        ...tableOptions.rowStyle,
                                    },
                                    filterCellStyle: {
                                        ...tableOptions.filterCellStyle,
                                    },
                                    searchFieldAlignment: "left",
                                    searchFieldStyle: {
                                        border: "1px solid #333333",
                                        borderRadius: "5px",
                                        width: "400px",
                                    },
                                }}
                                data={viewModel.mapTableData}
                                title="Search sensors"
                                icons={{
                                    ResetSearch: () => <ResetSearchIcon />,
                                }}
                                components={{ Container: (props: any) => <Paper {...props} elevation={0} /> }}
                                localization={{ toolbar: { searchPlaceholder: "Start typing to search" } }}
                            />
                        </RoofcareTableWrapper>
                    )}
                </div>
            </PropertyDetailMapContainer>
        );
    };

    const displayInstallationDetails = (marker: IMarker): JSX.Element => {
        const device: InstallationListItem | undefined = viewModel.getInstallation(parseInt(marker.id));

        if (device !== undefined) {
            const property: Property = viewModel.getModel;

            if (property !== undefined) {
                /*                 if (showContractor === true) {
                    if (isNullOrUndefined(device.contractorName) === true || device.contractorName.length === 0) {
                        setShowContractor(false);
                    }
                }

                if (showClient === true) {
                    if (isNullOrUndefined(property.clientName) === true || property.clientName.length === 0) {
                        setShowClient(false);
                    }
                } */

                return (
                    <MapPopup>
                        {/*                         {showClient && !showContractor && <div className="title">{device.name + " (" + property.clientName + ")"}</div>}
                        {!showClient && showContractor && <div className="title">{device.name + " (" + device.contractorName + ")"}</div>} */}
                        {<div className="title">{device.name}</div>}
                        <div className="address">{formatAddress(property, true)}</div>
                        {/*                         {showClient && showContractor && (
                            <>
                                <div className="title">{device.name + " (" + property.clientName + ")"}</div> */}
                        <div className="title">{device.contractorName}</div>
                        {/*                          </>
                        )} */}
                        <div className="view" onClick={() => goToInstallation(marker.id)}>
                            View Unit
                        </div>
                    </MapPopup>
                );
            } else {
                return (
                    <MapPopup>
                        <div>{device.name}</div>
                    </MapPopup>
                );
            }
        }

        return (
            <MapPopup>
                {marker.position[0]}, {marker.position[1]}
            </MapPopup>
        );
    };

    function goToInstallation(id: string): void {
        const device: InstallationListItem | undefined = viewModel.getInstallation(parseInt(id));

        if (device !== null && device !== undefined) {
            handleDeviceClick(device);
        }
    }

    const handleDeviceClick = (device: InstallationListItem) => {
        const { path } = router.match;
        const { projectId, propertyId } = router.match.params;
        let url: string = "/";
        switch (path) {
            case AppUrls.Client.Main.EstateView.Property.PropertyDetail: {
                url = AppUrls.Client.Main.EstateView.Property.PropertyInstallation;
                break;
            }
            case AppUrls.Client.ClientView.Property.PropertyDetail: {
                url = AppUrls.Client.ClientView.Property.PropertyInstallation;
                break;
            }
            case AppUrls.Client.ContractorView.Property.PropertyDetail: {
                url = AppUrls.Client.ContractorView.Property.PropertyInstallation;
                break;
            }
            case AppUrls.Client.ContractorView.Project.PropertyDetail: {
                url = AppUrls.Client.ContractorView.Project.PropertyInstallation;
                break;
            }
            default: {
            }
        }

        url = url
            .replace(":projectId", projectId)
            .replace(":propertyId", propertyId)
            .replace(":installId", device.id?.toString() || "");

        router.history.push(url);
    };

    return MobXReact.useObserver(() => renderPage());
};
