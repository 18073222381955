import { Paper, Select, Typography, useTheme } from "@material-ui/core";
import * as Mui from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { ContractorDashboardViewModel } from "./ContractorDashboardViewModel";
import { Loader } from "Core/Components";
import { StoresInstance } from "Custom/Stores";
import { useRouter } from "Core/Utils";
import { useObserver } from "mobx-react-lite";
import { RoofcareTable, RoofcareTableWrapper, RoofcareTableWrapperFullWidth } from "Custom/Components/Table/RoofcareTable";
import { ProjectListSearch } from "Custom/Views/Projects/ProjectListSearch";
import { ProjectToolbarRowV2, ProjectToolbarRowV3 } from "Custom/StylesAppSpecific/Controls/BoxAddWrapper";
import { IMarker, RoofcareMap } from "Custom/Components/Map/Map";
import { DEFAULTBOUNDS } from "Custom/Globals/Globals";
import L, { LatLngBounds, Map as LeafletMap } from "leaflet";
import { DashBoardListContainer, DashBoardMapContainer, DashboardListMapContainer } from "Custom/Views/Estate/Dashboard.styles";
import { ResetSearchIcon } from "Custom/Components/Table/ResetSearchIcon";
import { EstateCommonRowDTO, InstallationMapModelDTO } from "Custom/Models/Domain";
import { Container } from "Custom/StylesAppSpecific/AppStyling";
import { MapPopup } from "Custom/StylesAppSpecific/CommonMap.styles";
import { generateID, isNullOrUndefined } from "Custom/Utils/utils";
import { formatCPRAddress } from "Custom/Utils/format";
import { AppUrls } from "Custom/Globals";
import { UnitStatusBox } from "Custom/Components";

import * as Defaults from "Custom/StylesAppSpecific/TableOptions";
import { MultiSelectView } from "Custom/Components/Select/MultipleSelect";

export const ContractorDashboardView: React.FunctionComponent = () => {
    const [viewModel] = useState(() => ContractorDashboardViewModel.Instance);
    const [bounds, setBounds] = useState<LatLngBounds>(DEFAULTBOUNDS);
    const [showContractor, setShowContractor] = useState<boolean>(false);
    const [showClient, setShowClient] = useState<boolean>(true);

    const { history } = useRouter();
    const theme = useTheme();

    //useEffect below only gets run on initial render
    useEffect(() => {
        // *optional* passing the router into the viewmodel so we can navigate from there
        //console.log("First render");
        let promise = viewModel.loadDashboardAsync();

        promise.then((result) => {
            setBounds(viewModel.mapBounds);

            viewModel.setFilterSets();
        });

        //This gets run when the page is exited
        return () => {
            //console.log("Unmounting");
        };
    }, []);

    const isInError = (fieldName: string): boolean => {
        /*         let isValid = props.filtersViewModel.getValid(fieldName);
        return !isValid; */
        return false;
    };

    const getError = (fieldName: string): string => {
        /*         let retVal: string = props.filtersViewModel.getError(fieldName);
        return retVal; */
        return "";
    };

    const setSearchString = (value: string) => {
        viewModel.setSearchString(value);
        viewModel.setPage(0);
    };

    const setRowsPerPage = (rows: number) => {
        viewModel.setRowsPerPage(rows);
    };

    const setPage = (page: number) => {
        viewModel.setPage(page);
    };

    const setOrderChange = (columnId: number, direction: any) => {
        viewModel.setOrderChange(columnId, direction);
    };

    const handleClientsFilterChange = (values: number[] | string[] | null) => {
        const valueList: string[] = values as string[];
        const intValues: string[] = [];

        for (let i: number = 0; i < valueList.length; i++) {
            const item: string = valueList[i];
            intValues.push(item);
        }

        viewModel.setClientFilter(intValues);
    };

    const handleStatusChange = (values: number[] | string[] | null) => {
        const valueList: string[] = values as string[];
        const intValues: string[] = [];

        for (let i: number = 0; i < valueList.length; i++) {
            const item: string = valueList[i];
            intValues.push(item);
        }

        viewModel.setStatusFilter(intValues);
    };

    function renderPage() {
        if ((viewModel.IsLoading || StoresInstance.domain.ProjectStore.isLoading) && viewModel.IsDownloading === false) return <Loader />;
        const tableOptions = Defaults.GetRoofcareTableOptions() as any;
        return (
            <Container>
                <RoofcareTableWrapper>
                    <ProjectToolbarRowV2>
                        <div className="search-field" style={{ width: "580px" }}>
                            <ProjectListSearch searchString={viewModel.getSearchString()} setSearchString={setSearchString} placeholder="Start typing to search" />
                        </div>
                    </ProjectToolbarRowV2>
                    <ProjectToolbarRowV3 style={{ marginTop: "20px" }}>
                        <Mui.Box>
                            <Mui.Box>
                                <Mui.Typography style={{ color: theme.palette.secondary.contrastText }} variant="h2" className="select-title">
                                    End Users:
                                </Mui.Typography>
                                <Mui.Box className="multiselectbox">
                                    <MultiSelectView
                                        fieldName=""
                                        values={viewModel.getClientsFilter()}
                                        display="End users"
                                        useNumber={true}
                                        getOptions={(includeDeleted: boolean) => viewModel.getClientsKVPair}
                                        handleChange={handleClientsFilterChange}
                                        getError={getError}
                                        getValid={isInError}
                                    />
                                </Mui.Box>
                            </Mui.Box>
                        </Mui.Box>
                        <Mui.Box>
                            <Mui.Box>
                                <Mui.Typography style={{ color: theme.palette.secondary.contrastText }} variant="h2" className="select-title">
                                    Status:
                                </Mui.Typography>
                                <Mui.Box className="multiselectbox">
                                    <MultiSelectView
                                        fieldName=""
                                        values={viewModel.getStatusFilter()}
                                        display="Status"
                                        useNumber={true}
                                        getOptions={viewModel.getStatusKVPair}
                                        handleChange={handleStatusChange}
                                        getError={getError}
                                        getValid={isInError}
                                    />
                                </Mui.Box>
                            </Mui.Box>
                        </Mui.Box>
                    </ProjectToolbarRowV3>
                    <DashboardListMapContainer id="dashboardListMapContainer">
                        <DashBoardListContainer id="dashBoardListContainer" style={{ overflow: "hidden", borderRadius: "12px" }}>
                            <RoofcareTable
                                id="dashboardmap"
                                className="dashboardmap"
                                columns={[
                                    {
                                        title: "End User",
                                        field: "clientName",
                                        sorting: true,
                                        filtering: true,
                                    },
                                    {
                                        title: "Property",
                                        field: "addressLine1",
                                        sorting: true,
                                        filtering: true,
                                    },
                                    {
                                        width: "auto",
                                        title: "Town",
                                        field: "city",
                                        sorting: true,
                                        filtering: true,
                                    },
                                    {
                                        width: "auto",
                                        title: "Postcode",
                                        field: "postcode",
                                        sorting: true,
                                        filtering: true,
                                    },
                                    {
                                        width: "auto",
                                        title: "Units",
                                        field: "propertyCount",
                                        sorting: true,
                                        filtering: true,
                                    },
                                    {
                                        maxWidth: "68px",
                                        title: "Alert",
                                        field: "hasAlertAction",
                                        sorting: true,
                                        /* defaultSort: getSortDirection(6), */
                                        render: (rowData: EstateCommonRowDTO) => {
                                            {
                                                return rowData.hasAlertAction ? (
                                                    <div className="central-cell">
                                                        <div className="tick">
                                                            <span>&#10003;</span>
                                                        </div>
                                                        {rowData.hasValidEmailAddesses && <div className="alerticon tick fa fa-envelope"></div>}
                                                        {rowData.hasValidEmailAddesses == false && <div className="alerticon cross fa fa-envelope"></div>}
                                                        {rowData.hasValidMobileNumbers && <div className="alerticon tick fa fa-mobile"></div>}
                                                        {rowData.hasValidMobileNumbers == false && <div className="alerticon cross fa fa-mobile "></div>}
                                                    </div>
                                                ) : (
                                                    <div className="central-cell">
                                                        <div className="cross">
                                                            <span>&#88;</span>
                                                        </div>
                                                        {rowData.hasValidEmailAddesses && <div className="alerticon tick fa fa-envelope"></div>}
                                                        {rowData.hasValidEmailAddesses == false && <div className="alerticon cross fa fa-envelope"></div>}
                                                        {rowData.hasValidMobileNumbers && <div className="alerticon tick fa fa-mobile"></div>}
                                                        {rowData.hasValidMobileNumbers == false && <div className="alerticon cross fa fa-mobile "></div>}
                                                    </div>
                                                );
                                            }
                                        },
                                    },
                                    {
                                        maxWidth: "95px",
                                        title: "Behaviour",
                                        field: "hasUnitBehaviour",
                                        sorting: true,
                                        /* defaultSort: getSortDirection(7), */
                                        render: (rowData: EstateCommonRowDTO) => {
                                            {
                                                return rowData.hasUnitBehaviour ? (
                                                    <div className="central-cell">
                                                        <div className="tick">
                                                            <span>&#10003;</span>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="central-cell">
                                                        <div className="cross">
                                                            <span>&#88;</span>
                                                        </div>
                                                    </div>
                                                );
                                            }
                                        },
                                    },
                                    {
                                        maxWidth: "95px",
                                        title: "Condition",
                                        field: "hasConditionSet",
                                        sorting: true,
                                        /* defaultSort: getSortDirection(8), */
                                        render: (rowData: EstateCommonRowDTO) => {
                                            {
                                                return rowData.hasConditionSet ? (
                                                    <div className="central-cell">
                                                        <div className="tick">
                                                            <span>&#10003;</span>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="central-cell">
                                                        <div className="cross">
                                                            <span>&#88;</span>
                                                        </div>
                                                    </div>
                                                );
                                            }
                                        },
                                    },
                                    {
                                        width: "auto",
                                        title: "Status",
                                        field: "maxStatus",
                                        sorting: true,
                                        filtering: true,
                                        render: (rowData: any) => {
                                            if (rowData !== null && rowData !== undefined) {
                                                return (
                                                    <div className="status-readings" style={{ display: "flex", justifyContent: "center", width: "75px" }}>
                                                        <UnitStatusBox
                                                            className={"device-status "}
                                                            backgroundColor={rowData.status.statusColour}
                                                            textColour={rowData.status.statusTextColour}
                                                        >
                                                            <div className="status-name">{rowData.status.name}</div>
                                                        </UnitStatusBox>
                                                    </div>
                                                );
                                            }
                                        },
                                    },
                                ]}
                                options={{
                                    search: false,
                                    toolbar: false,
                                    thirdSortClick: false,
                                    initialPage: viewModel.currentPage,
                                    pageSize: 50,
                                    pageSizeOptions: [10, 20, 50, 100],
                                    emptyRowsWhenPaging: false,
                                    headerStyle: {
                                        ...tableOptions.headerStyle,
                                    },
                                    rowStyle: {
                                        ...tableOptions.rowStyle,
                                    },
                                    filterCellStyle: {
                                        ...tableOptions.filterCellStyle,
                                    },
                                    searchFieldAlignment: "left",
                                    searchFieldStyle: {
                                        border: "1px solid #333333",
                                        borderRadius: "5px",
                                        width: "400px",
                                    },
                                }}
                                data={viewModel.getListData}
                                title=""
                                icons={{
                                    ResetSearch: () => <ResetSearchIcon />,
                                }}
                                onRowClick={(_: any, rowData: any) => goToProperty(rowData.propertyId)}
                                components={{ Container: (props: any) => <Paper {...props} elevation={0} /> }}
                                localization={{ toolbar: { searchPlaceholder: "Start typing to search" } }}
                                onOrderChange={(orderedColumnId: any, orderDirection: any) => {
                                    setOrderChange(orderedColumnId, orderDirection);
                                }}
                                onChangeRowsPerPage={(rows: number) => {
                                    setRowsPerPage(rows);
                                }}
                                onChangePage={(page: number) => {
                                    setPage(page);
                                }}
                            />
                        </DashBoardListContainer>
                        <DashBoardMapContainer>
                            <div id="unittitle" className="unittitle MuiTableCell-root MuiTableCell-head MTableHeader-header-29 MuiTableCell-alignLeft">
                                <div className="title">Property and unit map</div>
                                <div className="layer-select">
                                    <div className="control-label">Current Layer :</div>
                                    <Select
                                        id="layer-select"
                                        className="form-control"
                                        value={viewModel.getLayer}
                                        onChange={onLayerChange}
                                        MenuProps={{
                                            getContentAnchorEl: null,
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "left",
                                            },
                                        }}
                                    >
                                        <option value={0} style={{ padding: "3px 10px 3px" }}>
                                            Properties
                                        </option>
                                        <option value={1} style={{ padding: "3px 10px 0px" }}>
                                            Units
                                        </option>
                                    </Select>
                                </div>
                            </div>

                            <div className="mapsection">
                                <RoofcareMap
                                    id="contractordashboard-map"
                                    //controls={[{ key: "layers-on-map", control: renderLayerMapControl() }]}
                                    boundsToFit={bounds}
                                    markers={viewModel.markers}
                                    displayPopUp={viewModel.showProperties ? displayPropertyDetails : displayInstallationDetails}
                                />
                            </div>
                        </DashBoardMapContainer>
                    </DashboardListMapContainer>
                </RoofcareTableWrapper>
            </Container>
        );
    }

    const onLayerChange = () => {
        viewModel.toggleLayer();
        setBounds(viewModel.mapBounds);
    };

    const displayPropertyDetails = (marker: IMarker): JSX.Element => {
        const property: EstateCommonRowDTO | undefined = viewModel.getProperty(marker.id);

        if (property !== undefined) {
            if (showClient === true) {
                if (isNullOrUndefined(property.clientName) === true || property.clientName.length === 0) {
                    setShowClient(false);
                }
            }

            return (
                <MapPopup>
                    <div className="address">{formatCPRAddress(property, true)}</div>
                    {showClient && (
                        <>
                            <div className="title">{"Client: " + property.clientName}</div>
                        </>
                    )}
                    <div className="view" onClick={() => goToProperty(marker.id)}>
                        View property
                    </div>
                </MapPopup>
            );
        }

        return (
            <MapPopup>
                {marker.position[0]}, {marker.position[1]}
            </MapPopup>
        );
    };

    const displayInstallationDetails = (marker: IMarker): JSX.Element => {
        const device: InstallationMapModelDTO | undefined = viewModel.getInstallation(marker.id);

        if (device !== undefined) {
            const property: EstateCommonRowDTO | undefined = viewModel.getProperty(device.roofcareAddressId);

            if (property !== undefined) {
                if (showContractor === true) {
                    if (isNullOrUndefined(device.contractorName) === true || device.contractorName.length === 0) {
                        setShowContractor(false);
                    }
                }

                if (showClient === true) {
                    if (isNullOrUndefined(property.clientName) === true || property.clientName.length === 0) {
                        setShowClient(false);
                    }
                }

                return (
                    <MapPopup>
                        {showClient && !showContractor && <div className="title">{device.name + " (" + property.clientName + ")"}</div>}
                        {!showClient && showContractor && <div className="title">{device.name + " (" + device.contractorName + ")"}</div>}
                        {!showClient && !showContractor && <div className="title">{device.name}</div>}
                        <div className="address">{formatCPRAddress(property, true)}</div>
                        {showClient && showContractor && (
                            <>
                                <div className="title">{device.name + " (" + property.clientName + ")"}</div>
                                <div className="title">{device.contractorName}</div>
                            </>
                        )}
                        <div className="view" onClick={() => goToInstallation(marker.id)}>
                            View Unit
                        </div>
                    </MapPopup>
                );
            } else {
                return (
                    <MapPopup>
                        <div>{device.name}</div>
                    </MapPopup>
                );
            }
        }

        return (
            <MapPopup>
                {marker.position[0]}, {marker.position[1]}
            </MapPopup>
        );
    };

    function goToInstallation(id: string): void {
        let url: string = AppUrls.Client.ContractorView.Property.PropertyInstallation;

        const propertyId: string = viewModel.getPropertyIdForUnit(id);
        const projectId: string = viewModel.getProjectIdForProperty(propertyId);

        history.push(url.replace(":projectId", projectId).replace(":propertyId", propertyId).replace(":installId", id));
    }

    function goToProperty(id: string): void {
        let url: string = AppUrls.Client.ContractorView.Property.PropertyDetail;

        const projectId: string = viewModel.getProjectIdForProperty(id);

        history.push(url.replace(":projectId", projectId).replace(":propertyId", id));
    }

    return useObserver(() => renderPage());
};
