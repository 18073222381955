import styled from "styled-components";

export interface IUnitStatusProps {
    backgroundColor: string;
    textColour: string;
    className?: string;
    /*     disabled?: boolean;
    onClick?: (id: string) => any; */
}

export const UnitStatusBox = styled.div<IUnitStatusProps>`
    background-color: ${(props) => props.backgroundColor};
    box-shadow: inset 0px 3px 6px #cdcbcbb8;
    color: ${(props) => props.textColour};

    &.device-status {
        display: flex;
        flex: 1 0 1;
        width: 100%;
        min-width: 74px;
        min-height: 25px;
        padding: 5px 0;
        border-radius: 2px;
        text-align: middle;
        align-content: middle !important;
        position: relative;

        .status-name {
            width: 100%;
            text-align: center;
            align-content: middle !important;
        }

        &.overview {
            min-width: 84px;
            padding: 5px !important;
            max-width: 180px;
        }
    }
`;
