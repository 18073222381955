import { Button } from "@material-ui/core";
import * as React from "react";
import { ClientAddModalDialogPaper } from "./ClientAddModal.styles";
import { CancelBtn } from "Custom/StylesAppSpecific/Controls/CancelBtn";
import { SuccessBtn } from "Custom/StylesAppSpecific/Controls/SuccessBtn";
import { ClientAddEditForm } from "./ClientAddEditForm";
import { ClientViewModel } from "./ClientViewModel";
import { BaseModal } from "Custom/Components/Modal/BaseModal";
import { Main } from "./ClientAddEdit.style";
import { Observer } from "mobx-react-lite";

interface IProps {
    open?: boolean;
    onClose(): void;
    onClientAdded?: (id: string) => void;
}

export const ClientAddModal: React.FC<IProps> = ({ open, onClose, onClientAdded }) => {
    const [viewModel, setViewModel] = React.useState<ClientViewModel | null>(null);

    React.useEffect(() => {
        if (open) {
            setViewModel(new ClientViewModel());
        }
    }, [open]);

    const handleSubmit = () => {
        if (viewModel) {
            viewModel.doSubmit(undefined, false).then((apiResult) => {
                if (apiResult && apiResult.wasSuccessful) {
                    if (onClientAdded) {
                        onClientAdded(apiResult.payload.client.id);
                    }
                    onClose();
                }
            });
        }
    };

    return (
        <BaseModal
            open={open}
            onClose={onClose}
            title="Add new end user"
            PaperComponent={ClientAddModalDialogPaper}
            actions={
                viewModel ? (
                    <Observer>
                        {() => (
                            <>
                                <SuccessBtn onClick={handleSubmit} autoFocus disabled={viewModel?.IsLoading}>
                                    {viewModel?.IsLoading ? "Adding..." : "Add new end user"}
                                </SuccessBtn>
                                <CancelBtn onClick={onClose} disabled={viewModel?.IsLoading}>
                                    Cancel
                                </CancelBtn>
                            </>
                        )}
                    </Observer>
                ) : (
                    <></>
                )
            }
        >
            {viewModel !== null && (
                <Main>
                    <ClientAddEditForm viewModel={viewModel!} />
                </Main>
            )}
        </BaseModal>
    );
};
