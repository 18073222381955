import { Box } from "@material-ui/core";
import styled from "styled-components";

export const ProjectActionListCell = styled.div`
    &.followupdate,
    &.resolved,
    &.created {
        padding: 5px;
        min-width: 100px;
        max-width: 115px;
        text-align: center;
    }

    &.overdue {
        background-color: #b42025;
        color: #ffffff !important;
    }
`;

export const ActionConditionRow = styled.div`
    .actionconditionitem {
        display: flex;
        flex-direction: row;
        flex: 1 1 0;
        justify-content: flex-start;
        color: white;

        &.invalidselection {
            color: #b42025;
        }
    }
`;

export const ActionWrapper: any = styled(Box)`
    color: black;
    padding: 15px 30px;
`;
