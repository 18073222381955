import { computed, observable } from "mobx";
import { ModelBase } from "Core/Models/ModelBase";
import { IsNotEmpty, Min } from "class-validator";
import { IDocumentModelDTO } from "../IDocumentModelDTO";

//Base class expects the model type and the DTO model type
//If you are not using a DTO just pass in undefined as the DTO
export class ProjectDocumentModel extends ModelBase<ProjectDocumentModel, ProjectDocumentModelDTO> implements IDocumentModelDTO {
    public id?: string = "";
    public rowVersion: string = "";
    public projectId: string = "";
    @observable
    @IsNotEmpty({ message: "You must have a filename" })
    public filename: string = "";
    @observable
    public createdDate: string = "";
    public creator: string = "";
    @observable
    public createdByName: string = "";
    @observable
    public isDeleted: boolean = false;
    @observable
    @Min(1, { message: "You must choose a category" })
    public documentCategoryId: number = 0;
    public documentId: string = "";
    public url: string = "";

    @computed public get category(): number {
        return this.documentCategoryId;
    }

    //fromDto is required but you can leave it blank
    fromDto(model: ProjectDocumentModelDTO): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
    }

    //toDto is required but you can leave it blank
    toDto(model: ProjectDocumentModel): void {}
}

export interface ProjectDocumentModelDTO extends IDocumentModelDTO {
    projectId: string;
}
