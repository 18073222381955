import { InstallationStatusDataDTO, SensorValueDTO, SensorValueExtended } from "Custom/Models/Domain/Installations";
import { action, IObservableArray, observable } from "mobx";
import { ModelBase } from "Core/Models/ModelBase";
import { isNullOrUndefined } from "Custom/Utils/utils";
import moment from "moment";

export class InstallationDataResult extends ModelBase<InstallationDataResult, SensorValueDTO> {
    //@MobX.observable public sensorValues: MobX.IObservableArray<SensorValueDTO> = MobX.observable([]);

    public sensorValues: IObservableArray<SensorValueExtended> = observable([]);

    //fromDto is required but you can leave it blank
    @action
    fromDto(model: SensorValueDTO): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
        // NOTE We don't have an object, we have an array of objects, so use fromDtoArr
    }

    @action
    public clear(): void {
        this.sensorValues.clear();
    }

    @action
    public fromDtoArr(model: SensorValueDTO[], statusData: InstallationStatusDataDTO): void {
        this.sensorValues.clear();
        this.sensorValues.replace(this.generateSensorValues(model.slice(0), statusData));
    }

    @action
    public generateSensorValues(values: SensorValueDTO[], statusData: InstallationStatusDataDTO): SensorValueExtended[] {
        const { install_Height } = statusData;
        let retVal: SensorValueExtended[] = [];

        if (isNullOrUndefined(values) === false) {
            retVal = values.map((item: SensorValueDTO) => {
                const value: SensorValueExtended = <SensorValueExtended>item;
                value.state = item.status;
                return value;
            });
        }
        return retVal;
    }

    @action
    public updateSensorValueHideOnGraph(payload: SensorValueDTO) {
        // let record: SensorValueDTO | undefined = this.sensorValues.find((a) => a.id === payload.id);

        for (let i = 0; i < this.sensorValues.length; i++) {
            if (this.sensorValues[i].id === payload.id) {
                this.sensorValues[i].hideOnGraph = payload.hideOnGraph;
                break;
            }
        }

        /*         if (record !== undefined) {
            record.hideOnGraph = payload.hideOnGraph;
        } */
    }

    //toDto is required but you can leave it blank
    toDto(model: InstallationDataResult): void {}
}
