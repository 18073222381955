import * as React from "react";
import * as MobXReact from "mobx-react-lite";
import { PropertyDetailViewModel } from "./PropertyDetailViewModel";
import { PropertyDetailDetailContainer, DeviceGridView } from "./PropertyDetailDetail.styles";
import { formatAddress, formatBooleanYesNo, formatDateTime } from "Custom/Utils/format";
import { Typography } from "@material-ui/core";
import { InstallationListItem } from "Custom/Models/Domain/Installations";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { AppUrls } from "Custom/Globals";
import { useRouter } from "Core/Utils";
import { generateID } from "Core/Utils/Utils";
import { UnitStatusBox } from "Custom/Components";
import { Stores, StoresContext } from "Custom/Stores";

interface IProps {
    viewModel: PropertyDetailViewModel;
}

export const PropertyDetailDetail: React.FC<IProps> = ({ viewModel }) => {
    const store = React.useContext<Stores>(StoresContext);
    const router = useRouter<{ propertyId: string; projectId: string }>();

    const handleDeviceClick = (device: InstallationListItem) => {
        const { path } = router.match;
        const { projectId, propertyId } = router.match.params;
        let url: string = "/";
        switch (path) {
            case AppUrls.Client.Main.EstateView.Property.PropertyDetail: {
                url = AppUrls.Client.Main.EstateView.Property.PropertyInstallation;
                break;
            }
            case AppUrls.Client.ClientView.Property.PropertyDetail: {
                url = AppUrls.Client.ClientView.Property.PropertyInstallation;
                break;
            }
            case AppUrls.Client.ContractorView.Property.PropertyDetail: {
                url = AppUrls.Client.ContractorView.Property.PropertyInstallation;
                break;
            }
            case AppUrls.Client.ContractorView.Project.PropertyDetail: {
                url = AppUrls.Client.ContractorView.Project.PropertyInstallation;
                break;
            }
            default: {
            }
        }

        url = url
            .replace(":projectId", projectId)
            .replace(":propertyId", propertyId)
            .replace(":installId", device.id?.toString() || "");

        router.history.push(url);
    };

    let showABC: boolean = store.domain.AccountStore.containsRoles(["admin", "regionalsalesmanager", "contractoradmin"]);

    const renderGridDevice = (device: InstallationListItem) => (
        <li key={generateID()} onClick={() => handleDeviceClick(device)}>
            <div className="cell">
                <div className="device-name">{device.name}</div>
                {/* <div className="device-sim-id"></div> */}
            </div>
            <div className="cell">
                Sim ID: {device.simId}
                {/* Device ID: {device.deviceId} */}
            </div>
            <div className="cell">Created date: {formatDateTime(device.createdDate!)}</div>
            <div className="cell">Last reading: {formatDateTime(device.getLatestReadingTime)}</div>
            <div className="cell">
                <div className="header">
                    <div>
                        <div className="central-cell alert">
                            <div>
                                <span>Alert</span>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="central-cell behaviour">
                            <div>
                                <span>Behaviour</span>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="central-cell condition">
                            <div>
                                <span>Condition</span>
                            </div>
                        </div>
                    </div>
                </div>
                {showABC && (
                    <div className="data">
                        <div>
                            {device.hasAlertAction ? (
                                <div className="central-cell alert">
                                    <div className="tick">
                                        <span>&#10003;</span>
                                    </div>
                                    {device.hasValidEmailAddesses && <div className="alerticon tick fa fa-envelope"></div>}
                                    {device.hasValidEmailAddesses == false && <div className="alerticon cross fa fa-envelope"></div>}
                                    {device.hasValidMobileNumbers && <div className="alerticon tick fa fa-mobile"></div>}
                                    {device.hasValidMobileNumbers == false && <div className="alerticon cross fa fa-mobile "></div>}
                                </div>
                            ) : (
                                <div className="central-cell alert">
                                    <div className="cross">
                                        <span>&#88;</span>
                                    </div>
                                    {device.hasValidEmailAddesses && <div className="alerticon tick fa fa-envelope"></div>}
                                    {device.hasValidEmailAddesses == false && <div className="alerticon cross fa fa-envelope"></div>}
                                    {device.hasValidMobileNumbers && <div className="alerticon tick fa fa-mobile"></div>}
                                    {device.hasValidMobileNumbers == false && <div className="alerticon cross fa fa-mobile "></div>}
                                </div>
                            )}
                        </div>
                        <div>
                            {device.hasUnitBehaviour ? (
                                <div className="central-cell behaviour">
                                    <div className="tick">
                                        <span>&#10003;</span>
                                    </div>
                                </div>
                            ) : (
                                <div className="central-cell behaviour">
                                    <div className="cross">
                                        <span>&#88;</span>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div>
                            {device.hasConditionSet ? (
                                <div className="central-cell condition">
                                    <div className="tick">
                                        <span>&#10003;</span>
                                    </div>
                                </div>
                            ) : (
                                <div className="central-cell condition">
                                    <div className="cross">
                                        <span>&#88;</span>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
            <div className="cell status-readings">
                <UnitStatusBox className={"device-status "} backgroundColor={device.getStatus.statusColour} textColour={device.getStatus.statusTextColour}>
                    <div className="status-name">{device.getStatus.name}</div>
                </UnitStatusBox>
            </div>
            <div className="cell next-icon">
                <NavigateNextIcon />
            </div>
        </li>
    );

    const renderGridDevices = () => <DeviceGridView>{viewModel.getInstallations.map((device) => renderGridDevice(device))}</DeviceGridView>;

    return MobXReact.useObserver(() => (
        <PropertyDetailDetailContainer>
            <Typography variant="h1">
                {viewModel.projectName} - {formatAddress(viewModel.model, true)}
            </Typography>
            <Typography variant="h1">
                <span>Project no:</span> {viewModel.projectNumber}
            </Typography>
            <Typography variant="h1" component="h3">
                Installations
            </Typography>
            {renderGridDevices()}
        </PropertyDetailDetailContainer>
    ));
};
