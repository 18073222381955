import { ConditionSetMetricDto } from "./ConditionSetMetric";
import { AlertSetMetricDto } from "./AlertSetMetric";
import { ModelBase } from "Core/Models";
import { action, IObservableArray, observable } from "mobx";

export class InstallationMetricsResponseModel extends ModelBase<InstallationMetricsResponseModel, InstallationMeticsResponseDto> {
    public deviceId: number = -1;
    public startDate: string = "";
    public endDate: string = "";
    @observable public conditionSetMetrics: IObservableArray<ConditionSetMetricDto> = observable([]);
    @observable public totalPeriodCount: number = 0;
    @observable public yearConditionSetMetrics: IObservableArray<ConditionSetMetricDto> = observable([]);
    @observable public totalYearCount: number = 0;

    @action
    public reset() {
        this.deviceId = -1;
        this.startDate = "";
        this.endDate = "";
        this.conditionSetMetrics.clear();
        this.totalPeriodCount = 0;
        this.yearConditionSetMetrics.clear();
        this.totalYearCount = 0;
    }

    @action
    public partialReset() {
        this.deviceId = -1;
        this.startDate = "";
        this.endDate = "";
        this.conditionSetMetrics.clear();
        this.totalPeriodCount = 0;
    }

    @action
    fromDtoArr(model: InstallationMeticsResponseDto, getYearData: boolean): void {
        this.deviceId = model.deviceId;
        this.startDate = model.startDate;
        this.endDate = model.endDate;
        this.totalPeriodCount = model.totalPeriodCount;

        this.conditionSetMetrics.clear();
        this.conditionSetMetrics = observable(model.conditionSetMetrics.slice(0));

        if (getYearData === true) {
            this.totalYearCount = model.totalYearCount;
        }

        if (getYearData === true) {
            this.yearConditionSetMetrics.clear();
            this.yearConditionSetMetrics = observable(model.yearConditionSetMetrics.slice(0));
        }
    }

    //fromDto is required but you can leave it blank
    fromDto(model: InstallationMeticsResponseDto): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
    }

    //toDto is required but you can leave it blank
    toDto(model: InstallationMetricsResponseModel): void {}
}
export interface InstallationMeticsResponseDto {
    deviceId: number;
    startDate: string;
    endDate: string;
    conditionSetMetrics: ConditionSetMetricDto[];
    totalPeriodCount: number;
    yearConditionSetMetrics: ConditionSetMetricDto[];
    totalYearCount: number;
}
