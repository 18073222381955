import React, { useEffect, useState } from "react";
import { useObserver } from "mobx-react-lite";
import { useRouter } from "Core/Utils";

import { Container } from "Custom/StylesAppSpecific/AppStyling";
import { ClientAddEdit } from "./ClientAddEdit";

export const ClientAddEditPage: React.FC = () => {
    const { history } = useRouter();

    useEffect(() => {}, []);

    return useObserver(() => <Container>{<ClientAddEdit />}</Container>);
};
