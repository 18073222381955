import * as React from "react";
import * as Mui from "@material-ui/core";

import { DeleteModalDialogPaper } from "./DeleteModal.styles";
import { CancelBtn } from "../../StylesAppSpecific/Controls/CancelBtn";
import { SuccessBtn } from "Custom/StylesAppSpecific/Controls/SuccessBtn";
import { BaseModal } from "./BaseModal";

interface IProps {
    open?: boolean;
    onClose(): void;
    onDelete(): void;
    title: string;
    text: string;
}

export const DeleteModal: React.FC<IProps> = ({ open, onClose, onDelete, title, text }) => (
    <BaseModal
        open={open}
        onClose={onClose}
        title={title}
        PaperComponent={DeleteModalDialogPaper}
        actions={
            <>
                <SuccessBtn onClick={onDelete} color="secondary" autoFocus variant="contained">
                    Ok
                </SuccessBtn>
                <CancelBtn onClick={onClose}>Cancel</CancelBtn>
            </>
        }
    >
        <p className="client-modal-description">{text}</p>
    </BaseModal>
);
