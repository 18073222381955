import * as MobX from "mobx";

import { FieldType, isEmptyOrWhitespace, sortByString } from "Core/Utils/Utils";
import { ForgotPasswordModelDTO, GenericIncludeDeleted, LoginModel } from "Custom/Models";
import { User, UserDTO, UserRelatedDTO } from "Custom/Models/User";
import { action, computed, observable } from "mobx";
import { Role } from "Custom/Models";
import { ApiResult } from "Core/Models/ApiResult";
import { AppUrls } from "Custom/Globals";
import { StoresInstance } from "Custom/Stores/Stores";
import { RoleStore, UserStore } from "Custom/Stores/Domain";
import { ViewModelBase } from "Core/ViewModels/ViewModelBase";
import { Server } from "Custom/Globals/AppUrls";
import { DeleteUserRequest } from "./DeleteUserRequest";
import { ForgotPasswordRequest } from "./ForgotPasswordRequest";
import { debounce } from "@material-ui/core";
import { DEBOUNCE_VALUE_MS } from "Custom/Globals/Globals";
import { KeyValuePair } from "Core/Models/KeyValuePair";
import { DropDownListItem } from "./../../Models/API/DropDownListItem";
import { UserDetailsViewModel } from "./UserDetailsViewModel";

const domainStores = StoresInstance.domain;
export default class UserListViewModel extends ViewModelBase<User> {
    private static _instance: UserListViewModel;
    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    @MobX.observable public userCount: number = 0;
    @MobX.observable private ascOrder = true;
    @MobX.observable public Valid: boolean = false;
    @MobX.observable public resetLoginAttemptsError = "";

    private userStore: UserStore = StoresInstance.domain.UserStore;
    private roleStore: RoleStore = StoresInstance.domain.RoleStore;

    @observable public roles = observable<DropDownListItem>([]);
    @observable public rolesFilter = observable<string>([]);
    @observable public filterRoleFilter = observable<string>([]);

    private firstTimeLoad: boolean = true;

    public users: MobX.IObservableArray<User> = MobX.observable<User>([]);

    @computed get getAllUsers(): any[] {
        let retVal = this.users.slice();
        retVal.sort((a: UserDTO, b: UserDTO) => {
            if (this.ascOrder) {
                return sortByString(a.lastName, b.lastName);
            } else {
                return sortByString(b.lastName, a.lastName);
            }
        });
        return retVal;
    }

    @computed get getAllowedUsers(): User[] {
        const filterBySearchString = (userlist: User): boolean => {
            if (isEmptyOrWhitespace(this.filterSearchString)) {
                return true;
            }

            const searchStringUpperCase = this.filterSearchString.toUpperCase();

            return userlist.firstName.toUpperCase().includes(searchStringUpperCase) || userlist.lastName.toUpperCase().includes(searchStringUpperCase);
        };

        const filterByRole = (user: User): boolean => {
            if (this.filterRoleFilter.length === this.roles.length) {
                return true;
            }

            return this.filterRoleFilter.indexOf(user.roleIds) > -1;
        };

        /* let retVal = this.users.slice().filter((a: User) => a.isDeleted === false);

        retVal = retVal.filter((a: User) => a.email.toLocaleLowerCase() !== "admin@shoothill.com");

        retVal.sort((a: UserDTO, b: UserDTO) => {
            if (this.ascOrder) {
                return sortByString(a.lastName, b.lastName);
            } else {
                return sortByString(b.lastName, a.lastName);
            }
        });
        return retVal; */

        return this.users
            .slice()
            .filter((a) => a.isDeleted === false)
            .filter((a) => filterByRole(a))
            .filter((a) => filterBySearchString(a));
    }

    constructor() {
        super(new User("0"));
        this.setDecorators(User);
    }

    @action
    public clear() {
        this.userCount = 0;
        //this.ascOrder = true;
        this.Valid = false;
        this.resetLoginAttemptsError = "";
        this.users.clear();
        this.roles.clear();

        // SINCE USING INSTANCE. DO NOT RESET THESE !!!!!!
        /*         this.rolesFilter.clear();
        this.filterRoleFilter.clear();
        this.firstTimeLoad = true; */
    }

    /*     public setUser(user: User, newUser: boolean) {
        this.setValue("id", newUser ? "" : user.id);
        this.setValue("firstName", newUser ? "" : user.firstName);
        this.setValue("lastName", newUser ? "" : user.lastName);
        this.setValue("email", newUser ? "" : user.email);
        this.setValue("roles", newUser ? "" : user.roles);
        this.setValue("roleIds", newUser ? "" : user.roleIds);
        this.setValue("rowVersion", newUser ? "" : user.rowVersion);
        this.setValue("isDeleted", newUser ? false : user.isDeleted);
        this.setValue("userRoles", newUser ? [] : user.userRoles);
        this.setValue("lastLoggedIn", newUser ? "" : user.lastLoggedIn);
    } */

    public get(fieldName: any): any {
        return this.getValue(fieldName);
    }

    @action
    setRoleId(value: string) {
        const roles = this.roleStore.getRoles;

        const userRoles: Role[] = roles.filter((a: Role) => a.id === value);
        const newRoles: string = userRoles.map((a) => a.id).join(",");
        this.set("roleIds", value);
        this.set("roles", newRoles);
        this.model.userRoles.replace(userRoles);
    }

    @action
    public set(fieldName: any, value: string | number | boolean | Date) {
        this.setValue(fieldName, value as string);
    }

    public isFieldValid(fieldName: keyof FieldType<User>, value: any): boolean {
        let { isValid, errorMessage } = this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    @action
    public setUsers(users: UserDTO[]) {
        this.users.clear();
        users.forEach((item, index) => {
            let domainModel = this.users.find((dm: UserDTO) => dm.id === item.id);

            if (!domainModel) {
                domainModel = new User("0");

                domainModel.fromDto(item);
                this.users.push(domainModel);
            }
        });
        // load roles into drop down
        this.setRoles();
    }

    @action
    public async loadUsersAsync(): Promise<ApiResult<UserDTO[]>> {
        const includeGenericDeleted: GenericIncludeDeleted = {
            includeDeleted: true,
        };
        const apiResult = await this.Post<UserDTO[]>(Server.Api.User.getAll, includeGenericDeleted);

        if (apiResult.wasSuccessful) {
            MobX.runInAction(() => {
                this.setUsers(apiResult.payload);
            });
        }
        return apiResult;
    }

    public getEmail = (id: string): string => {
        let retVal: string | undefined = "";

        const forgetfulUser: User | undefined = this.users.find((a) => a.id === id);

        if (forgetfulUser !== undefined) {
            retVal = forgetfulUser.email;
        }

        return retVal;
    };

    public async forgotPassword(email: string): Promise<ApiResult<ForgotPasswordModelDTO>> {
        const request: ForgotPasswordRequest = {
            emailAddress: email,
        };
        const apiResult = await this.Post<ForgotPasswordModelDTO>(Server.Api.Account.ForgotPassword, request);
        MobX.runInAction(() => {
            if (apiResult.wasSuccessful) {
                // Not sure we need to do anything.
            }
        });
        return apiResult;
    }

    public deleteUser = async (deletedUserId: string): Promise<ApiResult<UserDTO[]>> => {
        const request: DeleteUserRequest = {
            id: deletedUserId,
        };
        const apiResult = await this.Post<UserDTO[]>(Server.Api.User.deleteUser, request);

        if (apiResult.wasSuccessful) {
            MobX.runInAction(() => {
                this.setUsers(apiResult.payload);
            });
        }
        return apiResult;
    };

    public getUser = (id: string) => {
        if (id) {
            return this.users.find((u) => u.id === id);
        } else {
            return new User("0");
        }
    };

    @computed get getRolesList(): MobX.IObservableArray<Role> {
        return this.roleStore.getRoles;
    }

    @computed get getUserCount(): number {
        return this.userCount;
    }

    @computed get getIsLoadingData(): boolean {
        return this.userStore.getIsLoadingData;
    }

    @action
    public setOrderAsc() {
        this.ascOrder = !this.ascOrder;
    }

    @computed get getOrderAsc(): boolean {
        return this.ascOrder;
    }

    // #region Role Filter
    @action
    public setRoles = (): void => {
        let rolesList: DropDownListItem[] = [];
        const userAndRelatedViewModel = new UserDetailsViewModel();
        let promise: Promise<ApiResult<UserRelatedDTO>> = userAndRelatedViewModel.loadRelatedAsync();
        promise.then((response) => {
            MobX.runInAction(() => {
                if (response.wasSuccessful) {
                    const roles = response.payload.roles;

                    for (let r: number = 0; r < roles.length; r++) {
                        let role: DropDownListItem = {
                            displayName: roles[r].name,
                            id: roles[r].id,
                            enumId: 0,
                            className: "",
                            ordinal: 0,
                        };
                        rolesList.push(role);
                    }

                    for (let f: number = 0; f < rolesList.length; f++) {
                        const role: DropDownListItem | undefined = rolesList.find((a) => a.id === rolesList[f].id);
                        this.roles.push(rolesList[f]);
                        if (this.firstTimeLoad === true) {
                            this.rolesFilter.push(rolesList[f].id);
                            this.filterRoleFilter.push(rolesList[f].id);
                        }
                    }

                    this.firstTimeLoad = false;
                }
            });
        });
    };

    @computed
    get getRolesKVPair(): KeyValuePair[] {
        const retVal: KeyValuePair[] = [];
        const rolesList = this.roleStore.getRoles;

        for (let i: number = 0; i < this.roles.length; i++) {
            retVal.push({
                key: this.roles[i].displayName,
                value: this.roles[i].id,
            });
        }
        return retVal;
    }

    public getRoleFilter = () => {
        return computed(() => this.rolesFilter);
    };

    @action
    public setRoleFilter = (values: string[]) => {
        this.rolesFilter.replace(values);
        this.setFilterRole(values);
    };

    private setFilterRole = debounce(
        action((values: string[]) => {
            this.filterRoleFilter.replace(values);
        }),
        DEBOUNCE_VALUE_MS,
    );
    // #endregion Role Filter

    // #region Project Sorting
    @observable public sortColumnId: number = 0;
    @observable public sortDirection: any = "asc";

    @action
    public setOrderChange = (columnId: number, direction: any) => {
        this.sortColumnId = columnId;
        this.sortDirection = direction;
    };
    // #endregion Project Sorting

    // #region Paging
    @observable public pageSize: number = 50;
    @observable public currentPage: number = 0;

    @action
    public setRowsPerPage(rows: number) {
        this.pageSize = rows;
    }

    @action
    public setPage = (page: number) => {
        this.currentPage = page;
    };
    // #endregion Paging

    // #region Search String Filter
    @observable public searchString: string = "";
    @observable public filterSearchString: string = "";

    public getSearchString = () => {
        return computed(() => this.searchString);
    };

    @action
    public setSearchString = (value: string) => {
        this.searchString = value;
        this.setFilterSearchString(value);
    };

    private setFilterSearchString = debounce(
        action((value: string) => {
            this.filterSearchString = value;
        }),
        DEBOUNCE_VALUE_MS,
    );

    // #endregion Search String Filter
}
