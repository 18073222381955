import { DropDownListItem } from "Custom/Models/API/DropDownListItem";
import styled from "styled-components";

export const UnitListTooltipContainer: any = styled.div`
    .control-clipboardbutton {
        img {
            padding-right: 10px;
        }
    }

    .company-tooltip,
    .__react_component_tooltip {
        padding: 0 !important;
    }

    text-align: left;
    font: normal normal normal 10px/14px Mont;
    line-height: 14px;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;

    .tooltip-company {
        font-size: 10px;
    }

    .place-top {
        margin-top: 0px !important;
    }

    .section1 {
        position: relative;
        display: inline-block;
        min-width: 100%;

        .data {
            margin: 5px 10px !important;
            .section1-companyname {
                /*      background-color: green; */
            }
            .section1-contact {
                /*  background-color: orange; */
            }

            .section1-phone {
                /*    background-color: red; */
            }
        }
        background-color: #337ab7;
    }

    .section2 {
        padding: 5px 10px !important;
        border-bottom: 1px white solid;
    }

    .section3 {
        padding: 5px 10px !important;
    }
`;
