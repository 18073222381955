import { observable } from "mobx";
import { ModelBase } from "Core/Models/ModelBase";

//Base class expects the model type and the DTO model type
//If you are not using a DTO just pass in undefined as the DTO
export class ClientProjectListItem extends ModelBase<ClientProjectListItem, ClientProjectListItemDTO> {
    @observable public id: string = "";
    @observable public number: string = "";
    @observable public name: string = "";
    @observable public isDeleted: boolean = false;
    @observable public status: number = 0; // Project Status Enum Id = 0 is 'unknown'.
    @observable public paymentStatus: number = 1;
    @observable public regionalSalesManager: string = "";
    @observable public propertyCount: number = 0;

    //fromDto is required but you can leave it blank
    fromDto(model: ClientProjectListItemDTO): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
    }

    //toDto is required but you can leave it blank
    toDto(model: ClientProjectListItem): void {}
}

export interface ClientProjectListItemDTO {
    id: string;
    number: string;
    name: string;
    isDeleted: boolean;
    status: number; // Project Status Enum Id.
    paymentStatus: number;
    regionalSalesManager: string;
    propertyCount: number;
}
