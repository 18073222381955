import * as React from "react";
import { ClientViewModel } from "./ClientViewModel";
import { ClientBox, ClientAddressBox, ClientContactBox } from "./ClientAddEdit.style";
import { RoofcareInput } from "Custom/StylesAppSpecific/Controls/RoofcareInput";
import { ClientAddress } from "Custom/Components/Address/ClientAddress";
import { AddressModel } from "Custom/Models/Domain/Address/AddressModel";
import { ClientContacts } from "./ClientContacts";
import { useObserver } from "mobx-react-lite";
import { AdminAddEditBox } from "Custom/StylesAppSpecific/AdminStyling";

interface IProps {
    viewModel: ClientViewModel;
}

export const ClientAddEditForm: React.FC<IProps> = ({ viewModel }) => {
    const setAddress = (address: AddressModel): void => {
        viewModel.setClientAddress(address);
    };

    return useObserver(() => (
        <>
            <AdminAddEditBox>
                <ClientBox>
                    <RoofcareInput<ClientViewModel>
                        type="text"
                        label="End user name"
                        validateOnBlur={true}
                        viewModel={viewModel}
                        fieldName="client.clientName"
                        shrink={true}
                        maxLength={128}
                    />
                </ClientBox>
                <ClientAddressBox>
                    <ClientAddress viewModel={viewModel} title="End user Address" setAddress={setAddress} errorMessage={""} />
                </ClientAddressBox>
            </AdminAddEditBox>
            <ClientContactBox style={{ marginTop: "15px" }}>
                <ClientContacts clientViewModel={viewModel} />
            </ClientContactBox>
        </>
    ));
};
