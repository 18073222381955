import styled from "styled-components";
import { Checkbox } from "@material-ui/core";

export const PropertyDetailMapContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    position: relative;
    width: 100%;
    padding-left: 50vw;

    div.section1 {
        position: absolute;
        text-align: right;
        display: flex;
        flex-direction: column;
        align-items: middle;
        flex-basis: 50%;
        bottom: 0;
        left: 0;
        top: 0;
        width: 50vw;

        section {
            border: none;
            height: 100%;
        }
    }

    div.section2 {
        .MuiToolbar-root {
            flex-direction: column;
            align-items: flex-start;
            padding: 15px 20px;
            //            background-color: ${(props) => props.theme.app.colors.grey.background};
            background-color: #3b3c3b;
            color: white;
            border-bottom: 1px solid ${(props) => props.theme.app.colors.grey.border};

            h6 {
                font-weight: normal;
                padding-bottom: 6px;
            }

            .MuiTextField-root {
                padding-left: 0;

                .MuiInput-formControl {
                    height: 34px;
                    width: 100% !important;

                    .MuiInputAdornment-root.MuiInputAdornment-positionEnd button {
                        padding-right: 0;
                    }
                }
            }
        }

        .MuiTableHead-root {
            background-color: ${(props) => props.theme.app.colors.grey.background};

            th {
                padding: 8px 20px;
                background-color: transparent;

                &:last-of-type {
                    padding-right: 0;
                }
            }
        }

        .MuiTableBody-root {
            td {
                padding: 8px 20px;

                &:last-of-type {
                    padding-right: 0;
                }

                .MuiCheckbox-root {
                    margin: -9px;
                }
            }
        }

        .MuiTableFooter-root {
            display: none !important;
        }

        .device-status {
            /*             display: flex;
            flex: 1 0 1;
            width: 183px;
            padding: 3.5px 0;
            align-content: middle;
            position: relative; */
            /*             &.device_unknown {
                color: white;
                background-color: ${(props) => props.theme.app.colors.deviceWarning.unknown};
            } */

            /* &.device_amberwarning {
                color: white;
                background-color: ${(props) => props.theme.app.colors.deviceWarning.amber};
            }

            &.device_redwarning {
                color: white;
                background-color: ${(props) => props.theme.app.colors.deviceWarning.red};
            }

            &.device_floodwarning {
                color: white;
                background-color: ${(props) => props.theme.app.colors.deviceWarning.flood};
            }

            &.device_greenwarning {
                color: white;
                background-color: ${(props) => props.theme.app.colors.deviceWarning.green};
            } */

            /*             > div {
                margin-left: auto;
                margin-right: auto;
                position: relative;
                font-size: 12px;
                display: flex;
                align-items: center;
            } */
        }
    }
`;

export const ShowingOnMapContainer = styled.div`
    margin: 5px 6px;
    background-color: white;
    box-shadow: 0px 0px 3px #00000029;
    padding: 15px;
    font-family: "Mont-Regular", "Montserrat", "Roboto Condensed", "Helvetica", "Arial", "sans-serif";
    text-align: left;
    border-radius: 5px;

    span {
        font-weight: bold;
        font-size: 1.5em;
        vertical-align: middle;

        &.label {
            font-family: Arial, sans-serif;
            font-size: 1.166666em;
            font-weight: normal;
        }
    }
`;

interface IColouredCheckboxProps {
    customcolour: any;
}

export const ColouredCheckbox = styled(Checkbox)<IColouredCheckboxProps>`
    margin: -9px -4px -9px 11px;

    svg {
        background-color: white !important;

        > path {
            fill: ${(props) => props.customcolour} !important;
        }
    }

    &.Mui-checked {
        color: ${(props) => props.customcolour} !important;

        svg {
            border: none !important;
        }
    }
`;
