import styled from "styled-components";
import { Box } from "@material-ui/core";
import SwitchLogo from "Custom/Content/green-redSwitch.svg";

export const DeviceCommandWrapper: any = styled(Box)`
    display: flex;
    flex: 1 0 0;
    flex-direction: column;
`;

export const DeviceAlertAdd: any = styled(Box)`
    right: 30px;
    margin-top: -35px;
    position: absolute;
    z-index: 100;
    display: flex;
    flex-direction: row;
`;

export const DeviceCommandSectionBox: any = styled(Box)`
    display: flex;
    flex: 1 0 0;
    flex-direction: row;
    padding: 15px 15px;
    width: 100%;
    min-width: 320px;
    min-height: 65px;
    flex: 1;
    margin-bottom: 15px;
    align-content: flex-start;
    align-items: center;
    justify-content: center;
    background-color: ${(props) => props.theme.app.colors.section.main};

    border-bottom: 3px dashed #b2b2b2;

    .nodeleteallowed {
        opacity: 0.5;
    }

    .nodeleteallowed::hover {
        cursor: default;
    }

    .section-title {
        display: flex;
        flex: 1;
        max-width: 300px;
        padding: 15px 30px;
    }

    .column-title {
        display: flex;
        margin-top: -5px;
        align-items: center;
        justify-content: center;

        &.freq {
            align-items: left;
            justify-content: start;
        }
    }

    .success {
        color: ${(props) => props.theme.app.colors.success};
    }

    .error {
        color: ${(props) => props.theme.app.colors.red.dark};
    }

    .section-options-column {
        display: flex;
        flex: 1;
        width: 100%;
        padding: 15px;
        flex-direction: row;
    }

    .gridcommand {
        max-width: 150px !important;
    }

    .section-options-row {
        display: flex;
        flex: 1;
        height: 100%;
        padding: 15px 0;
        flex-direction: row;

        .section-options-option {
            display: flex;
            flex: 0;
            width: 325px;
            padding: 0 15px;

            input {
                padding: 0 15px;
                min-width: 60px !important;
                width: 60px !important;
            }

            .form-control {
                min-width: 70px !important;
                width: 70px !important;
            }
        }

        .section-options-value {
            display: flex;
            flex-direction: column;
            flex: 0;
            padding: 0 15px;
            max-width: 150px;
            //background-color: olive;

            input {
                padding: 0 15px;
            }

            .section-options-valueerror {
                color: ${(props) => props.theme.app.colors.red.dark};
            }

            .section-options-valueunsent {
                margin-top: 10px;
                color: ${(props) => props.theme.app.colors.success};
            }

            .form-control {
                max-width: 90px !important;
                width: 90px !important;
            }
            .MuiInputLabel-formControl {
                text-align: left;
                font: normal normal bold 14px "Mont-Bold";
                letter-spacing: 0px;
                color: ${(props) => props.theme.app.colors.black.light};
                opacity: 1;
                height: 19px;
                margin-bottom: 5px;
            }
            .Mui-disabled {
                opacity: 0.5;
                /* color: ${(props) => props.theme.app.colors.grey.background}; */
            }

            input {
                padding-left: 10px;
                padding-right: 10px;
                background-color: #ffffff !important;
                border: 1px solid ${(props) => props.theme.app.colors.grey.background};
                border-radius: 3px;
                opacity: 1;
                padding: 7px;
                text-align: left;
                font: normal normal normal 12px/26px "Mont-Regular";
                letter-spacing: 0px;
                color: #757474 !important;
                opacity: 1;
                font-weight: 300;
            }
        }
    }
`;

export const AlertSwitchOption: any = styled.div`
    width: 68px;
    background-repeat: no-repeat;
    height: 40px;

    // Green (On) should be to the right of the button not left No SH Number
    margin-left: -18px;
    margin-top: -8px;
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);

    &.notselected {
        background: url(${SwitchLogo}) 67px 0;
        // background-color: pink;
    }

    &.selected {
        background: url(${SwitchLogo}) 1px 0; // These make the image disappear no-repeat center center fixed
        // background-color: red;
    }

    &.hide {
        opacity: 0.3;
    }
`;

export const SectionSend: any = styled(Box)`
    display: flex;
    flex: 0;
    width: 94px;
`;

export const RowTitle: any = styled(Box)`
    display: flex;
    flex: 1 0 0;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    line-height: 36px;
`;

export const ValueBetween: any = styled(Box)`
    display: flex;
    flex: 1 1 0;
    flex-direction: row;
    justify-content: space-around;
    //background-color: pink;
`;
