export interface Address {
    buildingName: string;
    addressLine1: string;
    addressLine2: string;
    city: string;
    county: string;
    postcode: string;
}

export const DefaultAddress: Address = {
    buildingName: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    county: "",
    postcode: "",
};
