import { ApiResult } from "Core/Models";
import { ViewModelBase } from "Core/ViewModels/ViewModelBase";
// @ts-ignore
import { OpenStreetMapProvider } from "leaflet-geosearch";
import { SearchResult } from "leaflet-geosearch/dist/providers/provider";
import { RawResult } from "leaflet-geosearch/dist/providers/openStreetMapProvider";
import { OSMLocation } from "Custom/Utils/OSM";

export class OSMViewModel extends ViewModelBase<any> {
    private static _instance: OSMViewModel;

    provider = new OpenStreetMapProvider({
        params: {
            countrycodes: "gb", // uk
            addressdetails: 1, // include address parts
        },
    });

    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    constructor() {
        super({});
    }

    public isFieldValid(fieldName: "latitude" | "longitude", value: any): boolean {
        return true;
    }

    public getLocationForPostcode = async (postcode: string): Promise<OSMLocation> => {
        let retVal: OSMLocation = {
            latitude: 0,
            longitude: 0,
        };

        try {
            const resultControls: SearchResult<RawResult>[] = await this.provider.search({ query: postcode });

            if (resultControls !== undefined) {
                if (resultControls.length === 1) {
                    const item = resultControls[0];
                    let latitude: number = 0;
                    let longitude: number = 0;
                    if (item.raw.lat !== undefined || item.raw.lat !== null) {
                        latitude = parseFloat(item.raw.lat);
                    }

                    if (item.raw.lon !== undefined || item.raw.lon !== null) {
                        longitude = parseFloat(item.raw.lon);
                    }

                    retVal.latitude = latitude;
                    retVal.longitude = longitude;
                }
            }
        } catch (exception) {
            console.log(exception);
        }

        return retVal;
    };

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
