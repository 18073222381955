import { Box, Fade, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import { AddressModel } from "Custom/Models/Domain/Address/AddressModel";

// @ts-ignore
import { OpenStreetMapProvider } from "leaflet-geosearch";
import { Main, AddressLineBox, AddressResultBox, AddressRow } from "./Address.style";
import { SearchResult } from "leaflet-geosearch/dist/providers/provider";
import { RawResult } from "leaflet-geosearch/dist/providers/openStreetMapProvider";
import { RoofcareInput, RoofcareIndividualInput, RoofcareToggleTypography, RoofcareLabel } from "Custom/StylesAppSpecific/Controls/RoofcareInput";
import { ContractorViewModel } from "Custom/Views/ContractorSetup/ContractorViewModel";
import { autorun } from "Core/Base";
import { selectOSMAddress } from "Custom/Utils/OSM";
import { AcceptBtn } from "Custom/StylesAppSpecific/Controls/AcceptBtn";
import { AddressItem } from "Custom/Models/API/Hopewiser";
import { generateID, isNullOrUndefined } from "Custom/Utils/utils";
import styled from "styled-components";

type AddressProps = {
    viewModel: ContractorViewModel;
    title: string;
    setAddress: (address: AddressModel) => void;
    errorMessage: string;
};

export const ContractorAddress: React.FunctionComponent<AddressProps> = (props: AddressProps) => {
    const [errorMessage, setErrorMessage] = useState("");
    const [inputValue, updateText] = useState("");
    const [results, updateResults] = useState<AddressItem[]>([]);
    const [isManual, setIsManual] = useState(true);
    const [showResults, setshowResults] = useState(false);

    const provider = new OpenStreetMapProvider({
        params: {
            countrycodes: "gb", // uk
            addressdetails: 1, // include address parts
        },
    });

    useEffect(() => {
        //useEffect only gets run on initial render
        updateText(props.viewModel.getAddressString);

        //This gets run when the page is exited
        return () => {};
    }, []);

    useEffect(() => {
        autorun(() => {
            setErrorMessage(props.errorMessage);
        });
    }, [props.errorMessage]);

    const onInputChange = async (e: any): Promise<void> => {
        updateText(e.target.value);
        props.viewModel.setSearchCriteria(e.target.value);
    };

    const toggleManualEntry = () => {
        setIsManual(!isManual);
        updateResults([]);
        updateText("");
    };

    const selectAddress = (item: AddressItem) => {
        if (isNullOrUndefined(item) === false) {
            if (item.itemText.startsWith("+")) {
                doSidSearch(item);
            } else {
                setshowResults(false);
                props.viewModel.getSelectedAddress(item.sid).then((result) => {
                    if (result.wasSuccessful) {
                        props.setAddress(props.viewModel.getModel.contractor.getAddress);
                    }
                });
            }
        }
    };

    const doSearch = () => {
        setshowResults(false);
        props.viewModel.addressSearch().then((result) => {
            if (result.wasSuccessful) {
                setshowResults(true);
                updateResults(props.viewModel.getSearchResults);
            }
        });
    };

    const doSidSearch = (sidItem: AddressItem) => {
        setshowResults(false);
        props.viewModel.addressSearch(sidItem).then((result) => {
            if (result.wasSuccessful) {
                setshowResults(true);
                updateResults(props.viewModel.getSearchResults);
            }
        });
    };

    return (
        <>
            <Box width="100%" style={{ padding: "20px 0px 0px" }} display="flex" flexDirection="column">
                <RoofcareLabel>{props.title}</RoofcareLabel>
                {errorMessage !== "" && (
                    <Typography variant="caption" style={{ color: "red" }}>
                        {errorMessage}
                    </Typography>
                )}
                {isManual == false && (
                    <>
                        <AddressRow>
                            <div className="searchaddressbox">
                                <RoofcareIndividualInput
                                    type="text"
                                    placeholder="search"
                                    value={inputValue}
                                    onChange={onInputChange}
                                    style={{ maxWidth: "640px", width: "100%" }}
                                />
                            </div>
                            <AcceptBtn onClick={doSearch} disabled={inputValue.length < 6}>
                                Find Address
                            </AcceptBtn>
                        </AddressRow>
                        {results.length > 0 && showResults && (
                            <AddressResultBox>
                                items found: {results.length}
                                {results.map((item: AddressItem, index: number) => {
                                    return (
                                        <AddressLineBox
                                            key={generateID()}
                                            onClick={() => {
                                                selectAddress(item);
                                            }}
                                        >
                                            {item.itemText}
                                        </AddressLineBox>
                                    );
                                })}
                            </AddressResultBox>
                        )}
                    </>
                )}
                {/*                         <Box onClick={toggleManualEntry}>
                            {isManual == false && <RoofcareToggleTypography>+ Add address manually</RoofcareToggleTypography>}
                            {isManual == true && <RoofcareToggleTypography>+ Add address autocomplete</RoofcareToggleTypography>}
                        </Box> */}
                {(isManual || props.viewModel.errorMessage !== "") && (
                    <>
                        <Box className="row">
                            <Box className="cell-left" maxWidth="640px">
                                <RoofcareInput<AddressModel>
                                    type="text"
                                    label="Address Line 1"
                                    validateOnBlur={true}
                                    viewModel={props.viewModel}
                                    fieldName="contractor.addressLine1"
                                    shrink={true}
                                    maxLength={128}
                                />
                            </Box>
                            <Box className="cell-right" maxWidth="640px">
                                <RoofcareInput<AddressModel>
                                    type="text"
                                    label="Address Line 2"
                                    validateOnBlur={true}
                                    viewModel={props.viewModel}
                                    fieldName="contractor.addressLine2"
                                    shrink={true}
                                    maxLength={128}
                                />
                            </Box>
                        </Box>

                        <Box className="row">
                            <Box className="cell-left" maxWidth="225px">
                                <RoofcareInput<AddressModel>
                                    type="text"
                                    label="City"
                                    validateOnBlur={true}
                                    viewModel={props.viewModel}
                                    fieldName="contractor.city"
                                    shrink={true}
                                    maxLength={128}
                                />
                            </Box>
                            <Box className="cell-right" maxWidth="220px">
                                <RoofcareInput<AddressModel>
                                    type="text"
                                    label="County"
                                    validateOnBlur={true}
                                    viewModel={props.viewModel}
                                    fieldName="contractor.county"
                                    shrink={true}
                                    maxLength={128}
                                />
                            </Box>
                            <Box className="cell-right" maxWidth="200px">
                                <RoofcareInput<AddressModel>
                                    type="text"
                                    label="Postcode"
                                    validateOnBlur={true}
                                    viewModel={props.viewModel}
                                    fieldName="contractor.postcode"
                                    shrink={true}
                                    maxLength={128}
                                />
                            </Box>
                        </Box>
                    </>
                )}
            </Box>
        </>
    );
};
