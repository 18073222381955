import { observable } from "mobx";
import { ModelBase } from "Core/Models/ModelBase";
import { IsNotEmpty } from "class-validator";

//Base class expects the model type and the DTO model type
//If you are not using a DTO just pass in undefined as the DTO
export class AdminTabPageModel extends ModelBase<AdminTabPageModel, AdminTabPageModelDTO> {
    @observable
    @IsNotEmpty({ message: "You must give me your name!!" })
    public name: string = "";

    public id: string = "";

    //fromDto is required but you can leave it blank
    fromDto(model: AdminTabPageModelDTO): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
    }

    //toDto is required but you can leave it blank
    toDto(model: AdminTabPageModel): void {}
}

export type AdminTabPageModelDTO = {
    id: string;
    name: string;
};
