import { ViewModelBase } from "Core/ViewModels/ViewModelBase";
import { ClientListItemModel, ClientListItemModelDTO } from "Custom/Models/Domain/Clients/ClientListModel";
import { FieldType } from "Core/Utils/Utils";
import { action, computed, IObservableArray, observable, runInAction } from "mobx";
import { ApiResult } from "Core/Models";
import { GenericIncludeDeleted } from "Custom/Models";
import { Server } from "Custom/Globals/AppUrls";
import { DeleteRequest } from "../User/DeleteUserRequest";

//extend viewmodel base and passing your model as the generic type
export class ClientListViewModel extends ViewModelBase<ClientListItemModel> {
    //Singleton instance of class
    private static _instance: ClientListViewModel;
    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    @observable public errorMessage: string = "";

    public clients: IObservableArray<ClientListItemModel> = observable<ClientListItemModel>([]);

    @computed get getClients(): any[] {
        return this.clients.slice();
    }

    @computed get getAllowedClients(): any[] {
        return this.clients.slice().filter((a) => a.isDeleted === false);
    }

    constructor() {
        //Pass in a new instance of your model
        //By passing in true as the second parameter, we make this model undoable which means we can use save and reset options on the model
        //If you make a change to the model you need to persist it with a saveModel() call
        //If you make changes to your model you can revert it back by calling resetModel()
        super(new ClientListItemModel(), false);
        //EN - Haven't figured out how to make this call work from the base model yet
        //This is only needed if you make use of the validation decorators
        this.setDecorators(ClientListItemModel);
    }

    //This must be present in your viewmodel. Just return true if you dont need to validate anything.
    //keyof BlankModel & string lets you add type checking to the fieldName
    //I am using the validator package to make checking easier https://www.npmjs.com/package/validator
    public isFieldValid(fieldName: keyof FieldType<ClientListItemModel>): boolean {
        const { isValid, errorMessage } = this.validateDecorators(fieldName);

        //You need to this two properties after validation
        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    @action
    public setClients(clients: ClientListItemModelDTO[]) {
        this.clients.clear();
        clients.forEach((item, index) => {
            let domainModel = this.clients.find((dm: ClientListItemModelDTO) => dm.id === item.id);

            if (!domainModel) {
                domainModel = new ClientListItemModel();

                domainModel.fromDto(item);
                this.clients.push(domainModel);
            }
        });
    }

    @action
    public async loadClientListAsync(): Promise<ApiResult<ClientListItemModelDTO[]>> {
        const includeGenericDeleted: GenericIncludeDeleted = {
            includeDeleted: false,
        };
        const apiResult = await this.Post<ClientListItemModelDTO[]>(Server.Api.Client.getClientForList, includeGenericDeleted);

        if (apiResult.wasSuccessful) {
            runInAction(() => {
                this.setClients(apiResult.payload);
            });
        }
        return apiResult;
    }

    public deleteClient = async (deletedUserId: string): Promise<ApiResult<ClientListItemModelDTO[]>> => {
        const request: DeleteRequest = {
            id: deletedUserId,
        };
        const apiResult = await this.Post<ClientListItemModelDTO[]>(Server.Api.Client.deleteClient, request);

        if (apiResult.wasSuccessful) {
            runInAction(() => {
                this.setClients(apiResult.payload);
            });
        }
        return apiResult;
    };
}
