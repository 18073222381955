import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import { useRouter } from "Core/Utils";
import { ProjectPaymentStatusAddEditDetailViewModel } from "./ProjectPaymentStatusAddEditDetailViewModel";
import { BackBox, BackToList, BackToListText } from "Custom/StylesAppSpecific/Controls/BackToList";
import { AppUrls } from "Custom/Globals";
import { ProjectPaymentStatusAddEditDetailModel } from "./ProjectPaymentStatusAddEditDetailModel";
import { RoofcareInput, RoofcareLabel } from "Custom/StylesAppSpecific/Controls/RoofcareInput";
import { AdminDetailContainer, BackToListWrapper, ButtonSectionBox, DetailWrapper, NameSectionBox } from "Custom/Views/Admin/Condition/Condition.style";
import { SuccessBtn } from "Custom/StylesAppSpecific/Controls/SuccessBtn";
import { CancelBtn } from "Custom/StylesAppSpecific/Controls/CancelBtn";
import { Loader } from "Core/Components";
import { useLoadCall } from "Custom/Utils/useLoadCall";
import { useObserver } from "mobx-react-lite";
import * as Defaults from "Custom/StylesAppSpecific/TableOptions";
import { ControlLabel } from "Custom/Components/ControlLabel/ControlLabel";
import { Box } from "@material-ui/core";
import { ColourPicker } from "../Common/ColourPicker";

export const ProjectPaymentStatusAddEditDetail: React.FunctionComponent = () => {
    const [viewModel] = useState(() => new ProjectPaymentStatusAddEditDetailViewModel());
    const router = useRouter<{ id?: string }>();
    const { history, match } = useRouter();
    let { id } = match.params as any;
    const [canSave, setCanSave] = useState(false);
    const [openCP, setOpenCP] = React.useState(false);

    const useStyles = makeStyles((theme) => ({
        colourBlockWrapper: {
            //paddingRight: "15px",
        },
        colourBlock: {
            verticalAlign: "middle",
            backgroundColor: viewModel.getBackgroundColour,
            color: viewModel.getTextColour,
            padding: "5px 16px",
            fontSize: "14px",
            textAlign: "center",
            marginTop: "5px",
            minWidth: "183px",
            maxWidth: "183px",
            borderRadius: "3px",
            height: "29px",
            cursor: "default",
            "&:hover": {
                cursor: "pointer",
            },
        },
    }));

    const classes = useStyles();

    //useEffect below only gets run on initial render
    useEffect(() => {
        // *optional* passing the router into the viewmodel so we can navigate from there
        //console.log("First render");
        //This gets run when the page is exited
        return () => {
            // console.log("Unmounting");
        };
    }, []);

    useEffect(() => {
        checkData(); // hate that this doesn't piggin work.
    }, [viewModel.getName, viewModel.getOrdinal]);

    const notFound = useLoadCall(
        async () => {
            if (id === null || id < 1) {
                return true;
            } else {
                let promise = await viewModel.loadItemAsync(id);

                if (promise.wasSuccessful === true) {
                    setCanSave(true);
                }

                return !!viewModel.getValue("id");
            }
        },
        { customCheck: () => id !== null || id < 1 },
    );

    const handleBack = () => {
        router.history.push(AppUrls.Client.Main.Admin.Root + "#projectpaymentstatus");
    };

    const validateAndSave = (exit: boolean) => {
        //validate model and all sub objects
        if (viewModel.isModelValid() === true) {
            let promise = viewModel.upsertProjectPaymentStatus();
            promise.then((result: any) => {
                if (result.wasSuccessful === true) {
                    if (exit === true) {
                        handleBack();
                    }
                }
            });
        }
    };

    const onSave = (exit: boolean): void => {
        validateAndSave(exit);
    };

    const checkData = () => {
        let retVal: boolean = false;
        if (viewModel.getName.length > 0 && viewModel.getOrdinal > 0) {
            retVal = true;
        }

        if (retVal !== canSave) {
            setCanSave(retVal);
        }
    };

    const onUpdateColours = (backgroundColour: string, textColour: string) => {
        viewModel.setValue("colorHex", backgroundColour);
        viewModel.setValue("textColorHex", textColour);
        setOpenCP(false);
    };

    const onCloseColours = () => {
        setOpenCP(false);
    };

    const openColourPicker = (e: any) => {
        setOpenCP(true);
    };

    function renderPage() {
        if (viewModel.IsLoading || notFound) return <Loader />;

        return (
            <>
                <AdminDetailContainer>
                    <ColourPicker
                        text={viewModel.getNameForColourPicker}
                        openPicker={openCP}
                        backgroundColour={viewModel.getBackgroundColour}
                        textColour={viewModel.getTextColour}
                        onUpdate={onUpdateColours}
                        onClose={onCloseColours}
                    />
                    <DetailWrapper>
                        <BackToListWrapper style={{ paddingTop: "15px" }}>
                            <BackToList onClick={handleBack} tabIndex={0}>
                                <BackBox>
                                    <NavigateBeforeIcon fontSize="large" />
                                </BackBox>
                                <BackToListText>Back to Payment Statuses</BackToListText>
                            </BackToList>
                        </BackToListWrapper>
                        <NameSectionBox>
                            <RoofcareInput<ProjectPaymentStatusAddEditDetailModel>
                                type="text"
                                label="Name:"
                                validateOnBlur={true}
                                viewModel={viewModel}
                                fieldName="statusName"
                                shrink={true}
                                maxLength={50}
                                inputProps={{ onBlur: checkData() }}
                            />
                        </NameSectionBox>
                        <NameSectionBox>
                            <div className="admin-color-section">
                                <RoofcareLabel className="admin-color-section-label">Display Colours:</RoofcareLabel>
                                <div className={classes.colourBlockWrapper}>
                                    <div onClick={openColourPicker} className={classes.colourBlock}>
                                        {viewModel.getNameForColourPicker}
                                    </div>
                                </div>
                            </div>
                        </NameSectionBox>
                        <NameSectionBox>
                            <Box className="numberbox" maxWidth="200px">
                                <ControlLabel label="Drop Down Position:" htmlFor="dropdownposition">
                                    <RoofcareInput
                                        id="dropdownposition"
                                        className="form-control"
                                        type="number"
                                        fullWidth={true}
                                        viewModel={viewModel}
                                        fieldName="ordinal"
                                        inputProps={{
                                            onBlur: checkData(),
                                            placeholder: "Range 0-9999",
                                            maxLength: 4,
                                            min: 1,
                                            max: 9999,
                                        }}
                                    />
                                </ControlLabel>
                            </Box>
                        </NameSectionBox>

                        <ButtonSectionBox>
                            <SuccessBtn disabled={!canSave} onClick={() => onSave(false)}>
                                Save
                            </SuccessBtn>
                            <SuccessBtn disabled={!canSave} onClick={() => onSave(true)} style={{ marginLeft: "15px" }}>
                                Save &#x26; exit
                            </SuccessBtn>
                            <CancelBtn onClick={handleBack}>Cancel</CancelBtn>
                        </ButtonSectionBox>
                    </DetailWrapper>
                </AdminDetailContainer>
            </>
        );
    }

    return useObserver(() => renderPage());
};
