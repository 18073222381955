import { Avatar, Box, Button, Fade, FormControl, Paper, Typography } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";

import { AuditInstallationViewModel } from "./AuditInstallationViewModel";
import { DataJsonProps } from "../DataJsonProps";

export const AuditInstallationSection: React.FunctionComponent<DataJsonProps> = ({ data }) => {
    const [viewModel] = useState(() => new AuditInstallationViewModel());

    React.useEffect(() => {
        viewModel.setAuditItem(data);
    }, [data]);

    //useEffect below only gets run on initial render
    useEffect(() => {
        // *optional* passing the router into the viewmodel so we can navigate from there
        //console.log("First render");
        //This gets run when the page is exited
        return () => {
            //console.log("Unmounting");
        };
    }, []);

    return (
        <Fade in timeout={1000}>
            <div className="column">
                <div>TODO </div>
            </div>
        </Fade>
    );
};
