import React from "react";
import { styled } from "../../Core/Base";

const spriteWidth = "132px";
const spriteHeight = "132px";
const wellBackground = "#f3f3f3";
const textColor = "#595959";
const goodBg = "#f8f8f8";
const bestBg = "#f0f0f0";

const Main = styled.div`
    padding-top: 70px;

    body.unsupported {
        background: #f2f2f3;
        width: 100%;
    }
    .unsupported .container2 {
        width: 100%;
        min-width: 300px;
    }
    .unsupported-browser {
        background: #fff;
        margin: 35px auto;
        width: 100%;
        box-shadow: 0 0 5px #cacace;
        position: relative;
        padding: 20px 30px;
        text-align: left;
    }
    .unsupported-browser h1 {
        font-size: 2em;
        font-weight: 0;
    }
    .unsupported-browser h2 {
        border: 0;
    }
    .unsupported-browser li {
        margin-bottom: 5px;
    }
    .unsupported-browser li a {
        color: inherit;
    }
    .unsupported-browser li a:hover {
        color: #007bc3;
    }
    .unsupported hr {
        margin-top: 50px;
    }
    .unsupported-message {
        font-size: 0.9em;
        margin-top: 40px;
        margin-bottom: 30px;
    }
    .unsupported-message li {
        margin-bottom: 5px;
    }
`;

export const UnsupportedBrowser: React.FC = () => {
    return (
        <Main className="container2 text-center">
            <div className="grid-tools FullSite">
                <div className="container2">
                    <div className="container-wrapper">
                        <div className="container-full-column">
                            <div className="unsupported-browser">
                                <h1>Please upgrade your browser in order to use the Roofcare portal</h1>
                                <br />
                                <p>
                                    We built the Roofcare portal using the latest technology which improves the look of the site, increases the speed of the site, and gives you a
                                    better experience with new features and functions. Unfortunately, your browser does not support these technologies.
                                </p>
                                <br />
                                <h2>Please download one of these free and up-to-date browsers:</h2>
                                <br />
                                <ul>
                                    <li>
                                        <a href="https://www.mozilla.com/firefox/" target="_blank">
                                            Firefox
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.google.com/chrome/browser/desktop/" target="_blank">
                                            Chrome
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.microsoft.com/en-us/windows/microsoft-edge" target="_blank">
                                            Microsoft Edge
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://windows.microsoft.com/en-us/internet-explorer/download-ie" target="_blank">
                                            Internet Explorer
                                        </a>
                                    </li>
                                </ul>
                                <hr />
                                <div className="unsupported-message">
                                    <h3>I can't update my browser</h3>
                                    <br />
                                    <ul>
                                        <li>Ask your system administrator to update your browser if you cannot install updates yourself.</li>
                                        <li>
                                            If you can't change your browser because of compatibility issues, think about installing a second browser for utilization of this site
                                            and keep the old one for compatibility.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Main>
    );
};
