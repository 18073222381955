import styled from "styled-components";

export const PropertyDetailDetailContainer = styled.div`
    padding: 25px 30px;
    background: transparent linear-gradient(180deg, #565758 0%, #373a40 100%) 0% 0% no-repeat padding-box;
    width: 100%;

    > h1 {
        margin-bottom: 5px;
    }

    > h2 {
        font-weight: normal;
        margin-bottom: 30px;

        > span {
            font-weight: bold;
        }
    }

    > h3 {
        margin-bottom: 15px;
    }

    div.device {
        display: flex;
        background-color: ${(props) => props.theme.app.colors.grey.back};
        padding: 25px 30px;
        margin-bottom: 5px;

        > div.device-text {
            flex-grow: 1;
            padding-right: 65px;

            > div.status-readings {
                display: flex;
                justify-content: space-between;
                font-size: 1.166666em;

                > div > span.devicelist-title {
                    font-weight: bold;
                }
            }

            > div.device-row {
                padding-bottom: 4px;

                > div.device-name {
                    font-size: 1.5em;
                    font-weight: bold;
                }
            }
        }

        .device-status {
            display: flex;
            flex: 1 0 1;
            width: 183px;
            margin: 5px 0;
            align-content: middle;
            position: relative;
            &.device_unknown {
                color: white;
                background-color: ${(props) => props.theme.app.colors.deviceWarning.unknown};
            }

            &.device_amberwarning {
                color: white;
                background-color: ${(props) => props.theme.app.colors.deviceWarning.amber};
            }

            &.device_redwarning {
                color: white;
                background-color: ${(props) => props.theme.app.colors.deviceWarning.red};
            }

            &.device_floodwarning {
                color: white;
                background-color: ${(props) => props.theme.app.colors.deviceWarning.flood};
            }

            &.device_greenwarning {
                color: white;
                background-color: ${(props) => props.theme.app.colors.deviceWarning.green};
            }

            > div {
                margin-left: auto;
                margin-right: auto;
                position: relative;
                font-size: 18px;
                display: flex;
                align-items: center;
            }
        }

        div.next-icon {
            display: flex;
            flex-direction: column;
            padding-left: 30px;

            > svg {
                flex-grow: 1;
                width: 3.792em;
                height: 3.792em;
                margin: -26px;
            }
        }
    }
`;

export const DeviceGridView: any = styled.ul`
    list-style: none;

    li {
        background-color: ${(props) => props.theme.app.colors.grey.back};
        margin-bottom: 5px;
        display: flex;
        flex-direction: row;
        flex-grow: 1;
        padding-right: 25px;
        cursor: pointer;
    }

    li {
        .cell {
            padding: 25px 30px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            font-size: 1.166666em;
            align-self: center;
            max-width: 450px;
            width: 100%;

            .device-name {
                font-size: 1.5em;
                font-weight: bold;
            }

            .device-status {
                display: flex;
                flex: 1 0 1;
                width: 183px;
                margin: 5px 0;
                align-content: middle;
            }

            .device_unknown,
            .device_amberwarning,
            .device_redwarning,
            .device_floodwarning,
            .device_greenwarning {
                color: white;
            }

            .header,
            .data {
                display: flex;
                flex: 1 0 1;
                flex-direction: row;
                justify-content: space-between;
            }
        }

        .status-readings {
            max-width: 255px;
            width: 100%;
        }

        .next-icon {
            max-width: 30px;
            width: 100%;
            display: flex;
            flex-direction: column;
            padding-left: 0px;

            > svg {
                flex-grow: 1;
                width: 3.792em;
                height: 3.792em;
                margin: -26px;
            }
        }
    }
`;
