import styled from "styled-components";
import { BaseModalDialogPaper } from "Custom/Components/Modal/BaseModal.styles";

export const MovePropertyModalDialogPaper = styled(BaseModalDialogPaper)`
    min-width: 420px;
    background: transparent linear-gradient(180deg, #565758 0%, #373a40 100%) 0% 0% no-repeat padding-box;
    color: white;

    .MuiDialogContent-root {
        p {
            padding-bottom: 15px;
            font-size: 12px;
        }
    }
`;
