import * as React from "react";
import { BaseModal } from "Custom/Components/Modal/BaseModal";
import { AddressModel } from "Custom/Models/Domain/Address/AddressModel";
import { SuccessBtn } from "Custom/StylesAppSpecific/Controls/SuccessBtn";
import { CancelBtn } from "Custom/StylesAppSpecific/Controls/CancelBtn";
import { PropertyAddress } from "Custom/Components/Address/PropertyAddress";
import { PropertyModalDialogPaper } from "./PropertyAddModal.styles";
import { useState } from "react";
import { AddressViewModel } from "Custom/Components/Address/AddressViewModel";

interface IProps {
    address?: AddressModel;
    open?: boolean;
    onClose(): void;
    onChange(address: AddressModel): void;
    onAdd(address: AddressModel): void;
}

export const PropertyAddModal: React.FC<IProps> = ({ address, onChange, onClose, open, onAdd }) => {
    const [viewModel] = useState(() => new AddressViewModel());
    const [errorMessage, setErrorMessage] = useState("");

    const onAddressChange = (address: AddressModel) => {
        viewModel.setAddress(address);
        //onChange(address);
    };

    const onAddProperty = () => {
        setErrorMessage("");
        if (viewModel.isModelValid() === false) {
            setErrorMessage("Please fix the validation errors and re-submit.");
        } else {
            onChange(viewModel.model);
            onAdd(viewModel.model);
        }
    };

    React.useEffect(() => {
        if (address !== undefined) {
            viewModel.setAddress(address);
        }
    }, [address]);

    const title: string = address === undefined ? "Add new property" : "Edit property";
    const btnText: string = address === undefined ? "Add new property" : "Update property";

    return (
        <BaseModal
            title={title}
            onClose={onClose}
            open={open}
            PaperComponent={PropertyModalDialogPaper}
            actions={
                <>
                    <SuccessBtn onClick={onAddProperty} autoFocus>
                        {btnText}
                    </SuccessBtn>
                    <CancelBtn onClick={onClose}>Cancel</CancelBtn>
                </>
            }
        >
            <PropertyAddress setAddress={onAddressChange} viewModel={viewModel} errorMessage={errorMessage} generateLatLongs isOpen={open !== undefined ? open! : false} />
        </BaseModal>
    );
};
