import styled from "styled-components";

export const BackBtnContainer = styled.div`
    font-size: 10px;
    display: flex;
    align-items: center;
    user-select: none;
    cursor: pointer;
    outline: none;
    padding-bottom: 3px;

    .back-icon {
        margin-left: -10px;
        color: ${(props) => props.theme.app.colors.red.dark};
        font-size: 18px;
        font-weight: 700;
        font-family: "Mont-Bold", "Montserrat", "Roboto Condensed", "Helvetica", "Arial", "sans-serif";
        display: flex;
        margin-bottom: 1px;
    }
`;
