import React, { useContext } from "react";

import { Stores, StoresContext } from "../../Stores";
import { PrivateRoute } from "../../../Core/Utils/React";
import { LoginPage } from "Custom/Views/Account/LoginPage";
import { ResetPasswordPage } from "Custom/Views/Account/ResetPasswordPage";
import { ForgotPasswordPage } from "Custom/Views/Account/ForgotPasswordPage";
import { ForgotPasswordSuccessPage } from "Custom/Views/Account/ForgotPasswordSuccessPage";
import { Logout } from "Custom/Views/Account/Logout";
import { RouteWithLayout } from "Core/Components/RouteWithLayout";
import { Client } from "Custom/Globals/AppUrls";
import { LoginLayout } from "../Layouts/LoginLayout";
import * as RouterUtil from "Custom/Utils/routing";

export const AccountRoutes: React.FC = () => {
    const store = useContext<Stores>(StoresContext);
    return (
        <>
            <RouteWithLayout layout={LoginLayout} exact path={Client.Account.Login} component={LoginPage} />
            <PrivateRoute
                exact
                path={Client.Account.Logout}
                component={Logout}
                isAllowed={() => {
                    return store.domain.AccountStore.IsLoggedIn && RouterUtil.requireAnyLoggedIn(store);
                }}
            />
            <RouteWithLayout layout={LoginLayout} exact path={Client.Account.ForgotPassword} component={ForgotPasswordPage} />
            <RouteWithLayout layout={LoginLayout} exact path={Client.Account.ForgotPasswordSuccess} component={ForgotPasswordSuccessPage} />
            <RouteWithLayout layout={LoginLayout} exact path={Client.Account.ResetPassword} component={ResetPasswordPage} />
        </>
    );
};
