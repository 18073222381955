import { useContext } from "react";
import * as React from "react";

import { Stores, StoresContext } from "Custom/Stores";

import { PrivateRoute } from "Core/Utils";
import { Client } from "Custom/Globals/AppUrls";

import * as RouterUtil from "Custom/Utils/routing";

import { UnitsView } from "./UnitsView";
import { InstallationView } from "../Installations/InstallationView";

export const UnitRoutes: React.FC = () => {
    const store = useContext<Stores>(StoresContext);

    return (
        <>
            <PrivateRoute
                component={UnitsView}
                exact
                isAllowed={() => {
                    return store.domain.AccountStore.IsLoggedIn && RouterUtil.requireAdminLogin(store);
                }}
                path={Client.Main.Unit.Root}
            />
            <PrivateRoute
                component={InstallationView}
                exact
                isAllowed={() => {
                    return store.domain.AccountStore.IsLoggedIn && RouterUtil.requireAdminLogin(store);
                }}
                path={Client.Main.Unit.Installation}
            />
        </>
    );
};
