import React, { useContext } from "react";
import { Stores, StoresContext } from "../../Stores";
import { PrivateRoute } from "../../../Core/Utils/React";
import * as RouterUtil from "Custom/Utils/routing";
import { Client } from "Custom/Globals/AppUrls";
import { ActionsPage } from "./ActionsPage";

export const ActionRoutes: React.FC = () => {
    const store = useContext<Stores>(StoresContext);
    return (
        <>
            <PrivateRoute
                component={ActionsPage}
                exact
                isAllowed={() => {
                    return store.domain.AccountStore.IsLoggedIn && RouterUtil.requireAdminLogin(store);
                }}
                path={Client.Main.Action.Root}
            />
            {
                <PrivateRoute
                    component={ActionsPage}
                    exact
                    isAllowed={() => {
                        return store.domain.AccountStore.IsLoggedIn && RouterUtil.requireAdminLogin(store);
                    }}
                    path={Client.Main.Action.ActionAddEditDetail}
                />
                /*<PrivateRoute
                component={ProjectDetailsPage}
                exact
                isAllowed={() => {
                    return store.domain.AccountStore.IsLoggedIn && RouterUtil.requireAdminLogin(store);
                }}
                path={Client.Main.Project.ProjectDetail}
            /> */
            }
        </>
    );
};
