import React, { useRef, useEffect, useState } from "react";
import { useObserver } from "mobx-react-lite";
import { generateID } from "Core/Utils/Utils";
import { AlertActionAnalyticsBox, AlertActionAnalyticsWrapper, AnalyticsBox, BarchartWrapper } from "./TabAnalytics.style";
import { DeviceAlertActionItemModelDTO } from "../TabConfigurationModel";
import { AlertAnalyticBarChart } from "Custom/Components/BarCharts/AlertAnalyticBarChart";

interface IConditionSetMetricProps {
    imageHeight: number;
    imageWidth: number;
    marginTop: number;
    alertAnalytics: DeviceAlertActionItemModelDTO[];
    total: number;
    yearAlertAnalytics: DeviceAlertActionItemModelDTO[];
    yearTotal: number;
    isLoading: boolean;
    counter: number;
}

export function AlertActionAnalysisComponent(props: IConditionSetMetricProps) {
    const { imageHeight, imageWidth, marginTop, alertAnalytics, total, isLoading, yearAlertAnalytics, yearTotal, counter } = props;
    const componentRef: any = useRef(generateID());
    const [showDownload, setShowDownload] = React.useState(false);

    useEffect(() => {
        return () => {
            // Clean up after yourself
        };
    }, []);

    useEffect(() => {
        setShowDownload(false);
    }, [isLoading]);

    useEffect(() => {
        if (isLoading === false) {
        }
    }, [alertAnalytics, /* svgElement.current, */ total]);

    return useObserver(() => (
        <>
            <AlertActionAnalyticsWrapper id="alertActionAnalyticsWrapper">
                <AlertActionAnalyticsBox id="alertActionAnalyticsBox">
                    <BarchartWrapper height={imageHeight}>
                        {/*                 <div className="download-metric-wrapper">
                    {showDownload === true && (
                        <a id="download" className="download-metric-svg" href="#">
                            Download SVG
                        </a>
                    )}
                </div> */}
                        <div id="variableAnalytics">
                            <AlertAnalyticBarChart
                                backgroundColour="#585858"
                                textColor="#ffffff"
                                title={"Alerts Analysis"}
                                imageHeight={imageHeight}
                                imageWidth={imageWidth}
                                marginTop={marginTop}
                                alertAnalytics={alertAnalytics}
                                total={total}
                                isLoading={isLoading}
                                counter={counter}
                            />
                        </div>
                    </BarchartWrapper>
                    <BarchartWrapper height={imageHeight}>
                        {/*                 <div className="download-metric-wrapper">
                    {showDownload === true && (
                        <a id="download" className="download-metric-svg" href="#">
                            Download SVG
                        </a>
                    )}
                </div> */}
                        <div id="yearAnalytics">
                            <AlertAnalyticBarChart
                                backgroundColour="#585858"
                                textColor="#ffffff"
                                title={"Alert Analysis - 12 months YoY"}
                                imageHeight={300}
                                imageWidth={imageWidth}
                                marginTop={marginTop}
                                alertAnalytics={yearAlertAnalytics}
                                total={yearTotal}
                                isLoading={isLoading}
                                counter={counter}
                            />
                        </div>
                    </BarchartWrapper>
                </AlertActionAnalyticsBox>
            </AlertActionAnalyticsWrapper>
        </>
    ));
}
