import { ITab } from "../Components/Tabs/Tabs";
import { useRouter } from "../../Core/Utils/Hooks/UseReactRouter";

export function useHasRoutedTabs(tabs: ITab[]): ITab | undefined {
    const router = useRouter();

    const hash = router.location.hash.toLowerCase();
    if (!hash || hash === "#") return;
    return tabs.find((t) => `#${t.hash}`.toLowerCase() === hash);
}
