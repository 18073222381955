import { EditableInput, EditableInputProps } from "Core/Components/EditableInput";
import { TextField } from "@material-ui/core";
import styled from "styled-components";

export const RoofcareInput: any = styled(EditableInput)`
    .MuiInputBase-root {
        margin-bottom: 10px;
    }

    .MuiFormLabel-root,
    .MuiFormControlLabel-label,
    .MuiTypography-body1 {
        color: white !important;
        position: absolute;
        top: -5px;
        font-family: "Mont", sans-serif;
    }
`;

/**
 * Standard, styled, MUI TextField
 */
export const RoofcareTextField: any = styled(TextField)`
    .MuiInputBase-root {
        margin-bottom: 10px;
    }

    .MuiFormLabel-root {
        color: white !important;
        position: absolute;
        top: -5px;
        font-family: "Mont", sans-serif;
    }
`;

export const RoofcareLabel = styled(FormLabel)`
    text-align: left;
    font: normal normal bold 14px "Mont-Bold";
    letter-spacing: 0px;
    color: ${(props) => props.theme.app.colors.white};
    opacity: 1;
    height: 19px;
    margin-bottom: 5px;

    &.admin-color-section-label {
        font: normal normal bold 14px "Mont", sans-serif !important;
    }
`;

export const RoofcareTitleLabel: any = styled(RoofcareLabel)`
    line-height: 36px;
`;

export const RoofcareIndividualInput = styled(Input)`
    //input {
    margin-top: 5px;
    background-color: #ffffff !important;
    border: 1px solid ${(props) => props.theme.app.colors.grey.background};
    border-radius: 3px;
    opacity: 1;
    padding: 0;
    width: 100%;

    text-align: left;
    font: normal normal normal 12px/26px "Mont-Regular";
    letter-spacing: 0px;
    color: #757474 !important;
    opacity: 1;
    font-weight: 300;
    //}

    input {
        padding: 10px;
    }

    &.MuiInput-underline::before {
        border: none !important;
    }
`;

export const RoofcareToggleTypography = styled(Typography)`
    text-align: left;
    letter-spacing: 0px;
    color: ${(props) => props.theme.app.colors.blue.button};
    opacity: 1;
    font-weight: 300;
    font: normal normal normal 12px/26px "Mont-Regular" !important;
`;

import { makeStyles } from "@material-ui/core/styles";
import { FormLabel, Input, Typography } from "@material-ui/core";

export const useSelectStyles = makeStyles({
    select: {
        minWidth: "320px",
        disableScrollLock: true,
        "& ul": {
            backgroundColor: "#ffffff",
        },
        "& li": {
            fontSize: 12,
        },
    },
});
