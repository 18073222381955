import * as Models from "Custom/Models";
import * as Utils from "Custom/Utils/compare";

const createVersion = (version: string, date: string, items: string[]) => {
    return {
        date,
        items: items.map((item) => {
            return { description: item };
        }),
        version,
    };
};

const versions = [
    createVersion("0.3.9", "2023-03-21", [
        "Installation - RC080 - Change to the sensor value calculated value calculation.  Means changes to website, standingwater processor and alerter",
    ]),
    createVersion("0.3.8", "2023-03-08", ["Unit - Fix for not being able to generate the QR code issue."]),
    createVersion("0.3.7", "2023-03-08", ["Estate - Optimisation for RC062"]),
    createVersion("0.3.6", "2023-03-07", [
        "Estate - RC062 - Added overview for property and click through to the installation",
        "Project - RC075 - Fix for contacts not loading when adding a new end user with contacts from the add / edit project page",
    ]),
    createVersion("0.3.5", "2023-02-22", [
        "Actions - RC068 - Added Action tab with list of all project actions / communications.",
        "Installations - Put in extra checks to hide audit trail items that should be hidden.",
    ]),
    createVersion("0.3.4", "2023-02-17", [
        "Contacts - RC063 - Added new contacts tab for Contractor and End user contacts with CSV Download",
        "Installation - Images - RC073 - Added toggle to switch sorting direction",
        "Project Actions - Fixed issue with add action.",
    ]),
    createVersion("0.3.3", "2023-02-09", ["Admin - RC071 - Added Min temperature for units.  Sensor values below this value will be ignored"]),
    createVersion("0.3.2", "2023-02-07", [
        "Installation - RC035 - Abililty to remove a device from a property.",
        "Installations - RC069 - Added CSV download of all installations",
        "Units - RC070 - Added CSV download of all units",
        "RC001 - Audit trail updated when commissioned from RoofCare Installation Assistant",
        "Installation - Average Peak Capacity LTM over 100% fix. Capped between 0 and 100%.",
        "Installation - RC001 - Added Installation tab, for when a unit is commissioned",
        "Installation - RC001 - QR code download is now two QR codes and device name at 96dpi.",
        "Project - Action - RC065, RC067, RC072 - Project Action resolution",
    ]),
    createVersion("0.3.1", "2023-01-20", [
        "Installations - RC069 - Added CSV download of all installations",
        "Units - RC070 - Added CSV download of all units",
        "Project Communication - changed the colour of the edit project action button",
    ]),
    createVersion("0.2.78", "2022-12-08", [
        "Unit - RC001 - Added ability to download a QR code from the unit list page server side.",
        "Project - Add / Edit - Swapped out the End user select for an autocomplete.  Add new only available when nothing is found",
    ]),
    createVersion("0.2.77", "2022-11-29", [
        "Installation - RC059 - Standing water processor - Added sending email and SMS messages when the standing water hits red.",
        "Installation - RC059 - Added standing water value to the Unit and installation tables for all users",
        "Installation - Edit - Fixed issue where Unit name not saving from installation edit form, due to unit data",
    ]),
    createVersion("0.2.76", "2022-11-25", ["Installation - Fix for average Peak calculation when working height has not been set"]),
    createVersion("0.2.75", "2022-11-24", [
        "Estate - Property - RC051 - Added document list and ability to add documents to the property",
        "Unit - RC058 - Added Status data to the add / edit unit modal.",
        "Project - Fixed issue with count descrepency between admin project status table and project status dropdown counts",
        "Project Property - Fixed and issue where if an address is set then cancelled, when the modal is reopened, it contains the old address",
        "Project Property - Add property address - Fixed issue when using autocomplete to chose an address then switching to manual mode, would leave the map at the zero location",
        "Project Propety - Edit property adddress - Fixed issue where map in the modal silent crashed when closing the modal.",
    ]),
    createVersion("0.2.74", "2022-11-18", [
        "Admin - RC055 - Added Project Status list and ability to add / edit / delete",
        "Admin - RC050 - Added Project Payment Status list and ability to add / edit / delete",
        "Database - RC057 - Schema",
    ]),
    createVersion("0.2.73", "2022-11-14", ["Admin - Colours - RC054 - Added an additional colour picker to the pre-selected list of colours"]),
    createVersion("0.2.72", "2022-11-07", ["Installation - RC016 - Added Standing water information "]),
    createVersion("0.2.71", "2022-11-02", [
        "Installation - Audit - RC052 - Filter out audit items before commission date, if a commission date exists",
        "Installation - RC043 - Added add/update and continue to modals",
        "Installation - RC052 - Filter out images / analytics / metrics / notes / sensor values, if a commission date exists",
        "Installation - Images - RC053 - Fix for end date not storing correctly",
    ]),
    createVersion("0.2.70", "2022-10-21", [
        "Installation - Audit alert text, made decimal places consistant",
        "Images - Fixed display issue when less than three images",
        "Installation - RC045 - Re-wrote how autocomplete get the data for the property / project dropdown",
    ]),
    createVersion("0.2.69", "2022-09-27", [
        "Alerter - RC039 - Rewritten to test against every sensor value since the last alerter run and if a value crosses multiple alert events to add all the events instead of the previous top one.",
    ]),
    createVersion("0.2.68", "2022-09-16", [
        "General - RC041 - Removed Unit number from tables, map pop ups and pages",
        "Installation - Overview - Fixed issue breaking the key performance totals",
        "Installation - Overview - Restyled the siphonics graph to cope with over 1000 in the count. Will need looking at if the value ever goes above 10,000.",
    ]),
    createVersion("0.2.67", "2022-09-15", ["Installation - Value calculation - removal of intermediate values, due to changing install heights"]),
    createVersion("0.2.66", "2022-09-14", [
        "Alerter - RC036 - NO data from any device - Email Alert",
        "Installation - RC040 - Changed the sytling of the Analytics and metric tabs to match the overview tab",
    ]),
    createVersion("0.2.65", "2022-09-13", [
        "Reset Password - RC037 - Restyled",
        "Installation - UN204 - Key performance data P1 & P2 now labelled.",
        "Installation - UN204 - Box titles updated with more timings",
        "Installation - UN204 - Core Alerts and Siphonic counts now 90 days instead of 30 days",
        "Projects - RC038 - Project Status. Added new status, renamed several and added ordinal field to order the list in none alphabetical order",
    ]),
    createVersion("0.2.64", "2022-09-09", [
        "Installation - Overview - Show all alerts with scrollbar",
        "Installation - Overview - Images - Defaults to view 2",
        "Installation - Overview - Condition history is now 90 days instead of a month",
        "Installation - Overview - Siphonic box maintains name, for none siphonic units",
        "Installation - Overview - Box titles can now include period details",
        "Installation - Overview - Key performance data now shows year totals / percentages for Alerts, Max capacity and average peak",
    ]),
    createVersion("0.2.63", "2022-09-08", [
        "Installation - UN204 - Fixed issue with P1 and P2 counts",
        "Installation - UN204 - Fixed ordering on the Siphonic analytics",
        "Analytics - Show now in height order not created order",
        "Installation - UN204 - Added additional No data messages to make the view consistant",
        "Installation - UN204 - Fixed max Capacity LTM anomaly",
        "Installation - UN204 - Recent Alerts - fixed wrong date showing in list",
    ]),
    createVersion("0.2.62", "2022-09-06", ["Installation - Overview - RC038 - Fixed image order", "Installation - UN204 - New dashboard Overview tab, needs styling"]),
    createVersion("0.2.61", "2022-09-02", [
        "Installation - Move Unit - RC033 - Fixed issue with move unit failing, if audit entries exist.",
        "Installation - UN300 - Fixed issue where it was using the device id incorrectly and made the page more resiliant by diabling the button, if the installation is not in a fit state",
        "Installation - RC037 - Fixed issue with duplicate images,",
    ]),
    createVersion("0.2.60", "2022-08-23", [
        "Installation - Refactored loading in preparation for UN204",
        "End User / Contractor - RC030 - Added secondary phone number to the contacts",
        "Installation - Average Height - RC029 - Added date controls so the graph and csv data can have more precision",
        "Installation - Fixed minimum date being before mimimum sensor value warning on multiple tabs.  Fixed issue with end date not working correctly",
        "Installation - RC031 - Images - In additon to sorting days backwards.  Sort the images by backward time within the date.",
        "Note: RC031 - Due to the devices taking images out of view order and the website sorting by view, some of the view 2 images will have a time later than the view 3 image. ",
    ]),
    createVersion("0.2.59", "2022-08-16", ["Units - Add/Edit - RC028 - Fix for date control giving invalid dates, when typed in"]),
    createVersion("0.2.58", "2022-08-05", [
        "Admin - Version - Fixed issue with duplicate entry",
        "Installation - UN300 - Added ability to commission a unit and hide previous data",
        "Units - RC025 - Fixed issue with table header sizing on sort",
    ]),
    createVersion("0.2.57", "2022-08-03", ["Unit - Edit - Firmware not being read correctly when editing"]),
    createVersion("0.2.56", "2022-08-02", ["Installation - Audit - Changed modal close button", "Installation - Audit - Table now defaults to reverse created date order"]),
    createVersion("0.2.55", "2022-08-01", [
        "Installation Audit - Added modal to show Email details",
        "Installation Audit - Added modal to show alert details",
        "Database - Tweaks to stored procedures ",
    ]),
    createVersion("0.2.54", "2022-08-01", [
        "Installation - Images - BF158 - Images ordered in view order (regardless of time order). This will need additional work, for when images are missing.",
        "Installation - Commands - When sending a command, set the Audit value as Send Unit command and not Installation.",
        "Admin - Commands - When sending multiple commands, audit trail will show single line instead of multiple items",
        "Stored alerts for overview - Added time of event in addition to created time",
        "Installation - Audit - Addded modal for emails to show the email.",
        "Installation - Audit - Added Audit type filtering to the table",
        "Installation - Audit - Added sorting to the table",
        "Alerter - Added saving an alert item to the audit log.",
    ]),
    createVersion("0.2.53", "2022-07-27", [
        "Unit - Edit - RC019 - Added ability to edit unit notes and delete them",
        "Unit - Notes - RC019 - Merged the functionality of the new Unit Notes table with the old CRM device notes, since the new table allows greater functionality",
        "Installation - Attributes - RC700 - Fixed issue with alert and attribute lines losing the scale values and drawing incorrectly",
    ]),
    createVersion("0.2.52", "2022-07-25", ["Installation Images - Rolled back pending discussion", "Installation Images - RC024 - Fix for zoom in Modal when flip image is used"]),
    createVersion("0.2.51", "2022-07-22", [
        "Units - RC023 - Added ability to hide a unit, with are you sure prompt",
        "Installation - Add / Edit, fixed disabled style issues",
        "Units - RC021 - Added ability to load the unit from the unit list.",
        "Breadcrumbs - Added breadcrumbs for Admin and Units section and renamed all the Installation breadcrumb names",
        "Installation - Admin - RC015 - Added a table of sent / unsent individual commands and ",
        "Installation - Images - BF158 & RC009 - Since images need to be in date and also view order, the time has been split into rows. Blank, non clickable images for any missing images for that minute are shown",
        "Installation - Temperatures - Updated the cutoff point for valid data",
        "Installation - RC017 - Added Firmware option on add / edit installation modal. Fixed issues with simId needing to be selected twice before it would update the associated device and property sometimes selecting nothing",
    ]),
    createVersion("0.2.50", "2022-07-08", [
        "Estates - Added whether Alert Action, Unit Behaviour and Condition sets have been set for every installation under the estate",
        "Contractor View - Added whether Alert Action, Unit Behaviour and Condition sets have been set for every installation under the estate",
        "End User View - Removed places where Alert Action, Unit Behaviour and Condition sets properties are shown",
        "Admin - Alert Action - Added whether the installations attached to the alert action have valid email and mobile settings",
        "Installation List - Added whether the installations have valid email and mobile settings as well as an alert action",
        "Units - RC018 - Added firmware filter and column",
        "Estate - RC020 - Added Email / mobile images to Estate tab and clicked through pages",
        "Contractor View - RC0200 - Added Email / mobile images to Estate tab and clicked through pages",
        "Project -> Property -> installation list. Fixed issue with some columns missing",
    ]),
    createVersion("0.2.49", "2022-07-04", [
        "Estate - Added whether Alert Action, Unit Behaviour and Condition sets have been set for installations on a property.",
        "Installation List - Added whether Alert Action, Unit Behaviour and Condition sets have been set for an installation",
    ]),
    createVersion("0.2.48", "2022-07-01", [
        "Installation Images - BF158 / RC009 - Partial fix.  Images are sorted by minute and then view (and the list reversed), so will not work, for example, if view 1 comes in a minute before views 2 and 3",
    ]),
    createVersion("0.2.47", "2022-07-01", [
        "Installation Images - RC010 - Fixed an issue where viewing images, then going to another installation did not clear out the images.",
        "Installation Images - RC013 / RC011 - Modal correctly appears when the image is clicked",
        "Installation Images - BF158 - Rolled back change since it breaks if there is more than three images in a day, it was sorting them in view order as requested, which is wrong if more than three images.",
    ]),
    createVersion("0.2.46", "2022-06-30", [
        "Installation - Fixed database issue with moving units to between properties",
        "Project Status - UN206 - Added Test to Project Status list",
        "Alerter - UN203 - Change to SMS format - Added address and re-formatted",
        "Alerter - UN201 - Change to email to HTMLformat - Added logo and address and re-formatted",
        "Installation - UN202 - Removed the date header from the imabe sections and added the date to the image",
        "Project List - UN200 - Search box will search on end user lead contact name",
        "Project Status - UN301 - Added Paid to Project Status List",
        "Installation Images - BF158 - Forced the row to show images in view order, not when they were taken order",
        "Installation Images - Refactored the custom dates to work the same as the device data tab and fixed the formatting of the manual entry",
        "Multiple Maps - UN205 - Changed the property map pins to be its status not the static property colour",
        "Property - Add Installation - BF156 - Fix for occasional missing property",
        "Installation Images - Reverse image list by date",
        "Installation Commands - Saving a command will, update any unsent commands with the same command Ids as sent (with no sent date), so duplicate commands will no longer be sent to the device",
    ]),
    createVersion("0.2.45", "2022-06-16", [
        "Unit - UN152 - Added Firmware concept to Unit.  Forced to Unknown, until UN153 is implemented",
        "Search boxes - fixed incorrect paramater",
        "Admin - UN153 - Initial database changes to support editing unit firmware, version, test grade and unit status. Needs further server and client work",
        "Project - BF403 - Project Status count.  Removed deleted projects from count",
        "Installation - UN150 / UN152 - Added firmware version logic to photo now button (by hiding button )",
        "Installation - SH050 - Added Sent Command option to the audit log",
        "Alerter - UN150 / UN152 - Stop sending alerts if the unit's firmware does not support the image request",
        "Project - V1.8008 - Added Contractor Contact to the Project.",
    ]),
    createVersion("0.2.44", "2022-06-14", ["Installation - Removed the request image button temporarily until the version type is in."]),
    createVersion("0.2.43", "2022-06-07", [
        "Installation - BF400 - Changed the animation timings for the lines to make them turn up earlier",
        "Installation - BF401 - Changed title of the 12 month condition set pie chart",
    ]),
    createVersion("0.2.42", "2022-06-07", [
        "Unit Values - V1.8005 - Added is deleted flag for later use with sanitisation.",
        "DB optimisation and cleanup. Added nightly index cleaning to the live database, removed some unused procedures, cleaned up some procedures that used commands for status generation. Removed unused stored procedures",
        "Unit Values - Added intermediate value field and calculating on way into database, rather than on the way out.",
        "Installation - Added Analytics tab - to show Alert Analytics over a given time period as a bar chart",
        "General - Backend server and client side optimisations.",
        "Installation - Images - BF300 - Reworked the maths to make it more consistant across the different image sizes.",
        "Installation - Admin - UN151 - User is now stored, when Requesting an image from the device, (as opposed to it being assigned to Shoothill Admin, who is assigned when the alerter asks for an image)",
        "Installation  - Audit - UN151 / SH050 / UN104- Added a new audit trail for the installation, for when the event requirements are defined. This will also show when alerts are sent out",
        "Installation - SJ101 - Stop the custom range going back further than there is data for.",
    ]),
    createVersion("0.2.41", "2022-06-07", ["Installation - Device data - Fix for downlaod of device data"]),
    createVersion("0.2.40", "2022-05-20", [
        "Alerter - Added storing of every alert trip for display later and fixed issues with the SMS and Email formatting.",
        "Maps - Fixed issue with default pin displaying instead of the expected data",
    ]),
    createVersion("0.2.39", "2022-05-16", [
        "Projects - Added Potential Units to the projects table - V1.8012",
        "Installation - Technical debt clean up for Device Alerts",
        "Alerter - Removed all references to device alerts and cleaned up the storing of the last run.",
        "Tables - Count obscured - BF155 - Text changed from white.",
        "Installation - Average Height, Overview, Device Data - fixed styling that was throwing the page width out of whack.",
        "Users - Added roles dropdown filter to table",
        "Installation - Attributes - BF121 - disabling the alert button for the animation duration, to stop users mashing the animation.",
        "Installation - Device Data - Preparatory refactored Hide on graph to retrieve less data from the server.  Refactored the code to remove the metric and analytic data moved to other tabs - BF154",
        "End User - Add / Edit End User - BF118 - Sorting of Contacts by first name and then last name",
        "Installation - Device Data - Added persistance to the table, so if you was on page 5 and hide an item, it will return you to page 5",
        "Installation - Device Data - When hiding a value, when the page reloads, it will scroll to the changed item",
        "Contractor - Add / Edit End User - Sorting of Contacts by first name and then last name",
        "Installation - Images - V1.8007 - Refactored modal to allow images to be downloaded using right mouse click. Temp fix",
        "Installation - Overview - Refactored to use the same mechanism and modal as the images tab, allowing moving between images same size modal",
        "Installation - Images - V1.8007 - In addition, added a button on the modal to download the image.",
        "Installation - Images - Modals - V1.8006 - Added zoom on mouse over.",
    ]),
    createVersion("0.2.38", "2022-05-06", [
        "Installation - Device Data - Removed the circular graph since it is moving to the metrics tab",
        "Installation - Metrics tab - Double piechart added with lines and legend.  Issues when all data in one slice.",
        "Installation - Metrics tab - Experimental download metrics image added.",
        "Estate, Contractor View - Property View - BF119 - Added the ability on the property map to move the unit pins.",
        "End User - BF118 - Default sort is now project name, not project number",
        "Installation - Configuration - Fixed font on the key gutter dimensions image.",
        "Installation - Overview - Refactored Flip Image to use icon not checkbox",
        "Installations - Persist unit status across page loads.",
        "Units - Persist filter drop downs across page loads.",
        "Installation - Attribute - BF150 - More fixes to remove the animation, once it has started",
        "Project List - V1.8011 - Added project count to each status in the status dropdown",
        "Installation - Attribute - Moved image to top of the tab.",
    ]),
    createVersion("0.2.37", "2022-04-26", ["Project - Add / Edit - BF116 - List of end user contacts has been styled"]),
    createVersion("0.2.36", "2022-04-25", ["Alert Email - Alter alert copy", "Installation - Attributes - UN114 - Alert Action animation tidy up"]),
    createVersion("0.2.35", "2022-04-25", [
        "Graphs - Fixed display issue when max condition set items",
        "Metrics - SH057 - Initial wire in the condition sets to the pie chart, since it was written for the old system. ",
        "Installation - Attributes - UN114 - Added in some live rendering. Fixed issues with text and dissappearing elements",
    ]),
    createVersion("0.2.34", "2022-04-19", [
        "Condition Set - UN115 - Converted grid to table. Added contractor and end user fields.  Added update on save to the list",
        "Admin Lists  - Fixed issue where deleted device items would be included in the count of devices",
        "Alert Actions - UN115 - Added unit list to Alert Action add / edit page.",
        "Unit Behaviour - UN115 - Added unit list to Unit Behaviour add / edit page.",
    ]),
    createVersion("0.2.33", "2022-04-14", [
        "Estates - UN116 - Filter list to only show 1+ units and option to show those without units",
        "Estates - Implemented table sorting and filter persistence",
        "Unit - Edit - BF116 - Allow unit name to be additionally edited from the unit edit page",
        "Contacts - BF115 - Relaxed the validation on phone and email fields so only one of them is required",
        "Contractor - Add / Edit - Reformatted the contractor edit page",
        "End User - Add / Edit - Reformatted the end user edit page",
        "Condition Set - UN115 - Initial proof of concept work",
    ]),
    createVersion("0.2.32", "2022-04-13", [
        "Installation -Device Date- BF120 - fix for initial device data the same across units",
        "Installation - Images - SH086 - Implemented as Date view instead of Camara View.",
        "Unit - Unit Version - BF119 - Added missing unit versions and show in ordinal order",
    ]),

    createVersion("0.2.31", "2022-04-11", [
        "Installation - Average Height - BF111 - fix for data call lag",
        "Installation - Status - BF113 - cleaned up the status code to remove any deleted condition set items.",
    ]),
    createVersion("0.2.30", "2022-04-08", [
        "Installation - Average Graph - BF110 - Removed hidden values from the calculations and fixed a typo",
        "Device Data - BF110 - fixed resync issue and added mechanism to force average graph to update.",
        "Installation - Status - Added additional default status of Flood as an option, instead of using the highest conditon set value, when the calculated value is greater than the capacity.",
        "Installation List - Added missing statuses to the dropdown, for statuses that lie outside the condition set structure",
        "Installation - Attribute - Image - Set the attribute and alert lines to black",
    ]),
    createVersion("0.2.29", "2022-04-07", [
        "Installation - Device Data - UN113 - Only Admin & regional sales managers can download the device data",
        "Installation - Images - V1.8009 - Implemented Request Image, using Alerter code",
    ]),
    createVersion("0.2.28", "2022-04-06", [
        "Contractor View - BH108 - Contractors should now only see devices that are assigned to them",
        "Contractor View - BF109 - The drop down shows multiple options per property",
        "Client View - BF109 - The drop downs should show only valid options",
        "Client View - Fixed Contractor dropdown to cope with end users not having contractors, by addind a new option",
        "Client View - Implemented Status filter on units page",
        "Contractor View - Implemented Status filter on units page",
        "Estate - BF103 - Removed Mixed as a status and using percentage of working height of the current status to determine status",
        "Estate, Client View, Contractor View - BF103 - Status drop down only shows valid options for the list",
    ]),
    createVersion("0.2.27", "2022-04-05", [
        "Units - BF106 - Fixed issue with search failing",
        "Installation - Attributes - BF105 - Made the condition set legend bars a fixed height",
        "Project - Add - BH107 - Fixed issue where clients and contacts could not be selected",
        "Installation - Attributes - BF102 - Added attribute button and implemented the lines on the diagram",
        "Estate / Dashboard Maps - BF104 - Filter out the pins for units as well as properties",
        "Map Pins - Changed the border colour on the pin to grey if the pin is white.",
        "Installation - Configuration - BF101 - Page will now maintain the state of the sections being open or closed across data changes",
    ]),
    createVersion("0.2.26", "2022-04-04", ["Units - UN112 - Removed deployed column and replaced with address field", "Installation - Attributes - Fix for Legend"]),
    createVersion("0.2.25", "2022-04-01", [
        "Installation - View / Editing- Refactored to decrease the loading time of the page",
        "Installations - UN113 - Removed unit number from the table",
        "Installation - Attributes - UN111 - Added Flood and 'from' and 'to' to the image",
        "Units - UN112 - Put field in to show if deployed or not. Still need to show where.",
    ]),
    createVersion("0.2.24", "2022-04-01", ["Header - Added hover mouse pointer change to the logout button", "Contractor - Add / Edit - Address section styling changes"]),
    createVersion("0.2.24", "2022-03-31", [
        "Installation - Download data - Removed the RawValue column and split it into the six components parts",
        "Installation - Download data fixed issue where download was returning the wrong name for the Status",
        "Unit - SH027 - Edit - Added note list and ability to add notes and additional modal styling",
    ]),
    createVersion("0.2.23", "2022-03-30", [
        "Graphs - Doubled the thickness of the attribute and alert actions line",
        "Units - SH027 - Implemented basic persistence - needs further validation and testing",
        "Maps & Tables - Styling changes",
    ]),
    createVersion("0.2.22", "2022-03-29", [
        "Installation - UN105 - Added base measurement to installation for pitched roofs.",
        "Installation - Graphs - UN106 - Added alert toggle to the graphs",
        "Unit - SH027 - Initial backend database and server work.  Client side view list and filter dropdowns.  Still need to work on the edit",
        "Estate - UN108 - Search filter should filter map and have additional filters",
        "Client View - UN108 - Search filter should filter map and have additional filters",
        "Contractor View - UN108 - Search filter should filter map and have additional filters",
        "Graphs - Temporarily set the attribute and alert lines and text to black",
    ]),
    createVersion("0.2.21", "2022-03-24", [
        "Installation - Attribute Image- SH048 - Alert Action view reworked",
        "Installation - Added missing colour label on editing alert actions",
        "Installation - Alert action saving now regenerates the installation",
        "Admin - Added additional validation on the name fields",
        "Installation List - First pass at status filter.",
        "Graphs - Changed wording on switches",
    ]),
    createVersion("0.2.20", "2022-03-23", [
        "Installation - Attribute Image- SH048 - Styling changes and added Alert Action text, needs discussion",
        "Alerter - UN103 - Removed device id from SMS and Email",
    ]),
    createVersion("0.2.19", "2022-03-22", [
        "Installation - Attribute - SH048 - Changed LHS to be a legend. Added more text. Started adding in the alert action information.  Draws the line, but no text at the moment",
        "Project - Property - Document tab, uploader and list added",
        "Project - UN105 - Added On Hold status",
    ]),
    createVersion("0.2.18", "2022-03-21", [
        "Installation - Attribute - SH048 - Initial Condition set adding bars and creating the diagram in code. Needs text and alerts",
        "Color Picker - Fix for red being cyan",
        "Installation - UN103 - Device Id hidden on all pages except add/edit. Needs removing from alerter",
        "Estate, End User, Contractor Dashboard - Styling changes",
        "General - Table styling refactoring to simplify",
    ]),
    createVersion("0.2.17", "2022-03-18", ["Line Graphs - Added flood level", "Boxplot Graph - Added flood level"]),
    createVersion("0.2.16", "2022-03-17", [
        "Admin - Condition Sets / Alert Actions / Unit Behaviour - Stopped deleting if dependent installations.",
        "Admin - Condition Sets / Alert Actions / Unit Behaviour - Tables now display Drainage Type and a count of dependent installations",
        "Installation - Configuration tab - Removal of admin condition sets and alert actions if they would make the installation invalid",
        "Estate, End user dashboard, contractor dashboard - Map styling changes",
        "Installation - Configuration tab - Added bounds validation for the condition set rows.",
        "Installation - Configuration tab - Added bounds validation for the alert action rows.",
    ]),
    createVersion("0.2.15", "2022-03-16", [
        "Colour Picker - Added missing red colour",
        "Admin - Added additional validation, to ensure editing a Unit Behaviour, Condition Set or Alert Action will check that it doesn't invalidate any of the installations that use those items",
        "Installation - Add / Edit added validation on the drop down unit behaviour list to ensure items in it are valid",
        "Installation - Add / Edit added validation on save to ensure the unit behavior values are within bounds",
        "Client View - SH043 - Added contractor if it exists for any attached installations",
        "Project - SH087 - Property view now shows status",
    ]),
    createVersion("0.2.14", "2022-03-14", [
        "Installation - SH082 - Additional changes to ensure when a property removes a device or project is deleted, the installation location is reset",
        "Alerter - UN100 - Removed the device alerts from the alerter",
        "Installation - Add / Edit - Refactored and created new functions to speed up the loading and saving",
        "Admin - UN102 - Colour Picker changes.  Needs flood colour bands added to graphs and the validation code to cope with flood.",
    ]),
    createVersion("0.2.13", "2022-03-11", [
        "Property - SH083 - Add / Edit Property location using map pin drag",
        "ClientView - BF004 - Rewote duplicate routes to stop data appearing twice",
        "Installation - SH082 - Add / Edit Installation location from property and map pin",
    ]),
    createVersion("0.2.12", "2022-03-07", [
        "Estate - SH043 - Added Contractor to table, if one exists for any of the installations attached",
        "Installation - SH051 - Removed options to create new local unit behaviour",
        "Installation - BF001 - Stop Editing selected standard Condition set and fixed issues with saving and deleting not updating statuses",
    ]),
    createVersion("0.2.11", "2022-03-04", [
        "Installation - Fixed issue where saving condition sets, didn't regenerate the installation status",
        "Statuses - Fixed and edge case issue, where some property statuses failed to calculate",
        "Tables - Fixed colour issue with sorted columns",
        "Property Map - Implemented pop up for map pins and navigation",
        "Status - Added concept of Base Condition to Statuses.  May need adding to condition set lists?",
        "Device - Admin - UN100 - Removed the Device Commands and Device Alerts.  This will need Device alerts to be removed from the alerter",
    ]),
    createVersion("0.2.10", "2022-03-03", [
        "Line Graphs - SH028 -  Coloured using the Condition set colours, with a base colour for the area below the lowest condition set. Needs further discussion",
        "Boxplot Graphs - SH028 - Coloured using the Condition set colours, with a base colour for the area below the lowest condition set.",
        "Line And Boxplot Graphs - Added switch to turn on H, FB, P1 and P2 lines",
    ]),
    createVersion("0.2.9", "2022-03-01", [
        "Map Pins - Wired in the map pin colours based on the installation status colour",
        "Map - Refactored to simplify installation and property pin creation",
        "Property Details - Tab under the dashboards refactored",
        "Property Status - Added concept of Mixed status where a property has more than one installation but they use different condition sets",
        "Installation Status - As instructions, made the point the lower bound of the section, not the upper bound",
        "Installation - Add / Edit SH061 - Unit Behaviour is required when saving an installation",
        "Installation - Alert Action view - BF003 - Fixed Email tick style.",
        "Graphs - Temporarily removed all the colours off the graphs.",
    ]),
    createVersion("0.2.8", "2022-02-28", [
        "Installation - Device Data - Calculates status server side now",
        "Contacts - Ensure contacts are unique for a contractor or end user",
        "Properties - SH081 - Added building name field and ability to add / edit building name for properties",
        "Map Pins - Created custom pins that allow any colour rather than a fixed colour",
        "Installation - Add / Edit - SH061 changing the drainage type will reset the unit behaviour.  This needs expanding on saving to also reset the condition sets and alert settings.",
        "General fixes and enhancements during testing",
    ]),
    createVersion("0.2.7", "2022-02-24", [
        "Condition Sets - Partial implementation of status on tables and installation pages.  Need more info for Properties and to do the graphs",
        "Project - SH079 - Add end user contact from Add / Edit project modal",
        "Add Contact Button - fixed styling",
        "Contractor Contact - SH084 - Added ability to edit.",
        "End User Contact - SH085 - Added ability to edit.",
        "General styling and refactoring changes",
        "Unit - UN89 - Add / Edit validation for Sim ID and Device ID and stopping cut and paste into those fields",
    ]),
    createVersion("0.2.6", "2022-02-16", [
        "Contractor & Client View - Fixed issue with deleted projects",
        "Unit - Alert Action - Added calculated value to the Alert Action view to make consistent with Condition set",
        "Move Unit - Clear out peripheral data",
        "Alerter - Added Alert Actions into alerter and updated the email template (no UAT instance at the moment)",
        "Client & Contractor View - SH049 - Added property notes",
        "Project Add / Edit - Styling changes",
        "Estate - SH043 - Added an Estate Tab",
        "Header - Styling and ensuring depressed on root paths.",
    ]),
    createVersion("0.2.5", "2022-02-10", [
        "Project - Potential unit - Allow backspace on a number field and added additional validation",
        "Unit - Configuration - SH065 - Added popups if Condition Set or Alert Action is in an edit state. Stops navigation away.",
        "Admin - Renamed the 'save & continue' buttons",
        "Alert Actions - Green (On) should be to the right of the button not left",
        "Contractor, End User Dashboard - Made map square, rounded corners. Page needs restyling.",
        "Device - Device Data - Removed test data.",
        "Device - SH027.2 & SH027.3 Hiding a device clears out associated data.",
        "Project - SH027.1 - Delete project clears out associated data for all attached devices",
        "Project - SH080 - Add / edit project, show postcode in the address boxes",
    ]),
    createVersion("0.2.4", "2022-02-09", [
        "Project - Add Client - SH063 fixed background colour",
        "Condition Set - SH062 - Fixed loose field and styling changes",
        "Alert Action - SH062 - Fixed loose field and styling changes",
        "Project - SH064 - Fixed Contractor typo",
        "User - Add / Edit - SH044 - Re-aligned the form and adjusted field sizes",
        "Modals - Background styling ",
        "Contractor View - Map tab table styling",
        "End User View - Map tab table styling",
        "User - Add / Edit - SH044 styling changes",
    ]),
    createVersion("0.2.3", "2022-02-07", [
        "Admin - SH059 - Number boxes now select the number on focus",
        "Unit Behaviour - SH060 - Fixed loading unit behaviour resetting reading value",
    ]),
    createVersion("0.2.2", "2022-02-04", [
        "Contractor View - Added Dashboard view - needs table / map state persistence",
        "Client View - Added Dashboard View - needs table / map table state persistence",
        "Units - Fixed validation on selecting from the alert action and condition set dropdowns",
        "Units - Added save buttons to save condition sets and alert actions",
        "Client View Dashboard - Map now zooms to pins",
        "Client View Table - Implemented maintaining state when you leave the page and return",
        "Breacdrumb - Rewrote the generate address line for properties to remove empty sections",
        "Contractor View Dashboard - Map now zooms to pins",
        "Contractor View Table - Implemented maintaining state when you leave the page and return",
        "Admin - Validation for saving, unit behaviour, condition sets and alert actions, if they have been assigned to units.",
        "Contractor View - Added units tab and wired in the page heirarchy",
        "Client View - Added units tab and wired in the page heirarchy",
        "Admin - Condition Sets - Saving overwriting dependent devices",
        "Admin - Added save and continue to each of the three tabs when adding / editing",
        "Admin - Unit Behaviour - Saving overwriting dependent devices",
        "Admin - Alert Actions - Saving overwriting dependent devices",
        "Units - Styling changes",
        "Unit Behaviour - Updated Read text to say Read (mins)",
        "Unit Behaviour - Added 8 X Reading Frequency",
        "Unit Behaviour - Fixed issue where typing a number into the value caused a NaN to be generated",
    ]),
    createVersion("0.2.1", "2022-01-28", [
        "End User - Renamed Clients to end user ",
        "Units - Renamed installations to units",
        "Units - Added Units List Page",
        "Admin - Added Admin tab",
        "Admin - Added Condition sets list and add / edit / remove functionality",
        "Admin - Added Alert Action list and add / edit / remove functionality",
        "Units - General restyle",
        "Units - Renamed Config tab to Admin",
        "Units - Added new Configuration tab",
        "Units - Add new - Added unit behaviour functionality",
        "Units - Added Condition sets list and add / edit / remove functionality",
        "Units - Added Alert Action list and add / edit / remove functionality",
        "General - Restyled site",
        "Condition Sets - Removed Direction",
        "Condition sets, Alert Actions, Unit Behaviours - % now allows negative values",
        "Condition sets, Alert Actions, Unit Behaviours - Removed requirement to stop reuse of types",
        "Condition sets- Limited amount of device rows we can save",
        "Alert Actions - Limited amount of device rows we can save",
        "Unit Behaviours - Device when saving, calculates command values differently when using percentages",
        "Unit Behaviours - Removed condition from first Row",
        "User, End User, Contractor - Changed Add new button text",
        "Units - Allowing editing of unit behaviours.  This will resend the command on every save.",
        "Condition sets, Alert Actions, Unit Behaviours - mm now allows negative values",
        "Unit Behaviours - Device when saving, calculates command values differently when using mm",
        "Condition Set, Alert Actions, Unit Behaviours - Fix for delete showing error",
        "Condition Set - Removed the warning message on save for missing types",
        "Alert Actions - Removed the warning message on save for missing types",
        "Alert Actions - Increased the number of alert items",
        "Contractor - Add / Edit edited title text",
        "End User - Add / Edit edited title text",
        "Contractor - Stopped contractors being deleted if they are attached to one or more projects",
        "Unit - Gutter diagram, removed 5/7 BS and replaced with 70%",
        "Unit - Number generation on new units, script written for exisiting units",
        "Unit list - Added add new installation and edit from the list",
        "Unit - Attributes. Added working and freeboard heights",
        "Unit - Attributes. Added roof type and drainage type and their values",
        "Project - Communication. Made text easier to read",
        "Commands - Put a pop up if sending R0=0",
        "Unit - Add/Edit will allow you edit new unit behaviours, but will not allow editing of existing unit behaviours",
        "Unit List - Added filtering so can see units with and without properties or hidden units",
        "---",
        "Unit - Add / Edit will now filter unit behaviour drop down based on current drainage type",
        "Unit - Configuration tab - will now filter condition sets drop down based on current drainage type",
        "Unit - Configuration tab - will now filter alert actions drop down based on current drainage type",
        "Unit - Unit behaviour - rewritten to ensure correct command item are sent for the command index row",
        "Unit - Configuration tab - When adding a new row to a alert action.  The type dropdown will be drainage type appropriate",
        "Unit - Configuration tab - When adding a new row to a condition set.  The type dropdown will be drainage type appropriate",
        "Project - Deletion of a project now removes affected units and places them back into the device pool",
        "Unit - Configuration tab - Condition set view - Added calculated value field",
    ]),
    createVersion("0.1.42", "2022-03-31", ["Installation - Download CSV - split raw values into component parts"]),
    createVersion("0.1.41", "2022-01-11", ["Installation Configuration - Fixed issue with SMS state not persisting"]),
    createVersion("0.1.40", "2021-12-08", ["Installation Tables - Fixed incorrect status on out of range values"]),
    createVersion("0.1.39", "2021-12-07", [
        "Project - Add new numeric input field to form - Potential Units. This is also displayed in Communication CSV file in column H.",
        "Regional Sales Manager - Given additional privialages.",
        "Project Status - Refactored Device sensor data import to simplifiy status generation",
        "Installation Overview - Graph now takes into account the hide on graph flag",
        "Installation Tables - Put in a display fix for the incorrect data after relaxing the validation rules previously.",
    ]),
    createVersion("0.1.38", "2021-12-07", ["Project List - Column sort order now persists when navigating pages."]),
    createVersion("0.1.37", "2021-12-06", ["Project Filters - Alterations to prevent filter pop-up from closing when the selection is changed."]),
    createVersion("0.1.36", "2021-12-02", ["Project Status - Moved to single source of truth. Removed Lead, EOI and Active. Renamed Campaign to Selling."]),
    createVersion("0.1.35", "2021-11-29", ["Installation - Average Height - Min/Max values can now be downloaded as a csv."]),
    createVersion("0.1.34", "2021-11-26", [
        "Installation Graphs - Disabled sensor value validity check.",
        "Installation - Edit Installation now available irrespective of project status.",
    ]),
    createVersion("0.1.33", "2021-11-23", [
        "Project List - Added regional sales manager, contractor and property count to the csv",
        "Project List - Added filters for client, regional sales manager and project status",
        "Project List - Added persistence of settings",
    ]),
    createVersion("0.1.32", "2021-11-18", ["Project Commands - Rewrote how commands are used in status calculations", "Installation - Changed max battery from 3.75 to 3.6"]),
    createVersion("0.1.31", "2021-11-17", ["Project Communication- Added ability to edit actions"]),
    createVersion("0.1.30", "2021-11-17", ["Project Communication CSV - Added project status to the CSV", "Project Status - Added three new project statuses"]),
    createVersion("0.1.29", "2021-11-16", [
        "Project List- Amended communication CSV format to include projects with no notes and sorting.",
        "Project Communication - Renamed the default note as Background",
        "Project Communication - Renamed the remaining notes as actions",
    ]),
    createVersion("0.1.28", "2021-11-04", [
        "Project List- Added download communication CSV to list.",
        "Project List- Search now additionally searches Client and Regional Sales Manager.",
    ]),
    createVersion("0.1.27", "2021-11-02", ["Project - Added project communication section", "Project - Added project document section"]),
    createVersion("0.1.26", "2021-10-11", ["Project Status- Added four more project statuses"]),
    createVersion("0.1.25", "2021-09-23", ["Installation Battery - Updated the Max Battery Voltage from 3.6 to 3.75"]),
    createVersion("0.1.24", "2021-09-13", [
        "Installation Configuration - Locking down the send buttons to stop duplicates",
        "Installation Overview - Added Received Time Column",
        "Installation Device Data - Added Received Time Column to table and download",
    ]),
    createVersion("0.1.23", "2021-09-08", ["Installation Alerts - Allow sending of photo command"]),
    createVersion("0.1.23", "2021-09-06", [
        "Installation Status - Re-implemented flooding status, using max side height",
        "Line Graph - Re-implemented flooding status",
        "Boxplot graph - Re-implemented flooding status",
        "Client and Contractor view - Re-implemented flooding status on maps",
    ]),
    createVersion("0.1.22", "2021-09-03", [
        "Client View - Fixes for installation devices",
        "Contractor View - Fixes for installation devices",
        "Edit Installation - Fix for crash",
        "Property Installation list - Tweaks for latest value",
        "Installation Atributes tab - Added reworked image",
    ]),
    createVersion("0.1.21", "2021-09-02", [
        "Installation Attributes - Rework for new commands",
        "Installation Graphs - Rework for new commands",
        "Installation Configuration - Fix for persistence",
        "Installation Data - Added temperature column",
        "Installation Data - Reworked statuses in table",
        "Installation Overview - Reworked statuses in table",
        "Installation lists - Reworked overall status",
        "Installation view - Reworked overall status",
        "Installation view - Temperature changes",
    ]),
    createVersion("0.1.20", "2021-08-31", ["Device Command - Styling changes", "Device Command - Changed upload frequency to a dropdown list"]),
    createVersion("0.1.19", "2021-08-25", ["Device Alert - New Device Alert interface and notification sender"]),
    createVersion("0.1.18", "2021-08-16", ["Device Command - New Device Command interface"]),
    createVersion("0.1.17", "2021-08-03", [
        "Device Data Graph - Fix for custom dates on the device data tab not refreshing",
        "Device Data Graph - Stopped start and end date from doing the search automarically and added a search button",
    ]),
    createVersion("0.1.16", "2021-06-30", [
        "Device Data Graph - Fix for switching between devices, messing up the device data graph and settings",
        "General - Converted project to Dot Net 5 to fix issue from latest Windows update",
    ]),
    createVersion("0.1.15", "2021-06-08", ["Device Graph - Fix for x-axis tick labels."]),
    createVersion("0.1.14", "2021-05-28", [
        "Installations - Fix for wrong colour installation pin and installation list not showing latest data.",
        "Installtions - Hidden temperature fields",
    ]),
    createVersion("0.1.13", "2021-05-26", [
        "Property - Can only add installations if an associated project is in a won state.",
        "Breadcrumb - Simplification",
        "Contractor View - Added Project Tab and ability to view Projects / Properties and Installations for contractor admins",
        "Project Add / Edit - Added contractor dropdown for admin users",
        "Admin User - New Map page, showing properties or installations",
        "General - Fixes and enhancements",
    ]),
    createVersion("0.1.12", "2021-05-24", ["Line Graphs - Tooltip fix"]),
    createVersion("0.1.11", "2021-05-21", ["Installation - Fix for CSV download"]),
    createVersion("0.1.10", "2021-05-17", [
        "Default Font Changed to Mont",
        "Images - Added Quick Data",
        "General - Fixes and enhancements",
        "Notification Sender - Now running off Roofcare project",
    ]),
    createVersion("0.1.9", "2021-05-13", [
        "Projects - Added property map tab.",
        "Line graphs - Added timestamp & data on mouseover",
        "Accounts - Fixed issue with new user invalid tokens",
    ]),
    createVersion("0.1.8", "2021-05-11", [
        "Property - Edit Property added to property lists",
        "Map Views - Forcing devices with no location to take the location of their property",
        "General - General fixes and enhancements",
    ]),
    createVersion("0.1.7", "2021-05-10", ["Installation - Fix for Latest Images out of order."]),
    createVersion("0.1.6", "2021-05-07", [
        "Installation Images - Image two and three auto flipped.",
        "Installation - Device Data - Added a show select to preselect date ranges.",
        "Installation - Device Data - Added Download CSV function.",
        "General - General fixes and enhancements",
    ]),
    createVersion("0.1.5", "2021-05-05", ["Property - Implemented new Address creation mechanism."]),
    createVersion("0.1.4", "2021-04-30", ["Client & Contractor View - Tabbed the home page and added map view", "Users - Contractors and Client dropsdowns now searchable"]),
    createVersion("0.1.3", "2021-04-28", ["Installation - Edit Installation added"]),
    createVersion("0.1.2", "2021-04-27", [
        "Client. Fixed issue where a client could not be edited",
        "Edit User - Fix for saving new email address",
        "General - Sorting of lists",
        "Emails - Fixed base urls",
        "Emails - Updated email from",
        "Users - Stopped being allowed to assign to invalid contractors and clients",
    ]),
    createVersion("0.1.1", "2021-04-26", ["Added Changelog.", "Add Installation - Added additional validation and checks"]),
    createVersion("0.1.0", "2021-04-23", ["Initial Release."]),
];

const currentVersion = versions
    .filter((version) => version.items && version.items.length > 0)
    .reduce((previous, current) => {
        return Utils.versionNumbers(previous.version, current.version) > 0 ? previous : current;
    }).version;

export const ChangeLog: { currentVersion: string; versions: Models.ChangeLogVersion[] } = {
    currentVersion,
    versions,
};
