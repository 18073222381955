import * as React from "react";
import * as Mui from "@material-ui/core";
import { ProjectStatusSelectContainer, ProjectStatusSelectSelect } from "./ProjectStatusSelect.styles";
import { MenuItem } from "@material-ui/core";
import { Theme } from "@material-ui/core";
import { generateID } from "Core/Utils/Utils";
import { isNullOrUndefined } from "Custom/Utils/utils";
import { ProjectPaymentStatusDropdownListItem } from "Custom/Models/API/ProjectPaymentStatusDropdownListItem";

interface IProps {
    value: number;
    options: ProjectPaymentStatusDropdownListItem[];
    onChange(value: number): any;
    disabled?: boolean;
}

export const ProjectPaymentStatusSelect: React.FC<IProps> = ({ value, options, onChange, disabled }) => {
    const theme: Theme = Mui.useTheme();

    const handleChange = (value: string) => {
        onChange(Number(value));
    };

    const projectStatus = options.find((ps) => ps.id === value);
    const color = isNullOrUndefined(projectStatus) ? "#000000" : projectStatus!.color;
    const textColor = isNullOrUndefined(projectStatus) ? "#FFFFFF" : projectStatus!.textColor;

    return (
        <ProjectStatusSelectContainer>
            <ProjectStatusSelectSelect
                statuscolor={color}
                statustext={textColor}
                value={value}
                onChange={(c) => handleChange(c.target.value as string)}
                disabled={disabled}
                MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                    },
                }}
            >
                {options.map((option) => (
                    <MenuItem value={option.id} key={generateID()}>
                        {option.displayName}
                    </MenuItem>
                ))}
            </ProjectStatusSelectSelect>
        </ProjectStatusSelectContainer>
    );
};
