import styled from "styled-components";
import { Box } from "@material-ui/core";

export const BackToList: any = styled(Box)`
    display: flex;

    flex-direction: row;
    align-items: center;
    flex: 1 0 0;
    color: ${(props) => props.theme.app.colors.black.dark};
    font-size: 10px;
    .back-icon {
        color: ${(props) => props.theme.app.colors.red.dark};
        font-size: 14px;
    }
    padding-left: 20px;

    &:hover {
        cursor: pointer;
    }
`;

export const BackToListText: any = styled(Box)`
    display: inline-block;
    padding-left: 20px;
    color: ${(props) => props.theme.app.colors.white.main};
    font-size: 10px;
`;

export const BackBox: any = styled(Box)`
    margin-top: -9px;
    width: 8px;
    height: 20px;
    color: ${(props) => props.theme.app.colors.orange.main};
`;
