import { contains } from "../../../Utils/array";
import { ContractorModel, ContractorModelDTO } from "./ContractorModel";
import { ContractorContactModel, ContractorContactModelDTO } from "Custom/Models/Domain/Contacts/ContractorContactModel";
import { ModelBase } from "Core/Models";
import { action, computed, IObservableArray, observable } from "mobx";
import { throws } from "assert";

export class ContractorAndContactsModel extends ModelBase<ContractorAndContactsModel, ContractorAndContactsDTO> {
    @observable public contractor: ContractorModel = new ContractorModel();
    //Use this format to get computeds tofire when items added / changed
    public contacts: IObservableArray<ContractorContactModel> = observable([]);

    @computed
    public get getContactsLength(): number {
        return this.contacts.slice().length;
    }

    //fromDto is required but you can leave it blank
    @action
    fromDto(model: ContractorAndContactsDTO): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model

        this.contractor.fromDto(model.contractor);
        this.contacts.length = 0;
        for (let i: number = 0; i < model.contacts.length; i++) {
            let temp: ContractorContactModel = new ContractorContactModel();
            temp.fromDto(model.contacts[i]);
            this.contacts.push(temp);
        }
    }

    //toDto is required but you can leave it blank
    toDto(model: ContractorAndContactsDTO): void {
        model.contractor = {
            addressLine1: "",
            addressLine2: "",
            contractorName: "",
            city: "",
            county: "",
            postcode: "",
            isDeleted: false,
            rowVersion: "",
            id: "",
            createdBy: "",
            createdDate: "",
        };

        this.contractor.toDto(model.contractor);
        model.contacts = [];

        for (let i: number = 0; i < this.contacts.length; i++) {
            if (this.contacts[i].isDeleted === true && this.contacts[i].id === "") {
                // then no point adding a new deleted item....
            } else {
                const temp = this.contacts[i].getDto();
                model.contacts.push(temp);
            }
        }
    }
}

export type ContractorAndContactsDTO = {
    contractor: ContractorModelDTO;
    contacts: ContractorContactModelDTO[];
};
