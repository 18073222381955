import { observable } from "mobx";
import { ModelBase } from "Core/Models/ModelBase";

//Base class expects the model type and the DTO model type
//If you are not using a DTO just pass in undefined as the DTO
export class ProjectCommunicationModel extends ModelBase<ProjectCommunicationModel, ProjectCommunicationModelDTO> {
    /*     @observable
    @IsNotEmpty({ message: "You must give me your name!!" })
    public firstName: string = ""; */

    public id?: string = undefined;
    @observable
    public isDefaultNote: boolean = false;
    public rowVersion: string = "";

    public projectId: string = "";
    @observable
    public note: string = "";
    @observable
    public createdDate: string | undefined = undefined;
    public createdBy: string = "";
    @observable
    public creator: string = "";
    @observable
    public isDeleted: boolean = false;
    public lastUpdatedBy: string = "";
    @observable
    public updater: string = "";
    @observable
    public updatedDate: string | undefined = undefined;
    @observable
    public followUpDate: string | undefined = undefined;

    @observable
    public isResolved: boolean = false;
    @observable
    public resolver: string = "";
    @observable
    public resolvedDate?: string | undefined = undefined;

    @observable
    public resolvedBy?: string | undefined = undefined;

    //fromDto is required but you can leave it blank
    fromDto(model: ProjectCommunicationModelDTO): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
    }

    //toDto is required but you can leave it blank
    toDto(model: ProjectCommunicationModel): void {}

    toCommunicationDto(): ProjectCommunicationModelDTO {
        let retVal: ProjectCommunicationModelDTO = {
            id: this.id,
            isDefaultNote: this.isDefaultNote,
            rowVersion: this.rowVersion,
            projectId: this.projectId,
            note: this.note,
            createdDate: this.createdDate,
            creator: this.creator,
            createdBy: this.createdBy,
            isDeleted: this.isDeleted,
            lastUpdatedBy: this.lastUpdatedBy,
            updater: this.updater,
            updatedDate: this.updatedDate,
            followUpDate: this.followUpDate,
            isResolved: this.isResolved,
            resolver: this.resolver,
            resolvedDate: this.resolvedDate,
            resolvedBy: this.resolvedBy,
        };
        return retVal;
    }
}

export type ProjectCommunicationModelDTO = {
    id?: string;
    isDefaultNote: boolean;
    rowVersion: string;
    projectId: string;
    note: string;
    createdDate?: string;
    creator: string;
    createdBy: string;
    isDeleted: boolean;
    lastUpdatedBy: string;
    updater: string;
    updatedDate?: string;
    followUpDate?: string;
    isResolved: boolean;
    resolver: string;
    resolvedDate?: string;
    resolvedBy?: string;
};
