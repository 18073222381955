import styled from "styled-components";
import { Box } from "@material-ui/core";
import { BaseModalDialogPaper } from "Custom/Components/Modal/BaseModal.styles";

export const ColourPickerModalDialogPaper = styled(BaseModalDialogPaper)`
    min-width: 420px;
    max-width: 420px;
    background: #3b3c3b 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #000000c2;
    border-radius: 18px;
    color: white;
`;

export const ColourPickerContainer = styled(Box)`
    position: relative;
    width: 100%;
    display: flex;
    flex: 1 0 0;
    flex-direction: row;
    padding-bottom: 15px;
`;

export const ColumnTitle = styled(Box)`
    color: white;
    padding-bottom: 5px;
`;

export const ColourTable = styled.div`
    padding-top: 15px;
    position: relative;
    display: flex;
    flex-direction: row;
    flex: 1 0 0;
    max-width: 100%;
`;

export const ColourColumn = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 0 0;
    max-width: 50%;

    &.lhs {
        padding-right: 8px;
    }

    &.rhs {
        padding-left: 8px;
    }
`;

export const ColourCell = styled.div`
    width: 100%;
    height: 30px;

    margin: 5px 0;
    border-radius: 2px;

    &:hover {
        cursor: pointer;
    }
`;

export const BackgroundContainer = styled(Box)`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
`;

export const TextContainer = styled(Box)`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    color: white;
    padding-left: 15px;
`;

export const DisplayContainer = styled(Box)`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
`;
