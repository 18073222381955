import * as MobX from "mobx";
import { ModelBase } from "Core/Models/ModelBase";
import { IsNotEmpty } from "class-validator";
import { action, observable } from "mobx";

//Base class expects the model type and the DTO model type
//If you are not using a DTO just pass in undefined as the DTO
export class ProjectAddEditModel extends ModelBase<ProjectAddEditModel, ProjectAddEditModelDTO> {
    @observable id?: string = undefined;
    @observable number: string = "";
    @observable searchProperty = "";

    @observable @IsNotEmpty({ message: "Name is required." }) name: string = "";

    @observable createdDate: string = "";
    @observable createdBy: string = "";
    @observable @IsNotEmpty({ message: "Regional Sales Manager is required." }) regionalSalesManagerId: string = "";
    @IsNotEmpty({ message: "Client is required." })
    @observable
    clientId: string = "";
    @observable @IsNotEmpty({ message: "Lead Contact is required." }) leadContactId: string = "";
    @observable status: number = 0; // Project Status Enum Id = 0 is 'unknown'.

    @observable paymentStatus: number = 1; // Project Payment Status Id = 1 is 'unknown'.

    @observable @IsNotEmpty({ message: "At least one property is required." }) propertyIds: string[] = [];
    @observable rowVersion: string = "";
    @observable isDeleted: boolean = false;

    @observable contractorId: string = "";
    @observable contractorLeadContactId: string = "";

    @observable
    @IsNotEmpty({ message: "A number is required" })
    potentialUnits: number = 0;

    //fromDto is required but you can leave it blank
    @action
    fromDto(model: ProjectAddEditModelDTO): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
    }

    //toDto is required but you can leave it blank
    toDto(model: ProjectAddEditModelDTO): void {
        model.id = this.id;
        model.name = this.name;
        model.number = this.number;
        model.status = this.status;
        model.paymentStatus = this.paymentStatus;
        model.clientId = this.clientId;
        model.createdBy = this.createdBy;
        model.createdDate = this.createdDate;
        model.regionalSalesManagerId = this.regionalSalesManagerId;
        model.propertyIds = this.propertyIds;
        model.rowVersion = this.rowVersion;
        model.isDeleted = this.isDeleted;
        model.leadContactId = this.leadContactId;
        model.contractorId = this.contractorId;
        model.potentialUnits = this.potentialUnits;
        model.contractorLeadContactId = this.contractorLeadContactId;
    }
}

export type ProjectAddEditModelDTO = {
    id?: string;
    number: string;
    name: string;
    createdDate: string;
    createdBy: string;
    regionalSalesManagerId: string;
    clientId: string;
    contractorId: string;
    leadContactId: string;
    status: number; // Project Status Enum Id.
    paymentStatus: number;
    propertyIds: string[];
    rowVersion: string;
    isDeleted: boolean;
    potentialUnits: number;
    contractorLeadContactId: string;
};
