import { action, IObservableArray, observable } from "mobx";
import { ModelBase } from "Core/Models/ModelBase";
import { ContactListItemDTO, ContactListItem } from "./ContactListItem";
import { NewContactModelDTO } from "../Projects/Contact";
import { ContactType } from "Custom/Models/Domain/Contacts/ContactType";

//Base class expects the model type and the DTO model type
//If you are not using a DTO just pass in undefined as the DTO
export class ContactListModel extends ModelBase<ContactListModel, ContactListDTO> {
    @observable
    public contacts: IObservableArray<ContactListItem> = observable([]);

    @action
    public clear() {
        this.contacts.clear();
    }

    //fromDto is required but you can leave it blank
    fromDto(model: ContactListDTO): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
    }

    @action
    public fromDTO(items: ContactListItemDTO[]) {
        this.contacts.clear();

        for (let i: number = 0; i < items.length; i++) {
            let newOne: ContactListItem = new ContactListItem();
            newOne.fromDto(items[i]);
            this.contacts.push(newOne);
        }
    }
    @action public updateContact(updated: NewContactModelDTO | undefined, contactType: ContactType) {
        if (updated !== undefined) {
            let contacts = this.contacts.slice();

            let record: ContactListItem | undefined = contacts.find((a) => a.sourceId === updated.id && a.contactType === contactType);

            if (record !== undefined) {
                record.contactName = updated.firstName + " " + updated.lastName;
                record.email = updated.email === null ? "" : updated.email!;
                record.firstName = updated.firstName;
                record.lastName = updated.lastName;
                record.isDeleted = updated.isDeleted;
                record.phone = updated.phone === null ? "" : updated.phone!;
                record.phone2 = updated.phone2 === null ? "" : updated.phone2!;
                record.position = updated.position;
                record.rowVersion = updated.rowVersion;

                this.contacts.replace(contacts);
            } // else should we be adding one??
        }
    }

    //toDto is required but you can leave it blank
    toDto(model: ContactListModel): void {}
}

export type ContactListDTO = {};
