import styled from "styled-components";
import { withProps } from "../../../Core/Utils/React/WithProps";
import { Button } from "@material-ui/core";
import addNewIcon from "Custom/Content/AddNewButtonIcon.svg";

export const AddNewBtn = styled(Button)`
    width: 150px;
    max-width: 150px;
    height: 30px; // height needs to include drop shadow as well
    background-color: transparent;
    background: url(${addNewIcon}) no-repeat;
    background-size: cover;
    color: ${(props) => props.theme.app.colors.white.main};
    text-align: left;
    font: normal normal normal 10px/14px Mont;
    letter-spacing: 0px;

    .btnText {
        margin-left: 20px;
        margin-top: -5px;
    }

    &:hover {
        background-color: transparent;
    }

    :disabled {
        opacity: 0.5;
        /* background-color: ${(props) => props.theme.app.colors.success.dark}; */
        color: white;
    }
`;
