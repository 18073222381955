import { DeviceAttributeBox } from "Custom/Views/Installations/TabAttributes.style";
import { useRef, useEffect } from "react";
import { useObserver } from "mobx-react-lite";
import { GutterSensTabContent } from "./GuttersensTabContent";

import { isNullOrUndefined } from "Custom/Utils/utils";

import { AttributeSummary } from "./AttributeSummary";
import { HrLine } from "../CustomComponents";
import { DynamicSettingsImageContainer } from "Custom/Components/DynamicSettings/DynamicSettingsImageContainer";
import { BandData } from "Custom/Components/DynamicSettings/BandData";
import { InstallationViewModel } from "./InstallationViewModel";
import { DeviceAlertActionModelDTO, DeviceConditionSetModelDTO } from "./TabConfigurationModel";
import { UnitNoteSection } from "../Units/UnitNote/UnitNoteSection";

interface TabAttributesProps {
    parentViewModel: InstallationViewModel;
    canAddNotes: boolean;
    noteAdded?: boolean;
    showNoteModal: (view: number) => void;
}

export function TabAttributes(props: TabAttributesProps) {
    const { parentViewModel, canAddNotes } = props;

    const notesRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        // tidy up after
        return () => {};
    }, []);

    let maxWorkingHeight: string = "";

    let alert1: string | undefined = undefined;
    let alert2: string | undefined = undefined;
    let alert3: string | undefined = undefined;
    let alert4: string | undefined = undefined;

    if (isNullOrUndefined(parentViewModel.statusData) == false) {
        const maxWorkingHeightValue: number = Math.min(parentViewModel.statusData!.side1_Height, parentViewModel.statusData!.side2_Height);
        const freeboardHeightValue: number = maxWorkingHeightValue * 0.7;
        maxWorkingHeight = maxWorkingHeightValue.toFixed(0);
        alert2 = freeboardHeightValue.toFixed(0);
        alert1 = (freeboardHeightValue * 0.9).toFixed(0);
        alert3 = (freeboardHeightValue * 1.1).toFixed(0);
        alert4 = parentViewModel.statusData!.install_Height.toFixed(0);
    }

    let bandData: BandData[] = [];

    // TODO CMR Need to choose between Condition sets and alerts
    const conditionSet: DeviceConditionSetModelDTO | undefined = parentViewModel.getConditionSet;
    if (conditionSet !== undefined) {
        for (let i: number = 0; i < conditionSet.items.length; i++) {
            const item = conditionSet.items[i];
            const bd: BandData = {
                id: item.id,
                name: item.name,
                type: item.type,
                value: item.value,
                units: item.units,
                backgroundColour: item.statusColour,
                textColour: item.statusTextColour,
                calculatedValue: item.calculatedValue,
            };
            bandData.push(bd);
        }
    }

    let alertActions: BandData[] = [];

    const alerts: DeviceAlertActionModelDTO | undefined = parentViewModel.getAlertActions;
    if (alerts !== undefined) {
        for (let i: number = 0; i < alerts.items.length; i++) {
            const item = alerts.items[i];
            const aa: BandData = {
                id: item.id,
                name: item.name,
                type: item.type,
                value: item.value,
                units: item.units,
                backgroundColour: item.alertColour,
                textColour: item.alertTextColour,
                calculatedValue: item.calculatedValue,
            };
            alertActions.push(aa);
        }
    }

    return useObserver(() => (
        <GutterSensTabContent>
            <DeviceAttributeBox>
                <DynamicSettingsImageContainer
                    alertActions={alertActions}
                    conditionSetData={bandData}
                    deviceStatusData={parentViewModel.statusData}
                    imageHeight={210}
                    title={"Condition Set"}
                    marginTop={0}
                />
                <HrLine />
                <AttributeSummary deviceStatusData={parentViewModel.statusData} />
                <HrLine />

                <UnitNoteSection
                    isLoading={parentViewModel.IsLoading}
                    unitNotes={parentViewModel.deviceNotes}
                    canAddNotes={canAddNotes}
                    setNotes={parentViewModel.setDeviceNotes}
                    deviceDbId={parentViewModel.model.device?.id!}
                />
            </DeviceAttributeBox>
        </GutterSensTabContent>
    ));
}
