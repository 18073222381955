import { InstallationStatusDataDTO } from "Custom/Models/Domain";
import { observable } from "mobx";
import { ModelBase } from "Core/Models/ModelBase";
import { IsNotEmpty, MaxLength } from "class-validator";
import { TestGradeDTO } from "./TestGrade";
import { DeviceUnitStatusDTO } from "./DeviceUnitStatus";
import { UnitVersionDTO } from "./UnitVersion";
import { DefaultUnitNote, UnitNoteDTO } from "./UnitNote";
import { UnitFirmwareDTO } from "./UnitFirmware";
import { ProjectDropdown } from "Custom/Models/Domain/Projects/ProjectDropdown";

export class DeviceUnitDataMinModel extends ModelBase<DeviceUnitDataMinModel, DeviceUnitDataMinDTO> {
    public id: string | null = null;
    @observable public deviceId: number = -1;
    @observable public deliveryDate: string | null = null;
    @observable public acceptanceDate: string | null = null;
    @observable public testGradeId: number = 1;
    @observable public versionId: number = 1;
    @observable public firmwareId: number = 1;
    @observable public currentUnitStatusId: number = 1;

    @observable public qrProjectId?: string | null;

    @observable public newNote: UnitNoteDTO = DefaultUnitNote();

    @observable
    @IsNotEmpty({ message: "Device name is required." })
    @MaxLength(50, { message: "Maximum name length is 50 characters" })
    public unitName: string = "";

    //fromDto is required but you can leave it blank
    fromDto(model: DeviceUnitDataMinDTO): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
        this.newNote = DefaultUnitNote();
        this.newNote.deviceDbId = this.deviceId;
    }

    //toDto is required but you can leave it blank
    toDto(model: DeviceUnitDataMinModel): void {}

    toRequestDto(): DeviceUnitDataMinDTO {
        return {
            id: this.id,
            deviceId: this.deviceId,
            deliveryDate: this.deliveryDate,
            acceptanceDate: this.acceptanceDate,
            testGradeId: this.testGradeId,
            versionId: this.versionId,
            currentUnitStatusId: this.currentUnitStatusId,
            unitName: this.unitName,
            firmwareId: this.firmwareId,
            qrProjectId: this.qrProjectId,
        };
    }

    fromInstallAndRelatedDto(payload: DeviceUnitDataMinDTO) {
        if (payload !== null && payload !== undefined) {
            this.id = payload.id;
            this.deviceId = payload.deviceId;
            this.deliveryDate = payload.deliveryDate;
            this.acceptanceDate = payload.acceptanceDate;
            this.testGradeId = payload.testGradeId;
            this.versionId = payload.versionId;
            this.currentUnitStatusId = payload.currentUnitStatusId;
            this.unitName = payload.unitName;
            this.firmwareId = payload.firmwareId;
            this.qrProjectId = payload.qrProjectId;
        } else {
            this.clear();
        }
    }

    clear() {
        this.id = null;
        this.deviceId = -1;
        this.deliveryDate = null;
        this.acceptanceDate = null;
        this.testGradeId = 1;
        this.versionId = 1;
        this.currentUnitStatusId = 1;
        this.newNote = DefaultUnitNote();
        this.unitName = "";
        this.qrProjectId = null;
    }
}

export interface DeviceUnitDataMinDTO {
    id: string | null;
    deviceId: number;
    deliveryDate: string | null;
    acceptanceDate: string | null;
    testGradeId: number;
    versionId: number;
    currentUnitStatusId: number;
    firmwareId: number;
    unitName: string;
    qrProjectId?: string | null;
}

export interface DeviceUnitDataMinDTOExt extends DeviceUnitDataMinDTO {
    testGrade: string;
    version: string;
    currentStatus: string;
    firmware: string;
}

export interface DeviceUnitDataDTO extends DeviceUnitDataMinDTO {
    // id: string | null;
    //deviceId: number;
    createdDate: string;
    createdBy: string;
    // deliveryDate: string | null;
    //acceptanceDate: string | null;
    //testGradeId: number;
    // versionId: number;
    //firmwareId: number;
    //currentUnitStatusId: number;
    //unitName: string;
}

export interface DeviceUnitDataAndRelatedDTO {
    unitData: DeviceUnitDataDTO;
    statusData: InstallationStatusDataDTO;
    notes: UnitNoteDTO[];
    testGrade: TestGradeDTO[];
    unitStatus: DeviceUnitStatusDTO[];
    unitVersion: UnitVersionDTO[];
    unitFirmware: UnitFirmwareDTO[];
    projects: ProjectDropdown[];
}
