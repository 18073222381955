import { isArrayNullOrUndefinedOrEmpty } from "./../Utils/array";
// Libs

import * as MobX from "mobx";

import { ArrayMinSize, IsEmail, IsNotEmpty } from "class-validator";
import { ModelBase } from "Core/Models/ModelBase";
import { Role } from ".";
import { ClientMinDTO, ContractorMinDTO } from "./Domain";

// App
export class User extends ModelBase<User, UserDTO> {
    public id: string = "";
    @MobX.observable
    @IsNotEmpty({ message: "You must enter a firstname" })
    public firstName: string = "";
    @MobX.observable
    @IsNotEmpty({ message: "You must enter a lastname" })
    public lastName: string = "";

    @MobX.observable
    @IsNotEmpty({ message: "You must enter an email address" })
    @IsEmail({}, { message: "Email address is not in a valid format" })
    public email: string = "";

    @MobX.observable
    @IsNotEmpty({ message: "You must choose a role" })
    public roles: string = "";

    @MobX.observable
    public userClient: string = "";

    @MobX.observable
    public userContractor: string = "";

    @MobX.observable
    public roleIds: string = "";

    @MobX.observable public rowVersion: string = "";
    @MobX.observable public isDeleted: boolean = false;
    @MobX.observable @ArrayMinSize(1, { message: "You must choose a role" }) public userRoles = MobX.observable<Role>([]);
    @MobX.observable public lastLoggedIn: string = "";

    constructor(id: string) {
        super();
        this.id = id;
    }

    @MobX.computed
    public get displayName(): string {
        return this.firstName + " " + this.lastName;
    }

    @MobX.action
    public fromDto = (model: UserDTO) => {
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                this[key] = model[key];
            }
        }
    };

    public toDto = (model: UserDTO) => {
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                model[key] = this[key];
            }
        }
    };
}

export interface UserDTO {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    roles: string;
    roleIds: string;
    rowVersion: string;
    isDeleted: boolean;
    userRoles: Role[];
    userClient: string;
    userContractor: string;

    lastLoggedIn: string;
}

export interface UserRelatedDTO {
    roles: Role[];
    clients: ClientMinDTO[];
    contractors: ContractorMinDTO[];
}
export interface UserAndRelatedDTO extends UserRelatedDTO {
    user: User;
}
