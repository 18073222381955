import { ProjectsAndRelatedForList } from "Custom/Models/Domain/Projects/ProjectsAndRelatedForList";
import { KeyValuePair } from "Core/Models/KeyValuePair";
import { DropDownListItem } from "./../../Models/API/DropDownListItem";
import { ProjectStatusDropDownListItem } from "Custom/Models/API/ProjectStatusDropDownListItem";
import { ProjectListItemModel, ProjectListItemModelDTO } from "Custom/Models/Domain/Projects/ProjectListItemModel";
import { ViewModelBase } from "Core/ViewModels/ViewModelBase";
import { FieldType, isEmptyOrWhitespace, sortByString } from "Core/Utils/Utils";
import { action, computed, observable, runInAction } from "mobx";
import { StoresInstance } from "Custom/Stores";
import { ProjectStore } from "Custom/Stores/Domain";
import { DeleteRequest, DeleteUserRequest } from "../User/DeleteUserRequest";
import { ApiResult } from "Core/Models";
import { Server } from "Custom/Globals/AppUrls";
import { FileDownloadResponseDTO } from "../Installations/FileDownloadResponseDTO";
import { debounce } from "@material-ui/core";
import { DEBOUNCE_VALUE_MS } from "Custom/Globals/Globals";
import { ProjectPaymentStatusDropdownListItem } from "Custom/Models/API/ProjectPaymentStatusDropdownListItem";

//extend viewmodel base and passing your model as the generic type
export class ProjectListViewModel extends ViewModelBase<ProjectListItemModel> {
    //Singleton instance of class
    private static _instance: ProjectListViewModel;
    @observable public IsDownloading: boolean = false;
    @observable public projectCount: number = 0;

    @observable public pageSize: number = 50;
    @observable public currentPage: number = 0;

    private projectStore: ProjectStore = StoresInstance.domain.ProjectStore;
    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    @observable public errorMessage: string = "";
    @observable public projects: any = [];

    // #region Project Filters

    @observable public searchString: string = "";
    @observable public filterSearchString: string = "";

    @observable public projectStatusCount = observable<Map<ProjectStatusDropDownListItem, number>>([]);

    @observable public projectStatuses = observable<ProjectStatusDropDownListItem>([]);

    @observable public projectPaymentStatuses = observable<ProjectPaymentStatusDropdownListItem>([]);

    @observable public statusFilter = observable<number>([]);
    @observable public filterStatusFilter = observable<number>([]);

    @observable public paymentStatusFilter = observable<number>([]);
    @observable public filterPaymentStatusFilter = observable<number>([]);

    @observable public clients = observable<DropDownListItem>([]);
    @observable public clientsFilter = observable<string>([]);
    @observable public filterClientsFilter = observable<string>([]);

    @observable public regManagers = observable<DropDownListItem>([]);
    @observable public regManagerFilter = observable<string>([]);
    @observable public filterRegManagerFilter = observable<string>([]);

    // #endregion Project Filters

    // #region Project Sorting

    // By default this will be the first column (by index) and in ascending order.
    @observable public sortColumnId: number = 0;
    @observable public sortDirection: any = "asc";

    // #endregion Project Sorting

    @computed
    get getAllowedProjects(): ProjectListItemModel[] {
        const filterBySearchString = (project: ProjectListItemModel): boolean => {
            if (isEmptyOrWhitespace(this.filterSearchString)) {
                return true;
            }

            const searchStringUpperCase = this.filterSearchString.toUpperCase();

            return (
                project.clientName.toUpperCase().includes(searchStringUpperCase) ||
                project.clientLeadContact.toUpperCase().includes(searchStringUpperCase) ||
                // project.contractorName.toUpperCase().includes(searchStringUpperCase) ||
                // project.contractorLeadContact.toUpperCase().includes(searchStringUpperCase) ||
                project.name.toUpperCase().includes(searchStringUpperCase) ||
                project.number.toUpperCase().includes(searchStringUpperCase)
            );
        };

        const filterByProjectStatus = (project: ProjectListItemModel): boolean => {
            if (this.filterStatusFilter.length === this.projectStatuses.length) {
                return true;
            }

            return this.filterStatusFilter.indexOf(project.status) > -1;
        };

        const filterByProjectPaymentStatus = (project: ProjectListItemModel): boolean => {
            if (this.filterPaymentStatusFilter.length === this.projectStatuses.length) {
                return true;
            }

            return this.filterPaymentStatusFilter.indexOf(project.paymentStatus) > -1;
        };

        const filterByClient = (project: ProjectListItemModel): boolean => {
            if (this.filterClientsFilter.length === this.clients.length) {
                return true;
            }

            return this.filterClientsFilter.indexOf(project.clientId) > -1;
        };

        const filterByRegionalSalesManager = (project: ProjectListItemModel): boolean => {
            if (this.filterRegManagerFilter.length === this.regManagers.length) {
                return true;
            }

            return this.filterRegManagerFilter.indexOf(project.regionalSalesManagerId) > -1;
        };

        return this.projectStore.getAll
            .filter((a) => a.isDeleted === false)
            .filter((a) => filterByProjectStatus(a))
            .filter((a) => filterByProjectPaymentStatus(a))
            .filter((a) => filterByClient(a))
            .filter((a) => filterByRegionalSalesManager(a))
            .filter((a) => filterBySearchString(a));
    }

    // #region Client Filter

    @computed
    get getClientsKVPair(): KeyValuePair[] {
        const retVal: KeyValuePair[] = [];

        for (let i: number = 0; i < this.clients.length; i++) {
            retVal.push({
                key: this.clients[i].displayName,
                value: this.clients[i].id,
            });
        }

        return retVal;
    }

    public getClientFilter = () => {
        return computed(() => this.clientsFilter);
    };

    @action
    public setClientFilter = (values: string[]) => {
        this.clientsFilter.replace(values);
        this.setFilterClient(values);
    };

    private setFilterClient = debounce(
        action((values: string[]) => {
            this.filterClientsFilter.replace(values);
        }),
        DEBOUNCE_VALUE_MS,
    );

    // #endregion Client Filter

    // #region Regional Manager Filter

    @computed
    get getRegManagerKVPair(): KeyValuePair[] {
        const retVal: KeyValuePair[] = [];

        for (let i: number = 0; i < this.regManagers.length; i++) {
            retVal.push({
                key: this.regManagers[i].displayName,
                value: this.regManagers[i].id,
            });
        }

        return retVal;
    }

    public getRegManagerFilter = () => {
        return computed(() => this.regManagerFilter);
    };

    @action
    public setRegManagerFilter = (values: string[]) => {
        this.regManagerFilter.replace(values);
        this.setFilterRegManager(values);
    };

    private setFilterRegManager = debounce(
        action((values: string[]) => {
            this.filterRegManagerFilter.replace(values);
        }),
        DEBOUNCE_VALUE_MS,
    );

    // #endregion Regional Manager Filter

    //#region Project Payment Status Filter
    @action
    public setProjectPaymentStatusFilter = (values: number[]) => {
        this.paymentStatusFilter.replace(values);
        this.setFilterProjectPaymentStatus(values);
    };

    private setFilterProjectPaymentStatus = debounce(
        action((values: number[]) => {
            this.filterPaymentStatusFilter.replace(values);
        }),
        DEBOUNCE_VALUE_MS,
    );

    @computed
    get getProjectPaymentStatusKVPair(): KeyValuePair[] {
        const retVal: KeyValuePair[] = [];

        for (let i: number = 0; i < this.projectPaymentStatuses.length; i++) {
            retVal.push({
                key: this.projectPaymentStatuses[i].displayName + " (" + this.projectPaymentStatuses[i].count + ")",
                value: this.projectPaymentStatuses[i].id,
            });
        }

        return retVal;
    }

    public getPaymentStatusFilter = () => {
        return computed(() => this.paymentStatusFilter);
    };

    // #endregion Project Payment Status Filter
    // #region Project Status Filter

    @computed
    get getProjectStatusKVPair(): KeyValuePair[] {
        const retVal: KeyValuePair[] = [];

        for (let i: number = 0; i < this.projectStatuses.length; i++) {
            retVal.push({
                key: this.projectStatuses[i].displayName + " (" + this.projectStatuses[i].count + ")",
                value: this.projectStatuses[i].enumId,
            });
        }

        return retVal;
    }

    public getStatusFilter = () => {
        return computed(() => this.statusFilter);
    };

    @action
    public setProjectStatusFilter = (values: number[]) => {
        this.statusFilter.replace(values);
        this.setFilterProjectStatus(values);
    };

    private setFilterProjectStatus = debounce(
        action((values: number[]) => {
            this.filterStatusFilter.replace(values);
        }),
        DEBOUNCE_VALUE_MS,
    );

    // #endregion Project Status Filter

    // #region Search String Filter

    public getSearchString = () => {
        return computed(() => this.searchString);
    };

    @action
    public setSearchString = (value: string) => {
        this.searchString = value;
        this.setFilterSearchString(value);
    };

    private setFilterSearchString = debounce(
        action((value: string) => {
            this.filterSearchString = value;
        }),
        DEBOUNCE_VALUE_MS,
    );

    // #endregion Search String Filter

    // #region Project Sorting

    @action
    public setOrderChange = (columnId: number, direction: any) => {
        this.sortColumnId = columnId;
        this.sortDirection = direction;
    };

    // #endregion Project Sorting

    @action
    public setRowsPerPage(rows: number) {
        this.pageSize = rows;
    }

    @action
    public setPage = (page: number) => {
        this.currentPage = page;
    };

    @action
    public getProject = (id: string): any => {
        let retVal: any = null;

        if (this.projects.length > 0) {
            retVal = this.projects.find((a: any) => a.id === id);
        }

        return retVal;
    };

    @action
    public setProject = (_newProject: any, someBoolean: boolean): any => {
        /* TODO */
    };

    @action
    public setProjects = (projects: ProjectListItemModelDTO[]): any => {
        this.projectStore.setProjects(projects);
    };

    @action
    public setClients = (clients: DropDownListItem[]): any => {
        // Remove any not in the new list and from the filters
        // Add any missing to this list and the filters
        // Don't touch the filters, since this needs to persist.
        for (let x: number = 0; x < clients.length; x++) {
            const client: DropDownListItem | undefined = this.clients.find((a) => a.id === clients[x].id);

            if (client !== undefined) {
                client.displayName = clients[x].displayName;
            } else {
                this.clients.push(clients[x]);
                this.clientsFilter.push(clients[x].id);
                this.filterClientsFilter.push(clients[x].id);
            }
        }
    };

    @action
    public setRegManagers = (managers: DropDownListItem[]): any => {
        // Remove any not in the new list and from the filters
        // Add any missing to this list and the filters
        // Don't touch the filters, since this needs to persist.

        for (let x: number = 0; x < managers.length; x++) {
            const manager: DropDownListItem | undefined = this.regManagers.find((a) => a.id === managers[x].id);

            if (manager !== undefined) {
                manager.displayName = managers[x].displayName;
            } else {
                this.regManagers.push(managers[x]);
                this.regManagerFilter.push(managers[x].id);
                this.filterRegManagerFilter.push(managers[x].id);
            }
        }
    };

    @action
    public setProjectStatuses = (projectStatuses: ProjectStatusDropDownListItem[]): void => {
        for (let index: number = 0; index < projectStatuses.length; index++) {
            const projectStatus: ProjectStatusDropDownListItem | undefined = this.projectStatuses.find((ps) => ps.id === projectStatuses[index].id);

            if (projectStatus !== undefined) {
                this.projectStatuses.remove(projectStatus);
                this.projectStatuses.push(projectStatuses[index]);
            } else {
                this.projectStatuses.push(projectStatuses[index]);
                this.statusFilter.push(projectStatuses[index].enumId);
                this.filterStatusFilter.push(projectStatuses[index].enumId);
            }
        }
    };

    @action
    public setProjectPaymentStatuses = (statuses: ProjectPaymentStatusDropdownListItem[]): void => {
        for (let index: number = 0; index < statuses.length; index++) {
            const projectPaymentStatus: ProjectPaymentStatusDropdownListItem | undefined = this.projectPaymentStatuses.find((ps) => ps.id === statuses[index].id);

            if (projectPaymentStatus !== undefined) {
                this.projectPaymentStatuses.remove(projectPaymentStatus);
                this.projectPaymentStatuses.push(statuses[index]);
            } else {
                this.projectPaymentStatuses.push(statuses[index]);
                this.paymentStatusFilter.push(statuses[index].id);
                this.filterPaymentStatusFilter.push(statuses[index].id);
            }
        }
    };

    @action
    public deleteProjectStatus = (id: string): void => {
        const projectStatus: ProjectStatusDropDownListItem | undefined = this.projectStatuses.find((ps) => ps.id === id);

        if (projectStatus !== undefined) {
            this.projectStatuses.remove(projectStatus);
            this.statusFilter.remove(projectStatus.enumId);
            this.filterStatusFilter.remove(projectStatus.enumId);
        }
    };

    @action
    public deleteProjectPaymentStatus = (id: number): void => {
        /*  const projectStatus: ProjectStatusDropDownListItem | undefined = this.projectStatuses.find((ps) => ps.id === id);

        if (projectStatus !== undefined) {
            this.projectStatuses.remove(projectStatus);
        } */
        // alert("TODO deleteProjectPaymentStatus in ProjectListViewModel");
    };

    @action
    public loadProjectsAsync = async (): Promise<any> => {
        const apiResult = await this.projectStore.loadProjectListAsync().then((result) => {
            runInAction(() => {
                if (result.wasSuccessful === true) {
                    this.setClients(result.payload.clients);
                    this.setRegManagers(result.payload.regionalSalesManagers);
                    this.setProjectStatuses(result.payload.projectStatuses);
                    this.setProjectPaymentStatuses(result.payload.projectPaymentStatuses);
                }
            });
        });
        return apiResult;
    };

    constructor() {
        //Pass in a new instance of your model
        //By passing in true as the second parameter, we make this model undoable which means we can use save and reset options on the model
        //If you make a change to the model you need to persist it with a saveModel() call
        //If you make changes to your model you can revert it back by calling resetModel()
        super(new ProjectListItemModel(), false);
        //EN - Haven't figured out how to make this call work from the base model yet
        //This is only needed if you make use of the validation decorators
        this.setDecorators(ProjectListItemModel);
    }

    //isValid will check all fields to make sure they are in a valid state.
    public doSubmit = async (e: any) => {
        e.preventDefault();

        if (this.isModelValid()) {
            //Do stuff here
            this.errorMessage = "Form is valid";
        } else {
            this.errorMessage = "Form is not valid";
        }
    };

    //This must be present in your viewmodel. Just return true if you dont need to validate anything.
    //keyof BlankModel & string lets you add type checking to the fieldName
    //I am using the validator package to make checking easier https://www.npmjs.com/package/validator
    public isFieldValid(fieldName: keyof FieldType<ProjectListItemModel>): boolean {
        const { isValid, errorMessage } = this.validateDecorators(fieldName);

        //You need to this two properties after validation
        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    @action
    public deleteProject = async (deletedProjectId: string): Promise<ApiResult<ProjectListItemModelDTO[]>> => {
        const request: DeleteRequest = {
            id: deletedProjectId,
        };
        const apiResult = await this.Post<ProjectListItemModelDTO[]>(Server.Api.Project.deleteProject, request);

        if (apiResult.wasSuccessful) {
            runInAction(() => {
                const projects: ProjectListItemModelDTO[] = apiResult.payload;
                this.setProjects(projects);
            });
        }
        return apiResult;
    };

    @action
    public downloadProjectsCommunicationCSV = async (): Promise<ApiResult<FileDownloadResponseDTO>> => {
        let apiResult: ApiResult<FileDownloadResponseDTO> = {
            wasSuccessful: false,
            errors: [],
            headers: "",
            payload: { fileName: "", stringFile: "", contentType: "", byteFile: undefined },
        };

        if (this.IsDownloading === false && this.IsLoading === false) {
            this.IsDownloading = true;

            const request = {
                searchString: this.searchString,
                clients: this.clientsFilter,
                regManagers: this.regManagerFilter,
                statuses: this.statusFilter,
            };

            apiResult = await this.Post<FileDownloadResponseDTO>(Server.Api.Project.downloadProjectsCommunicationsAsCsv, request);

            if (apiResult.wasSuccessful === true) {
                //Do stuff here
                runInAction(() => {
                    runInAction(() => {
                        this.IsDownloading = false;
                    });
                });
            } else {
                runInAction(() => {
                    this.IsDownloading = false;
                });
            }

            let fileName: string = "test.csv";

            const file: string = apiResult.payload.stringFile;
            const type: string = apiResult.payload.contentType;
            fileName = apiResult.payload.fileName;

            const url = window.URL.createObjectURL(new Blob([file], { type }));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
        }

        return apiResult;
    };

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
