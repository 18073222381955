import { TextareaAutosize, Fade, Typography, Checkbox } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import { ProjectCommunicationViewModel } from "./ProjectCommunicationViewModel";
import { ProjectDetailsViewModel } from "../ProjectDetailsViewModel";
import { Container } from "Custom/StylesAppSpecific/AppStyling";
import { ProjectNote } from "./Note/ProjectNote";
import { ProjectNoteList } from "./Note/ProjectNoteList";
import { ProjectNoteSectionContainer } from "./ProjectCommunication.style";
import { ContactModel, ProjectCommunicationModel } from "Custom/Models/Domain";
import { PropertyNotesContainer } from "../Properties/PropertyNotes/PropertyNotes.styled";
import { BaseModal } from "Custom/Components/Modal/BaseModal";
import { DeleteModalDialogPaper } from "Custom/Components/Modal/DeleteModal.styles";
import { AcceptBtn } from "Custom/StylesAppSpecific/Controls/AcceptBtn";
import { CancelBtn } from "Custom/StylesAppSpecific/Controls/CancelBtn";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { Moment } from "moment";
import { AddCommunicationBox } from "./Note/ProjectNote.style";
import { isValidDateString } from "Custom/Utils/utils";

interface IProps {
    viewModel: ProjectDetailsViewModel;
}

export const ProjectCommunicationTab: React.FC<IProps> = ({ viewModel }) => {
    const [internalViewModel] = useState(() => new ProjectCommunicationViewModel());
    const [open, setOpen] = React.useState(false);
    const [newCommunication, setNewCommunication] = React.useState("");
    const [newFollowupDate, setFollowUpDate] = React.useState<Moment | null>(null);
    const [newisResolved, setIsResolved] = React.useState<boolean>(false);
    const [newResolvedDate, setResolvedDate] = React.useState<Moment | null>(null);

    const [newResolvedBy, setResolvedBy] = React.useState<string | undefined>(undefined);

    const [editId, setEditId] = React.useState<string | undefined>(undefined);

    //useEffect below only gets run on initial render
    useEffect(() => {
        setNewCommunication("");
        setFollowUpDate(null);
        setOpen(false);
        setEditId(undefined);
        setResolvedDate(null);
        setIsResolved(false);
        setResolvedBy(undefined);

        // *optional* passing the router into the viewmodel so we can navigate from there
        // console.log("First render");
        //This gets run when the page is exited
        return () => {
            // console.log("Unmounting");
        };
    }, []);

    const getLeadContactText = (viewModel: ProjectDetailsViewModel): any => {
        let displayName: string = "";
        let position: string = "";
        let email: string = "";
        let phone: string = "";

        if (viewModel !== undefined) {
            const leadContact: ContactModel | undefined = viewModel.getLeadContact;
            if (viewModel.getLeadContact !== undefined) {
                displayName = leadContact?.displayName!;
                position = " - " + leadContact?.position! + ". ";

                if (leadContact?.email && leadContact?.email.trim().length > 0) {
                    email = leadContact?.email!.trim();
                }

                if (leadContact?.phone && leadContact?.phone.trim().length > 0) {
                    phone = "    " + leadContact?.phone!.trim();
                } else if (leadContact?.phone2 && leadContact?.phone2.trim().length > 0) {
                    phone = "    " + leadContact?.phone2!.trim();
                }
            }
        }

        return (
            <div className="leadcontact-wrapper">
                {displayName.length > 0 && <div className="leadcontact-start">{displayName}</div>}
                {position.length > 0 && <div className="leadcontact-mid">{position}</div>}
                {email.length > 0 && <div className="leadcontact-mid">{email}</div>}
                {phone.length > 0 && <div className="leadcontact-mid">{phone}</div>}
            </div>
        );
    };

    const leadContactRow: HTMLElement = getLeadContactText(viewModel);

    const handleAddClick = (id: string) => {
        setOpen(true);
    };

    const handleSubmitNewCommunication = (e: React.FormEvent) => {
        e.preventDefault();
        const ret = viewModel.upsertNewCommunicationNote(newCommunication, newFollowupDate === null ? undefined : newFollowupDate.toISOString());

        ret.then((result) => {
            setNewCommunication("");
            setFollowUpDate(null);
            setEditId(undefined);
            setResolvedBy(undefined);
        });
        setOpen(false);
    };

    const handleSubmitEditCommunication = (e: React.FormEvent) => {
        e.preventDefault();
        const ret = viewModel.editCommunicationNote(
            editId!,
            newCommunication,
            newFollowupDate === null ? undefined : newFollowupDate.toISOString(),
            newisResolved,
            newResolvedDate === null ? undefined : newResolvedDate.toISOString(),
            newResolvedBy,
        );

        ret.then((result) => {
            setNewCommunication("");
            setFollowUpDate(null);
            setEditId(undefined);
            setIsResolved(false);
            setResolvedDate(null);
            setResolvedBy(undefined);
        });
        setOpen(false);
    };

    const handleCommunicationChangeChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setNewCommunication(e.target.value);
    };

    const onClose = () => {
        setNewCommunication("");
        setFollowUpDate(null);
        setEditId(undefined);
        setIsResolved(false);
        setResolvedDate(null);
        setResolvedBy(undefined);
        setOpen(false);
    };

    /*     const handleFollowUpDateChange = (date: any, value?: string | null) => {
        setFollowUpDate(date);
    }; */

    const handleFollowUpDateChange = (date: any, value: string | null | undefined) => {
        const mom: Moment = moment(value, "DD/MM/YYYY");
        if (value !== null && value !== undefined) {
            if (isValidDateString(value!) === true && value!.length === 10) {
                setFollowUpDate(date);
            }
        } else {
            setFollowUpDate(null);
        }
    };

    const handleResolvedDateChange = (date: any, value: string | null | undefined) => {
        const mom: Moment = moment(value, "DD/MM/YYYY");
        if (value !== null && value !== undefined) {
            if (isValidDateString(value!) === true && value!.length === 10) {
                setResolvedDate(date);
            }
        } else {
            setResolvedDate(null);
        }
    };

    const setOpenAddModal = () => {
        setOpen(true);
    };

    const setEditCommuniction = (id: string) => {
        const note: ProjectCommunicationModel | undefined = viewModel.getCommunicationList.find((a) => a.id == id);
        if (note !== undefined) {
            //alert(id + " - " + note.id! + " - " + note.note);
            setNewCommunication(note.note);
            setFollowUpDate(note.followUpDate === undefined || note.followUpDate === null ? null : moment.utc(note.followUpDate!));
            setEditId(id);

            setIsResolved(note.isResolved);
            setResolvedDate(note.resolvedDate === undefined || note.resolvedDate === null ? null : moment.utc(note.resolvedDate!));

            setOpen(true);
        }
    };

    let addeditModalTitle: string = editId === undefined ? "Add new action" : "Edit action";
    let addeditModalSection: string = editId === undefined ? "Add action below" : "Edit action below";
    let addeditModalAcceptText: string = editId === undefined ? "Add Action" : "Save";
    let addeditModalAccept: any = editId === undefined ? handleSubmitEditCommunication : handleSubmitEditCommunication;

    return (
        <Container>
            <Fade in timeout={1000}>
                <>
                    <BaseModal
                        open={open}
                        onClose={onClose}
                        title={addeditModalTitle}
                        PaperComponent={DeleteModalDialogPaper}
                        actions={
                            <>
                                <AcceptBtn onClick={addeditModalAccept} color="secondary" autoFocus variant="contained">
                                    {addeditModalAcceptText}
                                </AcceptBtn>
                                <CancelBtn onClick={onClose}>Cancel</CancelBtn>
                            </>
                        }
                    >
                        <AddCommunicationBox>
                            <div>{addeditModalSection}</div>
                            <TextareaAutosize value={newCommunication} onChange={handleCommunicationChangeChange} className="note-textarea" />

                            <div style={{ paddingTop: "15px" }}>Follow up date</div>
                            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                <KeyboardDatePicker
                                    placeholder="Follow up date"
                                    label=""
                                    format="DD/MM/YYYY"
                                    value={newFollowupDate}
                                    onChange={handleFollowUpDateChange}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                            {editId !== undefined && (
                                <div style={{ display: "flex", width: "100%", flexDirection: "row", paddingTop: "10px" }}>
                                    <Typography style={{ fontSize: "14px", marginTop: "8px" }}>Resolve note</Typography>
                                    <Checkbox
                                        checked={newisResolved}
                                        className="form-control"
                                        name={"is Resolved"}
                                        onChange={() => {
                                            let temp: boolean = !newisResolved;
                                            setIsResolved(temp);

                                            if (temp === false) {
                                                setResolvedDate(null);
                                                setResolvedBy(undefined);
                                            } else {
                                                setResolvedDate(moment.utc());
                                                setResolvedBy(undefined);
                                            }
                                        }}
                                        value={newisResolved === true ? 1 : 0}
                                    />
                                </div>
                            )}
                            {newisResolved === true && (
                                <div style={{ paddingBottom: "10px" }}>
                                    <div style={{ paddingTop: "15px" }}>Resolved Date</div>
                                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                        <KeyboardDatePicker
                                            placeholder="Resolved date"
                                            label=""
                                            format="DD/MM/YYYY"
                                            value={newResolvedDate}
                                            onChange={handleResolvedDateChange}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                            )}
                        </AddCommunicationBox>
                    </BaseModal>

                    <ProjectNoteSectionContainer>
                        <PropertyNotesContainer>
                            <Typography variant="h1" component="h3">
                                Lead Contact
                            </Typography>
                            {leadContactRow}
                        </PropertyNotesContainer>
                    </ProjectNoteSectionContainer>
                    <ProjectNoteSectionContainer>
                        <ProjectNote viewModel={viewModel} setOpenAddModal={setOpenAddModal} />
                    </ProjectNoteSectionContainer>
                    <ProjectNoteSectionContainer>
                        <div style={{ display: "flex", flexDirection: "column", flex: "1 0 0" }}>
                            <ProjectNoteList viewModel={viewModel} setOpenAddModal={setOpenAddModal} setEditCommuniction={setEditCommuniction} />
                        </div>
                    </ProjectNoteSectionContainer>
                </>
            </Fade>
        </Container>
    );
};
