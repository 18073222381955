export enum SensorType {
    Unknown = 0,
    Battery = 1,
    Ultrasound = 2,
    Laser = 3,
    RainGauge = 4,
    Temperature = 5,
    Camera = 6,
    Calibrated = 7,
    SignalStrength = 8,
}

export class SensorTypeHelpers {
    public static getText = (sensorType: SensorType) => {
        switch (sensorType) {
            case SensorType.Battery: {
                return "Battery";
            }
            case SensorType.Calibrated: {
                return "Calibrated";
            }
            case SensorType.Camera: {
                return "Camera";
            }
            case SensorType.Laser: {
                return "Laser";
            }
            case SensorType.RainGauge: {
                return "Rain Gauge";
            }
            case SensorType.Temperature: {
                return "Temperature";
            }
            case SensorType.Ultrasound:
                return "Ultrasound";

            case SensorType.Unknown:
            default: {
                return "Unknown";
            }
        }
    };

    public static getOptions = (ignoreUnknown: boolean = false) => {
        const options: {
            label: string;
            value: string;
        }[] = [];

        for (const key in SensorType) {
            const keyAsInt = parseInt(key);

            if (isNaN(keyAsInt) === false) {
                const projectGuaranteeType = keyAsInt as SensorType;

                if (ignoreUnknown === false || projectGuaranteeType !== SensorType.Unknown) {
                    options.push({
                        label: SensorTypeHelpers.getText(projectGuaranteeType),
                        value: keyAsInt.toString(),
                    });
                }
            }
        }

        return options;
    };
}
