import { ViewModelBase } from "Core/ViewModels/ViewModelBase";
import { DeviceAlertActionModelDTO, DeviceConditionSetModelDTO, TabConfigurationModel, TabConfigurationModelDTO } from "./TabConfigurationModel";
import { FieldType } from "Core/Utils/Utils";
import { action, computed, observable, runInAction } from "mobx";
import { Server } from "Custom/Globals/AppUrls";
import { ApiResult } from "Core/Models";
import { AdminAlertActionTabModelDTO } from "../Admin/AdminAlertActionTabModel";
import { AdminConditionTabModelDTO } from "../Admin/AdminConditionTabModel";
import { ConditionSetViewModel } from "./Condition/ConditionSetViewModel";
import { ConditionItemModelDTO } from "../Admin/Condition/ConditionItemModel";
import { ConditionAddEditDetailModelDTO } from "../Admin/Condition/ConditionAddEditDetailModel";
import { ConditionItemViewModel } from "../Admin/Condition/ConditionItemViewModel";
import { AlertActionViewModel } from "./AlertAction/AlertActionViewModel";
import { AlertActionItemModelDTO } from "../Admin/AlertAction/AlertActionItemModel";
import { AlertActionItemViewModel } from "../Admin/AlertAction/AlertActionItemViewModel";
import { AlertActionAddEditDetailModelDTO } from "../Admin/AlertAction/AlertActionAddEditDetailModel";
import { ConditionType, ConditionUnits, DrainageType, InstallationStatusDataDTO, RoofType } from "Custom/Models/Domain";
import { GenericIdRequest } from "Custom/Models";

//extend viewmodel base and passing your model as the generic type
export class TabConfigurationViewModel extends ViewModelBase<TabConfigurationModel> {
    //Singleton instance of class
    private static _instance: TabConfigurationViewModel;

    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    @observable public errorMessage: string = "";

    @observable public keyGutterCollapsed: boolean = true;
    @observable public conditionSetCollapsed: boolean = true;
    @observable public alertActionCollapsed: boolean = true;

    @observable public adminConditionSetOptions: AdminConditionTabModelDTO[] = [];
    @observable public adminAlertActionOptions: AdminAlertActionTabModelDTO[] = [];

    @observable public deviceConditionSets: ConditionSetViewModel[] = [];
    @observable public deviceAlertActions: AlertActionViewModel[] = [];

    @observable public drainageType: DrainageType = DrainageType.Gravity;

    @observable public statusData: InstallationStatusDataDTO | undefined = undefined;

    @computed public get getConditionSetOptions(): AdminConditionTabModelDTO[] {
        let temp: AdminConditionTabModelDTO[] = this.adminConditionSetOptions.slice();

        if (this.statusData) {
            temp = temp.filter((a) => a.drainageType <= this.statusData!.drainageType);

            // Need our working height.
            let maxWorkingHeightValue: number = Math.min(this.statusData.side1_Height, this.statusData.side2_Height);

            if (this.statusData.roofType === RoofType.Flat) {
                maxWorkingHeightValue = this.statusData.maxHeight;
            }
            const freeboardHeightValue: number = maxWorkingHeightValue * 0.7;

            let retVal: AdminConditionTabModelDTO[] = [];
            for (let i: number = 0; i < temp.length; i++) {
                let valid: boolean = this.isAdminConditionSetValid(maxWorkingHeightValue, freeboardHeightValue, this.statusData.p1, this.statusData.p2, temp[i]);

                if (valid === true) {
                    retVal.push(temp[i]);
                }
            }
            return retVal;
        }
        // else just return the gravity ones since they work for everyone

        return temp.filter((a) => a.drainageType <= DrainageType.Gravity);
    }

    @action public setKeyGutterCollapsed = (state: boolean) => {
        this.keyGutterCollapsed = state;
    };

    @action public setConditionSetCollapsed = (state: boolean) => {
        this.conditionSetCollapsed = state;
    };
    @action public setAlertActionCollapsed = (state: boolean) => {
        this.alertActionCollapsed = state;
    };

    @action public clear = () => {
        this.alertActionCollapsed = true;
        this.conditionSetCollapsed = true;
        this.keyGutterCollapsed = true;
    };

    public isAdminConditionSetValid = (maxWorkingHeightValue: number, freeboardHeightValue: number, p1: number, p2: number, itemToCheck: AdminConditionTabModelDTO): boolean => {
        let retVal: boolean = true;

        for (let j: number = 0; j < itemToCheck.items.length; j++) {
            let tempItem: ConditionItemModelDTO = itemToCheck.items[j];
            let itemValue: number = 0;

            if (tempItem.type === ConditionType.H) {
                itemValue = maxWorkingHeightValue;
            } else if (tempItem.type === ConditionType.FB) {
                itemValue = freeboardHeightValue;
            } else if (tempItem.type === ConditionType.P1) {
                itemValue = p1;
            } else if (tempItem.type === ConditionType.P2) {
                itemValue = p2;
            }

            if (tempItem.units == ConditionUnits.MM) {
                itemValue = itemValue + tempItem.value;
            } else {
                // it equals %
                let percentValue: number = tempItem.value / 100;
                itemValue = itemValue + itemValue * percentValue;
            }
            let calculatedValue: number = itemValue;

            if (calculatedValue < 0 || calculatedValue > maxWorkingHeightValue) {
                retVal = false;
            }
        }

        return retVal;
    };

    @computed public get getAlertActionOptions(): AdminAlertActionTabModelDTO[] {
        let temp: AdminAlertActionTabModelDTO[] = this.adminAlertActionOptions.slice();

        if (this.statusData) {
            temp = temp.filter((a) => a.drainageType <= this.statusData!.drainageType);

            // Need our working height.
            let maxWorkingHeightValue: number = Math.min(this.statusData.side1_Height, this.statusData.side2_Height);

            if (this.statusData.roofType === RoofType.Flat) {
                maxWorkingHeightValue = this.statusData.maxHeight;
            }
            const freeboardHeightValue: number = maxWorkingHeightValue * 0.7;

            let retVal: AdminAlertActionTabModelDTO[] = [];
            for (let i: number = 0; i < temp.length; i++) {
                let valid: boolean = this.isAdminAlertActionValid(maxWorkingHeightValue, freeboardHeightValue, this.statusData.p1, this.statusData.p2, temp[i]);

                if (valid === true) {
                    retVal.push(temp[i]);
                }
            }
            return retVal;
        }
        // else just return the gravity ones since they work for everyone

        return temp.filter((a) => a.drainageType <= DrainageType.Gravity);
    }

    public isAdminAlertActionValid = (maxWorkingHeightValue: number, freeboardHeightValue: number, p1: number, p2: number, itemToCheck: AdminAlertActionTabModelDTO): boolean => {
        let retVal: boolean = true;

        for (let j: number = 0; j < itemToCheck.items.length; j++) {
            let tempItem: AlertActionItemModelDTO = itemToCheck.items[j];
            let itemValue: number = 0;

            if (tempItem.type === ConditionType.H) {
                itemValue = maxWorkingHeightValue;
            } else if (tempItem.type === ConditionType.FB) {
                itemValue = freeboardHeightValue;
            } else if (tempItem.type === ConditionType.P1) {
                itemValue = p1;
            } else if (tempItem.type === ConditionType.P2) {
                itemValue = p2;
            }

            if (tempItem.units == ConditionUnits.MM) {
                itemValue = itemValue + tempItem.value;
            } else {
                // it equals %
                let percentValue: number = tempItem.value / 100;
                itemValue = itemValue + itemValue * percentValue;
            }
            let calculatedValue: number = itemValue;

            if (calculatedValue < 0 || calculatedValue > maxWorkingHeightValue) {
                retVal = false;
            }
        }

        return retVal;
    };

    @computed public get getIsAlertActionsEditable(): boolean {
        let retVal: boolean = false;

        for (let i: number = 0; i < this.deviceAlertActions.length; i++) {
            if (this.deviceAlertActions[i].getIsEditable === true) {
                retVal = true;
                break;
            }
        }

        return retVal;
    }

    @computed public get getIsConditionSetsEditable(): boolean {
        let retVal: boolean = false;
        for (let i: number = 0; i < this.deviceConditionSets.length; i++) {
            if (this.deviceConditionSets[i].getIsEditable === true) {
                retVal = true;
                break;
            }
        }

        return retVal;
    }

    @computed public get getConditionSets(): ConditionSetViewModel[] {
        return this.deviceConditionSets.filter((item, index) => index === 0).slice();
    }

    @computed public get getAlertActions(): AlertActionViewModel[] {
        return this.deviceAlertActions.filter((item, index) => index === 0).slice();
    }

    @action public setDrainageType(drainageType: DrainageType) {
        this.drainageType = drainageType;
    }

    @computed public get getDrainageType(): DrainageType {
        let retVal: DrainageType = DrainageType.Gravity;

        if (this.statusData !== null && this.statusData !== undefined) {
            retVal = this.statusData.drainageType;
        }

        return retVal;
    }

    @action public setStatusData(statusData: InstallationStatusDataDTO | undefined) {
        this.statusData = statusData;
    }

    constructor() {
        //Pass in a new instance of your model
        //By passing in true as the second parameter, we make this model undoable which means we can use save and reset options on the model
        //If you make a change to the model you need to persist it with a saveModel() call
        //If you make changes to your model you can revert it back by calling resetModel()
        super(new TabConfigurationModel(), false);
        //EN - Haven't figured out how to make this call work from the base model yet
        //This is only needed if you make use of the validation decorators
        this.setDecorators(TabConfigurationModel);
    }

    //isValid will check all fields to make sure they are in a valid state.
    public doSubmit = async (e: any) => {
        e.preventDefault();

        if (this.isModelValid()) {
            //Do stuff here
            this.errorMessage = "Form is valid";
        } else {
            this.errorMessage = "Form is not valid";
        }
    };

    //This must be present in your viewmodel. Just return true if you dont need to validate anything.
    //keyof BlankModel & string lets you add type checking to the fieldName
    //I am using the validator package to make checking easier https://www.npmjs.com/package/validator
    public isFieldValid(fieldName: keyof FieldType<TabConfigurationModel>): boolean {
        const { isValid, errorMessage } = this.validateDecorators(fieldName);

        //You need to this two properties after validation
        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    @action
    setAdminAlertActionOptions(items: AdminAlertActionTabModelDTO[]) {
        this.adminAlertActionOptions = [];
        this.adminAlertActionOptions = items;
    }

    @action
    setAdminConditionSetOptions(items: AdminConditionTabModelDTO[]) {
        this.adminConditionSetOptions = [];
        this.adminConditionSetOptions = items;
    }

    @action
    setDeviceConditionSets(items: ConditionSetViewModel[]) {
        this.deviceConditionSets = [];
        this.deviceConditionSets = items;
    }

    @action
    setAlertActions(items: AlertActionViewModel[]) {
        this.deviceAlertActions = [];
        this.deviceAlertActions = items;
    }

    @action
    public async loadData(id: number): Promise<ApiResult<TabConfigurationModelDTO>> {
        const request: InstallationConfigurationRequest = {
            deviceId: id,
        };
        const apiResult = await this.Post<TabConfigurationModelDTO>(Server.Api.Installation.getInstallationConfiguration, request);

        if (apiResult.wasSuccessful) {
            runInAction(() => {
                if (apiResult.payload !== null && apiResult.payload !== undefined) {
                    this.setLocalData(apiResult);
                }
                this.setIsLoading(false);
            });
        } else {
            // Error. What to do?
            this.setIsLoading(false);
            this.setIsErrored(true);
            this.history.push("/");
        }

        return apiResult;
    }

    @action
    public addNewCondition(): void {
        let newCondition: ConditionSetViewModel = new ConditionSetViewModel();
        newCondition.setIsEditable(true);
        this.deviceConditionSets.unshift(newCondition);
    }

    @action
    public addNewAlertAction(): void {
        let newAlertAction: AlertActionViewModel = new AlertActionViewModel();
        newAlertAction.setIsEditable(true);
        this.deviceAlertActions.unshift(newAlertAction);
    }

    @action
    public async addExistingConditionSetLocal(id: string, installId: number): Promise<ApiResult<ConditionAddEditDetailModelDTO>> {
        let request: GenericIdRequest = {
            id: id,
        };

        const apiResult = await this.Post<ConditionAddEditDetailModelDTO>(Server.Api.ConditionSet.getConditionSetById, request);

        if (apiResult.wasSuccessful) {
            runInAction(() => {
                const payload: ConditionAddEditDetailModelDTO = apiResult.payload;
                if (payload != undefined && payload !== null) {
                    let itemsToAdd: ConditionItemModelDTO[] = [];
                    let itemVMsToAdd: ConditionItemViewModel[] = [];

                    apiResult.payload.items.forEach((setDtoItem, index) => {
                        const conditionSetItemDto: ConditionItemModelDTO = {
                            id: null,
                            rowVersion: setDtoItem.rowVersion,
                            isDeleted: setDtoItem.isDeleted,
                            createdBy: setDtoItem.createdBy,
                            createdDate: setDtoItem.createdDate,

                            name: setDtoItem.name,
                            type: setDtoItem.type,
                            value: setDtoItem.value,
                            units: setDtoItem.units,
                            conditionSetId: null,
                            commandIndex: index,
                            statusColour: setDtoItem.statusColour,
                            statusTextColour: setDtoItem.statusTextColour,
                        };
                        const conditionSetItem = new ConditionItemViewModel();
                        conditionSetItem.model.fromDto(conditionSetItemDto);
                        conditionSetItem.model.setCommandIndex(conditionSetItemDto.commandIndex);

                        itemsToAdd.push(conditionSetItemDto);
                        itemVMsToAdd.push(conditionSetItem);
                    });

                    const conditionSetDto: ConditionAddEditDetailModelDTO = {
                        id: null, // since this is the device conditionset id, so don't store an Id
                        rowVersion: apiResult.payload.rowVersion,
                        isDeleted: apiResult.payload.isDeleted,
                        createdBy: apiResult.payload.createdBy,
                        createdDate: apiResult.payload.createdDate,
                        name: apiResult.payload.name,
                        condtionSetId: apiResult.payload.id, // CMR hopefully this should be the right id
                        items: itemsToAdd,
                        unitListItems: apiResult.payload.unitListItems,
                    };
                    const conditionSet = new ConditionSetViewModel();
                    conditionSet.model.fromDto(conditionSetDto);
                    conditionSet.conditions = itemVMsToAdd;
                    conditionSet.model.conditionSetId = apiResult.payload.id;
                    //We can't allow conditionsets to be edited
                    conditionSet.setIsEditable(false);
                    this.setDeviceConditionSets([conditionSet]);
                }
            });
        }

        return apiResult;
    }

    @action
    public async addExistingAlertActionLocal(id: string, installId: number): Promise<void> {
        let request: GenericIdRequest = {
            id: id,
        };
        const apiResult = await this.Post<AlertActionAddEditDetailModelDTO>(Server.Api.AlertActions.getAlertActionById, request);
        if (apiResult.wasSuccessful) {
            runInAction(() => {
                const payload: AlertActionAddEditDetailModelDTO = apiResult.payload;
                if (payload != undefined && payload !== null) {
                    let itemsToAdd: AlertActionItemModelDTO[] = [];
                    let itemVMsToAdd: AlertActionItemViewModel[] = [];

                    apiResult.payload.items.forEach((setDtoItem, index) => {
                        const alertActionItemDto: AlertActionItemModelDTO = {
                            id: null,
                            rowVersion: setDtoItem.rowVersion,
                            isDeleted: setDtoItem.isDeleted,
                            createdBy: setDtoItem.createdBy,
                            createdDate: setDtoItem.createdDate,

                            name: setDtoItem.name,
                            type: setDtoItem.type,
                            value: setDtoItem.value,
                            direction: setDtoItem.direction,
                            units: setDtoItem.units,
                            alertActionId: null,
                            email: setDtoItem.email,
                            sms: setDtoItem.sms,
                            photo: setDtoItem.photo,
                            commandIndex: index,
                            alertColour: setDtoItem.alertColour,
                            alertTextColour: setDtoItem.alertTextColour,
                        };
                        const alertActionItem = new AlertActionItemViewModel();
                        alertActionItem.model.fromDto(alertActionItemDto);
                        alertActionItem.model.setCommandIndex(alertActionItemDto.commandIndex);

                        itemsToAdd.push(alertActionItemDto);
                        itemVMsToAdd.push(alertActionItem);
                    });

                    const alertActionDto: AlertActionAddEditDetailModelDTO = {
                        id: null,
                        rowVersion: apiResult.payload.rowVersion,
                        isDeleted: apiResult.payload.isDeleted,
                        createdBy: apiResult.payload.createdBy,
                        createdDate: apiResult.payload.createdDate,
                        name: apiResult.payload.name,
                        items: itemsToAdd,
                        unitListItems: apiResult.payload.unitListItems,
                    };
                    const alertAction = new AlertActionViewModel();
                    alertAction.model.fromDto(alertActionDto);
                    alertAction.alertActions = itemVMsToAdd;
                    alertAction.model.alertActionId = apiResult.payload.id;
                    alertAction.setIsEditable(true);
                    this.setAlertActions([alertAction]);
                }
            });
        }
    }

    @action
    public setLocalData(apiResult: ApiResult<TabConfigurationModelDTO>): void {
        const conditionSetOptions: AdminConditionTabModelDTO[] = apiResult.payload.conditionSetOptions;
        this.setAdminConditionSetOptions(conditionSetOptions);
        const alertActionOptions: AdminAlertActionTabModelDTO[] = apiResult.payload.alertActionOptions;
        this.setAdminAlertActionOptions(alertActionOptions);

        let conditionSetsToAdd: ConditionSetViewModel[] = [];
        let alertActionsToAdd: AlertActionViewModel[] = [];

        // JC: The data here is for Device, but is using the admin models.
        // So you will see the deviceConditionSetId being set for the conditionSetId.
        // Done this way to avoid duplicating code from creating new viewmodels etc.
        const conditionSets: DeviceConditionSetModelDTO[] = apiResult.payload.deviceConditionSets;
        conditionSets.forEach((setDto) => {
            let itemsToAdd: ConditionItemModelDTO[] = [];
            let itemVMsToAdd: ConditionItemViewModel[] = [];

            setDto.items.forEach((setDtoItem, index) => {
                const conditionSetItemDto: ConditionItemModelDTO = {
                    id: setDtoItem.id,
                    rowVersion: setDtoItem.rowVersion,
                    isDeleted: setDtoItem.isDeleted,
                    createdBy: setDtoItem.createdBy,
                    createdDate: setDtoItem.createdDate,

                    name: setDtoItem.name,
                    type: setDtoItem.type,
                    value: setDtoItem.value,
                    units: setDtoItem.units,
                    conditionSetId: setDtoItem.deviceConditionSetId,
                    commandIndex: index,
                    statusColour: setDtoItem.statusColour,
                    statusTextColour: setDtoItem.statusTextColour,
                };
                const conditionSetItem = new ConditionItemViewModel();
                conditionSetItem.model.fromDto(conditionSetItemDto);
                conditionSetItem.model.setCommandIndex(conditionSetItemDto.commandIndex);

                itemsToAdd.push(conditionSetItemDto);
                itemVMsToAdd.push(conditionSetItem);
            });

            const conditionSetDto: ConditionAddEditDetailModelDTO = {
                id: setDto.id,
                rowVersion: setDto.rowVersion,
                isDeleted: setDto.isDeleted,
                createdBy: setDto.createdBy,
                createdDate: setDto.createdDate,
                condtionSetId: setDto.conditionSetId,

                name: setDto.name,
                items: itemsToAdd,
                unitListItems: setDto.unitListItems,
            };
            const conditionSet = new ConditionSetViewModel();
            conditionSet.model.fromDto(conditionSetDto);
            conditionSet.conditions = itemVMsToAdd;
            conditionSetsToAdd.push(conditionSet);
        });
        this.setDeviceConditionSets(conditionSetsToAdd);

        const alertActions: DeviceAlertActionModelDTO[] = apiResult.payload.deviceAlertActions;
        alertActions.forEach((setDto) => {
            let itemsToAdd: AlertActionItemModelDTO[] = [];
            let itemVMsToAdd: AlertActionItemViewModel[] = [];

            setDto.items.forEach((setDtoItem, index) => {
                const alertActionItemDto: AlertActionItemModelDTO = {
                    id: setDtoItem.id,
                    rowVersion: setDtoItem.rowVersion,
                    isDeleted: setDtoItem.isDeleted,
                    createdBy: setDtoItem.createdBy,
                    createdDate: setDtoItem.createdDate,

                    name: setDtoItem.name,
                    type: setDtoItem.type,
                    value: setDtoItem.value,
                    direction: setDtoItem.direction,
                    units: setDtoItem.units,
                    alertActionId: setDtoItem.deviceAlertActionId,
                    email: setDtoItem.email,
                    sms: setDtoItem.sms,
                    photo: setDtoItem.photo,
                    commandIndex: index,
                    alertColour: setDtoItem.alertColour,
                    alertTextColour: setDtoItem.alertTextColour,
                };
                const alertActionItem = new AlertActionItemViewModel();
                alertActionItem.model.fromDto(alertActionItemDto);
                alertActionItem.model.setCommandIndex(alertActionItemDto.commandIndex);

                itemsToAdd.push(alertActionItemDto);
                itemVMsToAdd.push(alertActionItem);
            });

            const alertActionDto: AlertActionAddEditDetailModelDTO = {
                id: setDto.id,
                rowVersion: setDto.rowVersion,
                isDeleted: setDto.isDeleted,
                createdBy: setDto.createdBy,
                createdDate: setDto.createdDate,

                name: setDto.name,
                items: itemsToAdd,
                unitListItems: [],
            };
            const alertAction = new AlertActionViewModel();
            alertAction.model.fromDto(alertActionDto);
            alertAction.alertActions = itemVMsToAdd;
            alertActionsToAdd.push(alertAction);
        });
        this.setAlertActions(alertActionsToAdd);
    }

    @action
    public deleteLocalConditionSet(index: number): void {
        let newConditionSets = [];

        for (let i = 0; i < this.getConditionSets.length; i++) {
            const element = this.getConditionSets[i];

            if (i != index) {
                newConditionSets.push(element);
            }
        }

        this.setDeviceConditionSets(newConditionSets);
    }

    @action
    public deleteLocalAlertAction(index: number): void {
        let newAlertActions = [];

        for (let i = 0; i < this.getAlertActions.length; i++) {
            const element = this.getAlertActions[i];

            if (i != index) {
                newAlertActions.push(element);
            }
        }

        this.setAlertActions(newAlertActions);
    }
}

export interface AddExistingConditionSetRequest {
    id: string;
    deviceId: number;
}

export interface InstallationConfigurationRequest {
    deviceId: number;
}
