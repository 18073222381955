import { action, computed, observable } from "mobx";
import { ModelBase } from "Core/Models/ModelBase";
import { Address } from "Custom/Models/Domain/Properties/Address";
import { isNullOrUndefined } from "Custom/Utils/utils";
import { InstallationDTO } from "./Installation";
import { UnitStatus, UnitStatusDTO } from "./UnitStatus";

export class InstallationListItem extends ModelBase<InstallationListItem, InstallationListItemDTO> {
    @observable public id?: number = 0;
    @observable public rowVersion?: string = "";
    @observable public deviceId: string = "";
    @observable public simId: string = "";
    @observable public name: string = "";
    @observable public number: string = "";
    @observable public lastUpdated: string | undefined | null;
    @observable public ipAddress: string | undefined | null;
    @observable public latitude: number | undefined | null;
    @observable public longitude: number | undefined | null;
    @observable public roofcareAddressId: string | undefined | null = undefined;
    @observable public createdDate: string | undefined | null;
    @observable public earliestValue: string | undefined | null;
    @observable public isDeleted: boolean = false;
    @observable public address: Address | undefined | null;
    @observable public contractorId: string | undefined | null;
    @observable public showOnMap = true;

    @observable public temperature: number | undefined | null;
    @observable public latestReadingTime: string | undefined | null;
    @observable public status: UnitStatus = new UnitStatus();
    @observable public lastGainValue: string = "";
    @observable public lastGainValues: number[] = observable([]);
    @observable public contractorName: string = "";
    @observable public hasAlertAction: boolean = false;
    @observable public hasConditionSet: boolean = false;
    @observable public hasUnitBehaviour: boolean = false;
    @observable public hasValidEmailAddesses: boolean = false;
    @observable public hasValidMobileNumbers: boolean = false;

    @computed get getStatus(): UnitStatus {
        let retVal: UnitStatus = this.status;

        if (retVal === null || retVal === undefined) {
            this.status = new UnitStatus();
        }
        retVal = this.status;

        return retVal;
    }

    @computed get getLatestReadingTime(): string {
        let retVal: string = "";

        if (isNullOrUndefined(this.latestReadingTime) === false) {
            if (this.latestReadingTime?.trim() !== "0001-01-01T00:00:00") {
                retVal = this.latestReadingTime!;
            }
        }

        return retVal;
    }

    //fromDto is required but you can leave it blank
    @action
    fromDto(model: InstallationListItemDTO): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }

        for (let key in model.status) {
            if (model.status.hasOwnProperty(key)) {
                if (this.status[key] instanceof Date) {
                    this.status[key] = new Date(model.status[key]);
                } else {
                    this.status[key] = model.status[key];
                }
            }
        }

        // TODO CMR Sort out the Address object
    }

    //toDto is required but you can leave it blank
    toDto(model: InstallationListItem): InstallationListItemDTO {
        return {
            id: model.id,
            rowVersion: model.rowVersion,
            deviceId: model.deviceId,
            simId: model.simId,
            name: model.name,
            lastUpdated: model.lastUpdated,
            ipAddress: model.ipAddress,
            latitude: model.latitude,
            longitude: model.longitude,
            roofcareAddressId: model.roofcareAddressId,
            createdDate: model.createdDate,
            earliestValue: model.earliestValue,
            isDeleted: model.isDeleted,
            contractorId: model.contractorId,
            temperature: model.temperature,
            latestReadingTime: model.latestReadingTime,
            status: model.status,
            lastGainValue: model.lastGainValue,
            lastGainValues: model.lastGainValues,
            contractorName: model.contractorName,
            number: model.number,
            hasAlertAction: model.hasAlertAction,
            hasConditionSet: model.hasConditionSet,
            hasUnitBehaviour: model.hasUnitBehaviour,
            hasValidEmailAddesses: model.hasValidEmailAddesses,
            hasValidMobileNumbers: model.hasValidMobileNumbers,
        };
    }
}
export interface InstallationListItemDTO extends InstallationDTO {
    status: UnitStatusDTO;
    latestReadingTime: string | null | undefined;
    temperature: number | null | undefined;
    lastGainValue: string;
    lastGainValues: number[];
    contractorName: string;
    hasAlertAction: boolean;
    hasConditionSet: boolean;
    hasUnitBehaviour: boolean;
    hasValidEmailAddesses: boolean;
    hasValidMobileNumbers: boolean;
}
