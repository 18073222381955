import { Input, Paper, Button, Box } from "@material-ui/core";
import styled from "styled-components";

export const TransferListPage: any = styled(Box)`
    padding-top: 15px;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex: 1 0 0;
    justify-content: space-between;
    //background-color: pink;

    span.MuiTypography-caption {
        font-size: 1.166666em;
        font-weight: bold;
    }
`;

export const TransferListWrapper: any = styled(Box)`
    width: 100%;
    display: flex;
    flex-direction: row;
    flex: 1 0 0;
    justify-content: space-between;
    align-items: stretch;
`;

export const ListWrapper: any = styled(Box)`
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1 0 0;
    justify-content: space-between;

    min-height: 275px;
    overflow: hidden;
    background: #393c41 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #000000c2;
    border-radius: 18px;
    opacity: 1;

    .titlePanel {
        padding: 12px 18px 15px;
        background: rgb(56 103 204 / 43%) 0% 0% no-repeat padding-box;
        position: relative;
    }
`;

export const TransferListButtonWrapper: any = styled(Box)`
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    vertical-align: middle;
    height: auto;
    padding: 4px 12px 0;
    flex-grow: 1;
`;

export const TransferListPaper = styled(Paper)`
    width: auto;
    min-height: 274px;
    max-height: 400px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background-color: ${(props) => props.theme.app.colors.section.main};

    &.MuiPaper-rounded {
        border-radius: 0;
    }

    .list-property {
        padding: 6px 10px;

        &.selected {
            background-color: ${(props) => props.theme.app.colors.grey.background};
        }
    }
`;

export const SearchBox: any = styled(Input)`
    border-radius: 0;
    box-shadow: none;
    width: auto;
    border-top: none;
    border-left: none;
    border-right: none;
    margin: 15px 15px 9px;

    > input {
        padding: 10px;
    }
`;

export const AddNewBox: any = styled(Paper)`
    position: absolute;
    top: 10px;
    right: 15px;
    width: 150px;

    padding: 5px;

    font-size: 12px;
    background: #565657 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 3px #00000073;
    border-radius: 100px;
    opacity: 1;

    p {
        text-decoration: none;
        color: ${(props) => props.theme.app.colors.white.main} !important;
    }
`;

export const InternalList: any = styled(Box)`
    display: flex;
    flex-direction: column;
    flex: 1 0 0;
    padding: 15px 0px 9px;
    border-top: 1px dashed #b2b2b2;

    .list-property {
        padding: 5px 15px;
        color: ${(props) => props.theme.app.colors.white.main};
        background-color: ${(props) => props.theme.app.colors.section.main};
    }

    .selected {
        background-color: #626265 !important;
    }

    border-top-left-radius: 0;
    border-top-right-radius: 0;

    overflow: auto;
    flex-basis: auto;
    flex-shrink: 1;

    .no-properties {
        color: #84888b;
        /* padding: 8px 10px; */
        padding: 5px 15px;
        color: ${(props) => props.theme.app.colors.white.main};
    }
`;

export const InternalListToo: any = styled(InternalList)`
    border-top: none;
`;

export const ButtonWrapper: any = styled(Box)`
    display: flex;
    flex-direction: column;
    flex: 1 0 0;
    justify-content: center;

    .Mui-disabled {
        color: ${(props) => props.theme.app.colors.white.main} !important;
        background-color: ${(props) => props.theme.app.colors.blue.button} !important;
        box-shadow: 0px 3px 6px #000000c2;
    }
`;

export const TransferListButton = styled(Button)`
    margin: 5px 0;
    min-width: 115px;
    font-size: 1.166666em;
    padding: 9px 10px 7px;
    line-height: 1.4;

    background: white 0% 0% no-repeat padding-box;
    background-color: ${(props) => props.theme.app.colors.blue.button};
    box-shadow: 0px 3px 6px #000000c2;
    border-radius: 100px;
    opacity: 1;
`;
