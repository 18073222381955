import { ViewModelBase } from "Core/ViewModels/ViewModelBase";
import { FieldType, isEmptyOrWhitespace } from "Core/Utils/Utils";
import { action, computed, IObservableArray, observable, runInAction } from "mobx";
import { ClientPropertyListAndMapItems, InstallationMapModel, InstallationMapModelDTO } from "Custom/Models/Domain";
import L, { LatLngBounds } from "leaflet";
import { IMarker } from "Custom/Components/Map/Map";
import { markerIconGreen, markerIconAmber, markerIconFlood, markerIconRed, markerIconUnkown, getIconWithUnits } from "Custom/Components/Map/icons";
import { DEFAULTBOUNDS } from "Custom/Globals/Globals";
import { ApiResult } from "Core/Models";
import { Server } from "Custom/Globals/AppUrls";
import { ClientPropertyItemModel } from "Custom/Models/Domain/Clients/ClientPropertyItemModel";
import { InstallationsViewModel } from "./InstallationsViewModel";

import { SvgPinComponent, SvgPinComponentAsString } from "Custom/Components/Map/Pin";
import { getDefaultPropertyMarkerIcon, getInstallationAndPropertyCountMarkerIcon, getInstallationMarkerIcon } from "Custom/Utils/map";

//extend viewmodel base and passing your model as the generic type
export class InstallationMapTabViewModel extends ViewModelBase<InstallationMapModel> {
    //Singleton instance of class
    private static _instance: InstallationMapTabViewModel;
    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    @observable public errorMessage: string = "";
    @observable public showProperties: boolean = true;

    public parentViewModel: InstallationsViewModel = InstallationsViewModel.Instance;

    public installations: IObservableArray<InstallationMapModel> = observable<InstallationMapModel>([]);
    public properties: IObservableArray<ClientPropertyItemModel> = observable<ClientPropertyItemModel>([]);

    // @observable public filterSearchString: string = "";

    @computed get filterSearchString(): string {
        return this.parentViewModel.searchString;
    }

    @action public cleanUp() {
        this.installations.clear();
        this.properties.clear();
    }

    @action
    public toggleLayer() {
        this.showProperties = !this.showProperties;
    }

    @action
    public setShowProperties(value: boolean) {
        this.showProperties = value;
    }

    @computed
    public get getInstallations(): InstallationMapModel[] {
        let retVal = this.installations.slice();
        return retVal.filter((a) => this.filterBySearchString(a));
    }

    private filterBySearchString = (item: InstallationMapModel): boolean => {
        if (isEmptyOrWhitespace(this.filterSearchString)) {
            return true;
        }

        const searchStringUpperCase = this.filterSearchString.toUpperCase();

        return (
            item.contractorName?.toUpperCase().includes(searchStringUpperCase) ||
            item.simId?.toUpperCase().includes(searchStringUpperCase) ||
            item.deviceId?.toUpperCase().includes(searchStringUpperCase) ||
            item.name?.toUpperCase().includes(searchStringUpperCase) ||
            item.number?.toUpperCase().includes(searchStringUpperCase)
        );
    };

    @computed
    public get getShowProperties(): boolean {
        return this.showProperties;
    }

    @computed
    public get getShowPropertyText(): string {
        return this.showProperties === true ? "Properties" : "Installation";
    }

    @computed
    public get getProperties(): ClientPropertyItemModel[] {
        return this.properties.slice();
    }

    public getInstallation(id: string): InstallationMapModel | undefined {
        return this.installations.find((a) => a.id === parseInt(id));
    }

    public getProperty(id: string): ClientPropertyItemModel | undefined {
        return this.properties.find((a: ClientPropertyItemModel) => a.propertyId === id);
    }

    @action
    public setInstallations(installations: InstallationMapModelDTO[]) {
        this.installations.clear();
        installations.forEach((item) => {
            const domainModel = new InstallationMapModel();
            domainModel.fromDto(item);
            this.installations.push(domainModel);
        });
    }

    @action
    public setProperties(properties: ClientPropertyItemModel[], installations: InstallationMapModelDTO[]) {
        properties.forEach((item: ClientPropertyItemModel) => {
            const domainModel = new ClientPropertyItemModel();

            domainModel.fromDto(item);

            const units: InstallationMapModelDTO[] = installations.filter((a) => a.roofcareAddressId === item.propertyId);
            domainModel.setUnits(units);

            this.properties.push(domainModel);
        });
    }

    public showingWarning = observable({
        /*         [InstallationStatus.GreenWarning]: true,
        [InstallationStatus.AmberWarning]: true,
        [InstallationStatus.RedWarning]: true,
        [InstallationStatus.FloodWarning]: true,
        [InstallationStatus.Unknown]: true, */
    });

    constructor() {
        //Pass in a new instance of your model
        //By passing in true as the second parameter, we make this model undoable which means we can use save and reset options on the model
        //If you make a change to the model you need to persist it with a saveModel() call
        //If you make changes to your model you can revert it back by calling resetModel()
        super(new InstallationMapModel(), false);
        //EN - Haven't figured out how to make this call work from the base model yet
        //This is only needed if you make use of the validation decorators
        this.setDecorators(InstallationMapModel);
    }

    //This must be present in your viewmodel. Just return true if you dont need to validate anything.
    //keyof BlankModel & string lets you add type checking to the fieldName
    //I am using the validator package to make checking easier https://www.npmjs.com/package/validator
    public isFieldValid(fieldName: keyof FieldType<InstallationMapModel>): boolean {
        const { isValid, errorMessage } = this.validateDecorators(fieldName);

        //You need to this two properties after validation
        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    @computed
    public get mapBounds(): LatLngBounds {
        let retVal: LatLngBounds = DEFAULTBOUNDS;
        let bounds: LatLngBounds = new LatLngBounds(
            this.installations.filter((a) => a.latitude !== 0 && a.longitude !== 0).map((installation) => [installation.getValue("latitude"), installation.getValue("longitude")]),
        );

        if (this.showProperties === true) {
            bounds = new LatLngBounds(
                this.properties
                    .filter((a) => a.latitude !== 0 && a.longitude !== 0)
                    .map((property: ClientPropertyItemModel) => [property.getValue("latitude"), property.getValue("longitude")]),
            );
        }

        const isValid: boolean = bounds.isValid();

        if (isValid) {
            retVal = bounds;
        }

        return retVal;
    }

    private getPropertyInstallationMarkerIcon(property: ClientPropertyItemModel) {
        let allUnits: InstallationMapModel[] = property.getUnits;
        let units: InstallationMapModel[] = allUnits; /*TODO CMR .filter((i) => this.showingWarning[i.status] !== false) */

        if (units.length > 0) {
            units.sort((a: InstallationMapModel, b: InstallationMapModel) => {
                if (a === b) {
                    return 0;
                }

                if (a > b) {
                    return 1;
                }

                return -1;
            });

            const unit = units[0];

            return getInstallationAndPropertyCountMarkerIcon(unit.getStatus.statusColour, unit.getStatus.statusTextColour, allUnits.length);
        }

        return markerIconUnkown;
    }

    @computed
    public get markers(): IMarker[] {
        if (this.showProperties) {
            const filtered: ClientPropertyItemModel[] = [];
            const searchStringUpperCase: string = this.filterSearchString.toUpperCase();
            this.properties.forEach((property) => {
                const allowedUnits = property.getUnits.filter((a) => this.filterBySearchString(a));
                if (allowedUnits.length > 0) {
                    filtered.push(property);
                } else {
                    if (property.clientName?.toUpperCase().includes(searchStringUpperCase)) {
                        filtered.push(property);
                    }
                }
            });

            return filtered
                .filter((a) => a.latitude !== 0 && a.longitude !== 0)
                .map((property: ClientPropertyItemModel) => ({
                    id: property.getValue("propertyId"),
                    position: [property.getValue("latitude"), property.getValue("longitude")],
                    icon: this.getPropertyInstallationMarkerIcon(property),
                }));
        } else {
            const filtered: ClientPropertyItemModel[] = [];
            // Filter out based on the checkboxes
            this.properties.forEach((property) => {
                const allowedUnits: InstallationMapModel[] = property.getUnits /* TODO CMR .filter((i) => this.showingWarning[i.status] !== false) */
                    .filter((a) => this.filterBySearchString(a));

                if (allowedUnits.length > 0) {
                    filtered.push(property);
                }
            });

            return filtered
                .filter((a) => a.latitude !== 0 && a.longitude !== 0)
                .map((property: ClientPropertyItemModel) => ({
                    id: property.getValue("propertyId"),
                    position: [property.getValue("latitude"), property.getValue("longitude")],
                    icon: this.getPropertyInstallationMarkerIcon(property),
                    number: property.getUnits.length,
                }));
        }
    }

    @action
    public async loadMapData(): Promise<ApiResult<ClientPropertyListAndMapItems>> {
        const apiResult = await this.Post<ClientPropertyListAndMapItems>(Server.Api.Property.getAllAdminPropertyAndInstallationMapItems);

        if (apiResult.wasSuccessful) {
            runInAction(() => {
                this.setProperties(apiResult.payload.properties, apiResult.payload.installations);
                this.setInstallations(apiResult.payload.installations);
            });
        }
        return apiResult;
    }
}
