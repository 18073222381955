import { useContext, useEffect, useState } from "react";
import { formatDateTime } from "Custom/Utils/format";
import { InstallationHeaderDivider, InstallationHeaderPanel } from "./InstallationHeader.style";
import { InstallationListItem, InstallationStatusDataDTO } from "Custom/Models/Domain/Installations";
import { useObserver } from "mobx-react-lite";
import { Box } from "@material-ui/core";
import { useRouter } from "Core/Utils";
import * as RouterUtil from "Custom/Utils/routing";
import { Stores, StoresContext } from "Custom/Stores";
import { CancelBtn } from "Custom/StylesAppSpecific/Controls/CancelBtn";
import { MovePropertyModal } from "./Modals/MovePropertyModal";
import { StringLike } from "@visx/scale";
import { UnitStatusBox } from "Custom/Components";

interface InstallationHeaderProps {
    device: InstallationListItem | undefined;
    statusData: InstallationStatusDataDTO | undefined;
}

export function InstallationHeader(props: InstallationHeaderProps) {
    const store: Stores = useContext<Stores>(StoresContext);
    const { device, statusData } = props;
    const { history, match } = useRouter();
    const [showCreate, setShowCreate] = useState(false);

    const key: string = device ? device!.simId : "unknown";
    const [moveUnitOpen, setMoveUnitOpen] = useState(false);
    const [selectedProperty, setSelectedProperty] = useState("");

    useEffect(() => {
        if (RouterUtil.requireAdminLogin(store) === true || RouterUtil.requireContractorAdminLogin(store) === true) {
            setShowCreate(true);
        }
        return () => {
            // Clean up after yourself
            setShowCreate(false);
        };
    }, []);

    /*     useEffect(() => {
        if (RouterUtil.requireAdminLogin(store) === true || RouterUtil.requireContractorAdminLogin(store) === true) {
            setShowCreate(true);
        }
    }, [device]); */

    const currentUrl = `${match.url}`;
    const editUrl = currentUrl + "/edit";

    const handleMoveUnitOpen = () => {
        setMoveUnitOpen(true);
    };

    const handleMoveUnitClose = () => {
        setMoveUnitOpen(false);
        setSelectedProperty("");
    };

    const handleMoveUnit = (selectedProjectProperty: StringLike) => {
        /* viewModel.upsertUnitProperty(selectedProjectProperty).then(() => { */
        setMoveUnitOpen(false);
        setSelectedProperty("");
        /* }); */
    };

    return useObserver(() => (
        <>
            <MovePropertyModal open={moveUnitOpen} onClose={handleMoveUnitClose} onMoveProperty={handleMoveUnit} title="Move Unit?" device={device} />

            <InstallationHeaderPanel>
                <Box className="guttersen-header">
                    <div className="device" key={`device_${device ? device.deviceId : "deviveId"}_${device ? device.id : "Id"}`}>
                        <div className="device-row status-readings" style={{ display: "flex", justifyContent: "space-between" }}>
                            <div style={{ flex: 1, padding: 0, maxWidth: "74px" }}>
                                {device !== null && device !== undefined && (
                                    <UnitStatusBox
                                        className={"device-status "}
                                        backgroundColor={device !== null && device !== undefined ? device.getStatus.statusColour : "#FFFFFF"}
                                        textColour={device !== null && device !== undefined ? device.getStatus.statusTextColour : "#000000"}
                                    >
                                        <div className="status-name">{device !== null && device !== undefined ? device.getStatus.name : "Unknown"}</div>
                                    </UnitStatusBox>
                                )}
                            </div>
                            <div className="device-name" style={{ flex: 1 }}>
                                {device ? device.name : ""}
                            </div>
                            <div className="device-sim-id" style={{ flex: 1 }}>
                                <span className="devicelist-title">Sim ID:</span> {device ? device!.simId : ""}
                            </div>
                            <div className="device-sim-id" style={{ flex: 1 }}>
                                {/* <span className="devicelist-title">Device ID:</span> {device ? device!.deviceId : ""} */}
                            </div>
                            <div className="device-sim-id" style={{ flex: 1 }}>
                                <span className="devicelist-title">Contractor:</span> {device ? device.contractorName : ""}
                            </div>
                        </div>
                        <InstallationHeaderDivider />
                        <div className="status-readings">
                            <div className="device-created">
                                <span className="devicelist-title">Created date:</span> {device && device.createdDate ? formatDateTime(device.createdDate!) : ""}
                            </div>
                            {statusData !== undefined && (
                                <div className="device-created">
                                    <span className="devicelist-title">Commissioned:</span>{" "}
                                    {statusData && statusData.commissionDate ? formatDateTime(statusData.commissionDate!) : ""}
                                </div>
                            )}
                            <div className="device-lastreading">
                                <span className="devicelist-title">Last reading:</span> {device && device.latestReadingTime ? formatDateTime(device.latestReadingTime!) : ""}
                            </div>

                            {showCreate && (
                                <div style={{ display: "flex", flex: "1 0 0", justifyContent: "flex-end" }}>
                                    <CancelBtn onClick={handleMoveUnitOpen}>Move Unit</CancelBtn>
                                </div>
                            )}
                        </div>
                    </div>
                </Box>
            </InstallationHeaderPanel>
        </>
    ));
}
