import React, { useRef, useEffect } from "react";
import { useObserver } from "mobx-react-lite";
import { generateID } from "Core/Utils/Utils";
import { ConditionSetMetricDto } from "./ConditionSetMetric";
import * as d3 from "d3";
import { ConditionSetMetricsInnerBox } from "./TabMetrics.style";

interface IConditionSetMetricProps {
    imageHeight: number;
    imageWidth: number;
    marginTop: number;
    metrics: ConditionSetMetricDto[];
    total: number;
    isLoading: boolean;
    counter: number;
    title: string;

    backgroundColour?: string;
    textColor?: string;
}

export function ConditionSetMetricComponent(props: IConditionSetMetricProps) {
    const { counter, imageHeight, imageWidth, marginTop, metrics, total, isLoading, title, backgroundColour, textColor } = props;
    const componentRef: any = useRef(generateID());
    const [showDownload, setShowDownload] = React.useState(false);

    const pieRadius: number = imageHeight / 2 - marginTop * 1.25;

    const pieLeftX: number = 210;
    const pieRightX: number = 625;

    let bgColor: string = backgroundColour !== undefined ? backgroundColour : "#ffffff";
    let txtColor: string = textColor !== undefined ? textColor : "#000000";

    useEffect(() => {
        return () => {
            // Clean up after yourself
            if (svgElement.current !== null) {
                const svg = d3.select(svgElement.current);
                svg.remove();
            }
            const svg = d3.select(svgElement.current);
            svg.selectAll().remove();
            svg.selectAll(".legend-title").remove();
            svg.selectAll(".pie-chart-background").remove();
            svg.selectAll(".pie-left").remove();
            svg.selectAll(".pie-right").remove();
        };
    }, []);

    useEffect(() => {
        setShowDownload(false);
    }, [isLoading]);

    const radiansToDegrees = (radians: number) => {
        return (radians * 180) / Math.PI;
    };

    const getTextWidthHeight = (textToCalculate: string): any => {
        let retVal: any = {} as any;
        let text = document.createElement("span");
        document.body.appendChild(text);

        text.style.font = "Mont-Regular, Mont";
        text.style.fontSize = "10px";
        text.style.height = "auto";
        text.style.width = "auto";
        text.style.position = "absolute";
        text.style.whiteSpace = "no-wrap";
        text.innerHTML = textToCalculate;
        //text.color = "transparent";

        retVal.width = Math.ceil(text.clientWidth);
        retVal.height = Math.ceil(text.clientHeight);
        document.body.removeChild(text);

        return retVal;
    };

    // The useRef Hook creates a variable that "holds on" to a value across rendering passes. In
    // this case it will hold our component's SVG DOM element. It's initialized null and React
    // will assign it later (see the return statement).
    const svgElement: React.MutableRefObject<null> = useRef(null);

    useEffect(() => {
        if (isLoading === false) {
            let showlines: boolean = false;

            const imageMiddle: number = imageHeight / 2;
            const ThreeSixtyDegreesRadian: number = 6.283185307179587;

            // Bind D3 data.
            const svg = d3.select(svgElement.current);
            svg.selectAll().remove();
            svg.selectAll(".legend-title").remove();
            svg.selectAll(".pie-chart-background").remove();
            svg.selectAll(".pie-left").remove();
            svg.selectAll(".pie-right").remove();
            svg.append("polygon")
                .attr("class", "pie-chart-background")
                .attr("points", 0 + "," + 0 + " " + 0 + "," + imageHeight + " " + imageWidth + "," + imageHeight + " " + imageWidth + "," + 0)
                .attr("style", "fill: " + bgColor);

            if (total !== 0 && props.metrics !== null && svgElement.current !== null) {
                const totalData = Array.from(metrics.values()).map((item: ConditionSetMetricDto) => ({
                    colour: item.statusColour,
                    key: item.name as string,
                    value: ((item.count / total) * 100) as number,
                    actualCount: item.count,
                }));

                let maxSlice: number = Math.max(
                    ...totalData.map(function (item: any) {
                        return item.value;
                    }),
                );

                const leftPieData = totalData.filter((item: any) => item.value == maxSlice);
                const rightPieData = totalData.filter((item: any) => item.value != maxSlice);

                // now sort out the left side other value and if we need lines
                let otherCount: number = 0;
                for (let i: number = 0; i < rightPieData.length; i++) {
                    if (rightPieData[i].value > 0) {
                        showlines = true;
                    }
                    otherCount += rightPieData[i].actualCount;
                }

                leftPieData.push({
                    colour: "#00FF00",
                    key: "Other",
                    value: 100 - maxSlice,
                    actualCount: otherCount,
                });

                svg.append("text")
                    .attr("class", "legend-title")
                    .attr("data-name", "Legend")
                    //.attr("transform", "translate(" + (imageWidth / 2 - 30) + " 19)")
                    .attr("fill", txtColor)
                    .attr("fontFamily", "Mont-Regular, Mont")
                    .attr("style", "font-size: 16px; text-anchor: middle")
                    .append("tspan")
                    .attr("x", imageWidth / 2)
                    .attr("y", "25")
                    .text(title);

                /***************************************** Left piechart ***************************/
                // The pie.
                const pie = d3
                    .pie<any>()
                    .sort(null)
                    .value((item: any) => item.value);

                const pieCentreRadius = 0;

                const pieArcsLeft = pie(leftPieData);
                const pieArcLeft = d3.arc<any>().innerRadius(pieCentreRadius).outerRadius(pieRadius);

                // angles are in radians
                //const firstSliceAngle: number = radiansToDegrees(pieArcsLeft[0].endAngle) - radiansToDegrees(pieArcsLeft[0].startAngle);
                const secondSliceAngle: number = radiansToDegrees(pieArcsLeft[1].endAngle) - radiansToDegrees(pieArcsLeft[1].startAngle);

                const pieLeftAngle: number = 90 + secondSliceAngle / 2;

                // Pie slices.
                // do all your transforms on ONE line, else they fail.
                svg.selectAll("#pieSliceLeft")
                    .data(pieArcsLeft)
                    .join(
                        (enter) =>
                            enter
                                .append("path")
                                .attr("class", "pie-left")
                                .attr("id", "pieSlice")
                                .attr("stroke", bgColor)
                                .attr("stroke-width", 3)
                                .attr("fill", (d, index) => d.data.colour)
                                .attr("d", pieArcLeft)
                                .attr("transform", "translate(" + pieLeftX + " " + imageMiddle + ") rotate( " + pieLeftAngle + ")"),
                        (update) =>
                            update
                                .attr("fill", (d, index) => d.data.colour)
                                .attr("d", pieArcLeft)
                                .attr("transform", "translate(" + pieLeftX + " " + imageMiddle + ") rotate( " + pieLeftAngle + ")"),
                        (exit) => exit.remove(),
                    );

                /***************************************** Right piechart ***************************/

                const pieRight = d3
                    .pie<any>()
                    .sort(null)
                    .value((item: any) => item.value);

                const pieArcsRight = pieRight(rightPieData);
                const pieArcRight = d3
                    .arc<any>()
                    .innerRadius(pieCentreRadius)
                    .outerRadius(pieRadius - 25);

                svg.selectAll("#pieSliceRight")
                    .data(pieArcsRight)
                    .join(
                        (enter) =>
                            enter
                                .append("path")
                                .attr("class", "pie-right piechart-rightchart")
                                .attr("transform", "translate(" + pieRightX + " " + imageMiddle + ")")
                                .attr("id", "pieSlice")
                                .attr("stroke", bgColor)
                                .attr("stroke-width", 2)
                                .attr("fill", (d, index) => d.data.colour)
                                .attr("d", pieArcRight),
                        (update) =>
                            update
                                .attr("fill", (d, index) => d.data.colour)
                                .attr("d", pieArcRight)
                                .attr("transform", "translate(" + pieRightX + " " + imageMiddle + ")"),
                        (exit) => exit.remove(),
                    );

                /***************************************** Lines ***************************/
                if (showlines === true) {
                    const secondPieOffset: number = 15;

                    const halfAngle = secondSliceAngle / 2;

                    const radianAngle: number = (halfAngle * Math.PI) / 180.0;
                    const sinHalfAngle: number = Math.sin(radianAngle);

                    const startHeight: number = pieRadius * sinHalfAngle;

                    // Top Line
                    setTimeout(() => {
                        let startX: number = pieLeftX + pieRadius;
                        let endX: number = pieRightX;
                        let lineY: number = imageMiddle - startHeight;

                        let pts: [number, number][] = [
                            [startX, lineY],
                            [endX, imageMiddle - pieRadius - secondPieOffset + 25],
                        ];

                        let line = d3.line();
                        let pathOfLine: string | null = line(pts);

                        if (pathOfLine !== null) {
                            svg.append("path").attr("class", "cone-lines").attr("d", pathOfLine).attr("fill", "none").attr("stroke", txtColor);
                        }
                    }, 300);

                    // Bottom Line
                    setTimeout(() => {
                        let startX: number = pieLeftX + pieRadius;
                        let endX: number = pieRightX;
                        let lineY: number = imageMiddle + startHeight;

                        let pts: [number, number][] = [
                            [startX, lineY],
                            [endX, imageMiddle + pieRadius + secondPieOffset - 25],
                        ];

                        let line = d3.line();
                        let pathOfLine: string | null = line(pts);

                        if (pathOfLine !== null) {
                            svg.append("path").attr("class", "cone-lines").attr("d", pathOfLine).attr("fill", "none").attr("stroke", txtColor);
                        }
                    }, 300);
                }
                /***************************************** Values ***************************/
                // Left Pie Values
                const hypotForValueText: number = pieRadius + 10;
                const hypotForRightValueText: number = hypotForValueText - 25;
                const padding: number = 5;

                for (let i: number = 0; i < pieArcsLeft.length; i++) {
                    let item: any = leftPieData[i];

                    if (item.value !== 0) {
                        let text: string = item.key;

                        const startAngleDegrees: number = radiansToDegrees(pieArcsLeft[i].startAngle);
                        const endAngleDegrees: number = radiansToDegrees(pieArcsLeft[i].endAngle);
                        const secondSliceAngle: number = endAngleDegrees - startAngleDegrees;

                        const halfAngle: number = secondSliceAngle / 2;

                        let midPointDegrees: number = startAngleDegrees + halfAngle;
                        midPointDegrees += pieLeftAngle;

                        if (midPointDegrees >= 360) {
                            midPointDegrees -= 360;
                        }

                        // Need to work out which Quadrant the angle is in.
                        let quadrant: number = 0;

                        let textPointx: number = 0;
                        let textPointy1: number = 0;

                        let midPointRadians: number = midPointDegrees * (Math.PI / 180.0);

                        let sinHalfAngle: number = Math.sin(midPointRadians);
                        let cosHalfAngle: number = Math.cos(midPointRadians);

                        const labelText: string = item.key + ", " + item.value.toFixed(1) + "%";
                        let widthHeight: any = getTextWidthHeight(labelText);
                        const heightOfset: number = widthHeight.height / 4;

                        if (midPointDegrees >= 0 && midPointDegrees < 90) {
                            quadrant = 0;
                            textPointy1 = imageMiddle - Math.abs(hypotForValueText * cosHalfAngle) - heightOfset;
                            textPointx = pieLeftX + Math.abs(hypotForValueText * sinHalfAngle);
                        } else if (midPointDegrees >= 90 && midPointDegrees < 180) {
                            quadrant = 1;
                            textPointy1 = imageMiddle + Math.abs(hypotForValueText * cosHalfAngle) + heightOfset;
                            textPointx = pieLeftX + Math.abs(hypotForValueText * sinHalfAngle);
                        } else if (midPointDegrees >= 180 && midPointDegrees < 270) {
                            quadrant = 2;
                            textPointy1 = imageMiddle + Math.abs(hypotForValueText * cosHalfAngle) - heightOfset;
                            textPointx = pieLeftX - Math.abs(hypotForValueText * sinHalfAngle) - widthHeight.width;
                        } else if (midPointDegrees >= 270 && midPointDegrees < 360) {
                            quadrant = 3;
                            textPointy1 = imageMiddle - Math.abs(hypotForValueText * cosHalfAngle) + heightOfset;
                            textPointx = pieLeftX - Math.abs(hypotForValueText * sinHalfAngle) - widthHeight.width;
                        }

                        /*   if (quadrant < 2) {
                            textPointx += padding;
                        } else {
                            textPointx -= padding;
                        } */

                        let point0x: number = textPointx - padding;
                        let point0y: number = textPointy1 - widthHeight.height - padding;

                        let point1x: number = textPointx + widthHeight.width + padding;
                        let point1y: number = textPointy1 - widthHeight.height - padding;

                        let point2x: number = textPointx + widthHeight.width + padding;
                        let point2y: number = textPointy1 + padding;

                        let point3x: number = textPointx - padding;
                        let point3y: number = textPointy1 + padding;

                        setTimeout(() => {
                            if (point0x < 0) {
                                // Make sure the text is not going off the left side of the image.
                                let positive: number = Math.abs(point0x) + 1;
                                point0x += positive;
                                point1x += positive;
                                point2x += positive;
                                point3x += positive;
                                textPointx += positive;
                            }

                            svg.append("polygon")
                                .attr("class", "pie-legend")
                                .attr("points", point0x + "," + point0y + " " + point1x + "," + point1y + " " + point2x + "," + point2y + " " + point3x + "," + point3y)
                                .attr("style", "fill: " + bgColor)
                                .attr("stroke", txtColor);
                            svg.append("text")
                                .attr("class", "pie-legend")
                                .attr("data-name", item.key)
                                .attr("transform", "translate(" + textPointx + " " + (textPointy1 - padding / 2) + ")")
                                .attr("fill", txtColor)
                                .attr("style", "font-size: 10px; font-family: Mont-Regular;")
                                .append("tspan")
                                .attr("x", "0")
                                .attr("y", "0")
                                .text(labelText);
                        }, 501 * (i + 1));
                    }
                }

                // Right Pie values
                for (let i: number = 0; i < pieArcsRight.length; i++) {
                    let item: any = rightPieData[i];

                    if (item.value !== 0) {
                        let text: string = item.key;

                        const startAngleDegrees: number = radiansToDegrees(pieArcsRight[i].startAngle);
                        const endAngleDegrees: number = radiansToDegrees(pieArcsRight[i].endAngle);
                        const secondSliceAngle: number = endAngleDegrees - startAngleDegrees;

                        const halfAngle: number = secondSliceAngle / 2;

                        let midPointDegrees: number = startAngleDegrees + halfAngle;
                        // NO ROTATION ON THE RIGHT PIE

                        // Need to work out which Quadrant the angle is in.
                        let quadrant: number = 0;

                        let textPointx: number = 0;
                        let textPointy1: number = 0;

                        let midPointRadians: number = midPointDegrees * (Math.PI / 180.0);

                        let sinHalfAngle: number = Math.sin(midPointRadians);
                        let cosHalfAngle: number = Math.cos(midPointRadians);

                        const labelText: string = item.key + ", " + item.value.toFixed(1) + "%";
                        let widthHeight: any = getTextWidthHeight(labelText);
                        const heightOfset: number = widthHeight.height / 4;

                        if (midPointDegrees >= 0 && midPointDegrees < 90) {
                            quadrant = 0;
                            textPointy1 = imageMiddle - Math.abs(hypotForRightValueText * cosHalfAngle) - heightOfset;
                            textPointx = pieRightX + Math.abs(hypotForRightValueText * sinHalfAngle);
                        } else if (midPointDegrees >= 90 && midPointDegrees < 180) {
                            quadrant = 1;
                            textPointy1 = imageMiddle + Math.abs(hypotForRightValueText * cosHalfAngle) + heightOfset;
                            textPointx = pieRightX + Math.abs(hypotForRightValueText * sinHalfAngle);
                        } else if (midPointDegrees >= 180 && midPointDegrees < 270) {
                            quadrant = 2;
                            textPointy1 = imageMiddle + Math.abs(hypotForRightValueText * cosHalfAngle) - heightOfset;
                            textPointx = pieRightX - Math.abs(hypotForRightValueText * sinHalfAngle) - widthHeight.width;
                        } else if (midPointDegrees >= 270 && midPointDegrees < 360) {
                            quadrant = 3;
                            textPointy1 = imageMiddle - Math.abs(hypotForRightValueText * cosHalfAngle) + heightOfset;
                            textPointx = pieRightX - Math.abs(hypotForRightValueText * sinHalfAngle) - widthHeight.width;
                        }

                        if (quadrant < 2) {
                            textPointx += padding;
                        } else {
                            textPointx -= padding;
                        }

                        let point0x: number = textPointx - padding;
                        let point0y: number = textPointy1 - widthHeight.height - padding;

                        let point1x: number = textPointx + widthHeight.width + padding;
                        let point1y: number = textPointy1 - widthHeight.height - padding;

                        let point2x: number = textPointx + widthHeight.width + padding;
                        let point2y: number = textPointy1 + padding;

                        let point3x: number = textPointx - padding;
                        let point3y: number = textPointy1 + padding;

                        setTimeout(() => {
                            if (point1x > imageWidth) {
                                // Make sure the text is not going off the left side of the image.
                                let negative: number = point1x - imageWidth + 1;
                                point0x -= negative;
                                point1x -= negative;
                                point2x -= negative;
                                point3x -= negative;
                                textPointx -= negative;
                            }

                            svg.append("polygon")
                                .attr("class", "pie-legend")
                                .attr("points", point0x + "," + point0y + " " + point1x + "," + point1y + " " + point2x + "," + point2y + " " + point3x + "," + point3y)
                                .attr("style", "fill: " + bgColor)
                                .attr("stroke", txtColor);
                            svg.append("text")
                                .attr("class", "pie-legend")
                                .attr("data-name", item.key)
                                .attr("transform", "translate(" + textPointx + " " + (textPointy1 - padding / 2) + ")")
                                .attr("fill", txtColor)
                                .attr("style", "font-size: 10px; font-family: Mont-Regular;")
                                .append("tspan")
                                .attr("x", "0")
                                .attr("y", "0")
                                .text(labelText);
                        }, 501 * (i + 1));
                    }
                }

                /***************************************** Legend  ***************************/
                let barLength: number = 12;
                let legendBandHeight: number = 12;
                let currentY: number = imageHeight - legendBandHeight - 10;
                let currentX: number = 30;

                for (let i: number = 0; i < metrics.length; i++) {
                    //for (let i: number = 0; i < 2; i++) {
                    let item: ConditionSetMetricDto = metrics[i];
                    let text: string = item.name;

                    let point0x: number = currentX;
                    let point0y = currentY; // constant

                    let point1x = point0x + barLength; // constant
                    let point1y = currentY; // constant

                    let point2x = point0x + barLength; // constant
                    let point2y = currentY + legendBandHeight;

                    let point3x = point0x; // constant
                    let point3y = point2y; // needs calculating

                    setTimeout(() => {
                        let amendedName: string = item.name;

                        if (amendedName.length >= 15) {
                            amendedName = item.name.substring(0, 12) + "...";
                        }

                        svg.append("polygon")
                            .attr("class", "pie-legend")
                            .attr("points", point0x + "," + point0y + " " + point1x + "," + point1y + " " + point2x + "," + point2y + " " + point3x + "," + point3y)
                            .attr("style", "fill: " + metrics[i].statusColour + ";");
                        svg.append("text")
                            .attr("class", "pie-legend")
                            .attr("data-name", amendedName)
                            .attr("transform", "translate(" + (point1x + barLength + 30) + " " + (point0y + 10) + ")")
                            .attr("fill", txtColor)
                            .attr("fontSize", 8)
                            .attr("fontFamily", "Mont-Regular, Mont")
                            .append("tspan")
                            .attr("x", "-39")
                            .attr("y", "0")
                            .text(amendedName);
                    }, 501 * (i + 1));

                    currentX = currentX + 125;
                }
            } else {
                svg.append("text")
                    .attr("class", "legend-title")
                    .attr("data-name", "Legend")
                    .attr("transform", "translate(" + (imageWidth / 2 + 30) + " 19)")
                    .attr("fill", txtColor)
                    .attr("style", "font-size: 16px; text-anchor: middle")
                    .attr("fontFamily", "Mont-Regular, Mont")
                    .append("tspan")
                    .attr("x", "-39")
                    .attr("y", "10")
                    .text("No condition set metrics");
            }
        }
    }, [metrics, svgElement.current, total, imageHeight, imageWidth, marginTop, isLoading, counter, title]);

    return useObserver(() => (
        <>
            <ConditionSetMetricsInnerBox height={imageHeight} maxwidth="50%">
                <div id="metricimage">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        ref={svgElement}
                        height={props.imageHeight}
                        width={imageWidth}
                        viewBox={"0, 0, 800 300"}
                        preserveAspectRatio="xMidYMid meet"
                    />
                </div>
            </ConditionSetMetricsInnerBox>
        </>
    ));
}
