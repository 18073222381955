import * as React from "react";
import { PropertyViewModel } from "./PropertyViewModel";
import { PropertyDetailsPageContainer } from "./PropertyDetailsPage.styles";
import * as MobXReact from "mobx-react-lite";
import * as MobX from "mobx";
import { ProjectDetailsViewModel } from "../Projects/Details/ProjectDetailsViewModel";
import { useHasRoutedTabs } from "../../Utils/useHasRoutedTabs";
import { useRouter } from "../../../Core/Utils/Hooks/UseReactRouter";
import { formatAddress } from "../../Utils/format";
import { Loader } from "../../../Core/Components/Loader";
import { AppUrls } from "../../Globals";
import { ITab, Tabs } from "../../Components/Tabs/Tabs";
import { InstallationList } from "../Projects/Details/Properties/Installations/InstallationList";
import { useLoadCall } from "../../Utils/useLoadCall";
import { NotFoundModal } from "Custom/Components/NotFoundModal/NotFoundModal";
import { BackBtn } from "../../Components/BackBtn";
import { PropertyNotes } from "../Projects/Details/Properties/PropertyNotes/PropertyNotes";
import { PropertyDocumentsTab } from "./Documents/PropertyDocuments";

const TAB_INSTALLATIONS = "installations";
const TAB_NOTES = "notes";
const TAB_DOCUMENTS = "documents";
/* const TAB_ACTIVITY = "activity"; */

export const PropertyDetailsPage: React.FC = () => {
    const [viewModel] = React.useState(() => PropertyViewModel.Instance);
    const [projectVM] = React.useState(() => ProjectDetailsViewModel.Instance);
    /*     const [documentCount, setDocumentCount] = React.useState<number>(viewModel.documents.length); */

    /* const activityCount = 0; // TODO */

    const getTabOptions: () => ITab[] = () => [
        { key: TAB_INSTALLATIONS, title: "Installations", hash: "installations" },
        { key: TAB_NOTES, title: "Property notes", hash: "notes" },
        { key: TAB_DOCUMENTS, title: "Property documents", hash: "documents" },
        /*{ key: TAB_ACTIVITY, title: "Activity", hash: "activity" }, */
    ];

    const routedTab = useHasRoutedTabs(getTabOptions());
    const [selectedTab, setSelectedTab] = React.useState(routedTab?.key || TAB_INSTALLATIONS);
    const [tabKey, setTabKey] = React.useState(0);

    const router = useRouter<{ projectId?: string; propertyId: string; clientId?: string }>();

    /*     React.useEffect(() => {
        setDocumentCount(viewModel.getDocuments.length);
    }, [viewModel.getDocuments]); */

    const notFound = useLoadCall(
        async () => {
            const { propertyId, projectId } = router.match.params;

            await viewModel.loadAsync(propertyId, projectId);
            return !!viewModel.getValue("id");
        },
        { checkGuidParamKey: "propertyId" },
    );

    const getTabKey = () => {
        return `projectdetailsdetails-tab-${selectedTab}-${tabKey}`;
    };

    const reload = (): void => {
        const { propertyId, projectId } = router.match.params;
        viewModel.loadAsync(propertyId, projectId).then((result: any) => {});
        // TODO CMR need an easier way of doing this.
    };

    const renderContent = () => {
        switch (selectedTab) {
            case TAB_INSTALLATIONS:
                return <InstallationList key={getTabKey()} viewModel={viewModel} reload={reload} />;
            case TAB_NOTES:
                return <PropertyNotes key={getTabKey()} viewModel={viewModel} />;
            case TAB_DOCUMENTS:
                return <PropertyDocumentsTab key={getTabKey()} externalViewModel={viewModel} title="Property Documents" />;
            /*             case TAB_ACTIVITY:
                return <div key={getTabKey()} />; */
        }
    };

    const handleTabClick = (tab: ITab) => {
        setSelectedTab(tab.key);
        setTabKey(tabKey + 1);
    };

    const handleBack = () => {
        router.history.push(getReturnRoute());
    };

    const getBackText = (): string => {
        let retVal: string = "previous";
        const { path } = router.match;

        switch (path) {
            case AppUrls.Client.Main.Device.PropertyDetail: {
                retVal = "units";
                break;
            }
            case AppUrls.Client.ClientView.Project.PropertyDetail:
            case AppUrls.Client.Main.Client.Project.PropertyDetail:
            case AppUrls.Client.Main.Client.Property.ClientsDetailProperty:
            case AppUrls.Client.Main.Project.ProjectDetail:
            case AppUrls.Client.Main.Project.Property.PropertyRoot:
            default: {
                retVal = projectVM.pageTitle;
                break;
            }
        }

        return retVal;
    };

    const renderPage = () => {
        if (viewModel.IsLoading || notFound) return <Loader />;

        return (
            <PropertyDetailsPageContainer>
                <div className="info-address">
                    <BackBtn text={`Back to ${getBackText()}`} onClick={handleBack} />

                    <h2>{formatAddress(viewModel.model, true)}</h2>

                    <h2>
                        <span>Project number: {projectVM.getValue("number")}</span>
                    </h2>
                </div>

                <Tabs tabs={getTabOptions()} onTabClick={handleTabClick} selected={selectedTab} minTabWidth={210} tabPadding="20px 0 15px" />

                <div className="content">{renderContent()}</div>
            </PropertyDetailsPageContainer>
        );
    };

    const getReturnRoute = () => {
        const { projectId, clientId } = router.match.params;
        const { path } = router.match;

        let url: string = AppUrls.Client.Root;

        switch (path) {
            case AppUrls.Client.Main.Device.PropertyDetail: {
                url = AppUrls.Client.Main.Device.Root; // Need to switch to map
                break;
            }
            case AppUrls.Client.Main.Device.PropertyDetail: {
                url = AppUrls.Client.Main.Device.Root;
                break;
            }
            case AppUrls.Client.ClientView.Project.PropertyDetail: {
                url = AppUrls.Client.ClientView.Project.ProjectDetail.replace(":projectId", projectId!);
                break;
            }
            case AppUrls.Client.Main.Client.Project.PropertyDetail: {
                url = AppUrls.Client.Main.Client.Project.ClientsDetailProject.replace(":clientId", clientId!).replace(":projectId", projectId!);
                break;
            }
            case AppUrls.Client.Main.Client.Property.ClientsDetailProperty: {
                url = AppUrls.Client.Main.Client.ClientsDetail.replace(":clientId", clientId!);
                break;
            }
            case AppUrls.Client.Main.Project.ProjectDetail: {
                url = AppUrls.Client.Main.Project.ProjectDetail.replace(":projectId", projectId!);
                break;
            }
            case AppUrls.Client.Main.Project.Property.PropertyRoot: {
                url = AppUrls.Client.Main.Project.ProjectDetail.replace(":projectId", projectId!);
                break;
            }

            default: {
                break;
            }
        }
        return url;
    };

    return MobXReact.useObserver(() => (
        <>
            <NotFoundModal returnRoute={getReturnRoute()} open={notFound} />
            {renderPage()}
        </>
    ));
};
