import styled from "styled-components";
import { Paper } from "@material-ui/core";

export const BaseModalDialogPaper = styled(Paper)`
    border-radius: 0;
    background: transparent linear-gradient(180deg, #565758 0%, #373a40 100%) 0% 0% no-repeat padding-box;
    .roofcare-dialog-title {
        background: ${(props) => props.theme.app.colors.headers.main};
        color: ${(props) => props.theme.palette.common.white};
        padding: 16px 20px;

        h2 {
            display: flex;
            justify-content: space-between;
            font-size: 14px;
            font-weight: normal;

            span.modal-close {
                cursor: pointer;
                font-weight: bold;
            }
        }
    }

    .MuiDialogContent-root {
        padding: 16px 20px 0;

        p {
            font-size: 14px;
        }

        hr {
            border: none;
            height: 1px;
            background-color: ${(props) => props.theme.app.colors.grey.border};
        }
    }

    .MuiDialogActions-root {
        padding: 20px;

        > :not(:first-child) {
            margin-left: 20px;
        }

        > button {
            width: 100%;
            font-size: 14px;
        }
    }

    p,
    .client-modal-description {
        color: white;
    }

    p,
    .client-modal-arraydescription {
        color: white;
        padding-bottom: 0 !important;
    }
`;
