import { PropertyDTO } from "Custom/Models/Domain";
import { ModelBase } from "Core/Models";
import { action, computed, observable } from "mobx";
import { IsNotEmpty } from "class-validator";
import { UnitStatus, UnitStatusDTO } from "../Installations/UnitStatus";

export class AddressModel extends ModelBase<AddressModel, AddressModelDTO> {
    @observable
    public id?: string = "";

    @observable
    public buildingName: string = "";

    @observable
    @IsNotEmpty({ message: "Address line 1 is required" })
    public addressLine1: string = "";

    @observable
    public addressLine2: string = "";

    @observable
    @IsNotEmpty({ message: "City is required" })
    public city: string = "";

    @observable
    public county: string = "";

    @observable
    @IsNotEmpty({ message: "Postcode is required" })
    public postcode: string = "";

    @observable public locationLatitude: number = 0;
    @observable public locationLongitude: number = 0;

    @observable
    public saveAddress: boolean = false;

    public fromDto = (model: AddressModelDTO) => {
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                this[key] = model[key];

                if (key === "addressLine2") {
                    if (this[key] === null || this[key] === undefined) {
                        this[key] = "";
                    }
                }
            }
        }
    };

    @action
    public clear() {
        this.id = "";
        this.buildingName = "";
        this.addressLine1 = "";
        this.addressLine2 = "";
        this.city = "";
        this.county = "";
        this.postcode = "";
        this.locationLatitude = 0;
        this.locationLongitude = 0;
    }

    public toDto = (): AddressModelDTO => {
        return {
            buildingName: this.buildingName,
            addressLine1: this.addressLine1,
            addressLine2: this.addressLine2,
            city: this.city,
            county: this.county,
            postcode: this.postcode,
            locationLatitude: this.locationLatitude,
            locationLongitude: this.locationLongitude,
        };
    };

    public toPropertyDto = (): PropertyDTO => {
        return {
            buildingName: this.buildingName,
            addressLine1: this.addressLine1,
            addressLine2: this.addressLine2,
            city: this.city,
            county: this.county,
            postcode: this.postcode,
            locationLatitude: this.locationLatitude,
            locationLongitude: this.locationLongitude,
            dateCreated: "",
            clientId: "",
            id: this.id,
            okToAddInstallation: false,
            status: new UnitStatus(),
        };
    };

    @action
    public copy = (model: AddressModel) => {
        this.buildingName = model.buildingName;
        this.addressLine1 = model.addressLine1;
        this.addressLine2 = model.addressLine2;
        this.city = model.city;
        this.county = model.county;
        this.postcode = model.postcode;
        this.id = model.id;
        this.locationLatitude = model.locationLatitude;
        this.locationLongitude = model.locationLongitude;
    };

    @computed
    public get getAddress(): AddressModel {
        let retVal = new AddressModel();

        retVal.buildingName = this.buildingName;
        retVal.addressLine1 = this.addressLine1;
        retVal.addressLine2 = this.addressLine2 === undefined ? "" : retVal.addressLine2;
        retVal.city = this.city;
        retVal.county = this.county;
        retVal.postcode = this.postcode;
        retVal.id = this.id;
        return retVal;
    }
}

export type AddressModelDTO = {
    buildingName: string;
    addressLine1: string;
    addressLine2: string;
    city: string;
    county: string;
    postcode: string;
    locationLatitude: number;
    locationLongitude: number;
};
