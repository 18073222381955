import { ViewModelBase } from "Core/ViewModels/ViewModelBase";
import { FieldType } from "Core/Utils/Utils";
import { action, observable, runInAction } from "mobx";
import { AdminMiscDTO, AdminMiscModel } from "./AdminMiscModel";
import { ApiResult, ApiResultErrorType } from "Core/Models";
import { Server } from "Custom/Globals/AppUrls";
import { isNullOrUndefined } from "util";

//extend viewmodel base and passing your model as the generic type
export class AdminMiscViewModel extends ViewModelBase<AdminMiscModel> {
    //Singleton instance of class
    private static _instance: AdminMiscViewModel;
    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    @observable public errorMessage: string = "";

    constructor() {
        //Pass in a new instance of your model
        //By passing in true as the second parameter, we make this model undoable which means we can use save and reset options on the model
        //If you make a change to the model you need to persist it with a saveModel() call
        //If you make changes to your model you can revert it back by calling resetModel()
        super(new AdminMiscModel(), false);
        //EN - Haven't figured out how to make this call work from the base model yet
        //This is only needed if you make use of the validation decorators
        this.setDecorators(AdminMiscModel);
    }

    //isValid will check all fields to make sure they are in a valid state.
    public doSubmit = async (e: any) => {
        e.preventDefault();

        if (this.isModelValid()) {
            //Do stuff here
            this.errorMessage = "Form is valid";
        } else {
            this.errorMessage = "Form is not valid";
        }
    };

    //This must be present in your viewmodel. Just return true if you dont need to validate anything.
    //keyof BlankModel & string lets you add type checking to the fieldName
    //I am using the validator package to make checking easier https://www.npmjs.com/package/validator
    public isFieldValid(fieldName: keyof FieldType<AdminMiscModel>): boolean {
        const { isValid, errorMessage } = this.validateDecorators(fieldName);

        //You need to this two properties after validation
        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    @action public async loadMisc(): Promise<ApiResult<AdminMiscDTO | undefined>> {
        let apiResult: ApiResult<AdminMiscDTO | undefined> = {
            wasSuccessful: false,
            errors: [],
            headers: "",
            payload: undefined,
        };

        apiResult = await this.Get<AdminMiscDTO | undefined>(Server.Api.Admin.getAdminMisc);

        runInAction(() => {
            if (apiResult.wasSuccessful === true) {
                this.errorMessage = "";

                if (isNullOrUndefined(apiResult.payload) === false) {
                    this.model.fromDto(apiResult.payload!);
                }
            } else {
                this.errorMessage = "Failed to get the admin data.";

                apiResult.wasSuccessful = false;
                apiResult.errors = [
                    {
                        type: ApiResultErrorType.Basic,
                        message: "Failed to get the the admin data.",
                    },
                ];

                setTimeout(() => {
                    runInAction(() => {});
                }, 5000);
            }
        });

        return apiResult;
    }

    @action public async upsertMisc(): Promise<ApiResult<AdminMiscDTO | undefined>> {
        let apiResult: ApiResult<AdminMiscDTO | undefined> = {
            wasSuccessful: false,
            errors: [],
            headers: "",
            payload: undefined,
        };

        let request: AdminMiscDTO = this.model.toTheDto();

        apiResult = await this.Post<AdminMiscDTO | undefined>(Server.Api.Admin.upsertAdminMisc, request);

        runInAction(() => {
            if (apiResult.wasSuccessful === true) {
                this.errorMessage = "";

                if (isNullOrUndefined(apiResult.payload) === false) {
                    this.model.fromDto(apiResult.payload!);
                }
            } else {
                this.errorMessage = "Failed to get the admin data.";

                apiResult.wasSuccessful = false;
                apiResult.errors = [
                    {
                        type: ApiResultErrorType.Basic,
                        message: "Failed to get the the admin data.",
                    },
                ];

                setTimeout(() => {
                    runInAction(() => {});
                }, 5000);
            }
        });

        return apiResult;
    }
}
