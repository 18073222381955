import * as d3 from "d3";
import { svg } from "leaflet";
import { useEffect, useRef } from "react";
import { transpileModule } from "typescript";
import { SvgArrowComponent } from "./Arrow";
import { CircularDisplayWrapper } from "./CircularDisplay.style";

export enum ICircularDisplayArrow {
    NONE = 0,
    SAME = 1,
    UP = 2,
    DOWN = 3,
}
interface IProps {
    centerData: string;
    height: number;
    width: number;

    fillColor: string;
    strokeColor: string;

    strokeWidth: number;

    arrow?: ICircularDisplayArrow;

    legendText?: string;
    legendInMiddle?: boolean;

    circleDiameter: number;

    prefix: string;
}

export const CircularDisplay: React.FC<IProps> = (props) => {
    const { prefix, fillColor, circleDiameter, strokeColor, centerData, height, width, strokeWidth, arrow, legendText, legendInMiddle } = props;
    // The useRef Hook creates a variable that "holds on" to a value across rendering passes. In
    // this case it will hold our component's SVG DOM element. It's initialized null and React
    // will assign it later (see the return statement).
    const svgElement = useRef(null);
    const svgSize = circleDiameter;
    const pieRadius = (circleDiameter - 8) / 2;

    let hasArrow: boolean = false; // needs to be false
    let isUpArrow: boolean = true; // needs to be true

    if (arrow !== undefined && arrow !== ICircularDisplayArrow.NONE && arrow !== ICircularDisplayArrow.SAME) {
        hasArrow = true;

        if (props.arrow === ICircularDisplayArrow.DOWN) {
            isUpArrow = false;
        }
    }

    useEffect(() => {
        return () => {
            // Clean up after yourself
        };
    }, []);

    // The useEffect hook is for running side effects outside of React, for instance inserting
    // elements into the DOM using D3.
    useEffect(() => {
        if (svgElement.current !== null) {
            if (hasArrow === true) {
                let G1 = document.getElementById(prefix + "centertext");
                let G2 = document.getElementById(prefix + "centerArrow");
                let BB1 = (G1 as any).getBBox();
                let BB2 = (G2 as any).getBBox();
                (G2 as any).setAttribute("transform", "translate(" + (BB1.x + BB1.width - BB2.x) + " " + (BB1.y + BB1.height - BB2.y - 35) + ")");
            }
        }
    }, [
        props.centerData,
        props.arrow,
        props.circleDiameter,
        props.fillColor,
        props.height,
        props.legendInMiddle,
        props.legendText,
        props.strokeColor,
        props.width,
        svgElement.current,
    ]);

    const offset: number = hasArrow === true ? -10 : 0;

    return (
        <CircularDisplayWrapper height={props.height}>
            <div className="circlesection">
                <svg xmlns="http://www.w3.org/2000/svg" ref={svgElement} height={height} width={width} viewBox={`-${svgSize / 2}, -${svgSize / 2}, 160, 160`}>
                    <circle id={prefix + "centerCircle"} fill={fillColor} stroke={strokeColor} strokeWidth={strokeWidth} r={pieRadius - 1} cx={0} cy={0} />
                    <g id={prefix + "centerrow"} transform={"translate(" + offset + ", 0)"}>
                        <g id={prefix + "centertext"} transform="translate(-6, 10)">
                            <text fill="white" fontFamily="Mont-Regular" fontSize="3.2em" textAnchor="middle" dy="0">
                                {centerData}
                            </text>
                        </g>
                        <g id={prefix + "centerArrow"} transform="translate(0, 12)">
                            {hasArrow === true && isUpArrow === true && (
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="34.286" viewBox="0 0 30 34.286">
                                    <path id="noun-arrow-89790" d="M120,0,105,15h8.571V34.286h12.857V15H135Z" transform="translate(-105)" fill={strokeColor} />
                                </svg>
                            )}
                            {hasArrow === true && isUpArrow === false && (
                                <svg width="30" height="34.285999" viewBox="0 0 30 34.286" version="1.1" id="svg17" xmlns="http://www.w3.org/2000/svg">
                                    <path id="noun-arrow-89790" d="m 15,34.286 15,-15 H 21.429 V 0 H 8.572 V 19.286 H 0 Z" fill={strokeColor} transform="translate(0)" />
                                </svg>
                            )}
                        </g>
                    </g>
                    {legendInMiddle !== undefined && legendInMiddle === true && legendText !== undefined && (
                        <text fill="white" fontFamily="Mont-Regular" fontSize="1em" text-anchor="middle" dy="2.0em">
                            {legendText!}
                        </text>
                    )}
                </svg>
            </div>
        </CircularDisplayWrapper>
    );
};
