import { ConditionType, ConditionUnits } from "./../../../Models/Domain/Installations/Enums";
import { action, observable } from "mobx";
import { ModelBase } from "Core/Models/ModelBase";
import { IsNotEmpty } from "class-validator";

//Base class expects the model type and the DTO model type
//If you are not using a DTO just pass in undefined as the DTO
export class ConditionItemModel extends ModelBase<ConditionItemModel, ConditionItemModelDTO> {
    public id: string | null = null;
    public rowVersion: string | null = null;
    public createdBy: string | null = null;
    public createdDate: string | null = null;
    public conditionSetId: string | null = null;

    @observable
    public commandIndex: number = 0;

    @observable
    public statusColour: string = "#07703B";

    @observable
    public statusTextColour: string = "#ffffff";

    @observable public isDeleted: boolean = false;

    @observable
    @IsNotEmpty({ message: "You must give me a parameter name!!" })
    public name: string = "";

    @observable
    public type: ConditionType = ConditionType.H;

    @observable
    @IsNotEmpty({ message: "You must give me a value!!" })
    public value: number = 0;

    @observable
    public units: ConditionUnits = ConditionUnits.MM;

    @action
    public setCommandIndex = (value: number) => {
        this.commandIndex = value;
    };

    //fromDto is required but you can leave it blank
    fromDto(model: ConditionItemModelDTO): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
    }

    //toDto is required but you can leave it blank
    toDto(model: ConditionItemModel): void {}

    public toSendDto(): ConditionItemModelDTO {
        return {
            id: this.id,
            rowVersion: this.rowVersion,
            isDeleted: this.isDeleted,
            createdBy: this.createdBy,
            createdDate: this.createdDate,

            name: this.name,
            type: this.type,
            value: this.value,
            units: this.units,
            conditionSetId: this.conditionSetId,
            commandIndex: this.commandIndex,

            statusColour: this.statusColour,
            statusTextColour: this.statusTextColour,
        };
    }
}

export type ConditionItemModelDTO = {
    id: string | null;
    rowVersion: string | null;
    isDeleted: boolean;
    createdBy: string | null;
    createdDate: string | null;

    name: string;
    type: ConditionType;
    value: number;
    units: ConditionUnits;
    conditionSetId: string | null;
    commandIndex: number;
    statusColour: string;
    statusTextColour: string;
};
