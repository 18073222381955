import { Box, Fade, Paper, useTheme } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import { Loader } from "Core/Components";
import { useObserver } from "mobx-react-lite";
import { AdminTabPageViewModel } from "../AdminTabPageViewModel";
import { Container } from "Custom/StylesAppSpecific/AppStyling";
import { ProjectListSearch } from "../../Projects/ProjectListSearch";
import { AdminSearchWrapper } from "Custom/StylesAppSpecific/Controls/BoxAddWrapper";
import { ProjectButton } from "../../CustomComponents";
import { AppUrls } from "Custom/Globals";
import { useRouter } from "Core/Base";
import { RoofcareTable, RoofcareTableWrapperFullWidth } from "Custom/Components/Table/RoofcareTable";
import { ResetSearchIcon } from "Custom/Components/Table/ResetSearchIcon";
import { DeleteCircle } from "../Condition/Condition.style";
import { DeleteModal } from "Custom/Components/Modal/DeleteModal";
import { AdminProjectPaymentStatusTabViewModel } from "./AdminProjectPaymentStatusTabViewModel";
import { AdminProjectPaymentStatusTabModel } from "./AdminProjectPaymentStatusTabModel";

import * as Defaults from "Custom/StylesAppSpecific/TableOptions";
import { ProjectStatusCell } from "Custom/Components/Status/ProjectStatusCell.styles";
import { ProjectListViewModel } from "Custom/Views/Projects/ProjectListViewModel";
interface IProps {
    parentViewModel: AdminTabPageViewModel;
}

export const AdminProjectPaymentStatusTab: React.FunctionComponent<IProps> = ({ parentViewModel }) => {
    const [viewModel] = useState(() => AdminProjectPaymentStatusTabViewModel.Instance);
    const { history } = useRouter();

    const [deleteOpen, setDeleteOpen] = React.useState(false);
    const [selectedItem, selectItem] = React.useState(-1);

    const handleDeleteOpen = () => {
        setDeleteOpen(true);
    };

    const handleDeleteClose = () => {
        setDeleteOpen(false);
        selectItem(-1);
    };

    const handleDelete = () => {
        viewModel.delete(selectedItem).then(() => {
            // Sort out the Project List
            ProjectListViewModel.Instance.deleteProjectPaymentStatus(selectedItem);

            setDeleteOpen(false);
            selectItem(-1);
        });
    };

    //useEffect below only gets run on initial render
    useEffect(() => {
        //let promise =
        viewModel.loadList();

        // promise.then((result) => {});

        //This gets run when the page is exited
        return () => {
            viewModel.clear();
        };
    }, []);

    const setSearchString = (value: string) => {
        viewModel.setSearchString(value);
    };

    const onDelete = (id: number) => {
        selectItem(id);
        handleDeleteOpen();
    };

    const renderContent = () => {
        const tableOptions = Defaults.GetRoofcareTableOptions() as any;
        return (
            <>
                <DeleteModal
                    open={deleteOpen}
                    onClose={handleDeleteClose}
                    onDelete={handleDelete}
                    text="Do you want to continue to delete this project payment status?"
                    title="Delete Project Payment Status?"
                />
                <RoofcareTableWrapperFullWidth key="condition-set-table" paddingTop="5px !important">
                    <RoofcareTable
                        columns={[
                            {
                                width: "auto",
                                title: "Status Name",
                                field: "statusName",
                                sorting: true,
                                filtering: true,
                                render: (rowData: AdminProjectPaymentStatusTabModel) => {
                                    if (rowData !== null && rowData !== undefined) {
                                        return (
                                            <ProjectStatusCell onClick={() => handleRowClick(rowData)} statuscolor={rowData.colorHex} textcolor={rowData.textColorHex}>
                                                {rowData.statusName}
                                            </ProjectStatusCell>
                                        );
                                    }
                                },
                            },
                            {
                                width: "auto",
                                title: "Author",
                                field: "author",
                                sorting: true,
                                filtering: true,
                                render: (rowData: AdminProjectPaymentStatusTabModel) => {
                                    if (rowData !== null && rowData !== undefined) {
                                        return <div onClick={() => handleRowClick(rowData)}>{rowData.author}</div>;
                                    }
                                },
                            },
                            {
                                width: "auto",
                                title: "Drop Down Position",
                                field: "ordinal",
                                sorting: true,
                                filtering: true,
                                render: (rowData: AdminProjectPaymentStatusTabModel) => {
                                    if (rowData !== null && rowData !== undefined) {
                                        return <div onClick={() => handleRowClick(rowData)}>{rowData.ordinal}</div>;
                                    }
                                },
                            },
                            {
                                width: "auto",
                                title: "Project Count",
                                field: "projectCount",
                                sorting: true,
                                filtering: true,
                                render: (rowData: AdminProjectPaymentStatusTabModel) => {
                                    if (rowData !== null && rowData !== undefined) {
                                        return <div onClick={() => handleRowClick(rowData)}>{rowData.projectCount}</div>;
                                    }
                                },
                            },
                            {
                                width: "40px",
                                maxWidth: "40px",
                                title: "",
                                field: "name2",
                                sorting: false,
                                filtering: false,
                                render: (rowData: AdminProjectPaymentStatusTabModel) => {
                                    if (rowData.projectCount === 0 && rowData.id > 1) {
                                        return <DeleteCircle onClick={() => onDelete(rowData.id)}>X</DeleteCircle>;
                                    } else {
                                        return <div></div>;
                                    }
                                },
                                cellStyle: { width: "40px", padding: "0 15px 0 10px" },
                                headerStyle: { width: "40px" },
                            },
                        ]}
                        options={{
                            search: false,
                            toolbar: false,
                            thirdSortClick: false,
                            headerStyle: {
                                ...tableOptions.headerStyle,
                            },
                            rowStyle: {
                                ...tableOptions.rowStyle,
                            },
                            filterCellStyle: {
                                ...tableOptions.filterCellStyle,
                            },
                            pageSize: 50,
                            pageSizeOptions: [10, 20, 50, 100],
                            emptyRowsWhenPaging: false,
                            searchFieldAlignment: "left",
                            searchFieldStyle: {
                                border: "1px solid #333333",
                                borderRadius: "5px",
                                width: "400px",
                            },
                        }}
                        data={viewModel.getFilteredItems}
                        title=""
                        icons={{
                            ResetSearch: () => <ResetSearchIcon />,
                        }}
                        components={{ Container: (props: any) => <Paper {...props} elevation={0} /> }}
                        localization={{ toolbar: { searchPlaceholder: "Start typing to search" } }}
                    />
                </RoofcareTableWrapperFullWidth>
            </>
        );
    };

    const handleRowClick = (row: any) => {
        history.push(AppUrls.Client.Main.Admin.ProjectPaymentStatus.ProjectPaymentStatusAddEditDetail.replace(":id", row.id));
    };

    const handleNewClick = () => {
        history.push(AppUrls.Client.Main.Admin.ProjectPaymentStatus.ProjectPaymentStatusAddEditDetail.replace(":id", "0"));
    };

    function renderPage() {
        if (viewModel.IsLoading === true) return <Loader />;

        return (
            <Container>
                <Fade in timeout={1000}>
                    <Container>
                        <Box width="100%">
                            <AdminSearchWrapper>
                                <div className="search-field" style={{ width: "580px" }}>
                                    <ProjectListSearch
                                        searchString={viewModel.getSearchString()}
                                        setSearchString={setSearchString}
                                        placeholder="Start typing to search payment status"
                                    />
                                </div>
                                <div>
                                    <ProjectButton icon={() => <span>&#43;</span>} text="Add new payment status" onClick={() => handleNewClick()} />
                                </div>
                            </AdminSearchWrapper>
                        </Box>
                        <div className="content">{renderContent()}</div>
                    </Container>
                </Fade>
            </Container>
        );
    }

    return useObserver(() => renderPage());
};
