import React, { useContext } from "react";
import { Stores, StoresContext } from "Custom/Stores";
import { PrivateRoute } from "Core/Utils/React";
import * as RouterUtil from "Custom/Utils/routing";
import { Client } from "Custom/Globals/AppUrls";
import { AdminTabPage } from "./AdminTabPage";
import { ConditionAddEditDetail } from "./Condition/ConditionAddEditDetail";
import { AlertActionAddEditDetail } from "./AlertAction/AlertActionAddEditDetail";
import { UnitBehaviourAddEditDetail } from "./UnitBehaviour/UnitBehaviourAddEditDetail";
import { DeviceUnitStatusAddEditDetail } from "./DeviceUnitStatus/DeviceUnitStatusAddEditDetail";
import { UnitFirmwareAddEditDetail } from "./UnitFirmware/UnitFirmwareAddEditDetail";
import { TestGradeAddEditDetail } from "./UnitTestGrade/TestGradeAddEditDetail";
import { UnitVersionAddEditDetail } from "./UnitVersion/UnitVersionAddEditDetail";
import { ProjectStatusAddEditDetail } from "./ProjectStatus/ProjectStatusAddEditDetail";
import { ProjectPaymentStatusAddEditDetail } from "./ProjectPaymentStatus/ProjectPaymentStatusAddEditDetail";

export const AdminRoutes: React.FC = () => {
    const store = useContext<Stores>(StoresContext);
    return (
        <>
            <PrivateRoute
                component={AdminTabPage}
                exact
                isAllowed={() => {
                    return store.domain.AccountStore.IsLoggedIn && RouterUtil.requireAdminLogin(store);
                }}
                path={Client.Main.Admin.Root}
            />
            <PrivateRoute
                component={ConditionAddEditDetail}
                exact
                isAllowed={() => {
                    return store.domain.AccountStore.IsLoggedIn && RouterUtil.requireAdminLogin(store);
                }}
                path={Client.Main.Admin.Condition.ConditionAddEditDetail}
            />
            <PrivateRoute
                component={AlertActionAddEditDetail}
                exact
                isAllowed={() => {
                    return store.domain.AccountStore.IsLoggedIn && RouterUtil.requireAdminLogin(store);
                }}
                path={Client.Main.Admin.AlertAction.AlertActionAddEditDetail}
            />
            <PrivateRoute
                component={UnitBehaviourAddEditDetail}
                exact
                isAllowed={() => {
                    return store.domain.AccountStore.IsLoggedIn && RouterUtil.requireAdminLogin(store);
                }}
                path={Client.Main.Admin.UnitBehaviour.UnitBehaviourAddEditDetail}
            />

            <PrivateRoute
                component={DeviceUnitStatusAddEditDetail}
                exact
                isAllowed={() => {
                    return store.domain.AccountStore.IsLoggedIn && RouterUtil.requireAdminLogin(store);
                }}
                path={Client.Main.Admin.DeviceUnitStatus.DeviceUnitStatusAddEditDetail}
            />

            <PrivateRoute
                component={UnitFirmwareAddEditDetail}
                exact
                isAllowed={() => {
                    return store.domain.AccountStore.IsLoggedIn && RouterUtil.requireAdminLogin(store);
                }}
                path={Client.Main.Admin.UnitFirmware.UnitFirmwareAddEditDetail}
            />

            <PrivateRoute
                component={TestGradeAddEditDetail}
                exact
                isAllowed={() => {
                    return store.domain.AccountStore.IsLoggedIn && RouterUtil.requireAdminLogin(store);
                }}
                path={Client.Main.Admin.UnitTestGrade.UnitTestGradeAddEditDetail}
            />

            <PrivateRoute
                component={UnitVersionAddEditDetail}
                exact
                isAllowed={() => {
                    return store.domain.AccountStore.IsLoggedIn && RouterUtil.requireAdminLogin(store);
                }}
                path={Client.Main.Admin.UnitVersion.UnitVersionAddEditDetail}
            />

            <PrivateRoute
                component={ProjectStatusAddEditDetail}
                exact
                isAllowed={() => {
                    return store.domain.AccountStore.IsLoggedIn && RouterUtil.requireAdminLogin(store);
                }}
                path={Client.Main.Admin.ProjectStatus.ProjectStatusAddEditDetail}
            />

            <PrivateRoute
                component={ProjectPaymentStatusAddEditDetail}
                exact
                isAllowed={() => {
                    return store.domain.AccountStore.IsLoggedIn && RouterUtil.requireAdminLogin(store);
                }}
                path={Client.Main.Admin.ProjectPaymentStatus.ProjectPaymentStatusAddEditDetail}
            />
        </>
    );
};
