import { KeyValuePair } from "Core/Models/KeyValuePair";

export enum ActionType {
    //Unknown = 0,
    NoFollowUpDate = 100,
    HasFollowUpDateNotOverdue = 110,
    HasFollowUpDateOverdue = 120,
    //NotResolved = 200,
    Resolved = 250,
}

export class ActionTypeHelpers {
    public static getText = (actionType: ActionType) => {
        switch (actionType) {
            /*             case ActionType.NotResolved: {
                return "Not resolved";
            } */
            case ActionType.Resolved: {
                return "Resolved";
            }
            case ActionType.HasFollowUpDateNotOverdue: {
                return "Follow up not overdue";
            }
            case ActionType.HasFollowUpDateOverdue: {
                return "Follow up overdue";
            }
            default: {
                return "No follow up date";
            }
        }
    };

    public static getAllFilter = (): string[] => {
        let retVal: string[] = [];

        for (const key in ActionType) {
            const keyAsInt = parseInt(key);

            if (isNaN(keyAsInt) === false) {
                const item = keyAsInt as ActionType;

                retVal.push(keyAsInt.toString());
            }
        }

        return retVal;
    };

    public static getOptions = () => {
        const options: {
            key: string;
            value: string;
        }[] = [];

        for (const key in ActionType) {
            const keyAsInt = parseInt(key);

            //if (keyAsInt !== ActionType.NotResolved) {
            if (isNaN(keyAsInt) === false) {
                const item = keyAsInt as ActionType;

                options.push({
                    key: ActionTypeHelpers.getText(item),
                    value: keyAsInt.toString(),
                });
            }
            //}
        }

        return options;
    };
}
