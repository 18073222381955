export interface UnitNoteDTO {
    id?: number;
    deviceDbId: number;
    note: string;
    publishDate: string;
    authorId: string | null;
    author: string | null;
    rowVersion: string | null;
    isDeleted: boolean;
}

export const DefaultUnitNote = (): UnitNoteDTO => {
    return {
        id: -1,
        deviceDbId: -1,
        note: "",
        publishDate: "",
        authorId: null,
        author: null,
        rowVersion: null,
        isDeleted: false,
    };
};

export interface InsertUnitNoteRequest {
    deviceDbId: number;
    note: string;
}
