import React, { useEffect } from "react";
import Link from "@material-ui/core/Link";
import { Link as RouterLink, useLocation, useRouteMatch } from "react-router-dom";
import { Client } from "Custom/Globals/AppUrls";
import { useBreadcrumbStyles, RoofcareBreadcrumbs } from "./RoofcareBreadcrumb.style";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { RoofcareBreadcrumbViewModel } from "./RoofcareBreadcrumbViewModel";
import { BreadcrumbsContext, IBreadcrumbContextIdValues } from "./RoofcareBreadcrumbCommon";
import { useRouter } from "Core/Utils";
import { ApiResult } from "Core/Models";
import { BreadcrumbContextValueDTO } from "Custom/Models/Domain/Breadcrumb/BreadcrumbContextValue";

export const RoofcareBreadcrumb: React.FC = () => {
    const [viewModel] = React.useState<RoofcareBreadcrumbViewModel>(() => RoofcareBreadcrumbViewModel.Instance);

    const location = useLocation();
    const router = useRouter<{ clientId?: string; contractorId?: string; installId?: string; projectId?: string; propertyId?: string }>();

    const breadcrumbsValues = React.useContext(BreadcrumbsContext);

    const classes = useBreadcrumbStyles();
    const homeMatch = useRouteMatch(Client.Root);

    const estateMatch = useRouteMatch(Client.Main.EstateView.Root);
    /*     const estateUnitMatch = useRouteMatch(Client.Main.EstateView.Device.DeviceRoot);
    const estateUnitInstallationMatch = useRouteMatch(Client.Main.EstateView.Device.Installation);
    const estateUnitPropertyDetailMatch = useRouteMatch(Client.Main.EstateView.Device.PropertyDetail);
    const estateUnitPropertyInstallationMatch = useRouteMatch(Client.Main.EstateView.Device.PropertyInstallation); */

    const estatePropertyDetail = useRouteMatch(Client.Main.EstateView.Property.PropertyDetail);
    const estatePropertyInstallation = useRouteMatch(Client.Main.EstateView.Property.PropertyInstallation);

    const installationMatch = useRouteMatch(Client.Main.Device.Root);
    const installationInstallationMatch = useRouteMatch(Client.Main.Device.Installation);
    const installationPropertyDetailMatch = useRouteMatch(Client.Main.Device.PropertyDetail);
    const installationPropertyInstallationMatch = useRouteMatch(Client.Main.Device.PropertyInstallation);

    const unitMatch = useRouteMatch(Client.Main.Unit.Root);
    const unitInstallationMatch = useRouteMatch(Client.Main.Unit.Installation);

    const adminMatch = useRouteMatch(Client.Main.Admin.Root);

    const adminConditionMatch = useRouteMatch(Client.Main.Admin.Condition.ConditionAddEditDetail);
    const adminAlertActionMatch = useRouteMatch(Client.Main.Admin.AlertAction.AlertActionAddEditDetail);
    const adminUnitBehaviourMatch = useRouteMatch(Client.Main.Admin.UnitBehaviour.UnitBehaviourAddEditDetail);
    const adminUnitTestGradeMatch = useRouteMatch(Client.Main.Admin.UnitTestGrade.UnitTestGradeAddEditDetail);
    const adminUnitFirmwareMatch = useRouteMatch(Client.Main.Admin.UnitFirmware.UnitFirmwareAddEditDetail);
    const adminUnitVersionMatch = useRouteMatch(Client.Main.Admin.UnitVersion.UnitVersionAddEditDetail);
    const adminUnitStatusMatch = useRouteMatch(Client.Main.Admin.DeviceUnitStatus.DeviceUnitStatusAddEditDetail);

    const clientMatch = useRouteMatch(Client.Main.Client.Root);
    const clientAddEditMatch = useRouteMatch(Client.Main.Client.ClientAddEditDetail);

    const dashboardMatch = useRouteMatch(Client.Main.Dashboard.Root);

    const projectMatch = useRouteMatch(Client.Main.Project.Root);
    const projectAddEditMatch = useRouteMatch(Client.Main.Project.ProjectAddEdit);
    const projectDetailMatch = useRouteMatch(Client.Main.Project.ProjectDetail);

    const propertyMatch = useRouteMatch(Client.Main.Project.Property.PropertyRoot);

    const deviceMatch = useRouteMatch(Client.Main.Project.Property.PropertyInstallation);

    const contractorMatch = useRouteMatch(Client.Main.Contractor.Root);
    const contractorDetailMatch = useRouteMatch(Client.Main.Contractor.ContractorAddEditDetail);

    const userMatch = useRouteMatch(Client.Main.User.Root);
    const userDetailMatch = useRouteMatch(Client.Main.User.UserDetail);

    const showClientsList = clientMatch;

    const showEstate = estateMatch || homeMatch?.isExact;

    const showDashboard = dashboardMatch;

    const showProjects = projectMatch;
    const showProjectsDetails = projectDetailMatch && (projectDetailMatch.params as any).id?.toLowerCase() !== "edit";
    const showProjectsList = showProjects && !showProjectsDetails;

    const showProperty = showProjectsDetails && propertyMatch;

    const showDevice = showProperty && deviceMatch;

    const showContractorsList = contractorMatch;

    const showUsersList = userMatch;

    const [matchIdData, setContextData] = React.useState<IBreadcrumbContextIdValues>({
        clientId: undefined,
        contractorId: undefined,
        installationId: undefined,
        projectId: undefined,
        propertyId: undefined,
        userId: undefined,
    });

    useEffect(() => {
        const { clientId, contractorId, installId, projectId, propertyId } = router.match.params;

        if (
            clientId !== matchIdData.clientId ||
            contractorId !== matchIdData.contractorId ||
            installId !== matchIdData.installationId ||
            projectId !== matchIdData.projectId ||
            propertyId !== matchIdData.propertyId
        ) {
            const temp: IBreadcrumbContextIdValues = {
                clientId: clientId,
                contractorId: contractorId,
                installationId: installId === undefined ? undefined : parseInt(installId),
                projectId: projectId,
                propertyId: propertyId,
            };

            setContextData(temp);

            let promise = viewModel.GetBreadcrumbData(temp);

            promise.then((result: ApiResult<BreadcrumbContextValueDTO>) => {
                if (result.wasSuccessful) {
                    const payload = result.payload;

                    breadcrumbsValues.setContext({
                        clientName: payload.clientName,
                        contractorName: payload.contractorName,
                        installationName: payload.installationName,
                        projectName: payload.projectName,
                        propertyName: payload.propertyName,
                        userName: payload.userName,
                    });
                }
            });
        }
    }, [location]);

    return (
        <RoofcareBreadcrumbs className={classes.root} aria-label="breadcrumb" separator={<NavigateNextIcon fontSize="large" />}>
            {/* homeMatch && (
                <Link component={RouterLink} to={Client.Root}>
                    Home
                </Link>
            ) */}
            {/* {mapMatch && (
                <Link component={RouterLink} to={Client.Main.Map.Root}>
                    Property And Installations Map
                </Link>
            )}
            {mapInstallationMatch && (
                <Link component={RouterLink} to={Client.Main.Map.Installation.replace(":installId", (mapInstallationMatch!.params as any).installId)}>
                    {breadcrumbsValues.installationName || "Loading..."}
                </Link>
            )}
            {mapPropertyDetailMatch && (
                <Link component={RouterLink} to={Client.Main.Map.PropertyDetail.replace(":propertyId", (mapPropertyDetailMatch!.params as any).propertyId)}>
                    {breadcrumbsValues.propertyName || "Loading..."}
                </Link>
            )}
            {mapPropertyInstallationMatch && (
                <Link
                    component={RouterLink}
                    to={Client.Main.Map.PropertyInstallation.replace(":propertyId", (mapPropertyInstallationMatch!.params as any).propertyId).replace(
                        ":installId",
                        (mapPropertyInstallationMatch!.params as any).installId,
                    )}
                >
                    {breadcrumbsValues.installationName || "Loading..."}
                </Link>
            )} */}
            {estateMatch && (
                <Link component={RouterLink} to={Client.Main.EstateView.Root}>
                    Estate
                </Link>
            )}
            {estatePropertyDetail && (
                <Link
                    component={RouterLink}
                    to={Client.Main.EstateView.Property.PropertyDetail.replace(":projectId", (estatePropertyDetail.params as any).projectId).replace(
                        ":propertyId",
                        (estatePropertyDetail.params as any).propertyId,
                    )}
                >
                    {breadcrumbsValues.propertyName || "Loading..."}
                </Link>
            )}
            {estatePropertyInstallation && (
                <Link
                    component={RouterLink}
                    to={Client.Main.EstateView.Property.PropertyInstallation.replace(":projectId", (estatePropertyInstallation.params as any).projectId)
                        .replace(":propertyId", (estatePropertyInstallation.params as any).propertyId)
                        .replace(":installId", (estatePropertyInstallation.params as any).installId)}
                >
                    {breadcrumbsValues.installationName || "Loading..."}
                </Link>
            )}
            {dashboardMatch && (
                <Link component={RouterLink} to={Client.Main.Dashboard.Root}>
                    Dashboard
                </Link>
            )}
            {installationMatch && (
                <Link component={RouterLink} to={Client.Main.Device.Root}>
                    Installation
                </Link>
            )}
            {installationInstallationMatch && (
                <Link component={RouterLink} to={Client.Main.Device.Installation.replace(":installId", (installationInstallationMatch!.params as any).installId)}>
                    {breadcrumbsValues.installationName || "Loading..."}
                </Link>
            )}
            {installationPropertyDetailMatch && (
                <Link component={RouterLink} to={Client.Main.Device.PropertyDetail.replace(":propertyId", (installationPropertyDetailMatch!.params as any).propertyId)}>
                    {breadcrumbsValues.propertyName || "Loading..."}
                </Link>
            )}
            {installationPropertyInstallationMatch && (
                <Link
                    component={RouterLink}
                    to={Client.Main.Device.PropertyInstallation.replace(":propertyId", (installationPropertyInstallationMatch!.params as any).propertyId).replace(
                        ":installId",
                        (installationPropertyInstallationMatch!.params as any).installId,
                    )}
                >
                    {breadcrumbsValues.installationName || "Loading..."}
                </Link>
            )}
            {clientMatch && (
                <Link component={RouterLink} to={Client.Main.Client.Root}>
                    End Users
                </Link>
            )}
            {showClientsList && (
                <Link component={RouterLink} to={Client.Main.Client.Root}>
                    End Users
                </Link>
            )}
            {clientAddEditMatch && (
                <Link component={RouterLink} to={Client.Main.Project.ProjectAddEdit.replace(":projectId", (clientAddEditMatch.params as any).projectId)}>
                    {(clientAddEditMatch.params as any).id === "0" ? "New end user" : "Edit end user"}
                </Link>
            )}
            {showDashboard && (
                <Link component={RouterLink} to={Client.Main.Dashboard.Root}>
                    Dashboard
                </Link>
            )}
            {showProjects && (
                <Link component={RouterLink} to={Client.Main.Project.Root}>
                    Projects
                </Link>
            )}
            {showProjectsList && (
                <Link component={RouterLink} to={Client.Main.Project.Root}>
                    Projects
                </Link>
            )}
            {projectAddEditMatch && (
                <Link component={RouterLink} to={Client.Main.Project.ProjectAddEdit.replace(":projectId", (projectAddEditMatch.params as any).projectId)}>
                    {(projectAddEditMatch.params as any).id === "0" ? "New project" : "Edit project"}
                </Link>
            )}
            {showProjectsDetails && (
                <Link component={RouterLink} to={Client.Main.Project.ProjectDetail.replace(":projectId", (projectDetailMatch!.params as any).projectId)}>
                    {breadcrumbsValues.projectName || "Loading..."}
                </Link>
            )}
            {showProperty && (
                <Link
                    component={RouterLink}
                    to={Client.Main.Project.Property.PropertyRoot.replace(":projectId", (propertyMatch!.params as any).projectId).replace(
                        ":propertyId",
                        (propertyMatch!.params as any).propertyId,
                    )}
                >
                    {breadcrumbsValues.propertyName || "Loading..."}
                </Link>
            )}
            {showDevice && (
                <Link
                    component={RouterLink}
                    to={Client.Main.Project.Property.PropertyInstallation.replace(":projectId", (deviceMatch!.params as any).id)
                        .replace(":propertyId", (deviceMatch!.params as any).propertyId)
                        .replace(":deviceId", (deviceMatch!.params as any).deviceId)}
                >
                    {breadcrumbsValues.installationName || "Loading..."}
                </Link>
            )}
            {contractorMatch && (
                <Link component={RouterLink} to={Client.Main.Contractor.Root}>
                    Contractors
                </Link>
            )}
            {showContractorsList && (
                <Link component={RouterLink} to={Client.Main.Contractor.Root}>
                    Contractors
                </Link>
            )}
            {contractorDetailMatch && (
                <Link component={RouterLink} to={Client.Main.Contractor.ContractorAddEditDetail.replace(":id", (contractorDetailMatch.params as any).id)}>
                    {(contractorDetailMatch.params as any).id === "0" ? "New contractor" : "Edit contractor"}
                </Link>
            )}
            {userMatch && (
                <Link component={RouterLink} to={Client.Main.User.Root}>
                    Users
                </Link>
            )}
            {showUsersList && (
                <Link component={RouterLink} to={Client.Main.User.Root}>
                    Users
                </Link>
            )}
            {userDetailMatch && (
                <Link component={RouterLink} to={Client.Main.User.UserDetail.replace(":id", (userDetailMatch.params as any).id)}>
                    {(userDetailMatch.params as any).id === "0" ? "New user" : "Edit user"}
                </Link>
            )}
            {adminMatch && (
                <Link component={RouterLink} to={Client.Main.Admin.Root}>
                    Admin
                </Link>
            )}
            {adminConditionMatch && (
                <Link component={RouterLink} to={Client.Main.Admin.Root + "#condition"}>
                    Condition Set
                </Link>
            )}
            {adminAlertActionMatch && (
                <Link component={RouterLink} to={Client.Main.Admin.Root + "#condition"}>
                    Alert Action
                </Link>
            )}
            {adminUnitBehaviourMatch && (
                <Link component={RouterLink} to={Client.Main.Admin.Root + "#condition"}>
                    Unit Behaviour
                </Link>
            )}
            {adminUnitTestGradeMatch && (
                <Link component={RouterLink} to={Client.Main.Admin.Root + "#condition"}>
                    Unit Test Grade
                </Link>
            )}
            {adminUnitFirmwareMatch && (
                <Link component={RouterLink} to={Client.Main.Admin.Root + "#condition"}>
                    Unit Firmware
                </Link>
            )}
            {adminUnitVersionMatch && (
                <Link component={RouterLink} to={Client.Main.Admin.Root + "#condition"}>
                    Unit Version
                </Link>
            )}
            {adminUnitStatusMatch && (
                <Link component={RouterLink} to={Client.Main.Admin.Root + "#condition"}>
                    Unit Status
                </Link>
            )}

            {unitMatch && (
                <Link component={RouterLink} to={Client.Main.Unit.Root}>
                    Units
                </Link>
            )}
            {unitInstallationMatch && (
                <Link component={RouterLink} to={Client.Main.Device.Installation.replace(":installId", (unitInstallationMatch!.params as any).installId)}>
                    {breadcrumbsValues.installationName || "Loading..."}
                </Link>
            )}
        </RoofcareBreadcrumbs>
    );
};
