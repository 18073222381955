import { IDraggableMarker } from "./../../../Components/Map/DraggablePin";
import { FieldType } from "Core/Utils/Utils";
import { ViewModelBase } from "Core/ViewModels/ViewModelBase";
import { AppUrls } from "Custom/Globals";
import { PropertyDetailAndRelatedDTO } from "./PropertyDetailAndRelatedDTO";
import { Property, PropertyDTO } from "Custom/Models/Domain/Properties/Property";
import { InstallationListItem } from "Custom/Models/Domain/Installations/InstallationListItem";
import { LatLngBounds } from "leaflet";
import { IMarker } from "Custom/Components/Map/Map";
import { DEFAULTBOUNDS } from "Custom/Globals/Globals";
import { getInstallationMarkerIcon } from "Custom/Utils/map";
import { observable, runInAction, computed, action } from "mobx";
import { DocumentCategory, PropertyDocumentModel, PropertyDocumentModelDTO } from "Custom/Models/Domain";
import { IDocumentViewModel } from "Custom/Views/Projects/Details/ProjectDetailsViewModel";
import { ApiResult } from "Core/Models";
import { PropertyDocumentInfoRequest } from "Custom/Views/Properties/Documents/PropertyDocumentInfoRequest";

//extend viewmodel base and passing your model as the generic type
export class PropertyDetailViewModel extends ViewModelBase<Property> implements IDocumentViewModel {
    public errorMessage: string = "";

    public installations = observable<InstallationListItem>([]);

    public showingWarning = observable({
        /*         [InstallationStatus.GreenWarning]: true,
        [InstallationStatus.AmberWarning]: true,
        [InstallationStatus.RedWarning]: true,
        [InstallationStatus.FloodWarning]: true, */
    });

    @observable public projectName: string = "";
    @observable public projectNumber: string = "";

    public documents = observable<PropertyDocumentModel>([]);
    public documentCategories = observable<DocumentCategory>([]);

    public allowDraggable: boolean = false;

    /*     @observable public pingpong: boolean = false; */

    constructor(allowDraggable: boolean) {
        //Pass in a new instance of your model
        //By passing in true as the second parameter, we make this model undoable which means we can use save and reset options on the model
        //If you make a change to the model you need to persist it with a saveModel() call
        //If you make changes to your model you can revert it back by calling resetModel()
        super(new Property(), false);
        //EN - Haven't figured out how to make this call work from the base model yet
        //This is only needed if you make use of the validation decorators
        this.setDecorators(Property);

        this.allowDraggable = allowDraggable;
    }

    @computed
    public get getDocumentCount(): number {
        /*  if (this.pingpong == true) { */
        return this.documents.length;
        /*         } else {
            return this.documents.length;
        } */
    }

    public async loadAsync(id: string, projectId: string) {
        const apiResult = await this.Post<PropertyDetailAndRelatedDTO>(AppUrls.Server.Api.Property.getPropertyDetailsAndRelatedView, { id, projectId });

        if (apiResult.wasSuccessful) {
            runInAction(() => {
                this.model.fromDto(apiResult.payload.property);
                this.projectName = apiResult.payload.related.projectName;
                this.projectNumber = apiResult.payload.related.projectNumber;

                this.installations.clear();
                apiResult.payload.related.installations.forEach((installationDto) => {
                    const installation = new InstallationListItem();

                    installation.fromDto(installationDto);
                    this.installations.push(installation);
                });

                this.documents.clear();
                apiResult.payload.related.documents.forEach((document) => {
                    const doc = new PropertyDocumentModel();
                    doc.fromDto(document);
                    this.documents.push(doc);
                });

                /*                 this.pingpong = !this.pingpong; */

                this.documentCategories.replace(
                    apiResult.payload.related.documentCategories.map((cat) => {
                        return cat;
                    }),
                );
            });
            let s = 0;
            s++;
        }
    }

    public async loadContractorViewAsync(id: string, projectId: string) {
        const apiResult = await this.Post<PropertyDetailAndRelatedDTO>(AppUrls.Server.Api.Property.getPropertyDetailsAndRelatedForContractorView, { id, projectId });

        if (apiResult.wasSuccessful) {
            runInAction(() => {
                this.model.fromDto(apiResult.payload.property);
                this.projectName = apiResult.payload.related.projectName;
                this.projectNumber = apiResult.payload.related.projectNumber;

                this.installations.clear();
                this.documents.clear();
                this.documentCategories.clear();

                apiResult.payload.related.installations.forEach((installationDto) => {
                    const installation = new InstallationListItem();
                    installation.fromDto(installationDto);
                    this.installations.push(installation);
                });

                apiResult.payload.related.documents.forEach((document) => {
                    const doc = new PropertyDocumentModel();
                    doc.fromDto(document);
                    this.documents.push(doc);
                });

                this.documentCategories.replace(
                    apiResult.payload.related.documentCategories.map((cat) => {
                        return cat;
                    }),
                );
            });
        }
    }

    @computed public get getInstallations() {
        return this.installations.filter((a) => a.isDeleted === false);
    }

    @computed
    public get getDocuments(): PropertyDocumentModel[] {
        return this.documents.slice(0).filter((a) => a.isDeleted === false);
    }

    @computed
    public get getDocumentCategories(): DocumentCategory[] {
        return this.documentCategories.slice(0).filter((a) => a.isDeleted === false);
    }

    @action
    public downloadDocument = async (id: string) => {
        let doc: PropertyDocumentModel | undefined = this.documents.find((a) => a.id === id);
        if (doc !== undefined) {
            let fileUri = doc!.url;
            let link = document.createElement("a");
            link.href = fileUri;
            link.setAttribute("download", doc.filename);
            document.body.appendChild(link);
            link.click();
        }
    };

    @action public async deleteDocument(id: string): Promise<ApiResult<PropertyDocumentModel>> {
        const request = {
            id: id,
            state: true,
        };
        const apiResult = await this.Post<PropertyDocumentModel>(AppUrls.Server.Api.ProjectDocument.deleteProjectDocument, request);

        if (apiResult.wasSuccessful) {
            runInAction(() => {
                const item = new PropertyDocumentModel();
                item.fromDto(apiResult.payload);

                let existing: PropertyDocumentModel[] = this.documents.filter((a) => a.id !== item.id);

                if (existing !== undefined) {
                    existing.push(item);
                    this.documents.replace(existing);
                }
            });
        }
        return apiResult;
    }

    @action public async upsertDocument(acceptedFiles: File[], documentCategoryTypeId: number): Promise<ApiResult<PropertyDocumentModelDTO>> {
        const request: PropertyDocumentInfoRequest = {
            propertyId: this.model.id!,
            propertyNumber: "0",
            documentCategoryTypeId: documentCategoryTypeId as number,
        };

        let apiResult: ApiResult<PropertyDocumentModelDTO> = {
            wasSuccessful: false,
            errors: [],
            headers: "",
            payload: {
                id: undefined,
                rowVersion: "",
                propertyId: "",
                filename: "",
                createdDate: "",
                creator: "",
                createdByName: "",
                isDeleted: false,
                documentCategoryId: 0,
                documentId: "",
                url: "",
            },
        };

        for (let i: number = 0; i < acceptedFiles.length; i++) {
            const file: File = acceptedFiles[i];
            let apiResult = await this.postFormWithFile<PropertyDocumentModelDTO>(AppUrls.Server.Api.PropertyDocument.uploadPropertyDocument, file, request);

            if (apiResult.wasSuccessful) {
                runInAction(() => {
                    const item = new PropertyDocumentModel();
                    item.fromDto(apiResult.payload);
                    this.documents.push(item);
                });
            }
        }
        return apiResult;
    }

    @computed
    public get mapBounds() {
        let bounds = new LatLngBounds(this.installations.map((installation) => [installation.getValue("latitude"), installation.getValue("longitude")]));
        const isValid: boolean = bounds.isValid();
        /*         if (isValid) {
            bounds = bounds.pad(10);
        } */
        return isValid ? bounds : DEFAULTBOUNDS;
    }

    public onPositionChange = async (id: string, lat: number, lng: number): Promise<any> => {
        const deviceId: number = parseInt(id);
        const apiResult = await this.Post<number>(AppUrls.Server.Api.Installation.setUnitLocation, { deviceId: deviceId, latitude: lat, longitude: lng });

        if (apiResult.wasSuccessful) {
            runInAction(() => {
                const temp = this.installations.slice();
                this.installations.clear();
                temp.forEach((installationItem) => {
                    const installation = installationItem;

                    if (installation.id === deviceId) {
                        installation.latitude = lat;
                        installation.longitude = lng;
                    }

                    this.installations.push(installation);
                });
            });
        }
    };

    @computed
    public get markers(): IMarker[] {
        return this.installations
            .filter((i) => i.showOnMap)
            .map((installation: InstallationListItem) => {
                let marker: IDraggableMarker = {
                    id: installation.getValue("id"),
                    position: [installation.getValue("latitude"), installation.getValue("longitude")],
                    icon: getInstallationMarkerIcon(installation.status !== null && installation.status !== undefined ? installation.status.statusColour : "#ffffff"),
                    draggable: this.allowDraggable,
                    onPositionChange: this.onPositionChange,
                };
                return marker;
            });
    }

    public getInstallation = (id: number): InstallationListItem | undefined => {
        return this.installations === null || this.installations === undefined ? undefined : this.installations.find((a) => a.id! === id);
    };

    @computed
    public get sensorsRows() {
        return this.installations.slice().length || 1;
    }

    @computed
    public get mapTableData() {
        return this.installations.map((installation) => ({
            id: installation.getValue("id"),
            show: installation.getValue("showOnMap"),
            name: installation.getValue("name"),
            sensor: installation.getValue("temperature"),
            lastReading: installation.getLatestReadingTime,
            status: installation.getStatus,
        }));
    }

    public upsert = async () => {
        const apiResult = await this.Post<PropertyDTO>(AppUrls.Server.Api.Property.upsertProperty, this.model);

        if (apiResult.wasSuccessful) {
            runInAction(() => {
                this.model.fromDto(apiResult.payload);
            });
        }

        return apiResult;
    };

    //This must be present in your viewmodel. Just return true if you dont need to validate anything.
    //keyof BlankModel & string lets you add type checking to the fieldName
    //I am using the validator package to make checking easier https://www.npmjs.com/package/validator
    public isFieldValid(fieldName: keyof FieldType<Property>): boolean {
        const { isValid, errorMessage } = this.validateDecorators(fieldName);

        //You need to this two properties after validation
        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
