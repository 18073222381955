import { isNullOrUndefined } from "Custom/Utils/utils";
import { ContractorMinDTO } from "./../../Models/Domain/Contractors/ContractorModel";
import { ClientMinDTO } from "./../../Models/Domain/Clients/ClientModel";
import * as MobX from "mobx";

import { FieldType, sortByString } from "Core/Utils/Utils";
import { GenericIdRequest, GenericIncludeDeleted } from "Custom/Models";
import { User, UserAndRelatedDTO, UserDTO, UserRelatedDTO } from "Custom/Models/User";
import { action, computed } from "mobx";
import { Role } from "Custom/Models";
import { ApiResult } from "Core/Models/ApiResult";
import { AppUrls } from "Custom/Globals";
import { StoresInstance } from "Custom/Stores/Stores";
import { RoleStore, UserStore } from "Custom/Stores/Domain";
import { ViewModelBase } from "Core/ViewModels/ViewModelBase";
import { Server } from "Custom/Globals/AppUrls";
import { validate, Validate, ValidationError } from "class-validator";
import { IsEmailInUseRequest } from "./IsEmailInUseRequestDto";

export class UserDetailsViewModel extends ViewModelBase<User> {
    @MobX.observable public Valid: boolean = false;
    private domainStores = StoresInstance.domain;
    private userStore: UserStore = this.domainStores.UserStore;
    private roleStore: RoleStore = this.domainStores.RoleStore;
    @MobX.observable public resetLoginAttemptsError = "";

    @MobX.observable public clients: MobX.IObservableArray<ClientMinDTO> = MobX.observable([]);
    @MobX.observable public contractors: MobX.IObservableArray<ContractorMinDTO> = MobX.observable([]);

    constructor() {
        super(new User("0"));
        this.setDecorators(User);
    }

    public setUser(user: User, newUser: boolean) {
        this.setValue("id", newUser ? "" : user.id);
        this.setValue("firstName", newUser ? "" : user.firstName);
        this.setValue("lastName", newUser ? "" : user.lastName);
        this.setValue("email", newUser ? "" : user.email);
        this.setValue("roles", newUser ? "" : user.roles);
        this.setValue("roleIds", newUser ? "" : user.roleIds);
        this.setValue("rowVersion", newUser ? "" : user.rowVersion);
        this.setValue("isDeleted", newUser ? false : user.isDeleted);
        this.setValue("userRoles", newUser ? [] : user.userRoles);
        this.setValue("lastLoggedIn", newUser ? "" : user.lastLoggedIn);

        this.setValue("userClient", newUser ? "" : user.userClient);
        this.setValue("userContractor", newUser ? "" : user.userContractor);
    }

    @computed
    public get getClients(): ClientMinDTO[] {
        return this.clients.slice().filter((a: ClientMinDTO) => a.isDeleted === false);
    }

    @computed
    public get getContractors(): ContractorMinDTO[] {
        return this.contractors.slice().filter((a: ContractorMinDTO) => a.isDeleted === false);
    }

    public get(fieldName: any): any {
        return this.getValue(fieldName);
    }

    @action
    setRoleId(value: string) {
        const roles = this.roleStore.getRoles;
        const userRoles: Role[] = roles.filter((a: Role) => a.id === value);
        const newRoles: string = userRoles.map((a) => a.id).join(",");
        this.set("roleIds", value);
        this.set("roles", newRoles);
        this.model.userRoles.replace(userRoles);
    }

    @action
    setClientId(value: string) {
        this.set("userClient", value);
        this.isFieldValid("userClient", value);
    }

    @action
    setContractorId(value: string) {
        this.set("userContractor", value);
        this.isFieldValid("userContractor", value);
    }

    @action
    public set(fieldName: any, value: string | number | boolean | Date) {
        this.setValue(fieldName, value as string);
    }

    public isFieldValid(fieldName: keyof FieldType<User>, value: any): boolean {
        let { isValid, errorMessage } = this.validateDecorators(fieldName);

        if (fieldName === "userClient") {
            if (isNullOrUndefined(value) === true || value.length === 0) {
                const roles = this.roleStore.getRoles;
                if (roles.length > 0 && this.model.roleIds.length > 0) {
                    const role: Role | undefined = roles.find((a: Role) => a.id === this.model.roleIds);
                    // Note: this won't work if we ever allow multiple roles.
                    // then we will need to split on roleids and check each one.
                    if (role !== undefined && role.name.indexOf("client") !== -1) {
                        errorMessage = "A client is required when the role is client";
                        isValid = false;
                    }
                }
            }
        }

        if (fieldName === "userContractor") {
            if (isNullOrUndefined(value) === true || value.length === 0) {
                const roles = this.roleStore.getRoles;
                if (roles.length > 0 && this.model.roleIds.length > 0) {
                    const role: Role | undefined = roles.find((a: Role) => a.id === this.model.roleIds);
                    // Note: this won't work if we ever allow multiple roles.
                    // then we will need to split on roleids and check each one.
                    if (role !== undefined && role.name.indexOf("contractor") !== -1) {
                        errorMessage = "A contractor is required when the role is " + role.name;
                        isValid = false;
                    }
                }
            }
        }

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    public getUser = (id: string) => {
        if (id.length > 0) {
            return this.model;
        } else {
            return new User("0");
        }
    };

    @computed get getIsLoadingData(): boolean {
        return this.IsLoading;
    }

    public Validate = (): Promise<ValidationError[]> => {
        let promise = validate(this.model).then((errors) => {
            // errors is an array of validation errors
            let retVal: ValidationError[] = [];
            if (errors.length > 0) {
                retVal = errors;
                console.log("validation failed");
            } else {
                console.log("validation succeed");
            }

            return retVal;
        });

        return promise;
    };

    @action
    public async loadUserAsync(id: string): Promise<ApiResult<UserAndRelatedDTO>> {
        const request: GenericIdRequest = {
            id: id,
        };
        const apiResult = await this.Post<UserAndRelatedDTO>(Server.Api.User.getUser, request);

        MobX.runInAction(() => {
            if (apiResult.wasSuccessful) {
                this.model.fromDto(apiResult.payload.user);
                this.roleStore.setRoles(apiResult.payload.roles);

                let clients: ClientMinDTO[] = apiResult.payload.clients;
                clients.sort((a: ClientMinDTO, b: ClientMinDTO) => {
                    return sortByString(a.clientName, b.clientName);
                });
                this.clients.replace(clients);

                let contractors: ContractorMinDTO[] = apiResult.payload.contractors;
                contractors.sort((a: ContractorMinDTO, b: ContractorMinDTO) => {
                    return sortByString(a.contractorName, b.contractorName);
                });
                this.contractors.replace(apiResult.payload.contractors);
            } else {
            }
        });
        return apiResult;
    }

    @action
    public async loadRelatedAsync(): Promise<ApiResult<UserRelatedDTO>> {
        this.roleStore.setRoles([]);
        this.clients.clear();
        this.contractors.clear();
        const apiResult = await this.Post<UserAndRelatedDTO>(Server.Api.User.getUserRelated);
        MobX.runInAction(() => {
            if (apiResult.wasSuccessful) {
                this.roleStore.setRoles(apiResult.payload.roles);
                this.clients.push(...apiResult.payload.clients);
                this.contractors.push(...apiResult.payload.contractors);
            } else {
            }
        });
        return apiResult;
    }

    public postUserDetailAsync = async (): Promise<UserDTO> => {
        const apiResult = await this.Post<UserDTO>(AppUrls.Server.Api.User.upsertUser, this.getModel);

        MobX.runInAction(() => {
            if (apiResult.wasSuccessful) {
                let existingUser = this.userStore.getUsers.find((u) => u.id === this.getValue("id"));
                if (existingUser) {
                    const index = this.userStore.getUsers.indexOf(existingUser, 0);
                    if (index > -1) {
                        this.userStore.getUsers.splice(index, 1);
                    }
                }
                const newUser = new User(apiResult.payload.id);
                newUser.fromDto(apiResult.payload);
                this.userStore.getUsers.push(newUser);
            }
        });

        return apiResult.payload;
    };

    public async resetFailedLoginAttempts(): Promise<ApiResult> {
        const apiResult = await this.Post<any>(AppUrls.Server.Api.User.resetFailedLoginAttemptsCount, {
            id: this.getValue("id"),
        });

        if (apiResult.wasSuccessful) {
            MobX.runInAction(() => (this.resetLoginAttemptsError = ""));
        } else {
            MobX.runInAction(() => {
                this.IsErrored = true;
                this.resetLoginAttemptsError = "Unknown Error resetting Failed Login Attempts Count";
            });
        }
        return apiResult;
    }

    public isEmailInUse = async (id: string, email: string): Promise<boolean> => {
        const request: IsEmailInUseRequest = {
            id: id,
            email: email,
        };

        const apiResult = await this.Post<boolean>(AppUrls.Server.Api.User.isEmailInUse, request);

        if (apiResult.wasSuccessful === false) {
            MobX.runInAction(() => {
                this.IsErrored = true;
                // TODO set Error Message
            });
        }

        return apiResult.payload;
    };

    public setExisitingEmail = (): boolean => {
        let { isValid, errorMessage } = this.validateDecorators("email");

        this.setError("email", "The email address is already in use.");
        this.setValid("email", false);

        return isValid;
    };
}
