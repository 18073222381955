import { Box } from "@material-ui/core";
import styled from "styled-components";
import { BaseModalDialogPaper } from "../Modal/BaseModal.styles";

export const ContactModalDialogPaper = styled(BaseModalDialogPaper)`
    min-width: 680px;
    background: #3a3c41 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #000000c2;
    border-radius: 18px;

    section {
        margin: 0;
        padding-bottom: 16px;

        > div {
            padding: 0;
            display: flex;
            flex-direction: column;
        }

        .address-toggle {
            margin-top: 16px;
            align-self: flex-start;
        }

        label {
            margin-bottom: 0;
        }
    }

    .MuiDialogActions-root {
        padding-bottom: 40px;
        padding-left: 0;
        padding-right: 0;
        min-width: 380px;
        margin: auto;
    }
`;

export const AddEditBoxRow: any = styled(Box)`
    padding-bottom: 15px;
    display: flex;
    flex-direction: row;
    flex: 1 0 0;

    > div {
        max-width: 325px;
        width: 100%;
        padding: 0px 15px;
    }

    > div:first-of-type {
        padding-left: 0px;
    }

    > div:last-of-type {
        padding-right: 0px;
    }

    .fullwidth {
        width: 100%;
        max-width: 100%;
        padding: 0px 15px;
    }

    .input-label {
        font-weight: 700;
        font-family: "Mont-Bold", "Montserrat", "Roboto Condensed", "Helvetica", "Arial", "sans-serif";
    }

    .wide-input > div.MuiBox-root {
        width: 100%;
    }
`;

export const ContactULGrid: any = styled.ul`
    list-style: none;
    border-bottom: solid 1px #ffffff;
    li {
        width: 100%;
        display: grid;
    }

    li.add {
        grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
        gap: 4px 0px;
        grid-auto-flow: row;
        @media (max-width: 1059px) {
            text-align: center;
        }
        @media (min-width: 1060px) {
            grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
            gap: 0px 4px;
            grid-auto-flow: column;
            grid-template-rows: 70px;

            .cell:last-of-type {
                position: relative;
                button {
                    position: absolute;
                    bottom: 0px;
                    right: 0px;
                }
            }
        }

        padding-bottom: 30px;
    }

    li.row {
        gap: 0px 4px;
        grid-auto-flow: column;
        grid-template-rows: 35px;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 30px 90px;
    }

    li.row:first-of-type {
        margin-top: 30px;
    }
`;
