import { action, IObservableArray, observable } from "mobx";
import { ModelBase } from "Core/Models/ModelBase";
import { ProjectCommunicationListItemDTO, ProjectCommunicationListItemModel } from "./ProjectCommunicationListItemModel";

//Base class expects the model type and the DTO model type
//If you are not using a DTO just pass in undefined as the DTO
export class ActionsModel extends ModelBase<ActionsModel, ActionsModelDTO> {
    @observable
    public actions: IObservableArray<ProjectCommunicationListItemModel> = observable([]);

    @action
    public clear() {
        this.actions.clear();
    }

    //fromDto is required but you can leave it blank
    fromDto(model: ActionsModelDTO): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
    }

    @action
    public fromDTO(items: ProjectCommunicationListItemDTO[]) {
        this.actions.clear();

        for (let i: number = 0; i < items.length; i++) {
            let newOne: ProjectCommunicationListItemModel = new ProjectCommunicationListItemModel();
            newOne.fromDto(items[i]);
            this.actions.push(newOne);
        }
    }

    //toDto is required but you can leave it blank
    toDto(model: ActionsModel): void {}
}

export type ActionsModelDTO = {};
