import { ModelBase } from "Core/Models";
import { RoofType } from "Custom/Models/Domain";
import { calculateActualHeight } from "Custom/Utils/utils";
import { action, IObservableArray, observable } from "mobx";
import { InstallationCommissioned } from "./InstallationCommissioned";
import { ImageType, InstallationCommissionImage } from "./InstallationCommissionedImage";

export class InstallationCommissionedResponseModel extends ModelBase<InstallationCommissionedResponseModel, InstallationCommissionedResponseDto> {
    @observable
    public installationCommissioned: InstallationCommissioned | undefined | null;

    @observable
    public generalImages: IObservableArray<InstallationCommissionImage> = observable([]);

    @observable
    public drainageImages: IObservableArray<InstallationCommissionImage> = observable([]);

    @action
    public clear() {
        this.installationCommissioned = undefined;
        this.generalImages.clear();
        this.drainageImages.clear();
    }

    //fromDto is required but you can leave it blank
    fromDto(model: InstallationCommissionedResponseDto): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
            if (key === "images") {
                if (model.images.length > 0) {
                    this.generalImages.push(...model.images.filter((a) => a.imageTypeId === ImageType.General));
                    this.drainageImages.push(...model.images.filter((a) => a.imageTypeId === ImageType.Drainage));
                }
            }
        }

        // Note Install Height 1 & 2 are not in this.

        if (
            model.installationCommissioned !== null &&
            model.installationCommissioned !== undefined &&
            this.installationCommissioned !== null &&
            this.installationCommissioned !== undefined
        ) {
            if (model.installationCommissioned.roofType === RoofType.Pitched) {
                let side1_Height = calculateActualHeight(model.installationCommissioned.side1_Length, model.installationCommissioned.side1_Angle);
                let side2_Height = calculateActualHeight(model.installationCommissioned.side2_Length, model.installationCommissioned.side2_Angle);

                this.installationCommissioned.side1_Height = side1_Height;
                this.installationCommissioned.side2_Height = side2_Height;
            }
        }
    }

    //toDto is required but you can leave it blank
    toDto(model: InstallationCommissionedResponseModel): void {}
}

export interface InstallationCommissionedResponseDto {
    installationCommissioned: InstallationCommissioned;
    images: InstallationCommissionImage[];
}
