import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useObserver } from "mobx-react-lite";
import React from "react";
import styled from "styled-components";

import { CollapsiblePanelViewModel } from "./CollapsiblePanelviewModel";

interface IProps {
    viewModel: CollapsiblePanelViewModel;
    className?: string;
}

const CollapsiblePanelBase: React.FC<IProps> = (props) => {
    const onChangeAccordion = () => {
        props.viewModel.toggleCollapsed();
    };

    return useObserver(() => (
        <Accordion className={props.className} expanded={!props.viewModel.isCollapsed} onChange={onChangeAccordion}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>{props.viewModel.title}</Typography>
            </AccordionSummary>
            <AccordionDetails>{props.children}</AccordionDetails>
        </Accordion>
    ));
};

export const CollapsiblePanel = styled(CollapsiblePanelBase)`
    && {
        background-color: transparent;
        box-shadow: none;
        margin-bottom: 0;
        margin-top: 0;

        & .MuiAccordionSummary-root {
            background-color: rgb(56, 103, 204, 0.43);
            border-radius: 4px;
            box-shadow: inset 0px 1px 1px rgba(252, 153, 153, 0.2), 0px 3px 6px rgba(0, 0, 0, 0.4);
            margin-bottom: 15px;
            min-height: 46px;

            & > .MuiAccordionSummary-content {
                margin: 0;

                > p {
                    color: white;
                    font-size: 14px;
                }
            }

            & > .MuiAccordionSummary-expandIcon {
                color: white;
            }
        }

        & .MuiCollapse-container {
            & > .MuiCollapse-wrapper {
                & > .MuiCollapse-wrapperInner {
                    & > div {
                        & > .MuiAccordionDetails-root {
                            padding: 0;
                        }
                    }
                }
            }
        }
    }
`;

export const CollapsiblePanelSettings = styled.div`
    display: flex;
    flex: 1 0 0;
    width: 100%;
    flex-direction: column;
    background-color: #3a3c41;
    margin-top: -15px;
    border-radius: 4px;
    color: white;
    padding: 15px;
    margin-bottom: 11px;

    > .panel-settings-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-bottom: 15px;

        .MuiInputBase-root {
            max-width: 415px;
            width: 100%;
            margin-right: 30px;
        }
    }
`;

export const CollapsibleSubPanel = styled(CollapsiblePanelBase)`
    &&& {
        background-color: transparent;
        box-shadow: none;
        margin-bottom: 0;
        margin-top: 0;

        & .MuiAccordionSummary-root {
            background-color: rgb(114, 114, 115, 0.43);
            border-radius: 4px;
            box-shadow: inset 0px 1px 1px rgba(255, 255, 255, 0.2), 0px 3px 6px rgba(0, 0, 0, 0.4);
            margin-bottom: 15px;
            min-height: 28px;

            & > .MuiAccordionSummary-content {
                margin: 0;

                > p {
                    color: white;
                    font-size: 12px;
                    margin-bottom: -3px;
                }
            }

            & > .MuiAccordionSummary-expandIcon {
                color: white;
                padding: 5px 12px;
            }
        }

        & .MuiCollapse-container {
            & > .MuiCollapse-wrapper {
                & > .MuiCollapse-wrapperInner {
                    & > div {
                        & > .MuiAccordionDetails-root {
                            padding: 0;
                        }
                    }
                }
            }
        }
    }
`;
