import { useObserver } from "mobx-react-lite";
import { LineGraph } from "Custom/Components/Graphs/LineGraph";
import { SensorValueDTO } from "Custom/Models/Domain/Installations/SensorValue";
import { Typography } from "@material-ui/core";
import { InstallationStatusDataDTO } from "Custom/Models/Domain";
import { DeviceAlertActionModelDTO, DeviceConditionSetModelDTO } from "./TabConfigurationModel";
import { useState } from "react";
import { GraphHeader, GraphWrapper } from "./InstallationView.style";
import { AlertSwitchOption } from "./DeviceSection.style";

interface GutterSensOverviewGraphProps {
    graphHeight: number;
    graphWidth: number;
    readings: SensorValueDTO[];
    deviceStatusData?: InstallationStatusDataDTO;
    conditionSet?: DeviceConditionSetModelDTO;
    alertAction?: DeviceAlertActionModelDTO;
    showGraphHeader?: boolean;
    showGraphLegend?: boolean;
    backgroundColour?: string;
}

export function GutterSensOverviewGraph(props: GutterSensOverviewGraphProps) {
    const { alertAction, backgroundColour, graphHeight, graphWidth, readings, deviceStatusData, conditionSet, showGraphHeader, showGraphLegend } = props;

    const [showLines, setShowLines] = useState<boolean>(false);
    const [showAlertLines, setShowAlertLines] = useState<boolean>(false);

    const hasData: boolean = readings.length > 0;

    const btnClass: string = showLines == false ? "notselected" : "selected";
    const btnAlertClass: string = showAlertLines == false ? "notselected" : "selected";

    const showHeader: boolean = showGraphHeader === undefined || showGraphHeader;

    return useObserver(() => (
        <>
            {hasData && (
                <GraphWrapper id="graphwrapper">
                    {showHeader === true && (
                        <GraphHeader>
                            <div className="slider-text">Show attribute lines:</div>
                            <AlertSwitchOption id={"showlines-select-"} className={btnClass} onClick={() => setShowLines(!showLines)} />

                            <div className="slider-text lhs15">Show alert lines:</div>
                            <AlertSwitchOption id={"showlines-select-"} className={btnAlertClass} onClick={() => setShowAlertLines(!showAlertLines)} />
                        </GraphHeader>
                    )}
                    <LineGraph
                        graphHeight={graphHeight}
                        marginTop={0}
                        maxWidth={"75%"}
                        paddingTop={20}
                        pinToZero={true}
                        readings={readings}
                        showDataPoints={false}
                        showTooltips={false}
                        graphWidth={graphWidth}
                        deviceStatusData={deviceStatusData}
                        conditionSet={conditionSet}
                        showStatusLines={showLines}
                        showAlertLines={showAlertLines}
                        alertAction={alertAction}
                        showGraphLegend={showGraphLegend}
                        backgroundColour={backgroundColour}
                        numXTicks={5}
                    />
                </GraphWrapper>
            )}
            {!hasData && <Typography variant="caption">There is no visible data</Typography>}
        </>
    ));
}
