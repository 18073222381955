import { IComputedValue } from "mobx";
import React from "react";
import styled from "styled-components";

import { Button, InputAdornment, TextField } from "@material-ui/core";
import { useObserver } from "Core/Base";
import { ResetSearchIcon } from "Custom/Components/Table/ResetSearchIcon";
interface IProps {
    searchString: IComputedValue<string>;
    setSearchString(value: string): void;
    placeholder?: string;
}

const ClearButton = styled(Button)`
    background-color: #b42025;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    color: white;

    &:hover {
        background-color: darkred;
    }
`;

const StyledTextField = styled(TextField)`
    width: 100%;
    border-radius: 5px;
    height: 34px;
    text-decoration: none;

    > div {
        border-radius: 5px !important;
    }

    input {
        padding: 7px 10px 6px;
    }
`;

const ClearAdornment = styled(InputAdornment)`
    height: 32px;
    border-radius: 5px !important;
    cursor: pointer;
`;

export const ProjectListSearch: React.FC<IProps> = (props) => {
    const onChange = (event: any) => {
        props.setSearchString(event.target.value);
    };

    return useObserver(() => (
        <StyledTextField
            fullWidth={true}
            autoFocus={true}
            onChange={onChange}
            placeholder={props.placeholder ? props.placeholder : "Start typing to search projects"}
            value={props.searchString.get()}
            InputProps={{
                endAdornment: (
                    <ClearAdornment position="end" onClick={() => props.setSearchString("")}>
                        <ResetSearchIcon>clear</ResetSearchIcon>
                    </ClearAdornment>
                ),
            }}
        />
    ));
};
