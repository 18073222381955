import styled from "styled-components";
import { BaseModalDialogPaper } from "Custom/Components/Modal/BaseModal.styles";
import { Paper } from "@material-ui/core";

export const EmailPaper = styled(Paper)`
    width: 100%;
    padding: 15px;
    margin-top: 15px;
    border-radius: 0;
    display: flex;
    flex: 1 0 0;
`;

export const AuditModalWrapper = styled.div`
    .auditrow {
        padding: 15px 0;
    }

    .title {
        padding-right: 15px;
        font-weight: 700;
    }
`;

export const AuditModalDialogPaper = styled(BaseModalDialogPaper)`
    min-width: 640px;
    background: transparent linear-gradient(180deg, #565758 0%, #373a40 100%) 0% 0% no-repeat padding-box;
    color: white;
    display: flex;
    flex: 1 0 0;
    max-width: 1020px;

    .row {
        padding-top: 15px;
        display: flex;
        flex-direction: row;
        flex: 1 0 0;
    }

    .cell-left {
        display: flex;
        flex-direction: row;
        flex: 1 0 0;
    }

    .cell-right {
        margin-left: 20px;
        display: flex;
        flex-direction: row;
        flex: 1 0 0;
    }

    .MuiDialogContent-root {
        p {
            padding-bottom: 15px;
            font-size: 12px;
        }
    }
`;
