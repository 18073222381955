import styled from "styled-components";
import { BaseModalDialogPaper } from "../../Components/Modal/BaseModal.styles";

export const ErrorModalDialogPaper = styled(BaseModalDialogPaper)`
    box-shadow: 0px 3px 6px #000000c2;
    border-radius: 18px;
    min-width: 420px;

    .MuiDialogContent-root {
        h2 {
            font-weight: normal;
        }

        p {
            padding-top: 10px;
            padding-bottom: 40px;
            font-size: 14px;
        }
    }

    .MuiDialogActions-root > button {
        width: auto;
    }
`;
