import * as Mui from "@material-ui/core";

import { React, StoresInstance, useEffect, useObserver, useRouter, useState } from "Core/Base";
import { Role, UserAndRelatedDTO, UserRelatedDTO } from "Custom/Models";
import { User } from "Custom/Models";
import { UserDetailsViewModel } from "./UserDetailsViewModel";
import { Client } from "Custom/Globals/AppUrls";
import { AppUrls } from "Custom/Globals";
import { AddEditWrapper, EditWrapper } from "Custom/StylesAppSpecific/Controls/AddEditWrapper";
import { BackBox, BackToList, BackToListText } from "Custom/StylesAppSpecific/Controls/BackToList";
import { DetailContainer } from "Custom/StylesAppSpecific/Controls/DetailsContainer";
import { Box, useMediaQuery } from "@material-ui/core";
import { CancelBtn } from "Custom/StylesAppSpecific/Controls/CancelBtn";
import { SuccessBtn } from "Custom/StylesAppSpecific/Controls/SuccessBtn";
import { AcceptBtn } from "Custom/StylesAppSpecific/Controls/AcceptBtn";
import { RoofcareInput } from "Custom/StylesAppSpecific/Controls/RoofcareInput";
import NavigatePrevIcon from "@material-ui/icons/NavigateBefore";
import { AddEditButtonRow } from "Custom/StylesAppSpecific/Controls/AddEditButtonRow";
import { UserDetailCell, UserDetailLongCell, UserDetailsBox, UserDetailsRow } from "./UserDetailsPage.Style";
import { createStyles, makeStyles } from "@material-ui/styles";
import { useLoadCall } from "../../Utils/useLoadCall";
import { isGuid } from "../../Utils/isGuid";
import { Loader } from "Core/Components";
import { NotFoundModal } from "Custom/Components/NotFoundModal/NotFoundModal";
import { ClientMinDTO, ContractorMinDTO } from "Custom/Models/Domain";
import { ApiResult } from "Core/Models";
import { ControlLabel } from "Custom/Components/ControlLabel/ControlLabel";
import { generateID, isNullOrUndefined } from "Custom/Utils/utils";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { MainBackToListWrapper } from "../Admin/Condition/Condition.style";
import { Main } from "../Clients/Client.styles";

const domainStores = StoresInstance.domain;

const useStyles = makeStyles((theme: Mui.Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    }),
);

type UserOptions = {
    roles: JSX.Element[];
    clients: JSX.Element[];
    contractors: JSX.Element[];
};

enum RoleState {
    Neither = 0,
    ClientOnly = 1,
    ContractorOnly = 2,
}

type UserOptionState = {
    rolesSelected: string;
    clientSelected: string;
    contractorSelected: string;
    showAdditionalOptions: RoleState;
};

export const UserDetailsPage: React.FC = () => {
    const classes = useStyles();
    const desktopScreen = useMediaQuery("(min-width:600px)");
    const [viewModel] = useState(() => new UserDetailsViewModel());
    const [optionState, setState] = useState<UserOptionState>({
        rolesSelected: "",
        clientSelected: "",
        contractorSelected: "",
        showAdditionalOptions: RoleState.Neither,
    });

    const [roleStore] = useState(StoresInstance.domain.RoleStore);
    const [options, setOptions] = useState<UserOptions>({
        roles: [],
        clients: [],
        contractors: [],
    });

    const [errorMessage, setErrorMessage] = useState("");
    const { history, match } = useRouter();
    const [newUser, setNewUser] = useState(false);
    const [loader, setLoader] = useState(false);
    const [saveText, setSaveText] = useState("Save user");
    const [titleText, setTitleText] = useState("Add End User");

    let { id } = match.params as any;

    React.useEffect(() => {
        if (id === "0") {
            let promise: Promise<ApiResult<UserRelatedDTO>> = viewModel.loadRelatedAsync();

            promise.then((result: ApiResult<UserRelatedDTO>) => {
                if (result.wasSuccessful) {
                    createOptions();
                }
            });

            setNewUser(true);
        }

        return () => {
            // Clean up after yourself
        };
    }, []);

    React.useEffect(() => {
        if (history.location.pathname === AppUrls.Client.Main.User.UserDetail.replace(":id", "0")) {
            setNewUser(true);
        }
    }, [history.location.pathname]);

    const backToList = () => {
        history.push(AppUrls.Client.Main.User.Root);
    };

    const createOptions = () => {
        let options: UserOptions = {
            roles: [],
            clients: [],
            contractors: [],
        };

        const roles = roleStore.getRoles.map((item: Role, index: number) => {
            return (
                <Mui.MenuItem key={generateID()} value={item.id}>
                    {item.name}
                </Mui.MenuItem>
            );
        });
        options.roles = roles;

        const clients = viewModel.getClients.map((item: ClientMinDTO, index: number) => {
            return (
                <Mui.MenuItem key={generateID()} value={item.id}>
                    {item.clientName}
                </Mui.MenuItem>
            );
        });
        options.clients = clients;

        const contractors = viewModel.getContractors.map((item: ContractorMinDTO, index: number) => {
            return (
                <Mui.MenuItem key={generateID()} value={item.id}>
                    {item.contractorName}
                </Mui.MenuItem>
            );
        });
        options.contractors = contractors;

        setOptions(options);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const notFound = useLoadCall(
        async () => {
            if (id !== "0") {
                setSaveText("Save user");
                setTitleText("Edit user");
                const promise = viewModel.loadUserAsync(id);

                promise.then((result: ApiResult<UserAndRelatedDTO>) => {
                    if (result.wasSuccessful) {
                        createOptions();
                        let currentUser: User = viewModel.getUser(id);
                        if (currentUser) {
                            const newState: UserOptionState = {
                                rolesSelected: "",
                                clientSelected: "",
                                contractorSelected: "",
                                showAdditionalOptions: RoleState.Neither,
                            };

                            viewModel.setUser(currentUser, false);
                            const roleIds: any = viewModel.get("roleIds");
                            if (isNullOrUndefined(roleIds) === false) {
                                newState.rolesSelected = roleIds.trim();
                                newState.showAdditionalOptions = getRoleState(roleIds.trim());
                            }
                            const userClient: any = viewModel.get("userClient");
                            if (isNullOrUndefined(userClient) === false) {
                                newState.clientSelected = userClient.trim();
                            }
                            const userContractor: any = viewModel.get("userContractor");
                            if (isNullOrUndefined(userContractor) === false) {
                                newState.contractorSelected = userContractor.trim();
                            }

                            setState(newState);
                        }
                    }
                    return !!viewModel.getValue("id") && viewModel.getValue("id") !== "0";
                });
                return true;
            } else {
                setSaveText("Add new user");
                setTitleText("Add new user");
                viewModel.setUser(new User("0"), true);
                return true;
            }
        },
        {
            customCheck: () => id === "0" || isGuid(id),
        },
    );

    const doSubmit = (e: any) => {
        e.preventDefault();

        if (!viewModel.isModelValid()) {
            setErrorMessage("Please fix the validation errors and re-submit.");
        } else {
            viewModel.isEmailInUse(viewModel.get("id"), viewModel.get("email")).then((result: boolean) => {
                if (result === true) {
                    setErrorMessage("Please fix the validation errors and re-submit.");
                    viewModel.setExisitingEmail();
                } else {
                    setErrorMessage("");
                    setLoader(true);
                    viewModel.postUserDetailAsync().then(() => {
                        setLoader(false);
                        domainStores.ShowSnackBar("User saved successfully");
                        history.push(Client.Main.User.Root);
                    });
                }
            });
        }
    };

    const resetLoginCount = () => {
        viewModel.resetFailedLoginAttempts().then(() => {});
    };

    const handleRoleChange = (event: any) => {
        const value: string = event.target.value.trim();
        viewModel.setRoleId(value);
        const newState: RoleState = getRoleState(value);
        setState({ ...optionState, rolesSelected: value, showAdditionalOptions: newState });
    };

    const getRoleState = (value: string): RoleState => {
        let roleState: RoleState = RoleState.Neither;
        const role: Role | undefined = roleStore.getRoles.find((a) => a.id === value);

        if (role !== undefined) {
            if (role.name == "client") {
                roleState = RoleState.ClientOnly;
            } else if (role.name.indexOf("contractor") !== -1) {
                roleState = RoleState.ContractorOnly;
            }
        }
        return roleState;
    };

    const handleClientChange = (item: ClientMinDTO) => {
        viewModel.setClientId(item.id);
        setState({ ...optionState, clientSelected: item.id });
    };

    const handleContractorChange = (item: ContractorMinDTO) => {
        viewModel.setContractorId(item.id);
        setState({ ...optionState, contractorSelected: item.id });
    };

    const isInError = (fieldName: string) => {
        const isValid: boolean = viewModel.getValid(fieldName);
        return !isValid;
    };

    const renderPage = () => {
        if (viewModel.IsLoading || notFound) {
            return <Loader />;
        }

        return (
            <DetailContainer>
                <div>{viewModel.IsLoading ? "Resetting..." : ""}</div>
                <div className="error">{viewModel.resetLoginAttemptsError}</div>

                <MainBackToListWrapper>
                    <BackToList onClick={backToList}>
                        <BackBox>
                            <NavigatePrevIcon fontSize="large" />
                        </BackBox>
                        <BackToListText>Back to user list</BackToListText>
                    </BackToList>
                </MainBackToListWrapper>
                <AddEditWrapper>
                    <EditWrapper>
                        <form onSubmit={doSubmit} id="detailsPanelForm" style={{ width: "100%" }}>
                            <Mui.Typography variant="h5" className="title">
                                {titleText}
                            </Mui.Typography>
                            {errorMessage !== "" && (
                                <Mui.Typography variant="caption" style={{ color: "red" }}>
                                    {errorMessage}
                                </Mui.Typography>
                            )}
                            <Main>
                                <UserDetailsBox>
                                    <UserDetailsRow>
                                        <UserDetailLongCell>
                                            <RoofcareInput
                                                viewModel={viewModel}
                                                label="First name"
                                                fieldName="firstName"
                                                shrink={true}
                                                inputProps={{ placeholder: "First name" }}
                                            />
                                        </UserDetailLongCell>
                                        <UserDetailLongCell>
                                            <RoofcareInput viewModel={viewModel} label="Last name" fieldName="lastName" shrink={true} inputProps={{ placeholder: "Last name" }} />
                                        </UserDetailLongCell>
                                    </UserDetailsRow>
                                    <UserDetailsRow>
                                        <UserDetailLongCell className="userDetailLongCell">
                                            <RoofcareInput
                                                viewModel={viewModel}
                                                label="Email Address"
                                                fieldName="email"
                                                shrink={true}
                                                fullWidth
                                                inputProps={{ placeholder: "Enter users email address" }}
                                            />
                                        </UserDetailLongCell>
                                        <UserDetailCell>
                                            <Box paddingTop="8px" width="100%" paddingRight="15px">
                                                <ControlLabel label="Roles" htmlFor="role-select">
                                                    <Box maxWidth="320px">
                                                        <Mui.Select
                                                            id="role-select"
                                                            value={optionState.rolesSelected}
                                                            onChange={handleRoleChange}
                                                            MenuProps={{
                                                                getContentAnchorEl: null,
                                                                anchorOrigin: {
                                                                    vertical: "bottom",
                                                                    horizontal: "left",
                                                                },
                                                            }}
                                                            fullWidth
                                                        >
                                                            {options.roles}
                                                        </Mui.Select>
                                                        <Box>
                                                            {isInError("roles") && <Mui.FormHelperText style={{ color: "red" }}>{viewModel.getError("roles")}</Mui.FormHelperText>}
                                                        </Box>
                                                    </Box>
                                                </ControlLabel>
                                            </Box>
                                        </UserDetailCell>
                                        {optionState.showAdditionalOptions === RoleState.ClientOnly && (
                                            <>
                                                <UserDetailCell>
                                                    <Box className="cell-right rcselect userselect" paddingTop="8px" width="100%">
                                                        <ControlLabel label="End users" htmlFor="client-autocomplete">
                                                            <Box>
                                                                <Autocomplete
                                                                    blurOnSelect
                                                                    id="client-autocomplete"
                                                                    disableClearable
                                                                    onBlur={() => isInError("clients")}
                                                                    options={viewModel.getClients}
                                                                    getOptionLabel={(option: ClientMinDTO) => option.clientName}
                                                                    onChange={(event: any, value: ClientMinDTO) => handleClientChange(value)}
                                                                    value={viewModel.getClients.find((a) => a.id === optionState.clientSelected)}
                                                                    renderInput={(params: JSX.IntrinsicAttributes & Mui.TextFieldProps) => {
                                                                        return (
                                                                            <Mui.TextField
                                                                                {...params}
                                                                                variant="standard"
                                                                                placeholder="End users"
                                                                                InputProps={{
                                                                                    ...params.InputProps,
                                                                                    style: { padding: "0 10px" },
                                                                                }}
                                                                            />
                                                                        );
                                                                    }}
                                                                />
                                                                <Box>
                                                                    {isInError("clients") && (
                                                                        <Mui.FormHelperText style={{ color: "red" }}>{viewModel.getError("clients")}</Mui.FormHelperText>
                                                                    )}
                                                                </Box>
                                                            </Box>
                                                        </ControlLabel>
                                                    </Box>
                                                </UserDetailCell>
                                            </>
                                        )}
                                        {optionState.showAdditionalOptions === RoleState.ContractorOnly && (
                                            <UserDetailCell>
                                                <Box className="cell-right rcselect userselect" paddingTop="8px" width="100%">
                                                    <ControlLabel label="Contractors" htmlFor="contractor-autocomplete">
                                                        <Box>
                                                            <Autocomplete
                                                                blurOnSelect
                                                                id="contractor-autocomplete"
                                                                disableClearable
                                                                onBlur={() => isInError("clients")}
                                                                options={viewModel.getContractors}
                                                                getOptionLabel={(option) => option.contractorName}
                                                                onChange={(event, value) => handleContractorChange(value)}
                                                                value={viewModel.getContractors.find((a) => a.id === optionState.contractorSelected)}
                                                                renderInput={(params) => {
                                                                    return (
                                                                        <Mui.TextField
                                                                            {...params}
                                                                            variant="standard"
                                                                            placeholder="Contractors"
                                                                            InputProps={{
                                                                                ...params.InputProps,
                                                                                style: { padding: "0 10px" },
                                                                            }}
                                                                        />
                                                                    );
                                                                }}
                                                            />
                                                            <Box>
                                                                {isInError("contractor") && (
                                                                    <Mui.FormHelperText style={{ color: "red" }}>{viewModel.getError("contractor")}</Mui.FormHelperText>
                                                                )}
                                                            </Box>
                                                        </Box>
                                                    </ControlLabel>
                                                </Box>
                                            </UserDetailCell>
                                        )}
                                    </UserDetailsRow>
                                    {newUser === false && (
                                        <UserDetailsRow>
                                            <UserDetailLongCell style={{ maxWidth: "320px" }}>
                                                <AcceptBtn style={{ maxWidth: "320px" }} onClick={() => resetLoginCount()}>
                                                    Reset Failed Login Attempts Count
                                                </AcceptBtn>
                                            </UserDetailLongCell>
                                        </UserDetailsRow>
                                    )}
                                </UserDetailsBox>
                            </Main>
                            <AddEditButtonRow>
                                <SuccessBtn id="save" variant="contained" color="primary" type="submit" disabled={loader}>
                                    {!loader ? saveText : "Saving"}
                                </SuccessBtn>
                                <span style={{ paddingRight: "20px" }} />
                                <CancelBtn id="cancel" variant="contained" color="secondary" onClick={backToList}>
                                    Cancel
                                </CancelBtn>
                            </AddEditButtonRow>
                        </form>
                    </EditWrapper>
                </AddEditWrapper>
            </DetailContainer>
        );
    };

    return useObserver(() => (
        <>
            <NotFoundModal returnRoute={AppUrls.Client.Main.User.Root} open={notFound} />
            {renderPage()}
        </>
    ));
};
