import styled from "styled-components";
import { withProps } from "../../../Core/Utils/React/WithProps";
import { Button } from "@material-ui/core";

const _CancelBtn = withProps(Button)({
    variant: "contained",
});

export const CancelBtn = styled(_CancelBtn)`
    background-color: ${(props) => props.theme.app.colors.cancel.button};
    :hover {
        background-color: ${(props) => props.theme.app.colors.cancel.dark};
    }
    color: ${(props) => props.theme.palette.error.contrastText};
    max-width: 179px;
    height: 34px;
    margin-left: 15px;

    background: ${(props) => props.theme.app.colors.red.dark} 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #000000bf;
    border-radius: 100px;
    opacity: 1;
`;
