import { ViewModelBase } from "Core/ViewModels/ViewModelBase";
import { AlertActionItemModel } from "./AlertActionItemModel";
import { action, computed, observable } from "mobx";
import { FieldType } from "Core/Utils/Utils";
import { ConditionType, ConditionUnits } from "Custom/Models/Domain";

//extend viewmodel base and passing your model as the generic type
export class AlertActionItemViewModel extends ViewModelBase<AlertActionItemModel> {
    //Singleton instance of class
    // private static _instance: AlertActionItemViewModel;
    // public static get Instance() {
    //     return this._instance || (this._instance = new this());
    // }

    @observable public errorMessage: string = "";

    public maxWorkingHeightValue: number | undefined = undefined;
    public freeboardHeightValue: number | undefined = undefined;
    public p1: number | undefined = undefined;
    public p2: number | undefined = undefined;

    constructor() {
        //Pass in a new instance of your model
        //By passing in true as the second parameter, we make this model undoable which means we can use save and reset options on the model
        //If you make a change to the model you need to persist it with a saveModel() call
        //If you make changes to your model you can revert it back by calling resetModel()
        super(new AlertActionItemModel(), false);
        //EN - Haven't figured out how to make this call work from the base model yet
        //This is only needed if you make use of the validation decorators
        this.setDecorators(AlertActionItemModel);
    }

    public setTransients = (maxWorkingHeightValue: number | undefined, freeboardHeightValue: number | undefined, p1: number | undefined, p2: number | undefined) => {
        this.maxWorkingHeightValue = maxWorkingHeightValue;
        this.freeboardHeightValue = freeboardHeightValue;
        this.p1 = p1;
        this.p2 = p2;
    };

    @action
    public clearErrors() {
        this.errorMessage = "";
    }

    @action
    public clear() {
        this.errorMessage = "";
        this.maxWorkingHeightValue = undefined;
        this.freeboardHeightValue = undefined;
        this.p1 = undefined;
        this.p2 = undefined;
    }

    @computed public get getNameForColourPicker(): string {
        return this.model.name.length === 0 ? "Alert" : this.model.name;
    }

    @computed public get getTextColour(): string {
        return this.model.alertTextColour;
    }

    @computed public get getBackgroundColour(): string {
        return this.model.alertColour;
    }

    @computed
    public get getPhoto(): boolean {
        return this.model.photo;
    }

    @action
    public setPhoto(value: boolean): void {
        this.model.photo = value;
    }

    @computed
    public get getEmail(): boolean {
        return this.model.email;
    }

    @action
    public setEmail(value: boolean): void {
        this.model.email = value;
    }

    @computed
    public get getSMS(): boolean {
        return this.model.sms;
    }

    @action
    public setSMS(value: boolean): void {
        this.model.sms = value;
    }

    @computed
    public get getPhotoClass(): string {
        return this.model.photo == false ? "notselected" : "selected";
    }

    @computed
    public get getSMSClass(): string {
        return this.model.sms == false ? "notselected" : "selected";
    }

    @computed
    public get getEmailClass(): string {
        return this.model.email == false ? "notselected" : "selected";
    }

    @action public setNextDirection() {
        const current: number = this.model.direction;
        let newValue: number = current + 1;

        if (newValue >= 3) {
            newValue = 0;
        }

        this.model.direction = newValue;
    }

    public getUnitsFormatted() {
        let unit = "";
        if (this.model.units === 0) {
            unit = "mm";
        } else {
            unit = "%";
        }

        return `${this.model.value} ${unit}`;
    }

    public getAlertActionType() {
        let type = "";

        switch (this.model.type) {
            case 0:
                type = "H";
                break;
            case 1:
                type = "FB";
                break;
            case 2:
                type = "P1";
                break;
            case 3:
                type = "P2";
                break;
        }

        return type;
    }

    //isValid will check all fields to make sure they are in a valid state.
    public doSubmit = async (e: any) => {
        e.preventDefault();

        if (this.isModelValid()) {
            //Do stuff here
            this.errorMessage = "";
        } else {
            this.errorMessage = "Form is not valid";
        }
    };

    public isModelValidWithSiblings(index: number, alertActions: AlertActionItemViewModel[]): boolean {
        if (this.model.isDeleted === true) {
            // Bail out if the record is deleted
            return true;
        }

        let retVal: boolean = this.isModelValid();
        const fieldName: keyof FieldType<AlertActionItemModel> = "type";
        let { isValid, errorMessage } = this.validateDecorators(fieldName);

        //You need to this two properties after validation
        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return retVal;
    }

    //This must be present in your viewmodel. Just return true if you dont need to validate anything.
    //keyof AlertActionItemModel & string lets you add type checking to the fieldName
    //I am using the validator package to make checking easier https://www.npmjs.com/package/validator
    public isFieldValid(fieldName: keyof FieldType<AlertActionItemModel>, value: any): boolean {
        let { isValid, errorMessage } = this.validateDecorators(fieldName);

        if (fieldName == "type") {
            // Needs checking in isModelValidWithSiblings since it is dependent on its siblings
        }

        if (fieldName == "value") {
            if (this.model.units === 0) {
                // mm
                if (value > 1000) {
                    errorMessage = "mm value must be less than 1000";
                    isValid = false;
                } else if (value < -1000) {
                    errorMessage = "mm value must be more than -1000";
                    isValid = false;
                }
            } else {
                // %
                if (value > 100) {
                    errorMessage = "% value must be less than 100%";
                    isValid = false;
                } else if (value < -100) {
                    errorMessage = "% value must be more than -100%";
                    isValid = false;
                }
            }

            if (this.model.type === ConditionType.H) {
                if (value > 0) {
                    errorMessage = "Value must be less than 0 for values of H";
                    isValid = false;
                }
            }

            if (this.maxWorkingHeightValue !== undefined && this.freeboardHeightValue !== undefined) {
                const p1: number = this.p1 === undefined ? 0 : this.p1;
                const p2: number = this.p2 === undefined ? 0 : this.p2;

                const isSetValid: boolean = this.isAlertActionValid(this.maxWorkingHeightValue, this.freeboardHeightValue, p1, p2);

                if (isSetValid === false) {
                    //errorMessage = "Condition set is outside the bounds of 0 and the working height of " + maxWorkingHeightValue!.toFixed(0) + "mm";
                    errorMessage = "Outside bounds";
                    isValid = false;
                }
            }
        }

        //You need to this two properties after validation
        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public isAlertActionValid = (maxWorkingHeightValue: number, freeboardHeightValue: number, p1: number, p2: number): boolean => {
        let retVal: boolean = true;

        let itemValue: number = 0;

        if (this.model.type === ConditionType.H) {
            itemValue = maxWorkingHeightValue;
        } else if (this.model.type === ConditionType.FB) {
            itemValue = freeboardHeightValue;
        } else if (this.model.type === ConditionType.P1) {
            itemValue = p1;
        } else if (this.model.type === ConditionType.P2) {
            itemValue = p2;
        }

        if (this.model.units == ConditionUnits.MM) {
            itemValue = itemValue + this.model.value;
        } else {
            // it equals %
            let percentValue: number = this.model.value / 100;
            itemValue = itemValue + itemValue * percentValue;
        }
        let calculatedValue: number = itemValue;

        if (calculatedValue < 0 || calculatedValue > maxWorkingHeightValue) {
            retVal = false;
        }

        return retVal;
    };

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
