import { Box } from "@material-ui/core";
import styled from "styled-components";
import { BaseModalDialogPaper } from "Custom/Components/Modal/BaseModal.styles";

export const IntallationAddModalDialogPaper = styled(BaseModalDialogPaper)`
    width: 100%;
    max-width: none;
    margin: 20px;
    background-color: ${(props) => props.theme.app.colors.tabContent.light};
    .MuiDialogActions-root {
        width: 378px;
        margin: auto;
        padding-bottom: 40px;
    }
`;

export const ContractorBox = styled(Box)`
    max-width: none;
    border-bottom: 1px dashed #ced4da;
    padding-bottom: 15px;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1 0 1;

    .property-showmap {
        color: #ee8b1f;
        cursor: default;

        :hover {
            cursor: pointer;
        }
    }
`;

export const ContractorSelectBox = styled(Box)`
    display: flex;
    flex: 1 0 1;
    margin-top: 10px;
    flex-direction: column;
    // background-color: yellow;
    min-width: 100%;
    max-width: 640px;

    span.control-label,
    select.contractor-select {
        // font-size: 14px !important;
        font-weight: normal !important;
        color: white !important;
        margin-bottom: 7px !important;
    }

    /*     > select.contractor-select {
        width: 100%;
        min-width: 300px;
        max-width: 640px;
    } */
`;
export const DeviceBox = styled(Box)`
    max-width: none;
    border-bottom: 1px solid #cdcccc;
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 15px 0;
`;

export const DeviceCell = styled(Box)`
    width: 100%;
    justify-content: left;

    .MuiInputLabel-root {
        // font-size: 14px !important;
        font-weight: normal !important;
        color: white !important;
        margin-bottom: 7px !important;
    }

    .device-hide {
        margin-left: -12px;
        padding: 15px 0;
        flex-direction: row;

        input {
            margin-top: 2px;
        }

        img {
            margin-top: -43px;
            width: 29px;
        }
    }

    .device-hide {
        font-size: 14px;
    }

    .device-hide label {
        color: white !important;
    }

    .device-hide .form-control,
    .device-hide label {
        display: inline-flex;
        margin-bottom: 0px;
    }

    .device-hide.form-control {
        height: 15px;
        margin: 0;
        width: 30px;
        vertical-align: text-bottom;
    }
`;

export const DeviceRow = styled(Box)`
    width: 100%;
    max-width: none;
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    color: white;

    &.withBorder {
        border-bottom: 1px dashed #cdcccc;
        margin-bottom: 15px;
    }

    .subtitle {
        font-size: 18px !important;
        color: white;
        margin: 0px 0 5px 0;
    }

    .subtitleadditional {
        font-size: 18px !important;
        color: #ff9300;
        margin: 0px 0 5px 0;
    }
`;

export const DeviceStatusRow = styled(Box)`
    display: flex;
    flex: 1 0 auto;
    flex-direction: row;
    justify-content: left;
    padding-bottom: 15px;
    border-bottom: 1px dashed #cdcccc;

    .MuiInputLabel-root {
        // font-size: 14px !important;
        font-weight: normal !important;
        color: white !important;
        margin-bottom: 7px !important;
    }
`;

export const DeviceStatusBox = styled(Box)`
    max-width: none;
    display: flex;
    flex: 1 0 0;
    flex-direction: column;
    justify-content: left;
    color: white;
    .subtitle {
        font-size: 18px !important;
        color: white;
        margin: 15px 0 20px 0;
    }

    .subtitleadditional {
        font-size: 18px !important;
        color: #ff9300;
        margin: 15px 0 20px 0;
    }
`;

export const DeviceStatusCell = styled(Box)`
    padding-left: 15px;
    justify-content: left;

    .MuiInputLabel-root {
        // font-size: 14px !important;
        font-weight: normal !important;
        color: white !important;
        margin-bottom: 7px !important;
    }
`;
