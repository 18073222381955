import React, { useContext } from "react";
import { Stores, StoresContext } from "Custom/Stores";
import { PrivateRoute } from "Core/Utils/React";
import * as RouterUtil from "Custom/Utils/routing";
import { Client } from "Custom/Globals/AppUrls";
import { MapPage } from "./MapPage";
import { PropertyDetailsPage } from "../Properties/PropertyDetailsPage";
import { InstallationView } from "../Installations/InstallationView";

export const MapRoutes: React.FC = () => {
    const store = useContext<Stores>(StoresContext);
    return (
        <>
            {/* <PrivateRoute
                component={MapPage}
                exact
                isAllowed={() => {
                    return store.domain.AccountStore.IsLoggedIn && RouterUtil.requireAdminLogin(store);
                }}
                path={Client.Main.Map.Root}
            />
            <PrivateRoute
                component={PropertyDetailsPage}
                exact
                isAllowed={() => {
                    return store.domain.AccountStore.IsLoggedIn && RouterUtil.requireAdminLogin(store);
                }}
                path={Client.Main.Map.PropertyDetail}
            />
            <PrivateRoute
                component={InstallationView}
                exact
                isAllowed={() => {
                    return store.domain.AccountStore.IsLoggedIn && RouterUtil.requireAdminLogin(store);
                }}
                path={Client.Main.Map.PropertyInstallation}
            />
            <PrivateRoute
                component={InstallationView}
                exact
                isAllowed={() => {
                    return store.domain.AccountStore.IsLoggedIn && RouterUtil.requireAdminLogin(store);
                }}
                path={Client.Main.Map.Installation}
            /> */}
        </>
    );
};
