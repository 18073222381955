import { IMarker } from "Custom/Components/Map/Map";
import { LatLng, LatLngBounds, LatLngExpression } from "leaflet";

export const GoogleAPI: string = "AIzaSyAxSjp70d85VFkhetYSYwTS2IGdt8ZIST8";

export const DEFAULTNE: LatLngExpression = new LatLng(59.03997395566861, 12.876356957167042);
export const DEFAULTSW: LatLngExpression = new LatLng(50.06346735807479, -17.314070457329542);
export const DEFAULTBOUNDS: LatLngBounds = new LatLngBounds(DEFAULTSW, DEFAULTNE);

export const DEBOUNCE_VALUE_MS = 200;

export const DEFAULTCELLCOLOUR = "#07703B";
export const FLOODCOLOUR = "#1B17DB";
export const BASECONDITIONCOLOUR = "#07703B";
