import styled from "styled-components";
import MaterialTable from "material-table";
import { Box } from "@material-ui/core";
import { Container } from "Custom/StylesAppSpecific/AppStyling";

export const RoofcareTableWrapper: any = styled(Box)`
    color: black;
    padding: 15px 30px;

    .MuiInputAdornment-positionEnd .MuiIconButton-root {
        padding-right: 0 !important;
        margin-right: -3px;
        // margin-top: -2px;
    }
`;

export const RoofcareTableWrapperFullWidth: any = styled(Box)`
    color: black;
    padding: 15px 0;

    .MuiInputAdornment-positionEnd .MuiIconButton-root {
        padding-right: 0 !important;
        margin-right: -3px;
        // margin-top: -2px;
    }
`;

export const RoofcareTableWrapperAdmin: any = styled(Box)`
    color: black;
    width: 100%;
    padding: 0;

    //background-color: pink;

    .MuiInputAdornment-positionEnd .MuiIconButton-root {
        padding-right: 0 !important;
        margin-right: -3px;
        // margin-top: -2px;
    }
`;

export const RoofcareTable: any = styled(MaterialTable)`
    .MuiTableSortLabel-active {
        color: #ffffff !important;
    }

    .MuiTableCell-head {
        background: #495d89 0% 0% no-repeat padding-box;
        color: #ffffff;
        box-shadow: inset 0px 1px 1px #ffffff40;
        opacity: 0.43;
        font-family: Mont-Bold, Montserrat, "Roboto Condensed", Helvetica, Arial, sans-serif;
        border: unset;
    }

    .MuiPaper-root {
        background: transparent linear-gradient(180deg, #565758 0%, #373a40 100%) 0% 0% no-repeat padding-box !important;
        background-color: transparent !important;
    }

    .MuiTableSortLabel-active {
        color: white !important;
    }

    .dashboardmap {
        &.MuiPaper-root {
            display: flex;
            flex: 1 0 0;
            flex-direction: "column";

            background: transparent linear-gradient(180deg, #565758 0%, #373a40 100%) 0% 0% no-repeat padding-box;
        }
    }

    .MuiTableCell-root {
        border-bottom: unset !important;
    }

    .MuiTablePagination-toolbar {
        background-color: #484848;
    }
`;

export const RoofcareTableBox: any = styled(Container)`
    /* padding: 5px; */

    div.device-calculatedValue {
        &.device_unknown {
            color: ${(props) => props.theme.app.colors.black.light};
        }

        &.device_amberwarning {
            color: ${(props) => props.theme.app.colors.deviceWarning.amber.main};
        }

        &.device_redwarning {
            color: ${(props) => props.theme.app.colors.deviceWarning.red.main};
        }

        &.device_floodwarning {
            color: ${(props) => props.theme.app.colors.deviceWarning.flood.main};
        }

        &.device_greenwarning {
            color: ${(props) => props.theme.app.colors.deviceWarning.green.main};
        }
    }

    div.device-status {
        display: flex;
        flex: 1 0 1;
        min-width: 88px;
        width: 100%;
        padding: 5px 0;
        align-content: middle;
        position: relative;
        &.device_unknown {
            color: ${(props) => props.theme.palette.common.black};
            background-color: ${(props) => props.theme.app.colors.deviceWarning.unknown.main};
        }

        &.device_amberwarning {
            color: ${(props) => props.theme.palette.common.white};
            background-color: ${(props) => props.theme.app.colors.deviceWarning.amber.main};
        }

        &.device_redwarning {
            color: ${(props) => props.theme.palette.common.white};
            background-color: ${(props) => props.theme.app.colors.deviceWarning.red.main};
        }

        &.device_floodwarning {
            color: ${(props) => props.theme.palette.common.white};
            background-color: ${(props) => props.theme.app.colors.deviceWarning.flood.main};
        }

        &.device_greenwarning {
            color: ${(props) => props.theme.palette.common.white};
            background-color: ${(props) => props.theme.app.colors.deviceWarning.green.main};
        }

        > div {
            margin-left: auto;
            margin-right: auto;
            position: relative;
            font-size: 12px;
        }
    }
`;

export const MailCell: any = styled(Box)`
    display: flex;
    flex-direction: row;
    flex: 1 0 0;
    img {
        padding-right: 5px;
    }

    .text {
        margin-top: 1px;
    }
`;
