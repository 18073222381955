import React from "react";
import { Header } from "../Header";
import { CSSGrid, Cell } from "Core/Components/Styles";
import { RoofcareBreadcrumb } from "Custom/Views/RoofcareBreadcrumb";
import { ChangeLog } from "Custom/Views/Changelog/Changelog";

//EN: use empty generic type to get default react props
export const AdminLayout: React.FC<{}> = (props) => {
    if (!props) return <>Props undefined</>;
    return (
        <>
            <CSSGrid
                style={{ background: "#3B3C3B", opacity: 1 }}
                height="100%"
                columns={"1fr"}
                rows={"0 auto 1fr"}
                gap={"0px"}
                areas={["header", "breadcrumb", "content", "footer"]}
            >
                <Cell area={"header"}>
                    <Header />
                </Cell>
                <Cell marginTop={"70px"} area={"breadcrumb"} id={"breadcrumb"} className="roofcarebreadcrumbcell">
                    <RoofcareBreadcrumb />
                </Cell>
                <Cell area={"content"} id={"content"}>
                    {props && <div style={{ height: "100%" }}>{props.children}</div>}
                </Cell>
                <Cell area={"footer"} id={"footer"}>
                    {props && <ChangeLog />}
                </Cell>
            </CSSGrid>
        </>
    );
};
