import React, { CSSProperties } from "react";
import { Box } from "@material-ui/core";
import { defaultTheme as theme } from "../../Custom/StylesAppSpecific/AppStyling";
import { CSSProp } from "styled-components";

interface IProjectButtonProps {
    onClick: any;
    text: string;
    icon: Function;
    style?: CSSProperties | undefined;
}

export const ProjectButton = (props: IProjectButtonProps) => {
    const { onClick, text, icon } = props;
    const defaultCss = {
        cursor: "pointer",
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderRadius: "100px",
        padding: "0px 10px",
        boxShadow: "0 1px 1px rgba(0,0,0,0.12), 0 2px 2px rgba(0,0,0,0.12),0 4px 4px rgba(0,0,0,0.12), 0 8px 8px rgba(0,0,0,0.12),0 16px 16px rgba(0,0,0,0.12)",
    };
    const css = props.style === undefined ? undefined : props.style;
    return (
        <Box style={{ ...defaultCss, ...css }} onClick={() => onClick()}>
            <span style={{ fontSize: "20px", marginRight: "5px" }}>{icon()}</span>
            <span>{text}</span>
        </Box>
    );
};

interface IHrLineProps {
    color?: string | undefined;
    size?: string | undefined;
    type?: string | undefined;
    marginTop?: string | undefined;
    marginBottom?: string | undefined;
}

export const HrLine = (props: IHrLineProps) => {
    const { color, size, type, marginTop, marginBottom } = props;
    const style = {
        height: `${size ? size : "1px"}`,
        borderBottom: `${size ? size : "1px"} ${type ? type : "dashed"} ${color ? color : "#b2b2b2"}`,
        margin: `${marginTop ? marginTop : "30px"} 0px ${marginBottom ? marginBottom : "30px"} 0px`,
    };

    return <div style={{ ...style }}>{""}</div>;
};
