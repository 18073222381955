import { action, observable } from "mobx";
import { ModelBase } from "Core/Models/ModelBase";

//Base class expects the model type and the DTO model type
//If you are not using a DTO just pass in undefined as the DTO
export class ContractorListItemModel extends ModelBase<ContractorListItemModel, ContractorListItemModelDTO> {
    @observable public id: string = "";
    @observable public companyName: string = "";
    @observable public contactName: string = "";
    @observable public contactNumber: string = "";
    @observable public contactEmail: string = "";
    @observable public isDeleted: boolean = false;
    @observable public projectCount: number = 0;

    //fromDto is required but you can leave it blank
    @action
    fromDto(model: ContractorListItemModelDTO): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
    }

    //toDto is required but you can leave it blank
    toDto(model: ContractorListItemModel): void {}
}

export type ContractorListItemModelDTO = {
    id: string;
    companyName: string;
    contactName: string;
    contactNumber: string;
    contactEmail: string;
    isDeleted: boolean;
    projectCount: number;
};
