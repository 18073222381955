import { action, computed, observable } from "mobx";

import { InstallationImageDto } from "Custom/Models/Domain";
import { CollapsiblePanelViewModel } from "./CollapsiblePanelviewModel";
import { InstallationImageViewModel2 } from "./InstallationImageViewModel";

export class CollapsibleInstallationImagePanelViewModel extends CollapsiblePanelViewModel {
    // #region Constructors and Disposers

    constructor(id: string, title: string, isCollapsed: boolean, items: InstallationImageDto[]) {
        super(id, title, isCollapsed, []);

        const installationImageViewModels: InstallationImageViewModel2[] = [];

        for (const item of items) {
            installationImageViewModels.push(new InstallationImageViewModel2(item, this));
        }

        this.installationImageViewModels.replace(installationImageViewModels);
    }

    // #endregion Constructors and Disposers

    // #region Properties

    @observable
    public installationImageViewModels = observable<InstallationImageViewModel2>([]);

    @observable
    public activeInstallationViewModel: InstallationImageViewModel2 | null = null;

    @computed
    public get canDisplayActiveInstallation(): boolean {
        return this.activeInstallationViewModel !== null && !this.isCollapsed;
    }

    @computed
    public get previousDate(): string {
        const index = this.installationImageViewModels.findIndex((vm) => vm.id === this.activeInstallationViewModel!.id!);

        return index > 0 ? this.installationImageViewModels[index - 1].date : "";
    }

    @computed
    public get nextDate(): string {
        const index = this.installationImageViewModels.findIndex((vm) => vm.id === this.activeInstallationViewModel!.id!);

        return index < this.installationImageViewModels.length - 1 ? this.installationImageViewModels[index + 1].date : "";
    }

    @computed
    public get previousTime(): string {
        const index = this.installationImageViewModels.findIndex((vm) => vm.id === this.activeInstallationViewModel!.id!);

        return index > 0 ? this.installationImageViewModels[index - 1].time : "";
    }

    @computed
    public get nextTime(): string {
        const index = this.installationImageViewModels.findIndex((vm) => vm.id === this.activeInstallationViewModel!.id!);

        return index < this.installationImageViewModels.length - 1 ? this.installationImageViewModels[index + 1].time : "";
    }

    @computed
    public get canMovePrevious(): boolean {
        const index = this.installationImageViewModels.findIndex((vm) => vm.id === this.activeInstallationViewModel!.id!);

        return index > 0;
    }

    @computed
    public get canMoveNext(): boolean {
        const index = this.installationImageViewModels.findIndex((vm) => vm.id === this.activeInstallationViewModel!.id!);

        return index < this.installationImageViewModels.length - 1;
    }

    // #endregion Properties

    // #region Actions

    @action
    public setImages = (items: InstallationImageDto[]) => {
        const installationImageViewModels: InstallationImageViewModel2[] = [];

        for (const item of items) {
            installationImageViewModels.push(new InstallationImageViewModel2(item, this));
        }

        this.installationImageViewModels.replace(installationImageViewModels);
    };

    @action
    public movePreviousInstallationViewModel = () => {
        const index = this.installationImageViewModels.findIndex((vm) => vm.id === this.activeInstallationViewModel!.id!);

        this.activeInstallationViewModel = this.installationImageViewModels[index - 1];
    };

    @action
    public moveNextInstallationViewModel = () => {
        const index = this.installationImageViewModels.findIndex((vm) => vm.id === this.activeInstallationViewModel!.id!);

        this.activeInstallationViewModel = this.installationImageViewModels[index + 1];
    };

    @action
    public setActiveInstallationViewModel = (viewmodel: InstallationImageViewModel2 | null = null) => {
        this.activeInstallationViewModel = viewmodel;
    };

    @computed
    public get getInstallationImageViewModels() {
        return this.installationImageViewModels.slice(0);
    }

    // #endregion Actions
}
