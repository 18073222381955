import * as React from "react";
import { ProjectDetailsPageContainer } from "./ProjectDetailsPage.styles";
import { ITab, Tabs } from "Custom/Components/Tabs/Tabs";
import { useObserver, useRouter } from "Core/Base";
import { AppUrls } from "Custom/Globals";
import { ProjectDetailsDetails } from "./ProjectDetailsDetails";
import { PropertyMap } from "Custom/Views/CommonViews/PropertyMap/PropertyMap";
import { ProjectDetailsViewModel } from "./ProjectDetailsViewModel";
import { Property } from "Custom/Models/Domain";
import { useHasRoutedTabs } from "Custom/Utils/useHasRoutedTabs";
import { Loader } from "Core/Components";
import { NotFoundModal } from "Custom/Components/NotFoundModal/NotFoundModal";
import { useLoadCall } from "Custom/Utils/useLoadCall";

const TAB_DETAILS = "details";
const TAB_MAP = "map";

const tabOptions: ITab[] = [
    { key: TAB_DETAILS, title: "Detail", hash: "detail" },
    { key: TAB_MAP, title: "Map", hash: "map" },
];

export const ProjectDetailsPage: React.FC = () => {
    const [viewModel] = React.useState(() => ProjectDetailsViewModel.Instance);
    const [properties, setProperties] = React.useState<Property[]>([]);

    const hashTab = useHasRoutedTabs(tabOptions);
    const [selectedTab, setSelectedTab] = React.useState(hashTab?.key || TAB_DETAILS);
    const [tabKey, setTabKey] = React.useState(0);

    const router = useRouter<{ projectId: string; clientId?: string }>();

    const notFound = useLoadCall(
        async () => {
            const { projectId } = router.match.params;

            let promise = await viewModel.loadProjectDetailsAsync(projectId);

            if (promise.wasSuccessful) {
                onPropertyLoad(viewModel.getProperties);
            }
            return !!viewModel.getValue("id");
        },
        { checkGuidParamKey: "projectId" },
    );

    const handleTabClick = (tab: ITab) => {
        setSelectedTab(tab.key);
        setTabKey(tabKey + 1);
    };

    const getTabKey = () => {
        return `projectdetails-tab-${selectedTab}-${tabKey}`;
    };

    const onPropertyLoad = (properties: Property[]): void => {
        setProperties(properties);
    };

    const renderContent = () => {
        switch (selectedTab) {
            case TAB_DETAILS:
                return <ProjectDetailsDetails key={getTabKey()} viewModel={viewModel} onPropertyLoad={onPropertyLoad} />;
            case TAB_MAP:
                return <PropertyMap properties={properties} />;
        }
    };

    const handleBack = () => {
        const { path } = router.match;
        const { clientId } = router.match.params;

        let url: string = AppUrls.Client.Main.Project.Root;

        switch (path) {
            case AppUrls.Client.Main.Client.Project.ClientsDetailProject:
            case AppUrls.Client.Main.Client.Property.ClientsDetailProperty: {
                url = AppUrls.Client.Main.Client.ClientsDetail.replace(":clientId", clientId!);
                break;
            }
            case AppUrls.Client.ContractorView.Project.ProjectDetail: {
                url = AppUrls.Client.ContractorView.Root;
                break;
            }
            default: {
                break;
            }
        }
        router.history.push(url);
    };

    const renderPage = () => {
        if (viewModel.IsLoading || notFound) return <Loader />;
        return (
            <ProjectDetailsPageContainer>
                <Tabs tabs={tabOptions} onTabClick={handleTabClick} backBtnText="Back to project list" onBack={handleBack} showBackBtn selected={selectedTab} minTabWidth={132} />
                <div className="content">{renderContent()}</div>
            </ProjectDetailsPageContainer>
        );
    };

    return useObserver(() => (
        <>
            <NotFoundModal open={notFound} returnRoute={AppUrls.Client.Main.Project.Root} />
            {renderPage()}
        </>
    ));
};
