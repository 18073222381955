import styled from "styled-components";
import { Box } from "@material-ui/core";

export const TabCommonBox: any = styled(Box)`
    padding: 5px 15px;
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    width: 100%;
    background-color: #585858;
    color: white;
`;
