import styled from "styled-components";
import { Box } from "@material-ui/core";

interface IProjectStatusCellProps {
    statuscolor: string;
    textcolor: string;
}

export const ProjectStatusCell = styled(Box)<IProjectStatusCellProps>`
    padding: 0;
    padding: 5px 16px;
    background-color: ${(props) => props.statuscolor} !important;
    color: ${(props) => props.textcolor} !important;
    font-size: 10px;
    text-align: center;
`;
