import React, { useState } from "react";
import { useObserver } from "mobx-react-lite";
import { generateID } from "Core/Utils/Utils";
import {
    ConditionPanel,
    ConditionPanelActions,
    ConditionPanelHeading,
    ConditionPanelTable,
    ConditionPanelTableHeadings,
    ConditionPanelTableRows,
    CustomColourBoxWrapper,
} from "./ConditionItemView.style";
import EditLogo from "Custom/Content/editIcon.svg";
import DeleteLogo from "Custom/Content/deleteIcon.svg";
import { ConditionSetViewModel } from "./ConditionSetViewModel";
import { ItemSectionBox, NameSectionBoxNoBorder } from "Custom/Views/Admin/Condition/Condition.style";
import { Grid, Typography } from "@material-ui/core";
import { ConditionItem } from "Custom/Views/Admin/Condition/ConditionItem";
import { RoofcareInput } from "Custom/StylesAppSpecific/Controls/RoofcareInput";
import { ConditionAddEditDetailModel } from "Custom/Views/Admin/Condition/ConditionAddEditDetailModel";
import { DeleteModal } from "Custom/Components/Modal/DeleteModal";
import { TabConfigurationViewModel } from "../TabConfigurationViewModel";
import { ApiResult } from "Core/Models";
import { TabConfigurationModelDTO } from "../TabConfigurationModel";
import { MatchParams } from "../TabConfiguration";
import { useRouter } from "Core/Utils";
import { BoxAdd } from "Custom/StylesAppSpecific/Controls/BoxAddWrapper";
import { AddItemText } from "Custom/StylesAppSpecific/Controls/AddEditWrapper";
import { ConditionType, ConditionUnits, DrainageType, InstallationStatusDataDTO, RoofType } from "Custom/Models/Domain";
import { ConditionItemViewModel } from "Custom/Views/Admin/Condition/ConditionItemViewModel";
import { SendBtn } from "Custom/StylesAppSpecific/Controls/SuccessBtn";
import { SectionSend } from "../DeviceSection.style";
import { CustomColourBox } from "../AlertAction/CustomColourBox";

interface ConditionSetViewProps {
    drainageType: DrainageType;
    viewModel: ConditionSetViewModel;
    statusData: InstallationStatusDataDTO | undefined;
    index: number;
    onConditionSetChange: () => void;
}

export const ConditionSetView: React.FC<ConditionSetViewProps> = (props: ConditionSetViewProps) => {
    const { index, statusData, onConditionSetChange, viewModel, drainageType } = props;
    const { match } = useRouter();
    const { installId } = match.params as MatchParams;
    const [tabConfigurationViewModel] = useState(() => TabConfigurationViewModel.Instance);
    const [areYouSureOpen, setAreYouSureOpen] = React.useState(false);
    const [areYouSureDeleteOpen, setAreYouSureDeleteOpen] = React.useState(false);
    const [isBtnDisabled, setIsBtnDisabled] = useState<boolean | undefined>(false);

    const handleAreYouSureOpen = (): void => {
        setAreYouSureOpen(true);
    };

    const handleAreYouSureClose = (): void => {
        setAreYouSureOpen(false);
    };

    const handleAreYouSureDeleteOpen = (): void => {
        setAreYouSureDeleteOpen(true);
    };

    const handleAreYouSureDeleteClose = (): void => {
        setAreYouSureDeleteOpen(false);
    };

    const validateAndSave = (): void => {
        //validate model and all sub objects
        if (viewModel.isModelAndChildrenValid(statusData) === true) {
            let promise = viewModel.upsertDeviceConditionSet(installId!);

            promise.then((result: ApiResult<TabConfigurationModelDTO>) => {
                if (result.wasSuccessful === true) {
                    tabConfigurationViewModel.setLocalData(result);
                    onConditionSetChange();
                }
            });
        }
    };

    const deleteDeviceConditionSet = (): void => {
        handleAreYouSureDeleteClose();
        if (viewModel.model.id !== null && viewModel.model.id !== undefined && viewModel.model.id !== "") {
            let promise = viewModel.setDeviceConditionSetIsDeleted();

            promise.then((result: ApiResult<TabConfigurationModelDTO>) => {
                if (result.wasSuccessful === true) {
                    tabConfigurationViewModel.setLocalData(result);
                    onConditionSetChange();
                }
            });
        } else {
            // Delete the local condition set.
            tabConfigurationViewModel.deleteLocalConditionSet(index);
            onConditionSetChange();
        }
    };

    const onSave = (): void => {
        handleAreYouSureOpen();
    };

    const handleSaveConditionSet = (): void => {
        handleAreYouSureClose();
        validateAndSave();
    };

    const handleNewClick = (): void => {
        viewModel.addNewCondition();
    };

    const formatEdit = (): JSX.Element => {
        return (
            <div className="circle-icon cursor-pointer">
                <img src={EditLogo} onClick={() => viewModel.editConditionSet()} alt="edit" />
            </div>
        );
    };

    const formatDelete = (): JSX.Element => {
        return <img src={DeleteLogo} onClick={() => handleAreYouSureDeleteOpen()} alt="delete" className={"cursor-pointer cross-icon"} />;
    };

    const conditonSetId: string | null = viewModel.model.getConditionSetId;
    const showEdit: boolean = conditonSetId === null ? true : false;

    const viewOnly = (): JSX.Element => {
        return (
            <ConditionPanel>
                <ConditionPanelHeading>
                    Condition set: {viewModel.model.name}{" "}
                    <div>
                        {showEdit && formatEdit()} {formatDelete()}
                    </div>
                </ConditionPanelHeading>
                <ConditionPanelTable>
                    <ConditionPanelTableHeadings>
                        <div>Name</div>
                        <div>Colour</div>
                        <div>Condition</div>
                        <div>Value</div>
                    </ConditionPanelTableHeadings>
                    <ConditionPanelTableRows>
                        {viewModel.conditions.map((item) => {
                            return (
                                <div key={generateID()}>
                                    <div>{item.model.name}</div>
                                    <CustomColourBoxWrapper>
                                        <CustomColourBox backgroundColour={item.model.statusColour} textColour={item.model.statusTextColour} message={item.model.name} />
                                    </CustomColourBoxWrapper>
                                    <div>
                                        {item.getConditionType()} {item.getUnitsFormatted()}
                                    </div>
                                    <div>{getCalculatedValue(item, statusData)} mm</div>
                                </div>
                            );
                        })}
                    </ConditionPanelTableRows>
                </ConditionPanelTable>
            </ConditionPanel>
        );
    };

    const editableForm = (): JSX.Element => {
        return (
            <>
                <DeleteModal
                    open={areYouSureOpen}
                    onClose={handleAreYouSureClose}
                    onDelete={handleSaveConditionSet}
                    text="Are you sure you want to save this condition?"
                    title="Do you want to continue?"
                />
                <ConditionPanel>
                    <ConditionPanelHeading>
                        <NameSectionBoxNoBorder>
                            <RoofcareInput<ConditionAddEditDetailModel>
                                type="text"
                                label="Condition set:"
                                validateOnBlur={true}
                                viewModel={viewModel}
                                fieldName="name"
                                shrink={true}
                                maxLength={128}
                            />
                        </NameSectionBoxNoBorder>
                        <div>{formatDelete()}</div>
                    </ConditionPanelHeading>
                    <ConditionPanelTable>
                        <ItemSectionBox>
                            <Grid container direction="column" spacing={1}>
                                <Grid container direction="row" spacing={1}>
                                    <Grid item xs={2} className="column-title">
                                        <Typography variant="h6">Name</Typography>
                                    </Grid>
                                    <Grid item xs={3} className="column-title">
                                        <Typography variant="h6">Condition</Typography>
                                    </Grid>
                                    <Grid item xs={1} className="column-title">
                                        <Typography variant="h6"></Typography>
                                    </Grid>
                                </Grid>
                                {getRows()}
                            </Grid>
                        </ItemSectionBox>
                    </ConditionPanelTable>
                    <ConditionPanelActions>
                        {viewModel.getConditions.length < 4 && (
                            <BoxAdd onClick={() => handleNewClick()}>
                                <AddItemText fontcolor="#F29D29" style={{ paddingLeft: "0!important" }}>
                                    <div style={{ marginLeft: "-15px" }}>+ Add another</div>
                                </AddItemText>
                            </BoxAdd>
                        )}

                        <SectionSend>
                            <SendBtn disabled={isBtnDisabled} type="button" onClick={() => onSave()}>
                                <div className="btnText">Save</div>
                            </SendBtn>
                        </SectionSend>
                    </ConditionPanelActions>
                </ConditionPanel>
            </>
        );
    };

    const getRows = (): JSX.Element[] => {
        return viewModel.getConditions.map((item) => {
            return <ConditionItem key={generateID()} localViewModel={item} onDelete={() => {}} drainageType={drainageType} />;
        });
    };

    const getCalculatedValue = (item: ConditionItemViewModel, statusData: InstallationStatusDataDTO | undefined) => {
        let retVal: string = "";
        if (statusData !== undefined) {
            let maxWorkingHeightValue: number = Math.min(statusData!.side1_Height, statusData!.side2_Height);

            if (statusData!.roofType === RoofType.Flat) {
                maxWorkingHeightValue = statusData!.maxHeight;
            }

            const freeboardHeightValue: number = maxWorkingHeightValue * 0.7;
            const p1 = statusData!.p1;
            const p2 = statusData!.p2;

            let value: number = 0;
            if (item.model.type === ConditionType.H) {
                value = maxWorkingHeightValue;
            } else if (item.model.type === ConditionType.FB) {
                value = freeboardHeightValue;
            } else if (item.model.type === ConditionType.P1) {
                value = p1;
            } else {
                value = p2;
            }

            if (item.model.units === ConditionUnits.MM) {
                value = value + item.model.value;
            } else {
                // it equals %
                let percentValue: number = item.model.value / 100;
                value = value + value * percentValue;
            }

            retVal = value.toFixed(0);
        }

        return retVal;
    };

    return useObserver(() => (
        <>
            <DeleteModal
                open={areYouSureDeleteOpen}
                onClose={handleAreYouSureDeleteClose}
                onDelete={deleteDeviceConditionSet}
                text="Are you sure you want to delete this condition?"
                title="Do you want to continue?"
            />
            {viewModel.getIsEditable ? editableForm() : viewOnly()}
        </>
    ));
};
