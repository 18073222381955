import styled from "styled-components";
import { Box } from "@material-ui/core";

export interface LineGraphBoxProps {
    maxwidth: number;
    paddingTop: string;
}

export const BoxplotBox: any = styled(Box)<LineGraphBoxProps>`
    padding-top: padding-top: ${(props) => props.paddingTop};
    max-width: 100%;
`;
