import * as React from "react";

import { CancelBtn } from "../../StylesAppSpecific/Controls/CancelBtn";
import { BaseModal } from "../../Components/Modal/BaseModal";
import { StoresInstance } from "../../Stores/Stores";
import { useObserver, useRouter } from "../../../Core/Base";
import { NotFoundModalDialogPaper } from "./NotFoundModal.styles";

interface IProps {
    returnRoute: string;
    open?: boolean;
}

export const NotFoundModal: React.FC<IProps> = ({ returnRoute, open }) => {
    const router = useRouter();

    const handleClose = () => {
        router.history.push(returnRoute);
    };

    return (
        <BaseModal
            open={open}
            onClose={handleClose}
            title="Not Found"
            PaperComponent={NotFoundModalDialogPaper}
            actions={
                <>
                    <CancelBtn onClick={handleClose}>OK</CancelBtn>
                </>
            }
        >
            <h2>Not found</h2>
            <p className="client-modal-description">Click OK to return</p>
        </BaseModal>
    );
};
