import { ViewModelBase } from "Core/ViewModels/ViewModelBase";
import { FieldType } from "Core/Utils/Utils";
import { InstallationCommandTable, InstallationCommandSection, InstallationCommandResult } from "Custom/Models/Domain/Installations";

//extend viewmodel base and passing your model as the generic type
export class InstallationCommandViewModel extends ViewModelBase<InstallationCommandResult> {
    //Singleton instance of class
    private static _instance: InstallationCommandViewModel;
    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    constructor() {
        //Pass in a new instance of your model
        //By passing in true as the second parameter, we make this model undoable which means we can use save and reset options on the model
        //If you make a change to the model you need to persist it with a saveModel() call
        //If you make changes to your model you can revert it back by calling resetModel()
        super(new InstallationCommandResult(), false);
        //EN - Haven't figured out how to make this call work from the base model yet
        //This is only needed if you make use of the validation decorators
        this.setDecorators(InstallationCommandResult);
    }

    //This must be present in your viewmodel. Just return true if you dont need to validate anything.
    //keyof BlankModel & string lets you add type checking to the fieldName
    //I am using the validator package to make checking easier https://www.npmjs.com/package/validator
    public isFieldValid(fieldName: keyof FieldType<InstallationCommandResult>): boolean {
        const { isValid, errorMessage } = this.validateDecorators(fieldName);

        //You need to this two properties after validation
        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    public getOptions = (): InstallationCommandTable => {
        let retVal: InstallationCommandTable = {
            sections: [],
        };

        const genericMaxValue: number = Number.MAX_SAFE_INTEGER;

        let ordinal: number = 0;

        // Version V3.1

        //R
        let r: InstallationCommandSection = {
            title: "",
            commands: [],
            display: false,
            ordinal: ordinal,
            sectionId: "R",
            isBoolean: true,
        };
        r.commands.push({
            item: "0",
            value: 1,
            isBit: false,
            minRange: 0,
            maxRange: 1,
            default: 1,
            description: "Run = 1 / Stop = 0",
        }); // Run / Stop
        r.commands.push({
            item: "1",
            value: 1,
            isBit: false,
            minRange: 0,
            maxRange: 1,
            default: 1,
            description: "Enable Ultrasonic",
        }); // Ultrasonic
        r.commands.push({
            item: "2",
            value: 1,
            isBit: false,
            minRange: 0,
            maxRange: 1,
            default: 1,
            description: "Enable Image 1",
        }); // Image 1
        r.commands.push({
            item: "3",
            value: 1,
            isBit: false,
            minRange: 0,
            maxRange: 1,
            default: 1,
            description: "Enable Image 2",
        }); // Image 2
        r.commands.push({
            item: "4",
            value: 1,
            isBit: false,
            minRange: 0,
            maxRange: 1,
            default: 1,
            description: "Enable Image 3",
        }); // Image 3
        r.commands.push({
            item: "5",
            value: 0,
            isBit: false,
            minRange: 0,
            maxRange: 1,
            default: 1,
            description: "Enable Absolute Height",
        }); // Enable Absolute Height

        retVal.sections.push(r);
        ordinal += 10;
        //G Gain
        let g: InstallationCommandSection = {
            title: "Gain Value",
            commands: [],
            display: true,
            ordinal: ordinal,
            sectionId: "G",
            isBoolean: false,
        };
        // g.commands.push({ item: "0", value: 0, isBit: false, minRange: 0, maxRange: 100000, default: 0, description: "Auto Gain value" });
        g.commands.push({
            item: "1",
            value: 0,
            isBit: false,
            minRange: 0,
            maxRange: genericMaxValue,
            default: 0,
            description: "Gain value",
        });
        g.commands.push({
            item: "2",
            value: 0,
            isBit: false,
            minRange: 0,
            maxRange: genericMaxValue,
            default: 0,
            description: "Gain value",
        });
        g.commands.push({
            item: "3",
            value: 0,
            isBit: false,
            minRange: 0,
            maxRange: genericMaxValue,
            default: 0,
            description: "Gain value",
        });
        g.commands.push({
            item: "4",
            value: 0,
            isBit: false,
            minRange: 0,
            maxRange: genericMaxValue,
            default: 0,
            description: "Gain value",
        });
        g.commands.push({
            item: "5",
            value: 0,
            isBit: false,
            minRange: 0,
            maxRange: genericMaxValue,
            default: 0,
            description: "Gain value",
        });
        g.commands.push({
            item: "6",
            value: 0,
            isBit: false,
            minRange: 0,
            maxRange: genericMaxValue,
            default: 0,
            description: "Gain value",
        });
        g.commands.push({
            item: "7",
            value: 0,
            isBit: false,
            minRange: 0,
            maxRange: genericMaxValue,
            default: 0,
            description: "Gain value",
        });
        g.commands.push({
            item: "8",
            value: 0,
            isBit: false,
            minRange: 0,
            maxRange: genericMaxValue,
            default: 0,
            description: "Gain value",
        });
        g.commands.push({
            item: "9",
            value: 0,
            isBit: false,
            minRange: 0,
            maxRange: genericMaxValue,
            default: 0,
            description: "Gain value",
        });

        retVal.sections.push(g);
        ordinal += 10;
        // C
        let c: InstallationCommandSection = {
            title: "",
            commands: [
                {
                    item: "0",
                    value: 5,
                    isBit: false,
                    minRange: 0,
                    maxRange: genericMaxValue,
                    default: 5,
                    description: "Count of gain of values",
                },
            ],
            display: false,
            ordinal: ordinal,
            sectionId: "C",
            isBoolean: false,
        };
        retVal.sections.push(c);
        ordinal += 10;

        // D
        let d: InstallationCommandSection = {
            title: "",
            commands: [
                {
                    item: "0",
                    value: 3,
                    isBit: false,
                    minRange: 0,
                    maxRange: genericMaxValue,
                    default: 3,
                    description: "Blanking Delay",
                },
            ],
            display: false,
            ordinal: ordinal,
            sectionId: "D",
            isBoolean: false,
        };
        retVal.sections.push(d);
        ordinal += 10;

        // S
        let s: InstallationCommandSection = {
            title: "Reading frequency",
            commands: [
                {
                    item: "0",
                    value: 15,
                    isBit: false,
                    minRange: 0,
                    maxRange: 240,
                    default: 15,
                    description: "Normal Sampling (min)",
                },
                {
                    item: "1",
                    value: 5,
                    isBit: false,
                    minRange: 0,
                    maxRange: 240,
                    default: 5,
                    description: "Warning Sampling (min)",
                },
                {
                    item: "2",
                    value: 2,
                    isBit: false,
                    minRange: 0,
                    maxRange: 240,
                    default: 2,
                    description: "Alarm Sampling (min)",
                },
            ],
            display: true,
            ordinal: ordinal,
            sectionId: "S",
            isBoolean: false,
        };
        retVal.sections.push(s);
        ordinal += 10;

        // u
        let u: InstallationCommandSection = {
            title: "Data upload frequency",
            commands: [
                {
                    item: "0",
                    value: 130,
                    isBit: false,
                    minRange: 5,
                    maxRange: 1400,
                    default: 120,
                    description: "Normal data upload in min",
                },
                {
                    item: "1",
                    value: 30,
                    isBit: false,
                    minRange: 5,
                    maxRange: 120,
                    default: 30,
                    description: "Warning Data Upload in min",
                },
                {
                    item: "2",
                    value: 10,
                    isBit: false,
                    minRange: 5,
                    maxRange: 60,
                    default: 10,
                    description: "Alarm Data Upload in min",
                },
            ],
            display: true,
            ordinal: ordinal,
            sectionId: "U",
            isBoolean: false,
        };
        retVal.sections.push(u);
        ordinal += 10;

        // p
        let p: InstallationCommandSection = {
            title: "Image capture and upload frequency",
            commands: [
                {
                    item: "0",
                    value: 1440,
                    isBit: false,
                    minRange: 10,
                    maxRange: 1440,
                    default: 1440,
                    description: "Normal photo upload (min)",
                },
                {
                    item: "1",
                    value: 120,
                    isBit: false,
                    minRange: 10,
                    maxRange: 240,
                    default: 120,
                    description: "Warning photo Upload (min)",
                },
                {
                    item: "2",
                    value: 30,
                    isBit: false,
                    minRange: 10,
                    maxRange: 120,
                    default: 30,
                    description: "Alarm photo Upload (min)",
                },
            ],
            display: true,
            ordinal: ordinal,
            sectionId: "P",
            isBoolean: false,
        };
        retVal.sections.push(p);
        ordinal += 10;

        // a
        let a: InstallationCommandSection = {
            title: "Warning level threshold",
            commands: [
                {
                    item: "0",
                    value: 7,
                    isBit: true,
                    minRange: 0,
                    maxRange: 7,
                    default: 7,
                    description: "Enable Alarms",
                },
                {
                    item: "1",
                    value: 35,
                    isBit: false,
                    minRange: 0,
                    maxRange: genericMaxValue,
                    default: 350,
                    description: "Warning Level (mm)",
                },
                {
                    item: "2",
                    value: 50,
                    isBit: false,
                    minRange: 0,
                    maxRange: genericMaxValue,
                    default: 250,
                    description: "Alarm level (mm)",
                },
            ],
            display: true,
            ordinal: ordinal,
            sectionId: "A",
            isBoolean: false,
        };
        retVal.sections.push(a);
        ordinal += 10;

        // I
        let i: InstallationCommandSection = {
            title: "Image resolution",
            commands: [
                {
                    item: "0",
                    value: 4,
                    isBit: false,
                    minRange: 0,
                    maxRange: 4,
                    default: 4,
                    description: "Image 1 Resolution",
                },
                {
                    item: "1",
                    value: 5,
                    isBit: false,
                    minRange: 0,
                    maxRange: 6,
                    default: 5,
                    description: "Image 2 Resolution",
                },
                {
                    item: "3",
                    value: 6,
                    isBit: false,
                    minRange: 0,
                    maxRange: 6,
                    default: 6,
                    description: "Image 3 Resolution",
                },
                {
                    item: "4",
                    value: 36,
                    isBit: false,
                    minRange: 11,
                    maxRange: 99,
                    default: 36,
                    description: "Image 1 Compression",
                },
                {
                    item: "5",
                    value: 36,
                    isBit: false,
                    minRange: 11,
                    maxRange: 99,
                    default: 36,
                    description: "Image 2 Compression",
                },
                {
                    item: "6",
                    value: 36,
                    isBit: false,
                    minRange: 11,
                    maxRange: 99,
                    default: 36,
                    description: "Image 3 Compression",
                },
            ],
            display: false,
            ordinal: ordinal,
            sectionId: "I",
            isBoolean: false,
        };
        retVal.sections.push(i);
        ordinal += 10;

        return retVal;
    };
}
