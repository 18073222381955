import { formatAddress, formatDate } from "Custom/Utils/format";
import { runInAction, action, computed, observable } from "mobx";
import { ViewModelBase } from "Core/ViewModels/ViewModelBase";
import { FieldType } from "Core/Utils/Utils";
import { Server } from "Custom/Globals/AppUrls";
import { InstallationImageDto, InstallationStatusDataDTO, StandingWaterStatus } from "Custom/Models/Domain/Installations";
import { ApiResult } from "Core/Models";
import { InstallationCommissionedResponseDto, InstallationCommissionedResponseModel } from "./InstallationCommissionedResponseModel";
import { GenericIdNumberRequest } from "Custom/Models";
import { Address, Property } from "Custom/Models/Domain";
import { InstallationCommissionImage } from "./InstallationCommissionedImage";

//extend viewmodel base and passing your model as the generic type
export class TabInstallationViewModel extends ViewModelBase<InstallationCommissionedResponseModel> {
    constructor() {
        //Pass in a new instance of your model
        //By passing in true as the second parameter, we make this model undoable which means we can use save and reset options on the model
        //If you make a change to the model you need to persist it with a saveModel() call
        //If you make changes to your model you can revert it back by calling resetModel()
        super(new InstallationCommissionedResponseModel(), false);
        //EN - Haven't figured out how to make this call work from the base model yet
        //This is only needed if you make use of the validation decorators
        this.setDecorators(InstallationCommissionedResponseModel);
    }

    //Singleton instance of class
    private static _instance: TabInstallationViewModel;

    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    @observable public hackCounter: number = 0;

    @action
    public reset() {
        this.model.clear();
    }

    @computed
    public get getImagesForModal(): InstallationImageDto[] {
        let retVal: InstallationImageDto[] = [];

        if (this.model.generalImages !== undefined && this.model.generalImages.length > 0) {
            for (let i: number = 0; i < this.model.generalImages.length; i++) {
                let image: InstallationCommissionImage = this.model.generalImages[i];
                retVal.push({
                    id: image.id,
                    url: image.imageUrl,
                    width: 0,
                    height: 0,
                    timeStamp: image.createdOn,
                    view: i,
                    deviceId: "",
                    simId: "",
                    flipped: false,
                    isDeleted: false,
                });
            }
        }

        return retVal;
    }

    @computed
    public get getDrainageImagesForModal(): InstallationImageDto[] {
        let retVal: InstallationImageDto[] = [];

        if (this.model.drainageImages !== undefined && this.model.drainageImages.length > 0) {
            for (let i: number = 0; i < this.model.drainageImages.length; i++) {
                let image: InstallationCommissionImage = this.model.drainageImages[i];
                retVal.push({
                    id: image.id,
                    url: image.imageUrl,
                    width: 0,
                    height: 0,
                    timeStamp: image.createdOn,
                    view: i,
                    deviceId: "",
                    simId: "",
                    flipped: false,
                    isDeleted: false,
                });
            }
        }

        return retVal;
    }

    @computed
    public get hasDrainageImages(): boolean {
        return this.model.drainageImages !== undefined && this.model.drainageImages.length > 0;
    }
    @computed
    public get hasImages(): boolean {
        return this.model.generalImages !== undefined && this.model.generalImages.length > 0;
    }

    @computed
    public get HasValidData(): boolean {
        return this.model.installationCommissioned !== undefined;
    }

    @computed
    public get Counter(): number {
        return this.hackCounter;
    }

    // General

    @computed
    public get getCommisionDate(): string {
        let retVal: string = "";

        if (this.model.installationCommissioned !== null && this.model.installationCommissioned !== undefined) {
            retVal = formatDate(this.model.installationCommissioned.commissionDate);
        }

        return retVal;
    }

    @computed
    public get getDeviceName(): string {
        let retVal: string = "";

        if (this.model.installationCommissioned !== null && this.model.installationCommissioned !== undefined) {
            retVal = this.model.installationCommissioned.deviceName;
        }

        return retVal;
    }

    @computed
    public get getProjectDetails(): string {
        let retVal: string = "";

        if (this.model.installationCommissioned !== null && this.model.installationCommissioned !== undefined) {
            retVal = this.model.installationCommissioned.projectNumber + " - " + this.model.installationCommissioned.projectName;
        }

        return retVal;
    }

    // Step 1 Contractor / Installer

    @computed
    public get getContractorName(): string {
        let retVal: string = "";

        if (this.model.installationCommissioned !== null && this.model.installationCommissioned !== undefined) {
            retVal = this.model.installationCommissioned.contractorName;
        }

        return retVal;
    }
    @computed
    public get getInstaller(): string {
        let retVal: string = "";
        if (this.model.installationCommissioned !== null && this.model.installationCommissioned !== undefined) {
            retVal = this.model.installationCommissioned.installerFirstName + " " + this.model.installationCommissioned.installerLastName;
        }
        return retVal;
    }

    // Step 6 End User
    @computed
    public get getClient(): string {
        let retVal: string = "";
        if (this.model.installationCommissioned !== null && this.model.installationCommissioned !== undefined) {
            retVal = this.model.installationCommissioned.clientName;
        }
        return retVal;
    }

    @computed
    public get getAddress(): string {
        let retVal: string = "";
        if (this.model.installationCommissioned !== null && this.model.installationCommissioned !== undefined) {
            let property: Address = {
                buildingName: this.model.installationCommissioned.buildingName,
                addressLine1: this.model.installationCommissioned.addressLine1,
                addressLine2: this.model.installationCommissioned.addressLine2,
                city: this.model.installationCommissioned.city,
                county: this.model.installationCommissioned.county,
                postcode: this.model.installationCommissioned.postcode,
            };

            retVal = formatAddress(property, true);
        }
        return retVal;
    }
    // Step 3 Sensor Location
    @computed
    public get getSensorLocationText(): string {
        let retVal: string = "";
        if (this.model.installationCommissioned !== null && this.model.installationCommissioned !== undefined) {
            retVal = this.model.installationCommissioned.sensorLocationDescription;
        }
        return retVal;
    }

    // Step 4 / 5 Gutter Details / Install Height

    @computed
    public get getCommissionedStatusData(): InstallationStatusDataDTO | undefined {
        let retVal: InstallationStatusDataDTO | undefined = undefined;
        if (this.model.installationCommissioned !== null && this.model.installationCommissioned !== undefined) {
            // Need to work out side heights since not stored in the DB table.
            retVal = {
                id: undefined,
                gutterSensId: undefined,
                side1_Length: this.model.installationCommissioned.side1_Length,
                side2_Length: this.model.installationCommissioned.side2_Length,
                side1_Angle: this.model.installationCommissioned.side1_Angle,
                side2_Angle: this.model.installationCommissioned.side2_Angle,
                side1_Height: this.model.installationCommissioned.side1_Height,
                side2_Height: this.model.installationCommissioned.side2_Height,
                install_Height: this.model.installationCommissioned.install_Height,
                emailAddresses: "",
                mobileNumbers: "",
                roofType: this.model.installationCommissioned.roofType,
                maxHeight: this.model.installationCommissioned.maxHeight,
                drainageType: this.model.installationCommissioned.drainageType,
                p1: this.model.installationCommissioned.p1,
                p2: this.model.installationCommissioned.p2,
                siphonicBrand: undefined,
                baseMeasurement: this.model.installationCommissioned.baseMeasurement,
                isDeleted: false,
                propertyId: this.model.installationCommissioned.propertyId,
                contractorId: this.model.installationCommissioned.contractorId,
                commissionDate: this.model.installationCommissioned.commissionDate,
                isComissioned: true,

                standingWaterDays: 5,
                standingWaterPercent: 10,
                standingWaterEnum: StandingWaterStatus.Unknown,

                allowSWEmailSend: false,
                allowSWSMSSend: false,

                side1_Length2: 0,
                side2_Length2: 0,
                side1_Angle2: 0,
                side2_Angle2: 0,
                baseWidth: 0,
                sideType: this.model.installationCommissioned.sideType,
            };
        }
        return retVal;
    }
    // Step 6 Installation
    @computed
    public get getInstallNotes(): string {
        let retVal: string = "";
        if (this.model.installationCommissioned !== null && this.model.installationCommissioned !== undefined) {
            retVal = this.model.installationCommissioned.installNotes;
        }
        return retVal;
    }

    //This must be present in your viewmodel. Just return true if you dont need to validate anything.
    //keyof BlankModel & string lets you add type checking to the fieldName
    //I am using the validator package to make checking easier https://www.npmjs.com/package/validator
    public isFieldValid(fieldName: keyof FieldType<InstallationCommissionedResponseModel>): boolean {
        const { isValid, errorMessage } = this.validateDecorators(fieldName);

        //You need to this two properties after validation
        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    @action
    public load = async (deviceId: number): Promise<ApiResult<InstallationCommissionedResponseDto | undefined>> => {
        let apiResult: ApiResult<InstallationCommissionedResponseDto | undefined> = {
            wasSuccessful: false,
            errors: [],
            headers: "",
            payload: undefined,
        };

        try {
            if (this.IsLoading === false) {
                this.setIsLoading(true);
                this.model.clear();

                const request: GenericIdNumberRequest = {
                    id: deviceId,
                };

                const apiResult: ApiResult<InstallationCommissionedResponseDto> = await this.Post<InstallationCommissionedResponseDto>(
                    Server.Api.Installation.getInstallationCommissionByDeviceId,
                    request,
                );

                if (apiResult.wasSuccessful) {
                    let payload = apiResult.payload;
                    runInAction(() => {
                        this.model.fromDto(payload);
                        this.hackCounter++;
                    });
                } else {
                    // Error. What to do?
                    this.setIsErrored(true);
                    this.history.push("/");
                }
            }
        } catch (exception) {
            // Error. What to do?
            this.setIsErrored(true);
            this.history.push("/");
        } finally {
            this.setIsLoading(false);
        }

        return apiResult;
    };
}
