import { Box, Fade } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useObserver } from "mobx-react-lite";
import * as RouterUtil from "Custom/Utils/routing";
import { Stores, StoresContext } from "Custom/Stores";
import { InstallationsViewModel } from "./InstallationsViewModel";
import { Loader } from "Core/Components";
import { SwitchOption, ToolbarAddInstalltionWrapper, ToolbarSearchSwitchWrapper, ToolbarUnitOptions } from "Custom/StylesAppSpecific/Controls/BoxAddWrapper";
import { Container } from "Custom/StylesAppSpecific/AppStyling";
import { ITab } from "Custom/Components/Tabs/Tabs";
import { useHasRoutedTabs } from "Custom/Utils/useHasRoutedTabs";
import { InstallationListTabView } from "./InstallationListTab";
import { InstallationMapTabView } from "./InstallationMapTabView";
import { ProjectListSearch } from "Custom/Views/Projects/ProjectListSearch";
import { Addeditsection } from "../NotesTableRow.style";
import { AddNewBtn } from "Custom/StylesAppSpecific/Controls/AddNewBtn";
import { useRouter } from "Core/Base";
import { InstallationAddModal } from "Custom/Views/Projects/Details/Properties/Installations/InstallationAddModal";
import { InstallationModalData } from "Custom/Views/Projects/Details/Properties/Installations/InstallationList";
import { AppUrls } from "Custom/Globals";
import { ClickableImgCell } from "Custom/Components/Table/ClickableImgCell";
import EditLogo from "Custom/Content/editIcon.svg";

const TAB_LIST = "list";
const TAB_MAP = "map";

export const InstallationsView: React.FunctionComponent = () => {
    const store = React.useContext<Stores>(StoresContext);
    const router = useRouter();
    const [viewModel] = useState(() => InstallationsViewModel.Instance);
    const [tabKey, setTabKey] = React.useState(0);

    // 02/02/2022 SJ said to not show the map on the Client / contractor view of this page.
    const [showListMapSwitch, setShowListMapSwitch] = React.useState(false);

    const [newInstallationOpen, setNewInstallationOpen] = React.useState<InstallationModalData>({ open: false, id: "0" });

    const handleNewClick = () => {
        setNewInstallationOpen({ open: true, id: "0" });
    };

    const reload = (): void => {
        const { path } = router.match;

        setSelectedTab(viewModel.currentTab);

        switch (path) {
            case AppUrls.Client.ClientView.Device.DeviceRoot: {
                const apiResult = viewModel.loadForClientViewUnitViewAsync();
                apiResult.then((result) => {
                    setShowListMapSwitch(false);
                });
                break;
            }
            case AppUrls.Client.ContractorView.Device.DeviceRoot: {
                const apiResult = viewModel.loadForContractorViewUnitViewAsync();
                apiResult.then((result) => {
                    setShowListMapSwitch(false);
                });
                break;
            }
            case AppUrls.Client.Main.Device.Root:
            default: {
                const apiResult = viewModel.loadForMainViewUnitViewAsync();
                apiResult.then((result) => {
                    setShowListMapSwitch(true);
                });
                break;
            }
        }
    };

    const handleNewInstallationClose = (saved: boolean) => {
        setNewInstallationOpen({ open: false, id: "0" });

        if (saved === true) {
            reload();
        }
    };

    const getTabOptions: () => ITab[] = () => {
        let retVal: ITab[] = [
            { key: TAB_LIST, title: "List", hash: "list" },
            { key: TAB_MAP, title: "Map", hash: "map" },
        ];
        return retVal;
    };

    const routedTab = useHasRoutedTabs(getTabOptions());
    const [selectedTab, setSelectedTab] = React.useState(routedTab?.key || TAB_LIST);

    const handleTabClick = () => {
        // Toggle the state
        if (selectedTab === TAB_LIST) {
            setSelectedTab(TAB_MAP);
            viewModel.setCurrentTab(TAB_MAP);
        } else {
            setSelectedTab(TAB_LIST);
            viewModel.setCurrentTab(TAB_LIST);
        }

        setTabKey(tabKey + 1);
    };

    const getTabKey = () => {
        return `installationview-tab-${selectedTab}-${tabKey}`;
    };

    //useEffect below only gets run on initial render
    useEffect(() => {
        reload();

        return () => {
            viewModel.cleanUp();
        };
    }, []);

    const setEditInstallation = (id: string) => {
        setNewInstallationOpen({ open: true, id: id });
    };

    const renderContent = () => {
        switch (selectedTab) {
            case TAB_LIST:
                return <InstallationListTabView setEditInstallation={setEditInstallation} />;
            case TAB_MAP:
                return <InstallationMapTabView />;
        }
    };

    const setSearchString = (value: string) => {
        viewModel.setSearchString(value);
        viewModel.setPage(0);
    };

    const handleCSVClick = (id: string) => {
        let ret = viewModel.downloadInstallationsAsCSV();

        ret.then((ret) => {
            //alert("Back");
        });
    };

    const renderPage = () => {
        if (viewModel.IsLoading) return <Loader />;
        const btnClass: string = selectedTab === TAB_LIST ? "listView" : "mapView";

        const canShowAddInstallation: boolean = RouterUtil.requireContractorAdminorAdminLogin(store) && viewModel.model.okToAddInstallation === true;

        return (
            <Fade in timeout={1000}>
                <Container>
                    <InstallationAddModal open={newInstallationOpen.open} onClose={handleNewInstallationClose} propertyViewModel={undefined} id={newInstallationOpen.id} />

                    <Box width="100%">
                        <ToolbarSearchSwitchWrapper>
                            <div className="search-field" style={{ width: "580px" }}>
                                <ProjectListSearch
                                    searchString={viewModel.getSearchString()}
                                    setSearchString={setSearchString}
                                    placeholder="Start typing to search installations"
                                />
                            </div>
                            <div className="download-csv">
                                <ClickableImgCell className="projectnote-addedit" logo={EditLogo} text={"Download installations CSV"} onClick={handleCSVClick} id={"0"} />
                            </div>
                            {showListMapSwitch && (
                                <ToolbarUnitOptions>
                                    <SwitchOption className={btnClass} onClick={() => handleTabClick()}></SwitchOption>
                                </ToolbarUnitOptions>
                            )}
                        </ToolbarSearchSwitchWrapper>
                        <ToolbarAddInstalltionWrapper>
                            <ToolbarUnitOptions>
                                {canShowAddInstallation && (
                                    <div className="add-wrapper">
                                        <div className="add" role="button" tabIndex={0} onClick={handleNewClick}>
                                            <Addeditsection>
                                                <AddNewBtn>
                                                    <span className="btnText">Add new installation</span>
                                                </AddNewBtn>
                                            </Addeditsection>
                                        </div>
                                    </div>
                                )}
                            </ToolbarUnitOptions>
                        </ToolbarAddInstalltionWrapper>
                    </Box>
                    <div className="content">{renderContent()}</div>
                </Container>
            </Fade>
        );
    };

    return useObserver(() => renderPage());
};
