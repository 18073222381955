export enum ProjectCommunicationSortEnum {
    DateAsc = 0,
    DateDesc = 100,
    FollowUpAsc = 200,
    FollowUpDesc = 300,
    ResolvedAsc = 400,
    ResolvedDesc = 500,
}

export class ProjectCommunicationSortHelpers {
    public static getText = (item: ProjectCommunicationSortEnum): string => {
        let retVal: string = "Date Ascending";

        switch (item) {
            case ProjectCommunicationSortEnum.DateDesc: {
                retVal = "Date Descending";
                break;
            }
            case ProjectCommunicationSortEnum.FollowUpAsc: {
                retVal = "Follow Up Ascending";
                break;
            }
            case ProjectCommunicationSortEnum.FollowUpDesc: {
                retVal = "Follow Up Descending";
                break;
            }
            case ProjectCommunicationSortEnum.ResolvedAsc: {
                retVal = "Resolved Ascending";
                break;
            }
            case ProjectCommunicationSortEnum.ResolvedDesc: {
                retVal = "Resolved Descending";
                break;
            }
            case ProjectCommunicationSortEnum.DateAsc:
            default: {
                break;
            }
        }
        return retVal;
    };

    public static getStatusClass = (item: ProjectCommunicationSortEnum): string => {
        let retVal: string = "projectcommdate-asc";

        switch (item) {
            case ProjectCommunicationSortEnum.DateDesc: {
                retVal = "projectcommdate-desc";
                break;
            }
            case ProjectCommunicationSortEnum.FollowUpAsc: {
                retVal = "projectcommfollow-asc";
                break;
            }
            case ProjectCommunicationSortEnum.FollowUpDesc: {
                retVal = "projectcommfollow-desc";
                break;
            }
            case ProjectCommunicationSortEnum.ResolvedAsc: {
                retVal = "projectcommresolved-asc";
                break;
            }
            case ProjectCommunicationSortEnum.ResolvedDesc: {
                retVal = "projectcommresolved-desc";
                break;
            }

            case ProjectCommunicationSortEnum.DateAsc:
            default: {
                break;
            }
        }
        return retVal;
    };

    public static getOptions = () => {
        const options: {
            label: string;
            value: string;
        }[] = [];

        for (const key in ProjectCommunicationSortEnum) {
            const keyAsInt = parseInt(key);

            if (isNaN(keyAsInt) === false) {
                const item = keyAsInt as ProjectCommunicationSortEnum;

                options.push({
                    label: ProjectCommunicationSortHelpers.getText(item),
                    value: keyAsInt.toString(),
                });
            }
        }

        return options;
    };
}
