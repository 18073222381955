import { Box } from "@material-ui/core";
import React from "react";
import { useObserver } from "mobx-react-lite";
import styled from "styled-components";

import { InstallationImagePanelDialog } from "./InstallationImagePanelDialog";
import { InstallationImagePanel } from "./InstallationImagePanel";
import { CollapsibleInstallationImagePanelViewModel } from "./CollapsibleInstallationImagePanelViewModel";
import { InstallationImageViewModel2 } from "./InstallationImageViewModel";
import { chunkItems } from "Custom/Utils/array";

interface IProps {
    viewModel: CollapsibleInstallationImagePanelViewModel;
    className?: string;
}

export const InstallationImagePanelContainerBase: React.FC<IProps> = (props) => {
    const data: InstallationImageViewModel2[] = props.viewModel.installationImageViewModels.slice(0);
    const chunks: InstallationImageViewModel2[][] = chunkItems<InstallationImageViewModel2>(data, 3);
    return useObserver(() => (
        <React.Fragment>
            {props.viewModel.canDisplayActiveInstallation && <InstallationImagePanelDialog viewModel={props.viewModel.activeInstallationViewModel!} />}
            <InstallationImagePanelSection id="panelsection" className={props.className}>
                {chunks.map((chunk, index) => {
                    return (
                        <div id={"panelsectionrow-" + index} key={"row_" + index} className="row">
                            {chunk.map((item: InstallationImageViewModel2) => (
                                <InstallationImagePanel key={item.id} viewModel={item} />
                            ))}
                        </div>
                    );
                })}
            </InstallationImagePanelSection>
        </React.Fragment>
    ));
};

export const InstallationImagePanelSection = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    /* background-color: pink; */

    .row {
        display: flex;
        flex: 1;
        flex-direction: row;
        width: 100%;
        /*       background-color: #fafafa; */
    }
`;

export const InstallationImagePanelContainer = styled(InstallationImagePanelContainerBase)`
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px 15px -15px;
`;
