import { action, observable } from "mobx";
import { ModelBase } from "Core/Models/ModelBase";
import { IsNotEmpty } from "class-validator";
import { InstallationAuditModelDTO } from "../InstallationAuditModel";
import { AlertDirection, ConditionType, ConditionUnits } from "Custom/Models/Domain";

//Base class expects the model type and the DTO model type
//If you are not using a DTO just pass in undefined as the DTO
export class AuditTrippedAlertModel extends ModelBase<AuditTrippedAlertModel, AuditTrippedAlertModelDTO> {
    @observable public Name: string = "";
    @observable public PreviousValue: number = 0;
    @observable public LatestValue: number = 0;
    @observable public LatestValueTime: string | null = "";
    @observable public Value: number = 0;
    @observable public CalculatedTripValue: number = 0;
    @observable public Type: ConditionType = ConditionType.H;
    @observable public Units: ConditionUnits = ConditionUnits.MM;
    @observable public Direction: AlertDirection = AlertDirection.UPDOWN;
    @observable public Photo: boolean = false;
    @observable public Email: boolean = false;
    @observable public SMS: boolean = false;
    @observable public CommandIndex: number = 0;
    @observable public Changeset: number = 0;
    @observable public CreatedDate: string = "";

    //fromDto is required but you can leave it blank
    @action
    public fromDto(model: AuditTrippedAlertModelDTO): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
    }

    //toDto is required but you can leave it blank
    toDto(model: AuditTrippedAlertModel): void {}

    @action
    public loadData(data: InstallationAuditModelDTO) {
        // TODO Stuff about the version number later
        let model: AuditTrippedAlertModelDTO = JSON.parse(data.dataJson);
        this.fromDto(model);
    }
}

export type AuditTrippedAlertModelDTO = {
    Name: string;
    PreviousValue: number;
    LatestValue: number;
    LatestValueTime: string | null;
    Value: number;
    CalculatedTripValue: number;
    Type: ConditionType;
    Units: ConditionUnits;
    Direction: AlertDirection;
    Photo: boolean;
    Email: boolean;
    SMS: boolean;
    CommandIndex: number;
    Changeset: number;
    CreatedDate: string;
};
