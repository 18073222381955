/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useState, useEffect } from "react";
import { StoresInstance } from "Custom/Stores";

export const Logout: React.FC = () => {
    useEffect(() => {
        StoresInstance.domain.AccountStore.Logout();

        return () => {
            // Clean up after yourself
        };
    }, []);

    return <></>;
};
