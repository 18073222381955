import { ModelBase } from "Core/Models";
import { action, observable } from "mobx";

export class BreadcrumbContextValue extends ModelBase<BreadcrumbContextValue, BreadcrumbContextValueDTO> {
    @observable public clientName: string | undefined = undefined;
    @observable public contractorName: string | undefined = undefined;
    @observable public installationName: string | undefined = undefined;
    @observable public projectName: string | undefined = undefined;
    @observable public propertyName: string | undefined = undefined;
    @observable public userName: string | undefined = undefined;

    //fromDto is required but you can leave it blank
    @action
    fromDto(model: BreadcrumbContextValueDTO): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
    }

    //toDto is required but you can leave it blank
    toDto(model: BreadcrumbContextValueDTO): void {}
}

export interface BreadcrumbContextValueDTO {
    clientName: string | undefined;
    contractorName: string | undefined;
    installationName: string | undefined;
    projectName: string | undefined;
    propertyName: string | undefined;
    userName: string | undefined;
}
