import styled from "styled-components";
import { TabCommonBox } from "../TabCommonStyle.style";

export const AlertActionAnalyticsWrapper: any = styled.div`
    position: relative;
    display: flex;
    flex: 1 0 0;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
`;

export const AlertActionAnalyticsBox: any = styled.div`
    display: flex;
    flex: 1 0 0;
    flex-direction: row;
    width: 100%;
    max-width: 100%;
    justify-content: space-between;
    padding-bottom: 15px;
    @media screen and (min-width: 1600px) {
        flex-direction: row;
    }

    @media screen and (max-width: 1600px) {
        flex-direction: column;
    }
`;

export const AnalyticsBox: any = styled(TabCommonBox)`
    padding: 5px 15px;
    display: flex;
    flex: 1 0 0;
    flex-direction: column;
    color: white;
`;

export const BarchartWrapper: any = styled(TabCommonBox)`
    display: flex;
    flex: 1 0 0;
    flex-direction: row;
    @media screen and (min-width: 1600px) {
        max-width: 50%;
    }

    @media screen and (max-width: 1600px) {
        max-width: 100%;
        margin-bottom: 15px;
    }

    justify-content: center;
    padding-bottom: 15px;
    color: white;
`;
