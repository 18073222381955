import { useEffect, useState } from "react";
import { useObserver } from "mobx-react-lite";
import { KeyPerformanceDataBox, KeyPerformanceDataCell, KeyPerformanceDataRow } from "./KeyPerformanceData.style";
import { CircularDisplay, ICircularDisplayArrow } from "Custom/Components/Displays/CircularDisplay";
import { DrainageType, InstallationStatusDataDTO, KeyPerformanceData } from "Custom/Models/Domain";

interface KeyPerformanceDataProps {
    keyPerformance: KeyPerformanceData;
    statusData?: InstallationStatusDataDTO;
}

export function KeyPerformanceDataControl(props: KeyPerformanceDataProps) {
    const { statusData, keyPerformance } = props;

    //const [viewModel] = useState(() => new GutterSensLatestImageViewModel());

    useEffect(() => {
        const {} = props;

        return () => {
            // tidy up after yourself
        };
    }, []);

    /*     useEffect(() => {
    }, [data]); */

    const getCircleColour = (value: ICircularDisplayArrow): string => {
        let retVal: string = "#ffffff";

        switch (value) {
            case ICircularDisplayArrow.UP: {
                retVal = "#C70101";
                break;
            }
            case ICircularDisplayArrow.DOWN: {
                retVal = "#61AA66";
                break;
            }
            case ICircularDisplayArrow.NONE:
            case ICircularDisplayArrow.SAME:
            default: {
                retVal = "#F29D29";
                break;
            }
        }

        return retVal;
    };

    let showP1: boolean = false;
    let showP2: boolean = false;
    let showMaxAverage: boolean = false;

    if (statusData !== null && statusData !== undefined) {
        showMaxAverage = true;

        if (statusData.drainageType !== DrainageType.Gravity) {
            showP1 = true;
        }
        if (statusData.drainageType === DrainageType.DualSiphonic) {
            showP2 = true;
        }
    }

    return useObserver(() => (
        <>
            <KeyPerformanceDataBox>
                <KeyPerformanceDataRow>
                    <KeyPerformanceDataCell>
                        <div className="display">
                            <CircularDisplay
                                centerData={keyPerformance.alerts30.toString()}
                                height={80}
                                width={80}
                                circleDiameter={160}
                                fillColor="#3a3c41"
                                strokeColor={getCircleColour(keyPerformance.alerts30Direction)}
                                legendText=""
                                legendInMiddle={false}
                                arrow={keyPerformance.alerts30Direction}
                                strokeWidth={6}
                                prefix="alert30"
                            />
                        </div>
                        <div className="title">Alerts in last 30 days</div>
                    </KeyPerformanceDataCell>
                    <KeyPerformanceDataCell>
                        <div className="display">
                            <CircularDisplay
                                centerData={keyPerformance.alertsLTM.toString()}
                                height={80}
                                width={80}
                                circleDiameter={160}
                                fillColor="#3a3c41"
                                strokeColor={getCircleColour(keyPerformance.alertLTMDirection)}
                                legendText=""
                                legendInMiddle={false}
                                arrow={keyPerformance.alertLTMDirection}
                                strokeWidth={6}
                                prefix="alertltm"
                            />
                        </div>
                        <div className="title">Alerts LTM</div>
                    </KeyPerformanceDataCell>
                    {showMaxAverage === true && (
                        <KeyPerformanceDataCell>
                            <div className="display">
                                <CircularDisplay
                                    centerData={keyPerformance.maxCapacityLTM.toFixed(0) + "%"}
                                    height={80}
                                    width={80}
                                    circleDiameter={160}
                                    fillColor="#3a3c41"
                                    strokeColor={getCircleColour(keyPerformance.maxCapacityLTMDirection)}
                                    legendText=""
                                    legendInMiddle={false}
                                    arrow={keyPerformance.maxCapacityLTMDirection}
                                    strokeWidth={6}
                                    prefix="capacityltm"
                                />
                            </div>
                            <div className="title">Max capacity LTM</div>
                        </KeyPerformanceDataCell>
                    )}
                </KeyPerformanceDataRow>
                <KeyPerformanceDataRow>
                    {showMaxAverage === true && (
                        <KeyPerformanceDataCell>
                            <div className="display">
                                <CircularDisplay
                                    centerData={keyPerformance.averagePeak.toFixed(0) + "%"}
                                    height={80}
                                    width={80}
                                    circleDiameter={160}
                                    fillColor="#3a3c41"
                                    strokeColor={getCircleColour(keyPerformance.averagePeakDirection)}
                                    legendText=""
                                    legendInMiddle={false}
                                    arrow={keyPerformance.averagePeakDirection}
                                    strokeWidth={6}
                                    prefix="averagepeak"
                                />
                            </div>
                            <div className="title">Average peak capacity LTM</div>
                        </KeyPerformanceDataCell>
                    )}
                    {showP1 === true && (
                        <KeyPerformanceDataCell>
                            <div className="display">
                                <CircularDisplay
                                    centerData={keyPerformance.p1Exceeded.toString()}
                                    height={80}
                                    width={80}
                                    circleDiameter={160}
                                    fillColor="#3a3c41"
                                    strokeColor={getCircleColour(keyPerformance.p1ExceededDirection)}
                                    legendText=""
                                    legendInMiddle={false}
                                    arrow={keyPerformance.p1ExceededDirection}
                                    strokeWidth={6}
                                    prefix="p1exceed"
                                />
                            </div>
                            <div className="title">P1 exceeded last 30 days</div>
                        </KeyPerformanceDataCell>
                    )}
                    {showP2 === true && (
                        <KeyPerformanceDataCell>
                            <div className="display">
                                <CircularDisplay
                                    centerData={keyPerformance.p2Exceeded.toString()}
                                    height={80}
                                    width={80}
                                    circleDiameter={160}
                                    fillColor="#3a3c41"
                                    strokeColor={getCircleColour(keyPerformance.p2ExceededDirection)}
                                    legendText=""
                                    legendInMiddle={false}
                                    arrow={keyPerformance.p2ExceededDirection}
                                    strokeWidth={6}
                                    prefix="p2exceed"
                                />
                            </div>
                            <div className="title">P2 exceeded last 30 days</div>
                        </KeyPerformanceDataCell>
                    )}
                </KeyPerformanceDataRow>
            </KeyPerformanceDataBox>
        </>
    ));
}
