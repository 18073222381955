import React, { useRef, useEffect, useState } from "react";
import { useObserver } from "mobx-react-lite";
import { GutterSensTabContent } from "./GuttersensTabContent";
import { generateID } from "Core/Utils/Utils";
import { ConfigurationBox } from "Custom/Views/Installations/TabConfiguration.style";
import { CollapsiblePanel, CollapsiblePanelSettings } from "Custom/Components/Panels/CollapsiblePanel";
import { CollapsiblePanelViewModel } from "Custom/Components/Panels/CollapsiblePanelviewModel";

import Dimensions from "Custom/Content/gutterDimensions.svg";
import { MenuItem, Select } from "@material-ui/core";
import { TabConfigurationViewModel } from "./TabConfigurationViewModel";
import { ConditionSetView } from "./Condition/ConditionSetView";
import { ProjectButton } from "../CustomComponents";
import { useRouter } from "Core/Utils";
import { AlertActionView } from "./AlertAction/AlertActionView";
import { InstallationViewModel } from "./InstallationViewModel";
import { ApiResult } from "Core/Models";
import { ConditionAddEditDetailModelDTO } from "../Admin/Condition/ConditionAddEditDetailModel";
import { TabConfigurationModelDTO } from "./TabConfigurationModel";

interface TabSettingProps {
    parentViewModel: InstallationViewModel;
    onConditionSetChange: () => void;
}

export interface MatchParams {
    installId?: number;
}

export function TabConfiguration(props: TabSettingProps) {
    const { parentViewModel, onConditionSetChange } = props;
    const [viewModel] = useState(() => TabConfigurationViewModel.Instance);
    const { match } = useRouter();
    const { installId } = match.params as MatchParams;

    const componentRef: any = useRef(generateID());

    const [viewModelKGD] = useState(() => new CollapsiblePanelViewModel("KGD", "Key gutter dimensions", viewModel.keyGutterCollapsed, [], viewModel.setKeyGutterCollapsed));
    const [viewModelCRP] = useState(() => new CollapsiblePanelViewModel("CRP", "Condition Set", viewModel.conditionSetCollapsed, [], viewModel.setConditionSetCollapsed));
    const [viewModelAA] = useState(() => new CollapsiblePanelViewModel("AA", "Alert action", viewModel.alertActionCollapsed, [], viewModel.setAlertActionCollapsed));

    useEffect(() => {
        viewModel.setStatusData(parentViewModel.statusData);
        let promise = viewModel.loadData(installId!);

        promise.then((result: any) => {});
        // tidy up after yourself
        return () => {
            // DO NOT CLEAR THE VIEWMODEL HERE, IT WILL BREAK THE COLLAPSABLE PANEL LOGIC
        };
    }, []);

    useEffect(() => {
        viewModel.setStatusData(parentViewModel.statusData);
    }, [parentViewModel.statusData]);

    useEffect(() => {
        parentViewModel.setIsEditingAlertAction(viewModel.getIsAlertActionsEditable);
    }, [viewModel.getIsAlertActionsEditable]);

    useEffect(() => {
        parentViewModel.setIsEditingConditionSet(viewModel.getIsConditionSetsEditable);
    }, [viewModel.getIsConditionSetsEditable]);

    const addExistingConditionSetLocal = async (value: string, installId: number) => {
        let promise = viewModel.addExistingConditionSetLocal(value, installId);

        promise.then((result: ApiResult<ConditionAddEditDetailModelDTO>) => {
            //viewModel.
            let conditionSets = viewModel.getConditionSets;

            if (conditionSets[0].isModelAndChildrenValid(parentViewModel.statusData) === true) {
                let promise = conditionSets[0].upsertDeviceConditionSet(installId!);

                promise.then((result: ApiResult<TabConfigurationModelDTO>) => {
                    if (result.wasSuccessful === true) {
                        viewModel.setLocalData(result);
                        onConditionSetChange();
                    }
                });
            }
        });
    };

    return useObserver(() => (
        <GutterSensTabContent>
            <ConfigurationBox>
                <CollapsiblePanel key={generateID()} viewModel={viewModelKGD}>
                    <CollapsiblePanelSettings>
                        <img src={Dimensions} />
                    </CollapsiblePanelSettings>
                </CollapsiblePanel>
                <CollapsiblePanel key={generateID()} viewModel={viewModelCRP}>
                    <CollapsiblePanelSettings>
                        <div className="panel-settings-container">
                            {viewModel.getConditionSets.length === 0 && (
                                <Select
                                    id={"condition-sets-select"}
                                    value={-1}
                                    onChange={(event) => addExistingConditionSetLocal(event.target.value as string, installId!)}
                                    MenuProps={{
                                        getContentAnchorEl: null,
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left",
                                        },
                                    }}
                                >
                                    {viewModel.getConditionSetOptions.map((item) => {
                                        return (
                                            <MenuItem key={generateID()} value={item.id}>
                                                {item.name}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            )}
                            {viewModel.getConditionSets.length === 0 && <ProjectButton icon={() => <></>} text="Add new condition" onClick={() => viewModel.addNewCondition()} />}
                        </div>
                        {viewModel.getConditionSets.map((item, index) => {
                            return (
                                <ConditionSetView
                                    key={generateID()}
                                    viewModel={item}
                                    index={index}
                                    drainageType={viewModel.getDrainageType}
                                    statusData={parentViewModel.statusData}
                                    onConditionSetChange={onConditionSetChange}
                                />
                            );
                        })}
                    </CollapsiblePanelSettings>
                </CollapsiblePanel>
                <CollapsiblePanel key={generateID()} viewModel={viewModelAA}>
                    <CollapsiblePanelSettings>
                        <div className="panel-settings-container">
                            {viewModel.getAlertActions.length === 0 && (
                                <Select
                                    id={"alert-actions-select"}
                                    value={-1}
                                    onChange={(event) => viewModel.addExistingAlertActionLocal(event.target.value as string, installId!)}
                                    MenuProps={{
                                        getContentAnchorEl: null,
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left",
                                        },
                                    }}
                                >
                                    {viewModel.getAlertActionOptions.map((item) => {
                                        return (
                                            <MenuItem key={generateID()} value={item.id}>
                                                {item.name}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            )}
                            {viewModel.getAlertActions.length === 0 && (
                                <ProjectButton icon={() => <></>} text="Add new alert action" onClick={() => viewModel.addNewAlertAction()} />
                            )}
                        </div>
                        <div className="panel-settings-container">
                            {parentViewModel.hasStatusData === true && (
                                <div>
                                    Alert email address and mobile number setup status:
                                    {parentViewModel!.hasEmailAddresses === true && <div className="alerticon tick fa fa-envelope"></div>}
                                    {parentViewModel!.hasEmailAddresses === false && <div className="alerticon cross fa fa-envelope"></div>}
                                    {parentViewModel!.hasMobileNumbers === true && <div className="alerticon tick fa fa-mobile"></div>}
                                    {parentViewModel!.hasMobileNumbers === false && <div className="alerticon cross fa fa-mobile "></div>}
                                </div>
                            )}
                        </div>
                        {viewModel.getAlertActions.map((item, index) => {
                            return (
                                <AlertActionView
                                    key={generateID()}
                                    viewModel={item}
                                    index={index}
                                    drainageType={viewModel.getDrainageType}
                                    statusData={parentViewModel.statusData}
                                    onAlertActionChange={onConditionSetChange}
                                />
                            );
                        })}
                    </CollapsiblePanelSettings>
                </CollapsiblePanel>
            </ConfigurationBox>
        </GutterSensTabContent>
    ));
}
