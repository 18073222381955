import React, { useRef, useEffect, useState, useContext } from "react";
import { useObserver } from "mobx-react-lite";
import { generateID } from "Core/Utils/Utils";
import { Box, Checkbox, MenuItem, Select, Typography, useTheme } from "@material-ui/core";
import moment, { Moment } from "moment";
import { GutterSensTabContent } from "./GuttersensTabContent";
import { InstallationStatusDataDTO, HideOnGraph, DaysOption, SensorValueExtended } from "Custom/Models/Domain/Installations";
import { isNullOrUndefined, isValidDateString } from "Custom/Utils/utils";
import { InstallationDataTable } from "./InstallationDataTable";
import { InstallationDataGraph } from "./InstallationDataGraph";
import { InstallationDataViewModel } from "./InstallationDataViewModel";
import { DataCell, DeviceDataBox, DeviceDataGraphBox, DeviceDataRow } from "Custom/Views/Installations/TabDeviceData.style";
import MomentUtils from "@date-io/moment";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Loader } from "Core/Components";
import { SuccessBtn } from "Custom/StylesAppSpecific/Controls/SuccessBtn";
import * as RouterUtil from "Custom/Utils/routing";
import { ProjectButton } from "../CustomComponents";
import { DeviceAlertActionModelDTO, DeviceConditionSetModelDTO } from "./TabConfigurationModel";
import { Stores, StoresContext } from "Custom/Stores";
import { LineGraphState } from "./Overview/TabOverview";

interface TabDataProps {
    deviceId: number;
    deviceStatusData: InstallationStatusDataDTO | undefined;
    conditionSet: DeviceConditionSetModelDTO | undefined;
    alertAction: DeviceAlertActionModelDTO | undefined;
    counter: number;
    updateIsHiddenResult: (values: SensorValueExtended[]) => any;
    minDate: Moment;
}

export function TabData(props: TabDataProps) {
    const storesContext = useContext<Stores>(StoresContext);
    const { alertAction, deviceStatusData, deviceId, conditionSet, counter, minDate } = props;
    const [viewModel] = useState(() => InstallationDataViewModel.Instance);
    const theme = useTheme();
    const [showDataPoints, setShowDataPoints] = React.useState(false);
    const [dimensions, setDimensions] = React.useState<LineGraphState>({
        width: 400,
        height: 675,
    });

    const componentRef: any = useRef(generateID());

    useEffect(() => {
        const { deviceId } = props;
        const { getSensorValues, IsLoading, load, reset } = viewModel;

        if ((isNullOrUndefined(getSensorValues) === true || getSensorValues.length === 0) && IsLoading === false && deviceId > 0) {
            load(deviceId);
        }

        updateWindowDimensions();
        window.addEventListener("resize", updateWindowDimensions);

        // tidy up after yourself
        return () => {
            window.removeEventListener("resize", updateWindowDimensions);
        };
    }, []);

    useEffect(() => {
        const { deviceId } = props;
        const { getSensorValues, IsLoading, load } = viewModel;

        if ((isNullOrUndefined(getSensorValues) === true || getSensorValues.length === 0) && IsLoading === false && deviceId > 0) {
            load(deviceId);
        }
    }, [deviceId]);

    useEffect(() => {
        // CMR - Since the tab is hidden by default, its width is 0
        // so by forcing a refresh everytime the tab is cleared, the graph should be the right size.
        updateWindowDimensions();
    }, [counter]);

    useEffect(() => {
        const { conditionSet } = props;
        viewModel.setConditionSet(conditionSet);
    }, [conditionSet]);

    useEffect(() => {
        const { deviceStatusData } = props;
        if (isNullOrUndefined(deviceStatusData) === false) {
            viewModel.setStatusData(deviceStatusData!);
        }
    }, [deviceStatusData]);

    useEffect(() => {
        if (viewModel.IsLoading === false) {
            document.documentElement.scrollTop = document.body.scrollTop = viewModel.scrollTop;
        }
    }, [viewModel.getScrollTop, viewModel.IsLoading]);

    ///
    /// updateWindowDimensions
    ///
    const updateWindowDimensions = () => {
        if (componentRef !== undefined && componentRef != null && componentRef.current !== null && componentRef.current !== undefined) {
            setDimensions({
                width: componentRef!.current!.offsetWidth - 45,
                height: componentRef.current!.offsetHeight! > 675 ? 389 : 389, // 389 gives roughly the height from the mocks once you add the bottom to it
            });
        }
    };

    const updateStart = (event: any, value: string | null | undefined): void => {
        let mom: Moment = moment(value, "DD/MM/YYYY");
        if (value !== null && value !== undefined) {
            if (isValidDateString(value!) === true && value!.length === 10) {
                if (mom < minDate) {
                    mom = minDate;
                }
                viewModel.setStartDate(mom);
            }
        }
    };

    const updateEnd = (event: any, value: string | null | undefined): void => {
        let mom: Moment = moment(value, "DD/MM/YYYY");
        if (value !== null && value !== undefined) {
            if (isValidDateString(value!) === true && value!.length === 10) {
                if (mom < minDate) {
                    mom = minDate;
                }
                viewModel.setEndDate(mom);
            }
        }
    };

    const searchByCustomDate = (event: any): void => {
        viewModel.load(props.deviceId);
    };

    const updateIsHidden = (isHidden: HideOnGraph): void => {
        const scrollTop: number = window.pageYOffset || document.documentElement.scrollTop;
        let promise = viewModel.updateIsHidden(isHidden, scrollTop);

        promise.then(() => {
            props.updateIsHiddenResult(viewModel.getSensorValues);
        });
    };

    const handleDaysToGraphDropDownOnChange = async (event: any) => {
        const days: number = parseInt(event.target.value);
        viewModel.setDaysToGraph(days);

        if (days > 0) {
            let mom: Moment = moment().add(days * -1, "days");

            if (mom < minDate) {
                mom = minDate;
            }

            viewModel.setStartDate(mom);
            viewModel.setEndDate(moment());
            await viewModel.load(props.deviceId);
        }
    };

    const disableDates: boolean = viewModel.getDaysToGraph > 0;

    const downloadData = () => {
        viewModel.downloadCSV(props.deviceId);
    };

    const hasData: string = viewModel.getSensorValues.length > 0 ? "" : "disabledbutton";

    const showDownloadButton: boolean = RouterUtil.requireAdminLogin(storesContext);

    const renderPage = () => {
        if (viewModel.IsLoading) return <Loader />;

        return (
            <GutterSensTabContent>
                <DeviceDataBox>
                    <Box className="guttersens-admin-deviceimages">
                        <DeviceDataRow>
                            <Typography style={{ fontSize: "16px" }}>Device Data</Typography>
                        </DeviceDataRow>
                        <DeviceDataRow>
                            <DataCell style={{ marginLeft: "-15px" }}>
                                <Typography style={{ fontSize: "14px" }} className="select-title">
                                    Show:
                                </Typography>
                                <Box maxWidth="140px" width="100%" className="selectBox">
                                    <Select
                                        id="role-select"
                                        value={viewModel.getDaysToGraph}
                                        onChange={handleDaysToGraphDropDownOnChange}
                                        MenuProps={{
                                            getContentAnchorEl: null,
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "left",
                                            },
                                        }}
                                        fullWidth
                                    >
                                        {viewModel.daysOptions.map((item: DaysOption, index: number) => {
                                            return (
                                                <MenuItem key={generateID()} value={item.value}>
                                                    {item.label}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </Box>
                            </DataCell>
                            <DataCell style={{ marginLeft: "-15px" }}>
                                <Typography style={{ fontSize: "14px" }}>Show from:</Typography>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        className="datepicker"
                                        placeholder="01/01/2021"
                                        label={""}
                                        value={viewModel.dateStart}
                                        disabled={disableDates}
                                        mask={"__-__-____"}
                                        format="DD/MM/YYYY"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={updateStart}
                                        minDate={minDate}
                                    />
                                </MuiPickersUtilsProvider>
                            </DataCell>
                            <DataCell>
                                <Typography style={{ fontSize: "14px" }}>Show to:</Typography>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        className="datepicker"
                                        placeholder="01/01/2021"
                                        label={""}
                                        value={viewModel.dateEnd}
                                        disabled={disableDates}
                                        mask={"__-__-____"}
                                        format="DD/MM/YYYY"
                                        InputLabelProps={{
                                            shrink: true,
                                            /* disabled: disableDates, */
                                        }}
                                        onChange={updateEnd}
                                        minDate={minDate}
                                    />
                                </MuiPickersUtilsProvider>
                            </DataCell>
                            <DataCell>
                                <SuccessBtn
                                    placeholder="Update graph using custom dates"
                                    id="customsearch"
                                    variant="contained"
                                    color="primary"
                                    type="button"
                                    disabled={disableDates}
                                    onClick={searchByCustomDate}
                                >
                                    Search
                                </SuccessBtn>
                            </DataCell>
                            <DataCell>
                                {viewModel.IsLoading && <Box className={"downloadcsv " + hasData}>Processing</Box>}
                                {!viewModel.IsLoading && showDownloadButton === true && (
                                    <ProjectButton
                                        icon={() => <div>&#x21e9;</div>}
                                        style={{
                                            borderRadius: "5px",
                                        }}
                                        text="Download CSV"
                                        onClick={() => downloadData()}
                                    />
                                )}
                            </DataCell>
                            <DataCell>
                                <Typography style={{ fontSize: "14px" }}>Show data points:</Typography>
                                <Box className="guttersens-checkbox">
                                    <Checkbox
                                        checked={showDataPoints}
                                        className="form-control"
                                        name={"showDatapoints"}
                                        onChange={() => {
                                            setShowDataPoints(!showDataPoints);
                                        }}
                                        value={showDataPoints === true ? 1 : 0}
                                    />
                                </Box>
                            </DataCell>
                        </DeviceDataRow>
                    </Box>
                    <DeviceDataGraphBox className="DeviceDataGraphBox" ref={componentRef}>
                        <InstallationDataGraph
                            graphHeight={dimensions.height}
                            readings={viewModel.graphSensorValues}
                            showDataPoints={showDataPoints}
                            graphWidth={dimensions.width}
                            deviceStatusData={deviceStatusData}
                            conditionSet={conditionSet}
                            alertAction={alertAction}
                        />
                    </DeviceDataGraphBox>
                    <Box>
                        <InstallationDataTable
                            startDate={viewModel.dateStart}
                            endDate={viewModel.dateEnd}
                            installHeight={deviceStatusData !== null && deviceStatusData !== undefined ? deviceStatusData.install_Height : 0}
                            sensorData={viewModel.getSensorValues}
                            updateIsHidden={updateIsHidden}
                        />
                    </Box>
                </DeviceDataBox>
            </GutterSensTabContent>
        );
    };

    return useObserver(() => <>{renderPage()}</>);
}
