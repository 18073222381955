import L from "leaflet";
import iconGreen from "../../Content/mapmarker-green.svg";
import iconAmber from "../../Content/mapmarker-amber.svg";
import iconRed from "../../Content/mapmarker-red.svg";
import iconFlood from "../../Content/mapmarker-flood.svg";
import iconUnknown from "../../Content/mapmarker-unknown.svg";

import iconDefaultMarker from "../../Content/marker-icon.png";
import iconDefaultMarker2 from "../../Content/marker-icon-2x.png";
import { UnitStatus } from "Custom/Models/Domain";

export const markerIconDefault = new L.Icon({
    iconUrl: iconDefaultMarker,
    iconRetinaUrl: iconDefaultMarker2,
    iconSize: [25, 41],
    iconAnchor: [12, 32],
    popupAnchor: [0, -43],
});

export const markerIconGreen = new L.Icon({
    iconUrl: iconGreen,
    iconRetinaUrl: iconGreen,
    iconSize: [48, 56],
    iconAnchor: [24, 47],
    popupAnchor: [0, -53],
});

export const markerIconAmber = new L.Icon({
    iconUrl: iconAmber,
    iconRetinaUrl: iconAmber,
    iconSize: [48, 56],
    iconAnchor: [24, 47],
    popupAnchor: [0, -53],
});

export const getIconWithUnits = (status: UnitStatus, units: number) => {
    let html: string = "<div class'marker-with-number'>";

    let icon = iconUnknown;

    switch (status) {
        /* TODO CMR NEED DYNAMIC ICON */
        default: {
            break;
        }
    }

    html += "<img src='" + icon + "' />";
    if (units !== 0) {
        if (units > 10) {
            html += "<div class='marker-number2'>" + units.toString() + "</div>";
        } else {
            html += "<div class='marker-number'>" + units.toString() + "</div>";
        }
    }
    html += "</div>";

    let retVal = new L.DivIcon({
        iconSize: [48, 56],
        iconAnchor: [24, 47],
        popupAnchor: [0, -53],
        className: "",
        html: html,
    });
    return retVal;
};

export const markerIconRed = new L.Icon({
    iconUrl: iconRed,
    iconRetinaUrl: iconRed,
    iconSize: [48, 56],
    iconAnchor: [24, 47],
    popupAnchor: [0, -53],
});

export const markerIconFlood = new L.Icon({
    iconUrl: iconFlood,
    iconRetinaUrl: iconFlood,
    iconSize: [48, 56],
    iconAnchor: [24, 47],
    popupAnchor: [0, -53],
});

export const markerIconUnkown = new L.Icon({
    iconUrl: iconUnknown,
    iconRetinaUrl: iconUnknown,
    iconSize: [48, 56],
    iconAnchor: [24, 47],
    popupAnchor: [0, -53],
});
