/* eslint-disable prettier/prettier */
import { useRef, useEffect, useState } from "react";
import { useObserver } from "mobx-react-lite";
import { generateID, sortByMoment } from "Core/Utils/Utils";
import { InstallationCommandViewModel } from "./InstallationCommandViewModel";
import {
    InstallationCommand,
    InstallationCommandTable,
    InstallationCommandSection,
    InstallationCommandNoteDTO,
    InstallationStatusDataDTO,
    InstallationCommandHistoryDTO,
} from "Custom/Models/Domain/Installations";
import { InstallationCommandWrapper } from "Custom/Views/Installations/InstallationCommandWrapper";
import { InstallationCommandSectionWrapper } from "Custom/Views/Installations/InstallationCommandSection";
import { Box, Grid, Paper, Typography } from "@material-ui/core";
import { RoofcareTable, RoofcareTableBox } from "Custom/Components/Table/RoofcareTable";
import { ConfigRow, DeviceAdminRow, DeviceHeader } from "./TabAdmin.style";
import { CommandSection } from "./CommandSection";
import { formatDateTime } from "Custom/Utils/format";
import { Addeditsection } from "./NotesTableRow.style";
import { AddNewBtn } from "Custom/StylesAppSpecific/Controls/AddNewBtn";

import * as Defaults from "Custom/StylesAppSpecific/TableOptions";
import { DeviceUnsentSection } from "./DeviceUnsentSection";
import { AlertSwitchOption } from "./DeviceSection.style";
import moment from "moment";
import { InstallationAuditViewModel } from "./Audit/InstallationAuditViewModel";
interface TabAdminProps {
    sensorGains: number[];
    deviceCommands: InstallationCommand[];
    deviceCommandHistory: InstallationCommandHistoryDTO[];
    commandNotes: InstallationCommandNoteDTO[];
    deviceId: number;
    simId: string;
    deviceStatusData: InstallationStatusDataDTO | undefined;

    addCommand: (newCommand: InstallationCommand) => any;
    updateCommandHistory: (newCommands: InstallationCommandHistoryDTO[], commands: InstallationCommand[]) => void;

    showCommandNoteModal: (view: number) => void;
}

export function TabAdmin(props: TabAdminProps) {
    const { deviceCommands, deviceCommandHistory, addCommand, sensorGains, showCommandNoteModal, commandNotes, deviceId, simId, updateCommandHistory } = props;

    const [viewModel] = useState(() => InstallationCommandViewModel.Instance);

    const [commands, setCommands] = useState<InstallationCommand[]>([]);
    const [commandHistory, setCommandHistory] = useState<InstallationCommandHistoryDTO[]>([]);
    const [notes, setNotes] = useState<InstallationCommandNoteDTO[]>([]);
    const tableOptions = Defaults.GetRoofcareTableOptions() as any;

    const [showAll, setShowAll] = useState(false);

    useEffect(() => {
        // tidy up after
        return () => {};
    }, []);

    useEffect(() => {
        setCommands(deviceCommands);
    }, [deviceCommands]);

    useEffect(() => {
        setCommandHistory(deviceCommandHistory);
    }, [deviceCommandHistory]);

    useEffect(() => {
        setNotes(commandNotes);
    }, [commandNotes]);

    const table: InstallationCommandTable = viewModel.getOptions();
    const options: InstallationCommandSection[] = table.sections.filter((a) => a.display === true);

    const listItems = options.map((item) => {
        let commands: InstallationCommand[] = deviceCommands.filter((a) => a.commandId === item.sectionId);

        return (
            <>
                <InstallationCommandSectionWrapper
                    key={generateID()}
                    addCommand={addCommand}
                    deviceCommands={commands}
                    section={item}
                    sensorGains={sensorGains}
                    simId={props.simId}
                />
            </>
        );
    });

    const handleNewClicked = (): void => {
        showCommandNoteModal(-1);
    };

    const handleRowClicked = (event: any, rowData: InstallationCommandNoteDTO): void => {
        showCommandNoteModal(rowData.id);
    };

    const addCommandHistory = (newCommands: InstallationCommandHistoryDTO[], commands: InstallationCommand[]): any => {
        updateCommandHistory(newCommands, commands);
        InstallationAuditViewModel.Instance.reset();
    };

    const btnClass: string = showAll == false ? "notselected" : "selected";

    return useObserver(() => (
        <>
            <InstallationCommandWrapper className="guttersens-admin-devicecontrols">
                <ConfigRow>
                    <DeviceHeader>
                        <Typography variant="h1" className="sectionTitle">
                            Admin Commands
                        </Typography>
                    </DeviceHeader>
                    <CommandSection addCommandHistory={addCommandHistory} simId={simId} deviceId={deviceId} />
                </ConfigRow>

                <ConfigRow>
                    <DeviceHeader>
                        <Typography variant="h1" className="sectionTitle">
                            Device Command History
                        </Typography>
                    </DeviceHeader>
                    <RoofcareTableBox>
                        <RoofcareTable
                            columns={[
                                {
                                    title: "Date",
                                    field: "timestamp",
                                    type: "date",
                                    customSort: (a: InstallationCommandHistoryDTO, b: InstallationCommandHistoryDTO) =>
                                        sortByMoment(moment.utc(a.timestamp), moment.utc(b.timestamp)),
                                    filtering: false,
                                    render: (rowData: InstallationCommandHistoryDTO) => <Box>{formatDateTime(rowData.timestamp)}</Box>,
                                },
                                { title: "Command", field: "command", sorting: true, filtering: false },
                                {
                                    title: "Author",
                                    field: "author",
                                    sorting: true,
                                    filtering: false,
                                },
                            ]}
                            data={commandHistory}
                            options={{
                                thirdSortClick: false,
                                sorting: true,
                                filtering: false,
                                search: false,
                                toolbar: false,
                                cellStyle: {
                                    fontSize: 10,
                                },
                                headerStyle: {
                                    background: " #495D89 0% 0% no-repeat padding-box",
                                    boxShadow: " inset 0px 1px 1px #FFFFFF40",
                                    color: "#fff",
                                    border: "unset",
                                    fontFamily: ["Mont-Bold", "Montserrat", "Roboto Condensed", "Helvetica", "Arial", "sans-serif"].join(","),
                                },
                                rowStyle: {
                                    backgroundColor: "#525355",
                                    color: "#fff",
                                    fontSize: 24,
                                },
                                pageSize: 50,
                                pageSizeOptions: [10, 20, 50, 100],
                                emptyRowsWhenPaging: false,
                                tableLayout: "fixed",
                            }}
                            components={{ Container: (props: any) => <Paper {...props} elevation={0} /> }}
                            title=""
                        />
                    </RoofcareTableBox>
                </ConfigRow>
            </InstallationCommandWrapper>

            <ConfigRow>
                <DeviceHeader>
                    <Typography variant="h1" className="sectionTitle">
                        {showAll === false ? <span>Unsent Commands</span> : <span>All Commands</span>}
                    </Typography>
                </DeviceHeader>
                <DeviceAdminRow>
                    <div className="slider-text">Show all Commands:</div>
                    <AlertSwitchOption id={"showlines-select-"} className={btnClass} onClick={() => setShowAll(!showAll)} />
                </DeviceAdminRow>

                <DeviceUnsentSection commands={commands} showAll={showAll} updateShowAll={setShowAll} />
            </ConfigRow>

            <ConfigRow>
                <DeviceHeader>
                    <Grid container direction="row" spacing={1}>
                        <Grid item xs={11} className="column-title">
                            <Typography variant="h1" className="sectionTitle">
                                Command Notes
                            </Typography>
                        </Grid>
                        <Grid item xs={1} className="column-title" style={{ marginRight: "-30px" }}>
                            <Addeditsection onClick={handleNewClicked} title="Add new note">
                                <AddNewBtn>
                                    <div className="btnText">Add new note</div>
                                </AddNewBtn>
                            </Addeditsection>
                        </Grid>
                    </Grid>
                </DeviceHeader>
                <RoofcareTableBox>
                    <RoofcareTable
                        columns={[
                            {
                                title: "Date",
                                field: "publishDate",
                                type: "date",
                                customSort: (a: InstallationCommandNoteDTO, b: InstallationCommandNoteDTO) => sortByMoment(moment.utc(a.publishDate), moment.utc(b.publishDate)),
                                filtering: false,
                                render: (rowData: InstallationCommandNoteDTO) => <Box>{formatDateTime(rowData.publishDate)}</Box>,
                            },
                            {
                                title: "Author",
                                field: "authorId",
                                sorting: true,
                                filtering: false,
                            },
                            { title: "Note", field: "note", sorting: true, filtering: false },
                        ]}
                        data={notes}
                        onRowClick={handleRowClicked}
                        options={{
                            thirdSortClick: false,
                            sorting: true,
                            filtering: false,
                            search: false,
                            toolbar: false,
                            cellStyle: {
                                fontSize: 10,
                            },
                            pageSize: 50,
                            pageSizeOptions: [10, 20, 50, 100],
                            emptyRowsWhenPaging: false,
                            headerStyle: {
                                ...tableOptions.headerStyle,
                            },
                            rowStyle: {
                                ...tableOptions.rowStyle,
                            },
                            tableLayout: "fixed",
                        }}
                        components={{ Container: (props: any) => <Paper {...props} elevation={0} /> }}
                        title=""
                    />
                </RoofcareTableBox>
                {notes.length > 0 && (
                    <Grid container direction="row" spacing={1} style={{ marginTop: "5px" }}>
                        <Grid item xs={1} className="column-title">
                            <Addeditsection onClick={handleNewClicked} title="Add new note">
                                <AddNewBtn>
                                    <div className="btnText">Add new note</div>
                                </AddNewBtn>
                            </Addeditsection>
                        </Grid>
                        <Grid item xs={11} className="column-title"></Grid>
                    </Grid>
                )}
            </ConfigRow>
        </>
    ));
}
