import styled from "styled-components";
import { Box, Link } from "@material-ui/core";

export const BoxAccount: any = styled(Box)`
    padding: 15px;
    margin: auto;
    width: 410px;
    .login-logo {
        max-width: 420px;
        height: auto;
    }
`;

export const LoginForgotPasswordLink = styled(Link)`
    text-align: left;
    text-decoration: underline;
    font: normal normal normal 12px/26px "Mont-Regular";
    letter-spacing: 0px;
    color: white;
    opacity: 1;
    cursor: pointer;
`;

export const LoginForgotPasswordBox: any = styled(Box)`
    text-align: left;
`;

export const LogonTitle: any = styled(Box)`
    color: #ffffff;
    text-align: left;
    font: 42px "Mont-Regular";
    letter-spacing: -0.56px;
    opacity: 1;
`;
