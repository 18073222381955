import { useObserver } from "mobx-react-lite";
import { Box, Paper, Theme, Typography, useMediaQuery } from "@material-ui/core";
import { DeviceCommandSectionBox } from "./DeviceSection.style";
import { useEffect, useState } from "Core/Base";
import { InstallationCommand } from "Custom/Models/Domain";
import { RoofcareTable, RoofcareTableBox } from "Custom/Components/Table/RoofcareTable";
import { formatBooleanYesNo, formatDateTime } from "Custom/Utils/format";
import { sortByMoment } from "Core/Utils/Utils";
import moment from "moment";

interface IDeviceUnsentSectionProps {
    commands: InstallationCommand[];
    showAll: boolean;
    updateShowAll: (showAll: boolean) => void;
}

export function DeviceUnsentSection(props: IDeviceUnsentSectionProps) {
    const { commands, showAll, updateShowAll } = props;
    const desktopScreen: boolean = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));
    const mobileScreen: boolean = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

    const [isBtnDisabled, setIsBtnDisabled] = useState<boolean | undefined>(false);

    const [unsentCommands, setUnsentCommands] = useState<InstallationCommand[]>([]);

    useEffect(() => {
        return () => {
            // Clean up after yourself
        };
    }, []);

    useEffect(() => {
        if (showAll === false) {
            setUnsentCommands(commands.filter((a) => a.completed === false));
        } else {
            setUnsentCommands(commands);
        }
    }, [commands, showAll]);

    let columns = [
        {
            title: "Added",
            field: "value",
            type: "text",
            customSort: (a: InstallationCommand, b: InstallationCommand) => sortByMoment(moment.utc(a.timestamp), moment.utc(b.timestamp)),
            filtering: false,
            render: (rowData: InstallationCommand) => <Box>{rowData.timestamp === undefined ? "" : formatDateTime(rowData.timestamp)}</Box>,
        },
        {
            title: "Command Id",
            field: "commandItem",
            type: "text",
            sorting: true,
            filtering: false,
            render: (rowData: InstallationCommand) => <Box>{rowData.commandItem}</Box>,
        },
        {
            title: "Command Value",
            field: "value",
            type: "text",
            sorting: true,
            filtering: false,
            render: (rowData: InstallationCommand) => <Box>{rowData.value}</Box>,
        },
    ];

    if (showAll === true) {
        columns.push({
            title: "Sent / Superseded",
            field: "completed",
            type: "text",
            sorting: true,
            filtering: false,
            render: (rowData: InstallationCommand) => <Box>{rowData.sent === undefined ? "" : formatBooleanYesNo(rowData.completed)}</Box>,
        });
        columns.push({
            title: "Sent",
            field: "sent",
            type: "text",
            customSort: (a: InstallationCommand, b: InstallationCommand) => sortByMoment(moment.utc(a.sent), moment.utc(b.sent)),
            filtering: false,
            render: (rowData: InstallationCommand) => <Box>{rowData.sent === undefined ? "" : formatDateTime(rowData.sent)}</Box>,
        });
    }

    return useObserver(() => (
        <>
            <RoofcareTableBox>
                <RoofcareTable
                    columns={columns}
                    data={unsentCommands}
                    options={{
                        thirdSortClick: false,
                        sorting: true,
                        filtering: false,
                        search: false,
                        toolbar: false,
                        cellStyle: {
                            fontSize: 10,
                        },
                        headerStyle: {
                            background: " #495D89 0% 0% no-repeat padding-box",
                            boxShadow: " inset 0px 1px 1px #FFFFFF40",
                            color: "#fff",
                            border: "unset",
                            fontFamily: ["Mont-Bold", "Montserrat", "Roboto Condensed", "Helvetica", "Arial", "sans-serif"].join(","),
                        },
                        rowStyle: {
                            backgroundColor: "#525355",
                            color: "#fff",
                            fontSize: 24,
                        },
                        pageSize: 20,
                        pageSizeOptions: [10, 20, 50, 100],
                        emptyRowsWhenPaging: false,
                        tableLayout: "fixed",
                    }}
                    components={{ Container: (props: any) => <Paper {...props} elevation={0} /> }}
                    title=""
                />
            </RoofcareTableBox>
        </>
    ));
}
