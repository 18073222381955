import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { BoxplotBox } from "./BoxplotGraph.style";
import * as d3 from "d3";
//import { bisector, extent, max, min } from 'd3-array';
//@ts-ignore no TS files yet
import { AxisLeft } from "@visx/axis";
//@ts-ignore no TS files yet
//import { localPoint } from '@visx/event';
//@ts-ignore no TS files yet
//import { LinearGradient } from '@visx/gradient';
//@ts-ignore no TS files yet
import { Grid } from "@visx/grid";
//@ts-ignore no TS files yet
import { Group } from "@visx/group";
//@ts-ignore no TS files yet
import { Point } from "@visx/point";
//@ts-ignore no TS files yet
import { scaleUtc, scaleLinear, coerceNumber, TimeDomain } from "@visx/scale";
//@ts-ignore no TS files yet
import { Bar, Line } from "@visx/shape";
//@ts-ignore no TS files yet
//import { Text } from '@visx/text';
//@ts-ignore no TS files yet
//import { withTooltip, TooltipWithBounds } from '@visx/tooltip';
//@ts-ignore no TS files yet
import { BoxPlot } from "@visx/stats";
//@ts-ignore no TS files yet
//import { GlyphDot } from "@visx/glyph";
//@ts-ignore no TS files yet
//import { useTooltip, useTooltipInPortal, defaultStyles } from "@visx/tooltip";

import { BottomAxis } from "./BottomAxis";
import { AverageSensorValue, DrainageType, InstallationStatusDataDTO, InstallationWorkingData } from "Custom/Models/Domain/Installations";
import { generateID, sortByStringDates } from "Core/Utils/Utils";
import { DeviceAlertActionModelDTO, DeviceConditionItemModelDTO, DeviceConditionSetModelDTO } from "Custom/Views/Installations/TabConfigurationModel";
import { generateStatusWorkingData } from "Custom/Utils/installation";
import { GraphLegend, GraphLegendItem } from ".";
import { DEFAULTCELLCOLOUR, FLOODCOLOUR } from "Custom/Globals/Globals";

export interface BoxplotGraphProps {
    graphHeight: number;
    graphWidth: number;
    marginTop: number;
    maxWidth: string;
    readings: AverageSensorValue[];
    deviceStatusData: InstallationStatusDataDTO | undefined;
    conditionSet: DeviceConditionSetModelDTO | undefined;
    showStatusLines: boolean; // H, FB, p1, p2
    alertAction: DeviceAlertActionModelDTO | undefined;
    showAlertLines: boolean;
}

export type GraphNode = {
    date: Date;
    median: number;
    minValue: number;
    maxValue: number;
};

export interface LineGraphState {
    width: number;
    height: number;
}

export function BoxplotGraph(props: BoxplotGraphProps) {
    const DEFAULTOUTSIDECOLOUR: string = DEFAULTCELLCOLOUR;
    const { alertAction, readings, graphHeight, graphWidth, marginTop, maxWidth, deviceStatusData, conditionSet, showStatusLines, showAlertLines } = props;
    const axisColor: string = "#ffffff";
    let showOutOfRange: boolean = true;
    let installHeight: number = 0;
    let workingData: InstallationWorkingData | undefined = undefined;

    if (deviceStatusData !== null && deviceStatusData !== undefined) {
        installHeight = deviceStatusData.install_Height;
        workingData = generateStatusWorkingData(deviceStatusData);
    }

    let opacityLevel: number = 1;
    // let lineColour: string = "#ffffff";
    let lineColour: string = "#000000";
    let lineWidth: string = "2";

    if (showAlertLines === true || showStatusLines === true) {
        // opacityLevel = 0.3;
        opacityLevel = 1;
    }

    const graphBottomTickLabelProps: any = (val: any, i: any) => ({
        dy: "0.25em",
        textAnchor: "middle",
        fontFamily: "Lato,Verdana,Helvetica,Arial,sans-serif",
        fontSize: 10,
        fill: axisColor,
    });

    const graphLeftTickLabelProps: any = (val: any, i: any) => ({
        dx: "-1.5em",
        dy: "0.25em",
        textAnchor: "middle",
        fontFamily: "Lato,Verdana,Helvetica,Arial,sans-serif",
        fontSize: 10,
        fill: axisColor,
    });

    let dataset: GraphNode[] = [];

    let sortedReadings: AverageSensorValue[] = readings.slice(0).sort((a: AverageSensorValue, b: AverageSensorValue) => {
        return sortByStringDates(a.readingDate, b.readingDate);
    });

    for (let i: number = 0; i < sortedReadings.length; i++) {
        let value: number | undefined = sortedReadings[i].calculatedValue;

        dataset.push({
            date: new Date(sortedReadings[i].readingDate),
            median: value,
            minValue: sortedReadings[i].minValue > 0 ? sortedReadings[i].minValue : 0,
            maxValue: sortedReadings[i].maxValue,
        });
    }

    // accessors
    const xSelector = (d: GraphNode) => d.date;

    const marginLeft: number = 65;
    const marginBottom: number = 35;

    const xMax = graphWidth - marginLeft;
    const yMax = graphHeight - marginBottom;

    const tDomain: TimeDomain = dataset.map((a) => a.date);

    const getMinMax = (vals: (number | { valueOf(): number })[]) => {
        const numericVals = vals.map(coerceNumber);
        return [Math.min(...numericVals), Math.max(...numericVals)];
    };

    const xScale = scaleUtc({
        range: [0, xMax],
        domain: getMinMax(tDomain),
        nice: true,
    });

    let data: number[] = dataset.map((a) => a.median);
    let mins: number[] = dataset.map((a) => a.minValue);
    let maxes: number[] = dataset.map((a) => a.maxValue);

    //let showFlood: boolean = false;
    if (workingData !== undefined) {
        data.push(workingData.working_Height + 10);
        //showFlood = true;
    }

    if (showStatusLines && workingData !== undefined) {
        //data.push(workingData.working_Height + 5);
        data.push(workingData.freeboard_Height);

        if (workingData.drainageType > DrainageType.Gravity) {
            data.push(workingData.p1);

            if (workingData.drainageType == DrainageType.DualSiphonic) {
                data.push(workingData.p2);
            }
        }
    }

    if (showAlertLines && alertAction !== undefined) {
        for (let i: number = 0; i < alertAction.items.length; i++) {
            data.push(alertAction.items[i].calculatedValue);
        }
    }

    let sortedConditionItems: DeviceConditionItemModelDTO[] | undefined = undefined;

    const showBands: boolean =
        props.conditionSet !== null &&
        props.conditionSet !== undefined &&
        props.conditionSet.items !== null &&
        props.conditionSet.items !== undefined &&
        props.conditionSet.items.length > 0;

    if (showBands === true) {
        let retVal = props.conditionSet!.items.slice().sort((a: DeviceConditionItemModelDTO, b: DeviceConditionItemModelDTO) => {
            if (a === undefined && b === undefined) {
                return 0;
            }

            if (a === undefined) {
                return -1;
            }

            if (b === undefined) {
                return 1;
            }

            return a.calculatedValue < b.calculatedValue ? -1 : 1;
        });

        for (let i: number = 0; i < retVal.length; i++) {
            if (retVal[i].calculatedValue <= 0) {
                // if the conditionset value is 0 or negative, we don't need the grey section
                showOutOfRange = false;
            }
            data.push(retVal[i].calculatedValue);
        }

        sortedConditionItems = retVal;
    }

    if (deviceStatusData !== null && deviceStatusData !== undefined && workingData !== null && workingData !== undefined) {
        // We need to add the flood point in

        let flood: DeviceConditionItemModelDTO = {
            id: null,
            rowVersion: null,
            isDeleted: false,
            createdBy: null,
            createdDate: null,

            name: "Flood",
            type: 0,
            value: 0,
            units: 0,
            deviceConditionSetId: null,
            commandIndex: 5,
            statusColour: FLOODCOLOUR,
            statusTextColour: "#ffffff",
            calculatedValue: workingData.working_Height,
        };
        sortedConditionItems?.push(flood);
    }

    data = data.concat(maxes);
    data = data.concat(mins);

    let numberData: number[] = [];
    for (let i: number = 0; i < data.length; i++) {
        if (data[i] !== undefined) {
            numberData.push(data[i]!);
        }
    }

    let maxValue = Math.max.apply(null, numberData);
    let minValue = Math.min.apply(null, numberData);

    let range = (Math.abs(maxValue - minValue) * 0.05) / 2;
    maxValue += range;
    minValue -= range;

    // Force 0 to by at bottom axis.
    if (minValue < 0) {
        minValue = 0;
    }

    const yScale = scaleLinear({
        range: [yMax, 0],
        domain: [0, maxValue],
        nice: true,
    });

    const series = [dataset];
    let gridRowCount: number = 5;

    const x0 = (d: GraphNode) => d.date;
    const min = (d: GraphNode) => d.minValue;
    const max = (d: GraphNode) => d.maxValue;
    const median = (d: GraphNode) => d.median;

    const hasData: boolean = readings.length > 0;
    const topOfGraph = 5;

    let longestword: number = 0;
    if (alertAction !== null && alertAction !== undefined) {
        for (let i: number = 0; i < alertAction.items.length; i++) {
            let temp: string = alertAction.items[i].name + " (" + alertAction.items[i].calculatedValue.toFixed(0) + "mm)";
            if (temp.length > longestword) {
                longestword = temp.length;
            }
        }
    }

    const alertTextOffset: number = longestword * 4.25;

    return (
        <>
            {hasData && (
                <div className="table-guttersens-averageheightgraph">
                    <GraphLegend paddingLeft={marginLeft.toString() + "px"}>
                        {showOutOfRange === true && (
                            <GraphLegendItem backgroundColor={DEFAULTOUTSIDECOLOUR} textColor="#000000" opacity={opacityLevel}>
                                <div className="band-box"></div>
                                <div className="band-text">Base Condition (0 mm)</div>
                            </GraphLegendItem>
                        )}
                        {sortedConditionItems !== null &&
                            sortedConditionItems !== undefined &&
                            sortedConditionItems!.map((condition) => {
                                return (
                                    <GraphLegendItem backgroundColor={condition.statusColour} textColor={condition.statusTextColour} opacity={opacityLevel}>
                                        <div className="band-box"></div>
                                        <div className="band-text">
                                            {condition.name} ({condition.calculatedValue.toFixed(0)} mm)
                                        </div>
                                    </GraphLegendItem>
                                );
                            })}
                    </GraphLegend>
                    <svg xmlns="http://www.w3.org/2000/svg" height={graphHeight + marginTop} width={graphWidth + marginLeft}>
                        <rect fill="#565758" height={graphHeight + marginTop} rx={14} width={graphWidth + marginLeft} x={0} y={0} />
                        {!showBands && (
                            <Group top={topOfGraph} key={generateID()}>
                                <rect key={generateID()} fill="#DADADA" fillOpacity="0.2" height={yMax} width={graphWidth - marginLeft} x={marginLeft} y={0} />
                            </Group>
                        )}

                        <Group left={0} top={topOfGraph} z-index={0}>
                            <Grid height={yMax} left={marginLeft} numTicksColumns={10} numTicksRows={10} top={0} width={xMax} xScale={xScale} yScale={yScale} />

                            <AxisLeft
                                label={"Average Height (mm)"}
                                labelClassName={"graphaxistext"}
                                left={marginLeft}
                                numTicks={gridRowCount}
                                scale={yScale}
                                stroke={axisColor}
                                tickStroke={axisColor}
                                tickFormat={(d: any) => d.toFixed(0)}
                                tickLabelProps={graphLeftTickLabelProps}
                                top={0}
                                labelProps={{
                                    dx: "-0.5em",
                                    dy: "0.25em",
                                    textAnchor: "middle",
                                    fontSize: 10,
                                    fill: axisColor,
                                }}
                            />
                            <BottomAxis
                                label={"Date"}
                                labelClassName={"graphaxistext"}
                                left={marginLeft}
                                marginLeft={marginLeft}
                                showLegend={false}
                                stroke={axisColor}
                                tickLabelProps={graphBottomTickLabelProps}
                                tickTextFill={axisColor}
                                top={yMax}
                                width={graphWidth}
                                xScale={xScale}
                                showTime={false}
                                xLegendOffset={30}
                            />
                        </Group>
                        {showBands && (
                            <Group top={topOfGraph}>
                                {/* We know conditionSet is not undefined else showBands would be false */}
                                {showOutOfRange === true && (
                                    <rect
                                        key={generateID()}
                                        fill={DEFAULTOUTSIDECOLOUR}
                                        fillOpacity={opacityLevel}
                                        height={yMax - yScale(sortedConditionItems![0].calculatedValue)}
                                        width={graphWidth - marginLeft}
                                        x={marginLeft}
                                        y={yScale(sortedConditionItems![0].calculatedValue)}
                                    />
                                )}

                                <rect
                                    key={generateID()}
                                    fill={sortedConditionItems![0].statusColour}
                                    fillOpacity={opacityLevel}
                                    height={
                                        yScale(sortedConditionItems![0].calculatedValue) - (sortedConditionItems!.length > 1 ? yScale(sortedConditionItems![1].calculatedValue) : 0)
                                    }
                                    width={graphWidth - marginLeft}
                                    x={marginLeft}
                                    y={sortedConditionItems!.length > 1 ? yScale(sortedConditionItems![1].calculatedValue) : 0}
                                />
                                {sortedConditionItems!.length > 1 && (
                                    <rect
                                        key={generateID()}
                                        fill={sortedConditionItems![1].statusColour}
                                        fillOpacity={opacityLevel}
                                        height={
                                            yScale(sortedConditionItems![1].calculatedValue) -
                                            (sortedConditionItems!.length > 2 ? yScale(sortedConditionItems![2].calculatedValue) : 0)
                                        }
                                        width={graphWidth - marginLeft}
                                        x={marginLeft}
                                        y={sortedConditionItems!.length > 2 ? yScale(sortedConditionItems![2].calculatedValue) : 0}
                                    />
                                )}
                                {sortedConditionItems!.length > 2 && (
                                    <rect
                                        key={generateID()}
                                        fill={sortedConditionItems![2].statusColour}
                                        fillOpacity={opacityLevel}
                                        height={
                                            yScale(sortedConditionItems![2].calculatedValue) -
                                            (sortedConditionItems!.length > 3 ? yScale(sortedConditionItems![3].calculatedValue) : 0)
                                        }
                                        width={graphWidth - marginLeft}
                                        x={marginLeft}
                                        y={sortedConditionItems!.length > 3 ? yScale(sortedConditionItems![3].calculatedValue) : 0}
                                    />
                                )}
                                {sortedConditionItems!.length > 3 && ( //There can only be 4 max so set it to y of 0
                                    <rect
                                        key={generateID()}
                                        fill={sortedConditionItems![3].statusColour}
                                        fillOpacity={opacityLevel}
                                        height={
                                            yScale(sortedConditionItems![3].calculatedValue) -
                                            (sortedConditionItems!.length > 4 ? yScale(sortedConditionItems![4].calculatedValue) : 0)
                                        }
                                        width={graphWidth - marginLeft}
                                        x={marginLeft}
                                        y={sortedConditionItems!.length > 4 ? yScale(sortedConditionItems![4].calculatedValue) : 0}
                                    />
                                )}
                                {sortedConditionItems!.length > 4 && ( //There can only be 4 max so set it to y of 0
                                    <rect
                                        key={generateID()}
                                        fill={sortedConditionItems![4].statusColour}
                                        fillOpacity={opacityLevel}
                                        height={yScale(sortedConditionItems![4].calculatedValue)}
                                        width={graphWidth - marginLeft}
                                        x={marginLeft}
                                        y={0}
                                    />
                                )}
                            </Group>
                        )}
                        {showStatusLines && workingData !== undefined && (
                            <Group top={topOfGraph} key={generateID()}>
                                <Line
                                    key={generateID()}
                                    className="level-line floodWarningLevel"
                                    from={new Point({ x: marginLeft, y: yScale(workingData.working_Height) })}
                                    stroke={lineColour}
                                    strokeWidth={lineWidth}
                                    to={new Point({ x: graphWidth, y: yScale(workingData.working_Height) })}
                                />
                                <text fill={lineColour} fontSize="8" textAnchor="middle" transform={`translate(${marginLeft + 20} , ${yScale(workingData.working_Height + 1)})`}>
                                    {"H (" + workingData.working_Height.toFixed(0) + "mm)"}
                                </text>
                                <Line
                                    key={generateID()}
                                    className="level-line floodWarningLevel"
                                    from={new Point({ x: marginLeft, y: yScale(workingData.freeboard_Height) })}
                                    stroke={lineColour}
                                    strokeWidth={lineWidth}
                                    to={new Point({ x: graphWidth, y: yScale(workingData.freeboard_Height) })}
                                />
                                <text fill={lineColour} fontSize="8" textAnchor="middle" transform={`translate(${marginLeft + 21} , ${yScale(workingData.freeboard_Height + 1)})`}>
                                    {"FB (" + workingData.freeboard_Height.toFixed(0) + "mm)"}
                                </text>

                                {workingData.drainageType > DrainageType.Gravity && (
                                    <>
                                        <Line
                                            key={generateID()}
                                            className="level-line floodWarningLevel"
                                            from={new Point({ x: marginLeft, y: yScale(workingData.p1) })}
                                            stroke={lineColour}
                                            strokeWidth={lineWidth}
                                            to={new Point({ x: graphWidth, y: yScale(workingData.p1) })}
                                        />
                                        <text fill={lineColour} fontSize="8" textAnchor="middle" transform={`translate(${marginLeft + 20} , ${yScale(workingData.p1 + 1)})`}>
                                            {"P1 (" + workingData.p1.toFixed(0) + "mm)"}
                                        </text>
                                        {workingData.drainageType === DrainageType.DualSiphonic && (
                                            <>
                                                <Line
                                                    key={generateID()}
                                                    className="level-line floodWarningLevel"
                                                    from={new Point({ x: marginLeft, y: yScale(workingData.p2) })}
                                                    stroke={lineColour}
                                                    strokeWidth={lineWidth}
                                                    to={new Point({ x: graphWidth, y: yScale(workingData.p2) })}
                                                />
                                                <text
                                                    fill={lineColour}
                                                    fontSize="8"
                                                    textAnchor="middle"
                                                    transform={`translate(${marginLeft + 20} , ${yScale(workingData.p2 + 1)})`}
                                                >
                                                    {"P2 (" + workingData.p2.toFixed(0) + "mm)"}
                                                </text>
                                            </>
                                        )}
                                    </>
                                )}
                            </Group>
                        )}

                        {showAlertLines && alertAction !== undefined && (
                            <Group top={topOfGraph} key={generateID()}>
                                {alertAction.items.map((item, index) => {
                                    return (
                                        <>
                                            <Line
                                                key={generateID()}
                                                className="level-line floodWarningLevel"
                                                from={new Point({ x: marginLeft, y: yScale(item.calculatedValue) })}
                                                stroke={lineColour}
                                                strokeWidth={lineWidth}
                                                strokeDasharray={"3 3"}
                                                to={new Point({ x: graphWidth, y: yScale(item.calculatedValue) })}
                                            />
                                            <text
                                                fill={lineColour}
                                                fontSize="8"
                                                textAnchor="left"
                                                transform={`translate(${graphWidth - alertTextOffset} , ${yScale(item.calculatedValue + 1)})`}
                                            >
                                                {item.name + " (" + item.calculatedValue.toFixed(0) + "mm)"}
                                            </text>
                                        </>
                                    );
                                })}
                            </Group>
                        )}

                        <Group top={topOfGraph}>
                            {xMax > 8 &&
                                dataset.map((d, i) => {
                                    return (
                                        <>
                                            <BoxPlot
                                                key={generateID()}
                                                boxWidth={10}
                                                fill="#FFFFFF"
                                                fillOpacity={0.3}
                                                firstQuartile={median(d)}
                                                left={xScale(xSelector(d))! + marginLeft - 5}
                                                max={max(d)}
                                                median={median(d)}
                                                min={min(d)}
                                                stroke="#FFFFFF"
                                                strokeWidth={2}
                                                thirdQuartile={median(d)}
                                                valueScale={yScale}
                                            />
                                        </>
                                    );
                                })}
                        </Group>
                    </svg>
                </div>
            )}
            {!hasData && <div>There is currently no data to show.</div>}
        </>
    );
}
