import styled from "styled-components";
import { Box } from "@material-ui/core";

export const LatestImagesBox: any = styled(Box)`
    display: flex;
    padding: 15px 15px;
    width: 100%;
    flex-direction: column;

    .rotateimg180 {
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        transform: rotate(180deg);
    }

    /* background-color: pink; */

    .device-latest-image {
        display: flex;
        flex: 1 0 auto;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;

        /* background-color: orange; */

        .device-latest-image-cells {
            display: flex;
            flex: 1 0 auto;
            width: 100%;
            flex-direction: row;

            /* background-color: red; */

            .main-image {
                max-width: 100%;
                width: 100%;
            }
        }
    }

    .device-other-images {
        margin-top: 15px;
        display: flex;
        flex: 1 0 auto;
        flex-direction: row;
        justify-content: flex-start;
        width: 100%;

        .selectable-image {
            max-width: 95px;
            padding-right: 10px;

            img {
                max-width: 85px;
            }
        }
    }
`;
