import { ApiResultBasicError, ApiResultErrorType } from "./../../../Core/Models/ApiResultError";
import { ViewModelBase } from "Core/ViewModels/ViewModelBase";
import { BlankModel } from "Core/Models/BlankModel";
import { FieldType } from "Core/Utils/Utils";
import { action, observable, runInAction } from "mobx";
import { trim } from "lodash-es";
import { InstallationCommandSimple } from "Custom/Models/Domain/Installations/InstallationCommandSimple";
import { Server } from "Custom/Globals/AppUrls";
import { ApiResult } from "Core/Models";
import { InstallationCommandHistoryResultDTO } from "Custom/Models/Domain/Installations/InstallationCommandHistoryResult";

// extend viewmodel base and passing your model as the generic type
export class CommandSectionViewModel extends ViewModelBase<BlankModel> {
    // Singleton instance of class
    private static _instance: CommandSectionViewModel;
    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    @observable public errorMessage: string = "";
    @observable public showError: boolean = false;
    @observable public showSuccess: boolean = false;
    @observable public showAPIError: boolean = false;

    constructor() {
        // Pass in a new instance of your model
        // By passing in true as the second parameter, we make this model undoable which means we can use save and reset options on the model
        // If you make a change to the model you need to persist it with a saveModel() call
        // If you make changes to your model you can revert it back by calling resetModel()
        super(new BlankModel(), false);
        // EN - Haven't figured out how to make this call work from the base model yet
        // This is only needed if you make use of the validation decorators
        this.setDecorators(BlankModel);
    }

    //isValid will check all fields to make sure they are in a valid state.
    public doSubmit = async (e: any) => {
        e.preventDefault();

        if (this.isModelValid()) {
            // Do stuff here
            this.errorMessage = "Form is valid";
        } else {
            this.errorMessage = "Form is not valid";
        }
    };

    // This must be present in your viewmodel. Just return true if you dont need to validate anything.
    // keyof BlankModel & string lets you add type checking to the fieldName
    // I am using the validator package to make checking easier https://www.npmjs.com/package/validator
    public isFieldValid(fieldName: keyof FieldType<BlankModel>): boolean {
        const { isValid, errorMessage } = this.validateDecorators(fieldName);

        // You need to this two properties after validation
        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    @action
    public submit = async (value: string, simId: string, deviceId: string): Promise<ApiResult<InstallationCommandHistoryResultDTO>> => {
        const regex = /^(?:[a-z]|[A-Z])+\d*=\d+/;
        const filterRegEx = new RegExp(regex, "i");
        let apiResult: ApiResult<InstallationCommandHistoryResultDTO> = {
            wasSuccessful: false,
            errors: [],
            headers: "",
            payload: {
                commandHistory: [],
                commands: [],
            },
        };

        if (value === undefined || value === null || value.length === 0) {
            this.showError = true;
            this.errorMessage = "A value is required";
            apiResult.errors = [
                {
                    type: ApiResultErrorType.Basic,
                    message: "A value is required",
                },
            ];
        } else {
            let valid: boolean = true;

            const splitCommands: string[] = value.split(",");

            for (let i: number = 0; i < splitCommands.length; i++) {
                const sect: string = trim(splitCommands[i]);

                const result: boolean = filterRegEx.test(sect);

                if (false === result) {
                    valid = false;
                    this.showError = true;
                    this.errorMessage = "Invalid command (" + sect + ")";
                    apiResult.errors = [
                        {
                            type: ApiResultErrorType.Basic,
                            message: "Invalid command (" + sect + ")",
                        },
                    ];
                    break;
                }
            }

            if (true === valid) {
                // Erik has requested I don't store the full string, but as individual components, so the
                // device API doesn't need changing.

                let request: InstallationCommandSimple = {
                    command: value,
                    simId: simId,
                    deviceId: deviceId,
                };

                apiResult = await this.Post<InstallationCommandHistoryResultDTO>(Server.Api.Installation.upsertSimpleCommand, request);

                runInAction(() => {
                    if (apiResult.wasSuccessful === true) {
                        value = "";
                        this.showError = false;
                        this.errorMessage = "";
                        this.showSuccess = true;

                        setTimeout(() => {
                            runInAction(() => {
                                this.showSuccess = false;
                            });
                        }, 3000);
                    } else {
                        this.showAPIError = true;
                        this.errorMessage = "Failed to store the value.";

                        apiResult.wasSuccessful = false;
                        apiResult.errors = [
                            {
                                type: ApiResultErrorType.Basic,
                                message: "Failed to store the value.",
                            },
                        ];

                        setTimeout(() => {
                            runInAction(() => {
                                this.showAPIError = false;
                            });
                        }, 5000);
                    }
                });
            }
        }

        return apiResult;
    };
}
