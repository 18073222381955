import * as React from "react";
import { useEffect } from "react";
import { MapContainer, TileLayer, useMap } from "react-leaflet";

const POSITION_CLASSES = {
    bottomleft: "leaflet-bottom leaflet-left",
    bottomright: "leaflet-bottom leaflet-right",
    topleft: "leaflet-top leaflet-left",
    topright: "leaflet-top leaflet-right",
};

export type MapControlPosition = keyof typeof POSITION_CLASSES;

interface IProps {
    position?: MapControlPosition;
}

export const MapControl: React.FC<IProps> = ({ position, children }) => {
    const parentMap = useMap();
    const minimap = React.useMemo(() => <div>{children}</div>, [children]);
    /* 
    useEffect(() => {
        return () => {
            // Clean up after yourself
        };
    }, []);

    const processclick = (event: any): any => {
        // Don't propogate the event to the document
        if (event.stopPropagation) {
            event.stopPropagation(); // W3C model
        } else {
            event.cancelBubble = true; // IE model
        }
    }; */

    const positionClass = (position && POSITION_CLASSES[position]) || POSITION_CLASSES.topleft;
    return (
        <div className={positionClass}>
            <div className="leaflet-control roofcontrol" /* onClick={processclick} */>{minimap}</div>
        </div>
    );
};
