import * as React from "react";
import * as Mui from "@material-ui/core";

import { ChangelogModalDialogPaper } from "./ChangelogModal.styles";
import { BaseModal } from "Custom/Components/Modal/BaseModal";

interface IProps {
    open?: boolean;
    onClose(): void;
    title: string;
}

export const ChangelogModal: React.FC<IProps> = ({ open, onClose, title, children }) => (
    <BaseModal
        open={open}
        onClose={onClose}
        title={title}
        PaperComponent={ChangelogModalDialogPaper}
        actions={
            <>
                <Mui.Button onClick={onClose} color="secondary" autoFocus variant="contained">
                    Close
                </Mui.Button>
            </>
        }
    >
        {children}
    </BaseModal>
);
