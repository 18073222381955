import { Box, Fade, Paper, useTheme } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import { Loader } from "Core/Components";
import { AdminAlertActionTabViewModel } from "./AdminAlertActionTabViewModel";
import { useObserver } from "mobx-react-lite";
import { AdminTabPageViewModel } from "./AdminTabPageViewModel";
import { Container } from "Custom/StylesAppSpecific/AppStyling";
import { ProjectListSearch } from "../Projects/ProjectListSearch";
import { AdminSearchWrapper } from "Custom/StylesAppSpecific/Controls/BoxAddWrapper";
import { ProjectButton } from "../CustomComponents";
import { AppUrls } from "Custom/Globals";
import { useRouter } from "Core/Base";
import { RoofcareTable, RoofcareTableWrapperFullWidth } from "Custom/Components/Table/RoofcareTable";
import { ResetSearchIcon } from "Custom/Components/Table/ResetSearchIcon";
import { DeleteCircle } from "./Condition/Condition.style";
import { DeleteModal } from "Custom/Components/Modal/DeleteModal";
import { AdminAlertActionTabModel } from "./AdminAlertActionTabModel";
import { DrainageType } from "Custom/Models/Domain";

import * as Defaults from "Custom/StylesAppSpecific/TableOptions";
interface IProps {
    parentViewModel: AdminTabPageViewModel;
}

export const AdminAlertActionTab: React.FunctionComponent<IProps> = ({ parentViewModel }) => {
    const [viewModel] = useState(() => AdminAlertActionTabViewModel.Instance);
    const { history } = useRouter();

    const [deleteOpen, setDeleteOpen] = React.useState(false);
    const [selectedSet, selectSet] = React.useState("");

    const handleDeleteOpen = () => {
        setDeleteOpen(true);
    };

    const handleDeleteClose = () => {
        setDeleteOpen(false);
        selectSet("");
    };

    const handleDeleteConditionSet = () => {
        viewModel.deleteAlertAction(selectedSet).then(() => {
            setDeleteOpen(false);
            selectSet("");
        });
    };

    //useEffect below only gets run on initial render
    useEffect(() => {
        // *optional* passing the router into the viewmodel so we can navigate from there
        // console.log("First render");

        //let promise =
        viewModel.loadList();

        // promise.then((result) => {});

        //This gets run when the page is exited
        return () => {
            // console.log("Unmounting");
            viewModel.clear();
        };
    }, []);

    const setSearchString = (value: string) => {
        viewModel.setSearchString(value);
        //viewModel.setPage(0);
    };

    const onDeleteAlertAction = (id: string) => {
        selectSet(id);
        handleDeleteOpen();
    };

    //onRowClick={(_: any, rowData: any) => handleRowClick(rowData)}

    const renderContent = () => {
        const tableOptions = Defaults.GetRoofcareTableOptions() as any;
        return (
            <>
                <DeleteModal
                    open={deleteOpen}
                    onClose={handleDeleteClose}
                    onDelete={handleDeleteConditionSet}
                    text="Do you want to continue to delete this alert action?"
                    title="Delete alert action?"
                />
                <RoofcareTableWrapperFullWidth key="condition-set-table" paddingTop="5px !important">
                    <RoofcareTable
                        columns={[
                            {
                                width: "auto",
                                title: "Alert action name",
                                field: "name",
                                sorting: true,
                                filtering: true,
                                render: (rowData: AdminAlertActionTabModel) => {
                                    if (rowData !== null && rowData !== undefined) {
                                        return <div onClick={() => handleRowClick(rowData)}>{rowData.name}</div>;
                                    }
                                },
                            },
                            {
                                width: "auto",
                                title: "Drainage Type",
                                field: "drainageType",
                                sorting: true,
                                filtering: true,
                                render: (rowData: AdminAlertActionTabModel) => {
                                    if (rowData !== null && rowData !== undefined) {
                                        let dtype: string = "Gravity";
                                        if (rowData.drainageType === DrainageType.Gravity) {
                                            dtype = "Gravity";
                                        } else if (rowData.drainageType === DrainageType.SingleSiphonic) {
                                            dtype = "Single Siphonic";
                                        } else if (rowData.drainageType === DrainageType.DualSiphonic) {
                                            dtype = "Dual Siphonic";
                                        }

                                        return <div onClick={() => handleRowClick(rowData)}>{dtype}</div>;
                                    }
                                },
                            },
                            {
                                width: "auto",
                                title: "Device Count",
                                field: "deviceCount",
                                sorting: true,
                                filtering: true,
                                render: (rowData: AdminAlertActionTabModel) => {
                                    if (rowData !== null && rowData !== undefined) {
                                        return <div onClick={() => handleRowClick(rowData)}>{rowData.deviceCount}</div>;
                                    }
                                },
                            },
                            {
                                width: "40px",
                                maxWidth: "40px",
                                title: "",
                                field: "name2",
                                sorting: false,
                                filtering: false,
                                render: (rowData: AdminAlertActionTabModel) => {
                                    if (rowData.deviceCount === 0) {
                                        return <DeleteCircle onClick={() => onDeleteAlertAction(rowData.id)}>X</DeleteCircle>;
                                    } else {
                                        return <div></div>;
                                    }
                                },
                                cellStyle: { width: "40px", padding: "0 15px 0 10px" },
                                headerStyle: { width: "40px" },
                            },
                        ]}
                        options={{
                            search: false,
                            toolbar: false,
                            thirdSortClick: false,
                            headerStyle: {
                                ...tableOptions.headerStyle,
                            },
                            rowStyle: {
                                ...tableOptions.rowStyle,
                            },
                            filterCellStyle: {
                                ...tableOptions.filterCellStyle,
                            },
                            pageSize: 50,
                            pageSizeOptions: [10, 20, 50, 100],
                            emptyRowsWhenPaging: false,
                            searchFieldAlignment: "left",
                            searchFieldStyle: {
                                border: "1px solid #333333",
                                borderRadius: "5px",
                                width: "400px",
                            },
                        }}
                        data={viewModel.getFilteredAlertActions}
                        title=""
                        icons={{
                            ResetSearch: () => <ResetSearchIcon />,
                        }}
                        components={{ Container: (props: any) => <Paper {...props} elevation={0} /> }}
                        localization={{ toolbar: { searchPlaceholder: "Start typing to search" } }}
                    />
                </RoofcareTableWrapperFullWidth>
            </>
        );
    };

    const handleRowClick = (row: any) => {
        history.push(AppUrls.Client.Main.Admin.AlertAction.AlertActionAddEditDetail.replace(":id", row.id));
    };

    const handleNewClick = () => {
        history.push(AppUrls.Client.Main.Admin.AlertAction.AlertActionAddEditDetail.replace(":id", "0"));
    };

    function renderPage() {
        if (viewModel.IsLoading === true) return <Loader />;

        return (
            <Container>
                <Fade in timeout={1000}>
                    <Container>
                        <Box width="100%">
                            <AdminSearchWrapper>
                                <div className="search-field" style={{ width: "580px" }}>
                                    <ProjectListSearch
                                        searchString={viewModel.getSearchString()}
                                        setSearchString={setSearchString}
                                        placeholder="Start typing to search alert actions"
                                    />
                                </div>
                                <div>
                                    <ProjectButton icon={() => <span>&#43;</span>} text="Add new alert action" onClick={() => handleNewClick()} />
                                </div>
                            </AdminSearchWrapper>
                        </Box>
                        <div className="content">{renderContent()}</div>
                    </Container>
                </Fade>
            </Container>
        );
    }

    return useObserver(() => renderPage());
};
