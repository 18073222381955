import * as React from "react";
import * as MobXReact from "mobx-react-lite";
import { PropertyMapContainer, PropertyMapPopup } from "./PropertyMap.styles";
import { IMarker, RoofcareMap } from "Custom/Components/Map/Map";
import { useTheme } from "@material-ui/core";
import { Address, Property } from "Custom/Models/Domain";
import { Loader } from "Core/Components";
import { PropertyMapViewModel } from "./PropertyMapViewModel";
import { formatAddress } from "Custom/Utils/format";
import { useRouter } from "Core/Base";
import { AppUrls } from "Custom/Globals";
import { isNullOrUndefined } from "Custom/Utils/utils";
interface IProps {
    properties: Property[];
}

export const PropertyMap: React.FC<IProps> = ({ properties }) => {
    const [viewModel] = React.useState(() => new PropertyMapViewModel());
    const theme = useTheme();
    const router = useRouter<{ projectId: string; clientId: string }>();

    React.useEffect(() => {
        viewModel.setProperties(properties);
        return () => {
            // Clean up after yourself
        };
    }, []);

    React.useEffect(() => {
        /*  MobX.runInAction(() => { */
        viewModel.setProperties(properties);
        /* }); */
    }, [properties]);

    const goTo = (propertyId: string) => {
        const { projectId, clientId } = router.match.params;
        const { path } = router.match;

        // Work out the url from the current route going from Project to Property
        let url: string = AppUrls.Client.Main.Project.Property.PropertyRoot;

        switch (path) {
            case AppUrls.Client.ClientView.Project.ProjectDetail: {
                url = AppUrls.Client.ClientView.Project.PropertyDetail;
                break;
            }
            case AppUrls.Client.Main.Client.Project.ClientsDetailProject: {
                url = AppUrls.Client.Main.Client.Project.PropertyDetail.replace(":clientId", clientId!);
                break;
            }
            case AppUrls.Client.Main.Project.ProjectDetail:
            default: {
                break;
            }
        }
        router.history.push(url.replace(":projectId", isNullOrUndefined(projectId) ? ":projectId" : projectId!).replace(":propertyId", propertyId));
    };

    const displayPropertyDetails = (marker: IMarker): JSX.Element => {
        const property: Property | undefined = properties.find((a) => a.id === marker.id);
        return (
            <PropertyMapPopup>
                <div className="address">{formatAddress(property as Address, true)}</div>
                <div className="view" onClick={() => goTo(marker.id)}>
                    View property
                </div>
            </PropertyMapPopup>
        );
    };

    const renderPage = () => {
        if (viewModel.IsLoading) return <Loader />;

        return (
            <PropertyMapContainer>
                <div className="mapsection">
                    <RoofcareMap
                        id="property-map"
                        /* controls={[{ key: "showing-on-map", control: renderShowingOnMapControl() }]} */
                        boundsToFit={viewModel.mapBounds}
                        markers={viewModel.markers}
                        displayPopUp={displayPropertyDetails}
                    />
                </div>
            </PropertyMapContainer>
        );
    };

    return MobXReact.useObserver(() => renderPage());
};
