import { observable } from "mobx";
import { ModelBase } from "Core/Models/ModelBase";
import { IsNotEmpty } from "class-validator";

//Base class expects the model type and the DTO model type
//If you are not using a DTO just pass in undefined as the DTO
export class InstallationAuditModel extends ModelBase<InstallationAuditModel, InstallationAuditModelDTO> {
    @observable
    @IsNotEmpty({ message: "You must give me your name!!" })
    public firstName: string = "";

    public id: string | null = null;
    public rowVersion: string | null = null;
    public isDeleted: boolean = false;
    public createdDate: string = "";
    public createdBy: string | null = null;
    public author: string = "";
    public deviceId: number = 0;
    public deviceStatusDataId: number = 0;
    public auditTypeEnum: AuditType = AuditType.Unknown; // Unknown
    public auditTypeName: string = "";
    public dataJson: string = "";
    public description: string = "";
    public comments: string = "";
    public version: number = 1;

    //fromDto is required but you can leave it blank
    fromDto(model: InstallationAuditModelDTO): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
    }

    //toDto is required but you can leave it blank
    toDto(model: InstallationAuditModel): void {}
}

export type InstallationAuditModelDTO = {
    id: string | null;
    rowVersion: string | null;
    isDeleted: boolean;
    createdDate: string;
    createdBy: string | null;
    author: string;
    deviceId: number;
    deviceStatusDataId: number;
    auditTypeEnum: AuditType;
    auditTypeName: string;
    dataJson: string;
    description: string;
    comments: string;
    version: number;
};

// CMR NOTE this needs to be kept in sync to with the database
export enum AuditType {
    Unknown = 1,
    Installation = 100,
    Commissioned = 105,
    Decommissioned = 110,
    MovedUnit = 120,
    Cleaning = 150,
    Maintainance = 160,
    SentAlertEmail = 1000,
    SentAlertSMS = 1010,
    SentPhotoCommand = 1020,
    SentUnitCommand = 1030,
    UnitAlertTripped = 1050,

    StandingWaterEmail = 2000,
    StandingWaterSMS = 2010,
}
