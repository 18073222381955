import React, { useEffect } from "react";
import Link from "@material-ui/core/Link";
import { Link as RouterLink, useLocation, useRouteMatch } from "react-router-dom";
import { Client } from "Custom/Globals/AppUrls";
import { useBreadcrumbStyles, RoofcareBreadcrumbs } from "./RoofcareBreadcrumb.style";

import { BreadcrumbsContext, IBreadcrumbContextIdValues } from "./RoofcareBreadcrumbCommon";
import { RoofcareBreadcrumbViewModel } from "./RoofcareBreadcrumbViewModel";
import { useRouter } from "Core/Base";
import { ApiResult } from "Core/Models";
import { BreadcrumbContextValueDTO } from "Custom/Models/Domain/Breadcrumb/BreadcrumbContextValue";

export const RoofcareClientViewBreadcrumb: React.FC = () => {
    const [viewModel] = React.useState<RoofcareBreadcrumbViewModel>(() => RoofcareBreadcrumbViewModel.Instance);

    const location = useLocation();
    const router = useRouter<{ clientId?: string; contractorId?: string; installId?: string; projectId?: string; propertyId?: string }>();
    const breadcrumbsValues = React.useContext(BreadcrumbsContext);

    const classes = useBreadcrumbStyles();

    //const homeMatch = useRouteMatch(Client.Root);

    const installationMatch = useRouteMatch(Client.ClientView.Device.DeviceRoot);
    const installationInstallationMatch = useRouteMatch(Client.ClientView.Device.Installation);
    const installationPropertyDetailMatch = useRouteMatch(Client.ClientView.Device.PropertyDetail);
    const installationPropertyInstallationMatch = useRouteMatch(Client.ClientView.Device.PropertyInstallation);

    /*  const clientViewProjectDetail = useRouteMatch(Client.Client.Project.ProjectDetail);
    const clientViewProjectPropertyDetail = useRouteMatch(Client.Client.Project.PropertyDetail);
    const clientViewProjectPropertyPropertyInstallation = useRouteMatch(Client.Client.Project.PropertyInstallation);
 */
    //const clientViewPropertyRoot = useRouteMatch(Client.ClientView.Property.PropertyRoot);
    const clientViewPropertyDetail = useRouteMatch(Client.ClientView.Property.PropertyDetail);
    const clientViewPropertyInstallation = useRouteMatch(Client.ClientView.Property.PropertyInstallation);

    const [matchIdData, setContextData] = React.useState<IBreadcrumbContextIdValues>({
        clientId: undefined,
        contractorId: undefined,
        installationId: undefined,
        projectId: undefined,
        propertyId: undefined,
        userId: undefined,
    });

    useEffect(() => {
        const { clientId, contractorId, installId, projectId, propertyId } = router.match.params;

        if (
            clientId !== matchIdData.clientId ||
            contractorId !== matchIdData.contractorId ||
            installId !== matchIdData.installationId ||
            projectId !== matchIdData.projectId ||
            propertyId !== matchIdData.propertyId
        ) {
            const temp: IBreadcrumbContextIdValues = {
                clientId: clientId,
                contractorId: contractorId,
                installationId: installId === undefined ? undefined : parseInt(installId),
                projectId: projectId,
                propertyId: propertyId,
            };

            setContextData(temp);

            let promise = viewModel.GetBreadcrumbData(temp);

            promise.then((result: ApiResult<BreadcrumbContextValueDTO>) => {
                if (result.wasSuccessful) {
                    const payload = result.payload;

                    breadcrumbsValues.setContext({
                        clientName: payload.clientName,
                        contractorName: payload.contractorName,
                        installationName: payload.installationName,
                        projectName: payload.projectName,
                        propertyName: payload.propertyName,
                        userName: payload.userName,
                    });
                }
            });
        }
    }, [location]);

    return (
        <RoofcareBreadcrumbs className={classes.root} aria-label="breadcrumb">
            <Link component={RouterLink} to={Client.Root}>
                End User
            </Link>

            {/* {clientViewProjectDetail && (
                <Link component={RouterLink} to={Client.Client.Project.ProjectDetail.replace(":projectId", (clientViewProjectDetail.params as any).projectId)}>
                    {breadcrumbsValues.projectName || "Loading..."}
                </Link>
            )}

            {clientViewProjectPropertyDetail && (
                <Link
                    component={RouterLink}
                    to={Client.Client.Project.PropertyDetail.replace(":projectId", (clientViewProjectPropertyDetail.params as any).projectId).replace(
                        ":propertyId",
                        (clientViewProjectPropertyDetail.params as any).propertyId,
                    )}
                >
                    {breadcrumbsValues.propertyName || "Loading..."}
                </Link>
            )}

            {clientViewProjectPropertyPropertyInstallation && (
                <Link
                    component={RouterLink}
                    to={Client.Client.Project.PropertyInstallation.replace(":projectId", (clientViewProjectPropertyPropertyInstallation.params as any).projectId)
                        .replace(":propertyId", (clientViewProjectPropertyPropertyInstallation.params as any).propertyId)
                        .replace(":installId", (clientViewProjectPropertyPropertyInstallation.params as any).installId)}
                >
                    {breadcrumbsValues.installationName || "Loading..."}
                </Link>
            )} */}

            {/*             {clientViewPropertyRoot && (
                <Link component={RouterLink} to={Client.ClientView.Property.PropertyRoot}>
                    Client
                </Link>
            )} */}

            {clientViewPropertyDetail && (
                <Link
                    component={RouterLink}
                    to={Client.ClientView.Property.PropertyDetail.replace(":projectId", (clientViewPropertyDetail.params as any).projectId).replace(
                        ":propertyId",
                        (clientViewPropertyDetail.params as any).propertyId,
                    )}
                >
                    {breadcrumbsValues.propertyName || "Loading..."}
                </Link>
            )}

            {clientViewPropertyInstallation && (
                <Link
                    component={RouterLink}
                    to={Client.ClientView.Property.PropertyInstallation.replace(":projectId", (clientViewPropertyInstallation.params as any).projectId)
                        .replace(":propertyId", (clientViewPropertyInstallation.params as any).propertyId)
                        .replace(":installId", (clientViewPropertyInstallation.params as any).installId)}
                >
                    {breadcrumbsValues.installationName || "Loading..."}
                </Link>
            )}

            {installationMatch && (
                <Link component={RouterLink} to={Client.ClientView.Device.DeviceRoot}>
                    Units
                </Link>
            )}
            {installationInstallationMatch && (
                <Link component={RouterLink} to={Client.ClientView.Device.Installation.replace(":installId", (installationInstallationMatch!.params as any).installId)}>
                    {breadcrumbsValues.installationName || "Loading..."}
                </Link>
            )}
            {installationPropertyDetailMatch && (
                <Link component={RouterLink} to={Client.ClientView.Device.PropertyDetail.replace(":propertyId", (installationPropertyDetailMatch!.params as any).propertyId)}>
                    {breadcrumbsValues.propertyName || "Loading..."}
                </Link>
            )}
            {installationPropertyInstallationMatch && (
                <Link
                    component={RouterLink}
                    to={Client.ClientView.Device.PropertyInstallation.replace(":propertyId", (installationPropertyInstallationMatch!.params as any).propertyId).replace(
                        ":installId",
                        (installationPropertyInstallationMatch!.params as any).installId,
                    )}
                >
                    {breadcrumbsValues.installationName || "Loading..."}
                </Link>
            )}
        </RoofcareBreadcrumbs>
    );
};
