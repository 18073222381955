import { GenericIdNumberRequest, GenericIdRequest } from "./../../../Models/API/GenericIdRequest";
import { ViewModelBase } from "Core/ViewModels/ViewModelBase";
import { InstallationAuditModel, InstallationAuditModelDTO } from "./InstallationAuditModel";
import { FieldType, sortByString, sortByStringDates } from "Core/Utils/Utils";
import { action, computed, IObservableArray, observable, runInAction } from "mobx";
import { ApiResult } from "Core/Models";
import { Server } from "Custom/Globals/AppUrls";
import { KeyValuePair } from "Core/Models/KeyValuePair";
import { DEBOUNCE_VALUE_MS } from "Custom/Globals/Globals";
import { debounce } from "lodash-es";

//extend viewmodel base and passing your model as the generic type
export class InstallationAuditViewModel extends ViewModelBase<InstallationAuditModel> {
    //Singleton instance of class
    private static _instance: InstallationAuditViewModel;
    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    @observable public errorMessage: string = "";

    @observable public pageSize: number = 50;
    @observable public currentPage: number = 0;

    // By default this will be the first column (by index) and in ascending order.
    @observable public sortColumnId: number = 0;
    @observable public sortDirection: any = "desc";

    @observable public auditTrail: IObservableArray<InstallationAuditModelDTO> = observable([]);

    @computed public get getAuditTrailTotal(): number {
        return this.auditTrail.slice().length;
    }

    @computed public get getAuditTrail(): InstallationAuditModelDTO[] {
        let retVal = this.auditTrail.slice();

        const filterByAuditType = (item: InstallationAuditModelDTO): boolean => {
            if (this.filterAuditTypeFilter.length === this.auditTrail.length) {
                return true;
            }

            let ret: boolean = false;
            let found: boolean = this.filterAuditTypeFilter.includes(item.auditTypeEnum.toString());
            if (found === true) {
                ret = true;
            } else {
                ret = false;
            }

            return ret;
        };

        return retVal.filter((a) => filterByAuditType(a));
    }

    public firstTime: boolean = true;

    @observable public filterAuditTypeFilter = observable<string>([]);
    @observable public auditTypeList: KeyValuePair[] = [];

    constructor() {
        //Pass in a new instance of your model
        //By passing in true as the second parameter, we make this model undoable which means we can use save and reset options on the model
        //If you make a change to the model you need to persist it with a saveModel() call
        //If you make changes to your model you can revert it back by calling resetModel()
        super(new InstallationAuditModel(), false);
        //EN - Haven't figured out how to make this call work from the base model yet
        //This is only needed if you make use of the validation decorators
        this.setDecorators(InstallationAuditModel);
    }

    @action reset() {
        this.auditTrail.clear();
        this.sortColumnId = 0;
        this.sortDirection = "asc";
        this.pageSize = 50;
        this.currentPage = 0;
        this.firstTime = true;
        this.filterAuditTypeFilter.clear();
        this.auditTypeList = [];
    }

    @computed
    public get getAuditTypeFilterKVPair(): KeyValuePair[] {
        return this.auditTypeList.slice();
    }

    @action
    public setAuditTypeFilter = (values: string[]) => {
        this.filterAuditTypeFilter.replace(values);
        this.setFilterDisplay(values);
    };

    private setFilterDisplay = debounce(
        action((values: string[]) => {
            this.filterAuditTypeFilter.replace(values);
        }),
        DEBOUNCE_VALUE_MS,
    );

    public getAuditTypeFilter = () => {
        return computed(() => this.filterAuditTypeFilter);
    };

    @action
    public setOrderChange = (columnId: number, direction: any) => {
        this.sortColumnId = columnId;
        this.sortDirection = direction;
    };

    @action
    public setRowsPerPage(rows: number) {
        this.pageSize = rows;
    }

    @action
    public setPage = (page: number) => {
        this.currentPage = page;
    };

    //isValid will check all fields to make sure they are in a valid state.
    public doSubmit = async (e: any) => {
        e.preventDefault();

        if (this.isModelValid()) {
            //Do stuff here
            this.errorMessage = "Form is valid";
        } else {
            this.errorMessage = "Form is not valid";
        }
    };

    //This must be present in your viewmodel. Just return true if you dont need to validate anything.
    //keyof BlankModel & string lets you add type checking to the fieldName
    //I am using the validator package to make checking easier https://www.npmjs.com/package/validator
    public isFieldValid(fieldName: keyof FieldType<InstallationAuditModel>): boolean {
        const { isValid, errorMessage } = this.validateDecorators(fieldName);

        //You need to this two properties after validation
        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    @action
    public load = async (deviceId: number): Promise<ApiResult<InstallationAuditModelDTO[] | undefined>> => {
        let apiResult: ApiResult<InstallationAuditModelDTO[] | undefined> = {
            wasSuccessful: false,
            errors: [],
            headers: "",
            payload: undefined,
        };

        try {
            if (this.IsLoading === false) {
                const request: GenericIdNumberRequest = {
                    id: deviceId,
                };

                const apiResult: ApiResult<InstallationAuditModelDTO[]> = await this.Post<InstallationAuditModelDTO[]>(Server.Api.InstallationAudit.getAuditForDeviceId, request);

                if (apiResult.wasSuccessful) {
                    runInAction(() => {
                        /*   Not needed, since sort done by table                      let sorted: InstallationAuditModelDTO[] = [];

                        if (apiResult.payload !== null && apiResult.payload !== undefined && apiResult.payload.length > 0) {
                            let toSort: InstallationAuditModelDTO[] = apiResult.payload.slice(0);

                            sorted = toSort.sort((a: InstallationAuditModelDTO, b: InstallationAuditModelDTO) => {
                                return sortByStringDates(b.createdDate, a.createdDate);
                            });
                        } */
                        this.auditTrail.replace(apiResult.payload);

                        if (this.firstTime === true) {
                            this.filterAuditTypeFilter.clear();
                            this.auditTypeList = [];

                            for (let s: number = 0; s < this.auditTrail.length; s++) {
                                const found = this.auditTypeList.find((a) => a.value.toString() === this.auditTrail[s].auditTypeEnum.toString());

                                if (found === undefined) {
                                    this.auditTypeList.push({
                                        key: this.auditTrail[s].auditTypeName,
                                        value: this.auditTrail[s].auditTypeEnum.toString(),
                                    });

                                    this.filterAuditTypeFilter.push(this.auditTrail[s].auditTypeEnum.toString());
                                }
                            }

                            this.auditTypeList.sort((a: KeyValuePair, b: KeyValuePair) => {
                                return sortByString(a.key, b.key);
                            });

                            this.firstTime = false;
                        }
                    });
                } else {
                    // Error. What to do?
                    this.setIsErrored(true);
                    this.history.push("/");
                }
            }
        } catch (exception) {
            // Error. What to do?
            this.setIsErrored(true);
            this.history.push("/");
        } finally {
            this.setIsLoading(false);
        }

        return apiResult;
    };
}
