import styled from "styled-components";

export const PropertyListContainer = styled.div`
    padding: 20px 30px 44px;

    .MuiToolbar-root {
        flex-direction: column;
        align-items: flex-start;
        padding: 15px 20px;
        background-color: #585858;
        color: white;
        /* background-color: ${(props) => props.theme.app.colors.grey.background}; */
        /* border-bottom: 1px solid ${(props) => props.theme.app.colors.grey.border}; */

        h6 {
            font-weight: normal;
            padding-bottom: 6px;
        }

        .MuiTextField-root {
            padding-left: 0;

            .MuiInput-formControl {
                height: 34px;

                .MuiInputAdornment-root.MuiInputAdornment-positionEnd button {
                    padding-right: 0;
                }
            }
        }
    }

    .MuiTableHead-root {
        background-color: ${(props) => props.theme.app.colors.grey.background};

        th {
            padding: 12px 20px;
            background-color: transparent;
        }
    }

    .MuiTableBody-root {
        td {
            padding: 12px 20px;
        }
    }

    .MuiTableFooter-root {
        display: none !important;
    }
`;
