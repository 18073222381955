import { observable } from "mobx";
import { ModelBase } from "Core/Models/ModelBase";
import { IsNotEmpty } from "class-validator";

//Base class expects the model type and the DTO model type
//If you are not using a DTO just pass in undefined as the DTO
export class ColourPickerModel extends ModelBase<ColourPickerModel, ColourPickerModelDTO> {
    @observable
    @IsNotEmpty({ message: "You must give me your name!!" })
    public firstName: string = "";

    //fromDto is required but you can leave it blank
    fromDto(model: ColourPickerModelDTO): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
    }

    //toDto is required but you can leave it blank
    toDto(model: ColourPickerModel): void {}
}

export type ColourPickerModelDTO = {
    firstName: string;
};
