import * as MobX from "mobx";
import { ModelBase } from "Core/Models/ModelBase";
import { IsNotEmpty } from "class-validator";

//Base class expects the model type and the DTO model type
//If you are not using a DTO just pass in undefined as the DTO
export class Project extends ModelBase<Project, ProjectDTO> {
    @MobX.observable public number: string = "";
    @MobX.observable public name: string = "";
    @MobX.observable public createdDate: string = "";
    @MobX.observable public createdBy: string = "";
    @MobX.observable public regionalSalesManagerId: string = "";
    @MobX.observable public clientId: string = "";
    @MobX.observable public contractorId: string = "";
    @MobX.observable public status: number = 0; // Project Status Enum Id = 0 is 'unknown/target'.
    @MobX.observable public paymentStatus: number = 1; // Project Payment Status Id = 1 is 'unknown'.
    @MobX.observable public rowVersion: string = "";
    @MobX.observable public isDeleted: boolean = false;
    @MobX.observable public id: string = "";

    //fromDto is required but you can leave it blank
    @MobX.action
    fromDto(model: ProjectDTO): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
    }

    //toDto is required but you can leave it blank
    toDto(model: Project): void {}
}

export type ProjectDTO = {
    number: string;
    name: string;
    createdDate: string;
    createdBy: string;
    regionalSalesManagerId: string;
    clientId: string;
    contractor: string;
    status: number; // Project Status Enum Id.
    paymentStatus: number;
    rowVersion: string;
    isDeleted: boolean;
    id: string;
};
