import React, { useContext, useEffect, useState } from "react";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import { useRouter } from "Core/Utils";
import { DeviceUnitStatusAddEditDetailViewModel } from "./DeviceUnitStatusAddEditDetailViewModel";
import { BackBox, BackToList, BackToListText } from "Custom/StylesAppSpecific/Controls/BackToList";
import { AppUrls } from "Custom/Globals";
import { DeviceUnitStatusAddEditDetailModel } from "./DeviceUnitStatusAddEditDetailModel";
import { RoofcareInput } from "Custom/StylesAppSpecific/Controls/RoofcareInput";
import { AdminDetailContainer, BackToListWrapper, ButtonSectionBox, DetailWrapper, NameSectionBox } from "Custom/Views/Admin/Condition/Condition.style";
import { SuccessBtn } from "Custom/StylesAppSpecific/Controls/SuccessBtn";
import { CancelBtn } from "Custom/StylesAppSpecific/Controls/CancelBtn";
import { Loader } from "Core/Components";
import { useLoadCall } from "Custom/Utils/useLoadCall";
import { useObserver } from "mobx-react-lite";
import * as Defaults from "Custom/StylesAppSpecific/TableOptions";
import { ControlLabel } from "Custom/Components/ControlLabel/ControlLabel";
import { Box } from "@material-ui/core";

export const DeviceUnitStatusAddEditDetail: React.FunctionComponent = () => {
    const [viewModel] = useState(() => new DeviceUnitStatusAddEditDetailViewModel());
    const router = useRouter<{ id?: string }>();
    const { history, match } = useRouter();
    let { id } = match.params as any;
    const [canSave, setCanSave] = useState(false);

    //const tableOptions = Defaults.GetRoofcareTableOptions() as any;

    //useEffect below only gets run on initial render
    useEffect(() => {
        // *optional* passing the router into the viewmodel so we can navigate from there
        //console.log("First render");
        //This gets run when the page is exited
        return () => {
            // console.log("Unmounting");
        };
    }, []);

    useEffect(() => {
        checkData(); // hate that this doesn't piggin work.
    }, [viewModel.getName, viewModel.getOrdinal]);

    const notFound = useLoadCall(
        async () => {
            if (id === null || id < 1) {
                return true;
            } else {
                let promise = await viewModel.loadDeviceUnitStatussAsync(id);

                if (promise.wasSuccessful === true) {
                    setCanSave(true);
                }

                return !!viewModel.getValue("id");
            }
        },
        { customCheck: () => id !== null || id < 1 },
    );

    const handleBack = () => {
        router.history.push(AppUrls.Client.Main.Admin.Root + "#unitdevicestatus");
    };

    const validateAndSave = (exit: boolean) => {
        //validate model and all sub objects
        if (viewModel.isModelValid() === true) {
            let promise = viewModel.upsertDeviceUnitStatus();
            promise.then((result: any) => {
                if (result.wasSuccessful === true) {
                    if (exit === true) {
                        handleBack();
                    }
                }
            });
        }
    };

    const onSave = (exit: boolean): void => {
        validateAndSave(exit);
    };

    /*   const setSearchString = (value: string) => {
        viewModel.setSearchString(value);
        viewModel.setPage(0);
    };

    const setRowsPerPage = (rows: number) => {
        viewModel.setRowsPerPage(rows);
    };

    const setPage = (page: number) => {
        viewModel.setPage(page);
    };

    const setOrderChange = (columnId: number, direction: any) => {
        viewModel.setOrderChange(columnId, direction);
    };

    const getSortDirection = (columnIndex: number) => {
        return viewModel.sortColumnId === columnIndex ? viewModel.sortDirection : "";
    }; */

    const checkData = () => {
        let retVal: boolean = false;
        if (viewModel.getName.length > 0 && viewModel.getOrdinal > 0) {
            retVal = true;
        }

        if (retVal !== canSave) {
            setCanSave(retVal);
        }
    };

    function renderPage() {
        if (viewModel.IsLoading || notFound) return <Loader />;

        return (
            <>
                {/*                 <DeleteModal
                    open={areYouSureOpen}
                    onClose={handleAreYouSureClose}
                    onDelete={handleSaveDeviceUnitStatus}
                    text="This alert action set does not contain all the options. Do you want to continue?"
                    title="Do you want to continue?"
                /> */}
                <AdminDetailContainer>
                    <DetailWrapper>
                        <BackToListWrapper style={{ paddingTop: "15px" }}>
                            <BackToList onClick={handleBack} tabIndex={0}>
                                <BackBox>
                                    <NavigateBeforeIcon fontSize="large" />
                                </BackBox>
                                <BackToListText>Back to Unit Status</BackToListText>
                            </BackToList>
                        </BackToListWrapper>
                        <NameSectionBox>
                            <RoofcareInput<DeviceUnitStatusAddEditDetailModel>
                                type="text"
                                label="Name:"
                                validateOnBlur={true}
                                viewModel={viewModel}
                                fieldName="name"
                                shrink={true}
                                maxLength={50}
                                inputProps={{ onBlur: checkData() }}
                            />
                        </NameSectionBox>
                        <NameSectionBox>
                            <Box className="numberbox" maxWidth="200px">
                                <ControlLabel label="Drop Down Position:" htmlFor="dropdownposition">
                                    <RoofcareInput
                                        id="dropdownposition"
                                        className="form-control"
                                        type="number"
                                        fullWidth={true}
                                        viewModel={viewModel}
                                        fieldName="ordinal"
                                        inputProps={{
                                            onBlur: checkData(),
                                            placeholder: "Range 0-9999",
                                            maxLength: 4,
                                            min: 1,
                                            max: 9999,
                                        }}
                                    />
                                </ControlLabel>
                            </Box>
                        </NameSectionBox>

                        {/*                         <ItemSectionBox id="unittabletitle">
                            <h1>Assigned Units</h1>
                        </ItemSectionBox> */}
                        {/* <ItemSectionTable id="unittable">
                            {hasUnits() === false && <div>Alert Action has no assigned units</div>}
                            {hasUnits() === true && (
                                <RoofcareTableWrapperAdmin key="alertActionUnitswrapper">
                                    <RoofcareTable
                                        id="alertActionUnits"
                                        className="alertActionUnits"
                                        columns={[
                                            {
                                                title: "Name",
                                                field: "name",
                                                sorting: true,
                                                filtering: true,
                                                defaultSort: getSortDirection(0),
                                            },
                                            {
                                                title: "End User",
                                                field: "clientName",
                                                sorting: true,
                                                filtering: true,
                                                defaultSort: getSortDirection(1),
                                            },
                                            {
                                                title: "Contractor",
                                                field: "contractorName",
                                                sorting: true,
                                                filtering: true,
                                                defaultSort: getSortDirection(2),
                                            },
                                            {
                                                title: "Property",
                                                field: "addressLine1",
                                                sorting: true,
                                                filtering: true,
                                                defaultSort: getSortDirection(3),
                                            },
                                            {
                                                width: "auto",
                                                title: "Town",
                                                field: "city",
                                                sorting: true,
                                                filtering: true,
                                                defaultSort: getSortDirection(4),
                                            },
                                            {
                                                width: "auto",
                                                title: "Postcode",
                                                field: "postcode",
                                                sorting: true,
                                                filtering: true,
                                                defaultSort: getSortDirection(5),
                                            },
                                            {
                                                width: "auto",
                                                title: "Status",
                                                field: "status",
                                                sorting: false,
                                                filtering: true,
                                                defaultSort: getSortDirection(6),
                                                render: (rowData: UnitListItemModelDTO) => {
                                                    if (rowData !== null && rowData !== undefined) {
                                                        return (
                                                            <div className="status-readings" style={{ display: "flex", justifyContent: "center", width: "75px" }}>
                                                                <UnitStatusBox
                                                                    className={"device-status"}
                                                                    backgroundColor={rowData.status.statusColour}
                                                                    textColour={rowData.status.statusTextColour}
                                                                >
                                                                    <div className="status-name">{rowData.status.name}</div>
                                                                </UnitStatusBox>
                                                            </div>
                                                        );
                                                    }
                                                },
                                            },
                                        ]}
                                        options={{
                                            search: false,
                                            toolbar: false,
                                            thirdSortClick: false,
                                            initialPage: viewModel.currentPage,
                                            defaultGroupOrder: viewModel.sortColumnId,
                                            defaultGroupDirection: viewModel.sortDirection,
                                            pageSize: viewModel.pageSize,
                                            pageSizeOptions: [10, 20, 50, 100],
                                            emptyRowsWhenPaging: false,
                                            headerStyle: {
                                                ...tableOptions.headerStyle,
                                            },
                                            rowStyle: {
                                                ...tableOptions.rowStyle,
                                            },
                                            filterCellStyle: {
                                                ...tableOptions.filterCellStyle,
                                            },
                                            searchFieldAlignment: "left",
                                            searchFieldStyle: {
                                                border: "1px solid #333333",
                                                borderRadius: "5px",
                                                width: "400px",
                                            },
                                        }}
                                        data={viewModel.getUnits}
                                        title=""
                                        icons={{
                                            ResetSearch: () => <ResetSearchIcon />,
                                        }}
                                        components={{ Container: (props: any) => <Paper {...props} elevation={0} /> }}
                                        localization={{ toolbar: { searchPlaceholder: "Start typing to search" } }}
                                        onOrderChange={(orderedColumnId: any, orderDirection: any) => {
                                            setOrderChange(orderedColumnId, orderDirection);
                                        }}
                                        onChangeRowsPerPage={(rows: number) => {
                                            setRowsPerPage(rows);
                                        }}
                                        onChangePage={(page: number) => {
                                            setPage(page);
                                        }}
                                    />
                                </RoofcareTableWrapperAdmin>
                            )}
                        </ItemSectionTable> */}
                        <ButtonSectionBox>
                            <SuccessBtn disabled={!canSave} onClick={() => onSave(false)}>
                                Save
                            </SuccessBtn>
                            <SuccessBtn disabled={!canSave} onClick={() => onSave(true)} style={{ marginLeft: "15px" }}>
                                Save &#x26; exit
                            </SuccessBtn>
                            <CancelBtn onClick={handleBack}>Cancel</CancelBtn>
                        </ButtonSectionBox>
                    </DetailWrapper>
                </AdminDetailContainer>
            </>
        );
    }

    return useObserver(() => renderPage());
};
