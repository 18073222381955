import { Avatar, Box, Button, Fade, FormControl, Paper, Typography } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";

import { AuditEmailModel } from "./AuditEmailModel";
import { AuditEmailViewModel } from "./AuditEmailViewModel";
import { Centered } from "Core/Components";
import { DataJsonProps } from "../DataJsonProps";
import { useObserver } from "mobx-react-lite";
import { EmailPaper } from "../../Modals/AuditModal.style";
import { AuditDetailsContainer } from "../TabAudit.style";

export const AuditEmailSection: React.FunctionComponent<DataJsonProps> = ({ data }) => {
    const [viewModel] = useState(() => new AuditEmailViewModel());

    //useEffect below only gets run on initial render
    useEffect(() => {
        // *optional* passing the router into the viewmodel so we can navigate from there
        //console.log("First render");
        //This gets run when the page is exited
        return () => {
            //console.log("Unmounting");
        };
    }, []);

    React.useEffect(() => {
        viewModel.setAuditItem(data);
    }, [data]);

    function renderModal() {
        const data = viewModel.getDisplayData;

        return (
            <Fade in timeout={1000}>
                <AuditDetailsContainer className="column">
                    <div className="row">
                        <div className="title">Subject:</div>
                        <div>{data.Subject}</div>
                    </div>
                    <div className="row">
                        <div className="title">To:</div>
                        <div>{data.Addresses}</div>
                    </div>
                    <EmailPaper>
                        <div className="innerHTMLblock" dangerouslySetInnerHTML={{ __html: data.Message }} />
                    </EmailPaper>
                </AuditDetailsContainer>
            </Fade>
        );
    }

    return useObserver(() => renderModal());
};
