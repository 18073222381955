import * as React from "react";

import { ErrorModalDialogPaper } from "./ErrorModal.styles";
import { CancelBtn } from "../../StylesAppSpecific/Controls/CancelBtn";
import { BaseModal } from "../../Components/Modal/BaseModal";
import { StoresInstance } from "../../Stores/Stores";
import { useObserver } from "../../../Core/Base";

export const ErrorModal: React.FC = () => {
    const handleClose = () => {
        StoresInstance.domain.CloseErrorDialog();
    };

    return useObserver(() => (
        <BaseModal
            open={StoresInstance.domain.openErrorDialog}
            onClose={handleClose}
            title="Error"
            PaperComponent={ErrorModalDialogPaper}
            actions={
                <>
                    <CancelBtn onClick={handleClose}>OK</CancelBtn>
                </>
            }
        >
            <h2 style={{ color: "white" }}>{StoresInstance.domain.errorDialogTitle}</h2>
            <p className="client-modal-description">{StoresInstance.domain.errorDialogContent}</p>
            {StoresInstance.domain.errorDialogArrayContent.length > 0 && (
                <>
                    {StoresInstance.domain.errorDialogArrayContent.map((item) => {
                        return <p className="client-modal-arraydescription">{item}</p>;
                    })}
                    <p className="client-modal-description">&nbsp;</p>
                </>
            )}
        </BaseModal>
    ));
};
