import styled from "styled-components";
import { BaseModalDialogPaper } from "Custom/Components/Modal/BaseModal.styles";

export const ClientAddModalDialogPaper = styled(BaseModalDialogPaper)`
    width: 100%;
    max-width: none;
    margin: 10px;
    background: transparent linear-gradient(180deg, #565758 0%, #373a40 100%) 0% 0% no-repeat padding-box;

    .MuiDialogActions-root {
        width: 378px;
        margin: auto;
        padding-bottom: 40px;
    }
`;
