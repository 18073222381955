import { DrainageType, InstallationStatusDataDTO, InstallationWorkingData, RoofType } from "Custom/Models/Domain/Installations";

export const generateStatusWorkingData = (statusData: InstallationStatusDataDTO | undefined): InstallationWorkingData | undefined => {
    let retVal: InstallationWorkingData | undefined = undefined;

    if (statusData !== null && statusData !== undefined) {
        retVal = {
            install_Height: 0,
            working_Height: 0,
            freeboard_Height: 0,

            roofType: RoofType.Pitched,
            drainageType: DrainageType.Gravity,
            p1: 0,
            p2: 0,
        };

        let maxWorkingHeightValue: number = Math.min(statusData!.side1_Height, statusData!.side2_Height);

        if (statusData.roofType === RoofType.Flat) {
            maxWorkingHeightValue = statusData!.maxHeight;
        }

        const freeboardHeightValue: number = maxWorkingHeightValue * 0.7;

        retVal.freeboard_Height = freeboardHeightValue;
        retVal.drainageType = statusData.drainageType;
        retVal.install_Height = statusData.install_Height;
        retVal.p1 = statusData.drainageType !== DrainageType.Gravity ? statusData.p1 : 0;
        retVal.p2 = statusData.drainageType !== DrainageType.Gravity ? statusData.p2 : 0;
        retVal.roofType = statusData.roofType;
        retVal.working_Height = maxWorkingHeightValue;
    }

    return retVal;
};
