import styled from "styled-components";

export const PropertyDetailPageContainer = styled.div`
    background-color: #585858;
    padding-top: 10px;
    height: 100%;
    display: flex;
    flex-direction: column;
    color: white;

    > div.content {
        flex-grow: 1;
        display: flex;
        background-color: #585858;
        color: white;
    }
`;
