import { formatAddress } from "./../../Utils/format";
import { isNullOrUndefined } from "Custom/Utils/utils";
import { ViewModelBase } from "Core/ViewModels/ViewModelBase";
import { FieldType } from "Core/Utils/Utils";
import { action, computed, IObservableArray, observable, runInAction } from "mobx";
import { AddressModel, AddressModelDTO } from "Custom/Models/Domain/Address/AddressModel";
import validator from "validator";
import { AppUrls } from "Custom/Globals";
import { ApiResult } from "Core/Models";
import { AddressItem, AddressRequest, AddressResults, AddressResult, ConvertAddressItemToAddressModelDTO } from "Custom/Models/API/Hopewiser";
import { LatLngBounds, LatLngExpression } from "leaflet";
import { getDefaultPropertyMarkerIcon } from "Custom/Utils/map";
import { DEFAULTBOUNDS } from "Custom/Globals/Globals";
import { IDraggableMarker } from "../Map/DraggablePin";

//extend viewmodel base and passing your model as the generic type
export class AddressViewModel extends ViewModelBase<AddressModel> {
    //Singleton instance of class
    private static _instance: AddressViewModel;

    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    @observable public errorMessage: string = "";
    @observable public searchCriteria: string = "";
    @observable public searchResults: IObservableArray<AddressItem> = observable([]);

    @computed get getSearchResults(): AddressItem[] {
        return this.searchResults.slice();
    }

    constructor() {
        //Pass in a new instance of your model
        //By passing in true as the second parameter, we make this model undoable which means we can use save and reset options on the model
        //If you make a change to the model you need to persist it with a saveModel() call
        //If you make changes to your model you can revert it back by calling resetModel()
        super(new AddressModel(), false);
        //EN - Haven't figured out how to make this call work from the base model yet
        //This is only needed if you make use of the validation decorators
        this.setDecorators(AddressModel);
    }

    @action
    public clear(): void {
        this.errorMessage = "";
        this.searchCriteria = "";
        this.searchResults.clear();
        this.model.clear();
    }

    @computed
    public get mapBounds() {
        let center: LatLngExpression = {
            lat: this.model.locationLatitude === undefined ? 0 : this.model.locationLatitude!,
            lng: this.model.locationLongitude === undefined ? 0 : this.model.locationLongitude!,
        };

        let ne: LatLngExpression = {
            lat: center.lat - 0.0001,
            lng: center.lng + 0.0001,
        };

        let sw: LatLngExpression = {
            lat: center.lat + 0.0001,
            lng: center.lng - 0.0001,
        };

        let retVal: LatLngBounds = DEFAULTBOUNDS;

        let bounds: LatLngBounds = new LatLngBounds(sw, ne);

        const isValid: boolean = bounds.isValid();

        if (isValid) {
            retVal = bounds;
        }

        return retVal;
    }

    @computed
    public get getPostcode(): string {
        return this.model.postcode;
    }

    @computed
    public get getId(): string | undefined {
        return this.model.id;
    }

    @action
    public setLocation = (latitude: number, longitude: number) => {
        this.setValue("locationLatitude", latitude);
        this.setValue("locationLongitude", longitude);
    };

    //This must be present in your viewmodel. Just return true if you dont need to validate anything.
    //keyof BlankModel & string lets you add type checking to the fieldName
    //I am using the validator package to make checking easier https://www.npmjs.com/package/validator
    public isFieldValid(fieldName: keyof FieldType<AddressModel>, value: any): boolean {
        let { isValid, errorMessage } = this.validateDecorators(fieldName);

        if ((fieldName as any) === "addressLine1") {
            if (validator.isEmpty(value)) {
                errorMessage = "Address line 1 is required";
                isValid = false;
            } else if (value.toString().length > 128) {
                errorMessage = "Address line 1 needs to be less than 128 characters";
                isValid = false;
            }
        }

        if ((fieldName as any) === "addressLine2") {
            if (value.toString().length > 128) {
                errorMessage = "Address line 2 needs to be less than 128 characters";
                isValid = false;
            }
        }

        if ((fieldName as any) === "city") {
            if (validator.isEmpty(value)) {
                errorMessage = "City is required";
                isValid = false;
            } else if (value.toString().length > 128) {
                errorMessage = "City needs to be less than 128 characters";
                isValid = false;
            }
        }

        if ((fieldName as any) === "county") {
            if (value.toString().length > 128) {
                errorMessage = "County needs to be less than 128 characters";
                isValid = false;
            }
        }

        if ((fieldName as any) === "postcode") {
            if (validator.isEmpty(value)) {
                errorMessage = "Postcode is required";
                isValid = false;
            } else if (!validator.isPostalCode(value, "GB")) {
                errorMessage = "Postcode is not valid";
                isValid = false;
            }
        }

        //You need to this two properties after validation
        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    @computed get getAddress(): AddressModel {
        return this.model.getAddress;
    }

    @action setAddress = (address: AddressModel) => {
        this.model.copy(address);
        this.searchCriteria = formatAddress(address);
    };

    @action setSearchCriteria(value: string) {
        this.searchCriteria = value;
    }

    @action public addressSearch = async (item?: AddressItem): Promise<ApiResult<AddressResults>> => {
        this.errorMessage = "";
        let searchAddress = JSON.stringify(
            this.searchCriteria.split(",").map((item: string) => {
                return item.trim();
            }),
        );

        let fromSid: boolean = false;
        if (isNullOrUndefined(item) === false) {
            fromSid = true;
            searchAddress = JSON.stringify([item!.sid]);
        }

        const apiResult = await this.Post<AddressResults>(AppUrls.Server.Api.Hopewiser.getAddresses, searchAddress);

        if (apiResult.wasSuccessful) {
            runInAction(() => {
                const addressResults = apiResult.payload;

                if (fromSid === true) {
                    const add: string = item!.itemText.replace("+", "").trim();

                    for (let i: number = 0; i < addressResults.items.length; i++) {
                        addressResults.items[i].itemText += " " + add;
                    }
                }

                this.searchResults.replace(addressResults.items);
            });
        }

        return apiResult;
    };

    @action public getSelectedAddress = async (sid: string): Promise<ApiResult<AddressResult>> => {
        this.errorMessage = "";
        const url = AppUrls.Server.Api.Hopewiser.getAddressDetails;

        const request: AddressRequest = {
            Sid: sid,
        };
        const apiResult = await this.Post<AddressResult>(url, request);

        if (apiResult.wasSuccessful) {
            runInAction(() => {
                const addressResults = apiResult.payload;
                if (addressResults.status === "OK") {
                    let retVal: AddressModelDTO = ConvertAddressItemToAddressModelDTO(addressResults.results.items[0]);

                    this.model.fromDto(retVal);
                }
            });
        }

        return apiResult;
    };

    @action
    public setModel = (value: AddressModelDTO) => {
        this.model.fromDto(value);
    };

    @computed
    public get getMapBounds(): LatLngBounds {
        let center: LatLngExpression = {
            lat: this.model.locationLatitude,
            lng: this.model.locationLongitude,
        };

        let ne: LatLngExpression = {
            lat: center.lat - 0.0001,
            lng: center.lng + 0.0001,
        };

        let sw: LatLngExpression = {
            lat: center.lat + 0.0001,
            lng: center.lng - 0.0001,
        };

        let retVal: LatLngBounds = DEFAULTBOUNDS;

        let bounds: LatLngBounds = new LatLngBounds(sw, ne);

        const isValid: boolean = bounds.isValid();

        if (isValid) {
            retVal = bounds;
        }

        return retVal;
    }

    @computed public get getPropertyCenter(): LatLngExpression {
        let retVal: LatLngExpression = {
            lat: this.model.locationLatitude,
            lng: this.model.locationLongitude,
        };
        return retVal;
    }

    @action
    public onPositionChange = (id: string, lat: number, lng: number): any => {
        this.setValue("locationLatitude", lat);
        this.setValue("locationLongitude", lng);
    };

    @computed
    public get getMarkers(): IDraggableMarker[] {
        let retVal: IDraggableMarker[] = [];

        if (
            (isNullOrUndefined(this.model.locationLatitude) === false || this.model.locationLatitude === 0) &&
            (isNullOrUndefined(this.model.locationLongitude) === false || this.model.locationLongitude === 0)
        ) {
            let marker: IDraggableMarker = {
                id: this.model.id === undefined ? "tempPin" : this.model.id,
                position: [this.model.locationLatitude, this.model.locationLongitude],
                icon: getDefaultPropertyMarkerIcon(),
                draggable: true,
                onPositionChange: this.onPositionChange,
            };
            retVal.push(marker);
        }

        return retVal;
    }
}
