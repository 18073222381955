import { action, computed, observable } from "mobx";
import { ModelBase } from "Core/Models/ModelBase";
import { IsNotEmpty } from "class-validator";
import { UnitBehaviourItemModel, UnitBehaviourItemModelDTO } from "./UnitBehaviourItemModel";
import { UnitListItemModelDTO } from "Custom/Views/Installations/InstallationList/UnitListItemModel";

//Base class expects the model type and the DTO model type
//If you are not using a DTO just pass in undefined as the DTO
export class UnitBehaviourAddEditDetailModel extends ModelBase<UnitBehaviourAddEditDetailModel, UnitBehaviourAddEditDetailModelDTO> {
    public id: string | null = null;
    public rowVersion: string | null = null;

    @observable
    @IsNotEmpty({ message: "You must give the unit behaviour set a name!!" })
    public name: string = "";

    public isDeleted: boolean = false;
    public createdBy: string | null = null;
    public createdDate: string | null = null;
    @observable public items: any[] = [];

    @observable public unitListItems: UnitListItemModelDTO[] = [];

    @computed public get getItems() {
        return this.items.slice();
    }

    @computed public get getUnits(): UnitListItemModelDTO[] {
        return this.unitListItems.slice();
    }

    @action public clear = () => {
        this.id = null;
        this.rowVersion = null;
        this.name = "";
        this.isDeleted = false;
        this.createdBy = null;
        this.createdDate = null;
        this.items = [];
        this.unitListItems = [];
    };

    //fromDto is required but you can leave it blank
    fromDto(model: UnitBehaviourAddEditDetailModelDTO): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else if (key === "items") {
                    this.items = [];

                    for (let i: number = 0; i < model[key].length; i++) {
                        let newItem = new UnitBehaviourItemModel();
                        newItem.fromDto(model[key][i]);
                        this.items.push(newItem);
                    }
                } else {
                    this[key] = model[key];
                }
            }
        }
    }

    //toDto is required but you can leave it blank
    toDto(model: UnitBehaviourAddEditDetailModel): void {}
}

export type UnitBehaviourAddEditDetailModelDTO = {
    id: string | null;
    name: string;
    rowVersion: string | null;
    isDeleted: boolean;
    createdBy: string | null;
    createdDate: string | null;
    items: UnitBehaviourItemModelDTO[];

    unitListItems: UnitListItemModelDTO[];
};
