import { Box, makeStyles, Typography, useMediaQuery } from "@material-ui/core";
import { Cell } from "Core/Components/Styles";
import styled from "styled-components";

export const useContactTableStyles = makeStyles({
    table: {
        minWidth: 650,
        marginTop: "-24px",
    },
    headerRow: {
        "& > th": {
            "&:first-child": {
                paddingLeft: "0",
            },
            "&:last-child": {
                paddingRight: "0",
            },
        },
    },
    body: {
        "& > div": {
            padding: "28px 0 13px",
        },
    },
    contactRow: {
        "& > td": {
            color: "#fff",
            borderBottom: "none",
            paddingTop: "12px",
            paddingBottom: "12px",
        },
        "&:first-child": {
            "& > td": {
                paddingTop: "28px",
            },
        },
        "&:last-child": {
            "& > td": {
                paddingBottom: "13px",
            },
        },
    },
    addBtn: {
        marginTop: "35px",
        marginBottom: "10px",
        paddingBottom: "5px",
        "& > button": {
            marginTop: "28px",
            height: "32px",
        },
    },
    addContactBtn: {
        marginBottom: "10px",
        fontSize: "16px",
    },
});

export const ClientContactsWrapper = styled(Box)`
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;
    //background-color: green;
    margin-top: 15px;
    border-top: 1px solid ${(props) => props.theme.app.colors.grey.background};
    padding: 15px 0;

    @media screen and (max-width: 1024px) {
    }

    @media screen and (max-width: 640px) {
        flex-direction: column;
    }
`;

export const ClientContactRowBox: any = styled(Box)`
    margin-top: 15px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-weight: 700;
    font-family: "Mont-Bold", "Montserrat", "Roboto Condensed", "Helvetica", "Arial", "sans-serif";
    padding: 15px;
    //background-color: orange;

    button {
        margin-top: 20px;
    }
`;

export const ClientContactCell: any = styled(Cell)`
    text-align: left;

    @media screen and (max-width: 1024px) {
    }

    @media screen and (max-width: 640px) {
    }
`;
