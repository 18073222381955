import { AuditType } from "Custom/Views/Installations/Audit/InstallationAuditModel";

export const isNullOrUndefined = (object: any | undefined | null): boolean => {
    return object === undefined || object === null;
};

export const isValidDate = (date: any): boolean => {
    return date && Object.prototype.toString.call(date) === "[object Date]" && !isNaN(date);
};

export const isValidDateString = (date: string): boolean => {
    let retVal: boolean = false;
    const regexp = new RegExp(/^(0?[1-9]|[12][0-9]|3[01])[\/](0?[1-9]|1[012])[\/\-]\d{4}$/);

    retVal = regexp.test(date);

    return retVal;
};

export const isEmail = (item: string): boolean => {
    let retVal: boolean = false;
    const regexp = new RegExp(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );

    retVal = regexp.test(item);

    return retVal;
};

export const generateID = (): string => {
    // Math.random should be unique because of its seeding algorithm.
    // Convert it to base 36 (numbers + letters), and grab the first 9 characters
    // after the decimal.
    return "_" + Math.random().toString(36).substr(2, 9);
};

export const IsAuditTypeAlerter = (type: AuditType, createdBy: string) => {
    let retVal: boolean = false;

    if (type === AuditType.SentAlertEmail || type === AuditType.SentAlertSMS || type === AuditType.UnitAlertTripped) {
        retVal = true;
    } else if (type === AuditType.SentPhotoCommand && createdBy.toLocaleUpperCase() === "96232CA1-438F-4546-8EB9-C510B13195CD") {
        retVal = true;
    }
    return retVal;
};

export const IsAuditTypeCommission = (type: AuditType) => {
    let retVal: boolean = false;

    if (type === AuditType.Commissioned) {
        retVal = true;
    }
    return retVal;
};

export const calculateActualHeight = (hypotenuse: number, angleDegrees: number): number => {
    let retVal: number = length;

    const thetaDegrees: number = 180 - angleDegrees;
    const thetaRadians: number = (thetaDegrees * Math.PI) / 180;
    // Math.sin uses radians not degrees.
    // SOH = Sine theta = opposite / hypotenuse.
    // so height = hypotenuse * sine theta (probably)
    retVal = hypotenuse * Math.sin(thetaRadians);
    return retVal;
};
