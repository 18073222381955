import moment from "moment";
//import * as Models from "Custom/Models";
import * as StringUtils from "./string";
import { Address } from "../Models/Domain/Properties";
import { ProjectPropertyListItemModel } from "Custom/Views/Installations/Modals/ProjectPropertyListItemModel";
import { EstateCommonRowDTO } from "Custom/Models/Domain";

export const dateFormat = "D/M/YYYY";
export const doubleDateFormat = "DD/MM/YYYY";
export const isoDateFormat = "YYYY-MM-DDT00:00:00[Z]";
export const timeFormat = "H:mm";
export const dateTimeFormat = `${doubleDateFormat} ${timeFormat}`;

export const formatDate = (date: string) => {
    //AJ: This first check might not be needed, and might be handled by momentDate.isValid().
    //AJ: But I don't have time to test and confirm.
    if (date == undefined || date.length == 0) return "";

    //AJ: Dates without times don't need UTC/local handling
    const momentDate = moment.utc(date).local();

    if (momentDate.isValid() == false) return "";

    return momentDate.format(doubleDateFormat);
};

export const formatTime = (date: string) => {
    //AJ: This first check might not be needed, and might be handled by momentDate.isValid().
    //AJ: But I don't have time to test and confirm.
    if (date == undefined || date.length == 0) return "";

    //AJ: Times without dates don't need UTC/local handling
    const momentDate = moment.utc(date).local();

    if (momentDate.isValid() == false) return "";

    return momentDate.format(timeFormat);
};

export const formatDateTime = (date: string) => {
    //AJ: This first check might not be needed, and might be handled by momentDate.isValid().
    //AJ: But I don't have time to test and confirm.
    if (date == undefined || date.length == 0 || date === "0001-01-01T00:00:00") {
        return "";
    }

    //AJ: DateTimes do need UTC/local handling
    const momentDate = moment.utc(date).local();

    if (momentDate.isValid() == false) {
        return "";
    }

    return momentDate.format(dateTimeFormat);
};

export const formatBooleanYesNo = (value: boolean) => {
    return value == true ? "Yes" : "No";
};

export const formatBooleanYesNoDate = (value: boolean, date: string | undefined) => {
    let retVal: string = "Yes"; // assume yes, to make the logic below so much simpler

    if (value === true) {
        if (date !== undefined && date !== null && date.length > 0) {
            const momentDate = moment.utc(date);
            if (momentDate.isValid() === true) {
                return momentDate.format(doubleDateFormat);
            }
        }
    } else {
        retVal = "No";
    }

    return retVal;
};

export const formatAddress = (address: Address, withPostcode: boolean = false) => {
    let formattedAddress = "";

    if (address.buildingName) formattedAddress += `  ${address.buildingName.trim()},`;

    if (address.addressLine1) formattedAddress += ` ${address.addressLine1.trim()},`;

    if (address.addressLine2) formattedAddress += ` ${address.addressLine2.trim()},`;

    if (address.city) formattedAddress += ` ${address.city.trim()},`;

    if (address.county) formattedAddress += ` ${address.county.trim()},`;

    if (withPostcode === true && address.postcode) formattedAddress += ` ${address.postcode.trim()},`;

    formattedAddress = StringUtils.trimChar(formattedAddress, ",").trim();

    return formattedAddress.replace(",, ", ", ");
};

export const formatCPRAddress = (address: EstateCommonRowDTO, withPostcode: boolean = false) => {
    let formattedAddress = "";

    if (address.addressLine1) formattedAddress += ` ${address.addressLine1.trim()},`;

    if (address.addressLine2) formattedAddress += ` ${address.addressLine2.trim()},`;

    if (address.city) formattedAddress += ` ${address.city.trim()},`;

    if (address.county) formattedAddress += ` ${address.county.trim()},`;

    if (withPostcode === true && address.postcode) formattedAddress += ` ${address.postcode.trim()},`;

    formattedAddress = StringUtils.trimChar(formattedAddress, ",").trim();

    return formattedAddress.replace(",, ", ", ");
};

export const formatPPAddress = (address: ProjectPropertyListItemModel, withPostcode: boolean = false) => {
    let formattedAddress = "";

    if (address.addressLine1) formattedAddress += ` ${address.addressLine1.trim()},`;

    if (address.addressLine2) formattedAddress += ` ${address.addressLine2.trim()},`;

    if (address.city) formattedAddress += ` ${address.city.trim()},`;

    if (address.county) formattedAddress += ` ${address.county.trim()},`;

    if (withPostcode === true && address.postcode) formattedAddress += ` ${address.postcode.trim()},`;

    formattedAddress = StringUtils.trimChar(formattedAddress, ",").trim();

    return formattedAddress.replace(",, ", ", ");
};

export const formatLowercaseAddress = (address: Address, withPostcode: boolean = false) => {
    let formattedAddress = formatAddress(address, withPostcode);
    return formattedAddress.toLocaleLowerCase();
};

export const formatShow = (state: boolean) => {
    return "TODO formatShow";
};

export const formatWarningCount = (warningCount: number) => {
    return "TODO formatWarningCount";
};

/* export const formatMapAddressWithoutPostcode = (address: Microsoft.Maps.IAddress) => {
	let formattedAddress = "";

	if (address.addressLine) formattedAddress += ` ${address.addressLine},`;

	if (address.adminDistrict) formattedAddress += ` ${address.adminDistrict},`;

	if (address.countryRegion) formattedAddress += ` ${address.countryRegion},`;

	if (address.postalCode) formattedAddress += ` ${address.postalCode},`;

	formattedAddress = StringUtils.trimChar(formattedAddress, ",").trim();

	return formattedAddress;
}; */

export const formatValue = (value: string | number | undefined) => {
    if (value == null) {
        return "-";
    }

    if (typeof value === "string" && value.length > 0) {
        return `£ ${value}`;
    }

    if (typeof value === "number") {
        return `£ ${value.toFixed(2)}`;
    }

    return "-";
};
