import { autorun, React, StoresInstance, useObserver, useRouter, useState } from "Core/Base";
import { formatAddress } from "Custom/Utils/format";

import { Box, Button, FormHelperText, Input, MenuItem, Select, TextField, TextFieldProps, Typography, useMediaQuery } from "@material-ui/core";
import { AddEditWrapper } from "Custom/StylesAppSpecific/Controls/AddEditWrapper";
import { DetailContainer } from "Custom/StylesAppSpecific/Controls/DetailsContainer";

import { AppUrls } from "Custom/Globals";
import { CancelBtn } from "Custom/StylesAppSpecific/Controls/CancelBtn";
import { SuccessBtn } from "Custom/StylesAppSpecific/Controls/SuccessBtn";
import { TransferList } from "Custom/Components/TransferList/TransferList";
import ProjectAddEditViewModel from "./ProjectAddEditViewModel";
import { ClientModel, ContractorContactModel, ContractorContactModelDTO, ContractorModelDTO, Property } from "Custom/Models/Domain";
import * as DomainClient from "Custom/Models/Domain/Clients";

import { ProjectAddEditBox } from "./ProjectAddEdit.style";
import { AddEditButtonRow } from "Custom/StylesAppSpecific/Controls/AddEditButtonRow";
import { User } from "Custom/Models/User";
import { RoofcareInput } from "Custom/StylesAppSpecific/Controls/RoofcareInput";
import { ContactModel } from "Custom/Models/Domain/Contacts/ContactModel";
import { ContactModel as LeadContactModel, ContactViewModel } from "Custom/Components/Contact/";
import { roofcareModalStyle } from "Custom/StylesAppSpecific/ModalStyling";
import { AddressModel } from "Custom/Models/Domain/Address/AddressModel";
import { ClientSelectMenuStyling } from "./Project.styles";
import { ClientAddModal } from "../Clients/ClientAddModal";
import { ContactAddModal } from "Custom/Components/Contact/ContactModal.View";
import { PropertyAddModal } from "./PropertyAddModal";
import { useLoadCall } from "Custom/Utils/useLoadCall";
import { NotFoundModal } from "Custom/Components/NotFoundModal/NotFoundModal";
import { isGuid } from "../../Utils/isGuid";
import { Loader } from "Core/Components";
import { ControlLabel } from "../../Components/ControlLabel/ControlLabel";
import { OSMLocation } from "Custom/Utils/OSM";
import { OSMViewModel } from "Custom/ViewModels/OSMViewModel";
import { BackBox, BackToList, BackToListText } from "Custom/StylesAppSpecific/Controls/BackToList";
import NavigatePrevIcon from "@material-ui/icons/NavigateBefore";
import { MainBackToListWrapper } from "../Admin/Condition/Condition.style";

import { NewContactViewModel } from "./Contact";
import Autocomplete from "@material-ui/lab/Autocomplete";

const domainStores = StoresInstance.domain;

export const ProjectAddEditPage: React.FC = () => {
    const modalClasses = roofcareModalStyle();
    const desktopScreen = useMediaQuery("(min-width:600px)");
    const [viewModel] = useState(() => new ProjectAddEditViewModel());
    const { history, match } = useRouter();
    const [newProject, setNewProject] = useState(false);

    const [hasAutoCompleteData, setHasAutoCompleteData] = useState(false);

    const [loader, setLoader] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [searchString, setSearchString] = useState("");
    const [showAddOption, setShowAddOption] = useState(false);
    const [hideClientSelect, setHideClientSelect] = useState(false);
    const [isNew, setIsNew] = useState(false);
    const [osmViewModel] = useState(() => OSMViewModel.Instance);

    const [sourceProperties, setSourceProperties] = useState<Property[]>([]);
    const [destinationProperties, setDestinationProperties] = useState<Property[]>([]);

    const [clients, setClients] = useState<DomainClient.ClientModel[]>([]);
    const [selectedClient, setSelectedClient] = useState<ClientModel | undefined>(undefined);

    const [users, setRegionalManagers] = useState<User[]>([]);
    const [leadContacts, setLeadContact] = useState<ContactModel[]>([]);
    const [contractors, setContractors] = useState<ContractorModelDTO[]>([]);
    const [contractorLeadContacts, setContractorLeadContacts] = useState<ContractorContactModelDTO[]>([]);

    const [propertyOpen, setPropertyOpen] = React.useState(false);
    const [clientOpen, setClientOpen] = React.useState(false);
    const [contactOpen, setContactOpen] = React.useState(false);

    const [newAddress, setNewAddress] = React.useState<AddressModel>(new AddressModel());

    const [showContractorDropdown, setShowContractorDropdown] = React.useState(true);

    const router = useRouter<{ projectId: string; propertyId: string }>();

    const handlePropertyOpen = () => {
        setNewAddress(new AddressModel());
        setPropertyOpen(true);
    };

    const handlePropertyClose = () => {
        setPropertyOpen(false);
    };

    const handleContactClose = () => {
        setContactOpen(false);
    };

    const handleClientClose = () => {
        setClientOpen(false);
    };

    const handleSaveProperty = async (addr: AddressModel) => {
        if (addr.locationLatitude === 0 && addr.locationLongitude === 0) {
            // Then search for the lat and long
            osmViewModel.getLocationForPostcode(addr.postcode).then(async (result: OSMLocation) => {
                addr.setValue("locationLatitude", result.latitude);
                addr.setValue("locationLongitude", result.longitude);

                const ret: boolean = await viewModel.upsertProperty(addr);

                if (ret === true) {
                    setPropertyOpen(false);
                }
            });
        } else {
            const retVal: boolean = await viewModel.upsertProperty(addr);

            if (retVal === true) {
                setPropertyOpen(false);
            }
        }
    };

    let { projectId, clientId } = match.params as any;

    React.useEffect(() => {
        if (clientId !== undefined) {
            viewModel.setValue("clientId", clientId);
            setHideClientSelect(true);
        }
    }, [clientId]);

    const notFound = useLoadCall(
        async () => {
            if (projectId === "0") {
                // Neeed to load properties and clients
                await viewModel.loadProjectRelatedAsync();
                setSourceProperties(viewModel.SourceProperties);
                setDestinationProperties([]);
                setClients(viewModel.AllClients);
                setLeadContact(viewModel.ClientContacts);
                setRegionalManagers(viewModel.AllRegionalManagers);
                setContractors(viewModel.AllContractors);
                setContractorLeadContacts(viewModel.ContractorContacts);
                setIsNew(true);
                setHasAutoCompleteData(true);
                return true;
            } else {
                // Neeed to load properties and clients and the project details
                let promise = await viewModel.loadProjectDetailsAsync(projectId);

                if (promise.wasSuccessful === true) {
                    setClients(viewModel.AllClients);
                    setRegionalManagers(viewModel.AllRegionalManagers);
                    setSourceProperties(viewModel.SourceProperties);
                    setLeadContact(viewModel.ClientContacts);
                    setContractorLeadContacts(viewModel.ContractorContacts);
                    setDestinationProperties(viewModel.DestinationProperties);
                    setContractors(viewModel.AllContractors);
                    setHasAutoCompleteData(true);
                }
                return !!viewModel.getValue("id");
            }
        },
        { customCheck: () => projectId === "0" || isGuid(projectId) },
    );

    React.useEffect(() => {
        if (history.location.pathname === AppUrls.Client.Main.Project.ProjectAddEdit.replace(":projectId", "0")) {
            setNewProject(true);
        }
    }, [history.location.pathname]);

    React.useEffect(() => {
        setErrorMessage(viewModel.errorMessage);
    }, [viewModel.errorMessage]);

    React.useEffect(() => {
        setSourceProperties(viewModel.SourceProperties);
        setDestinationProperties(viewModel.DestinationProperties);
        setLeadContact(viewModel.ClientContacts);
        setShowAddOption(viewModel.getClientId == "" ? false : true);
    }, [viewModel.getClientId]);

    React.useEffect(() => {
        setTimeout(() => {
            setSelectedClient(viewModel.getSelectedClient);
        }, 500);
    }, [viewModel.getSelectedClient]);

    React.useEffect(() => {
        setContractorLeadContacts(viewModel.ContractorContacts);
    }, [viewModel.getContractorId]);

    React.useEffect(() => {
        return autorun(() => {
            setSourceProperties(viewModel.SourceProperties);
            setSearchString(viewModel.getSearchString);
        });
    }, [viewModel]);

    React.useEffect(() => {
        const { path } = router.match;

        switch (path) {
            case AppUrls.Client.ContractorView.Project.ProjectAddEdit: {
                setShowContractorDropdown(false);
                break;
            }

            default: {
                if (showContractorDropdown === false) {
                    setShowContractorDropdown(true);
                }

                break;
            }
        }
    }, []);

    const renderPropertyOption = (value: Property): string => {
        return formatAddress(value, true);
    };

    const generateID = function () {
        // Math.random should be unique because of its seeding algorithm.
        // Convert it to base 36 (numbers + letters), and grab the first 9 characters
        // after the decimal.
        return "_" + Math.random().toString(36).substr(2, 9);
    };

    /*     const handleClientChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        viewModel.set("clientId", event.target.value as any);
        viewModel.set("leadContactId", "");
    }; */

    const handleAutocompleteClientChange = (client: DomainClient.ClientModel) => {
        viewModel.set("clientId", client.id);
        viewModel.set("leadContactId", "");
        setSelectedClient(client);
    };

    const handleUserChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        viewModel.set("regionalSalesManagerId", event.target.value as any);
    };

    const handlePropertyDestinationChange = (newDestination: Property[]): void => {
        const retVal: string[] = newDestination.map((item: Property) => {
            return item.id;
        });

        viewModel.setProperties(retVal);
    };

    const handleLeadContactChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        if (event.target.value !== undefined) {
            viewModel.set("leadContactId", event.target.value as any);
        }
    };

    const handleContractorChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        viewModel.set("contractorId", event.target.value as any);
        viewModel.set("contractorLeadContactId", "");
    };

    const handleContractorLeadContactChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        if (event.target.value !== undefined) {
            viewModel.set("contractorLeadContactId", event.target.value as any);
        }
    };

    const handlePotentialUnitsChange = (event: any) => {
        const value = parseInt(event.target.value);

        if (Number.isInteger(value)) {
            viewModel.set("potentialUnits", value as any);
        }
    };

    const addPropertyClicked = (): void => {
        handlePropertyOpen();
    };

    const onSearchChange = (text: string): void => {
        viewModel.set("searchProperty", text as any);
        //viewModel.setValue("searchProperty", text);
    };

    const setPropertyAddress = (address: AddressModel) => {
        setNewAddress(address);
    };

    const isInError = (fieldName: string) => {
        let isValid = viewModel.getValid(fieldName);
        return !isValid;
    };

    const handleNewClientItemClick = () => {
        setClientOpen(true);
    };

    const handleAddedClient = (clientId: string) => {
        setClients(viewModel.AllClients);
        viewModel.setValue("clientId", clientId);
        viewModel.isFieldValid("clientId", clientId);
    };

    const handleNewContactItemClick = () => {
        setContactOpen(true);
    };

    const handleSaveContact = async (contact: LeadContactModel) => {
        const newContactViewModel = new NewContactViewModel();
        // Set the sourceId to the chosen ClientId
        contact.sourceId = viewModel.getValue("clientId");

        // convert to NewContactDto and upsert
        newContactViewModel.toNewContactModelDTO(contact);
        const promise = newContactViewModel.postNewContactAsync();
        promise.then((result: any) => {
            if (result.wasSuccessful === true) {
                let updatedContacts = newContactViewModel.contactsList.filter((c) => c.clientId === viewModel.getValue("clientId"));
                setLeadContact(updatedContacts);
                // close Modal now
                handleContactClose();
            }
        });
    };

    const handleBack = () => {
        const { path } = router.match;

        let url: string = AppUrls.Client.Main.Project.Root;

        switch (path) {
            case AppUrls.Client.Main.Client.ClientsDetail: {
                url = `${AppUrls.Client.Main.Client.ClientsDetail.replace(":clientId", clientId)}#projects`;
                break;
            }
            case AppUrls.Client.ContractorView.Project.ProjectAddEdit: {
                url = `${AppUrls.Client.ContractorView.Root}/#projects`;
                break;
            }
            default: {
                break;
            }
        }

        history.push(url);
    };

    const handleFocus = (event: any) => event.target.select();

    const renderPage = () => {
        if (viewModel.IsLoading || notFound) return <Loader />;

        return (
            <>
                <ClientSelectMenuStyling />
                <ClientAddModal onClose={handleClientClose} open={clientOpen} onClientAdded={handleAddedClient} />
                <PropertyAddModal onClose={handlePropertyClose} open={propertyOpen} onAdd={handleSaveProperty} onChange={setPropertyAddress} />
                <ContactAddModal onClose={handleContactClose} open={contactOpen} onAdd={handleSaveContact} />

                <MainBackToListWrapper>
                    <BackToList onClick={handleBack}>
                        <BackBox>
                            <NavigatePrevIcon fontSize="large" />
                        </BackBox>
                        <BackToListText>Back to project list</BackToListText>
                    </BackToList>
                </MainBackToListWrapper>

                <AddEditWrapper>
                    <Typography variant="h1" component="h2">
                        {isNew ? "Add new" : "Edit"} project
                    </Typography>

                    <form onSubmit={viewModel.doSubmit} id="detailsPanelForm">
                        <DetailContainer>
                            {errorMessage !== "" && (
                                <Typography variant="caption" style={{ color: "red" }}>
                                    {errorMessage}
                                </Typography>
                            )}
                            <ProjectAddEditBox>
                                <Box display="flex">
                                    <Box className="wide-input" maxWidth="640px" width="50%" paddingTop="8px">
                                        <ControlLabel label="Project name" htmlFor="project-name">
                                            <RoofcareInput id="project-name" viewModel={viewModel} fieldName="name" fullWidth={true} />
                                        </ControlLabel>
                                    </Box>

                                    <Box className="cell-right" paddingTop="8px" width="100%">
                                        <ControlLabel label="Regional Sales Manager" htmlFor="user-select">
                                            <Box maxWidth="200px">
                                                <Select
                                                    id="user-select"
                                                    value={viewModel.model.regionalSalesManagerId}
                                                    onChange={handleUserChange}
                                                    MenuProps={{
                                                        getContentAnchorEl: null,
                                                        anchorOrigin: {
                                                            vertical: "bottom",
                                                            horizontal: "left",
                                                        },
                                                    }}
                                                    fullWidth
                                                >
                                                    {users.map((user: User, index: number) => {
                                                        return (
                                                            <MenuItem key={generateID()} value={user.id}>
                                                                {user.displayName}
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </Select>
                                                <Box>
                                                    {isInError("regionalSalesManagerId") && (
                                                        <FormHelperText style={{ color: "red" }}>{viewModel.getError("regionalSalesManagerId")}</FormHelperText>
                                                    )}
                                                </Box>
                                            </Box>
                                        </ControlLabel>
                                    </Box>
                                </Box>

                                <Box className="row">
                                    {hasAutoCompleteData && (selectedClient || isNew) && (
                                        <Box className="cell-left" maxWidth="200px">
                                            <ControlLabel label="End user name" htmlFor="client-select">
                                                <Autocomplete
                                                    blurOnSelect
                                                    id="pp-autocomplete"
                                                    disableClearable
                                                    options={viewModel.getClients}
                                                    getOptionLabel={(option: ClientModel) => option.clientName}
                                                    onChange={(event: any, value: ClientModel) => handleAutocompleteClientChange(value)}
                                                    defaultValue={selectedClient}
                                                    value={selectedClient !== undefined ? selectedClient : undefined}
                                                    renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => {
                                                        return (
                                                            <TextField
                                                                {...params}
                                                                variant="standard"
                                                                InputProps={{
                                                                    ...params.InputProps,
                                                                    style: { padding: "0 10px" },
                                                                }}
                                                            />
                                                        );
                                                    }}
                                                    noOptionsText={
                                                        <MenuItem style={{ color: "#337AB7" }} key="generateID()" onMouseDown={handleNewClientItemClick} value="">
                                                            + Add new end user
                                                        </MenuItem>
                                                    }
                                                />
                                                <Box>{isInError("clientId") && <FormHelperText style={{ color: "red" }}>{viewModel.getError("clientId")}</FormHelperText>}</Box>
                                            </ControlLabel>
                                        </Box>
                                    )}
                                    <Box className="cell-right" maxWidth="200px" marginLeft={hideClientSelect ? "0 !important" : undefined}>
                                        <ControlLabel label="Lead Contact" htmlFor="leadcontact-select">
                                            <Select
                                                id="leadcontact-select"
                                                value={viewModel.model.leadContactId}
                                                onChange={handleLeadContactChange}
                                                MenuProps={{
                                                    getContentAnchorEl: null,
                                                    anchorOrigin: {
                                                        vertical: "bottom",
                                                        horizontal: "left",
                                                    },
                                                    className: "client-select-menu",
                                                }}
                                            >
                                                {leadContacts.map((client: ContactModel, index: number) => {
                                                    return (
                                                        <MenuItem key={generateID()} value={client.id}>
                                                            {client.displayName}
                                                        </MenuItem>
                                                    );
                                                })}
                                                <MenuItem key="generateID()" onClick={handleNewContactItemClick} value="">
                                                    + Add new contact
                                                </MenuItem>
                                            </Select>
                                            {isInError("leadContactId") && <FormHelperText style={{ color: "red" }}>{viewModel.getError("leadContactId")}</FormHelperText>}
                                        </ControlLabel>
                                    </Box>
                                    {showContractorDropdown && (
                                        <>
                                            <Box className="cell-right" maxWidth="200px" marginLeft={hideClientSelect ? "0 !important" : undefined}>
                                                <ControlLabel label="Contractor" htmlFor="contractor-select">
                                                    <Select
                                                        id="contractor-select"
                                                        value={viewModel.model.contractorId}
                                                        onChange={handleContractorChange}
                                                        MenuProps={{
                                                            getContentAnchorEl: null,
                                                            anchorOrigin: {
                                                                vertical: "bottom",
                                                                horizontal: "left",
                                                            },
                                                        }}
                                                    >
                                                        {contractors.map((contractor: ContractorModelDTO, index: number) => {
                                                            return (
                                                                <MenuItem key={generateID()} value={contractor.id}>
                                                                    {contractor.contractorName}
                                                                </MenuItem>
                                                            );
                                                        })}
                                                    </Select>
                                                    {isInError("contractorId") && <FormHelperText style={{ color: "red" }}>{viewModel.getError("contractorId")}</FormHelperText>}
                                                </ControlLabel>
                                            </Box>
                                            <Box className="cell-right" maxWidth="200px" marginLeft={hideClientSelect ? "0 !important" : undefined}>
                                                <ControlLabel label="Contractor Lead Contact" htmlFor="contractorleadcontact-select">
                                                    <Select
                                                        id="contractorleadcontact-select"
                                                        value={viewModel.model.contractorLeadContactId}
                                                        onChange={handleContractorLeadContactChange}
                                                        MenuProps={{
                                                            getContentAnchorEl: null,
                                                            anchorOrigin: {
                                                                vertical: "bottom",
                                                                horizontal: "left",
                                                            },
                                                            className: "client-select-menu",
                                                        }}
                                                    >
                                                        {contractorLeadContacts.map((contact: ContractorContactModelDTO, index: number) => {
                                                            return (
                                                                <MenuItem key={generateID()} value={contact.id}>
                                                                    {contact.firstName + " " + contact.lastName}
                                                                </MenuItem>
                                                            );
                                                        })}
                                                    </Select>
                                                    {isInError("contractorLeadContactId") && (
                                                        <FormHelperText style={{ color: "red" }}>{viewModel.getError("contractorLeadContactId")}</FormHelperText>
                                                    )}
                                                </ControlLabel>
                                            </Box>
                                        </>
                                    )}
                                </Box>
                                <Box className="row">
                                    <Box className="cell-left" maxWidth="200px">
                                        <ControlLabel label="Potential Units" htmlFor="potential-units">
                                            <RoofcareInput
                                                id="potential-units"
                                                className="form-control"
                                                type="number"
                                                fullWidth={true}
                                                viewModel={viewModel}
                                                fieldName="potentialUnits"
                                                /* onChange={handlePotentialUnitsChange} */
                                                onFocus={handleFocus}
                                                inputProps={{
                                                    placeholder: "Range 0-1000",
                                                    maxLength: 4,
                                                    min: 0,
                                                    max: 1000,
                                                }}
                                            />
                                            {/*  {isInError("potentialUnits") && <FormHelperText style={{ color: "red" }}>{viewModel.getError("potentialUnits")}</FormHelperText>} */}
                                        </ControlLabel>
                                    </Box>
                                </Box>
                                <Typography variant="h1" component="h2">
                                    Add properties to project
                                </Typography>
                                <Box>{isInError("propertyIds") && <FormHelperText style={{ color: "red" }}>{viewModel.getError("propertyIds")}</FormHelperText>}</Box>
                                <TransferList
                                    //searchString={searchString}
                                    source={sourceProperties}
                                    destination={destinationProperties}
                                    allOption={false}
                                    renderOption={renderPropertyOption}
                                    onDestinationChange={handlePropertyDestinationChange}
                                    sourceTitle="All properties"
                                    destinationTitle="Project properties"
                                    showAddOption={showAddOption}
                                    showSearch={true}
                                    addOptionText="+ Add new property"
                                    onAddOptionClicked={addPropertyClicked}
                                    placeholderText={"Search for name of property"}
                                    onSearchChange={onSearchChange}
                                />
                            </ProjectAddEditBox>
                            <AddEditButtonRow>
                                <SuccessBtn id="save" variant="contained" type="submit" disabled={loader}>
                                    {isNew ? <>{loader ? "Creating" : "Create project"}</> : <>{!loader ? "Save changes" : "Saving"}</>}
                                </SuccessBtn>
                                <CancelBtn
                                    id="cancel"
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => {
                                        handleBack();
                                    }}
                                >
                                    Cancel
                                </CancelBtn>
                            </AddEditButtonRow>
                        </DetailContainer>
                    </form>
                </AddEditWrapper>
            </>
        );
    };

    return useObserver(() => (
        <>
            <NotFoundModal returnRoute={AppUrls.Client.Main.Project.Root} open={notFound} />
            {renderPage()}
        </>
    ));
};
