import { Box } from "@material-ui/core";
import * as d3 from "d3";
import React, { useRef, useEffect } from "react";
import { generateID, isEmptyOrWhitespace } from "Core/Utils/Utils";
import { DeviceAlertActionItemModelDTO } from "Custom/Views/Installations/TabConfigurationModel";
import { AnalyticBox } from "./AlertAnalyticsBarChart.style";
import { ConditionSetViewModel } from "Custom/Views/Installations/Condition/ConditionSetViewModel";

export interface IBarChartData {
    colour: string;
    details: string;
    count: number;
}

interface IAlertAnalyticBarChart {
    imageHeight: number;
    imageWidth: number;
    marginTop: number;
    alertAnalytics: DeviceAlertActionItemModelDTO[];
    total: number;
    isLoading: boolean;
    title: string;
    counter: number;
    backgroundColour?: string;
    textColor?: string;

    halfgraph?: boolean;
    isSiphonics?: boolean;
}

export const AlertAnalyticBarChart: React.FC<IAlertAnalyticBarChart> = (props) => {
    // #region Code Behind
    const { halfgraph, imageHeight, imageWidth, isSiphonics, marginTop, alertAnalytics, textColor, total, isLoading, title, counter, backgroundColour } = props;

    let barHeight = 40;
    let line = d3.line();

    let barMarginLeft: number = 120;
    let barMarginRight: number = 60;

    if (isSiphonics !== undefined && isSiphonics === true) {
        barMarginLeft = 60;
        barMarginRight = 40;
    }

    let bgColor: string = backgroundColour !== undefined ? backgroundColour : "#ffffff";

    let textLinesColor: string = textColor !== undefined ? textColor : "#000000";

    // The useRef Hook creates a variable that "holds on" to a value across rendering passes. In
    // this case it will hold our component's SVG DOM element. It's initialized null and React
    // will assign it later (see the return statement).
    const svgElement: React.MutableRefObject<null> = useRef(null);

    useEffect(() => {
        return () => {
            // Clean up after yourself
            if (svgElement.current !== null) {
                const svg = d3.select(svgElement.current);
                svg.remove();
            }
            const svg = d3.select(svgElement.current);
            svg.selectAll().remove();
            svg.selectAll(".legend-title").remove();
            svg.selectAll(".barchart-background").remove();
            svg.selectAll(".bartextTotal").remove();
            svg.selectAll(".bartext").remove();
            svg.selectAll(".bar").remove();
        };
    }, []);

    // The useEffect hook is for running side effects outside of React, for instance inserting
    // elements into the DOM using D3.
    useEffect(() => {
        if (alertAnalytics !== null && alertAnalytics.length > 0 && svgElement.current !== null) {
            const topAxisY: number = marginTop + 5;
            const bottomAxisY: number = imageHeight - marginTop - 5;

            let sortedData: DeviceAlertActionItemModelDTO[] = [];

            if (isSiphonics !== undefined && isSiphonics === true) {
                sortedData = alertAnalytics.sort((a: DeviceAlertActionItemModelDTO, b: DeviceAlertActionItemModelDTO) => {
                    if (a === undefined && b === undefined) {
                        return 0;
                    }

                    if (a === undefined) {
                        return -1;
                    }

                    if (b === undefined) {
                        return 1;
                    }

                    return a.cutOff < b.cutOff ? -1 : 1;
                });
            } else {
                sortedData = alertAnalytics.sort((a: DeviceAlertActionItemModelDTO, b: DeviceAlertActionItemModelDTO) => {
                    if (a === undefined && b === undefined) {
                        return 0;
                    }

                    if (a === undefined) {
                        return -1;
                    }

                    if (b === undefined) {
                        return 1;
                    }

                    return b.cutOff < a.cutOff ? -1 : 1;
                });
            }
            // Map the data.
            const data = Array.from(sortedData).map((item: DeviceAlertActionItemModelDTO) => ({
                colour: item.alertColour,
                key: item.name as string,
                value: item.count as number,
            }));

            let maxValue: number = 0;

            if (data.length > 0) {
                if (data.length == 6) {
                    barHeight = barHeight - 20;
                } else if (data.length > 3) {
                    barHeight = barHeight - 10;
                }

                maxValue = Math.max(
                    ...data.map(function (o) {
                        return o.value;
                    }),
                );
            }

            // Bind D3 data.
            const svg = d3.select(svgElement.current);
            svg.selectAll().remove();
            svg.selectAll(".legend-title").remove();
            svg.selectAll(".barchart-background").remove();
            svg.selectAll(".bartextTotal").remove();
            svg.selectAll(".bartext").remove();
            svg.selectAll(".bar").remove();
            svg.append("polygon")
                .attr("class", "barchart-background")
                .attr("points", 0 + "," + 0 + " " + 0 + "," + imageHeight + " " + imageWidth + "," + imageHeight + " " + imageWidth + "," + 0)
                .attr("style", "fill: " + bgColor + ";");

            if (data.length > 0) {
                // The chart.
                const xScale = d3
                    .scaleLinear<any>()
                    .domain([0, d3.max(data, (d: any) => d.value)])
                    .range([barMarginLeft, imageWidth - barMarginRight]);

                // Backup bar colours.
                const barColour = ["#E81123", "#FFB900", "#498205", "#00B7C3", "#0078D7", "#C239B3"];

                svg.append("text")
                    .attr("class", "legend-title")
                    .attr("data-name", "Legend")
                    .attr("fill", textLinesColor)
                    .attr("fontFamily", "Mont-Regular, Mont")
                    .attr("style", "font-size: 16px; text-anchor: middle")
                    .append("tspan")
                    .attr("x", imageWidth / 2)
                    .attr("y", "25")
                    .text(title);

                // Add the Axes.
                const yScale = d3
                    .scaleBand()
                    .range([0, bottomAxisY])
                    .domain(data.map((d) => d.key))
                    .padding(0.2);

                let tempMax: number = maxValue;

                if (tempMax === 0) {
                    tempMax = 6;
                }

                // Draw y-axis  since can't get d3 to do it for me

                let yaxisLine: [number, number][] = [
                    [barMarginLeft - 1, topAxisY],
                    [barMarginLeft - 1, bottomAxisY + 10],
                ];

                let pathOfYAxis: string | null = line(yaxisLine);

                if (pathOfYAxis !== null) {
                    svg.append("path")
                        .attr("class", "attributes")
                        .attr("d", pathOfYAxis)
                        .attr("fill", "none")
                        .attr("stroke", textLinesColor)
                        .style("stroke-width", function (d) {
                            return "1";
                        });
                }

                // Add the x-axis. ticks
                let tickInterval: number = 1;

                if (maxValue < 20) {
                    tickInterval = 1;
                } else if (maxValue < 50) {
                    tickInterval = 2;
                } else if (maxValue < 100) {
                    tickInterval = 5;
                } else if (maxValue < 200) {
                    tickInterval = 10;
                } else {
                    tickInterval = 20;
                }

                if (halfgraph !== undefined && halfgraph !== null && halfgraph === true) {
                    if (maxValue < 10) {
                        tickInterval = 1;
                    } else if (maxValue < 20) {
                        tickInterval = 2;
                    } else if (maxValue < 50) {
                        tickInterval = 5;
                    } else if (maxValue < 100) {
                        tickInterval = 10;
                    } else if (maxValue < 200) {
                        tickInterval = 20;
                    } else if (maxValue < 300) {
                        tickInterval = 30;
                    } else if (maxValue < 1000) {
                        tickInterval = 100;
                    } else if (maxValue < 10000) {
                        tickInterval = 1000;
                    } else {
                        tickInterval = 2000;
                    }
                }

                for (let i = 1; i <= maxValue; i += tickInterval) {
                    let tickLine: [number, number][] = [
                        [xScale(i), topAxisY],
                        [xScale(i), bottomAxisY + 10],
                    ];

                    let pathOfTick: string | null = line(tickLine);

                    if (pathOfTick !== null) {
                        svg.append("path")
                            .attr("class", "attributes")
                            .attr("d", pathOfTick)
                            .attr("fill", "none")
                            .attr("stroke", textLinesColor)
                            .attr("opacity", "0.3")
                            .style("stroke-width", function (d) {
                                return "1";
                            });

                        svg.append("text")
                            .attr("class", "tick")
                            .attr("data-name", "tick")
                            .attr("fill", textLinesColor)
                            .attr("fontFamily", "Mont-Regular, Mont")
                            .attr("style", "font-size: 8px; text-anchor: middle")
                            .append("tspan")
                            .attr("x", xScale(i))
                            .attr("y", bottomAxisY + 25)
                            .text(i.toString());
                    }
                }

                // Add the bars.
                svg.selectAll(".bar")
                    .data(data)
                    .join(
                        (enter) =>
                            enter
                                .append("rect")
                                .attr("class", "bar alertaction-band")
                                .attr("key", generateID())
                                .attr("fill", (d, index) => (isEmptyOrWhitespace(d.colour) ? barColour[index] : d.colour))
                                .attr("x", xScale(0))
                                .attr("y", (d, index) => yScale(data[index]!.key!)! + marginTop)

                                .attr("width", (d) => xScale(d.value) - xScale(0))
                                .attr("height", barHeight),
                        (update) => update.attr("width", (d) => xScale(d.value) - xScale(0)),
                        (exit) => exit.remove(),
                    );

                // Bar text.
                svg.selectAll(".bartext")
                    .data(data)
                    .join(
                        (enter) =>
                            enter
                                .append("text")
                                .attr("class", "bartext")
                                .attr("key", generateID())
                                .attr("fill", textLinesColor)
                                .attr("font-family", "Mont-Regular")
                                .attr("font-size", 14)
                                .attr("x", 15)
                                .attr("y", (d, index) => yScale(data[index]!.key!)! + marginTop + barHeight / 2 + 3)
                                .text((d) => `${d.key}`),
                        (update) => update.text((d) => `${d.key}`),
                        (exit) => exit.remove(),
                    );

                // Add the Totals
                svg.selectAll(".bartextTotal")
                    .data(data)
                    .join(
                        (enter) =>
                            enter
                                .append("text")
                                .attr("class", "bartextTotal alertaction-bandtext")
                                .attr("key", generateID())
                                .attr("fill", textLinesColor)
                                .attr("font-family", "Mont-Regular")
                                .attr("font-size", 14)
                                .attr("x", (d) => xScale(d.value) - xScale(0) + barMarginLeft + 8)
                                .attr("y", (d, index) => yScale(data[index]!.key!)! + marginTop + barHeight / 2 + 3)
                                .text((d) => `${d.value} `),
                        (update) => update.text((d) => `${d.value} `),
                        (exit) => exit.remove(),
                    );
            }
        } else {
            if (svgElement.current !== null) {
                const svg = d3.select(svgElement.current);
                svg.selectAll().remove();
                svg.selectAll(".legend-title").remove();
                svg.selectAll(".barchart-background").remove();
                svg.selectAll(".bartextTotal").remove();
                svg.selectAll(".bartext").remove();
                svg.selectAll(".bar").remove();
                svg.append("polygon")
                    .attr("class", "barchart-background")
                    .attr("points", 0 + "," + 0 + " " + 0 + "," + imageHeight + " " + imageWidth + "," + imageHeight + " " + imageWidth + "," + 0)
                    .attr("style", "fill: " + bgColor + ";");

                svg.append("text")
                    .attr("class", "legend-title")
                    .attr("data-name", "Legend")
                    .attr("transform", "translate(" + (imageWidth / 2 + 30) + " 19)")
                    .attr("fill", textLinesColor)
                    .attr("style", "font-size: 16px; text-anchor: middle")
                    .attr("fontFamily", "Mont-Regular, Mont")
                    .append("tspan")
                    .attr("x", "-39")
                    .attr("y", "10")
                    .text("No analytics set");
            }
        }
    }, [alertAnalytics, imageHeight, imageWidth, marginTop, total, title, svgElement.current, counter]);

    // #endregion Code Behind

    return (
        <AnalyticBox>
            <svg xmlns="http://www.w3.org/2000/svg" ref={svgElement} height={imageHeight} width={imageWidth} viewBox={`0, 0, ` + imageWidth + `  ` + imageHeight} />
        </AnalyticBox>
    );
};
