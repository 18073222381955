import styled from "styled-components";
import { withProps } from "../../../Core/Utils/React/WithProps";
import { Button } from "@material-ui/core";
import sendIcon from "Custom/Content/sendCommandIcon.svg";

const _SuccessBtn = withProps(Button)({
    variant: "contained",
    color: "secondary",
});

export const SuccessBtn = styled(_SuccessBtn)`
    max-width: 179px;
    height: 34px;

    :disabled {
        opacity: 0.5;
        background-color: ${(props) => props.theme.app.colors.success.dark};
        color: white;
    }

    background: ${(props) => props.theme.app.colors.success.dark} 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #000000bf;
    border-radius: 100px;
    opacity: 1;
`;

export const SuccessExtBtn = styled(SuccessBtn)`
    max-width: 250px !important;
    min-width: 220px !important;
`;

export const SendBtn = styled(Button)`
    width: 94px;
    height: 22px;
    background: url(${sendIcon}) no-repeat;
    background-size: cover;
    color: ${(props) => props.theme.app.colors.white.main};
    text-align: left;
    font: normal normal normal 10px/14px Mont;
    letter-spacing: 0px;

    .btnText {
        margin-left: -7px;
        margin-top: 2px;
    }

    :disabled {
        opacity: 0.5;
        /* background-color: ${(props) => props.theme.app.colors.success.dark}; */
        color: white;
    }
`;
