import { Box, Checkbox, TextareaAutosize } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";

import { UnitNoteSectionViewModel } from "./UnitNoteSectionViewModel";
import { DefaultUnitNote, UnitNoteDTO } from "../UnitNote";
import { ContractorBox, DeviceCell, UnitRow } from "../UnitEditDataModel.styles";
import { AddItemText } from "Custom/StylesAppSpecific/Controls/AddEditWrapper";
import { BoxAdd } from "Custom/StylesAppSpecific/Controls/BoxAddWrapper";
import { formatDateTime } from "Custom/Utils/format";
import { SuccessBtn } from "Custom/StylesAppSpecific/Controls/SuccessBtn";
import { CancelBtn } from "Custom/StylesAppSpecific/Controls/CancelBtn";
import EditLogo from "Custom/Content/editIcon.svg";
import { HrLine } from "../../CustomComponents";
import { NotesHeader, NotesTable, NotesTableRow } from "../../Installations/NotesTableRow.style";
import { useObserver } from "mobx-react-lite";
import { generateID } from "Custom/Utils/utils";

interface IUnitNoteSectionProps {
    isLoading: boolean;
    unitNotes: UnitNoteDTO[];
    canAddNotes: boolean;
    setNotes: (payload: UnitNoteDTO[]) => any;
    deviceDbId: number;
}

interface INoteState {
    selectedItemId: number | undefined;
    selectedNote: string;
    isDeleted: boolean;
}

export const UnitNoteSection: React.FunctionComponent<IUnitNoteSectionProps> = ({ canAddNotes, deviceDbId, isLoading, setNotes, unitNotes }) => {
    const [viewModel] = useState(() => new UnitNoteSectionViewModel(deviceDbId));
    const [newNote, setNewNote] = React.useState<INoteState>({ selectedItemId: undefined, selectedNote: "", isDeleted: false });
    const [showAddNoteSection, setShowAddNoteSection] = useState(false);

    //useEffect below only gets run on initial render
    useEffect(() => {
        // *optional* passing the router into the viewmodel so we can navigate from there
        //console.log("First render");
        //This gets run when the page is exited
        return () => {
            //console.log("Unmounting");

            setShowAddNoteSection(false);
            setNewNote({ selectedItemId: undefined, selectedNote: "", isDeleted: false });
        };
    }, []);

    const handleNewClick = () => {
        setShowAddNoteSection(true);
    };

    const createNote = () => {
        let noteToUpsert = DefaultUnitNote();

        if (newNote.selectedItemId === undefined || newNote.selectedItemId! < 1) {
            // then this is a new note so nothing to do really
        } else {
            // we are updating an existing note, so find it

            const found: UnitNoteDTO | undefined = unitNotes.find((a) => a.id === newNote.selectedItemId);

            if (found !== undefined) {
                noteToUpsert.deviceDbId = found.deviceDbId;
                noteToUpsert.id = newNote.selectedItemId;
                noteToUpsert.isDeleted = found.isDeleted;
                noteToUpsert.rowVersion = found.rowVersion;
                // reset the author, not sure if we should change the publishDate.
                noteToUpsert.authorId = null;
                noteToUpsert.publishDate = found.publishDate;
            }
        }
        // write in the note
        noteToUpsert.note = newNote.selectedNote;
        noteToUpsert.isDeleted = newNote.isDeleted;

        viewModel.setNote(noteToUpsert);
        let promise = viewModel.upsertNote();

        promise.then((apiResult: any) => {
            if (apiResult.wasSuccessful) {
                setNotes(apiResult.payload);
                setShowAddNoteSection(false);
                setNewNote({ selectedItemId: undefined, selectedNote: "", isDeleted: false });
            }
        });
    };

    const cancelNote = () => {
        setShowAddNoteSection(false);
        setNewNote({ selectedItemId: undefined, selectedNote: "", isDeleted: false });
    };

    const toggleIsDeleted = () => {
        setNewNote({ selectedItemId: newNote.selectedItemId, selectedNote: newNote.selectedNote, isDeleted: !newNote.isDeleted });
    };

    const handleNoteChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setNewNote({ selectedItemId: newNote.selectedItemId, selectedNote: e.target.value, isDeleted: newNote.isDeleted });
    };

    const handleEditNote = (note: UnitNoteDTO) => {
        setNewNote({ selectedItemId: note.id!, selectedNote: note.note, isDeleted: note.isDeleted });
        setShowAddNoteSection(true);
    };

    return useObserver(() => (
        <>
            {isLoading === false && (
                <NotesHeader>
                    <div className="notes">Notes</div>
                    {showAddNoteSection === false && canAddNotes === true && (
                        <Box className="titleRow">
                            <BoxAdd onClick={() => handleNewClick()}>
                                <AddItemText fontcolor="#F29D29" style={{ paddingLeft: "0!important" }}>
                                    <div style={{ marginLeft: "-15px", color: "#F29D29" }}>+ Add Note</div>
                                </AddItemText>
                            </BoxAdd>
                        </Box>
                    )}
                    {showAddNoteSection === true && (
                        <>
                            <DeviceCell>
                                <TextareaAutosize value={newNote.selectedNote} onChange={handleNoteChange} className="note-textarea" />
                            </DeviceCell>
                            {newNote.selectedItemId !== undefined && newNote.selectedItemId > 0 && (
                                <DeviceCell>
                                    <div className="device-hide" style={{ marginTop: "-20px" }}>
                                        <Checkbox checked={newNote.isDeleted} onChange={() => toggleIsDeleted()}></Checkbox>
                                        <label>Remove Note</label>
                                    </div>
                                </DeviceCell>
                            )}
                            <Box style={{ display: "flex", flex: "1", paddingBottom: "15px" }}>
                                <SuccessBtn disabled={newNote.selectedNote.length === 0} onClick={createNote}>
                                    Save Note
                                </SuccessBtn>
                                <CancelBtn onClick={cancelNote}>Cancel Note</CancelBtn>
                            </Box>
                        </>
                    )}
                </NotesHeader>
            )}
            <ContractorBox>
                <UnitRow>
                    <NotesTable key={generateID()}>
                        {unitNotes.map((note: UnitNoteDTO, index: number) => {
                            let title: string = formatDateTime(note.publishDate) + " added by " + note.author;
                            return (
                                <div className="notes-row">
                                    <NotesTableRow key={"devicenote-" + index}>
                                        <div className="notetablerow">
                                            <div className="title">{title}</div>
                                            {canAddNotes === true && (
                                                <div className="editbtn" onClick={() => handleEditNote(note)}>
                                                    <img src={EditLogo} /> Edit
                                                </div>
                                            )}
                                        </div>
                                        <div className="note">{note.note}</div>
                                    </NotesTableRow>
                                    {index < unitNotes.length - 1 && <HrLine marginTop="25px" marginBottom="25px" />}
                                </div>
                            );
                        })}
                        {unitNotes.length > 0 && <HrLine type={"solid"} marginTop="25px" marginBottom="0px" />}
                    </NotesTable>
                </UnitRow>
            </ContractorBox>
        </>
    ));
};
