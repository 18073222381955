import { SortDirection } from "Custom/Models";
import * as Utils from "Custom/Utils/array";

export interface DocumentCategory {
    id: number;
    categoryName: string;
    ordinal: number;
    isDeleted: boolean;
}

export class DocumentCategoryHelpers {
    public static getClassName = (id: number) => {
        const number = parseInt(id as any);

        if (isNaN(number) == true) {
            return "documentCategory-unknown";
        } else {
            return `documentCategory-${id}`;
        }
    };

    public static getOptions = (ignoreUnknown: boolean = false, documentCategories: DocumentCategory[]) => {
        let options: {
            display: string;
            className: string;
            value: string;
        }[] = [];

        let docCats = Utils.sortBy(documentCategories, "ordinal", SortDirection.Asc);

        for (let i: number = 0; i < docCats.length; i++) {
            let dc = docCats[i];

            if (dc.isDeleted === false) {
                options.push({
                    className: DocumentCategoryHelpers.getClassName(i + 1),
                    display: dc.categoryName,
                    value: dc.id.toString(),
                });
            }
        }

        return options;
    };
}
