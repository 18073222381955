import React from "react";
import { ClientDetailsViewModel } from "./ClientDetailsViewModel";
import * as MobXReact from "mobx-react-lite";
import { ClientProjectsList } from "Custom/Views/ClientView/ClientProjectsList";

interface IProps {
    viewModel: ClientDetailsViewModel;
}

export const ClientDetailsProjectList: React.FC<IProps> = ({ viewModel }) => {
    return MobXReact.useObserver(() => (
        <ClientProjectsList projects={viewModel.projects} projectStatuses={viewModel.projectStatuses} projectPaymentStatuses={viewModel.projectPaymentStatuses} />
    ));
};
