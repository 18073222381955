import { DEFAULTCELLCOLOUR, FLOODCOLOUR } from "Custom/Globals/Globals";
import { ConditionType, ConditionUnits, DrainageType, InstallationStatusDataDTO, RoofType } from "Custom/Models/Domain";
import * as d3 from "d3";
import { useEffect, useRef, useState } from "react";
import { DynamicSettingsImageBox } from "./DynamicSettingsImage.style";
import { BandData } from "./BandData";
import { generateID } from "Core/Utils/Utils";

export interface IDynamicSettingsImageProps {
    imageHeight: number;
    imageWidth: number;
    marginTop: number;
    deviceStatusData: InstallationStatusDataDTO | undefined;
    conditionSetData: BandData[];
    alertActions: BandData[];
    showAlertActions: boolean;
    showAttributes: boolean;
}

export function DynamicSettingsImage(props: IDynamicSettingsImageProps) {
    const DEFAULTOUTSIDECOLOUR: string = DEFAULTCELLCOLOUR;
    const { alertActions, deviceStatusData, conditionSetData, imageHeight, imageWidth, showAlertActions, showAttributes } = props;

    const attributeLineColour: string = "#000000";
    const alertLineColour: string = "#000000";
    const legendBandHeight: number = 22;
    const barLength: number = 320;
    const floodAreaHeight: number = 33;
    const yMax = 170; // overallHeight - floodAreaHeight for the fixed flood

    const angle: number = 18;
    const offset: number = yMax + floodAreaHeight;
    const radianAngle: number = (angle * Math.PI) / 180.0;
    const tanAngle: number = Math.tan(radianAngle);

    let timingMultiplier: number = 1;
    let hasData: boolean = false;
    let dataValueScale: d3.ScaleLinear<number, number, never> = d3.scaleLinear();

    let maxWorkingHeightValue: number = 0;

    if (conditionSetData !== null && conditionSetData !== undefined) {
        hasData = true;
    }

    // The useRef Hook creates a variable that "holds on" to a value across rendering passes. In
    // this case it will hold our component's SVG DOM element. It's initialized null and React
    // will assign it later (see the return statement).
    const svgElement = useRef(null);

    const generateCommonData = (): BandData[] => {
        if (props.deviceStatusData !== null && props.deviceStatusData !== undefined && svgElement.current !== null) {
            // Sort the data out
            maxWorkingHeightValue = Math.min(deviceStatusData!.side1_Height, deviceStatusData!.side2_Height);

            if (deviceStatusData!.roofType === RoofType.Flat) {
                maxWorkingHeightValue = deviceStatusData!.maxHeight;
            }

            // Add baseCondition
            let baseCondition: BandData = {
                id: null,
                name: "Base Condition",
                type: ConditionType.H,
                value: 0,
                units: ConditionUnits.MM,
                backgroundColour: DEFAULTOUTSIDECOLOUR,
                textColour: "#ffffff",
                calculatedValue: 0,
            };

            let bandData: BandData[] = [];
            let bandDataAndFlood: BandData[] = [];

            if (hasData === true) {
                conditionSetData.sort((a: BandData, b: BandData) => {
                    if (a === undefined && b === undefined) {
                        return 0;
                    }

                    if (a === undefined) {
                        return -1;
                    }

                    if (b === undefined) {
                        return 1;
                    }

                    return a.calculatedValue < b.calculatedValue ? -1 : 1;
                });

                if (conditionSetData.length > 0) {
                    if (conditionSetData[0].calculatedValue !== 0) {
                        bandData.push(baseCondition);
                        bandDataAndFlood.push(baseCondition);
                    }

                    for (let i: number = 0; i < conditionSetData.length; i++) {
                        bandData.push(conditionSetData[i]);
                        bandDataAndFlood.push(conditionSetData[i]);
                    }
                }
            } else {
                bandData.push(baseCondition);
            }

            const data: number[] = bandData.map((a) => a.calculatedValue);
            data.push(maxWorkingHeightValue);

            let maxValue = Math.max.apply(null, data);

            // Sort out the scale
            dataValueScale = d3.scaleLinear().domain([0, maxValue]).range([0, yMax]);

            return bandDataAndFlood;
        }
        return [];
    };

    useEffect(() => {
        return () => {
            // Clean up after yourself
            if (svgElement.current !== null) {
                const svg = d3.select(svgElement.current);
                svg.remove();
            }
        };
    }, []);

    useEffect(() => {
        let showFloodAndLegend = false;

        if (
            props.deviceStatusData !== null &&
            props.deviceStatusData !== undefined &&
            svgElement.current !== null &&
            props.conditionSetData !== null &&
            props.conditionSetData !== undefined &&
            props.conditionSetData.length > 0
        ) {
            showFloodAndLegend = true;
        }

        if (props.deviceStatusData !== null && props.deviceStatusData !== undefined && svgElement.current !== null) {
            let bandDataAndFlood: BandData[] = generateCommonData();

            let floodCondition: BandData = {
                id: null,
                name: "Flood",
                type: ConditionType.H,
                value: 0,
                units: ConditionUnits.MM,
                backgroundColour: FLOODCOLOUR,
                textColour: "#ffffff",
                calculatedValue: maxWorkingHeightValue,
            };

            // Bind D3 data.
            const svg = d3.select(svgElement.current);
            // Clear out the image so we can start again. Needed for the alert toggle to work and to stop text layering
            svg.selectAll().remove();
            svg.selectAll(".legend").remove();
            svg.selectAll(".middlesection").remove();
            svg.selectAll(".alertaction").remove();
            svg.selectAll(".attributes").remove();

            //https://www.freshconsulting.com/insights/blog/d3-js-gradients-the-easy-way/

            let defs: d3.Selection<SVGDefsElement, unknown, any, any> = svg.append("defs");

            /// LHS

            if (showFloodAndLegend === true) {
                svg.append("text")
                    .attr("data-name", "Legend")
                    .attr("transform", "translate(120 19)")
                    .attr("fill", "#fff")
                    .attr("fontSize", 12)
                    .attr("fontFamily", "Mont-Regular, Mont")
                    .append("tspan")
                    .attr("x", "-39")
                    .attr("y", "0")
                    .text("Condition Set Legend");
            }
            let currentY: number = 30; // which is our height

            if (conditionSetData !== null && conditionSetData !== undefined && conditionSetData.length > 0) {
                bandDataAndFlood.push(floodCondition);
            }

            // Draw the Legend
            for (let i: number = bandDataAndFlood.length - 1; i >= 0; i--) {
                let item: BandData = bandDataAndFlood[i];
                let nextItem: number = maxWorkingHeightValue;

                if (i !== bandDataAndFlood.length - 1) {
                    currentY += 2;
                    nextItem = bandDataAndFlood[i + 1].calculatedValue - 1;
                }

                // bottom line
                let point0x = 0; // constant
                let point0y = currentY; // constant

                let point1x = barLength; // constant
                let point1y = currentY; // constant

                let point2x = barLength; // constant
                let point2y = currentY + legendBandHeight;

                // back to the beginning

                let point3x = 0; // constant
                let point3y = currentY + legendBandHeight; // needs calculating

                //https://www.freshconsulting.com/insights/blog/d3-js-gradients-the-easy-way/
                let uniqueId: string = "svgGradient" + generateID();
                let fill: string = "url(#" + uniqueId + ")";
                let gradient = defs.append("linearGradient").attr("id", uniqueId).attr("x1", "0%").attr("x2", "100%").attr("y1", "70%").attr("y2", "70%");
                gradient.append("stop").attr("class", "start").attr("offset", "0%").attr("stop-color", item.backgroundColour).attr("stop-opacity", 1);
                gradient.append("stop").attr("class", "end").attr("offset", "100%").attr("stop-color", "#585858").attr("stop-opacity", 1);

                let text: string = item.name + " - From: " + item.calculatedValue.toFixed(0) + "mm To: " + nextItem.toFixed(0) + "mm";

                if (item.name.toLocaleLowerCase().indexOf("base condition") === -1) {
                    let type: string = "H";
                    switch (item.type) {
                        case ConditionType.FB: {
                            type = "FB";
                            break;
                        }
                        case ConditionType.P1: {
                            type = "P1";
                            break;
                        }
                        case ConditionType.P2: {
                            type = "P2";
                            break;
                        }
                        case ConditionType.H:
                        default: {
                            break;
                        }
                    }
                    if (i !== bandDataAndFlood.length - 1) {
                        text = item.name + " - (" + type + " " + item.value + ") - From: " + item.calculatedValue.toFixed(0) + "mm To: " + nextItem.toFixed(0) + "mm";
                    } else {
                        text = item.name + " - From: " + item.calculatedValue.toFixed(0) + "mm";
                    }
                }

                setTimeout(() => {
                    svg.append("polygon")
                        .attr("class", "legend")
                        .attr("points", point0x + "," + point0y + " " + point1x + "," + point1y + " " + point2x + "," + (point2y - 1) + " " + point3x + "," + (point3y - 2))
                        .attr("style", "fill: " + fill + ";");
                    svg.append("text")
                        .attr("class", "legend")
                        .attr("data-name", item.name)
                        .attr("transform", "translate(70 " + (point0y + 3 + legendBandHeight / 2) + ")")
                        .attr("fill", item.textColour)
                        .attr("fontSize", 12)
                        .attr("fontFamily", "Mont-Regular, Mont")
                        .append("tspan")
                        .attr("x", "-39")
                        .attr("y", "0")
                        .text(text);
                }, 501 * (i + 1) * timingMultiplier);

                currentY = currentY + legendBandHeight + 2;
            }

            /*********************************Middle bit************************************************************** */

            /// Middle bit
            // background
            if (props.deviceStatusData !== null && props.deviceStatusData !== undefined && svgElement.current !== null) {
                //put the grey background in.
                svg.append("path").attr("class", "middlesection").attr("d", "m979.986 32.32-58.219 170.811H384.94L327.001 32Z").attr("fill", "#707070").attr("opacity", 0.5);

                let startPointX: number = 427;
                let endPointX: number = 879;

                let pointlight0x = startPointX;
                let pointlight0y = yMax + floodAreaHeight;

                let pointlight1x = endPointX;
                let pointlight1y = yMax + floodAreaHeight;

                let pointlight2x = 935;
                let pointlight2y = floodAreaHeight;

                let pointlight3x = 372;
                let pointlight3y = floodAreaHeight;

                svg.append("polygon")
                    .attr("class", "legend")
                    .attr(
                        "points",
                        pointlight0x +
                            "," +
                            pointlight0y +
                            " " +
                            pointlight1x +
                            "," +
                            pointlight1y +
                            " " +
                            pointlight2x +
                            "," +
                            pointlight2y +
                            " " +
                            pointlight3x +
                            "," +
                            pointlight3y,
                    )
                    .attr("fill", "#707070")
                    .attr("opacity", 0.9);

                // condition set
                for (let i: number = 0; i < bandDataAndFlood.length; i++) {
                    // For the LHS polygon we need 5 points
                    // let i: number = 0;
                    let item: BandData = bandDataAndFlood[i];
                    currentY = offset - dataValueScale(item.calculatedValue);

                    // bottom line
                    let point0x = startPointX;
                    let point0y = currentY;

                    let point1x = endPointX;
                    let point1y = currentY;

                    let nextValue: number = 0;

                    if (i === bandDataAndFlood.length - 1) {
                        nextValue = 0;
                    } else {
                        nextValue = offset - dataValueScale(bandDataAndFlood[i + 1].calculatedValue);
                    }

                    let height: number = nextValue;

                    // SOCAHTOA - Javascript works in radians, so we need to convert from degrees to radians
                    // TOA = tan(angle) = opposite / adjacent => adjacent = opposite / tan (angle)
                    let opposite: number = 0;

                    if (i === 0) {
                        opposite = (yMax + floodAreaHeight - height) * tanAngle;
                    } else {
                        opposite = (currentY - height) * tanAngle;
                    }

                    let point2x = endPointX + opposite;
                    let point2y = height; // needs calculating

                    // back to the beginning

                    let point3x = startPointX - opposite;
                    let point3y = height; // needs calculating

                    endPointX = point2x;
                    startPointX = point3x;

                    // Draw the in band polygon
                    setTimeout(() => {
                        svg.append("polygon")
                            .attr("class", "middlesection middlesection-band")
                            .attr("points", point0x + "," + point0y + " " + point1x + "," + point1y + " " + point2x + "," + point2y + " " + point3x + "," + point3y)
                            .attr("style", "fill: " + item.backgroundColour + ";");
                    }, 500 * (i + 1) * timingMultiplier);
                    //
                }

                // Up down arrow
                setTimeout(() => {
                    svg.append("path")
                        .attr("class", "middlesection")
                        .attr("data-name", "Path 3356")
                        .attr("d", "m662.943 46.439-9.973-13.626-9.965 13.626h8.971v141.859h-8.971l9.966 13.626 9.973-13.626h-8.972V46.439Z")
                        .attr("fill", "#fff")
                        .attr("stroke", "rgba(0,0,0,0)");

                    svg.append("text")
                        .attr("class", "middlesection")
                        .attr("data-name", "Install height")
                        .attr("transform", "translate(653 19)")
                        .attr("fill", "#fff")
                        .attr("fontSize", 12)
                        .attr("fontFamily", "Mont-Regular, Mont")
                        .append("tspan")
                        .attr("x", "-39")
                        .attr("y", "0")
                        .text("Install Height");
                }, 600 * bandDataAndFlood.length * timingMultiplier);

                setTimeout(() => {
                    // Left arc
                    svg.append("path")
                        .attr("class", "middlesection")
                        .attr("fill", "none")
                        .attr("stroke", "#fff")
                        .attr("d", "M475.764 203a47.232 47.232 0 0 0-62.864-44.538")
                        .style("stroke-dasharray", "3 3");

                    svg.append("text")
                        .attr("class", "middlesection")
                        .attr("data-name", "Angle between side 1")
                        .attr("transform", "translate(481 176)")
                        .attr("fill", "#fff")
                        .attr("fontSize", 12)
                        .attr("fontFamily", "Mont-Regular, Mont")
                        .append("tspan")
                        .attr("x", "0")
                        .attr("y", "0")
                        .text("Angle between")
                        .append("tspan")
                        .attr("x", "0")
                        .attr("y", "17")
                        .text("side 1 & base");
                }, 600 * bandDataAndFlood.length * timingMultiplier);
                // Right arc
                setTimeout(() => {
                    svg.append("path")
                        .attr("class", "middlesection")
                        .attr("fill", "none")
                        .attr("stroke", "#fff")
                        .attr("d", "M893.13 158.203a46.847 46.847 0 0 0-15.73-2.7 47.351 47.351 0 0 0-47.2 47.5")
                        .style("stroke-dasharray", "3 3");

                    svg.append("text")
                        .attr("class", "middlesection")
                        .attr("data-name", "Angle between side 2")
                        .attr("transform", "translate(734 176)")
                        .attr("fill", "#fff")
                        .attr("fontSize", 12)
                        .attr("fontFamily", "Mont-Regular, Mont")
                        .append("tspan")
                        .attr("x", "0")
                        .attr("y", "0")
                        .text("Angle between")
                        .append("tspan")
                        .attr("x", "0")
                        .attr("y", "17")
                        .text("side 2 & base");
                }, 620 * bandDataAndFlood.length * timingMultiplier);

                // Side texts
                svg.append("text")
                    .attr("class", "middlesection")
                    .attr("data-name", "Side 2")
                    .attr("transform", "rotate(-71.2 548.953 -591.323)")
                    .attr("fill", "#fff")
                    .attr("fontSize", 12)
                    .attr("fontFamily", "Mont-Regular, Mont")
                    .append("tspan")
                    .attr("x", "-17")
                    .attr("y", "0")
                    .text("Side 2");

                svg.append("text")
                    .attr("class", "middlesection")
                    .attr("data-name", "Side 1")
                    .attr("transform", "rotate(71.2 104.025 320.827)")
                    .attr("fill", "#fff")
                    .attr("fontSize", 12)
                    .attr("fontFamily", "Mont-Regular, Mont")
                    .append("tspan")
                    .attr("x", "-16")
                    .attr("y", "0")
                    .text("Side 1");

                svg.append("path")
                    .attr("class", "middlesection")
                    .attr("d", "m327 32.5 57.52 171.094 537.316.539 58.176-171.295")
                    .attr("fill", "none")
                    .attr("stroke", "#fff")
                    .style("stroke-linecap", "round")
                    .style("stroke-width", function (d) {
                        return "4";
                    });

                // Reset the start and endpoint for the line
                startPointX = 427;
                endPointX = 879;
            }
        }
    }, [conditionSetData, svgElement.current]);

    useEffect(() => {
        let bandDataAndFlood: BandData[] = generateCommonData();
        /******************************************* ATTRIBUTES SHOW / HIDE ************************************* */
        // reset current Y for the RHS
        let currentY = 30; // which is our height

        // Bind D3 data.
        const svg = d3.select(svgElement.current);

        /****************** Show Attributes ********************************* */
        // hack since always want this
        if (showAttributes === true) {
            if (deviceStatusData !== undefined) {
                let startPointX: number = 427;
                let endPointX: number = 879;

                let attributeColor: string = attributeLineColour;

                let maxWorkingHeightValue: number = Math.min(deviceStatusData!.side1_Height, deviceStatusData!.side2_Height);

                if (deviceStatusData!.roofType === RoofType.Flat) {
                    maxWorkingHeightValue = deviceStatusData!.maxHeight;
                }

                const freeboardHeightValue: number = maxWorkingHeightValue * 0.7;

                let yH = offset - dataValueScale(maxWorkingHeightValue);

                let line = d3.line();

                // SOCAHTOA - Javascript works in radians, so we need to convert from degrees to radians
                // TOA = tan(angle) = opposite / adjacent => adjacent = opposite / tan (angle)
                let opposite: number = (yMax + floodAreaHeight - yH) * tanAngle;

                let startXH: number = startPointX - opposite;
                let endXH: number = endPointX + opposite;

                let ptsH: [number, number][] = [
                    [startXH, yH],
                    [endXH, yH],
                ];

                let pathOfLineH: string | null = line(ptsH);

                if (pathOfLineH !== null) {
                    svg.append("path")
                        .attr("class", "attributes")
                        .attr("d", pathOfLineH)
                        .attr("fill", "none")
                        .attr("stroke", attributeColor)
                        .style("stroke-width", function (d) {
                            return "2";
                        });

                    svg.append("text")
                        .attr("class", "attributes")
                        .attr("data-name", "Freeboard")
                        .attr("fill", attributeColor)
                        .attr("fontSize", 12)
                        .attr("fontFamily", "Mont-Regular, Mont")
                        .attr("textAnchor", "middle")
                        .append("tspan")
                        .attr("x", startXH + 1)
                        .attr("y", yH - 2)
                        .text("H (" + maxWorkingHeightValue.toFixed(0) + "mm)");
                }

                ///////////////////////  FreeBoard //////////////////////////

                let yFB: number = offset - dataValueScale(freeboardHeightValue);

                line = d3.line();

                // SOCAHTOA - Javascript works in radians, so we need to convert from degrees to radians
                // TOA = tan(angle) = opposite / adjacent => adjacent = opposite / tan (angle)
                opposite = (yMax + floodAreaHeight - yFB) * tanAngle;
                let startXFB: number = startPointX - opposite;
                let endXFB: number = endPointX + opposite;

                let ptsFB: [number, number][] = [
                    [startXFB, yFB],
                    [endXFB, yFB],
                ];

                let pathOfLineFB: string | null = line(ptsFB);

                if (pathOfLineFB !== null) {
                    svg.append("path")
                        .attr("class", "attributes")
                        .attr("d", pathOfLineFB)
                        .attr("fill", "none")
                        .attr("stroke", attributeColor)
                        .style("stroke-width", function (d) {
                            return "2";
                        });

                    svg.append("text")
                        .attr("class", "attributes")
                        .attr("data-name", "Working Height")
                        .attr("fill", attributeColor)
                        .attr("fontSize", 12)
                        .attr("fontFamily", "Mont-Regular, Mont")
                        .attr("textAnchor", "middle")
                        .append("tspan")
                        .attr("x", startXFB + 1)
                        .attr("y", yFB - 2)
                        .text("FB (" + freeboardHeightValue.toFixed(0) + "mm)");
                }

                if (deviceStatusData.drainageType !== DrainageType.Gravity) {
                    /* //////////////////// //////////////////////////////// P1 */
                    let yP1: number = offset - dataValueScale(deviceStatusData.p1);

                    line = d3.line();

                    // SOCAHTOA - Javascript works in radians, so we need to convert from degrees to radians
                    // TOA = tan(angle) = opposite / adjacent => adjacent = opposite / tan (angle)
                    opposite = (yMax + floodAreaHeight - yP1) * tanAngle;
                    let startXP1: number = startPointX - opposite;
                    let endXP1: number = endPointX + opposite;

                    let ptsP1: [number, number][] = [
                        [startXP1, yP1],
                        [endXP1, yP1],
                    ];

                    let pathOfLineP1: string | null = line(ptsP1);

                    if (pathOfLineP1 !== null) {
                        svg.append("path")
                            .attr("class", "attributes")
                            .attr("d", pathOfLineP1)
                            .attr("fill", "none")
                            .attr("stroke", attributeColor)
                            .style("stroke-width", function (d) {
                                return "2";
                            });

                        svg.append("text")
                            .attr("class", "attributes")
                            .attr("data-name", "p1")
                            .attr("fill", attributeColor)
                            .attr("fontSize", 12)
                            .attr("fontFamily", "Mont-Regular, Mont")
                            .attr("textAnchor", "middle")
                            .append("tspan")
                            .attr("x", startXP1 + 1)
                            .attr("y", yP1 - 2)
                            .text("P1 (" + deviceStatusData.p1.toFixed(0) + "mm)");
                    }

                    if (deviceStatusData.drainageType === DrainageType.DualSiphonic) {
                        /*  ////////////////////////////// P2 */
                        let yP2: number = offset - dataValueScale(deviceStatusData.p2);

                        line = d3.line();

                        // SOCAHTOA - Javascript works in radians, so we need to convert from degrees to radians
                        // TOA = tan(angle) = opposite / adjacent => adjacent = opposite / tan (angle)
                        opposite = (yMax + floodAreaHeight - yP2) * tanAngle;
                        let startXP2: number = startPointX - opposite;
                        let endXP2: number = endPointX + opposite;

                        let ptsP2: [number, number][] = [
                            [startXP2, yP2],
                            [endXP2, yP2],
                        ];

                        let pathOfLineP2: string | null = line(ptsP2);

                        if (pathOfLineP2 !== null) {
                            svg.append("path")
                                .attr("class", "attributes")
                                .attr("d", pathOfLineP2)
                                .attr("fill", "none")
                                .attr("stroke", attributeColor)
                                .style("stroke-width", function (d) {
                                    return "2";
                                });

                            svg.append("text")
                                .attr("class", "attributes")
                                .attr("data-name", "p2")
                                .attr("fill", attributeColor)
                                .attr("fontSize", 12)
                                .attr("fontFamily", "Mont-Regular, Mont")
                                .attr("textAnchor", "middle")
                                .append("tspan")
                                .attr("x", startXP2 + 1)
                                .attr("y", yP2 - 2)
                                .text("P2 (" + deviceStatusData.p2.toFixed(0) + "mm)");
                        }
                    }
                }
            }
        } else {
            svg.selectAll(".attributes").remove();
        }
    }, [showAttributes]);

    useEffect(() => {
        let bandDataAndFlood: BandData[] = generateCommonData();
        /// ***********************  Draw Alert Actions *************************
        /************************************** RHS Alert Action Section ********************************************************** */
        // reset current Y for the RHS
        let currentY = 30; // which is our height
        // Bind D3 data.
        const svg = d3.select(svgElement.current);

        if (showAlertActions === true && alertActions !== undefined && alertActions.length > 0) {
            alertActions.sort((a: BandData, b: BandData) => {
                if (a === undefined && b === undefined) {
                    return 0;
                }

                if (a === undefined) {
                    return -1;
                }

                if (b === undefined) {
                    return 1;
                }

                return a.calculatedValue < b.calculatedValue ? -1 : 1;
            });

            let startPointX: number = 427;
            const rhsOffset: number = 1000;

            // Put in the legend

            svg.append("text")
                .attr("class", "alertaction")
                .attr("data-name", "Legend")
                .attr("transform", "translate(1175 19)")
                .attr("fill", "#fff")
                .attr("fontSize", 12)
                .attr("fontFamily", "Mont-Regular, Mont")
                .append("tspan")
                .attr("x", "-39")
                .attr("y", "0")
                .text("Alert Action Legend");

            let line = d3.line();
            let timeoutBase: number = 300;

            for (let i: number = 0; i < alertActions.length; i++) {
                let y = offset - dataValueScale(alertActions[i].calculatedValue);

                let timeout: number = timeoutBase * (alertActions.length - i);

                // SOCAHTOA - Javascript works in radians, so we need to convert from degrees to radians
                // TOA = tan(angle) = opposite / adjacent => adjacent = opposite / tan (angle)
                let opposite: number = (yMax + floodAreaHeight - y) * tanAngle;

                let startX: number = startPointX - opposite;
                let endX: number = rhsOffset;

                let pts: [number, number][] = [
                    [startX, y],
                    [endX, y],
                ];

                let pathOfLine: string | null = line(pts);

                setTimeout(() => {
                    if (pathOfLine !== null) {
                        svg.append("path")
                            .attr("class", "alertaction alertaction-band")
                            .attr("d", pathOfLine)
                            .attr("fill", "none")
                            .attr("stroke", alertLineColour)
                            .style("stroke-dasharray", "3 3");
                    }
                }, 400);
            }

            let defs: any = svg.select("defs");

            // Now do the legend and second line

            for (let i: number = alertActions.length - 1; i >= 0; i--) {
                let timeout: number = timeoutBase * (alertActions.length - i);
                let lineY = offset - dataValueScale(alertActions[i].calculatedValue);

                let line = d3.line();

                let item: BandData = alertActions[i];

                if (i !== alertActions.length - 1) {
                    currentY += 2;
                }

                // bottom line
                let point0x = rhsOffset;
                let point0y = currentY;

                let point1x = rhsOffset + barLength;
                let point1y = currentY;

                let point2x = rhsOffset + barLength;
                let point2y = currentY + legendBandHeight;

                // back to the beginning

                let point3x = rhsOffset; // constant
                let point3y = currentY + legendBandHeight;

                //https://www.freshconsulting.com/insights/blog/d3-js-gradients-the-easy-way/
                let uniqueId: string = "svgGradient" + generateID();
                let fill: string = "url(#" + uniqueId + ")";
                let gradient = defs
                    .append("linearGradient")
                    .attr("id", uniqueId)
                    .attr("class", "alertaction")
                    .attr("x1", "0%")
                    .attr("x2", "100%")
                    .attr("y1", "70%")
                    .attr("y2", "70%");
                gradient.append("stop").attr("class", "start").attr("offset", "0%").attr("stop-color", "#585858").attr("stop-opacity", 1);
                gradient.append("stop").attr("class", "end").attr("offset", "100%").attr("stop-color", item.backgroundColour).attr("stop-opacity", 1);

                let text: string = item.name + " - " + item.calculatedValue.toFixed(0) + "mm";

                if (item.name.toLocaleLowerCase().indexOf("base condition") === -1) {
                    let type: string = "H";
                    switch (item.type) {
                        case ConditionType.FB: {
                            type = "FB";
                            break;
                        }
                        case ConditionType.P1: {
                            type = "P1";
                            break;
                        }
                        case ConditionType.P2: {
                            type = "P2";
                            break;
                        }
                        case ConditionType.H:
                        default: {
                            break;
                        }
                    }

                    text = item.name + " - (" + type + " " + item.value + ") - " + item.calculatedValue.toFixed(0) + "mm";
                }

                let endY: number = currentY + 3 + legendBandHeight / 2;

                setTimeout(() => {
                    svg.append("polygon")
                        .attr("class", "alertaction alertaction-band")
                        .attr("points", point0x + "," + point0y + " " + point1x + "," + point1y + " " + point2x + "," + (point2y - 1) + " " + point3x + "," + (point3y - 2))
                        .attr("style", "fill: " + fill + ";");

                    svg.append("text")
                        .attr("class", "alertaction alertaction-band")
                        .attr("data-name", item.name)
                        .attr("transform", "translate(" + (rhsOffset + 162) + " " + endY + ")")
                        .attr("fill", item.textColour)
                        .attr("fontSize", 12)
                        .attr("fontFamily", "Mont-Regular, Mont")
                        .append("tspan")
                        .attr("x", "-39")
                        .attr("y", "0")
                        .text(text);
                }, 200);
                currentY = currentY + legendBandHeight + 2;

                //
                let startX: number = rhsOffset;
                let endX: number = rhsOffset + 170;

                let pts: [number, number][] = [
                    [startX, lineY],
                    [endX - 50, endY - 5],
                ];

                let pathOfLine: string | null = line(pts);

                setTimeout(() => {
                    if (pathOfLine !== null && showAlertActions === true) {
                        svg.append("path")
                            .attr("class", "alertaction alertaction-band")
                            .attr("d", pathOfLine)
                            .attr("fill", "none")
                            .attr("stroke", alertLineColour)
                            .style("stroke-dasharray", "3 3");
                    }
                }, 1500);
            }
        } else {
            svg.selectAll(".alertaction").attr("class", "alertaction alertaction-band cancel-animation");
            setTimeout(() => {
                if (showAlertActions === false) {
                    svg.selectAll(".alertaction").attr("class", "alertaction alertaction-band cancel-animation");
                    svg.selectAll(".alertaction").remove();
                    svg.selectAll(".cancel-animation").remove();
                }
            }, 1510);

            setTimeout(() => {
                if (showAlertActions === false) {
                    svg.selectAll(".alertaction").remove();
                    svg.selectAll(".cancel-animation").remove();
                }
            }, 2500);
        }
    }, [alertActions, showAlertActions]);

    return (
        <>
            <DynamicSettingsImageBox height={imageHeight}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    ref={svgElement}
                    height={props.imageHeight}
                    width={imageWidth}
                    viewBox={`0, 0, 1300, ` + props.imageHeight}
                    preserveAspectRatio="xMidYMid meet"
                />
            </DynamicSettingsImageBox>
        </>
    );
}
