import React, { createRef, useEffect, useMemo, useRef, useState } from "react";
import * as styles from "./Map.styles";
import { UnsupportedBrowser } from "Custom/Views/UnsupportedBrowser";
import { MapContainer, TileLayer, Marker, Popup, ZoomControl, useMap } from "react-leaflet";
import { MapControl, MapControlPosition } from "./Control";
import L, { LatLngBounds, Point, Map as LeafletMap, DragEndEvent, LatLngExpression } from "leaflet";
import { generateID } from "Core/Utils/Utils";
import { isNullOrUndefined } from "Custom/Utils/utils";
import { MapHack } from "./MapHack";
import { IMarker } from "./Map";

export interface IDraggableMarker extends IMarker {
    onPositionChange?: (id: string, lat: number, long: number) => void;
}

export interface IDraggableMarkerDisplay extends IDraggableMarker {
    displayPopUp: (marker: IMarker | IDraggableMarker) => JSX.Element;
    marker: IMarker | IDraggableMarker;
}

export const DraggableMarker: React.FC<IDraggableMarkerDisplay> = ({ onPositionChange, position, id, number, icon, draggable, displayPopUp, marker }) => {
    const markerRef = useRef(null);
    const eventHandlers = useMemo(
        () => ({
            dragend() {
                const localMarker = markerRef.current;
                if (localMarker != null) {
                    let position = (localMarker as any).getLatLng();
                    if (onPositionChange !== undefined) {
                        onPositionChange!(id, position.lat, position.lng);
                    }
                }
            },
        }),
        [],
    );

    const popup: (marker: IMarker) => JSX.Element = displayPopUp;

    return (
        <Marker ref={markerRef} key={generateID()} icon={icon} position={position} draggable={draggable === undefined ? false : draggable} eventHandlers={eventHandlers}>
            <Popup closeButton={false} keepInView maxWidth={280} offset={new Point(0, 30)}>
                {popup(marker)}
            </Popup>
        </Marker>
    );
};
