import React, { useContext } from "react";
import { Stores, StoresContext } from "../../Stores";
import { PrivateRoute } from "../../../Core/Utils/React";
import * as RouterUtil from "Custom/Utils/routing";
import { Client } from "Custom/Globals/AppUrls";
import { ContactListPage } from "./ContactListPage";

export const ContactsRoutes: React.FC = () => {
    const store = useContext<Stores>(StoresContext);
    return (
        <>
            <PrivateRoute
                component={ContactListPage}
                exact
                isAllowed={() => {
                    return store.domain.AccountStore.IsLoggedIn && RouterUtil.requireAdminLogin(store);
                }}
                path={Client.Main.Contact.Root}
            />
            <PrivateRoute
                component={ContactListPage}
                exact
                isAllowed={() => {
                    return store.domain.AccountStore.IsLoggedIn && RouterUtil.requireAdminLogin(store);
                }}
                path={Client.Main.Contact.ContactAddEditDetail}
            />
            {/* <PrivateRoute
                component={ProjectDetailsPage}
                exact
                isAllowed={() => {
                    return store.domain.AccountStore.IsLoggedIn && RouterUtil.requireAdminLogin(store);
                }}
                path={Client.Main.Project.ProjectDetail}
            /> */}
        </>
    );
};
