import * as MobX from "mobx";
import { ModelBase } from "Core/Models/ModelBase";
import { FieldType, sortByString } from "Core/Utils/Utils";
import { IsNotEmpty } from "class-validator";
import { ContactModel } from "./ContactModel";

//Base class expects the model type and the DTO model type
//If you are not using a DTO just pass in undefined as the DTO
export class ContactListModel extends ModelBase<ContactListModel, ContactListModel> {
    @MobX.observable public contacts: MobX.IObservableArray<ContactModel> = MobX.observable([]);
    @MobX.observable private ascOrder = true;

    @MobX.computed get getAllContacts(): ContactModel[] {
        let retVal = this.contacts.slice();
        retVal.sort((a: ContactModel, b: ContactModel) => {
            if (this.ascOrder) {
                return sortByString(a.lastName, b.lastName);
            } else {
                return sortByString(b.lastName, a.lastName);
            }
        });
        return retVal;
    }

    @MobX.computed get getAllowedContacts(): ContactModel[] {
        let retVal = this.contacts.slice().filter((a: ContactModel) => a.isDeleted === false);
        retVal.sort((a: ContactModel, b: ContactModel) => {
            if (this.ascOrder) {
                return sortByString(a.lastName, b.lastName);
            } else {
                return sortByString(b.lastName, a.lastName);
            }
        });
        return retVal;
    }

    //fromDto is required but you can leave it blank
    @MobX.action
    fromDto(model: ContactListModelDTO): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
    }

    //toDto is required but you can leave it blank
    toDto(model: ContactListModelDTO): void {}
}

export type ContactListModelDTO = {
    contacts: ContactModel[];
};
