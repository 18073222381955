import styled from "styled-components";

export interface DynamicSettingsImageWrapperProps {
    maxwidth?: string;
    paddingTop: string;
}

export const DynamicSettingsButtonRow: any = styled.div`
    display: flex;
    flex: 1 0 0;
    flex-direction: row;
    width: 100%;

    justify-content: center;
`;

export const DynamicSettingsImageWrapper: any = styled.div<DynamicSettingsImageWrapperProps>`
    padding-top: ${(props) => props.paddingTop};
    max-width: ${(props) => props.maxwidth} !== undefined ? ${(props) => props.maxwidth} : 100%;

/*     background-color: black; */
    display: flex;
    flex: 1 0 0;
    flex-direction: column;

`;

export const DynamicSettingsImageTitle: any = styled.div`
    width: 100%;
    display: flex;
    flex: 1 0 auto;
    flex-flow: row nowrap;
    flex-direction: row;
    height: auto;
    justify-content: space-between;
    text-align: center;
    font: normal normal normal 18px/27px Mont;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    padding: 20px 0;
    /* border-top: 2px dashed #b2b2b2; */
`;

export interface DynamicSettingsImageProps {
    height: string;
    maxwidth?: string;
    paddingTop: string;
}

export const DynamicSettingsImageBox: any = styled.div<DynamicSettingsImageProps>`
    min-height: ${(props) => props.height};
    max-width: ${(props) => props.maxwidth} !== undefined ? ${(props) => props.maxwidth} : 100%;
    padding-top: ${(props) => props.paddingTop};
`;
