import { TestGradeDTO } from "./TestGrade";
import { UnitListUnitItemModel, UnitListUnitItemModelDTO } from "./UnitListItemModel";
import { observable, action, runInAction, IObservableArray, computed } from "mobx";
import { ViewModelBase } from "Core/ViewModels";
import { FieldType, isEmptyOrWhitespace } from "Core/Utils/Utils";
import { ApiResult } from "Core/Models";
import { Server } from "Custom/Globals/AppUrls";
import { debounce } from "@material-ui/core";
import { KeyValuePair } from "Core/Models/KeyValuePair";
import { DEBOUNCE_VALUE_MS } from "Custom/Globals/Globals";
import { UnitListAndRelatedDTO } from "./UnitListAndRelated";
import { DisplayUnit, DisplayUnitHelpers } from "Custom/Models/Domain";
import { UnitListItemDTO } from "./UnitListItem";
import { UnitVersionDTO } from "./UnitVersion";
import { DeviceUnitStatusDTO } from "./DeviceUnitStatus";
import { UnitFirmwareDTO } from "./UnitFirmware";
import { FileDownloadResponseDTO } from "../Installations/FileDownloadResponseDTO";

export class UnitsViewModel extends ViewModelBase<UnitListUnitItemModel> {
    private firstTimeLoad: boolean = true;
    private static _instance: UnitsViewModel;

    private IsDownloading: boolean = false;

    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    @observable public currentTab: string = "list";

    @observable public errorMessage: string = "";

    public units: IObservableArray<UnitListItemDTO> = observable<UnitListItemDTO>([]);

    public testGrades: IObservableArray<TestGradeDTO> = observable<TestGradeDTO>([]);
    @observable public filterTestGrades = observable<string>([]);

    public unitStatus: IObservableArray<DeviceUnitStatusDTO> = observable<DeviceUnitStatusDTO>([]);
    @observable public filterStatusFilter = observable<string>([]);

    @observable public filterStandingWaterFilter = observable<string>(["0", "1", "3"]);

    public unitVersion: IObservableArray<UnitVersionDTO> = observable<UnitVersionDTO>([]);
    @observable public filterUnitVersion = observable<string>([]);

    public unitFirmware: IObservableArray<UnitFirmwareDTO> = observable<UnitFirmwareDTO>([]);
    @observable public filterFirmware = observable<string>([]);

    // Table Settings
    @observable public searchString: string = "";
    @observable public filterSearchString: string = "";
    @observable public pageSize: number = 50;
    @observable public currentPage: number = 0;
    // By default this will be the first column (by index) and in ascending order.
    @observable public sortColumnId: number = 0;
    @observable public sortDirection: any = "asc";

    public unitDisplay: KeyValuePair[] = DisplayUnitHelpers.getOptionsKeyValuePair();
    @observable public filterDisplay = observable<number>([1]);

    public constructor() {
        super(new UnitListUnitItemModel(), false);
        this.setDecorators(UnitListUnitItemModel);
    }

    public getSimIdFromId(id: string): string {
        let retVal: string = "";

        if (this.units.length > 0) {
            const unit: UnitListItemDTO | undefined = this.units.find((a) => a.id === parseInt(id, 10));
            if (unit !== undefined) {
                retVal = unit.simId;
            }
        }

        return retVal;
    }

    // #region Installation Status Filter
    public getUnitStatusKVPair = (includeDeleted: boolean): KeyValuePair[] => {
        const retVal: KeyValuePair[] = [];

        for (let i: number = 0; i < this.unitStatus.length; i++) {
            retVal.push({
                key: this.unitStatus[i].name,
                value: this.unitStatus[i].id!.toString(),
            });
        }

        return retVal;
    };

    public getTestGradeKVPair = (includeDeleted: boolean): KeyValuePair[] => {
        const retVal: KeyValuePair[] = [];

        for (let i: number = 0; i < this.testGrades.length; i++) {
            retVal.push({
                key: this.testGrades[i].name,
                value: this.testGrades[i].id!.toString(),
            });
        }

        return retVal;
    };

    public getVersionKVPair = (includeDeleted: boolean): KeyValuePair[] => {
        const retVal: KeyValuePair[] = [];

        for (let i: number = 0; i < this.unitVersion.length; i++) {
            retVal.push({
                key: this.unitVersion[i].name,
                value: this.unitVersion[i].id!.toString(),
            });
        }

        return retVal;
    };

    public getFirmwareKVPair = (includeDeleted: boolean): KeyValuePair[] => {
        const retVal: KeyValuePair[] = [];

        for (let i: number = 0; i < this.unitFirmware.length; i++) {
            retVal.push({
                key: this.unitFirmware[i].name,
                value: this.unitFirmware[i].id!.toString(),
            });
        }

        return retVal;
    };

    public getStatusFilter = () => {
        return computed(() => this.filterStatusFilter);
    };

    @action
    public setUnitStatusFilter = (values: string[]) => {
        this.filterStatusFilter.replace(values);
        this.setFilterUnitStatus(values);
    };

    private setFilterUnitStatus = debounce(
        action((values: string[]) => {
            this.filterStatusFilter.replace(values);
        }),
        DEBOUNCE_VALUE_MS,
    );
    // #endregion Installation Status Filter

    // #region Installation Standing Water Filter
    public getStandingWaterKVPair = (includeDeleted: boolean): KeyValuePair[] => {
        const retVal: KeyValuePair[] = [];

        retVal.push({
            key: "Unknown",
            value: "0",
        });

        retVal.push({
            key: "Green",
            value: "1",
        });

        retVal.push({
            key: "Red",
            value: "3",
        });

        return retVal;
    };

    public getStandingWaterFilter = () => {
        return computed(() => this.filterStandingWaterFilter);
    };

    @action
    public setStandingWaterFilter = (values: string[]) => {
        this.filterStandingWaterFilter.replace(values);
        this.setFilterStandingWaterFilter(values);
    };

    private setFilterStandingWaterFilter = debounce(
        action((values: string[]) => {
            this.filterStandingWaterFilter.replace(values);
        }),
        DEBOUNCE_VALUE_MS,
    );
    // #endregion Installation Standing Water Filter

    public getTestGradesFilter = () => {
        return computed(() => this.filterTestGrades);
    };

    @action
    public setUnitTestGradesFilter = (values: string[]) => {
        this.filterTestGrades.replace(values);
        this.setTestGradesFilter(values);
    };

    private setTestGradesFilter = debounce(
        action((values: string[]) => {
            this.filterTestGrades.replace(values);
        }),
        DEBOUNCE_VALUE_MS,
    );
    // #endregion Installation Test Grade Filter

    public getVersionFilter = () => {
        return computed(() => this.filterUnitVersion);
    };

    @action
    public setUnitVersionFilter = (values: string[]) => {
        this.filterUnitVersion.replace(values);
        this.setVersionFilter(values);
    };

    @action
    public setUnitFirmwareFilter = (values: string[]) => {
        this.filterFirmware.replace(values);
        this.setFirmwareFilter(values);
    };

    private setFirmwareFilter = debounce(
        action((values: string[]) => {
            this.filterFirmware.replace(values);
        }),
        DEBOUNCE_VALUE_MS,
    );

    private setVersionFilter = debounce(
        action((values: string[]) => {
            this.filterUnitVersion.replace(values);
        }),
        DEBOUNCE_VALUE_MS,
    );
    // #endregion Installation Test Grade Filter

    @action public setUnitDisplayFilter(values: number[]) {
        this.filterDisplay.replace(values);
        this.setFilterDisplay(values);
    }

    private setFilterDisplay = debounce(
        action((values: number[]) => {
            this.filterDisplay.replace(values);
        }),
        DEBOUNCE_VALUE_MS,
    );

    public getDisplayFilter = () => {
        return computed(() => this.filterDisplay);
    };

    public getFirmwareFilter = () => {
        return computed(() => this.filterFirmware);
    };

    public getUnitDisplayKVPair = (includeDeleted: boolean): KeyValuePair[] => {
        if (this.unitDisplay === undefined) {
            this.unitDisplay = DisplayUnitHelpers.getOptionsKeyValuePair();

            return this.unitDisplay;
        }

        return this.unitDisplay.slice();
    };

    @action
    public setCurrentTab = (tab: string) => {
        this.currentTab = tab;
    };

    @computed
    public get getCurrentTab() {
        return this.currentTab;
    }

    @computed public get getUnits() {
        let retVal = this.units.slice();

        if (this.filterDisplay.length === 3) {
            // then we all items
        } else if (this.filterDisplay.length === 0) {
            // we want no units
            retVal = [];
        } /* else {
            const wantProperties: boolean = this.filterDisplay.indexOf(DisplayUnit.WithProperty) !== -1;
            const withoutProperties: boolean = this.filterDisplay.indexOf(DisplayUnit.WithoutProperty) !== -1;
            const showHidden: boolean = this.filterDisplay.indexOf(DisplayUnit.Hidden) !== -1;

            if (wantProperties == true && withoutProperties == true) {
                // then don't do anything
            } else if (wantProperties == true) {
                retVal = retVal.filter((a) => a.roofcareAddressId !== null && a.roofcareAddressId !== undefined);
            } else if (withoutProperties == true) {
                retVal = retVal.filter((a) => a.roofcareAddressId === null || a.roofcareAddressId === undefined);
            }

            // finally filter out the hidden ones.
            if (showHidden === true) {
                // show the hidden ones
                retVal = retVal.filter((a) => a.isDeleted === true);
            } else {
                // filter out the hidden ones
                retVal = retVal.filter((a) => a.isDeleted === false);
            }
        } */

        const filterBySearchString = (item: UnitListItemDTO): boolean => {
            if (isEmptyOrWhitespace(this.filterSearchString)) {
                return true;
            }

            const searchStringUpperCase = this.filterSearchString.toUpperCase();

            return (
                item.simId.toUpperCase().includes(searchStringUpperCase) ||
                item.deviceId.toUpperCase().includes(searchStringUpperCase) ||
                item.name.toUpperCase().includes(searchStringUpperCase) ||
                item.number.toUpperCase().includes(searchStringUpperCase) ||
                (item.addressLine1 !== null && item.addressLine1 !== undefined && item.addressLine1.toUpperCase().includes(searchStringUpperCase)) ||
                (item.city !== null && item.city !== undefined && item.city.toUpperCase().includes(searchStringUpperCase)) ||
                (item.postcode !== null && item.postcode !== undefined && item.postcode.toUpperCase().includes(searchStringUpperCase))
                /* If we need it?  ||
                item.clientName.toUpperCase().includes(searchStringUpperCase) ||
                item.contractorName.toUpperCase().includes(searchStringUpperCase) */
            );
        };

        const filterByCurrentStatus = (item: UnitListItemDTO): boolean => {
            if (this.filterStatusFilter.length === this.unitStatus.length) {
                return true;
            }

            let statusResponse: boolean = false;
            if (item.unitData !== null && item.unitData !== undefined) {
                // Check 1 - see if it is device item only from the main list.
                let found: boolean = this.filterStatusFilter.includes(item.unitData.currentUnitStatusId.toString());
                // have we a match from Admin/Devices
                if (found) {
                    statusResponse = true;
                }
            }
            return statusResponse;
        };

        const filterByVersion = (item: UnitListItemDTO): boolean => {
            if (this.filterUnitVersion.length === this.unitVersion.length) {
                return true;
            }

            let statusResponse: boolean = false;
            if (item.unitData !== null && item.unitData !== undefined) {
                // Check 1 - see if it is device item only from the main list.
                let found: boolean = this.filterUnitVersion.includes(item.unitData.versionId.toString());
                // have we a match from Admin/Devices
                if (found) {
                    statusResponse = true;
                }
            }
            return statusResponse;
        };

        const filterByTestGrade = (item: UnitListItemDTO): boolean => {
            if (this.filterTestGrades.length === this.testGrades.length) {
                return true;
            }

            let statusResponse: boolean = false;
            if (item.unitData !== null && item.unitData !== undefined) {
                // Check 1 - see if it is device item only from the main list.
                let found: boolean = this.filterTestGrades.includes(item.unitData.testGradeId.toString());
                // have we a match from Admin/Devices
                if (found) {
                    statusResponse = true;
                }
            }
            return statusResponse;
        };

        const filterByFirmware = (item: UnitListItemDTO): boolean => {
            if (this.filterFirmware.length === this.unitFirmware.length) {
                return true;
            }

            let statusResponse: boolean = false;
            if (item.unitData !== null && item.unitData !== undefined) {
                // Check 1 - see if it is device item only from the main list.
                let found: boolean = this.filterFirmware.includes(item.unitData.firmwareId.toString());
                // have we a match from Admin/Devices
                if (found) {
                    statusResponse = true;
                }
            }
            return statusResponse;
        };

        const filterByStandingWater = (item: UnitListItemDTO): boolean => {
            if (this.filterStandingWaterFilter.length === 3) {
                return true;
            }

            let retVal: boolean = false;
            // Check 1 - see if it is device item only from the main list.
            let found: boolean = this.filterStandingWaterFilter.includes(item.standingWaterEnum.toString());
            // have we a match from Admin/Devices
            if (found) {
                retVal = true;
            }
            return retVal;
        };

        return retVal
            .filter((a) => filterBySearchString(a))
            .filter((a) => filterByTestGrade(a))
            .filter((a) => filterByVersion(a))
            .filter((a) => filterByFirmware(a))
            .filter((a) => filterByStandingWater(a))
            .filter((a) => filterByCurrentStatus(a));
    }

    public getSearchString = () => {
        return computed(() => this.searchString);
    };

    @action
    public cleanUp = () => {
        // TODO Any Cleanup Code here. e.g. if  a user or project or client etc, wipe it from the instance on page shutdown
        this.units.clear();
        this.model.clear();
        this.unitStatus.clear();
        this.testGrades.clear();
        this.unitVersion.clear();
    };

    public doSubmit = async (e: any) => {
        e.preventDefault();

        if (await this.isMyModelValid()) {
            //Do stuff here
            this.errorMessage = "Form is valid";
        } else {
            this.errorMessage = "Form is not valid";
        }
    };

    public isFieldValid(fieldName: keyof FieldType<UnitListUnitItemModel>, value: any): boolean {
        let { isValid, errorMessage } = this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    private isMyModelValid = async (): Promise<boolean> => {
        let isValid = true;

        // this will automatically call isFieldValid.
        if ((await this.isModelValid()) === false) {
            isValid = false;
        }
        return isValid;
    };

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    @action
    public setUnits(units: UnitListItemDTO[]) {
        this.units.clear();
        units.forEach((item, index) => {
            this.units.push(item);
        });
    }

    @action
    public setTestGrade(testGrades: TestGradeDTO[]) {
        this.testGrades.clear();

        testGrades.forEach((item, index) => {
            this.testGrades.push(item);
            if (this.firstTimeLoad === true) {
                this.filterTestGrades.push(item.id!.toString());
            }
        });
    }

    @action
    public setUnitStatus(statuses: DeviceUnitStatusDTO[]) {
        this.unitStatus.clear();
        statuses.forEach((item, index) => {
            this.unitStatus.push(item);
            if (this.firstTimeLoad === true) {
                this.filterStatusFilter.push(item.id!.toString());
            }
        });
    }

    @action
    public setUnitVersions(versions: UnitVersionDTO[]) {
        this.unitVersion.clear();

        versions.forEach((item, index) => {
            this.unitVersion.push(item);
            if (this.firstTimeLoad === true) {
                this.filterUnitVersion.push(item.id!.toString());
            }
        });
    }

    @action
    public setFirmwareVersions(firmwares: UnitFirmwareDTO[]) {
        this.unitFirmware.clear();
        firmwares.forEach((item, index) => {
            this.unitFirmware.push(item);
            if (this.firstTimeLoad === true) {
                this.filterFirmware.push(item.id!.toString());
            }
        });
    }

    @action
    public setData = (data: UnitListAndRelatedDTO) => {
        this.setUnits(data.units);

        this.setTestGrade(data.testGrade);
        this.setUnitStatus(data.unitStatus);
        this.setUnitVersions(data.unitVersion);
        this.setFirmwareVersions(data.unitFirmware);
        this.firstTimeLoad = false;

        for (let i: number = 0; i < this.units.length; i++) {
            let unit: UnitListItemDTO = this.units[i];

            if (unit.unitData !== null && unit.unitData !== undefined) {
                const testGrade: TestGradeDTO | undefined = this.testGrades.find((a) => a.id === unit.unitData.testGradeId);

                if (testGrade !== undefined) {
                    unit.unitData.testGrade = testGrade.name;
                } else {
                    unit.unitData.testGrade = "Unknown";
                }

                const unitStatus: DeviceUnitStatusDTO | undefined = this.unitStatus.find((a) => a.id === unit.unitData.currentUnitStatusId);

                if (unitStatus !== undefined) {
                    unit.unitData.currentStatus = unitStatus.name;
                } else {
                    unit.unitData.currentStatus = "Unknown";
                }

                const unitFirmware: UnitVersionDTO | undefined = this.unitFirmware.find((a) => a.id === unit.unitData.firmwareId);

                if (unitFirmware !== undefined) {
                    unit.unitData.firmware = unitFirmware.name;
                } else {
                    unit.unitData.firmware = "Unknown";
                }

                const unitVersion: UnitVersionDTO | undefined = this.unitVersion.find((a) => a.id === unit.unitData.versionId);

                if (unitVersion !== undefined) {
                    unit.unitData.version = unitVersion.name;
                } else {
                    unit.unitData.version = "Unknown";
                }
            } else {
                unit.unitData = {
                    id: null,
                    deviceId: unit.id,
                    deliveryDate: null,
                    acceptanceDate: null,
                    testGradeId: 1,
                    testGrade: "Unknown",
                    currentUnitStatusId: 1,
                    currentStatus: "Unknown",
                    versionId: 1,
                    version: "Unknown",
                    unitName: unit.name,
                    firmwareId: 1,
                    firmware: "Unknown",
                };
            }
        }
    };

    @action
    public async loadForMainViewUnitViewAsync(): Promise<ApiResult<UnitListAndRelatedDTO>> {
        const apiResult = await this.Post<UnitListAndRelatedDTO>(Server.Api.Unit.getAllUnitsForListAndRelated);
        if (apiResult.wasSuccessful) {
            runInAction(() => {
                this.setData(apiResult.payload);
            });
        }
        return apiResult;
    }

    @action
    public async deleteUnit(deviceId: number | undefined): Promise<ApiResult<number>> {
        const apiResult = await this.Post<number>(Server.Api.Installation.deleteUnit, { id: deviceId });
        if (apiResult.wasSuccessful) {
            runInAction(() => {
                if (apiResult.payload === 1) {
                    this.setUnits(this.units.slice().filter((a) => a.id !== deviceId));
                }
            });
        }
        return apiResult;
    }

    @action
    public setSearchString = (value: string) => {
        this.searchString = value;
        this.setFilterSearchString(value);
    };

    private setFilterSearchString = debounce(
        action((value: string) => {
            this.filterSearchString = value;
        }),
        DEBOUNCE_VALUE_MS,
    );

    @action
    public setRowsPerPage(rows: number) {
        this.pageSize = rows;
    }

    @action
    public setPage = (page: number) => {
        this.currentPage = page;
    };

    @action
    public setOrderChange = (columnId: number, direction: any) => {
        this.sortColumnId = columnId;
        this.sortDirection = direction;
    };

    @action
    public getInstallationQRCode = async (deviceId: number) => {
        /*         const request = {
            id: deviceId,
        }; */

        // window.open(Server.Api.Image.downloadCommissioningDeviceImage + "?imageId=2");

        window.open(Server.Api.Image.getInstallationQRCode + "?id=" + deviceId);

        /* const apiResult: ApiResult<FileDownloadResponseDTO> = await this.Post<FileDownloadResponseDTO>(Server.Api.Installation.getInstallationQRCode, request);

        if (apiResult.wasSuccessful) {
            runInAction(() => {
                let fileName: string = "test.bmp";

                const file: string = apiResult.payload.stringFile;
                const type: string = apiResult.payload.contentType;
                fileName = apiResult.payload.fileName;

                const url = window.URL.createObjectURL(new Blob([file], { type }));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", fileName);
                document.body.appendChild(link);
                link.click();
            });
        } else {
            // Error. What to do?
            this.setIsErrored(true);
            if (apiResult.errors !== null && apiResult.errors !== undefined && apiResult.errors.length > 0) {
                this.Errors = apiResult.errors[0].message;
                this.history.push("/");
            }
        } */
    };

    @action
    public downloadUnitsAsCsv = async (): Promise<ApiResult<FileDownloadResponseDTO>> => {
        let apiResult: ApiResult<FileDownloadResponseDTO> = {
            wasSuccessful: false,
            errors: [],
            headers: "",
            payload: { fileName: "", stringFile: "", contentType: "", byteFile: undefined },
        };

        try {
            if (this.IsDownloading === false && this.IsLoading === false) {
                this.IsDownloading = true;

                apiResult = await this.Post<any>(Server.Api.Unit.downloadUnitsAsCsv);

                if (apiResult.wasSuccessful) {
                    const file: string = apiResult.payload.stringFile;
                    const type: string = apiResult.payload.contentType;
                    const fileName: string = apiResult.payload.fileName;

                    const url = window.URL.createObjectURL(new Blob([file], { type }));
                    const link = document.createElement("a");

                    link.href = url;
                    link.setAttribute("download", fileName);

                    document.body.appendChild(link);

                    link.click();
                }
            }
        } catch (exception) {
        } finally {
            runInAction(() => {
                this.IsDownloading = false;
            });
        }

        return apiResult;
    };
}
