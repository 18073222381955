import * as React from "react";
import * as MobXReact from "mobx-react-lite";
import { ClientDetailsContainer } from "./Client.styles";
import { ClientDetailsViewModel } from "./ClientDetailsViewModel";
import { useRouter } from "Core/Base";
import { useLoadCall } from "Custom/Utils/useLoadCall";
import { Loader } from "Core/Components/Loader";
import { NotFoundModal } from "Custom/Components/NotFoundModal/NotFoundModal";
import { AppUrls } from "Custom/Globals";
import { ITab, Tabs } from "Custom/Components/Tabs/Tabs";
import { useHasRoutedTabs } from "Custom/Utils/useHasRoutedTabs";
import { ClientDetailsProjectList } from "./ClientDetailsProjectList";
import { Typography } from "@material-ui/core";
import { ClientDetailsPropertyList } from "./ClientDetailsPropertyList";

const TAB_PROJECTS = "projects";
const TAB_ACTIVE_PROPERTIES = "activeProperties";

const tabOptions: ITab[] = [
    { key: TAB_PROJECTS, title: "Projects", hash: "projects" },
    { key: TAB_ACTIVE_PROPERTIES, title: "Active properties", hash: "properties" },
];

export const ClientDetailsPage: React.FC = () => {
    const [viewModel] = React.useState(() => new ClientDetailsViewModel());

    const router = useRouter<{ clientId: string }>();

    const notFound = useLoadCall(
        async () => {
            const { clientId } = router.match.params;

            await viewModel.loadClient(clientId);
            return !!viewModel.getValue("id");
        },
        { checkGuidParamKey: "clientId" },
    );

    const hashTab = useHasRoutedTabs(tabOptions);
    const [selectedTab, setSelectedTab] = React.useState(hashTab?.key || TAB_PROJECTS);
    const [tabKey, setTabKey] = React.useState(0);

    const handleTabClick = (tab: ITab) => {
        setSelectedTab(tab.key);
        setTabKey(tabKey + 1);
    };

    const getTabKey = (): string => {
        return `clienthome-tab-${selectedTab}-${tabKey}`;
    };

    const renderTabContent = () => {
        switch (selectedTab) {
            case TAB_PROJECTS:
                return <ClientDetailsProjectList key={getTabKey()} viewModel={viewModel} />;
            case TAB_ACTIVE_PROPERTIES:
                return <ClientDetailsPropertyList key={getTabKey()} viewModel={viewModel} />;
        }
    };

    const renderPage = () => {
        if (viewModel.IsLoading || notFound) return <Loader />;

        return (
            <ClientDetailsContainer>
                <div className="title">
                    <Typography variant="h1">{viewModel.getValue("clientName")}</Typography>
                </div>
                <Tabs tabs={tabOptions} onTabClick={handleTabClick} selected={selectedTab} minTabWidth={132} tabPadding="11.5px 8px" />

                <div className="content">{renderTabContent()}</div>
            </ClientDetailsContainer>
        );
    };

    return MobXReact.useObserver(() => (
        <>
            <NotFoundModal returnRoute={AppUrls.Client.Main.Client.Root} open={notFound} />
            {renderPage()}
        </>
    ));
};
