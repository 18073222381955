import { Box, Fade, Typography } from "@material-ui/core";
import React, { useState } from "react";

import { ForgotPasswordViewModel } from "./ForgotPasswordViewModel";
import { useObserver } from "mobx-react-lite";
import { useRouter } from "Core/Utils";
import { BoxAccount, LogonTitle } from "./LoginPage.style";
import { PaperAccount, AccountBtnBox, AccountImageBox, LoginInstructionBox, LoginStatementBox } from "Custom/StylesAppSpecific/AccountStyling";
import { CSSGrid } from "Core/Components/Styles";

import logoUrl from "Custom/Content/RCGlogo.svg";
import { AcceptBtn } from "Custom/StylesAppSpecific/Controls/AcceptBtn";
import { CancelBtn } from "Custom/StylesAppSpecific/Controls/CancelBtn";
import { AppUrls } from "Custom/Globals";
import { RoofcareInput } from "Custom/StylesAppSpecific/Controls/RoofcareInput";

export const ForgotPasswordPage = () => {
    const [viewModel] = useState(() => new ForgotPasswordViewModel());
    const { history } = useRouter();
    const [errorMessage, setErrorMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    const doSubmit = async (e: any) => {
        e.preventDefault();

        setErrorMessage("");
        setIsSuccess(false);

        if (viewModel.isModelValid()) {
            setIsLoading(true);
            const apiResult: any = await viewModel.ForgotPasswordAsync();
            setIsLoading(false);
            if (apiResult !== null) {
                if (apiResult.wasSuccessful) {
                    setIsSuccess(true);
                } else {
                    if (apiResult && apiResult.errors && apiResult.errors.length > 0) {
                        // if the email address doesn't exist don't say so, that if a security risk
                        if (apiResult.errors[0].message.indexOf("email address does not belong to an account.") != -1) {
                            setIsSuccess(true);
                        } else {
                            setErrorMessage("* " + apiResult.errors[0].message);
                        }
                    } else {
                        setErrorMessage("* Unknown error has occurred.");
                    }
                }
            }
        }
    };

    const cancel = () => {
        history.push(AppUrls.Client.Root);
    };

    return useObserver(() => (
        <Fade in timeout={1000}>
            <PaperAccount style={{ top: 0, padding: 0, height: "100vh" }}>
                <BoxAccount style={{ width: "330px", padding: 0 }}>
                    {isSuccess === false && (
                        <>
                            <LoginStatementBox>
                                <Box>
                                    <LogonTitle style={{ textAlign: "left" }}>Roofcare portal</LogonTitle>
                                </Box>
                                <Typography style={{ fontSize: "14px", color: "white", fontFamily: "'Mont',sans-serif", textAlign: "left", lineHeight: 1.9 }}>
                                    Ooops, you forgot your password!
                                </Typography>
                            </LoginStatementBox>
                            <LoginInstructionBox>
                                <Typography style={{ fontSize: "14px", color: "white", fontFamily: "'Arial',sans-serif", textAlign: "left", lineHeight: 1.8 }}>
                                    Please enter the email address you use to log in below and click on the "Forgot password" button. We will then send you an email containing a
                                    link for you to reset your password.
                                </Typography>
                            </LoginInstructionBox>
                        </>
                    )}
                    <form onSubmit={doSubmit} autoComplete="new-password">
                        {isSuccess === false && (
                            <>
                                {errorMessage !== "" && (
                                    <Typography style={{ color: "red" }} variant="caption">
                                        {errorMessage}
                                    </Typography>
                                )}
                                <CSSGrid columns={"1fr"} gap={"1em"}>
                                    <RoofcareInput
                                        type="email"
                                        label=""
                                        validateOnBlur={true}
                                        viewModel={viewModel}
                                        fieldName="emailAddress"
                                        shrink={true}
                                        inputProps={{ placeholder: "Enter your login email address" }}
                                    />
                                </CSSGrid>
                                <AccountBtnBox>
                                    <AcceptBtn type="submit" disabled={isLoading}>
                                        {isLoading ? "Please Wait..." : "Forgot password"}
                                    </AcceptBtn>
                                    <CancelBtn disabled={isLoading} onClick={cancel}>
                                        {isLoading ? "Please Wait..." : "Cancel"}
                                    </CancelBtn>
                                </AccountBtnBox>
                            </>
                        )}
                        {isSuccess == true && (
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                                <Box>
                                    <LogonTitle style={{ textAlign: "left" }}>Roofcare portal</LogonTitle>
                                </Box>
                                <Box style={{ padding: "15px 0" }}>
                                    <Typography style={{ fontSize: "14px", color: "white", fontFamily: "'Arial',sans-serif", textAlign: "left", lineHeight: 1.8 }}>
                                        Success. An email has been sent to the email address if it exists. Please check your email.
                                    </Typography>
                                </Box>
                                <AcceptBtn onClick={cancel} disabled={isLoading}>
                                    {isLoading ? "Please Wait..." : "Return to Login"}
                                </AcceptBtn>
                            </div>
                        )}
                    </form>
                </BoxAccount>
            </PaperAccount>
        </Fade>
    ));
};
