import { Avatar, Box, Button, Fade, FormControl, FormHelperText, MenuItem, Paper, Select, Typography } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";

import { ControlLabel } from "Custom/Components/ControlLabel/ControlLabel";
import { PropertyViewModel } from "../PropertyViewModel";
import { PropertyDocumentsViewModel } from "./PropertyDocumentViewModel";
import { Container } from "Custom/StylesAppSpecific/AppStyling";
import { ProjectDocumentsContainer, AddDocumentBox, ItemTitle } from "./PropertyDocument.style";
import { ClickableImgCell } from "Custom/Components/Table/ClickableImgCell";
import AddLogo from "Custom/Content/plusIcon.svg";
import { BaseModal } from "Custom/Components/Modal/BaseModal";
import { DeleteModalDialogPaper } from "Custom/Components/Modal/DeleteModal.styles";
import { AcceptBtn } from "Custom/StylesAppSpecific/Controls/AcceptBtn";
import { CancelBtn } from "Custom/StylesAppSpecific/Controls/CancelBtn";
import { useDropzone } from "react-dropzone";
import { DocumentCategory } from "Custom/Models/Domain/Projects";
import { generateID } from "Core/Utils/Utils";
import { useObserver } from "Core/Base";
import { PropertyDocumentList } from "./PropertyDocumentList";

interface IProps {
    externalViewModel: PropertyViewModel;
    title?: string;
}

export const PropertyDocumentsTab: React.FC<IProps> = ({ externalViewModel, title }) => {
    const maxSize = 100000000; // 100mb
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({ maxSize: maxSize, minSize: 1, multiple: true });

    const [internalViewModel] = useState(() => new PropertyDocumentsViewModel());
    const [open, setOpen] = React.useState(false);
    const [valid, setModelValid] = React.useState(false);
    const [firstTime, setFirstTime] = React.useState(true);

    //useEffect below only gets run on initial render
    useEffect(() => {
        internalViewModel.setValue("documentCategoryId", 0);
        internalViewModel.setValue("filename", "");
        //This gets run when the page is exited
        return () => {};
    }, []);

    useEffect(() => {
        setModelValid(internalViewModel.isModelValid());
    }, [internalViewModel.isModelValid()]);

    useEffect(() => {
        if (acceptedFiles.length === 0) {
            internalViewModel.setValue("filename", "");
        } else if (acceptedFiles.length >= 1) {
            internalViewModel.setValue("filename", acceptedFiles[0].name);
        }
    }, [acceptedFiles]);

    const setOpenAddModal = () => {
        internalViewModel.setValue("documentCategoryId", 0);
        internalViewModel.setValue("filename", "");
        setOpen(true);
        setFirstTime(true);
    };

    const onClose = () => {
        internalViewModel.setValue("documentCategoryId", 0);
        internalViewModel.setValue("filename", "");
        setOpen(false);
        setFirstTime(true);
    };

    const handleSubmitDocument = (e: React.FormEvent) => {
        e.preventDefault();
        setFirstTime(false);
        if (internalViewModel.isModelValid() === true) {
            const ret = externalViewModel.upsertDocument(acceptedFiles, internalViewModel.model.documentCategoryId);

            ret.then((result) => {
                if (result.wasSuccessful === true) {
                    internalViewModel.setValue("documentCategoryId", 0);
                    internalViewModel.setValue("filename", "");
                }
            });
            setOpen(false);
            setFirstTime(true);
        }
    };

    const files = acceptedFiles.map((file: any) => (
        <p key={file.path}>
            {file.path} - {file.size} bytes
        </p>
    ));

    const handleDocumentCategoryChange = async (event: any) => {
        const category: number = parseInt(event.target.value);
        internalViewModel.setValue("documentCategoryId", category);
    };

    const isInError = (fieldName: string) => {
        let isValid = internalViewModel.getValid(fieldName);
        return !isValid;
    };

    const tabTitle: string = title !== null && title !== undefined && title.length > 0 ? title : "Project Documents";

    return useObserver(() => (
        <Container>
            <Fade in timeout={1000}>
                <>
                    <BaseModal
                        open={open}
                        onClose={onClose}
                        title="Add new document"
                        PaperComponent={DeleteModalDialogPaper}
                        actions={
                            <>
                                <AcceptBtn onClick={handleSubmitDocument} color="secondary" autoFocus variant="contained">
                                    Add Document
                                </AcceptBtn>
                                <CancelBtn onClick={onClose}>Cancel</CancelBtn>
                            </>
                        }
                    >
                        <AddDocumentBox>
                            <div style={{ paddingBottom: "15px" }}>
                                <ControlLabel label="Document category" htmlFor="role-documentCategory">
                                    <Select
                                        id="role-documentCategory"
                                        value={internalViewModel.model.category.toString()}
                                        onChange={handleDocumentCategoryChange}
                                        MenuProps={{
                                            getContentAnchorEl: null,
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "left",
                                            },
                                        }}
                                        fullWidth
                                    >
                                        {externalViewModel.documentCategories.map((dc: DocumentCategory, index: number) => {
                                            return (
                                                <MenuItem key={generateID()} value={dc.id}>
                                                    {dc.categoryName}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                    {!firstTime && isInError("documentCategoryId") && (
                                        <FormHelperText style={{ color: "red" }}>{internalViewModel.getError("documentCategoryId")}</FormHelperText>
                                    )}
                                </ControlLabel>
                            </div>
                            <ControlLabel label="File" htmlFor="dropzone" />
                            {internalViewModel.model.filename.length === 0 && (
                                <div {...getRootProps({ className: "dropzone" })}>
                                    <input id="dropzone" {...getInputProps()} />
                                    <p style={{ color: "#111111" }}>Drag a file onto here, or click to select</p>
                                </div>
                            )}
                            {internalViewModel.model.filename.length > 0 && <aside>{files}</aside>}
                            {!firstTime && isInError("filename") && <FormHelperText style={{ color: "red" }}>{internalViewModel.getError("filename")}</FormHelperText>}
                        </AddDocumentBox>
                    </BaseModal>

                    <ProjectDocumentsContainer>
                        <div style={{ padding: "0 30px", display: "flex", flexDirection: "row", alignSelf: "flex-start", flex: "1 0 0", width: "100" }}>
                            <Typography variant="h1" component="h3">
                                {tabTitle}
                            </Typography>
                            <ClickableImgCell className="projectdocument-add onitsown" logo={AddLogo} text={"Add new document"} onClick={setOpenAddModal} id="0" />
                        </div>
                    </ProjectDocumentsContainer>

                    {externalViewModel.documents.length > 0 && (
                        <>
                            <ProjectDocumentsContainer>
                                <div style={{ display: "flex", flexDirection: "column", flex: "1 0 0" }}>
                                    <PropertyDocumentList viewModel={externalViewModel} />
                                </div>
                            </ProjectDocumentsContainer>
                            <div style={{ marginTop: "15px" }}>
                                <ClickableImgCell className="projectnote-addedit onitsown" logo={AddLogo} text={"Add new document"} onClick={setOpenAddModal} id="0" />
                            </div>
                        </>
                    )}
                </>
            </Fade>
        </Container>
    ));
};
