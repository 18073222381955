import { ViewModelBase } from "Core/ViewModels/ViewModelBase";
import { SimpleImageModalModel } from "./SimpleImageModalModel";
import { FieldType } from "Core/Utils/Utils";
import { action, computed, IObservableArray, observable, runInAction } from "mobx";
import { InstallationCommissionImage } from "./InstallationCommissionedImage";
import { FileDownloadResponseDTO } from "../FileDownloadResponseDTO";
import { ApiResult } from "Core/Models";
import { Server } from "Custom/Globals/AppUrls";

//extend viewmodel base and passing your model as the generic type
export class SimpleImageModalViewModel extends ViewModelBase<SimpleImageModalModel> {
    //Singleton instance of class
    private static _instance: SimpleImageModalViewModel;
    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    @observable public IsDownloading: boolean = false;

    @observable public errorMessage: string = "";

    @observable public selectedImageId: number = -1;
    @observable public images: IObservableArray<InstallationCommissionImage> = observable([]);

    constructor() {
        //Pass in a new instance of your model
        //By passing in true as the second parameter, we make this model undoable which means we can use save and reset options on the model
        //If you make a change to the model you need to persist it with a saveModel() call
        //If you make changes to your model you can revert it back by calling resetModel()
        super(new SimpleImageModalModel(), false);
        //EN - Haven't figured out how to make this call work from the base model yet
        //This is only needed if you make use of the validation decorators
        this.setDecorators(SimpleImageModalModel);
    }

    @action
    public clear() {
        this.selectedImageId = -1;
        this.images.clear();
        this.errorMessage = "";
    }

    @action
    setSelectedImageId(initialImageId: number) {
        if (this.selectedImageId === -1) {
            this.selectedImageId = initialImageId;
        }
    }

    @action
    setImages(images: InstallationCommissionImage[]) {
        this.images.replace(images);
    }

    @action
    public moveNext() {
        const current = this.images.find((a) => a.id === this.selectedImageId);

        if (current !== undefined) {
            let newIndex: number = this.images.indexOf(current) + 1;

            if (newIndex > this.images.length - 1) {
                newIndex = this.images.length - 1;
            }
            const newImage = this.images[newIndex];

            this.selectedImageId = newImage.id;
        }
    }

    @action
    public movePrevious() {
        const current = this.images.find((a) => a.id === this.selectedImageId);

        if (current !== undefined) {
            let newIndex: number = this.images.indexOf(current) - 1;

            if (newIndex < 0) {
                newIndex = 0;
            }

            const newImage = this.images[newIndex];

            this.selectedImageId = newImage.id;
        }
    }

    @computed
    public get canMovePrevious(): boolean | undefined {
        if (this.selectedImageId === -1 || this.images.length < 2) {
            return false;
        }

        const current = this.images.find((a) => a.id === this.selectedImageId);

        if (current !== undefined) {
            const currentIndex: number = this.images.indexOf(current);

            if (currentIndex > 0) {
                return true;
            }
        }

        return false;
    }

    @computed
    public get canMoveNext(): boolean | undefined {
        if (this.selectedImageId === -1 || this.images.length < 2) {
            return false;
        }
        const current = this.images.find((a) => a.id === this.selectedImageId);

        if (current !== undefined) {
            const currentIndex: number = this.images.indexOf(current);

            if (currentIndex < this.images.length - 1) {
                return true;
            }
        }

        return false;
    }

    @computed
    public get selectedImage(): InstallationCommissionImage | undefined {
        return this.images.find((a) => a.id === this.selectedImageId);
    }

    public downloadImage = async () => {
        let apiResult: ApiResult<FileDownloadResponseDTO> = {
            wasSuccessful: false,
            errors: [],
            headers: "",
            payload: { fileName: "", stringFile: "", contentType: "", byteFile: undefined },
        };

        try {
            if (this.IsDownloading === false && this.IsLoading === false) {
                //this.IsDownloading = true;
                window.open(Server.Api.Image.downloadCommissioningDeviceImage + "?imageId=" + this.selectedImageId);
            }
        } catch (exception) {
        } finally {
            runInAction(() => {
                this.IsDownloading = false;
            });
        }

        return apiResult;
    };

    @action
    public flipImage = async () => {
        const apiResult = await this.Post<InstallationCommissionImage>(Server.Api.Installation.updateCommissionImageOrientation, {
            id: this.selectedImage!.id,
            flipped: !this.selectedImage!.flipped,
        });

        if (apiResult.wasSuccessful) {
            this.setFlipped(apiResult.payload.flipped);
        }
    };

    @action
    public setFlipped = (flipped: boolean) => {
        if (this.selectedImage !== undefined) {
            this.selectedImage!.flipped = flipped;
        }
    };

    //isValid will check all fields to make sure they are in a valid state.
    public doSubmit = async (e: any) => {
        e.preventDefault();

        if (this.isModelValid()) {
            //Do stuff here
            this.errorMessage = "Form is valid";
        } else {
            this.errorMessage = "Form is not valid";
        }
    };

    //This must be present in your viewmodel. Just return true if you dont need to validate anything.
    //keyof BlankModel & string lets you add type checking to the fieldName
    //I am using the validator package to make checking easier https://www.npmjs.com/package/validator
    public isFieldValid(fieldName: keyof FieldType<SimpleImageModalModel>): boolean {
        const { isValid, errorMessage } = this.validateDecorators(fieldName);

        //You need to this two properties after validation
        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
