export enum AlertDirection {
    UP = 0,
    UPDOWN = 1,
    DOWN = 2,
}

export enum ConditionType {
    H = 0,
    FB = 1,
    P1 = 2,
    P2 = 3,
}

export enum ConditionUnits {
    MM = 0,
    PERCENT = 1,
}
