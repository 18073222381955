import { Box, Paper, Typography, useTheme } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import { ContactListItem } from "./ContactListItem";
import { ContactListViewModel } from "./ContactListViewModel";
import { Loader } from "Core/Components";
import { useObserver } from "mobx-react-lite";
import { ResetSearchIcon } from "Custom/Components/Table/ResetSearchIcon";
import * as Defaults from "Custom/StylesAppSpecific/TableOptions";
import { ProjectToolbarRowV2, ProjectToolbarRowV3 } from "Custom/StylesAppSpecific/Controls/BoxAddWrapper";
import { RoofcareTable, RoofcareTableWrapper } from "Custom/Components/Table/RoofcareTable";
import { formatEditContextual } from "Custom/Components/AddEditControls";
import { Container } from "Custom/StylesAppSpecific/AppStyling";
import { ContactType, ContactTypeHelpers } from "Custom/Models/Domain/Contacts/ContactType";
import EditLogo from "Custom/Content/editIcon.svg";
import { ProjectListSearch } from "../Projects/ProjectListSearch";
import { ClickableImgCell } from "Custom/Components/Table/ClickableImgCell";
import { MultiSelectView } from "Custom/Components/Select/MultipleSelect";
import { ContactAddModal } from "Custom/Components/Contact/ContactModal.View";
import { ContactModel, ContactModelDTO } from "Custom/Components/Contact/";
import { ApiResult } from "Core/Models";
import { NewContactModelDTO } from "../Projects/Contact";

export interface IContactState {
    addEditOpen: boolean;
    id: string;
    contactType: ContactType;
    modalContact: ContactModel;
}

export const ContactListPage: React.FunctionComponent = () => {
    const [viewModel] = useState(() => new ContactListViewModel());
    const [addEditOpen, setAddEditOpen] = useState<IContactState>({ addEditOpen: false, id: "", contactType: ContactType.Client, modalContact: new ContactModel() });
    const theme = useTheme();

    //useEffect below only gets run on initial render
    useEffect(() => {
        if (viewModel.IsLoading == false) {
            const response = viewModel.load();
            response.then(() => {});
        }

        return () => {
            //console.log("Unmounting");
            viewModel.clear();
        };
    }, []);

    const renderPage = () => {
        if (viewModel.IsLoading) return <Loader />;

        const tableOptions = Defaults.GetRoofcareTableOptions() as any;

        const getSortDirection = (columnIndex: number) => {
            return viewModel.sortColumnId === columnIndex ? viewModel.sortDirection : "";
        };

        const setOrderChange = (columnId: number, direction: any) => {
            viewModel.setOrderChange(columnId, direction);
        };

        const setSearchString = (value: string) => {
            viewModel.setSearchString(value);
            viewModel.setPage(0);
        };

        const setRowsPerPage = (rows: number) => {
            viewModel.setRowsPerPage(rows);
        };

        const setPage = (page: number) => {
            viewModel.setPage(page);
        };

        function handleCSVClick(id: string) {
            viewModel.downloadCSV();
        }

        const isInError = (fieldName: string): boolean => {
            /*         let isValid = props.filtersViewModel.getValid(fieldName);
        return !isValid; */

            return false;
        };

        const getError = (fieldName: string): string => {
            return "";
        };

        const handleContactTypeFilterChange = (values: number[] | string[] | null) => {
            viewModel.setContactTypeFilter(values as string[]);
        };

        const editContactClick = (id: string, context: string) => {
            let contactType: ContactType = parseInt(context) as ContactType;
            setAddEditOpen({ addEditOpen: true, id: "", contactType: contactType, modalContact: viewModel.getContactModel(id, contactType) });
        };

        const handleContactClose = () => {
            setAddEditOpen({ addEditOpen: false, id: "", contactType: ContactType.Client, modalContact: new ContactModel() });
        };

        const handleSaveContact = async (model: ContactModel) => {
            // We have to assume this model is valid (given it took several hours to debug it to prove it was valid and fix the issues in validation)

            let promise: ApiResult<NewContactModelDTO[]> = await viewModel.updateContact(model, addEditOpen.contactType);

            if (promise.wasSuccessful === true) {
                setAddEditOpen({ addEditOpen: false, id: "", contactType: ContactType.Client, modalContact: new ContactModel() });
            }
        };

        return (
            <Container>
                {
                    <>
                        <ContactAddModal onClose={handleContactClose} open={addEditOpen.addEditOpen} onAdd={handleSaveContact} contact={addEditOpen.modalContact} />
                        <RoofcareTableWrapper>
                            <ProjectToolbarRowV2>
                                <div className="search-field" style={{ width: "580px" }}>
                                    <ProjectListSearch searchString={viewModel.getSearchString()} setSearchString={setSearchString} placeholder="Start typing to search contacts" />
                                </div>
                                <div className="download-csv">
                                    <ClickableImgCell className="projectnote-addedit" logo={EditLogo} text={"Download Contacts CSV"} onClick={handleCSVClick} id={"0"} />
                                </div>
                            </ProjectToolbarRowV2>
                            <ProjectToolbarRowV3 style={{ marginTop: "20px" }}>
                                <Box>
                                    <Box>
                                        <Typography style={{ color: theme.palette.secondary.contrastText }} variant="h2" className="select-title">
                                            Contact Type:
                                        </Typography>
                                        <Box className="multiselectbox">
                                            <MultiSelectView
                                                fieldName=""
                                                values={viewModel.getContactTypeFilter}
                                                display="End users"
                                                useNumber={true}
                                                getOptions={() => viewModel.getContactTypeFilterOptions}
                                                handleChange={handleContactTypeFilterChange}
                                                getError={getError}
                                                getValid={isInError}
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                            </ProjectToolbarRowV3>
                        </RoofcareTableWrapper>

                        <RoofcareTable
                            columns={[
                                {
                                    title: "Contact Type",
                                    field: "contactType",
                                    sorting: true,
                                    filtering: true,
                                    render: (rowData: ContactListItem) => {
                                        if (rowData !== null && rowData !== undefined) {
                                            return <div className={"resolved "}>{ContactTypeHelpers.getText(rowData.contactType)}</div>;
                                        }
                                    },
                                    defaultSort: getSortDirection(0),
                                },
                                { title: "Company", field: "parent", sorting: true, filtering: false, defaultSort: getSortDirection(1), width: "auto" },
                                { title: "Company Postcode", field: "parentPostcode", sorting: true, filtering: false, defaultSort: getSortDirection(2) },
                                { title: "Contact Name", field: "contactName", sorting: true, filtering: false, defaultSort: getSortDirection(3), width: "auto" },
                                { title: "Position", field: "position", sorting: true, filtering: false, defaultSort: getSortDirection(4) },
                                { title: "Contact Email", field: "email", sorting: true, filtering: false, defaultSort: getSortDirection(5), width: "auto" },
                                { title: "Contact Number", field: "phone", sorting: true, filtering: false, defaultSort: getSortDirection(6) },
                                { title: "Other Number", field: "phone2", sorting: true, filtering: false, defaultSort: getSortDirection(7) },
                                {
                                    title: "",
                                    field: "id",
                                    sorting: false,
                                    filtering: false,
                                    render: (rowData: ContactListItem) => {
                                        if (rowData !== null && rowData !== undefined) {
                                            return formatEditContextual(rowData.sourceId!, rowData.contactType.toString(), "contact", editContactClick);
                                        }
                                    },
                                },
                            ]}
                            style={{
                                backgroundColor: "#3B3C3B",
                                padding: 0,
                            }}
                            options={{
                                thirdSortClick: false,
                                cellStyle: {
                                    fontSize: 10,
                                },
                                initialPage: viewModel.currentPage,
                                defaultGroupOrder: viewModel.sortColumnId,
                                defaultGroupDirection: viewModel.sortDirection,
                                pageSize: viewModel.pageSize,
                                pageSizeOptions: [10, 20, 50, 100],
                                emptyRowsWhenPaging: false,
                                headerStyle: {
                                    ...tableOptions.headerStyle,
                                },
                                rowStyle: {
                                    ...tableOptions.rowStyle,
                                },
                                search: false,
                                searchFieldAlignment: "left",
                                searchFieldStyle: {
                                    border: "1px solid #333333",
                                    borderRadius: "5px",
                                    width: "400px",
                                },
                                /* tableLayout: "fixed", */
                            }}
                            data={viewModel.getContacts}
                            title=""
                            icons={{
                                ResetSearch: () => (
                                    <span style={{ marginRight: "-11px" }}>
                                        <ResetSearchIcon />
                                    </span>
                                ),
                            }}
                            components={{
                                Container: (props: any) => <Paper {...props} elevation={0} />,
                                Toolbar: (props: any) => <></>,
                            }}
                            onOrderChange={(orderedColumnId: any, orderDirection: any) => {
                                setOrderChange(orderedColumnId, orderDirection);
                            }}
                            onChangeRowsPerPage={(rows: number) => {
                                setRowsPerPage(rows);
                            }}
                            onChangePage={(page: number) => {
                                setPage(page);
                            }}
                        />
                    </>
                }
            </Container>
        );
    };

    return useObserver(() => renderPage());
};
