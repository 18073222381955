import { ModelBase } from "Core/Models";
import { UnitStatus, UnitStatusDTO } from "Custom/Models/Domain";
import { action, observable } from "mobx";

//Base class expects the model type and the DTO model type
//If you are not using a DTO just pass in undefined as the DTO
export class DeviceStatusModel extends ModelBase<DeviceStatusModel, DeviceStatusModelDTO> {
    @observable public id: string = "";
    @observable public displayName: string = "";
    @observable public color: string = "";
    @observable public textColor: string = "";

    @action public clear() {
        this.id = "";
        this.displayName = "";
        this.color = "";
        this.textColor = "";
    }

    //fromDto is required but you can leave it blank
    fromDto(model: DeviceStatusModelDTO): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
    }

    //toDto is required but you can leave it blank
    toDto(model: DeviceStatusModel): void {}
}

export type DeviceStatusModelDTO = {
    id: string;
    displayName: string;
    color: string;
    textColor: string;
};
