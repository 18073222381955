import { DeviceUnitBehaviourItemModel } from "./../../../Views/Projects/Details/Properties/Installations/DeviceUnitBehaviourItemModel";
import { calculateActualHeight, isNullOrUndefined } from "Custom/Utils/utils";
import { action, observable } from "mobx";
import { ModelBase } from "Core/Models/ModelBase";
import { IsNotEmpty, Max, MaxLength, Min } from "class-validator";
import { DrainageType, InstallationStatusDataDTO, RoofType, SideType, StandingWaterStatus } from ".";
import { DeviceUnitBehaviourModel, DeviceUnitBehaviourModelDTO } from "Custom/Views/Projects/Details/Properties/Installations/DeviceUnitBehaviourModel";
import { UnitBehaviourItemModel } from "Custom/Views/Admin/UnitBehaviour/UnitBehaviourItemModel";
import { DeviceUnitDataMinDTO, DeviceUnitDataMinModel } from "Custom/Views/Units/DeviceUnitDataMin";
import { InstallationAndRelatedForEditDTO } from "./InstallationAndRelatedForEdit";

export class InstallationAddEdit extends ModelBase<InstallationAddEdit, InstallationAddEditDTO> {
    //Device Data
    @observable public id?: number = 0;
    @observable public rowVersion?: string = "";

    @observable
    @IsNotEmpty({ message: "Device id is required." })
    @MaxLength(15, { message: "Maximum Device id length is 15 characters" })
    public deviceId: string = "";

    @observable
    @IsNotEmpty({ message: "This is required." })
    @MaxLength(15, { message: "Maximum length is 15 characters" })
    public deviceIdConfirm: string = "";

    @observable
    @MaxLength(15, { message: "Maximum SIM id length is 15 characters" })
    @IsNotEmpty({ message: "SIM id is required." })
    public simId: string = "";

    @observable
    @MaxLength(15, { message: "Maximum length is 15 characters" })
    @IsNotEmpty({ message: "This is required." })
    public simIdConfirm: string = "";

    @observable
    @IsNotEmpty({ message: "Device name is required." })
    @MaxLength(50, { message: "Maximum name length is 50 characters" })
    public name: string = "";

    @observable public number: string = "";
    @observable public lastUpdated: string | undefined | null;
    @observable public latitude: number | undefined | null;
    @observable public longitude: number | undefined | null;
    @observable public earliestValue: string | undefined | null;

    @observable
    public roofcareAddressId: string | undefined | null = "";

    @observable public isDeleted: boolean = false;

    // CMR 31/03/2021 - Simon doesn't know if this is a required field or not.
    @observable public contractorId: string = "";

    //Device Status Data
    @observable public deviceStatusItemId?: number = 0;
    @observable public gutterSensId?: number = 0;
    @observable @IsNotEmpty({ message: "Side 1 length is required." }) public side1_Length: number = 0;
    @observable @IsNotEmpty({ message: "Side 2 length is required." }) public side2_Length: number = 0;
    @observable
    @IsNotEmpty({ message: "Side 1 Angle is required." })
    @Min(-180, { message: "Side 1 min angle is -180." })
    @Max(180, { message: "Side 1 max angle is 180." })
    public side1_Angle: number = 0;
    @observable
    @IsNotEmpty({ message: "Side 2 Angle is required." })
    @Min(-180, { message: "Side 2 min angle is -180." })
    @Max(180, { message: "Side 2 max angle is 180." })
    public side2_Angle: number = 0;

    @observable @IsNotEmpty({ message: "Side 1 length 2 is required." }) public side1_Length2: number = 0;
    @observable @IsNotEmpty({ message: "Side 2 length 2 is required." }) public side2_Length2: number = 0;
    @observable
    @IsNotEmpty({ message: "Side 1 Angle 2 is required." })
    @Min(-180, { message: "Side 1 min 2 angle is -180." })
    @Max(180, { message: "Side 1 max 2 angle is 180." })
    public side1_Angle2: number = 0;
    @observable
    @IsNotEmpty({ message: "Side 2 Angle 2 is required." })
    @Min(-180, { message: "Side 2 min angle 2 is -180." })
    @Max(180, { message: "Side 2 max angle 2 is 180." })
    public side2_Angle2: number = 0;

    @observable public side1_Height: number = 0;
    @observable public side2_Height: number = 0;
    @observable
    @IsNotEmpty({ message: "Install Height is required." })
    @Min(1, { message: "Install height is required" })
    public install_Height: number = 0;

    @observable
    @IsNotEmpty({ message: "Base Width is required." })
    @Min(0, { message: "Base Width is required" })
    public baseWidth: number = 0;

    @observable public sideType: SideType = SideType.ThreeSideSym;

    @observable public emailAddresses: string = "";
    @observable public mobileNumbers: string = "";

    @observable public roofType: RoofType = RoofType.Pitched;

    @IsNotEmpty({ message: "Max Height is required." })
    @observable
    public maxHeight: number = 0;
    @observable public drainageType: DrainageType = DrainageType.Gravity;
    @IsNotEmpty({ message: "P1 is required." })
    @observable
    public p1: number = 0;
    @IsNotEmpty({ message: "P2 is required." })
    @observable
    public p2: number = 0;
    @observable public siphonicBrand?: number | null = null;

    @IsNotEmpty({ message: "Base Measurment is required." })
    @observable
    public baseMeasurement: number = 0;

    @observable public statusDataIsDeleted: boolean = false;
    @observable public propertyId: string | null = null;
    @observable public statusDataContractorId: string | null = null;
    @observable public commissionDate: string | null = null;
    @observable public isComissioned: boolean = false;

    @observable public standingWaterDays: number = 5;
    @observable public standingWaterPercent: number = 10;
    public standingWaterStatus: StandingWaterStatus = StandingWaterStatus.Unknown;

    @observable public allowSWEmailSend: boolean = true;
    @observable public allowSWSMSSend: boolean = true;

    @observable public unitData: DeviceUnitDataMinModel = new DeviceUnitDataMinModel();

    // We send this to the server, but never read it
    public initialCommand: string | undefined = undefined;

    public createdDate: string = "";

    @observable
    public unitBehaviour: DeviceUnitBehaviourModel | undefined = undefined;

    public isUnitBehaviourValid: boolean = true;

    constructor() {
        super();
        this.setError("unitBehaviour", "");
        this.setValid("unitBehaviour", false);
    }

    @action public updateItemFromVM(vm: UnitBehaviourItemModel) {
        if (this.unitBehaviour !== undefined) {
            let toUpdate: DeviceUnitBehaviourItemModel | undefined = this.unitBehaviour!.items.find((a) => a.commandIndex === vm.commandIndex);

            if (toUpdate !== undefined) {
                toUpdate!.name = vm.name;
                toUpdate!.type = vm.type;
                toUpdate!.value = vm.value;
                toUpdate!.units = vm.units;
                toUpdate!.frequency = vm.frequency;
                toUpdate!.reading = vm.reading;
            }
        }
    }

    //fromDto is required but you can leave it blank
    @action
    fromDto(model: InstallationAddEditDTO): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
    }

    @action
    public setPropertyId(propertyId: string) {
        if (isNullOrUndefined(this.roofcareAddressId) === true || this.roofcareAddressId === "0") {
            this.roofcareAddressId = propertyId;
        }
    }

    public getStatusData(): InstallationStatusDataDTO {
        return {
            id: this.deviceStatusItemId,
            gutterSensId: this.gutterSensId,
            side1_Length: this.side1_Length,
            side2_Length: this.side2_Length,
            side1_Angle: this.side1_Angle,
            side2_Angle: this.side2_Angle,
            side1_Height: this.side1_Height,
            side2_Height: this.side2_Height,
            install_Height: this.install_Height,
            emailAddresses: this.emailAddresses,
            mobileNumbers: this.mobileNumbers,
            roofType: this.roofType,
            maxHeight: this.maxHeight,
            drainageType: this.drainageType,
            p1: this.p1,
            p2: this.p2,
            siphonicBrand: this.siphonicBrand,
            baseMeasurement: this.baseMeasurement,
            isDeleted: this.isDeleted,
            propertyId: this.propertyId,
            contractorId: this.contractorId,
            commissionDate: this.commissionDate,
            isComissioned: this.isComissioned,

            standingWaterDays: this.standingWaterDays,
            standingWaterPercent: this.standingWaterPercent,
            standingWaterEnum: this.standingWaterStatus,

            allowSWEmailSend: this.allowSWEmailSend,
            allowSWSMSSend: this.allowSWSMSSend,

            side1_Length2: this.side1_Length2,
            side2_Length2: this.side2_Length2,
            side1_Angle2: this.side1_Angle2,
            side2_Angle2: this.side2_Angle2,
            baseWidth: this.baseWidth,
            sideType: this.sideType,
        };
    }

    @action
    public setStatusData(model: InstallationStatusDataDTO | undefined): void {
        //Device Status Data
        this.deviceStatusItemId = model !== undefined ? model.id : undefined;
        this.gutterSensId = model !== undefined ? model.gutterSensId : undefined;
        this.side1_Length = model !== undefined ? model.side1_Length : 0;
        this.side2_Length = model !== undefined ? model.side2_Length : 0;
        this.side1_Angle = model !== undefined ? model.side1_Angle : 0;
        this.side1_Angle = model !== undefined ? model.side1_Angle : 0;
        this.side2_Angle = model !== undefined ? model.side2_Angle : 0;
        this.side1_Height = model !== undefined ? model.side1_Height : 0;
        this.side2_Height = model !== undefined ? model.side2_Height : 0;
        this.install_Height = model !== undefined ? model.install_Height : 0;
        this.emailAddresses = model !== undefined ? model.emailAddresses : "";
        this.mobileNumbers = model !== undefined ? model.mobileNumbers : "";
        this.roofType = model !== undefined ? model.roofType : RoofType.Pitched;
        this.maxHeight = model !== undefined ? model.maxHeight : 0;
        this.drainageType = model !== undefined ? model.drainageType : DrainageType.Gravity;
        this.p1 = model !== undefined ? model.p1 : 0;
        this.p2 = model !== undefined ? model.p2 : 0;
        this.siphonicBrand = model !== undefined ? model.siphonicBrand : null;
        this.baseMeasurement = model !== undefined ? model.baseMeasurement : 0;

        this.statusDataIsDeleted = model !== undefined ? model.isDeleted : false;
        this.propertyId = model !== undefined ? model.propertyId : null;
        this.statusDataContractorId = model !== undefined ? model.contractorId : null;
        this.commissionDate = model !== undefined ? model.commissionDate : null;
        this.isComissioned = model !== undefined ? model.isComissioned : false;

        this.standingWaterDays = model !== undefined ? model.standingWaterDays : 5;
        this.standingWaterPercent = model !== undefined ? model.standingWaterPercent : 10;
        this.standingWaterStatus = model !== undefined ? model.standingWaterEnum : 0;

        this.allowSWEmailSend = model !== undefined ? model.allowSWEmailSend : true;
        this.allowSWSMSSend = model !== undefined ? model.allowSWSMSSend : true;

        this.side1_Length2 = model !== undefined ? model.side1_Length2 : 0;
        this.side2_Length2 = model !== undefined ? model.side2_Length2 : 0;
        this.side1_Angle2 = model !== undefined ? model.side1_Angle2 : 0;
        this.side2_Angle2 = model !== undefined ? model.side2_Angle2 : 0;
        this.baseWidth = model !== undefined ? model.baseWidth : 0;
        this.sideType = model !== undefined ? model.sideType : SideType.ThreeSideSym;
    }

    @action
    fromInstallAndRelatedDto(model: InstallationAndRelatedForEditDTO): void {
        this.id = model.device.id;
        this.rowVersion = model.device.rowVersion;
        this.lastUpdated = model.device.lastUpdated;
        this.latitude = model.device.latitude;
        this.longitude = model.device.longitude;
        this.roofcareAddressId = model.device.roofcareAddressId === null || model.device.roofcareAddressId === undefined ? "" : model.device.roofcareAddressId;
        this.isDeleted = model.device.isDeleted;
        this.contractorId = model.device.contractorId ? model.device.contractorId : "";
        this.simId = model.device.simId ? model.device.simId : "";
        this.deviceId = model.device.deviceId ? model.device.deviceId : "";

        this.earliestValue = model.device.earliestValue;

        // auto load the confirms
        this.simIdConfirm = this.simId;
        this.deviceIdConfirm = this.deviceId;

        this.createdDate = model.device.createdDate ? model.device.createdDate : "";
        this.name = model.device.name;
        this.number = model.device.number;
        this.rowVersion = model.device.rowVersion ? model.device.rowVersion : "";

        //Device Status Data
        this.deviceStatusItemId = model.statusData?.id;
        this.gutterSensId = model.device.id;
        this.side1_Length = model.statusData?.side1_Length ? model.statusData?.side1_Length : 0;
        this.side2_Length = model.statusData?.side2_Length ? model.statusData?.side2_Length : 0;
        this.side1_Angle = model.statusData?.side1_Angle ? model.statusData?.side1_Angle : 0;
        this.side1_Angle = model.statusData?.side1_Angle ? model.statusData?.side1_Angle : 0;
        this.side2_Angle = model.statusData?.side2_Angle ? model.statusData?.side2_Angle : 0;
        this.side1_Height = model.statusData?.side1_Height ? model.statusData?.side1_Height : 0;
        this.side2_Height = model.statusData?.side2_Height ? model.statusData?.side2_Height : 0;
        this.install_Height = model.statusData?.install_Height ? model.statusData?.install_Height : 0;
        this.emailAddresses = model.statusData?.emailAddresses ? model.statusData?.emailAddresses : "";
        this.mobileNumbers = model.statusData?.mobileNumbers ? model.statusData?.mobileNumbers : "";
        this.roofType = model.statusData?.roofType ? model.statusData?.roofType : RoofType.Pitched;
        this.maxHeight = model.statusData?.maxHeight ? model.statusData?.maxHeight : 0;
        this.drainageType = model.statusData?.drainageType ? model.statusData?.drainageType : DrainageType.Gravity;
        this.p1 = model.statusData?.p1 ? model.statusData?.p1 : 0;
        this.p2 = model.statusData?.p2 ? model.statusData?.p2 : 0;
        this.siphonicBrand = model.statusData?.siphonicBrand ? model.statusData?.siphonicBrand : null;
        this.baseMeasurement = model.statusData?.baseMeasurement ? model.statusData?.baseMeasurement : 0;

        this.statusDataIsDeleted = model.statusData?.isDeleted ? model.statusData?.isDeleted : false;
        this.propertyId = model.statusData?.propertyId ? model.statusData?.propertyId : null;
        this.statusDataContractorId = model.statusData?.contractorId ? model.statusData?.contractorId : null;
        this.commissionDate = model.statusData?.commissionDate ? model.statusData?.commissionDate : null;
        this.isComissioned = model.statusData?.isComissioned ? model.statusData?.isComissioned : false;

        this.standingWaterDays = model.statusData?.standingWaterDays !== undefined ? model.statusData?.standingWaterDays : 5;
        this.standingWaterPercent = model.statusData?.standingWaterPercent !== undefined ? model.statusData?.standingWaterPercent : 10;
        this.standingWaterStatus = model.statusData?.standingWaterEnum !== undefined ? model.statusData?.standingWaterEnum : 0;

        this.allowSWEmailSend = model.statusData?.allowSWEmailSend !== undefined ? model.statusData?.allowSWEmailSend : true;
        this.allowSWSMSSend = model.statusData?.allowSWSMSSend !== undefined ? model.statusData?.allowSWSMSSend : true;

        this.side1_Length2 = model.statusData?.side1_Length2 !== undefined ? model.statusData?.side1_Length2 : 0;
        this.side2_Length2 = model.statusData?.side2_Length2 !== undefined ? model.statusData?.side2_Length2 : 0;
        this.side1_Angle2 = model.statusData?.side1_Angle2 !== undefined ? model.statusData?.side1_Angle2 : 0;
        this.side2_Angle2 = model.statusData?.side2_Angle2 !== undefined ? model.statusData?.side2_Angle2 : 0;
        this.baseWidth = model.statusData?.baseWidth !== undefined ? model.statusData?.baseWidth : 0;
        this.sideType = model.statusData?.sideType !== undefined ? model.statusData?.sideType : SideType.ThreeSideSym;

        this.unitBehaviour = undefined;

        if (model.unitBehaviours !== null && model.unitBehaviours !== undefined) {
            if (model.unitBehaviours.length > 0) {
                this.unitBehaviour = new DeviceUnitBehaviourModel();
                this.unitBehaviour.fromDto(model.unitBehaviours[0]);
            }
        }

        if (model.unitData !== null && model.unitData !== undefined) {
            this.unitData = new DeviceUnitDataMinModel();
            this.unitData.fromDto(model.unitData);
        }
    }

    @action
    clear = (): void => {
        this.id = 0;
        this.name = "";
        this.number = "";
        this.deviceId = "";
        this.simId = "";
        this.simIdConfirm = "";
        this.deviceIdConfirm = "";
        this.rowVersion = undefined;
        this.lastUpdated = undefined;
        this.latitude = undefined;
        this.longitude = undefined;
        this.roofcareAddressId = undefined;
        this.isDeleted = false;
        this.contractorId = "";
        this.createdDate = "";
        this.earliestValue = undefined;

        //Device Status Data
        this.deviceStatusItemId = undefined;
        this.gutterSensId = undefined;
        this.side1_Length = 0;
        this.side2_Length = 0;
        this.side1_Angle = 0;
        this.side2_Angle = 0;
        this.side1_Height = 0;
        this.side2_Height = 0;
        this.install_Height = 0;
        this.emailAddresses = "";
        this.mobileNumbers = "";
        this.roofType = RoofType.Pitched;
        this.maxHeight = 0;
        this.drainageType = DrainageType.Gravity;
        this.p1 = 0;
        this.p2 = 0;
        this.siphonicBrand = null;
        this.baseMeasurement = 0;
        this.statusDataIsDeleted = false;
        this.propertyId = null;
        this.statusDataContractorId = null;
        this.commissionDate = null;
        this.isComissioned = false;

        this.standingWaterDays = 5;
        this.standingWaterPercent = 10;
        this.standingWaterStatus = 0;

        this.allowSWEmailSend = true;
        this.allowSWSMSSend = true;

        this.side1_Length2 = 0;
        this.side2_Length2 = 0;
        this.side1_Angle2 = 0;
        this.side2_Angle2 = 0;
        this.baseWidth = 0;
        this.sideType = SideType.ThreeSideSym;

        this.unitBehaviour = undefined;

        this.setError("unitBehaviour", "");
        this.setValid("unitBehaviour", false);

        this.setError("roofcareAddressId", "");
        this.setValid("roofcareAddressId", false);

        this.unitData = new DeviceUnitDataMinModel();
    };

    @action public recalculateData = () => {
        // TODO RC001 - Need to work out hight based on the SideType

        this.side1_Height = calculateActualHeight(this.side1_Length, this.side1_Angle);
        this.side2_Height = calculateActualHeight(this.side2_Length, this.side2_Angle);
    };

    //toDto is required but you can leave it blank
    toDto(): InstallationAddEditDTO {
        // TODO RC001 - Need to work out hight based on the SideType
        this.side1_Height = calculateActualHeight(this.side1_Length, this.side1_Angle);
        this.side2_Height = calculateActualHeight(this.side2_Length, this.side2_Angle);

        return {
            id: this.id,
            rowVersion: this.rowVersion,
            deviceId: this.deviceId,
            simId: this.simId,
            name: this.name,
            number: this.number,
            latitude: this.latitude,
            longitude: this.longitude,
            roofcareAddressId: this.roofcareAddressId,
            isDeleted: this.isDeleted,
            contractorId: this.contractorId,
            //Device Status Data
            deviceStatusItemId: this.deviceStatusItemId,
            gutterSensId: this.gutterSensId,
            side1_Length: this.side1_Length,
            side2_Length: this.side2_Length,
            side1_Angle: this.side1_Angle,
            side2_Angle: this.side2_Angle,
            side1_Height: this.side1_Height,
            side2_Height: this.side2_Height,
            install_Height: this.install_Height,
            emailAddresses: this.emailAddresses,
            mobileNumbers: this.mobileNumbers,
            roofType: this.roofType,
            maxHeight: this.maxHeight,
            drainageType: this.drainageType,
            p1: this.p1,
            p2: this.p2,
            siphonicBrand: this.siphonicBrand,
            initialCommand: this.initialCommand,
            unitBehaviour: undefined, //Needs defining outside
            baseMeasurement: this.baseMeasurement,
            unitData: this.unitData.toRequestDto(),
            statusDataIsDeleted: this.statusDataIsDeleted,
            propertyId: this.propertyId,
            statusDataContractorId: this.statusDataContractorId,
            commissionDate: this.commissionDate,
            isComissioned: this.isComissioned,
            standingWaterDays: this.standingWaterDays,
            standingWaterPercent: this.standingWaterPercent,
            standingWaterStatus: this.standingWaterStatus,
            allowSWEmailSend: this.allowSWEmailSend,
            allowSWSMSSend: this.allowSWSMSSend,

            side1_Length2: this.side1_Length2,
            side2_Length2: this.side2_Length2,
            side1_Angle2: this.side1_Angle2,
            side2_Angle2: this.side2_Angle2,
            baseWidth: this.baseWidth,
            sideType: this.sideType,
        };
    }
}

export interface InstallationAddEditDTO {
    //Device Data
    id?: number;
    latitude: number | undefined | null;
    longitude: number | undefined | null;
    roofcareAddressId: string | undefined | null;
    rowVersion?: string;
    deviceId: string;
    simId: string;
    name: string;
    number: string;
    isDeleted: boolean;

    //Device Status Data
    contractorId: string;
    deviceStatusItemId?: number;
    gutterSensId?: number;
    side1_Length: number;
    side2_Length: number;
    side1_Angle: number;
    side2_Angle: number;
    side1_Height: number;
    side2_Height: number;
    install_Height: number;
    emailAddresses: string;
    mobileNumbers: string;
    roofType: RoofType;
    maxHeight: number; // Required for Rooftype Flat
    drainageType: DrainageType;
    p1: number; // Required for Single / Dual Siphonic Drainage
    p2: number; // Required for Dual Siphonic Drainage
    siphonicBrand?: number | null;
    baseMeasurement: number | null;

    standingWaterDays: number;
    standingWaterPercent: number;
    standingWaterStatus: StandingWaterStatus;

    allowSWEmailSend: boolean;
    allowSWSMSSend: boolean;

    side1_Length2: number;
    side2_Length2: number;
    side1_Angle2: number;
    side2_Angle2: number;
    baseWidth: number;
    sideType: SideType;

    statusDataIsDeleted: boolean;
    propertyId: string | null;
    statusDataContractorId: string | null;
    commissionDate: string | null;
    isComissioned: boolean;

    initialCommand: string | undefined;

    unitBehaviour: DeviceUnitBehaviourModelDTO | undefined;

    unitData: DeviceUnitDataMinDTO;
}
