import { Length } from "class-validator";
import { ViewModelBase } from "Core/ViewModels/ViewModelBase";
import { ContractorModel } from "Custom/Models/Domain/Contractors/ContractorModel";
import { FieldType } from "Core/Utils/Utils";
import { action, computed, IObservableArray, observable, runInAction } from "mobx";
import { ApiResult } from "Core/Models";
import { GenericIdRequest } from "Custom/Models";
import { Client, Server } from "Custom/Globals/AppUrls";
import { AddressModel, AddressModelDTO } from "Custom/Models/Domain/Address/AddressModel";
import { ContractorAndContactsDTO, ContractorAndContactsModel } from "Custom/Models/Domain/Contractors/ContractorAndContact";
import { ContractorContactModel, ContractorContactModelDTO } from "Custom/Models/Domain/Contacts/ContractorContactModel";
import validator from "validator";
import { History } from "history";
import { getHistory } from "Core/Utils/Utils";
import { formatAddress } from "Custom/Utils/format";
import { AddressItem, AddressRequest, AddressResult, AddressResults, ConvertAddressItemToAddressModelDTO } from "Custom/Models/API/Hopewiser";
import { isNullOrUndefined } from "Custom/Utils/utils";
import { AppUrls } from "Custom/Globals";
import { ContactModel as ComponentContactModel } from "Custom/Components/Contact";

//extend viewmodel base and passing your model as the generic type
export class ContractorViewModel extends ViewModelBase<ContractorAndContactsModel> {
    public history: History;
    //Singleton instance of class
    private static _instance: ContractorViewModel;

    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    @observable public errorMessage: string = "";
    @observable public searchCriteria: string = "";
    @observable public searchResults: IObservableArray<AddressItem> = observable([]);

    @computed get getSearchResults(): AddressItem[] {
        return this.searchResults.slice();
    }

    @computed get getContractor(): ContractorModel {
        return this.model.contractor;
    }

    @computed get getContractorContacts(): ContractorContactModel[] {
        return this.model.contacts.slice();
    }

    @computed get getComponentContacts(): ComponentContactModel[] {
        let retVal: ComponentContactModel[] = [];
        let list = this.model.contacts
            .slice()
            .filter((c) => c.isDeleted === false)
            .sort((a: ContractorContactModel, b: ContractorContactModel) => {
                if (a === undefined && b === undefined) {
                    return 0;
                }

                if (a === undefined) {
                    return -1;
                }

                if (b === undefined) {
                    return 1;
                }

                if (a.firstName.toLocaleLowerCase() === b.firstName.toLocaleLowerCase()) {
                    return a.displayName.toLocaleLowerCase() < b.displayName.toLocaleLowerCase() ? -1 : 1;
                }

                return a.firstName.toLocaleLowerCase() < b.firstName.toLocaleLowerCase() ? -1 : 1;
            });

        list.forEach((contact) => {
            retVal.push(this.toComponentContactModel(contact));
        });

        return retVal;
    }

    @action
    public toComponentContactModel = (contact: ContractorContactModel) => {
        const retVal = new ComponentContactModel();
        retVal.firstName = contact.firstName;
        retVal.lastName = contact.lastName;
        retVal.position = contact.position;
        retVal.email = contact.email;
        retVal.phone = contact.phone;
        retVal.phone2 = contact.phone2;
        retVal.rowVersion = contact.rowVersion;
        retVal.isDeleted = contact.isDeleted;
        retVal.createdDate = contact.createdDate;
        retVal.createdBy = contact.createdBy;
        retVal.id = contact.id;
        retVal.sourceId = contact.contractorId;

        return retVal;
    };

    @action
    public fromComponentContactModel = (contact: ComponentContactModel) => {
        const retVal = new ContractorContactModel();
        retVal.firstName = contact.firstName;
        retVal.lastName = contact.lastName;
        retVal.position = contact.position;
        retVal.email = contact.email == null ? "" : contact.email!;
        retVal.phone = contact.phone == null ? "" : contact.phone!;
        retVal.phone2 = contact.phone2 == null ? "" : contact.phone2!;
        retVal.rowVersion = contact.rowVersion;
        retVal.isDeleted = contact.isDeleted;
        retVal.createdDate = contact.createdDate;
        retVal.createdBy = contact.createdBy;
        retVal.id = contact.id;
        retVal.contractorId = contact.sourceId;

        return retVal;
    };

    @computed get getContractorContactLength(): number {
        const temp = this.getContractorContacts;
        return temp.length;
    }

    @computed get getAddress(): AddressModel {
        return this.model.contractor.getAddress;
    }

    @action
    public setName = (value: string) => {
        this.model.contractor.contractorName = value;
    };

    @action
    public setAddress = (address: AddressModel): void => {
        this.model.contractor.setAddress(address);
    };

    @computed
    public get getAddressString(): string {
        return formatAddress(this.model.contractor.getAddress, true);
    }

    @action
    public deleteContact = (id: string) => {
        const item: ContractorContactModel | undefined = this.model.contacts.find((a: any) => a.generatedTempId === id);

        if (item != undefined) {
            item.isDeleted = true;
        }
    };

    constructor() {
        //Pass in a new instance of your model
        //By passing in true as the second parameter, we make this model undoable which means we can use save and reset options on the model
        //If you make a change to the model you need to persist it with a saveModel() call
        //If you make changes to your model you can revert it back by calling resetModel()
        super(new ContractorAndContactsModel(), false);
        //EN - Haven't figured out how to make this call work from the base model yet
        //This is only needed if you make use of the validation decorators
        this.setDecorators(ContractorAndContactsModel);
        this.history = getHistory();
    }

    public additionalChecks = (): boolean => {
        let retVal: boolean = true;

        return retVal;
    };

    //isValid will check all fields to make sure they are in a valid state.
    @action
    public doSubmit = async (e: any) => {
        e.preventDefault();
        if (this.isModelValid() && this.additionalChecks()) {
            //Do stuff here
            this.saveContractorAsync().then((apiResult) => {
                if (apiResult.wasSuccessful) {
                    this.history.push(Client.Main.Contractor.Root);
                }
            });
        } else {
            this.errorMessage = "Please fix the highighted errors";
            this.IsLoading = false;
        }
    };

    @action
    public async saveContractorAsync(): Promise<ApiResult<ContractorAndContactsDTO>> {
        const request: ContractorAndContactsDTO = {} as any;

        this.model.toDto(request);

        const apiResult = await this.Post<ContractorAndContactsDTO>(Server.Api.Contractor.upsertContractor, request);

        if (apiResult.wasSuccessful) {
            runInAction(() => {
                this.setContractorAndContacts(apiResult.payload);
            });
        } else {
            this.errorMessage = "Failed to save the client. The client might have changed since you opened it.  Reload and try again.";
        }

        return apiResult;
    }

    //This must be present in your viewmodel. Just return true if you dont need to validate anything.
    //keyof BlankModel & string lets you add type checking to the fieldName
    //I am using the validator package to make checking easier https://www.npmjs.com/package/validator
    public isFieldValid(fieldName: keyof FieldType<ContractorAndContactsModel>, value: any): boolean {
        let { isValid, errorMessage } = this.validateDecorators(fieldName);

        if ((fieldName as any) === "contractor.clientName") {
            if (validator.isEmpty(value)) {
                errorMessage = "Contractor Name is required";
                isValid = false;
            } else if (value.toString().length > 128) {
                errorMessage = "Contractor Name needs to be less than 128 characters";
                isValid = false;
            }
        }

        if ((fieldName as any) === "contractor.addressLine1") {
            if (validator.isEmpty(value)) {
                errorMessage = "Address line 1 is required";
                isValid = false;
            } else if (value.toString().length > 128) {
                errorMessage = "Address line 1 needs to be less than 128 characters";
                isValid = false;
            }
        }

        if ((fieldName as any) === "contractor.addressLine2") {
            if (value.toString().length > 128) {
                errorMessage = "Address line 2 needs to be less than 128 characters";
                isValid = false;
            }
        }

        if ((fieldName as any) === "contractor.city") {
            if (validator.isEmpty(value)) {
                errorMessage = "City is required";
                isValid = false;
            } else if (value.toString().length > 128) {
                errorMessage = "City needs to be less than 128 characters";
                isValid = false;
            }
        }

        if ((fieldName as any) === "contractor.county") {
            if (value.toString().length > 128) {
                errorMessage = "County needs to be less than 128 characters";
                isValid = false;
            }
        }

        if ((fieldName as any) === "contractor.postcode") {
            if (validator.isEmpty(value)) {
                errorMessage = "Postcode is required";
                isValid = false;
            } else if (!validator.isPostalCode(value, "GB")) {
                errorMessage = "Postcode is not valid";
                isValid = false;
            }
        }

        //You need to this two properties after validation
        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    @action
    public setContractorAndContacts(model: ContractorAndContactsDTO) {
        this.model.fromDto(model);
    }

    @action
    public async loadContractorAsync(id: string): Promise<ApiResult<ContractorAndContactsDTO>> {
        const request: GenericIdRequest = {
            id: id,
        };
        const apiResult = await this.Post<ContractorAndContactsDTO>(Server.Api.Contractor.getContractorAndRelated, request);

        if (apiResult.wasSuccessful) {
            runInAction(() => {
                this.setContractorAndContacts(apiResult.payload);
            });
        }
        return apiResult;
    }

    @action
    addContact = (model: ComponentContactModel) => {
        let newContact = this.fromComponentContactModel(model);
        let index = this.model.contacts.findIndex((contact) => contact.email === newContact.email);
        if (index === -1) {
            let temp: ContractorContactModel = new ContractorContactModel();
            temp.fromDto(newContact);
            this.model.contacts.push(temp);
        }
    };

    @action
    updateContact = (model: ComponentContactModel) => {
        let newContact = this.fromComponentContactModel(model);
        let temp: ContractorContactModel = new ContractorContactModel();
        temp.fromDto(newContact);
        let index = this.model.contacts.findIndex((contact) => contact.id === model.id);
        console.log(index);
        if (index >= 0) {
            this.model.contacts[index] = temp;
        }
    };

    @computed
    public get getPostcode(): string {
        return this.model.contractor.postcode;
    }

    @action
    public setLocation = (latitude: number, longitude: number) => {
        // contractors are not on the map, so don't have a lat long
        //this.model.contractor.setValue("locationLatitude", latitude);
        //this.model.contractor.setValue("locationLongitude", latitude);
    };

    @action setSearchCriteria(value: string) {
        this.searchCriteria = value;
    }

    @action public addressSearch = async (item?: AddressItem): Promise<ApiResult<AddressResults>> => {
        this.errorMessage = "";
        let searchAddress = JSON.stringify(
            this.searchCriteria.split(",").map((item: string) => {
                return item.trim();
            }),
        );

        let fromSid: boolean = false;
        if (isNullOrUndefined(item) === false) {
            fromSid = true;
            searchAddress = JSON.stringify([item!.sid]);
        }

        const apiResult = await this.Post<AddressResults>(AppUrls.Server.Api.Hopewiser.getAddresses, searchAddress);

        if (apiResult.wasSuccessful) {
            runInAction(() => {
                const addressResults = apiResult.payload;

                if (fromSid === true) {
                    const add: string = item!.itemText.replace("+", "").trim();

                    for (let i: number = 0; i < addressResults.items.length; i++) {
                        addressResults.items[i].itemText += " " + add;
                    }
                }

                this.searchResults.replace(addressResults.items);
            });
        }

        return apiResult;
    };

    @action public getSelectedAddress = async (sid: string): Promise<ApiResult<AddressResult>> => {
        this.errorMessage = "";
        const url = AppUrls.Server.Api.Hopewiser.getAddressDetails;

        const request: AddressRequest = {
            Sid: sid,
        };
        const apiResult = await this.Post<AddressResult>(url, request);

        if (apiResult.wasSuccessful) {
            runInAction(() => {
                const addressResults = apiResult.payload;
                if (addressResults.status === "OK") {
                    let retVal: AddressModelDTO = ConvertAddressItemToAddressModelDTO(addressResults.results.items[0]);

                    let addr: AddressModel = new AddressModel();
                    addr.fromDto(retVal);
                    this.model.contractor.setAddress(addr);
                }
            });
        }

        return apiResult;
    };
}
