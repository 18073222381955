import * as MobX from "mobx";
import { ModelBase } from "Core/Models/ModelBase";

//Base class expects the model type and the DTO model type
//If you are not using a DTO just pass in undefined as the DTO
export class PropertyItemModel extends ModelBase<PropertyItemModel, PropertyItemModelDTO> {
    @MobX.observable propertyId: string = "";
    @MobX.observable buildingName: string = "";
    @MobX.observable addressLine1: string = "";
    @MobX.observable addressLine2: string = "";
    @MobX.observable city: string = "";
    @MobX.observable county: string = "";
    @MobX.observable postcode: string = "";
    @MobX.observable installationCount: number = 0;
    @MobX.observable projectId: string = "";
    @MobX.observable projectNumber: string = "";
    @MobX.observable projectName: string = "";
    @MobX.observable clientName: string = "";
    @MobX.observable regionalSalesManagerName: string = "";

    //fromDto is required but you can leave it blank
    fromDto(model: PropertyItemModelDTO): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
    }

    //toDto is required but you can leave it blank
    toDto(model: PropertyItemModel): void {}
}

export type PropertyItemModelDTO = {
    propertyId: string;
    buildingName: string;
    addressLine1: string;
    addressLine2: string;
    city: string;
    county: string;
    postcode: string;
    installationCount: number;
    projectId: string;
    projectNumber: string;
    projectName: string;
    clientName: string;
    regionalSalesManagerName: string;
};
