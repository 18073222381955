import React, { useState, useContext } from "react";
import { Link as RouterLink, useRouteMatch } from "react-router-dom";
import { AppBar, IconButton, Divider, Theme, Box, List, ListItemText, ListItem } from "@material-ui/core";
import { useObserver } from "mobx-react-lite";
import { StoresContext, Stores } from "Custom/Stores";
import { Client } from "Custom/Globals/AppUrls";
import * as RouterUtil from "Custom/Utils/routing";
import roofcareLogo from "../Content/logo.svg";
import { HeaderTitle, HeaderImage, HeaderToolbar, HeaderDrawer } from "./Header.style";
import { useRouter } from "Core/Utils";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { useMediaQuery } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useLocation } from "react-router-dom";
import { AppUrls } from "Custom/Globals";

export const Header: React.FC = () => {
    const theme: Theme = useTheme();
    const desktopScreen: boolean = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));
    const mobileScreen: boolean = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
    const [open, setOpen] = useState(false);
    const storesContext = useContext<Stores>(StoresContext);
    const { history } = useRouter();

    const handleDrawerOpen = () => setOpen(true);
    const handleDrawerClose = () => setOpen(false);

    const gotoHome = () => {
        history.push(Client.Root);
    };

    const hideBorders: boolean = !RouterUtil.requireAdminLogin(storesContext);
    // this has to be a string to stop the browser complaining.
    const hideBordersString: string = hideBorders.toString();

    interface IHeaderLinkProps {
        link?: any;
        route?: any;
        text: string;
        onClick?: any;
        isHome?: boolean;
        className?: string;
    }

    const useStyles = makeStyles({
        button: {
            textDecoration: "none",
            fontSize: "14px",
            fontWeight: "bold",
            height: "100%",
            width: "130px",

            padding: "0px !important",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "transparent",
            "&:hover": {
                boxShadow: " inset 3px 3px 6px #00000029",
            },

            "& .MuiListItem-root": {
                color: "#444 !important",
                padding: "0",
            },
        },
        li: {
            "&:hover": {
                cursor: "pointer",
            },
        },
        isActive: {
            transition: "backgroundColor 1s ease-in",
            backgroundColor: theme.app.colors.orange.darker,
            boxShadow: "inset 3px 3px 6px #00000029 !important",
        },
    });

    const HeaderLink = (props: IHeaderLinkProps) => {
        const { isHome, link, route, text, onClick, className } = props;
        const classes = useStyles();
        const location = useLocation();
        const router = useRouter();
        const emptyString = () => "";
        const handleListItemClick = onClick === undefined ? emptyString : onClick;

        const extraClasses: string = className !== undefined && className !== null ? className : "";

        const isSameRoute = () => {
            if (route) {
                let retVal: boolean = location.pathname.includes(route);

                if (retVal === false) {
                    const homeMatch = useRouteMatch(Client.Root);
                    if (isHome !== null && isHome !== undefined && isHome! === true && homeMatch?.isExact) {
                        const { path } = router.match;

                        if (path === AppUrls.Client.Root || path === AppUrls.Client.Main.EstateView.Root) {
                            retVal = true;
                        }
                    }
                }

                return retVal;
            }
        };
        return (
            <ListItem
                className={extraClasses}
                onClick={() => handleListItemClick()}
                classes={{ root: classes.button, selected: classes.isActive }}
                component={link}
                to={route}
                selected={isSameRoute()}
            >
                <span>{text}</span>
            </ListItem>
        );
    };

    const LogOutLink = () => {
        const handleLogOut = () => {
            storesContext.domain.AccountStore.Logout(false);
            gotoHome();
        };
        return <HeaderLink className="logoutOption" onClick={handleLogOut} text="Log out" />;
    };

    const loggedInAdminList = (
        <>
            <HeaderLink link={RouterLink} route={Client.Main.EstateView.Root} text="Estate" isHome={true} />
            <HeaderLink link={RouterLink} route={Client.Main.Project.Root} text="Project" />
            <HeaderLink link={RouterLink} route={Client.Main.Action.Root} text="Action" />
            <HeaderLink link={RouterLink} route={Client.Main.Device.Root} text="Installation" />
            <HeaderLink link={RouterLink} route={Client.Main.Unit.Root} text="Unit" />
            <HeaderLink link={RouterLink} route={Client.Main.Client.Root} text="End User" />
            <HeaderLink link={RouterLink} route={Client.Main.Contractor.Root} text="Contractor" />
            <HeaderLink link={RouterLink} route={Client.Main.Contact.Root} text="Contact" />

            <HeaderLink link={RouterLink} route={Client.Main.User.Root} text="User" />
            <HeaderLink link={RouterLink} route={Client.Main.Admin.Root} text="Admin" />
            <LogOutLink />
        </>
    );

    const loggedInClientList: JSX.Element = (
        <>
            <HeaderLink link={RouterLink} route={Client.ClientView.Root} text="Dashboard" isHome={true} />
            <HeaderLink link={RouterLink} route={Client.ClientView.Device.DeviceRoot} text="Units" />
            <LogOutLink />
        </>
    );

    const loggedInContractorList: JSX.Element = (
        <>
            <HeaderLink link={RouterLink} route={Client.ContractorView.Root} text="Dashboard" isHome={true} />
            <HeaderLink link={RouterLink} route={Client.ContractorView.Device.DeviceRoot} text="Units" />
            <LogOutLink />
        </>
    );

    // const loggedInRegionalSalesManagerList: JSX.Element =  <LogOutLink />;

    function getMenu(): JSX.Element {
        if (RouterUtil.requireAdminLogin(storesContext)) {
            return loggedInAdminList;
        } else if (RouterUtil.requireClientLogin(storesContext)) {
            return loggedInClientList;
        } else if (RouterUtil.requireContractorLogin(storesContext)) {
            return loggedInContractorList;
            /* RegionalSalesManager is the same as admin         } else if (RouterUtil.requireRegionalSalesManagerLogin(storesContext)) {
            return loggedInRegionalSalesManagerList; */
        } else {
            return <LogOutLink />;
        }
    }

    return useObserver(() => (
        <>
            <AppBar style={{ backgroundColor: "#EE8B1F", height: "70", paddingTop: "23" }}>
                <HeaderToolbar hideborders="false">
                    <HeaderImage onClick={gotoHome}>
                        <img src={roofcareLogo} alt="Roofcare Portal" className="logoHeader" style={{ maxHeight: "82.5px", marginLeft: "5px" }} />
                    </HeaderImage>
                    {/* <HeaderDivider /> */}
                    <HeaderTitle flexGrow={1}></HeaderTitle>

                    {storesContext.domain.AccountStore.IsLoggedIn ? (
                        <>
                            {!mobileScreen && <Box>{getMenu}</Box>}
                            {mobileScreen && (
                                <IconButton color="primary" aria-label="Open drawer" onClick={handleDrawerOpen} edge="start">
                                    <MenuIcon />
                                </IconButton>
                            )}
                        </>
                    ) : (
                        <></>
                    )}
                </HeaderToolbar>
            </AppBar>

            <HeaderDrawer variant="persistent" anchor="left" open={open}>
                <div>
                    <IconButton onClick={handleDrawerClose}>
                        <ChevronLeftIcon className="chevron" style={{ fill: "#B42025" }} />
                    </IconButton>
                </div>
                <Divider />
                {desktopScreen && <List style={{ display: "flex", flex: "1 0 0", width: "130px" }}>{getMenu}</List>}
                {!desktopScreen && <List style={{ display: "flex", flex: "1 0 0", width: "100%" }}>{getMenu}</List>}
            </HeaderDrawer>
        </>
    ));
};
