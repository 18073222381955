import React from "react";
import { SVGProps, HTMLProps } from "react";

interface IPin {
    textColour?: string;
    fillColour: string;
}
export const SvgPinComponentAsString = (fillColour: string): string => {
    let borderColor: string = "white";

    if (fillColour.toLocaleLowerCase() === "#ffffff" || fillColour.toLocaleLowerCase() === "#fff") {
        borderColor = "#bebebe";
    }

    let SVG: string = '<svg xmlns="http://www.w3.org/2000/svg" width="48" height="56" viewBox="0 0 48 56">';
    SVG += "<defs>";
    SVG += '<filter id="a" x="0" y="0" width="48" height="56" filterUnits="userSpaceOnUse">';
    SVG += '           <feOffset dy="3" />';
    SVG += '         <feGaussianBlur stdDeviation="3" result="b" />';
    SVG += '    <feFlood flood-opacity="0.161" />';
    SVG += '    <feComposite operator="in" in2="b" />';
    SVG += '    <feComposite in="SourceGraphic" />';
    SVG += "</filter>";
    SVG += "</defs>";
    SVG += '<g transform="translate(-142 -807)">';
    SVG += '    <g style={{ filter: "url(#a)" }} transform="matrix(1, 0, 0, 1, 142, 807)">';
    SVG += '        <g transform="translate(9 6)">';
    // Fill Colour
    SVG +=
        '              <path fill="' +
        fillColour +
        '" style={{ stroke: "none" }} d="M 15 37.29278182983398 C 13.98420524597168 36.26674652099609 10.91725730895996 33.1004753112793 7.885059833526611 29.28749084472656 C 3.053709983825684 23.21207046508789 0.5 18.27153968811035 0.5 15 C 0.5 11.12691020965576 2.008260011672974 7.485640048980713 4.746950149536133 4.746950149536133 C 7.485640048980713 2.008260011672974 11.12691020965576 0.5 15 0.5 C 18.87309074401855 0.5 22.51436042785645 2.008260011672974 25.25304985046387 4.746950149536133 C 27.99173927307129 7.485640048980713 29.5 11.12691020965576 29.5 15 C 29.5 18.27153968811035 26.9462890625 23.21207046508789 22.11494064331055 29.28749084472656 C 19.08274269104004 33.1004753112793 16.01579475402832 36.26674652099609 15 37.29278182983398 Z"/>';
    // White border around
    SVG +=
        '               <path fill="' +
        borderColor +
        '" style={{ stroke: "none" }} d="M 15 1 C 11.26046943664551 1 7.744760513305664 2.456249237060547 5.100509643554688 5.100509643554688 C 2.456249237060547 7.744760513305664 1 11.26046943664551 1 15 C 1 18.15586090087891 3.513980865478516 22.98606872558594 8.270170211791992 28.96844863891602 C 10.99294853210449 32.39318466186523 13.74583435058594 35.29536437988281 15 36.57955932617188 C 16.25416564941406 35.29536437988281 19.00704956054688 32.39318466186523 21.72982978820801 28.96844863891602 C 26.48601913452148 22.98606872558594 29 18.15586090087891 29 15 C 29 11.26046943664551 27.54375076293945 7.744760513305664 24.89949035644531 5.100509643554688 C 22.25523948669434 2.456249237060547 18.73952865600586 1 15 1 M 15 0 C 23.28426933288574 0 30 6.715730667114258 30 15 C 30 23.28427124023438 15 38 15 38 C 15 38 0 23.28427124023438 0 15 C 0 6.715730667114258 6.715730667114258 0 15 0 Z"/>';
    SVG += "        </g>";
    SVG += "    </g>";
    SVG += "</g>";
    SVG += "</svg>";

    return SVG;
};

export const SvgPinComponent = ({ textColour, fillColour, ...props }: SVGProps<SVGSVGElement> & IPin) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="48" height="56" viewBox="0 0 48 56">
        <defs>
            <filter id="a" x="0" y="0" width="48" height="56" filterUnits="userSpaceOnUse">
                <feOffset dy="3" />
                <feGaussianBlur stdDeviation="3" result="b" />
                <feFlood flood-opacity="0.161" />
                <feComposite operator="in" in2="b" />
                <feComposite in="SourceGraphic" />
            </filter>
        </defs>
        <g transform="translate(-142 -807)">
            <g style={{ filter: "url(#a)" }} transform="matrix(1, 0, 0, 1, 142, 807)">
                <g style={{ fill: `${fillColour}` }} transform="translate(9 6)">
                    <path
                        style={{ stroke: "none" }}
                        d="M 15 37.29278182983398 C 13.98420524597168 36.26674652099609 10.91725730895996 33.1004753112793 7.885059833526611 29.28749084472656 C 3.053709983825684 23.21207046508789 0.5 18.27153968811035 0.5 15 C 0.5 11.12691020965576 2.008260011672974 7.485640048980713 4.746950149536133 4.746950149536133 C 7.485640048980713 2.008260011672974 11.12691020965576 0.5 15 0.5 C 18.87309074401855 0.5 22.51436042785645 2.008260011672974 25.25304985046387 4.746950149536133 C 27.99173927307129 7.485640048980713 29.5 11.12691020965576 29.5 15 C 29.5 18.27153968811035 26.9462890625 23.21207046508789 22.11494064331055 29.28749084472656 C 19.08274269104004 33.1004753112793 16.01579475402832 36.26674652099609 15 37.29278182983398 Z"
                    />
                    <path
                        style={{ stroke: "none" }}
                        d="M 15 1 C 11.26046943664551 1 7.744760513305664 2.456249237060547 5.100509643554688 5.100509643554688 C 2.456249237060547 7.744760513305664 1 11.26046943664551 1 15 C 1 18.15586090087891 3.513980865478516 22.98606872558594 8.270170211791992 28.96844863891602 C 10.99294853210449 32.39318466186523 13.74583435058594 35.29536437988281 15 36.57955932617188 C 16.25416564941406 35.29536437988281 19.00704956054688 32.39318466186523 21.72982978820801 28.96844863891602 C 26.48601913452148 22.98606872558594 29 18.15586090087891 29 15 C 29 11.26046943664551 27.54375076293945 7.744760513305664 24.89949035644531 5.100509643554688 C 22.25523948669434 2.456249237060547 18.73952865600586 1 15 1 M 15 0 C 23.28426933288574 0 30 6.715730667114258 30 15 C 30 23.28427124023438 15 38 15 38 C 15 38 0 23.28427124023438 0 15 C 0 6.715730667114258 6.715730667114258 0 15 0 Z"
                    />
                </g>
            </g>
        </g>
    </svg>
);
