import { ViewModelBase } from "Core/ViewModels/ViewModelBase";
import { FieldType } from "Core/Utils/Utils";
import { action, computed, observable } from "mobx";
import { NewContactModel, NewContactModelDTO } from "./NewContact.Model";
import * as MobX from "mobx";

import { ContactViewModel, ContactModel } from "Custom/Components/Contact";

import { ContactModel as ClientContacModel } from "Custom/Models/Domain/Contacts/ContactModel";

import validator from "validator";
import { ApiResult } from "Core/Models/ApiResult";
import { Client, Server } from "Custom/Globals/AppUrls";

//extend viewmodel base and passing your model as the generic type
export class NewContactViewModel extends ViewModelBase<NewContactModel> {
    //Singleton instance of class
    private static _instance: NewContactViewModel;
    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    @observable public errorMessage: string = "";

    constructor() {
        super(new NewContactModel(), false);
        this.setDecorators(NewContactModel);
    }

    @action
    setNewContact = (contact: NewContactModel) => {
        this.model.copy(contact);
    };

    @action
    public toNewContactModelDTO = (contact: ContactModel) => {
        this.model.toNewContactModelDTO(contact);
    };

    @observable
    public contactsList: ClientContacModel[] = [];

    @computed
    public get returnContacts(): ClientContacModel[] {
        return this.contactsList;
    }

    @action
    public postNewContactAsync = async (): Promise<ApiResult<NewContactModelDTO[]>> => {
        const request: NewContactModelDTO = this.model;
        let apiResult = await this.Post<NewContactModelDTO[]>(Server.Api.Client.upsertNewContact, request);

        if (apiResult) {
            if (apiResult.wasSuccessful) {
                MobX.runInAction(() => {
                    for (const contact of apiResult.payload) {
                        this.contactsList.push(this.model.fromNewContactModelDTO(contact));
                    }
                });
            } else {
                console.log(apiResult.errors);
            }
        }

        return apiResult;
    };

    @action
    public doSubmit = async (e: any) => {
        e.preventDefault();
    };

    public isFieldValid(fieldName: keyof FieldType<NewContactModel>, value: any): boolean {
        let { isValid, errorMessage } = this.validateDecorators(fieldName);

        if (fieldName === "phone") {
            const temp: string = this.model.phone === null ? "" : this.model.phone!;
            if (validator.isEmpty(value) && temp.length === 0) {
                errorMessage = "Phone number s required";
                isValid = false;
            } else if (value.length > 0 && value.length < 6) {
                errorMessage = "Phone number must be at least 6 characters long";
                isValid = false;
            }
        }

        if (fieldName === "email") {
            const temp: string = this.model.email === null ? "" : this.model.email!;
            if (validator.isEmpty(value) && temp.length === 0) {
                errorMessage = "Email address is required";
                isValid = false;
            } else if (value.length > 0 && validator.isEmail(value) === false) {
                errorMessage = "Email address is not in the correct format";
                isValid = false;
            }
        }

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
