import { Box, Typography, useMediaQuery } from "@material-ui/core";
import styled from "styled-components";

export const Main = styled.section`
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
    // background-color: green;

    @media screen and (max-width: 1024px) {
    }

    @media screen and (max-width: 640px) {
        flex-direction: column;
    }

    /* label.MuiInputLabel-shrink {
        font-size: 19px !important;
        color: ${(props) => props.theme.palette.text.primary} !important;
    }

    label + .MuiInput-formControl {
        margin-top: 21px;
    } */

    p.Mui-error {
        font-size: 12px;
    }
`;

export const ClientBox = styled(Box)`
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;

    // background-color: yellow
    @media screen and (max-width: 1024px) {
    }

    @media screen and (max-width: 640px) {
        flex-direction: column;
    }
`;

export const ClientAddressBox = styled(Box)`
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;
    /* 
    background-color: pink; */

    @media screen and (max-width: 1024px) {
    }

    @media screen and (max-width: 640px) {
        flex-direction: column;
    }
`;

export const ClientContactBox = styled(Box)`
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: row;
    // background-color: red
    @media screen and (max-width: 1024px) {
    }

    @media screen and (max-width: 640px) {
        flex-direction: column;
    }

    label:not(.MuiInputLabel-root) {
        margin-bottom: 12px;
    }
`;
