import styled from "styled-components";

export const ProjectNoteSectionContainer = styled.div`
    width: 100%;
    display: flex;
    flex: 1 0 0;

    border-bottom: 1px dashed #d4d4d4;
    padding: 15px 0;

    .triangle {
        width: 0;
        height: 0;
        border-width: 0 15px 10px 15px;
        border-color: transparent transparent #f3f3f3 transparent;
        border-style: solid;
        margin-left: 10px;
        margin-top: 10px;

        &.overdue {
            border-color: transparent transparent #b42025 transparent;
        }
    }
`;
