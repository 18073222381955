import { DynamicSettingsButtonRow, DynamicSettingsImageWrapper } from "./DynamicSettingsImage.style";
import { DynamicSettingsImage } from "./DynamicSettingsImage";
import { InstallationStatusDataDTO } from "Custom/Models/Domain";
import { LineGraphState } from "../Graphs/BoxplotGraph";
import { useEffect, useRef, useState } from "react";
import { generateID } from "Custom/Utils/utils";
import { BandData } from "./BandData";
import React from "react";
import { AlertSwitchOption } from "Custom/Views/Installations/DeviceSection.style";
import * as d3 from "d3";
import { GraphHeader } from "Custom/Views/Installations/InstallationView.style";

export interface IDynamicSettingsImageProps {
    imageHeight: number;
    marginTop: number;
    /* maxWidth: string; */
    conditionSetData: BandData[];
    deviceStatusData: InstallationStatusDataDTO | undefined;
    alertActions: BandData[];

    title: string;
}

export interface IDynamicSettingsButtonState {
    showAction: boolean;
    hideButton: boolean;
}

export function DynamicSettingsImageContainer(props: IDynamicSettingsImageProps) {
    const { alertActions, conditionSetData, deviceStatusData, imageHeight, marginTop, title } = props;
    const componentRef: any = useRef(generateID());
    const [dimensions, setDimensions] = useState<LineGraphState>({
        width: 400,
        height: 675,
    });
    const [showAlertActions, setShowAlertActions] = React.useState<IDynamicSettingsButtonState>({ showAction: false, hideButton: false });
    const [showAttributes, setShowAttributes] = React.useState(false);

    const updateWindowDimensions = () => {
        if (componentRef !== undefined && componentRef != null && componentRef.current !== null && componentRef.current !== undefined) {
            setDimensions({
                width: componentRef!.current!.offsetWidth - 45,
                height: componentRef.current!.offsetHeight! > 675 ? 389 : 389, // 389 gives roughly the height from the mocks once you add the bottom to it
            });
        }
    };

    useEffect(() => {
        updateWindowDimensions();
        window.addEventListener("resize", updateWindowDimensions);

        // tidy up after yourself
        return () => {
            window.removeEventListener("resize", updateWindowDimensions);
            setShowAlertActions({ showAction: false, hideButton: false });
        };
    }, []);
    let btnAlertClass: string = showAlertActions.showAction == false ? "notselected" : "selected";
    if (showAlertActions.hideButton === true) {
        btnAlertClass += " hide";
    }

    let btnAttributeClass: string = showAttributes == false ? "notselected" : "selected";
    return (
        <>
            <GraphHeader className="attributes">
                {deviceStatusData !== null && deviceStatusData !== undefined && (
                    <>
                        <div className="slider-text">Show attribute lines:</div>
                        <AlertSwitchOption id={"showlines-select-"} className={btnAttributeClass} onClick={() => setShowAttributes(!showAttributes)} />
                    </>
                )}
                {alertActions !== null && alertActions !== undefined && alertActions.length > 0 && (
                    <>
                        <div className="slider-text lhs15">Show alert lines:</div>
                        <AlertSwitchOption
                            id={"showlines-select-"}
                            className={btnAlertClass}
                            disabled={showAlertActions.hideButton}
                            onClick={() => {
                                if (showAlertActions.hideButton === false) {
                                    const newState: boolean = !showAlertActions.showAction;
                                    setTimeout(() => {
                                        setShowAlertActions({ showAction: newState, hideButton: false });
                                    }, 2500);

                                    setShowAlertActions({ showAction: newState, hideButton: true });
                                }

                                /*                             
                                const newState: boolean = !showAlertActions.showAction;
                                if (showAlertActions.hideButton === false) {
                                    if (newState === true) {
                                        setShowAlertActions({ showAction: newState, hideButton: true });

                                        setTimeout(() => {
                                            setShowAlertActions({ showAction: newState, hideButton: false });
                                        }, 2500);
                                    } else {
                                        setShowAlertActions({ showAction: newState, hideButton: false });
                                    }
                                } */
                            }}
                        />
                    </>
                )}
            </GraphHeader>
            <DynamicSettingsImageWrapper ref={componentRef}>
                <DynamicSettingsImage
                    showAttributes={showAttributes}
                    showAlertActions={showAlertActions.showAction}
                    alertActions={alertActions}
                    conditionSetData={conditionSetData}
                    deviceStatusData={deviceStatusData}
                    imageHeight={imageHeight}
                    imageWidth={dimensions.width}
                    marginTop={marginTop}
                />
            </DynamicSettingsImageWrapper>
        </>
    );
}
