import { Fade } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { AuditTrippedAlertViewModel } from "./AuditTrippedAlertViewModel";
import { DataJsonProps } from "../DataJsonProps";
import { useObserver } from "mobx-react-lite";
import { EmailPaper } from "../../Modals/AuditModal.style";
import { AuditDetailsContainer } from "../TabAudit.style";
import { AuditTrippedAlertModel } from "./AuditTrippedAlertModel";
import { formatDateTime } from "Custom/Utils/format";
import { AlertDirection, ConditionType, ConditionUnits } from "Custom/Models/Domain";
import DownArrowLogo from "Custom/Content/downArrow.svg";
import UpArrowLogo from "Custom/Content/upArrow.svg";
import UpDownArrowLogo from "Custom/Content/upDownArrow.svg";

export const AuditTrippedAlertSection: React.FunctionComponent<DataJsonProps> = ({ data }) => {
    const [viewModel] = useState(() => new AuditTrippedAlertViewModel());

    //useEffect below only gets run on initial render
    useEffect(() => {
        // *optional* passing the router into the viewmodel so we can navigate from there
        //console.log("First render");
        //This gets run when the page is exited
        return () => {
            //console.log("Unmounting");
        };
    }, []);

    React.useEffect(() => {
        viewModel.setAuditItem(data);
    }, [data]);

    const formatDirection = (item: AlertDirection): JSX.Element => {
        let logo = <img src={UpDownArrowLogo} />;

        switch (item) {
            case 0:
                logo = <img src={UpArrowLogo} />;
                break;
            case 1:
                logo = <img src={UpDownArrowLogo} />;
                break;
            case 2:
                logo = <img src={DownArrowLogo} />;
                break;
        }

        return logo;
    };

    function renderModal() {
        const data: AuditTrippedAlertModel = viewModel.getDisplayData;

        let units: string = "mm";
        let type: string = "H";

        if (data !== null && data !== undefined) {
            if (data.Units === ConditionUnits.PERCENT) {
                units = "%";
            }

            switch (data.Type) {
                case ConditionType.FB: {
                    type = "FB";
                    break;
                }
                case ConditionType.P1: {
                    type = "P1";
                    break;
                }
                case ConditionType.P2: {
                    type = "P2";
                    break;
                }
                case ConditionType.H:
                default: {
                    break;
                }
            }
        }
        return (
            <Fade in timeout={1000}>
                <AuditDetailsContainer className="column">
                    <div className="row">
                        <div className="title">Alert name:</div>
                        <div>{data.Name}</div>
                    </div>
                    {data.LatestValueTime !== null && data.LatestValueTime !== undefined && (
                        <div className="row">
                            <div className="title">Alert tripped at:</div>
                            <div>{formatDateTime(data.LatestValueTime)}</div>
                        </div>
                    )}

                    <div className="row">
                        <div className="sectiontitle">Alert settings</div>
                    </div>

                    <div className="row">
                        <div className="auditcell">
                            <div className="title">Type:</div>
                            <div>{type}</div>
                        </div>
                        <div className="auditcell">
                            <div className="title">Value:</div>
                            <div>{data.Value}</div>
                        </div>
                        <div className="auditcell">
                            <div className="title">Units:</div>
                            <div>{units}</div>
                        </div>
                        <div className="auditcell">
                            <div className="title">Direction:</div>
                            <div>{formatDirection(data.Direction)}</div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="sectiontitle">Alert selection</div>
                    </div>
                    <div className="row">
                        <div className="auditcell">
                            <div className="title">Email:</div>
                            <div>{data.Email ? <span className="tick">&#10003;</span> : <span className="cross">&#88;</span>}</div>
                        </div>
                        <div className="auditcell">
                            <div className="title">SMS:</div>
                            <div>{data.SMS ? <span className="tick">&#10003;</span> : <span className="cross">&#88;</span>}</div>
                        </div>
                        <div className="auditcell">
                            <div className="title">Photo:</div>
                            <div>{data.Photo ? <span className="tick">&#10003;</span> : <span className="cross">&#88;</span>}</div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="sectiontitle">Values</div>
                    </div>

                    <div className="row">
                        <div className="auditcell">
                            <div className="title">Calculated value:</div>
                            <div>{data.CalculatedTripValue.toFixed(0)}mm</div>
                        </div>
                        <div className="auditcell">
                            <div className="title">Previous value:</div>
                            <div>{data.PreviousValue.toFixed(0)}mm</div>
                        </div>
                        <div className="auditcell">
                            <div className="title">Latest value:</div>
                            <div>{data.LatestValue.toFixed(0)}mm</div>
                        </div>
                    </div>
                </AuditDetailsContainer>
            </Fade>
        );
    }

    return useObserver(() => renderModal());
};
