import styled from "styled-components";
import { Box } from "@material-ui/core";

export const AddEditButtonRow: any = styled(Box)`
    margin: 20px auto 0px;
    padding: 40px;
    width: 100%;
    display: flex;
    flex-direction: row;

    flex: 1 0 0;
    justify-content: center;

    > button {
        width: 179px;
        min-width: fit-content;

        &:nth-of-type(2) {
            margin-left: 20px;
        }
    }
`;
