import { observable } from "mobx";
import { ModelBase } from "Core/Models/ModelBase";
import { IsNotEmpty } from "class-validator";

//Base class expects the model type and the DTO model type
//If you are not using a DTO just pass in undefined as the DTO
export class ProjectPropertyListItemModel extends ModelBase<ProjectPropertyListItemModel, ProjectPropertyListItemDTO> {
    public id: string = "";
    public addressLine1: string = "";
    public addressLine2: string = "";
    public city: string = "";
    public county: string = "";
    public postcode: string = "";
    public projectNumber: string = "";
    public projectName: string = "";
    public latitude: number | undefined = undefined;
    public longitude: number | undefined = undefined;

    //fromDto is required but you can leave it blank
    fromDto(model: ProjectPropertyListItemDTO): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
    }

    //toDto is required but you can leave it blank
    toDto(model: ProjectPropertyListItemModel): void {}
}

export type ProjectPropertyListItemDTO = {
    id: string;
    addressLine1: string;
    addressLine2: string;
    city: string;
    county: string;
    postcode: string;
    projectNumber: string;
    projectName: string;
    latitude: number | undefined;
    longitude: number | undefined;
};

export interface SwapPropertyRequest {
    id: number;
    rowVersion: string;
    roofcareAddressId: string;
}
