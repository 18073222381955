import { ModelBase } from "Core/Models";
import { UnitStatus, UnitStatusDTO } from "Custom/Models/Domain";
import { action, observable } from "mobx";

//Base class expects the model type and the DTO model type
//If you are not using a DTO just pass in undefined as the DTO
export class DeviceStatusWithCommandModel extends ModelBase<DeviceStatusWithCommandModel, DeviceStatusWithCommandModelDTO> {
    @observable public id: string = "";
    @observable public commandIndex: number = 0;
    @observable public adminConditionSetItemId: string = "";

    @action public clear() {
        this.id = "";
        this.commandIndex = 0;
        this.adminConditionSetItemId = "";
    }

    //fromDto is required but you can leave it blank
    fromDto(model: DeviceStatusWithCommandModelDTO): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
    }

    //toDto is required but you can leave it blank
    toDto(model: DeviceStatusWithCommandModel): void {}
}

export type DeviceStatusWithCommandModelDTO = {
    id: string;
    commandIndex: number;
    adminConditionSetItemId: string;
};
