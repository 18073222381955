import { Box, Checkbox, FormControlLabel, Paper, Radio, RadioGroup, TextareaAutosize, Typography, useTheme } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import EditLogo from "Custom/Content/editIcon.svg";
import { ProjectCommunicationListItemModel } from "./ProjectCommunicationListItemModel";
import { ActionsViewModel } from "./ActionsViewModel";
import { Loader } from "Core/Components";
import { useRouter } from "Core/Utils";
import { useObserver } from "mobx-react-lite";
import { ResetSearchIcon } from "Custom/Components/Table/ResetSearchIcon";
import * as Defaults from "Custom/StylesAppSpecific/TableOptions";
import { ProjectToolbarRowV2, ProjectToolbarRowV3 } from "Custom/StylesAppSpecific/Controls/BoxAddWrapper";
import { RoofcareTable, RoofcareTableWrapper } from "Custom/Components/Table/RoofcareTable";
import { formatEdit } from "Custom/Components/AddEditControls";
import moment, { Moment } from "moment";
import { Container } from "Custom/StylesAppSpecific/AppStyling";
import { ActionConditionRow, ActionWrapper, ProjectActionListCell } from "./ActionsPage.style";
import { ProjectListSearch } from "../Projects/ProjectListSearch";
import MomentUtils from "@date-io/moment";
import { formatDate } from "Custom/Utils/format";
import { isValidDateString } from "Custom/Utils/utils";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ClickableImgCell } from "Custom/Components/Table/ClickableImgCell";
import { ActionDateTypeFilter } from "./GetDownloadActionRequest";
import { BaseModal } from "Custom/Components/Modal/BaseModal";
import { DeleteModalDialogPaper } from "Custom/Components/Modal/DeleteModal.styles";
import { AcceptBtn } from "Custom/StylesAppSpecific/Controls/AcceptBtn";
import { CancelBtn } from "Custom/StylesAppSpecific/Controls/CancelBtn";
import { AddCommunicationBox } from "../Projects/Details/Communication/Note/ProjectNote.style";
import { ActionType } from "Custom/Models/Domain/Projects/ActionTypes";

export interface EditCommunicationState {
    open: boolean;
    newCommunication: string;
    newFollowupDate: Moment | null;
    newIsResolved: boolean;
    newResolvedDate: Moment | null;
    newResolvedBy: string | undefined;
    editId: string | undefined;
}

export const ActionsPage: React.FunctionComponent = () => {
    const [viewModel] = useState(() => new ActionsViewModel());
    const theme = useTheme();
    const { history } = useRouter();
    //const [addEditOpen, setAddEditOpen] = useState<boolean>(false);

    const [editState, setEditState] = useState<EditCommunicationState>({
        open: false,
        newCommunication: "",
        newFollowupDate: null,
        newIsResolved: false,
        newResolvedDate: null,
        newResolvedBy: undefined,
        editId: undefined,
    });

    const [startDate, setStartDate] = useState(moment.utc().startOf("year"));
    const [endDate, setEndDate] = useState(moment.utc().add(1, "days").startOf("day").add(-1, "minute"));

    const minDate: Moment = moment.utc("2021-11-04");

    //useEffect below only gets run on initial render
    useEffect(() => {
        clearCommunicationState();
        if (viewModel.IsLoading == false) {
            const response = viewModel.load();
            response.then(() => {});
        }

        return () => {
            //console.log("Unmounting");
            viewModel.clear();
        };
    }, []);

    const clearCommunicationState = () => {
        setEditState({
            open: false,
            newCommunication: "",
            newFollowupDate: null,
            newIsResolved: false,
            newResolvedDate: null,
            newResolvedBy: undefined,
            editId: undefined,
        });
    };

    const updateStart = (date: any, value: string | null | undefined) => {
        let mom: Moment = moment(value, "DD/MM/YYYY");
        if (value !== null && value !== undefined) {
            if (isValidDateString(value!) === true && value!.length === 10) {
                if (mom < minDate) {
                    mom = minDate;
                }
                viewModel.setStartDate(mom);
                setStartDate(mom);
            }
        }
    };

    const updateEnd = (date: any, value: string | null | undefined) => {
        let mom: Moment = moment(value, "DD/MM/YYYY");
        if (value !== null && value !== undefined) {
            if (isValidDateString(value!) === true && value!.length === 10) {
                if (mom < minDate) {
                    mom = minDate;
                }
                viewModel.setEndDate(mom);
                setEndDate(mom);
            }
        }
    };

    const renderPage = () => {
        if (viewModel.IsLoading) return <Loader />;

        const tableOptions = Defaults.GetRoofcareTableOptions() as any;

        const getSortDirection = (columnIndex: number) => {
            return viewModel.sortColumnId === columnIndex ? viewModel.sortDirection : "";
        };

        const setOrderChange = (columnId: number, direction: any) => {
            viewModel.setOrderChange(columnId, direction);
        };

        const setSearchString = (value: string) => {
            viewModel.setSearchString(value);
            viewModel.setPage(0);
        };

        const setRowsPerPage = (rows: number) => {
            viewModel.setRowsPerPage(rows);
        };

        const setPage = (page: number) => {
            viewModel.setPage(page);
        };

        const isInError = (fieldName: string): boolean => {
            /*         let isValid = props.filtersViewModel.getValid(fieldName);
        return !isValid; */

            return false;
        };

        const getError = (fieldName: string): string => {
            /*         let retVal: string = props.filtersViewModel.getError(fieldName);
        return retVal; */
            return "";
        };
        /* 
        function handleActionTypeFilterChange(values: number[] | string[] | null): void {
            viewModel.setActionTypeFilter(values as string[]);
        } */

        const editActionClick = (id: string) => {
            let action: ProjectCommunicationListItemModel | undefined = viewModel.getActionById(id);

            if (action !== undefined) {
                setEditState({
                    open: true,
                    newCommunication: action.note,
                    newFollowupDate: action.followUpDate === null ? null : moment.utc(action.followUpDate),
                    newIsResolved: action.isResolved,
                    newResolvedDate: action.resolvedDate === null ? null : moment.utc(action.resolvedDate),
                    newResolvedBy: action.resolvedBy === null ? undefined : action.resolvedBy,
                    editId: id,
                });
            }
        };

        const handleActionDateTypeFilterChange = (e: any, value: string) => {
            viewModel.setDateTypeOption(parseInt(value) as ActionDateTypeFilter);
        };

        function handleCSVClick(id: string) {
            viewModel.downloadCSV();
        }

        const onClose = () => {
            clearCommunicationState();
        };

        /*         const handleSubmitNewCommunication = (e: React.FormEvent) => {
            e.preventDefault();
            const ret = viewModel.upsertNewCommunicationNote(editState.newCommunication, editState.newFollowupDate === null ? undefined : editState.newFollowupDate.toISOString());

            ret.then((result) => {
                clearCommunicationState();
            });
        }; */

        const handleSubmitEditCommunication = (e: React.FormEvent) => {
            e.preventDefault();
            const ret = viewModel.editCommunicationNote(
                editState.editId!,
                editState.newCommunication,
                editState.newFollowupDate === null ? undefined : editState.newFollowupDate.toISOString(),
                editState.newIsResolved,
                editState.newResolvedDate === null ? undefined : editState.newResolvedDate.toISOString(),
                editState.newResolvedBy,
            );

            ret.then((result) => {
                clearCommunicationState();
            });
        };

        const handleCommunicationChangeChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
            setEditState({
                open: editState.open,
                newCommunication: e.target.value,
                newFollowupDate: editState.newFollowupDate,
                newIsResolved: editState.newIsResolved,
                newResolvedDate: editState.newResolvedDate,
                newResolvedBy: editState.newResolvedBy,
                editId: editState.editId,
            });
        };

        const handleFollowUpDateChange = (date: any, value: string | null | undefined) => {
            const mom: Moment = moment(value, "DD/MM/YYYY");
            if (value !== null && value !== undefined) {
                if (isValidDateString(value!) === true && value!.length === 10) {
                    setEditState({
                        open: editState.open,
                        newCommunication: editState.newCommunication,
                        newFollowupDate: date,
                        newIsResolved: editState.newIsResolved,
                        newResolvedDate: editState.newResolvedDate,
                        newResolvedBy: editState.newResolvedBy,
                        editId: editState.editId,
                    });
                }
            } else {
                setEditState({
                    open: editState.open,
                    newCommunication: editState.newCommunication,
                    newFollowupDate: null,
                    newIsResolved: editState.newIsResolved,
                    newResolvedDate: editState.newResolvedDate,
                    newResolvedBy: editState.newResolvedBy,
                    editId: editState.editId,
                });
            }
        };

        const handleResolvedDateChange = (date: any, value: string | null | undefined) => {
            const mom: Moment = moment(value, "DD/MM/YYYY");
            if (value !== null && value !== undefined) {
                if (isValidDateString(value!) === true && value!.length === 10) {
                    setEditState({
                        open: editState.open,
                        newCommunication: editState.newCommunication,
                        newFollowupDate: editState.newFollowupDate,
                        newIsResolved: editState.newIsResolved,
                        newResolvedDate: date,
                        newResolvedBy: editState.newResolvedBy,
                        editId: editState.editId,
                    });
                }
            } else {
                setEditState({
                    open: editState.open,
                    newCommunication: editState.newCommunication,
                    newFollowupDate: editState.newFollowupDate,
                    newIsResolved: editState.newIsResolved,
                    newResolvedDate: null,
                    newResolvedBy: editState.newResolvedBy,
                    editId: editState.editId,
                });
            }
        };

        const disableDates: boolean = false;
        let addeditModalTitle: string = editState.editId === undefined ? "Add new action" : "Edit action";
        let addeditModalSection: string = editState.editId === undefined ? "Add action below" : "Edit action below";
        let addeditModalAcceptText: string = editState.editId === undefined ? "Add Action" : "Save";
        let addeditModalAccept: any = editState.editId === undefined ? handleSubmitEditCommunication : handleSubmitEditCommunication;

        const resolvedValid: string = viewModel.isResolvedValid === true ? "" : " invalidselection";
        const followUpValid: string = viewModel.isFollowUpValid === true ? "" : " invalidselection";

        return (
            <Container>
                {
                    <>
                        <BaseModal
                            open={editState.open}
                            onClose={onClose}
                            title={addeditModalTitle}
                            PaperComponent={DeleteModalDialogPaper}
                            actions={
                                <>
                                    <AcceptBtn onClick={addeditModalAccept} color="secondary" autoFocus variant="contained">
                                        {addeditModalAcceptText}
                                    </AcceptBtn>
                                    <CancelBtn onClick={onClose}>Cancel</CancelBtn>
                                </>
                            }
                        >
                            <AddCommunicationBox>
                                <div>{addeditModalSection}</div>
                                <TextareaAutosize value={editState.newCommunication} onChange={handleCommunicationChangeChange} className="note-textarea" />

                                <div style={{ paddingTop: "15px" }}>Follow up date</div>
                                <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        placeholder="Follow up date"
                                        label=""
                                        format="DD/MM/YYYY"
                                        value={editState.newFollowupDate}
                                        onChange={handleFollowUpDateChange}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                                {editState.editId !== undefined && (
                                    <div style={{ display: "flex", width: "100%", flexDirection: "row", paddingTop: "10px" }}>
                                        <Typography style={{ fontSize: "14px", marginTop: "8px" }}>Resolve note</Typography>
                                        <Checkbox
                                            checked={editState.newIsResolved}
                                            className="form-control"
                                            name={"is Resolved"}
                                            onChange={() => {
                                                let temp: boolean = !editState.newIsResolved;
                                                setEditState({
                                                    open: editState.open,
                                                    newCommunication: editState.newCommunication,
                                                    newFollowupDate: editState.newFollowupDate,
                                                    newIsResolved: temp,
                                                    newResolvedDate: temp === false ? null : moment.utc(),
                                                    newResolvedBy: undefined,
                                                    editId: editState.editId,
                                                });
                                            }}
                                            value={editState.newIsResolved === true ? 1 : 0}
                                        />
                                    </div>
                                )}
                                {editState.newIsResolved === true && (
                                    <div style={{ paddingBottom: "10px" }}>
                                        <div style={{ paddingTop: "15px" }}>Resolved Date</div>
                                        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                                            <KeyboardDatePicker
                                                placeholder="Resolved date"
                                                label=""
                                                format="DD/MM/YYYY"
                                                value={editState.newResolvedDate}
                                                onChange={handleResolvedDateChange}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                )}
                            </AddCommunicationBox>
                        </BaseModal>

                        <ActionWrapper>
                            <ProjectToolbarRowV2>
                                <div className="search-field" style={{ width: "580px" }}>
                                    <ProjectListSearch searchString={viewModel.getSearchString()} setSearchString={setSearchString} placeholder="Start typing to search actions" />
                                </div>
                                <div className="download-csv">
                                    <ClickableImgCell className="projectnote-addedit" logo={EditLogo} text={"Download Actions CSV"} onClick={handleCSVClick} id={"0"} />
                                </div>
                            </ProjectToolbarRowV2>
                            <ProjectToolbarRowV3 style={{ marginTop: "20px" }}>
                                <Box>
                                    <Typography style={{ color: theme.palette.secondary.contrastText }} variant="h2" className="select-title">
                                        Action Conditions:
                                    </Typography>
                                    <ActionConditionRow>
                                        <Box className="actionconditionitem">
                                            <AcceptBtn onClick={() => viewModel.clearActionConditions()} style={{ marginRight: "15px" }}>
                                                None
                                            </AcceptBtn>
                                            <AcceptBtn onClick={() => viewModel.setAllActionConditions()}>All</AcceptBtn>
                                        </Box>
                                        <Box className="actionconditionitem">
                                            <Typography style={{ fontSize: "14px", marginTop: "8px" }}>No follow up date</Typography>
                                            <Checkbox
                                                checked={viewModel.IsNoFollowupDate}
                                                className="form-control"
                                                name={"nofollowupdate"}
                                                onChange={() => {
                                                    viewModel.toggleAction(ActionType.NoFollowUpDate);
                                                }}
                                            />
                                        </Box>
                                        <Box className={"actionconditionitem" + followUpValid}>
                                            <Typography style={{ fontSize: "14px", marginTop: "8px" }}>Follow up not overdue</Typography>
                                            <Checkbox
                                                checked={viewModel.IsFollowUpNotOverdue}
                                                className="form-control"
                                                name={"nofollowupdate"}
                                                onChange={() => {
                                                    viewModel.toggleAction(ActionType.HasFollowUpDateNotOverdue);
                                                }}
                                            />
                                        </Box>
                                        <Box className={"actionconditionitem" + followUpValid}>
                                            <Typography style={{ fontSize: "14px", marginTop: "8px" }}>Overdue follow up</Typography>
                                            <Checkbox
                                                checked={viewModel.IsFollowUpOverdue}
                                                className="form-control"
                                                name={"nofollowupdate"}
                                                onChange={() => {
                                                    viewModel.toggleAction(ActionType.HasFollowUpDateOverdue);
                                                }}
                                            />
                                        </Box>
                                        <Box className={"actionconditionitem" + resolvedValid}>
                                            <Typography style={{ fontSize: "14px", marginTop: "8px" }}>Resolved</Typography>
                                            <Checkbox
                                                checked={viewModel.IsResolved}
                                                className="form-control"
                                                name={"nofollowupdate"}
                                                onChange={() => {
                                                    viewModel.toggleAction(ActionType.Resolved);
                                                }}
                                            />
                                        </Box>
                                    </ActionConditionRow>
                                </Box>
                            </ProjectToolbarRowV3>
                            <ProjectToolbarRowV3 style={{ marginTop: "15px", color: "white" /* , backgroundColor: "pink" */ }}>
                                <Box /* style={{ backgroundColor: "green" }} */>
                                    <Typography style={{ color: theme.palette.secondary.contrastText }} variant="h2" className="select-title">
                                        Date Conditions:
                                    </Typography>
                                    <div style={{ display: "flex", flexDirection: "row", flex: "1 0 0", marginTop: "-5px" /* , backgroundColor: "orange" */ }}>
                                        <div style={{ marginTop: "12px" /* , backgroundColor: "yellowgreen" */ }}>
                                            <RadioGroup
                                                row
                                                aria-label="dates"
                                                defaultValue={viewModel.getActionDateTypeFilter}
                                                value={viewModel.getActionDateTypeFilter}
                                                name="radio-buttons-group"
                                                onChange={handleActionDateTypeFilterChange}
                                            >
                                                <FormControlLabel value={ActionDateTypeFilter.IGNOREDATES} control={<Radio color="default" />} label="Off" />
                                                <FormControlLabel value={ActionDateTypeFilter.CREATED} control={<Radio color="default" />} label="Created" />
                                                <FormControlLabel value={ActionDateTypeFilter.FOLLOWUP} control={<Radio color="default" />} label="Folllow up" />
                                                <FormControlLabel value={ActionDateTypeFilter.RESOLVED} control={<Radio color="default" />} label="Resolved" />
                                            </RadioGroup>
                                        </div>
                                        <Box style={{ maxWidth: "300px", paddingRight: "15px" /* , backgroundColor: "blue" */ }}>
                                            <Typography style={{ fontSize: "14px", width: "100px" }}>Show from:</Typography>
                                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                                <KeyboardDatePicker
                                                    placeholder="01/01/2021"
                                                    label={""}
                                                    value={startDate}
                                                    disabled={disableDates}
                                                    mask={"__/__/____"}
                                                    format="DD/MM/YYYY"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    onChange={updateStart}
                                                    minDate={minDate}
                                                    maxDate={endDate}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </Box>
                                        <Box style={{ maxWidth: "300px" }}>
                                            <Typography style={{ fontSize: "14px", width: "75px" }}>Show to:</Typography>
                                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                                <KeyboardDatePicker
                                                    placeholder="01/01/2021"
                                                    label={""}
                                                    value={endDate}
                                                    disabled={disableDates}
                                                    mask={"__/__/____"}
                                                    format="DD/MM/YYYY"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    onChange={updateEnd}
                                                    minDate={startDate}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </Box>
                                    </div>
                                </Box>
                            </ProjectToolbarRowV3>
                        </ActionWrapper>

                        <RoofcareTable
                            columns={[
                                {
                                    width: "200px",
                                    maxWidth: "200px",
                                    minWidth: "200px",
                                    title: "Project Name",
                                    field: "projectName",
                                    sorting: true,
                                    filtering: true,
                                    defaultSort: getSortDirection(0),
                                },
                                {
                                    width: "100px",
                                    maxWidth: "100px",
                                    minWidth: "100px",
                                    title: "Project Number",
                                    field: "projectNumber",
                                    sorting: true,
                                    filtering: false,
                                    defaultSort: getSortDirection(1),
                                },
                                { width: "auto", title: "Note", field: "note", sorting: true, filtering: false, defaultSort: getSortDirection(2) },
                                {
                                    title: "Created Date",
                                    field: "createdDate",
                                    sorting: true,
                                    filtering: false,
                                    defaultSort: getSortDirection(4),
                                    render: (rowData: ProjectCommunicationListItemModel) => {
                                        if (rowData !== null && rowData !== undefined) {
                                            if (rowData.createdDate !== undefined && rowData.createdDate !== null) {
                                                return <ProjectActionListCell className={"created "}>{formatDate(rowData.createdDate)}</ProjectActionListCell>;
                                            } else {
                                                return <div></div>;
                                            }
                                        }
                                    },
                                },

                                {
                                    title: "Follow Up Date",
                                    field: "followUpDate",
                                    sorting: true,
                                    filtering: false,
                                    render: (rowData: ProjectCommunicationListItemModel) => {
                                        if (rowData !== null && rowData !== undefined) {
                                            if (rowData.followUpDate !== undefined && rowData.followUpDate !== null) {
                                                let isOverdue: string = "";

                                                if (rowData.isResolved === false && moment.utc(rowData.followUpDate).endOf("d") < moment.utc()) {
                                                    isOverdue = " overdue";
                                                }

                                                return <ProjectActionListCell className={"followupdate " + isOverdue}>{formatDate(rowData.followUpDate)}</ProjectActionListCell>;
                                            } else {
                                                return <div></div>;
                                            }
                                        }
                                    },
                                    defaultSort: getSortDirection(3),
                                },
                                {
                                    title: "Resolved Date",
                                    field: "resolvedDate",
                                    sorting: true,
                                    filtering: false,
                                    defaultSort: getSortDirection(4),
                                    render: (rowData: ProjectCommunicationListItemModel) => {
                                        if (rowData !== null && rowData !== undefined) {
                                            if (rowData.resolvedDate !== undefined && rowData.resolvedDate !== null) {
                                                return <ProjectActionListCell className={"resolved "}>{formatDate(rowData.resolvedDate)}</ProjectActionListCell>;
                                            } else {
                                                return <div></div>;
                                            }
                                        }
                                    },
                                },
                                {
                                    width: "110px",
                                    maxWidth: "110px",
                                    minWidth: "110px",
                                    title: "",
                                    field: "id",
                                    sorting: false,
                                    filtering: false,
                                    render: (rowData: any) => {
                                        if (rowData !== null && rowData !== undefined) {
                                            return formatEdit(rowData.id, "action", editActionClick);
                                        }
                                    },
                                },
                            ]}
                            style={{
                                backgroundColor: "#3B3C3B",
                                padding: 0,
                            }}
                            options={{
                                thirdSortClick: false,
                                cellStyle: {
                                    fontSize: 10,
                                },
                                initialPage: viewModel.currentPage,
                                defaultGroupOrder: viewModel.sortColumnId,
                                defaultGroupDirection: viewModel.sortDirection,
                                pageSize: viewModel.pageSize,
                                pageSizeOptions: [10, 20, 50, 100],
                                emptyRowsWhenPaging: false,
                                headerStyle: {
                                    ...tableOptions.headerStyle,
                                },
                                rowStyle: {
                                    ...tableOptions.rowStyle,
                                },
                                searchFieldAlignment: "left",
                                searchFieldStyle: {
                                    border: "1px solid #333333",
                                    borderRadius: "5px",
                                    width: "400px",
                                },
                                /* tableLayout: "fixed", */
                            }}
                            data={viewModel.getActions}
                            title=""
                            icons={{
                                ResetSearch: () => (
                                    <span style={{ marginRight: "-11px" }}>
                                        <ResetSearchIcon />
                                    </span>
                                ),
                            }}
                            components={{
                                Container: (props: any) => <Paper {...props} elevation={0} />,
                                Toolbar: (props: any) => <></>,
                            }}
                            onOrderChange={(orderedColumnId: any, orderDirection: any) => {
                                setOrderChange(orderedColumnId, orderDirection);
                            }}
                            onChangeRowsPerPage={(rows: number) => {
                                setRowsPerPage(rows);
                            }}
                            onChangePage={(page: number) => {
                                setPage(page);
                            }}
                        />
                    </>
                }
            </Container>
        );
    };

    return useObserver(() => renderPage());
};
