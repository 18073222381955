import "date-fns";

import { Box, FormControl, Grid } from "@material-ui/core";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import DateFnsUtils from "@date-io/date-fns";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import React from "react";

export const DatePicker: React.FC = () => {
    const [startDate, setStartDate] = React.useState<MaterialUiPickersDate | undefined>(undefined);

    function handleStartDateChange(date: MaterialUiPickersDate): void {
        setStartDate(date);
    }

    return (
        <>
            <Grid>
                <Grid item xs={2}>
                    <FormControl margin="normal">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid item xs={6} sm={6}>
                                <Box textAlign="left">
                                    <KeyboardDatePicker
                                        margin="normal"
                                        id="start-date-picker-dialog"
                                        format="MM/dd/yyyy"
                                        value={startDate}
                                        onChange={handleStartDateChange}
                                        KeyboardButtonProps={{
                                            "aria-label": "change date",
                                        }}
                                    />
                                </Box>
                            </Grid>
                        </MuiPickersUtilsProvider>
                    </FormControl>
                </Grid>
            </Grid>
        </>
    );
};
