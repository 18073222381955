import styled from "styled-components";
import { Box } from "@material-ui/core";
import { TabCommonBox } from "./TabCommonStyle.style";

export const ConfigBox: any = styled(TabCommonBox)`
    /*     padding: 5px 15px;
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    width: 100%;
    background-color: white; */
`;

export const ConfigRow: any = styled(Box)`
    display: block;
    padding: 0 30px;
    padding-top: 15px;
    width: 100%;
    min-width: 320px;
`;

export const DeviceHeader: any = styled(Box)`
    display: flex;
    flex: 1 0 0;
    width: 100%;
    background-color: ${(props) => props.theme.app.colors.headers.main};
    padding: 15px;
`;

export const DeviceAdminRow: any = styled(Box)`
    display: flex;
    flex: 1 0 0;
    width: 100%;
    justify-content: left;
    padding: 15px 0;

    .slider-text {
        margin-top: 5px;
        padding-right: 15px;
    }
`;
