import React, { useState } from "react";
import { Box, Typography, useMediaQuery, AppBar, Toolbar } from "@material-ui/core";

import * as styles from "./Home.styles";
import circleRight from "Custom/Content/circle-right.svg";

import { UnsupportedBrowser } from "./UnsupportedBrowser";
import { BoxMiddle, Cell, StaticBGImg } from "Core/Components/Styles";
import { EditableInput } from "Core/Components/EditableInput";
import { KeyValuePair } from "Core/Models/KeyValuePair";
import shootHill from "../Content/shoothillFooterLogo.svg";
import { AutoGrid } from "Core/Components/AutoGrid";

export const Home: React.FC = () => {
    const getSection1 = () => {
        return (
            <styles.Section1>
                <styles.WhiteBoxWritingHeader>Creative. Technologies.</styles.WhiteBoxWritingHeader>
                <styles.WhiteBoxWritingBody>Need something a bit different?</styles.WhiteBoxWritingBody>
                <styles.WhiteBoxWritingBody>Shoothill Creative is for you.</styles.WhiteBoxWritingBody>
                <Box maxWidth={"500px"}>
                    <styles.CaptionText>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. In efficitur sem eget dui suscipit volutpat. Donec faucibus odio vitae arcu vulputate egestas. Sed
                        nec tellus diam. Nam maximus tempus posuere. Fusce molestie pharetra justo, et consequat est vulputate ac. Sed blandit, dui vel porta vehicula, mi justo
                        aliquam nibh, sed suscipit dui dui quis nisi.
                    </styles.CaptionText>
                </Box>
                <Box paddingTop={"10px"} display={"flex"}>
                    <Box paddingRight={"10px"}>
                        <Typography variant={"h4"} color="textSecondary">
                            Bring my ideas to life
                        </Typography>
                    </Box>
                </Box>
            </styles.Section1>
        );
    };

    const getSection2 = () => {
        return (
            <styles.Section2>
                <AutoGrid desktopColumns={"1fr 1fr"} rowGap={"60px"}>
                    <Box textAlign="center">
                        <Typography variant={"h2"} color={"textSecondary"}>
                            So what can we create for you?
                        </Typography>
                        <styles.CaptionText>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. In efficitur sem eget dui suscipit volutpat. Donec faucibus odio vitae arcu vulputate egestas.
                            Sed nec tellus diam. Nam maximus tempus posuere. Fusce molestie pharetra justo, et consequat est vulputate ac. Sed blandit, dui vel porta vehicula, mi
                            justo aliquam nibh, sed suscipit dui dui quis nisi.
                        </styles.CaptionText>
                        <styles.CaptionText>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. In efficitur sem eget dui suscipit volutpat. Donec faucibus odio vitae arcu vulputate egestas.
                            Sed nec tellus diam. Nam maximus tempus posuere. Fusce molestie pharetra justo, et consequat est vulputate ac. Sed blandit, dui vel porta vehicula, mi
                            justo aliquam nibh, sed suscipit dui dui quis nisi. Lorem ipsum dolor sit amet, consectetur adipiscing elit. In efficitur sem eget dui suscipit
                            volutpat. Donec faucibus odio vitae arcu vulputate egestas. Sed nec tellus diam. Nam maximus tempus posuere. Fusce molestie pharetra justo, et consequat
                            est vulputate ac. Sed blandit, dui vel porta vehicula, mi justo aliquam nibh, sed suscipit dui dui quis nisi. Lorem ipsum dolor sit amet, consectetur
                            adipiscing elit. In efficitur sem eget dui suscipit volutpat. Donec faucibus odio vitae arcu vulputate egestas. Sed nec tellus diam. Nam maximus tempus
                            posuere. Fusce molestie pharetra justo, et consequat est vulputate ac. Sed blandit, dui vel porta vehicula, mi justo aliquam nibh, sed suscipit dui dui
                            quis nisi.
                        </styles.CaptionText>
                    </Box>
                </AutoGrid>
            </styles.Section2>
        );
    };

    const getSection3 = () => {
        return (
            <styles.Section3>
                <AutoGrid desktopColumns={"1fr 1fr 1fr 1fr"} rowGap={"60px"} justifyItems="center" className="gridSection">
                    <Box textAlign="center">
                        <styles.headerStyle>Marketing campaigns</styles.headerStyle>
                        <styles.paraStyle>
                            Our applications are built to improve the efficiency, productivity and value of our customers. We pride ourselves on our ability to fully understand
                            your business which, in turn, enables us to design and develop award-winning software that delivers tangible results.
                        </styles.paraStyle>
                    </Box>
                    <Box textAlign="center">
                        {/*   <img alt={""} src={speakerIcon} />*/}
                        <styles.headerStyle>Design & branding</styles.headerStyle>
                        <styles.paraStyle>
                            Our applications are built to improve the efficiency, productivity and value of our customers. We pride ourselves on our ability to fully understand
                            your business which, in turn, enables us to design and develop award-winning software that delivers tangible results.
                        </styles.paraStyle>
                    </Box>
                    <Box textAlign="center">
                        {/*  <img alt={""} src={campaignsIcon} />*/}
                        <styles.headerStyle>Shoothill labs</styles.headerStyle>
                        <styles.paraStyle>
                            Our applications are built to improve the efficiency, productivity and value of our customers. We pride ourselves on our ability to fully understand
                            your business which, in turn, enables us to design and develop award-winning software that delivers tangible results.
                        </styles.paraStyle>
                    </Box>
                    <Box textAlign="center">
                        {/*      <img alt={""} src={designAndBrandingIcon} /> */}
                        <styles.headerStyle>Speaker</styles.headerStyle>
                        <styles.paraStyle>
                            Our applications are built to improve the efficiency, productivity and value of our customers. We pride ourselves on our ability to fully understand
                            your business which, in turn, enables us to design and develop award-winning software that delivers tangible results.
                        </styles.paraStyle>
                    </Box>
                </AutoGrid>
            </styles.Section3>
        );
    };

    const getSection4 = () => {
        return (
            <styles.Section4>
                <AutoGrid desktopColumns={"2fr 0.3fr 0.5fr"} tabletColumns={"2fr 1fr"} rowGap={"60px"} justifyContent={"center"}>
                    <Box>
                        <styles.WhiteBoxWritingHeader1>Work. Better.</styles.WhiteBoxWritingHeader1>
                        <styles.WhiteBoxWritingBody1>Transform how you do business</styles.WhiteBoxWritingBody1>
                        <styles.WhiteBoxWritingBody2>with Shoothill digital tecnology</styles.WhiteBoxWritingBody2>
                    </Box>
                    <Box className="s3Img"></Box>
                </AutoGrid>
            </styles.Section4>
        );
    };

    const getSection5 = () => {
        return (
            <styles.Section5>
                <Box>
                    <Typography variant={"h2"} color={"textSecondary"}>
                        An idea is born.
                    </Typography>
                    <Typography color={"textSecondary"}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. In efficitur sem eget dui suscipit volutpat. Donec faucibus odio vitae arcu vulputate egestas. Sed
                        nec tellus diam. Nam maximus tempus posuere. Fusce molestie pharetra justo, et consequat est vulputate ac. Sed blandit, dui vel porta vehicula, mi justo
                        aliquam nibh, sed suscipit dui dui quis nisi. Lorem ipsum dolor sit amet, consectetur adipiscing elit. In efficitur sem eget dui suscipit volutpat. Donec
                        faucibus odio vitae arcu vulputate egestas. Sed nec tellus diam. Nam maximus tempus posuere. Fusce molestie pharetra justo, et consequat est vulputate ac.
                        Sed blandit, dui vel porta vehicula, mi justo aliquam nibh, sed suscipit dui dui quis nisi. Lorem ipsum dolor sit amet, consectetur adipiscing elit. In
                        efficitur sem eget dui suscipit volutpat. Donec faucibus odio vitae arcu vulputate egestas. Sed nec tellus diam. Nam maximus tempus posuere. Fusce molestie
                        pharetra justo, et consequat est vulputate ac. Sed blandit, dui vel porta vehicula, mi justo aliquam nibh, sed suscipit dui dui quis nisi.
                    </Typography>
                </Box>
                <Box paddingTop="50px">
                    <AutoGrid desktopColumns={"1fr 1fr"} tabletColumns={"500px 250px"} rowGap={"60px"}>
                        <Typography color={"textSecondary"}>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. In efficitur sem eget dui suscipit volutpat. Donec faucibus odio vitae arcu vulputate Sed nec
                            Sed nec tellus diam. Nam maximus tempus posuere. Fusce molestie pharetra justo, et consequat est vulputate ac. Sed blandit, dui vel porta vehicula, mi
                            justo aliquam nibh, sed suscipit dui dui quis nisi. Lorem ipsum dolor sit amet, consectetur adipiscing elit. In efficitur sem eget dui suscipit
                            volutpat. Donec faucibus odio vitae arcu vulputate egestas. Sed nec tellus diam. Nam maximus tempus posuere. Fusce molestie pharetra justo, est est
                            vulputate ac. Sed blandit, dui vel porta vehicula, mi justo aliquam nibh, sed suscipit dui dui quis nisi. Lorem ipsum dolor sit amet, consectetur
                            adipiscing elit. In efficitur sem eget dui suscipit volutpat. Donec faucibus odio vitae arcu vulputate egestas. Sed nec tellus diam. Nam maximus tempus
                            posuere. Fusce molestie pharetra justo, et consequat est vulputate ac. Sed blandit, dui vel porta vehicula, mi justo aliquam nibh, sed suscipit dui dui
                            quis nisi. Lorem ipsum dolor sit amet, consectetur adipiscing elit. In efficitur sem eget dui suscipit volutpat. Donec faucibus odio vitae arcu
                            vulputate egestas. Sed nec tellus diam. Nam maximus tempus posuere. Fusce molestie pharetra justo, et consequat est vulputate ac. Sed blandit, dui vel
                            porta vehicula, mi justo aliquam nibh, sed suscipit dui dui quis nisi. Lorem ipsum dolor sit amet, consectetur adipiscing elit. In efficitur sem eget
                            dui suscipit volutpat. Donec faucibus odio vitae arcu vulputate egestas. Sed nec tellus diam. Nam maximus tempus posuere. Fusce molestie pharetra justo,
                            et consequat est vulputate ac. Sed blandit, dui vel porta vehicula, mi justo aliquam nibh, sed suscipit dui dui quis nisi. Lorem ipsum dolor sit amet,
                            consectetur adipiscing elit. In efficitur sem eget dui suscipit volutpat. Donec faucibus odio vitae arcu vulputate egestas. Sed nec tellus diam. Nam
                            maximus tempus posuere. Fusce molestie pharetra justo, et consequat est vulputate ac. Sed blandit, dui vel porta vehicula, mi justo aliquam nibh, sed
                            suscipit dui dui quis nisi.
                        </Typography>
                    </AutoGrid>
                </Box>
            </styles.Section5>
        );
    };

    const getSection6 = () => {
        return (
            <styles.Section6>
                <AutoGrid desktopColumns={"2fr 1fr"} tabletColumns={"2fr 1fr"} rowGap={"60px"} justifyContent={"center"}>
                    <Box>
                        <Typography variant={"h1"} color={"textSecondary"}>
                            Why Shoothill creative are the right people to bring your ideas to life
                        </Typography>
                        <Typography variant={"h3"} color={"textSecondary"}>
                            Rachael Carrington
                        </Typography>
                        <Typography variant={"h4"} color={"textSecondary"}>
                            Senior Project Manager at Mail Metro Media
                        </Typography>
                    </Box>
                </AutoGrid>
            </styles.Section6>
        );
    };

    const getSection7 = () => {
        return (
            <styles.Section7>
                <AutoGrid desktopColumns={"1fr 1fr"} tabletColumns={"1fr"} rowGap={"60px"} justifyContent={"center"}>
                    <Box>
                        <Typography variant={"h1"} color={"textSecondary"}>
                            Harnessing the power of AI to achieve the impossible
                        </Typography>
                    </Box>
                    <Box className="paddingTest">
                        <Typography color={"textSecondary"}>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. In efficitur sem eget dui suscipit volutpat. Donec faucibus odio vitae arcu vulputate egestas.
                            Sed nec tellus diam. Nam maximus tempus posuere. Fusce molestie pharetra justo, et consequat est vulputate ac. Sed blandit, dui vel porta vehicula, mi
                            justo aliquam nibh, sed suscipit dui dui quis nisi. Lorem ipsum dolor sit amet, consectetur adipiscing elit. In efficitur sem eget dui suscipit
                            volutpat. Donec faucibus odio vitae arcu vulputate egestas. Sed nec tellus diam. Nam maximus tempus posuere. Fusce molestie pharetra justo, et consequat
                            est vulputate ac. Sed blandit, dui vel porta vehicula, mi justo aliquam nibh, sed suscipit dui dui quis nisi. Lorem ipsum dolor sit amet, consectetur
                            adipiscing elit. In efficitur sem eget dui suscipit volutpat. Donec faucibus odio vitae arcu vulputate egestas. Sed nec tellus diam. Nam maximus tempus
                            posuere. Fusce molestie pharetra justo, et consequat est vulputate ac. Sed blandit, dui vel porta vehicula, mi justo aliquam nibh, sed suscipit dui dui
                            quis nisi.
                        </Typography>
                    </Box>
                </AutoGrid>
            </styles.Section7>
        );
    };

    const getSection8 = () => {
        return (
            <styles.Section8>
                <AutoGrid desktopColumns="3fr 1fr">
                    <AutoGrid desktopColumns="3fr">
                        <Typography variant={"h1"} color={"textSecondary"}>
                            Talk to us.
                        </Typography>
                        <Box paddingTop="25px">
                            <Typography variant={"h2"} color={"textSecondary"}>
                                Send a message
                            </Typography>
                        </Box>
                    </AutoGrid>
                    <Box paddingLeft="125px" paddingTop="35px">
                        <Typography variant={"h3"} color={"textSecondary"}>
                            Contact us.
                        </Typography>
                    </Box>
                </AutoGrid>
                {/* This section covers the input table */}
                <AutoGrid paddingTop={"25px"} desktopColumns={"3fr 1fr"} tabletColumns={"3fr 1fr"} rowGap={"60px"} justifyContent={"center"}>
                    <AutoGrid desktopColumns="1fr">
                        <Box paddingLeft="125px">
                            <Box>
                                <Typography variant={"h5"} color={"textSecondary"}>
                                    Phone
                                </Typography>
                                <Typography color={"textSecondary"}>+44 (0) 1743 636300</Typography>
                            </Box>
                            <Box paddingTop="50px">
                                <Typography variant={"h5"} color={"textSecondary"}>
                                    Email.
                                </Typography>
                                <Typography color={"textSecondary"}>info@shoothill.com</Typography>
                            </Box>
                            <Box paddingTop="50px">
                                <Typography variant={"h5"} color={"textSecondary"}>
                                    Social.
                                </Typography>
                                <Box paddingTop="150px">
                                    <Typography>
                                        <img alt={""} src={shootHill} />
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </AutoGrid>
                </AutoGrid>
            </styles.Section8>
        );
    };

    const getSection9 = () => {
        return (
            <styles.Section9>
                <AppBar position="static" style={{ backgroundColor: "#000000" }}>
                    <Toolbar style={{ opacity: "1" }}>
                        <Box>
                            <Typography>© Shoothill Ltd 2020</Typography>
                        </Box>
                        <Box style={{ paddingLeft: "15px" }}>
                            <Typography>Privacy policy</Typography>
                        </Box>
                        <Box flexGrow={1} style={{ paddingLeft: "15px" }}>
                            <Typography>Shoothill Ltd are registered in England & Wales with company number 5885234</Typography>
                        </Box>
                        <Box>
                            <Typography style={{ padding: "21px 5px 24px 19px" }}>Home </Typography>
                        </Box>
                        <Box>
                            <Typography style={{ padding: "21px 5px 24px 19px" }}>Services </Typography>
                        </Box>
                        <Box>
                            <Typography style={{ padding: "21px 5px 24px 19px" }}>Technology </Typography>
                        </Box>
                        <Box>
                            <Typography style={{ padding: "21px 5px 24px 19px" }}>Work </Typography>
                        </Box>
                        <Box>
                            <Typography style={{ padding: "21px 5px 24px 19px" }}>Shoothill labs </Typography>
                        </Box>
                        <Box>
                            <Typography style={{ padding: "21px 136px 24px 19px" }}>Contact us </Typography>
                        </Box>
                    </Toolbar>
                </AppBar>
            </styles.Section9>
        );
    };

    return (
        <>
            {(window as any).IE11 ? (
                <UnsupportedBrowser />
            ) : (
                <>
                    <styles.Main>
                        {getSection1()}
                        {getSection2()}
                        {getSection3()}
                        {getSection4()}
                        {getSection5()}
                        {getSection6()}
                        {getSection7()}
                        {getSection8()}
                        {getSection9()}
                    </styles.Main>
                </>
            )}
        </>
    );
};
