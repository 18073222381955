import * as MobX from "mobx";
import { ModelBase } from "Core/Models/ModelBase";
import { IsEmail, IsNotEmpty, ValidateIf, MinLength, MaxLength } from "class-validator";
import { generateID } from "Custom/Utils/utils";

//Base class expects the model type and the DTO model type
//If you are not using a DTO just pass in undefined as the DTO
export class ContactModel extends ModelBase<ContactModel, ContactModelDTO> {
    public id: string = "";

    @MobX.observable
    @IsNotEmpty({ message: "First name is required." })
    @MaxLength(50, { message: "Maximum first name length is 50 characters" })
    public firstName: string = "";

    @MobX.observable
    @IsNotEmpty({ message: "Last name is required" })
    @MaxLength(50, { message: "Maximum last name length is 50 characters" })
    public lastName: string = "";

    @MobX.observable
    @IsNotEmpty({ message: "Position is required" })
    @MaxLength(50, { message: "Maximum position length is 50 characters" })
    public position: string = "";

    @MobX.observable
    //@IsNotEmpty({ message: "Email is required if phone is empty" })
    //@IsEmail({ message: "Not a valid email address" })
    @MaxLength(256, { message: "Maximum email length is 256 characters" })
    public email: string | null = "";

    @MobX.observable
    //@IsNotEmpty({ message: "Phone is required if email is empty" })
    @MaxLength(50, { message: "Maximum phone length is 50 characters" })
    //@MinLength(6, { message: "Minimum phone length is 6 characters" })
    public phone: string | null = "";

    @MobX.observable
    //@IsNotEmpty({ message: "Phone is required if email is empty" })
    @MaxLength(50, { message: "Maximum phone length is 50 characters" })
    //@MinLength(6, { message: "Minimum phone length is 6 characters" })
    public phone2: string | null = "";

    @MobX.observable public rowVersion: string = "";
    @MobX.observable public isDeleted: boolean = false;
    @MobX.observable createdDate: string = "";
    @MobX.observable createdBy: string = "";
    @MobX.observable clientId: string = "";
    @MobX.observable sourceId: string = "";

    @MobX.observable generatedTempId: string = generateID();

    //fromDto is required but you can leave it blank
    @MobX.action
    fromDto(model: ContactModelDTO): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
        this.sourceId = this.clientId;
    }

    @MobX.computed
    public get displayName(): string {
        return this.firstName + " " + this.lastName;
    }

    //toDto is required but you can leave it blank
    toDto(model: ContactModelDTO): void {
        model = this.getDto();
    }

    public getDto(): ContactModelDTO {
        const retVal: ContactModelDTO = {
            firstName: this.firstName,
            lastName: this.lastName,
            position: this.position,
            email: this.email,
            phone: this.phone,
            phone2: this.phone2,
            rowVersion: this.rowVersion,
            isDeleted: this.isDeleted,
            createdDate: this.createdDate,
            createdBy: this.createdBy,
            id: this.id,
            clientId: this.clientId,
        };
        return retVal;
    }

    public isEmailValid = () => {
        let retVal: boolean = true;

        return retVal;
    };

    public isPhoneValid = () => {
        let retVal: boolean = true;

        return retVal;
    };
}

export type ContactModelDTO = {
    firstName: string;
    lastName: string;
    position: string;
    email: string | null;
    phone: string | null;
    phone2: string | null;
    rowVersion: string;
    isDeleted: boolean;
    createdDate: string;
    createdBy: string;
    id: string;
    clientId: string;
};
