import { LineGraphBox } from "./LineGraph.style";
//import * as d3 from "d3";
//import { bisector /* , extent, max, min */ } from "d3-array";
import { max, extent, bisector } from "d3-array";
//@ts-ignore no TS files yet
import { AxisLeft } from "@visx/axis";
//@ts-ignore no TS files yet
import { localPoint } from "@visx/event";
//@ts-ignore no TS files yet
//import { LinearGradient } from '@visx/gradient';
//@ts-ignore no TS files yet
//import { Grid } from '@visx/grid';
//@ts-ignore no TS files yet
import { Group } from "@visx/group";
//@ts-ignore no TS files yet
import { Point } from "@visx/point";
//@ts-ignore no TS files yet
import { scaleUtc, scaleLinear, TimeDomain, coerceNumber, scaleTime } from "@visx/scale";
//@ts-ignore no TS files yet
import { Line, LinePath, Bar } from "@visx/shape";
//@ts-ignore no TS files yet
//import { Text } from '@visx/text';
//@ts-ignore no TS files yet
//import { withTooltip, TooltipWithBounds, useTooltipInPortal } from '@visx/tooltip';
import { useTooltip, useTooltipInPortal, defaultStyles } from "@visx/tooltip";

//@ts-ignore no TS files yet
import { GlyphDot } from "@visx/glyph";

import { SensorValueDTO } from "Custom/Models/Domain/Installations/SensorValue";

import { BottomAxis } from "./BottomAxis";

import React, { useCallback, useMemo } from "react";
import { timeFormat } from "d3";
import * as d3 from "d3";
import { generateID, sortByStringDates } from "Core/Utils/Utils";
import moment from "moment";
import "moment-timezone";
import { DrainageType, InstallationStatusDataDTO, InstallationWorkingData } from "Custom/Models/Domain";
import { DeviceAlertActionModelDTO, DeviceConditionItemModelDTO, DeviceConditionSetModelDTO } from "Custom/Views/Installations/TabConfigurationModel";
import { generateStatusWorkingData } from "Custom/Utils/installation";
import { GraphLegend, GraphLegendItem } from ".";
import { DEFAULTCELLCOLOUR, FLOODCOLOUR } from "Custom/Globals/Globals";
interface LineGraphProps {
    graphHeight: number;
    graphWidth: number;
    marginTop: number;
    maxWidth: string;
    paddingTop: number;
    readings: SensorValueDTO[];
    deviceStatusData?: InstallationStatusDataDTO;
    conditionSet?: DeviceConditionSetModelDTO;
    showDataPoints: boolean;
    showTooltips: boolean;
    pinToZero: boolean;
    showStatusLines: boolean; // H, FB, p1, p2
    alertAction: DeviceAlertActionModelDTO | undefined;
    showAlertLines: boolean;
    showGraphLegend?: boolean;
    backgroundColour?: string;
    numXTicks?: number;
}

export type GraphNode = {
    date: Date;
    value: number;
};

export function LineGraph(props: LineGraphProps) {
    const {
        alertAction,
        backgroundColour,
        showGraphLegend,
        graphHeight,
        graphWidth,
        marginTop,
        maxWidth,
        paddingTop,
        deviceStatusData,
        readings,
        showDataPoints,
        pinToZero,
        showStatusLines,
        showAlertLines,
        showTooltips,
        numXTicks,
    } = props;

    const DEFAULTOUTSIDECOLOUR: string = DEFAULTCELLCOLOUR;
    let installHeight: number = 0;
    let workingData: InstallationWorkingData | undefined = undefined;

    let showOutOfRange: boolean = true;

    if (deviceStatusData !== null && deviceStatusData !== undefined) {
        installHeight = deviceStatusData.install_Height;
        workingData = generateStatusWorkingData(deviceStatusData);
    }

    let opacityLevel: number = 1;
    // let lineColour: string = "#ffffff";
    let lineColour: string = "#000000";
    let lineWidth: string = "2";

    if (showAlertLines === true || showStatusLines === true) {
        // opacityLevel = 0.3;
        opacityLevel = 1;
    }

    /*     const utcToLocal = (utcdateTime: string, tz: string) => {
        let zone = moment.tz(tz).format("Z"); // Actual zone value e:g +5:30
        let zoneValue = zone.replace(/[^0-9: ]/g, ""); // Zone value without + - chars
        let operator = zone && zone.split("") && zone.split("")[0] === "-" ? "-" : "+"; // operator for addition subtraction
        let localDateTime;
        let hours = zoneValue.split(":")[0];
        let minutes = zoneValue.split(":")[1];
        if (operator === "-") {
            localDateTime = moment(utcdateTime).subtract(hours, "hours").subtract(minutes, "minutes").format("YYYY-MM-DD HH:mm:ss");
        } else if (operator) {
            localDateTime = moment(utcdateTime).add(hours, "hours").add(minutes, "minutes").format("YYYY-MM-DD HH:mm:ss");
        } else {
            localDateTime = "Invalid Timezone Operator";
        }
        return localDateTime;
    }; */

    const axisColor: string = "#ffffff";

    const graphBottomTickLabelProps: any = (val: any, i: any) => ({
        dy: "0.25em",
        textAnchor: "middle",
        fontFamily: "Lato,Verdana,Helvetica,Arial,sans-serif",
        fontSize: 8,
        fill: axisColor,
    });

    const graphLeftTickLabelProps: any = (val: any, i: any) => ({
        dx: "-1.5em",
        dy: "0.25em",
        textAnchor: "middle",
        fontFamily: "Lato,Verdana,Helvetica,Arial,sans-serif",
        fontSize: 10,
        fill: axisColor,
    });

    let dataset: GraphNode[] = [];

    let sortedReadings: SensorValueDTO[] = readings.slice(0).sort((a: SensorValueDTO, b: SensorValueDTO) => {
        return sortByStringDates(a.updated, b.updated);
    });

    for (let i: number = 0; i < sortedReadings.length; i++) {
        let value: number = sortedReadings[i].calculatedValue;

        if (value !== 0) {
            //#4319 Change data so negative values calculated against the offset values are zeroed
            const temp: number = installHeight - value;
            value = temp >= 0 ? temp : 0;
        }
        // const converted: string = utcToLocal(sortedReadings[i].updated, "Europe/London");
        // TODO Need to sort out the getting.
        const converted: string = sortedReadings[i].updated;
        dataset.push({
            date: new Date(converted),
            value: value,
        });
    }

    // accessors
    const xSelector = (d: GraphNode) => d.date;
    const ySelector = (d: GraphNode) => d.value;

    const marginLeft: number = 65;
    //const marginBottom: number = 75;
    const marginBottom: number = 45;

    const xMax = graphWidth - marginLeft;
    const yMax = graphHeight - marginBottom;

    const tDomain: TimeDomain = dataset.map((a) => a.date);

    const getMinMax = (vals: (number | { valueOf(): number })[]) => {
        const numericVals = vals.map(coerceNumber);
        return [Math.min(...numericVals), Math.max(...numericVals)];
    };

    const data: number[] = dataset.map((a) => a.value);

    //let showFlood: boolean = false;
    if (workingData !== undefined) {
        data.push(workingData.working_Height + 10);
        //showFlood = true;
    }

    if (showStatusLines && workingData !== undefined) {
        //data.push(workingData.working_Height + 5);
        data.push(workingData.freeboard_Height);

        if (workingData.drainageType > DrainageType.Gravity) {
            data.push(workingData.p1);

            if (workingData.drainageType == DrainageType.DualSiphonic) {
                data.push(workingData.p2);
            }
        }
    }

    if (showAlertLines && alertAction !== undefined) {
        for (let i: number = 0; i < alertAction.items.length; i++) {
            data.push(alertAction.items[i].calculatedValue);
        }
    }

    let sortedConditionItems: DeviceConditionItemModelDTO[] | undefined = undefined;

    const showBands: boolean =
        props.conditionSet !== null &&
        props.conditionSet !== undefined &&
        props.conditionSet.items !== null &&
        props.conditionSet.items !== undefined &&
        props.conditionSet.items.length > 0;

    if (showBands === true) {
        let retVal = props.conditionSet!.items.slice().sort((a: DeviceConditionItemModelDTO, b: DeviceConditionItemModelDTO) => {
            if (a === undefined && b === undefined) {
                return 0;
            }

            if (a === undefined) {
                return -1;
            }

            if (b === undefined) {
                return 1;
            }

            return a.calculatedValue < b.calculatedValue ? -1 : 1;
        });

        for (let i: number = 0; i < retVal.length; i++) {
            if (retVal[i].calculatedValue <= 0) {
                // if the conditionset value is 0 or negative, we don't need the grey section
                showOutOfRange = false;
            }

            data.push(retVal[i].calculatedValue);
        }

        sortedConditionItems = retVal;
    }

    if (deviceStatusData !== null && deviceStatusData !== undefined && workingData !== null && workingData !== undefined) {
        // We need to add the flood point in

        let flood: DeviceConditionItemModelDTO = {
            id: null,
            rowVersion: null,
            isDeleted: false,
            createdBy: null,
            createdDate: null,

            name: "Flood",
            type: 0,
            value: 0,
            units: 0,
            deviceConditionSetId: null,
            commandIndex: 5,
            statusColour: FLOODCOLOUR,
            statusTextColour: "#ffffff",
            calculatedValue: workingData.working_Height,
        };
        sortedConditionItems?.push(flood);
    }

    let maxValue = Math.max.apply(null, data);
    let minValue = Math.min.apply(null, data);

    const range: number = (Math.abs(maxValue - minValue) * 0.05) / 2;
    maxValue += range;
    minValue -= range;

    if (pinToZero === true) {
        // if (minValue < 0) {
        minValue = 0;
        // }
    }

    // scales -  might need scaleUtc later?
    const dateScale = useMemo(
        () =>
            scaleTime({
                clamp: true,
                domain: getMinMax(tDomain),
                nice: true,
                range: [0, xMax],
            }),
        [xMax, tDomain],
    );

    const dataValueScale = useMemo(
        () =>
            scaleLinear({
                domain: [minValue, maxValue],
                range: [yMax, 0],
            }),
        [yMax, minValue, maxValue],
    );

    const series = [dataset];
    let gridRowCount: number = 5;

    const showLegendatTop: boolean = true;
    const topOfGraph = 5;

    /* Tool Tip stuff */
    const { tooltipData, tooltipLeft, tooltipTop, tooltipOpen, showTooltip, hideTooltip } = useTooltip();

    const { containerRef, TooltipInPortal } = useTooltipInPortal({
        // use TooltipWithBounds
        detectBounds: true,
        // when tooltip containers are scrolled, this will correctly update the Tooltip position
        scroll: true,
    });

    // accessors
    const getDate = (d: GraphNode) => d.date;
    const getValue = (d: GraphNode) => d.value;
    const bisectDate = bisector<GraphNode, Date>((d) => d.date).left;

    const handleTooltip = useCallback(
        (event: React.TouchEvent<SVGRectElement> | React.MouseEvent<SVGRectElement>) => {
            let { x } = localPoint(event) || { x: 0 };

            const x0: Date = dateScale.invert(x - marginLeft);
            const index: number = bisectDate(dataset, x0, 1);
            const d0: GraphNode = dataset[index - 1];
            const d1: GraphNode = dataset[index];
            let d: GraphNode = d0;
            if (d1 && getDate(d1)) {
                d = x0.valueOf() - getDate(d0).valueOf() > getDate(d1).valueOf() - x0.valueOf() ? d1 : d0;
            }
            const value: number = dataValueScale(getValue(d));

            showTooltip({
                tooltipData: d,
                tooltipLeft: x,
                tooltipTop: value + marginTop + topOfGraph,
            });
        },
        [showTooltip, dataValueScale, dateScale, dataset, marginTop, topOfGraph, marginLeft],
    );
    const formatDate = timeFormat("%d/%m/%Y %H:%M ");

    let longestword: number = 0;
    if (alertAction !== null && alertAction !== undefined) {
        for (let i: number = 0; i < alertAction.items.length; i++) {
            let temp: string = alertAction.items[i].name + " (" + alertAction.items[i].calculatedValue.toFixed(0) + "mm)";
            if (temp.length > longestword) {
                longestword = temp.length;
            }
        }
    }

    const alertTextOffset: number = longestword * 4.25 + 2;
    const showLegend: boolean = showGraphLegend === undefined || showGraphLegend;
    const fillColour: string = backgroundColour === undefined ? "#565758" : backgroundColour;

    const tooltipStyles = {
        ...defaultStyles,
        minWidth: 60,
        backgroundColor: fillColour,
        color: "ffffff",
    };

    return (
        <>
            {showLegend === true && (
                <GraphLegend paddingLeft={marginLeft.toString() + "px"}>
                    {showOutOfRange === true && (
                        <GraphLegendItem backgroundColor={DEFAULTOUTSIDECOLOUR} textColor="#000000" opacity={opacityLevel}>
                            <div className="band-box"></div>
                            <div className="band-text">Base Condition (0 mm)</div>
                        </GraphLegendItem>
                    )}

                    {sortedConditionItems !== null &&
                        sortedConditionItems !== undefined &&
                        sortedConditionItems!.map((condition) => {
                            return (
                                <GraphLegendItem backgroundColor={condition.statusColour} textColor={condition.statusTextColour} opacity={opacityLevel}>
                                    <div className="band-box"></div>
                                    <div className="band-text">
                                        {condition.name} ({condition.calculatedValue.toFixed(0)} mm)
                                    </div>
                                </GraphLegendItem>
                            );
                        })}
                </GraphLegend>
            )}
            <LineGraphBox maxWidth={maxWidth} paddingTop={"0"} id="linegraph">
                <svg xmlns="http://www.w3.org/2000/svg" height={graphHeight} width={graphWidth + 20} ref={containerRef}>
                    <rect fill={fillColour} height={graphHeight} rx={14} width={graphWidth} x={0} y={0} />
                    {xMax > 8 &&
                        series.map((d, i) => {
                            return (
                                <>
                                    <Group key={generateID()} left={0} top={topOfGraph} z-index={0}>
                                        <AxisLeft
                                            key={generateID()}
                                            label={"Height (mm)"}
                                            labelClassName={"graphaxistext"}
                                            labelProps={{
                                                dx: "-0.5em",
                                                dy: "0.25em",
                                                textAnchor: "middle",
                                                fontSize: 10,
                                                fill: axisColor,
                                            }}
                                            left={marginLeft}
                                            numTicks={gridRowCount}
                                            scale={dataValueScale}
                                            stroke={axisColor}
                                            tickStroke={axisColor}
                                            tickFormat={(d: any) => d.toFixed(0)}
                                            tickLabelProps={graphLeftTickLabelProps}
                                            top={0}
                                        />
                                        <BottomAxis
                                            key={generateID()}
                                            label={"Time"}
                                            labelClassName={"graphaxistext"}
                                            left={marginLeft}
                                            marginLeft={marginLeft}
                                            showLegend={false}
                                            stroke={axisColor}
                                            tickLabelProps={graphBottomTickLabelProps}
                                            tickTextFill={axisColor}
                                            top={yMax}
                                            width={graphWidth}
                                            xScale={dateScale}
                                            showTime={true}
                                            xLegendOffset={35}
                                            numTicks={numXTicks}
                                        />
                                    </Group>
                                    {!showBands && (
                                        <Group top={topOfGraph} key={generateID()}>
                                            <rect key={generateID()} fill="#FFFFFF" fillOpacity="0.2" height={yMax} width={graphWidth - marginLeft} x={marginLeft} y={0} />
                                        </Group>
                                    )}

                                    {showBands && (
                                        <Group top={topOfGraph} key={generateID()}>
                                            {/* We know conditionSet is not undefined else showBands would be false */}
                                            <rect
                                                key={generateID()}
                                                fillOpacity={opacityLevel}
                                                fill={DEFAULTOUTSIDECOLOUR}
                                                height={yMax - dataValueScale(sortedConditionItems![0].calculatedValue)}
                                                width={graphWidth - marginLeft}
                                                x={marginLeft}
                                                y={dataValueScale(sortedConditionItems![0].calculatedValue)}
                                            />
                                            <rect
                                                key={generateID()}
                                                fill={sortedConditionItems![0].statusColour}
                                                fillOpacity={opacityLevel}
                                                height={
                                                    dataValueScale(sortedConditionItems![0].calculatedValue) -
                                                    (sortedConditionItems!.length > 1 ? dataValueScale(sortedConditionItems![1].calculatedValue) : 0)
                                                }
                                                width={graphWidth - marginLeft}
                                                x={marginLeft}
                                                y={sortedConditionItems!.length > 1 ? dataValueScale(sortedConditionItems![1].calculatedValue) : 0}
                                            />
                                            {sortedConditionItems!.length > 1 && (
                                                <rect
                                                    key={generateID()}
                                                    fill={sortedConditionItems![1].statusColour}
                                                    fillOpacity={opacityLevel}
                                                    height={
                                                        dataValueScale(sortedConditionItems![1].calculatedValue) -
                                                        (sortedConditionItems!.length > 2 ? dataValueScale(sortedConditionItems![2].calculatedValue) : 0)
                                                    }
                                                    width={graphWidth - marginLeft}
                                                    x={marginLeft}
                                                    y={sortedConditionItems!.length > 2 ? dataValueScale(sortedConditionItems![2].calculatedValue) : 0}
                                                />
                                            )}
                                            {sortedConditionItems!.length > 2 && (
                                                <rect
                                                    key={generateID()}
                                                    fill={sortedConditionItems![2].statusColour}
                                                    fillOpacity={opacityLevel}
                                                    height={
                                                        dataValueScale(sortedConditionItems![2].calculatedValue) -
                                                        (sortedConditionItems!.length > 3 ? dataValueScale(sortedConditionItems![3].calculatedValue) : 0)
                                                    }
                                                    width={graphWidth - marginLeft}
                                                    x={marginLeft}
                                                    y={sortedConditionItems!.length > 3 ? dataValueScale(sortedConditionItems![3].calculatedValue) : 0}
                                                />
                                            )}
                                            {sortedConditionItems!.length > 3 && ( //There can only be 4 max but we might have added flood
                                                <rect
                                                    key={generateID()}
                                                    fill={sortedConditionItems![3].statusColour}
                                                    fillOpacity={opacityLevel}
                                                    height={
                                                        dataValueScale(sortedConditionItems![3].calculatedValue) -
                                                        (sortedConditionItems!.length > 4 ? dataValueScale(sortedConditionItems![4].calculatedValue) : 0)
                                                    }
                                                    width={graphWidth - marginLeft}
                                                    x={marginLeft}
                                                    y={sortedConditionItems!.length > 4 ? dataValueScale(sortedConditionItems![4].calculatedValue) : 0}
                                                />
                                            )}
                                            {sortedConditionItems!.length > 4 && ( //There can only be 4 max so set it to y of 0
                                                <rect
                                                    key={generateID()}
                                                    fill={sortedConditionItems![4].statusColour}
                                                    fillOpacity={opacityLevel}
                                                    height={dataValueScale(sortedConditionItems![4].calculatedValue)}
                                                    width={graphWidth - marginLeft}
                                                    x={marginLeft}
                                                    y={0}
                                                />
                                            )}
                                        </Group>
                                    )}

                                    {showStatusLines && workingData !== undefined && (
                                        <Group top={topOfGraph} key={generateID()}>
                                            <Line
                                                key={generateID()}
                                                className="level-line floodWarningLevel"
                                                from={new Point({ x: marginLeft, y: dataValueScale(workingData.working_Height) })}
                                                stroke={lineColour}
                                                strokeWidth={lineWidth}
                                                to={new Point({ x: graphWidth, y: dataValueScale(workingData.working_Height) })}
                                            />
                                            <text
                                                fill={lineColour}
                                                fontSize="8"
                                                textAnchor="middle"
                                                transform={`translate(${marginLeft + 20} , ${dataValueScale(workingData.working_Height + 1)})`}
                                            >
                                                {"H (" + workingData.working_Height.toFixed(0) + "mm)"}
                                            </text>
                                            <Line
                                                key={generateID()}
                                                className="level-line floodWarningLevel"
                                                from={new Point({ x: marginLeft, y: dataValueScale(workingData.freeboard_Height) })}
                                                stroke={lineColour}
                                                strokeWidth={lineWidth}
                                                to={new Point({ x: graphWidth, y: dataValueScale(workingData.freeboard_Height) })}
                                            />
                                            <text
                                                fill={lineColour}
                                                fontSize="8"
                                                textAnchor="middle"
                                                transform={`translate(${marginLeft + 21} , ${dataValueScale(workingData.freeboard_Height + 1)})`}
                                            >
                                                {"FB (" + workingData.freeboard_Height.toFixed(0) + "mm)"}
                                            </text>

                                            {workingData.drainageType > DrainageType.Gravity && (
                                                <>
                                                    <Line
                                                        key={generateID()}
                                                        className="level-line floodWarningLevel"
                                                        from={new Point({ x: marginLeft, y: dataValueScale(workingData.p1) })}
                                                        stroke={lineColour}
                                                        strokeWidth={lineWidth}
                                                        to={new Point({ x: graphWidth, y: dataValueScale(workingData.p1) })}
                                                    />
                                                    <text
                                                        fill={lineColour}
                                                        fontSize="8"
                                                        textAnchor="middle"
                                                        transform={`translate(${marginLeft + 20} , ${dataValueScale(workingData.p1 + 1)})`}
                                                    >
                                                        {"P1 (" + workingData.p1.toFixed(0) + "mm)"}
                                                    </text>
                                                    {workingData.drainageType === DrainageType.DualSiphonic && (
                                                        <>
                                                            <Line
                                                                key={generateID()}
                                                                className="level-line floodWarningLevel"
                                                                from={new Point({ x: marginLeft, y: dataValueScale(workingData.p2) })}
                                                                stroke={lineColour}
                                                                strokeWidth={lineWidth}
                                                                to={new Point({ x: graphWidth, y: dataValueScale(workingData.p2) })}
                                                            />
                                                            <text
                                                                fill={lineColour}
                                                                fontSize="8"
                                                                textAnchor="middle"
                                                                transform={`translate(${marginLeft + 20} , ${dataValueScale(workingData.p2 + 1)})`}
                                                            >
                                                                {"P2 (" + workingData.p2.toFixed(0) + "mm)"}
                                                            </text>
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </Group>
                                    )}

                                    {showAlertLines && alertAction !== undefined && (
                                        <Group top={topOfGraph} key={generateID()}>
                                            {alertAction.items.map((item, index) => {
                                                return (
                                                    <>
                                                        <Line
                                                            key={generateID()}
                                                            className="level-line floodWarningLevel"
                                                            from={new Point({ x: marginLeft, y: dataValueScale(item.calculatedValue) })}
                                                            stroke={lineColour}
                                                            strokeWidth={lineWidth}
                                                            strokeDasharray={"3 3"}
                                                            to={new Point({ x: graphWidth, y: dataValueScale(item.calculatedValue) })}
                                                        />
                                                        <text
                                                            fill={lineColour}
                                                            fontSize="8"
                                                            textAnchor="left"
                                                            transform={`translate(${graphWidth - alertTextOffset} , ${dataValueScale(item.calculatedValue + 1)})`}
                                                        >
                                                            {item.name + " (" + item.calculatedValue.toFixed(0) + "mm)"}
                                                        </text>
                                                    </>
                                                );
                                            })}
                                        </Group>
                                    )}

                                    <Group key={generateID()} top={(i * yMax + marginTop) / 2 + topOfGraph}>
                                        <LinePath
                                            key={generateID()}
                                            data={d}
                                            stroke={"#ffffff"}
                                            strokeWidth={1}
                                            x={(d: GraphNode) => dateScale(xSelector(d)) + marginLeft}
                                            y={(d: GraphNode) => dataValueScale(ySelector(d))}
                                        />
                                        <Bar
                                            x={marginLeft}
                                            y={(i * yMax + marginTop) / 2}
                                            width={graphWidth - marginLeft}
                                            height={graphHeight - marginBottom}
                                            fill="transparent"
                                            rx={0}
                                            style={{ borderRadius: "0px" }}
                                            onTouchStart={handleTooltip}
                                            onTouchMove={handleTooltip}
                                            onMouseMove={handleTooltip}
                                            onMouseLeave={() => hideTooltip()}
                                        />
                                    </Group>
                                    {showDataPoints &&
                                        d.map((d, i) => {
                                            const cx = dateScale(xSelector(d)) + marginLeft;
                                            const cy = dataValueScale(ySelector(d)) + marginTop / 2 + topOfGraph;
                                            return (
                                                <g key={generateID()}>
                                                    <GlyphDot cx={cx} cy={cy} fill={"transparent"} r={4} stroke={"#1F78B4"} strokeWidth={2} />
                                                    <GlyphDot cx={cx} cy={cy} fill={"#ffffff"} r={2} />
                                                </g>
                                            );
                                        })}
                                    {showTooltips === true && tooltipOpen && tooltipData && tooltipTop && tooltipLeft && (
                                        <TooltipInPortal
                                            key={generateID()} // update tooltip bounds each render
                                            top={tooltipTop}
                                            left={tooltipLeft}
                                            style={tooltipStyles}
                                        >
                                            <div style={{ color: "#ffffff" }}>
                                                <div>
                                                    <strong>Time: </strong>
                                                    {formatDate(getDate(tooltipData! as GraphNode))}
                                                </div>
                                                <div>
                                                    <strong>Height (mm) </strong>
                                                    {getValue(tooltipData! as GraphNode)}
                                                </div>
                                            </div>
                                        </TooltipInPortal>
                                    )}
                                    {showTooltips === true && tooltipData && tooltipTop && tooltipLeft && (
                                        <g>
                                            <circle
                                                cx={tooltipLeft}
                                                cy={tooltipTop}
                                                r={4}
                                                fill="#1F78B4"
                                                fillOpacity={0.1}
                                                stroke="#1F78B4"
                                                strokeOpacity={0.1}
                                                strokeWidth={2}
                                                pointerEvents="none"
                                            />
                                            <circle cx={tooltipLeft} cy={tooltipTop} r={4} fill={"#1F78B4"} stroke="white" strokeWidth={2} pointerEvents="none" />
                                        </g>
                                    )}
                                </>
                            );
                        })}
                </svg>
            </LineGraphBox>
        </>
    );
}
