import styled from "styled-components";
import { BaseModalDialogPaper } from "../Modal/BaseModal.styles";

export const NotFoundModalDialogPaper = styled(BaseModalDialogPaper)`
    min-width: 420px;

    .MuiDialogContent-root {
        h2 {
            font-weight: normal;
        }

        p {
            padding-top: 10px;
            padding-bottom: 40px;
            font-size: 14px;
        }
    }

    .MuiDialogActions-root > button {
        width: auto;
    }
`;
