import React, { useEffect, useState } from "react";
import * as Mui from "@material-ui/core";
import { useObserver } from "mobx-react-lite";
import { useRouter } from "Core/Utils";
import { ResetSearchIcon } from "Custom/Components/Table/ResetSearchIcon";
import { ClientListViewModel } from "Custom/Views/Clients/ClientListViewModel";

import AddLogo from "Custom/Content/plusIcon.svg";
import { RoofcareTable, RoofcareTableWrapper } from "Custom/Components/Table/RoofcareTable";
import { Container } from "Custom/StylesAppSpecific/AppStyling";
import { AddItemText } from "Custom/StylesAppSpecific/Controls/AddEditWrapper";
import { BoxAdd, BoxAddWrapper } from "Custom/StylesAppSpecific/Controls/BoxAddWrapper";
import { AppUrls } from "Custom/Globals";
import { createStyles, makeStyles } from "@material-ui/core";

import { formatDelete, formatEdit } from "Custom/Components/AddEditControls";

import { DeleteModal } from "Custom/Components/Modal/DeleteModal";
import { ProjectButton } from "../CustomComponents";

import * as Defaults from "Custom/StylesAppSpecific/TableOptions";

const useModalStyles = makeStyles((theme: Mui.Theme) =>
    createStyles({
        paper: {
            position: "relative",
            top: "5%",
            width: "90%",
            height: "90%",
            marginLeft: "auto",
            marginRight: "auto",
            backgroundColor: theme.palette.background.paper,
            border: "1px solid #000",
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
            zIndex: 9000,
        },
    }),
);

export const ClientListPage: React.FC = () => {
    const classes = useModalStyles();
    const [viewModel] = useState(() => new ClientListViewModel());
    const theme = Mui.useTheme();
    const tableOptions = Defaults.GetRoofcareTableOptions() as any;
    const { history } = useRouter();

    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [deleteOpen, setDeleteOpen] = React.useState(false);
    const [selectedClient, selectClient] = React.useState("");

    const handleDeleteOpen = () => {
        setDeleteOpen(true);
    };

    const handleDeleteClose = () => {
        setDeleteOpen(false);
        selectClient("");
    };

    const handleDeleteUser = () => {
        viewModel.deleteClient(selectedClient).then(() => {
            setDeleteOpen(false);
            selectClient("");
        });
    };

    const deleteClientClick = (id: string) => {
        selectClient(id);
        handleDeleteOpen();
    };

    useEffect(() => {
        const apiResult = viewModel.loadClientListAsync();
        return () => {
            // Clean up after yourself
        };
    }, []);

    const handleNewClick = () => {
        history.push(AppUrls.Client.Main.Client.ClientAddEditDetail.replace(":clientId", "0"));
    };

    const editClientClick = (id: string) => {
        history.push(AppUrls.Client.Main.Client.ClientAddEditDetail.replace(":clientId", id));
    };

    const handleRowClick = (rowData: any) => {
        history.push(AppUrls.Client.Main.Client.ClientsDetail.replace(":clientId", rowData.id));
    };

    return useObserver(() => (
        <Container>
            {
                <>
                    <DeleteModal
                        open={deleteOpen}
                        onClose={handleDeleteClose}
                        onDelete={handleDeleteUser}
                        title="Delete end user?"
                        text="Are you sure you want to delete this end user?"
                    />
                    {/*                     <Modal open={open} onClose={handleClose} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
                        <div className={classes.paper}>
                            <h2 id="client-modal-title">Add / Edit Client Modal</h2>
                            <p id="client-modal-description">Need Add Client details here. Not yet defined.</p>
                        </div>
                    </Modal> */}

                    <Mui.Box width="100%">
                        <BoxAddWrapper>
                            <ProjectButton icon={() => <span>&#43;</span>} text="Add new end user" onClick={() => handleNewClick()} />
                            {/* <BoxAdd onClick={() => handleNewClick()}>
                                <img src={AddLogo} alt="Add new end user" />
                                <AddItemText> Add new end user</AddItemText>
                            </BoxAdd> */}
                        </BoxAddWrapper>
                    </Mui.Box>

                    <RoofcareTable
                        columns={[
                            { title: "End User", field: "clientName", sorting: true, filtering: true },
                            { title: "Total Project", field: "projectCount", sorting: true, filtering: false },
                            { title: "Lead", field: "leadCount", sorting: true, filtering: false },
                            { title: "Active", field: "activeCount", sorting: true, filtering: false },
                            { title: "Won", field: "wonCount", sorting: true, filtering: false },
                            { title: "Lost", field: "lostCount", sorting: true, filtering: false },
                            { title: "Total Properties", field: "propertyCount", sorting: true, filtering: false },
                            {
                                title: "",
                                field: "id",
                                sorting: false,
                                filtering: false,
                                render: (rowData: any) => {
                                    if (rowData !== null && rowData !== undefined) {
                                        return formatEdit(rowData.id, "end user", editClientClick);
                                    }
                                },
                            },
                            {
                                title: "",
                                field: "id",
                                sorting: false,
                                filtering: false,
                                render: (rowData: any) => {
                                    if (rowData !== null && rowData !== undefined) {
                                        return formatDelete(rowData.id, "end user", deleteClientClick);
                                    }
                                },
                            },
                        ]}
                        options={{
                            thirdSortClick: false,
                            cellStyle: {
                                fontSize: 10,
                            },
                            headerStyle: {
                                ...tableOptions.headerStyle,
                            },
                            rowStyle: {
                                ...tableOptions.rowStyle,
                            },
                            /* filterCellStyle: {
                                ...tableOptions.filterCellStyle,
                            }, */
                            pageSize: 50,
                            pageSizeOptions: [10, 20, 50, 100],
                            emptyRowsWhenPaging: false,
                            searchFieldAlignment: "left",
                            searchFieldStyle: {
                                border: "1px solid #333333",
                                borderRadius: "5px",
                                width: "400px",
                            },
                        }}
                        data={viewModel.getAllowedClients}
                        title=""
                        searchText="Search an End User"
                        icons={{
                            ResetSearch: () => (
                                <span style={{ marginRight: "-11px" }}>
                                    <ResetSearchIcon />
                                </span>
                            ),
                        }}
                        style={{
                            backgroundColor: "#3B3C3B",
                            padding: 0,
                        }}
                        onRowClick={(_: any, rowData: any) => handleRowClick(rowData)}
                        components={{ Container: (props: any) => <Mui.Paper {...props} elevation={0} /> }}
                    />
                </>
            }
        </Container>
    ));
};
