import React from "react";
import { Header } from "Custom/Views/Header";
import { CSSGrid, Cell } from "Core/Components/Styles";

//EN: use empty generic type to get default react props
export const LoginLayout: React.FC<{}> = (props) => {
    if (!props) return <>Props undefined</>;
    return (
        <>
            <CSSGrid columns={"1fr"} rows={"0px"} gap={"0px"} areas={["header", "content"]} style={{ height: "100vh" }}>
                <Cell area={"content"} id={"content"} className="overall-content">
                    <div>{props.children}</div>
                </Cell>
            </CSSGrid>
            {/* <CSSGrid columns={"1fr"} rows={"0px"} gap={"0px"} areas={["header", "content"]}>
                <Cell area={"header"} height={87}>
                    <Header />
                </Cell>
                <Cell marginTop={"70px"} area={"content"} id={"content"}>
                    {props && <div>{props.children}</div>}
                </Cell>
            </CSSGrid> */}
        </>
    );
};
