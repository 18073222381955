import { Grid, Paper, Typography } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import { useRouter } from "Core/Utils";
import { AlertActionAddEditDetailViewModel } from "./AlertActionAddEditDetailViewModel";
import { BackBox, BackToList, BackToListText } from "Custom/StylesAppSpecific/Controls/BackToList";
import { AppUrls } from "Custom/Globals";
import { AlertActionAddEditDetailModel } from "./AlertActionAddEditDetailModel";
import { RoofcareInput } from "Custom/StylesAppSpecific/Controls/RoofcareInput";
import {
    AddNewBox,
    AdminDetailContainer,
    BackToListWrapper,
    ButtonSectionBox,
    DetailWrapper,
    ItemSectionBox,
    ItemSectionTable,
    NameSectionBox,
} from "Custom/Views/Admin/Condition/Condition.style";
import { SuccessBtn } from "Custom/StylesAppSpecific/Controls/SuccessBtn";
import { CancelBtn } from "Custom/StylesAppSpecific/Controls/CancelBtn";
import { BoxAdd } from "Custom/StylesAppSpecific/Controls/BoxAddWrapper";
import { AddItemText } from "Custom/StylesAppSpecific/Controls/AddEditWrapper";
import { AlertActionItem } from "./AlertActionItem";
import { generateID } from "Core/Utils/Utils";
import { DeleteModal } from "Custom/Components/Modal/DeleteModal";
import { Loader } from "Core/Components";
import { useLoadCall } from "Custom/Utils/useLoadCall";
import { isGuid } from "Custom/Utils/isGuid";
import { useObserver } from "mobx-react-lite";
import { AlertActionItemViewModel } from "./AlertActionItemViewModel";
import { ApiResult } from "Core/Models";
import { UnitStatusBox } from "Custom/Components";
import { ResetSearchIcon } from "Custom/Components/Table/ResetSearchIcon";
import { RoofcareTable, RoofcareTableWrapperAdmin } from "Custom/Components/Table/RoofcareTable";
import * as Defaults from "Custom/StylesAppSpecific/TableOptions";
import { UnitListItemModel, UnitListItemModelDTO } from "Custom/Views/Installations/InstallationList/UnitListItemModel";

export const AlertActionAddEditDetail: React.FunctionComponent = () => {
    const [maxAlerts] = useState<number>(6);
    const [viewModel] = useState(() => new AlertActionAddEditDetailViewModel());
    const router = useRouter<{ alertAction?: string }>();
    const { history, match } = useRouter();
    let { id } = match.params as any;

    /*     const [areYouSureOpen, setAreYouSureOpen] = React.useState(false); */
    const [canSave, setCanSave] = useState(false);

    const tableOptions = Defaults.GetRoofcareTableOptions() as any;
    /*     const handleAreYouSureOpen = () => {
        setAreYouSureOpen(true);
    };

    const handleAreYouSureClose = () => {
        setAreYouSureOpen(false);
    }; */

    const [localAlertActions, setLocalAlertActions] = useState<AlertActionItemViewModel[]>([]);
    //useEffect below only gets run on initial render
    useEffect(() => {
        // *optional* passing the router into the viewmodel so we can navigate from there
        //console.log("First render");
        //This gets run when the page is exited
        return () => {
            // console.log("Unmounting");
        };
    }, []);

    const notFound = useLoadCall(
        async () => {
            if (id === "0") {
                return true;
            } else {
                let promise = await viewModel.loadAlertActionsAsync(id);

                if (promise.wasSuccessful === true) {
                    let items: AlertActionItemViewModel[] = viewModel.getAlertActions;
                    setLocalAlertActions(items);
                    if (items.length >= 1) {
                        setCanSave(true);
                    }
                }

                return !!viewModel.getValue("id");
            }
        },
        { customCheck: () => id === "0" || isGuid(id) },
    );

    const handleBack = () => {
        router.history.push(AppUrls.Client.Main.Admin.Root + "#alerts");
    };

    const validateAndSave = (exit: boolean) => {
        //validate model and all sub objects
        if (viewModel.isModelAndChildrenValid() === true) {
            if (viewModel.model.id && viewModel.model.id !== "") {
                const isValid = viewModel.getIsAlertActionValid();
                isValid.then((result: ApiResult<number | undefined>) => {
                    if (result.wasSuccessful === true && result.errors.length === 0) {
                        let promise = viewModel.upsertAlertAction();

                        promise.then((result: any) => {
                            if (result.wasSuccessful === true) {
                                if (exit === true) {
                                    handleBack();
                                }
                            }
                        });
                    } else {
                        // The error automatically gets shown to the user.
                    }
                });
            } else {
                let promise = viewModel.upsertAlertAction();

                promise.then((result: any) => {
                    if (result.wasSuccessful === true) {
                        handleBack();
                    }
                });
            }
        }
    };

    const onSave = (exit: boolean): void => {
        /*         if (localAlertActions.length < maxAlerts) {
            handleAreYouSureOpen();
        } else { */
        validateAndSave(exit);
        /*  } */
    };

    /*     const handleSaveAlertAction = () => {
        handleAreYouSureClose();
        validateAndSave();
    }; */

    const onDeleteRow = () => {
        setLocalAlertActions(viewModel.getAlertActions);
    };

    const getRows = () => {
        return viewModel.getAlertActions.map((item: any) => {
            return <AlertActionItem key={generateID()} localViewModel={item} onDelete={onDeleteRow} />;
        });
    };

    const handleNewClick = () => {
        viewModel.addNewAlertAction();
        setLocalAlertActions(viewModel.getAlertActions);
        setCanSave(true);
    };

    const showAdd: boolean = localAlertActions.length < maxAlerts;

    const setSearchString = (value: string) => {
        viewModel.setSearchString(value);
        viewModel.setPage(0);
    };

    const setRowsPerPage = (rows: number) => {
        viewModel.setRowsPerPage(rows);
    };

    const setPage = (page: number) => {
        viewModel.setPage(page);
    };

    const setOrderChange = (columnId: number, direction: any) => {
        viewModel.setOrderChange(columnId, direction);
    };

    const hasUnits = () => {
        return viewModel.getUnits.length > 0;
    };

    const getSortDirection = (columnIndex: number) => {
        return viewModel.sortColumnId === columnIndex ? viewModel.sortDirection : "";
    };

    function renderPage() {
        if (viewModel.IsLoading || notFound) return <Loader />;

        return (
            <>
                {/*                 <DeleteModal
                    open={areYouSureOpen}
                    onClose={handleAreYouSureClose}
                    onDelete={handleSaveAlertAction}
                    text="This alert action set does not contain all the options. Do you want to continue?"
                    title="Do you want to continue?"
                /> */}
                <AdminDetailContainer>
                    <DetailWrapper>
                        <BackToListWrapper style={{ paddingTop: "15px" }}>
                            <BackToList onClick={handleBack} tabIndex={0}>
                                <BackBox>
                                    <NavigateBeforeIcon fontSize="large" />
                                </BackBox>
                                <BackToListText>Back to alert action</BackToListText>
                            </BackToList>
                        </BackToListWrapper>
                        <NameSectionBox>
                            <RoofcareInput<AlertActionAddEditDetailModel>
                                type="text"
                                label="Alert action:"
                                validateOnBlur={true}
                                viewModel={viewModel}
                                fieldName="name"
                                shrink={true}
                                maxLength={128}
                            />
                        </NameSectionBox>
                        <ItemSectionBox>
                            <Grid container direction="column" spacing={1}>
                                <Grid container direction="row" spacing={1}>
                                    <Grid item xs={2} className="column-title">
                                        <Typography variant="h6">Name</Typography>
                                    </Grid>
                                    <Grid item xs={1} className="column-title">
                                        <Typography variant="h6">Colour</Typography>
                                    </Grid>
                                    <Grid item xs={3} className="column-title">
                                        <Typography variant="h6">Condition</Typography>
                                    </Grid>
                                    <Grid item xs={2} className="column-title">
                                        <Typography variant="h6">Direction</Typography>
                                    </Grid>
                                    <Grid item xs={1} className="column-title">
                                        <Typography variant="h6">Photo</Typography>
                                    </Grid>
                                    <Grid item xs={1} className="column-title">
                                        <Typography variant="h6">SMS</Typography>
                                    </Grid>
                                    <Grid item xs={1} className="column-title">
                                        <Typography variant="h6">Email</Typography>
                                    </Grid>
                                    <Grid item xs={1} className="column-title">
                                        <Typography variant="h6"></Typography>
                                    </Grid>
                                </Grid>
                                {getRows()}
                            </Grid>
                        </ItemSectionBox>
                        <AddNewBox>
                            {showAdd === true && (
                                <BoxAdd onClick={() => handleNewClick()}>
                                    <AddItemText fontcolor="#F29D29" style={{ paddingLeft: "0!important" }}>
                                        <div style={{ marginLeft: "-15px" }}>+ Add another</div>
                                    </AddItemText>
                                </BoxAdd>
                            )}
                        </AddNewBox>
                        <ItemSectionBox id="unittabletitle">
                            <h1>Assigned Units</h1>
                        </ItemSectionBox>
                        <ItemSectionTable id="unittable">
                            {hasUnits() === false && <div>Alert Action has no assigned units</div>}
                            {hasUnits() === true && (
                                <RoofcareTableWrapperAdmin key="alertActionUnitswrapper">
                                    <RoofcareTable
                                        id="alertActionUnits"
                                        className="alertActionUnits"
                                        columns={[
                                            {
                                                title: "Name",
                                                field: "name",
                                                sorting: true,
                                                filtering: true,
                                                defaultSort: getSortDirection(0),
                                            },
                                            {
                                                title: "End User",
                                                field: "clientName",
                                                sorting: true,
                                                filtering: true,
                                                defaultSort: getSortDirection(1),
                                            },
                                            {
                                                title: "Contractor",
                                                field: "contractorName",
                                                sorting: true,
                                                filtering: true,
                                                defaultSort: getSortDirection(2),
                                            },
                                            {
                                                title: "Property",
                                                field: "addressLine1",
                                                sorting: true,
                                                filtering: true,
                                                defaultSort: getSortDirection(3),
                                            },
                                            {
                                                width: "auto",
                                                title: "Town",
                                                field: "city",
                                                sorting: true,
                                                filtering: true,
                                                defaultSort: getSortDirection(4),
                                            },
                                            {
                                                width: "auto",
                                                title: "Postcode",
                                                field: "postcode",
                                                sorting: true,
                                                filtering: true,
                                                defaultSort: getSortDirection(5),
                                            },
                                            {
                                                width: "auto",
                                                title: "Has Email",
                                                field: "hasValidEmailAddesses",
                                                sorting: true,
                                                defaultSort: getSortDirection(6),
                                                render: (rowData: UnitListItemModel) => {
                                                    {
                                                        return rowData.hasValidEmailAddesses ? (
                                                            <div className="central-cell">
                                                                <div className="tick">
                                                                    <span>&#10003;</span>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div className="central-cell">
                                                                <div className="cross">
                                                                    <span>&#88;</span>
                                                                </div>
                                                            </div>
                                                        );
                                                    }
                                                },
                                            },
                                            {
                                                width: "auto",
                                                title: "Has Mobile",
                                                field: "hasValidMobileNumbers",
                                                sorting: true,
                                                defaultSort: getSortDirection(7),
                                                render: (rowData: UnitListItemModel) => {
                                                    {
                                                        return rowData.hasValidMobileNumbers ? (
                                                            <div className="central-cell">
                                                                <div className="tick">
                                                                    <span>&#10003;</span>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div className="central-cell">
                                                                <div className="cross">
                                                                    <span>&#88;</span>
                                                                </div>
                                                            </div>
                                                        );
                                                    }
                                                },
                                            },
                                            {
                                                width: "auto",
                                                title: "Status",
                                                field: "status",
                                                sorting: false,
                                                filtering: true,
                                                defaultSort: getSortDirection(8),
                                                render: (rowData: UnitListItemModelDTO) => {
                                                    if (rowData !== null && rowData !== undefined) {
                                                        return (
                                                            <div className="status-readings" style={{ display: "flex", justifyContent: "center", width: "75px" }}>
                                                                <UnitStatusBox
                                                                    className={"device-status"}
                                                                    backgroundColor={rowData.status.statusColour}
                                                                    textColour={rowData.status.statusTextColour}
                                                                >
                                                                    <div className="status-name">{rowData.status.name}</div>
                                                                </UnitStatusBox>
                                                            </div>
                                                        );
                                                    }
                                                },
                                            },
                                        ]}
                                        options={{
                                            search: false,
                                            toolbar: false,
                                            thirdSortClick: false,
                                            initialPage: viewModel.currentPage,
                                            defaultGroupOrder: viewModel.sortColumnId,
                                            defaultGroupDirection: viewModel.sortDirection,
                                            pageSize: viewModel.pageSize,
                                            pageSizeOptions: [10, 20, 50, 100],
                                            emptyRowsWhenPaging: false,
                                            headerStyle: {
                                                ...tableOptions.headerStyle,
                                            },
                                            rowStyle: {
                                                ...tableOptions.rowStyle,
                                            },
                                            filterCellStyle: {
                                                ...tableOptions.filterCellStyle,
                                            },
                                            searchFieldAlignment: "left",
                                            searchFieldStyle: {
                                                border: "1px solid #333333",
                                                borderRadius: "5px",
                                                width: "400px",
                                            },
                                        }}
                                        data={viewModel.getUnits}
                                        title=""
                                        icons={{
                                            ResetSearch: () => <ResetSearchIcon />,
                                        }}
                                        /* onRowClick={(_: any, rowData: any) => goToDevice(rowData.propertyId)} */
                                        components={{ Container: (props: any) => <Paper {...props} elevation={0} /> }}
                                        localization={{ toolbar: { searchPlaceholder: "Start typing to search" } }}
                                        onOrderChange={(orderedColumnId: any, orderDirection: any) => {
                                            setOrderChange(orderedColumnId, orderDirection);
                                        }}
                                        onChangeRowsPerPage={(rows: number) => {
                                            setRowsPerPage(rows);
                                        }}
                                        onChangePage={(page: number) => {
                                            setPage(page);
                                        }}
                                    />
                                </RoofcareTableWrapperAdmin>
                            )}
                        </ItemSectionTable>
                        <ButtonSectionBox>
                            <SuccessBtn disabled={!canSave} onClick={() => onSave(false)}>
                                Save
                            </SuccessBtn>
                            <SuccessBtn disabled={!canSave} onClick={() => onSave(true)} style={{ marginLeft: "15px" }}>
                                Save &#x26; exit
                            </SuccessBtn>
                            <CancelBtn onClick={handleBack}>Cancel</CancelBtn>
                        </ButtonSectionBox>
                    </DetailWrapper>
                </AdminDetailContainer>
            </>
        );
    }

    return useObserver(() => renderPage());
};
