import { ViewModelBase } from "Core/ViewModels/ViewModelBase";
import { ContractorDashboardModel, ContractorDashboardModelDTO } from "./ContractorDashboardModel";
import { FieldType, isEmptyOrWhitespace } from "Core/Utils/Utils";
import { action, computed, observable, runInAction } from "mobx";
import { debounce } from "lodash-es";
import { IMarker } from "Custom/Components/Map/Map";
import { DEBOUNCE_VALUE_MS, DEFAULTBOUNDS } from "Custom/Globals/Globals";
import { ApiResult } from "Core/Models";
import { Server } from "Custom/Globals/AppUrls";
import { EstateCommonRowDTO, InstallationMapModelDTO } from "Custom/Models/Domain";
import L, { LatLngBounds } from "leaflet";

import { getDefaultPropertyMarkerIcon, getInstallationMarkerIcon } from "Custom/Utils/map";
import { KeyValuePair } from "Core/Models/KeyValuePair";
import { DeviceStatusWithCommandModelDTO } from "Custom/Models/Domain/Installations/DeviceStatusWithCommandModel";

//extend viewmodel base and passing your model as the generic type
export class ContractorDashboardViewModel extends ViewModelBase<ContractorDashboardModel> {
    //Singleton instance of class
    private static _instance: ContractorDashboardViewModel;
    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    @observable public errorMessage: string = "";

    @observable public IsDownloading: boolean = false;

    @observable public pageSize: number = 50;
    @observable public currentPage: number = 0;

    @observable public searchString: string = "";
    @observable public filterSearchString: string = "";

    // By default this will be the first column (by index) and in ascending order.
    @observable public sortColumnId: number = 0;
    @observable public sortDirection: any = "asc";

    @observable public showProperties: boolean = true;

    @action
    public toggleLayer() {
        this.showProperties = !this.showProperties;
    }

    @action
    public setOrderChange = (columnId: number, direction: any) => {
        this.sortColumnId = columnId;
        this.sortDirection = direction;
    };

    // #endregion Project Sorting

    // #region Project Filtering
    @observable public filterClientsFilter = observable<string>([]);
    @observable public clientsList: KeyValuePair[] = [];

    @observable public filterStatusFilter = observable<string>([]);
    @observable public estateStatuses: KeyValuePair[] = [];

    public commandStatuses = observable<DeviceStatusWithCommandModelDTO>([]);
    @observable public filterCommandStatusesFilter = observable<string>([]);
    @observable public commandStatusList: KeyValuePair[] = [];

    @computed
    public get getClientsKVPair(): KeyValuePair[] {
        return this.clientsList.slice();
    }

    public getClientsFilter = () => {
        return computed(() => this.filterClientsFilter);
    };

    @action
    public setClientFilter = (values: string[]) => {
        this.filterClientsFilter.clear();
        this.filterClientsFilter.replace(values);
        this.setFilterClient(values);
    };

    private setFilterClient = debounce(
        action((values: string[]) => {
            this.filterClientsFilter.replace(values);
        }),
        DEBOUNCE_VALUE_MS,
    );

    @action
    public getStatusKVPair = (): KeyValuePair[] => {
        if (this.estateStatuses != undefined) {
            return this.estateStatuses.slice();
        } else {
            const retVal: KeyValuePair[] = [];
            return retVal;
        }
    };

    public getStatusFilter = () => {
        return computed(() => this.filterStatusFilter);
    };

    @action
    public setStatusFilter = (values: string[]) => {
        this.filterStatusFilter.clear();
        this.filterStatusFilter.replace(values);
        this.setFilterUnitStatus(values);
    };

    private setFilterUnitStatus = debounce(
        action((values: string[]) => {
            this.filterStatusFilter.replace(values);
        }),
        DEBOUNCE_VALUE_MS,
    );

    @action
    public setFilterSets = () => {
        const model: ContractorDashboardModel = this.model;

        if (model != null) {
            if (model.deviceStatuses.length > 0) {
                this.estateStatuses = [];
                this.filterStatusFilter.clear();
                for (let s: number = 0; s < model.deviceStatuses.length; s++) {
                    this.estateStatuses.push({
                        key: model.deviceStatuses[s].displayName,
                        value: model.deviceStatuses[s].id,
                    });
                    this.filterStatusFilter.push(model.deviceStatuses[s].id);
                }
            }

            if (model.clientsList.length > 0) {
                this.clientsList = [];
                this.filterClientsFilter.clear();
                for (let s: number = 0; s < model.clientsList.length; s++) {
                    this.clientsList.push({
                        key: model.clientsList[s].clientName,
                        value: model.clientsList[s].id,
                    });
                    this.filterClientsFilter.push(model.clientsList[s].id);
                }
            }
        }
    };
    // #endregion Project Filtering

    public getInstallation(id: string): InstallationMapModelDTO | undefined {
        return this.model.units.find((a) => a.id === parseInt(id, 10));
    }

    public getProperty(id: string): EstateCommonRowDTO | undefined {
        return this.model.clients.find((a) => a.propertyId === id);
    }

    @computed public get getLayer(): number {
        return this.showProperties === true ? 0 : 1;
    }

    @computed public get getListData(): EstateCommonRowDTO[] {
        let retVal: EstateCommonRowDTO[] = this.model.clients;

        const filterBySearchString = (item: EstateCommonRowDTO): boolean => {
            if (isEmptyOrWhitespace(this.filterSearchString)) {
                return true;
            }

            const searchStringUpperCase = this.filterSearchString.toUpperCase();

            return (
                item.clientName.toUpperCase().includes(searchStringUpperCase) ||
                item.addressLine1.toUpperCase().includes(searchStringUpperCase) ||
                item.addressLine2.toUpperCase().includes(searchStringUpperCase) ||
                item.postcode.toUpperCase().includes(searchStringUpperCase) ||
                item.city.toUpperCase().includes(searchStringUpperCase) ||
                item.county.toUpperCase().includes(searchStringUpperCase) ||
                item.postcode.toUpperCase().includes(searchStringUpperCase)
            );
        };

        const filterByStatus = (item: EstateCommonRowDTO): boolean => {
            if (this.filterStatusFilter.length === this.estateStatuses.length) {
                return true;
            }

            let statusResponse: boolean = false;
            // Check 1 - see if it is device item only from the main list.
            let found: boolean = this.filterStatusFilter.includes(item.status.id);
            // have we a match from Admin/Devices
            if (found) {
                statusResponse = true;
            } else {
                // have we a match from Command set based on the admin condtiion sets
                if (this.commandStatuses !== undefined && this.commandStatuses.length > 0) {
                    let commandFound: DeviceStatusWithCommandModelDTO | undefined = this.commandStatuses.find((a) => a.id === item.status.id);

                    if (commandFound !== undefined) {
                        let found: boolean = this.filterStatusFilter.includes(commandFound.adminConditionSetItemId);
                        if (found) {
                            statusResponse = true;
                        }
                    }
                }
            }

            return statusResponse;
        };

        const filterByClient = (item: EstateCommonRowDTO): boolean => {
            if (this.filterClientsFilter.length === this.clientsList.length) {
                return true;
            }

            let clientResponse: boolean = false;
            let found: boolean = this.filterClientsFilter.includes(item.clientId);
            if (found) {
                clientResponse = true;
            } else {
                clientResponse = false;
            }

            return clientResponse;
        };

        return retVal
            .filter((a) => filterByClient(a))
            .filter((a) => filterByStatus(a))
            .filter((a) => filterBySearchString(a));
    }

    public getSearchString = () => {
        return computed(() => this.searchString);
    };

    @action
    public setSearchString = (value: string) => {
        this.searchString = value;
        this.setFilterSearchString(value);
    };

    private setFilterSearchString = debounce(
        action((value: string) => {
            this.filterSearchString = value;
        }),
        DEBOUNCE_VALUE_MS,
    );

    // #endregion Project Sorting

    @action
    public setRowsPerPage(rows: number) {
        this.pageSize = rows;
    }

    @action
    public setPage = (page: number) => {
        this.currentPage = page;
    };

    @computed
    public get markers(): IMarker[] {
        if (this.showProperties) {
            return this.getListData.map((client: EstateCommonRowDTO) => ({
                id: client.propertyId,
                position: [client.latitude, client.longitude],
                icon: getInstallationMarkerIcon(client.status !== null && client.status !== undefined ? client.status.statusColour : "#ffffff"),
            }));
        } else {
            let pins: InstallationMapModelDTO[] = [];

            let filteredList: EstateCommonRowDTO[] = this.getListData;

            for (let i: number = 0; i < filteredList.length; i++) {
                let showThese: InstallationMapModelDTO[] = this.model.units.filter((a) => a.roofcareAddressId === filteredList[i].propertyId);
                pins.push(...showThese);
            }

            return pins.map((installation) => ({
                id: installation.id.toString(),
                position: [installation.latitude, installation.longitude],
                icon: getInstallationMarkerIcon(installation.status !== null && installation.status !== undefined ? installation.status.statusColour : "#ffffff"),
            }));
        }

        return [];
    }

    constructor() {
        //Pass in a new instance of your model
        //By passing in true as the second parameter, we make this model undoable which means we can use save and reset options on the model
        //If you make a change to the model you need to persist it with a saveModel() call
        //If you make changes to your model you can revert it back by calling resetModel()
        super(new ContractorDashboardModel(), false);
        //EN - Haven't figured out how to make this call work from the base model yet
        //This is only needed if you make use of the validation decorators
        this.setDecorators(ContractorDashboardModel);
    }

    //isValid will check all fields to make sure they are in a valid state.
    public doSubmit = async (e: any) => {
        e.preventDefault();

        if (this.isModelValid()) {
            //Do stuff here
            this.errorMessage = "Form is valid";
        } else {
            this.errorMessage = "Form is not valid";
        }
    };

    //This must be present in your viewmodel. Just return true if you dont need to validate anything.
    //keyof BlankModel & string lets you add type checking to the fieldName
    //I am using the validator package to make checking easier https://www.npmjs.com/package/validator
    public isFieldValid(fieldName: keyof FieldType<ContractorDashboardModel>): boolean {
        const { isValid, errorMessage } = this.validateDecorators(fieldName);

        //You need to this two properties after validation
        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    @action
    public async loadDashboardAsync(): Promise<ApiResult<ContractorDashboardModelDTO>> {
        const apiResult = await this.Post<ContractorDashboardModelDTO>(Server.Api.Contractor.getDashboard);

        if (apiResult.wasSuccessful) {
            runInAction(() => {
                this.setClients(apiResult.payload.clients);
                this.setUnits(apiResult.payload.units);
                this.model.setClientsList(apiResult.payload.clientsList);
                this.model.setDevicesStatus(apiResult.payload.clients, apiResult.payload.deviceStatuses);
                this.model.setDevicesWithCommand(apiResult.payload.deviceStatusesWithCommandIdx);
            });
        }
        return apiResult;
    }

    @action
    public setUnits(units: InstallationMapModelDTO[]) {
        this.model.units = units;
    }

    @action
    public setClients(clients: EstateCommonRowDTO[]) {
        this.model.clients = clients;
    }

    public getProjectIdForProperty(id: string): string {
        let retVal: string = "";

        const property: EstateCommonRowDTO | undefined = this.getProperty(id);

        if (property !== undefined) {
            retVal = property.projectId;
        }

        return retVal;
    }

    public getPropertyIdForUnit(id: string): string {
        let retVal: string = "";

        const unit: InstallationMapModelDTO | undefined = this.getInstallation(id);

        if (unit !== undefined) {
            const property: EstateCommonRowDTO | undefined = this.getProperty(unit.roofcareAddressId);

            if (property !== undefined) {
                retVal = property.propertyId;
            }
        }
        return retVal;
    }

    @computed
    public get mapBounds(): LatLngBounds {
        let retVal: LatLngBounds = DEFAULTBOUNDS;
        let bounds: LatLngBounds = new LatLngBounds(
            this.model.units.filter((a) => a.latitude !== 0 && a.longitude !== 0).map((installation) => [installation.latitude, installation.longitude]),
        );

        if (this.showProperties) {
            bounds = new LatLngBounds(
                this.model.clients.filter((a) => a.latitude !== 0 && a.longitude !== 0).map((property: EstateCommonRowDTO) => [property.latitude, property.longitude]),
            );
        }

        const isValid: boolean = bounds.isValid();

        if (isValid) {
            retVal = bounds;
        }

        return retVal;
    }
}
