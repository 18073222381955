import { DeviceStatusCell } from "./../../Projects/Details/Properties/Installations/InstallationAddModal.styles";
import { ViewModelBase } from "Core/ViewModels/ViewModelBase";
//import {PropertyOverviewModel } from "./PropertyOverviewModel";
import { FieldType } from "Core/Utils/Utils";
import { action, computed, IObservableArray, observable, runInAction } from "mobx";
import { BlankModel } from "Core/Models/BlankModel";
import { ApiResult, ApiResultErrorType } from "Core/Models";
import { Server } from "Custom/Globals/AppUrls";
import { isNullOrUndefined } from "Custom/Utils/utils";
import { UnitOverview } from "./UnitOverview";

export interface IPropertyOverviewRequest {
    propertyId: string;
    page: number;
}

export interface PropertyOverviewReponse {
    devices: UnitOverview[];
}

//extend viewmodel base and passing your model as the generic type
export class PropertyOverviewViewModel extends ViewModelBase<BlankModel> {
    //Singleton instance of class
    private static _instance: PropertyOverviewViewModel;

    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    @observable public errorMessage: string = "";

    @observable public propertyId: string = "";

    @observable public currentPage: number = 0;

    @observable public numberOfUnits: number = 0;

    @observable public recordCount: number = 0;

    @observable public units: IObservableArray<UnitOverview> = observable([]);

    @action
    public clear() {
        this.errorMessage = "";
        this.propertyId = "";
        this.currentPage = 0;
        this.numberOfUnits = 0;
        this.units.clear();
    }

    constructor() {
        //Pass in a new instance of your model
        //By passing in true as the second parameter, we make this model undoable which means we can use save and reset options on the model
        //If you make a change to the model you need to persist it with a saveModel() call
        //If you make changes to your model you can revert it back by calling resetModel()
        super(new BlankModel(), false);
        //EN - Haven't figured out how to make this call work from the base model yet
        //This is only needed if you make use of the validation decorators
        this.setDecorators(BlankModel);
    }

    @computed public get getUnitsLoaded(): number {
        return this.units.length;
    }

    @computed public get getPageNumber(): number {
        return this.currentPage;
    }

    @computed public get totalPages(): number {
        return Math.ceil(this.numberOfUnits / 4);
    }

    @computed public get getUnits(): UnitOverview[] {
        return this.units.slice();
    }

    @computed public get getRecordCount(): number {
        return this.recordCount;
    }

    @action public prevPage = async () => {
        let temp: number = this.currentPage - 1;
        if (temp < 0) {
            this.currentPage = 0;
        } else {
            this.currentPage = temp;

            let promise = await this.loadAsync();
        }
    };

    @action public nextPage = async () => {
        let temp: number = this.currentPage + 1;
        if (temp > this.totalPages - 1) {
            this.currentPage = this.totalPages - 1;
        } else {
            this.currentPage = temp;

            let promise = await this.loadAsync();
        }
    };

    @action public setNumberOfUnits(noUnits: number) {
        this.numberOfUnits = noUnits;
    }

    @action public async loadAsync(): Promise<ApiResult<PropertyOverviewReponse | undefined>> {
        let apiResult: ApiResult<PropertyOverviewReponse | undefined> = {
            wasSuccessful: false,
            errors: [],
            headers: "",
            payload: undefined,
        };

        if (this.IsLoading === false) {
            let request: IPropertyOverviewRequest = {
                propertyId: this.propertyId,
                page: this.currentPage,
            };

            this.units.clear();
            this.recordCount = 0;

            apiResult = await this.Post<PropertyOverviewReponse | undefined>(Server.Api.Estate.getPropertyOverVieww, request);

            runInAction(() => {
                if (apiResult.wasSuccessful === true) {
                    this.errorMessage = "";

                    if (isNullOrUndefined(apiResult.payload) === false) {
                        const devices = apiResult.payload!.devices;
                        this.units = observable(devices);
                        setTimeout(() => {
                            runInAction(() => {
                                this.recordCount = this.units.length;
                            });
                        }, 200);
                    }
                } else {
                    this.errorMessage = "Failed to get the installation data.";

                    apiResult.wasSuccessful = false;
                    apiResult.errors = [
                        {
                            type: ApiResultErrorType.Basic,
                            message: "Failed to get the the admin data.",
                        },
                    ];

                    setTimeout(() => {
                        runInAction(() => {});
                    }, 5000);
                }
            });
        }
        return apiResult;
    }

    @action public setProperty(propertyId: string) {
        this.propertyId = propertyId;
    }

    //isValid will check all fields to make sure they are in a valid state.
    public doSubmit = async (e: any) => {
        e.preventDefault();

        if (this.isModelValid()) {
            //Do stuff here
            this.errorMessage = "Form is valid";
        } else {
            this.errorMessage = "Form is not valid";
        }
    };

    //This must be present in your viewmodel. Just return true if you dont need to validate anything.
    //keyof BlankModel & string lets you add type checking to the fieldName
    //I am using the validator package to make checking easier https://www.npmjs.com/package/validator
    public isFieldValid(fieldName: keyof FieldType<BlankModel>): boolean {
        const { isValid, errorMessage } = this.validateDecorators(fieldName);

        //You need to this two properties after validation
        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
