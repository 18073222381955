import * as MobX from "mobx";
import { ModelBase } from "Core/Models/ModelBase";
export interface AverageSensorValueDTO {
    id: number;
    deviceId: string;
    readingDate: string;
    calculatedValue: number;
    minValue: number;
    maxValue: number;
}

export interface AverageSensorValue extends AverageSensorValueDTO {}

export class AverageSensorResult extends ModelBase<AverageSensorResult, AverageSensorValueDTO> {
    @MobX.observable public averageSensorValues: MobX.IObservableArray<AverageSensorValue> = MobX.observable([]);

    //fromDto is required but you can leave it blank
    @MobX.action
    fromDto(model: AverageSensorValueDTO): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
        // NOTE We don't have an object, we have an array of objects, so use fromDtoArr
    }

    @MobX.action
    fromDtoArr(model: AverageSensorValueDTO[]): void {
        this.averageSensorValues.clear();
        this.averageSensorValues = MobX.observable(model.slice(0));
    }

    //toDto is required but you can leave it blank
    toDto(model: AverageSensorResult): void {}
}
