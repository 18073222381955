import * as React from "react";
import * as MobXReact from "mobx-react-lite";
import { PropertyNotesContainer } from "Custom/Views/Projects/Details/Properties/PropertyNotes/PropertyNotes.styled";
import { Typography } from "@material-ui/core";
import { ProjectDetailsViewModel } from "../../ProjectDetailsViewModel";
import { generateID, isNullOrEmpty } from "Core/Utils/Utils";
import { isNullOrUndefined } from "Custom/Utils/utils";
import { Stores, StoresContext } from "Custom/Stores";
import * as RouterUtil from "Custom/Utils/routing";
import { NoteListHeader, ProjectNote, ProjectNoteItem, ProjectNoteListItem } from "./ProjectNote.style";
import { formatDate, formatTime } from "Custom/Utils/format";
import { ClickableImgCell } from "Custom/Components/Table/ClickableImgCell";
//import EditLogo from "Custom/Content/crownIcon.svg";
import { ProjectCommunicationSortEnum, ProjectCommunicationSortHelpers } from "Custom/Models/Domain/Projects";
import { CommunicationSortSelect } from "./CommunicationSortSelect";
import AddLogo from "Custom/Content/plusIcon.svg";
import EditLogo from "Custom/Content/editIcon.svg";
import EditLogoBlk from "Custom/Content/editIconblk.svg";
import moment from "moment";
interface IProps {
    viewModel: ProjectDetailsViewModel;
    setOpenAddModal: () => void;
    setEditCommuniction: (id: string) => any;
}

export const ProjectNoteList: React.FC<IProps> = ({ viewModel, setOpenAddModal, setEditCommuniction }) => {
    const store = React.useContext<Stores>(StoresContext);
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [allowedToEdit, setAllowedToEdit] = React.useState(false);

    React.useEffect(() => {
        if (!viewModel.IsLoading) {
            setIsLoaded(true);
        }
    }, [viewModel.IsLoading]);

    React.useEffect(() => {
        const allowEdit: boolean = RouterUtil.requireAdminLogin(store) === true;
        setAllowedToEdit(allowEdit);

        return () => {};
    }, []);

    const handleCSVClick = (id: string) => {
        viewModel.downloadCommunicationCSV();
    };

    const setSort = (value: ProjectCommunicationSortEnum): void => {
        viewModel.setSortCommunicationOrder(value);
    };

    return MobXReact.useObserver(() => (
        <>
            <PropertyNotesContainer>
                <NoteListHeader>
                    <div style={{ paddingBottom: "15px", display: "flex", flexDirection: "row", flex: "1 0 0" }}>
                        <Typography variant={"h1"}>Project Actions</Typography>
                        {viewModel.getCommunicationList.length > 0 && (
                            <ClickableImgCell
                                className="projectnote-addedit"
                                logo={EditLogo}
                                text={"Download CSV"}
                                onClick={handleCSVClick}
                                id={viewModel.getDefaultNote === undefined || viewModel.getDefaultNote.id === undefined ? "0" : viewModel.getDefaultNote.id!}
                            />
                        )}

                        <ClickableImgCell
                            className="projectnote-addedit"
                            logo={AddLogo}
                            text={"Add new action"}
                            onClick={setOpenAddModal}
                            id={viewModel.getDefaultNote === undefined || viewModel.getDefaultNote.id === undefined ? "0" : viewModel.getDefaultNote.id!}
                        />
                    </div>
                    {viewModel.getCommunicationList.length > 0 && (
                        <div style={{ display: "flex", flexDirection: "column", marginTop: "-25px" }}>
                            <Typography variant={"h1"}>Sort actions by</Typography>
                            <CommunicationSortSelect value={viewModel.sortCommunicationOrder} onChange={setSort} disabled={false} />
                        </div>
                    )}
                </NoteListHeader>
                {viewModel.getCommunicationList.map((communication) => {
                    let onDate: string = communication.createdDate!;
                    let creator: string = communication.creator;

                    let beenEdited: boolean = false;
                    let beenResolved: boolean = false;

                    if (communication.updatedDate !== null && communication.updatedDate !== undefined) {
                        if (communication.createdDate != communication.updatedDate) {
                            onDate = communication.updatedDate;
                            creator = communication.updater;
                            beenEdited = true;
                        }
                    }

                    if (communication.isResolved === true && communication.resolvedDate !== null && communication.resolvedDate !== undefined) {
                        onDate = communication.resolvedDate;
                        creator = communication.resolver;
                        beenEdited = false;
                        beenResolved = true;
                    }

                    let isOverDue: string = "";
                    let icon: any = EditLogoBlk;
                    if (communication.isResolved === false && moment.utc(communication.followUpDate).endOf("d") < moment.utc()) {
                        isOverDue = " overdue";
                        icon = EditLogo;
                    }

                    return (
                        <>
                            <div key={generateID()} className={"triangle " + isOverDue}></div>
                            <ProjectNoteListItem key={generateID()} className={isOverDue}>
                                <div className="communication">
                                    <div className="title">
                                        On {formatDate(onDate)} at {formatTime(onDate)} {creator} {beenResolved && <span>resolved</span>}
                                        {beenEdited && <span>updated</span>} {!beenEdited && !beenResolved && <span>wrote</span>}{" "}
                                        {(beenEdited === true || beenResolved === true) && (
                                            <span className="created">
                                                (Created on {formatDate(communication.createdDate!)} {formatTime(communication.createdDate!)} by {communication.creator} )
                                            </span>
                                        )}
                                        :
                                    </div>
                                    <ProjectNote key={generateID()} className="project-note">
                                        {communication.note}
                                    </ProjectNote>
                                </div>
                                {communication.isResolved === false && (
                                    <div className="followup">
                                        <div className="title">{isNullOrUndefined(communication.followUpDate) === true ? "" : "Follow up: "}</div>
                                        <div className="project-note">{formatDate(communication.followUpDate!)}</div>
                                    </div>
                                )}
                                {communication.isResolved === true && (
                                    <div className="followUp">
                                        <div className="title">{isNullOrUndefined(communication.resolvedDate) === true ? "" : "Resolved: "}</div>
                                        <div className="project-note">{formatDate(communication.resolvedDate!)}</div>
                                    </div>
                                )}

                                <div className={"editAction " + isOverDue}>
                                    {communication.isResolved === false && (
                                        <ClickableImgCell
                                            id={communication.id!}
                                            className={"projectnote-addedit onitsown whiteout " + isOverDue}
                                            logo={icon}
                                            text={"Edit action"}
                                            onClick={setEditCommuniction}
                                        />
                                    )}
                                </div>
                            </ProjectNoteListItem>
                        </>
                    );
                })}
            </PropertyNotesContainer>
            <div>
                <ClickableImgCell
                    className="projectnote-addedit onitsown"
                    logo={AddLogo}
                    text={"Add new action"}
                    onClick={setOpenAddModal}
                    id={viewModel.getDefaultNote === undefined || viewModel.getDefaultNote.id === undefined ? "0" : viewModel.getDefaultNote.id!}
                />
            </div>
        </>
    ));
};
