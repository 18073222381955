import { action, computed, observable } from "mobx";
import { ModelBase } from "Core/Models/ModelBase";
import { AddressModel } from "Custom/Models/Domain";

//Base class expects the model type and the DTO model type
//If you are not using a DTO just pass in undefined as the DTO
export class ClientPropertyListItem extends ModelBase<ClientPropertyListItem, ClientPropertyListItemDTO> {
    @observable id: string = "";
    @observable isDeleted: boolean = false;

    @observable buildingName = "";
    @observable addressLine1 = "";
    @observable addressLine2 = "";
    @observable city: string = "";
    @observable county: string = "";
    @observable postcode: string = "";
    @observable installationCount: number = 0;
    @observable locationLatitude: number = 0;
    @observable locationLongitude: number = 0;

    @computed
    public get toAddressModel(): AddressModel {
        const retVal: AddressModel = new AddressModel();

        retVal.id = this.id;
        retVal.buildingName = this.buildingName;
        retVal.addressLine1 = this.addressLine1;
        retVal.addressLine2 = this.addressLine2;
        retVal.city = this.city;
        retVal.county = this.county;
        retVal.postcode = this.postcode;
        retVal.locationLatitude = this.locationLatitude;
        retVal.locationLongitude = this.locationLongitude;

        return retVal;
    }

    //fromDto is required but you can leave it blank
    fromDto(model: ClientPropertyListItemDTO): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
    }

    //toDto is required but you can leave it blank
    toDto(model: ClientPropertyListItem): void {}

    @action public toClientPropertyListItemDtofromAddress(addr: AddressModel) {
        this.buildingName = addr.buildingName;
        this.addressLine1 = addr.addressLine1;
        this.addressLine2 = addr.addressLine2;
        this.city = addr.city;
        this.county = addr.county;
        this.postcode = addr.postcode;
        this.locationLatitude = addr.locationLatitude;
        this.locationLongitude = addr.locationLongitude;

        let retVal: ClientPropertyListItemDTO = {
            buildingName: addr.buildingName,
            addressLine1: addr.addressLine1,
            addressLine2: addr.addressLine2,
            city: addr.city,
            county: addr.county,
            postcode: addr.postcode,
            isDeleted: this.isDeleted,
            id: this.id,
            locationLatitude: addr.locationLatitude,
            locationLongitude: addr.locationLongitude,
            installationCount: this.installationCount,
        };

        return retVal;
    }
}

export interface ClientPropertyListItemDTO {
    id: string;
    isDeleted: boolean;
    buildingName: string;
    addressLine1: string;
    addressLine2: string;
    city: string;
    county: string;
    postcode: string;
    installationCount: number;
    locationLatitude: number;
    locationLongitude: number;
}
