import styled from "styled-components";
import { Box, Typography } from "@material-ui/core";

export const ClickableCell = styled(Box)`
    text-align: right;
    display: flex;
    flex: 1 0 1;
    flex-direction: row;
    align-items: left;
    padding: 0 10px;

    &.clickablecell-disabled {
        opacity: 0.5;
        pointer-events: none;
    }

    @media screen and (max-width: 1024px) {
    }
    @media screen and (max-width: 640px) {
    }

    color: ${(props) => props.theme.palette.common.white};

    img {
        padding-right: 12px;
        &.smaller {
            padding-right: 7px;
        }
    }

    &.delete {
        color: ${(props) => props.theme.palette.common.white};
    }

    cursor: default;

    &:hover {
        cursor: pointer;
    }

    &.house {
        margin-top: -18px;
        img {
            margin-top: 10px;
        }
    }

    &.forceheight {
        margin-top: -2px;
        min-height: 40px;

        .cellImageText {
            margin-top: -3px !important;
        }
    }

    &.smaller {
        img {
            padding-right: 7px;
        }
    }
`;

export const CellImgText = styled(Typography)`
    font-size: 11px;
    letter-spacing: 0;
    text-align: left;
    padding-top: 3px;
    max-width: 70px;
`;
