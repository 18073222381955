import { UnitStatus, UnitStatusDTO } from "./UnitStatus";
import { ModelBase } from "Core/Models";
import { computed, observable } from "mobx";

export class InstallationMapModel extends ModelBase<InstallationMapModel, InstallationMapModelDTO> {
    @observable id: number = 0;
    @observable deviceId: string = "";
    @observable simId: string = "";
    @observable number: string = "";
    @observable name: string = "";
    @observable contractorName: string = "";
    @observable lastUpdated: string = "";
    @observable latitude: number = 0;
    @observable longitude: number = 0;
    @observable roofcareAddressId: string = "";
    @observable status: UnitStatus = new UnitStatus();
    @observable hasAlertAction: boolean = false;
    @observable hasConditionSet: boolean = false;
    @observable hasUnitBehaviour: boolean = false;

    @computed public get getStatus(): UnitStatus {
        let retVal: UnitStatus = this.status;

        if (retVal === null || retVal === undefined) {
            this.status = new UnitStatus();
        }
        retVal = this.status;

        return retVal;
    }

    //fromDto is required but you can leave it blank
    fromDto(model: InstallationMapModelDTO): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
    }

    //toDto is required but you can leave it blank
    toDto(model: InstallationMapModelDTO): void {}
}

export interface InstallationMapModelDTO {
    id: number;
    deviceId: string;
    simId: string;
    name: string;
    number: string;
    contractorName: string;
    lastUpdated: string;
    latitude: number;
    longitude: number;
    roofcareAddressId: string;
    status: UnitStatusDTO;
    hasAlertAction: boolean;
    hasConditionSet: boolean;
    hasUnitBehaviour: boolean;
}
