import styled from "styled-components";
import { Box } from "@material-ui/core";

export const ProjectAddEditBox: any = styled(Box)`
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1 0 0;

    .row {
        padding-top: 15px;
        display: flex;
        flex-direction: row;
        flex: 1 0 0;
    }

    .cell-left {
        display: flex;
        flex-direction: row;
        flex: 1 0 0;
    }

    .cell-right {
        margin-left: 20px;
        display: flex;
        flex-direction: row;
        flex: 1 0 0;
    }

    .input-label {
        font-weight: 700;
        font-family: "Mont-Bold", "Montserrat", "Roboto Condensed", "Helvetica", "Arial", "sans-serif";
    }

    .rcSelect {
        margin-top: 10px;
        //background-color: yellow;
        min-width: 200px;
    }

    .client-select {
        li[data-value=""] {
            background-color: white;
            z-index: 2;
            position: sticky;
            bottom: 0;
        }
    }

    .wide-input > div.MuiBox-root {
        width: 100%;
    }
`;

export const ProjectAddEditBtns: any = styled(Box)`
    margin-top: 15px;
    padding: 15px;
    width: 100%;
    display: flex;
    flex-direction: row;

    flex: 1 0 0;
    justify-content: center;
`;
