//@ts-ignore no TS files yet
import { AxisBottom } from "@visx/axis";
//@ts-ignore no TS files yet
import { Group } from "@visx/group";
//@ts-ignore no TS files yet
import { Line } from "@visx/shape";
//@ts-ignore no TS files yet
import { Point } from "@visx/point";
import moment from "moment";
import { generateID } from "Core/Utils/Utils";

interface BottomAxisProps {
    xScale: any;
    top: number;
    left: number;
    marginLeft: number;
    width: number;
    label: string;
    stroke: string;
    tickTextFill: string;
    labelClassName: string;
    tickLabelProps: any;
    showLegend: boolean;
    showTime: boolean;
    xLegendOffset: number;
    numTicks?: number;
}

export function BottomAxis(props: BottomAxisProps) {
    const { showTime, numTicks, xLegendOffset, label, labelClassName, marginLeft, xScale, stroke, tickLabelProps, tickTextFill, top, width, showLegend } = props;

    const tickLabelSize = 8;
    const tickRotate = 0;
    const tickColor = stroke;
    const tickOffsetX = 0;
    const tickOffsetY = 10;
    const legendYOffset: number = 50;
    const legendXOffset: number = 65;

    const formatTickDate = (tick: any): string => {
        let retVal: string = "";

        let mom: moment.Moment = moment(tick.value);
        retVal = mom.format("DD/MM/YYYY");
        return retVal;
    };

    const formatTickTime = (tick: any): string => {
        let retVal: string = "";
        let mom: moment.Moment = moment(tick.value);
        retVal = mom.format("HH:mm");
        return retVal;
    };

    return (
        <AxisBottom
            label={label}
            labelClassName={labelClassName}
            left={marginLeft}
            scale={xScale}
            stroke={stroke}
            tickStroke={stroke}
            tickLabelProps={tickLabelProps}
            top={top}
            numTicks={numTicks}
            // tickFormat={ + "\r\n" + d.getHours() + ":" + ((d.getMinutes() < 10) ? "0" + d.getMinutes() : d.getMinutes() ))}
        >
            {(props: any) => {
                const axisCenter = (props.axisToPoint.x - props.axisFromPoint.x) / 2;
                return (
                    <g className="my-custom-bottom-axis">
                        {props.ticks.map((tick: any, i: number) => {
                            const tickX = tick.to.x;
                            const tickY = tick.to.y + tickLabelSize + props.tickLength - 8;
                            return (
                                <>
                                    <Group key={generateID()}>
                                        <Line
                                            key={generateID()}
                                            className="vx-axis-line"
                                            fill={"transparent"}
                                            from={new Point({ x: 0, y: 0 })}
                                            stroke={"#7B7A7E"}
                                            strokeWidth={1}
                                            to={new Point({ x: width - 65, y: 0 })}
                                        />
                                    </Group>
                                    <Group className={"vx-axis-tick"} key={generateID()}>
                                        <Line from={tick.from} stroke={tickColor} to={tick.to} key={generateID()} />
                                        <text
                                            key={generateID()}
                                            fill={tickColor}
                                            fontSize={tickLabelSize}
                                            textAnchor="middle"
                                            transform={`translate(${tickX - 4}, ${tickY}) rotate(${tickRotate})`}
                                        >
                                            {formatTickDate(tick)}
                                        </text>
                                        {showTime === true && (
                                            <text
                                                key={generateID()}
                                                fill={tickColor}
                                                fontSize={tickLabelSize}
                                                textAnchor="middle"
                                                transform={`translate(${tickX + tickOffsetX}, ${tickY + tickOffsetY}) rotate(${tickRotate})`}
                                            >
                                                {formatTickTime(tick)}
                                            </text>
                                        )}
                                    </Group>
                                </>
                            );
                        })}
                        <text fill={tickColor} fontSize="8" textAnchor="middle" transform={`translate(${axisCenter}, ${xLegendOffset})`}>
                            {props.label}
                        </text>
                    </g>
                );
            }}
        </AxisBottom>
    );
}
