import * as moment from "moment";

import { Box, Checkbox, FormControl, FormControlLabel, FormHelperText, InputLabel, MenuItem, MenuProps, Radio, TextField } from "@material-ui/core";
import { FieldType, generateID, isNullOrEmpty } from "../Utils/Utils";
import { KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ObjPathProxy, getPath } from "ts-object-path";
import React, { useEffect, useRef, useState } from "react";

import FormLabel from "@material-ui/core/FormLabel";
import { IModel } from "Core/Models/IModel";
import { IViewModel } from "Core/ViewModels/IViewModel";
import { KeyValuePair } from "Core/Models/KeyValuePair";
import { Moment } from "moment";
import MomentUtils from "@date-io/moment";
import RadioGroup from "@material-ui/core/RadioGroup";
import Select from "@material-ui/core/Select";
import { Validation } from "./Validation";
import styled from "styled-components";
import { useObserver } from "mobx-react-lite";

const Control = styled(FormControl)`
    min-width: 120px;
`;

type TModel<T> = IModel<T> & any;

type InputProps = {
    renderValue?: (selected: any) => JSX.Element;
};

export type EditableInputProps<T> = {
    "data-cy"?: string;
    className?: string;
    noFill?: boolean;
    style?: React.CSSProperties;
    viewModel: IViewModel<T>;
    fieldName: keyof FieldType<T> | string;
    selectItems?: KeyValuePair[];
    inputProps?: InputProps;
    maxLength?: number;
    required?: boolean;
    editMode?: boolean;
    shrink?: boolean;
    validateOnStart?: boolean;
    validateOnBlur?: boolean;
    fullwidth?: boolean;
    type?: "text" | "number" | "tel" | "password" | "email" | "checkbox" | "radio" | "select" | "date" | "time" | "datetime" | "multiselect";
    label?: string;
    labelSize?: string;
    defaultValue?: string;
    helperText?: string;
    color?: string;
    multiline?: boolean;
    rows?: number;
    variant?: "filled" | "outlined" | "standard";
    paddingTop?: string | number;
    paddingRight?: string | number;
    paddingBottom?: string | number;
    paddingLeft?: string | number;
    selectMenuProps?: Partial<MenuProps>;
    onSelectItemClick?: (item: KeyValuePair) => any;
    //[x: string]: IViewModel | undefined | string | boolean | InputProps | KeyValuePair[];
};

export function EditableInput<T>(props: EditableInputProps<T>) {
    const rendered = useRef<boolean>(false);
    let datacy: any = "none";

    /*const { multiline } = props.inputProps!;*/
    const [bind, { text, password, radio, checkbox, date, time, email, select, number, multiselect, tel }] = Validation<T>(props.viewModel);

    let fieldName: keyof FieldType<T> = props.fieldName as any;
    if (typeof props.fieldName !== "string") {
        const p = getPath(fieldName as string);
        fieldName = p.join(".") as any;
    }

    datacy = props["data-cy"] ? props["data-cy"] : fieldName;

    useEffect(() => {
        rendered.current = true;
        if (props.validateOnStart) {
            validate(props.viewModel.getValue(fieldName));
        }
    }, []);

    const validate = (value: any) => {
        props.viewModel.isFieldValid(fieldName, value);
    };

    const isInError = (): boolean => {
        let isValid = props.viewModel.getValid(fieldName);
        if (props.validateOnStart) {
            return !isValid;
        }

        if (!rendered.current) {
            return false;
        }
        return !isValid;
    };

    const renderControl = () => {
        if (props.type === "checkbox") {
            return checkBox();
        } else if (props.type === "radio") {
            return radiocomp();
        } else if (props.type === "select" || props.type === "multiselect") {
            return selectField();
        } else if (props.type === "date") {
            return dateComp();
        } else if (props.type === "time") {
            return timeComp();
        } else {
            return textField();
        }
    };

    const dateComp = () => {
        return (
            <>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                        data-cy={datacy}
                        className={props.className}
                        placeholder="01/JAN/2020"
                        label={props.label}
                        {...date(fieldName, { validateOnBlur: props.validateOnBlur })}
                        mask={"__-__-____"}
                        format="DD-MM-YYYY"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </MuiPickersUtilsProvider>
            </>
        );
    };

    const timeComp = () => {
        return (
            <>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardTimePicker
                        data-cy={datacy}
                        className={props.className}
                        placeholder="08:00 AM"
                        label={props.label}
                        mask={"__:__ _M"}
                        {...time(fieldName, { validateOnBlur: props.validateOnBlur })}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </MuiPickersUtilsProvider>
            </>
        );
    };

    const radiocomp = () => {
        return (
            <Control className={props.className} error={isInError()}>
                <FormLabel component="legend" id="demo-simple-radio-label">
                    {props.label}
                </FormLabel>
                <RadioGroup {...radio(fieldName, { validateOnBlur: props.validateOnBlur })} data-cy={datacy} row={true} aria-label={props.label} name={props.label}>
                    {props.selectItems!.map((item: KeyValuePair, index: number) => {
                        return <FormControlLabel data-cy={`${datacy}-${index}`} key={generateID()} value={item.value} name={props.label} control={<Radio />} label={item.key} />;
                    })}
                </RadioGroup>
                {isInError() && <FormHelperText style={{ color: "red" }}>{props.viewModel.getError(fieldName)}</FormHelperText>}
            </Control>
        );
    };

    const selectField = () => {
        return (
            <Box paddingTop={props.paddingTop} paddingRight={props.paddingRight} paddingBottom={props.paddingBottom} paddingLeft={props.paddingLeft}>
                <Control className={props.className} error={isInError()}>
                    <InputLabel id="demo-simple-select-label">{props.label}</InputLabel>
                    <Select
                        data-cy={datacy}
                        inputProps={{ ...props.inputProps, maxLength: props.maxLength, className: props.className, style: props.style }}
                        MenuProps={{
                            disableScrollLock: true,
                            ...(props.selectMenuProps || {}),
                        }}
                        {...(props.type === "multiselect"
                            ? multiselect(fieldName, { validateOnBlur: props.validateOnBlur })
                            : select(fieldName, { validateOnBlur: props.validateOnBlur }))}
                        {...(props.inputProps || {})}
                    >
                        {props.selectItems!.map((item: KeyValuePair, index: number) => {
                            return (
                                <MenuItem
                                    data-cy={`${datacy}-${index}`}
                                    key={generateID()}
                                    value={item.value}
                                    onClick={() => props.onSelectItemClick && props.onSelectItemClick(item)}
                                >
                                    {item.key}
                                </MenuItem>
                            );
                        })}
                    </Select>
                    {isInError() && <FormHelperText style={{ color: "red" }}>{props.viewModel.getError(fieldName)}</FormHelperText>}
                </Control>
            </Box>
        );
    };

    const getTypeOfInput = () => {
        if (props.type === "number") {
            return number(fieldName, { validateOnBlur: props.validateOnBlur });
        } else if (props.type === "tel") {
            return tel(fieldName, { validateOnBlur: props.validateOnBlur });
        } else if (props.type === "password") {
            return password(fieldName, { validateOnBlur: props.validateOnBlur });
        } else if (props.type === "email") {
            return email(fieldName, { validateOnBlur: props.validateOnBlur });
        }
        return text(fieldName, { validateOnBlur: props.validateOnBlur });
    };

    const textField = () => {
        if (props.editMode) {
            return (
                <Box paddingTop={props.paddingTop} paddingRight={props.paddingRight} paddingBottom={props.paddingBottom} paddingLeft={props.paddingLeft}>
                    <TextField
                        className={props.className}
                        //data-cy={props.cypress ? props.cypress : props.label?.toLowerCase().replace(/[^a-zA-Z0-9]/g,"")}
                        data-cy={datacy}
                        fullWidth
                        {...getTypeOfInput()}
                        InputLabelProps={{ shrink: props.shrink ?? undefined, style: { color: props.color, fontSize: props.labelSize } }}
                        /*InputProps={{ maxLength: props.maxLength }}*/
                        inputProps={{ ...props.inputProps, maxLength: props.maxLength, className: props.className, style: props.style }}
                        //{...props.x}
                        label={props.label}
                        defaultValue={props.defaultValue}
                        multiline={props.multiline}
                        rows={props.rows}
                        autoComplete={props.noFill ? "new-password" : props.type}
                        variant={props.variant}
                        helperText={isNullOrEmpty(props.viewModel.getError(fieldName)) ? props.helperText : props.viewModel.getError(fieldName)}
                        /*helperText={props.helperText}*/
                    />
                </Box>
            );
        } else {
            return (
                <InputLabel className={"input-label"} id={"text-label-" + generateID()}>
                    {props.viewModel.getValue(fieldName)}
                </InputLabel>
            );
        }
    };

    const checkBox = () => {
        return (
            <>
                <FormControlLabel
                    data-cy={datacy}
                    style={{ color: isInError() ? "red" : "black" }}
                    control={<Checkbox className={props.className} {...checkbox(fieldName, { validateOnBlur: props.validateOnBlur })} />}
                    label={props.label}
                />
                {isInError() && (
                    <FormHelperText style={{ color: "red" }}>
                        {/*{errorMessage}*/}
                        {props.viewModel.getError(fieldName)}
                    </FormHelperText>
                )}
            </>
        );
    };

    const formatSelectValue = (value: any) => {
        let retval = props.selectItems!.find((a) => a.value == value);
        if (retval) return retval!.key;
        else return "";
    };

    return useObserver(() => <Box>{renderControl()}</Box>);
}

EditableInput.defaultProps = {
    className: "",
    noFill: false,
    //shrink: false,
    color: "rgba(0, 0, 0, 0.7)",
    style: {},
    type: "text",
    editMode: true,
    validateOnStart: false,
    validateOnBlur: true,
    fullwidth: true,
    helperText: "",
    labelSize: "1.3em",
    paddingTop: 1,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    rows: 5,
    variant: "standard",
    inputProps: {
        maxLength: undefined,
    },
};
