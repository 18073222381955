import { Box } from "@material-ui/core";
import styled from "styled-components";
import { TabCommonBox } from "../TabCommonStyle.style";

export const InstallationWrapper: any = styled.div`
    position: relative;
    display: flex;
    flex: 1 0 0;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
`;
export const InstallationDivisor: any = styled.div`
    margin: 0 0 5px 0;
    border-bottom: 1px dashed white;
`;

export const GalleryWrapper = styled(Box)`
    display: flex;
    flex-direction: flex;
    max-width: 100%;
    flex-wrap: wrap;
    justify-content: flex-start;

    img:not(:first-child) {
        margin-left: 20px;
    }

    .gallery-image-wrapper:not(:first-child) {
        margin-left: 20px;
    }

    .gallery-image-wrapper {
        position: relative;
        max-width: 200px;

        img:hover {
            cursor: pointer;
        }

        img {
            cursor: default;
        }

        .rotateimg180 {
            -webkit-transform: rotate(180deg);
            -moz-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
            -o-transform: rotate(180deg);
            transform: rotate(180deg);
        }
    }
`;
