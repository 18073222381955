import React, { useContext } from "react";
import { Stores, StoresContext } from "Custom/Stores";
import { PrivateRoute } from "Core/Utils/React";
import { ClientListPage } from "./ClientListPage";
import { ClientAddEditPage } from "./ClientAddEditPage";
import * as RouterUtil from "Custom/Utils/routing";
import { Client } from "Custom/Globals/AppUrls";
import { ClientDetailsPage } from "./ClientDetailsPage";
import { ProjectDetailsPage } from "../Projects/Details/ProjectDetailsPage";
import { PropertyDetailsPage } from "../Properties/PropertyDetailsPage";
import { InstallationView } from "../Installations/InstallationView";

export const ClientRoutes: React.FC = () => {
    const store = useContext<Stores>(StoresContext);
    return (
        <>
            <PrivateRoute
                component={ClientListPage}
                exact
                isAllowed={() => {
                    return store.domain.AccountStore.IsLoggedIn && RouterUtil.requireAdminLogin(store);
                }}
                path={Client.Main.Client.Root}
            />
            <PrivateRoute
                component={ClientAddEditPage}
                exact
                isAllowed={() => {
                    return store.domain.AccountStore.IsLoggedIn && RouterUtil.requireAdminLogin(store);
                }}
                path={Client.Main.Client.ClientAddEditDetail}
            />
            <PrivateRoute
                component={ClientDetailsPage}
                exact
                isAllowed={() => {
                    return store.domain.AccountStore.IsLoggedIn && RouterUtil.requireAdminLogin(store);
                }}
                path={Client.Main.Client.ClientsDetail}
            />
            <PrivateRoute
                component={ProjectDetailsPage}
                exact
                isAllowed={() => {
                    return store.domain.AccountStore.IsLoggedIn && RouterUtil.requireAdminLogin(store);
                }}
                path={Client.Main.Client.Project.ClientsDetailProject}
            />
            <PrivateRoute
                component={PropertyDetailsPage}
                exact
                isAllowed={() => {
                    return store.domain.AccountStore.IsLoggedIn && RouterUtil.requireAdminLogin(store);
                }}
                path={Client.Main.Client.Project.PropertyDetail}
            />
            <PrivateRoute
                component={InstallationView}
                exact
                isAllowed={() => {
                    return store.domain.AccountStore.IsLoggedIn && RouterUtil.requireAdminLogin(store);
                }}
                path={Client.Main.Client.Project.PropertyInstallation}
            />

            <PrivateRoute
                component={PropertyDetailsPage}
                exact
                isAllowed={() => {
                    return store.domain.AccountStore.IsLoggedIn && RouterUtil.requireAdminLogin(store);
                }}
                path={Client.Main.Client.Property.ClientsDetailProperty}
            />

            <PrivateRoute
                component={InstallationView}
                exact
                isAllowed={() => {
                    return store.domain.AccountStore.IsLoggedIn && RouterUtil.requireAdminLogin(store);
                }}
                path={Client.Main.Client.Property.PropertyInstallation}
            />
        </>
    );
};
