import { Fade, MenuItem, Select, useTheme } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { runInAction } from "mobx";
import { useObserver } from "mobx-react-lite";
import { MapTabContainer, MapTabControl, MapTabLayerControl, MapTabUnitControl } from "./InstallationMapTab.style";
import { IMarker, RoofcareMap } from "Custom/Components/Map/Map";
import { InstallationsViewModel } from "./InstallationsViewModel";
import { Loader } from "Core/Components";
import { useRouter } from "Core/Utils";
import { formatAddress } from "Custom/Utils/format";
import { DEFAULTBOUNDS } from "Custom/Globals/Globals";
import L, { LatLngBounds, Map as LeafletMap } from "leaflet";
import { generateID, isNullOrUndefined } from "Custom/Utils/utils";
import { MapPopup, ColouredCheckbox, ShowLayersMapContainer, ShowUnitFilterContainer } from "Custom/StylesAppSpecific/CommonMap.styles";
import { InstallationMapModel, UnitStatus } from "Custom/Models/Domain";
import { InstallationMapTabViewModel } from "./InstallationMapTabViewModel";
import { ClientPropertyItemModel } from "Custom/Models/Domain/Clients/ClientPropertyItemModel";
import { AppUrls } from "Custom/Globals";

export const InstallationMapTabView: React.FunctionComponent = () => {
    const [installationsViewModel] = useState(() => InstallationsViewModel.Instance);
    const [viewModel] = useState(() => InstallationMapTabViewModel.Instance);
    const [showClient, setShowClient] = useState<boolean>(false);
    const theme = useTheme();
    const { history } = useRouter();
    const [bounds, setBounds] = useState<LatLngBounds>(DEFAULTBOUNDS);
    const router = useRouter();

    //useEffect below only gets run on initial render
    useEffect(() => {
        let promise = viewModel.loadMapData();

        promise.then((results: any) => {
            setBounds(viewModel.mapBounds);
        });

        return () => {
            viewModel.cleanUp();
        };
    }, []);

    const handleToggleShowingWarning = (status: UnitStatus) => {
        runInAction(() => {
            // TODO CMR viewModel.showingWarning[status] = !viewModel.showingWarning[status];
        });
    };

    const renderUnitFilterControl = () => {
        return (
            <ShowUnitFilterContainer>
                <div className="unittitle">Property and unit map</div>
                {viewModel.showProperties === true && <div></div>}
                {viewModel.showProperties === false && (
                    <>
                        <div className="installoptionrow">
                            <span>Show on map:</span>
                            {/* TODO CMR <div className="optioncell">
                                <ColouredCheckbox
                                    checked={viewModel.showingWarning[InstallationStatus.GreenWarning]}
                                    customcolour={theme.app.colors.deviceWarning.green!}
                                    onChange={() => handleToggleShowingWarning(InstallationStatus.GreenWarning)}
                                />
                                <div className="label greenwarning">
                                    <div className="celltext">Green warning</div>
                                </div>
                            </div>
                            <div className="optioncell">
                                <ColouredCheckbox
                                    checked={viewModel.showingWarning[InstallationStatus.AmberWarning]}
                                    customcolour={theme.app.colors.deviceWarning.amber!}
                                    onChange={() => handleToggleShowingWarning(InstallationStatus.AmberWarning)}
                                />
                                <div className="label amberwarning">
                                    <div className="celltext">Amber warning</div>
                                </div>
                            </div>
                            <div className="optioncell">
                                <ColouredCheckbox
                                    checked={viewModel.showingWarning[InstallationStatus.RedWarning]}
                                    customcolour={theme.app.colors.deviceWarning.red!}
                                    onChange={() => handleToggleShowingWarning(InstallationStatus.RedWarning)}
                                />
                                <div className="label redwarning">
                                    <div className="celltext">Red warning</div>
                                </div>
                            </div>
                            <div className="optioncell">
                                <ColouredCheckbox
                                    checked={viewModel.showingWarning[InstallationStatus.FloodWarning]}
                                    customcolour={theme.app.colors.deviceWarning.flood!}
                                    onChange={() => handleToggleShowingWarning(InstallationStatus.FloodWarning)}
                                />
                                <div className="label floodwarning">
                                    <div className="celltext">Flood warning</div>
                                </div>
                            </div>
                            <div className="optioncell">
                                <ColouredCheckbox
                                    checked={viewModel.showingWarning[InstallationStatus.Unknown]}
                                    customcolour={theme.app.colors.deviceWarning.unknown!}
                                    onChange={() => handleToggleShowingWarning(InstallationStatus.Unknown)}
                                />
                                <div className="label unknown">
                                    <div className="celltext">Unknown</div>
                                </div>
                            </div> */}
                        </div>
                    </>
                )}
            </ShowUnitFilterContainer>
        );
    };

    const displayPropertyDetails = (marker: IMarker): JSX.Element => {
        const property: ClientPropertyItemModel | undefined = viewModel.getProperty(marker.id);

        if (property !== undefined) {
            if (showClient === true) {
                if (isNullOrUndefined(property.clientName) === true || property.clientName.length === 0) {
                    setShowClient(false);
                }
            }

            return (
                <MapPopup>
                    <div className="propertysection">
                        <div>{property.clientName}</div>
                        <div className="address">{formatAddress(property)}</div>
                    </div>
                    <div className="unitsection">
                        <div className="unitRow">
                            <div className="view" onClick={() => goToProperty(marker.id)}>
                                View property
                            </div>
                        </div>
                    </div>
                </MapPopup>
            );
        }

        return (
            <MapPopup>
                {marker.position[0]}, {marker.position[1]}
            </MapPopup>
        );
    };

    const goToProperty = (propertyId: string) => {
        let url: string = AppUrls.Client.Main.Device.PropertyDetail;
        router.history.push(url.replace(":propertyId", propertyId));
    };

    const goToInstallation = (installationId: string) => {
        let url: string = AppUrls.Client.Main.Device.Installation;
        router.history.push(url.replace(":installId", installationId));
    };

    const getUnitStatus = (unit: InstallationMapModel): any => {
        let retVal = (
            <svg width="20" height="20">
                <circle cx="5" cy="10" r="5" stroke="#0000005E" stroke-width="1" fill="#0000005E" />
            </svg>
        );

        switch (unit.status) {
            /* TODO CMR case InstallationStatus.AmberWarning: {
                retVal = (
                    <svg width="20" height="20">
                        <circle cx="5" cy="10" r="5" stroke="#F29D29" stroke-width="1" fill="#F29D29" />
                    </svg>
                );
                break;
            }
            case InstallationStatus.FloodWarning: {
                retVal = (
                    <svg width="20" height="20">
                        <circle cx="5" cy="10" r="5" stroke="#337AB7" stroke-width="1" fill="#337AB7" />
                    </svg>
                );
                break;
            }
            case InstallationStatus.GreenWarning: {
                retVal = (
                    <svg width="20" height="20">
                        <circle cx="5" cy="10" r="5" stroke="#61AA66" stroke-width="1" fill="#61AA66" />
                    </svg>
                );
                break;
            }
            case InstallationStatus.RedWarning: {
                retVal = (
                    <svg width="20" height="20">
                        <circle cx="5" cy="10" r="5" stroke="#B42025" stroke-width="1" fill="#B42025" />
                    </svg>
                );
                break;
            } */
            default: {
                break;
            }
        }
        return retVal;
    };

    const displayInstallationDetails = (marker: IMarker): JSX.Element => {
        const property: ClientPropertyItemModel | undefined = viewModel.getProperty(marker.id);

        if (property !== undefined) {
            if (showClient === true) {
                if (isNullOrUndefined(property.clientName) === true || property.clientName.length === 0) {
                    setShowClient(false);
                }
            }

            let units = property.getUnits.slice();
            let total = units.length;
            const limit = 5; // Simon said to limit it to 5.
            let islimited: boolean = false;
            let otherUnits: number = 0;
            if (units.length > limit) {
                units = units.slice(0, limit);
                islimited = true;
                otherUnits = total - limit;
            }

            return (
                <MapPopup>
                    <div className="propertysection">
                        <div>{property.clientName}</div>
                        <div className="address">{formatAddress(property)}</div>
                    </div>
                    <div className="unitsection">
                        {units.map((unit) => {
                            return (
                                <div className="unitRow">
                                    <div className="unitStatus">{getUnitStatus(unit)}</div>
                                    <div className="unitdetails" onClick={(event) => goToInstallation(unit.id.toString())}>
                                        <div className="unitcell">{unit.name}</div>
                                    </div>
                                </div>
                            );
                        })}
                        {islimited === true && (
                            <div className="unitRow">
                                <div className="unitStatus"></div>
                                <div className="unitdetails">plus {otherUnits} other units</div>
                            </div>
                        )}
                    </div>
                </MapPopup>
            );
        }

        return (
            <MapPopup>
                {marker.position[0]}, {marker.position[1]}
            </MapPopup>
        );
    };

    const handleLayerChange = (event: any) => {
        if (event.target.value === "0" || event.target.value === 0) {
            viewModel.setShowProperties(false);
        } else {
            viewModel.setShowProperties(true);
        }
    };

    const renderPage = () => {
        if (viewModel.IsLoading) return <Loader />;

        return (
            <Fade in timeout={1000}>
                <MapTabContainer>
                    <div className="mapsection">
                        <MapTabControl>
                            <MapTabUnitControl className="content">{renderUnitFilterControl()}</MapTabUnitControl>
                            <MapTabLayerControl>
                                <Select
                                    id="layer-select"
                                    value={viewModel.showProperties === true ? 1 : 0}
                                    onChange={handleLayerChange}
                                    MenuProps={{
                                        getContentAnchorEl: null,
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left",
                                        },
                                    }}
                                    fullWidth
                                >
                                    <MenuItem key={generateID()} value={0}>
                                        Units
                                    </MenuItem>
                                    <MenuItem key={generateID()} value={1}>
                                        Properties
                                    </MenuItem>
                                </Select>
                            </MapTabLayerControl>
                        </MapTabControl>
                        <RoofcareMap
                            id="installationtab-map"
                            boundsToFit={bounds}
                            markers={viewModel.markers}
                            displayPopUp={viewModel.showProperties ? displayPropertyDetails : displayInstallationDetails}
                        />
                    </div>
                </MapTabContainer>
            </Fade>
        );
    };

    return useObserver(() => renderPage());
};
