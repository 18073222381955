import styled from "styled-components";
import { TabCommonBox } from "../TabCommonStyle.style";

export const AuditBox: any = styled(TabCommonBox)`
    padding: 5px 15px;
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    width: 100%;
    background-color: #585858;
    color: white;

    &.filterCell {
        flex-direction: row;
        .select-title {
            margin-top: -2px;
            padding-right: 15px;
        }
        .select-comp {
        }
    }
`;

export const AuditDetailsContainer: any = styled.div`
    width: 100%;

    .title {
        font-weight: 700;
    }

    .auditcell {
        min-width: 100px;
    }
    .sectiontitle {
        width: 100%;
        border-top: 1px dotted #fefefe;
        padding-top: 5px;
        font-size: 14px;
    }

    .innerHTMLblock {
        padding: 0 15px;
    }
`;
