import * as React from "react";
import { useState } from "react";
import { BaseModal } from "Custom/Components/Modal/BaseModal";
import { SuccessBtn, SuccessExtBtn } from "Custom/StylesAppSpecific/Controls/SuccessBtn";
import { CancelBtn } from "Custom/StylesAppSpecific/Controls/CancelBtn";
import {
    ContractorBox,
    ContractorSelectBox,
    DeviceBox,
    DeviceCell,
    DeviceRow,
    DeviceStatusBox,
    DeviceStatusCell,
    DeviceStatusRow,
    IntallationAddModalDialogPaper,
} from "./InstallationAddModal.styles";
import { InstallationAddViewModel } from "./InstallationAddViewModel";
import { PropertyViewModel } from "Custom/Views/Properties/PropertyViewModel";
import { formatAddress, formatDate, formatPPAddress } from "Custom/Utils/format";
import { Box, Checkbox, FormControl, FormControlLabel, FormHelperText, MenuItem, Radio, RadioGroup, Select, TextField, TextFieldProps, Typography } from "@material-ui/core";
import { useObserver, useRouter } from "Core/Base";
import { ContractorModel, DrainageType, RoofType } from "Custom/Models/Domain";
import { RoofcareInput } from "Custom/StylesAppSpecific/Controls/RoofcareInput";
import { InstallationAddEdit } from "Custom/Models/Domain/Installations/InstallationAddEdit";
import { generateID } from "Custom/Utils/utils";
import { ApiResult } from "Core/Models";
import { ControlLabel } from "Custom/Components/ControlLabel/ControlLabel";
import { InstallationNoPropertyDTO } from "./InstallationNoPropertyDTO";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Loader } from "Core/Components";
import { DeviceUnitBehaviourView } from "./DeviceUnitBehaviourView";
import { ProjectPropertyListItemModel } from "Custom/Views/Installations/Modals/ProjectPropertyListItemModel";
import { MapConsumer, MapContainer, TileLayer } from "react-leaflet";
import { DraggableMarker, IDraggableMarker } from "Custom/Components/Map/DraggablePin";
import { DEFAULTPOPUP } from "Custom/Globals/GlobalComponents";
import { InstallationAndRelatedForEditDTO } from "Custom/Models/Domain/Installations/InstallationAndRelatedForEdit";
import { UnitFirmwareDTO } from "Custom/Views/Units/UnitFirmware";
import { CommissionModal } from "Custom/Components/Modal/CommissionModal";
import { TabAttributes } from "Custom/Views/Installations/TabAttributes";
import moment from "moment";
import { BoxAdd } from "Custom/StylesAppSpecific/Controls/BoxAddWrapper";
import { AddItemText } from "Custom/StylesAppSpecific/Controls/AddEditWrapper";
import { InstallationRelatedDTO } from "Custom/Models/Domain/Installations/InstallationRelatedDTO";

interface IProps {
    open: boolean;
    onClose(saved: boolean): void;
    propertyViewModel: PropertyViewModel | undefined;
    id: string;
}

export const InstallationAddModal: React.FC<IProps> = ({ onClose, open, propertyViewModel, id }) => {
    const [viewModel] = useState(() => new InstallationAddViewModel());
    const [errorMessage, setErrorMessage] = useState("");
    const [isNew, setisNew] = useState(true);
    const [isLoading, setisLoading] = useState(false);
    const [showMap, setShowMap] = useState(false);
    const [counter, setCounter] = useState(0);
    const [devicesNoProperty, setDevicesNoProperty] = useState<InstallationNoPropertyDTO[]>([]);

    const [contractors, setContractors] = useState<ContractorModel[]>([]);
    const [contractorOptions, setContractorOptions] = useState<JSX.Element[]>([]);
    const [contractor, setContractor] = useState<string>("");

    const [properties, setProperties] = useState<ProjectPropertyListItemModel[]>([]);
    const [selectedProperty, setSelectedProperty] = useState<ProjectPropertyListItemModel | undefined>(undefined);

    const [modalTitle, setModalTitle] = useState<string>("Add new installation");

    const router = useRouter<{ installId: string }>();

    // Commission
    const [showCommission, setShowCommission] = useState(false);
    const [showCommissionButton, setShowCommissionButton] = useState(false);

    const [options, setOptions] = useState<{
        /*         testGradeOptions: JSX.Element[];
        unitStatusOptions: JSX.Element[];
        unitVersionOptions: JSX.Element[]; */
        unitFirmwareOptions: JSX.Element[];
    }>({
        /*         testGradeOptions: [],
        unitStatusOptions: [],
        unitVersionOptions: [], */
        unitFirmwareOptions: [],
    });

    React.useEffect(() => {
        let dataUpdated: boolean = false;
        /*         let tgOptions: JSX.Element[] = options.testGradeOptions;
        let usOptions: JSX.Element[] = options.unitStatusOptions;
        let uvOptions: JSX.Element[] = options.unitVersionOptions; */
        let fmOptions: JSX.Element[] = options.unitFirmwareOptions;

        /* if (options.testGradeOptions.length === 0 && viewModel.getTestGrades.length > 0) {
            tgOptions = viewModel.getTestGrades.map((c: TestGradeDTO, index: number) => {
                return (
                    <MenuItem key={generateID()} value={c.id!}>
                        {c.name}
                    </MenuItem>
                );
            });
            dataUpdated = true;
        }

        if (options.unitStatusOptions.length === 0 && viewModel.getUnitStatus.length > 0) {
            const usOptions = viewModel.getUnitStatus.map((c: DeviceUnitStatusDTO, index: number) => {
                return (
                    <MenuItem key={generateID()} value={c.id!}>
                        {c.name}
                    </MenuItem>
                );
            });
            dataUpdated = true;
        }
        if (options.unitVersionOptions.length === 0 && viewModel.getVersions.length > 0) {
            uvOptions = viewModel.getVersions.map((c: UnitVersionDTO, index: number) => {
                return (
                    <MenuItem key={generateID()} value={c.id!}>
                        {c.name}
                    </MenuItem>
                );
            });
            dataUpdated = true;
        } */

        if (options.unitFirmwareOptions.length === 0 && viewModel.getFirmware.length > 0) {
            fmOptions = viewModel.getFirmware.map((c: UnitFirmwareDTO, index: number) => {
                return (
                    <MenuItem key={generateID()} value={c.id!}>
                        {c.name}
                    </MenuItem>
                );
            });
            dataUpdated = true;
        }

        if (dataUpdated === true) {
            setOptions({ /* testGradeOptions: tgOptions, unitStatusOptions: usOptions, unitVersionOptions: uvOptions, */ unitFirmwareOptions: fmOptions });
        }
    }, [viewModel.getVersions, viewModel.getTestGrades, viewModel.getTestGrades, viewModel.getFirmware]);

    React.useEffect(() => {
        if (propertyViewModel !== null && propertyViewModel !== undefined) {
            viewModel.setRoofcareId(propertyViewModel.model.id);
        }

        return () => {
            // Clean up after yourself
            setisNew(true);
            viewModel.clearModel();
        };
    }, []);

    React.useEffect(() => {
        if (propertyViewModel !== null && propertyViewModel !== undefined) {
            viewModel.setRoofcareId(propertyViewModel.model.id);
        }
    }, [propertyViewModel, id]);

    React.useEffect(() => {
        if (viewModel !== null && viewModel !== undefined) {
            const device = viewModel.devicesNoProperty.find((a) => a.simId === viewModel.model.simId);

            if (device !== undefined) {
                viewModel.loadFromNoPropertyDevice(device);
            }
        }
    }, [viewModel.getSimId]);

    React.useEffect(() => {
        console.log("Use Effect propertyAddress:" + viewModel.getRoofcareAddressId);
        if (viewModel !== null && viewModel !== undefined) {
            let projectproperty: ProjectPropertyListItemModel | undefined = viewModel.getProperties.find((a) => a.id === viewModel.getValue("roofcareAddressId"));
            console.log("Use Effect have projectproperty??? - " + (projectproperty !== undefined).toString());
            setSelectedProperty(projectproperty);
        }
    }, [viewModel.getRoofcareAddressId]);

    React.useEffect(() => {
        setisLoading(true);
        window.scrollTo(0, 0);
        if (open === true) {
            setErrorMessage("");
            if (id === "0") {
                viewModel.setRoofcareId(undefined);
                viewModel.loadRelatedAsync().then((result: ApiResult<InstallationRelatedDTO>) => {
                    if (result.wasSuccessful) {
                        setContractors(viewModel.getContractors);
                        setDevicesNoProperty(viewModel.getDevicesNoProperty);
                        setProperties(viewModel.getProperties);
                        setSelectedProperty(viewModel.getProperties.find((a) => a.id === viewModel.getValue("roofcareAddressId")));
                        if (propertyViewModel !== undefined) {
                            viewModel.setRoofcareId(propertyViewModel.model.id);
                        }
                    } else {
                        setErrorMessage("Failed to load the contractor list");
                    }

                    if (id === "0") {
                        setisLoading(false);
                        setisNew(true);
                    }
                });
            }

            if (id !== "0") {
                setModalTitle("Edit installation");
                viewModel.loadInstallationAsync(id).then((result: ApiResult<InstallationAndRelatedForEditDTO>) => {
                    if (result.wasSuccessful) {
                        setContractors(viewModel.getContractors);
                        setDevicesNoProperty(viewModel.getDevicesNoProperty);
                        setProperties(viewModel.getProperties);
                        setSelectedProperty(viewModel.getProperties.find((a) => a.id === viewModel.getValue("roofcareAddressId")));
                        setisNew(false);
                    } else {
                        setErrorMessage("Failed to load the installation device");
                    }
                    setisLoading(false);
                });
            }
        }
    }, [open]);

    React.useEffect(() => {
        let options: JSX.Element[] = contractors.map((c: ContractorModel, index: number) => {
            return (
                <MenuItem key={generateID()} value={c.id}>
                    {c.displayName}
                </MenuItem>
            );
        });
        options.unshift(
            <MenuItem key={generateID()} value={""}>
                {"None"}
            </MenuItem>,
        );

        setContractorOptions(options);
    }, [contractors]);

    React.useEffect(() => {
        if (open) {
            setContractor(viewModel.model.contractorId);
        }
    }, [viewModel.getContractor]);

    React.useEffect(() => {
        if (open) {
            setErrorMessage(viewModel.Errors);
        }
    }, [viewModel.IsErrored]);

    const onAddInstallation = (saveAndContinue: boolean) => {
        viewModel.setFirstTime(false);
        if (!viewModel.islocalModelValid()) {
            setErrorMessage("Please fix the validation errors and re-submit.");
        } else {
            viewModel.updateUnitBehavioursFromVMs();

            const result = viewModel.okToUseSIMID().then((ret) => {
                if (ret.wasSuccessful === true && ret.payload === true) {
                    viewModel.upsertInstallation().then((result: ApiResult<InstallationAndRelatedForEditDTO>) => {
                        if (result.wasSuccessful) {
                            setisNew(false);

                            if (saveAndContinue === false) {
                                callOnClose(true);
                            } else {
                                setSelectedProperty(viewModel.getProperties.find((a) => a.id === viewModel.getValue("roofcareAddressId")));
                                setisNew(false);
                                setModalTitle("Edit installation");
                            }
                        } else {
                            window.scrollTo(0, 0);
                            setErrorMessage("Failed to save the installation device");
                        }
                    });
                } else {
                    // don't save.
                }
            });
        }
    };

    const handleContractorChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        viewModel.set("contractorId", event.target.value as any);
    };

    const isInError = (fieldName: string) => {
        let isValid = viewModel.getValid(fieldName);
        return !isValid;
    };

    const close = () => {
        callOnClose(false);
    };

    const callOnClose = (saved: boolean = false) => {
        viewModel.clearModel();
        onClose(saved);
    };

    const setSimId = (value: string) => {
        viewModel.setSimId(value);

        setCounter(counter + 1);
    };

    const successBtnText: string = isNew === true ? "Add Installation" : "Update";

    const saveAndContinueBtnText: string = isNew === true ? "Add and continue" : "Update and continue";

    const onRoofTypeChange = (e: any) => {
        viewModel.setValue("roofType", parseInt((e.target as HTMLInputElement).value));
    };

    const onDrainageTypeChange = (e: any) => {
        viewModel.setDrainageTypeValue(parseInt((e.target as HTMLInputElement).value));
        viewModel.deleteUnitBehaviour();
    };

    const addNewUnitBehaviour = (value: string) => {
        if (value.length === 0) {
            viewModel.createNewUnitBehaviour();
        } else {
            viewModel.setUnitBehaviourItem(value);
        }
    };

    const handlePropertyChange = (value: ProjectPropertyListItemModel | undefined): void => {
        if (value !== null && value !== undefined) {
            viewModel.setProperty(value);
        } else {
            viewModel.setProperty(undefined);
        }
    };

    const handlePaste = (e: any) => {
        e.preventDefault();
    };

    const handleUnitFirmwareChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        viewModel.setFirmwareValue(parseInt(event.target.value as string));
    };

    console.log(viewModel.model.deviceId);

    const onCommissionInstallation = (event: any) => {
        setShowCommission(true);
    };

    const closeCommission = () => {
        setShowCommission(false);
    };

    const onCommissionSave = (commissionDate: string | null) => {
        setShowCommission(false);

        if (moment.utc(viewModel.model.commissionDate).toISOString() !== commissionDate) {
            // Only save if the data has changed;
            let promise = viewModel.updateCommissionDate(commissionDate);

            promise.then((apiResult) => {
                viewModel.setValue("commissionDate", commissionDate);
            });
        }
    };

    const handleShowCommissionButtonClick = () => {
        setShowCommissionButton(!showCommissionButton);
    };

    return useObserver(() => (
        <>
            <CommissionModal
                open={showCommission}
                title="Commission Installation"
                onClose={closeCommission}
                onCommission={onCommissionSave}
                minDate={viewModel.model.earliestValue !== null && viewModel.model.earliestValue !== undefined ? viewModel.model.earliestValue! : viewModel.model.createdDate}
                commissionDateTime={viewModel.model.commissionDate}
            />

            <BaseModal
                title={modalTitle}
                onClose={callOnClose}
                open={open}
                PaperComponent={IntallationAddModalDialogPaper}
                actions={
                    <>
                        <SuccessExtBtn
                            onClick={() => {
                                onAddInstallation(true);
                            }}
                        >
                            {saveAndContinueBtnText}
                        </SuccessExtBtn>

                        <SuccessExtBtn
                            onClick={() => {
                                onAddInstallation(false);
                            }}
                            autoFocus
                        >
                            {successBtnText}
                        </SuccessExtBtn>
                        <CancelBtn onClick={close}>Cancel</CancelBtn>
                    </>
                }
            >
                {(viewModel.getIfLoading === true || isLoading === true) && <Loader />}

                {viewModel.getIfLoading === false && isLoading === false && (
                    <>
                        {errorMessage.length > 0 && (
                            <Box>
                                <FormHelperText style={{ color: "red" }}>{errorMessage}</FormHelperText>
                            </Box>
                        )}

                        <ContractorBox>
                            <FormControl>
                                <ContractorSelectBox className="cell-left">
                                    <ControlLabel label="Add Contractor" htmlFor="contractor-select">
                                        <Select
                                            id="contractor-select"
                                            value={viewModel.model.contractorId}
                                            onChange={handleContractorChange}
                                            MenuProps={{
                                                getContentAnchorEl: null,
                                                anchorOrigin: {
                                                    vertical: "bottom",
                                                    horizontal: "left",
                                                },
                                                className: "client-select-menu",
                                            }}
                                            style={{ width: "100%", minWidth: "300px", maxWidth: "640px" }}
                                        >
                                            {contractorOptions}
                                        </Select>
                                        <Box>{isInError("contractorId") && <FormHelperText style={{ color: "red" }}>{viewModel.getError("contractorId")}</FormHelperText>}</Box>
                                    </ControlLabel>
                                </ContractorSelectBox>
                            </FormControl>
                            <FormControl>
                                <ContractorSelectBox className="cell-left">
                                    <ControlLabel label="Property" htmlFor="property-select">
                                        <Autocomplete
                                            blurOnSelect
                                            id="pp-autocomplete"
                                            disableClearable
                                            options={properties}
                                            getOptionLabel={(option: ProjectPropertyListItemModel) => option.projectNumber + " : " + formatPPAddress(option, true)}
                                            onChange={(event: any, value: ProjectPropertyListItemModel) => handlePropertyChange(value)}
                                            value={selectedProperty}
                                            renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => {
                                                return (
                                                    <TextField
                                                        {...params}
                                                        variant="standard"
                                                        placeholder="Choose a property"
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            style: { padding: "0 10px" },
                                                        }}
                                                    />
                                                );
                                            }}
                                        />
                                        <Box>
                                            {viewModel.isRoofcareAddressInError && (
                                                <FormHelperText style={{ color: "red" }}>{viewModel.getError("roofcareAddressId")}</FormHelperText>
                                            )}
                                        </Box>
                                    </ControlLabel>
                                </ContractorSelectBox>
                            </FormControl>
                            <div style={{ padding: "15px 0" }}>
                                <div
                                    className="property-showmap"
                                    onClick={() => {
                                        setShowMap(!showMap);
                                    }}
                                >
                                    {showMap && "- Hide Map"}
                                    {!showMap && "+ Show Map"}
                                </div>
                            </div>

                            {showMap && (
                                <MapContainer center={[51.505, -0.09]} zoom={13} style={{ padding: "15px 0", width: "100%", minHeight: "350px" }}>
                                    <TileLayer
                                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    />
                                    <MapConsumer>
                                        {(map: L.Map) => {
                                            map.fitBounds(viewModel.getMapBounds);

                                            console.log("map center:", map.getCenter());
                                            return null;
                                        }}
                                    </MapConsumer>
                                    {viewModel.getMarkers &&
                                        viewModel.getMarkers.map((marker, index) => {
                                            return (
                                                <>
                                                    <DraggableMarker
                                                        id={marker.id}
                                                        key={generateID()}
                                                        icon={marker.icon}
                                                        position={marker.position}
                                                        draggable={marker.draggable === undefined ? false : marker.draggable}
                                                        onPositionChange={
                                                            (marker as IDraggableMarker).onPositionChange !== undefined ? (marker as IDraggableMarker).onPositionChange : undefined
                                                        }
                                                        marker={marker}
                                                        displayPopUp={DEFAULTPOPUP}
                                                    />
                                                </>
                                            );
                                        })}
                                </MapContainer>
                            )}
                        </ContractorBox>
                        <DeviceBox>
                            {viewModel.hasAddress === true && (
                                <DeviceRow style={{ fontWeight: "bold", fontSize: "14px", color: "white" }}>{formatAddress(viewModel.getPropertyAddress, true)}</DeviceRow>
                            )}
                            <DeviceRow>
                                <DeviceCell>
                                    <RoofcareInput<InstallationAddEdit>
                                        type="text"
                                        label="Name"
                                        validateOnBlur={true}
                                        viewModel={viewModel}
                                        fieldName="name"
                                        shrink={true}
                                        maxLength={50}
                                        fullwidth
                                        inputProps={{ placeholder: "Please enter a name" }}
                                    />
                                </DeviceCell>
                            </DeviceRow>
                            <DeviceRow>
                                {id === "0" && (
                                    <DeviceCell style={{ marginTop: "8px", zIndex: 2 }}>
                                        <ControlLabel label="Sim Id" htmlFor="simId-autocomplete">
                                            <Autocomplete
                                                blurOnSelect
                                                id="simId-autocomplete"
                                                disableClearable
                                                options={devicesNoProperty.map((option) => option.simId)}
                                                onChange={(event: any, value: any) => viewModel.setSimId(value)}
                                                value={viewModel.getValue("simId")}
                                                renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => {
                                                    return (
                                                        <TextField
                                                            {...params}
                                                            variant="standard"
                                                            placeholder="Please enter your id"
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                style: { padding: "0 10px" },
                                                                onPaste: (event: any) => handlePaste(event),
                                                            }}
                                                        />
                                                    );
                                                }}
                                            />
                                        </ControlLabel>
                                        <Box>{isInError("simId") && <FormHelperText style={{ color: "red" }}>{viewModel.getError("simId")}</FormHelperText>}</Box>
                                    </DeviceCell>
                                )}
                                {id !== "0" && (
                                    <DeviceCell style={{ marginTop: "0px", zIndex: 2 }}>
                                        <RoofcareInput<InstallationAddEdit>
                                            type="text"
                                            label="Sim Id"
                                            validateOnBlur={true}
                                            viewModel={viewModel}
                                            fieldName="simId"
                                            shrink={true}
                                            maxLength={15}
                                            inputProps={{ disabled: true, placeholder: "Please enter your Sim id", onPaste: (event: any) => handlePaste(event) }}
                                        />
                                    </DeviceCell>
                                )}

                                <DeviceCell style={{ marginTop: "0px", zIndex: 2, paddingLeft: "20px" }}>
                                    <RoofcareInput<InstallationAddEdit>
                                        type="text"
                                        label="Sim Id Confirm"
                                        validateOnBlur={true}
                                        viewModel={viewModel}
                                        fieldName="simIdConfirm"
                                        shrink={true}
                                        maxLength={15}
                                        inputProps={{
                                            disabled: isNew !== true ? true : false,
                                            placeholder: "Please re-enter your Sim id",
                                            onPaste: (event: any) => handlePaste(event),
                                        }}
                                    />
                                </DeviceCell>
                            </DeviceRow>
                            <DeviceRow className="withBorder" style={{ paddingBottom: "15px" }}>
                                <DeviceCell style={{ marginTop: "8px" }}>
                                    <RoofcareInput<InstallationAddEdit>
                                        type="text"
                                        label="Device Id"
                                        validateOnBlur={true}
                                        viewModel={viewModel}
                                        fieldName="deviceId"
                                        shrink={true}
                                        maxLength={15}
                                        inputProps={{ placeholder: "Please enter your id", onPaste: (event: any) => handlePaste(event) }}
                                    />
                                </DeviceCell>
                                <DeviceCell style={{ marginTop: "8px", paddingLeft: "20px" }}>
                                    <RoofcareInput<InstallationAddEdit>
                                        type="text"
                                        label="Device Id Confirm"
                                        validateOnBlur={true}
                                        viewModel={viewModel}
                                        fieldName="deviceIdConfirm"
                                        shrink={true}
                                        maxLength={15}
                                        inputProps={{
                                            placeholder: "Please re-enter your device id",
                                            onPaste: (event: any) => handlePaste(event),
                                        }}
                                    />
                                </DeviceCell>
                            </DeviceRow>
                            <DeviceRow>
                                <div>
                                    <p className="subtitle">Unit</p>
                                </div>
                            </DeviceRow>
                            <DeviceRow className="withBorder">
                                <div style={{ paddingBottom: "15px" }}>
                                    <FormControl>
                                        <ContractorSelectBox className="cell-left">
                                            <ControlLabel label="Firmware" htmlFor="firmware-select">
                                                <Select
                                                    id="firmware-select"
                                                    value={viewModel.model.unitData.firmwareId}
                                                    onChange={handleUnitFirmwareChange}
                                                    MenuProps={{
                                                        getContentAnchorEl: null,
                                                        anchorOrigin: {
                                                            vertical: "bottom",
                                                            horizontal: "left",
                                                        },
                                                        className: "client-select-menu",
                                                    }}
                                                    style={{ width: "100%", minWidth: "300px", maxWidth: "640px" }}
                                                >
                                                    {options.unitFirmwareOptions}
                                                </Select>
                                                <Box>{isInError("firmwareId") && <FormHelperText style={{ color: "red" }}>{viewModel.getError("firmwareId")}</FormHelperText>}</Box>
                                            </ControlLabel>
                                        </ContractorSelectBox>
                                    </FormControl>
                                </div>
                                {/*                             <div>
                                <p className="subtitleadditional">Working Height: {viewModel.getWorkingHeightString}</p>
                            </div>
                            <div className="subtitleadditional">
                                <p className="subtitleadditional">Freeboard: {viewModel.getFreeboardString}</p>
                            </div> */}
                            </DeviceRow>
                            <DeviceRow>
                                <div>
                                    <p className="subtitle">Roof Specification</p>
                                </div>
                                <div>
                                    <p className="subtitleadditional">Working Height: {viewModel.getWorkingHeightString}</p>
                                </div>
                                <div className="subtitleadditional">
                                    <p className="subtitleadditional">Freeboard: {viewModel.getFreeboardString}</p>
                                </div>
                            </DeviceRow>
                            <DeviceRow>
                                <div>
                                    <RadioGroup
                                        row
                                        aria-label="units"
                                        defaultValue={viewModel.getValue("roofType")}
                                        value={viewModel.getValue("roofType")}
                                        name="radio-buttons-group"
                                        onChange={onRoofTypeChange}
                                    >
                                        <FormControlLabel value={1} control={<Radio color="default" />} label="Pitched" />
                                        <FormControlLabel value={2} control={<Radio color="default" />} label="Flat" />
                                    </RadioGroup>
                                </div>
                                <div>
                                    <RadioGroup
                                        row
                                        aria-label="units"
                                        defaultValue={viewModel.getValue("drainageType")}
                                        value={viewModel.getValue("drainageType")}
                                        name="radio-buttons-group"
                                        onChange={onDrainageTypeChange}
                                    >
                                        <FormControlLabel value={1} control={<Radio color="default" />} label="Gravity" />
                                        <FormControlLabel value={2} control={<Radio color="default" />} label="Single Siphonics" />
                                        <FormControlLabel value={3} control={<Radio color="default" />} label="Dual Siphonics" />
                                    </RadioGroup>
                                </div>
                                <div>
                                    {viewModel.model.drainageType !== DrainageType.Gravity && (
                                        <>
                                            <Select
                                                style={{ minWidth: "200px", maxWidth: "200px" }}
                                                id={"siphonicbrand-select"}
                                                value={viewModel.model.siphonicBrand}
                                                onChange={(event) => viewModel.setValue<number>("siphonicBrand", parseInt(event.target.value as string, 10))}
                                                MenuProps={{
                                                    getContentAnchorEl: null,
                                                    anchorOrigin: {
                                                        vertical: "bottom",
                                                        horizontal: "left",
                                                    },
                                                }}
                                                fullWidth
                                            >
                                                <MenuItem key={generateID()} value="1">
                                                    Brand 1
                                                </MenuItem>
                                                <MenuItem key={generateID()} value="2">
                                                    Brand 2
                                                </MenuItem>
                                            </Select>
                                            {isInError("siphonicBrand") && <FormHelperText style={{ color: "red" }}>{viewModel.getError("siphonicBrand")}</FormHelperText>}
                                        </>
                                    )}
                                </div>
                            </DeviceRow>
                            <DeviceRow>
                                <RoofcareInput<InstallationAddEdit>
                                    type="number"
                                    label="Install Height"
                                    validateOnBlur={true}
                                    viewModel={viewModel}
                                    fieldName="install_Height"
                                    shrink={true}
                                    inputProps={{ placeholder: "Please enter height", min: 0 }}
                                />
                            </DeviceRow>
                        </DeviceBox>
                        {viewModel.model.drainageType !== DrainageType.Gravity && (
                            <DeviceStatusBox>
                                <>
                                    <p className="subtitle">Prime points</p>
                                    <DeviceStatusRow style={{ border: "none" }}>
                                        <RoofcareInput<InstallationAddEdit>
                                            type="number"
                                            label="P1"
                                            validateOnBlur={true}
                                            viewModel={viewModel}
                                            fieldName="p1"
                                            shrink={true}
                                            inputProps={{ placeholder: "Please enter height", min: 0 }}
                                        />
                                    </DeviceStatusRow>
                                    {viewModel.model.drainageType === DrainageType.DualSiphonic && (
                                        <DeviceStatusRow>
                                            <RoofcareInput<InstallationAddEdit>
                                                type="number"
                                                label="P2"
                                                validateOnBlur={true}
                                                viewModel={viewModel}
                                                fieldName="p2"
                                                shrink={true}
                                                inputProps={{ placeholder: "Please enter height", min: 0 }}
                                            />
                                        </DeviceStatusRow>
                                    )}
                                </>
                            </DeviceStatusBox>
                        )}

                        {viewModel.model.roofType === RoofType.Pitched && (
                            <DeviceStatusBox>
                                <>
                                    {/* TODO RC001 ADD SideType Drop down in here */}

                                    <DeviceStatusRow style={{ marginTop: "10px" }}>
                                        <RoofcareInput<InstallationAddEdit>
                                            type="number"
                                            label="Base Measurement"
                                            validateOnBlur={true}
                                            viewModel={viewModel}
                                            fieldName="baseMeasurement"
                                            shrink={true}
                                            fullwidth
                                            style={{ minWidth: "255px" }}
                                            inputProps={{ placeholder: "Please enter the base measurement", min: 0 }}
                                        />
                                    </DeviceStatusRow>

                                    <p className="subtitle">Side 1</p>
                                    <DeviceStatusRow>
                                        <RoofcareInput<InstallationAddEdit>
                                            type="number"
                                            label="Length mm"
                                            validateOnBlur={true}
                                            viewModel={viewModel}
                                            fieldName="side1_Length"
                                            shrink={true}
                                            inputProps={{ placeholder: "Please enter length", min: 0 }}
                                        />
                                        <DeviceStatusCell>
                                            <RoofcareInput<InstallationAddEdit>
                                                type="number"
                                                label="Angle between side 1 &#38; base"
                                                validateOnBlur={true}
                                                viewModel={viewModel}
                                                fieldName="side1_Angle"
                                                shrink={true}
                                                fullwidth
                                                style={{ minWidth: "255px" }}
                                                inputProps={{ placeholder: "Please enter angle", min: -180, max: 180 }}
                                            />
                                        </DeviceStatusCell>

                                        {/* TODO RC001  ADD second length 1 in here */}
                                    </DeviceStatusRow>

                                    <p className="subtitle">Side 2</p>
                                    <DeviceStatusRow>
                                        <RoofcareInput<InstallationAddEdit>
                                            type="number"
                                            label="Length mm"
                                            validateOnBlur={true}
                                            viewModel={viewModel}
                                            fieldName="side2_Length"
                                            shrink={true}
                                            inputProps={{ placeholder: "Please enter length", min: 0 }}
                                        />
                                        <DeviceStatusCell>
                                            <RoofcareInput<InstallationAddEdit>
                                                type="number"
                                                label="Angle between side 2 &#38; base"
                                                validateOnBlur={true}
                                                viewModel={viewModel}
                                                fieldName="side2_Angle"
                                                shrink={true}
                                                fullwidth
                                                style={{ minWidth: "255px" }}
                                                inputProps={{ placeholder: "Please enter angle", min: -180, max: 180 }}
                                            />
                                        </DeviceStatusCell>

                                        {/* TODO RC001  ADD second length 2 in here */}
                                    </DeviceStatusRow>
                                </>
                            </DeviceStatusBox>
                        )}
                        {viewModel.model.roofType === RoofType.Flat && (
                            <DeviceStatusRow>
                                <RoofcareInput<InstallationAddEdit>
                                    type="number"
                                    label="Max Height mm"
                                    validateOnBlur={true}
                                    viewModel={viewModel}
                                    fieldName="maxHeight"
                                    shrink={true}
                                    inputProps={{ placeholder: "Please enter length", min: 0 }}
                                />
                            </DeviceStatusRow>
                        )}
                        <DeviceStatusBox>
                            <p className="subtitle">Standing Water</p>
                            <DeviceStatusRow>
                                <RoofcareInput<InstallationAddEdit>
                                    type="number"
                                    label="Days to monitor"
                                    validateOnBlur={true}
                                    viewModel={viewModel}
                                    fieldName="standingWaterDays"
                                    style={{ minWidth: "255px" }}
                                    shrink={true}
                                    inputProps={{ placeholder: "Please enter days", min: 0, max: 7 }}
                                />
                                <DeviceStatusCell>
                                    <RoofcareInput<InstallationAddEdit>
                                        type="number"
                                        label="Capacity alert level (%)"
                                        validateOnBlur={true}
                                        viewModel={viewModel}
                                        fieldName="standingWaterPercent"
                                        shrink={true}
                                        fullwidth
                                        style={{ minWidth: "255px" }}
                                        inputProps={{ placeholder: "Please enter percentage", min: 0, max: 100 }}
                                    />
                                </DeviceStatusCell>
                                {/* <DeviceStatusCell>
                                    <Typography style={{ fontSize: "14px" }}>Send standing water emails:</Typography>
                                    <Box className="guttersens-checkbox">
                                        <Checkbox
                                            checked={viewModel.model.allowSWEmailSend}
                                            className="form-control"
                                            name={"allowStandingWaterEmails"}
                                            onChange={() => {
                                                viewModel.setValue("allowSWEmailSend", !viewModel.model.allowSWEmailSend);
                                            }}
                                            value={viewModel.model.allowSWEmailSend === true ? 1 : 0}
                                        />
                                    </Box>
                                </DeviceStatusCell>
                                <DeviceStatusCell>
                                    <Typography style={{ fontSize: "14px" }}>Send Standing Water SMS:</Typography>
                                    <Box className="guttersens-checkbox">
                                        <Checkbox
                                            checked={viewModel.model.allowSWSMSSend}
                                            className="form-control"
                                            name={"allowStandingWaterSMS"}
                                            onChange={() => {
                                                viewModel.setValue("allowSWSMSSend", !viewModel.model.allowSWSMSSend);
                                            }}
                                            value={viewModel.model.allowSWSMSSend === true ? 1 : 0}
                                        />
                                    </Box>
                                </DeviceStatusCell> */}
                            </DeviceStatusRow>
                        </DeviceStatusBox>
                        <DeviceStatusBox>
                            <p className="subtitle">Unit Behaviour</p>
                            {viewModel.localUnitBehaviour === undefined && (
                                <>
                                    <DeviceStatusRow style={{ border: "none" }}>
                                        <>
                                            <Select
                                                style={{ minWidth: "200px", maxWidth: "200px", marginRight: "30px" }}
                                                id={"condition-sets-select"}
                                                value={-1}
                                                onChange={(event) => addNewUnitBehaviour(event.target.value as string)}
                                                MenuProps={{
                                                    getContentAnchorEl: null,
                                                    anchorOrigin: {
                                                        vertical: "bottom",
                                                        horizontal: "left",
                                                    },
                                                }}
                                                fullWidth
                                            >
                                                {viewModel.getAdminUnitBehaviours.map((item) => {
                                                    return (
                                                        <MenuItem key={generateID()} value={item.id}>
                                                            {item.name}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </>
                                    </DeviceStatusRow>
                                </>
                            )}
                            <DeviceStatusRow>
                                <div style={{ display: "flex", flex: "1 0 0", flexDirection: "column" }}>
                                    {viewModel.hasUnitBehaviour === true && (
                                        <DeviceUnitBehaviourView
                                            id={viewModel.adminUnitBehaviourId}
                                            viewModel={viewModel.localUnitBehaviour}
                                            handleDelete={viewModel.deleteUnitBehaviour}
                                            drainageType={viewModel.model.drainageType}
                                        />
                                    )}
                                    <Box>{isInError("unitBehaviour") && <FormHelperText style={{ color: "red" }}>{viewModel.getError("unitBehaviour")}</FormHelperText>}</Box>
                                </div>
                            </DeviceStatusRow>
                        </DeviceStatusBox>
                        <DeviceStatusBox>
                            <p className="subtitle">State notification</p>
                            <DeviceStatusRow>
                                <DeviceCell>
                                    <RoofcareInput<InstallationAddEdit>
                                        type="text"
                                        label="Email Address (comma separated):"
                                        validateOnBlur={true}
                                        viewModel={viewModel}
                                        fieldName="emailAddresses"
                                        shrink={true}
                                        fullwidth
                                        inputProps={{ placeholder: "Enter a comma separated list of email addresses", width: "100%", maxWidth: "640px" }}
                                    />
                                </DeviceCell>
                            </DeviceStatusRow>
                            <DeviceStatusRow>
                                <DeviceCell>
                                    <RoofcareInput<InstallationAddEdit>
                                        type="text"
                                        label="Mobile numbers (comma separated)"
                                        validateOnBlur={true}
                                        viewModel={viewModel}
                                        fieldName="mobileNumbers"
                                        shrink={true}
                                        style={{ minWidth: "100%" }}
                                        fullwidth
                                        inputProps={{ placeholder: "Enter a comma separated list of phone numbers", width: "100%", maxWidth: "640px" }}
                                    />
                                </DeviceCell>
                            </DeviceStatusRow>
                            {isNew === false && (
                                <>
                                    <DeviceStatusRow>
                                        <DeviceStatusBox>
                                            <p className="subtitle">Commission Installation</p>

                                            {viewModel.model.deviceStatusItemId !== undefined &&
                                                viewModel.model.deviceStatusItemId !== null &&
                                                viewModel.model.deviceStatusItemId !== 0 &&
                                                viewModel.model.commissionDate !== null &&
                                                viewModel.model.commissionDate !== undefined &&
                                                viewModel.model.commissionDate.length > 0 && (
                                                    <DeviceCell style={{ marginBottom: "15px" }}>
                                                        <p className="">The installation commissioned date is {formatDate(viewModel.model.commissionDate)}</p>
                                                        <p className="">Use the button below to remove this date or move the date forward</p>
                                                    </DeviceCell>
                                                )}

                                            {viewModel.model.deviceStatusItemId !== undefined &&
                                                viewModel.model.deviceStatusItemId !== null &&
                                                viewModel.model.deviceStatusItemId !== 0 &&
                                                (viewModel.model.commissionDate === null ||
                                                    viewModel.model.commissionDate === undefined ||
                                                    viewModel.model.commissionDate.length === 0) && (
                                                    <DeviceCell style={{ marginBottom: "15px" }}>
                                                        <p className="">The installation has not been commissioned</p>
                                                        <p className="">Use the button below to commission the installation</p>
                                                    </DeviceCell>
                                                )}

                                            {(viewModel.model.deviceStatusItemId === undefined || viewModel.model.deviceStatusItemId === 0) && (
                                                <DeviceCell style={{ marginBottom: "15px" }}>
                                                    <p className="">The installation has not been commissioned</p>
                                                    <p className="">
                                                        The installation cannot be commissioned until it has a valid Unit Behaviour and the Roof specification data has been saved.
                                                    </p>
                                                </DeviceCell>
                                            )}

                                            <DeviceCell>
                                                <SuccessBtn
                                                    onClick={onCommissionInstallation}
                                                    disabled={viewModel.model.deviceStatusItemId === undefined || viewModel.model.deviceStatusItemId === 0}
                                                >
                                                    Commission
                                                </SuccessBtn>
                                            </DeviceCell>
                                        </DeviceStatusBox>
                                    </DeviceStatusRow>

                                    <DeviceStatusRow style={{ border: "none" }}>
                                        <DeviceCell>
                                            <div className="device-hide">
                                                <Checkbox checked={viewModel.model.isDeleted} onChange={() => viewModel.set("isDeleted", !viewModel.model.isDeleted)}></Checkbox>
                                                <label>Hide</label>
                                            </div>
                                        </DeviceCell>
                                    </DeviceStatusRow>
                                </>
                            )}
                        </DeviceStatusBox>
                    </>
                )}
            </BaseModal>
        </>
    ));
};
