import { action, computed, observable, runInAction } from "mobx";
import moment from "moment";

import { ViewModelBase } from "Core/ViewModels/ViewModelBase";
import { FieldType } from "Core/Utils/Utils";
import { InstallationImage, InstallationImageDto } from "Custom/Models/Domain";
import { CollapsibleInstallationImagePanelViewModel } from "./CollapsibleInstallationImagePanelViewModel";
import { Server } from "Custom/Globals/AppUrls";
import { ApiResult } from "Core/Models";
import { FileDownloadResponseDTO } from "Custom/Views/Installations/FileDownloadResponseDTO";

export class InstallationImageViewModel2 extends ViewModelBase<any> {
    // #region Constructors and Disposers

    constructor(imageDto: InstallationImageDto, parentViewModel: CollapsibleInstallationImagePanelViewModel) {
        super({});

        this.parentViewModel = parentViewModel;
        this.id = imageDto.id;
        this.url = imageDto.url;
        this.width = imageDto.width;
        this.height = imageDto.height;
        this.timeStamp = imageDto.timeStamp;
        this.view = imageDto.view;
        this.deviceId = imageDto.deviceId;
        this.simId = imageDto.simId;
        this.flipped = imageDto.flipped;
    }

    // #region Constructors and Disposers

    // #region Properties

    @observable
    public parentViewModel: CollapsibleInstallationImagePanelViewModel;

    @observable public IsDownloading: boolean = false;

    @observable
    public id: number = -1;

    @observable
    public url: string = "";

    @observable
    public width: number = 0;

    @observable
    public height: number = 0;

    @observable
    public timeStamp: string = "";

    @observable
    public view: number = 1;

    @observable
    public deviceId: string = "";

    @observable
    public simId: string = "";

    @observable
    public flipped: boolean = false;

    @computed
    public get date() {
        return moment(this.timeStamp).format("DD/MM/YYYY");
    }

    @computed
    public get time() {
        return moment(this.timeStamp).format("HH:mm");
    }

    // #endregion Properties

    // #region Actions

    @action
    public flipImage = async () => {
        const apiResult = await this.Post<InstallationImage>(Server.Api.Installation.updateImageOrientation, {
            id: this.id,
            flipped: !this.flipped,
        });

        if (apiResult.wasSuccessful) {
            this.setFlipped(apiResult.payload.flipped);
        }
    };

    @action
    public setFlipped = (flipped: boolean) => {
        this.flipped = flipped;
    };

    // #endregion Actions

    // #region Boilerplate

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    public isFieldValid(fieldName: keyof FieldType<any>): boolean {
        return true;
    }

    public downloadImage = async () => {
        let apiResult: ApiResult<FileDownloadResponseDTO> = {
            wasSuccessful: false,
            errors: [],
            headers: "",
            payload: { fileName: "", stringFile: "", contentType: "", byteFile: undefined },
        };

        try {
            if (this.IsDownloading === false && this.IsLoading === false) {
                //this.IsDownloading = true;

                window.open(Server.Api.Image.downloadDeviceImage + "?imageId=" + this.id);

                /* apiResult = await this.Post<any>(Server.Api.Image.downloadImage, {
                    id: this.id,
                });

                if (apiResult.wasSuccessful) {
                    let file: string = apiResult.payload.stringFile;
                    const type: string = apiResult.payload.contentType;
                    const fileName: string = apiResult.payload.fileName;
                    const byteFile: any = apiResult.payload.byteFile;

                    file = atob(byteFile);

                    const url = window.URL.createObjectURL(new Blob([file], { type }));
                    const link = document.createElement("a");

                    link.href = url;
                    link.setAttribute("download", fileName);

                    document.body.appendChild(link);

                    link.click();
                } */
            }
        } catch (exception) {
        } finally {
            runInAction(() => {
                this.IsDownloading = false;
            });
        }

        return apiResult;
    };

    // #endregion Boilerplate
}
