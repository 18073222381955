import * as React from "react";
import * as Mui from "@material-ui/core";
import { KeyValuePair } from "Core/Models/KeyValuePair";
import { MenuItem } from "@material-ui/core";
import { Theme } from "@material-ui/core";
import { generateID } from "Core/Utils/Utils";
import { ProjectCommunicationSortEnum, ProjectCommunicationSortHelpers } from "Custom/Models/Domain/Projects/ProjectCommunicationSort";
import { CommunicationSortContainer, CommunicationSortSelectSelect } from "./ProjectNote.style";

interface IProps {
    value: ProjectCommunicationSortEnum;
    onChange(value: ProjectCommunicationSortEnum): any;
    disabled?: boolean;
}

export const CommunicationSortSelect: React.FC<IProps> = ({ value, onChange, disabled }) => {
    const theme: Theme = Mui.useTheme();
    const options = React.useMemo<KeyValuePair[]>(
        () => [
            { key: ProjectCommunicationSortHelpers.getText(ProjectCommunicationSortEnum.DateAsc), value: ProjectCommunicationSortEnum.DateAsc },
            { key: ProjectCommunicationSortHelpers.getText(ProjectCommunicationSortEnum.DateDesc), value: ProjectCommunicationSortEnum.DateDesc },
            { key: ProjectCommunicationSortHelpers.getText(ProjectCommunicationSortEnum.FollowUpAsc), value: ProjectCommunicationSortEnum.FollowUpAsc },
            { key: ProjectCommunicationSortHelpers.getText(ProjectCommunicationSortEnum.FollowUpDesc), value: ProjectCommunicationSortEnum.FollowUpDesc },
            { key: ProjectCommunicationSortHelpers.getText(ProjectCommunicationSortEnum.ResolvedAsc), value: ProjectCommunicationSortEnum.ResolvedAsc },
            { key: ProjectCommunicationSortHelpers.getText(ProjectCommunicationSortEnum.ResolvedDesc), value: ProjectCommunicationSortEnum.ResolvedDesc },
        ],
        [],
    );

    const handleChange = (value: string) => {
        onChange(Number(value));
    };

    return (
        <CommunicationSortContainer>
            <CommunicationSortSelectSelect
                value={value}
                onChange={(c) => handleChange(c.target.value as string)}
                disabled={disabled}
                MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                    },
                }}
            >
                {options.map((s) => (
                    <MenuItem value={s.value} key={generateID()}>
                        {s.key}
                    </MenuItem>
                ))}
            </CommunicationSortSelectSelect>
        </CommunicationSortContainer>
    );
};
