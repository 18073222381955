import { action, computed, observable } from "mobx";
import { ModelBase } from "Core/Models/ModelBase";
import { InstallationMapModel, InstallationMapModelDTO } from "../Installations/InstallationMapModel";

//Base class expects the model type and the DTO model type
//If you are not using a DTO just pass in undefined as the DTO
export class ClientPropertyItemModel extends ModelBase<ClientPropertyItemModel, ClientPropertyItemModelDTO> {
    @observable propertyId: string = "";
    @observable buildingName: string = "";
    @observable addressLine1: string = "";
    @observable addressLine2: string = "";
    @observable city: string = "";
    @observable county: string = "";
    @observable postcode: string = "";
    @observable installationCount: number = 0;
    @observable projectId: string = "";
    @observable projectNumber: string = "";
    @observable projectName: string = "";
    @observable clientName: string = "";
    @observable regionalSalesManagerName: string = "";
    @observable latitude: number = 0;
    @observable longitude: number = 0;

    @observable units: InstallationMapModel[] = [];

    @computed get getUnits() {
        return this.units.slice();
    }

    @action public setUnits(units: InstallationMapModelDTO[]) {
        this.units = [];

        units.forEach((item) => {
            let dm: InstallationMapModel = new InstallationMapModel();
            dm.fromDto(item);
            this.units.push(dm);
        });
    }

    //fromDto is required but you can leave it blank
    fromDto(model: ClientPropertyItemModelDTO): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
    }

    //toDto is required but you can leave it blank
    toDto(model: ClientPropertyItemModel): void {}
}

export type ClientPropertyItemModelDTO = {
    propertyId: string;
    buildingName: string;
    addressLine1: string;
    addressLine2: string;
    city: string;
    county: string;
    postcode: string;
    installationCount: number;
    projectId: string;
    projectNumber: string;
    projectName: string;
    clientName: string;
    regionalSalesManagerName: string;
    latitude: number;
    longitude: number;
};
