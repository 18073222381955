import React, { useEffect, useState } from "react";
import { useObserver } from "mobx-react-lite";
import { useRouter } from "../../../Core/Utils";

import { Container } from "Custom/StylesAppSpecific/AppStyling";
import { ContractorAddEdit } from "./ContractorAddEdit";

export const ContractorAddEditPage: React.FC = () => {
    const { history } = useRouter();

    useEffect(() => {
        //const apiResult = viewModel.loadContractorListAsync();

        return () => {
            // Clean up after yourself
        };
    }, []);

    return useObserver(() => <Container>{<ContractorAddEdit />}</Container>);
};
