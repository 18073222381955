import { FormControlLabel, FormHelperText, Grid, Input, MenuItem, Radio, RadioGroup, Select, TextField } from "@material-ui/core";
import { useObserver } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { ConditionSectionBox } from "Custom/Views/Admin/Condition/Condition.style";
import { UnitBehaviourItemViewModel } from "./UnitBehaviourItemViewModel";
import { generateID } from "Core/Utils/Utils";
import { DrainageType } from "Custom/Models/Domain";

interface IUnitBehaviourItemProps {
    localViewModel: UnitBehaviourItemViewModel;
    onDelete: () => void;
    drainageType?: DrainageType;
    allowedit?: boolean;
}

export const UnitBehaviourItem: React.FunctionComponent<IUnitBehaviourItemProps> = ({ localViewModel, onDelete, drainageType, allowedit }) => {
    const [viewModel] = useState(localViewModel);
    const [firstTime, setFirstTime] = React.useState(true);
    const [canEdit, setCanEdit] = React.useState(true);

    //useEffect below only gets run on initial render
    useEffect(() => {
        // *optional* passing the router into the viewmodel so we can navigate from there
        // console.log("First render");

        if (allowedit !== undefined) {
            setCanEdit(allowedit);
        }

        // This gets run when the page is exited
        return () => {
            // console.log("Unmounting");
        };
    }, []);

    const onNameChange = (e: any) => {
        viewModel.setValue("name", e.target.value);
    };

    const onValueChange = (e: any) => {
        // Stop the control passing us a number as text
        let value: number = e.target.value;

        // BUT that filters out '-' which comes through as '' so
        // check for both and allow through!
        if (e.target.value === "-" || e.target.value === "") {
            value = e.target.value;
        } else {
            value = Number(e.target.value);
        }

        viewModel.setValue("value", value);
    };

    const onUnitChange = (e: any) => {
        viewModel.setValue("units", parseInt((e.target as HTMLInputElement).value));
    };

    const onTypeChange = (e: any) => {
        viewModel.setValue("type", parseInt((e.target as HTMLInputElement).value));
    };

    const isInError = (fieldName: string) => {
        let isValid = viewModel.getValid(fieldName);
        return !isValid;
    };

    const handleDelete = () => {
        viewModel.setValue("isDeleted", true);
        onDelete();
    };

    const index: string = generateID();
    const wrapperClassName: string = canEdit === true ? "" : "disablewrapper";
    const gridClassName: string = canEdit === true ? "" : "disablegrid";
    const handleFocus = (event: any) => event.target.select();

    function renderPage() {
        return (
            <Grid container direction="column" spacing={1} style={{ padding: "10px 0" }}>
                <Grid container direction="row" spacing={1}>
                    <Grid item xs={2} className="column-title">
                        <Input disabled={!canEdit} className="form-control" type="text" value={viewModel.getValue("name")} onChange={onNameChange} inputProps={{ maxLength: 15 }} />
                        {/* !firstTime && */ isInError("name") && <FormHelperText style={{ color: "red" }}>{viewModel.getError("name")}</FormHelperText>}
                    </Grid>
                    <Grid item xs={3} className="column-title">
                        {viewModel.model.commandIndex !== 0 && (
                            <ConditionSectionBox>
                                <div className="type">
                                    <Select
                                        disabled={!canEdit}
                                        className="form-control"
                                        value={viewModel.getValue("type")}
                                        onChange={onTypeChange}
                                        MenuProps={{
                                            getContentAnchorEl: null,
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "left",
                                            },
                                        }}
                                    >
                                        <option value={0}>H</option>
                                        <option value={1}>FB</option>
                                        {(drainageType === undefined || drainageType === null || drainageType >= DrainageType.SingleSiphonic) && <option value={2}>P1</option>}
                                        {(drainageType === undefined || drainageType === null || drainageType === DrainageType.DualSiphonic) && <option value={3}>P2</option>}
                                    </Select>
                                    {/* !firstTime &&  */ isInError("type") && <FormHelperText style={{ color: "red" }}>{viewModel.getError("type")}</FormHelperText>}
                                </div>
                                <div className="value">
                                    <Input
                                        disabled={!canEdit}
                                        className="form-control"
                                        type="number"
                                        value={viewModel.getValue("value")}
                                        onChange={onValueChange}
                                        onFocus={handleFocus}
                                        inputProps={{
                                            placeholder: viewModel.getValue("units") === 0 ? "Range 0-1000" : "-100-100",
                                            maxLength: 4,
                                            min: viewModel.getValue("units") === 0 ? -1000 : -100,
                                            max: viewModel.getValue("units") === 0 ? 1000 : 100,
                                        }}
                                    />
                                    {/* !firstTime &&  */ isInError("value") && <FormHelperText style={{ color: "red" }}>{viewModel.getError("value")}</FormHelperText>}
                                </div>
                                <div className="units">
                                    <RadioGroup
                                        row
                                        aria-label="units"
                                        defaultValue={viewModel.getValue("units")}
                                        value={viewModel.getValue("units")}
                                        name="radio-buttons-group"
                                        onChange={onUnitChange}
                                    >
                                        <FormControlLabel value={0} control={<Radio disabled={!canEdit} color="default" />} label="mm" />
                                        <FormControlLabel value={1} control={<Radio disabled={!canEdit} color="default" />} label="%" />
                                    </RadioGroup>
                                </div>
                            </ConditionSectionBox>
                        )}
                    </Grid>
                    <Grid item xs={2} className="column-title" style={{ position: "relative" }}>
                        <div className="section-options-value">
                            <div>
                                <TextField
                                    disabled={!canEdit}
                                    className="form-control gridcommand"
                                    type="number"
                                    value={viewModel.model.reading}
                                    onFocus={handleFocus}
                                    onChange={(event) => viewModel.setValue<number>("reading", parseInt(event.target.value, 10))}
                                    inputProps={{ placeholder: viewModel.getReadingPlaceHolder, maxLength: 3, min: viewModel.getMinReading, max: viewModel.getMaxReading }}
                                />
                            </div>
                            {isInError("reading") && <FormHelperText style={{ color: "red" }}>{viewModel.getError("reading")}</FormHelperText>}
                        </div>
                    </Grid>
                    <Grid item xs={2} className="column-title">
                        <Select
                            disabled={!canEdit}
                            id={"freq-select-red"}
                            value={viewModel.model.frequency}
                            onChange={(event) => viewModel.setValue<number>("frequency", parseInt(event.target.value as string, 10))}
                            MenuProps={{
                                getContentAnchorEl: null,
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                },
                            }}
                            fullWidth
                        >
                            <MenuItem key={generateID()} value="3">
                                3 x Reading Frequency
                            </MenuItem>
                            <MenuItem key={generateID()} value="4">
                                4 x Reading Frequency
                            </MenuItem>
                            <MenuItem key={generateID()} value="6">
                                6 x Reading Frequency
                            </MenuItem>
                            <MenuItem key={generateID()} value="8">
                                8 x Reading Frequency
                            </MenuItem>
                        </Select>
                        {isInError("frequency") && <FormHelperText style={{ color: "red" }}>{viewModel.getError("frequency")}</FormHelperText>}
                    </Grid>
                    {/*                     <Grid item xs={1} className="column-title">
                        <DeleteCircle onClick={handleDelete} style={{ marginTop: "4px" }}>
                            X
                        </DeleteCircle>
                    </Grid> */}
                </Grid>
            </Grid>
        );
    }

    return useObserver(() => renderPage());
};
