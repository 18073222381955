import { Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import { ClientViewModel } from "./ClientViewModel";
import { useObserver } from "Core/Base";
import { DeleteModal } from "Custom/Components/Modal/DeleteModal";

import { ContactListPage, ContactListPaper, InternalListToo, ListWrapper } from "Custom/StylesAppSpecific/Controls/ContactBoxes";

import { ContactAddRow as ComponentContactAddRow, ContactListView, ContactModel as ComponentContactModel, ContactViewModel } from "Custom/Components/Contact/";
import { ContactAddModal } from "Custom/Components/Contact/ContactModal.View";

type ClientContactsProps = {
    clientViewModel: ClientViewModel;
};

export const ClientContacts: React.FunctionComponent<ClientContactsProps> = (props: ClientContactsProps) => {
    const [viewModel, setViewModel] = useState(() => new ClientViewModel());
    const [deleteOpen, setDeleteOpen] = React.useState(false);
    const [selectedContact, selectContact] = React.useState("");
    const [contactOpen, setContactOpen] = React.useState(false);
    const [modalContact, setModalContact] = React.useState<ComponentContactModel>(new ComponentContactModel());

    useEffect(() => {
        setViewModel(props.clientViewModel);
    }, [props.clientViewModel]);

    const handleDeleteOpen = () => {
        setDeleteOpen(true);
    };

    const handleDeleteClose = () => {
        setDeleteOpen(false);
        selectContact("");
    };

    const handleDeleteContact = () => {
        modalContact.isDeleted = true;

        const contactViewModel = new ContactViewModel();
        contactViewModel.setContact(modalContact);
        props.clientViewModel.updateContact(modalContact);
        setDeleteOpen(false);
        selectContact("");
    };

    const handleDelete = async (contact: ComponentContactModel) => {
        setModalContact(contact);
        handleDeleteOpen();
    };

    const handleSaveContact = async (contact: ComponentContactModel) => {
        props.clientViewModel.updateContact(contact);
        setContactOpen(false);
    };

    const handleOpen = async (contact: ComponentContactModel) => {
        setModalContact(contact);
        setContactOpen(true);
    };

    const handleContactClose = () => {
        setContactOpen(false);
    };

    const getContactOptions = (contacts: ComponentContactModel[]): JSX.Element | JSX.Element[] => {
        if (contacts.length == 0) {
            return <div style={{ color: "white" }}>There are currently no contacts added to this client</div>;
        } else {
            let contactList: ComponentContactModel[] = contacts;

            return (
                <>
                    <ContactListView contactsList={contactList} onOpen={(c: ComponentContactModel) => handleOpen(c)} onDelete={(c: ComponentContactModel) => handleDelete(c)} />
                </>
            );
        }
    };

    const addContact = (model: ComponentContactModel) => {
        props.clientViewModel.addContact(model);
    };

    return useObserver(() => (
        <>
            <ContactAddModal onClose={handleContactClose} open={contactOpen} onAdd={handleSaveContact} contact={modalContact} />
            <DeleteModal
                open={deleteOpen}
                onClose={handleDeleteClose}
                onDelete={handleDeleteContact}
                title="Delete Client Contact?"
                text="Are you sure you want to delete this contact?"
            />
            <ContactListPage>
                <ComponentContactAddRow addContact={addContact} title={"Contact information"} />
                <ListWrapper>
                    <Typography variant="caption" className="titlePanel">
                        Contacts
                    </Typography>
                    <ContactListPaper variant="outlined">
                        <InternalListToo>{getContactOptions(viewModel.getComponentContacts)}</InternalListToo>
                    </ContactListPaper>
                </ListWrapper>
            </ContactListPage>
        </>
    ));
};
