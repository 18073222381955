import styled from "styled-components";

export const PropertyDetailsPageContainer = styled.div`
    background: ${(props) => props.theme.app.colors.grey.back} 0% 0% no-repeat padding-box;

    .info-address {
        padding: 20px 30px 16px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .back-btn {
            color: white;
            font-size: 10px;
            display: flex;
            align-items: center;
            user-select: none;
            cursor: pointer;
            outline: none;
            padding-bottom: 3px;

            .back-icon {
                margin-left: -10px;
                color: ${(props) => props.theme.app.colors.orange.main};
                font-size: 18px;
                font-weight: 700;
                font-family: "Mont-Bold", "Montserrat", "Roboto Condensed", "Helvetica", "Arial", "sans-serif";
                display: flex;
                margin-bottom: 1px;
            }
        }

        h2 {
            color: white;
            font-weight: normal;
            margin-top: 4px;

            span {
                font-size: 14px;
            }
        }
    }

    > div.content {
        //background-color: white;
        color: #ffffff;
    }
`;
