import * as React from "react";
import * as MobXReact from "mobx-react-lite";
import { PropertyNotesContainer } from "./PropertyNotes.styled";
import { Typography } from "@material-ui/core";
import { PropertyViewModel } from "../../../../Properties/PropertyViewModel";
import { PropertyDetailViewModel } from "../../../../CommonViews/PropertyDetails/PropertyDetailViewModel";
import { isNullOrEmpty } from "../../../../../../Core/Utils/Utils";
import { AddEditPropertyNote } from "./AddEditPropertyNote";
import iconEditNote from "Custom/Content/editNoteIcon.svg";
import { Stores, StoresContext } from "Custom/Stores";
import * as RouterUtil from "Custom/Utils/routing";
interface IProps {
    viewModel: PropertyViewModel | PropertyDetailViewModel;
}

export const PropertyNotes: React.FC<IProps> = ({ viewModel }) => {
    const store = React.useContext<Stores>(StoresContext);
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [isNoteNew, setIsNoteNew] = React.useState(false);
    const [isEditing, setIsEditing] = React.useState(false);
    const [allowedToEdit, setAllowedToEdit] = React.useState(false);

    React.useEffect(() => {
        if (!viewModel.IsLoading) {
            const isNew = isNullOrEmpty(viewModel.getValue("note"));
            setIsNoteNew(isNew);
            setIsEditing(isNew);
            setIsLoaded(true);
        }
    }, [viewModel.IsLoading]);

    React.useEffect(() => {
        // const allowEdit: boolean = RouterUtil.requireAdminLogin(store) === true;

        // SH049 Clients and Contractors and Admin can all edit / add notes
        const allowEdit: boolean = RouterUtil.requireAnyLoggedIn(store) === true;
        setAllowedToEdit(allowEdit);
    }, []);

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const hasaNote = (): string => {
        let retVal: string = "No property Note";
        const note: string = viewModel.getValue("note");
        if (note !== undefined && note !== null && note.length > 0) {
            retVal = note;
        }
        return retVal;
    };

    return MobXReact.useObserver(() => (
        <PropertyNotesContainer>
            <Typography variant="h1" component="h3">
                Property notes
                {(isEditing && allowedToEdit) ||
                    (isLoaded && allowedToEdit && (
                        <span className="edit-button" role="button" tabIndex={0} onClick={handleEditClick}>
                            <img src={iconEditNote} alt="" />
                            Edit this note
                        </span>
                    ))}
            </Typography>
            {isEditing && allowedToEdit ? (
                <AddEditPropertyNote viewModel={viewModel} isNew={isNoteNew} />
            ) : (
                <Typography variant="body1" className="property-note">
                    {hasaNote()}
                </Typography>
            )}
        </PropertyNotesContainer>
    ));
};
