import { PrivateRoute } from "Core/Utils";
import * as React from "react";
import { AppUrls } from "Custom/Globals";
import * as RouterUtil from "Custom/Utils/routing";
import { StoresInstance } from "Core/Base";
import { PropertyDetailPage } from "Custom/Views/CommonViews/PropertyDetails/PropertyDetailPage";
import { InstallationView } from "Custom/Views/Installations/InstallationView";
import { ProjectDetailsPage } from "Custom/Views/Projects/Details/ProjectDetailsPage";
import { ProjectAddEditPage } from "Custom/Views/Projects/ProjectAddEditPage";
import { EstateView } from "./EstateView";

export const EstateRoutes: React.FC = () => {
    return (
        <>
            {/*********** PROPERTIES Admin and Not ***********/}
            <PrivateRoute
                path={AppUrls.Client.Main.EstateView.Root}
                exact
                isAllowed={() => {
                    return StoresInstance.domain.AccountStore.IsLoggedIn && RouterUtil.requireAdminLogin(StoresInstance);
                }}
                component={EstateView}
            />
            <PrivateRoute
                path={AppUrls.Client.Main.EstateView.Property.PropertyDetail}
                exact
                isAllowed={() => {
                    return StoresInstance.domain.AccountStore.IsLoggedIn && RouterUtil.requireAdminLogin(StoresInstance);
                }}
                component={PropertyDetailPage}
            />
            <PrivateRoute
                path={AppUrls.Client.Main.EstateView.Property.PropertyInstallation}
                exact
                isAllowed={() => {
                    return StoresInstance.domain.AccountStore.IsLoggedIn && RouterUtil.requireAdminLogin(StoresInstance);
                }}
                component={InstallationView}
            />
            {/*********** PROJECTS - Admin Only ***********/}
            <PrivateRoute
                path={AppUrls.Client.Main.EstateView.Project.ProjectDetail}
                exact
                isAllowed={() => {
                    return StoresInstance.domain.AccountStore.IsLoggedIn && RouterUtil.requireAdminLogin(StoresInstance);
                }}
                component={ProjectDetailsPage}
            />
            <PrivateRoute
                path={AppUrls.Client.Main.EstateView.Project.PropertyDetail}
                exact
                isAllowed={() => {
                    return StoresInstance.domain.AccountStore.IsLoggedIn && RouterUtil.requireAdminLogin(StoresInstance);
                }}
                component={PropertyDetailPage}
            />
            <PrivateRoute
                path={AppUrls.Client.Main.EstateView.Project.PropertyInstallation}
                exact
                isAllowed={() => {
                    return StoresInstance.domain.AccountStore.IsLoggedIn && RouterUtil.requireAdminLogin(StoresInstance);
                }}
                component={InstallationView}
            />
            <PrivateRoute
                path={AppUrls.Client.Main.EstateView.Project.ProjectAddEdit}
                exact
                isAllowed={() => {
                    return StoresInstance.domain.AccountStore.IsLoggedIn && RouterUtil.requireAdminLogin(StoresInstance);
                }}
                component={ProjectAddEditPage}
            />
            {/* Unit List Stuff */}
            {/* <PrivateRoute
                component={InstallationsView}
                exact
                isAllowed={() => {
                    return StoresInstance.domain.AccountStore.IsLoggedIn && RouterUtil.requireAdminLogin(StoresInstance);
                }}
                path={AppUrls.Client.Main.EstateView.Device.DeviceRoot}
            />
            <PrivateRoute
                component={PropertyDetailsPage}
                exact
                isAllowed={() => {
                    return StoresInstance.domain.AccountStore.IsLoggedIn && RouterUtil.requireAdminLogin(StoresInstance);
                }}
                path={AppUrls.Client.Main.EstateView.Device.PropertyDetail}
            />
            <PrivateRoute
                component={InstallationView}
                exact
                isAllowed={() => {
                    return StoresInstance.domain.AccountStore.IsLoggedIn && RouterUtil.requireAdminLogin(StoresInstance);
                }}
                path={AppUrls.Client.Main.EstateView.Device.PropertyInstallation}
            />
            <PrivateRoute
                component={InstallationView}
                exact
                isAllowed={() => {
                    return StoresInstance.domain.AccountStore.IsLoggedIn && RouterUtil.requireAdminLogin(StoresInstance);
                }}
                path={AppUrls.Client.Main.EstateView.Device.Installation}
            /> */}
        </>
    );
};
