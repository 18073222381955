import { Box } from "@material-ui/core";
import styled from "styled-components";

export const Main = styled.section`
    margin-top: 15px;
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: row;
    width: 100%;
    // background-color: green;

    @media screen and (max-width: 1024px) {
    }

    @media screen and (max-width: 640px) {
        flex-direction: column;
    }

    .filtertablewrapper {
        display: flex;
        flex: 1 0 1;
        flex-direction: column;
        width: 100%;
    }

    .property-showmap {
        color: #ee8b1f;
        cursor: default;

        :hover {
            cursor: pointer;
        }
    }
`;

export const PropertyAddressWrapper: any = styled(Box)`
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: row !important;
    width: 100%;
    justify-content: space-between;

    &.MuiBox-root {
        flex-direction: row !important;
    }
`;

export const PropertyAddressAndMap: any = styled(Box)`
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: row !important;
    width: 100%;
    justify-content: space-between;

    &.MuiBox-root {
        flex-direction: row !important;
    }

    .column-1 {
        padding-right: 7px;
    }

    .column-2 {
        padding-left: 8px;
    }
`;

export const PropertyAddressColumn: any = styled(Box)`
    width: 100%;
    max-width: 50%;

    &.column-1 {
        padding-right: 7px;
    }

    &.column-2 {
        padding-left: 8px;
    }
`;

export const PropertyAddressBox: any = styled(Box)`
    padding: 5px;
    display: flex;
    flex: 1 1 0;
    flex-direction: column;
    /*     background: transparent linear-gradient(180deg, #565758 0%, #373a40 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px -3px 6px #00000029; */
`;

export const PropertyMapBox: any = styled(Box)`
    display: flex;
    flex: 1 1 0;
    flex-direction: column;
    /*     background: transparent linear-gradient(180deg, #565758 0%, #373a40 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px -3px 6px #00000029; */
`;

export const PropertyMapLatLongBox: any = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;

    > div {
        width: 50%;
    }
`;

export const AddressLineBox: any = styled(Box)`
    padding: 5px 15px;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1 0 0;
    cursor: default;

    &:hover {
        background-color: ${(props) => props.theme.app.colors.blue.light};
        color: ${(props) => props.theme.app.colors.white.main};
        cursor: pointer;
    }
`;

export const AddressRow: any = styled(Box)`
    display: flex;
    flex-direction: row;
    flex: 1 0 0;
    flex-grow: 1;
    justify-content: space-between;

    .searchaddressbox {
        display: flex;
        flex-direction: row;
        flex: 1 0 0;
        flex-grow: 1;
        padding-right: 20px;
    }

    button {
        margin-top: 5px;
    }
`;
export const AddressResultBox: any = styled(Box)`
    padding: 5px 15px;
    width: 100%;
    max-width: 640px;
    position: fixed;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    flex: 1 0 0;
    flex-grow: 1;
    cursor: default;
    border: 1px solid ${(props) => props.theme.app.colors.grey.border};
    height: auto;
    max-height: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    overflow-y: auto;
    z-index: 10;
    margin-top: 58px;
`;
