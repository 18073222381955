import React, { useEffect, useState } from "react";
import { useObserver } from "mobx-react-lite";
import { useRouter } from "Core/Utils";
import { ResetSearchIcon } from "Custom/Components/Table/ResetSearchIcon";
import { ClientViewPropertyListViewModel } from "./ClientViewPropertyListViewModel";

import { RoofcareTable, RoofcareTableWrapper } from "Custom/Components/Table/RoofcareTable";
import { Container } from "Custom/StylesAppSpecific/AppStyling";
import { AppUrls } from "Custom/Globals";
import { PropertyListPageContainer } from "Custom/Views/CommonViews/PropertyListPage.styles";
import { Paper } from "@material-ui/core";
import { Loader } from "Core/Components";
import { ITab, Tabs } from "Custom/Components/Tabs/Tabs";
import { useHasRoutedTabs } from "Custom/Utils/useHasRoutedTabs";
import { InstallationMapViewModel } from "../CommonViews/InstallationMap/InstallationMapViewModel";
import { InstallationMap } from "../CommonViews/InstallationMap/InstallationMap";
import { ViewModel } from "mobx-utils";

import * as Defaults from "Custom/StylesAppSpecific/TableOptions";

const TAB_PROPERTIES = "properties";
const TAB_MAP = "map";

const tabOptions: ITab[] = [
    { key: TAB_PROPERTIES, title: "List View", hash: "properties" },
    { key: TAB_MAP, title: "Map View", hash: "map" },
];

export const ClientViewPropertyListPage: React.FC = () => {
    const [viewModel] = useState(() => ClientViewPropertyListViewModel.Instance);
    const [installationMapViewModel, setInstallatioMapViewModel] = useState(() => new InstallationMapViewModel());
    const { history } = useRouter();

    const hashTab = useHasRoutedTabs(tabOptions);
    const [selectedTab, setSelectedTab] = React.useState(hashTab?.key || TAB_PROPERTIES);
    const [tabKey, setTabKey] = React.useState(0);
    const tableOptions = Defaults.GetRoofcareTableOptions() as any;

    useEffect(() => {
        if (viewModel.IsLoading === false) {
            const apiResult = viewModel.loadPropertyListAndDevicesAsync();
            apiResult.then((result) => {
                if (result.wasSuccessful == true) {
                    const temp: InstallationMapViewModel = new InstallationMapViewModel();
                    temp.setInstallations(viewModel.getInstallations);
                    setInstallatioMapViewModel(temp);
                } else {
                }
            });
        }

        return () => {
            // Clean up after yourself
        };
    }, []);

    const handleTabClick = (tab: ITab) => {
        setSelectedTab(tab.key);
        setTabKey(tabKey + 1);
    };

    const getTabKey = (): string => {
        return `contractorviewhome-tab-${selectedTab}-${tabKey}`;
    };

    const renderTabContent = () => {
        switch (selectedTab) {
            case TAB_PROPERTIES:
                return (
                    <RoofcareTableWrapper key={getTabKey()} paddingTop="5px !important">
                        <RoofcareTable
                            columns={[
                                { title: "Property", field: "propertyAddress", sorting: true, filtering: true },
                                { title: "Installations", field: "installationCount", sorting: true, filtering: false },
                                { title: "Project No", field: "projectNumber", sorting: true, filtering: false },
                                { title: "Project Name", field: "projectName", sorting: true, filtering: false },
                                { title: "End user", field: "clientName", sorting: true, filtering: false },
                                { title: "Regional Sales Manager", field: "regionalSalesManagerName", sorting: true, filtering: false },
                            ]}
                            options={{
                                thirdSortClick: false,
                                pageSize: 50,
                                pageSizeOptions: [10, 20, 50, 100],
                                emptyRowsWhenPaging: false,
                                headerStyle: {
                                    ...tableOptions.headerStyle,
                                },
                                rowStyle: {
                                    ...tableOptions.rowStyle,
                                },
                                searchFieldAlignment: "left",
                                searchFieldStyle: {
                                    border: "1px solid #333333",
                                    borderRadius: "5px",
                                    width: "400px",
                                },
                            }}
                            data={viewModel.propertyTableData}
                            title="Property"
                            icons={{
                                ResetSearch: () => <ResetSearchIcon />,
                            }}
                            components={{ Container: (props: any) => <Paper {...props} elevation={0} /> }}
                            localization={{ toolbar: { searchPlaceholder: "Start typing to search" } }}
                            onRowClick={(_: any, rowData: any) => handleRowClick(rowData)}
                        />
                    </RoofcareTableWrapper>
                );
            case TAB_MAP:
                return <InstallationMap viewModel={installationMapViewModel} properties={viewModel.propertyTableData} showClient={false} showContractor />;
        }
    };

    const handleRowClick = (rowData: { id: string; projectId: string }) => {
        history.push(AppUrls.Client.ClientView.Property.PropertyDetail.replace(":propertyId", rowData.id).replace(":projectId", rowData.projectId));
    };

    const renderPage = () => {
        if (viewModel.IsLoading) return <Loader />;

        return (
            <Container>
                <PropertyListPageContainer>
                    <Tabs tabs={tabOptions} onTabClick={handleTabClick} selected={selectedTab} minTabWidth={132} tabPadding="11.5px 8px" />
                    <div className="content">{renderTabContent()}</div>
                </PropertyListPageContainer>
            </Container>
        );
    };

    return useObserver(() => renderPage());
};
