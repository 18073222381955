import { AddressModel } from "Custom/Models/Domain/Address/AddressModel";

export type OSMLocation = {
    latitude: number;
    longitude: number;
};

export const getStringIfExist = (original: string | undefined | null): string => {
    return original === undefined || original === null ? "" : original;
};

export const selectOSMAddress = (item: any): AddressModel | undefined => {
    let address: AddressModel | undefined = undefined;
    /* Address details in the xml and json formats return a list of names 
    together with a designation label. 
    Per default the following labels may appear:

    continent
    country, country_code
    region, state, state_district, county
    municipality, city, town, village
    city_district, district, borough, suburb, subdivision
    hamlet, croft, isolated_dwelling
    neighbourhood, allotments, quarter
    city_block, residental, farm, farmyard, industrial, commercial, retail
    road
    house_number, house_name
    emergency, historic, military, natural, landuse, place, railway, man_made, 
    aerialway, boundary, amenity, aeroway, club, craft, leisure, office, mountain_pass, 
    shop, tourism, bridge, tunnel, waterway
 */

    if (item.raw !== undefined || item.raw !== null) {
        if (item.raw.address !== undefined || item.raw.address !== null) {
            address = new AddressModel();
            const data = item.raw.address;

            const house_number: string = getStringIfExist(data.house_number);
            const house_name: string = getStringIfExist(data.house_name);
            const hasNumberName: boolean = house_number.length > 0 || house_name.length > 0;

            address.addressLine1 = getStringIfExist(data.road);

            const commercial: string = getStringIfExist(data.commercial);
            const industrial: string = getStringIfExist(data.industrial);
            const retail: string = getStringIfExist(data.retail);
            const shop: string = getStringIfExist(data.shop);

            if (address.addressLine1.length == 0) {
                if (commercial.length > 0) {
                    address.addressLine1 = commercial;
                } else if (industrial.length > 0) {
                    address.addressLine1 = industrial;
                } else if (retail.length > 0) {
                    address.addressLine1 = retail;
                } else if (shop.length > 0) {
                    address.addressLine1 = shop;
                }
            }

            if (hasNumberName === true) {
                // tack on the number or name before address 1
                if (house_number.length > 0) {
                    address.addressLine1 = (house_number + " " + address.addressLine1).trim();
                } else if (house_name.length > 0) {
                    address.addressLine1 = (house_name + " " + address.addressLine1).trim();
                }
            }

            const residential: string = getStringIfExist(data.residential);
            const suburb: string = getStringIfExist(data.suburb);
            if (residential.length > 0) {
                address.addressLine2 = residential;
            } else if (suburb.length > 0) {
                address.addressLine2 = suburb;
            }
            address.addressLine2 = getStringIfExist(data.suburb);

            const municipality: string = getStringIfExist(data.municipality);
            const village: string = getStringIfExist(data.village);
            const town: string = getStringIfExist(data.town);
            const city: string = getStringIfExist(data.city);
            if (city.length > 0) {
                address.city = city;
            } else if (town.length > 0) {
                address.city = town;
            } else if (village.length > 0) {
                address.city = village;
            } else if (municipality.length > 0) {
                address.city = municipality;
            }

            address.county = getStringIfExist(data.county);

            if (address.county.length > 0) {
                address.county = address.county.replace("Combined Authority", "");
            }

            if (address.county.length == 0) {
                // then check the other options
                const region: string = getStringIfExist(data.region);
                const state: string = getStringIfExist(data.state);
                const state_district: string = getStringIfExist(data.state_district);
                if (region.length > 0) {
                    address.county = region;
                } else if (state.length > 0) {
                    address.county = state;
                } else if (state_district.length > 0) {
                    address.county = state_district;
                }
            }
            address.postcode = getStringIfExist(data.postcode);

            if (item.raw.lat !== undefined || item.raw.lat !== null) {
                address.locationLatitude = parseFloat(item.raw.lat);
            }
            if (item.raw.lon !== undefined || item.raw.lon !== null) {
                address.locationLongitude = parseFloat(item.raw.lon);
            }
        }
    }

    return address;
};
