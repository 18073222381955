import { ClickableImgCell } from "Custom/Components/Table/ClickableImgCell";
import EditLogo from "Custom/Content/editIcon.svg";
import DeleteLogo from "Custom/Content/deleteIcon.svg";

export const formatEdit = (id: string, editObject: string, editClientClick: (id: string) => void): any => {
    return <ClickableImgCell logo={EditLogo} text={"Edit " + editObject} onClick={editClientClick} id={id} />;
};

export const formatEditContextual = (id: string, context: string, editObject: string, editClientClick: (id: string, context: string) => void): any => {
    return <ClickableImgCell logo={EditLogo} text={"Edit " + editObject} onClickExt={editClientClick} id={id} context={context} />;
};

export const formatDelete = (id: string, deleteObject: string, deleteClientClick: (id: string) => void): any => {
    return <ClickableImgCell logo={DeleteLogo} text={"Delete " + deleteObject} onClick={deleteClientClick} id={id} className="delete" />;
};
