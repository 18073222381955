import * as React from "react";
import * as MobXReact from "mobx-react-lite";
import { PropertyDetailViewModel } from "./PropertyDetailViewModel";
import { Loader } from "Core/Components/Loader";
import { useLoadCall } from "Custom/Utils/useLoadCall";
import { useRouter } from "Core/Base";
import { NotFoundModal } from "Custom/Components/NotFoundModal/NotFoundModal";
import { AppUrls } from "Custom/Globals";
import { ITab, Tabs } from "Custom/Components/Tabs/Tabs";
import { PropertyDetailPageContainer } from "./PropertyDetailPage.styles";
import { PropertyDetailDetail } from "./PropertyDetailDetail";
import { PropertyDetailMap } from "./PropertyDetailMap";
import { useHasRoutedTabs } from "Custom/Utils/useHasRoutedTabs";

import { PropertyNotes } from "../../Projects/Details/Properties/PropertyNotes/PropertyNotes";
import { Stores, StoresContext } from "Custom/Stores";
import * as RouterUtil from "Custom/Utils/routing";
import { computed } from "mobx";
import { ProjectDocumentsTab } from "Custom/Views/Projects/Details/Documents/ProjectDocuments";
import { PropertyOverview } from "./PropertyOverview";
import { PropertyOverviewViewModel } from "./PropertyOverviewViewModel";

const TAB_OVERVIEW = "overview";
const TAB_DETAIL = "detail";
const TAB_MAP = "map";
const TAB_NOTES = "notes";
const TAB_DOCUMENTS = "documents";

export const PropertyDetailPage: React.FC = () => {
    const storesContext = React.useContext<Stores>(StoresContext);
    const allowDraggable: boolean = RouterUtil.requireContractorAdminLogin(storesContext) || RouterUtil.requireAdminLogin(storesContext);
    const [viewModel] = React.useState(() => new PropertyDetailViewModel(allowDraggable));

    /*     const [documentCount, setDocumentCount] = React.useState<number>(viewModel.documents.length); */

    const tabOptions: ITab[] = [
        { key: TAB_OVERVIEW, title: "Overview", hash: "overview" },
        { key: TAB_DETAIL, title: "Detail", hash: "detail" },
        { key: TAB_MAP, title: "Map", hash: "map" },
        { key: TAB_NOTES, title: "Property notes", hash: "notes" },
        { key: TAB_DOCUMENTS, title: "Property documents", hash: "documents" },
    ];

    const routedTab = useHasRoutedTabs(tabOptions);
    const [selectedTab, setSelectedTab] = React.useState(routedTab?.hash || TAB_OVERVIEW);
    const [tabKey, setTabKey] = React.useState(0);
    const [returnUrl, setReturnUrl] = React.useState("");

    const router = useRouter<{ propertyId: string; projectId: string }>();

    const handleTabClick = (tab: ITab) => {
        setSelectedTab(tab.key);
        setTabKey(tabKey + 1);
    };

    /*     React.useEffect(() => {
        setDocumentCount(viewModel.getDocumentCount);
    }, [viewModel.getDocumentCount]); */

    React.useEffect(() => {
        const { path, params } = router.match;

        switch (path) {
            case AppUrls.Client.Main.EstateView.Property.PropertyDetail: {
                setReturnUrl(AppUrls.Client.Main.EstateView.Root);
                break;
            }
            case AppUrls.Client.ClientView.Property.PropertyDetail: {
                setReturnUrl(AppUrls.Client.ClientView.Root);
                break;
            }
            case AppUrls.Client.ContractorView.Property.PropertyDetail: {
                setReturnUrl(AppUrls.Client.ContractorView.Root);
                break;
            }
            //case AppUrls.Client.ContractorView.Project.PropertyDetail: {
            //    setReturnUrl(AppUrls.Client.ContractorView.Project.ProjectDetail.replace(":projectId", params.projectId));
            //    break;
            //}

            default: {
                setReturnUrl("/");
            }
        }

        return () => {
            // Clean up after yourself
            PropertyOverviewViewModel.Instance.clear();
        };
    }, []);

    const notFound = useLoadCall(
        async () => {
            const { propertyId, projectId } = router.match.params;
            const { path, params } = router.match;
            switch (path) {
                case AppUrls.Client.ContractorView.Property.PropertyDetail: {
                    // BF108 Contractor needs a cut down view
                    await viewModel.loadContractorViewAsync(propertyId, projectId);
                    break;
                }

                default: {
                    await viewModel.loadAsync(propertyId, projectId);
                }
            }

            return !!viewModel.getValue("id");
        },
        { checkGuidParamKey: "propertyId" },
    );

    const handleBack = () => {
        router.history.push(returnUrl);
    };

    const getTabKey = () => {
        return `property-detail-${selectedTab}-${tabKey}`;
    };

    const renderTabContent = () => {
        switch (selectedTab) {
            case TAB_OVERVIEW:
                return <PropertyOverview key={getTabKey()} parentViewModel={viewModel} />;
            case TAB_DETAIL:
                return <PropertyDetailDetail key={getTabKey()} viewModel={viewModel} />;
            case TAB_MAP:
                return <PropertyDetailMap key={getTabKey()} viewModel={viewModel} />;
            case TAB_NOTES:
                return <PropertyNotes key={getTabKey()} viewModel={viewModel} />;
            case TAB_DOCUMENTS:
                return <ProjectDocumentsTab key={getTabKey()} externalViewModel={viewModel} title="Property Documents" />;
            default:
                return null;
        }
    };

    const getReturnText = (): string => {
        let retVal: string = "Back to property list";
        const { match } = router;
        if (match.path === AppUrls.Client.ClientView.Property.PropertyDetail || match.path === AppUrls.Client.ContractorView.Property.PropertyDetail) {
            retVal = "Back to Dashboard";
        } else if (match.path === AppUrls.Client.Main.EstateView.Property.PropertyDetail) {
            retVal = "Back to Estate";
        }
        return retVal;
    };

    const renderPage = () => {
        if (viewModel.IsLoading || notFound) return <Loader />;

        return (
            <PropertyDetailPageContainer>
                <Tabs tabs={tabOptions} onTabClick={handleTabClick} backBtnText={getReturnText()} onBack={handleBack} showBackBtn selected={selectedTab} minTabWidth={132} />

                <div className="content">{renderTabContent()}</div>
            </PropertyDetailPageContainer>
        );
    };

    return MobXReact.useObserver(() => (
        <>
            <NotFoundModal returnRoute={returnUrl} open={notFound} />
            {renderPage()}
        </>
    ));
};
