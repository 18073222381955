import { observable } from "mobx";
import { ModelBase } from "Core/Models/ModelBase";
import { IsNotEmpty } from "class-validator";
import { ProjectCommunicationModelDTO } from "Custom/Models/Domain";

//Base class expects the model type and the DTO model type
//If you are not using a DTO just pass in undefined as the DTO
export class ProjectCommunicationListItemModel extends ModelBase<ProjectCommunicationListItemModel, ProjectCommunicationListItemDTO> {
    public id: string | null = null;
    public rowVersion: string | null = null;
    public isDeleted: boolean = false;
    public isDefaultNote: boolean = false;
    public createdDate: string | null = null;
    public createdBy: string | null = null;
    public creator: string = "";
    public projectId: string = "";
    public note: string = "";
    public updatedDate: string | null = null;
    public lastUpdatedBy: string | null = null;
    public updater: string = "";
    public followUpDate: string | null = null;
    public isResolved: boolean = false;
    public resolvedDate: string | null = null;
    public resolvedBy: string | null = null;
    public resolver: string = "";
    public projectName: string = "";
    public projectNumber: string = "";

    //fromDto is required but you can leave it blank
    fromDto(model: ProjectCommunicationListItemDTO): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
    }

    //toDto is required but you can leave it blank
    toDto(model: ProjectCommunicationListItemModel): void {}

    fromUpsertDto(payload: ProjectCommunicationModelDTO) {
        if (payload.id === this.id) {
            this.rowVersion = payload.rowVersion;
            this.isDeleted = payload.isDeleted;
            this.isDefaultNote = payload.isDeleted;
            this.createdDate = payload.createdDate === undefined ? null : payload.createdDate;
            this.createdBy = payload.createdBy;
            this.creator = payload.creator;
            this.projectId = payload.projectId;
            this.note = payload.note;
            this.updatedDate = payload.updatedDate === undefined ? null : payload.updatedDate;
            this.lastUpdatedBy = payload.lastUpdatedBy;
            this.updater = payload.updater;
            this.followUpDate = payload.followUpDate === undefined ? null : payload.followUpDate;
            this.isResolved = payload.isResolved;
            this.resolvedDate = payload.resolvedDate === undefined ? null : payload.resolvedDate;
            this.resolvedBy = payload.resolvedBy === undefined ? null : payload.resolvedBy;
            this.resolver = payload.resolver;
            // should not have changed and isn't part of the upsert this.projectName: string;
            // should not have changed and isn't part of the upsert this.projectNumber: string;
        }
    }
}

export interface ProjectCommunicationListItemDTO {
    id: string | null;
    rowVersion: string | null;
    isDeleted: boolean;
    isDefaultNote: boolean;
    createdDate: string | null;
    createdBy: string | null;
    creator: string;
    projectId: string;
    note: string;
    updatedDate: string | null;
    lastUpdatedBy: string | null;
    updater: string;
    followUpDate: string | null;
    isResolved: boolean;
    resolvedDate: string | null;
    resolvedBy: string | null;
    resolver: string;
    projectName: string;
    projectNumber: string;
}
