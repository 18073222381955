import React, { useContext } from "react";
import { Stores, StoresContext } from "../../Stores";
import { PrivateRoute } from "../../../Core/Utils/React";
import { ProjectListPage } from "./ProjectListPage";
import { ProjectAddEditPage } from "./ProjectAddEditPage";
import * as RouterUtil from "Custom/Utils/routing";
import { Client } from "Custom/Globals/AppUrls";
import { ProjectDetailsPage } from "./Details/ProjectDetailsPage";

export const ProjectRoutes: React.FC = () => {
    const store = useContext<Stores>(StoresContext);
    return (
        <>
            <PrivateRoute
                component={ProjectListPage}
                exact
                isAllowed={() => {
                    return store.domain.AccountStore.IsLoggedIn && RouterUtil.requireAdminLogin(store);
                }}
                path={Client.Main.Project.Root}
            />
            <PrivateRoute
                component={ProjectAddEditPage}
                exact
                isAllowed={() => {
                    return store.domain.AccountStore.IsLoggedIn && RouterUtil.requireAdminLogin(store);
                }}
                path={Client.Main.Project.ProjectAddEdit}
            />
            <PrivateRoute
                component={ProjectDetailsPage}
                exact
                isAllowed={() => {
                    return store.domain.AccountStore.IsLoggedIn && RouterUtil.requireAdminLogin(store);
                }}
                path={Client.Main.Project.ProjectDetail}
            />
        </>
    );
};
