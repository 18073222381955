import * as Models from "../Models";
import { action, computed, observable, runInAction } from "mobx";
import * as MobX from "mobx";
import { AccountStore, ClientStore, ProjectStore, PropertyStore } from "./Domain";
import { EditableCopy } from "../Models/EditableCopy";
import { EditableCopyRoute } from "../Models/EditableCopyRoute";
import { RoleStore } from "./Domain/Admin";
import { Stores } from "./Stores";
import { UserStore } from "./Domain/Admin";

export class DomainStores {
    private DEFAULTITLE: string = "An error Occurred";
    @observable public openSnackBar = false;
    @observable public openErrorDialog = false;
    @observable public errorDialogContent: string = "";
    @observable public errorDialogTitle: string = this.DEFAULTITLE;

    public errorDialogArrayContent: MobX.IObservableArray<string> = observable([]);

    @observable public snackBarContent: string = "";
    @observable public editableCopies = MobX.observable<EditableCopy>([]);
    @observable public editableCopyRoutes = MobX.observable<EditableCopyRoute>([]);
    public AccountStore: AccountStore = new AccountStore();

    public UserStore = new UserStore();
    public RoleStore = new RoleStore();

    public ClientStore = new ClientStore();
    public ProjectStore = new ProjectStore();
    public PropertyStore = new PropertyStore();

    public init(stores: Stores, initialState: Models.InitialState) {
        this.editableCopies.clear();
        this.editableCopyRoutes.clear();
        initialState.editableCopies.forEach((editableCopy) => {
            this.editableCopies.push(editableCopy);
        });
        initialState.editableCopyRoutes.forEach((editableCopyRoute) => {
            this.editableCopyRoutes.push(editableCopyRoute);
        });
        this.AccountStore.init(stores, initialState);

        this.UserStore.init(stores, initialState);
        this.RoleStore.init(stores, initialState);

        this.ClientStore.init(stores, initialState);
        this.ProjectStore.init(stores, initialState);
        this.PropertyStore.init(stores, initialState);
    }

    @action
    public ShowSnackBar(content: string) {
        this.snackBarContent = content;
        this.openSnackBar = true;
    }

    @action
    public CloseSnackBar() {
        this.openSnackBar = false;
    }

    @action
    public ShowErrorDialog(content: string) {
        this.errorDialogContent = content;
        this.errorDialogArrayContent.clear();
        this.openErrorDialog = true;
    }

    @action
    public ShowErrorArrayDialog(content: string[]) {
        this.errorDialogContent = "";
        this.errorDialogArrayContent.clear();
        this.errorDialogArrayContent.replace(content);
        this.openErrorDialog = true;
    }

    @action
    public CloseErrorDialog() {
        this.errorDialogContent = "";
        this.errorDialogArrayContent.clear();
        this.openErrorDialog = false;
        this.errorDialogTitle = this.DEFAULTITLE;
    }
}
