import React, { useEffect, useState } from "react";
import { useObserver } from "mobx-react-lite";
import { generateID } from "Core/Utils/Utils";
import {
    AlertActionPanel,
    AlertActionPanelActions,
    AlertActionPanelHeading,
    AlertActionPanelTable,
    AlertActionPanelTableHeadings,
    AlertActionPanelTableRows,
} from "./AlertActionItemView.style";
import EditLogo from "Custom/Content/editIcon.svg";
import DeleteLogo from "Custom/Content/deleteIcon.svg";
import DownArrowLogo from "Custom/Content/downArrow.svg";
import UpArrowLogo from "Custom/Content/upArrow.svg";
import UpDownArrowLogo from "Custom/Content/upDownArrow.svg";
import { AlertActionViewModel } from "./AlertActionViewModel";
import { ItemSectionBox, NameSectionBoxNoBorder } from "Custom/Views/Admin/Condition/Condition.style";
import { Grid, Typography } from "@material-ui/core";
import { AlertActionItem } from "Custom/Views/Admin/AlertAction/AlertActionItem";
import { RoofcareInput } from "Custom/StylesAppSpecific/Controls/RoofcareInput";
import { AlertActionAddEditDetailModel } from "Custom/Views/Admin/AlertAction/AlertActionAddEditDetailModel";
import { DeleteModal } from "Custom/Components/Modal/DeleteModal";
import { TabConfigurationViewModel } from "../TabConfigurationViewModel";
import { ApiResult } from "Core/Models";
import { TabConfigurationModelDTO } from "../TabConfigurationModel";
import { MatchParams } from "../TabConfiguration";
import { useRouter } from "Core/Utils";
import { BoxAdd } from "Custom/StylesAppSpecific/Controls/BoxAddWrapper";
import { AddItemText } from "Custom/StylesAppSpecific/Controls/AddEditWrapper";
import { AlertActionItemViewModel } from "Custom/Views/Admin/AlertAction/AlertActionItemViewModel";
import { ConditionType, ConditionUnits, DrainageType, InstallationStatusDataDTO, RoofType } from "Custom/Models/Domain";
import { SectionSend } from "../DeviceSection.style";
import { SendBtn } from "Custom/StylesAppSpecific/Controls/SuccessBtn";
import { CustomColourBox } from "./CustomColourBox";
import { CustomColourBoxWrapper } from "../Condition/ConditionItemView.style";

interface AlertActionViewProps {
    drainageType: DrainageType;
    viewModel: AlertActionViewModel;
    statusData: InstallationStatusDataDTO | undefined;
    index: number;
    onAlertActionChange: () => void;
}

export const AlertActionView: React.FC<AlertActionViewProps> = (props: AlertActionViewProps) => {
    const { statusData, onAlertActionChange } = props;
    const { match } = useRouter();
    const { installId } = match.params as MatchParams;
    const [maxAlertActions] = useState<number>(6);
    const [tabConfigurationViewModel] = useState(() => TabConfigurationViewModel.Instance);
    const [areYouSureOpen, setAreYouSureOpen] = React.useState(false);
    const [areYouSureDeleteOpen, setAreYouSureDeleteOpen] = React.useState(false);
    const [isBtnDisabled, setIsBtnDisabled] = useState<boolean | undefined>(false);

    useEffect(() => {
        return () => {
            // Clean up after yourself
        };
    }, []);

    const handleAreYouSureOpen = (): void => {
        setAreYouSureOpen(true);
    };

    const handleAreYouSureClose = (): void => {
        setAreYouSureOpen(false);
    };

    const handleAreYouSureDeleteOpen = (): void => {
        setAreYouSureDeleteOpen(true);
    };

    const handleAreYouSureDeleteClose = (): void => {
        setAreYouSureDeleteOpen(false);
    };

    const validateAndSave = (): void => {
        //validate model and all sub objects
        if (props.viewModel.isModelAndChildrenValid(statusData) === true) {
            let promise = props.viewModel.upsertDeviceAlertAction(installId!);

            promise.then((result: ApiResult<TabConfigurationModelDTO>) => {
                if (result.wasSuccessful === true) {
                    tabConfigurationViewModel.setLocalData(result);
                    onAlertActionChange();
                }
            });
        }
    };

    const deleteDeviceAlertAction = (): void => {
        handleAreYouSureDeleteClose();
        if (props.viewModel.model.id !== null && props.viewModel.model.id !== undefined && props.viewModel.model.id !== "") {
            let promise = props.viewModel.setDeviceAlertActionIsDeleted();

            promise.then((result: ApiResult<TabConfigurationModelDTO>) => {
                if (result.wasSuccessful === true) {
                    tabConfigurationViewModel.setLocalData(result);
                    onAlertActionChange();
                }
            });
        } else {
            // Delete the local condition set.
            tabConfigurationViewModel.deleteLocalAlertAction(props.index);
            onAlertActionChange();
        }
    };

    const onSave = (): void => {
        handleAreYouSureOpen();
    };

    const handleSaveAlertAction = (): void => {
        handleAreYouSureClose();
        validateAndSave();
    };

    const handleNewClick = (): void => {
        props.viewModel.addNewAlertAction();
    };

    const formatEdit = (): JSX.Element => {
        return (
            <div className="circle-icon cursor-pointer">
                <img src={EditLogo} onClick={() => props.viewModel.editAlertAction()} alt="edit" />
            </div>
        );
    };

    const formatDelete = (): JSX.Element => {
        return <img src={DeleteLogo} onClick={() => handleAreYouSureDeleteOpen()} alt="delete" className={"cursor-pointer cross-icon"} />;
    };

    const formatDirection = (item: AlertActionItemViewModel): JSX.Element => {
        let logo = <img src={UpDownArrowLogo} />;

        switch (item.model.direction) {
            case 0:
                logo = <img src={UpArrowLogo} />;
                break;
            case 1:
                logo = <img src={UpDownArrowLogo} />;
                break;
            case 2:
                logo = <img src={DownArrowLogo} />;
                break;
        }

        return logo;
    };

    const viewOnly = (): JSX.Element => {
        return (
            <AlertActionPanel>
                <AlertActionPanelHeading>
                    Alert action: {props.viewModel.model.name}{" "}
                    <div>
                        {formatEdit()} {formatDelete()}
                    </div>
                </AlertActionPanelHeading>
                <AlertActionPanelTable>
                    <AlertActionPanelTableHeadings>
                        <div>Name</div>
                        <div>Colour</div>
                        <div>Condition</div>
                        <div>Value</div>
                        <div>Direction</div>
                        <div>Photo</div>
                        <div>SMS</div>
                        <div>Email</div>
                    </AlertActionPanelTableHeadings>
                    <AlertActionPanelTableRows>
                        {props.viewModel.alertActions.map((item) => {
                            return (
                                <div key={generateID()}>
                                    <div>{item.model.name}</div>
                                    <CustomColourBoxWrapper>
                                        <CustomColourBox backgroundColour={item.model.alertColour} textColour={item.model.alertTextColour} message={item.model.name} />
                                    </CustomColourBoxWrapper>
                                    <div>
                                        {item.getAlertActionType()} {item.getUnitsFormatted()}
                                    </div>
                                    <div>{getCalculatedValue(item, statusData)} mm</div>
                                    <div>{formatDirection(item)}</div>
                                    <div>{item.model.photo ? <span>&#10003;</span> : ""}</div>
                                    <div>{item.model.sms ? <span>&#10003;</span> : ""}</div>
                                    <div>{item.model.email ? <span>&#10003;</span> : ""}</div>
                                </div>
                            );
                        })}
                    </AlertActionPanelTableRows>
                </AlertActionPanelTable>
            </AlertActionPanel>
        );
    };

    const getCalculatedValue = (item: AlertActionItemViewModel, statusData: InstallationStatusDataDTO | undefined) => {
        let retVal: string = "";
        if (statusData !== undefined) {
            let maxWorkingHeightValue: number = Math.min(statusData!.side1_Height, statusData!.side2_Height);

            if (statusData!.roofType === RoofType.Flat) {
                maxWorkingHeightValue = statusData!.maxHeight;
            }

            const freeboardHeightValue: number = maxWorkingHeightValue * 0.7;
            const p1 = statusData!.p1;
            const p2 = statusData!.p2;

            let value: number = 0;
            if (item.model.type === ConditionType.H) {
                value = maxWorkingHeightValue;
            } else if (item.model.type === ConditionType.FB) {
                value = freeboardHeightValue;
            } else if (item.model.type === ConditionType.P1) {
                value = p1;
            } else {
                value = p2;
            }

            if (item.model.units === ConditionUnits.MM) {
                value = value + item.model.value;
            } else {
                // it equals %
                let percentValue: number = item.model.value / 100;
                value = value + value * percentValue;
            }

            retVal = value.toFixed(0);
        }

        return retVal;
    };

    const editableForm = (): JSX.Element => {
        return (
            <>
                <DeleteModal
                    open={areYouSureOpen}
                    onClose={handleAreYouSureClose}
                    onDelete={handleSaveAlertAction}
                    text="Are you sure you want to save this alert action?"
                    title="Do you want to continue?"
                />
                <AlertActionPanel>
                    <AlertActionPanelHeading>
                        <NameSectionBoxNoBorder>
                            <RoofcareInput<AlertActionAddEditDetailModel>
                                type="text"
                                label="Alert Action:"
                                validateOnBlur={true}
                                viewModel={props.viewModel}
                                fieldName="name"
                                shrink={true}
                                maxLength={128}
                            />
                        </NameSectionBoxNoBorder>
                        <div>{formatDelete()}</div>
                    </AlertActionPanelHeading>
                    <AlertActionPanelTable>
                        <ItemSectionBox>
                            <Grid container direction="column" spacing={1}>
                                <Grid container direction="row" spacing={1}>
                                    <Grid item xs={2} className="column-title">
                                        <Typography variant="h6">Name</Typography>
                                    </Grid>
                                    <Grid item xs={2} className="column-title">
                                        <Typography variant="h6">Colour</Typography>
                                    </Grid>
                                    <Grid item xs={2} className="column-title">
                                        <Typography variant="h6">Condition</Typography>
                                    </Grid>
                                    <Grid item xs={2} className="column-title">
                                        <Typography variant="h6">Direction</Typography>
                                    </Grid>
                                    <Grid item xs={1} className="column-title">
                                        <Typography variant="h6">Photo</Typography>
                                    </Grid>
                                    <Grid item xs={1} className="column-title">
                                        <Typography variant="h6">SMS</Typography>
                                    </Grid>
                                    <Grid item xs={1} className="column-title">
                                        <Typography variant="h6">Email</Typography>
                                    </Grid>
                                    <Grid item xs={1} className="column-title">
                                        <Typography variant="h6"></Typography>
                                    </Grid>
                                </Grid>
                                {getRows()}
                            </Grid>
                        </ItemSectionBox>
                    </AlertActionPanelTable>

                    <AlertActionPanelActions>
                        {props.viewModel.getAlertActions.length < maxAlertActions && (
                            <BoxAdd onClick={() => handleNewClick()}>
                                <AddItemText fontcolor="#F29D29" style={{ paddingLeft: "0!important" }}>
                                    <div style={{ marginLeft: "-15px" }}>+ Add another</div>
                                </AddItemText>
                            </BoxAdd>
                        )}

                        <SectionSend>
                            <SendBtn disabled={isBtnDisabled} type="button" onClick={() => onSave()}>
                                <div className="btnText">Save</div>
                            </SendBtn>
                        </SectionSend>
                    </AlertActionPanelActions>
                </AlertActionPanel>
            </>
        );
    };

    const getRows = (): JSX.Element[] => {
        return props.viewModel.getAlertActions.map((item) => {
            return <AlertActionItem key={generateID()} localViewModel={item} onDelete={() => {}} drainageType={props.drainageType} />;
        });
    };

    return useObserver(() => (
        <>
            <DeleteModal
                open={areYouSureDeleteOpen}
                onClose={handleAreYouSureDeleteClose}
                onDelete={deleteDeviceAlertAction}
                text="Are you sure you want to delete this alert action?"
                title="Do you want to continue?"
            />
            {props.viewModel.getIsEditable ? editableForm() : viewOnly()}
        </>
    ));
};
