import styled from "styled-components";
import { BaseModalDialogPaper } from "../../Components/Modal/BaseModal.styles";

export const PropertyModalDialogPaper = styled(BaseModalDialogPaper)`
    min-width: 1024px;
    background: #3a3c41 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #000000c2;
    border-radius: 18px;

    section {
        margin: 0;
        padding-bottom: 16px;

        > div {
            padding: 0;
            display: flex;
            flex-direction: column;
        }

        .address-toggle {
            margin-top: 16px;
            align-self: flex-start;
        }

        label {
            margin-bottom: 0;
        }
    }

    .MuiDialogActions-root {
        padding-bottom: 40px;
        padding-left: 0;
        padding-right: 0;
        min-width: 380px;
        margin: auto;
    }
`;
