import { IComputedValue } from "mobx";
import { Box, Button, Checkbox, Fade, FormControl, FormHelperText, ListItemText, makeStyles, MenuItem, Paper, Select, Typography } from "@material-ui/core";
import React from "react";
import { useObserver } from "mobx-react-lite";

import { generateID } from "Custom/Utils/utils";
import { KeyValuePair } from "Core/Models/KeyValuePair";
import "./MultipleSelect.style.scss";

interface IMultiSelectProps {
    // Data
    fieldName: string;
    values: IComputedValue<number[] | string[] | null>;
    display: string;
    useNumber: boolean;
    showTopAllNone?: boolean;
    showBottomAllNone?: boolean;
    renderOption?: React.ReactChild;

    // Callbacks
    getOptions: (includeDeleted: boolean) => KeyValuePair[];
    handleChange: (values: number[] | string[] | null) => void;
    getValid: (fieldName: string) => boolean;
    getError: (fieldName: string) => string;
}

const useStyles = makeStyles({
    dropDown: {
        "& > div.MuiPaper-root.MuiMenu-paper": {
            top: "200px !important",
        },
    },
});

/**
 *
 * @param props current Client Id
 * @returns autocomplete form component
 */
export const MultiSelectView: React.FC<IMultiSelectProps> = (props) => {
    const classes = useStyles();

    const isInError = (fieldName: string): boolean => {
        let isValid = props.getValid(fieldName);
        return !isValid;
    };

    const getError = (fieldName: string) => {
        let retVal: string = props.getError(fieldName);
        return retVal;
    };

    const selectAll = (event: any) => {
        event.stopPropagation();
        const { getOptions, handleChange } = props;
        const newValue: number[] | null = getOptions(true).map((a) => a.value);

        handleChange(newValue);
    };

    const selectNone = (event: any) => {
        event.stopPropagation();
        const { handleChange } = props;

        handleChange([]);
    };

    const getPlaceHolder = (): string => {
        const values = props.values.get();
        let placeholder: string = "Select...";

        if (values !== null && values.length > 0) {
            const total: number = props.getOptions(true).length;
            const selected: number = values.length;

            if (total === selected) {
                placeholder = props.display + ": All";
            } else {
                placeholder = props.display + ": " + selected.toString() + " of " + total.toString();
            }
        }

        return placeholder;
    };

    const renderSelectAll = () => {
        return (
            <div className="allnonerow">
                <div className="button-wrapper">
                    <Button onClick={selectAll} color="default" autoFocus variant="contained" size="small">
                        All
                    </Button>
                    <Button onClick={selectNone} color="default" autoFocus variant="contained" size="small">
                        None
                    </Button>
                </div>
            </div>
        );
    };

    const onChange = (values: unknown) => {
        if (props.useNumber === true) {
            props.handleChange(values == null ? null : (values as number[]));
        } else {
            props.handleChange(values == null ? null : (values as string[]));
        }
    };

    return useObserver(() => (
        <Box className={`rcselect`} marginTop="-3px" width="100%" style={{ borderBottom: "0" }}>
            <FormControl variant="standard" style={{ maxWidth: "200px" }}>
                <Select
                    variant="standard"
                    style={{ paddingTop: "0" }}
                    id={props.fieldName + "-select"}
                    value={props.values.get()}
                    onChange={(e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
                        let value = e.target.value as any;

                        let ammended: any = [];
                        for (let i: number = 0; i < value.length; i++) {
                            if (value[i] !== undefined && value !== null && value !== NaN) {
                                ammended.push(value[i]);
                            }
                        }

                        onChange(ammended);
                    }}
                    MenuProps={{
                        getContentAnchorEl: null,
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                        },
                    }}
                    // renderValue={() => <em>{getPlaceHolder()}</em>}
                    renderValue={() => <span style={{ opacity: "0.65" }}>{getPlaceHolder()}</span>}
                    fullWidth
                    multiple
                >
                    {props.showTopAllNone === undefined || props.showTopAllNone! === true ? renderSelectAll() : ""}
                    {props.getOptions(true).map((option) => {
                        const displayName = option.key;
                        const optionId = option.value;
                        const values = props.values.get() as any[];

                        return (
                            <MenuItem className="rcselectitem" key={generateID()} value={optionId}>
                                <Checkbox size="small" checked={values.indexOf(optionId) > -1} />
                                <ListItemText primary={displayName} />
                            </MenuItem>
                        );
                    })}
                    {props.showBottomAllNone === undefined || props.showBottomAllNone! === true ? renderSelectAll() : ""}
                </Select>

                <Box>{isInError(props.fieldName) && <FormHelperText style={{ color: "red" }}>{getError(props.fieldName)}</FormHelperText>}</Box>
            </FormControl>
        </Box>
    ));
};
