import { DropDownListItem } from "./../Models/API/DropDownListItem";
import styled from "styled-components";
import { Checkbox } from "@material-ui/core";

export const ShowingOnMapContainer = styled.div`
    margin-left: 200px;
    background-color: #ffffff;
    box-shadow: 0px 0px 3px #00000029;
    padding: 15px;
    font-family: "Mont-Regular", Montserrat, Roboto Condensed, Helvetica, Arial, sans-serif;
    text-align: left;
    border-radius: 5px;
    max-width: 720px;

    span {
        font-weight: bold;
        font-size: 12px;
        vertical-align: middle;

        &.label {
            font-family: Arial, sans-serif;
            font-size: 1.166666em;
            font-weight: normal;
        }
    }
`;

interface IColouredCheckboxProps {
    customcolour: any;
}

export const ColouredCheckbox = styled(Checkbox)<IColouredCheckboxProps>`
    margin: -9px -4px -9px 11px;

    svg {
        background-color: white !important;

        > path {
            fill: ${(props) => props.customcolour} !important;
        }
    }

    &.Mui-checked {
        color: ${(props) => props.customcolour} !important;

        svg {
            border: none !important;
        }
    }
`;

export const MapPopup = styled.div`
    display: flex;
    flex-direction: column;
    .title {
        font-family: "Mont-Bold", "Montserrat", "Roboto Condensed", "Helvetica", "Arial", "sans-serif";
        text-transform: capitalize;
    }
    .address {
        /* font-weight: 600; */
    }

    .view {
        color: ${(props) => props.theme.app.colors.blue.light};
        cursor: default;
    }

    .view:hover {
        cursor: pointer;
    }

    .propertysection {
        background-color: #c6c6c6;
        padding: 10px;
        margin: -6px -11px;
        text-align: left;
        font: normal normal normal 10px/14px Mont;
        letter-spacing: 0px;
        color: #3a3c41;
        opacity: 1;
    }
    .unitsection {
        display: flex;
        flex: 1 0 0;
        flex-direction: column;

        .unitRow {
            max-height: 180px;
            margin-top: 10px;
            display: flex;
            flex: 1 0 0;
            flex-direction: row;
            /* 
            background-color: pink; */

            .unitStatus {
                max-width: 25px;
            }

            .unitdetails {
                position: relative;
                display: flex;
                flex: 1 0 1;
                flex-direction: column;

                /* background-color: orange; */
                font: normal normal normal 10px/10px Mont;

                .unitcell {
                    position: relative;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    flex: 1 0 0;
                    width: 100%;
                    text-align: left;
                    letter-spacing: 0px;
                    color: #3a3c41;
                    opacity: 1;

                    /* background-color: green; */
                }
            }

            .unitdetails:hover {
                cursor: pointer;
            }
        }
    }
`;

export const ShowLayersMapContainer = styled.div`
    background-color: #ffffff;
    box-shadow: 0px 0px 3px #00000029;
    padding: 15px;
    font-family: "Mont-Regular", Montserrat, Roboto Condensed, Helvetica, Arial, sans-serif;
    text-align: left;
    border-radius: 5px;
    max-width: 150px;

    span {
        font-weight: bold;
        font-size: 12px;
        vertical-align: middle;

        &.label {
            font-family: Arial, sans-serif;
            font-size: 1.166666em;
            font-weight: normal;
        }
    }

    .layerOption {
        margin-top: 5px;
        cursor: default;
    }

    .layerOption:hover {
        cursor: pointer;
        color: #337ab7;
    }

    .layericon {
        padding-right: 10px;
        color: #337ab7;
    }

    hr {
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .installcolumn {
        display: flex;
        flex: 1;
        flex-direction: column;
    }

    .installrow {
        margin-top: 10px;
        margin-left: -20px;
        display: flex;
        flex: 1;
        flex-direction: row;
    }
`;

export const ShowUnitFilterContainer = styled.div`
    display: flex;
    flex: 1;
    font-family: "Mont-Regular", Montserrat, Roboto Condensed, Helvetica, Arial, sans-serif;
    background-color: #3867cc;
    position: relative;
    color: white;
    vertical-align: center;
    height: 46px;
    line-height: 46px;

    .unittitle {
        padding: 0 30px;
    }

    .installoptionrow {
        vertical-align: center;
        line-height: 46px;
        position: relative;
        color: white;
        display: flex;
        flex: 1;
        flex-direction: row;
        padding: 0 30px;
    }

    .optioncell,
    .optioncelldropdown {
        display: flex;
        flex: 1;
        flex-direction: row;

        .label {
            padding: 2px 5px;
            margin-top: 5px;
            height: 20px;
            margin-top: 13px;

            .celltext {
                margin-top: -14px;
            }

            &.unknown {
                background-color: ${(props) => props.theme.app.colors.deviceWarning.unknown};
                color: white; // why doesn't this work? ${(props) => props.theme.app.colors.white.main};
            }

            &.greenwarning {
                background-color: ${(props) => props.theme.app.colors.deviceWarning.green};
                color: white;
            }

            &.amberwarning {
                background-color: ${(props) => props.theme.app.colors.deviceWarning.amber};
                color: white;
            }

            &.redwarning {
                background-color: ${(props) => props.theme.app.colors.deviceWarning.red};
                color: white;
            }

            &.floodwarning {
                background-color: ${(props) => props.theme.app.colors.deviceWarning.flood};
                color: white;
            }
        }
    }

    .optioncelldropdown {
        background-color: pink;
    }
`;
