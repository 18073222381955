import styled from "styled-components";
import { Select } from "@material-ui/core";

interface IProjectStatusSelectSelectProps {
    statuscolor: string;
    statustext: string;
}

export const ProjectStatusSelectSelect = styled(Select)<IProjectStatusSelectSelectProps>`
    padding: 0;
    width: 175px;
    border: 1px solid ${(props) => props.statuscolor};
    border-radius: 0;

    &.MuiInput-underline:before {
        z-index: 1;
    }

    div.MuiSelect-select {
        padding: 9px 16px;
        padding-right: ${(props) => (props.disabled ? "16px" : "48px")};
        background-color: ${(props) => props.statuscolor} !important;
        color: ${(props) => props.statustext} !important;
        font-size: 14px;
        text-align: center;
    }

    svg.MuiSelect-icon {
        width: 32px;
        height: 100%;
        padding: 6px;
        top: unset;
        background-color: white;
        display: ${(props) => (props.disabled ? "none" : "inherit")};
    }
`;

export const ProjectStatusSelectContainer = styled.div`
    border: 1px solid #cccccc;
    width: fit-content;
`;
