import { DayBox, DayboxRuler } from "Custom/Views/Installations/ImageDayBox.style";

import { useObserver } from "mobx-react-lite";
import { Box, Grid, TextField, Theme, Typography, useMediaQuery } from "@material-ui/core";
import { CommandSectionBox, SectionSend } from "./CommandSection.style";
import { React, useState } from "Core/Base";
import { CommandSectionViewModel } from "./CommandSectionViewModel";
import { SendBtn, SuccessBtn } from "Custom/StylesAppSpecific/Controls/SuccessBtn";
import { DeviceCommandSectionBox } from "./DeviceSection.style";
import { DeleteModal } from "Custom/Components/Modal/DeleteModal";
import { InstallationCommand, InstallationCommandHistoryDTO } from "Custom/Models/Domain";

interface CommandSectionProps {
    simId: string;
    deviceId: number;
    addCommandHistory: (newhistory: InstallationCommandHistoryDTO[], commands: InstallationCommand[]) => any;
}

export function CommandSection(props: CommandSectionProps) {
    const { simId, deviceId, addCommandHistory } = props;
    const [viewModel] = useState(() => new CommandSectionViewModel());
    const [value, setValue] = useState("");
    const [hasUnsentValue, setHasUnsentValue] = useState(false);
    const desktopScreen: boolean = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));
    const mobileScreen: boolean = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
    const [isBtnDisabled, setIsBtnDisabled] = useState<boolean | undefined>(false);

    const [areYouSureOpen, setAreYouSureOpen] = React.useState(false);

    const handleAreYouSureOpen = () => {
        setAreYouSureOpen(true);
    };

    const handleAreYouSureClose = () => {
        setAreYouSureOpen(false);
    };

    const setCommand = (value: any) => {
        let temp: string = value.trim().replace(/\s+/g, "");
        setValue(temp);
        if (temp.length === 0) {
            setHasUnsentValue(false);
        } else {
            setHasUnsentValue(true);
        }
    };

    const submit = () => {
        if (isBtnDisabled === false) {
            // remove all the spaces from the string
            let temp: string = value.trim().replace(/\s+/g, "");

            if (temp.toLocaleLowerCase().indexOf("r0=0") != -1) {
                handleAreYouSureOpen();
            } else {
                doSubmit();
            }
        }
    };

    const doSubmit = () => {
        setIsBtnDisabled(true);
        let promise = viewModel.submit(value, simId, deviceId.toString());

        promise.then((result) => {
            if (result.wasSuccessful === true) {
                addCommandHistory(result.payload.commandHistory, result.payload.commands);
                setHasUnsentValue(false);
                setValue("");
            }
            setIsBtnDisabled(false);
        });
    };

    const handleContinue = () => {
        handleAreYouSureClose();
        doSubmit();
    };

    return useObserver(() => (
        <>
            <DeleteModal
                open={areYouSureOpen}
                onClose={handleAreYouSureClose}
                onDelete={handleContinue}
                title="Shut down unit?"
                text="Are you sure you want to shut down the unit with command r0=0?"
            />
            <DeviceCommandSectionBox style={{ marginBottom: "0", padding: "15px 0 15px 0", minHeight: "45px" }}>
                <CommandSectionBox>
                    {viewModel.showSuccess && <div className="section-title success">Successfully updated value</div>}
                    {viewModel.showAPIError && <div className="section-title error">{viewModel.errorMessage}</div>}
                    {!viewModel.showSuccess && !viewModel.showAPIError && (
                        <>
                            <Grid container direction="row" spacing={1}>
                                <Grid item xs={3} className="column-title">
                                    <div>Commands: (e.g. G1=4,G2=6,P0=60,S0=10,U0=20)</div>
                                </Grid>
                                <Grid item xs={3} className="column-title">
                                    <TextField
                                        className="form-control"
                                        value={value}
                                        onChange={(event) => setCommand(event.target.value)}
                                        inputProps={{ placeholder: "e.g. G1=4,G2=6,P0=60,S0=10,U0=20", maxLength: 512 }}
                                    />
                                </Grid>
                                <Grid item xs={6} className="column-title" style={{ marginRight: "-30px" }}></Grid>
                            </Grid>
                        </>
                    )}
                </CommandSectionBox>
            </DeviceCommandSectionBox>
            <DeviceCommandSectionBox style={{ marginBottom: 0, padding: "15px 0 0 0", minHeight: "45px", borderBottom: "none" }}>
                <Grid container direction="row" spacing={1}>
                    <Grid item xs={11} className="column-title">
                        <Typography variant="h6"></Typography>
                    </Grid>
                    <Grid item xs={1} className="column-title" style={{ marginRight: "-30px" }}>
                        <SectionSend>
                            <SendBtn disabled={isBtnDisabled} type="button" onClick={() => submit()}>
                                <div className="btnText">Send</div>
                            </SendBtn>
                        </SectionSend>
                    </Grid>
                </Grid>
            </DeviceCommandSectionBox>
        </>
    ));
}
