import { Theme } from "@material-ui/core";
import { KeyValuePair } from "Core/Models/KeyValuePair";

export enum DisplayUnit {
    WithProperty = 1,
    WithoutProperty = 2,
    Hidden = 3,
}

export class DisplayUnitHelpers {
    public static getText = (status: DisplayUnit) => {
        switch (status) {
            case DisplayUnit.Hidden: {
                return "Hidden";
            }
            case DisplayUnit.WithoutProperty: {
                return "Without a Property";
            }
            case DisplayUnit.WithProperty:
            default: {
                return "With a Property";
            }
        }
    };

    public static getClassName = (status: DisplayUnit) => {
        switch (status) {
            case DisplayUnit.Hidden:
                return "device_hidden";

            case DisplayUnit.WithoutProperty:
                return "device_noproperty";

            case DisplayUnit.WithProperty:
            default:
                return "device_property";
        }
    };

    public static getOptionsKeyValuePair = (): KeyValuePair[] => {
        const retVal: KeyValuePair[] = [];

        for (const key in DisplayUnit) {
            const keyAsInt = parseInt(key);

            if (isNaN(keyAsInt) === false) {
                const item = keyAsInt as DisplayUnit;

                retVal.push({
                    key: DisplayUnitHelpers.getText(item),
                    value: keyAsInt,
                });
            }
        }

        return retVal;
    };
}
