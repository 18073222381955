import { ModelBase } from "Core/Models/ModelBase";
import * as MobX from "mobx";
import { validate, validateOrReject, Contains, IsInt, Length, IsEmail, IsFQDN, IsDate, Min, Max, IsNotEmpty, ValidateIf, Equals } from "class-validator";
import { RegisterAddressModel } from "./RegisterUserAddressModel";

export class RegisterUserModel extends ModelBase<RegisterUserModel, RegisterUserModelDTO> {
    @MobX.observable
    @IsNotEmpty({ message: "Last name is required" })
    public lastName: string = "";

    @MobX.observable
    @IsNotEmpty({ message: "First name is required" })
    public firstName: string = "";

    @MobX.observable
    public age: number | string = "";

    @MobX.observable
    public areaOfInterest: number | string = "";

    @MobX.observable
    @IsNotEmpty({ message: "Email address is required" })
    @IsEmail({}, { message: "Email address is not correct" })
    public emailAddress: string = "";

    @MobX.observable
    @IsNotEmpty({ message: "Password is required" })
    public password: string = "";

    @MobX.observable
    //@IsNotEmpty({ message: "Email address is required" })
    //@ValidateIf(that => that.password !== that.confirmPassword)
    //@Length(1, 0, { message: "password !== passwordConfirmation" })
    public confirmPassword: string = "";

    @MobX.observable
    public address: RegisterAddressModel = new RegisterAddressModel();

    @MobX.observable
    @Equals(true, { message: "Must be ticked" })
    public terms: boolean = false;

    @MobX.observable
    @IsNotEmpty({ message: "Password is required" })
    public gender: string = "";

    @MobX.observable
    public dob: Date = new Date();

    @MobX.observable
    public tod: Date = new Date();

    public fromDto = (model: RegisterUserModelDTO) => {};
    public toDto = (model: RegisterUserModel) => {};
}

export interface RegisterUserModelDTO {
    firstName: string;
    lastName: string;
    age: number | string;
    emailAddress: string;
    password: string;
    confirmPassword: string;
    address1: string;
    address2: string;
    city: string;
    county: string;
    postcode: string;
    saveAddress: boolean;
    terms: boolean;
}
