import React, { useRef, useEffect } from "react";
import { useObserver } from "mobx-react-lite";
import { GutterSensLatestImage } from "./GutterSensLatestImage";
import { GutterSensOverviewGraph } from "Custom/Views/Installations/GutterSensOverviewGraph";
import { GutterSensTabContent } from "../GuttersensTabContent";
import { generateID, sortByStringDates } from "Core/Utils/Utils";
import {
    InstallationImage,
    InstallationStatusDataDTO,
    InstallationImageFlipped,
    SensorValueDTO,
    Installation,
    InstallationListItem,
    RoofType,
    SensorValueMinDTO,
    KeyPerformanceData,
    TrippedAlertActionDisplay,
} from "Custom/Models/Domain/Installations";
import {
    OverviewBox,
    OverViewButton,
    OverviewGraphBox,
    RecentAlertRow,
    RecentAlertWrapper,
    SiphonicWrapper,
    StatusRow,
    TabCellHeader,
    TabOverviewCell,
    TabOverviewCellBlock,
} from "./TabOverview.style";
import { DeviceAlertActionItemModelDTO, DeviceAlertActionModelDTO, DeviceConditionSetModelDTO } from "../TabConfigurationModel";
import { UnitStatusBox } from "Custom/Components";
import { useRouter } from "Core/Utils";
import { Client } from "Custom/Globals/AppUrls";
import { TAB_ANALYTICS, TAB_DEVICEDATA, TAB_METRICS } from "../InstallationView";
import { CircularDisplay, ICircularDisplayArrow } from "Custom/Components/Displays/CircularDisplay";
import { AlertAnalyticBarChart } from "Custom/Components/BarCharts/AlertAnalyticBarChart";
import { KeyPerformanceDataControl } from "./KeyPerformanceData";
import { formatDateTime } from "Custom/Utils/format";
import { ConditionSetMetricDto } from "../Metrics/ConditionSetMetric";
import { ConditionSetMetricOverviewComponent } from "./ConditionSetMetricOverviewComponent";
import { getStandingWaterBackgroundColour, getStandingWaterTextColour, StandingWaterBox } from "Custom/Components/StandingWater/StandingWater";

interface TabOverviewProps {
    device: InstallationListItem | undefined;
    deviceImages: InstallationImage[];
    deviceStatusData: InstallationStatusDataDTO | undefined;
    conditionSet: DeviceConditionSetModelDTO | undefined;
    alertAction: DeviceAlertActionModelDTO | undefined;
    readings: SensorValueDTO[];
    keyPerformance: KeyPerformanceData;
    recentAlerts: TrippedAlertActionDisplay[];
    coreAlerts: DeviceAlertActionItemModelDTO[];
    conditionHistory: ConditionSetMetricDto[];
    conditionHistoryTotal: number;

    //Note this re-using the barchart
    siphonicList: DeviceAlertActionItemModelDTO[];

    showImageModal: (id: number) => void;
    updateImage: (deviceImageFlipped: InstallationImageFlipped) => any;
    changeTab: (hashKey: string) => void;
}

export interface LineGraphState {
    width: number;
    height: number;
}

export interface CapacityState {
    value: string;
    arrow: ICircularDisplayArrow;
}

export function TabOverview(props: TabOverviewProps) {
    const router = useRouter();
    const {
        changeTab,
        conditionHistory,
        conditionHistoryTotal,
        coreAlerts,
        device,
        alertAction,
        deviceImages,
        deviceStatusData,
        conditionSet,
        recentAlerts,
        readings,
        siphonicList,
        showImageModal,
        updateImage,
    } = props;
    const graphHeight: number = 260;
    const graphWidth: number = 285;
    const [dimensions, setDimensions] = React.useState<LineGraphState>({
        width: graphWidth,
        height: graphHeight,
    });

    const [capacity, setCapacity] = React.useState<CapacityState>({
        value: "",
        arrow: ICircularDisplayArrow.NONE,
    });

    const componentRef: any = useRef(generateID());

    ///
    /// updateWindowDimensions
    ///
    const updateWindowDimensions = () => {
        if (componentRef !== undefined && componentRef != null && componentRef.current !== null && componentRef.current !== undefined) {
            setDimensions({
                width: graphWidth, //componentRef!.current!.offsetWidth,
                height: graphHeight,
            });
        }
    };

    useEffect(() => {
        updateWindowDimensions();
        window.addEventListener("resize", updateWindowDimensions);

        // tidy up after yourself
        return () => {
            window.removeEventListener("resize", updateWindowDimensions);
            setCapacity({ value: "", arrow: ICircularDisplayArrow.NONE });
        };
    }, []);

    useEffect(() => {
        let value: string = "";
        let arrow: ICircularDisplayArrow = ICircularDisplayArrow.NONE;

        if (deviceStatusData !== null && deviceStatusData !== undefined) {
            let maxWorkingHeightValue: number = 0;
            maxWorkingHeightValue = Math.min(deviceStatusData!.side1_Height, deviceStatusData!.side2_Height);

            if (deviceStatusData!.roofType === RoofType.Flat) {
                maxWorkingHeightValue = deviceStatusData!.maxHeight;
            }

            if (readings.length > 0 && deviceStatusData!.install_Height > 0) {
                // readings should be sorted reversed?????
                let sortedReadings: SensorValueDTO[] = readings.slice(0).sort((a: SensorValueDTO, b: SensorValueDTO) => {
                    return sortByStringDates(b.updated, a.updated);
                });

                let latestValue: number = sortedReadings[0].calculatedValue;
                let installHeight: number = deviceStatusData!.install_Height;
                let percentage: number = 0;

                if (maxWorkingHeightValue > 0) {
                    // Don't want divide by 0
                    percentage = ((installHeight - latestValue) / maxWorkingHeightValue) * 100;
                }

                if (percentage > 100) {
                    value = "100%";
                } else {
                    value = percentage.toFixed(0) + "%";
                }

                if (readings.length > 1 && maxWorkingHeightValue > 0) {
                    if (installHeight - sortedReadings[0].calculatedValue === installHeight - sortedReadings[1].calculatedValue) {
                        arrow = ICircularDisplayArrow.SAME;
                    } else if (installHeight - sortedReadings[0].calculatedValue > installHeight - sortedReadings[1].calculatedValue) {
                        arrow = ICircularDisplayArrow.UP;
                    } else {
                        arrow = ICircularDisplayArrow.DOWN;
                    }
                }
            } else {
                value = "N/A";
                arrow = ICircularDisplayArrow.NONE;
            }
        }

        setCapacity({ value: value, arrow: arrow });
    }, [deviceStatusData, device, readings, recentAlerts, coreAlerts, conditionHistory, conditionHistoryTotal, siphonicList]);

    const maxSiphonicsWidth: number = 300; //Math.max(...siphonicList.map((a) => a.count)) > 1000 ? 280 : 300;

    return useObserver(() => (
        <GutterSensTabContent>
            <OverviewBox>
                <div className="parent">
                    <TabOverviewCell>
                        <TabOverviewCellBlock>
                            <TabCellHeader>
                                <div className="celltitle">Current Status</div>
                            </TabCellHeader>
                            <div style={{ height: "190px", paddingTop: "15px", paddingBottom: "15px", borderBottom: "1px dashed #585858" }}>
                                <CircularDisplay
                                    centerData={capacity.value}
                                    height={160}
                                    width={160}
                                    circleDiameter={160}
                                    fillColor="#3a3c41"
                                    strokeColor={device !== null && device !== undefined ? device.getStatus.statusColour : "#FFFFFF"}
                                    legendText="Capacity"
                                    legendInMiddle={true}
                                    arrow={capacity.arrow}
                                    strokeWidth={6}
                                    prefix="status"
                                />
                            </div>
                            <div>
                                <StatusRow className="first">
                                    <div className="status-row-title">Status</div>
                                    <div style={{ flex: "1 0 auto", maxWidth: "204px", margin: "0 auto" }}>
                                        {device !== null && device !== undefined && (
                                            <>
                                                <UnitStatusBox
                                                    className={"device-status overview"}
                                                    backgroundColor={device !== null && device !== undefined ? device.getStatus.statusColour : "#FFFFFF"}
                                                    textColour={device !== null && device !== undefined ? device.getStatus.statusTextColour : "#000000"}
                                                >
                                                    <div className="status-name">{device !== null && device !== undefined ? device.getStatus.name : "Unknown"}</div>
                                                </UnitStatusBox>
                                            </>
                                        )}
                                    </div>
                                </StatusRow>
                                <StatusRow className="last">
                                    <div className="status-row-title">Standing Water</div>
                                    <div className="status-row-sw">
                                        {device !== null && device !== undefined && (
                                            <>
                                                <StandingWaterBox
                                                    className={"standing-water overview"}
                                                    backgroundColor={
                                                        deviceStatusData !== null && deviceStatusData !== undefined
                                                            ? getStandingWaterBackgroundColour(deviceStatusData!.standingWaterEnum)
                                                            : "#FFFFFF"
                                                    }
                                                    textColour={
                                                        deviceStatusData !== null && deviceStatusData !== undefined
                                                            ? getStandingWaterTextColour(deviceStatusData!.standingWaterEnum)
                                                            : "#000000"
                                                    }
                                                >
                                                    <div className="status-name">
                                                        {deviceStatusData !== null && deviceStatusData !== undefined
                                                            ? deviceStatusData!.standingWaterPercent + "% over " + deviceStatusData!.standingWaterDays + " days"
                                                            : ""}
                                                    </div>
                                                </StandingWaterBox>
                                            </>
                                        )}
                                    </div>
                                </StatusRow>
                            </div>
                        </TabOverviewCellBlock>
                    </TabOverviewCell>
                    <TabOverviewCell>
                        <TabOverviewCellBlock>
                            <TabCellHeader>
                                <div className="celltitle">Key Performance Data</div>
                            </TabCellHeader>
                            <KeyPerformanceDataControl keyPerformance={props.keyPerformance} statusData={props.deviceStatusData} />
                        </TabOverviewCellBlock>
                    </TabOverviewCell>
                    <TabOverviewCell>
                        <TabOverviewCellBlock>
                            <TabCellHeader>
                                <div className="celltitle">All-time Alerts{/*  ({recentAlerts.length}) */}</div>
                            </TabCellHeader>
                            <div style={{ height: "280px", width: "100%" }}>
                                {recentAlerts.length === 0 && (
                                    <RecentAlertWrapper id="recentalertWrapper">
                                        <div className="noalerts">No recent alerts</div>
                                    </RecentAlertWrapper>
                                )}

                                {recentAlerts.length > 0 && (
                                    <RecentAlertWrapper id="recentalertWrapper">
                                        {recentAlerts.map((a: TrippedAlertActionDisplay) => {
                                            return (
                                                <RecentAlertRow style={{ backgroundColor: a.alertColour, color: a.alertTextColour }}>
                                                    <div>{formatDateTime(a.latestValueTime!)}</div>
                                                    <div>{a.name}</div>
                                                </RecentAlertRow>
                                            );
                                        })}
                                    </RecentAlertWrapper>
                                )}
                            </div>
                        </TabOverviewCellBlock>
                    </TabOverviewCell>
                    <TabOverviewCell>
                        <TabOverviewCellBlock>
                            <TabCellHeader>
                                <div className="celltitle">Latest Images</div>
                            </TabCellHeader>
                            {deviceImages.length === 0 && (
                                <RecentAlertWrapper id="recentalertWrapper">
                                    <div className="noalerts">No recent images</div>
                                </RecentAlertWrapper>
                            )}
                            {deviceImages.length > 0 && <GutterSensLatestImage deviceImages={deviceImages} showImageModal={showImageModal} />}
                        </TabOverviewCellBlock>
                    </TabOverviewCell>

                    <TabOverviewCell id="heightgraph-box">
                        <TabOverviewCellBlock id="heightgraph-cellblock">
                            <TabCellHeader>
                                <div className="celltitle">
                                    Height Graph <div className="period">(last day)</div>
                                </div>
                            </TabCellHeader>
                            {readings.length === 0 && (
                                <RecentAlertWrapper id="recentalertWrapper">
                                    <div className="noalerts">No sensor values for the period</div>
                                </RecentAlertWrapper>
                            )}
                            {readings.length > 0 && (
                                <div style={{ height: "280px" }}>
                                    <OverviewGraphBox ref={componentRef} style={{ marginTop: "10px" }}>
                                        <GutterSensOverviewGraph
                                            graphHeight={dimensions.height}
                                            readings={readings}
                                            graphWidth={dimensions.width}
                                            conditionSet={conditionSet}
                                            deviceStatusData={deviceStatusData}
                                            alertAction={alertAction}
                                            showGraphHeader={false}
                                            showGraphLegend={false}
                                            backgroundColour={"#3a3c41"}
                                        />
                                    </OverviewGraphBox>
                                </div>
                            )}
                            <OverViewButton onClick={() => changeTab(TAB_DEVICEDATA)}>
                                <div className="buttontext">View Graph</div>
                            </OverViewButton>
                        </TabOverviewCellBlock>
                    </TabOverviewCell>
                    <TabOverviewCell>
                        <TabOverviewCellBlock>
                            <TabCellHeader>
                                <div className="celltitle">
                                    Condition History <div className="period">(last 90 days)</div>
                                </div>
                            </TabCellHeader>
                            <div style={{ height: "280px" }}>
                                <ConditionSetMetricOverviewComponent
                                    imageHeight={270}
                                    imageWidth={300}
                                    marginTop={30}
                                    metrics={conditionHistory}
                                    total={conditionHistoryTotal}
                                    isLoading={false}
                                    counter={0}
                                    backgroundColour="#3a3c41"
                                />
                            </div>
                            <OverViewButton onClick={() => changeTab(TAB_METRICS)}>
                                <div className="buttontext">View Metrics</div>
                            </OverViewButton>
                        </TabOverviewCellBlock>
                    </TabOverviewCell>
                    <TabOverviewCell>
                        <TabOverviewCellBlock>
                            <TabCellHeader>
                                <div className="celltitle">
                                    Core Alerts <div className="period">(last 90 days)</div>
                                </div>
                            </TabCellHeader>
                            {coreAlerts.length === 0 && (
                                <RecentAlertWrapper id="recentalertWrapper">
                                    <div className="noalerts">No alerts set</div>
                                </RecentAlertWrapper>
                            )}
                            {coreAlerts.length > 0 && (
                                <div style={{ height: "280px" }}>
                                    <AlertAnalyticBarChart
                                        backgroundColour="#3a3c41"
                                        textColor="#ffffff"
                                        imageHeight={260}
                                        imageWidth={300}
                                        marginTop={20}
                                        alertAnalytics={coreAlerts}
                                        total={coreAlerts.length}
                                        isLoading={false}
                                        title={""}
                                        counter={1}
                                        halfgraph={true}
                                    />
                                </div>
                            )}
                            <OverViewButton onClick={() => changeTab(TAB_ANALYTICS)}>
                                <div className="buttontext">View Analytics</div>
                            </OverViewButton>
                        </TabOverviewCellBlock>
                    </TabOverviewCell>
                    <TabOverviewCell>
                        <TabOverviewCellBlock>
                            <TabCellHeader>
                                {siphonicList.length > 0 && (
                                    <div className="celltitle">
                                        Siphonics <div className="period">(last 90 days)</div>
                                    </div>
                                )}
                                {siphonicList.length === 0 && <div className="celltitle">Siphonics</div>}
                            </TabCellHeader>
                            {siphonicList.length === 0 && (
                                <SiphonicWrapper id="recentalertWrapper">
                                    <div className="nosiphonics">Non siphonic unit</div>
                                </SiphonicWrapper>
                            )}
                            {siphonicList.length > 0 && (
                                <div style={{ height: "280px" }}>
                                    <AlertAnalyticBarChart
                                        backgroundColour="#3a3c41"
                                        textColor="#ffffff"
                                        imageHeight={260}
                                        imageWidth={maxSiphonicsWidth}
                                        marginTop={20}
                                        alertAnalytics={siphonicList}
                                        total={siphonicList.length}
                                        isLoading={false}
                                        title={""}
                                        counter={100}
                                        halfgraph={true}
                                        isSiphonics={true}
                                    />
                                </div>
                            )}
                        </TabOverviewCellBlock>
                    </TabOverviewCell>
                </div>
            </OverviewBox>
        </GutterSensTabContent>
    ));
}
