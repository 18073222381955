import { ValueBetween } from "./DeviceSection.style";
import styled from "styled-components";
import { Box } from "@material-ui/core";
import { TabCommonBox } from "./TabCommonStyle.style";

export const DeviceAttributeBox: any = styled(TabCommonBox)`
    padding: 30px;
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    width: 100%;
`;

export const DeviceAttributeRow: any = styled(Box)`
    display: flex;
    flex: 1 0 auto;
    flex-direction: row;
    flex-wrap: wrap;
`;

export const DeviceAttributeImageRow: any = styled(Box)`
    width: 100%;
    display: flex;
    flex: 1 0 auto;
    flex-flow: row nowrap;
    flex-direction: row;
    height: auto;

    img,
    svg {
        min-width: 100% !important;
    }

    /* border-bottom: 2px dashed #b2b2b2; */
    padding-bottom: 20px;
`;

export const DeviceAttributeImageTitleRow: any = styled(Box)`
    width: 100%;
    display: flex;
    flex: 1 0 auto;
    flex-flow: row nowrap;
    flex-direction: row;
    height: auto;
    justify-content: space-between;
    text-align: center;
    font: normal normal normal 18px/27px Mont;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    padding: 20px 0;
    /* border-top: 2px dashed #b2b2b2; */
`;

export const DeviceAttributeCell: any = styled(Box)`
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;

    .cell {
        margin-right: 30px;
        width: 415px;
    }

    .body {
        background: #3a3c41 0% 0% no-repeat padding-box;
        padding: 15px;
        border-radius: 0px 0px 4px 4px;
    }

    .header {
        background: #475c89 0% 0% no-repeat padding-box;
        box-shadow: inset 0px 1px 1px #ffffff40;
        border-radius: 4px 4px 0px 0px;
        opacity: 1;
        height: 45px;
        font-size: 14px;
        display: flex;
        align-items: center;
        padding: 0 15px;
    }

    .main {
        display: flex;
        flex: 1 0 auto;
        flex-direction: row;
        font-size: 18px;
        padding-bottom: 15px;
        font-weight: 700;
        font-family: "Mont-Bold", "Montserrat", "Roboto Condensed", "Helvetica", "Arial", "sans-serif";
        text-align: left;
        letter-spacing: 0px;
        colo &.installheight {
            margin-top: 27px;
            .data {
                font-weight: 300;
                padding-left: 10px;
                text-align: left;
                font: normal normal normal 18px/27px Arial;
                letter-spacing: 0px;
            }
        }
    }

    div.summary {
        display: flex;
        flex: 1 0 auto;
        flex-direction: row;
        padding-bottom: 5px;

        .flood-warning {
            color: ${(props) => props.theme.app.colors.deviceWarning.flood};
        }

        .red-warning {
            color: ${(props) => props.theme.app.colors.deviceWarning.red};
        }

        .amber-warning {
            color: ${(props) => props.theme.app.colors.deviceWarning.amber};
        }

        .green-ok {
            color: ${(props) => props.theme.app.colors.deviceWarning.green};
        }

        .sideRowCell-angles {
            display: flex;
            flex-direction: row;
        }

        .title,
        .data {
            font-size: 12px;
            font: normal normal normal 12px/24px "Mont";
        }

        .data {
            padding-left: 10px;
        }
    }

    .title {
        font-weight: 700;
        font-family: "Mont-Bold", "Montserrat", "Roboto Condensed", "Helvetica", "Arial", "sans-serif";
    }

    .installheight {
    }

    .installheight {
        .title,
        .data {
            font-family: "Mont", "Montserrat", "Roboto Condensed", "Helvetica", "Arial", "sans-serif";
            font-weight: regular;
            font-size: 18px;
        }
        .data {
            margin-left: 10px;
        }
    }

    min-width: 320px;
`;
