import * as React from "react";
import { useState } from "react";
import { BaseModal } from "Custom/Components/Modal/BaseModal";
import { SuccessBtn, SuccessExtBtn } from "Custom/StylesAppSpecific/Controls/SuccessBtn";
import { CancelBtn } from "Custom/StylesAppSpecific/Controls/CancelBtn";
import { ContractorBox, ContractorSelectBox, DeviceStatusBox, UnitEditModalDialogPaper, UnitRow, UnitTitle } from "./UnitEditDataModel.styles";
import { UnitDataViewModel } from "./UnitDataViewModel";
import { Box, FormControl, FormControlLabel, FormHelperText, MenuItem, Radio, RadioGroup, Select, Typography } from "@material-ui/core";
import { useObserver } from "Core/Base";
import { RoofcareInput } from "Custom/StylesAppSpecific/Controls/RoofcareInput";
import { generateID, isValidDateString } from "Custom/Utils/utils";
import { Loader } from "Core/Components";

import { ControlLabel } from "Custom/Components/ControlLabel/ControlLabel";
import { TestGradeDTO } from "./TestGrade";
import { ApiResult } from "Core/Models";
import { DeviceAttributeCell, DeviceAttributeRow } from "../Installations/TabAttributes.style";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment, { Moment } from "moment";
import { UnitVersionDTO } from "./UnitVersion";
import { DeviceUnitStatusDTO } from "./DeviceUnitStatus";
import { UnitFirmwareDTO } from "./UnitFirmware";
import { DeviceUnitDataAndRelatedDTO, DeviceUnitDataMinDTO, DeviceUnitDataMinModel } from "./DeviceUnitDataMin";
import { UnitNoteSection } from "./UnitNote/UnitNoteSection";
import * as RouterUtil from "Custom/Utils/routing";
import { Stores, StoresContext } from "Custom/Stores";
import { DeviceBox, DeviceRow, DeviceStatusCell, DeviceStatusRow } from "../Projects/Details/Properties/Installations/InstallationAddModal.styles";
import { DrainageType, ProjectDropdown, RoofType } from "Custom/Models/Domain";
import { InstallationAddViewModel } from "../Projects/Details/Properties/Installations/InstallationAddViewModel";
import { InstallationAddEdit } from "Custom/Models/Domain/Installations/InstallationAddEdit";
import { UpsertDeviceUnitRequest } from "./UpsertDeviceUnitRequest";
interface IProps {
    open: boolean;
    onClose(saved: boolean): void;
    deviceId: string;
    simId: string;
}

interface INoteState {
    selectedItemId: number | undefined;
    selectedNote: string;
    isDeleted: boolean;
}

export const UnitEditDataModel: React.FC<IProps> = ({ onClose, open, deviceId, simId }) => {
    const store: Stores = React.useContext<Stores>(StoresContext);
    const [viewModelStatus] = useState(() => new InstallationAddViewModel());
    const [viewModel] = useState(() => new UnitDataViewModel(viewModelStatus));
    const [errorMessage, setErrorMessage] = useState("");
    const [isLoading, setisLoading] = useState(false);

    const [options, setOptions] = useState<{
        testGradeOptions: JSX.Element[];
        unitStatusOptions: JSX.Element[];
        unitVersionOptions: JSX.Element[];
        unitFirmwareOptions: JSX.Element[];
        projectOptions: JSX.Element[];
    }>({
        testGradeOptions: [],
        unitStatusOptions: [],
        unitVersionOptions: [],
        unitFirmwareOptions: [],
        projectOptions: [],
    });

    const [modalTitle, setModalTitle] = useState<string>("Edit Unit Data");

    React.useEffect(() => {
        return () => {
            // Clean up after yourself
            viewModel.clearModel();
        };
    }, []);

    React.useEffect(() => {
        setisLoading(true);
        window.scrollTo(0, 0);
        if (open === true) {
            setErrorMessage("");
            viewModel.loadModalAsync(deviceId).then((result: ApiResult<DeviceUnitDataAndRelatedDTO>) => {
                if (result.wasSuccessful) {
                } else {
                    setErrorMessage("Failed to load the unit device");
                }
                setisLoading(false);
            });
        }
    }, [open]);

    React.useEffect(() => {
        let dataUpdated: boolean = false;
        let tgOptions: JSX.Element[] = options.testGradeOptions;
        let usOptions: JSX.Element[] = options.unitStatusOptions;
        let uvOptions: JSX.Element[] = options.unitVersionOptions;
        let fmOptions: JSX.Element[] = options.unitFirmwareOptions;
        let pOptions: JSX.Element[] = options.projectOptions;

        if (options.testGradeOptions.length === 0) {
            tgOptions = viewModel.getTestGrades.map((c: TestGradeDTO, index: number) => {
                return (
                    <MenuItem key={generateID()} value={c.id!}>
                        {c.name}
                    </MenuItem>
                );
            });
            dataUpdated = true;
        }

        if (options.unitStatusOptions.length === 0) {
            usOptions = viewModel.getUnitStatus.map((c: DeviceUnitStatusDTO, index: number) => {
                return (
                    <MenuItem key={generateID()} value={c.id!}>
                        {c.name}
                    </MenuItem>
                );
            });
            dataUpdated = true;
        }
        if (options.unitVersionOptions.length === 0) {
            uvOptions = viewModel.getVersions.map((c: UnitVersionDTO, index: number) => {
                return (
                    <MenuItem key={generateID()} value={c.id!}>
                        {c.name}
                    </MenuItem>
                );
            });
            dataUpdated = true;
        }

        if (options.unitFirmwareOptions.length === 0) {
            fmOptions = viewModel.getFirmware.map((c: UnitFirmwareDTO, index: number) => {
                return (
                    <MenuItem key={generateID()} value={c.id!}>
                        {c.name}
                    </MenuItem>
                );
            });
            dataUpdated = true;
        }

        if (options.projectOptions.length === 0) {
            pOptions = viewModel.getProjects.map((c: ProjectDropdown, index: number) => {
                return (
                    <MenuItem key={generateID()} value={c.id!}>
                        {c.displayName}
                    </MenuItem>
                );
            });
            dataUpdated = true;
        }

        if (dataUpdated === true) {
            setOptions({ projectOptions: pOptions, testGradeOptions: tgOptions, unitStatusOptions: usOptions, unitVersionOptions: uvOptions, unitFirmwareOptions: fmOptions });
        }
    }, [viewModel.getVersions, viewModel.getTestGrades, viewModel.getTestGrades, viewModel.getFirmware]);

    React.useEffect(() => {
        if (open) {
            setErrorMessage(viewModel.Errors);
        }
    }, [viewModel.IsErrored]);

    const onSave = () => {
        if (viewModel.islocalModelValid() === false || viewModelStatus.isUnitStatusDataModelValid() === false) {
            setErrorMessage("Please fix the validation errors and re-submit.");
        } else {
            viewModel.upsertUnit().then((result: ApiResult<UpsertDeviceUnitRequest>) => {
                if (result.wasSuccessful) {
                    callOnClose(true);
                } else {
                    window.scrollTo(0, 0);
                    setErrorMessage("Failed to save the installation device");
                }
            });
        }
    };

    const onSaveAndContinue = () => {
        if (viewModel.islocalModelValid() === false || viewModelStatus.isUnitStatusDataModelValid() === false) {
            setErrorMessage("Please fix the validation errors and re-submit.");
        } else {
            viewModel.upsertUnit().then((result: ApiResult<UpsertDeviceUnitRequest>) => {
                if (result.wasSuccessful) {
                    window.scrollTo(0, 0);
                } else {
                    window.scrollTo(0, 0);
                    setErrorMessage("Failed to save the installation device");
                }
            });
        }
    };

    const handleTestGradeChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        viewModel.setTestGradeValue(parseInt(event.target.value as string));
    };

    const handleUnitStatusChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        viewModel.setUnitStatusValue(parseInt(event.target.value as string));
    };

    const handleQRProjectChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        viewModel.setQRProjectValue(event.target.value as string);
    };

    const handleUnitVersionChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        viewModel.setVersionValue(parseInt(event.target.value as string));
    };

    const handleUnitFirmwareChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        viewModel.setFirmwareValue(parseInt(event.target.value as string));
    };

    const isInError = (fieldName: string) => {
        let isValid = viewModel.getValid(fieldName);
        return !isValid;
    };

    const close = () => {
        callOnClose(false);
    };

    const callOnClose = (saved: boolean = false) => {
        viewModel.clearModel();
        onClose(saved);
    };

    const successBtnText: string = "Update";

    const updateDeliveryDate = (date: any, value: string | null | undefined): void => {
        const mom: Moment = moment(value, "DD/MM/YYYY");
        if (value !== null && value !== undefined) {
            if (isValidDateString(value!) === true && value!.length === 10) {
                viewModel.setDeliveryDate(mom);
            }
        } else {
            viewModel.setDeliveryDate(null);
        }
    };

    const updateAcceptanceDate = (date: any, value: string | null | undefined) => {
        const mom: Moment = moment(value, "DD/MM/YYYY");
        if (value !== null && value !== undefined) {
            if (isValidDateString(value!) === true && value!.length === 10) {
                viewModel.setAcceptanceDate(mom);
            }
        } else {
            viewModel.setAcceptanceDate(null);
        }
    };

    let canEditNotes: boolean = false;
    if (RouterUtil.requireAdminLogin(store) || RouterUtil.requireContractorLogin(store)) {
        canEditNotes = true;
    }

    const dateFormatter = (str: any) => {
        return str;
    };

    const onRoofTypeChange = (e: any) => {
        viewModelStatus.setValue("roofType", parseInt((e.target as HTMLInputElement).value));
    };

    const onDrainageTypeChange = (e: any) => {
        viewModelStatus.setDrainageTypeValue(parseInt((e.target as HTMLInputElement).value));
    };

    return useObserver(() => (
        <BaseModal
            title={modalTitle}
            onClose={callOnClose}
            open={open}
            PaperComponent={UnitEditModalDialogPaper}
            actions={
                <>
                    <SuccessExtBtn onClick={onSaveAndContinue}>{successBtnText + " and continue"}</SuccessExtBtn>
                    <SuccessBtn onClick={onSave}>{successBtnText}</SuccessBtn>
                    <CancelBtn onClick={close}>Cancel</CancelBtn>
                </>
            }
        >
            {(viewModel.getIfLoading === true || isLoading === true) && <Loader />}

            {viewModel.getIfLoading === false && isLoading === false && (
                <>
                    {errorMessage.length > 0 && (
                        <Box>
                            <FormHelperText style={{ color: "red" }}>{errorMessage}</FormHelperText>
                        </Box>
                    )}
                    <ContractorBox>
                        <DeviceAttributeRow key={generateID()}>
                            <DeviceAttributeCell key={generateID()}>
                                <UnitTitle>
                                    <div className="title" style={{ color: "#ffffffs" }}>
                                        Sim Id:
                                    </div>
                                    <div className="data" style={{ color: "#ffffffs" }}>
                                        {simId}
                                    </div>
                                </UnitTitle>
                            </DeviceAttributeCell>
                        </DeviceAttributeRow>

                        <DeviceStatusRow>
                            <RoofcareInput<DeviceUnitDataMinModel>
                                type="text"
                                label="Unit Name"
                                validateOnBlur={true}
                                viewModel={viewModel}
                                fieldName="unitName"
                                shrink={true}
                                maxLength={50}
                                fullwidth
                                inputProps={{ placeholder: "Please enter a unit name" }}
                            />
                            <DeviceStatusCell>
                                <ContractorSelectBox className="projectqr-selectwrapper">
                                    <ControlLabel label="Project for QR" htmlFor="projectqr-select">
                                        <Select
                                            id="projectqr-select"
                                            value={viewModel.model.qrProjectId}
                                            onChange={handleQRProjectChange}
                                            MenuProps={{
                                                getContentAnchorEl: null,
                                                anchorOrigin: {
                                                    vertical: "bottom",
                                                    horizontal: "left",
                                                },
                                                className: "projectqr-select",
                                            }}
                                            style={{ width: "100%", minWidth: "300px", maxWidth: "640px" }}
                                        >
                                            {options.projectOptions}
                                        </Select>
                                        <Box>{isInError("versionId") && <FormHelperText style={{ color: "red" }}>{viewModel.getError("versionId")}</FormHelperText>}</Box>
                                    </ControlLabel>
                                </ContractorSelectBox>
                            </DeviceStatusCell>
                        </DeviceStatusRow>
                        <DeviceBox>
                            <DeviceRow>
                                <div>
                                    <p className="subtitle">Roof Specification</p>
                                </div>
                                <div>
                                    <p className="subtitleadditional">Working Height: {viewModelStatus.getWorkingHeightString}</p>
                                </div>
                                <div className="subtitleadditional">
                                    <p className="subtitleadditional">Freeboard: {viewModelStatus.getFreeboardString}</p>
                                </div>
                            </DeviceRow>
                            <DeviceRow>
                                <div>
                                    <RadioGroup
                                        row
                                        aria-label="units"
                                        defaultValue={viewModelStatus.getValue("roofType")}
                                        value={viewModelStatus.getValue("roofType")}
                                        name="radio-buttons-group"
                                        onChange={onRoofTypeChange}
                                    >
                                        <FormControlLabel value={1} control={<Radio color="default" />} label="Pitched" />
                                        <FormControlLabel value={2} control={<Radio color="default" />} label="Flat" />
                                    </RadioGroup>
                                </div>
                                <div>
                                    <RadioGroup
                                        row
                                        aria-label="units"
                                        defaultValue={viewModelStatus.getValue("drainageType")}
                                        value={viewModelStatus.getValue("drainageType")}
                                        name="radio-buttons-group"
                                        onChange={onDrainageTypeChange}
                                    >
                                        <FormControlLabel value={1} control={<Radio color="default" />} label="Gravity" />
                                        <FormControlLabel value={2} control={<Radio color="default" />} label="Single Siphonics" />
                                        <FormControlLabel value={3} control={<Radio color="default" />} label="Dual Siphonics" />
                                    </RadioGroup>
                                </div>
                                <div>
                                    {viewModelStatus.model.drainageType !== DrainageType.Gravity && (
                                        <>
                                            <Select
                                                style={{ minWidth: "200px", maxWidth: "200px" }}
                                                id={"siphonicbrand-select"}
                                                value={viewModelStatus.model.siphonicBrand}
                                                onChange={(event) => viewModelStatus.setValue<number>("siphonicBrand", parseInt(event.target.value as string, 10))}
                                                MenuProps={{
                                                    getContentAnchorEl: null,
                                                    anchorOrigin: {
                                                        vertical: "bottom",
                                                        horizontal: "left",
                                                    },
                                                }}
                                                fullWidth
                                            >
                                                <MenuItem key={generateID()} value="1">
                                                    Brand 1
                                                </MenuItem>
                                                <MenuItem key={generateID()} value="2">
                                                    Brand 2
                                                </MenuItem>
                                            </Select>
                                            {isInError("siphonicBrand") && <FormHelperText style={{ color: "red" }}>{viewModel.getError("siphonicBrand")}</FormHelperText>}
                                        </>
                                    )}
                                </div>
                            </DeviceRow>
                            <DeviceRow>
                                <RoofcareInput<InstallationAddEdit>
                                    type="number"
                                    label="Install Height"
                                    validateOnBlur={true}
                                    viewModel={viewModelStatus}
                                    fieldName="install_Height"
                                    shrink={true}
                                    inputProps={{ placeholder: "Please enter height", min: 0 }}
                                />
                            </DeviceRow>
                        </DeviceBox>

                        {/* TODO RC001 - Choose SideType  */}

                        {viewModelStatus.model.drainageType !== DrainageType.Gravity && (
                            <DeviceStatusBox>
                                <>
                                    <p className="subtitle">Prime points</p>
                                    <DeviceStatusRow style={{ border: "none" }}>
                                        <RoofcareInput<InstallationAddEdit>
                                            type="number"
                                            label="P1"
                                            validateOnBlur={true}
                                            viewModel={viewModelStatus}
                                            fieldName="p1"
                                            shrink={true}
                                            inputProps={{ placeholder: "Please enter height", min: 0 }}
                                        />
                                    </DeviceStatusRow>
                                    {viewModelStatus.model.drainageType === DrainageType.DualSiphonic && (
                                        <DeviceStatusRow>
                                            <RoofcareInput<InstallationAddEdit>
                                                type="number"
                                                label="P2"
                                                validateOnBlur={true}
                                                viewModel={viewModelStatus}
                                                fieldName="p2"
                                                shrink={true}
                                                inputProps={{ placeholder: "Please enter height", min: 0 }}
                                            />
                                        </DeviceStatusRow>
                                    )}
                                </>
                            </DeviceStatusBox>
                        )}

                        {viewModelStatus.model.roofType === RoofType.Pitched && (
                            <DeviceStatusBox>
                                <>
                                    <DeviceStatusRow style={{ marginTop: "10px" }}>
                                        <RoofcareInput<InstallationAddEdit>
                                            type="number"
                                            label="Base Measurement"
                                            validateOnBlur={true}
                                            viewModel={viewModelStatus}
                                            fieldName="baseMeasurement"
                                            shrink={true}
                                            fullwidth
                                            style={{ minWidth: "255px" }}
                                            inputProps={{ placeholder: "Please enter the base measurement", min: 0 }}
                                        />
                                    </DeviceStatusRow>

                                    <p className="subtitle">Side 1</p>
                                    <DeviceStatusRow>
                                        <RoofcareInput<InstallationAddEdit>
                                            type="number"
                                            label="Length mm"
                                            validateOnBlur={true}
                                            viewModel={viewModelStatus}
                                            fieldName="side1_Length"
                                            shrink={true}
                                            inputProps={{ placeholder: "Please enter length", min: 0 }}
                                        />
                                        <DeviceStatusCell>
                                            <RoofcareInput<InstallationAddEdit>
                                                type="number"
                                                label="Angle between side 1 &#38; base"
                                                validateOnBlur={true}
                                                viewModel={viewModelStatus}
                                                fieldName="side1_Angle"
                                                shrink={true}
                                                fullwidth
                                                style={{ minWidth: "255px" }}
                                                inputProps={{ placeholder: "Please enter angle", min: -180, max: 180 }}
                                            />
                                        </DeviceStatusCell>

                                        {/* TODO RC001 - Add 4 length gutter data in here  */}
                                    </DeviceStatusRow>

                                    <p className="subtitle">Side 2</p>
                                    <DeviceStatusRow>
                                        <RoofcareInput<InstallationAddEdit>
                                            type="number"
                                            label="Length mm"
                                            validateOnBlur={true}
                                            viewModel={viewModelStatus}
                                            fieldName="side2_Length"
                                            shrink={true}
                                            inputProps={{ placeholder: "Please enter length", min: 0 }}
                                        />
                                        <DeviceStatusCell>
                                            <RoofcareInput<InstallationAddEdit>
                                                type="number"
                                                label="Angle between side 2 &#38; base"
                                                validateOnBlur={true}
                                                viewModel={viewModelStatus}
                                                fieldName="side2_Angle"
                                                shrink={true}
                                                fullwidth
                                                style={{ minWidth: "255px" }}
                                                inputProps={{ placeholder: "Please enter angle", min: -180, max: 180 }}
                                            />
                                        </DeviceStatusCell>
                                        {/* TODO RC001 - Add 4 length gutter data in here  */}
                                    </DeviceStatusRow>
                                </>
                            </DeviceStatusBox>
                        )}
                        {viewModelStatus.model.roofType === RoofType.Flat && (
                            <DeviceStatusRow>
                                <RoofcareInput<InstallationAddEdit>
                                    type="number"
                                    label="Max Height mm"
                                    validateOnBlur={true}
                                    viewModel={viewModelStatus}
                                    fieldName="maxHeight"
                                    shrink={true}
                                    inputProps={{ placeholder: "Please enter length", min: 0 }}
                                />
                            </DeviceStatusRow>
                        )}
                        <DeviceRow style={{ marginTop: "15px" }}>
                            <div>
                                <p className="subtitle">Unit Specification</p>
                            </div>
                        </DeviceRow>

                        <UnitRow>
                            <div>
                                <Typography style={{ fontSize: "14px", marginBottom: "10px" }}>Delivery Date</Typography>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        className="datepicker"
                                        placeholder="01/12/2021"
                                        label={""}
                                        value={viewModel.model.deliveryDate}
                                        mask={"__/__/____"}
                                        format="DD/MM/YYYY"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={updateDeliveryDate}
                                        invalidDateMessage="Invalid date"
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                            <div className="cell-right">
                                <Typography style={{ fontSize: "14px", marginBottom: "10px" }}>Acceptance:</Typography>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        className="datepicker"
                                        placeholder="01/12/2021"
                                        label={""}
                                        value={viewModel.model.acceptanceDate}
                                        mask={"__/__/____"}
                                        format="DD/MM/YYYY"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={updateAcceptanceDate}
                                        invalidDateMessage="Invalid date"
                                        rifmFormatter={dateFormatter}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                        </UnitRow>
                        <UnitRow>
                            <FormControl>
                                <ContractorSelectBox className="cell-left">
                                    <ControlLabel label="Test Grade" htmlFor="testgrade-select">
                                        <Select
                                            id="testgrade-select"
                                            value={viewModel.model.testGradeId}
                                            onChange={handleTestGradeChange}
                                            MenuProps={{
                                                getContentAnchorEl: null,
                                                anchorOrigin: {
                                                    vertical: "bottom",
                                                    horizontal: "left",
                                                },
                                                className: "client-select-menu",
                                            }}
                                            style={{ width: "100%", minWidth: "300px", maxWidth: "640px" }}
                                        >
                                            {options.testGradeOptions}
                                        </Select>
                                        <Box>{isInError("testGradeId") && <FormHelperText style={{ color: "red" }}>{viewModel.getError("testGradeId")}</FormHelperText>}</Box>
                                    </ControlLabel>
                                </ContractorSelectBox>
                            </FormControl>
                            <FormControl>
                                <ContractorSelectBox className="cell-right">
                                    <ControlLabel label="Version" htmlFor="version-select">
                                        <Select
                                            id="version-select"
                                            value={viewModel.model.versionId}
                                            onChange={handleUnitVersionChange}
                                            MenuProps={{
                                                getContentAnchorEl: null,
                                                anchorOrigin: {
                                                    vertical: "bottom",
                                                    horizontal: "left",
                                                },
                                                className: "client-select-menu",
                                            }}
                                            style={{ width: "100%", minWidth: "300px", maxWidth: "640px" }}
                                        >
                                            {options.unitVersionOptions}
                                        </Select>
                                        <Box>{isInError("versionId") && <FormHelperText style={{ color: "red" }}>{viewModel.getError("versionId")}</FormHelperText>}</Box>
                                    </ControlLabel>
                                </ContractorSelectBox>
                            </FormControl>
                            <FormControl>
                                <ContractorSelectBox className="cell-right">
                                    <ControlLabel label="Status" htmlFor="status-select">
                                        <Select
                                            id="status-select"
                                            value={viewModel.model.currentUnitStatusId}
                                            onChange={handleUnitStatusChange}
                                            MenuProps={{
                                                getContentAnchorEl: null,
                                                anchorOrigin: {
                                                    vertical: "bottom",
                                                    horizontal: "left",
                                                },
                                                className: "client-select-menu",
                                            }}
                                            style={{ width: "100%", minWidth: "300px", maxWidth: "640px" }}
                                        >
                                            {options.unitStatusOptions}
                                        </Select>
                                        <Box>
                                            {isInError("currentUnitStatusId") && (
                                                <FormHelperText style={{ color: "red" }}>{viewModel.getError("currentUnitStatusId")}</FormHelperText>
                                            )}
                                        </Box>
                                    </ControlLabel>
                                </ContractorSelectBox>
                            </FormControl>
                            <FormControl>
                                <ContractorSelectBox className="cell-right">
                                    <ControlLabel label="Firmware" htmlFor="firmware-select">
                                        <Select
                                            id="firmware-select"
                                            value={viewModel.model.firmwareId}
                                            onChange={handleUnitFirmwareChange}
                                            MenuProps={{
                                                getContentAnchorEl: null,
                                                anchorOrigin: {
                                                    vertical: "bottom",
                                                    horizontal: "left",
                                                },
                                                className: "client-select-menu",
                                            }}
                                            style={{ width: "100%", minWidth: "300px", maxWidth: "640px" }}
                                        >
                                            {options.unitFirmwareOptions}
                                        </Select>
                                        <Box>{isInError("firmwareId") && <FormHelperText style={{ color: "red" }}>{viewModel.getError("firmwareId")}</FormHelperText>}</Box>
                                    </ControlLabel>
                                </ContractorSelectBox>
                            </FormControl>
                        </UnitRow>
                    </ContractorBox>
                    <UnitNoteSection
                        isLoading={viewModel.getIfLoading || isLoading}
                        unitNotes={viewModel.getUnitNotes}
                        canAddNotes={canEditNotes}
                        setNotes={viewModel.setNotes}
                        deviceDbId={viewModel.model.deviceId}
                    />
                </>
            )}
        </BaseModal>
    ));
};
