import { AppUrls } from "Custom/Globals";
import { GenericIdRequest } from "Custom/Models";
import * as MobX from "mobx";
import { FieldType } from "Core/Utils/Utils";
import { ViewModelBase } from "Core/ViewModels/ViewModelBase";
import { ClientModel } from "Custom/Models/Domain/Clients/ClientModel";
import { ClientAndRelatedDTO } from "./ClientAndRelatedModel";
import { ClientPropertyListItem, ClientPropertyListItemDTO } from "../ClientView/ClientPropertyListItem";
import { ClientProjectListItem, ClientProjectListItemDTO } from "../ClientView/ClientProjectListItem";
import { ApiResult } from "Core/Models";
import { AddressModel, Property, PropertyDTO } from "Custom/Models/Domain";
import { Server } from "Custom/Globals/AppUrls";
import { StoresInstance } from "Core/Base";
import { ProjectStatusDropDownListItem } from "Custom/Models/API/ProjectStatusDropDownListItem";
import { ProjectPaymentStatusDropdownListItem } from "Custom/Models/API/ProjectPaymentStatusDropdownListItem";

//extend viewmodel base and passing your model as the generic type
const domainStores = StoresInstance.domain;
export class ClientDetailsViewModel extends ViewModelBase<ClientModel> {
    private static _instance: ClientDetailsViewModel;

    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    @MobX.observable public errorMessage: string = "";
    @MobX.observable public validMessage: string = "";

    public projects = MobX.observable<ClientProjectListItem>([]);
    public properties = MobX.observable<ClientPropertyListItem>([]);
    public projectStatuses = MobX.observable<ProjectStatusDropDownListItem>([]);
    public projectPaymentStatuses = MobX.observable<ProjectPaymentStatusDropdownListItem>([]);

    @MobX.computed
    public get getProperties(): ClientPropertyListItem[] {
        return this.properties.slice(0);
    }

    constructor() {
        //Pass in a new instance of your model
        //By passing in true as the second parameter, we make this model undoable which means we can use save and reset options on the model
        //If you make a change to the model you need to persist it with a saveModel() call
        //If you make changes to your model you can revert it back by calling resetModel()
        super(new ClientModel(), false);
        //EN - Haven't figured out how to make this call work from the base model yet
        //This is only needed if you make use of the validation decorators
        this.setDecorators(ClientModel);
    }

    public doSubmit = async (e: any) => {
        e.preventDefault();

        if (this.isModelValid()) {
            //Do stuff here
            this.errorMessage = "";
            this.validMessage = "Form is valid";
        } else {
            this.errorMessage = "Form is not valid";
            this.validMessage = "";
        }
    };

    private setProjects(projects: ClientProjectListItemDTO[]) {
        this.projects.clear();
        projects.forEach((projectDTO) => {
            const project = new ClientProjectListItem();
            project.fromDto(projectDTO);
            this.projects.push(project);
        });
    }

    private setProperties(properties: ClientPropertyListItemDTO[]) {
        this.properties.clear();
        properties.forEach((propertyDTO) => {
            const property = new ClientPropertyListItem();
            property.fromDto(propertyDTO);
            this.properties.push(property);
        });
    }

    private setProjectStatuses(projectStatuses: ProjectStatusDropDownListItem[]) {
        this.projectStatuses.clear();
        projectStatuses.forEach((projectStatus) => {
            this.projectStatuses.push(projectStatus);
        });
    }

    private setProjectPaymentStatuses(projectPaymentStatuses: ProjectPaymentStatusDropdownListItem[]) {
        this.projectPaymentStatuses.clear();
        projectPaymentStatuses.forEach((projectStatus) => {
            this.projectPaymentStatuses.push(projectStatus);
        });
    }

    public async loadClient(id: string): Promise<ApiResult<ClientAndRelatedDTO>> {
        const request: GenericIdRequest = {
            id: id,
        };
        const apiResult = await this.Post<ClientAndRelatedDTO>(AppUrls.Server.Api.Client.getClientAndRelated, request);

        if (apiResult.wasSuccessful) {
            MobX.runInAction(() => {
                this.model.fromDto(apiResult.payload.client);
                this.setProjects(apiResult.payload.related.projects);
                this.setProperties(apiResult.payload.related.properties);
                this.setProjectStatuses(apiResult.payload.related.projectStatuses);
                this.setProjectPaymentStatuses(apiResult.payload.related.projectPaymentStatuses);
            });
        }

        return apiResult;
    }

    //This must be present in your viewmodel. Just return true if you dont need to validate anything.
    //keyof BlankModel & string lets you add type checking to the fieldName
    //I am using the validator package to make checking easier https://www.npmjs.com/package/validator
    public isFieldValid(fieldName: keyof FieldType<ClientModel>): boolean {
        const { isValid, errorMessage } = this.validateDecorators(fieldName);

        //You need to this two properties after validation
        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    @MobX.action
    public async updatePropertyAddress(addr: AddressModel): Promise<boolean> {
        const propertyItem: ClientPropertyListItem | undefined = this.properties.find((a) => a.id === addr.id);
        if (propertyItem === undefined) {
            throw new Error("Failed to store property");
        }

        const request = propertyItem?.toClientPropertyListItemDtofromAddress(addr);

        const apiResult = await this.Post<ClientPropertyListItemDTO>(Server.Api.Property.updatePropertyAddress, request);

        MobX.runInAction(() => {
            if (apiResult.wasSuccessful) {
                domainStores.PropertyStore.updatePropertyAddress(apiResult.payload);

                let domainModel: ClientPropertyListItem | undefined = this.properties.find((dm) => dm.id === apiResult.payload.id);

                if (!domainModel) {
                    domainModel = new ClientPropertyListItem();

                    domainModel.fromDto(apiResult.payload);
                    this.properties.push(domainModel);
                } else {
                    domainModel.fromDto(apiResult.payload);
                    const temp = this.properties.slice();
                    this.properties.clear();
                    this.properties.replace(temp);
                }
            } else {
            }
        });

        return apiResult.wasSuccessful;
    }
}
