import React from "react";
import { Box, Typography, useMediaQuery } from "@material-ui/core";
import styled from "styled-components";
//import whyShoot from "../Content/whyShoothillCreativeBG.png";
//import workBetter from "../Content/workBetterBG.png";

export const Main = styled.section`
    position: relative;
    /* padding: 0 100px 0 100px; */
    margin-bottom: 500px;
    @media screen and (max-width: 1024px) {
        padding: 0 5px 0 5px;
    }
`;

export const Section1 = styled(Box)`
    padding: 300px 100px 200px 0;
    text-align: right;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    @media screen and (max-width: 1024px) {
        padding: 100px 0 0 0;
    }
`;

export const Section2 = styled(Box)`
    padding: 50px 100px 50px 100px;
    background-color: black;
    img {
        width: 140px;
    }
    @media screen and (max-width: 1024px) {
        padding: 50px 0 0 0;
    }
`;

export const Section3 = styled(Box)`
    padding: 50px 100px 50px 100px;
    background-color: black;
    @media screen and (max-width: 1024px) {
        padding: 50px 0 0 0;
    }
`;

/* background-image: url(${workBetter}); */

export const Section4 = styled(Box)`
    padding: 150px 100px 150px 100px;

    @media screen and (max-width: 1024px) {
        padding: 50px 0 0 0;
    }
    .s3Img {
        background-color: white;
        text-align: center;
        padding-top: 75px;
    }
`;

export const Section5 = styled(Box)`
    padding: 50px 100px 50px 100px;
    background-color: black;
    @media screen and (max-width: 1024px) {
        padding: 50px 0 0 0;
    }
    .s4Img {
        text-align: center;
        padding-left: 200px;
    }
`;

export const Section6 = styled(Box)`
    padding: 75px 100px 75px 100px;
    @media screen and (max-width: 1024px) {
        padding: 50px 0 0 0;
    }
`;

export const Section7 = styled(Box)`
    padding: 50px 100px 75px 100px;
    background-color: black;
    @media screen and (max-width: 1024px) {
        padding: 50px 0 0 0;
    }
    .paddingTest {
        padding-top: 35px;
    }
    .image-stack {
        display: inline-block;
    }
`;

export const Section8 = styled(Box)`
    padding: 75px 100px 125px 100px;
    background: transparent linear-gradient(116deg, #445055 0%, #0e1011 100%) 0% 0% no-repeat padding-box;
    @media screen and (max-width: 1024px) {
        padding: 50px 0 0 0;
    }
`;

export const Section9 = styled(Box)`
    padding: 0px 0px 0px 0px;
    /* background: black; */
    color: white;
    @media screen and (max-width: 1024px) {
        padding: 50px 0 0 0;
    }
`;

export const WhiteBoxCommon = styled(Typography)`
    background-color: white;
    color: #333333;
    margin-bottom: 5px;
    padding: 0 5px 0 5px;
`;
export const WhiteBoxWritingHeader = styled(WhiteBoxCommon)`
    font-size: 5em;
    font-weight: 700;
    font-family: "Mont-Bold", "Montserrat", "Roboto Condensed", "Helvetica", "Arial", "sans-serif";
    letter-spacing: -2px;
`;
export const WhiteBoxWritingBody = styled(WhiteBoxCommon)`
    font-size: 4.125em;
    letter-spacing: -1.65px;
`;
export const CaptionText = styled(Typography)`
    //width: 500px;
    font-size: 0.875em;
    color: white;
    letter-spacing: -0.65px;
    padding-top: 30px;
    text-align: left;
`;
export const headerStyle = styled(Typography)`
    padding-top: 10px;
    color: white;
    font: normal normal bold 21px/22px Open Sans;
    letter-spacing: -0.21px;
`;
export const paraStyle = styled(Typography)`
    padding-top: 10px;
    color: white;
    font: normal normal normal 14px/22px Open Sans;
    letter-spacing: -0.14px;
`;
export const WhiteBoxWritingHeader1 = styled(Typography)`
    color: #333333;
    display: inline-flex;
    flex-direction: column;
    background-color: white;
    margin-bottom: 5px;
    padding: 0 5px 0 5px;
    font-size: 5em;
    font-family: "Mont-Bold", "Montserrat", "Roboto Condensed", "Helvetica", "Arial", "sans-serif";
    letter-spacing: -2px;
`;
export const WhiteBoxWritingBody1 = styled(Typography)`
    color: #333333;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    margin-bottom: 5px;
    padding: 0 20px 0 5px;
    font-size: 4.125em;
    letter-spacing: -1.65px;
`;
export const WhiteBoxWritingBody2 = styled(Typography)`
    color: #333333;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    margin-bottom: 5px;
    padding: 0 0 0 5px;
    font-size: 4.125em;
    letter-spacing: -1.65px;
`;
