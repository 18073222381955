import { FormControl, MenuItem, Select } from "@material-ui/core";
import React, { useState } from "react";
import { CSSGrid } from "Core/Components/Styles";

const teams = [
    {
        value: "1",
        label: "Blackpool",
    },
    {
        value: "2",
        label: "Man City",
    },
    {
        value: "3",
        label: "Shrewsbury",
    },
    {
        value: "4",
        label: "Wolves",
    },
];

export const SelectInput: React.FC = () => {
    const [selection, setSelection] = useState("all");

    function handleChange(event: any) {
        setSelection(event.target.value);
    }

    return (
        <CSSGrid>
            <FormControl margin="normal">
                <Select
                    name="Select"
                    value={selection}
                    onChange={handleChange}
                    MenuProps={{
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                        },
                        transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                        },
                        getContentAnchorEl: null,
                    }}
                >
                    <MenuItem key={"all"} value={"all"}>
                        {"Show the final league positions"}
                    </MenuItem>
                    {teams.map((team, index) => {
                        return (
                            <MenuItem key={team.label} value={team.value}>
                                {team.label}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
        </CSSGrid>
    );
};
