import React, { useState } from "react";
import { useObserver } from "mobx-react-lite";
import { LineGraph } from "Custom/Components/Graphs/LineGraph";
import { SensorValueDTO } from "Custom/Models/Domain/Installations/SensorValue";
import { InstallationStatusDataDTO } from "Custom/Models/Domain";
import { DeviceAlertActionModelDTO, DeviceConditionSetModelDTO } from "./TabConfigurationModel";
import { AlertSwitchOption } from "./DeviceSection.style";
import { GraphHeader, GraphWrapper } from "./InstallationView.style";

interface InstallationDataGraphProps {
    graphHeight: number;
    graphWidth: number;
    readings: SensorValueDTO[];
    showDataPoints: boolean;
    deviceStatusData: InstallationStatusDataDTO | undefined;
    conditionSet: DeviceConditionSetModelDTO | undefined;
    alertAction?: DeviceAlertActionModelDTO;
}

export function InstallationDataGraph(props: InstallationDataGraphProps) {
    const { alertAction, graphHeight, graphWidth, readings, showDataPoints, deviceStatusData, conditionSet } = props;

    const [showLines, setShowLines] = useState<boolean>(false);
    const [showAlertLines, setShowAlertLines] = useState<boolean>(false);

    const hasData: boolean = readings.length > 0;
    const btnClass: string = showLines == false ? "notselected" : "selected";
    const btnAlertClass: string = showAlertLines == false ? "notselected" : "selected";
    return useObserver(() => (
        <>
            {hasData && (
                <GraphWrapper>
                    <GraphHeader>
                        <div className="slider-text">Show attribute lines:</div>
                        <AlertSwitchOption id={"showlines-select-"} className={btnClass} onClick={() => setShowLines(!showLines)} />

                        <div className="slider-text lhs15">Show alert lines:</div>
                        <AlertSwitchOption id={"showlines-select-"} className={btnAlertClass} onClick={() => setShowAlertLines(!showAlertLines)} />
                    </GraphHeader>
                    <LineGraph
                        graphHeight={graphHeight}
                        marginTop={0}
                        maxWidth={"100%"}
                        paddingTop={20}
                        pinToZero={true}
                        readings={readings}
                        showDataPoints={showDataPoints}
                        showTooltips={true}
                        graphWidth={graphWidth}
                        showStatusLines={showLines}
                        deviceStatusData={deviceStatusData}
                        conditionSet={conditionSet}
                        showAlertLines={showAlertLines}
                        alertAction={alertAction}
                    />
                </GraphWrapper>
            )}
            {!hasData && <h2>There is no visible data</h2>}
        </>
    ));
}
