import { Box, Fade, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import { AddressModel } from "Custom/Models/Domain/Address/AddressModel";
// @ts-ignore
import { OpenStreetMapProvider } from "leaflet-geosearch";
import { Main, AddressLineBox, AddressResultBox } from "./Address.style";
import { SearchResult } from "leaflet-geosearch/dist/providers/provider";
import { RawResult } from "leaflet-geosearch/dist/providers/openStreetMapProvider";
import { RoofcareInput, RoofcareIndividualInput, RoofcareLabel } from "Custom/StylesAppSpecific/Controls/RoofcareInput";
import { ClientViewModel } from "Custom/Views/Clients/ClientViewModel";
import { autorun } from "Core/Base";
import { selectOSMAddress } from "Custom/Utils/OSM";
import { generateID } from "Core/Utils/Utils";

type ClientAddressProps = {
    viewModel: ClientViewModel;
    title: string;
    setAddress: (address: AddressModel) => void;
    errorMessage: string;
};

export const ClientAddress: React.FunctionComponent<ClientAddressProps> = (props: ClientAddressProps) => {
    const [errorMessage, setErrorMessage] = useState("");
    const [inputValue, updateText] = useState("");
    const [results, updateResults] = useState<SearchResult<RawResult>[]>([]);
    const [isManual, setIsManual] = useState(true);
    const [currentPostcode, setCurrentPostcode] = useState("");
    const provider = new OpenStreetMapProvider({
        params: {
            countrycodes: "gb", // uk
            addressdetails: 1, // include address parts
        },
    });

    //useEffect below only gets run on initial render
    useEffect(() => {
        // *optional* passing the router into the viewmodel so we can navigate from there
        //This gets run when the page is exited
        setCurrentPostcode(props.viewModel.getPostcode);
        if (inputValue.length == 0) {
            updateText(props.viewModel.getAddressString);
        }
        return () => {
            // Clean up after yourself
        };
    }, []);

    /*     useEffect(() => {
        //setViewModel(props.viewModel);
        autorun(() => {
            if (inputValue.length == 0) {
                updateText(props.viewModel.getAddressString);
            }
        });
    }, [props.viewModel]); */

    useEffect(() => {
        autorun(() => {
            setErrorMessage(props.errorMessage);
        });
    }, [props.errorMessage]);

    const onInputChange = async (e: any): Promise<void> => {
        updateText(e.target.value);

        if (e.target.value.length > 5) {
            // Cors issue
            const resultControls: SearchResult<RawResult>[] = await provider.search({ query: e.target.value });
            if (resultControls !== undefined) {
                updateResults(resultControls);
            }
        }
    };

    const toggleManualEntry = () => {
        setIsManual(!isManual);
        updateResults([]);
        updateText("");
    };

    const selectAddress = (item: any) => {
        if (item.raw !== undefined || item.raw !== null) {
            if (item.raw.address !== undefined || item.raw.address !== null) {
                let address: AddressModel | undefined = selectOSMAddress(item);

                if (address !== undefined) {
                    updateText(item.label);
                    updateResults([]);
                    //Update address in client.
                    props.setAddress(address);
                    setIsManual(true);
                }
            }
        }
    };

    return (
        <Main>
            <Fade in timeout={1000}>
                <Box width="100%" display="flex" flexDirection="column">
                    <RoofcareLabel className="address-label">{props.title}</RoofcareLabel>
                    {errorMessage !== "" && (
                        <Typography variant="caption" style={{ color: "red" }}>
                            {errorMessage}
                        </Typography>
                    )}
                    {isManual == false && (
                        <>
                            <RoofcareIndividualInput
                                type="text"
                                placeholder="Start typing the address and select from drop down"
                                value={inputValue}
                                onChange={onInputChange}
                                style={{ maxWidth: "640px", width: "100%", marginTop: 0 }}
                            />
                            {results.length > 0 && (
                                <AddressResultBox>
                                    items found: {results.length}
                                    {results.map((item: any, index: number) => {
                                        return (
                                            <AddressLineBox
                                                key={generateID()}
                                                onClick={() => {
                                                    selectAddress(item);
                                                }}
                                            >
                                                {item.label}
                                            </AddressLineBox>
                                        );
                                    })}
                                </AddressResultBox>
                            )}
                        </>
                    )}
                    {/*                     <Box marginTop="18px" onClick={toggleManualEntry} alignSelf="flex-start">
                        {isManual == false && <RoofcareToggleTypography>+ Add address manually</RoofcareToggleTypography>}
                        {isManual == true && <RoofcareToggleTypography>+ Add address autocomplete</RoofcareToggleTypography>}
                    </Box> */}
                    {(isManual || props.viewModel.errorMessage !== "") && (
                        <>
                            <Box className="row">
                                <Box className="cell-left" maxWidth="640px">
                                    <RoofcareInput<AddressModel>
                                        type="text"
                                        label="Address Line 1"
                                        validateOnBlur={true}
                                        viewModel={props.viewModel}
                                        fieldName="client.addressLine1"
                                        shrink={true}
                                        maxLength={128}
                                    />
                                </Box>
                                <Box className="cell-right" maxWidth="640px">
                                    <RoofcareInput<AddressModel>
                                        type="text"
                                        label="Address Line 2"
                                        validateOnBlur={true}
                                        viewModel={props.viewModel}
                                        fieldName="client.addressLine2"
                                        shrink={true}
                                        maxLength={128}
                                    />
                                </Box>
                            </Box>

                            <Box className="row">
                                <Box className="cell-left" maxWidth="225px">
                                    <RoofcareInput<AddressModel>
                                        type="text"
                                        label="City"
                                        validateOnBlur={true}
                                        viewModel={props.viewModel}
                                        fieldName="client.city"
                                        shrink={true}
                                        maxLength={128}
                                    />
                                </Box>
                                <Box className="cell-right" maxWidth="220px">
                                    <RoofcareInput<AddressModel>
                                        type="text"
                                        label="County"
                                        validateOnBlur={true}
                                        viewModel={props.viewModel}
                                        fieldName="client.county"
                                        shrink={true}
                                        maxLength={128}
                                    />
                                </Box>
                                <Box className="cell-right" maxWidth="200px">
                                    <RoofcareInput<AddressModel>
                                        type="text"
                                        label="Postcode"
                                        validateOnBlur={true}
                                        viewModel={props.viewModel}
                                        fieldName="client.postcode"
                                        shrink={true}
                                        maxLength={128}
                                    />
                                </Box>
                            </Box>
                        </>
                    )}
                </Box>
            </Fade>
        </Main>
    );
};
