import React, { useEffect, useRef } from "react";
import { generateID } from "Core/Utils/Utils";
import { DeviceAttributeCell, DeviceAttributeRow } from "./TabAttributes.style";
import { DrainageType, InstallationStatusDataDTO, RoofType, SideType, SideTypeHelpers } from "Custom/Models/Domain";
import { isNullOrUndefined } from "Custom/Utils/utils";

interface IAttributesProps {
    deviceStatusData: InstallationStatusDataDTO | undefined;
}

export function AttributeSummary(props: IAttributesProps) {
    const { deviceStatusData } = props;

    let baseWidth: string = "Unknown";

    let installHeight: string = "Unknown";
    let side1_length: string = "Unknown";
    let side1_Height: string = "Unknown";
    let side1_Angle: string = "Unknown";
    let side1_AngleRadians: string = "";
    let side2_length: string = "Unknown";
    let side2_Height: string = "Unknown";
    let side2_Angle: string = "Unknown";
    let side2_AngleRadians: string = "";

    let side1_length2: string = "Unknown";
    let side1_Angle2: string = "Unknown";
    let side1_AngleRadians2: string = "";
    let side2_length2: string = "Unknown";
    let side2_Angle2: string = "Unknown";
    let side2_AngleRadians2: string = "";

    let sideTypeText: string = "";
    let sideType: SideType = SideType.ThreeSideSym;

    let baseMeasurement: string = "Unknown";

    let maxWorkingHeight: string = "";
    let freeboardHeight: string = "";

    let roofType: RoofType = RoofType.Pitched;
    let drainageType: DrainageType = DrainageType.Gravity;

    let maxHeight: string = "";
    let p1: string = "";
    let p2: string = "";

    let roofTypeString: string = "Pitched";
    let drainageTypeString: string = "Gravity";

    if (isNullOrUndefined(deviceStatusData) == false) {
        roofType = deviceStatusData!.roofType;
        drainageType = deviceStatusData!.drainageType;

        if (roofType === RoofType.Flat) {
            roofTypeString = "Flat";
        }

        if (drainageType === DrainageType.SingleSiphonic) {
            drainageTypeString = "Single Siphonic";
        }

        if (drainageType === DrainageType.DualSiphonic) {
            drainageTypeString = "Dual Siphonic";
        }

        maxHeight = deviceStatusData!.maxHeight.toFixed(0) + "mm";
        p1 = deviceStatusData!.p1.toFixed(0) + "mm";
        p2 = deviceStatusData!.p2.toFixed(0) + "mm";

        baseWidth = deviceStatusData!.baseWidth.toFixed(0) + "mm";
        installHeight = deviceStatusData!.install_Height.toFixed(0) + "mm";
        side1_length = deviceStatusData!.side1_Length.toFixed(0);
        side1_Height = deviceStatusData!.side1_Height.toFixed(0);
        side1_Angle = deviceStatusData!.side1_Angle.toFixed(0);
        side1_AngleRadians = ((deviceStatusData!.side1_Angle * Math.PI) / 180).toFixed(2) + " radians";

        side1_length2 = deviceStatusData!.side1_Length2.toFixed(0);
        side1_Angle2 = deviceStatusData!.side1_Angle2.toFixed(0);
        side1_AngleRadians2 = ((deviceStatusData!.side1_Angle2 * Math.PI) / 180).toFixed(2) + " radians";

        side2_length = deviceStatusData!.side2_Length.toFixed(0);
        side2_Height = deviceStatusData!.side2_Height.toFixed(0);
        side2_Angle = deviceStatusData!.side2_Angle.toFixed(0);
        side2_AngleRadians = ((deviceStatusData!.side2_Angle * Math.PI) / 180).toFixed(2) + " radians";

        side2_length2 = deviceStatusData!.side2_Length2.toFixed(0);
        side2_Angle2 = deviceStatusData!.side2_Angle2.toFixed(0);
        side2_AngleRadians2 = ((deviceStatusData!.side2_Angle2 * Math.PI) / 180).toFixed(2) + " radians";

        sideTypeText = SideTypeHelpers.getText(deviceStatusData!.sideType);
        sideType = deviceStatusData!.sideType;

        baseMeasurement = deviceStatusData!.baseMeasurement.toFixed(0) + "mm";

        let maxWorkingHeightValue: number = Math.min(deviceStatusData!.side1_Height, deviceStatusData!.side2_Height);

        if (roofType === RoofType.Flat) {
            maxWorkingHeightValue = deviceStatusData!.maxHeight;
        }

        const freeboardHeightValue: number = maxWorkingHeightValue * 0.7;
        maxWorkingHeight = maxWorkingHeightValue.toFixed(0) + "mm";
        freeboardHeight = freeboardHeightValue.toFixed(0) + "mm";
    }

    const componentRef: any = useRef(generateID());

    let floodWarningHeightValue: number = 0;

    if (isNullOrUndefined(deviceStatusData) == false) {
        const maxWorkingHeightValue: number = Math.min(deviceStatusData!.side1_Height, deviceStatusData!.side2_Height);
        floodWarningHeightValue = maxWorkingHeightValue;
    }

    //useEffect below only gets run on initial render
    useEffect(() => {
        //This gets run when the page is exited
        return () => {};
    }, []);

    // TODO WHen we add 4 sided gutters const showSecondLengths: boolean = sideType === SideType.Four

    return (
        <>
            <DeviceAttributeRow key={generateID()}>
                <DeviceAttributeCell key={generateID()}>
                    <div className="main installheight">
                        <div className="title">Roof Type:</div>
                        <div className="data">{roofTypeString}</div>
                    </div>
                </DeviceAttributeCell>
                <DeviceAttributeCell key={generateID()}>
                    <div className="main installheight">
                        <div className="title">Drainage Type:</div>
                        <div className="data">{drainageTypeString}</div>
                    </div>
                </DeviceAttributeCell>
                <DeviceAttributeCell key={generateID()}>
                    <div className="main installheight">
                        <div className="title">&nbsp;</div>
                        <div className="data">&nbsp;</div>
                    </div>
                </DeviceAttributeCell>
            </DeviceAttributeRow>
            <DeviceAttributeRow key={generateID()}>
                <DeviceAttributeCell key={generateID()}>
                    <div className="main installheight">
                        <div className="title">Install Height:</div>
                        <div className="data">{installHeight}</div>
                    </div>
                </DeviceAttributeCell>
                {roofType === RoofType.Pitched && (
                    <DeviceAttributeCell key={generateID()}>
                        <div className="main installheight">
                            <div className="title">Base Measurement:</div>
                            <div className="data">{baseMeasurement}</div>
                        </div>
                    </DeviceAttributeCell>
                )}
                <DeviceAttributeCell key={generateID()}>
                    <div className="main installheight">
                        <div className="title">Capacity:</div>
                        <div className="data">{maxWorkingHeight}</div>
                    </div>
                </DeviceAttributeCell>
                <DeviceAttributeCell key={generateID()}>
                    <div className="main installheight">
                        <div className="title">Freeboard:</div>
                        <div className="data">{freeboardHeight}</div>
                    </div>
                </DeviceAttributeCell>
            </DeviceAttributeRow>
            {roofType === RoofType.Pitched && (
                <DeviceAttributeRow key={generateID()}>
                    <DeviceAttributeCell key={generateID()}>
                        <div className="cell">
                            <div className="header">Side 1</div>
                            <div className="body">
                                <div className="summary">
                                    <div className="title">Length (mm):</div>
                                    <div className="data">{side1_length}</div>
                                </div>
                                <div className="summary">
                                    <div className="title">Angle between side 1 & base (degrees):</div>
                                    <div className="sideRowCell-angles">
                                        <div className="data">
                                            {side1_Angle}&#176;&nbsp;&#47;&nbsp;{side1_AngleRadians}
                                        </div>
                                    </div>
                                </div>
                                (side)
                                <div className="summary">
                                    <div className="title">Height (mm):</div>
                                    <div className="data">{side1_Height}</div>
                                </div>
                            </div>
                        </div>
                    </DeviceAttributeCell>
                    <DeviceAttributeCell key={generateID()}>
                        <div className="cell">
                            <div className="header">Side 2</div>
                            <div className="body">
                                <div className="summary">
                                    <div className="title">Length (mm):</div>
                                    <div className="data">{side2_length}</div>
                                </div>
                                <div className="summary">
                                    <div className="title">Angle between side 2 & base (degrees):</div>
                                    <div className="sideRowCell-angles">
                                        <div className="data">
                                            {side2_Angle}&#176;&nbsp;&#47;&nbsp;{side2_AngleRadians}
                                        </div>
                                    </div>
                                </div>
                                <div className="summary">
                                    <div className="title">Height (mm):</div>
                                    <div className="data">{side2_Height}</div>
                                </div>
                            </div>
                        </div>
                    </DeviceAttributeCell>

                    {drainageType !== DrainageType.Gravity && (
                        <DeviceAttributeCell key={generateID()}>
                            <div className="cell">
                                <div className="header">Siphonic</div>
                                <div className="body">
                                    <div className="summary">
                                        <div className="title">P1 (mm):</div>
                                        <div className="data">{p1}</div>
                                    </div>
                                    {drainageType === DrainageType.DualSiphonic && (
                                        <div className="summary">
                                            <div className="title">P2 (mm):</div>
                                            <div className="data">{p2}</div>
                                        </div>
                                    )}
                                    {drainageType !== DrainageType.DualSiphonic && (
                                        <div className="summary">
                                            <div className="title">&nbsp;</div>
                                            <div className="data">&nbsp;</div>
                                        </div>
                                    )}
                                    <div className="summary">
                                        <div className="title">&nbsp;</div>
                                        <div className="data">&nbsp;</div>
                                    </div>
                                </div>
                            </div>
                        </DeviceAttributeCell>
                    )}
                </DeviceAttributeRow>
            )}
            {roofType === RoofType.Flat && (
                <DeviceAttributeRow key={generateID()}>
                    <DeviceAttributeCell key={generateID()}>
                        <div className="cell">
                            <div className="header">Flat</div>
                            <div className="body">
                                <div className="summary">
                                    <div className="title">Max Height (mm):</div>
                                    <div className="data">{maxHeight}</div>
                                </div>

                                {drainageType !== DrainageType.Gravity && (
                                    <>
                                        <div className="summary">
                                            <div className="title">P1 (mm):</div>
                                            <div className="data">{p1}</div>
                                        </div>
                                        {drainageType === DrainageType.DualSiphonic && (
                                            <div className="summary">
                                                <div className="title">P2 (mm):</div>
                                                <div className="data">{p2}</div>
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    </DeviceAttributeCell>
                </DeviceAttributeRow>
            )}
        </>
    );
}
