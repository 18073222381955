import { Box, Fade, Paper } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import { Loader } from "Core/Components";
import { AdminTabPageViewModel } from "./AdminTabPageViewModel";
import { useObserver } from "mobx-react-lite";
import { useHasRoutedTabs } from "Custom/Utils/useHasRoutedTabs";
import { ITab, Tabs } from "Custom/Components/Tabs/Tabs";
import { AdminConditionTab } from "./AdminConditionTab";
import { AdminAlertActionTab } from "./AdminAlertActionTab";
import { AdminUnitBehaviourTab } from "./AdminUnitBehaviourTab";
import { Container } from "Custom/StylesAppSpecific/AppStyling";
import { AdminDeviceUnitStatusTab } from "./DeviceUnitStatus/AdminDeviceUnitStatusTab";
import { AdminUnitFirmwareTab } from "./UnitFirmware/AdminUnitFirmwareTab";
import { AdminTestGradeTab } from "./UnitTestGrade/AdminTestGradeTab";
import { AdminUnitVersionTab } from "./UnitVersion/AdminUnitVersionTab";
import { AdminProjectStatusTab } from "./ProjectStatus/AdminProjectStatusTab";
import { AdminProjectPaymentStatusTab } from "./ProjectPaymentStatus/AdminProjectPaymentStatusTab";
import { AdminMiscTab } from "./Misc/AdminMiscTab";

const TAB_CONDITION = "condition";
const TAB_ALERT_ACTION = "alertaction";
const TAB_UNIT_BEHAVIOUR = "unitbehaviour";

const TAB_UNIT_TESTGRADE = "unittestgrade";
const TAB_UNIT_FIRMWARE = "unitfirmware";
const TAB_UNIT_VERSION = "unitversion";
const TAB_UNIT_DEVICESTATUS = "unitdevicestatus";

const TAB_PROJECT_STATUS = "projectstatus";
const TAB_PROJECT_PAYMENTSTATUS = "projectpaymentstatus";

const TAB_MISC = "misc";

const tabOptions: ITab[] = [
    { key: TAB_CONDITION, title: "Condition Set", hash: "condition" },
    { key: TAB_ALERT_ACTION, title: "Alert actions", hash: "alerts" },
    { key: TAB_UNIT_BEHAVIOUR, title: "Unit behaviour", hash: "behaviour" },

    { key: TAB_UNIT_TESTGRADE, title: "Unit test grade", hash: "unittestgrade" },
    { key: TAB_UNIT_FIRMWARE, title: "Unit firmware", hash: "unitfirmware" },
    { key: TAB_UNIT_VERSION, title: "Unit version", hash: "unitversion" },
    { key: TAB_UNIT_DEVICESTATUS, title: "Unit Status", hash: "unitdevicestatus" },

    { key: TAB_PROJECT_STATUS, title: "Project Status", hash: "projectstatus" },
    { key: TAB_PROJECT_PAYMENTSTATUS, title: "Payment Status", hash: "projectpaymentstatus" },
    { key: TAB_MISC, title: "Misc", hash: "misc" },
];

export const AdminTabPage: React.FunctionComponent = () => {
    const [viewModel] = useState(() => new AdminTabPageViewModel());

    const hashTab = useHasRoutedTabs(tabOptions);
    const [selectedTab, setSelectedTab] = React.useState(hashTab?.key || TAB_CONDITION);
    const [tabKey, setTabKey] = React.useState(0);

    const handleTabClick = (tab: ITab) => {
        setSelectedTab(tab.key);
        setTabKey(tabKey + 1);
    };

    const getTabKey = (): string => {
        return `clienthome-tab-${selectedTab}-${tabKey}`;
    };

    const renderTabContent = () => {
        switch (selectedTab) {
            case TAB_CONDITION: {
                return <AdminConditionTab key={getTabKey()} parentViewModel={viewModel} />;
            }
            case TAB_ALERT_ACTION: {
                return <AdminAlertActionTab key={getTabKey()} parentViewModel={viewModel} />;
            }
            case TAB_UNIT_BEHAVIOUR: {
                return <AdminUnitBehaviourTab key={getTabKey()} parentViewModel={viewModel} />;
            }

            case TAB_UNIT_TESTGRADE: {
                return <AdminTestGradeTab key={getTabKey()} parentViewModel={viewModel} />;
            }

            case TAB_UNIT_FIRMWARE: {
                return <AdminUnitFirmwareTab key={getTabKey()} parentViewModel={viewModel} />;
            }

            case TAB_UNIT_VERSION: {
                return <AdminUnitVersionTab key={getTabKey()} parentViewModel={viewModel} />;
            }

            case TAB_UNIT_DEVICESTATUS: {
                return <AdminDeviceUnitStatusTab key={getTabKey()} parentViewModel={viewModel} />;
            }

            case TAB_PROJECT_STATUS: {
                return <AdminProjectStatusTab key={getTabKey()} parentViewModel={viewModel} />;
            }

            case TAB_PROJECT_PAYMENTSTATUS: {
                return <AdminProjectPaymentStatusTab key={getTabKey()} parentViewModel={viewModel} />;
            }

            case TAB_MISC: {
                return <AdminMiscTab key={getTabKey()} parentViewModel={viewModel} />;
            }
        }
    };

    //useEffect below only gets run on initial render
    useEffect(() => {
        // *optional* passing the router into the viewmodel so we can navigate from there
        // console.log("First render");
        // This gets run when the page is exited
        return () => {
            // console.log("Unmounting");
        };
    }, []);

    function renderPage() {
        if (viewModel.IsLoading === true) return <Loader />;

        return (
            <Container>
                <Fade in timeout={1000}>
                    <>
                        <Tabs tabs={tabOptions} onTabClick={handleTabClick} selected={selectedTab} minTabWidth={132} tabPadding="11.5px 8px" />

                        <div className="content">{renderTabContent()}</div>
                    </>
                </Fade>
            </Container>
        );
    }

    return useObserver(() => renderPage());
};
