import styled from "styled-components";

export const ProjectDocumentsContainer = styled.div`
    background-color: ${(props) => props.theme.app.colors.tabContent.light};
    color: white;
    padding: 38px 30px 30px;
    display: flex;
    flex: 1 0 0;
    min-width: 100%;

    //border-bottom: 1px dashed #d4d4d4;
    padding: 15px 0;

    .triangle {
        width: 0;
        height: 0;
        border-width: 0 15px 10px 15px;
        border-color: transparent transparent #f3f3f3 transparent;
        border-style: solid;
        margin-left: 10px;
        margin-top: 10px;
    }

    .projectdocument-add {
        display: flex;
        flex: 1 0 0;
        /* background-color: green; */
        justify-content: flex-end;

        &.onitsown {
            margin-top: 1px;
            flex-grow: 1;
            align-content: flex-end;
            min-width: 200px;
        }
    }
`;

export const AddDocumentBox = styled.div`
    width: 100%;
    min-height: 250px;
    display: flex;
    flex-direction: column;
    font-weight: 300;
    font-size: 14px;

    .dropzone {
        display: flex;
        flex: 1 0 0;
        width: 100%;
        flex-direction: column;
        background-color: #f3f3f3;
        vertical-align: middle;
        align-items: center;
        justify-content: center;
        border: 1ps dashed #000000;
        color: #111111;
        min-height: 150px;
    }
`;

export const ItemTitle: any = styled.div`
    display: flex;
    flex-direction: "row";
    flex: 1;
    max-width: 300px;
    padding: 5px 0px;
    font-weight: 700;
    font-family: "Mont-Bold", "Montserrat", "Roboto Condensed", "Helvetica", "Arial", "sans-serif";
`;

export const DocumentListContainer = styled.div`
    margin-top: -15px;
    padding: 0 30px;
    display: flex;
    flex: 1 0 0;
    flex-direction: column;
    min-width: 100%;

    .projectdoc-download {
        img {
            max-width: 32px !important;
            max-height: 32px !important;
        }
    }
`;

export const ProjectDocumentListItem = styled.div`
    background: #f3f3f3 0% 0% no-repeat padding-box;
    opacity: 1;

    padding: 15px;

    display: flex;
    flex-direction: row;
    align-content: space-between;
    flex: 1 0 0;
    width: 100%;

    .filename {
        font-size: 12px;
        font-weight: 500;
        font-family: Mont-Bold, Montserrat, Roboto Condensed, Helvetica, Arial, sans-serif;
    }

    .documentcell {
        display: flex;
        flex: 1 0 0;
    }

    .projectdoc-download {
        img {
            max-width: 32px !important;
            max-height: 32px !important;
        }
    }
`;
