import { Box } from "@material-ui/core";
import styled from "styled-components";

export const DetailContainer = styled(Box)`
    position: relative;
    width: 100%;
    margin-top: 2px;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;
