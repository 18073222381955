import styled from "styled-components";

import DirectionSwitch from "Custom/Content/multiArrows.svg";

export const ConditionDirectionSwitch: any = styled.div`
    width: 92px;
    background-repeat: no-repeat;
    height: 46px;
    position: relative;
    background: url(${DirectionSwitch});
    margin-top: -8px;

    &.up {
        // These make the image disappear no-repeat center center fixed
        // background-color: pink;
        background-position: 276px 48px, 0 0;
        margin-left: 4px;
    }

    &.updown {
        /* background: url(${DirectionSwitch}); */
        // background-color: red;
        background-position: 184px 48px, 0 0;
        margin-left: 0;
    }

    &.down {
        //background: url(${DirectionSwitch}) 276px 0 no-repeat;
        /* background: url(${DirectionSwitch}); */
        background-position: 92px 48px, 0 0;
        // background-color: red;
        margin-left: -4px;
    }
`;
