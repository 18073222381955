import styled from "styled-components";

export const ProjectDetailsDetailsContainer = styled.div`
    background-color: #585858;

    div.info-status-address {
        padding: 16px 30px 30px;

        h2 {
            font-weight: normal;
        }

        div.info-status {
            display: flex;
            justify-content: space-between;
            color: white;

            div.status {
                color: white;
                display: flex;
                align-items: center;

                span {
                    font-family: Arial;
                    font-size: 1.5em;
                    font-weight: bold;
                    padding-right: 10px;
                }
            }
        }
    }
`;
