import styled from "styled-components";

export interface IGraphLegendProps {
    paddingLeft: string;
}

export const GraphLegend: any = styled.div<IGraphLegendProps>`
    padding-left: ${(props) => props.paddingLeft};
    display: flex;
    width: 100%;
    flex: 1 0 0;

    /* background-color: pink; */
    min-height: 25px;

    > div {
        &:nth-of-type(1) {
            padding-left: 0 !important;
        }
    }
`;

export interface IGraphLegendItemProps {
    backgroundColor: string;
    textColor: string;
    opacity?: number;
}

export const GraphLegendItem: any = styled.div<IGraphLegendItemProps>`
    display: flex;
    flex-direction: column;
    padding: 0 15px;
    min-width: 125px;
    text-align: middle;
    justify-content: center;
    align-items: center;

    .band-box {
        height: 15px;
        width: 100%;
        background-color: ${(props) => props.backgroundColor};
        border-radius: 2px;
        opacity: ${(props) => (props.opacity === undefined ? 1 : props.opacity)};
    }
    .band-text {
        padding-top: 3px;
        font-size: 8px;
    }
`;
