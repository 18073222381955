import styled from "styled-components";
import { Box, Theme, colors, Paper } from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import { PaletteOptions, SimplePaletteColorOptions } from "@material-ui/core/styles/createPalette";
import { TypographyOptions } from "@material-ui/core/styles/createTypography";
import { Overrides } from "@material-ui/core/styles/overrides";

//Define your custom breakpoints here or accept the default values which are the standard common breakpoints
let customBreakpointValues = {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920,
};
export const customBreakpoints = createBreakpoints({ values: customBreakpointValues });

declare module "styled-components" {
    export interface DefaultTheme extends Theme {}
}

//Add your specific app styling here
declare module "@material-ui/core" {
    export interface Theme {
        app: {
            colors: {
                success: {
                    dark: React.CSSProperties["color"];
                    light: React.CSSProperties["color"];
                };
                cancel: {
                    button: React.CSSProperties["color"];
                    dark: React.CSSProperties["color"];
                };
                red: {
                    dark: React.CSSProperties["color"];
                    button: React.CSSProperties["color"];
                };
                white: {
                    main: React.CSSProperties["color"];
                    light: React.CSSProperties["color"];
                    dark: React.CSSProperties["color"];
                };
                black: {
                    light: React.CSSProperties["color"];
                    dark: React.CSSProperties["color"];
                };
                grey: {
                    border: React.CSSProperties["color"];
                    background: React.CSSProperties["color"];
                    shadow: React.CSSProperties["color"];
                    back: React.CSSProperties["color"];
                };
                blue: {
                    roofcare: React.CSSProperties["color"];
                    button: React.CSSProperties["color"];
                    dark: React.CSSProperties["color"];
                    light: React.CSSProperties["color"];
                };
                status: {
                    lead: React.CSSProperties["color"];
                    eoi: React.CSSProperties["color"];
                    waittrial: React.CSSProperties["color"];
                    trial: React.CSSProperties["color"];
                    campaign: React.CSSProperties["color"];
                    active: React.CSSProperties["color"];
                    won: React.CSSProperties["color"];
                    lost: React.CSSProperties["color"];
                    eoiad: React.CSSProperties["color"];
                    eoimb: React.CSSProperties["color"];
                    eoipm: React.CSSProperties["color"];
                };
                deviceWarning: {
                    green: {
                        main: React.CSSProperties["color"];
                        accent: React.CSSProperties["color"];
                    };
                    amber: {
                        main: React.CSSProperties["color"];
                        accent: React.CSSProperties["color"];
                    };
                    red: {
                        main: React.CSSProperties["color"];
                        accent: React.CSSProperties["color"];
                    };
                    flood: {
                        main: React.CSSProperties["color"];
                        accent: React.CSSProperties["color"];
                    };
                    unknown: {
                        main: React.CSSProperties["color"];
                        accent: React.CSSProperties["color"];
                    };
                };
                orange: {
                    main: React.CSSProperties["color"];
                    light: React.CSSProperties["color"];
                    dark: React.CSSProperties["color"];
                    darker: React.CSSProperties["color"];
                };
                breadcrumb: {
                    main: React.CSSProperties["color"];
                };
                tabContent: {
                    main: React.CSSProperties["color"];
                    light: React.CSSProperties["color"];
                    dark: React.CSSProperties["color"];
                    accent: React.CSSProperties["color"];
                };
                tabHeader: {
                    main: React.CSSProperties["color"];
                    accent: React.CSSProperties["color"];
                };
                headers: {
                    main: React.CSSProperties["color"];
                };
                section: {
                    main: React.CSSProperties["color"];
                };
            };
        };
    }
}

//Overrides
const customPalette: PaletteOptions = {
    common: { black: "#000", white: "#fff" },
    background: { paper: "#fff", default: "#fafafa" },
    primary: { light: "#7986cb", main: "#337AB7", dark: "#303f9f", contrastText: "#fff" },
    secondary: { main: "#61AA66", contrastText: "#fff" },
    error: { light: "#e57373", main: "#f44336", dark: "#d32f2f", contrastText: "#fff" },
    text: {
        primary: "rgba(0,0,0, 1)",
        secondary: "rgba(255,255,255, 1)",
        disabled: "rgba(0,0,0, 0.38)",
        hint: "rgba(0,0,0, 0.38)",
    },
};

const appTheme: Theme["app"] = {
    colors: {
        success: {
            dark: "#61AA66",
            light: colors.green[300],
        },
        cancel: {
            button: "#B42025",
            dark: "#8E191F",
        },
        red: {
            dark: "#B42025",
            button: "#CE0E2D",
        },
        white: {
            main: "#ffffff",
            light: "#ffffff",
            dark: "#aaaaaa",
        },
        black: {
            light: "#040505",
            dark: "#000",
        },
        grey: {
            border: "#CDCCCC",
            background: "#EDEDED",
            shadow: "#00000029",
            back: "#3B3C3B",
        },
        blue: {
            roofcare: "#00244C",
            button: "#337AB7",
            dark: "#005FAB",
            light: "#418ACA",
        },
        status: {
            lead: "#337AB7",
            eoi: "#EB8D00",
            waittrial: "#CE009E",
            trial: "#DF3A3A",
            campaign: "#0095DA",
            active: "#D58700",
            won: "#61AA66",
            lost: "#000000",
            eoiad: "#F1BE07",
            eoimb: "#F14E07",
            eoipm: "#AF3C1D",
        },
        deviceWarning: {
            green: {
                main: "#61AA66",
                accent: "#68C26E",
            },
            amber: {
                main: "#D58700",
                accent: "#F29D29",
            },
            red: {
                main: "#B42025",
                accent: "#D81A1A",
            },
            flood: {
                main: "#337AB7",
                accent: "#4496DB",
            },
            unknown: {
                main: "#DADADA",
                accent: "#DADADA",
            },
        },
        orange: {
            main: "#F29D29",
            light: "#F29D29",
            dark: "#EE8B1F",
            darker: "#CE730F",
        },
        breadcrumb: {
            main: "#00244C",
        },
        tabContent: {
            main: "white",
            light: "#565758",
            dark: "#373a40",
            accent: "#727273",
        },
        tabHeader: {
            main: "rgba(56, 103, 204, 0.43)",
            accent: "#FFFFFF40",
        },
        headers: {
            main: "#394F7E",
        },
        section: {
            main: "#3A3C41",
        },
    },
};

const customComponentOverrides: Overrides = {
    MuiTableCell: {
        root: {
            fontSize: "12px !important",
            borderBottom: "solid 1px #FFFFFF40",
        },
    },
    MuiButton: {
        root: {
            textTransform: "none",
            fontSize: "1.166666em",
            lineHeight: "1",
        },
    },
    MuiList: {
        root: {
            backgroundColor: "#ffffff !important",
        },
    },
    MuiSelect: {
        root: {
            backgroundColor: "#ffffff !important",
            //backgroundColor: "green",
            color: "#757474 !important",
            fontWeight: "normal",
            fontFamily: ["Mont-Regular", "Montserrat", "Roboto Condensed", "Helvetica", "Arial", "sans-serif"].join(","),
        },
        selectMenu: {
            width: "100%",
        },
        select: {
            //minWidth: "200px",
        },
    },
    MuiInput: {
        root: {
            //width: "100%",
            //maxWidth: "640px",
            //marginTop: "5px",
            backgroundColor: "#ffffff !important",
            border: "1px solid #EDEDED",
            borderRadius: "3px",
            color: "#757474 !important",
            fontWeight: "normal",
            opacity: "1",
            textAlign: "left",
            letterSpacing: "0px",
        },
        input: {
            paddingLeft: "10px",
            paddingRight: "10px",
            "&:-internal-autofill-selected": {
                backgroundColor: "#FFF !important",
            },
            "& .-internal-autofill-selected": {
                backgroundColor: "#FFF !important",
            },
        },
        underline: {
            "&::before": {
                border: "none !important",
            },
            "&::after": {
                border: "none !important",
            },
        },
    },
    MuiTextField: {
        root: {
            width: "100%",
        },
    },
    MuiInputLabel: {
        formControl: {
            textAlign: "left",
            font: 'normal normal bold 14px "Mont-Bold"',
            letterSpacing: "0px",
            color: appTheme.colors.black.light,
            opacity: 1,
            height: "19px",
            marginBottom: "5px",
        },
        shrink: {
            fontSize: "1.58333em !important",
            color: `${customPalette.text?.primary} !important`,
            "& + .MuiInput-formControl": {
                marginTop: "21px",
            },
        },
    },
    MuiCheckbox: {
        root: {
            "& svg": {
                border: "1px solid #CDCCCC",
                borderRadius: "5px",
                "& > path": {
                    display: "none",
                },
            },
        },
        colorSecondary: {
            "&.Mui-checked svg": {
                backgroundColor: (customPalette.secondary as SimplePaletteColorOptions).main,
                "& > path": {
                    display: "initial",
                    fill: "white",
                    height: "20px",
                    width: "20px",
                    transform: "scale(1.4)",
                    transformOrigin: "50% 50%",
                },
            },
        },
        colorPrimary: {
            "&.Mui-checked svg": {
                backgroundColor: (customPalette.primary as SimplePaletteColorOptions).main,
                "& > path": {
                    display: "initial",
                    fill: "white",
                    height: "20px",
                    width: "20px",
                    transform: "scale(1.4)",
                    transformOrigin: "50% 50%",
                },
            },
        },
    },
};

const customTypography: TypographyOptions = {
    fontFamily: ["Mont-Regular", "Montserrat", "Roboto Condensed", "Helvetica", "Arial", "sans-serif"].join(","),
    fontSize: 12,
    h1: {
        fontFamily: ["Mont-Bold", "Montserrat", "Roboto Condensed", "Helvetica", "Arial", "sans-serif"].join(","),
        fontSize: "1.5em",
        [customBreakpoints.down("sm")]: {
            fontSize: "1.3em",
        },
    },
    h2: {
        fontFamily: ["Mont-Bold", "Montserrat", "Roboto Condensed", "Helvetica", "Arial", "sans-serif"].join(","),
        fontSize: "18px",
        padding: "5px 0px",
        textTransform: "capitalize",
        [customBreakpoints.down("sm")]: {
            fontSize: "14px",
        },
    },
    /*  h3: {},
    h4: {},
    h5: {},
     */
    h6: {
        fontFamily: ["Mont-Bold", "Montserrat", "Roboto Condensed", "Helvetica", "Arial", "sans-serif"].join(","),
        fontSize: "1em",
        textAlign: "left",
    },
    caption: {
        fontFamily: ["Mont-Regular", "Montserrat", "Roboto Condensed", "Helvetica", "Arial", "sans-serif"].join(","),
    },
    body1: {
        fontSize: "14px",
        fontFamily: ["Mont-Regular", "Montserrat", "Roboto Condensed", "Helvetica", "Arial", "sans-serif"].join(","),
    },
};

const theme = createTheme({
    breakpoints: customBreakpoints,
    palette: customPalette,
    typography: customTypography,
    overrides: customComponentOverrides,
});

theme.app = appTheme;

export const defaultTheme = theme;

export const Container = styled(Box)`
    .MuiTableCell-root {
        font-size: 0.8rem;
    }
    .MuiTableRow-root {
        cursor: pointer;
    }
    .MuiTableCell-head {
        padding: 14px 20px;
        font-weight: bold;
        border-top: 1px solid #cdcccc;
        border-bottom: 1px solid #cdcccc;
    }
    .MuiTableCell-body {
        padding: 8px 20px;
    }
    .MuiToolbar-root {
        padding-left: 0;

        .MuiTextField-root {
            width: auto;
            flex-grow: 1;
        }

        .MuiTypography-h6 {
            font-size: 1.5em;
        }
    }
    .MuiTableCell-footer {
        cursor: initial;
    }
    .MuiTablePagination-toolbar {
        background-color: white;
    }

    .MuiTable-root {
        .MuiButtonBase-root {
            &.MuiTableSortLabel-active {
                color: #ffffff !important;
            }
        }
        .MuiTypography-root {
            &.MuiTypography-caption {
                color: #757474 !important;
            }
        }
    }
`;

// - only used on devices, needs removing TODO CMR
export const RoofcarePaper: any = styled(Paper)`
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 135px;
    color: black;
    bottom: 0;
    width: 100%;
    height: 100%;
    margin: auto;
    text-align: center;
    vertical-align: center;
    background-color: white;
`;
