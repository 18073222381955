import * as React from "react";
import * as MobXReact from "mobx-react-lite";
import { SuccessBtn } from "Custom/StylesAppSpecific/Controls/SuccessBtn";
import { TextareaAutosize, Typography } from "@material-ui/core";
import { ProjectDetailsViewModel } from "../../ProjectDetailsViewModel";
import { generateID, isNullOrEmpty } from "Core/Utils/Utils";
import { Stores, StoresContext } from "Custom/Stores";
import * as RouterUtil from "Custom/Utils/routing";
import { ProjectNote, ProjectNoteListItem } from "./ProjectNote.style";
import EditLogo from "Custom/Content/editNoteIcon.svg";
import AddLogo from "Custom/Content/plusIcon.svg";
import { ClickableImgCell } from "Custom/Components/Table/ClickableImgCell";
interface IProps {
    viewModel: ProjectDetailsViewModel;
    setOpenAddModal: () => void;
}

export const AddEditProjectNote: React.FC<IProps> = ({ viewModel, setOpenAddModal }) => {
    const store = React.useContext<Stores>(StoresContext);
    const formNoteRef = React.useRef(viewModel.getDefaultNote);
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [isEditing, setIsEditing] = React.useState(false);
    const [allowedToEdit, setAllowedToEdit] = React.useState(false);

    React.useEffect(() => {
        if (!viewModel.IsLoading) {
            setIsLoaded(true);
        }
    }, [viewModel.IsLoading]);

    React.useEffect(() => {
        const allowEdit: boolean = RouterUtil.requireAdminLogin(store) === true;
        setAllowedToEdit(allowEdit);
        return () => {};
    }, []);

    const handleEditClick = (id: string) => {
        setIsEditing(true);
    };

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        viewModel.setDefaultNote(e.target.value);
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        viewModel.upsertDefaultProjectNote();
    };

    return MobXReact.useObserver(() => (
        <>
            <div style={{ paddingBottom: "15px", display: "flex", flexDirection: "row" }}>
                <Typography variant="h1" component="h3">
                    Project background
                </Typography>
                <ClickableImgCell
                    className="projectnote-addedit"
                    logo={EditLogo}
                    text={"Edit background"}
                    onClick={handleEditClick}
                    id={viewModel.getDefaultNote === undefined || viewModel.getDefaultNote.id === undefined ? "0" : viewModel.getDefaultNote.id!}
                />
            </div>
            {isEditing && allowedToEdit && viewModel.getDefaultNote !== undefined ? (
                <form onSubmit={handleSubmit}>
                    <TextareaAutosize value={viewModel.getDefaultNote.note} onChange={handleChange} className="note-textarea" />
                    <SuccessBtn className="add-btn" type="submit">
                        Save
                    </SuccessBtn>
                </form>
            ) : (
                <>
                    <ProjectNoteListItem key={generateID()}>
                        {viewModel.getDefaultNote !== undefined && <ProjectNote className="project-note">{viewModel.getDefaultNote.note}</ProjectNote>}
                    </ProjectNoteListItem>
                </>
            )}
        </>
    ));
};
