import L from "leaflet";
import { SvgPinComponentAsString } from "Custom/Components/Map/Pin";
import { markerIconFlood } from "Custom/Components/Map/icons";

export function getInstallationMarkerIcon(color: string): L.DivIcon {
    let svgPin: string = SvgPinComponentAsString(color);

    let retVal: L.DivIcon = new L.DivIcon({
        html: svgPin,
        className: "transparent",
        iconSize: [48, 56],
        iconAnchor: [24, 47],
        popupAnchor: [0, -53],
    });

    return retVal;
}

export function getInstallationAndPropertyCountMarkerIcon(color: string, textColor: string, count: number): L.DivIcon {
    // TODO Need to add number of properties to SvgPinComponentAsString and make it the text colour
    return getInstallationMarkerIcon(color);
}

export function getDefaultPropertyMarkerIcon() {
    return markerIconFlood;
}
