import * as React from "react";
import * as RouterUtil from "Custom/Utils/routing";
import { PropertyDetailsPage } from "./PropertyDetailsPage";
import { Stores, StoresContext } from "Custom/Stores";
import { PrivateRoute } from "Core/Utils";
import { AppUrls } from "Custom/Globals";
import { InstallationView } from "Custom/Views/Installations/InstallationView";

export const PropertyRoutes: React.FC = () => {
    const store = React.useContext<Stores>(StoresContext);

    return (
        <>
            <PrivateRoute
                component={PropertyDetailsPage}
                exact
                isAllowed={() => {
                    return store.domain.AccountStore.IsLoggedIn && RouterUtil.requireAdminLogin(store);
                }}
                path={AppUrls.Client.Main.Project.Property.PropertyRoot}
            />

            <PrivateRoute
                component={InstallationView}
                exact
                isAllowed={() => {
                    return store.domain.AccountStore.IsLoggedIn && RouterUtil.requireAdminLogin(store);
                }}
                path={AppUrls.Client.Main.Project.Property.PropertyInstallation}
            />
        </>
    );
};
