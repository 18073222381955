import { ProjectDocumentModelDTO } from "./../../../Models/Domain/Projects/ProjectDocument";
import { DocumentCategory } from "Custom/Models/Domain/Projects";
import { ProjectCommunicationSortEnum } from "./../../../Models/Domain/Projects/ProjectCommunicationSort";
import { ProjectCommunicationModel, ProjectCommunicationModelDTO } from "Custom/Models/Domain/Projects/ProjectCommunication";
import { computed, action, observable, runInAction } from "mobx";
import { ApiResult } from "Core/Models";
import { FieldType } from "Core/Utils/Utils";
import { ViewModelBase } from "Core/ViewModels/ViewModelBase";
import { GenericIdRequest, IDocumentModelDTO, SortDirection } from "Custom/Models";
import { Project } from "Custom/Models/Domain/Projects/Project";
import { AppUrls } from "Custom/Globals";
import { ProjectAndRelatedForViewModelDTO } from "../ProjectAndRelatedModel";
import { StoresInstance } from "Custom/Stores";
import { formatAddress } from "Custom/Utils/format";
import { ClientModel } from "Custom/Models/Domain/Clients/ClientModel";
import { AddressModel, ContactModel, ProjectDocumentModel, Property, PropertyDocumentModelDTO, PropertyDTO } from "Custom/Models/Domain";
import { UpdateProjectPaymentStatusDTO, UpdateProjectStatusDTO } from "./UpdateProjectStatusDTO";
import { Server } from "Custom/Globals/AppUrls";
import * as UtilArray from "Custom/Utils/array";
import { FileDownloadResponseDTO } from "Custom/Views/Installations/FileDownloadResponseDTO";
import { ProjectDocumentInfoRequest } from "./Documents/ProjectDocumentInfoRequest";
import { ProjectStatusDropDownListItem } from "Custom/Models/API/ProjectStatusDropDownListItem";
import { ProjectPaymentStatusDropdownListItem } from "Custom/Models/API/ProjectPaymentStatusDropdownListItem";
const domainStores = StoresInstance.domain;

export interface IDocumentViewModel {
    IsLoading: boolean;
    getDocuments: IDocumentModelDTO[];
    getDocumentCategories: DocumentCategory[];

    deleteDocument(id: string): Promise<ApiResult<IDocumentModelDTO>>;
    upsertDocument(acceptedFiles: any, documentCategoryId: number): Promise<ApiResult<IDocumentModelDTO>>;
    downloadDocument(id: string): void;
}

//extend viewmodel base and passing your model as the generic type
export class ProjectDetailsViewModel extends ViewModelBase<Project> implements IDocumentViewModel {
    private static _instance: ProjectDetailsViewModel;

    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    @observable public sortBy: ProjectCommunicationSortEnum = ProjectCommunicationSortEnum.DateAsc;

    @observable public errorMessage: string = "";
    @observable public validMessage: string = "";

    @observable public client?: ClientModel;

    @observable public leadContact?: ContactModel;

    public properties = observable<Property>([]);

    public communications = observable<ProjectCommunicationModel>([]);

    public documents = observable<ProjectDocumentModel>([]);

    public documentCategories = observable<DocumentCategory>([]);

    public projectStatuses = observable<ProjectStatusDropDownListItem>([]);

    public projectPaymentStatuses = observable<ProjectPaymentStatusDropdownListItem>([]);

    constructor() {
        //Pass in a new instance of your model
        //By passing in true as the second parameter, we make this model undoable which means we can use save and reset options on the model
        //If you make a change to the model you need to persist it with a saveModel() call
        //If you make changes to your model you can revert it back by calling resetModel()
        super(new Project(), false);
        //EN - Haven't figured out how to make this call work from the base model yet
        //This is only needed if you make use of the validation decorators
        this.setDecorators(Project);
    }

    @computed
    public get sortCommunicationOrder(): ProjectCommunicationSortEnum {
        return this.sortBy;
    }

    @action
    public setSortCommunicationOrder(value: ProjectCommunicationSortEnum): void {
        this.sortBy = value;
    }

    @computed
    public get getLeadContact(): ContactModel | undefined {
        return this.leadContact;
    }

    @computed
    public get getProperties(): Property[] {
        return this.properties.slice(0);
    }

    @computed
    public get getDocuments(): ProjectDocumentModel[] {
        return this.documents.slice(0).filter((a) => a.isDeleted === false);
    }

    @computed
    public get getDocumentCategories(): DocumentCategory[] {
        return this.documentCategories.slice(0).filter((a) => a.isDeleted === false);
    }

    @action
    public downloadDocument = async (id: string) => {
        let doc: ProjectDocumentModel | undefined = this.documents.find((a) => a.id === id);
        if (doc !== undefined) {
            let fileUri = doc!.url;
            let link = document.createElement("a");
            link.href = fileUri;
            link.setAttribute("download", doc.filename);
            document.body.appendChild(link);
            link.click();
        }
    };

    @computed
    public get getCommunicationList(): ProjectCommunicationModel[] {
        let retVal: Array<ProjectCommunicationModel> = this.communications.slice(0).filter((a) => a.isDefaultNote === false && a.isDeleted === false);

        // Since updated is updated on first created, sort by updated date.
        //
        let field: string = "updatedDate";

        // Original let field: string = "createdDate";
        let direction: SortDirection = SortDirection.Asc;

        switch (this.sortBy) {
            case ProjectCommunicationSortEnum.DateDesc: {
                direction = SortDirection.Desc;
                break;
            }
            case ProjectCommunicationSortEnum.FollowUpAsc: {
                field = "followUpDate";
                direction = SortDirection.Asc;
                break;
            }
            case ProjectCommunicationSortEnum.FollowUpDesc: {
                field = "followUpDate";
                direction = SortDirection.Desc;
                break;
            }
            case ProjectCommunicationSortEnum.ResolvedAsc: {
                field = "resolvedDate";
                direction = SortDirection.Asc;
                break;
            }
            case ProjectCommunicationSortEnum.ResolvedDesc: {
                field = "resolvedDate";
                direction = SortDirection.Desc;
                break;
            }
            case ProjectCommunicationSortEnum.DateAsc:
            default: {
                break;
            }
        }
        const sortedArray: ProjectCommunicationModel[] = UtilArray.sortBy(retVal, field, direction);
        return sortedArray;
    }

    @computed
    get getDefaultNote(): ProjectCommunicationModel {
        const defaultNotes: ProjectCommunicationModel[] = this.communications.slice(0).filter((a) => a.isDefaultNote === true && a.isDeleted === false);
        let retVal: ProjectCommunicationModel = defaultNotes[0];
        return retVal;
    }

    @computed
    public get getPropertyCount(): number {
        return this.properties.slice(0).length;
    }

    @computed
    public get getDocumentCount(): number {
        return this.documents.slice(0).filter((a) => a.isDeleted === false).length;
    }

    @action
    public async loadProjectDetailsAsync(id: string): Promise<ApiResult<ProjectAndRelatedForViewModelDTO>> {
        const request: GenericIdRequest = {
            id: id,
        };
        const apiResult = await this.Post<ProjectAndRelatedForViewModelDTO>(AppUrls.Server.Api.Project.getProjectAndRelatedForView, request);

        if (apiResult.wasSuccessful) {
            runInAction(() => {
                this.model.fromDto(apiResult.payload.project);

                const newClient = new ClientModel();
                newClient.fromDto(apiResult.payload.related.client);
                this.client = newClient;

                const newLeadContact = new ContactModel();
                newLeadContact.fromDto(apiResult.payload.related.leadContact);
                this.leadContact = newLeadContact;

                this.properties.replace(
                    apiResult.payload.related.properties.map((p) => {
                        const newProperty = new Property();
                        newProperty.fromDto(p);
                        return newProperty;
                    }),
                );

                this.communications.replace(
                    apiResult.payload.related.communications.map((p) => {
                        const item = new ProjectCommunicationModel();
                        item.fromDto(p);
                        return item;
                    }),
                );

                this.documentCategories.replace(
                    apiResult.payload.related.documentCategories.map((p) => {
                        return p;
                    }),
                );

                // Sort by Ordinal
                apiResult.payload.related.projectStatuses.sort((a: ProjectStatusDropDownListItem, b: ProjectStatusDropDownListItem) => {
                    if (a === undefined && b === undefined) {
                        return 0;
                    }

                    if (a === undefined) {
                        return -1;
                    }

                    if (b === undefined) {
                        return 1;
                    }

                    return a.ordinal < b.ordinal ? -1 : 1;
                });

                this.projectStatuses.replace(
                    apiResult.payload.related.projectStatuses.map((ps) => {
                        return ps;
                    }),
                );

                apiResult.payload.related.projectPaymentStatuses.sort((a: ProjectPaymentStatusDropdownListItem, b: ProjectPaymentStatusDropdownListItem) => {
                    if (a === undefined && b === undefined) {
                        return 0;
                    }

                    if (a === undefined) {
                        return -1;
                    }

                    if (b === undefined) {
                        return 1;
                    }

                    return a.ordinal < b.ordinal ? -1 : 1;
                });

                this.projectPaymentStatuses.replace(
                    apiResult.payload.related.projectPaymentStatuses.map((ps) => {
                        return ps;
                    }),
                );

                if (apiResult.payload.related.communications.length === 0) {
                    let newCommunication: ProjectCommunicationModel = new ProjectCommunicationModel();

                    newCommunication.isDeleted = false;
                    newCommunication.lastUpdatedBy = apiResult.payload.project.createdBy;
                    newCommunication.createdBy = apiResult.payload.project.createdBy;
                    newCommunication.createdDate = apiResult.payload.project.createdDate;
                    newCommunication.creator = "";
                    newCommunication.followUpDate = "";
                    newCommunication.id = undefined;
                    newCommunication.isDefaultNote = true;
                    newCommunication.note = "";
                    newCommunication.projectId = apiResult.payload.project.id;
                    newCommunication.rowVersion = "";
                    newCommunication.updatedDate = apiResult.payload.project.createdDate;
                    newCommunication.updater = "";

                    this.communications.push(newCommunication);
                }

                this.documents.replace(
                    apiResult.payload.related.documents.map((p) => {
                        const item = new ProjectDocumentModel();
                        item.fromDto(p);
                        return item;
                    }),
                );
            });
        }

        return apiResult;
    }

    @action public async deleteDocument(id: string): Promise<ApiResult<ProjectDocumentModelDTO>> {
        const request = {
            id: id,
            state: true,
        };
        const apiResult = await this.Post<ProjectDocumentModelDTO>(AppUrls.Server.Api.ProjectDocument.deleteProjectDocument, request);

        if (apiResult.wasSuccessful) {
            runInAction(() => {
                const item = new ProjectDocumentModel();
                item.fromDto(apiResult.payload);

                let existing: ProjectDocumentModel[] = this.documents.filter((a) => a.id !== item.id);

                if (existing !== undefined) {
                    existing.push(item);
                    this.documents.replace(existing);
                }
            });
        }
        return apiResult;
    }

    @action public async upsertDocument(acceptedFiles: File[], documentCategoryTypeId: number): Promise<ApiResult<ProjectDocumentModelDTO>> {
        const request: ProjectDocumentInfoRequest = {
            projectId: this.model.id!,
            projectNumber: this.model.number,
            documentCategoryTypeId: documentCategoryTypeId as number,
        };

        let apiResult: ApiResult<ProjectDocumentModelDTO> = {
            wasSuccessful: false,
            errors: [],
            headers: "",
            payload: {
                id: undefined,
                rowVersion: "",
                projectId: "",
                filename: "",
                createdDate: "",
                creator: "",
                createdByName: "",
                isDeleted: false,
                documentCategoryId: 0,
                documentId: "",
                url: "",
            },
        };

        for (let i: number = 0; i < acceptedFiles.length; i++) {
            const file: File = acceptedFiles[i];
            let apiResult = await this.postFormWithFile<ProjectDocumentModelDTO>(AppUrls.Server.Api.ProjectDocument.uploadProjectDocument, file, request);

            if (apiResult.wasSuccessful) {
                runInAction(() => {
                    const item = new ProjectDocumentModel();
                    item.fromDto(apiResult.payload);
                    this.documents.push(item);
                });
            }
        }
        return apiResult;
    }

    @computed
    public get clientName() {
        const client = this.client;
        if (client) return client.clientName;
        return null;
    }

    @computed
    public get formattedClientAddress() {
        const client = this.client;
        if (!client) return null;
        return formatAddress(client, true);
    }

    @computed
    public get propertyListData() {
        return this.properties.map((p) => ({
            id: p.id,
            address: formatAddress(p, true),
            status: p.status,
        }));
    }

    @computed
    public get pageTitle() {
        return `${this.getValue("number")} ${this.getValue("name")} ${this.clientName}`;
    }

    //This must be present in your viewmodel. Just return true if you dont need to validate anything.
    //keyof BlankModel & string lets you add type checking to the fieldName
    //I am using the validator package to make checking easier https://www.npmjs.com/package/validator
    public isFieldValid(fieldName: keyof FieldType<Project>): boolean {
        const { isValid, errorMessage } = this.validateDecorators(fieldName);

        //You need to this two properties after validation
        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    @action
    public async upsertProjectStatus(status: number): Promise<ApiResult<UpdateProjectStatusDTO>> {
        const request: UpdateProjectStatusDTO = {
            id: this.model.id,
            rowVersion: this.model.rowVersion,
            status: status,
        };
        const apiResult = await this.Post<UpdateProjectStatusDTO>(AppUrls.Server.Api.Project.updateProjectStatus, request);

        if (apiResult.wasSuccessful) {
            runInAction(() => {
                if (this.model.id === apiResult.payload.id) {
                    this.setValue("status", apiResult.payload.status);
                    this.setValue("rowVersion", apiResult.payload.rowVersion);
                }
            });
        }

        return apiResult;
    }
    @action
    public async upsertProjectPaymentStatus(status: number): Promise<ApiResult<UpdateProjectPaymentStatusDTO>> {
        const request: UpdateProjectPaymentStatusDTO = {
            id: this.model.id,
            rowVersion: this.model.rowVersion,
            paymentStatus: status,
        };
        const apiResult = await this.Post<UpdateProjectPaymentStatusDTO>(AppUrls.Server.Api.Project.updateProjectPaymentStatus, request);

        if (apiResult.wasSuccessful) {
            runInAction(() => {
                if (this.model.id === apiResult.payload.id) {
                    this.setValue("paymentStatus", apiResult.payload.paymentStatus);
                    this.setValue("rowVersion", apiResult.payload.rowVersion);
                }
            });
        }

        return apiResult;
    }

    @action
    public async upsertProperty(addr: AddressModel): Promise<boolean> {
        const property: Property | undefined = this.properties.find((a) => a.id === addr.id);
        if (property === undefined) {
            throw new Error("Failed to store property");
        }

        const request = property?.toPropertyDtoWithAddress(addr);

        const apiResult = await this.Post<PropertyDTO>(Server.Api.Property.upsertProperty, request);

        runInAction(() => {
            if (apiResult.wasSuccessful) {
                domainStores.PropertyStore.updateProperty(apiResult.payload);

                let domainModel = this.properties.find((dm) => dm.id === apiResult.payload.id);

                if (!domainModel) {
                    domainModel = new Property();

                    domainModel.fromDto(apiResult.payload);
                    this.properties.push(domainModel);
                } else {
                    domainModel.fromDto(apiResult.payload);
                    const temp = this.properties.slice();
                    this.properties.clear();
                    this.properties.replace(temp);
                }
            } else {
            }
        });

        return apiResult.wasSuccessful;
    }

    @action
    public upsertDefaultProjectNote() {
        const defaultNotes: ProjectCommunicationModel[] = this.communications.slice(0).filter((a) => a.isDefaultNote === true && a.isDeleted === false);
        this.upsertProjectCommunication(defaultNotes[0]);
    }

    @action
    public setDefaultNote(value: string) {
        const defaultNotes: ProjectCommunicationModel[] = this.communications.slice(0).filter((a) => a.isDefaultNote === true && a.isDeleted === false);
        let retVal: ProjectCommunicationModel = defaultNotes[0];
        retVal.setValue("note", value);
        return retVal;
    }

    @action
    public async upsertNewCommunicationNote(newCommunication: string, followupDate: string | undefined): Promise<boolean> {
        const newNote: ProjectCommunicationModel = new ProjectCommunicationModel();

        newNote.id = undefined;
        newNote.isDefaultNote = false;
        newNote.rowVersion = "";
        newNote.projectId = this.model.id!;
        newNote.note = newCommunication;
        newNote.createdDate = undefined;
        newNote.creator = "";
        newNote.createdBy = "";
        newNote.isDeleted = false;
        newNote.lastUpdatedBy = "";
        newNote.updater = "";
        newNote.updatedDate = undefined;
        newNote.followUpDate = followupDate;

        return this.upsertProjectCommunication(newNote);
    }

    @action
    public async editCommunicationNote(
        editId: string,
        newCommunication: string,
        followupDate: string | undefined,
        isResolved: boolean,
        resolvedDate: string | undefined,
        resolvedBy: string | undefined,
    ): Promise<boolean> {
        const newNote: ProjectCommunicationModel = new ProjectCommunicationModel();

        const list: ProjectCommunicationModel[] = this.getCommunicationList;

        const item: ProjectCommunicationModel | undefined = list.find((a) => a.id == editId);

        // assume new, if it all goes pear shaped
        newNote.id = undefined;
        newNote.isDefaultNote = false;
        newNote.rowVersion = "";
        newNote.projectId = this.model.id!;
        newNote.note = newCommunication;
        newNote.createdDate = undefined;
        newNote.creator = "";
        newNote.createdBy = "";
        newNote.isDeleted = false;
        newNote.lastUpdatedBy = "";
        newNote.updater = "";
        newNote.updatedDate = undefined;
        newNote.followUpDate = followupDate;

        newNote.isResolved = isResolved;
        newNote.resolvedDate = resolvedDate;
        newNote.resolvedBy = resolvedBy;

        if (item !== undefined) {
            newNote.id = editId;
            newNote.rowVersion = item.rowVersion;
            newNote.createdDate = item.createdDate;
            newNote.creator = item.creator;
            newNote.createdBy = item.createdBy;
        }

        return this.upsertProjectCommunication(newNote);
    }

    @action
    public async upsertProjectCommunication(note: ProjectCommunicationModel): Promise<boolean> {
        if (note === undefined) {
            throw new Error("Failed to store project communication");
        }

        const request = note.toCommunicationDto();

        const apiResult = await this.Post<ProjectCommunicationModelDTO>(Server.Api.Project.upsertPropertyCommunication, request);

        runInAction(() => {
            if (apiResult.wasSuccessful) {
                let domainModel = this.communications.find((dm) => dm.id === apiResult.payload.id);

                if (!domainModel) {
                    domainModel = new ProjectCommunicationModel();

                    domainModel.fromDto(apiResult.payload);
                    this.communications.push(domainModel);
                } else {
                    domainModel.fromDto(apiResult.payload);
                    const temp = this.communications.slice();
                    this.communications.clear();
                    this.communications.replace(temp);
                }
            } else {
            }
        });

        return apiResult.wasSuccessful;
    }

    public downloadCommunicationCSV = async () => {
        const request = {
            id: this.model.id,
            sortOrder: this.sortCommunicationOrder,
        };

        const apiResult: ApiResult<FileDownloadResponseDTO> = await this.Post<FileDownloadResponseDTO>(Server.Api.Project.downloadCommunicationsAsCsv, request);
        let fileName: string = "test.csv";

        const file: string = apiResult.payload.stringFile;
        const type: string = apiResult.payload.contentType;
        fileName = apiResult.payload.fileName;

        const url = window.URL.createObjectURL(new Blob([file], { type }));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
    };
}
