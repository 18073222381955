import styled from "styled-components";
import { Box } from "@material-ui/core";

export const AlertActionPanel: any = styled(Box)`
    background-color: #565758;
    padding: 15px;
    margin: 15px 0px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
`;

export const AlertActionPanelHeading: any = styled(Box)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #b2b2b2;
    font-size: 14px;
    padding: 0px 0px 8px 0px;
    margin: 0px 0px 12px 0px;

    > div {
        display: flex;
        width: 60px;
        justify-content: space-between;
    }
`;

export const AlertActionPanelTable: any = styled(Box)``;

export const AlertActionPanelActions: any = styled(Box)`
    display: flex;
    flex-direction: row;
    margin-top: 10px;

    > div:last-child {
        margin-left: auto;
    }
`;

export const AlertActionPanelTableHeadings: any = styled(Box)`
    display: flex;
    flex-direction: row;
    font-size: 14px;
    margin-bottom: 20px;

    > div {
        &:nth-of-type(1) {
            min-width: 190px;
            width: 190px;
            max-width: 190px;
        }

        &:nth-of-type(2) {
            min-width: 210px;
            width: 210px;
            max-width: 210px;
        }

        &:nth-of-type(3) {
            min-width: 100px;
            width: 100px;
            max-width: 100px;
        }

        &:nth-of-type(4) {
            min-width: 150px;
            width: 150px;
            max-width: 150px;
            text-align: center;
        }

        &:nth-of-type(5),
        &:nth-of-type(6),
        &:nth-of-type(7) {
            min-width: 60px;
            width: 60px;
            max-width: 60px;
            text-align: center;
        }
    }
`;

export const AlertActionPanelTableRows: any = styled(Box)`
    > div {
        display: flex;
        flex-direction: row;
        padding: 12px 0px;
        border-bottom: 1px dashed #b2b2b2;
        font-size: 12px;
        align-items: center;

        > div {
            &:nth-of-type(1) {
                min-width: 190px;
                width: 190px;
                max-width: 190px;
            }

            &:nth-of-type(2) {
                min-width: 210px;
                width: 210px;
                max-width: 210px;
            }

            &:nth-of-type(3) {
                min-width: 100px;
                width: 100px;
                max-width: 100px;
            }

            &:nth-of-type(4) {
                min-width: 150px;
                width: 150px;
                max-width: 150px;
                text-align: center;
            }

            &:nth-of-type(5) {
                min-width: 60px;
                width: 60px;
                max-width: 60px;
                font-size: 18px;
                font-weight: bold;
                text-align: center;
            }

            //The Ticks
            &:nth-of-type(6),
            &:nth-of-type(7),
            &:nth-of-type(8) {
                min-width: 60px;
                width: 60px;
                max-width: 60px;
                font-size: 18px;
                color: #61aa66;
                font-weight: bold;
                text-align: center;
            }
        }
    }
`;
