import { LatestImagesBox } from "Custom/Views/Installations/Overview/GutterSensLatestImage.style";

import { useEffect, useState } from "react";
import { useObserver } from "mobx-react-lite";
import { Box, Typography } from "@material-ui/core";
import { InstallationImage } from "Custom/Models/Domain/Installations/InstallationImage";
import { GutterSensLatestImageViewModel } from "./GutterSensLatestImageViewModel";
import { generateID } from "Custom/Utils/utils";

interface GutterSensLatestImageProps {
    deviceImages: InstallationImage[];
    showImageModal: (id: number) => void;
}

export function GutterSensLatestImage(props: GutterSensLatestImageProps) {
    const [viewModel] = useState(() => new GutterSensLatestImageViewModel());

    let hasImages: boolean = false;

    useEffect(() => {
        const { deviceImages } = props;

        // Reverse sort the images
        let sortedImages: InstallationImage[] = deviceImages.slice(0).sort((a: InstallationImage, b: InstallationImage) => {
            if (a.view === b.view) {
                return 0;
            }

            if (a.view > b.view) {
                return 1;
            }

            return -1;
        });

        viewModel.setImages(sortedImages);
        // tidy up after yourself
        return () => {
            viewModel.resetViewModel();
        };
    }, []);

    useEffect(() => {
        const { deviceImages } = props;

        // Reverse sort the images
        let sortedImages: InstallationImage[] = deviceImages.slice(0).sort((a: InstallationImage, b: InstallationImage) => {
            if (a.view === b.view) {
                return 0;
            }

            if (a.view > b.view) {
                return 1;
            }

            return -1;
        });

        viewModel.setImages(sortedImages);
    }, [props.deviceImages]);

    if (viewModel.getImages !== null && viewModel.getImages !== undefined && viewModel.getImages.length > 0) {
        hasImages = true;
    }

    return useObserver(() => (
        <>
            <LatestImagesBox>
                {!hasImages && <Typography variant="caption">There are currently no images to show.</Typography>}
                {hasImages && (
                    <>
                        <Box className="device-latest-image">
                            <Box
                                className="device-latest-image-cells"
                                onClick={() => {
                                    let selectedImage = viewModel.getSelectedView;
                                    props.showImageModal(selectedImage!.id!);
                                }}
                            >
                                <img className={"main-image " + (viewModel.getSelectedView?.flipped === false ? "" : "rotateimg180")} src={viewModel.getSelectedView?.url} />
                            </Box>
                        </Box>

                        <Box className="device-other-images">
                            {viewModel.getUnSelectedViews.map((view: InstallationImage) => {
                                return (
                                    <div key={generateID()} className="selectable-image " onClick={() => viewModel.setView(view.view)}>
                                        <img className={view.flipped === false ? "" : "rotateimg180"} src={view.url} />
                                    </div>
                                );
                            })}
                        </Box>
                    </>
                )}
            </LatestImagesBox>
        </>
    ));
}
