import { ProjectDropdown } from "./../../Models/Domain/Projects/ProjectDropdown";
import { DrainageType, InstallationStatusDataDTO, RoofType } from "Custom/Models/Domain/Installations";
import { DefaultUnitNote, InsertUnitNoteRequest, UnitNoteDTO } from "./UnitNote";
import { DeviceUnitDataAndRelatedDTO, DeviceUnitDataMinDTO, DeviceUnitDataMinModel } from "./DeviceUnitDataMin";
import validator from "validator";
import { action, observable, computed, runInAction } from "mobx";
import { ViewModelBase } from "Core/ViewModels/ViewModelBase";
import { FieldType, generateID } from "Core/Utils/Utils";
import { ApiResult } from "Core/Models";
import { AppUrls } from "Custom/Globals";
import { GenericIdRequest } from "Custom/Models";

import { TestGradeDTO } from "./TestGrade";
import { UnitVersionDTO } from "./UnitVersion";
import { DeviceUnitStatusDTO } from "./DeviceUnitStatus";
import { Moment } from "moment";
import { MenuItem } from "@material-ui/core";
import { UnitFirmwareDTO } from "./UnitFirmware";
import { InstallationAddViewModel } from "../Projects/Details/Properties/Installations/InstallationAddViewModel";
import { UpsertDeviceUnitRequest } from "./UpsertDeviceUnitRequest";
//extend viewmodel base and passing your model as the generic type
export class UnitDataViewModel extends ViewModelBase<DeviceUnitDataMinModel> {
    @observable public errorMessage: string = "";
    @observable public validMessage: string = "";

    public viewModelStatus: InstallationAddViewModel | undefined = undefined;

    public unitNotes = observable<UnitNoteDTO>([]);

    public testGrades = observable<TestGradeDTO>([]);
    public unitStatus = observable<DeviceUnitStatusDTO>([]);
    public versions = observable<UnitVersionDTO>([]);
    public firmware = observable<UnitFirmwareDTO>([]);

    public projects = observable<ProjectDropdown>([]);

    @action public setNote(value: UnitNoteDTO) {
        this.model.newNote = value;
        this.model.newNote.deviceDbId = this.model.deviceId;
    }

    @computed public get getProjects(): ProjectDropdown[] {
        return this.projects.slice();
    }

    @computed public get getUnitNotes(): UnitNoteDTO[] {
        return this.unitNotes.slice().filter((a) => a.isDeleted === false);
    }

    @computed public get getTestGrades(): TestGradeDTO[] {
        return this.testGrades.slice();
    }

    @computed public get getUnitStatus(): DeviceUnitStatusDTO[] {
        return this.unitStatus.slice();
    }

    @computed public get getVersions(): UnitVersionDTO[] {
        return this.versions.slice();
    }

    @computed public get getFirmware(): UnitFirmwareDTO[] {
        return this.firmware.slice();
    }

    @action public setQRProjectValue(value: string) {
        this.setValue("qrProjectId", value);
    }

    @action public setTestGradeValue(value: number) {
        this.setValue("testGradeId", value);
    }

    @action public setUnitStatusValue(value: number) {
        this.setValue("currentUnitStatusId", value);
    }

    @action public setVersionValue(value: number) {
        this.setValue("versionId", value);
    }

    @action public setFirmwareValue(value: number) {
        this.setValue("firmwareId", value);
    }

    @action public setAcceptanceDate(value: Moment | null | undefined) {
        this.setValue("acceptanceDate", value === null || value === undefined ? null : value.toISOString());
    }
    @action public setDeliveryDate(value: Moment | null | undefined) {
        this.setValue("deliveryDate", value === null || value === undefined ? null : value.toISOString());
    }

    @computed get getIfLoading(): boolean {
        return this.IsLoading;
    }

    constructor(viewModelStatus: InstallationAddViewModel) {
        //Pass in a new instance of your model
        //By passing in true as the second parameter, we make this model undoable which means we can use save and reset options on the model
        //If you make a change to the model you need to persist it with a saveModel() call
        //If you make changes to your model you can revert it back by calling resetModel()
        super(new DeviceUnitDataMinModel(), false);
        //EN - Haven't figured out how to make this call work from the base model yet
        //This is only needed if you make use of the validation decorators
        this.setDecorators(DeviceUnitDataMinModel);
        this.viewModelStatus = viewModelStatus;
    }

    //This must be present in your viewmodel. Just return true if you dont need to validate anything.
    //keyof BlankModel & string lets you add type checking to the fieldName
    //I am using the validator package to make checking easier https://www.npmjs.com/package/validator
    public isFieldValid(fieldName: keyof FieldType<DeviceUnitDataMinModel>): boolean {
        let { isValid, errorMessage } = this.validateDecorators(fieldName);

        //You need to this two properties after validation
        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    @action
    public set(fieldName: any, value: string | number | boolean | Date) {
        this.setValue(fieldName, value as string);
        this.isFieldValid(fieldName);
    }

    /*     @action
    public setRoofcareId(value: string | undefined) {
        this.setValue("roofcareAddressId", value);
        this.isFieldValid("roofcareAddressId");
    } */

    @action
    public async loadModalAsync(id: string): Promise<ApiResult<DeviceUnitDataAndRelatedDTO>> {
        const request: GenericIdRequest = {
            id: id,
        };

        const apiResult: ApiResult<DeviceUnitDataAndRelatedDTO> = await this.Post<DeviceUnitDataAndRelatedDTO>(
            AppUrls.Server.Api.Unit.getDeviceUnitDataAndRelatedForDeviceId,
            request,
        );

        if (apiResult.wasSuccessful) {
            runInAction(() => {
                this.setIsLoading(true);
                this.model.fromInstallAndRelatedDto(apiResult.payload.unitData);

                this.viewModelStatus?.model.setStatusData(apiResult.payload.statusData);

                this.setNotes(apiResult.payload.notes);
                this.setTestGrade(apiResult.payload.testGrade);
                this.setUnitStatuses(apiResult.payload.unitStatus);
                this.setVersions(apiResult.payload.unitVersion);
                this.setFirmwares(apiResult.payload.unitFirmware);
                this.setProjects(apiResult.payload.projects);

                this.setIsLoading(false);
            });
        } else {
            this.setIsErrored(true);
            this.setErrors("Failed to store the installation.  Please try again later");
        }

        return apiResult;
    }

    @action
    public async upsertUnit(): Promise<ApiResult<UpsertDeviceUnitRequest>> {
        const request: UpsertDeviceUnitRequest = {
            unitData: this.model.toRequestDto(),
            statusData: this.viewModelStatus!.toUnitRequestDTO(),
        };

        const apiResult: ApiResult<UpsertDeviceUnitRequest> = await this.Post<UpsertDeviceUnitRequest>(AppUrls.Server.Api.Unit.upsertDeviceUnitData, request);

        if (apiResult.wasSuccessful) {
            runInAction(() => {
                this.model.fromInstallAndRelatedDto(apiResult.payload.unitData);
                this.viewModelStatus!.fromUnitUpsert(apiResult.payload.statusData);
            });
        }

        return apiResult;
    }

    @action
    public async upsertNote(): Promise<ApiResult<UnitNoteDTO[]>> {
        if (this.model.newNote !== undefined && this.model.newNote !== null) {
            if (this.model.newNote.deviceDbId < 1) {
                this.model.newNote.deviceDbId = this.model.deviceId;
            }
        }
        const apiResult: ApiResult<UnitNoteDTO[]> = await this.Post<UnitNoteDTO[]>(AppUrls.Server.Api.Unit.upsertDeviceUnitNote, this.model.newNote);

        if (apiResult.wasSuccessful) {
            runInAction(() => {
                this.setNotes(apiResult.payload);
                this.model.newNote = DefaultUnitNote();
            });
        } else {
            if (apiResult.errors !== null && apiResult.errors !== undefined && apiResult.errors.length > 0) {
                console.log(apiResult.errors[0].message);
            }
        }

        return apiResult;
    }

    @action
    setNotes = (payload: UnitNoteDTO[]) => {
        this.unitNotes.replace(payload);
    };

    @action
    setTestGrade = (payload: TestGradeDTO[]) => {
        this.testGrades.replace(payload);
    };

    @action
    setUnitStatuses = (payload: DeviceUnitStatusDTO[]) => {
        this.unitStatus.replace(payload);
    };

    @action setFirmwares = (payload: UnitFirmwareDTO[]) => {
        this.firmware.replace(payload);
    };

    @action setProjects = (payload: ProjectDropdown[]) => {
        this.projects.replace(payload);
    };

    @action
    setVersions = (payload: UnitVersionDTO[]) => {
        this.versions.replace(payload);
    };

    @action
    clearModel() {
        this.model.clear();
    }

    islocalModelValid = (): boolean => {
        let retVal: boolean = this.isModelValid();
        return retVal;
    };
}
