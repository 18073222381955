import { ModelBase } from "Core/Models";
import { action, observable } from "mobx";

export const DEFAULTCALCULATEDVALUE = -999999;
export class UnitStatus extends ModelBase<UnitStatus, UnitStatusDTO> {
    public id: string = "849B5ED0-25CC-40FA-9AF5-C793DFD1CA86";
    @observable public name: string = "Unknown";
    @observable public value: number = 0;
    @observable public calculatedValue: number = DEFAULTCALCULATEDVALUE;
    @observable public commandIndex: number = 0;
    @observable public statusColour: string = "#FFFFFF";
    @observable public statusTextColour: string = "#000000";
    public deviceConditionSetId: string = "";
    public isBaseCondition: boolean = false;

    @action Reset = () => {
        this.id = "849B5ED0-25CC-40FA-9AF5-C793DFD1CA86";
        this.name = "Unknown";
        this.value = 0;
        this.calculatedValue = DEFAULTCALCULATEDVALUE;
        this.commandIndex = 0;
        this.statusColour = "#FFFFFF";
        this.statusTextColour = "#000000";
        this.deviceConditionSetId = "";
        this.isBaseCondition = false;
    };

    //fromDto is required but you can leave it blank
    @action
    fromDto(model: UnitStatusDTO): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
    }

    //toDto is required but you can leave it blank
    toDto(model: UnitStatus): void {}
}

export interface UnitStatusDTO {
    id: string;
    name: string;
    value: number;
    calculatedValue: number;
    commandIndex: number;
    statusColour: string;
    statusTextColour: string;
    deviceConditionSetId: string;
    isBaseCondition: boolean;
}

/* export const DefaultUnitStatus: UnitStatus = new UnitStatus() = {
    id: "849B5ED0-25CC-40FA-9AF5-C793DFD1CA86",
    name: "Unknown",
    value: 0,
    calculatedValue: DEFAULTCALCULATEDVALUE,
    commandIndex: 0,
    statusColour: "#FFFFFF",
    statusTextColour: "#000000",
}; */
