import { action, observable } from "mobx";
import { ModelBase } from "Core/Models/ModelBase";
import { IsNotEmpty } from "class-validator";
import { InstallationAuditModelDTO } from "../InstallationAuditModel";

//Base class expects the model type and the DTO model type
//If you are not using a DTO just pass in undefined as the DTO
export class AuditSMSModel extends ModelBase<AuditSMSModel, AuditSMSModelDTO> {
    @observable
    public MobileNumbers: string = "";
    public Message: string = "";
    public Version: number = 1;

    //fromDto is required but you can leave it blank
    @action
    public fromDto(model: AuditSMSModelDTO): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
    }

    //toDto is required but you can leave it blank
    toDto(model: AuditSMSModel): void {}

    @action
    public loadData(data: InstallationAuditModelDTO) {
        // TODO Stuff about the version number later
        let model: AuditSMSModelDTO = JSON.parse(data.dataJson);
        this.fromDto(model);
    }
}

export type AuditSMSModelDTO = {
    MobileNumbers: string;
    Message: string;
    Version: number;
};
