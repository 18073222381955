import * as React from "react";
import { BaseModal } from "Custom/Components/Modal/BaseModal";
import { ContactModel, ContactModelDTO } from "./Contact.Model";
import { SuccessBtn } from "Custom/StylesAppSpecific/Controls/SuccessBtn";
import { CancelBtn } from "Custom/StylesAppSpecific/Controls/CancelBtn";
import { ContactModalDialogPaper, AddEditBoxRow } from "./ContactModal.Styles";
import { useState } from "react";
import { ContactViewModel } from "./Contact.ViewModel";
import { ContactAddRow } from "./ContactAddEdit.View";
import { Box } from "@material-ui/core";
import { RoofcareInput } from "Custom/StylesAppSpecific/Controls/RoofcareInput";

interface IContactProps {
    contact?: ContactModel;
    open?: boolean;
    onClose(): void;
    onAdd(contact: ContactModel): void;
}

export const ContactAddModal: React.FC<IContactProps> = ({ contact, onClose, open, onAdd }) => {
    const [viewModel] = useState(() => new ContactViewModel());
    const [errorMessage, setErrorMessage] = useState("");

    const onAddContact = () => {
        if (viewModel.isModelValid() === false) {
            setErrorMessage("Please fix the validation errors and re-submit.");
        } else {
            onAdd(viewModel.model);
        }
    };

    React.useEffect(() => {
        if (contact !== undefined) {
            viewModel.setContact(contact);
        }
    }, [contact]);

    const title: string = contact === undefined ? "Add new contact" : "Edit contact";
    const btnText: string = contact === undefined ? "Add new contact" : "Update contact";

    return (
        <BaseModal
            title={title}
            onClose={onClose}
            open={open}
            PaperComponent={ContactModalDialogPaper}
            actions={
                <>
                    <SuccessBtn onClick={onAddContact} autoFocus /* disabled={viewModel.isModelValid() ? false : true} */>
                        {btnText}
                    </SuccessBtn>
                    <CancelBtn onClick={onClose}>Cancel</CancelBtn>
                </>
            }
        >
            <AddEditBoxRow>
                <Box>
                    <RoofcareInput type="text" label="First Name" validateOnBlur={true} viewModel={viewModel} fieldName="firstName" shrink={true} />
                </Box>
                <Box>
                    <RoofcareInput type="text" label="Last Name" validateOnBlur={true} viewModel={viewModel} fieldName="lastName" shrink={true} />
                </Box>
            </AddEditBoxRow>
            <AddEditBoxRow>
                <Box>
                    <RoofcareInput type="email" label="Email" validateOnBlur={true} viewModel={viewModel} fieldName="email" shrink={true} />
                </Box>
            </AddEditBoxRow>
            <AddEditBoxRow>
                <Box>
                    <RoofcareInput type="tel" label="Phone" validateOnBlur={true} viewModel={viewModel} fieldName="phone" shrink={true} />
                </Box>
                <Box>
                    <RoofcareInput type="tel" label="Secondary Phone" validateOnBlur={true} viewModel={viewModel} fieldName="phone2" shrink={true} />
                </Box>
            </AddEditBoxRow>
            <AddEditBoxRow>
                <Box className="fullwidth">
                    <RoofcareInput type="text" label="Position" validateOnBlur={true} viewModel={viewModel} fieldName="position" shrink={true} />
                </Box>
            </AddEditBoxRow>
        </BaseModal>
    );
};
