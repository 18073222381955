import { UnitBehaviourItemModelDTO } from "Custom/Views/Admin/UnitBehaviour/UnitBehaviourItemModel";
import { DrainageType } from "Custom/Models/Domain";
import { observable } from "mobx";
import { ModelBase } from "Core/Models/ModelBase";
import { IsNotEmpty } from "class-validator";

//Base class expects the model type and the DTO model type
//If you are not using a DTO just pass in undefined as the DTO
export class AdminUnitBehaviourTabModel extends ModelBase<AdminUnitBehaviourTabModel, AdminUnitBehaviourTabModelDTO> {
    @observable
    @IsNotEmpty({ message: "You must give me your name!!" })
    public name: string = "";

    public drainageType: DrainageType = DrainageType.Gravity;

    public deviceCount: number = 0;

    public id: string = "";

    //fromDto is required but you can leave it blank
    fromDto(model: AdminUnitBehaviourTabModelDTO): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
    }

    //toDto is required but you can leave it blank
    toDto(model: AdminUnitBehaviourTabModel): void {}
}

export type AdminUnitBehaviourTabModelDTO = {
    id: string;
    name: string;
    drainageType: DrainageType;
    deviceCount: number;
    items: UnitBehaviourItemModelDTO[];
};
