import React, { Component, useContext, useEffect } from "react";
import { Stores, StoresContext } from "./Custom/Stores";
import * as MobXReact from "mobx-react-lite";

import { PrivateRoute } from "Core/Utils";

import { Samples } from "./Custom/Views/Samples";
import { SessionExpired } from "./Custom/Views/SessionExpired";
import { TestPage } from "./Core/Views/TestPage";

import { Error } from "./Core/Views/Error";
import { NotFound } from "./Core/Views/NotFound";
import { PrivacyPolicy } from "./Custom/Views/PrivacyPolicy";
import { TermsAndConditions } from "./Custom/Views/TermsAndConditions";

/* import { ProjectListPage } from "Custom/Views/Projects/ProjectListPage"; */

import { RouteWithLayout as Route } from "./Core/Components/RouteWithLayout";
import { Client } from "./Custom/Globals/AppUrls";

import { AccountRoutes } from "./Custom/Views/Account/AccountRoutes";
import { StoresInstance } from "Core/Base";
import * as RouterUtil from "Custom/Utils/routing";
import { Home } from "Custom/Views/Home";
import { ErrorModal } from "Custom/Views/Layouts/ErrorModal";
import { MainRoutes } from "Custom/Routes/MainRoutes";
import { ContractorViewRoutes } from "Custom/Views/ContractorView/ContractorViewRoutes";
import { ClientViewRoutes } from "Custom/Views/ClientView/ClientViewRoutes";
import { ContractorLayout } from "Custom/Views/Layouts/ContractorLayout";
import { ClientLayout } from "./Custom/Views/Layouts/ClientLayout";
import { LayoutNoHeader } from "Custom/Views/Layouts/LayoutNoHeader";
import { useLocation } from "react-router";
import { MapRoutes } from "Custom/Views/Map/MapRoutes";
import { AdminRoutes } from "Custom/Views/Admin/AdminRoutes";

import { EstateView } from "Custom/Views/Estate/EstateView";
import { ContractorDashboardView } from "Custom/Views/ContractorView/Dashboard/ContractorDashboardView";
import { ClientDashboardView } from "Custom/Views/ClientView/Dashboard/ClientDashboardView";
import { ActionRoutes } from "Custom/Views/Actions/ActionRoutes";
import { ContactsRoutes } from "Custom/Views/Contacts/ContactsRoutes";

export const AppRoutes: React.FC = () => {
    function getRootComponent(): any {
        if (true == RouterUtil.requireAdminLogin(StoresInstance) || true == RouterUtil.requireRegionalSalesManagerLogin(StoresInstance)) {
            return <EstateView />;
            //return <ProjectListPage />;
        } else if (true == RouterUtil.requireContractorLogin(StoresInstance)) {
            return <ContractorDashboardView />;
        } else if (true == RouterUtil.requireClientLogin(StoresInstance)) {
            return <ClientDashboardView />;
        } else {
            return <NotFound />;
        }
    }

    function getRootLayout() {
        if (true == RouterUtil.requireAdminLogin(StoresInstance)) {
            return undefined;
        } else if (true == RouterUtil.requireContractorLogin(StoresInstance)) {
            return ContractorLayout;
        } else if (true == RouterUtil.requireClientLogin(StoresInstance)) {
            return ClientLayout;
        } else if (true == RouterUtil.requireRegionalSalesManagerLogin(StoresInstance)) {
            return undefined; // TODO
        } else {
            return undefined;
        }
    }

    const path = useLocation().pathname;

    return MobXReact.useObserver(() => (
        <>
            {/*
            I have added a component that will now accept a different layout per route.
            This line
                import { RouteWithLayout as Route } from "./Core/Components/RouteWithLayout";
            renames the usual route to use the new RouteWithLayout component. The defayult layout is called DefaultLayout in the Layout folder
            */}
            <ErrorModal />

            <PrivateRoute
                component={getRootComponent}
                exact
                isAllowed={() => {
                    return StoresInstance.domain.AccountStore.IsLoggedIn && RouterUtil.requireAnyLoggedIn(StoresInstance);
                }}
                path={Client.Root}
                layout={getRootLayout()}
            />
            <ActionRoutes />
            <ClientViewRoutes />
            <ContactsRoutes />
            <AccountRoutes />

            <MainRoutes />
            <ContractorViewRoutes />
            <MapRoutes />
            <AdminRoutes />

            {/* <AppRoleRoutes /> */}

            <Route exact path={Client.SessionExpired} component={SessionExpired} layout={LayoutNoHeader} />
            <Route exact path={Client.Error} component={Error} />
            <Route exact path={Client.NotFound} component={NotFound} />
            <Route exact path="/samples" component={Samples} />

            <Route exact path="/privacy-policy" component={PrivacyPolicy} />
            <Route exact path="/terms" component={TermsAndConditions} />
            <Route exact path="/test" component={TestPage} />
            <Route exact path="/home" component={Home} />
        </>
    ));
};
