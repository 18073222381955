import React, { useEffect } from "react";
import Link from "@material-ui/core/Link";
import { Link as RouterLink, useLocation, useRouteMatch } from "react-router-dom";
import { Client } from "Custom/Globals/AppUrls";
import { useBreadcrumbStyles, RoofcareBreadcrumbs } from "./RoofcareBreadcrumb.style";

import { BreadcrumbsContext, IBreadcrumbContextIdValues } from "./RoofcareBreadcrumbCommon";
import { useRouter } from "Core/Base";
import { RoofcareBreadcrumbViewModel } from "./RoofcareBreadcrumbViewModel";
import { ApiResult } from "Core/Models";
import { BreadcrumbContextValueDTO } from "Custom/Models/Domain/Breadcrumb/BreadcrumbContextValue";

export const RoofcareContractorViewBreadcrumb: React.FC = () => {
    const [viewModel] = React.useState<RoofcareBreadcrumbViewModel>(() => RoofcareBreadcrumbViewModel.Instance);

    const location = useLocation();
    const router = useRouter<{ clientId?: string; contractorId?: string; installId?: string; projectId?: string; propertyId?: string }>();
    const breadcrumbsValues = React.useContext(BreadcrumbsContext);

    const classes = useBreadcrumbStyles();

    const homeMatch = useRouteMatch(Client.Root);

    const contractorUnitMatch = useRouteMatch(Client.ContractorView.Device.DeviceRoot);

    const contractorUnitInstallationMatch = useRouteMatch(Client.ContractorView.Device.Installation);
    const contractorUnitPropertyDetailMatch = useRouteMatch(Client.ContractorView.Device.PropertyDetail);
    const contractorUnitPropertyInstallationMatch = useRouteMatch(Client.ContractorView.Device.PropertyInstallation);

    const contractorViewPropertyDetail = useRouteMatch(Client.ContractorView.Property.PropertyDetail);
    const contractorViewPropertyInstallation = useRouteMatch(Client.ContractorView.Property.PropertyInstallation);

    const contractorViewProjectPropertyRoot = useRouteMatch(Client.ContractorView.Project.ProjectDetail);
    const contractorViewProjectPropertyDetail = useRouteMatch(Client.ContractorView.Project.PropertyDetail);
    const contractorViewProjectPropertyInstallation = useRouteMatch(Client.ContractorView.Project.PropertyInstallation);

    const [matchIdData, setContextData] = React.useState<IBreadcrumbContextIdValues>({
        clientId: undefined,
        contractorId: undefined,
        installationId: undefined,
        projectId: undefined,
        propertyId: undefined,
        userId: undefined,
    });

    useEffect(() => {
        const { clientId, contractorId, installId, projectId, propertyId } = router.match.params;

        if (
            clientId !== matchIdData.clientId ||
            contractorId !== matchIdData.contractorId ||
            installId !== matchIdData.installationId ||
            projectId !== matchIdData.projectId ||
            propertyId !== matchIdData.propertyId
        ) {
            const temp: IBreadcrumbContextIdValues = {
                clientId: clientId,
                contractorId: contractorId,
                installationId: installId === undefined ? undefined : parseInt(installId),
                projectId: projectId,
                propertyId: propertyId,
            };

            setContextData(temp);

            let promise = viewModel.GetBreadcrumbData(temp);

            promise.then((result: ApiResult<BreadcrumbContextValueDTO>) => {
                if (result.wasSuccessful) {
                    const payload = result.payload;

                    breadcrumbsValues.setContext({
                        clientName: payload.clientName,
                        contractorName: payload.contractorName,
                        installationName: payload.installationName,
                        projectName: payload.projectName,
                        propertyName: payload.propertyName,
                        userName: payload.userName,
                    });
                }
            });
        }
    }, [location]);

    return (
        <RoofcareBreadcrumbs className={classes.root} aria-label="breadcrumb">
            <Link component={RouterLink} to={Client.Root}>
                Contractor
            </Link>

            {/*             {(contractorViewPropertyRoot || homeMatch) && (
                <Link component={RouterLink} to={Client.ContractorView.Property.PropertyRoot}>
                    Contractor
                </Link>
            )} */}

            {contractorUnitMatch && (
                <Link component={RouterLink} to={Client.ContractorView.Device.DeviceRoot}>
                    Units
                </Link>
            )}

            {contractorViewPropertyDetail && (
                <Link
                    component={RouterLink}
                    to={Client.ContractorView.Property.PropertyDetail.replace(":projectId", (contractorViewPropertyDetail.params as any).projectId).replace(
                        ":propertyId",
                        (contractorViewPropertyDetail.params as any).propertyId,
                    )}
                >
                    {breadcrumbsValues.propertyName || "Loading..."}
                </Link>
            )}

            {contractorViewPropertyInstallation && (
                <Link
                    component={RouterLink}
                    to={Client.ContractorView.Property.PropertyInstallation.replace(":projectId", (contractorViewPropertyInstallation.params as any).projectId)
                        .replace(":propertyId", (contractorViewPropertyInstallation.params as any).propertyId)
                        .replace(":installId", (contractorViewPropertyInstallation.params as any).installId)}
                >
                    {breadcrumbsValues.installationName || "Loading..."}
                </Link>
            )}

            {contractorViewProjectPropertyRoot && (
                <Link component={RouterLink} to={Client.ContractorView.Project.ProjectDetail.replace(":projectId", (contractorViewProjectPropertyRoot.params as any).projectId)}>
                    {breadcrumbsValues.projectName || "Loading..."}
                </Link>
            )}

            {contractorViewProjectPropertyDetail && (
                <Link
                    component={RouterLink}
                    to={Client.ContractorView.Project.PropertyDetail.replace(":projectId", (contractorViewProjectPropertyDetail.params as any).projectId).replace(
                        ":propertyId",
                        (contractorViewProjectPropertyDetail.params as any).propertyId,
                    )}
                >
                    {breadcrumbsValues.propertyName || "Loading..."}
                </Link>
            )}

            {contractorViewProjectPropertyInstallation && (
                <Link
                    component={RouterLink}
                    to={Client.ContractorView.Project.PropertyInstallation.replace(":projectId", (contractorViewProjectPropertyInstallation.params as any).projectId)
                        .replace(":propertyId", (contractorViewProjectPropertyInstallation.params as any).propertyId)
                        .replace(":installId", (contractorViewProjectPropertyInstallation.params as any).installId)}
                >
                    {breadcrumbsValues.installationName || "Loading..."}
                </Link>
            )}

            {contractorUnitInstallationMatch && (
                <Link component={RouterLink} to={Client.ContractorView.Device.Installation.replace(":installId", (contractorUnitInstallationMatch!.params as any).installId)}>
                    {breadcrumbsValues.installationName || "Loading..."}
                </Link>
            )}
            {contractorUnitPropertyDetailMatch && (
                <Link component={RouterLink} to={Client.ContractorView.Device.PropertyDetail.replace(":propertyId", (contractorUnitPropertyDetailMatch!.params as any).propertyId)}>
                    {breadcrumbsValues.propertyName || "Loading..."}
                </Link>
            )}
            {contractorUnitPropertyInstallationMatch && (
                <Link
                    component={RouterLink}
                    to={Client.ContractorView.Device.PropertyInstallation.replace(":propertyId", (contractorUnitPropertyInstallationMatch!.params as any).propertyId).replace(
                        ":installId",
                        (contractorUnitPropertyInstallationMatch!.params as any).installId,
                    )}
                >
                    {breadcrumbsValues.installationName || "Loading..."}
                </Link>
            )}
        </RoofcareBreadcrumbs>
    );
};
