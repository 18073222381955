import { Box, Fade, Link, Paper, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import { ResetPasswordViewModel } from "./ResetPasswordViewModel";
import { runInAction } from "mobx";
import { useObserver } from "Core/Base";
import { Client } from "Custom/Globals/AppUrls";
import { AccountBtnBox, AccountImageBox, PaperAccount, LoginStatementBox, LoginInstructionBox } from "Custom/StylesAppSpecific/AccountStyling";
import { BoxAccount, LoginForgotPasswordBox, LoginForgotPasswordLink, LogonTitle } from "./LoginPage.style";
import { CancelBtn } from "Custom/StylesAppSpecific/Controls/CancelBtn";
import { AcceptBtn } from "Custom/StylesAppSpecific/Controls/AcceptBtn";
import logoUrl from "Custom/Content/RCGlogo.svg";
import { RoofcareInput } from "Custom/StylesAppSpecific/Controls/RoofcareInput";

export const ResetPasswordPage: React.FunctionComponent = () => {
    const [viewModel] = useState(() => new ResetPasswordViewModel());
    const [errorMessage, setErrorMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    useEffect(() => {
        viewModel.getTokenFromUrl();
    }, []);

    const doSubmit = async (e: any) => {
        e.preventDefault();

        if (viewModel.isModelValid()) {
            runInAction(() => {
                setIsLoading(true);
            });

            const apiResult = await viewModel.resetPasswordAsync();

            runInAction(() => {
                setIsLoading(false);

                if (apiResult) {
                    if (apiResult.wasSuccessful) {
                        setErrorMessage("");
                        setIsSuccess(true);
                    } else {
                        setErrorMessage("* " + apiResult.errors[0].message);
                        setIsSuccess(false);
                    }
                } else {
                    setErrorMessage("* Unknown error has occurred.");
                    setIsSuccess(false);
                }
            });
        }
    };

    const goToForgotPassword = async () => {
        viewModel.history.push(Client.Account.ForgotPassword);
    };

    const cancel = () => {
        viewModel.history.push(Client.Root);
    };

    return useObserver(() => (
        <Fade in timeout={1000}>
            <PaperAccount style={{ top: 0, padding: 0, height: "100vh" }}>
                <BoxAccount>
                    {viewModel.tokenValid === true ? (
                        <>
                            <Box justifyContent="start" textAlign="left" display="grid" minWidth="330px" maxWidth="330px">
                                <LoginStatementBox>
                                    <Box>
                                        <LogonTitle style={{ textAlign: "left" }}>Reset password</LogonTitle>
                                    </Box>
                                </LoginStatementBox>
                                {isSuccess === false && (
                                    <LoginInstructionBox>
                                        <Typography
                                            variant="caption"
                                            style={{ fontSize: "14px", color: "white", fontFamily: "'Arial',sans-serif", textAlign: "left", lineHeight: 1.8 }}
                                        >
                                            Please type a new password.
                                        </Typography>
                                    </LoginInstructionBox>
                                )}
                            </Box>
                            <form onSubmit={doSubmit} autoComplete="new-password">
                                {isSuccess === false && (
                                    <>
                                        {errorMessage !== "" && (
                                            <Typography style={{ color: "red" }} variant="caption">
                                                {errorMessage}
                                            </Typography>
                                        )}
                                        <RoofcareInput
                                            type="password"
                                            label="New Password"
                                            validateOnBlur={true}
                                            viewModel={viewModel}
                                            fieldName="newPassword"
                                            shrink={true}
                                            inputProps={{ placeholder: "Please enter your new password here" }}
                                        />
                                        <RoofcareInput
                                            type="password"
                                            label="Confirm Password"
                                            validateOnBlur={true}
                                            viewModel={viewModel}
                                            fieldName="confirmPassword"
                                            shrink={true}
                                            inputProps={{ placeholder: "Confirm your new password here" }}
                                        />

                                        <AccountBtnBox>
                                            <AcceptBtn type="submit" disabled={isLoading}>
                                                {isLoading ? "Please Wait..." : "Reset password"}
                                            </AcceptBtn>
                                            <CancelBtn disabled={isLoading} onClick={cancel}>
                                                {isLoading ? "Please Wait..." : "Cancel"}
                                            </CancelBtn>
                                        </AccountBtnBox>
                                        {errorMessage !== "" && <Link onClick={goToForgotPassword}>Return to forgot password</Link>}
                                    </>
                                )}

                                {isSuccess && (
                                    <>
                                        <Typography variant="h6" color="secondary" style={{ textAlign: "left" }}>
                                            Successfully Reset Password
                                        </Typography>
                                        <AccountBtnBox>
                                            <AcceptBtn onClick={cancel} disabled={isLoading}>
                                                {isLoading ? "Please Wait..." : "Return to Login"}
                                            </AcceptBtn>
                                        </AccountBtnBox>
                                    </>
                                )}
                            </form>
                        </>
                    ) : viewModel.tokenValid === false ? (
                        <>
                            <Box justifyContent="start" textAlign="left" display="grid" minWidth="300px" maxWidth="300px">
                                <LoginStatementBox>
                                    <Typography variant="caption" style={{ marginBottom: "15px", marginTop: "15px" }}>
                                        Reset your password
                                    </Typography>
                                </LoginStatementBox>
                                <LoginInstructionBox>
                                    <Typography style={{ color: "red" }} variant="caption">
                                        * Url is expired or invalid, please try again.
                                    </Typography>
                                </LoginInstructionBox>
                                <LoginForgotPasswordBox>
                                    <LoginForgotPasswordLink onClick={() => goToForgotPassword()}>Return to Forgot password?</LoginForgotPasswordLink>
                                </LoginForgotPasswordBox>
                            </Box>
                        </>
                    ) : (
                        <>
                            <Box justifyContent="start" textAlign="left" display="grid" minWidth="300px" maxWidth="300px">
                                <Typography component="h1" style={{ marginBottom: "15px", marginTop: "15px" }}>
                                    Reset password
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    Loading...
                                </Typography>
                            </Box>
                        </>
                    )}
                </BoxAccount>
            </PaperAccount>
        </Fade>
    ));
};
