import * as React from "react";
import * as MobX from "mobx";
import * as MobXReact from "mobx-react-lite";
import { TabsContainer } from "./Tabs.styles";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import { BackBox, BackToList, BackToListText } from "Custom/StylesAppSpecific/Controls/BackToList";

export interface ITab {
    title: string | MobX.IComputedValue<string>;
    key: string;
    hash?: string;
}

interface IProps {
    tabs: ITab[];
    onTabClick(tab: ITab): void;
    showBackBtn?: boolean;
    backBtnText?: string;
    onBack?(): void;
    minTabWidth?: number;
    selected: string;
    tabPadding?: string;
}

export const Tabs: React.FC<IProps> = ({ tabs, onTabClick, backBtnText, showBackBtn, onBack, minTabWidth, selected, tabPadding }) => {
    const [tabWidth, setTabWidth] = React.useState<undefined | number>(minTabWidth);
    const oldMinTabWidth = React.useRef(minTabWidth);

    React.useEffect(() => {
        if (minTabWidth !== oldMinTabWidth.current) {
            setTabWidth(minTabWidth);
            oldMinTabWidth.current = minTabWidth;
        }
    }, [minTabWidth]);

    const handleTabRef = (ref: null | HTMLDivElement) => {
        if (ref) {
            if (tabWidth === undefined || tabWidth < ref.clientWidth) {
                setTabWidth(ref.clientWidth);
            }
        }
    };

    const renderTabTitle = (title: string | MobX.IComputedValue<string>) => {
        if (typeof title === "string") return title;
        return title.get();
    };

    const handleTabClick = (tab: ITab) => {
        if (tab.hash) {
            window.location.hash = `#${tab.hash}`;
        }
        onTabClick(tab);
    };

    return MobXReact.useObserver(() => (
        <TabsContainer tabWidth={tabWidth} tabPadding={tabPadding}>
            <div className="tabs-padding left" />
            {showBackBtn && (
                <div className="tab-back" role="button" tabIndex={0} onClick={onBack}>
                    <BackBox>
                        <NavigateBeforeIcon fontSize="large" />
                    </BackBox>
                    <BackToListText>{backBtnText}</BackToListText>
                </div>
            )}
            {tabs.map((tab) => (
                <div
                    className={`tab ${tab.key === selected ? "selected" : ""}`}
                    role="button"
                    tabIndex={0}
                    onClick={() => handleTabClick(tab)}
                    key={`tab-${tab.key}`}
                    ref={handleTabRef}
                >
                    {renderTabTitle(tab.title)}
                </div>
            ))}
            <div className="tabs-padding right" />
        </TabsContainer>
    ));
};
