import { useEffect } from "react";
import { StoresInstance } from "../../../Custom/Stores";

export function useFormLockConcurrency(formRef: string, formTypes: [number], lockNow: boolean = false) {
    useEffect(() => {
        StoresInstance.coreStore.FormLockConcurrencyHubStore.setFormsInfo(formRef, formTypes);

        if (lockNow) {
            const cb = () => {
                formTypes.forEach((formType) => {
                    StoresInstance.coreStore.FormLockConcurrencyHubStore.lockForm(formType);
                });
            };

            if (StoresInstance.coreStore.FormLockConcurrencyHubStore.getIsHubConnected) {
                cb();
            } else {
                StoresInstance.coreStore.FormLockConcurrencyHubStore.enqueueToConnectionQueue(cb);
            }
        }

        return () => StoresInstance.coreStore.FormLockConcurrencyHubStore.unlockAllForms();
    }, []);
}
