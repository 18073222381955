import styled from "styled-components";
import { Box } from "@material-ui/core";

export const UserDetailsBox: any = styled(Box)`
    padding: 15px 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1 0 0;
    justify-content: left;
`;

export const UserDetailsRow: any = styled(Box)`
    width: 100%;
    display: flex;
    flex-direction: row;
    flex: 1 0 0;
    padding: 7.5px 0;
    // background-color: green;
`;

export const UserDetailCell: any = styled(Box)`
    width: 100%;
    max-width: 320px;
    display: flex;
    flex-direction: row;
    flex: 1 0 0;
    // background-color: yellow;
    padding-right: 15px;

    .MuiInputBase-input,
    input {
        width: 100%;
        max-width: 320px;
        //     background-color: green;
    }

    .rcselect {
        margin-top: 1px;
        min-width: 320px;
    }
`;

export const UserDetailLongCell: any = styled(Box)`
    display: flex;
    flex-direction: row;
    flex: 1 0 0;
    padding-right: 15px;
    max-width: 643px;
    width: 100%;

    > div {
        width: 100% !important;
        max-width: 643px;
    }

    .MuiInputBase-input,
    input {
        width: 100% !important;
    }
    //  background-color: pink;
`;
