import { action, observable } from "mobx";
import { ModelBase } from "Core/Models/ModelBase";
import { IsNotEmpty } from "class-validator";
import { UnitBehaviourItemModel, UnitBehaviourItemModelDTO } from "Custom/Views/Admin/UnitBehaviour/UnitBehaviourItemModel";

//Base class expects the model type and the DTO model type
//If you are not using a DTO just pass in undefined as the DTO
export class DeviceUnitBehaviourItemModel extends ModelBase<DeviceUnitBehaviourItemModel, DeviceUnitBehaviourItemModelDTO> {
    public id: string | null = null;
    public rowVersion: string | null = null;
    public createdBy: string | null = null;
    public createdDate: string | null = null;
    public deviceUnitBehaviourId: string | null = null;

    @observable
    public commandIndex: number = 0;

    @observable public isDeleted: boolean = false;

    @observable
    @IsNotEmpty({ message: "You must give me a parameter name!!" })
    public name: string = "";

    @observable
    public type: number = 0;

    @observable
    @IsNotEmpty({ message: "You must give me a value!!" })
    public value: number = 0;

    @observable
    public units: number = 0;

    @observable
    public frequency: number = 3; // choices are x3, x4, x6

    @observable
    public reading: number = this.commandIndex === 0 ? 1 : 5;

    @action
    public setCommandIndex = (value: number) => {
        this.commandIndex = value;
        // Set the reading to the minimum value;
        this.reading = this.commandIndex === 0 ? 1 : 5;
    };

    //fromDto is required but you can leave it blank
    fromDto(model: DeviceUnitBehaviourItemModelDTO): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
    }

    fromUnitBehaviourItemModelDTO(model: UnitBehaviourItemModelDTO) {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        model.unitBehaviourId = null;

        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] === "deviceUnitBehaviourId") {
                    this[key] = null;
                } else if (this[key] === "unitBehaviourId") {
                    this[key] = null;
                } else if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }

        // When cloning an admin Unit behaviour, we want to reset all these values
        this.createdDate = null;
        this.createdBy = null;
        this.rowVersion = null;
        this.id = null;
        this.deviceUnitBehaviourId = null;
    }

    //toDto is required but you can leave it blank
    toDto(model: DeviceUnitBehaviourItemModel): void {}

    public toUnitBehaviourItemModel(): UnitBehaviourItemModel {
        let retVal: UnitBehaviourItemModel = new UnitBehaviourItemModel();

        let temp: UnitBehaviourItemModelDTO = {
            id: null,
            rowVersion: null,
            isDeleted: false,
            createdBy: null,
            createdDate: null,

            name: this.name,
            type: this.type,
            value: this.value,
            units: this.units,
            unitBehaviourId: null,
            frequency: this.frequency,
            reading: this.reading,
            commandIndex: this.commandIndex,
        };

        retVal.fromDto(temp);

        return retVal;
    }

    public toSendDto(): DeviceUnitBehaviourItemModelDTO {
        return {
            id: this.id,
            rowVersion: this.rowVersion,
            isDeleted: this.isDeleted,
            createdBy: this.createdBy,
            createdDate: this.createdDate,

            name: this.name,
            type: this.type,
            value: this.value,
            units: this.units,
            deviceUnitBehaviourId: this.deviceUnitBehaviourId,
            frequency: this.frequency,
            reading: this.reading,
            commandIndex: this.commandIndex,
        };
    }
}

export type DeviceUnitBehaviourItemModelDTO = {
    id: string | null;
    rowVersion: string | null;
    isDeleted: boolean;
    createdBy: string | null;
    createdDate: string | null;

    name: string;
    type: number;
    value: number;
    units: number;
    deviceUnitBehaviourId: string | null;
    frequency: number;
    reading: number;
    commandIndex: number;
};
