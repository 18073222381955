/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useState, useEffect } from "react";
import { Box, Fade, Typography } from "@material-ui/core";
import { AccountImageBox, PaperAccount } from "Custom/StylesAppSpecific/AccountStyling";
import { BoxAccount, LogonTitle } from "./Account/LoginPage.style";
import { CSSGrid } from "Core/Components/Styles";
import { AcceptBtn } from "Custom/StylesAppSpecific/Controls/AcceptBtn";
import logoUrl from "Custom/Content/RCGlogo.svg";
import { Client } from "Custom/Globals/AppUrls";
import { useRouter } from "Core/Utils";

export const SessionExpired: React.FC = () => {
    const { history } = useRouter();
    const goHome = () => {
        history.push(Client.Account.Login);
    };

    return (
        <Fade in timeout={1000}>
            <PaperAccount style={{ top: 0, padding: 0, height: "100vh" }}>
                <BoxAccount>
                    <Box>
                        <LogonTitle style={{ textAlign: "center" }}>Roofcare portal</LogonTitle>
                    </Box>
                    <CSSGrid columns={"1fr"} gap={"1em"}>
                        <Box style={{ width: "100%", alignItems: "center", paddingTop: "15px" }}>
                            <Typography style={{ fontSize: "16px", color: "white", fontFamily: "'Arial',sans-serif", textAlign: "center", lineHeight: 1.8 }}>
                                Your session has expired! Please login.
                            </Typography>
                        </Box>
                        <Box style={{ width: "100%", alignItems: "center" }}>
                            <AcceptBtn onClick={goHome}>Go to Login Page</AcceptBtn>
                        </Box>
                    </CSSGrid>
                </BoxAccount>
            </PaperAccount>
        </Fade>
    );
};
