import styled from "styled-components";
import { Box } from "@material-ui/core";

export const AdminRow = styled(Box)`
    position: relative;
    width: 100%;
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    .input-label {
        font-weight: 400;
        font-family: "Mont-Bold", "Montserrat", "Roboto Condensed", "Helvetica", "Arial", "sans-serif";
        margin-top: 8px;
        padding-right: 10px;
    }
`;

export const InputControlWrapper = styled(Box)`
    position: relative;
    flex-direction: column;
    justify-content: flex-start;
`;
