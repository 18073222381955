import styled from "styled-components";

export const ProjectDetailsPageContainer = styled.div`
    padding-top: 20px;
    /* margin: 0 30px; */
    background-color: #3b3b3b;
    color: white;

    .content {
        background-color: #585858;
        color: white;
    }
`;
