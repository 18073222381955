import { ViewModelBase } from "Core/ViewModels/ViewModelBase";
import { EstateModel, EstateModelDTO } from "./EstateModel";
import { FieldType, isEmptyOrWhitespace } from "Core/Utils/Utils";
import { action, computed, observable, runInAction } from "mobx";
import { debounce } from "lodash-es";
import { IMarker } from "Custom/Components/Map/Map";
import { DEBOUNCE_VALUE_MS, DEFAULTBOUNDS } from "Custom/Globals/Globals";
import { ApiResult } from "Core/Models";
import { Server } from "Custom/Globals/AppUrls";
import { EstateCommonRowDTO, InstallationMapModel, InstallationMapModelDTO, DisplayEstateHelpers, DisplayEstate } from "Custom/Models/Domain";
import { DeviceStatusWithCommandModelDTO } from "Custom/Models/Domain/Installations/DeviceStatusWithCommandModel";
import L, { LatLngBounds } from "leaflet";

import { getDefaultPropertyMarkerIcon, getInstallationMarkerIcon } from "Custom/Utils/map";
import { KeyValuePair } from "Core/Models/KeyValuePair";

//extend viewmodel base and passing your model as the generic type
export class EstateViewModel extends ViewModelBase<EstateModel> {
    //Singleton instance of class
    private static _instance: EstateViewModel;
    public static get Instance() {
        return this._instance || (this._instance = new this());
    }
    private firstTimeRun: boolean = true;

    @observable public errorMessage: string = "";

    @observable public IsDownloading: boolean = false;

    @observable public pageSize: number = 50;
    @observable public currentPage: number = 0;

    @observable public searchString: string = "";
    @observable public filterSearchString: string = "";

    // By default this will be the first column (by index) and in ascending order.
    @observable public sortColumnId: number = 0;
    @observable public sortDirection: any = "asc";

    @observable public showProperties: boolean = true;

    @action
    public toggleLayer() {
        this.showProperties = !this.showProperties;
    }

    @action
    public setOrderChange = (columnId: number, direction: any) => {
        this.sortColumnId = columnId;
        this.sortDirection = direction;
    };

    // #endregion Project Sorting

    // #region Project Filtering
    @observable public filterClientsFilter = observable<string>([]);
    @observable public clientsList: KeyValuePair[] = [];

    @observable public filterContractorFilter = observable<string>([]);
    @observable public contractorList: KeyValuePair[] = [];

    @observable public statusFilter = observable<string>([]);
    @observable public filterStatusFilter = observable<string>([]);
    @observable public estateStatuses: KeyValuePair[] = [];

    public commandStatuses = observable<DeviceStatusWithCommandModelDTO>([]);
    @observable public filterCommandStatusesFilter = observable<string>([]);
    @observable public commandStatusFilter = observable<string>([]);
    @observable public commandStatusList: KeyValuePair[] = [];

    public estateDisplay: KeyValuePair[] = DisplayEstateHelpers.getOptionsKeyValuePair();
    @observable public filterDisplay = observable<number>([1]);

    @computed
    public get getClientsKVPair(): KeyValuePair[] {
        return this.clientsList.slice();
    }

    public getClientsFilter = () => {
        return computed(() => this.filterClientsFilter);
    };

    @action
    public setEstateDisplayFilter(values: number[]) {
        this.filterDisplay.replace(values);
        this.setFilterDisplay(values);
    }

    private setFilterDisplay = debounce(
        action((values: number[]) => {
            this.filterDisplay.replace(values);
        }),
        DEBOUNCE_VALUE_MS,
    );

    public getEstateDisplayKVPair = (includeDeleted: boolean): KeyValuePair[] => {
        if (this.estateDisplay === undefined) {
            this.estateDisplay = DisplayEstateHelpers.getOptionsKeyValuePair();

            return this.estateDisplay;
        }

        return this.estateDisplay.slice();
    };

    getDisplayFilter(): import("mobx").IComputedValue<number[] | string[] | null> {
        return computed(() => this.filterDisplay);
    }

    @action
    public setClientFilter = (values: string[]) => {
        this.filterClientsFilter.clear();
        this.filterClientsFilter.replace(values);
        this.setFilterClient(values);
    };

    private setFilterClient = debounce(
        action((values: string[]) => {
            this.filterClientsFilter.replace(values);
        }),
        DEBOUNCE_VALUE_MS,
    );

    @computed
    public get getContractorsKVPair(): KeyValuePair[] {
        return this.contractorList.slice();
    }

    public getContractorFilter = () => {
        return computed(() => this.filterContractorFilter);
    };

    @action
    public setContractorFilter = (values: string[]) => {
        this.filterContractorFilter.clear();
        this.filterContractorFilter.replace(values);
        this.setFilterContractor(values);
    };

    private setFilterContractor = debounce(
        action((values: string[]) => {
            this.filterContractorFilter.replace(values);
        }),
        DEBOUNCE_VALUE_MS,
    );

    @action
    public getStatusKVPair = (): KeyValuePair[] => {
        if (this.estateStatuses != undefined) {
            return this.estateStatuses.slice();
        } else {
            const retVal: KeyValuePair[] = [];
            return retVal;
        }
    };

    public getStatusFilter = () => {
        return computed(() => this.filterStatusFilter);
    };

    @action
    public setStatusFilter = (values: string[]) => {
        this.filterStatusFilter.clear();
        this.filterStatusFilter.replace(values);
        this.setFilterUnitStatus(values);
    };

    private setFilterUnitStatus = debounce(
        action((values: string[]) => {
            this.filterStatusFilter.replace(values);
        }),
        DEBOUNCE_VALUE_MS,
    );

    @action
    public setFilterSets = () => {
        const model: EstateModel = this.model;

        if (model != null) {
            if (model.deviceStatuses.length > 0) {
                this.estateStatuses = [];
                // this.filterStatusFilter.clear();
                this.statusFilter.clear();
                for (let s: number = 0; s < model.deviceStatuses.length; s++) {
                    this.estateStatuses.push({
                        key: model.deviceStatuses[s].displayName,
                        value: model.deviceStatuses[s].id,
                    });
                    this.statusFilter.push(model.deviceStatuses[s].id);
                    if (this.firstTimeRun === true) {
                        this.filterStatusFilter.push(model.deviceStatuses[s].id);
                    }
                }
            }

            if (model.contractorsList.length > 0) {
                this.contractorList = [];
                //  this.filterContractorFilter.clear();
                for (let s: number = 0; s < model.contractorsList.length; s++) {
                    let contName = model.contractorsList[s].contractorName;
                    let contId = model.contractorsList[s].id;
                    this.contractorList.push({
                        key: contName,
                        value: contId,
                    });
                    if (this.firstTimeRun === true) {
                        this.filterContractorFilter.push(contId);
                    }
                }
            }

            if (model.clientsList.length > 0) {
                this.clientsList = [];
                //  this.filterClientsFilter.clear();
                for (let s: number = 0; s < model.clientsList.length; s++) {
                    let clientName = model.clientsList[s].clientName;
                    let clientId = model.clientsList[s].id;
                    this.clientsList.push({
                        key: clientName,
                        value: clientId,
                    });
                    if (this.firstTimeRun === true) {
                        this.filterClientsFilter.push(clientId);
                    }
                }
            }

            if (model.deviceStatuses.length > 0 && model.clientsList.length > 0 && model.contractorsList.length > 0) {
                // so if the model is not null AND we have created the filter lists then we can set the firstTimeRun to false
                this.firstTimeRun = false;
            }
        }
    };
    // #endregion Project Filtering

    public getInstallation(id: string): InstallationMapModelDTO | undefined {
        return this.model.units.find((a) => a.id === parseInt(id, 10));
    }

    public getProperty(id: string): EstateCommonRowDTO | undefined {
        return this.model.clients.find((a) => a.propertyId === id);
    }

    @computed public get getLayer(): number {
        return this.showProperties === true ? 0 : 1;
    }

    @computed public get getListData(): EstateCommonRowDTO[] {
        let retVal: EstateCommonRowDTO[] = this.model.clients;
        let units: InstallationMapModel[] = this.model.units;

        if (this.filterDisplay.length === 3) {
            // then we all items
        } else if (this.filterDisplay.length === 0) {
            // we want no units
            retVal = [];
        } else {
            const wantUnits: boolean = this.filterDisplay.indexOf(DisplayEstate.WithUnits) !== -1;
            const withoutUnits: boolean = this.filterDisplay.indexOf(DisplayEstate.WithoutUnits) !== -1;

            if (wantUnits == true && withoutUnits == true) {
                // then don't do anything
            } else if (wantUnits == true) {
                retVal = retVal.filter((a) => a.propertyCount > 0);
            } else if (withoutUnits == true) {
                retVal = retVal.filter((a) => a.propertyCount === 0);
            }
        }

        const filterBySearchString = (item: EstateCommonRowDTO): boolean => {
            if (isEmptyOrWhitespace(this.filterSearchString)) {
                return true;
            }

            const searchStringUpperCase = this.filterSearchString.toUpperCase();

            // Search ContractorName unit name and unit number
            let devices = units.filter(
                (a) =>
                    a.roofcareAddressId === item.propertyId &&
                    ((a.contractorName !== null && a.contractorName !== undefined && a.contractorName.toUpperCase().includes(searchStringUpperCase)) ||
                        (a.name !== null && a.name !== undefined && a.name.toUpperCase().includes(searchStringUpperCase)) ||
                        (a.number !== null && a.number !== undefined && a.number.toUpperCase().includes(searchStringUpperCase))),
            );

            let contractorName = item.contractorName != null ? item.contractorName : "";

            return (
                item.clientName.toUpperCase().includes(searchStringUpperCase) ||
                contractorName.toUpperCase().includes(searchStringUpperCase) ||
                item.addressLine1.toUpperCase().includes(searchStringUpperCase) ||
                item.addressLine2.toUpperCase().includes(searchStringUpperCase) ||
                item.postcode.toUpperCase().includes(searchStringUpperCase) ||
                item.city.toUpperCase().includes(searchStringUpperCase) ||
                item.county.toUpperCase().includes(searchStringUpperCase) ||
                item.postcode.toUpperCase().includes(searchStringUpperCase) ||
                (devices !== undefined && devices !== null && devices.length > 0)
            );
        };

        const filterByStatus = (item: EstateCommonRowDTO): boolean => {
            if (this.filterStatusFilter.length === this.estateStatuses.length) {
                return true;
            }

            let statusResponse: boolean = false;
            // Check 1 - see if it is device item only from the main list.
            let found: boolean = this.filterStatusFilter.includes(item.status.id);
            // have we a match from Admin/Devices
            if (found) {
                statusResponse = true;
            } else {
                // have we a match from Command set based on the admin condtiion sets
                if (this.commandStatuses !== undefined && this.commandStatuses.length > 0) {
                    let commandFound: DeviceStatusWithCommandModelDTO | undefined = this.commandStatuses.find((a) => a.id === item.status.id);

                    if (commandFound !== undefined) {
                        let found: boolean = this.filterStatusFilter.includes(commandFound.adminConditionSetItemId);
                        if (found) {
                            statusResponse = true;
                        }
                    }
                }
            }

            return statusResponse;
        };

        const filterByClient = (item: EstateCommonRowDTO): boolean => {
            if (this.filterClientsFilter.length === this.clientsList.length) {
                return true;
            }

            let clientResponse: boolean = false;
            let found: boolean = this.filterClientsFilter.includes(item.clientId);
            if (found) {
                clientResponse = true;
            } else {
                clientResponse = false;
            }

            return clientResponse;
        };

        const filterByContractor = (item: EstateCommonRowDTO): boolean => {
            if (this.filterContractorFilter.length === this.contractorList.length) {
                return true;
            }

            let contractorResponse: boolean = false;
            let found: boolean = this.filterContractorFilter.includes(item.contractorId);
            if (found) {
                contractorResponse = true;
            } else {
                contractorResponse = false;
            }

            return contractorResponse;
        };

        return retVal
            .filter((a) => filterByClient(a))
            .filter((a) => filterByContractor(a))
            .filter((a) => filterByStatus(a))
            .filter((a) => filterBySearchString(a));
    }

    public getSearchString = () => {
        return computed(() => this.searchString);
    };

    @action
    public setSearchString = (value: string) => {
        this.searchString = value;
        this.setFilterSearchString(value);
    };

    private setFilterSearchString = debounce(
        action((value: string) => {
            this.filterSearchString = value;
        }),
        DEBOUNCE_VALUE_MS,
    );

    // #endregion Project Sorting

    @action
    public setRowsPerPage(rows: number) {
        this.pageSize = rows;
    }

    @action
    public setPage = (page: number) => {
        this.currentPage = page;
    };

    @computed
    public get markers(): IMarker[] {
        if (this.showProperties) {
            return this.getListData.map((client: EstateCommonRowDTO) => ({
                id: client.propertyId,
                position: [client.latitude, client.longitude],
                icon: getInstallationMarkerIcon(client.status === null || client.status === undefined ? "#ffffff" : client.status.statusColour),
            }));
        } else {
            let pins: InstallationMapModel[] = [];

            let filteredList: EstateCommonRowDTO[] = this.getListData;

            for (let i: number = 0; i < filteredList.length; i++) {
                let showThese: InstallationMapModel[] = this.model.units.filter((a) => a.roofcareAddressId === filteredList[i].propertyId);
                pins.push(...showThese);
            }
            return pins.map((installation: InstallationMapModel) => ({
                id: installation.id.toString(),
                position: [installation.latitude, installation.longitude],
                icon: getInstallationMarkerIcon(installation.status === null || installation.status === undefined ? "#ffffff" : installation.status.statusColour),
            }));
        }

        return [];
    }

    constructor() {
        super(new EstateModel(), false);
        this.setDecorators(EstateModel);
    }

    //isValid will check all fields to make sure they are in a valid state.
    public doSubmit = async (e: any) => {
        e.preventDefault();

        if (this.isModelValid()) {
            //Do stuff here
            this.errorMessage = "Form is valid";
        } else {
            this.errorMessage = "Form is not valid";
        }
    };

    //This must be present in your viewmodel. Just return true if you dont need to validate anything.
    //keyof BlankModel & string lets you add type checking to the fieldName
    //I am using the validator package to make checking easier https://www.npmjs.com/package/validator
    public isFieldValid(fieldName: keyof FieldType<EstateModel>): boolean {
        const { isValid, errorMessage } = this.validateDecorators(fieldName);

        //You need to this two properties after validation
        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    @action
    public async loadDashboardAsync(): Promise<ApiResult<EstateModelDTO>> {
        this.model.clear();
        const apiResult = await this.Post<EstateModelDTO>(Server.Api.Estate.getPropertiesForList);

        if (apiResult.wasSuccessful) {
            runInAction(() => {
                if (apiResult.payload !== undefined && apiResult.payload !== null) {
                    this.model.setClients(apiResult.payload.clients);
                    this.model.setUnits(apiResult.payload.units);
                    this.model.setClientsList(apiResult.payload.clientsList);
                    this.model.setContractorsList(apiResult.payload.contractorsList);
                    this.model.setDevicesStatus(apiResult.payload.deviceStatuses);
                    this.model.setDevicesWithCommand(apiResult.payload.deviceStatusesWithCommandIdx);
                }
            });
        }
        return apiResult;
    }

    public getProjectIdForProperty(id: string): string {
        let retVal: string = "";

        const property: EstateCommonRowDTO | undefined = this.getProperty(id);

        if (property !== undefined) {
            retVal = property.projectId;
        }

        return retVal;
    }

    public getPropertyIdForUnit(id: string): string {
        let retVal: string = "";

        const unit: InstallationMapModelDTO | undefined = this.getInstallation(id);

        if (unit !== undefined) {
            const property: EstateCommonRowDTO | undefined = this.getProperty(unit.roofcareAddressId);

            if (property !== undefined) {
                retVal = property.propertyId;
            }
        }
        return retVal;
    }

    @computed
    public get mapBounds(): LatLngBounds {
        let retVal: LatLngBounds = DEFAULTBOUNDS;
        let bounds: LatLngBounds = new LatLngBounds(
            this.model.units.filter((a) => a.latitude !== 0 && a.longitude !== 0).map((installation) => [installation.latitude, installation.longitude]),
        );

        if (this.showProperties) {
            bounds = new LatLngBounds(
                this.model.clients.filter((a) => a.latitude !== 0 && a.longitude !== 0).map((property: EstateCommonRowDTO) => [property.latitude, property.longitude]),
            );
        }

        const isValid: boolean = bounds.isValid();

        if (isValid) {
            retVal = bounds;
        }

        return retVal;
    }
}
