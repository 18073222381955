import { ViewModelBase } from "Core/ViewModels/ViewModelBase";
import { FieldType } from "Core/Utils/Utils";
import { action, computed, IObservableArray, observable } from "mobx";
import { InstallationListItem, InstallationMapModel } from "Custom/Models/Domain";
import { LatLng, LatLngBounds, LatLngBoundsLiteral } from "leaflet";
import { IMarker } from "Custom/Components/Map/Map";
import { markerIconGreen, markerIconAmber, markerIconFlood, markerIconRed, markerIconUnkown } from "Custom/Components/Map/icons";
import { DEFAULTBOUNDS } from "Custom/Globals/Globals";
import { getInstallationMarkerIcon } from "Custom/Utils/map";

//extend viewmodel base and passing your model as the generic type
export class InstallationMapViewModel extends ViewModelBase<InstallationMapModel> {
    //Singleton instance of class
    private static _instance: InstallationMapViewModel;
    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    @observable public errorMessage: string = "";

    public installations: IObservableArray<InstallationMapModel> = observable<InstallationMapModel>([]);

    @computed
    public get getInstallations(): InstallationMapModel[] {
        return this.installations.slice();
    }

    public getInstallation(id: string): InstallationMapModel | undefined {
        return this.installations.find((a) => a.id === parseInt(id));
    }

    @action
    public setInstallations(items: InstallationMapModel[]): void {
        this.installations.replace(items);
    }

    public showingWarning = observable({
        /*         [InstallationStatus.GreenWarning]: true,
        [InstallationStatus.AmberWarning]: true,
        [InstallationStatus.RedWarning]: true,
        [InstallationStatus.FloodWarning]: true, */
    });

    constructor() {
        //Pass in a new instance of your model
        //By passing in true as the second parameter, we make this model undoable which means we can use save and reset options on the model
        //If you make a change to the model you need to persist it with a saveModel() call
        //If you make changes to your model you can revert it back by calling resetModel()
        super(new InstallationMapModel(), false);
        //EN - Haven't figured out how to make this call work from the base model yet
        //This is only needed if you make use of the validation decorators
        this.setDecorators(InstallationMapModel);
    }

    /*     //isValid will check all fields to make sure they are in a valid state.
    public doSubmit = async (e: any) => {
        e.preventDefault();

        if (this.isModelValid()) {
            //Do stuff here
            this.errorMessage = "Form is valid";
        } else {
            this.errorMessage = "Form is not valid";
        }
    }; */

    //This must be present in your viewmodel. Just return true if you dont need to validate anything.
    //keyof BlankModel & string lets you add type checking to the fieldName
    //I am using the validator package to make checking easier https://www.npmjs.com/package/validator
    public isFieldValid(fieldName: keyof FieldType<InstallationMapModel>): boolean {
        const { isValid, errorMessage } = this.validateDecorators(fieldName);

        //You need to this two properties after validation
        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    @computed
    public get mapBounds() {
        let bounds: LatLngBounds = new LatLngBounds(this.installations.map((installation) => [installation.getValue("latitude"), installation.getValue("longitude")]));
        //bounds = bounds.pad(0.1);
        /*         const sw: LatLng = bounds.getSouthWest();
        const ne: LatLng = bounds.getNorthEast();

        let b: LatLngBounds = new LatLngBounds(sw, ne);
        let a: LatLngBounds = b.pad(0.5); */
        // WHY DOESN'T PAD WORK LIKE THE DOCUMENTATION.

        const isValid: boolean = bounds.isValid();
        /*         if (isValid) {
            bounds = bounds.pad(10);
        } */
        return isValid ? bounds : DEFAULTBOUNDS;
    }

    @computed
    public get markers(): IMarker[] {
        return (
            this.installations
                /* .filter((i) =>  i.showOnMap && this.showingWarning[i.status] !== false) */
                .map((installation) => ({
                    id: installation.getValue("id"),
                    position: [installation.getValue("latitude"), installation.getValue("longitude")],
                    icon: getInstallationMarkerIcon(installation.status !== null && installation.status !== undefined ? installation.status.statusColour : "#ffffff"),
                }))
        );
    }
}
