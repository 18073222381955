import styled from "styled-components";
import { Box } from "@material-ui/core";
import { TabCommonBox } from "./TabCommonStyle.style";

export const DeviceImagePanel: any = styled(TabCommonBox)`
    /*     padding: 5px 15px;
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    width: 100%; */

    .guttersens-admin-deviceimages {
        //background-color: green;
        display: flex;
        flex: 1 0 0;
        flex-direction: column;

        .device-image-row,
        .device-image-cell {
            display: flex;
            flex: 1 0 0;
            position: relative;
            flex-direction: row;
            padding: 3px;
        }
    }

    .slick-prev,
    .slick-next {
        border-radius: 10px;
        background-color: #337ab7;
        padding-top: 1px;
    }

    .slick-prev:hover,
    .slick-next:hover {
        border-radius: 10px;
        background-color: #337ab7;
        padding-top: 1px;
    }

    .device-images {
        display: flex;
        flex-direction: column;
        flex: 1 0 auto;
        width: 100%;
    }
`;

export const SortImagePanel: any = styled(Box)`
    display: flex;
    flex-direction: row;

    .slider-text {
        margin-top: 6px;
        padding-right: 15px;
    }
`;
