/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Stores } from "../Stores";
import { AccountStatus, ApiResult } from "Core/Models";
import axios, * as Axios from "axios";
import { History } from "history";

// App
import { observable, computed, action } from "mobx";
import { Client } from "Custom/Globals/AppUrls";
import { CheckHttpStatus, MergeDefaultConfig } from "Core/Utils";
import { getHistory } from "Core/Utils/Utils";

export class BaseStore {
    public history: History;
    public stores: Stores | null | undefined;
    @observable public IsErrored = false;
    @observable public Errors: string = "";

    @observable protected isLoadingCount = 0;
    @observable public isLoaded = true;
    @computed public get isLoading() {
        return this.isLoadingCount > 0;
    }
    public constructor(stores?: Stores) {
        this.history = getHistory();
        if (stores) this.stores = stores;
    }

    @action protected setIsLoading = () => {
        this.isLoadingCount++;
        this.isLoaded = false;
    };
    @action protected unsetIsLoading = () => {
        this.isLoadingCount--;
        if (this.isLoadingCount === 0) {
            this.isLoaded = true;
        }
    };

    @action protected setIsErrored = (state: boolean) => (this.IsErrored = state);
    @action protected setErrors = (state: string) => (this.Errors = state);

    Get = <TPayload = ApiResult<undefined>>(
        url: string,
        //model?: any,
        config?: Axios.AxiosRequestConfig,
    ): Promise<ApiResult<TPayload>> => {
        this.setIsLoading();
        const postPromise = axios
            .get<ApiResult<TPayload>>(url, this.getConfig(config))
            .then(async (response) => {
                if (response.headers["token-expired"]) {
                    let newTokenResult = await axios.post<ApiResult<any>>("/api/account/refresh", {
                        accessToken: sessionStorage.getItem(".auth"),
                    });
                    this.setLoginState(newTokenResult.data.payload);
                    if (newTokenResult.data.payload.jwt === "") {
                        //Go to session expired page
                        window.location.href = Client.SessionExpired;
                        //return false;
                    }
                    //Make the original call again
                    response = await axios.get<ApiResult<TPayload>>(url, this.getConfig(config));
                } else if (response.headers["unauthorized"]) {
                    this.logout();
                }
                CheckHttpStatus(response);
                this.setIsLoading();
                return response.data;
            })
            .catch((error) => {
                this.setIsErrored(true);
                this.unsetIsLoading();
                this.setErrors(error);
                if (error.response.status === 401) {
                    this.logout();
                }
                return { wasSuccessful: false };
            });

        return postPromise as Promise<ApiResult<TPayload>>;
    };

    private getAnyModelAsPayload(model: any): any {
        let exclude = ["Dirty", "Errors", "Valid", "Touched", "localComputedValues", "localValues", "isPropertyDirty"];
        let payload = {} as any;
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (!exclude.includes(key)) {
                    //EN: Check for recursed models
                    if (key == "model" && typeof model[key] === "object") {
                        continue;
                    }
                    payload[key] = model[key];
                    if (typeof payload[key] === "string") {
                        //EN: Exclude null characters in a string
                        (payload[key] as any as string).replace(/\0/g, "");
                    }
                }
            }
        }
        return payload;
    }

    Post = <TPayload = ApiResult<undefined>>(url: string, model?: any, config?: Axios.AxiosRequestConfig): Promise<ApiResult<TPayload>> => {
        this.setIsLoading();
        let payload = this.getAnyModelAsPayload(model);
        const postPromise = axios
            .post<ApiResult<TPayload>>(url, payload, this.getConfig(config))
            .then(async (response) => {
                if (response.headers["token-expired"]) {
                    let newTokenResult = await axios.post<ApiResult<any>>("/api/account/refresh", {
                        accessToken: sessionStorage.getItem(".auth"),
                    });
                    this.setLoginState(newTokenResult.data.payload);
                    if (newTokenResult.data.payload.jwt === "") {
                        //Go to session expired page
                        window.location.href = Client.SessionExpired;
                    }
                    response = await axios.post<ApiResult<TPayload>>(url, model, this.getConfig(config));
                } else if (response.headers["unauthorized"]) {
                    this.logout();
                }
                CheckHttpStatus(response);
                this.unsetIsLoading();

                return response.data;
            })
            .catch((error) => {
                this.setIsErrored(true);
                this.unsetIsLoading();
                this.setErrors(error);
                if (error && error.response && error.response.status === 401) {
                    this.logout();
                }
                return { wasSuccessful: false };
            });

        return postPromise as Promise<ApiResult<TPayload>>;
    };

    getConfig = (config?: Axios.AxiosRequestConfig) => {
        const requestConfig = MergeDefaultConfig(config);
        //Sets the bearer on every header if available
        //Note: You might need to remove this bearer if calling 3rd party api's
        let jwt = sessionStorage.getItem(".auth") as string;
        if (jwt && jwt.length > 0) {
            requestConfig.headers = {
                Authorization: "Bearer " + jwt,
                "content-type": "application/json",
            };
        } else {
            requestConfig.headers = {
                "content-type": "application/json",
            };
        }
        return requestConfig;
    };

    setLoginState = (apiResult: AccountStatus) => {
        sessionStorage.setItem(".auth", apiResult.jwt as string);
        //EN: Hack at the moment so that we do not get cicular references
        // let stores = (window as any).Stores as Stores;
        // if (stores) {
        // 	stores.domain.AccountStore.getLoginState(apiResult);
        // }
    };

    logout = () => {
        //(window as any).Stores.domain.AccountStore.Logout();
        this.history.push(Client.Account.Logout);
    };
}
