import React, { useRef, useEffect, useState } from "react";
import { useObserver } from "mobx-react-lite";
import { GutterSensTabContent } from "../GuttersensTabContent";
import { generateID, sortByMoment } from "Core/Utils/Utils";
import { AuditBox } from "Custom/Views/Installations/Audit/TabAudit.style";
import { DeviceHeader } from "../TabAdmin.style";
import { Box, Paper, Typography, useTheme } from "@material-ui/core";
import { RoofcareTable, RoofcareTableBox } from "Custom/Components/Table/RoofcareTable";
import { formatDateTime } from "Custom/Utils/format";
import { AuditType, InstallationAuditModelDTO } from "./InstallationAuditModel";
import { InstallationAuditViewModel } from "./InstallationAuditViewModel";
import { IsAuditTypeAlerter, isNullOrUndefined, IsAuditTypeCommission } from "Custom/Utils/utils";
import { AuditModal } from "../Modals/AuditModal";
import { MultiSelectView } from "Custom/Components/Select/MultipleSelect";
import moment from "moment";
import { InstallationCommissionedAuditRecord } from "Custom/Models/InstallationCommissionedAuditRecord";

interface TabAuditProps {
    deviceId?: number;
}

export function TabAudit(props: TabAuditProps) {
    const {} = props;
    const theme = useTheme();
    const [modalOpen, setModalOpen] = React.useState(false);
    const [auditItem, setAuditItem] = React.useState<InstallationAuditModelDTO | undefined>(undefined);
    const [viewModel] = useState(() => InstallationAuditViewModel.Instance);
    const [counter, setCount] = useState(0);

    const componentRef: any = useRef(generateID());

    useEffect(() => {
        const { deviceId } = props;
        const { getAuditTrail, IsLoading, load } = viewModel;

        if ((isNullOrUndefined(getAuditTrail) === true || getAuditTrail.length === 0) && IsLoading === false && isNullOrUndefined(deviceId) === false && deviceId! > 0) {
            let promise = load(deviceId!);

            promise.then(() => {
                let a = counter + 1;
                setCount(a);
            });
        }

        // tidy up after yourself
        return () => {};
    }, []);

    useEffect(() => {
        const { deviceId } = props;
        const { getAuditTrail, IsLoading, load } = viewModel;

        if ((isNullOrUndefined(getAuditTrail) === true || getAuditTrail.length === 0) && IsLoading === false && isNullOrUndefined(deviceId) === false && deviceId! > 0) {
            let promise = load(deviceId!);

            promise.then(() => {
                let a = counter + 1;
                setCount(a);
            });
        }

        // tidy up after yourself
        return () => {};
    }, [props.deviceId]);

    const getSortDirection = (columnIndex: number) => {
        return viewModel.sortColumnId === columnIndex ? viewModel.sortDirection : "";
    };

    const setRowsPerPage = (rows: number) => {
        viewModel.setRowsPerPage(rows);
    };

    const setPage = (page: number) => {
        viewModel.setPage(page);
    };

    const setOrderChange = (columnId: number, direction: any) => {
        viewModel.setOrderChange(columnId, direction);
    };

    const handleRowClick = (rowData: InstallationAuditModelDTO) => {
        // TODO CMR Commented out whilst I do the command changes
        handleOpen(rowData);
    };

    const handleOpen = async (auditItem: InstallationAuditModelDTO) => {
        setAuditItem(auditItem);
        setModalOpen(true);
    };

    const onCloseModal = () => {
        setModalOpen(false);
        setAuditItem(undefined);
    };

    const isInError = (fieldName: string): boolean => {
        return false;
    };

    const getError = (fieldName: string): string => {
        return "";
    };

    const handleAuditTypeFilterChange = (values: number[] | string[] | null) => {
        const valueList: string[] = values as string[];
        const intValues: string[] = [];

        for (let i: number = 0; i < valueList.length; i++) {
            const item: string = valueList[i];
            intValues.push(item);
        }

        viewModel.setAuditTypeFilter(intValues);
    };

    const GetInstaller = (rowData: InstallationAuditModelDTO): React.ReactNode => {
        let retVal: string = rowData.author;

        if (rowData.auditTypeEnum === AuditType.Commissioned) {
            if (rowData.dataJson !== null && rowData.dataJson !== undefined && rowData.dataJson.length > 0 && rowData.dataJson.toLocaleLowerCase().indexOf("version") !== -1) {
                let model: InstallationCommissionedAuditRecord = JSON.parse(rowData.dataJson);

                if (model !== undefined) {
                    retVal = model.installer;
                }
            }
        }

        return retVal;
    };

    return useObserver(() => (
        <>
            <AuditModal
                onClose={onCloseModal}
                open={modalOpen}
                title={auditItem !== undefined ? auditItem.auditTypeName + " (" + formatDateTime(auditItem.createdDate) + ")" : "Audit Row"}
                auditItem={auditItem}
            />

            <GutterSensTabContent>
                <AuditBox>
                    <Typography style={{ fontSize: "18px" }}>Installation Audit</Typography>
                </AuditBox>
                {viewModel.getAuditTrailTotal > 0 && (
                    <AuditBox className="filterCell">
                        <Typography style={{ color: theme.palette.secondary.contrastText }} variant="h2" className="select-title">
                            Audit Type:
                        </Typography>
                        <Box className="multiselectbox">
                            <MultiSelectView
                                fieldName=""
                                values={viewModel.getAuditTypeFilter()}
                                display="Audit Type"
                                useNumber={true}
                                getOptions={(includeDeleted: boolean) => viewModel.getAuditTypeFilterKVPair}
                                handleChange={handleAuditTypeFilterChange}
                                getError={getError}
                                getValid={isInError}
                            />
                        </Box>
                    </AuditBox>
                )}
                <AuditBox>
                    <RoofcareTableBox>
                        <RoofcareTable
                            columns={[
                                {
                                    title: "Audit Date",
                                    field: "timestamp",
                                    type: "date",
                                    sorting: true,
                                    filtering: false,
                                    customSort: (a: InstallationAuditModelDTO, b: InstallationAuditModelDTO) => sortByMoment(moment.utc(a.createdDate), moment.utc(b.createdDate)),

                                    defaultSort: getSortDirection(0),
                                    render: (rowData: InstallationAuditModelDTO) => <Box>{formatDateTime(rowData.createdDate)}</Box>,
                                },
                                {
                                    title: "Author",
                                    field: "author",
                                    sorting: true,
                                    filtering: false,
                                    defaultSort: getSortDirection(1),
                                    render: (rowData: InstallationAuditModelDTO) => {
                                        if (IsAuditTypeAlerter(rowData.auditTypeEnum, rowData.createdBy!) === true) {
                                            return <Box>Alerter</Box>;
                                        } else if (IsAuditTypeCommission(rowData.auditTypeEnum) === true) {
                                            return <Box>{GetInstaller(rowData)}</Box>;
                                        } else {
                                            return <Box>{rowData.author}</Box>;
                                        }
                                    },
                                },
                                {
                                    title: "Type",
                                    field: "auditTypeName",
                                    sorting: true,
                                    filtering: false,
                                    defaultSort: getSortDirection(2),
                                },
                                {
                                    title: "Description",
                                    field: "description",
                                    sorting: true,
                                    filtering: false,
                                    defaultSort: getSortDirection(3),
                                },
                                {
                                    title: "Additional",
                                    field: "description",
                                    sorting: true,
                                    filtering: false,
                                    defaultSort: getSortDirection(4),
                                    render: (rowData: InstallationAuditModelDTO) => {
                                        if (
                                            rowData.auditTypeEnum === AuditType.SentAlertEmail ||
                                            rowData.auditTypeEnum === AuditType.SentAlertSMS ||
                                            rowData.auditTypeEnum === AuditType.UnitAlertTripped ||
                                            rowData.auditTypeEnum === AuditType.StandingWaterEmail ||
                                            rowData.auditTypeEnum === AuditType.StandingWaterSMS
                                        ) {
                                            return (
                                                <Box style={{ cursor: "pointer" }} onClick={() => handleRowClick(rowData)}>
                                                    Additional Info
                                                </Box>
                                            );
                                        } else {
                                            return <div style={{ cursor: "default" }}></div>;
                                        }
                                    },
                                },
                            ]}
                            data={viewModel.getAuditTrail}
                            options={{
                                thirdSortClick: false,
                                sorting: true,
                                filtering: false,
                                search: false,
                                toolbar: false,
                                cellStyle: {
                                    fontSize: 10,
                                },
                                headerStyle: {
                                    background: " #495D89 0% 0% no-repeat padding-box",
                                    boxShadow: " inset 0px 1px 1px #FFFFFF40",
                                    color: "#fff",
                                    border: "unset",
                                    fontFamily: ["Mont-Bold", "Montserrat", "Roboto Condensed", "Helvetica", "Arial", "sans-serif"].join(","),
                                },
                                rowStyle: {
                                    backgroundColor: "#525355",
                                    color: "#fff",
                                    fontSize: 24,
                                    cursor: "default",
                                },
                                pageSize: 50,
                                pageSizeOptions: [10, 20, 50, 100],
                                emptyRowsWhenPaging: false,
                                tableLayout: "fixed",
                            }}
                            /* onRowClick={(_: any, rowData: any) => handleRowClick(rowData)} */
                            components={{ Container: (props: any) => <Paper {...props} elevation={0} /> }}
                            title=""
                            onOrderChange={(orderedColumnId: any, orderDirection: any) => {
                                setOrderChange(orderedColumnId, orderDirection);
                            }}
                            onChangeRowsPerPage={(rows: number) => {
                                setRowsPerPage(rows);
                            }}
                            onChangePage={(page: number) => {
                                setPage(page);
                            }}
                        />
                    </RoofcareTableBox>
                </AuditBox>
            </GutterSensTabContent>
        </>
    ));
}
