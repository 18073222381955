import { ApiResult } from "Core/Models/ApiResult";
import { ViewModelBase } from "Core/ViewModels/ViewModelBase";
import { FieldType } from "Core/Utils/Utils";
import { Server } from "Custom/Globals/AppUrls";
import { runInAction, action, computed, observable } from "mobx";
import {
    SensorValueDTO,
    InstallationStatusDataDTO,
    SensorValueExtended,
    GetValuesByDateRequest,
    HideOnGraph,
    DaysOption,
    RoofType,
    DrainageType,
    SideType,
} from "Custom/Models/Domain/Installations";
import moment, { Moment } from "moment";
import { InstallationDataResult } from "Custom/Models/Domain/Installations/InstallationDataResult";
import { FileDownloadResponseDTO } from "./FileDownloadResponseDTO";
import { DeviceConditionSetModelDTO } from "./TabConfigurationModel";
//extend viewmodel base and passing your model as the generic type
export class InstallationDataViewModel extends ViewModelBase<InstallationDataResult> {
    //
    ///
    ///   ENSURE ANY NEW VARIABLES ARE CLEARED IN THE RESET FUNCTION!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    //
    @observable scrollTop: number = 0;

    @observable public dateStart: Moment = moment.utc().startOf("day").add(-1, "days");

    @observable public dateEnd: Moment = moment.utc().startOf("day");

    @observable public daysToGraph = 1;

    @observable public statusData: InstallationStatusDataDTO = {
        id: undefined,
        gutterSensId: undefined,
        side1_Length: 0,
        side2_Length: 0,
        side1_Angle: 0,
        side2_Angle: 0,
        side1_Height: 0,
        side2_Height: 0,
        install_Height: 0,
        emailAddresses: "",
        mobileNumbers: "",
        roofType: RoofType.Pitched,
        maxHeight: 0,
        drainageType: DrainageType.Gravity,
        p1: 0,
        p2: 0,
        siphonicBrand: null,
        baseMeasurement: 0,
        isDeleted: false,
        commissionDate: null,
        propertyId: null,
        contractorId: null,
        isComissioned: false,
        standingWaterDays: 5,
        standingWaterPercent: 10,
        standingWaterEnum: 0,
        allowSWEmailSend: true,
        allowSWSMSSend: true,
        side1_Length2: 0,
        side2_Length2: 0,
        side1_Angle2: 0,
        side2_Angle2: 0,
        baseWidth: 0,
        sideType: SideType.ThreeSideSym,
    };

    @observable public conditionSet: DeviceConditionSetModelDTO | undefined;

    @action
    public setConditionSet(conditionSet: DeviceConditionSetModelDTO | undefined) {
        this.conditionSet = conditionSet;
    }

    //Singleton instance of class
    private static _instance: InstallationDataViewModel;
    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    @observable public daysOptions: DaysOption[] = [
        { label: "24 Hours", value: 1 },
        { label: "Week", value: 7 },
        { label: "Month", value: moment().daysInMonth() },
        { label: "Last 12 Months", value: moment().isLeapYear() ? 366 : 365 },
        { label: "Custom", value: -1 },
    ];

    @computed
    public get getDaysToGraph(): number {
        return this.daysToGraph;
    }

    @computed
    public get getScrollTop(): number {
        return this.scrollTop;
    }

    @computed
    public get getSensorValues(): SensorValueExtended[] {
        return this.model.sensorValues.slice();
    }

    @computed
    public get graphSensorValues(): SensorValueExtended[] {
        let retVal: SensorValueExtended[] = this.model.sensorValues.slice().filter((a) => a.hideOnGraph === false);
        return retVal;
    }

    constructor() {
        //Pass in a new instance of your model
        //By passing in true as the second parameter, we make this model undoable which means we can use save and reset options on the model
        //If you make a change to the model you need to persist it with a saveModel() call
        //If you make changes to your model you can revert it back by calling resetModel()
        super(new InstallationDataResult(), false);
        //EN - Haven't figured out how to make this call work from the base model yet
        //This is only needed if you make use of the validation decorators
        this.setDecorators(InstallationDataResult);
    }

    //This must be present in your viewmodel. Just return true if you dont need to validate anything.
    //keyof BlankModel & string lets you add type checking to the fieldName
    //I am using the validator package to make checking easier https://www.npmjs.com/package/validator
    public isFieldValid(fieldName: keyof FieldType<InstallationDataResult>): boolean {
        const { isValid, errorMessage } = this.validateDecorators(fieldName);

        //You need to this two properties after validation
        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    @action
    public setDaysToGraph = (days: number): void => {
        this.daysToGraph = days;
    };

    @action
    public setStatusData = (statusData: InstallationStatusDataDTO): void => {
        this.statusData = statusData;
    };

    @action
    public setStartDate = (start: Moment): void => {
        this.dateStart = start;
    };

    @action
    public setEndDate = (end: Moment): void => {
        this.dateEnd = end;
    };

    @action
    public reset = (): void => {
        this.dateStart = moment.utc().startOf("day").add(-1, "days");

        this.dateEnd = moment.utc().startOf("day");

        this.statusData = {
            id: undefined,
            gutterSensId: undefined,
            side1_Length: 0,
            side2_Length: 0,
            side1_Angle: 0,
            side2_Angle: 0,
            side1_Height: 0,
            side2_Height: 0,
            install_Height: 0,
            emailAddresses: "",
            mobileNumbers: "",
            roofType: RoofType.Pitched,
            maxHeight: 0,
            drainageType: DrainageType.Gravity,
            p1: 0,
            p2: 0,
            siphonicBrand: null,
            baseMeasurement: 0,
            isDeleted: false,
            commissionDate: null,
            propertyId: null,
            contractorId: null,
            isComissioned: false,
            standingWaterDays: 5,
            standingWaterPercent: 10,
            standingWaterEnum: 0,
            allowSWEmailSend: true,
            allowSWSMSSend: true,
            side1_Length2: 0,
            side2_Length2: 0,
            side1_Angle2: 0,
            side2_Angle2: 0,
            baseWidth: 0,
            sideType: SideType.ThreeSideSym,
        };

        this.model.sensorValues.clear();

        this.daysToGraph = 1;

        this.conditionSet = undefined;

        if (this.model.sensorValues !== null && this.model.sensorValues !== undefined) {
            this.model.sensorValues.clear();
        }
    };

    @action
    public load = async (deviceId: number): Promise<void> => {
        try {
            this.setIsLoading(true);
            this.scrollTop = 0;

            if (this.dateStart > this.dateEnd) {
                // Swap the dates
                const temp: Moment = this.dateStart;
                this.dateStart = this.dateEnd;
                this.dateEnd = temp;
            }

            const request: GetValuesByDateRequest = {
                id: deviceId,
                startDate: this.dateStart.toISOString(),
                endDate: this.dateEnd.toISOString(),
            };

            const apiResult = await this.Post<SensorValueDTO[]>(Server.Api.Installation.getReadingsForInstallationBetweenDates, request);

            if (apiResult.wasSuccessful) {
                runInAction(() => {
                    if (apiResult.payload !== null && apiResult.payload !== undefined) {
                        runInAction(() => {
                            const filtered = apiResult.payload.filter((a) => moment.utc(a.updated) >= this.dateStart && moment.utc(a.updated) <= this.dateEnd.endOf("day"));
                            this.model.fromDtoArr(filtered, this.statusData);
                        });
                    }
                });
            } else {
                // Error. What to do?
                this.setIsErrored(true);
                this.history.push("/");
            }
        } catch (exception) {
            // Error. What to do?
            this.setIsErrored(true);
            this.history.push("/");
        } finally {
            this.setIsLoading(false);
        }
        return;
    };

    @action
    public updateIsHidden = async (request: HideOnGraph, scrollTop: number): Promise<void> => {
        try {
            this.setIsLoading(true);

            request.startDate = this.dateStart.toISOString();
            request.endDate = this.dateEnd.toISOString();

            this.scrollTop = scrollTop;

            const apiResult = await this.Post<SensorValueDTO>(Server.Api.Installation.updateHideOnGraph, request);

            if (apiResult.wasSuccessful) {
                if (apiResult.payload !== null && apiResult.payload !== undefined) {
                    runInAction(() => {
                        this.model.updateSensorValueHideOnGraph(apiResult.payload);
                    });
                }
            } else {
                // Error. What to do?
                this.setIsErrored(true);
                this.history.push("/");
            }
        } catch (exception) {
            // Error. What to do?
            this.setIsErrored(true);
            this.history.push("/");
        } finally {
            this.setIsLoading(false);
        }
        return;
    };

    public downloadCSV = async (deviceId: number) => {
        const id: number = deviceId;
        const startDate: string = this.dateStart.toISOString();
        const endDate: string = this.dateEnd.toISOString();

        const request = {
            id: id,
            startDate: startDate,
            endDate: endDate,
        };

        const apiResult: ApiResult<FileDownloadResponseDTO> = await this.Post<FileDownloadResponseDTO>(Server.Api.Installation.downloadDataAsCsv, request);

        if (apiResult.wasSuccessful) {
            runInAction(() => {
                let fileName: string = "test.csv";

                const file: string = apiResult.payload.stringFile;
                const type: string = apiResult.payload.contentType;
                fileName = apiResult.payload.fileName;

                const url = window.URL.createObjectURL(new Blob([file], { type }));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", fileName);
                document.body.appendChild(link);
                link.click();
            });
        } else {
            // Error. What to do?
            this.setIsErrored(true);
            if (apiResult.errors !== null && apiResult.errors !== undefined && apiResult.errors.length > 0) {
                this.Errors = apiResult.errors[0].message;
                this.history.push("/");
            }
        }
    };
}
