import { InstallationListItem } from "Custom/Models/Domain";
import { isNullOrUndefined } from "Custom/Utils/utils";
import * as MobX from "mobx";
import { FieldType } from "../../../Core/Utils/Utils";
import { ViewModelBase } from "../../../Core/ViewModels/ViewModelBase";
import { AppUrls } from "../../Globals";
import { Property } from "../../Models/Domain/Properties";
import { StoresInstance } from "../../Stores";
import { PropertyAndRelatedModelDTO } from "./PropertyAndRelatedModel";
import { PropertyDocumentModel, PropertyDocumentModelDTO } from "Custom/Models/Domain/Properties/PropertyDocument";
import { PropertyDocumentInfoRequest } from "./Documents/PropertyDocumentInfoRequest";
import { DocumentCategory } from "Custom/Models/Domain/Projects";
import { PropertyDTO } from "../../Models/Domain/Properties/Property";
import { ApiResult } from "Core/Models";
import { action, computed, observable, runInAction } from "mobx";
import { UnitListItemModelDTO } from "../Installations/InstallationList/UnitListItemModel";
import { DeleteUserRequest } from "../User/DeleteUserRequest";

const domainStores = StoresInstance.domain;

//extend viewmodel base and passing your model as the generic type
export class PropertyViewModel extends ViewModelBase<Property> {
    private static _instance: PropertyViewModel;
    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    @observable public errorMessage: string = "";
    @observable public validMessage: string = "";

    public devices = observable<InstallationListItem>([]);
    public documents = observable<PropertyDocumentModel>([]);
    public documentCategories = observable<DocumentCategory>([]);

    constructor() {
        //Pass in a new instance of your model
        //By passing in true as the second parameter, we make this model undoable which means we can use save and reset options on the model
        //If you make a change to the model you need to persist it with a saveModel() call
        //If you make changes to your model you can revert it back by calling resetModel()
        super(new Property(), true);
        //EN - Haven't figured out how to make this call work from the base model yet
        //This is only needed if you make use of the validation decorators
        this.setDecorators(Property);
    }

    @computed public get getInstallations() {
        return this.devices.filter((a) => a.isDeleted === false);
    }

    public async loadAsync(id: string, projectId?: string) {
        const apiResult = await this.Post<PropertyAndRelatedModelDTO>(AppUrls.Server.Api.Property.getPropertyDetailsAndRelated, { id, projectId });

        if (apiResult.wasSuccessful) {
            MobX.runInAction(() => {
                this.model.fromDto(apiResult.payload.property);

                if (isNullOrUndefined(apiResult.payload.related) === false) {
                    this.devices.replace(
                        apiResult.payload.related.devices.map((device) => {
                            const newDevice = new InstallationListItem();
                            newDevice.fromDto(device);
                            return newDevice;
                        }),
                    );
                    this.documents.replace(
                        apiResult.payload.related.documents.map((doc) => {
                            const newDocument = new PropertyDocumentModel();
                            newDocument.fromDto(doc);
                            return newDocument;
                        }),
                    );
                    this.documentCategories.replace(
                        apiResult.payload.related.documentCategories.map((category) => {
                            return category;
                        }),
                    );
                } else {
                    this.devices.clear();
                    this.documents.clear();
                }
            });
        }

        return apiResult;
    }

    public upsert = async () => {
        const apiResult = await this.Post<PropertyDTO>(AppUrls.Server.Api.Property.upsertProperty, this.model);

        if (apiResult.wasSuccessful) {
            MobX.runInAction(() => {
                this.model.fromDto(apiResult.payload);
                this.saveModel();
            });
        }

        return apiResult;
    };

    // #region: Documents
    @computed
    public get getDocuments(): PropertyDocumentModel[] {
        const retVal: PropertyDocumentModel[] = this.documents.slice(0).filter((a) => a.isDeleted === false);
        return retVal;
    }

    @action
    public async upsertDocument(acceptedFiles: File[], documentCategoryTypeId: number): Promise<ApiResult<PropertyDocumentModelDTO>> {
        const request: PropertyDocumentInfoRequest = {
            propertyId: this.model.id!,
            propertyNumber: "0", //this.model.number,
            documentCategoryTypeId: documentCategoryTypeId as number,
        };

        let apiResult: ApiResult<PropertyDocumentModelDTO> = {
            wasSuccessful: false,
            errors: [],
            headers: "",
            payload: {
                id: undefined,
                rowVersion: "",
                propertyId: "",
                filename: "",
                createdDate: "",
                creator: "",
                createdByName: "",
                isDeleted: false,
                documentCategoryId: 0,
                documentId: "",
                url: "",
            },
        };

        for (let i: number = 0; i < acceptedFiles.length; i++) {
            const file: File = acceptedFiles[i];
            [i];
            const apiResult = await this.postFormWithFile<PropertyDocumentModelDTO>(AppUrls.Server.Api.PropertyDocument.uploadPropertyDocument, file, request);

            if (apiResult.wasSuccessful) {
                runInAction(() => {
                    const item = new PropertyDocumentModel();
                    item.fromDto(apiResult.payload);
                    this.documents.push(item);
                });
            }
        }
        return apiResult;
    }

    @action
    public downloadDocument = async (id: string) => {
        let doc: PropertyDocumentModel | undefined = this.documents.find((a) => a.id === id);
        if (doc !== undefined) {
            let fileUri = doc!.url;
            let link = document.createElement("a");
            link.href = fileUri;
            link.setAttribute("download", doc.filename);
            document.body.appendChild(link);
            link.click();
        }
    };

    @action public async deleteDocument(id: string): Promise<ApiResult<PropertyDocumentModelDTO>> {
        const request = {
            id: id,
            state: true,
        };
        const apiResult = await this.Post<PropertyDocumentModelDTO>(AppUrls.Server.Api.PropertyDocument.deletePropertyDocument, request);

        if (apiResult.wasSuccessful) {
            runInAction(() => {
                const item = new PropertyDocumentModel();
                item.fromDto(apiResult.payload);

                let existing: PropertyDocumentModel[] = this.documents.filter((a) => a.id !== item.id);

                if (existing !== undefined) {
                    existing.push(item);
                    this.documents.replace(existing);
                }
            });
        }
        return apiResult;
    }
    // #endregion: Documents

    @action public removeDeviceFromProperty = async (itemId: string): Promise<ApiResult<UnitListItemModelDTO[]>> => {
        const request: DeleteUserRequest = {
            id: itemId,
        };
        const apiResult = await this.Post<UnitListItemModelDTO[]>(AppUrls.Server.Api.Installation.removeDeviceFromProperty, request);

        if (apiResult.wasSuccessful) {
            runInAction(() => {
                this.removeDevice(itemId);
            });
        }
        return apiResult;
    };

    @action
    public removeDevice(device: string) {
        let devices: InstallationListItem[] = [];

        const deviceId: number = parseInt(device, 10);

        for (let i: number = 0; i < this.devices.length; i++) {
            if (this.devices[i].id !== deviceId) {
                devices.push(this.devices[i]);
            }
        }

        this.devices.replace(devices);
    }

    //This must be present in your viewmodel. Just return true if you dont need to validate anything.
    //keyof BlankModel & string lets you add type checking to the fieldName
    //I am using the validator package to make checking easier https://www.npmjs.com/package/validator
    public isFieldValid(fieldName: keyof FieldType<Property>): boolean {
        const { isValid, errorMessage } = this.validateDecorators(fieldName);

        //You need to this two properties after validation
        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
    // afterUpdate(fieldName: keyof FieldType<TestModel>, value: any): void {
    //     //Do something like call an api or whatever
    //     //Can use this instead of mobx reaction
    //     return;
    // }
    // beforeUpdate(fieldName: keyof FieldType<TestModel>, value: any): any {
    //     //Do something and return the new value if you want or just use it to perform another action
    //     if (value === "something" && fieldName === "firstName") {
    //         value = "boo";
    //     }
    //     return value;
    // }
}
