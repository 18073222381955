import styled from "styled-components";

export const KeyPerformanceDataBox: any = styled.div`
    height: 250px;
    display: flex;
    width: 100%;
    flex: 1 0 0;
    flex-direction: column;
`;

export const KeyPerformanceDataRow: any = styled.div`
    display: flex;
    width: 100%;
    flex: 1 0 0;
    flex-direction: row;
    justify-content: space-between;

    height: 125px;
    padding: 22px 15px;
`;

export const KeyPerformanceDataCell: any = styled.div`
    height: 125px;
    padding: 0 5px;
    max-width: 90px;

    .display {
        height: 80px;
        margin-left: auto;
        margin-right: auto;
    }

    .title {
        height: 25px;
        margin-left: auto;
        margin-right: auto;
        padding: 5px 0;

        margin-left: -5px;
        width: 90px;

        text-align: center;
        font: normal normal normal 12px/15px Mont;
        letter-spacing: 0px;
        color: #ffffff;
        opacity: 1;
    }
`;
