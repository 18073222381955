import styled from "styled-components";
import { Box } from "@material-ui/core";

export const CommandSectionBox: any = styled(Box)`
    display: flex;
    flex: 1 0 0;
    flex-direction: row;
    padding: 0 15px;
    width: 100%;
    min-width: 320px;
    min-height: 45px;
    flex: 1;
    align-content: flex-start;
    align-items: center;
    justify-content: center;

    .section-title {
        display: flex;
        flex: 1;
        max-width: 300px;
        padding: 15px 30px;
    }

    .success {
        color: ${(props) => props.theme.app.colors.success};
    }

    .error {
        color: ${(props) => props.theme.app.colors.red.dark};
    }

    .section-options {
        display: flex;
        flex: 1;
        height: 100%;
        padding: 15px;
        flex-direction: row;

        .section-options-option {
            display: flex;
            flex: 0;
            width: 325px;
            padding: 0 15px;

            input {
                padding: 0 15px;
                min-width: 60px !important;
                width: 60px !important;
            }

            .form-control {
                min-width: 300px !important;
                width: 300px !important;
            }
        }

        .section-options-value {
            display: flex;
            flex-direction: column;
            flex: 1;
            padding: 0 15px;

            input {
                width: 100%;
                padding: 0 15px;
            }

            .section-options-valueerror {
                color: ${(props) => props.theme.app.colors.red.dark};
            }

            .section-options-valueunsent {
                margin-top: 10px;
                color: ${(props) => props.theme.app.colors.success};
            }

            .form-control {
                max-width: 400px !important;
                width: 400px !important;
            }
            .MuiInputLabel-formControl {
                text-align: left;
                font: normal normal bold 14px "Mont-Bold";
                letter-spacing: 0px;
                color: ${(props) => props.theme.app.colors.black.light};
                opacity: 1;
                height: 19px;
                margin-bottom: 5px;
            }
            input {
                padding-left: 10px;
                padding-right: 10px;
                background-color: #ffffff !important;
                border: 1px solid ${(props) => props.theme.app.colors.grey.background};
                border-radius: 3px;
                opacity: 1;
                padding: 10px;
                text-align: left;
                font: normal normal normal 12px/26px "Mont-Regular";
                letter-spacing: 0px;
                color: #757474 !important;
                opacity: 1;
                font-weight: 300;
            }
        }
    }
`;

export const SectionSend: any = styled(Box)`
    display: flex;
    flex: 0;
    padding: 15px 30px;
    width: 200px;
`;
