import { Centered } from "./../../../Core/Components/Centered";
import styled from "styled-components";
import { Box } from "@material-ui/core";

export const InstallationViewBox: any = styled(Box)`
    div.tab-back {
        width: 100%;
        padding: 15px;
        border-bottom: 1px solid #d4d4d4;
        font-size: 10px;
        display: flex;

        .back-icon {
            margin-left: -10px;
            color: ${(props) => props.theme.app.colors.red.dark};
            font-size: 18px;
            font-weight: 700;
            font-family: "Mont-Bold", "Montserrat", "Roboto Condensed", "Helvetica", "Arial", "sans-serif";
            display: flex;
            margin-bottom: 1px;
        }
        .back-text {
            margin-top: 8px;
            font-weight: 400;
        }
    }

    div.content {
        background-image: linear-gradient(${(props) => props.theme.app.colors.tabContent.light}, ${(props) => props.theme.app.colors.tabContent.dark});
        color: ${(props) => props.theme.app.colors.tabContent.main};
    }
`;

export const InstallationModalViewBox: any = styled(Box)`
    overflow: hidden;
    overflow-y: hidden;
`;

export const ImageModalViewBox: any = styled(InstallationModalViewBox)`
    margin-top: -16px;
    img {
        z-index: 15;
        cursor: default;

        .device-image {
            width: 100%;
            max-width: 800px;
            object-fit: cover;
            margin-bottom: -5px;
        }
    }

    #main-img {
        display: block;
        filter: grayscale(0);
    }

    #zoom-img {
        pointer-events: none;
        position: relative;
        top: 50%;
        left: 50%;
    }

    #zoom {
        position: absolute;
        width: 150px;
        height: 150px;
        box-shadow: 0 0 0 2px rgba(242, 157, 41, 0.5), 5px 5px 10px 5px rgba(0, 0, 0, 0.2);
        border-radius: 50%;
        top: 0;
        left: 0;
        overflow: hidden;
        pointer-events: none;
        visibility: hidden;
        opacity: 0;
    }

    img:hover {
        cursor: pointer;
        /*  transform: scale(1.5); */
    }

    .rotateimg180 {
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        transform: rotate(180deg);
    }
`;

import { BaseModalDialogPaper } from "Custom/Components/Modal/BaseModal.styles";

export const InstallationModalDialogPaper = styled(BaseModalDialogPaper)`
    width: 95%;
    min-width: 300px;
    max-width: 468px;
`;

export const NoteModalViewBox: any = styled(InstallationModalViewBox)`
    width: 100%;
    max-width: 658px;
    min-height: 250px;

    .note-textarea {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
        min-height: 200px;
        max-height: 40vh;
        border-radius: 0;
        outline: none;
        padding: 5px;
        font-size: 1.4359em;
        font-family: "Mont-Regular", "Montserrat", "Roboto Condensed", "Helvetica", "Arial", "sans-serif";
        overflow-y: auto !important;
        margin-top: 9px;
        color: #000000bf;

        :focus {
            border-color: black;
        }
    }

    .error {
        color: ${(props) => props.theme.app.colors.red.dark};
    }
`;

export const TabBox: any = styled(Box)`
    background-color: white;
`;

export const GraphWrapper: any = styled.div`
    display: flex;
    flex: 1 0 0;
    width: 100%;
    flex-direction: column;
    /* background-color: pink; */
`;

export interface GraphHeaderProps {
    marginLeft: string;
}

export const GraphHeader: any = styled.div<GraphHeaderProps>`
    margin-left: ${(props) => props.marginLeft};
    display: flex;
    flex: 1 0 0;
    width: 100%;
    flex-direction: row;
    padding: 0 65px;

    /*    background-color: pink; */

    &.attributes {
        justify-content: center;
        padding: 0;
    }

    .slider-text {
        padding-right: 15px;
        line-height: 28px;

        &.lhs15 {
            padding-left: 15px;
        }
    }
`;
