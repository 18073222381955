import styled from "styled-components";
import { TabCommonBox } from "../TabCommonStyle.style";

export const MetricsBox: any = styled(TabCommonBox)`
    padding: 5px 15px;
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    width: 100%;
    background-color: #585858;
    color: white;
`;

export interface IConditionSetMetricsProps {
    height: string;
    maxwidth?: string;
    paddingTop: string;
}

export const ConditionSetMetricsInnerBox: any = styled.div<IConditionSetMetricsProps>`
    min-height: ${(props) => props.height};
    max-width: ${(props) => props.maxwidth} !== undefined ? ${(props) => props.maxwidth} : 100%;
    padding-top: ${(props) => props.paddingTop};
    position: relative;
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
`;

export const ConditionSetMetricsWrapper: any = styled.div`
    position: relative;
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
`;

export const ConditionSetMetricsBox: any = styled.div`
    display: flex;
    flex: 1 0 auto;
    flex-direction: row;
    width: 100%;
    max-width: 100%;
    justify-content: space-between;
    padding-bottom: 15px;
    @media screen and (min-width: 1600px) {
        flex-direction: row;
    }

    @media screen and (max-width: 1600px) {
        flex-direction: column;
    }
`;

export const AnalyticsBox: any = styled(TabCommonBox)`
    padding: 5px 15px;
    display: flex;
    flex: 1 0 0;
    flex-direction: column;
    color: white;
`;

export const PieChartWrapper: any = styled(TabCommonBox)`
    display: flex;
    flex: 1 0 0;
    flex-direction: row;
    @media screen and (min-width: 1600px) {
        max-width: 50%;
    }

    @media screen and (max-width: 1600px) {
        max-width: 100%;
        margin-bottom: 15px;
    }

    justify-content: center;
    padding-bottom: 15px;
    color: white;
`;
