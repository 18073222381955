import * as React from "react";
import { DialogActions, DialogContent, DialogTitle, PaperProps } from "@material-ui/core";
import { BaseModalDialogPaper } from "./BaseModal.styles";
import { Dialog } from "@material-ui/core";

interface IProps {
    open?: boolean;
    onClose(): void;
    title: string;
    actions?: React.ReactChild;
    PaperComponent?: React.ComponentType<PaperProps>;
}

export const BaseModal: React.FC<IProps> = ({ open, onClose, title, children, actions, PaperComponent }) => (
    <Dialog PaperComponent={PaperComponent || BaseModalDialogPaper} open={open === true} onClose={onClose} disableAutoFocus disableEnforceFocus disableRestoreFocus>
        <DialogTitle className="roofcare-dialog-title">
            <span>{title}</span>
            <span role="button" onClick={onClose} className="modal-close">
                🗙
            </span>
        </DialogTitle>
        <DialogContent>{children}</DialogContent>
        {actions !== undefined && (
            <>
                <hr />
                <DialogActions>{actions}</DialogActions>
            </>
        )}
    </Dialog>
);
