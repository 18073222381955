import { KeyValuePair } from "Core/Models/KeyValuePair";

export enum SideType {
    Unknown = 0,
    ThreeSideSym = 300,
    ThreeSideL = 301,
    ThreeSideR = 302,
    // No where in DB for this at the moment
    // FourSideSym = 400,
    // FourSideL = 401,
    // FourSidesR = 402,
}

export class SideTypeHelpers {
    public static getText = (drainType: SideType) => {
        switch (drainType) {
            /*             case SideType.FourSideL: {
                return "4 Side Left";
            }
            case SideType.FourSidesR: {
                return "4 Side Right";
            }
            case SideType.FourSideSym: {
                return "4 Side Sym";
            } */
            case SideType.ThreeSideL: {
                return "3 Side Left";
            }
            case SideType.ThreeSideR: {
                return "3 Side Right";
            }
            case SideType.ThreeSideSym:

            default: {
                return "3 Side Sym";
            }
        }
    };

    //public static getOptions = (ignoreUnknown: boolean = false) => {
    public static getOptions = (): KeyValuePair[] => {
        const options: KeyValuePair[] = [];

        for (const key in SideType) {
            const keyAsInt = parseInt(key);

            if (isNaN(keyAsInt) === false) {
                const item = keyAsInt as SideType;

                if (/* ignoreUnknown === false ||  */ item !== SideType.Unknown) {
                    options.push({
                        key: keyAsInt.toString(),
                        value: SideTypeHelpers.getText(item),
                    });
                }
            }
        }

        return options;
    };

    /*     public static getImageOptions = (): KeyValuePair[] => {
        const options: KeyValuePair[] = [];

        for (const key in SideType) {
            const keyAsInt = parseInt(key);

            if (isNaN(keyAsInt) === false) {
                const item = keyAsInt as SideType;

                //if (ignoreUnknown === false || item !== SensorType.Unknown) {
                options.push({
                    key: keyAsInt.toString(),
                    text: SideTypeHelpers.getImage(item.toString()) as any,
                });
                //}
            }
        }

        return options;
    }; */
}
