import { action, observable } from "mobx";
import { ModelBase } from "Core/Models/ModelBase";
import { IsNotEmpty } from "class-validator";
import { InstallationMapModelDTO, ContractorMinDTO, ClientMinDTO } from "Custom/Models/Domain";
import { EstateCommonRowDTO } from "Custom/Models/Domain/EstateCommonRowDTO";
import { DeviceStatusModelDTO } from "Custom/Models/Domain/Installations/DeviceStatusModel";
import { DeviceStatusWithCommandModelDTO } from "Custom/Models/Domain/Installations/DeviceStatusWithCommandModel";

//Base class expects the model type and the DTO model type
//If you are not using a DTO just pass in undefined as the DTO
export class ClientDashboardModel extends ModelBase<ClientDashboardModel, ClientDashboardModelDTO> {
    @observable public clients: EstateCommonRowDTO[] = [];
    @observable public units: InstallationMapModelDTO[] = [];

    @observable public clientsList: ClientMinDTO[] = [];
    @observable public contractorsList: ContractorMinDTO[] = [];

    @observable public deviceStatuses: DeviceStatusModelDTO[] = [];
    @observable public deviceStatusesWithCommandIdx: DeviceStatusWithCommandModelDTO[] = [];

    //fromDto is required but you can leave it blank
    fromDto(model: ClientDashboardModelDTO): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
    }

    @action
    public setClientsList(clientsList: ClientMinDTO[]) {
        this.clientsList = [];
        this.clientsList = clientsList;
    }

    @action
    public setContractorsList(contractorsList: ContractorMinDTO[]) {
        this.contractorsList = [];
        this.contractorsList = contractorsList;
    }

    /*     @action
    public setDevicesStatus(deviceStatuses: DeviceStatusModelDTO[]) {
        this.deviceStatuses = [];
        this.deviceStatuses = deviceStatuses;
    } */

    @action
    public setDevicesStatus = (clients: EstateCommonRowDTO[], deviceStatuses: DeviceStatusModelDTO[]) => {
        this.deviceStatuses = [];
        /// Remove the duplicates
        for (let i: number = 0; i < clients.length; i++) {
            let deviceStatus: DeviceStatusModelDTO | undefined = deviceStatuses.find((a) => a.id === clients[i].status.id);

            if (deviceStatus !== undefined) {
                let temp: DeviceStatusModelDTO | undefined = this.deviceStatuses.find((a) => a.id === deviceStatus!.id);
                if (temp === undefined) {
                    this.deviceStatuses.push(deviceStatus);
                }
            }
        }
    };

    @action
    public setDevicesWithCommand(deviceStatusesWithCommandIdx: DeviceStatusWithCommandModelDTO[]) {
        this.deviceStatusesWithCommandIdx = [];
        this.deviceStatusesWithCommandIdx = deviceStatusesWithCommandIdx;
    }

    //toDto is required but you can leave it blank
    toDto(model: ClientDashboardModel): void {}

    @action public clear() {
        this.clients = [];
        this.units = [];
        this.clientsList = [];
        this.contractorsList = [];
        this.deviceStatuses = [];
        this.deviceStatusesWithCommandIdx = [];
    }
}

export type ClientDashboardModelDTO = {
    clients: EstateCommonRowDTO[];
    units: InstallationMapModelDTO[];
    clientsList: ClientMinDTO[];
    contractorsList: ContractorMinDTO[];
    deviceStatuses: DeviceStatusModelDTO[];
    deviceStatusesWithCommandIdx: DeviceStatusWithCommandModelDTO[];
};
