import styled, { useTheme } from "styled-components";

export const InstallationListContainer = styled.div`
    padding: 25px 0px;
    background-color: ${(props) => props.theme.app.colors.tabContent.light};
    div.add-wrapper {
        display: flex;
        justify-content: flex-end;
        cursor: pointer;
        margin-top: -10px;
        margin-bottom: 15px;
        padding-right: 30px;
        div.add {
            display: flex;
            align-items: center;

            p {
                padding-left: 10px;
            }
        }
    }

    p.no-installations {
        color: white;
        padding-left: 15px;
    }

    p.no-installations span {
        color: ${(props) => props.theme.app.colors.blue.button};
        cursor: pointer;

        :not(:hover) {
            text-decoration: underline;
        }
    }

    div.device-header {
        background-color: ${(props) => props.theme.app.colors.tabHeader.main};
        box-shadow: inset 0px 1px 1px ${(props) => props.theme.app.colors.tabHeader.accent};
        color: ${(props) => props.theme.palette.common.white};
        display: flex;
        flex-direction: row;
        align-items: center;
        flex: 1 0 1;
        width: 100%;
        padding: 15px 30px;
    }

    div.devices-list {
        // padding-top: 25px;
        // border-bottom: solid 1px #84888b;
        cursor: default;

        > div.device {
            border-top: solid 1px #84888b;
            width: 100%;

            > div.device-row {
                background-color: ${(props) => props.theme.app.colors.tabContent.light};
                border-bottom: 1px solid ${(props) => props.theme.app.colors.tabContent.accent};
                color: white;
                display: flex;
                align-items: center;
                flex: 1 0 1;

                padding: 15px 0px;
                margin: 0 30px;
            }

            > div.device-header,
            div.device-row {
                display: flex;
                align-items: center;

                > div {
                    font-size: 12px;
                }

                > div.device-name {
                    width: 245px;
                }

                > div.device-info-group {
                    max-width: 540px;
                    flex-grow: 2;
                    display: grid;
                    align-items: center;
                    grid-template-columns: repeat(3, 1fr);
                }
            }

            .status-readings {
                // padding: 10px 0;
                display: flex;
                flex: 1 1 1;
                align-items: center;
                justify-content: left;
                width: 100%;
                //                background-color: green;

                .device-status {
                    display: flex;
                    flex: 1 0 1;
                    width: 74px;
                    padding: 5px 0;
                    border-radius: 2px;
                    align-content: middle;
                    position: relative;

                    &.device_unknown {
                        color: white;
                        background-color: ${(props) => props.theme.app.colors.deviceWarning.unknown.main};
                        box-shadow: inset 0px 3px 6px ${(props) => props.theme.app.colors.deviceWarning.unknown.accent};
                    }

                    &.device_amberwarning {
                        color: white;
                        background-color: ${(props) => props.theme.app.colors.deviceWarning.amber.main};
                        box-shadow: inset 0px 3px 6px ${(props) => props.theme.app.colors.deviceWarning.amber.accent};
                    }

                    &.device_redwarning {
                        color: white;
                        background-color: ${(props) => props.theme.app.colors.deviceWarning.red.main};
                        box-shadow: inset 0px 3px 6px ${(props) => props.theme.app.colors.deviceWarning.red.accent};
                    }

                    &.device_floodwarning {
                        color: white;
                        background-color: ${(props) => props.theme.app.colors.deviceWarning.flood.main};
                        box-shadow: inset 0px 3px 6px ${(props) => props.theme.app.colors.deviceWarning.flood.accent};
                    }

                    &.device_greenwarning {
                        color: white;
                        background-color: ${(props) => props.theme.app.colors.deviceWarning.green.main};
                        box-shadow: inset 0px 3px 6px ${(props) => props.theme.app.colors.deviceWarning.green.accent};
                    }

                    > div {
                        margin-left: auto;
                        margin-right: auto;
                        position: relative;
                        font-size: 10px;
                        text-transform: uppercase;
                    }
                }

                .device-temperature,
                .device-created,
                .device-lastreading {
                    display: flex;
                    justify-content: left;
                    //flex: 1 0 1;
                    max-width: 320px;
                    padding: 10px 15px;
                }

                .device-temperature {
                    min-width: 100px;
                }

                .device-lastreading {
                    min-width: 250px;
                }

                .device-editinstallation {
                    display: flex;
                    flex-grow: 1;
                    padding: 10px 15px;
                    justify-content: flex-end;
                    // background-color: pink;
                }

                .device-removeProperty {
                    display: flex;
                    flex-grow: 1;
                    padding: 10px 15px;
                    justify-content: center;
                    //
                    background-color: pink;

                    .MuiBox-root,
                    img {
                        height: 24px;
                        max-height: 24px !important;
                    }
                }
            }
            .devicelist-title {
                font-weight: 700;
                font-family: "Mont-Bold", "Montserrat", "Roboto Condensed", "Helvetica", "Arial", "sans-serif";
                padding-right: 10px;
            }
        }
    }

    div.devices-list:hover {
        cursor: pointer;
    }

    .device-removeProperty {
        display: flex;
        flex-grow: 1;
        padding: 10px 15px;
        justify-content: center;
        // background-color: pink;

        .MuiBox-root,
        img {
            height: 24px;
            max-height: 24px !important;
        }
    }
`;
