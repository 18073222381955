import styled from "styled-components";

export const Footer = styled.div`
    display: flex;
    height: 35px;
    width: 100%;
    flex: 1 0 0;
    justify-content: flex-end;

    padding: 10px 30px;
    background-color: #373a40;
    color: ${(props) => props.theme.app.colors.white.main};

    a {
        text-decoration: underline;
        cursor: default;
        font-weight: 700;
        font-family: "Mont-Bold", "Montserrat", "Roboto Condensed", "Helvetica", "Arial", "sans-serif";
    }

    a:hover {
        cursor: pointer;
    }
`;
