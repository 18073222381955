import * as Utils from "Core/Utils/Utils";
// Libs
// App
import { BaseStore } from "./BaseStore";
import { action, computed, observable } from "mobx";
import { Stores } from "../Stores";
import { InitialState } from "Custom/Models";
import { AccountStatus } from "Core/Models";

import * as ArrayUtils from "Custom/Utils/array";
import { Client } from "Custom/Globals/AppUrls";

export class AccountStore extends BaseStore {
    @observable public IsLoggedIn: boolean = false;
    @observable public UserName: string = "";
    @observable public DisplayName: string = "";
    @observable public GivenName: string = "";
    @observable public UserRoles: string[] = [];
    @observable public UseAdminGrids: boolean = false;
    @observable public UseAdminControlsLight: boolean = false;

    private jwt: string | null = null;

    public constructor() {
        super();
    }

    public init(stores: Stores, initialState: InitialState) {
        this.UseAdminGrids = initialState.appSettings.useAdminGrids === "true";
        this.UseAdminControlsLight = initialState.appSettings.useAdminControlsLight === "true";
        this.getLoginState(initialState.accountStatus);
    }

    @action
    public setIsLoggedIn(state: boolean) {
        this.IsLoggedIn = state;
    }

    public isInRole = (role: string): boolean => {
        if (this.UserRoles && this.UserRoles.length > 0) {
            return this.UserRoles.includes(role);
        }

        return false;
    };

    public containsRoles = (lookingFor: string[]): boolean => {
        let retVal: boolean = false;

        if (this.UserRoles && this.UserRoles.length > 0) {
            const result: string[] = this.UserRoles.filter((role: string) => ArrayUtils.contains(lookingFor, role));

            if (result.length > 0) {
                retVal = true;
            }
        }

        return retVal;
    };

    @action
    public Logout = (redirect: boolean = true): void => {
        sessionStorage.removeItem(".auth");
        document.cookie = ".refreshtoken= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
        this.IsLoggedIn = false;
        this.UserName = "";
        this.DisplayName = "";
        this.GivenName = "";
        this.UserRoles = [];

        if (redirect) {
            window.location.href = Client.Account.Login;
        }
    };

    public getJwt() {
        return this.jwt;
    }

    @action
    public getLoginState = (apiResult: AccountStatus) => {
        let jwt = apiResult?.jwt ?? "";
        if (!jwt || jwt.length === 0) {
            this.jwt = sessionStorage.getItem(".auth") as string;
        } else {
            this.jwt = jwt;
        }

        if (this.jwt && this.jwt !== "undefined" && this.jwt !== "null") {
            const data = Utils.parseJwt(this.jwt);
            sessionStorage.setItem(".auth", this.jwt);
            if (data === "") {
                return;
            }

            this.IsLoggedIn = true;
            this.UserName = data["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"] as string;
            this.DisplayName = data["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"] as string;
            this.GivenName = data["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname"] as string;

            let roles: string[] = [];
            if (Array.isArray(data["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"]) === true) {
                // just use the array.
                roles = data["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"] as string[];
            } else {
                // create an array.
                const temp: string = data["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"] as string;
                roles.push(temp);
            }

            this.UserRoles = roles;
        }
    };

    @computed get GetUseAdminGrids(): boolean {
        return this.UseAdminGrids;
    }

    @computed get GetUseAdminControlsLight(): boolean {
        return this.UseAdminControlsLight;
    }
}
