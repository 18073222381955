import React from "react";

/* export const breadcrumbNameMap: any = {
    "/": "Home",
    "/client": "Client",
    "/clientview": "ClientView",
    "/contractorview": "ContractorView",
    "/project": "Project",
    "/user": "Project",
    "/admin": "Admin",
}; */

export interface IBreadcrumbContextValues {
    clientName?: string;
    contractorName?: string;
    installationName?: string;
    projectName?: string;
    propertyName?: string;
    userName?: string;
}

export interface IBreadcrumbContextIdValues {
    clientId?: string;
    contractorId?: string;
    installationId?: number;
    projectId?: string;
    propertyId?: string;
    userId?: string;
}

export interface IBreadcrumbsContext extends IBreadcrumbContextValues {
    setContext(values: Partial<IBreadcrumbContextValues>): void;
}

export const BreadcrumbsContext = React.createContext<IBreadcrumbsContext>({ setContext: () => {} });
