import { Button, Card, CardHeader, CardMedia } from "@material-ui/core";
import PhotoOutlinedIcon from "@material-ui/icons/PhotoOutlined";
import PhotoRoundedIcon from "@material-ui/icons/PhotoRounded";
import React from "react";
import { useObserver } from "mobx-react-lite";
import styled from "styled-components";
import BlankImage from "Custom/Content/BlankCameraImage.png";
import { InstallationImageViewModel2 } from "./InstallationImageViewModel";

interface IProps {
    viewModel: InstallationImageViewModel2;
    className?: string;
}

export const InstallationImagePanelBase: React.FC<IProps> = (props) => {
    const onFlipImageClick = () => {
        props.viewModel.flipImage();
    };

    const onImageClick = () => {
        // If no image, i.e. no device id, then don't show the modal
        if (props.viewModel.id > 0) {
            props.viewModel.parentViewModel.setActiveInstallationViewModel(props.viewModel);
        }
    };

    return useObserver(() => (
        <Card className={props.className}>
            <CardHeader
                title={`Time: ${props.viewModel.date} ${props.viewModel.time}`}
                action={
                    props.viewModel.id !== -1 && (
                        <Button onClick={onFlipImageClick} startIcon={props.viewModel.flipped ? <PhotoRoundedIcon /> : <PhotoOutlinedIcon />}>
                            Flip image {/* {props.viewModel.view} */}
                        </Button>
                    )
                }
            />
            <CardMedia
                className={props.viewModel.flipped ? "rotate180" : ""}
                image={props.viewModel.url === undefined || props.viewModel.url.length > 0 ? props.viewModel.url : BlankImage}
                onClick={onImageClick}
            />
        </Card>
    ));
};

export const InstallationImagePanel = styled(InstallationImagePanelBase)`
    && {
        background-color: transparent;
        border-radius: 4px;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.4);
        margin: 15px;
        width: 300px;

        & .MuiCardHeader-root {
            background-color: rgb(56, 103, 204, 0.43);
            box-shadow: inset 0px 1px 1px rgba(252, 153, 153, 0.2);
            min-height: 46px;

            & > .MuiCardHeader-content {
                > span {
                    color: white;
                    font-size: 12px;
                }
            }

            & > .MuiCardHeader-action {
                margin-bottom: -8px;

                & > .MuiButton-root {
                    color: white;
                    font-size: 12px;
                }
            }
        }

        & .MuiCardMedia-root {
            height: 260px;

            &:hover {
                cursor: pointer;
            }
        }

        .rotate180 {
            -webkit-transform: rotate(180deg);
            -moz-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
            -o-transform: rotate(180deg);
            transform: rotate(180deg);
        }
    }
`;
