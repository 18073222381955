import { ViewModelBase } from "Core/ViewModels/ViewModelBase";
import { ColourPickerModel } from "./ColourPickerModel";
import { FieldType } from "Core/Utils/Utils";
import { action, computed, observable } from "mobx";

//extend viewmodel base and passing your model as the generic type
export class ColourPickerViewModel extends ViewModelBase<ColourPickerModel> {
    @observable public errorMessage: string = "";

    // CMR really no point putting this into a model,  I could have done this totally in the modal using state.
    @observable public backgroundColour: string = "#07703B";

    @observable public textColour: string = "#000000";

    @observable public text: string = "";

    @action public setBackgroundColour(hex: string) {
        this.backgroundColour = hex;
    }

    @computed public get getBackgroundColour(): string {
        return this.backgroundColour;
    }

    @computed public get getTextColour(): string {
        return this.textColour;
    }

    @computed public get getTextColourId(): number {
        return this.textColour === "#000000" ? 0 : 1;
    }

    @action public setTextColour(value: number) {
        this.textColour = value === 0 ? "#000000" : "#FFFFFF";
    }

    constructor(backgroundColour: string, textColour: string) {
        //Pass in a new instance of your model
        //By passing in true as the second parameter, we make this model undoable which means we can use save and reset options on the model
        //If you make a change to the model you need to persist it with a saveModel() call
        //If you make changes to your model you can revert it back by calling resetModel()
        super(new ColourPickerModel(), false);
        //EN - Haven't figured out how to make this call work from the base model yet
        //This is only needed if you make use of the validation decorators
        this.setDecorators(ColourPickerModel);

        this.backgroundColour = backgroundColour;
        this.textColour = textColour;
    }

    @action
    public updatePicker(backgroundColour: string, text: string, textColour: string) {
        this.backgroundColour = backgroundColour;
        this.textColour = textColour;
        this.text = text;
    }

    //isValid will check all fields to make sure they are in a valid state.
    public doSubmit = async (e: any) => {
        e.preventDefault();

        if (this.isModelValid()) {
            //Do stuff here
            this.errorMessage = "Form is valid";
        } else {
            this.errorMessage = "Form is not valid";
        }
    };

    //This must be present in your viewmodel. Just return true if you dont need to validate anything.
    //keyof BlankModel & string lets you add type checking to the fieldName
    //I am using the validator package to make checking easier https://www.npmjs.com/package/validator
    public isFieldValid(fieldName: keyof FieldType<ColourPickerModel>): boolean {
        const { isValid, errorMessage } = this.validateDecorators(fieldName);

        //You need to this two properties after validation
        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
