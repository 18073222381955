import { FormControlLabel, FormHelperText, Grid, Input, Radio, RadioGroup, Select } from "@material-ui/core";
import { DrainageType } from "Custom/Models/Domain";
import { useObserver } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { ConditionSectionBox, DeleteCircle } from "./Condition.style";
import { ConditionItemViewModel } from "./ConditionItemViewModel";
import { makeStyles } from "@material-ui/core/styles";
import { ColourPicker } from "../Common/ColourPicker";
interface IConditionItemProps {
    localViewModel: ConditionItemViewModel;
    drainageType?: DrainageType;
    onDelete: () => void;
}

export const ConditionItem: React.FunctionComponent<IConditionItemProps> = ({ localViewModel, onDelete, drainageType }) => {
    const [viewModel] = useState(localViewModel);
    const [firstTime, setFirstTime] = React.useState(true);
    const [openCP, setOpenCP] = React.useState(false);

    const useStyles = makeStyles((theme) => ({
        colourBlockWrapper: {
            //paddingRight: "15px",
        },
        colourBlock: {
            verticalAlign: "middle",
            backgroundColor: viewModel.getBackgroundColour,
            color: viewModel.getTextColour,
            padding: "5px 16px",
            fontSize: "10px",
            textAlign: "center",
            marginTop: "5px",
            cursor: "default",
            "&:hover": {
                cursor: "pointer",
            },
        },
    }));

    const classes = useStyles();

    //useEffect below only gets run on initial render
    useEffect(() => {
        // *optional* passing the router into the viewmodel so we can navigate from there
        // console.log("First render");

        // This gets run when the page is exited
        return () => {
            // console.log("Unmounting");
        };
    }, []);

    const onNameChange = (e: any) => {
        viewModel.setValue("name", e.target.value);
    };

    const onValueChange = (e: any) => {
        // Stop the control passing us a number as text
        let value: number = e.target.value;

        // BUT that filters out '-' which comes through as '' so
        // check for both and allow through!
        if (e.target.value === "-" || e.target.value === "") {
            value = e.target.value;
        } else {
            value = Number(e.target.value);
        }

        viewModel.setValue("value", value);
    };

    const onUnitChange = (e: any) => {
        viewModel.setValue("units", parseInt((e.target as HTMLInputElement).value));
    };

    const onTypeChange = (e: any) => {
        viewModel.setValue("type", parseInt((e.target as HTMLInputElement).value));
    };

    const isInError = (fieldName: string) => {
        let isValid = viewModel.getValid(fieldName);
        return !isValid;
    };

    const handleDelete = () => {
        viewModel.setValue("isDeleted", true);
        onDelete();
    };

    const handleFocus = (event: any) => event.target.select();

    const onUpdateColours = (backgroundColour: string, textColour: string) => {
        viewModel.setValue("statusColour", backgroundColour);
        viewModel.setValue("statusTextColour", textColour);
        setOpenCP(false);
    };

    const onCloseColours = () => {
        setOpenCP(false);
    };

    const openColourPicker = (e: any) => {
        setOpenCP(true);
    };

    function renderPage() {
        return (
            <>
                <ColourPicker
                    text={viewModel.getNameForColourPicker}
                    openPicker={openCP}
                    backgroundColour={viewModel.getBackgroundColour}
                    textColour={viewModel.getTextColour}
                    onUpdate={onUpdateColours}
                    onClose={onCloseColours}
                />
                <Grid container direction="column" spacing={1} style={{ padding: "10px 0" }}>
                    <Grid container direction="row" spacing={1}>
                        <Grid item xs={2} className="column-title">
                            <Input className="form-control" type="text" value={viewModel.getValue("name")} onChange={onNameChange} inputProps={{ maxLength: 15 }} />
                            {/* !firstTime && */ isInError("name") && <FormHelperText style={{ color: "red" }}>{viewModel.getError("name")}</FormHelperText>}
                        </Grid>
                        <Grid item xs={1} className="column-title">
                            <div className={classes.colourBlockWrapper}>
                                <div onClick={openColourPicker} className={classes.colourBlock}>
                                    {viewModel.getNameForColourPicker}
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={3} className="column-title">
                            <ConditionSectionBox>
                                <div className="type">
                                    <Select className="form-control" value={viewModel.getValue("type")} onChange={onTypeChange}>
                                        <option value={0}>H</option>
                                        <option value={1}>FB</option>
                                        {(drainageType === undefined || drainageType === null || drainageType >= DrainageType.SingleSiphonic) && <option value={2}>P1</option>}
                                        {(drainageType === undefined || drainageType === null || drainageType === DrainageType.DualSiphonic) && <option value={3}>P2</option>}
                                    </Select>
                                    {/* !firstTime &&  */ isInError("type") && <FormHelperText style={{ color: "red" }}>{viewModel.getError("type")}</FormHelperText>}
                                </div>
                                <div className="value">
                                    <Input
                                        className="form-control"
                                        type="number"
                                        value={viewModel.getValue("value")}
                                        onChange={onValueChange}
                                        onFocus={handleFocus}
                                        inputProps={{
                                            placeholder: viewModel.getValue("units") === 0 ? "Range 0-1000" : "-100-100",
                                            maxLength: 4,
                                            min: viewModel.getValue("units") === 0 ? -1000 : -100,
                                            max: viewModel.getValue("units") === 0 ? 1000 : 100,
                                        }}
                                    />
                                    {/* !firstTime &&  */ isInError("value") && <FormHelperText style={{ color: "red" }}>{viewModel.getError("value")}</FormHelperText>}
                                </div>
                                <div className="units">
                                    <RadioGroup
                                        row
                                        aria-label="units"
                                        defaultValue={viewModel.getValue("units")}
                                        value={viewModel.getValue("units")}
                                        name="radio-buttons-group"
                                        onChange={onUnitChange}
                                    >
                                        <FormControlLabel value={0} control={<Radio color="default" />} label="mm" />
                                        <FormControlLabel value={1} control={<Radio color="default" />} label="%" />
                                    </RadioGroup>
                                </div>
                            </ConditionSectionBox>
                        </Grid>
                        <Grid item xs={1} className="column-title">
                            <DeleteCircle onClick={handleDelete} style={{ marginTop: "4px" }}>
                                X
                            </DeleteCircle>
                        </Grid>
                    </Grid>
                </Grid>
            </>
        );
    }

    return useObserver(() => renderPage());
};
