export const Client = {
    Root: "/",
    SessionExpired: "/sessionexpired",
    Error: "/error",
    NotFound: "/notfound",
    Account: {
        Login: "/login",
        Logout: "/logout",
        ForgotPassword: "/forgotPassword",
        ForgotPasswordSuccess: "/forgotPassword-success",
        ResetPassword: "/resetpassword",
        Register: "/register",
    },
    Main: {
        Action: { Root: "/action", ActionAddEditDetail: "/action/edit/:id" },
        Admin: {
            Root: "/admin",
            Condition: {
                ConditionAddEditDetail: "/admin/condition/edit/:id",
            },
            AlertAction: {
                AlertActionAddEditDetail: "/admin/alertaction/edit/:id",
            },
            UnitBehaviour: {
                UnitBehaviourAddEditDetail: "/admin/unitbehaviour/edit/:id",
            },
            DeviceUnitStatus: {
                DeviceUnitStatusAddEditDetail: "/admin/deviceunitstatus/edit/:id",
            },
            ProjectStatus: { ProjectStatusAddEditDetail: "/admin/projstatus/edit/:id" },
            ProjectPaymentStatus: { ProjectPaymentStatusAddEditDetail: "/admin/projpaystatus/edit/:id" },
            UnitFirmware: {
                UnitFirmwareAddEditDetail: "/admin/unitfirmware/edit/:id",
            },
            UnitTestGrade: {
                UnitTestGradeAddEditDetail: "/admin/unittestgrade/edit/:id",
            },
            UnitVersion: {
                UnitVersionAddEditDetail: "/admin/unitversion/edit/:id",
            },
        },
        Client: {
            Root: "/client",
            ClientsDetail: "/client/:clientId",
            ClientAddEditDetail: "/client/edit/:clientId",
            Project: {
                ClientsDetailProject: "/client/:clientId/project/:projectId",
                PropertyDetail: "/client/:clientId/project/:projectId/property/:propertyId",
                PropertyInstallation: "/client/:clientId/project/:projectId/property/:propertyId/install/:installId",
            },
            Property: {
                ClientsDetailProperty: "/client/:clientId/property/:propertyId",
                PropertyInstallation: "/client/:clientId/property/:propertyId/install/:installId",
            },
        },
        Contact: { Root: "/contact", ContactAddEditDetail: "/contact/edit/:type/:id" },
        Contractor: {
            Root: "/contractor",
            ContractorsDetail: "/contractor/:id",
            ContractorAddEditDetail: "/contractor/edit/:id",
        },
        Dashboard: {
            Root: "/dashboard",
        },
        Device: {
            Root: "/installationview",
            PropertyDetail: "/installationview/property/:propertyId",
            PropertyInstallation: "/installationview/property/:propertyId/install/:installId",
            Installation: "/installationview/install/:installId",
        },
        EstateView: {
            Root: "/estate",
            /*             Device: {
                DeviceRoot: "/estate/units",
                PropertyDetail: "/estate/units/property/:propertyId",
                PropertyInstallation: "/estate/units/property/:propertyId/install/:installId",
                Installation: "/estate/units/install/:installId",
            }, */
            Property: {
                PropertyDetail: "/estate/property/:projectId/:propertyId",
                PropertyInstallation: "/estate/property/:projectId/:propertyId/install/:installId",
            },
            Project: {
                ProjectDetail: "/estate/project/:projectId",
                PropertyDetail: "/estate/project/:projectId/property/:propertyId",
                PropertyInstallation: "/estate/project/:projectId/property/:propertyId/install/:installId",

                ProjectAddEdit: "/estate/project/edit/:projectId",
            },
        },
        /*         Map: {
            Root: "/map",
            PropertyDetail: "/map/property/:propertyId",
            PropertyInstallation: "/map/property/:propertyId/install/:installId",
            Installation: "/map/install/:installId",
        }, */
        Project: {
            Root: "/project",
            ProjectAddEdit: "/project/edit/:projectId",
            ProjectDetail: "/project/:projectId",
            Property: {
                PropertyRoot: "/project/:projectId/property/:propertyId",
                PropertyInstallation: "/project/:projectId/property/:propertyId/install/:installId",
            },
        },
        Unit: {
            Root: "/unitlist",
            Installation: "/unitlist/install/:installId",
        },
        User: {
            Root: "/user",
            UserDetail: "/user/:id",
        },
    },
    ClientView: {
        Root: "/dashboard",
        Device: {
            DeviceRoot: "/clientview/units",
            PropertyDetail: "/clientview/units/property/:propertyId",
            PropertyInstallation: "/clientview/units/property/:propertyId/install/:installId",
            Installation: "/clientview/units/install/:installId",
        },
        Property: {
            PropertyRoot: "/clientview/property/property",
            PropertyDetail: "/clientview/property/project/:projectId/property/:propertyId",
            PropertyInstallation: "/clientview/property/project/:projectId/property/:propertyId/install/:installId",
        },
        Project: {
            ProjectRoot: "/clientview/project/project",
            ProjectDetail: "/clientview/project/project/:projectId",
            PropertyDetail: "/clientview/project/project/:projectId/property/:propertyId",
            PropertyInstallation: "/clientview/project/project/:projectId/property/:propertyId/install/:installId",
        },
    },
    ContractorView: {
        Root: "/dashboard",
        Device: {
            DeviceRoot: "/contractorview/units",
            PropertyDetail: "/contractorview/units/property/:propertyId",
            PropertyInstallation: "/contractorview/units/property/:propertyId/install/:installId",
            Installation: "/contractorview/units/install/:installId",
        },
        Property: {
            PropertyDetail: "/dashboard/property/:projectId/:propertyId",
            PropertyInstallation: "/dashboard/property/:projectId/:propertyId/install/:installId",
        },
        Project: {
            ProjectDetail: "/dashboard/project/:projectId",
            PropertyDetail: "/dashboard/project/:projectId/property/:propertyId",
            PropertyInstallation: "/dashboard/project/:projectId/property/:propertyId/install/:installId",
            ProjectAddEdit: "/dashboard/project/edit/:projectId",
        },
    },
};

export const Server = {
    Api: {
        Account: {
            Login: "./api/account/login",
            Logout: "./api/account/logout",
            ResetPassword: {
                Reset: "./api/account/resetpassword",
                VerifyToken: "./api/account/resetpasswordtoken",
            },
            SetPassword: "./api/account/setpassword",
            ForgotPassword: "./api/account/forgotpassword",
        },
        Admin: {
            getAdminMisc: "/api/admin/Get",
            upsertAdminMisc: "/api/admin/UpsertAdminMisc",
        },
        //Admin: {},
        LiteFormEmail: {
            PostLiteFormAsync: "/api/LiteForm/PostLiteFormAsync",
        },
        AlertActions: {
            deleteAlertAction: "/api/AlertAction/DeleteAlertAction",
            getAllAlertActions: "/api/AlertAction/GetAll",
            getAlertActionById: "/api/AlertAction/GetAlertActionById",
            getAlertActionsForList: "/api/AlertAction/GetAlertActionsForList",
            upsertAlertAction: "/api/AlertAction/UpsertAlertAction",
            getIsAlertActionValid: "/api/AlertAction/GetIsAlertActionValid",
        },
        Breadcrumb: {
            getBreadcrumbText: "/api/breadcrumb/GetBreadcrumbText",
        },
        Client: {
            deleteClient: "/api/Client/DeleteClient",
            getClients: "/api/Client/GetAllClients",
            getClientForList: "/api/Client/GetClientForList",
            getClientAndRelated: "/api/Client/GetClientAndRelated",
            getClient: "/api/Client/GetClient",
            getDashboard: "/api/Client/GetClientDashboard",
            upsertClient: "/api/Client/UpsertClient",
            upsertContact: "/api/Client/UpsertContact",
            getProjectAndPropertyListItems: "/api/Client/GetProjectAndPropertyListItems",
            getPropertiesAndRelatedForListAndMap: "/api/Client/GetPropertiesAndRelatedForListAndMap",
            upsertNewContact: "api/Client/upsertNewContact",

            // ALL CONTACTS
            getALLContactsForList: "api/Client/GetALLContactsForList",
            downloadContactsAsCsv: "api/Client/DownloadContactsAsCsv",
        },
        ClientView: {
            getPropertiesForList: "/api/Client/getPropertiesForList",
        },
        ConditionSet: {
            deleteConditionSet: "/api/ConditionSet/DeleteConditionSet",
            getAllConditionSets: "/api/ConditionSet/GetAll",
            getConditionSetById: "/api/ConditionSet/GetConditionSetById",
            getConditionSetsForList: "/api/ConditionSet/GetConditionSetForList",
            upsertConditionSet: "/api/ConditionSet/UpsertConditionSet",
            getIsConditionSetValid: "/api/ConditionSet/GetIsConditionSetValid",
        },
        Contractor: {
            getPropertiesForList: "/api/Contractor/GetPropertiesForList",
            deleteContractor: "/api/Contractor/DeleteContractor",
            getContractors: "/api/Contractor/GetAllContractors",
            getContractorsForList: "/api/Contractor/GetContractorForList",
            getContractorAndRelated: "/api/Contractor/GetContractorAndRelated",
            getDashboard: "/api/Contractor/GetContractorDashboard",
            getPropertiesAndRelatedForListAndMap: "/api/Contractor/GetPropertiesAndRelatedForListAndMap",
            upsertContractor: "/api/Contractor/UpsertContractor",
            upsertContact: "/api/Contractor/UpsertContact",
        },
        Estate: {
            getPropertiesForList: "/api/Estate/GetPropertiesForList",
            getPropertyOverVieww: "/api/Estate/GetPropertyOverview",
        },
        Hopewiser: {
            getAddresses: "api/Hopewiser/GetAddresses",
            getAddressDetails: "api/Hopewiser/GetAddressDetails",
        },
        Image: {
            // Not working downloadImage: "/api/SharmanImage/DownloadImage",
            downloadDeviceImage: "/api/SharmanImage/DownloadDeviceImage",
            downloadCommissioningDeviceImage: "/api/SharmanImage/DownloadCommissioningDeviceImage",
            getInstallationQRCode: "/api/SharmanImage/GetInstallationQRCode",
        },
        Installation: {
            downloadInstallationsAsCsv: "/api/Installation/DownloadInstallationsAsCsv",
            downloadDataAsCsv: "/api/Installation/DownloadDeviceDataAsCsv",
            downloadDataAsCsv1: "/api/Installation/DownloadDeviceDataAsCsv1",
            downloadMinMaxDataAsCsv: "/api/Installation/DownloadMinMaxDataAsCsv",
            getInstallationCommissionByDeviceId: "/api/Installation/GetInstallationCommissionByDeviceId",
            getInstallationRelated: "/api/Installation/GetInstallationRelated",
            getInstallationddressList: "/api/Installation/GetInstallationAddressList",
            getInstallationList: "/api/Installation/GetDeviceList",
            getInstallationListByAddress: "/api/Installation/GetInstallationListByAddress",
            getInstallationAndRelated: "/api/Installation/GetInstallationAndRelated",
            getInstallationAndRelatedForEdit: "/api/Installation/GetInstallationAndRelatedForEdit",
            // getReadingsForInstallationForPeriod: "/api/Installation/GetReadingsForInstallationForPeriod",
            updateImageOrientation: "/api/Installation/FlipImage",
            updateCommissionImageOrientation: "/api/Installation/FlipCommissionImage",

            upsertInstallation: "/api/Installation/UpsertInstallation",
            getAverageDayReadingsForInstallationForPeriod: "/api/Installation/GetAverageDayReadingsForInstallationForPeriod",
            getReadingsForInstallationBetweenDates: "/api/Installation/GetReadingsForInstallationBetweenDates",
            getImagesForInstallationBetweenDates: "/api/Installation/GetImagesForInstallationBetweenDates",
            sendPhotoCommand: "/api/Installation/SendPhotoCommand",
            okToUseSIMID: "/api/Installation/OKToUseSIMID",
            getInstallationNotes: "/api/Installation/GetAllInstallationNotes",
            updateStatusFilter: "/api/Installation/UpdateStatusFilter",
            updateHideOnGraph: "/api/Installation/UpdateHideOnGraph",
            upsertCommand: "/api/Installation/UpsertCommand",
            upsertSimpleCommand: "/api/Installation/UpsertSimpleCommand",
            getCommandNotes: "/api/Installation/GetAllCommandNotes",
            upsertCommandNote: "/api/Installation/UpsertCommandNote",
            upsertAlerts: "/api/Installation/UpsertInstallationAlerts",
            getCommandNote: "/api/Installation/GetCommandNote",
            getInstallationDevicesWithNoProperty: "/api/Installation/GetInstallationDevicesWithNoProperty",
            getInstallationsForUnitList: "/api/Installation/getInstallationsForUnitList",
            removeDeviceFromProperty: "api/Installation/RemoveDeviceFromProperty",
            getAllProjectAddressesForSwapAddress: "api/Installation/GetAllProjectAddressesForSwapAddress",
            sanitiseDataByCommissionDate: "api/Installation/SanitiseDataByCommissionDate",
            swapPropertyForDevice: "api/Installation/SwapPropertyForDevice",
            getInstallationConfiguration: "api/Installation/GetInstallationConfiguration",
            upsertDeviceConditionSet: "api/Installation/UpsertDeviceConditionSet",
            deleteDeviceConditionSet: "api/Installation/DeleteDeviceConditionSet",
            upsertDeviceAlertAction: "api/Installation/UpsertDeviceAlertAction",
            deleteDeviceAlertAction: "api/Installation/DeleteDeviceAlertAction",
            //Client View
            getClientViewInstallationsForUnitList: "/api/Installation/getClientViewInstallationsForUnitList",
            //ContractorView
            getContractorViewInstallationsForUnitList: "/api/Installation/getContractorViewInstallationsForUnitList",
            setUnitLocation: "/api/Installation/SetUnitLocation",

            deleteUnit: "api/installation/DeleteDevice",
        },
        InstallationAnalytics: {
            generateAlertActionAnalyticsBetweenDates: "/api/InstallationAnalytics/GenerateAlertActionAnalyticsBetweenDates",
        },
        InstallationAudit: {
            getAuditForDeviceId: "/api/InstallationAudit/GetAuditForDeviceId",
            getInstallationAuditByInstallationAuditId: "/api/InstallationAudit/GetInstallationAuditByInstallationAuditId",
        },
        InstallationMetrics: {
            generateDeviceMetricsBetweenDates: "/api/InstallationMetrics/GenerateDeviceMetricsBetweenDates",
        },
        Project: {
            deleteProject: "/api/project/DeleteProject",
            downloadCommunicationsAsCsv: "/api/project/DownloadCommunicationsAsCsv",
            downloadProjectsCommunicationsAsCsv: "/api/project/DownloadProjectsCommunicationsAsCsv",
            getAllProjectCommunicationForList: "/api/project/getAllProjectCommunicationForList",
            getAdminProjectRelated: "/api/project/GetAdminProjectRelated",
            getAllProjects: "/api/project/GetAllProjects",
            getProject: "/api/project/GetProject",
            getProjectAndRelatedForEdit: "/api/project/GetProjectAndRelatedForEdit",
            getProjectAndRelatedForView: "/api/project/GetProjectAndRelatedForView",
            getProjectsAndRelatedForList: "/api/project/GetProjectsAndRelatedForList",
            updateProjectStatus: "/api/project/UpdateProjectStatus",
            updateProjectPaymentStatus: "/api/project/UpdateProjectPaymentStatus",
            upsert: "/api/project/UpsertProject",
            upsertPropertyCommunication: "api/project/upsertPropertyCommunication",

            downloadActionsAsCsv: "api/project/DownloadActionsAsCsv",
        },
        ProjectDocument: {
            uploadProjectDocument: "/api/projectDocument/UploadDocument",
            deleteProjectDocument: "/api/projectDocument/SetIsDeleted",
            // downloadProjectDocument: "/api/projectDocument/DownloadProjectDocument",
        },
        Property: {
            getAllAdminPropertyAndInstallationMapItems: "/api/Property/GetAllAdminPropertyAndInstallationMapItems",
            getProperties: "/api/property/GetAllProperties",
            getPropertyDetailsAndRelated: "/api/Property/GetPropertyDetailsAndRelated",
            getPropertyDetailsAndRelatedForContractorView: "/api/property/getpropertydetailsandrelatedforcontractorview",
            getPropertyDetailsAndRelatedForClientView: "/api/property/getpropertydetailsandrelatedforclientview",
            getPropertyDetailsAndRelatedView: "/api/property/GetPropertyDetailsAndRelatedView",
            updatePropertyAddress: "/api/Property/UpdatePropertyAddress",
            upsertProperty: "/api/Property/UpsertProperty",
        },
        PropertyDocument: {
            uploadPropertyDocument: "/api/propertyDocument/UploadDocument",
            deletePropertyDocument: "/api/propertyDocument/SetIsDeleted",
            // downloadProjectDocument: "/api/projectDocument/DownloadProjectDocument",
        },
        Roles: {
            getAll: "/api/roles/getall",
            upsert: "/api/roles/Upsert",
        },
        StaticData: {
            deleteTestGrade: "/api/staticdata/DeleteTestGrade",
            deleteUnitFirmware: "/api/staticdata/DeleteUnitFirmware",
            deleteDeviceUnitStatus: "/api/staticdata/DeleteDeviceUnitStatus",
            deleteUnitVersion: "/api/staticdata/DeleteUnitVersion",
            deleteProjectStatus: "/api/staticdata/DeleteProjectStatus",
            deleteProjectPaymentStatus: "/api/staticdata/DeleteProjectPaymentStatus",

            getAllTestGradeForList: "/api/staticdata/GetAllTestGradeForList",
            getAllUnitFirmwareForList: "/api/staticdata/GetAllUnitFirmwareForList",
            getAllUnitStatusForList: "/api/staticdata/GetAllUnitStatusForList",
            getAllUnitVersionForList: "/api/staticdata/GetAllUnitVersionForList",

            getAllProjectStatusForList: "/api/staticdata/GetAllProjectStatusForList",
            getAllProjectPaymentStatusForList: "/api/staticdata/GetAllProjectPaymentStatusForList",

            getTestGradeById: "/api/staticdata/GetTestGradeById",
            getUnitFirmwareById: "/api/staticdata/GetUnitFirmwareById",
            getUnitVersionById: "/api/staticdata/GetUnitVersionById",
            getDeviceUnitStatusById: "/api/staticdata/GetDeviceUnitStatusById",
            getProjectStatusById: "/api/staticdata/GetProjectStatusById",
            getProjectPaymentStatusById: "/api/staticdata/GetProjectPaymentStatusById",

            upsertTestGrade: "/api/staticdata/UpsertTestGrade",
            upsertUnitFirmware: "/api/staticdata/UpsertUnitFirmware",
            upsertDeviceUnitStatus: "/api/staticdata/UpsertDeviceUnitStatus",
            upsertUnitVersion: "/api/staticdata/UpsertUnitVersion",
            upsertProjectStatus: "/api/staticdata/UpsertProjectStatus",
            upsertProjectPaymentStatus: "/api/staticdata/UpsertProjectPaymentStatus",
        },
        UnitBehaviour: {
            deleteUnitBehaviour: "/api/UnitBehaviour/DeleteUnitBehaviour",
            getAllUnitBehaviours: "/api/UnitBehaviour/GetAll",
            getUnitBehaviourById: "/api/UnitBehaviour/GetUnitBehaviourById",
            getUnitBehavioursForList: "/api/UnitBehaviour/GetUnitBehavioursForList",
            upsertUnitBehaviour: "/api/UnitBehaviour/UpsertUnitBehaviour",
            getIsUnitBehaviourValid: "/api/UnitBehaviour/GetIsAdminUnitBehaviourValid",
        },
        Unit: {
            getDeviceUnitRelated: "/api/unit/GetDeviceUnitRelated",
            getAllUnitsForListAndRelated: "/api/unit/GetAllUnitsForListAndRelated",
            getDeviceUnitDataAndRelatedForDeviceId: "/api/unit/GetDeviceUnitDataAndRelatedForDeviceId",
            upsertDeviceUnitData: "/api/unit/UpsertDeviceUnitData",
            upsertDeviceUnitNote: "/api/unit/UpsertDeviceUnitNote",
            insertDeviceUnitNote: "api/unit/InsertDeviceUnitNote",
            downloadUnitsAsCsv: "api/unit/DownloadUnitsAsCsv",
        },
        User: {
            // Old getAll: "/api/user/getall",
            getUser: "/api/user/GetUser",
            getUserRelated: "/api/user/GetUserRelated",
            deleteUser: "/api/user/DeleteUser",
            getAll: "/api/user/GetAllRoofcareUsers",
            getUserRoles: "/api/user/GetUserRoles",
            resetFailedLoginAttemptsCount: "./api/user/ResetLoginAttempts",
            upsertUser: "/api/user/UpsertUser",
            isEmailInUse: "/api/user/IsEmailInUse",
        },
    },
};
