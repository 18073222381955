import * as MobX from "mobx";
import { ModelBase } from "Core/Models/ModelBase";
import { AddressModel } from "../Address/AddressModel";
import { IsNotEmpty, ValidateIf } from "class-validator";
export class ClientModel extends ModelBase<ClientModel, ClientModelDTO> {
    public id: string = "";

    @MobX.observable @IsNotEmpty({ message: "Client name is required." }) public clientName: string = "";
    @MobX.observable public rowVersion: string = "";
    @MobX.observable public isDeleted: boolean = false;
    @MobX.observable createdDate: string = "";
    @MobX.observable createdBy: string = "";
    @MobX.observable buildingName: string = "";
    @MobX.observable @IsNotEmpty({ message: "Address Line 1 is required." }) addressLine1: string = "";
    @MobX.observable addressLine2: string = "";
    @MobX.observable @IsNotEmpty({ message: "City 1 is required." }) city: string = "";
    @MobX.observable county: string = "";
    @MobX.observable @IsNotEmpty({ message: "Postcode is required." }) postcode: string = "";

    constructor() {
        super();
    }

    @MobX.computed
    public get displayName(): string {
        return this.clientName;
    }

    @MobX.computed
    public get getAddress(): AddressModel {
        let retVal = new AddressModel();

        retVal.buildingName = this.buildingName;
        retVal.addressLine1 = this.addressLine1;
        retVal.addressLine2 = this.addressLine2 === undefined ? "" : retVal.addressLine2;
        retVal.city = this.city;
        retVal.county = this.county;
        retVal.postcode = this.postcode;

        return retVal;
    }

    @MobX.action
    public fromDto = (model: ClientModelDTO) => {
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                this[key] = model[key];

                if (key === "addressLine2") {
                    if (this[key] === null || this[key] === undefined) {
                        this[key] = "";
                    }
                }
            }
        }
    };

    public toDto = (model: ClientModelDTO) => {
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                model[key] = this[key];
            }
        }
    };

    @MobX.action
    public setAddress(addr: AddressModel): void {
        this.buildingName = addr.buildingName;
        this.addressLine1 = addr.addressLine1;
        this.addressLine2 = addr.addressLine2;
        this.city = addr.city;
        this.county = addr.county;
        this.postcode = addr.postcode;
    }
}

export interface ClientModelDTO {
    id: string;
    clientName: string;
    rowVersion: string;
    isDeleted: boolean;
    createdDate: string;
    createdBy: string;
    buildingName: string;
    addressLine1: string;
    addressLine2?: string | undefined;
    city: string;
    county: string;
    postcode: string;
}

export interface ClientMinDTO {
    id: string;
    clientName: string;
    isDeleted: boolean;
    /*     rowVersion: string;
    isDeleted: boolean;
    createdDate: string;
    createdBy: string;

    addressLine1: string;
    addressLine2?: string | undefined;
    city: string;
    county: string;
    postcode: string; */
}
