import * as React from "react";
import * as MobXReact from "mobx-react-lite";
import { PropertyNotesContainer } from "Custom/Views/Projects/Details/Properties/PropertyNotes/PropertyNotes.styled";
import { Typography } from "@material-ui/core";
import { ProjectDetailsViewModel } from "../../ProjectDetailsViewModel";
import { isNullOrEmpty } from "Core/Utils/Utils";

import iconEditNote from "Custom/Content/editNoteIcon.svg";
import { Stores, StoresContext } from "Custom/Stores";
import * as RouterUtil from "Custom/Utils/routing";
import { AddEditProjectNote } from "./AddEditProjectNote";

interface IProps {
    viewModel: ProjectDetailsViewModel;
    setOpenAddModal: () => void;
}

export const ProjectNote: React.FC<IProps> = ({ viewModel, setOpenAddModal }) => {
    const store = React.useContext<Stores>(StoresContext);
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [allowedToEdit, setAllowedToEdit] = React.useState(false);

    React.useEffect(() => {
        if (!viewModel.IsLoading) {
            setIsLoaded(true);
        }
    }, [viewModel.IsLoading]);

    React.useEffect(() => {
        const allowEdit: boolean = RouterUtil.requireAdminLogin(store) === true;
        setAllowedToEdit(allowEdit);

        return () => {};
    }, []);

    return MobXReact.useObserver(() => (
        <PropertyNotesContainer>
            <AddEditProjectNote viewModel={viewModel} setOpenAddModal={setOpenAddModal} />
        </PropertyNotesContainer>
    ));
};
