import { RightSide } from "Core/Components/Styles/BaseStyles";
import { Select } from "@material-ui/core";
import styled from "styled-components";

export const ProjectNoteItem = styled.div`
    width: 100%;
    display: flex;
    flex: 1 0 0;
    flex-direction: column;
    background: #f3f3f3 0% 0% no-repeat padding-box;
    opacity: 1;

    padding: 15px;

    .project-note {
        font-weight: 300;
        font-size: 14px;
    }
`;

export const ProjectNoteListItem = styled(ProjectNoteItem)`
    display: flex;
    flex-direction: row;
    align-content: space-between;
    flex: 1 0 0;
    width: 100%;
    color: #000000bf !important;

    &.overdue {
        background-color: #b42025;
        color: #ffffff !important;
    }

    .communication {
        display: flex;
        flex-direction: column;
        flex: 1 0 0;

        // background-color: lavender;
    }

    .followup {
        display: flex;
        flex-direction: column;
        flex: 1 0 0;
        max-width: 120px;
        // background-color: orange;
        text-align: right !important;
    }

    .editAction {
        display: flex;
        flex-direction: row;
        flex: 1 0 0;
        max-width: 100px;
        // background-color: orange;
        /*         text-align: right !important; */
        vertical-align: middle;
        padding: 17px 15px 0 0;

        &.overdue {
            background-color: #b42025;
            color: #ffffff !important;
        }
    }

    .title {
        font-size: 12px;
        font-weight: 500;
        font-family: Mont-Bold, Montserrat, Roboto Condensed, Helvetica, Arial, sans-serif;
    }
    .created {
        font-size: 8px;
        font-weight: 300;
        font-family: Mont-Bold, Montserrat, Roboto Condensed, Helvetica, Arial, sans-serif;
        margin-top: -2px;
    }
`;

export const ProjectNote = styled.div`
    width: 100%;
    font-weight: 300;
    font-size: 14px;
`;

export const AddCommunicationBox = styled.div`
    width: 100%;
    min-height: 250px;
    display: flex;
    flex-direction: column;
    font-weight: 300;
    font-size: 14px;

    .note-textarea {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
        min-height: 130px;
        max-height: 40vh;
        border-radius: 0;
        outline: none;
        padding: 5px;
        font-size: 1.4359em;
        font-family: "Mont-Regular", "Montserrat", "Roboto Condensed", "Helvetica", "Arial", "sans-serif";
        overflow-y: auto !important;
        margin-top: 9px;

        :focus {
            border-color: black;
        }
    }
`;

export const NoteListHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-content: space-between;
    flex: 1 0 0;
    width: 100%;
    /*     background-color: pink; */
`;

export const CommunicationSortSelectSelect = styled(Select)`
    padding: 0;
    width: 225px;
    border: 1px solid #e3e3e3;
    border-radius: 0;

    &.MuiInput-underline:before {
        z-index: 1;
    }

    div.MuiSelect-select {
        padding: 9px 16px;
        padding-right: ${(props) => (props.disabled ? "16px" : "48px")};
        font-size: 14px;
        text-align: center;
    }

    svg.MuiSelect-icon {
        width: 32px;
        height: 100%;
        padding: 6px;
        top: unset;
        background-color: white;
        display: ${(props) => (props.disabled ? "none" : "inherit")};
    }
`;

export const CommunicationSortContainer = styled.div`
    border: 1px solid #cccccc;
    width: fit-content;
`;
