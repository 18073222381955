import React, { useEffect, useState } from "react";
import { useObserver } from "mobx-react-lite";
import { useRouter } from "../../../Core/Utils";
import { ClientViewModel } from "Custom/Views/Clients/ClientViewModel";
import { AddEditWrapper, EditWrapper } from "Custom/StylesAppSpecific/Controls/AddEditWrapper";
import { AddEditButtonRow } from "Custom/StylesAppSpecific/Controls/AddEditButtonRow";
import { AppUrls } from "Custom/Globals";
import { StoresInstance } from "Custom/Stores";
import { Main } from "./ClientAddEdit.style";
import { BackBox, BackToList, BackToListText } from "Custom/StylesAppSpecific/Controls/BackToList";
import { SuccessBtn } from "Custom/StylesAppSpecific/Controls/SuccessBtn";
import { CancelBtn } from "Custom/StylesAppSpecific/Controls/CancelBtn";
import { Typography } from "@material-ui/core";
import NavigatePrevIcon from "@material-ui/icons/NavigateBefore";
import { DetailContainer } from "Custom/StylesAppSpecific/Controls/DetailsContainer";
import { AddressModel } from "Custom/Models/Domain/Address/AddressModel";
import { ClientAddEditForm } from "./ClientAddEditForm";
import { Loader } from "Core/Components";
import { useLoadCall } from "Custom/Utils/useLoadCall";
import { NotFoundModal } from "Custom/Components/NotFoundModal/NotFoundModal";
import { isGuid } from "Custom/Utils/isGuid";
import { MainBackToListWrapper } from "../Admin/Condition/Condition.style";
const domainStores = StoresInstance.domain;

export const ClientAddEdit: React.FC = () => {
    const [viewModel] = useState(() => new ClientViewModel());
    const [errorMessage, setErrorMessage] = useState("");
    const [saveText, setSaveText] = useState("Save Client");
    const { history, match } = useRouter();
    const [loader, setLoader] = useState(false);
    const [newClient, setNewClient] = useState(false);
    const [titleText, setTitleText] = useState("Add End User");
    let { clientId } = match.params as any;

    useEffect(() => {
        return () => {
            // Clean up after yourself
        };
    }, []);

    const notFound = useLoadCall(
        async () => {
            if (clientId === "0") {
                setNewClient(true);
                setTitleText("Add End User");
                return true;
            } else {
                let promise = await viewModel.loadClientAsync(clientId);
                setNewClient(false);
                setTitleText("Edit End User");

                if (promise.wasSuccessful === true) {
                }

                return !!viewModel.getClient.getValue("id");
            }
        },
        { customCheck: () => clientId === "0" || isGuid(clientId) },
    );

    React.useEffect(() => {}, [history.location.pathname]);

    const backToList = () => {
        // ideally but could be from anywhere history.goBack();
        // Q) Should we splat the user at this point
        history.push(AppUrls.Client.Main.Client.Root);
    };

    const changeClientName = (e: any) => {
        viewModel.setClientName(e.target.value);
    };

    const setAddress = (address: AddressModel): void => {
        viewModel.setClientAddress(address);
    };

    const renderPage = () => {
        if (viewModel.IsLoading || notFound) return <Loader />;

        return (
            <DetailContainer>
                <>
                    <MainBackToListWrapper>
                        <BackToList onClick={backToList}>
                            <BackBox>
                                <NavigatePrevIcon fontSize="large" />
                            </BackBox>
                            <BackToListText style={{ cursor: "pointer" }}>Back to end user list</BackToListText>
                        </BackToList>
                    </MainBackToListWrapper>
                    <div className="resetMsg">{viewModel.IsLoading ? "Resetting..." : ""}</div>
                    {
                        <AddEditWrapper>
                            <form onSubmit={viewModel.doSubmit} id="detailsPanelForm">
                                <DetailContainer>
                                    <div className="title">
                                        <Typography variant="h1" component="h2">
                                            {titleText}
                                        </Typography>
                                    </div>
                                    {viewModel.errorMessage !== "" && (
                                        <Typography variant="caption" style={{ color: "red" }}>
                                            {viewModel.errorMessage}
                                        </Typography>
                                    )}
                                    <ClientAddEditForm viewModel={viewModel} />
                                    <AddEditButtonRow>
                                        <SuccessBtn id="save" variant="contained" color="secondary" type="submit" disabled={loader}>
                                            {!loader ? saveText : "Saving"}
                                        </SuccessBtn>
                                        <span style={{ paddingRight: "20px" }} />
                                        <CancelBtn id="cancel" variant="contained" onClick={backToList}>
                                            Cancel
                                        </CancelBtn>
                                    </AddEditButtonRow>
                                </DetailContainer>
                            </form>
                        </AddEditWrapper>
                    }
                </>
            </DetailContainer>
        );
    };

    return useObserver(() => (
        <>
            <NotFoundModal returnRoute={AppUrls.Client.Main.Client.Root} open={notFound} />
            {renderPage()}
        </>
    ));
};
