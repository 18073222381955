/* eslint-disable prettier/prettier */
import { AverageFlexRow, AverageRow, DeviceAverageBox } from "Custom/Views/Installations/TabAverage.style";
import React, { useRef, useEffect, useState, useContext } from "react";
import { InstallationStatusDataDTO, DaysOption, AverageSensorValue } from "Custom/Models/Domain/Installations";
import { Box, Typography, Select, MenuItem, useTheme, Paper } from "@material-ui/core";
import { useObserver } from "mobx-react-lite";
import { GutterSensTabContent } from "./GuttersensTabContent";
import { generateID } from "Core/Utils/Utils";
import * as RouterUtil from "Custom/Utils/routing";
import { InstallationAverageViewModel } from "./InstallationAverageViewModel";
import { BoxplotGraph } from "Custom/Components/Graphs/BoxplotGraph";
import { Stores, StoresContext } from "Custom/Stores";
import { RoofcareTable, RoofcareTableBox } from "Custom/Components/Table/RoofcareTable";
import { formatDateTime } from "Custom/Utils/format";
import { DeviceAlertActionModelDTO, DeviceConditionSetModelDTO } from "./TabConfigurationModel";
import { AlertSwitchOption } from "./DeviceSection.style";
import { GraphWrapper, GraphHeader } from "./InstallationView.style";
import { Loader } from "Core/Components";
import { Moment } from "moment";
import moment from "moment";
import { isValidDateString } from "Custom/Utils/utils";
import { DataCell } from "./TabDeviceData.style";
import { SuccessBtn } from "Custom/StylesAppSpecific/Controls/SuccessBtn";
import { ProjectButton } from "../CustomComponents";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

interface TabAverageProps {
    deviceId: number;
    deviceStatusData: InstallationStatusDataDTO | undefined;
    conditionSet: DeviceConditionSetModelDTO | undefined;
    alertAction: DeviceAlertActionModelDTO | undefined;
    reloadData: boolean;
    setReloadAverageData: (value: boolean) => void;
    minDate: Moment;
}

export interface GraphState {
    width: number;
    height: number;
}

export function TabAverage(props: TabAverageProps) {
    const storesContext = useContext<Stores>(StoresContext);
    const { alertAction, deviceStatusData, conditionSet, reloadData, setReloadAverageData, minDate } = props;
    const theme = useTheme();
    const [viewModel] = useState(() => InstallationAverageViewModel.Instance);
    const [daysToGraph, setDaysToGraph] = useState(viewModel.daysToGraph);

    const [showLines, setShowLines] = useState<boolean>(false);
    const [showAlertLines, setShowAlertLines] = useState<boolean>(false);
    const btnClass: string = showLines == false ? "notselected" : "selected";
    const btnAlertClass: string = showAlertLines == false ? "notselected" : "selected";

    const componentRef: any = useRef(generateID());

    const [dimensions, setDimensions] = React.useState<GraphState>({
        width: 400,
        height: 389,
    });

    useEffect(() => {
        // tidy up after
        const { deviceId } = props;
        const { averageReadings, IsLoading, load } = viewModel;

        if ((reloadData === true || averageReadings === null || averageReadings === undefined || averageReadings.length === 0) && IsLoading === false && deviceId > 0) {
            let promise = load(deviceId);

            promise.then(() => {
                setReloadAverageData(false);
            });
        }

        updateWindowDimensions();
        window.addEventListener("resize", updateWindowDimensions);

        // tidy up after yourself
        return () => {
            window.removeEventListener("resize", updateWindowDimensions);
        };
    }, []);

    ///
    /// updateWindowDimensions
    ///

    const updateWindowDimensions = () => {
        if (componentRef !== undefined && componentRef != null && componentRef.current !== null && componentRef.current !== undefined) {
            setDimensions({
                width: componentRef!.current!.offsetWidth - 45,
                height: componentRef.current!.offsetHeight! > 675 ? 389 : 389, // 389 gives roughly the height from the mocks once you add the bottom to it
            });
        }
    };

    useEffect(() => {
        // tidy up after
        const { deviceId } = props;
        const { averageReadings, IsLoading, load } = viewModel;

        if ((averageReadings === null || averageReadings === undefined || averageReadings.length === 0) && IsLoading === false && deviceId > 0) {
            load(deviceId);
        }

        return () => {};
    }, [props.deviceId]);

    let workingHeight: number = 0;

    let showAverages: boolean = false;

    let averageDailyPeak: number = 0;
    let averageDaily: number = 0;
    let averageDailytrough: number = 0;

    if (viewModel.averageReadings.length > 0 && deviceStatusData !== undefined && deviceStatusData !== null) {
        showAverages = true;
        const n: number = viewModel.averageReadings.length;
        const maxWorkingHeightValue: number = Math.min(deviceStatusData.side1_Height, deviceStatusData.side2_Height);
        workingHeight = maxWorkingHeightValue;

        const calculated: number[] = viewModel.averageReadings.map((a) => a.calculatedValue);
        const maxes: number[] = viewModel.averageReadings.map((a) => a.maxValue);
        const mins: number[] = viewModel.averageReadings.map((a) => a.minValue);

        averageDaily = calculated.reduce((accumulator, currentValue) => accumulator + currentValue) / n /*- deviceStatusData.install_Height*/;
        averageDailyPeak = maxes.reduce((accumulator, currentValue) => accumulator + currentValue) / n /*- deviceStatusData.install_Height*/;
        averageDailytrough = mins.reduce((accumulator, currentValue) => accumulator + currentValue) / n /*- deviceStatusData.install_Height*/;
    }

    const handleDaysToGraphDropDownOnChange = async (event: any) => {
        const days: number = parseInt(event.target.value);
        viewModel.setDaysToGraph(days);

        if (days > 0) {
            let mom: Moment = moment().add(days * -1, "days");

            if (mom < minDate) {
                mom = minDate;
            }

            viewModel.setStartDate(mom);
            viewModel.setEndDate(moment());
            await viewModel.load(props.deviceId);
        }
    };

    const handleCSVClick = (id: string) => {
        viewModel.downloadInstallationMinMaxCSV(props.deviceId);
    };

    const updateStart = (event: any, value: string | null | undefined): void => {
        let mom: Moment = moment(value, "DD/MM/YYYY");
        if (value !== null && value !== undefined) {
            if (isValidDateString(value!) === true && value!.length === 10) {
                if (mom < minDate) {
                    mom = minDate;
                }

                viewModel.setStartDate(mom);
            }
        }
    };

    const updateEnd = (event: any, value: string | null | undefined): void => {
        let mom: Moment = moment(value, "DD/MM/YYYY");
        if (value !== null && value !== undefined) {
            if (isValidDateString(value!) === true && value!.length === 10) {
                if (mom < minDate) {
                    mom = minDate;
                }
                viewModel.setEndDate(mom);
            }
        }
    };

    const showDownloadButton: boolean = RouterUtil.requireAdminLogin(storesContext);
    const disableDates: boolean = viewModel.getDaysToGraph > 0;

    const searchByCustomDate = (event: any): void => {
        viewModel.load(props.deviceId);
    };

    const renderPage = () => {
        if (viewModel.IsLoading) return <Loader />;

        return (
            <GutterSensTabContent>
                <DeviceAverageBox>
                    <AverageRow>
                        <Typography style={{ fontSize: "18px" }}>Average Data</Typography>
                    </AverageRow>
                    <AverageFlexRow>
                        <DataCell style={{ marginLeft: "-15px" }}>
                            <Typography style={{ fontSize: "14px" }} className="select-title">
                                Show:
                            </Typography>
                            <Box maxWidth="130px" width="100%">
                                <Select
                                    id="role-select"
                                    value={viewModel.getDaysToGraph}
                                    onChange={handleDaysToGraphDropDownOnChange}
                                    MenuProps={{
                                        getContentAnchorEl: null,
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left",
                                        },
                                    }}
                                    fullWidth
                                >
                                    {viewModel.daysOptions.map((item: DaysOption, index: number) => {
                                        return (
                                            <MenuItem key={generateID()} value={item.value}>
                                                {item.label}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </Box>
                        </DataCell>
                        <DataCell style={{ marginLeft: "-15px" }}>
                            <Typography style={{ fontSize: "14px" }}>Show from:</Typography>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    className="datepicker"
                                    placeholder="01/01/2021"
                                    label={""}
                                    value={viewModel.dateStart}
                                    disabled={disableDates}
                                    mask={"__-__-____"}
                                    format="DD/MM/YYYY"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={updateStart}
                                    minDate={minDate}
                                />
                            </MuiPickersUtilsProvider>
                        </DataCell>
                        <DataCell>
                            <Typography style={{ fontSize: "14px" }}>Show to:</Typography>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    className="datepicker"
                                    placeholder="01/01/2021"
                                    label={""}
                                    value={viewModel.dateEnd}
                                    disabled={disableDates}
                                    mask={"__-__-____"}
                                    format="DD/MM/YYYY"
                                    InputLabelProps={{
                                        shrink: true,
                                        /* disabled: disableDates, */
                                    }}
                                    onChange={updateEnd}
                                    minDate={minDate}
                                />
                            </MuiPickersUtilsProvider>
                        </DataCell>
                        <DataCell>
                            <SuccessBtn
                                placeholder="Update graph using custom dates"
                                id="customsearch"
                                variant="contained"
                                color="primary"
                                type="button"
                                disabled={disableDates}
                                onClick={searchByCustomDate}
                            >
                                Search
                            </SuccessBtn>
                        </DataCell>
                        <DataCell>
                            {viewModel.IsLoading && <Box className={"downloadcsv " + showAverages}>Processing</Box>}
                            {!viewModel.IsLoading && showDownloadButton === true && (
                                <ProjectButton
                                    icon={() => <div>&#x21e9;</div>}
                                    style={{
                                        borderRadius: "5px",
                                    }}
                                    text="Download Min/Max CSV"
                                    onClick={handleCSVClick}
                                />
                            )}
                        </DataCell>
                    </AverageFlexRow>

                    <AverageFlexRow>
                        {showAverages && (
                            <Box className="averages">
                                <div className="average-peak">
                                    <Typography style={{ fontSize: "14px" }} className="">
                                        Average daily peak:
                                    </Typography>
                                    <Typography variant="h2" className="normal">
                                        {averageDailyPeak.toFixed(2)}
                                    </Typography>
                                </div>
                                <div className="average-value">
                                    <Typography style={{ fontSize: "14px" }}>Daily average:</Typography>
                                    <Typography variant="h2" className="normal">
                                        {averageDaily.toFixed(2)}
                                    </Typography>
                                </div>
                                <div className="average-trough">
                                    <Typography style={{ fontSize: "14px" }}>Average daily trough:</Typography>
                                    <Typography variant="h2" className="normal">
                                        {averageDailytrough.toFixed(2)}
                                    </Typography>
                                </div>
                            </Box>
                        )}
                    </AverageFlexRow>
                    <GraphWrapper ref={componentRef}>
                        {viewModel.averageReadings !== null && viewModel.averageReadings !== undefined && viewModel.averageReadings.length > 0 && (
                            <GraphHeader>
                                <div className="slider-text">Show attribute lines:</div>
                                <AlertSwitchOption id={"showlines-select-"} className={btnClass} onClick={() => setShowLines(!showLines)} />

                                <div className="slider-text lhs15">Show alert lines:</div>
                                <AlertSwitchOption id={"showlines-select-"} className={btnAlertClass} onClick={() => setShowAlertLines(!showAlertLines)} />
                            </GraphHeader>
                        )}
                        <BoxplotGraph
                            graphHeight={dimensions.height}
                            graphWidth={dimensions.width - 5}
                            marginTop={20}
                            maxWidth={"100%"}
                            readings={viewModel.averageReadings}
                            showStatusLines={showLines}
                            deviceStatusData={deviceStatusData}
                            conditionSet={conditionSet}
                            showAlertLines={showAlertLines}
                            alertAction={alertAction}
                        />
                    </GraphWrapper>
                    <AverageRow>
                        <RoofcareTableBox>
                            <RoofcareTable
                                columns={[
                                    {
                                        title: "Date",
                                        field: "readingDate",
                                        type: "date",
                                        sorting: true,
                                        filtering: false,
                                        render: (rowData: AverageSensorValue) => <Box>{formatDateTime(rowData.readingDate)}</Box>,
                                    },
                                    {
                                        title: "Min Value (mm)",
                                        field: "minValue",
                                        sorting: true,
                                        filtering: false,
                                        render: (rowData: AverageSensorValue) => <Box>{rowData.minValue.toFixed(2)}</Box>,
                                    },
                                    {
                                        title: "Max Value (mm)",
                                        field: "maxValue",
                                        sorting: true,
                                        filtering: false,
                                        render: (rowData: AverageSensorValue) => <Box>{rowData.maxValue.toFixed(2)}</Box>,
                                    },
                                    {
                                        title: "Avg Value (mm)",
                                        field: "calculatedValue",
                                        sorting: true,
                                        filtering: false,
                                        render: (rowData: AverageSensorValue) => <Box>{rowData.calculatedValue.toFixed(2)}</Box>,
                                    },
                                ]}
                                data={viewModel.averageReadings}
                                options={{
                                    thirdSortClick: false,
                                    sorting: true,
                                    filtering: false,
                                    search: false,
                                    toolbar: false,
                                    cellStyle: {
                                        fontSize: 10,
                                    },
                                    pageSize: 50,
                                    pageSizeOptions: [10, 20, 50, 100],
                                    emptyRowsWhenPaging: false,
                                    headerStyle: {
                                        background: " #495D89 0% 0% no-repeat padding-box",
                                        boxShadow: " inset 0px 1px 1px #FFFFFF40",
                                        color: "#fff",
                                        fontFamily: ["Mont-Bold", "Montserrat", "Roboto Condensed", "Helvetica", "Arial", "sans-serif"].join(","),
                                    },
                                    rowStyle: {
                                        backgroundColor: "#525355",
                                        color: "#fff",
                                        fontSize: 24,
                                    },
                                    tableLayout: "fixed",
                                }}
                                components={{ Container: (props: any) => <Paper {...props} elevation={0} /> }}
                                title=""
                            />
                        </RoofcareTableBox>
                    </AverageRow>
                </DeviceAverageBox>
            </GutterSensTabContent>
        );
    };

    return useObserver(() => <>{renderPage()}</>);
}
