import { Box, Typography } from "@material-ui/core";
import styled from "styled-components";

export const AddEditWrapper: any = styled(Box)`
    color: white;
    padding: 20px 30px 30px;
    background-color: #585858;

    margin-top: 25px;

    h1 {
        font-size: 1.5em;
        margin-top: 3px;
    }

    h2.MuiTypography-h1 {
        margin-top: 15px;
    }

    form {
        min-width: 600px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .title {
        margin-bottom: 25px;
    }
`;

export const EditWrapper = styled(Box)`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export interface IAddItemTextProps {
    fontcolor?: string;
}

export const AddItemText = styled(Typography)<IAddItemTextProps>`
    padding-left: 15px;
    font-size: 1em;
    letter-spacing: 0;
    text-align: left;
    color: ${(props) => (props.fontcolor !== undefined ? props.fontcolor : props.theme.app.colors.blue.light)};
    cursor: pointer;
    :hover {
        text-decoration: underline;
    }
`;

export const AddItemTextUnderline = styled(AddItemText)`
    text-decoration: underline;
`;
