import React, { useEffect, useState } from "react";
import { useObserver } from "mobx-react-lite";
import { useRouter } from "../../../Core/Utils";
import { ContractorViewModel } from "./ContractorViewModel";
import { AddEditWrapper, EditWrapper } from "Custom/StylesAppSpecific/Controls/AddEditWrapper";
import { AddEditButtonRow } from "Custom/StylesAppSpecific/Controls/AddEditButtonRow";
import { AppUrls } from "Custom/Globals";
import { StoresInstance } from "Custom/Stores";
import { Main, ClientBox, ClientAddressBox, ClientContactBox } from "Custom/Views/Clients/ClientAddEdit.style";
import { BackBox, BackToList, BackToListText } from "Custom/StylesAppSpecific/Controls/BackToList";
import { SuccessBtn } from "Custom/StylesAppSpecific/Controls/SuccessBtn";
import { CancelBtn } from "Custom/StylesAppSpecific/Controls/CancelBtn";
import { Box, Typography } from "@material-ui/core";
import NavigatePrevIcon from "@material-ui/icons/NavigateBefore";
import { DetailContainer } from "Custom/StylesAppSpecific/Controls/DetailsContainer";
import { ContractorAddress } from "Custom/Components/Address/ContractorAddress";
import { RoofcareInput } from "Custom/StylesAppSpecific/Controls/RoofcareInput";
import { AdminAddEditBox } from "Custom/StylesAppSpecific/AdminStyling";
import { ContractorContacts } from "./ContractorContacts";
import { AddressModel } from "Custom/Models/Domain/Address/AddressModel";
import { useLoadCall } from "../../Utils/useLoadCall";
import { isGuid } from "Custom/Utils/isGuid";
import { Loader } from "Core/Components";
import { NotFoundModal } from "Custom/Components/NotFoundModal/NotFoundModal";
import { MainBackToListWrapper } from "../Admin/Condition/Condition.style";
const domainStores = StoresInstance.domain;

export const ContractorAddEdit: React.FC = () => {
    const [viewModel] = useState(() => new ContractorViewModel());
    const [errorMessage, setErrorMessage] = useState("");
    const [saveText, setSaveText] = useState("Save contractor");
    const { history, match } = useRouter();
    const [loader, setLoader] = useState(false);
    const [titleText, setTitleText] = useState("Add Contractor");
    let { id } = match.params as any;

    const notFound = useLoadCall(
        async () => {
            if (id === "0") {
                setTitleText("Add Contractor");
                return true;
            } else {
                await viewModel.loadContractorAsync(id);
                setTitleText("Edit Contractor");
                return !!viewModel.getContractor.getValue("id");
            }
        },
        { customCheck: () => id === "0" || isGuid(id) },
    );

    const backToList = () => {
        // ideally but could be from anywhere history.goBack();
        // Q) Should we splat the user at this point
        history.push(AppUrls.Client.Main.Contractor.Root);
    };

    const changeName = (e: any) => {
        viewModel.setName(e.target.value);
    };

    const setAddress = (address: AddressModel): void => {
        viewModel.setAddress(address);
    };

    const renderPage = () => {
        if (viewModel.IsLoading || notFound) return <Loader />;

        return (
            <DetailContainer>
                <>
                    <MainBackToListWrapper>
                        <BackToList onClick={backToList}>
                            <BackBox>
                                <NavigatePrevIcon fontSize="large" />
                            </BackBox>
                            <BackToListText>Back to contractor list</BackToListText>
                        </BackToList>
                    </MainBackToListWrapper>

                    <div className="resetMsg">{viewModel.IsLoading ? "Resetting..." : ""}</div>
                    {
                        <AddEditWrapper>
                            <form onSubmit={viewModel.doSubmit} id="detailsPanelForm">
                                <DetailContainer>
                                    {viewModel.errorMessage !== "" && (
                                        <Typography variant="caption" style={{ color: "red" }}>
                                            {viewModel.errorMessage}
                                        </Typography>
                                    )}
                                    <>
                                        <Typography variant="h1" component="h2">
                                            {titleText}
                                        </Typography>
                                    </>
                                    <AdminAddEditBox>
                                        <Box display="flex">
                                            <Box className="wide-input" maxWidth="465px" width="50%" paddingTop="8px">
                                                <RoofcareInput<ContractorViewModel>
                                                    type="text"
                                                    label="Contractor Name"
                                                    validateOnBlur={true}
                                                    viewModel={viewModel}
                                                    fieldName="contractor.contractorName"
                                                    shrink={true}
                                                    maxLength={128}
                                                />
                                            </Box>
                                        </Box>
                                        <ClientAddressBox>
                                            <ContractorAddress viewModel={viewModel} title="Contractor Address" setAddress={setAddress} errorMessage={""} />
                                        </ClientAddressBox>
                                    </AdminAddEditBox>
                                    <ClientContactBox style={{ marginTop: "15px" }}>
                                        <ContractorContacts contractorViewModel={viewModel} />
                                    </ClientContactBox>
                                    <AddEditButtonRow>
                                        <SuccessBtn id="save" variant="contained" color="primary" type="submit" disabled={loader}>
                                            {!loader ? saveText : "Saving"}
                                        </SuccessBtn>
                                        <span style={{ paddingRight: "20px" }} />
                                        <CancelBtn id="cancel" variant="contained" color="secondary" onClick={backToList}>
                                            Cancel
                                        </CancelBtn>
                                    </AddEditButtonRow>
                                </DetailContainer>
                            </form>
                        </AddEditWrapper>
                    }
                </>
            </DetailContainer>
        );
    };

    return useObserver(() => (
        <>
            <NotFoundModal returnRoute={AppUrls.Client.Main.Contractor.Root} open={notFound} />
            {renderPage()}
        </>
    ));
};
