import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
    colourBlockWrapper: {
        //paddingRight: "15px",
    },
    colourBlock: {
        verticalAlign: "middle",
        backgroundColor: (props) => (props as ICustomColourBoxProps).backgroundColour,
        color: (props) => (props as ICustomColourBoxProps).textColour,
        padding: "5px 16px",
        fontSize: "10px",
        textAlign: "center",
        marginTop: "5px",
        cursor: "default",
        "&:hover": {
            cursor: "pointer",
        },
    },
});

interface ICustomColourBoxProps {
    backgroundColour: string;
    textColour: string;
    message: string;
}
export const CustomColourBox: React.FC<ICustomColourBoxProps> = (props: ICustomColourBoxProps) => {
    const classes = useStyles(props);

    return <div className={classes.colourBlock}>{props.message}</div>;
};
