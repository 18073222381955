import { Main } from "./Clients/Client.styles";
import styled from "styled-components";
import { AppBar, Box, Drawer, Toolbar } from "@material-ui/core";
import AppHeaderImage from "../Content/AppHeaderImage.jpg";

export const RoofcareHeader = styled(AppBar)`
    background-color: white !important;
    color: black;
`;

interface IHeaderToolbarProps {
    hideborders?: boolean;
}

export const HeaderToolbar: any = styled(Toolbar)<IHeaderToolbarProps>`
    height: 70px;
    background-color: ${(props) => props.theme.app.colors.orange.dark};
    color: ${(props) => props.theme.app.colors.white.main};

    .MuiListItem-root {
        color: ${(props) => props.theme.palette.text.secondary};
        border-left: 1px solid ${(props) => props.theme.app.colors.orange.main};
        border-right: 1px solid ${(props) => props.theme.app.colors.orange.main};

        :hover {
            background-color: ${(props) => props.theme.app.colors.orange.darker};
        }
    }

    background: url(${AppHeaderImage}) no-repeat;
`;

export const HeaderImage: any = styled(Box)`
    padding-top: 0px;
    //margin: 0 15px;
    cursor: default;

    &:hover {
        cursor: pointer;
    }
`;

export const HeaderDivider: any = styled(Box)`
    top: 24px;
    //left: 171px;
    width: 1px;
    height: 40px;
    background: #000000 0% 0% no-repeat padding-box;
    opacity: 1;
    margin: 0 15px;
`;

export const HeaderTitle: any = styled(Box)`
    top: 24px;
    color: ${(props) => props.theme.app.colors.white.main};
    text-align: left;
    font: normal normal normal 14px/18px "Mont-Regular";
    letter-spacing: -0.56px;
    opacity: 1;
    display: flex;
    flex: 1 0 0;
`;

export const HeaderDrawer: any = styled(Drawer)`
    text-align: left;
    font: normal normal normal 14px/18px "Mont-Regular";
    letter-spacing: -0.56px;
    color: ${(props) => props.theme.app.colors.white.main};
    opacity: 1;
    min-width: 150px;

    .chevron {
        //fill: ${(props) => props.theme.app.colors.red}!important;
    }

    .MuiDrawer-paper {
        min-width: 150px;
        display: flex;

        > div.header-btn {
            min-width: 130px;
            display: flex;
            justify-content: center;
        }
    }
`;
