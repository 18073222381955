import * as React from "react";
import * as MobXReact from "mobx-react-lite";
import * as MobX from "mobx";
import { InstallationMapMapContainer } from "./InstallationMap.styles";
import { IMarker, RoofcareMap } from "Custom/Components/Map/Map";
import { useTheme } from "@material-ui/core";
import { InstallationMapModel, UnitStatus } from "Custom/Models/Domain";
import { Loader } from "Core/Components";
import { InstallationMapViewModel } from "./InstallationMapViewModel";
import { PropertyMapItemModelDTO } from "Custom/Models/Domain/Properties/PropertyMapItemModel";
import { isNullOrUndefined } from "Custom/Utils/utils";
import { ColouredCheckbox, MapPopup, ShowingOnMapContainer } from "Custom/StylesAppSpecific/CommonMap.styles";
interface IProps {
    viewModel: InstallationMapViewModel;
    properties: PropertyMapItemModelDTO[];
    showContractor: boolean;
    showClient: boolean;
}

export const InstallationMap: React.FC<IProps> = ({ viewModel, properties, showClient, showContractor }) => {
    const theme = useTheme();

    React.useEffect(() => {
        return () => {
            // Clean up after yourself
        };
    }, []);

    const handleToggleShowingWarning = (status: UnitStatus) => {
        MobX.runInAction(() => {
            // TODO CMR viewModel.showingWarning[status] = !viewModel.showingWarning[status];
        });
    };

    const renderShowingOnMapControl = () => {
        return (
            <ShowingOnMapContainer>
                <span>Showing on map:</span>
                {/* <ColouredCheckbox
                    checked={viewModel.showingWarning[InstallationStatus.GreenWarning]}
                    customcolour={theme.app.colors.deviceWarning.green.main}
                    onChange={() => handleToggleShowingWarning(InstallationStatus.GreenWarning)}
                />
                <span className="label">Green warning</span>
                <ColouredCheckbox
                    checked={viewModel.showingWarning[InstallationStatus.AmberWarning]}
                    customcolour={theme.app.colors.deviceWarning.amber.main}
                    onChange={() => handleToggleShowingWarning(InstallationStatus.AmberWarning)}
                />
                <span className="label">Amber warning</span>
                <ColouredCheckbox
                    checked={viewModel.showingWarning[InstallationStatus.RedWarning]}
                    customcolour={theme.app.colors.deviceWarning.red.main}
                    onChange={() => handleToggleShowingWarning(InstallationStatus.RedWarning)}
                />
                <span className="label">Red warning</span> */}
                {/*                 <ColouredCheckbox
                    checked={viewModel.showingWarning[InstallationStatus.FloodWarning]}
                    customcolour={theme.app.colors.deviceWarning.flood!}
                    onChange={() => handleToggleShowingWarning(InstallationStatus.FloodWarning)}
                />
                <span className="label">Flood warning</span> */}
            </ShowingOnMapContainer>
        );
    };

    const displayPropertyDetails = (marker: IMarker): JSX.Element => {
        const device: InstallationMapModel | undefined = viewModel.getInstallation(marker.id);

        if (device !== undefined) {
            const property: PropertyMapItemModelDTO | undefined = properties.find((a) => a.id === device.roofcareAddressId);

            if (property !== undefined) {
                if (showContractor === true) {
                    if (isNullOrUndefined(device.contractorName) === true || device.contractorName.length === 0) {
                        showContractor = false;
                    }
                }

                if (showClient === true) {
                    if (isNullOrUndefined(property.clientName) === true || property.clientName.length === 0) {
                        showClient = false;
                    }
                }

                return (
                    <MapPopup>
                        {showClient && !showContractor && <div className="title">{device.name + " (" + property.clientName + ")"}</div>}
                        {!showClient && showContractor && <div className="title">{device.name + " (" + device.contractorName + ")"}</div>}
                        {!showClient && !showContractor && <div className="title">{device.name}</div>}
                        <div className="address">{property.propertyAddress}</div>
                        {showClient && showContractor && (
                            <>
                                <div className="title">{device.name + " (" + property.clientName + ")"}</div>
                                <div className="title">{device.contractorName}</div>
                            </>
                        )}
                    </MapPopup>
                );
            } else {
                return (
                    <MapPopup>
                        <div>{device.name}</div>
                    </MapPopup>
                );
            }
        }

        return (
            <MapPopup>
                {marker.position[0]}, {marker.position[1]}
            </MapPopup>
        );
    };

    const renderPage = () => {
        if (viewModel.IsLoading) return <Loader />;

        return (
            <InstallationMapMapContainer>
                <div className="mapsection">
                    <RoofcareMap
                        id="install-map"
                        controls={[{ key: "showing-on-map", control: renderShowingOnMapControl() }]}
                        boundsToFit={viewModel.mapBounds}
                        markers={viewModel.markers}
                        displayPopUp={displayPropertyDetails}
                    />
                </div>
            </InstallationMapMapContainer>
        );
    };

    return MobXReact.useObserver(() => renderPage());
};
