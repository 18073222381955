import { Stores } from "Custom/Stores";

export const requireContractorLogin = (store: Stores): boolean => {
    let retVal = false;

    if (store.domain.AccountStore.containsRoles(["contractor", "contractoradmin"])) {
        retVal = true;
    }

    return retVal;
};

export const requireNonAdminContractorLogin = (store: Stores): boolean => {
    let retVal = false;

    if (store.domain.AccountStore.containsRoles(["contractor"])) {
        retVal = true;
    }

    return retVal;
};

export const requireClientLogin = (store: Stores): boolean => {
    let retVal = false;

    if (store.domain.AccountStore.containsRoles(["client"])) {
        retVal = true;
    }

    return retVal;
};

export const requireAnyLoggedIn = (store: Stores): boolean => {
    let retVal = false;

    if (store.domain.AccountStore.containsRoles(["admin", "client", "contractoradmin", "contractor", "regionalsalesmanager"])) {
        retVal = true;
    }

    return retVal;
};

export const requireRegionalSalesManagerLogin = (store: Stores): boolean => {
    let retVal = false;

    if (store.domain.AccountStore.containsRoles(["regionalsalesmanager"])) {
        retVal = true;
    }

    return retVal;
};

export const requireContractorAdminLogin = (store: Stores): boolean => {
    let retVal = false;

    if (store.domain.AccountStore.containsRoles(["contractoradmin"])) {
        retVal = true;
    }

    return retVal;
};

export const requireContractorAdminorAdminLogin = (store: Stores): boolean => {
    let retVal = false;

    if (store.domain.AccountStore.containsRoles(["contractoradmin"]) || store.domain.AccountStore.containsRoles(["admin"])) {
        retVal = true;
    }

    return retVal;
};

export const requireAdminLogin = (store: Stores): boolean => {
    let retVal = false;

    // 15/04/2021 Regional Sales Manager has the same rights as admin.
    if (store.domain.AccountStore.containsRoles(["admin", "regionalsalesmanager"])) {
        retVal = true;
    }

    return retVal;
};

export const requireNoLoggedIn = (store: Stores): boolean => {
    return true;
};
