import { Box, Container, Fade, FormHelperText, Input, InputLabel } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import { Loader } from "Core/Components";
import { useObserver } from "mobx-react-lite";
import { AdminMiscViewModel } from "./AdminMiscViewModel";
import { AdminTabPageViewModel } from "../AdminTabPageViewModel";
import { ApiResult } from "Core/Models";
import { AdminMiscDTO } from "./AdminMiscModel";
import { AdminDetailContainer, ButtonSectionBox, DetailWrapper } from "../Condition/Condition.style";
import { SuccessBtn } from "Custom/StylesAppSpecific/Controls/SuccessBtn";
import { CancelBtn } from "Custom/StylesAppSpecific/Controls/CancelBtn";
import { generateID } from "Custom/Utils/utils";
import { AdminRow, InputControlWrapper } from "./AdminMiscTab.style";

interface IProps {
    parentViewModel: AdminTabPageViewModel;
}

export const AdminMiscTab: React.FunctionComponent<IProps> = ({ parentViewModel }) => {
    const [viewModel] = useState(() => new AdminMiscViewModel());
    const [canSave, setCanSave] = useState(false);

    //useEffect below only gets run on initial render
    useEffect(() => {
        // *optional* passing the router into the viewmodel so we can navigate from there
        // console.log("First render");

        let promise: Promise<ApiResult<AdminMiscDTO | undefined>> = viewModel.loadMisc();

        promise.then((result) => {
            if (result.wasSuccessful === true) {
                setCanSave(true);
            }
        });

        // This gets run when the page is exited
        return () => {
            // console.log("Unmounting");
        };
    }, []);

    function renderPage() {
        if (viewModel.IsLoading === true) return <Loader />;

        const onSave = (exit: boolean): void => {
            if (viewModel.isModelValid() == true) {
                let promise: Promise<ApiResult<AdminMiscDTO | undefined>> = viewModel.upsertMisc();

                promise.then((result) => {
                    if (result.wasSuccessful === true) {
                        setCanSave(true);
                    }
                });
            }
        };

        const handleBack = () => {
            let promise: Promise<ApiResult<AdminMiscDTO | undefined>> = viewModel.loadMisc();

            promise.then((result) => {
                if (result.wasSuccessful === true) {
                    setCanSave(true);
                }
            });
        };
        const handleFocus = (event: any) => event.target.select();

        const onValueChange = (e: any) => {
            // Stop the control passing us a number as text
            let value: number = e.target.value;

            // BUT that filters out '-' which comes through as '' so
            // check for both and allow through!
            if (e.target.value === "-" || e.target.value === "") {
                value = e.target.value;
            } else {
                value = Number(e.target.value);
            }

            viewModel.setValue("minSensorTemperature", value);
        };

        const isInError = (fieldName: string) => {
            let isValid = viewModel.getValid(fieldName);
            return !isValid;
        };

        return (
            <AdminDetailContainer>
                <DetailWrapper>
                    <AdminRow>
                        <InputLabel className={"input-label"} id={"text-label-" + generateID()}>
                            Min Sensor Reading temperature:
                        </InputLabel>
                        <InputControlWrapper>
                            <Input
                                className="form-control"
                                type="number"
                                value={viewModel.getValue("minSensorTemperature")}
                                onChange={onValueChange}
                                onFocus={handleFocus}
                                inputProps={{
                                    placeholder: "Sensor Min temperature ",
                                    maxLength: 4,
                                    min: -200,
                                    max: 200,
                                }}
                            />
                            {/* !firstTime &&  */ isInError("value") && <FormHelperText style={{ color: "red" }}>{viewModel.getError("minSensorTemperature")}</FormHelperText>}
                        </InputControlWrapper>
                    </AdminRow>
                    <ButtonSectionBox>
                        <SuccessBtn disabled={!canSave} onClick={() => onSave(false)}>
                            Save
                        </SuccessBtn>
                        <CancelBtn onClick={handleBack}>Reset</CancelBtn>
                    </ButtonSectionBox>
                </DetailWrapper>
            </AdminDetailContainer>
        );
    }

    return useObserver(() => renderPage());
};
