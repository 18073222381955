// Libraries
import * as MobX from "mobx";

// Core
import { FieldType } from "../../Core/Utils/Utils";
import { ViewModelBase } from "../../Core/ViewModels/ViewModelBase";

import { EditableCopy } from "./../Models/EditableCopy";
import { EditableCopy as EditableCopyDomain } from "../Models/Domain/EditableCopy";
import { DomainStores } from "./../Stores/DomainStores";
import { StoresInstance } from "../Stores/Stores";

export class EditableCopyViewModel extends ViewModelBase {
    constructor() {
        super(null);
    }
    public getEditableCopyByRouteAndPlaceholderNumber = (route: string, placeholderNumber: number) => {
        let editableCopyRoute = StoresInstance.domain.editableCopyRoutes.find((r) => r.route === route);

        if (editableCopyRoute !== undefined) {
            let editableCopyRouteId: string = editableCopyRoute.id;
            return StoresInstance.domain.editableCopies.find((c) => c.routeId === editableCopyRouteId && c.placeholderNumber === placeholderNumber)?.copyValue as string;
        }

        return "";
    };

    // #region Viewmodel Boilerplate

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    public isFieldValid(fieldName: keyof FieldType<EditableCopyViewModel>): boolean {
        return true;
    }

    // #endregion Viewmodel Boilerplate
}
