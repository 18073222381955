import styled from "styled-components";
import { Box } from "@material-ui/core";

export const NotesHeader: any = styled(Box)`
    margin-top: 10px;
    color: white;
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    width: 100%;
    border-bottom: 1px solid white;
    .titleRow {
        display: flex;
        flex: 1 0 auto;
        flex-direction: row;
        width: 100%;
        padding: 23px 0 15px 0;
    }

    .notes {
        font-size: 18px;
        font-weight: 700;
        font-family: "Mont-Bold", "Montserrat", "Roboto Condensed", "Helvetica", "Arial", "sans-serif";
    }

    .note-textarea {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
        min-height: 200px;
        max-height: 40vh;
        border-radius: 0;
        outline: none;
        padding: 5px;
        font-size: 1.4359em;
        font-family: "Mont-Regular", "Montserrat", "Roboto Condensed", "Helvetica", "Arial", "sans-serif";
        overflow-y: auto !important;
        margin-top: 9px;
        color: #000000bf;
        margin-bottom: 15px;

        :focus {
            border-color: black;
        }
    }
`;

export const NotesTable: any = styled(Box)`
    margin-top: 10px;
    color: white;
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    width: 100%;
    padding-bottom: 30px;

    .notes {
        font-size: 18px;
        font-weight: normal;
        letter-spacing: 0px;
        color: #ffffff;
    }

    .titleRow {
        display: flex;
        flex: 1 0 auto;
        flex-direction: row;
        width: 100%;
        padding: 23px 0px;

        .notes {
            font-size: 18px;
            font-weight: 700;
            font-family: "Mont-Bold", "Montserrat", "Roboto Condensed", "Helvetica", "Arial", "sans-serif";
        }
    }

    .link-addedit {
        padding-left: 10px;
        padding-top: 5px;
        display: flex;
        flex: 1 0 auto;
        flex-direction: row;

        img {
            height: 16px;
            width: 16px;
        }
    }

    .add-note {
        font-size: 10px;
        padding-left: 8px;
        padding-top: 1px;
        color: ${(props) => props.theme.app.colors.blue.light};
    }

    .notes-row {
        .item-divider {
            border-bottom: 1px dashed #cdcccc;
            margin-bottom: 29px;
            padding-bottom: 21px;
        }
    }

    .item-divider {
        border-bottom: 1px dashed #cdcccc;
        margin-bottom: 29px;
        padding-bottom: 21px;
    }
`;

export const NotesTableRow: any = styled(Box)`
    color: white;
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    width: 100%;

    .notetablerow {
        display: flex;
        flex: 1 0 auto;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;

        cursor: default;

        .editbtn:hover {
            cursor: pointer;
        }
    }

    .title {
        font-weight: 700;
        font-family: "Mont-Bold", "Montserrat", "Roboto Condensed", "Helvetica", "Arial", "sans-serif";
    }

    .note {
        padding-top: 15px;
        font-weight: 300;
    }
`;

export const Addeditsection: any = styled(Box)`
    padding-top: 5px;
    display: flex;
    flex: 1 0 auto;
    flex-direction: row;

    img {
        height: 16px;
        width: 16px;
    }

    .add-note {
        font-size: 10px;
        padding-left: 8px;
        padding-top: 1px;
        color: ${(props) => props.theme.app.colors.blue.light};
    }
`;
