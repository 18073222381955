import styled from "styled-components";
import { Box } from "@material-ui/core";

export const ConditionPanel: any = styled(Box)`
    background-color: #565758;
    padding: 15px;
    margin: 15px 0px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
`;

export const ConditionPanelHeading: any = styled(Box)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #b2b2b2;
    font-size: 14px;
    padding: 0px 0px 8px 0px;
    margin: 0px 0px 12px 0px;

    > div {
        display: flex;
        width: 60px;
        justify-content: space-between;
    }
`;

export const ConditionPanelTable: any = styled(Box)``;

export const ConditionPanelActions: any = styled(Box)`
    display: flex;
    flex-direction: row;
    margin-top: 10px;

    > div:last-child {
        margin-left: auto;
    }
`;

export const ConditionPanelTableHeadings: any = styled(Box)`
    display: flex;
    flex-direction: row;
    font-size: 14px;
    margin-bottom: 20px;

    > div {
        &:nth-of-type(1) {
            min-width: 190px;
            width: 190px;
            max-width: 190px;
        }

        &:nth-of-type(2) {
            min-width: 210px;
            width: 210px;
            max-width: 210px;
        }

        &:nth-of-type(3) {
            min-width: 150px;
            width: 150px;
            max-width: 150px;
        }
    }
`;

export const CustomColourBoxWrapper: any = styled.div`
    padding-right: 15px;
`;

export const ConditionPanelTableRows: any = styled(Box)`
    > div {
        display: flex;
        flex-direction: row;
        padding: 12px 0px;
        border-bottom: 1px dashed #b2b2b2;
        font-size: 12px;
        align-items: center;

        > div {
            &:nth-of-type(1) {
                min-width: 190px;
                width: 190px;
                max-width: 190px;
            }

            &:nth-of-type(2) {
                min-width: 210px;
                width: 210px;
                max-width: 210px;
            }

            &:nth-of-type(3) {
                min-width: 150px;
                width: 150px;
                max-width: 150px;
            }
        }
    }
`;
