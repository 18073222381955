import { Button, Card, CardActions, CardHeader, CardMedia, Modal } from "@material-ui/core";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import PhotoOutlinedIcon from "@material-ui/icons/PhotoOutlined";
import PhotoRoundedIcon from "@material-ui/icons/PhotoRounded";

import CloudDownloadIcon from "@material-ui/icons/CloudDownload";

import React, { useEffect } from "react";
import { useObserver } from "mobx-react-lite";
import styled from "styled-components";

import { InstallationImageViewModel2 } from "./InstallationImageViewModel";
import { ImageModalViewBox } from "Custom/Views/Installations/InstallationView.style";
import { gsap } from "gsap";

interface IProps {
    viewModel: InstallationImageViewModel2;
    className?: string;
    showFlipOption?: boolean;
}

export const InstallationImagePanelDialogBase: React.FC<IProps> = (props) => {
    let zoom: Element | null = document.querySelector("#zoom");
    let zoomImg: any | null = document.querySelector("#zoom-img");
    let mainImg: any | null = document.querySelector("#main-img");
    let enterTL = gsap.timeline({ paused: true });
    let timer: gsap.core.Tween = gsap.delayedCall(5, () => enterTL.reverse()).pause();
    let cx: number;
    let cy: number;
    let ratioX: number;
    let ratioY: number;

    useEffect(() => {
        // *optional* passing the router into the viewmodel so we can navigate from there

        //This gets run when the page is exited
        return () => {
            if (mainImg != null && window != null) {
                window.removeEventListener("resize", resize);
                mainImg.removeEventListener("mouseleave", leaveAction);
                mainImg.removeEventListener("mousemove", moveAction);
                mainImg.removeEventListener("load", init);
            }
        };
    }, []);

    const init = () => {
        if (mainImg === null) {
            zoom = document.querySelector("#zoom");
            zoomImg = document.querySelector("#zoom-img");
            mainImg = document.querySelector("#main-img");
        }

        zoomImg.src = mainImg.src;
        ratioX = mainImg.naturalWidth / mainImg.clientWidth;
        ratioY = mainImg.naturalHeight / mainImg.clientHeight;

        resize();

        gsap.set([zoom, zoomImg], { xPercent: -50, yPercent: -50 });

        gsap.set(zoom, { autoAlpha: 0, scale: 0 });

        enterTL.to(mainImg, { filter: "grayscale(1)", "-webkit-filter": "grayscale(1)", duration: 0.5 }, 0).to(zoom, { autoAlpha: 1, scale: 1.5, duration: 0.5 }, 0);

        window.addEventListener("resize", resize);
        mainImg.addEventListener("mouseleave", leaveAction);
        mainImg.addEventListener("mousemove", moveAction);
    };

    const leaveAction = () => {
        enterTL.reverse();
    };

    const moveAction = (e: any) => {
        let rect = mainImg.getBoundingClientRect();
        enterTL.play();
        timer.restart(true);

        let x = e.x - rect.left + mainImg.offsetLeft;
        let y = e.y - rect.top + mainImg.offsetTop;

        gsap.set(zoom, { x: x, y: y });

        // original gsap.set(zoomImg, { x: (cx - e.pageX) * ratio, y: (cy - e.pageY) * ratio });
        gsap.set(zoomImg, { x: (cx - e.x) * ratioX, y: (cy - e.y) * ratioY });

        /*         if (props.viewModel.flipped === true) {
            gsap.set(zoomImg, { rotation: 180, x: (cx - e.x) * ratioX, y: (cy - e.y) * ratioY });
        } */
    };

    const resize = () => {
        let rect = mainImg.getBoundingClientRect();
        cx = rect.left + rect.width / 2;
        cy = rect.top + rect.height / 2;
    };

    const onFlipImageClick = () => {
        let promise = props.viewModel.flipImage();

        promise.then(() => {
            if (mainImg != null && window != null) {
                window.removeEventListener("resize", resize);
                mainImg.removeEventListener("mouseleave", leaveAction);
                mainImg.removeEventListener("mousemove", moveAction);
                mainImg.removeEventListener("load", init);
            }

            gsap.set(zoom, { clearProps: true });
            gsap.set(zoomImg, { clearProps: true });
            init();
        });
    };

    const onDownloadClick = async () => {
        props.viewModel.downloadImage();
    };

    const onHandleCloseModal = () => {
        props.viewModel.parentViewModel.setActiveInstallationViewModel();
    };

    const onHandlePrevious = () => {
        props.viewModel.parentViewModel.movePreviousInstallationViewModel();
    };

    const onHandleNext = () => {
        props.viewModel.parentViewModel.moveNextInstallationViewModel();
    };

    const showFlipButton: boolean = props.showFlipOption === undefined ? true : props.showFlipOption;

    return useObserver(() => (
        <Modal className={props.className} open={props.viewModel.parentViewModel.canDisplayActiveInstallation} onClose={onHandleCloseModal}>
            <Card>
                <CardHeader
                    title={
                        <span>
                            <span>{props.viewModel.date}</span>
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                            <span>Time: {props.viewModel.time}</span>
                        </span>
                    }
                    action={
                        <>
                            <Button onClick={onDownloadClick} startIcon={<CloudDownloadIcon />}>
                                Download
                            </Button>
                            {showFlipButton && (
                                <Button onClick={onFlipImageClick} startIcon={props.viewModel.flipped ? <PhotoRoundedIcon /> : <PhotoOutlinedIcon />}>
                                    Flip image {/* {props.viewModel.view} */}
                                </Button>
                            )}
                        </>
                    }
                />
                <ImageModalViewBox className="imageModalViewBox">
                    <img
                        id="main-img"
                        alt="camera image"
                        className={"device-image " + (props.viewModel.flipped === false ? "" : "rotateimg180")}
                        src={props.viewModel.url}
                        style={{ width: "100%", maxWidth: "1280px", objectFit: "cover", marginBottom: "-5px", marginTop: "17px" }}
                        onLoad={init}
                    />
                    <div id="zoom">
                        <img id="zoom-img" className={"device-image " + (props.viewModel.flipped === false ? "" : "rotateimg180")} />
                    </div>
                </ImageModalViewBox>

                <CardActions>
                    <Button disabled={!props.viewModel.parentViewModel.canMovePrevious} onClick={onHandlePrevious} startIcon={<NavigateBeforeIcon />}>
                        {props.viewModel.parentViewModel.canMovePrevious ? (
                            <span>
                                <span>{props.viewModel.parentViewModel.previousDate}</span>
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                <span>Time: {props.viewModel.parentViewModel.previousTime}</span>
                            </span>
                        ) : (
                            ""
                        )}
                    </Button>
                    <Button disabled={!props.viewModel.parentViewModel.canMoveNext} onClick={onHandleNext} endIcon={<NavigateNextIcon />}>
                        {props.viewModel.parentViewModel.canMoveNext ? (
                            <span>
                                <span>{props.viewModel.parentViewModel.nextDate}</span>
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                <span>Time: {props.viewModel.parentViewModel.nextTime}</span>
                            </span>
                        ) : (
                            ""
                        )}
                    </Button>
                </CardActions>
            </Card>
        </Modal>
    ));
};

export const InstallationImagePanelDialog = styled(InstallationImagePanelDialogBase)`
    && {
        background-color: rgba(0, 0, 0, 0.5);

        & .MuiCard-root {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            background-color: #585858;
            border-radius: 4px;
            box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25);
            margin: 15px;
            max-width: 800px;
            max-height: 700px;

            & > .MuiCardHeader-root {
                background-color: rgb(56, 103, 204, 0.43);
                box-shadow: inset 0px 1px 1px rgba(252, 153, 153, 0.2);
                min-height: 50px;

                & > .MuiCardHeader-content {
                    > span {
                        color: white;
                        font-size: 14px;
                    }
                }

                & > .MuiCardHeader-action {
                    margin-bottom: -10px;
                    margin-top: -10px;

                    & > .MuiButton-root {
                        color: white;
                        font-size: 14px;
                    }
                }
            }

            & > .MuiCardMedia-root {
                height: 580px;
            }

            & > .MuiCardActions-root {
                background-color: rgb(56, 103, 204, 0.43);
                box-shadow: inset 0px 1px 1px rgba(252, 153, 153, 0.2);
                justify-content: space-between;
                min-height: 50px;

                & > .MuiButton-root {
                    color: white;
                    font-size: 14px;
                    padding: 10px 12px;
                }
            }
        }

        .rotate180 {
            -webkit-transform: rotate(180deg);
            -moz-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
            -o-transform: rotate(180deg);
            transform: rotate(180deg);
        }
    }
`;
