import { ClientModel, ClientModelDTO } from "./ClientModel";
import { ContactModel, ContactModelDTO } from "Custom/Models/Domain/Contacts/ContactModel";
import { ModelBase } from "Core/Models";
import { action, computed, IObservableArray, observable } from "mobx";
export class ClientAndContactsModel extends ModelBase<ClientAndContactsModel, ClientAndContactsDTO> {
    @observable public client: ClientModel = new ClientModel();
    //Use this format to get computeds tofire when items added / changed
    public contacts: IObservableArray<ContactModel> = observable([]);

    @computed
    public get getContactsLength(): number {
        return this.contacts.slice().length;
    }

    //fromDto is required but you can leave it blank
    @action
    fromDto(model: ClientAndContactsDTO): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model

        this.client.fromDto(model.client);
        this.contacts.length = 0;
        for (let i: number = 0; i < model.contacts.length; i++) {
            let temp: ContactModel = new ContactModel();
            temp.fromDto(model.contacts[i]);
            this.contacts.push(temp);
        }
    }

    //toDto is required but you can leave it blank
    toDto(model: ClientAndContactsDTO): void {
        model.client = {
            buildingName: "",
            addressLine1: "",
            addressLine2: "",
            clientName: "",
            city: "",
            county: "",
            postcode: "",
            isDeleted: false,
            rowVersion: "",
            id: "",
            createdBy: "",
            createdDate: "",
        };

        this.client.toDto(model.client);
        model.contacts = [];

        for (let i: number = 0; i < this.contacts.length; i++) {
            if (this.contacts[i].isDeleted === true && this.contacts[i].id === "") {
                // then no point adding a new deleted item....
            } else {
                const temp = this.contacts[i].getDto();
                model.contacts.push(temp);
            }
        }
    }
}

export type ClientAndContactsDTO = {
    client: ClientModelDTO;
    contacts: ContactModelDTO[];
};
