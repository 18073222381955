import { Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import { ContractorViewModel } from "./ContractorViewModel";
import { useObserver } from "Core/Base";
import { ContactAddRow, ContactListView, ContactModel, ContactViewModel, ContactModel as ComponentContactModel } from "Custom/Components/Contact/";

import { DeleteModal } from "Custom/Components/Modal/DeleteModal";
import { roofcareModalStyle } from "Custom/StylesAppSpecific/ModalStyling";
import { ContactAddModal } from "Custom/Components/Contact/ContactModal.View";

import { ContactListPage, ContactListPaper, InternalListToo, ListWrapper } from "Custom/StylesAppSpecific/Controls/ContactBoxes";

type ContractorContactsProps = {
    contractorViewModel: ContractorViewModel;
};

export const ContractorContacts: React.FunctionComponent<ContractorContactsProps> = (props: ContractorContactsProps) => {
    const [viewModel, setViewModel] = useState(() => new ContractorViewModel());
    const deleteModalClasses = roofcareModalStyle();
    const [contactOpen, setContactOpen] = React.useState(false);
    const [modalContact, setModalContact] = React.useState<ContactModel>(new ContactModel());

    useEffect(() => {
        setViewModel(props.contractorViewModel);
    }, [props.contractorViewModel]);

    const [deleteOpen, setDeleteOpen] = React.useState(false);
    const [selectedContact, selectContact] = React.useState("");

    const handleDeleteOpen = () => {
        setDeleteOpen(true);
    };

    const handleDeleteClose = () => {
        setDeleteOpen(false);
        selectContact("");
    };

    const handleDeleteContact = () => {
        modalContact.isDeleted = true;

        const contactViewModel = new ContactViewModel();
        contactViewModel.setContact(modalContact);
        props.contractorViewModel.updateContact(modalContact);
        setDeleteOpen(false);
        selectContact("");
    };

    const handleDelete = async (contact: ContactModel) => {
        setModalContact(contact);
        handleDeleteOpen();
    };

    const addContact = (model: ContactModel) => {
        props.contractorViewModel.addContact(model);
    };

    const handleSaveContact = async (model: ContactModel) => {
        props.contractorViewModel.updateContact(model);
        setContactOpen(false);
    };

    const handleOpen = async (contact: ContactModel) => {
        setModalContact(contact);
        setContactOpen(true);
    };

    const handleContactClose = () => {
        setContactOpen(false);
    };

    const getContactOptions = (contacts: ComponentContactModel[]) => {
        if (contacts.length == 0) {
            return <div style={{ color: "white" }}>There are currently no contacts added to this client</div>;
        } else {
            let contactList: ContactModel[] = props.contractorViewModel.getComponentContacts;

            return (
                <>
                    <ContactListView contactsList={contactList} onOpen={(c: ContactModel) => handleOpen(c)} onDelete={(c: ContactModel) => handleDelete(c)} />
                </>
            );
        }
    };

    return useObserver(() => (
        <>
            <ContactAddModal onClose={handleContactClose} open={contactOpen} onAdd={handleSaveContact} contact={modalContact} />
            <DeleteModal
                open={deleteOpen}
                onClose={handleDeleteClose}
                onDelete={handleDeleteContact}
                title="Delete Contractor Contact?"
                text="Are you sure you want to delete this contact?"
            />
            <ContactListPage>
                <ContactAddRow addContact={addContact} title={"Contact information"} />
                <ListWrapper>
                    <Typography variant="caption" className="titlePanel">
                        Contacts
                    </Typography>
                    <ContactListPaper variant="outlined">
                        <InternalListToo>{getContactOptions(viewModel.getComponentContacts)}</InternalListToo>
                    </ContactListPaper>
                </ListWrapper>
            </ContactListPage>
        </>
    ));
};
