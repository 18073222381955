import styled from "styled-components";
import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Tabs, Link, Box, Grid, TextField, Paper } from "@material-ui/core";

import sharmanBckgrnd from "../Content/roofcare-login-background.svg";

export const useAdminStyles: any = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
    },
}));

export const PaperAccount: any = styled(Paper)`
    font-family: "Mont";
    background-image: url(${sharmanBckgrnd}); // These make the image disappear no-repeat center center fixed
    background-repeat: no-repeat;
    background-origin: content-box, content-box;
    background-size: cover;
    padding: 28px 21px;
    display: flex;
    position: absolute;
    top: 87px;
    bottom: 0;
    width: 100%;
    height: auto;
    margin: auto;
    text-align: center;
    vertical-align: center;
    background-color: white;
`;

export const AccountBtnBox: any = styled(Box)`
    padding: 15px 0;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
    flex: 1 0 0;
    align-items: flex-start;
`;

export const AccountImageBox: any = styled(Box)`
    justify-content: center;
    min-width: 100%;
    text-align: center;

    img {
        transform: translateX(-150px);
    }
`;

export const LoginStatementBox: any = styled(Box)`
    padding-top: 115px;

    .MuiTypography-caption {
        font-family: "Mont-Bold", "Montserrat", "Roboto Condensed", "Helvetica", "Arial", "sans-serif";
        font-size: 14px;
        color: white;
    }
`;

export const LoginInstructionBox: any = styled(Box)`
    font-weight: normal;
    font-size: 14px;
    padding: 15px 0;
    color: white;
`;
