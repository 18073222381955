import { ModelBase } from "Core/Models";
import { ClientPropertyListItemDTO } from "Custom/Views/ClientView/ClientPropertyListItem";
import * as MobX from "mobx";
import { AddressModel } from "../Address/AddressModel";
import { UnitStatus, UnitStatusDTO } from "../Installations/UnitStatus";
import { Address } from "./Address";

export class Property extends ModelBase<Property, PropertyDTO> {
    @MobX.observable public buildingName = "";
    @MobX.observable public addressLine1: string = "";
    @MobX.observable public addressLine2: string = "";
    @MobX.observable public city: string = "";
    @MobX.observable public county: string = "";
    @MobX.observable public postcode: string = "";
    @MobX.observable public rowVersion: string = "";
    @MobX.observable public note: string = "";

    @MobX.observable public id: string = "";
    @MobX.observable public dateCreated: string = "";
    @MobX.observable public createdBy: string = "";
    @MobX.observable public locationLatitude?: number;
    @MobX.observable public locationLongitude?: number;
    @MobX.observable public okToAddInstallation: boolean = false;
    @MobX.observable public clientId: string = "";
    @MobX.observable public status: UnitStatus = new UnitStatus();

    @MobX.computed get getStatus(): UnitStatus {
        let retVal: UnitStatus = this.status;

        if (retVal === null || retVal === undefined) {
            this.status = new UnitStatus();
        }
        retVal = this.status;

        return retVal;
    }

    //fromDto is required but you can leave it blank
    @MobX.action
    fromDto(model: PropertyDTO): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
    }

    toDto(): void {}

    //toDto is required but you can leave it blank
    toPropertyDtoWithAddress(addr: AddressModel): PropertyDTO {
        const retVal: PropertyDTO = {
            // address
            buildingName: addr.buildingName,
            addressLine1: addr.addressLine1,
            addressLine2: addr.addressLine2,
            city: addr.city,
            county: addr.county,
            postcode: addr.postcode,

            //property base
            id: this.id,
            dateCreated: this.dateCreated,
            CreatedBy: this.createdBy,
            locationLatitude: addr.locationLatitude,
            locationLongitude: addr.locationLongitude,
            okToAddInstallation: this.okToAddInstallation,
            status: this.status,

            //dto
            rowVersion: this.rowVersion,
            clientId: this.clientId,
            note: this.note,
        };

        return retVal;
    }

    @MobX.action
    fromAddressDto(updatedProperty: ClientPropertyListItemDTO) {
        //this.id = updatedProperty.id;
        this.buildingName = updatedProperty.buildingName;
        this.addressLine1 = updatedProperty.addressLine1;
        this.addressLine2 = updatedProperty.addressLine2;
        this.city = updatedProperty.city;
        this.county = updatedProperty.county;
        this.postcode = updatedProperty.postcode;
        this.locationLatitude = updatedProperty.locationLatitude === undefined ? 0 : updatedProperty.locationLatitude!;
        this.locationLongitude = updatedProperty.locationLongitude === undefined ? 0 : updatedProperty.locationLongitude!;
    }

    @MobX.computed
    public get toAddressModel(): AddressModel {
        const retVal: AddressModel = new AddressModel();

        retVal.id = this.id;
        retVal.buildingName = this.buildingName;
        retVal.addressLine1 = this.addressLine1;
        retVal.addressLine2 = this.addressLine2;
        retVal.city = this.city;
        retVal.county = this.county;
        retVal.postcode = this.postcode;
        retVal.locationLatitude = this.locationLatitude === undefined ? 0 : this.locationLatitude!;
        retVal.locationLongitude = this.locationLongitude === undefined ? 0 : this.locationLongitude!;

        return retVal;
    }
}

export interface PropertyBase extends Address {
    id?: string;
    dateCreated?: string;
    CreatedBy?: string;
    locationLatitude?: number;
    locationLongitude?: number;
    okToAddInstallation: boolean;
}

export interface PropertyDTO extends PropertyBase {
    /*     occupierId?: number; */
    rowVersion?: string;
    clientId: string;
    note?: string;
    /*     occupierIsTenant: boolean;
    occupierEndOfLeaseMonth?: number;
    occupierEndOfLeaseYear?: number;

    perimeter?: number;
    eavesHeight?: number;
    roofSubstrate: RoofSubstrate;
    isSurvayed: boolean; */

    status: UnitStatusDTO;
}

export class PropertyHelpers {
    public static getAddress = (property: Property): Address => {
        const retVal: Address = {
            buildingName: property.buildingName,
            addressLine1: property.addressLine1,
            addressLine2: property.addressLine2,
            city: property.city,
            county: property.county,
            postcode: property.postcode,
        };
        return retVal;
    };
}
