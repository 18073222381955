import { BooleanLiteral } from "typescript";

export enum ImageType {
    General = 1,
    Drainage = 2,
}

export interface InstallationCommissionImage {
    id: number;
    installationCommissionedId: number;
    imageUrl: string;
    originalImageUrl: string;
    thumbnailImageUrl: string;
    ordinal: number;
    isDeleted: boolean;
    createdOn: string;
    imageTypeId: ImageType;
    flipped: boolean; // not implemented
}
