import * as React from "react";
import * as Mui from "@material-ui/core";
import { ResetSearchIcon } from "Custom/Components/Table/ResetSearchIcon";
import { Container } from "Custom/StylesAppSpecific/AppStyling";
import { RoofcareTable, RoofcareTableWrapper } from "Custom/Components/Table/RoofcareTable";
import { formatAddress } from "Custom/Utils/format";
import { useRouter } from "Core/Utils";
import { AppUrls } from "Custom/Globals";
import { ClickableImgCell } from "Custom/Components/Table/ClickableImgCell";
import EditLogo from "Custom/Content/editIcon.svg";
import { PropertyAddModal } from "Custom/Views/Projects/PropertyAddModal";
import { AddressModel, Property } from "Custom/Models/Domain";
import { OSMLocation } from "Custom/Utils/OSM";
import { OSMViewModel } from "Custom/ViewModels/OSMViewModel";
import { useObserver } from "Core/Base";
import { ClientDetailsViewModel } from "./ClientDetailsViewModel";
import { ClientPropertyListItem } from "Custom/Views/ClientView/ClientPropertyListItem";
interface IProps {
    viewModel: ClientDetailsViewModel;
}

export const ClientDetailsPropertyList: React.FC<IProps> = ({ viewModel }) => {
    const theme = Mui.useTheme();
    const [propertyOpen, setPropertyOpen] = React.useState(false);
    const [osmViewModel] = React.useState(() => OSMViewModel.Instance);
    const [propertyAddress, setPropertyAddress] = React.useState<AddressModel>(new AddressModel());

    const router = useRouter<{ clientId?: string }>();

    const handleRowClick = (rowData: any) => {
        const { history } = router;
        const { path } = router.match;
        const { clientId } = router.match.params;

        let url: string = AppUrls.Client.ClientView.Property.PropertyDetail.replace(":propertyId", rowData.id);

        switch (path) {
            case AppUrls.Client.Main.Client.ClientsDetail: {
                url = AppUrls.Client.Main.Client.Property.ClientsDetailProperty.replace(":clientId", clientId!);
                break;
            }
            default: {
                break;
            }
        }

        history.push(url.replace(":propertyId", rowData.id));
    };

    const formatEdit = (id: string): any => {
        return <ClickableImgCell logo={EditLogo} text={"Edit property"} onClick={editPropertyClick} id={id} />;
    };

    const editPropertyClick = (id: string) => {
        const editThis: ClientPropertyListItem | undefined = viewModel.getProperties.find((a: ClientPropertyListItem) => a.id === id);

        if (editThis !== undefined) {
            setPropertyAddress(editThis.toAddressModel);
            setPropertyOpen(true);
        }
    };

    const handlePropertyClose = () => {
        setPropertyOpen(false);
    };

    const handleSaveProperty = async (addr: AddressModel) => {
        if (addr.locationLatitude === 0 && addr.locationLongitude === 0) {
            // Then search for the lat and long
            osmViewModel.getLocationForPostcode(addr.postcode).then(async (result: OSMLocation) => {
                addr.setValue("locationLatitude", result.latitude);
                addr.setValue("locationLongitude", result.longitude);

                const ret: boolean = await viewModel.updatePropertyAddress(addr);

                if (ret === true) {
                    setPropertyOpen(false);
                }
            });
        } else {
            const retVal: boolean = await viewModel.updatePropertyAddress(addr);

            if (retVal === true) {
                setPropertyOpen(false);
            }
        }
    };

    return useObserver(() => (
        <Container>
            {/* <RoofcareTableWrapper> */}
            <PropertyAddModal address={propertyAddress} onClose={handlePropertyClose} open={propertyOpen} onAdd={handleSaveProperty} onChange={setPropertyAddress} />
            <RoofcareTable
                columns={[
                    {
                        title: "Address",
                        field: "addressLine1",
                        sorting: true,
                        filtering: true,
                        render: (rowData: any) => {
                            return formatAddress(rowData, true);
                        },
                        width: "auto",
                    },
                    { title: "Installations", field: "installationCount", sorting: true, filtering: false, width: "auto" },
                    {
                        title: "",
                        field: "id",
                        sorting: false,
                        filtering: false,
                        render: (rowData: any) => {
                            if (rowData !== null && rowData !== undefined) {
                                return formatEdit(rowData.id);
                            }
                        },
                        width: 100,
                    },
                ]}
                style={{
                    backgroundColor: " #585858",
                    padding: "0",
                    borderRadius: "0",
                }}
                options={{
                    thirdSortClick: false,
                    cellStyle: {
                        fontSize: 10,
                    },
                    pageSize: 50,
                    pageSizeOptions: [10, 20, 50, 100],
                    emptyRowsWhenPaging: false,
                    containerStyle: {
                        background: " #495D89 0% 0% no-repeat padding-box",
                    },
                    headerStyle: {
                        background: " #495D89 0% 0% no-repeat padding-box",
                        boxShadow: " inset 0px 1px 1px #FFFFFF40",
                        color: "#fff",
                        fontFamily: ["Mont-Bold", "Montserrat", "Roboto Condensed", "Helvetica", "Arial", "sans-serif"].join(","),
                    },
                    rowStyle: {
                        backgroundColor: "#525355",
                        color: "#fff",
                        fontSize: 24,
                    },
                    footerStyle: {
                        backgroundColor: "#525355",
                        color: "#fff",
                        fontSize: 24,
                    },
                    searchFieldAlignment: "left",
                    searchFieldStyle: {
                        border: "1px solid #333333",
                        borderRadius: "5px",
                        width: "400px",
                    },
                }}
                data={viewModel.properties}
                title=""
                icons={{
                    ResetSearch: () => (
                        <span style={{ marginRight: "-12px" }}>
                            <ResetSearchIcon />
                        </span>
                    ),
                }}
                onRowClick={(_: any, rowData: any) => handleRowClick(rowData)}
                components={{ Container: (props: any) => <Mui.Paper {...props} elevation={0} /> }}
            />
            {/* </RoofcareTableWrapper> */}
        </Container>
    ));
};
