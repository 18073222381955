import styled from "styled-components";

export const DashboardListMapContainer = styled.div`
    padding-top: 10px;
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;

    @media screen and (max-width: 1200px) {
        flex-direction: column;
    }

    @media screen and (min-width: 1200px) {
        flex-direction: row;
    }

    .content {
        flex: 1 1 auto;
        flex-direction: row;
    }

    .MuiPaper-root {
        display: flex !important;
        flex: 1 0 0;
        flex-direction: column;
        background: transparent linear-gradient(180deg, #565758 0%, #373a40 100%) 0% 0% no-repeat padding-box;
    }
`;

export const DashBoardListContainer = styled.div`
    display: flex;
    flex: 1 0 auto;
    flex-direction: row;

    padding-right: 0 !important;

    @media screen and (max-width: 1200px) {
        //max-width: 70%;
        width: 100%;
    }

    @media screen and (min-width: 1200px) {
        max-width: 70%;
    }

    // background-color: orange;
`;

export const DashBoardMapContainer = styled.div`
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    //background-color: yellow;
    margin-left: 15px;

    @media screen and (max-width: 1200px) {
        margin-top: 15px;
        width: 100%;
    }

    @media screen and (min-width: 1200px) {
        max-width: 30%;
    }

    .unittitle {
        display: flex;
        flex: 1 1 auto;
        flex-direction: row;
        justify-content: space-between;
        position: relative;

        box-shadow: rgba(255, 255, 255, 0.25) 0px 1px 1px inset;

        border-top-left-radius: 10px;
        border-top-right-radius: 10px;

        border-top: unset;
        border-bottom: unset;

        background: #495d89 0% 0% no-repeat padding-box;
        color: white;

        > div {
            margin: auto 0;
        }

        .title {
            padding-top: 3px;
        }

        @media screen and (max-width: 1384px) {
            //min-height: 71px !important;
        }

        @media screen and (max-width: 640px) {
        }

        @media screen and (min-width: 1024px) {
            //min-height: 20px;
            max-height: 54px;
        }

        @media screen and (max-width: 640px) {
        }

        .layer-select {
            right: 20px;
            top: 17px;
            position: absolute;
            display: flex;
            flex: 1 1 auto;
            flex-direction: row;
            max-width: 250px;
            .control-label {
                padding-right: 5px;
            }

            //select,
            //.MuiSelect-select,
            .form-control {
                margin-top: -6px !important;
            }
        }

        option {
            padding: 3px 5px 0px;
        }
    }

    div.mapsection {
        position: relative;
        text-align: right;
        display: flex;
        flex: 1 0 0;
        flex-direction: column;
        flex-basis: 50%;
        bottom: 0;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        max-height: 70vh;
        margin-top: 0;

        section {
            border: none;
            height: 100%;

            .leaflet-container {
                border-top-left-radius: 0 !important;
                border-top-right-radius: 0 !important;
            }
        }

        .leaflet-popup {
            //transform: translateY(10px) !important;
            //background-color: green;
        }

        .leaflet-container {
            border-radius: 10px;
            height: 100%;
        }

        // This is how to make the map square
        .leaflet-container:after {
            content: "";
            display: block;
            padding-bottom: 100%;
        }

        .leaflet-popup-content-wrapper {
            border-radius: 0 !important;
            //background-color: yellow;
            padding: 6px 11px;

            .leaflet-popup-content {
                margin: 0;
                //background-color: orange;
            }
        }
        .leaflet-popup-close-button {
            //display: none !important;
            //background-color: pink;
        }
    }

    //background-color: pink;
`;
