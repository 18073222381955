import * as React from "react";
import { ClientProjectListItem } from "./ClientProjectListItem";
import { ResetSearchIcon } from "Custom/Components/Table/ResetSearchIcon";
import { RoofcareTable, RoofcareTableWrapper } from "Custom/Components/Table/RoofcareTable";
import { Container } from "Custom/StylesAppSpecific/AppStyling";
import { ProjectStatusCell } from "Custom/Components/Status/ProjectStatusCell.styles";
import * as Mui from "@material-ui/core";
import { useRouter } from "Core/Utils";
import { AppUrls } from "Custom/Globals";
import { ProjectStatusDropDownListItem } from "Custom/Models/API/ProjectStatusDropDownListItem";
import { isNullOrUndefined } from "Custom/Utils/utils";

import * as Defaults from "Custom/StylesAppSpecific/TableOptions";
import { ClientProjectsListViewModel } from "./V1/ClientProjectsListViewModel";
import { ProjectPaymentStatusDropdownListItem } from "Custom/Models/API/ProjectPaymentStatusDropdownListItem";
interface IProps {
    projects: ClientProjectListItem[];
    projectStatuses: ProjectStatusDropDownListItem[];
    projectPaymentStatuses: ProjectPaymentStatusDropdownListItem[];
}

export const ClientProjectsList: React.FC<IProps> = ({ projects: project, projectStatuses, projectPaymentStatuses }) => {
    const theme = Mui.useTheme();
    const router = useRouter<{ clientId: string; projectId: string }>();
    const [viewModel] = React.useState(() => ClientProjectsListViewModel.Instance);
    const tableOptions = Defaults.GetRoofcareTableOptions() as any;

    const handleRowClick = (rowData: any) => {
        const { path } = router.match;

        const { clientId } = router.match.params;
        let url: string = "/";
        switch (path) {
            case AppUrls.Client.Main.Client.ClientsDetail: {
                url = AppUrls.Client.Main.Client.Project.ClientsDetailProject.replace(":clientId", clientId);
                break;
            }
            case AppUrls.Client.ClientView.Project.ProjectRoot:
            case AppUrls.Client.Root: {
                url = AppUrls.Client.ClientView.Project.ProjectDetail;
                break;
            }
            default: {
            }
        }

        router.history.push(url.replace(":projectId", rowData.id));
    };

    /**
     * Get the sort direction stored in the viewmodel for the provided column index.
     * Only use this on columns that have "sorting" set to true.
     */
    const getSortDirection = (columnIndex: number) => {
        return viewModel.sortColumnId === columnIndex ? viewModel.sortDirection : "";
    };

    const setOrderChange = (columnId: number, direction: any) => {
        viewModel.setOrderChange(columnId, direction);
    };

    return (
        <Container>
            {/* <RoofcareTableWrapper> */}
            <RoofcareTable
                columns={[
                    { title: "Project No.", field: "number", sorting: true, filtering: true, defaultSort: getSortDirection(0) },
                    { title: "Project Name.", field: "name", sorting: true, filtering: true, defaultSort: getSortDirection(1) },
                    { title: "Properties", field: "propertyCount", sorting: true, filtering: false, defaultSort: getSortDirection(2) },
                    {
                        title: "Regional Sales Manager",
                        field: "regionalSalesManager",
                        sorting: true,
                        defaultSort: getSortDirection(3),
                        filtering: true,
                        render: (rowData: ClientProjectListItem) => {
                            if (rowData !== null && rowData !== undefined) {
                                return <div>{rowData.regionalSalesManager}</div>;
                            } else {
                                return <div></div>;
                            }
                        },
                    },
                    {
                        title: "Status",
                        field: "status",
                        sorting: true,
                        defaultSort: getSortDirection(4),
                        filtering: true,
                        render: (rowData: ClientProjectListItem) => {
                            if (rowData !== null && rowData !== undefined && rowData.status !== undefined) {
                                const projectStatus = projectStatuses.find((ps) => ps.enumId === rowData.status);
                                const color = isNullOrUndefined(projectStatus) ? "#000000" : projectStatus!.color;
                                const textColor = isNullOrUndefined(projectStatus) ? "#FFFFFF" : projectStatus!.textColor;
                                const text = isNullOrUndefined(projectStatus) ? "Unknown" : projectStatus!.displayName;

                                return (
                                    <ProjectStatusCell statuscolor={color} textcolor={textColor}>
                                        {text}
                                    </ProjectStatusCell>
                                );
                            } else {
                                return <div></div>;
                            }
                        },
                    },
                    /* 17/11/2022 SJ said not needed on this table {
                        title: "Payment Status",
                        field: "paymentStatus",
                        sorting: true,
                        defaultSort: getSortDirection(5),
                        filtering: true,
                        render: (rowData: ClientProjectListItem) => {
                            if (rowData !== null && rowData !== undefined && rowData.status !== undefined) {
                                const projectStatus = projectPaymentStatuses.find((ps) => ps.id === rowData.paymentStatus);
                                const color = isNullOrUndefined(projectStatus) ? "#000000" : projectStatus!.color;
                                const textColor = isNullOrUndefined(projectStatus) ? "#FFFFFF" : projectStatus!.textColor;
                                const text = isNullOrUndefined(projectStatus) ? "Unknown" : projectStatus!.displayName;

                                return (
                                    <ProjectStatusCell statuscolor={color} textcolor={textColor}>
                                        {text}
                                    </ProjectStatusCell>
                                );
                            } else {
                                return <div></div>;
                            }
                        },
                    }, */
                ]}
                style={{
                    backgroundColor: " #585858",
                    padding: "0",
                    borderRadius: "0",
                }}
                options={{
                    defaultSort: 1,
                    thirdSortClick: false,
                    cellStyle: {
                        fontSize: 10,
                    },
                    pageSize: 50,
                    pageSizeOptions: [10, 20, 50, 100],
                    emptyRowsWhenPaging: false,
                    headerStyle: {
                        ...tableOptions.headerStyle,
                    },
                    rowStyle: {
                        ...tableOptions.rowStyle,
                    },
                    searchFieldAlignment: "left",
                    searchFieldStyle: {
                        border: "1px solid #333333",
                        borderRadius: "5px",
                        width: "400px",
                    },
                    tableLayout: "fixed",
                }}
                data={project}
                title=""
                icons={{
                    ResetSearch: () => (
                        <span style={{ marginRight: "-12px" }}>
                            <ResetSearchIcon />
                        </span>
                    ),
                }}
                onRowClick={(_: any, rowData: any) => handleRowClick(rowData)}
                onOrderChange={(orderedColumnId: any, orderDirection: any) => {
                    setOrderChange(orderedColumnId, orderDirection);
                }}
                components={{ Container: (props: any) => <Mui.Paper {...props} elevation={0} /> }}
            />
            {/* </RoofcareTableWrapper> */}
        </Container>
    );
};
