import React, { useRef, useEffect, useState } from "react";
import { useObserver } from "mobx-react-lite";
import { generateID } from "Core/Utils/Utils";
import { InstallationCommand, InstallationCommandSection, InstallationCommandItemValue } from "Custom/Models/Domain/Installations";
import { SortDirection } from "Custom/Models";
import * as UtilArray from "Custom/Utils/array";
import { SuccessBtn } from "Custom/StylesAppSpecific/Controls/SuccessBtn";
import {
    InstallationControlSection,
    InstallationSectionTitle,
    InstallationSectionOptions,
    InstallationSectionOptionValue,
    SectionSend,
} from "Custom/Views/Installations/InstallationCommandSection.style";
import { Box } from "@material-ui/core";

interface InstallationCommandSectionProps {
    sensorGains: number[];
    deviceCommands: InstallationCommand[];
    section: InstallationCommandSection;
    simId: string;

    addCommand: (newCommand: InstallationCommand) => any;
}

export function InstallationCommandSectionWrapper(props: InstallationCommandSectionProps) {
    const { sensorGains, deviceCommands, section, simId } = props;
    const [isBtnDisabled, setIsBtnDisabled] = useState<boolean | undefined>(false);

    const [stateValues, setStateValues] = React.useState({
        item: "",
        value: "",
        hasValue: false,
        showError: false,
        isLoading: false,
        errorMessage: "",
        showSuccess: false,
        showAPIError: false,
        hasUnsentValue: false,
    });

    useEffect(() => {}, []);

    useEffect(() => {
        let hasUnsentValueLocal: boolean = false;

        if (section !== null && section !== undefined && section.commands !== null && section.commands !== undefined && section.commands.length > 0) {
            if (stateValues.item == null || stateValues.item == undefined || stateValues.item.length === 0) {
                let resetDefault: string = section.commands[0].default.toString();

                const existingCommands = deviceCommands.filter((a) => a.commandId === section.sectionId && a.item === section.commands[0].item);

                if (existingCommands !== null && existingCommands !== undefined && existingCommands.length > 0) {
                    const sortedArray: InstallationCommand[] = UtilArray.sortBy(existingCommands, "id", SortDirection.Desc);

                    if (sortedArray[0].completed === false) {
                        hasUnsentValueLocal = true;
                    }
                    resetDefault = sortedArray[0].value;
                }
                setStateValues({
                    ...stateValues,
                    value: resetDefault,
                    item: section.commands[0].item,
                    hasUnsentValue: hasUnsentValueLocal,
                });
            }
        }
    }, [deviceCommands, section]);

    let options: any = [];

    //
    if (section.sectionId === "G") {
        options = section.commands.map((command: any) => {
            let existingValue: any = "";

            if (sensorGains !== null && sensorGains !== undefined && sensorGains.length > 0) {
                const itemId = parseInt(command.item) - 1;
                if (itemId >= 0 && sensorGains[itemId] != null && sensorGains[itemId] != undefined) {
                    existingValue = " (Existing: " + sensorGains[itemId] + ")";
                }
            }

            return <option value={command.item}>{section.sectionId + command.item + " - " + command.description + existingValue}</option>;
        });
    } else {
        options = section.commands.map((command: any) => {
            return <option value={command.item}>{section.sectionId + command.item + " - " + command.description}</option>;
        });
    }

    const setCommandItem = (value: any): void => {
        const { section, deviceCommands } = props;
        let resetDefault: string = "";
        let hasUnsentValue: boolean = false;

        if (section !== null && section !== undefined && section.commands !== null && section.commands !== undefined && section.commands.length > 0) {
            const item: InstallationCommandItemValue | undefined = section.commands.find((a: any) => a.item === value.toString());

            const existingCommands = deviceCommands.filter((a: any) => a.commandId === section.sectionId && a.item === value.toString());

            if (existingCommands !== null && existingCommands !== undefined && existingCommands.length > 0) {
                const sortedArray: InstallationCommand[] = UtilArray.sortBy(existingCommands, "id", SortDirection.Desc);

                if (sortedArray[0].completed === false) {
                    hasUnsentValue = true;
                }
                resetDefault = sortedArray[0].value;
            } else {
                // set the default or leave blank
                if (item !== null && item !== undefined) {
                    resetDefault = item.default.toString();
                }
            }
        }

        setStateValues({
            ...stateValues,
            item: value,
            value: resetDefault,
            hasValue: false,
            showError: false,
            errorMessage: "",
            hasUnsentValue: hasUnsentValue,
        });
    };

    const setCommandValue = (value: any): void => {
        const { section } = props;
        const { item } = stateValues;
        let hasValue: boolean = false;

        if (section !== null && section !== undefined && section.commands !== null && section.commands !== undefined && section.commands.length > 0) {
            let itemRow = section.commands.find((a: any) => a.item == item.toString());

            if (item == null || item == undefined || (item.length == 0 && itemRow === undefined)) {
                // cope with initial settings.
                itemRow = section.commands[0];
            }

            if (itemRow !== null && itemRow !== undefined) {
                const intValue: number = parseInt(value);
                if (intValue > itemRow.maxRange || intValue < itemRow.minRange) {
                    setStateValues({
                        ...stateValues,
                        hasValue: false,
                        showError: true,
                        errorMessage: "Value outside allowed range (" + itemRow.minRange + " - " + itemRow.maxRange + ")",
                    });
                } else {
                    // OK to go
                    if (value !== undefined && value != null && value.toString().length > 0) {
                        hasValue = true;
                    }

                    setStateValues({
                        ...stateValues,
                        value: value.replace(/\D/, ""),
                        hasValue: true,
                        showError: false,
                        errorMessage: "",
                    });
                }
            }
        }
    };

    const submit = (): void => {
        if (isBtnDisabled === false) {
            const { addCommand } = props;
            setIsBtnDisabled(true);
            let command: InstallationCommand = {
                command: section.sectionId + stateValues.item + "=" + stateValues.value,
                completed: false,
                id: 0,
                simId: simId,
                commandId: section.sectionId,
                commandItem: section.sectionId + stateValues.item,
                value: stateValues.value,
                item: stateValues.item,
                timestamp: "",
                sent: "",
                isDeleted: false,
            };

            addCommand(command);

            setTimeout(() => {
                setIsBtnDisabled(false);
            }, 1500);
        }
    };

    return useObserver(() => (
        <InstallationControlSection key={generateID()}>
            {stateValues.showSuccess && <div className="section-title success">Successfully updated value</div>}
            {stateValues.showAPIError && <div className="section-title error">{stateValues.errorMessage}</div>}
            {!stateValues.showSuccess && !stateValues.showAPIError && (
                <>
                    <InstallationSectionTitle>{section.title}</InstallationSectionTitle>
                    <InstallationSectionOptions>
                        <select className="form-control" onChange={(event) => setCommandItem(event.target.value)} value={stateValues.item}>
                            {options}
                        </select>
                    </InstallationSectionOptions>
                    <InstallationSectionOptionValue>
                        <Box>
                            <input className="form-control" maxLength={15} onChange={(event) => setCommandValue(event.target.value.replace(/\D/, ""))} value={stateValues.value} />
                            {stateValues.showError && <div className="section-options-valueerror">{stateValues.errorMessage}</div>}
                            {!stateValues.showError && stateValues.hasUnsentValue && <div className="section-options-valueunsent">Has unsent command data</div>}
                        </Box>
                    </InstallationSectionOptionValue>
                    <SectionSend>
                        <SuccessBtn disabled={isBtnDisabled} onClick={submit}>
                            Send
                        </SuccessBtn>
                    </SectionSend>
                </>
            )}
        </InstallationControlSection>
    ));
}
