import { contains } from "./../../Utils/array";
import { ViewModelBase } from "Core/ViewModels/ViewModelBase";
import { ProjectCommunicationListItemModel, ProjectCommunicationListItemDTO } from "./ProjectCommunicationListItemModel";
import { FieldType } from "Core/Utils/Utils";
import { action, computed, IComputedValue, IObservableArray, observable, runInAction } from "mobx";
import { ActionsModel } from "./ActionsModel";
import { ApiResult } from "Core/Models";
import { Server } from "Custom/Globals/AppUrls";
import { debounce } from "lodash-es";
import { DEBOUNCE_VALUE_MS } from "Custom/Globals/Globals";
import { ActionType, ActionTypeHelpers } from "Custom/Models/Domain/Projects/ActionTypes";
import moment, { Moment } from "moment";
import { FileDownloadResponseDTO } from "../Installations/FileDownloadResponseDTO";
import { ActionDateTypeFilter, GetDownloadActionRequest } from "./GetDownloadActionRequest";
import { ProjectCommunicationModel, ProjectCommunicationModelDTO } from "Custom/Models/Domain";

//extend viewmodel base and passing your model as the generic type
export class ActionsViewModel extends ViewModelBase<ActionsModel> {
    //Singleton instance of class
    private static _instance: ActionsViewModel;

    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    @observable public errorMessage: string = "";

    @observable public pageSize: number = 50;
    @observable public currentPage: number = 0;

    @observable public searchString: string = "";
    @observable public filterSearchString: string = "";

    // By default this will be the first column (by index) and in ascending order.
    @observable public sortColumnId: number = 0;
    @observable public sortDirection: any = "asc";

    public actionTypeFilter: IObservableArray<string> = observable(["120"]);

    @observable public dateTypeOption: ActionDateTypeFilter = ActionDateTypeFilter.IGNOREDATES;

    @observable public startDate: Moment = moment.utc().startOf("year");
    @observable public endDate: Moment = moment.utc().add(1, "days").startOf("day").add(-1, "minute");

    constructor() {
        //Pass in a new instance of your model
        //By passing in true as the second parameter, we make this model undoable which means we can use save and reset options on the model
        //If you make a change to the model you need to persist it with a saveModel() call
        //If you make changes to your model you can revert it back by calling resetModel()
        super(new ActionsModel(), false);
        //EN - Haven't figured out how to make this call work from the base model yet
        //This is only needed if you make use of the validation decorators
        this.setDecorators(ActionsModel);
    }

    @computed public get isResolvedValid(): boolean {
        let retVal: boolean = true;

        if (this.dateTypeOption === ActionDateTypeFilter.RESOLVED) {
            if (this.IsResolved === false) {
                retVal = false;
            }
        }

        return retVal;
    }

    @computed public get isFollowUpValid(): boolean {
        let retVal: boolean = true;

        if (this.dateTypeOption === ActionDateTypeFilter.FOLLOWUP) {
            if (this.IsFollowUpNotOverdue === false && this.IsFollowUpOverdue === false) {
                retVal = false;
            }
        }

        return retVal;
    }

    @action public setAllActionConditions() {
        this.actionTypeFilter.clear();
        this.actionTypeFilter.replace(ActionTypeHelpers.getAllFilter());
    }
    @action public clearActionConditions() {
        this.actionTypeFilter.clear();
    }

    @action public toggleAction(action: ActionType) {
        let index: number = this.actionTypeFilter.indexOf(action.toString());
        let inListAlready: boolean = index !== -1;
        if (inListAlready === false) {
            // add it
            this.actionTypeFilter.push(action.toString());
        } else {
            this.actionTypeFilter.replace(this.actionTypeFilter.filter((a) => a !== action.toString()));
        }
    }

    @computed public get IsNoFollowupDate(): boolean {
        return this.actionTypeFilter.indexOf(ActionType.NoFollowUpDate.toString()) !== -1;
    }

    @computed public get IsFollowUpNotOverdue(): boolean {
        return this.actionTypeFilter.indexOf(ActionType.HasFollowUpDateNotOverdue.toString()) !== -1;
    }

    @computed public get IsFollowUpOverdue(): boolean {
        return this.actionTypeFilter.indexOf(ActionType.HasFollowUpDateOverdue.toString()) !== -1;
    }

    @computed public get IsResolved(): boolean {
        return this.actionTypeFilter.indexOf(ActionType.Resolved.toString()) !== -1;
    }

    @computed public get getActionDateTypeFilter(): string | number | readonly string[] | undefined {
        return this.dateTypeOption as number;
    }

    @action public setDateTypeOption(value: ActionDateTypeFilter) {
        this.dateTypeOption = value;
    }

    @action public setStartDate(mom: moment.Moment) {
        this.startDate = mom;
    }

    @action public setEndDate(mom: moment.Moment) {
        this.endDate = mom;
    }

    @action
    public setSearchString = (value: string) => {
        this.searchString = value;
        this.setFilterSearchString(value);
    };

    public getSearchString = () => {
        return computed(() => this.searchString);
    };

    private setFilterSearchString = debounce(
        action((value: string) => {
            this.filterSearchString = value;
        }),
        DEBOUNCE_VALUE_MS,
    );

    /*     @action
    public setActionTypeFilter(newValues: string[]) {
        this.actionTypeFilter.replace(newValues);
    } */

    @computed
    public get getActionTypeFilter(): IComputedValue<number[] | string[] | null> {
        return computed(() => this.actionTypeFilter);
    }

    @computed
    public get getActionTypeFilterOptions(): any[] {
        return ActionTypeHelpers.getOptions();
    }

    @action
    public setOrderChange = (columnId: number, direction: any) => {
        this.sortColumnId = columnId;
        this.sortDirection = direction;
    };

    // #endregion Project Sorting

    @action
    public setRowsPerPage(rows: number) {
        this.pageSize = rows;
    }

    @action
    public setPage = (page: number) => {
        this.currentPage = page;
    };

    public clear() {
        this.model.clear();
    }

    public getActionById = (action: string): ProjectCommunicationListItemModel | undefined => {
        let retVal: ProjectCommunicationListItemModel | undefined = this.model.actions.find((a) => a.id === action);
        return retVal;
    };

    @action
    public load = async (): Promise<any> => {
        const apiResult: ApiResult<ProjectCommunicationListItemDTO[]> = await this.Get<ProjectCommunicationListItemDTO[]>(Server.Api.Project.getAllProjectCommunicationForList);

        if (apiResult.wasSuccessful) {
            runInAction(() => {
                this.model.fromDTO(apiResult.payload);
            });
        }
        return apiResult;
    };

    @computed public get getActions(): ProjectCommunicationListItemModel[] {
        let retVal: ProjectCommunicationListItemModel[] = this.model.actions.slice();

        if (this.searchString.length > 0) {
            retVal = retVal.filter(
                (or) =>
                    (or.projectName !== null && or.projectName !== undefined && or.projectName.toLowerCase().includes(this.searchString)) ||
                    (or.projectNumber !== null && or.projectNumber !== undefined && or.projectNumber.toLowerCase().includes(this.searchString)) ||
                    (or.note !== null && or.note !== undefined && or.note.toLowerCase().includes(this.searchString)),
            );
        }

        const filterByActionType = (item: ProjectCommunicationListItemModel): boolean => {
            if (this.actionTypeFilter.length === ActionTypeHelpers.getOptions().length) {
                return true;
            }
            // Check 1 - see if it is device item only from the main list.

            let found: boolean = false;

            for (let i: number = 0; i < this.actionTypeFilter.length; i++) {
                let action: ActionType = parseInt(this.actionTypeFilter[i]) as ActionType;

                switch (action) {
                    case ActionType.HasFollowUpDateNotOverdue: {
                        if (item.followUpDate !== null && item.followUpDate !== undefined) {
                            if (item.isResolved === false && moment.utc(item.followUpDate).startOf("d") > moment.utc()) {
                                found = true;
                            }
                        }

                        break;
                    }
                    case ActionType.HasFollowUpDateOverdue: {
                        if (item.followUpDate !== null && item.followUpDate !== undefined) {
                            if (item.isResolved === false && moment.utc(item.followUpDate).endOf("d") < moment.utc()) {
                                found = true;
                            }
                        }
                        break;
                    }
                    case ActionType.NoFollowUpDate: {
                        if ((item.isResolved === false && item.followUpDate === null) || item.followUpDate === undefined) {
                            found = true;
                        }
                        break;
                    }
                    /*                     case ActionType.NotResolved: {
                        if (item.resolvedDate === null || item.resolvedDate === undefined) {
                            found = true;
                        }
                        break;
                    } */
                    case ActionType.Resolved: {
                        if (item.resolvedDate !== null && item.resolvedDate !== undefined) {
                            found = true;
                        }
                        break;
                    }
                    default: {
                        break;
                    }
                }

                if (found === true) {
                    break;
                }
            }

            return found;
        };

        const filterByDates = (item: ProjectCommunicationListItemModel): boolean => {
            let retVal: boolean = false;

            switch (this.dateTypeOption) {
                case ActionDateTypeFilter.CREATED: {
                    if (item.createdDate === null && item.createdDate === undefined) {
                        retVal = true;
                    } else {
                        let testDate: moment.Moment = moment.utc(item.createdDate);
                        if (testDate >= this.startDate && testDate <= this.endDate) {
                            retVal = true;
                        }
                    }

                    break;
                }
                case ActionDateTypeFilter.RESOLVED: {
                    if (item.resolvedDate === null && item.resolvedDate === undefined) {
                        retVal = true;
                    } else {
                        let testDate: moment.Moment = moment.utc(item.resolvedDate);
                        if (testDate >= this.startDate && testDate <= this.endDate) {
                            retVal = true;
                        }
                    }
                    break;
                }
                case ActionDateTypeFilter.FOLLOWUP: {
                    if (item.followUpDate === null && item.followUpDate === undefined) {
                        retVal = true;
                    } else {
                        let testDate: moment.Moment = moment.utc(item.followUpDate);
                        if (testDate >= this.startDate && testDate <= this.endDate) {
                            retVal = true;
                        }
                    }
                    break;
                }
                case ActionDateTypeFilter.IGNOREDATES:
                default: {
                    retVal = true;
                    break;
                }
            }

            return retVal;
        };

        let first: ProjectCommunicationListItemModel[] = retVal.slice().filter((contact) => filterByActionType(contact));
        let second: ProjectCommunicationListItemModel[] = first.slice().filter((contact) => filterByDates(contact));
        return second;
    }

    public downloadCSV = async () => {
        const request: GetDownloadActionRequest = {
            searchString: this.searchString,

            noFollowUpDate: this.actionTypeFilter.indexOf("100") !== -1,
            hasFollowUpDateNotOverdue: this.actionTypeFilter.indexOf("110") !== -1,
            hasFollowUpDateOverdue: this.actionTypeFilter.indexOf("120") !== -1,
            resolved: this.actionTypeFilter.indexOf("250") !== -1,

            actionDateTypeFilter: this.dateTypeOption,
            startDate: this.startDate.toISOString(),
            endDate: this.endDate.toISOString(),
        };

        const apiResult: ApiResult<FileDownloadResponseDTO> = await this.Post<FileDownloadResponseDTO>(Server.Api.Project.downloadActionsAsCsv, request);

        if (apiResult.wasSuccessful) {
            runInAction(() => {
                let fileName: string = "actions.csv";

                const file: string = apiResult.payload.stringFile;
                const type: string = apiResult.payload.contentType;
                fileName = apiResult.payload.fileName;

                const url = window.URL.createObjectURL(new Blob([file], { type }));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", fileName);
                document.body.appendChild(link);
                link.click();
            });
        } else {
            // Error. What to do?
            this.setIsErrored(true);
            if (apiResult.errors !== null && apiResult.errors !== undefined && apiResult.errors.length > 0) {
                this.Errors = apiResult.errors[0].message;
                this.history.push("/");
            }
        }
    };

    //isValid will check all fields to make sure they are in a valid state.
    public doSubmit = async (e: any) => {
        e.preventDefault();

        if (this.isModelValid()) {
            //Do stuff here
            this.errorMessage = "Form is valid";
        } else {
            this.errorMessage = "Form is not valid";
        }
    };

    /* @action
    public async upsertNewCommunicationNote(newCommunication: string, followupDate: string | undefined): Promise<boolean> {
        const newNote: ProjectCommunicationModel = new ProjectCommunicationModel();

        newNote.id = undefined;
        newNote.isDefaultNote = false;
        newNote.rowVersion = "";
        newNote.projectId = this.model.id!;
        newNote.note = newCommunication;
        newNote.createdDate = undefined;
        newNote.creator = "";
        newNote.createdBy = "";
        newNote.isDeleted = false;
        newNote.lastUpdatedBy = "";
        newNote.updater = "";
        newNote.updatedDate = undefined;
        newNote.followUpDate = followupDate;

        return this.upsertProjectCommunication(newNote);
    } */

    @action
    public async editCommunicationNote(
        editId: string,
        newCommunication: string,
        followupDate: string | undefined,
        isResolved: boolean,
        resolvedDate: string | undefined,
        resolvedBy: string | undefined,
    ): Promise<boolean> {
        const newNote: ProjectCommunicationModel = new ProjectCommunicationModel();

        const list: ProjectCommunicationListItemModel[] = this.model.actions;

        const item: ProjectCommunicationListItemModel | undefined = list.find((a) => a.id == editId);

        if (item !== undefined) {
            // assume new, if it all goes pear shaped
            newNote.id = undefined;
            newNote.isDefaultNote = false;
            newNote.rowVersion = "";
            newNote.projectId = item.projectId;
            newNote.note = newCommunication;
            newNote.createdDate = undefined;
            newNote.creator = "";
            newNote.createdBy = "";
            newNote.isDeleted = false;
            newNote.lastUpdatedBy = "";
            newNote.updater = "";
            newNote.updatedDate = undefined;
            newNote.followUpDate = followupDate;

            newNote.isResolved = isResolved;
            newNote.resolvedDate = resolvedDate;
            newNote.resolvedBy = resolvedBy;

            if (item !== undefined) {
                newNote.id = editId;
                newNote.rowVersion = item.rowVersion === null ? "" : item.rowVersion;
                newNote.createdDate = item.createdDate === null ? "" : item.createdDate;
                newNote.creator = item.creator;
                newNote.createdBy = item.createdBy === null ? "" : item.createdBy;
            }
        }

        return this.upsertProjectCommunication(newNote);
    }

    @action
    public async upsertProjectCommunication(note: ProjectCommunicationModel): Promise<boolean> {
        if (note === undefined) {
            throw new Error("Failed to store project communication");
        }

        const request = note.toCommunicationDto();

        const apiResult = await this.Post<ProjectCommunicationModelDTO>(Server.Api.Project.upsertPropertyCommunication, request);

        runInAction(() => {
            if (apiResult.wasSuccessful) {
                let domainModel: ProjectCommunicationListItemModel | undefined = this.model.actions.find((dm) => dm.id === apiResult.payload.id);

                if (domainModel === undefined) {
                    // Future functionality:? If it is new (then we will need to know the project name / number etc and)
                    /*  domainModel = new ProjectCommunicationListItemModel();

                    domainModel.fromUpsertDto(apiResult.payload);
                    this.model.actions.push(domainModel); */
                } else {
                    domainModel.fromUpsertDto(apiResult.payload);
                    const temp = this.model.actions.slice();
                    this.model.actions.clear();
                    this.model.actions.replace(temp);
                }
            } else {
            }
        });

        return apiResult.wasSuccessful;
    }

    //This must be present in your viewmodel. Just return true if you dont need to validate anything.
    //keyof BlankModel & string lets you add type checking to the fieldName
    //I am using the validator package to make checking easier https://www.npmjs.com/package/validator
    public isFieldValid(fieldName: keyof FieldType<ActionsModel>): boolean {
        const { isValid, errorMessage } = this.validateDecorators(fieldName);

        //You need to this two properties after validation
        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
