import * as MobX from "mobx";
import { ModelBase } from "Core/Models/ModelBase";

//Base class expects the model type and the DTO model type
//If you are not using a DTO just pass in undefined as the DTO
export class ProjectListItemModel extends ModelBase<ProjectListItemModel, ProjectListItemModelDTO> {
    @MobX.observable public number: string = "";
    @MobX.observable public name: string = "";
    @MobX.observable public regionalSalesManagerId: string = "";
    @MobX.observable public regionalSalesManager: string = "";
    @MobX.observable public clientId: string = "";
    @MobX.observable public clientName: string = "";
    @MobX.observable public clientLeadContact: string = "";
    @MobX.observable public contractorName: string = "";
    @MobX.observable public contractorLeadContact: string = "";

    @MobX.observable public status: number = 0; // Project Status Enum Id = 0 is 'unknown'.
    @MobX.observable public paymentStatus: number = 0;
    @MobX.observable public c: number = 1;
    @MobX.observable public id: string = "";
    @MobX.observable public propertyCount: number = 0;
    @MobX.observable public potentialUnits: number = 0;
    @MobX.observable public isDeleted: boolean = false;

    //fromDto is required but you can leave it blank
    @MobX.action
    fromDto(model: ProjectListItemModelDTO): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
    }

    //toDto is required but you can leave it blank
    toDto(model: ProjectListItemModel): void {}
}

export interface ProjectListItemModelDTO {
    id?: string;
    number: string;
    name: string;
    regionalSalesManagerId: string;
    regionalSalesManager: string;
    clientId: string;
    clientName: string;
    status: number; // Project Status Enum Id.
    paymentStatus: number;
    isDeleted: boolean;
    propertyCount: number;
    clientLeadContact: string;
    contractorName: string;
    contractorLeadContact: string;
}
