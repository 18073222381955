import { DeviceConditionSetModelDTO, DeviceAlertActionModelDTO, CondiitonSetMetric, DeviceAlertActionItemModelDTO } from "./../../../Views/Installations/TabConfigurationModel";
import { InstallationImageDto } from "./InstallationImage";
import { action, computed, IObservableArray, observable } from "mobx";
import {
    InstallationAlertDTO,
    InstallationListItem,
    InstallationStatusDataDTO,
    SensorValueDTO,
    InstallationImage,
    InstallationCommand,
    InstallationCommandNoteDTO,
    SensorValueExtended,
    InstallationCommandDto,
    TrippedAlertActionDisplay,
    DrainageType,
} from "Custom/Models/Domain/Installations";

import { ModelBase } from "Core/Models";
import { InstallationCommandHistoryDTO } from "./InstallationCommandHistory";
import { isNullOrUndefined } from "Custom/Utils/utils";
import { DeviceUnitBehaviourModel, DeviceUnitBehaviourModelDTO } from "Custom/Views/Projects/Details/Properties/Installations/DeviceUnitBehaviourModel";
import { UnitNoteDTO } from "Custom/Views/Units/UnitNote";
import { KeyPerformanceData } from "./KeyPerformanceData";
import { ICircularDisplayArrow } from "Custom/Components/Displays/CircularDisplay";

export interface InstallationAndRelatedDTO {
    device: InstallationListItem;
    statusData: InstallationStatusDataDTO | undefined;
    values: SensorValueDTO[];
    images: InstallationImageDto[];
    notes: UnitNoteDTO[];
    commands: InstallationCommand[];
    commandNotes: InstallationCommandNoteDTO[];
    commandHistory: InstallationCommandHistoryDTO[];
    alerts: InstallationAlertDTO[];
    unitBehaviours: DeviceUnitBehaviourModelDTO[];
    conditionSets: DeviceConditionSetModelDTO[];
    alertActions: DeviceAlertActionModelDTO[];
    allowRequestImage: boolean;

    keyPerformance: KeyPerformanceData;
    conditionSetMetrics: CondiitonSetMetric[];
    totalMetricPeriodCount: number;
    alertActionAnalytics: DeviceAlertActionModelDTO;
    totalAnalyticPeriodCount: number;
    trippedAlertActions: TrippedAlertActionDisplay[];
}

export class InstallationAndRelated extends ModelBase<InstallationAndRelated, InstallationAndRelatedDTO> {
    @observable public device: InstallationListItem | undefined = undefined;
    @observable public statusData?: InstallationStatusDataDTO | undefined = undefined;
    @observable public values: SensorValueDTO[] = [];
    @observable public images: InstallationImage[] = [];
    @observable public notes: IObservableArray<UnitNoteDTO> = observable([]);
    @observable public commands: IObservableArray<InstallationCommandDto> = observable([]);
    @observable public commandHistory: IObservableArray<InstallationCommandHistoryDTO> = observable([]);
    @observable public commandNotes: IObservableArray<InstallationCommandNoteDTO> = observable([]);
    @observable public alerts: IObservableArray<InstallationAlertDTO> = observable([]);

    @observable public unitBehaviours: IObservableArray<DeviceUnitBehaviourModel> = observable([]);
    @observable public conditionSets: IObservableArray<DeviceConditionSetModelDTO> = observable([]);
    @observable public alertActions: IObservableArray<DeviceAlertActionModelDTO> = observable([]);

    @observable public allowRequestImage: boolean = false;

    @observable public keyPerformance: KeyPerformanceData = {
        alerts30: 0,
        alerts30Direction: ICircularDisplayArrow.NONE,
        alertsLTM: 0,
        alertLTMDirection: ICircularDisplayArrow.NONE,
        maxCapacityLTM: 0,
        maxCapacityLTMDirection: ICircularDisplayArrow.NONE,
        averagePeak: 0,
        averagePeakDirection: ICircularDisplayArrow.NONE,
        p1Exceeded: 0,
        p1ExceededDirection: ICircularDisplayArrow.NONE,
        p2Exceeded: 0,
        p2ExceededDirection: ICircularDisplayArrow.NONE,
        p190Exceeded: 0,
        p290Exceeded: 0,
    };

    @observable public conditionSetMetrics: IObservableArray<CondiitonSetMetric> = observable([]);
    @observable public siphonicList: IObservableArray<DeviceAlertActionItemModelDTO> = observable([]);

    @observable public totalMetricPeriodCount: number = 0;
    @observable public alertActionAnalytics: DeviceAlertActionModelDTO = {
        id: null,
        name: "",
        rowVersion: null,
        isDeleted: false,
        createdBy: null,
        createdDate: null,
        deviceId: 0,
        alertActionId: null,
        items: [],
    };
    @observable public totalAnalyticPeriodCount: number = 0;
    @observable public trippedAlertActions: IObservableArray<TrippedAlertActionDisplay> = observable([]);

    @action
    public updateIsHiddenResult(newValues: SensorValueExtended[]) {
        if (this.values !== undefined && this.values !== null && this.values.length > 0) {
            this.values.forEach((item: SensorValueDTO) => {
                const found: SensorValueExtended | undefined = newValues.find((a) => a.id === item.id);

                if (found !== undefined) {
                    item.hideOnGraph = found.hideOnGraph;
                }
            });
        }
    }

    //fromDto is required but you can leave it blank
    @action
    fromDto(model: InstallationAndRelatedDTO): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        this.siphonicList.clear();

        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (key === "device") {
                    let temp: InstallationListItem = new InstallationListItem();
                    temp.fromDto(model[key]);
                    this[key] = temp;
                } else if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }

        if (this.statusData !== null && this.statusData !== undefined && this.keyPerformance !== null && this.keyPerformance !== undefined) {
            let p1: DeviceAlertActionItemModelDTO = {
                id: null,
                rowVersion: null,
                isDeleted: false,
                createdBy: null,
                createdDate: null,
                name: "P1",
                type: 0,
                value: 0,
                direction: 0,
                units: 0,
                deviceAlertActionId: null,
                photo: false,
                email: false,
                sms: false,
                commandIndex: 0,
                alertColour: "blue",
                alertTextColour: "#ffffff",
                calculatedValue: 0,
                count: this.keyPerformance.p190Exceeded,
                cutOff: this.statusData!.p1,
            };

            if (this.statusData.drainageType === DrainageType.SingleSiphonic) {
                this.siphonicList.push(p1);
            } else if (this.statusData.drainageType === DrainageType.DualSiphonic) {
                let p2: DeviceAlertActionItemModelDTO = {
                    id: null,
                    rowVersion: null,
                    isDeleted: false,
                    createdBy: null,
                    createdDate: null,
                    name: "P2",
                    type: 0,
                    value: 0,
                    direction: 0,
                    units: 0,
                    deviceAlertActionId: null,
                    photo: false,
                    email: false,
                    sms: false,
                    commandIndex: 0,
                    alertColour: "blue",
                    alertTextColour: "#ffffff",
                    calculatedValue: 0,
                    count: this.keyPerformance.p290Exceeded,
                    cutOff: this.statusData!.p2,
                };

                this.siphonicList.push(p1);
                this.siphonicList.push(p2);
            } // else don't show anything
        }
    }

    toDto(model: InstallationAndRelated): void {
        return;
    }
}
