import { Theme } from "@material-ui/core";
import { KeyValuePair } from "Core/Models/KeyValuePair";

export enum DisplayEstate {
    WithUnits = 1,
    WithoutUnits = 2,
}

export class DisplayEstateHelpers {
    public static getText = (status: DisplayEstate) => {
        switch (status) {
            case DisplayEstate.WithoutUnits: {
                return "Without units";
            }
            case DisplayEstate.WithUnits:
            default: {
                return "With a unit";
            }
        }
    };

    public static getClassName = (status: DisplayEstate) => {
        switch (status) {
            case DisplayEstate.WithoutUnits:
                return "estate_nounits";

            case DisplayEstate.WithUnits:
            default:
                return "estate_unit";
        }
    };

    public static getOptionsKeyValuePair = (): KeyValuePair[] => {
        const retVal: KeyValuePair[] = [];

        for (const key in DisplayEstate) {
            const keyAsInt = parseInt(key);

            if (isNaN(keyAsInt) === false) {
                const item = keyAsInt as DisplayEstate;

                retVal.push({
                    key: DisplayEstateHelpers.getText(item),
                    value: keyAsInt,
                });
            }
        }

        return retVal;
    };
}
