import { action, computed, observable } from "mobx";
import { ModelBase } from "Core/Models/ModelBase";
import { IsNotEmpty } from "class-validator";
import { DeviceUnitBehaviourItemModel, DeviceUnitBehaviourItemModelDTO } from "./DeviceUnitBehaviourItemModel";
import { UnitBehaviourAddEditDetailModelDTO } from "Custom/Views/Admin/UnitBehaviour/UnitBehaviourAddEditDetailModel";
import { DrainageType } from "Custom/Models/Domain";

//Base class expects the model type and the DTO model type
//If you are not using a DTO just pass in undefined as the DTO
export class DeviceUnitBehaviourModel extends ModelBase<DeviceUnitBehaviourModel, DeviceUnitBehaviourModelDTO> {
    public id: string | null = null;
    public rowVersion: string | null = null;

    @observable
    @IsNotEmpty({ message: "You must give the unit behaviour set a name!!" })
    public name: string = "";

    public isDeleted: boolean = false;
    public createdBy: string | null = null;
    public createdDate: string | null = null;
    public unitBehaviourId: string | null = null;

    @observable public items: DeviceUnitBehaviourItemModel[] = [];

    public constructor() {
        super();
    }

    @action public InitItems() {
        for (let i: number = 0; i < 3; i++) {
            let temp: DeviceUnitBehaviourItemModel = new DeviceUnitBehaviourItemModel();
            temp.commandIndex = i;
            this.items.push(temp);
        }
    }

    @computed public get getItems() {
        return this.items.slice();
    }

    @action public setItems(loadingItems: DeviceUnitBehaviourItemModel[]) {
        this.items = loadingItems;
    }
    @action public clearItems() {
        this.items = [];
    }

    @action public clear = () => {
        this.id = null;
        this.rowVersion = null;
        this.name = "";
        this.isDeleted = false;
        this.createdBy = null;
        this.createdDate = null;
        this.items = [];
    };

    //fromDto is required but you can leave it blank
    fromDto(model: DeviceUnitBehaviourModelDTO): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else if (key === "items") {
                    this.items = [];

                    for (let i: number = 0; i < model[key].length; i++) {
                        let newItem = new DeviceUnitBehaviourItemModel();
                        newItem.fromDto(model[key][i]);
                        this.items.push(newItem);
                    }
                } else {
                    this[key] = model[key];
                }
            }
        }
    }

    fromAdminDto(model: UnitBehaviourAddEditDetailModelDTO) {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else if (key === "id") {
                    this.unitBehaviourId = model[key];
                } else if (key === "items") {
                    this.items = [];

                    for (let i: number = 0; i < model[key].length; i++) {
                        let newItem = new DeviceUnitBehaviourItemModel();
                        newItem.fromUnitBehaviourItemModelDTO(model[key][i]);
                        this.items.push(newItem);
                    }
                } else {
                    this[key] = model[key];
                }
            }
        }
    }

    //toDto is required but you can leave it blank
    toDto(model: DeviceUnitBehaviourModel): void {}

    toStoreDto(): DeviceUnitBehaviourModelDTO {
        let items: DeviceUnitBehaviourItemModelDTO[] = [];

        for (let i: number = 0; i < this.items.length; i++) {
            let item: DeviceUnitBehaviourItemModelDTO = this.items[i].toSendDto();
            items.push(item);
        }

        return {
            id: this.id,
            name: this.name,
            rowVersion: this.rowVersion,
            isDeleted: this.isDeleted,
            createdBy: this.createdBy,
            createdDate: this.createdDate,
            unitBehaviourId: this.unitBehaviourId,
            //drainageType: this.drainageType,
            items: items,
        };
    }
}

export type DeviceUnitBehaviourModelDTO = {
    id: string | null;
    name: string;
    rowVersion: string | null;
    isDeleted: boolean;
    createdBy: string | null;
    createdDate: string | null;
    unitBehaviourId: string | null;

    //drainageType: DrainageType;
    items: DeviceUnitBehaviourItemModelDTO[];
};
