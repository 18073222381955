import * as React from "react";
import { BackBtnContainer } from "../StylesAppSpecific/Controls/BackBtn";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";

interface IProps {
    text: string;
    onClick(): void;
}

export const BackBtn: React.FC<IProps> = ({ text, onClick }) => {
    return (
        <BackBtnContainer className="back-btn" role="button" tabIndex={0} onClick={onClick}>
            <div className="back-icon">
                <NavigateBeforeIcon fontSize="large" />
            </div>
            {text}
        </BackBtnContainer>
    );
};
