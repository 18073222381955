import { useObserver } from "Core/Base";
import { Box, List, ListItem, ListItemText, Typography, useMediaQuery } from "@material-ui/core";
import React, { useEffect } from "react";
import {
    AddNewBox,
    SearchBox,
    TransferListButton,
    TransferListPaper,
    ButtonWrapper,
    TransferListButtonWrapper,
    ListWrapper,
    InternalList,
    InternalListToo,
    TransferListPage,
} from "./TransferList.styles";
import { AddItemTextUnderline } from "Custom/StylesAppSpecific/Controls/AddEditWrapper";
import { generateID } from "Core/Utils/Utils";

interface TransferListProps {
    source: any[];
    destination: any[];
    allOption?: boolean;
    // colorTheme?: string;
    sourceTitle?: string;
    destinationTitle?: string;

    //searchString: string;
    showAddOption?: boolean;
    showSearch?: boolean;
    addOptionText?: string;
    onAddOptionClicked?: () => void;
    placeholderText?: string;
    onSearchChange?: (text: string) => void;

    renderOption: (option: any) => string;
    onDestinationChange: (newDestination: any[]) => void;
}

export const TransferList: React.FC<TransferListProps> = (props: TransferListProps) => {
    const desktopScreen = useMediaQuery("(min-width:600px)");
    const [checked, setChecked] = React.useState<any[]>([]);
    const [source, setSource] = React.useState<any[]>([]);
    const [destination, setDestination] = React.useState<any[]>([]);

    const [searchString, setSearchString] = React.useState<string>("");

    const not = (a: number[], b: number[]) => {
        return a.filter((value) => b.indexOf(value) === -1);
    };

    const intersection = (a: number[], b: number[]) => {
        return a.filter((value) => b.indexOf(value) !== -1);
    };

    const sourceChecked = intersection(checked, source);
    const destinationChecked = intersection(checked, destination);

    const handleToggle = (value: any) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const clearSource = () => {
        const retVal: any[] = destination.concat(sourceChecked);
        setDestination(retVal);
        setSource([]);
        props.onDestinationChange(retVal);
    };

    const handleCheckedSource = () => {
        const retVal: any[] = destination.concat(sourceChecked);
        setDestination(retVal);
        setSource(not(source, sourceChecked));
        setChecked(not(checked, sourceChecked));
        props.onDestinationChange(retVal);
    };

    const handleCheckedDestination = () => {
        setSource(source.concat(destinationChecked));
        const retVal: any[] = not(destination, destinationChecked);
        setDestination(retVal);
        setChecked(not(checked, destinationChecked));
        props.onDestinationChange(retVal);
    };

    const clearDestination = () => {
        setSource(source.concat(destination));
        setDestination([]);
        props.onDestinationChange([]);
    };

    useEffect(() => {
        setDestination(props.destination);
    }, [props.destination]);

    useEffect(() => {
        setSource(props.source);
    }, [props.source]);

    const internalList = (items: any[]) => (
        <List dense component="div" disablePadding role="list">
            {items.map((item: any) => {
                const labelId = `transfer-list-item-${item.id}-label`;
                return (
                    <ListItem key={generateID()} className={`list-property ${checked.indexOf(item) !== -1 ? "selected" : ""}`} role="listitem" button onClick={handleToggle(item)}>
                        <ListItemText id={labelId} primary={`${props.renderOption(item)}`} />
                    </ListItem>
                );
            })}
        </List>
    );

    const showAllOptions: boolean = props.allOption !== null && props.allOption !== undefined ? props.allOption! : false;

    const placeholderText: string = props.placeholderText ? props.placeholderText : "Search list";

    const onSearchChange = (e: any) => {
        if (props.onSearchChange) {
            props.onSearchChange(e.target.value);
        }
        setSearchString(e.target.value);
    };

    return useObserver(() => (
        <TransferListPage>
            <ListWrapper>
                {props.sourceTitle && (
                    <Typography variant="caption" className="titlePanel">
                        {props.sourceTitle}
                        {props.showAddOption && props.addOptionText && (
                            <AddNewBox onClick={props.onAddOptionClicked}>
                                <AddItemTextUnderline style={{ marginLeft: "-5px" }}>{props.addOptionText}</AddItemTextUnderline>
                            </AddNewBox>
                        )}
                    </Typography>
                )}

                {(props.showAddOption || props.showSearch) && (
                    <>
                        <TransferListPaper variant="outlined">
                            {props.showSearch && <SearchBox type="text" placeholder={placeholderText} value={searchString} onChange={onSearchChange} />}
                            <InternalList>{internalList(source)}</InternalList>
                        </TransferListPaper>
                    </>
                )}
            </ListWrapper>
            <Box display="flex" flexDirection="column">
                <Typography variant="caption">&nbsp;</Typography>
                <TransferListButtonWrapper>
                    {showAllOptions && (
                        <ButtonWrapper>
                            <TransferListButton variant="contained" color="primary" onClick={clearSource} disabled={source.length === 0} aria-label="move all right">
                                ≫
                            </TransferListButton>
                        </ButtonWrapper>
                    )}
                    <ButtonWrapper>
                        <TransferListButton
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={handleCheckedSource}
                            disabled={sourceChecked.length === 0}
                            aria-label="move selected right"
                        >
                            Add &gt;&gt;
                        </TransferListButton>
                        <TransferListButton
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={handleCheckedDestination}
                            disabled={destinationChecked.length === 0}
                            aria-label="move selected left"
                        >
                            &lt;&lt; Remove
                        </TransferListButton>
                    </ButtonWrapper>
                    {showAllOptions && (
                        <ButtonWrapper>
                            <TransferListButton variant="contained" color="primary" onClick={clearDestination} disabled={destination.length === 0} aria-label="move all left">
                                ≪
                            </TransferListButton>
                        </ButtonWrapper>
                    )}
                </TransferListButtonWrapper>
            </Box>
            <ListWrapper>
                {props.destinationTitle && (
                    <Typography variant="caption" className="titlePanel">
                        {props.destinationTitle}
                    </Typography>
                )}
                <TransferListPaper variant="outlined">
                    <InternalListToo>
                        {destination.length === 0 ? (
                            <Typography className="no-properties" variant="body2">
                                There are currently no properties added to this project
                            </Typography>
                        ) : (
                            internalList(destination)
                        )}
                    </InternalListToo>
                </TransferListPaper>
            </ListWrapper>
        </TransferListPage>
    ));
};
