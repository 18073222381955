//EN Do not remove default theme from here
import "Custom/StylesAppSpecific/AppStyling";

//EN: To support IE 11
// Uncomment the two lines below
// Revert MobX to 4.12.0
// Delete node_modules directory and reinstall
//import "react-app-polyfill/ie11";
//import "react-app-polyfill/stable";
import React, { useEffect, useState } from "react";
import * as MobX from "mobx";
import "index.scss";
import * as History from "history";
import { StoresInstance } from "../Custom/Stores";
import { getBaseUrl } from "./Utils/Utils";
import { InitViewModel } from "../Custom/ViewModels/InitViewModel";
import { AppErrorBoundary } from "../AppErrorBoundary";
import { useObserver } from "mobx-react-lite";
import { Error } from "./Views/Error";
import { Loader } from "./Components";

MobX.configure({ enforceActions: "observed" });

const baseUrl: string = getBaseUrl();
export const GlobalHistory = History.createBrowserHistory({
    basename: baseUrl,
});
GlobalHistory.listen((location: any) => {
    if (StoresInstance && StoresInstance.coreStore) {
        //StoresInstance.coreStore.PageView(location.pathname);
    }
    // Use setTimeout to make sure this runs after React Router's own listener
    setTimeout(() => {
        // Keep default behavior of restoring scroll position when user:
        // - clicked back button
        // - clicked on a link that programmatically calls `history.goBack()`
        // - manually changed the URL in the address bar (here we might want
        // to scroll to top, but we can't differentiate it from the others)
        if (location.action === "POP") {
            return;
        }
        // In all other cases, check fragment/scroll to top
        let hash = window.location.hash;
        if (hash) {
            let element = document.querySelector(hash);
            if (element) {
                element.scrollIntoView({ block: "start", behavior: "smooth" });
            }
        } else {
            window.scrollTo(0, 0);
        }
    });
});

export const IsDevMode: boolean = process.env.NODE_ENV === "development";
(window as any).isDev = IsDevMode;
// const devTools: JSX.Element | null = isDev ? <MobXReactDevTools /> : null;

type Props = {
    children: React.ReactNode;
};
export const CoreIndex: React.FC<Props> = (props: Props) => {
    const [viewModel] = useState(() => new InitViewModel());
    useEffect(() => {
        let _ = viewModel.getInitialState();

        return () => {
            // Clean up after yourself
        };
    }, []);

    const getRender = () => {
        if (viewModel.IsLoading) {
            // EN: Delayed in code behind so we can see the loading text
            return <Loader delayBeforeShow={0} />;
        } else if (viewModel.IsErrored) {
            return <Error />;
        }

        if (viewModel.DataReady) {
            return <AppErrorBoundary>{props.children}</AppErrorBoundary>;
        }
        return <></>;
    };

    return useObserver(() => {
        return <>{getRender()}</>;
    });
};
