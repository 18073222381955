import React, { useEffect, useState } from "react";
import { Box, Dialog, DialogContent, DialogTitle, Paper, Typography, useTheme } from "@material-ui/core";
import { useObserver } from "mobx-react-lite";
import { useRouter } from "Core/Utils";
import { ResetSearchIcon } from "Custom/Components/Table/ResetSearchIcon";

import UserListViewModel from "./UserListViewModel";

import { ClickableImgCell } from "Custom/Components/Table/ClickableImgCell";
import ForgotLogo from "Custom/Content/forgotSendIcon.svg";
import EditLogo from "Custom/Content/editIcon.svg";
import DeleteLogo from "Custom/Content/deleteIcon.svg";
import AddLogo from "Custom/Content/plusIcon.svg";

import { RoofcareTable, RoofcareTableWrapper } from "Custom/Components/Table/RoofcareTable";
import { Container } from "Custom/StylesAppSpecific/AppStyling";
import { AddItemText } from "Custom/StylesAppSpecific/Controls/AddEditWrapper";
import { BoxAdd, BoxAddWrapper, ProjectToolbarRowV2, ProjectToolbarRowV3 } from "Custom/StylesAppSpecific/Controls/BoxAddWrapper";
import { AppUrls } from "Custom/Globals";
import { DeleteModal } from "Custom/Components/Modal/DeleteModal";
import { Loader } from "../../../Core/Components/Loader";
import { formatDateTime } from "Custom/Utils/format";
import { ProjectButton } from "../CustomComponents";

import * as Defaults from "Custom/StylesAppSpecific/TableOptions";
import { ProjectListSearch } from "../Projects/ProjectListSearch";
import { MultiSelectView } from "Custom/Components/Select/MultipleSelect";
import { UserDetailsViewModel } from "./UserDetailsViewModel";

export const UserListPage: React.FC = () => {
    const [viewModel] = useState(() => UserListViewModel.Instance);
    const theme = useTheme();

    const router = useRouter();

    const { history } = useRouter();

    const reload = (): void => {
        const { path } = router.match;

        switch (path) {
            case AppUrls.Client.Main.User.Root:
            default: {
                const apiResult = viewModel.loadUsersAsync();
                break;
            }
        }
    };

    useEffect(() => {
        reload();

        return () => {
            // Clean up after yourself
            viewModel.clear();
        };
    }, []);

    const setSearchString = (value: string) => {
        viewModel.setSearchString(value);
        viewModel.setPage(0);
    };

    const setRowsPerPage = (rows: number) => {
        viewModel.setRowsPerPage(rows);
    };

    const setPage = (page: number) => {
        viewModel.setPage(page);
    };

    const setOrderChange = (columnId: number, direction: any) => {
        viewModel.setOrderChange(columnId, direction);
    };

    const [deleteOpen, setDeleteOpen] = React.useState(false);
    const [passwordOpen, setPasswordOpen] = React.useState(false);
    const [selectedUser, selectUser] = React.useState("");

    const handleDeleteOpen = () => {
        setDeleteOpen(true);
    };

    const handleDeleteClose = () => {
        setDeleteOpen(false);
        selectUser("");
    };

    const handleDeleteUser = () => {
        const deletedUser: string = selectedUser;
        viewModel.deleteUser(deletedUser).then(() => {
            setDeleteOpen(false);
            selectUser("");
        });
    };

    const handlePasswordDialog = (option: boolean) => {
        setPasswordOpen(option);
    };

    const handlePasswordSuccessClose = () => {
        setPasswordOpen(false);
    };

    const handleNewClick = () => {
        history.push(AppUrls.Client.Main.User.UserDetail.replace(":id", "0"));
    };

    const formatForgotPawword = (id: string): any => {
        return <ClickableImgCell logo={ForgotLogo} text={"Send Forgot Password"} onClick={forgotPasswordClick} id={id} />;
    };

    const forgotPasswordClick = (id: string) => {
        const email: string = viewModel.getEmail(id);

        if (email.length !== 0) {
            viewModel.forgotPassword(email).then((retVal) => {
                if (retVal !== undefined) {
                    if (retVal.wasSuccessful) {
                        handlePasswordDialog(true);
                    }
                }
            });
        }
    };

    const editUserClick = (id: string) => {
        history.push(AppUrls.Client.Main.User.UserDetail.replace(":id", id));
    };

    const deleteUserClick = (id: string) => {
        selectUser(id);
        handleDeleteOpen();
    };

    const formatEdit = (id: string): any => {
        return <ClickableImgCell logo={EditLogo} text={"Edit User"} onClick={editUserClick} id={id} />;
    };

    const formatDelete = (id: string): any => {
        return <ClickableImgCell logo={DeleteLogo} text={"Delete user"} onClick={deleteUserClick} id={id} className="delete" />;
    };

    const isInError = (fieldName: string): boolean => {
        /*         let isValid = props.filtersViewModel.getValid(fieldName);
        return !isValid; */

        return false;
    };

    const getError = (fieldName: string): string => {
        /*         let retVal: string = props.filtersViewModel.getError(fieldName);
        return retVal; */
        return "";
    };

    const handleRolesFilterChange = (values: number[] | string[] | null) => {
        viewModel.setRoleFilter(values as string[]);
    };

    const renderPage = () => {
        if (viewModel.IsLoading) return <Loader />;

        const tableOptions = Defaults.GetRoofcareTableOptions() as any;

        return (
            <Container>
                {
                    <>
                        <DeleteModal
                            open={deleteOpen}
                            onClose={handleDeleteClose}
                            onDelete={handleDeleteUser}
                            text="Are you sure you want to delete this user?"
                            title="Delete User?"
                        />

                        <RoofcareTableWrapper>
                            <ProjectToolbarRowV2>
                                <div className="search-field" style={{ width: "580px" }}>
                                    <ProjectListSearch searchString={viewModel.getSearchString()} setSearchString={setSearchString} placeholder="Start typing to search users" />
                                </div>
                                <div className="download-csv"></div>
                                <div className="add-new-project">
                                    <ProjectButton icon={() => <span>&#43;</span>} text="Add new user" onClick={() => handleNewClick()} />
                                </div>
                            </ProjectToolbarRowV2>
                            <ProjectToolbarRowV3 style={{ marginTop: "20px" }}>
                                <Box>
                                    <Box>
                                        <Typography style={{ color: theme.palette.secondary.contrastText }} variant="h2" className="select-title">
                                            Roles:
                                        </Typography>
                                        <Box className="multiselectbox">
                                            <MultiSelectView
                                                fieldName=""
                                                values={viewModel.getRoleFilter()}
                                                display="Roles"
                                                useNumber={true}
                                                getOptions={(isdeleted) => viewModel.getRolesKVPair}
                                                handleChange={handleRolesFilterChange}
                                                getError={getError}
                                                getValid={isInError}
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                            </ProjectToolbarRowV3>
                        </RoofcareTableWrapper>

                        <RoofcareTable
                            columns={[
                                { title: "Email", field: "email", sorting: true, filtering: true },
                                { title: "Name", field: "displayName", sorting: true, filtering: true },
                                { title: "Role", field: "roles", sorting: true, filtering: false },
                                {
                                    title: "Last Login",
                                    field: "lastLoginDate",
                                    sorting: true,
                                    filtering: false,
                                    render: (rowData: any) => {
                                        if (rowData !== null && rowData !== undefined) {
                                            return formatDateTime(rowData.lastLoginDate);
                                        }
                                    },
                                },
                                {
                                    title: "",
                                    field: "id",
                                    sorting: false,
                                    filtering: false,
                                    render: (rowData: any) => {
                                        if (rowData !== null && rowData !== undefined) {
                                            return formatForgotPawword(rowData.id);
                                        }
                                    },
                                },
                                {
                                    title: "",
                                    field: "id",
                                    sorting: false,
                                    filtering: false,
                                    render: (rowData: any) => {
                                        if (rowData !== null && rowData !== undefined) {
                                            return formatEdit(rowData.id);
                                        }
                                    },
                                },
                                {
                                    title: "",
                                    field: "id",
                                    sorting: false,
                                    filtering: false,
                                    render: (rowData: any) => {
                                        if (rowData !== null && rowData !== undefined) {
                                            return formatDelete(rowData.id);
                                        }
                                    },
                                },
                            ]}
                            style={{
                                backgroundColor: "#3B3C3B",
                                padding: 0,
                            }}
                            options={{
                                thirdSortClick: false,
                                cellStyle: {
                                    fontSize: 10,
                                },
                                initialPage: viewModel.currentPage,
                                pageSize: viewModel.pageSize,
                                pageSizeOptions: [10, 20, 50, 100],
                                emptyRowsWhenPaging: false,
                                headerStyle: {
                                    ...tableOptions.headerStyle,
                                },
                                rowStyle: {
                                    ...tableOptions.rowStyle,
                                },
                                filtering: false,
                                search: false,
                                defaultGroupOrder: viewModel.sortColumnId,
                                defaultGroupDirection: viewModel.sortDirection,
                            }}
                            icons={{
                                ResetSearch: () => <ResetSearchIcon />,
                            }}
                            data={viewModel.getAllowedUsers}
                            title=""
                            components={{ Container: (props: any) => <Paper {...props} elevation={0} />, Toolbar: (props: any) => <></> }}
                        />
                    </>
                }
            </Container>
        );
    };

    return useObserver(() => renderPage());
};
