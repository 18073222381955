import { KeyValuePairGeneric } from "Core/Models/KeyValuePair";
import * as MobX from "mobx";
import { ModelBase } from "Core/Models/ModelBase";
import moment from "moment";

export interface InstallationImageWrapper {
    imagedate: string;
    imageDateTime: string;
    view: number;
    image: InstallationImageDto;
}

export interface InstallationImageDto {
    id: number;
    url: string;
    width: number;
    height: number;
    timeStamp: string;
    view: number;
    deviceId: string;
    simId: string;
    flipped: boolean;
    isDeleted: boolean;
}

export class InstallationImage implements InstallationImageDto {
    public id: number = -1;
    public url: string = "";
    public width: number = 0;
    public height: number = 0;
    public timeStamp: string = "";
    public view: number = 1;
    public deviceId: string = "";
    public simId: string = "";
    public flipped: boolean = false;
    public isDeleted: boolean = false;

    private momentTime: moment.Moment | undefined = undefined;

    public getUpdated = (): moment.Moment | undefined => {
        if (this.momentTime === undefined) {
            // then return undefined
        } else {
            if (this.timeStamp.length !== 0) {
                this.momentTime = moment(this.momentTime);
            }
        }

        return this.momentTime;
    };
}

export class InstallationImageResult extends ModelBase<InstallationImageResult, InstallationImage> {
    @MobX.observable public installationImages: MobX.IObservableArray<KeyValuePairGeneric<string, InstallationImage[]>> = MobX.observable<
        KeyValuePairGeneric<string, InstallationImage[]>
    >([]);

    //fromDto is required but you can leave it blank
    @MobX.action
    fromDto(model: InstallationImageDto): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
        // NOTE We don't have an object, we have an array of objects, so use fromDtoArr
    }

    @MobX.action
    fromDtoArr(model: InstallationImageDto[]): void {
        this.installationImages.length = 0;

        const images: InstallationImageDto[] = model.slice(0);

        const items: InstallationImageWrapper[] = images.map(function (item: InstallationImageDto) {
            return {
                imagedate: moment(item.timeStamp).format("DD/MM/YYYY"),
                imageDateTime: moment(item.timeStamp).format("DD/MM/YYYY HH:mm"),
                image: item,
                view: item.view,
            };
        });

        let existing: KeyValuePairGeneric<string, InstallationImage[]>[] = this.installationImages.slice(0);

        for (let i = 0; i < items.length; i++) {
            if (existing.find((a) => a.key === items[i].imagedate) === undefined) {
                existing.push({ key: items[i].imagedate, value: [items[i].image as InstallationImage] });
            } else {
                let current: KeyValuePairGeneric<string, InstallationImage[]> | undefined = existing.find((a) => a.key === items[i].imagedate);
                if (current !== undefined) {
                    current.value.push(items[i].image as InstallationImage);
                } else {
                    existing.push({ key: items[i].imagedate, value: [items[i].image as InstallationImage] });
                }
            }
        }

        // Now we need to go through this list and sort per minute by view

        this.installationImages.replace(existing);
    }

    //toDto is required but you can leave it blank
    toDto(model: InstallationImageResult): void {}
}
