import React, { useState, useContext, useRef, useEffect } from "react";
import ReactHtmlParser from "react-html-parser";
import { Link, Box, useMediaQuery } from "@material-ui/core";
import { LinkBox, RightSide, Dot, DotWrapper } from "Core/Components/Styles/BaseStyles";
import { EditableCopyViewModel } from "../ViewModels/EditableCopyViewModel";
import { Animation } from "Core/Examples/Animation";
import { Buttons } from "Core/Examples/Buttons";
import { DatePicker } from "Core/Examples/DatePicker";
import { GridLayouts } from "Core/Examples/GridLayouts";
import { SelectInput } from "Core/Examples/SelectInput";
import { Text } from "Core/Examples/Text";
import { TextInputs } from "Core/Examples/TextInputs";
import { Layouts } from "Core/Examples/Layouts";
import { CSSGrid, Cell } from "Core/Components/Styles";

export const Samples: React.FC = () => {
    const desktopScreen = useMediaQuery("(min-width:600px)");
    const [viewModel] = useState(() => new EditableCopyViewModel());
    const [view, setView] = useState(<Text />);

    return (
        <CSSGrid columns={desktopScreen ? "1fr 5fr" : "1fr"}>
            <div>
                <LinkBox>
                    <Link onClick={() => setView(<Text />)}>{ReactHtmlParser(viewModel.getEditableCopyByRouteAndPlaceholderNumber("/samples", 1))}</Link>
                </LinkBox>
                <LinkBox>
                    <Link onClick={() => setView(<Buttons />)}>{ReactHtmlParser(viewModel.getEditableCopyByRouteAndPlaceholderNumber("/samples", 2))}</Link>
                </LinkBox>
                <LinkBox>
                    <Link onClick={() => setView(<TextInputs />)}>{ReactHtmlParser(viewModel.getEditableCopyByRouteAndPlaceholderNumber("/samples", 3))}</Link>
                </LinkBox>
                <LinkBox>
                    <Link onClick={() => setView(<SelectInput />)}>{ReactHtmlParser(viewModel.getEditableCopyByRouteAndPlaceholderNumber("/samples", 4))}</Link>
                </LinkBox>
                <LinkBox>
                    <Link onClick={() => setView(<DatePicker />)}>{ReactHtmlParser(viewModel.getEditableCopyByRouteAndPlaceholderNumber("/samples", 5))}</Link>
                </LinkBox>
                <LinkBox>
                    <Link onClick={() => setView(<GridLayouts />)}>{ReactHtmlParser(viewModel.getEditableCopyByRouteAndPlaceholderNumber("/samples", 6))}</Link>
                </LinkBox>
                <LinkBox>
                    <Link onClick={() => setView(<Layouts />)}>{ReactHtmlParser(viewModel.getEditableCopyByRouteAndPlaceholderNumber("/samples", 7))}</Link>
                </LinkBox>
                <LinkBox>
                    <Link onClick={() => setView(<Animation />)}>{ReactHtmlParser(viewModel.getEditableCopyByRouteAndPlaceholderNumber("/samples", 8))}</Link>
                </LinkBox>
                <LinkBox>
                    <Link onClick={() => setView(<Loaders />)}>{ReactHtmlParser(viewModel.getEditableCopyByRouteAndPlaceholderNumber("/samples", 9))}</Link>
                </LinkBox>
            </div>
            <Box>{view}</Box>
        </CSSGrid>
    );
};

export const Loaders: React.FC = () => {
    return (
        <>
            <Box paddingTop={2}>
                Animated Dots
                <DotWrapper>
                    <Dot delay="0s" />
                    <Dot delay=".1s" />
                    <Dot delay=".2s" />
                </DotWrapper>
            </Box>
        </>
    );
};
