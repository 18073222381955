import { ViewModelBase } from "Core/ViewModels/ViewModelBase";
import { FieldType } from "Core/Utils/Utils";
import { action, observable, runInAction } from "mobx";
import { BlankModel } from "Core/Models/BlankModel";
import { DefaultUnitNote, UnitNoteDTO } from "../UnitNote";
import { ApiResult } from "Core/Models";
import { AppUrls } from "Custom/Globals";

//extend viewmodel base and passing your model as the generic type
export class UnitNoteSectionViewModel extends ViewModelBase<BlankModel> {
    //Singleton instance of class
    /*     private static _instance: UnitNoteSectionViewModel;
    public static get Instance() {
        return this._instance || (this._instance = new this());
    } */

    @observable public errorMessage: string = "";

    public deviceId: number = -1;
    @observable public newNote: UnitNoteDTO = DefaultUnitNote();

    constructor(deviceDbId: number) {
        //Pass in a new instance of your model
        //By passing in true as the second parameter, we make this model undoable which means we can use save and reset options on the model
        //If you make a change to the model you need to persist it with a saveModel() call
        //If you make changes to your model you can revert it back by calling resetModel()
        super(new BlankModel(), false);
        //EN - Haven't figured out how to make this call work from the base model yet
        //This is only needed if you make use of the validation decorators
        this.setDecorators(BlankModel);

        this.deviceId = deviceDbId;
    }

    @action public setNote(value: UnitNoteDTO) {
        this.newNote = value;
        this.newNote.deviceDbId = this.deviceId;
    }

    @action
    public async upsertNote(): Promise<ApiResult<UnitNoteDTO[]>> {
        if (this.newNote !== undefined && this.newNote !== null) {
            if (this.newNote.deviceDbId < 1) {
                this.newNote.deviceDbId = this.deviceId;
            }
        }
        const apiResult: ApiResult<UnitNoteDTO[]> = await this.Post<UnitNoteDTO[]>(AppUrls.Server.Api.Unit.upsertDeviceUnitNote, this.newNote);

        if (apiResult.wasSuccessful) {
            runInAction(() => {
                this.newNote = DefaultUnitNote();
            });
        } else {
            if (apiResult.errors !== null && apiResult.errors !== undefined && apiResult.errors.length > 0) {
                console.log(apiResult.errors[0].message);
            }
        }

        return apiResult;
    }

    //isValid will check all fields to make sure they are in a valid state.
    public doSubmit = async (e: any) => {
        e.preventDefault();

        if (this.isModelValid()) {
            //Do stuff here
            this.errorMessage = "Form is valid";
        } else {
            this.errorMessage = "Form is not valid";
        }
    };

    //This must be present in your viewmodel. Just return true if you dont need to validate anything.
    //keyof BlankModel & string lets you add type checking to the fieldName
    //I am using the validator package to make checking easier https://www.npmjs.com/package/validator
    public isFieldValid(fieldName: keyof FieldType<BlankModel>): boolean {
        const { isValid, errorMessage } = this.validateDecorators(fieldName);

        //You need to this two properties after validation
        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
