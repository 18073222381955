import { Box, Typography, useMediaQuery } from "@material-ui/core";
import styled from "styled-components";

export const Main = styled.section`
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: row;
    width: 100%;
    // background-color: limegreen;

    @media screen and (max-width: 1024px) {
    }

    @media screen and (max-width: 640px) {
        flex-direction: column;
    }

    .filtertablewrapper {
        display: flex;
        flex: 1 0 1;
        flex-direction: column;
        width: 100%;
    }
`;

export const Section1 = styled(Box)`
    text-align: right;
    display: flex;
    flex: 1 0 1;
    flex-direction: column;
    align-items: middle;
    padding: 0 15px;
    // background-color: olive;
    min-width: 45%;

    @media screen and (max-width: 1024px) {
        //padding: 100px 0 0 0;
    }
    @media screen and (max-width: 640px) {
        padding: 15px;
    }
`;

export const Section2 = styled(Box)`
    // padding: 50px 100px 50px 100px;
    //background-color: white;
    // background-color: orange;
    display: flex;
    flex: 1 0 1;
    flex-direction: column;
    @media screen and (max-width: 1024px) {
    }

    @media screen and (max-width: 640px) {
        padding: 15px;
    }
`;

export const Section2_Filter = styled(Box)`
    //background-color: grey;
    display: flex;
    flex-direction: row;
    flex: 1 0 0;
    position: relative;
    width: 100%;
    height: 75px;
    background-color: white;

    .mapfilter {
        display: flex;
        flex-direction: row;
        flex: 1 0 0;
        background-color: grey;
    }

    @media screen and (max-width: 1024px) {
    }

    @media screen and (max-width: 640px) {
    }
`;

export const Section2_SearchTable = styled(Box)`
    // padding: 50px 100px 50px 100px;
    background-color: white;
    //background-color: pink;
    display: flex;
    flex: 1 0 0;

    @media screen and (max-width: 1024px) {
    }

    @media screen and (max-width: 640px) {
    }
`;

export const CaptionText = styled(Typography)`
    //width: 500px;
    font-size: 0.875em;
    color: white;
    letter-spacing: -0.65px;
    padding-top: 30px;
    text-align: left;
`;

export const ClientDetailsContainer = styled.div`
    div.title {
        padding: 15px 30px;
        color: white;
        h1 {
            font-size: 2em;
        }
    }
`;
