import { Box, Button, Fade, Paper, Typography } from "@material-ui/core";
import React, { useState } from "react";
import ReactHtmlParser from "react-html-parser";
import { Centered } from "Core/Components/Styles";
import { EditableCopyViewModel } from "Custom/ViewModels/EditableCopyViewModel";
import { useObserver } from "mobx-react-lite";

export const ForgotPasswordSuccessPage: React.FunctionComponent = () => {
    const [viewModel] = useState(() => new EditableCopyViewModel());

    return useObserver(() => (
        <Centered>
            <Fade in timeout={1000}>
                <Paper>
                    <Box padding="5px">
                        <Box justifyContent="center" textAlign="center" display="grid" minWidth="300px" maxWidth="300px">
                            <Typography component="h1" variant="h3" style={{ marginBottom: "25px", marginTop: "25px" }}>
                                {ReactHtmlParser(viewModel.getEditableCopyByRouteAndPlaceholderNumber("/forgotpassword-success", 1))}
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                {ReactHtmlParser(viewModel.getEditableCopyByRouteAndPlaceholderNumber("/forgotpassword-success", 2))}
                            </Typography>
                        </Box>
                    </Box>
                </Paper>
            </Fade>
        </Centered>
    ));
};
