import { observable } from "mobx";
import { ModelBase } from "Core/Models/ModelBase";
import { IsNotEmpty, Max, Min } from "class-validator";

//Base class expects the model type and the DTO model type
//If you are not using a DTO just pass in undefined as the DTO
export class AdminMiscModel extends ModelBase<AdminMiscModel, AdminMiscDTO> {
    public id: number | null = null;

    @observable
    @IsNotEmpty({ message: "Min sensor reading temperature is required" })
    @Max(200, { message: "Max value is 200 degrees" })
    @Min(-200, { message: "Min value is -200 degrees" })
    public minSensorTemperature: number = -3.0;

    //fromDto is required but you can leave it blank
    fromDto(model: AdminMiscDTO): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
    }

    //toDto is required but you can leave it blank
    toDto(model: AdminMiscModel): void {}

    toTheDto(): AdminMiscDTO {
        return {
            id: this.id,
            minSensorTemperature: this.minSensorTemperature,
        };
    }
}

export interface AdminMiscDTO {
    id: number | null;
    minSensorTemperature: number;
}
