import styled from "styled-components";
import { Box } from "@material-ui/core";

export const InstallationControlSection: any = styled(Box)`
    display: flex;
    flex-direction: row;
    width: 100%;
    min-height: 65px;
    flex: 1;
    background-color: #f7f7f7;
    margin-bottom: 15px;
    align-content: flex-start;
    align-items: center;
    justify-content: center;

    .form-control {
        max-width: 400px !important;
        width: 400px !important;
    }

    .success {
        color: $green;
    }

    .error {
        color: $sharmans-red;
    }
`;

export const InstallationSectionTitle: any = styled(Box)`
    display: flex;
    flex: 1;
    max-width: 300px;
    padding: 15px 30px;
    font-weight: 700;
    font-family: "Mont-Bold", "Montserrat", "Roboto Condensed", "Helvetica", "Arial", "sans-serif";
`;

export const InstallationSectionOptions: any = styled(Box)`
    display: flex;
    flex: 1;
    height: 100%;
    padding: 15px;
    flex-direction: row;

    input {
        padding: 0 15px;
        min-width: 60px !important;
        width: 60px !important;
    }

    .form-control {
        min-width: 300px !important;
        width: 300px !important;
    }
`;

export const InstallationSectionOptionValue: any = styled(Box)`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 0 15px;
    input {
        width: 100%;
        padding: 0 15px;
    }

    .section-options-valueerror {
        color: $sharmans-red;
    }

    .section-options-valueunsent {
        margin-top: 10px;
        color: $green;
    }

    .form-control {
        max-width: 400px !important;
        width: 400px !important;
    }
`;

export const SectionSend: any = styled(Box)`
    display: flex;
    flex: 0;
    padding: 15px 30px;
    width: 200px;
    margin: 0;
`;
