import styled from "styled-components";
import { Box } from "@material-ui/core";

export const AdminDetailContainer = styled(Box)`
    position: relative;
    width: 100%;
    margin-top: 2px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0px -3px 6px #00000029;
`;

export const DetailCloseRow: any = styled.div`
    display: flex;
    flex: 0 0 0;
    padding: 10px 15px 0 15px;
    width: 100%;
    justify-content: flex-end;
`;

export const DetailWrapper: any = styled.div`
    display: block;
    padding: 0 30px;
    width: 100%;
`;

export const BackToListWrapper: any = styled.div`
    display: block;
    margin-left: -30px;
    background-color: ${(props) => props.theme.app.colors.grey.back};
    margin-right: -30px;
    min-height: 48px;
`;

export const MainBackToListWrapper: any = styled.div`
    display: flex;
    flex: 1 0 0;
    flex-direction: row;
    background-color: ${(props) => props.theme.app.colors.grey.back};
    min-height: 48px;
`;

export const NameSectionBoxNoBorder: any = styled(Box)`
    display: flex;
    flex: 1 0 0;
    flex-direction: row;
    padding: 15px 30px 15px 0;
    width: 100%;
    min-width: 320px;
    min-height: 45px;
    flex: 1;
    align-content: flex-start;
`;

export const NameSectionBox: any = styled(NameSectionBoxNoBorder)`
    border-bottom: 1px solid #b2b2b2;

    .numberbox {
        min-width: 183px;
        .control-label {
            font-size: 14px;
            color: white;
            margin-bottom: 10px;
        }
    }

    .admin-color-section {
        display: flex;
        flex-direction: column;
        flex: 1 0 0;
    }
`;

export const PrimaryBox: any = styled(Box)`
    max-width: 200px;
    display: flex;
    flex: 1 0 0;
    flex-direction: row;
`;

export const SecondaryBox: any = styled(Box)`
    padding-left: 15px;
    padding-top: 25px;
    display: flex;
    flex: 1 0 0;
    flex-direction: row;

    //background-color: yellow;
`;

export const ItemSectionBoxBase: any = styled(Box)`
    display: flex;
    flex: 1 0 0;
    flex-direction: row;
    width: 100%;
    min-width: 320px;
    min-height: 45px;
    flex: 1;
    align-content: flex-start;
    /* border-bottom: 1px dashed #b2b2b2; */
    color: white;
    /* 
    background-color: yellow; */

    .unitcolumn-title {
        background: #495d89 0% 0% no-repeat padding-box;
        box-shadow: inset 0px 1px 1px #ffffff40;
        color: #fff;
        font-family: [ "Mont-Bold", "Montserrat", "Roboto Condensed", "Helvetica", "Arial", "sans-serif" ].join(",");
        border: unset;
    }
`;

export const ItemSectionBox: any = styled(ItemSectionBoxBase)`
    padding: 15px 30px 15px 0;
`;

export const ItemSectionTable: any = styled(ItemSectionBoxBase)`
    padding: 15px 0 15px 0;
`;

export const AddNewBox: any = styled(Box)`
    display: flex;
    flex: 1 0 0;
    flex-direction: row;
    padding: 15px 30px 45px 0;
    width: 100%;
    min-width: 320px;
    min-height: 45px;
    flex: 1;
    align-content: flex-start;
    border-bottom: 1px solid #b2b2b2;
`;

export const ButtonSectionBox: any = styled(Box)`
    display: flex;
    flex: 1 0 0;
    flex-direction: row;
    padding: 30px;
    width: 100%;
    min-width: 320px;
    min-height: 45px;
    flex: 1;
    align-content: flex-start;
    align-items: center;
    justify-content: center;
    color: white;
`;

export const ConditionRow: any = styled(Box)`
    padding: 10px 0;
`;

export const ConditionSectionBox: any = styled(Box)`
    display: flex;
    flex: 1 0 0;
    flex-direction: row;
    width: 100%;
    min-width: 320px;
    min-height: 45px;
    flex: 1;
    align-content: flex-start;

    .type {
    }

    .value {
        padding: 0 10px;
        input {
            max-width: 54px;
        }
    }

    .units {
        margin-top: -5px;

        .MuiButtonBase-root {
            &.Mui-disabled {
                color: white;
            }
        }

        .MuiFormControlLabel-label.Mui-disabled {
            color: white;
        }
    }
`;

export const DeleteCircle: any = styled.div`
    background-color: #c70101;
    width: 16px;
    height: 16px;
    border-radius: 8px;
    color: white;
    padding-left: 4px;
    padding-top: 1px;
    cursor: default;

    &:hover {
        cursor: pointer;
    }
`;
