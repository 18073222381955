import { Box, Fade, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import { AddressModel } from "Custom/Models/Domain/Address/AddressModel";
import { AddressViewModel } from "./AddressViewModel";
// @ts-ignore
import {
    Main,
    AddressLineBox,
    AddressResultBox,
    AddressRow,
    PropertyAddressBox,
    PropertyAddressWrapper,
    PropertyMapBox,
    PropertyMapLatLongBox,
    PropertyAddressAndMap,
    PropertyAddressColumn,
} from "./Address.style";
import { RoofcareInput, RoofcareIndividualInput, RoofcareToggleTypography, RoofcareLabel } from "Custom/StylesAppSpecific/Controls/RoofcareInput";
import { autorun, useObserver } from "Core/Base";
import { formatAddress } from "Custom/Utils/format";
import { AcceptBtn } from "Custom/StylesAppSpecific/Controls/AcceptBtn";
import { AddressItem } from "Custom/Models/API/Hopewiser";
import { generateID, isNullOrUndefined } from "Custom/Utils/utils";
import { OSMViewModel } from "Custom/ViewModels/OSMViewModel";
import { OSMLocation } from "Custom/Utils/OSM";
import L, { Map as LeafletMap } from "leaflet";
import { MapContainer, TileLayer, MapConsumer, useMap } from "react-leaflet";
import { DraggableMarker, IDraggableMarker } from "../Map/DraggablePin";
import { DEFAULTPOPUP } from "Custom/Globals/GlobalComponents";

type PropertyAddressProps = {
    viewModel: AddressViewModel;
    setAddress: (address: AddressModel) => void;
    errorMessage: string;
    generateLatLongs: boolean;
    isOpen: boolean;
};

export const PropertyAddress: React.FunctionComponent<PropertyAddressProps> = ({ viewModel, setAddress, errorMessage, generateLatLongs, isOpen }) => {
    const [osmViewModel] = useState(() => OSMViewModel.Instance);
    const [inputValue, updateText] = useState("");
    const [results, updateResults] = useState<AddressItem[]>([]);
    const [isManual, setIsManual] = useState(false);
    const [showResults, setshowResults] = useState(false);

    const [isViewModelLoaded, setViewModelLoaded] = useState(false);

    const [localMap, setLocalMap] = useState<LeafletMap | undefined>(undefined);
    const [mapId, setMapId] = useState<string>("");

    const getMapObjectCallback = (map: LeafletMap): void => {
        setLocalMap(map);
    };

    useEffect(() => {
        if (localMap && isManual === true) {
            setTimeout(function () {
                localMap.invalidateSize();
            }, 200);
        }
    }, [localMap, isManual]);

    useEffect(() => {
        if (localMap && isManual === true) {
            localMap.setView(viewModel.getPropertyCenter!, localMap.getZoom());
            setTimeout(function () {
                localMap.invalidateSize();
            }, 200);
        }

        /*         if (map !== null && map !== undefined) {
            map.fitBounds(viewModel.getMapBounds);
        } */
    }, [viewModel.getPropertyCenter]);

    //useEffect below only gets run on initial render
    useEffect(() => {
        // *optional* passing the router into the viewmodel so we can navigate from there
        //This gets run when the page is exited
        setMapId(generateID());

        return () => {
            setViewModelLoaded(false);
            setMapId("");
            // Clean up after yourself
            if (localMap) {
            }

            viewModel.clear();

            setLocalMap(undefined);
        };
    }, []);

    useEffect(() => {
        setViewModelLoaded(false);

        setTimeout(function () {
            setViewModelLoaded(true);
        }, 500);

        autorun(() => {
            if (inputValue.length == 0) {
                updateText(formatAddress(viewModel.getAddress));
            }

            const id: string | undefined = viewModel.getId;
            if (isNullOrUndefined(id) === false && id!.length > 0) {
                setIsManual(true);
            }
        });
    }, [viewModel]);

    const onInputChange = async (e: any): Promise<void> => {
        updateText(e.target.value);
        viewModel.setSearchCriteria(e.target.value);
    };

    const postcodeFocus = async () => {
        // alert("Has Focus");
    };

    const postcodeBlur = async () => {
        // alert("Lost Focus");
        if (isViewModelLoaded === true) {
            const text: string = viewModel.getPostcode.trim();
            let lookupPostcode: boolean = false;

            let lat: number | undefined = viewModel.getValue("locationLatitude");
            let lng: number | undefined = viewModel.getValue("locationLongitude");

            const locationUndefined: boolean = isNullOrUndefined(lat) === true || isNullOrUndefined(lng) === true;
            const locationZero: boolean = lat === 0 && lng === 0;

            if (locationUndefined || locationZero) {
                lookupPostcode = true;
            } else {
                if (text.toLocaleLowerCase() !== viewModel.getPostcode.trim().toLocaleLowerCase()) {
                    lookupPostcode = true;
                }
            }

            if (text.length > 5 && lookupPostcode) {
                osmViewModel.getLocationForPostcode(text).then((retVal: OSMLocation) => {
                    // ensure valid data before splatting it, since if it errors, it splats 0, 0 over the location values
                    if ((retVal.latitude !== retVal.longitude && retVal.latitude !== 0) || retVal.longitude !== 0) {
                        viewModel.setLocation(retVal.latitude, retVal.longitude);
                    }
                });
            }
        }
    };

    const toggleManualEntry = () => {
        const newManual: boolean = !isManual;
        setIsManual(newManual);
        updateResults([]);
        if (localMap !== undefined && newManual === true) {
            localMap.invalidateSize();
        }
    };

    const selectAddress = (item: AddressItem) => {
        if (isNullOrUndefined(item) === false) {
            if (item.itemText.startsWith("+")) {
                doSidSearch(item);
            } else {
                setshowResults(false);
                viewModel.getSelectedAddress(item.sid).then((result) => {
                    if (result.wasSuccessful) {
                        setAddress(viewModel.getModel);
                    }
                });
            }
        }
    };

    const doSearch = () => {
        setshowResults(false);
        viewModel.addressSearch().then((result) => {
            if (result.wasSuccessful) {
                setshowResults(true);
                updateResults(viewModel.getSearchResults);
            }
        });
    };

    const doSidSearch = (sidItem: AddressItem) => {
        setshowResults(false);
        viewModel.addressSearch(sidItem).then((result) => {
            if (result.wasSuccessful) {
                setshowResults(true);
                updateResults(viewModel.getSearchResults);
            }
        });
    };

    function SetBoundsRectangles() {
        const map = useMap();
        map.fitBounds(viewModel.getMapBounds);

        setTimeout(function () {
            if (map !== null && map !== undefined && viewModel !== null && viewModel !== undefined && isOpen === true) {
                map.fitBounds(viewModel.getMapBounds);
                map.invalidateSize();
            }
        }, 500);

        return <></>;
    }

    return useObserver(() => (
        <Main>
            <Fade in timeout={1000}>
                <>
                    <PropertyAddressWrapper>
                        <PropertyAddressBox>
                            <RoofcareLabel className="address-label">Property address</RoofcareLabel>
                            {errorMessage !== "" && (
                                <Typography variant="caption" style={{ color: "red" }}>
                                    {errorMessage}
                                </Typography>
                            )}

                            {isManual == false && (
                                <>
                                    <AddressRow>
                                        <div className="searchaddressbox">
                                            <RoofcareIndividualInput
                                                type="text"
                                                placeholder="search"
                                                value={inputValue}
                                                onChange={onInputChange}
                                                style={{ maxWidth: "640px", width: "100%" }}
                                            />
                                        </div>
                                        <AcceptBtn onClick={doSearch} disabled={inputValue.length < 6}>
                                            Find Address
                                        </AcceptBtn>
                                    </AddressRow>
                                    {results.length > 0 && showResults && (
                                        <AddressResultBox>
                                            items found: {results.length}
                                            {results.map((item: AddressItem, index: number) => {
                                                return (
                                                    <AddressLineBox
                                                        key={generateID()}
                                                        onClick={() => {
                                                            selectAddress(item);
                                                        }}
                                                    >
                                                        {item.itemText}
                                                    </AddressLineBox>
                                                );
                                            })}
                                        </AddressResultBox>
                                    )}
                                </>
                            )}
                            <Box className="address-toggle" onClick={toggleManualEntry}>
                                {isManual == false && <RoofcareToggleTypography>+ Add address manually</RoofcareToggleTypography>}
                                {isManual == true && <RoofcareToggleTypography>+ Add address autocomplete</RoofcareToggleTypography>}
                            </Box>
                            {(isManual || viewModel.errorMessage !== "" || errorMessage !== "") && (
                                <PropertyAddressAndMap>
                                    <PropertyAddressColumn className="column-1">
                                        <RoofcareInput<AddressModel>
                                            type="text"
                                            label="Building name"
                                            validateOnBlur={true}
                                            viewModel={viewModel}
                                            fieldName="buildingName"
                                            shrink={true}
                                            maxLength={128}
                                        />
                                        <RoofcareInput<AddressModel>
                                            type="text"
                                            label="Address Line 1"
                                            validateOnBlur={true}
                                            viewModel={viewModel}
                                            fieldName="addressLine1"
                                            shrink={true}
                                            maxLength={128}
                                        />
                                        <RoofcareInput<AddressModel>
                                            type="text"
                                            label="Address Line 2"
                                            validateOnBlur={true}
                                            viewModel={viewModel}
                                            fieldName="addressLine2"
                                            shrink={true}
                                            maxLength={128}
                                        />
                                        <RoofcareInput<AddressModel>
                                            type="text"
                                            label="City"
                                            validateOnBlur={true}
                                            viewModel={viewModel}
                                            fieldName="city"
                                            shrink={true}
                                            maxLength={128}
                                        />
                                        <RoofcareInput<AddressModel>
                                            type="text"
                                            label="County"
                                            validateOnBlur={true}
                                            viewModel={viewModel}
                                            fieldName="county"
                                            shrink={true}
                                            maxLength={128}
                                        />
                                        <RoofcareInput<AddressModel>
                                            type="text"
                                            label="Postcode"
                                            validateOnBlur={true}
                                            viewModel={viewModel}
                                            fieldName="postcode"
                                            shrink={true}
                                            maxLength={128}
                                            inputProps={{ onBlur: postcodeBlur(), onFocus: postcodeFocus() }}
                                        />
                                    </PropertyAddressColumn>
                                    <PropertyAddressColumn className="column-2">
                                        <PropertyMapBox>
                                            <PropertyMapLatLongBox>
                                                <div className="column-1">
                                                    <RoofcareInput<AddressModel>
                                                        type="number"
                                                        label="Latitude"
                                                        validateOnBlur={true}
                                                        viewModel={viewModel}
                                                        fieldName="locationLatitude"
                                                        shrink={true}
                                                        maxLength={20}
                                                        inputProps={{ disabled: true }}
                                                    />
                                                </div>
                                                <div className="column-2">
                                                    <RoofcareInput<AddressModel>
                                                        className=""
                                                        type="number"
                                                        label="Longitude"
                                                        validateOnBlur={true}
                                                        viewModel={viewModel}
                                                        fieldName="locationLongitude"
                                                        shrink={true}
                                                        maxLength={20}
                                                        inputProps={{ disabled: true }}
                                                    />
                                                </div>
                                            </PropertyMapLatLongBox>
                                            <MapContainer center={[51.505, -0.09]} zoom={13} style={{ width: "100%", minHeight: "350px" }}>
                                                {/*  <MapHack boundsToFit={viewModel.mapBounds} getMapObjectCallback={getMapObjectCallback} /> */}
                                                <TileLayer
                                                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                />
                                                {/*                                                 <MapConsumer>
                                                    {(map: L.Map) => {
                                                        map.fitBounds(viewModel.getMapBounds);
                                                        // console.log("map bounds:", map.getBounds());
                                                        map.invalidateSize();

                                                        map.flyToBounds(viewModel.getMapBounds);
                                                        return null;
                                                    }}
                                                </MapConsumer> */}
                                                <SetBoundsRectangles />
                                                {viewModel.getMarkers &&
                                                    viewModel.getMarkers.map((marker, index) => {
                                                        return (
                                                            <>
                                                                <DraggableMarker
                                                                    id={marker.id}
                                                                    key={generateID()}
                                                                    icon={marker.icon}
                                                                    position={marker.position}
                                                                    draggable={marker.draggable === undefined ? false : marker.draggable}
                                                                    onPositionChange={
                                                                        (marker as IDraggableMarker).onPositionChange !== undefined
                                                                            ? (marker as IDraggableMarker).onPositionChange
                                                                            : undefined
                                                                    }
                                                                    marker={marker}
                                                                    displayPopUp={DEFAULTPOPUP}
                                                                />
                                                            </>
                                                        );
                                                    })}
                                            </MapContainer>
                                        </PropertyMapBox>
                                    </PropertyAddressColumn>
                                </PropertyAddressAndMap>
                            )}
                        </PropertyAddressBox>
                    </PropertyAddressWrapper>
                </>
            </Fade>
        </Main>
    ));
};
