import React, { useContext } from "react";
import { Stores, StoresContext } from "../../Stores";
import { PrivateRoute } from "../../../Core/Utils/React";
import { ContractorListPage } from "./ContractorListPage";
import { ContractorAddEditPage } from "./ContractorAddEditPage";
import * as RouterUtil from "Custom/Utils/routing";
import { Client } from "Custom/Globals/AppUrls";

export const ContractorSetupRoutes: React.FC = () => {
    const store = useContext<Stores>(StoresContext);
    return (
        <>
            <PrivateRoute
                component={ContractorListPage}
                exact
                isAllowed={() => {
                    return store.domain.AccountStore.IsLoggedIn && RouterUtil.requireAdminLogin(store);
                }}
                path={Client.Main.Contractor.Root}
            />
            <PrivateRoute
                component={ContractorAddEditPage}
                exact
                isAllowed={() => {
                    return store.domain.AccountStore.IsLoggedIn && RouterUtil.requireAdminLogin(store);
                }}
                path={Client.Main.Contractor.ContractorAddEditDetail}
            />
        </>
    );
};
