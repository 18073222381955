import { StandingWaterStatus } from "Custom/Models/Domain";
import styled from "styled-components";

export interface IStandingWaterProps {
    backgroundColor: string;
    textColour: string;
    className?: string;
    /*     disabled?: boolean;
    onClick?: (id: string) => any; */
}

export const getStandingWaterBackgroundColour = (sw: StandingWaterStatus | undefined) => {
    let retVal: string = "#80777E";

    if (sw === undefined) {
        return retVal;
    }

    switch (sw) {
        case StandingWaterStatus.Red: {
            retVal = "#8E191F";
            break;
        }
        case StandingWaterStatus.Amber: {
            retVal = "#3a3c41";
            break;
        }
        case StandingWaterStatus.Green: {
            retVal = "#89FC9C";
            break;
        }
        case StandingWaterStatus.Unknown:
        default: {
            break;
        }
    }
    return retVal;
};

export const getStandingWaterTextColour = (sw: StandingWaterStatus | undefined) => {
    let retVal: string = "#FFFFFF";

    if (sw === undefined) {
        return retVal;
    }

    switch (sw) {
        case StandingWaterStatus.Red: {
            break;
        }
        case StandingWaterStatus.Amber: {
            //retVal = "";
            break;
        }
        case StandingWaterStatus.Green: {
            retVal = "#000000";
            break;
        }
        case StandingWaterStatus.Unknown:
        default: {
            break;
        }
    }
    return retVal;
};

export const StandingWaterBox = styled.div<IStandingWaterProps>`
    background-color: ${(props) => props.backgroundColor};
    box-shadow: inset 0px 2px 4px #565758;
    color: ${(props) => props.textColour};
    width: 25px;
    margin: 0 auto;

    &.standing-water {
        /*         display: flex;
        flex: 1 0 1;
        width: 25px;
        height: 25px;
        max-width: 25px;
        max-height: 25px;
        padding: 0;
        border-radius: 12px;
        text-align: middle;
        align-content: middle !important;
        position: relative; */
        display: flex;
        flex: 1 0 1;
        width: 100%;
        min-width: 74px;
        min-height: 25px;
        padding: 5px 0;
        border-radius: 2px;
        text-align: middle;
        align-content: middle !important;
        position: relative;

        .status-name {
            width: 100%;
            text-align: center;
            align-content: middle !important;
        }

        &.overview {
            padding: 5px !important;
        }
    }
`;
