import React, { createRef, useEffect, useMemo, useRef, useState } from "react";
import * as styles from "./Map.styles";
import { UnsupportedBrowser } from "Custom/Views/UnsupportedBrowser";
import { MapContainer, TileLayer, Marker, Popup, ZoomControl, useMap, MapConsumer } from "react-leaflet";
import { MapControl, MapControlPosition } from "./Control";
import L, { LatLngBounds, Point, Map as LeafletMap } from "leaflet";
import { generateID } from "Core/Utils/Utils";
import { DEFAULTPOPUP } from "Custom/Globals/GlobalComponents";
import { DraggableMarker, IDraggableMarker } from "./DraggablePin";

export interface IMarker {
    id: string;
    position: [number, number];
    icon: L.Icon | L.DivIcon;
    number?: number;
    draggable?: boolean;
}

interface IProps {
    id: string;
    controls?: { key: string; control: React.ReactChild; position?: MapControlPosition }[];
    boundsToFit: LatLngBounds | undefined;
    markers?: IMarker[];
    displayPopUp?: (marker: IMarker) => JSX.Element;
    getMapObjectCallback?: (map: LeafletMap) => void;
}

export const RoofcareMap: React.FC<IProps> = ({ id, controls, boundsToFit, markers, displayPopUp, getMapObjectCallback }) => {
    const popup: (marker: IMarker) => JSX.Element = displayPopUp ? displayPopUp : DEFAULTPOPUP;

    return (
        <>
            {(window as any).IE11 ? (
                <UnsupportedBrowser />
            ) : (
                <>
                    <styles.Main>
                        {boundsToFit && (
                            <MapContainer id={id} zoomControl={false} scrollWheelZoom bounds={boundsToFit}>
                                {/* <MapHack boundsToFit={boundsToFit} getMapObjectCallback={getMapObjectCallback} /> */}
                                <MapConsumer key={generateID()}>
                                    {(map: L.Map) => {
                                        map.fitBounds(boundsToFit);

                                        /* console.log("map center:", map.getCenter()); */
                                        return null;
                                    }}
                                </MapConsumer>
                                <TileLayer
                                    key={generateID()}
                                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                />
                                {markers &&
                                    markers.map((marker, index) => {
                                        return (
                                            <>
                                                <DraggableMarker
                                                    id={marker.id}
                                                    key={generateID()}
                                                    icon={marker.icon}
                                                    position={marker.position}
                                                    draggable={marker.draggable === undefined ? false : marker.draggable}
                                                    onPositionChange={
                                                        (marker as IDraggableMarker).onPositionChange !== undefined ? (marker as IDraggableMarker).onPositionChange : undefined
                                                    }
                                                    marker={marker}
                                                    displayPopUp={popup}
                                                >
                                                    <Popup key={generateID()} closeButton={false} keepInView maxWidth={280} offset={new Point(0, 30)}>
                                                        {popup(marker)}
                                                    </Popup>
                                                </DraggableMarker>
                                            </>
                                        );
                                    })}
                                <ZoomControl position="bottomleft" key={generateID()} />

                                {controls!.map((control) => (
                                    <MapControl position={control.position} key={`mapcontrol-${control.key}`}>
                                        {control.control}
                                    </MapControl>
                                ))}
                            </MapContainer>
                        )}
                    </styles.Main>
                </>
            )}
        </>
    );
};

RoofcareMap.defaultProps = {
    id: "roofcare-map-" + generateID(),
    controls: [],
    markers: [],
};
