import { DeviceAlertActionModelDTO } from "Custom/Views/Installations/TabConfigurationModel";
import { ModelBase } from "Core/Models";
import { action, IObservableArray, observable } from "mobx";

export class InstallationAnalyticsResponseModel extends ModelBase<InstallationAnalyticsResponseModel, InstallationAnalyticsResponseDto> {
    public deviceId: number = -1;
    public startDate: string = "";
    public endDate: string = "";
    @observable public alertActionAnalytics: DeviceAlertActionModelDTO | undefined = undefined;
    @observable public totalPeriodCount: number = 0;
    @observable public yearAlertActionAnalytics: DeviceAlertActionModelDTO | undefined = undefined;
    @observable public totalYearCount: number = 0;

    @action
    public reset() {
        this.deviceId = -1;
        this.startDate = "";
        this.endDate = "";
        this.alertActionAnalytics = undefined;
        this.totalPeriodCount = 0;
        this.yearAlertActionAnalytics = undefined;
        this.totalYearCount = 0;
    }
    @action
    public partialReset() {
        this.deviceId = -1;
        this.startDate = "";
        this.endDate = "";
        this.alertActionAnalytics = undefined;
        this.totalPeriodCount = 0;
    }

    @action
    fromDtoArr(model: InstallationAnalyticsResponseDto, getYearData: boolean): void {
        this.deviceId = model.deviceId;
        this.startDate = model.startDate;
        this.endDate = model.endDate;
        this.totalPeriodCount = model.totalPeriodCount;

        if (getYearData === true) {
            this.totalYearCount = model.totalYearCount;
        }

        this.alertActionAnalytics = undefined;
        this.alertActionAnalytics = model.alertActionAnalytics;

        if (getYearData === true) {
            this.yearAlertActionAnalytics = undefined;
            this.yearAlertActionAnalytics = model.yearAlertActionAnalytics;
        }
    }

    //fromDto is required but you can leave it blank
    fromDto(model: InstallationAnalyticsResponseDto): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
    }

    //toDto is required but you can leave it blank
    toDto(model: InstallationAnalyticsResponseModel): void {}
}
export interface InstallationAnalyticsResponseDto {
    deviceId: number;
    startDate: string;
    endDate: string;
    alertActionAnalytics: DeviceAlertActionModelDTO;
    totalPeriodCount: number;
    yearAlertActionAnalytics: DeviceAlertActionModelDTO;
    totalYearCount: number;
}
