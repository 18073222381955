import "./ClipboardBtn.less";

import * as React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { isNullOrUndefined } from "util";
import CopyIcon from "Custom/Content/copy.svg";

interface IClipboardButtonProps {
    type?: string;
    text: string;
    textToDisplay: string;

    backgroundControlColor: string;
    textColor: string;
}

interface IClipboardButtonState {
    copied: boolean;
}

export class ClipboardButton extends React.Component<IClipboardButtonProps, IClipboardButtonState> {
    public constructor(props: IClipboardButtonProps) {
        super(props);

        this.state = {
            copied: false,
        };
    }

    public render() {
        const { text, type, textToDisplay, textColor, backgroundControlColor } = this.props;

        const classes: string = "copy-button " + (this.state.copied === true ? "copied" : "");
        const copyType: string = isNullOrUndefined(type) === false ? type! : "Text";
        const adjustedText: string | JSX.Element = this.state.copied === true ? copyType + " Copied" : this.getText();

        return (
            <div className="control-clipboardbutton" style={{ color: textColor, backgroundColor: backgroundControlColor }}>
                {isNullOrUndefined(textToDisplay) === false && text.length > 0 && (
                    <div onClick={(e) => e.stopPropagation()}>
                        <CopyToClipboard
                            text={text}
                            onCopy={(event) => {
                                this.onCopy();
                            }}
                        >
                            <div style={{ color: textColor, backgroundColor: backgroundControlColor }} className={classes}>
                                {adjustedText}
                            </div>
                        </CopyToClipboard>
                    </div>
                )}
                {isNullOrUndefined(text) === true || (text.length === 0 && <div></div>)}
            </div>
        );
    }

    private onCopy = () => {
        this.setState({ copied: true });
        setTimeout(() => {
            this.setState({ copied: false });
        }, 3000);
    };

    private getText = () => {
        const { textToDisplay } = this.props;
        return (
            <>
                <img alt="copy" src={CopyIcon} />
                {textToDisplay}
            </>
        );
    };
}
