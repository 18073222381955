import { hot, setConfig } from "react-hot-loader";

import { AppRoutes } from "./AppRoutes";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/styles";
import React from "react";
import { Router } from "react-router-dom";
import { StylesProvider } from "@material-ui/styles";
import { ThemeProvider } from "styled-components";
import { defaultTheme } from "Custom/StylesAppSpecific/AppStyling";
import { GlobalHistory } from "Core/CoreIndex";
import { BreadcrumbsContext, IBreadcrumbContextValues } from "Custom/Views/RoofcareBreadcrumbCommon";

setConfig({
    showReactDomPatchNotification: false,
});

type ReducerFunction<T> = (currentState: T, newState: Partial<T>) => T;

const App: React.FC = () => {
    const [breadcrumbsContext, dispatchBreadcrumbsContext] = React.useReducer<ReducerFunction<IBreadcrumbContextValues>, IBreadcrumbContextValues>(
        (currentState, newState) => ({ ...currentState, ...newState }),
        {},
        (init) => ({ ...init }),
    );

    return (
        <StylesProvider injectFirst>
            <MuiThemeProvider theme={defaultTheme}>
                <ThemeProvider theme={defaultTheme}>
                    <CssBaseline>
                        <BreadcrumbsContext.Provider value={{ ...breadcrumbsContext, setContext: dispatchBreadcrumbsContext }}>
                            <Router history={GlobalHistory}>
                                <AppRoutes />
                            </Router>
                        </BreadcrumbsContext.Provider>
                    </CssBaseline>
                </ThemeProvider>
            </MuiThemeProvider>
        </StylesProvider>
    );
};

export default hot(module)(App);
