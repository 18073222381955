export const GetRoofcareTableOptions = (): object => {
    return {
        headerStyle: {
            background: " #495D89 0% 0% no-repeat padding-box",
            boxShadow: "inset 0px 1px 1px #FFFFFF40",
            color: "#fff",
            fontFamily: ["Mont-Bold", "Montserrat", "Roboto Condensed", "Helvetica", "Arial", "sans-serif"].join(","),
            border: "unset",
        },
        rowStyle: {
            backgroundColor: "#525355",
            color: "#fff",
            fontSize: 24,
            border: "unset",
        },
        filterCellStyle: {
            color: "#495D89",
            border: "unset",
        },
        pageSize: 50,
        pageSizeOptions: [10, 20, 50, 100],
        emptyRowsWhenPaging: false,
    };
};
