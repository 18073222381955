import { Box } from "@material-ui/core";
import styled from "styled-components";

export const GutterSensTabContent = styled.div`
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    min-width: 100%;

    .guttersens-overview-datatable {
        width: 100%;
    }

    .guttersens-attributes-section1 {
        padding: 5px 15px;
        display: flex;
        flex: 1 0 auto;
        flex-direction: row;
        width: 100%;

        /* background-color: yellow; */
        .guttersens-attributes-statuscalc {
            display: flex;
            flex-direction: column;
            flex: 1;
            /*background-color: orange;*/
            width: 100%;
            min-width: 640px;
            max-width: 50%;
            font-size: 16px;

            .statuscalc-installHeight {
            }

            .statuscalc-summary {
                display: flex;
                flex: 1 0 auto;
                flex-direction: row;
                justify-content: flex-start;
                font-size: 18px;

                .statuscalc-title {
                    display: flex;
                    flex: 1;
                    min-width: 400px;
                }

                .statuscalc-value {
                    display: flex;
                    flex: 1 0 auto;
                    padding-left: 30px;
                    font-weight: 700;
                    font-family: "Mont-Bold", "Montserrat", "Roboto Condensed", "Helvetica", "Arial", "sans-serif";
                }
            }

            .statuscalc-title {
                font-weight: 700;
                font-family: "Mont-Bold", "Montserrat", "Roboto Condensed", "Helvetica", "Arial", "sans-serif";
                width: 400px;
            }

            .guttersens-attributes-sideRow {
                width: 100%;
                display: flex;
                flex: 1 0 auto;
                flex-direction: row;
                flex-basis: auto;
                flex-wrap: wrap;
                justify-content: space-around;
                /*background-color: orange;*/
                .guttersens-attributes-sideRowCell {
                    display: flex;
                    flex: 1 0 auto;
                    flex-direction: column;
                    font-size: 16px;

                    .sideRowCell-title {
                        font-family: "Mont-Bold", "Montserrat", "Roboto Condensed", "Helvetica", "Arial", "sans-serif";
                    }

                    .sideRowCell-angles {
                        display: flex;
                        flex: 1 0 auto;
                        flex-direction: row;
                        justify-content: space-between;

                        .sideRowCell-angle {
                        }

                        .sideRowCell-angleradians {
                            padding-right: 15px;
                        }
                    }
                }
            }

            .flood-warning {
                color: #418aca;
            }

            .red-warning {
                color: #ce0e2d;
            }

            .amber-warning {
                color: #d58700;
            }
        }
    }
`;
