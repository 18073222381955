import { ViewModelBase } from "Core/ViewModels/ViewModelBase";
import { FieldType, isEmptyOrWhitespace } from "Core/Utils/Utils";
import { action, computed, IObservableArray, observable, runInAction } from "mobx";
import { DeviceStatusModel, DeviceStatusModelDTO } from "Custom/Models/Domain/Installations/DeviceStatusModel";
//extend viewmodel base and passing your model as the generic type
export class DeviceStatusViewModel extends ViewModelBase<DeviceStatusModel> {
    //Singleton instance of class
    private static _instance: DeviceStatusViewModel;
    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    constructor() {
        super(new DeviceStatusModel(), false);
    }

    @observable
    public errorMessage: string = "";

    @observable
    public unitStatuses: DeviceStatusModel[] = [];

    @action
    public setStatusesFromDTO = (statuses: DeviceStatusModelDTO[]) => {
        this.unitStatuses = [];
        if (statuses !== undefined && statuses !== null && statuses.length > 0) {
            statuses.forEach((status, index) => {
                let domainModel = new DeviceStatusModel();
                domainModel.fromDto(status);
                this.unitStatuses.push(domainModel);
            });
        }
    };

    //This must be present in your viewmodel. Just return true if you dont need to validate anything.
    //keyof BlankModel & string lets you add type checking to the fieldName
    //I am using the validator package to make checking easier https://www.npmjs.com/package/validator
    public isFieldValid(fieldName: keyof FieldType<DeviceStatusModel>): boolean {
        const { isValid, errorMessage } = this.validateDecorators(fieldName);

        //You need to this two properties after validation
        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
