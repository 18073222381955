import { Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { DeviceUnitBehaviourViewModel } from "./DeviceUnitBehaviourViewModel";
import { RoofcareInput } from "Custom/StylesAppSpecific/Controls/RoofcareInput";
import { AdminDetailContainer, DeleteCircle, DetailCloseRow, DetailWrapper, ItemSectionBox, NameSectionBox } from "Custom/Views/Admin/Condition/Condition.style";
import { DeleteModal } from "Custom/Components/Modal/DeleteModal";
import { Loader } from "Core/Components";
import { useLoadCall } from "Custom/Utils/useLoadCall";
import { isGuid } from "Custom/Utils/isGuid";
import { useObserver } from "mobx-react-lite";
import { generateID } from "Core/Utils/Utils";
import { UnitBehaviourItem } from "Custom/Views/Admin/UnitBehaviour/UnitBehaviourItem";
import { UnitBehaviourAddEditDetailModel } from "Custom/Views/Admin/UnitBehaviour/UnitBehaviourAddEditDetailModel";
import { DrainageType } from "Custom/Models/Domain";
import { UnitBehaviourItemViewModel } from "Custom/Views/Admin/UnitBehaviour/UnitBehaviourItemViewModel";

interface IDeviceUnitBehaviourViewProps {
    viewModel: DeviceUnitBehaviourViewModel | undefined | null;
    id: string;
    drainageType: DrainageType;
    handleDelete: () => void;
}

export const DeviceUnitBehaviourView: React.FunctionComponent<IDeviceUnitBehaviourViewProps> = ({ viewModel, id, handleDelete, drainageType }) => {
    const [firstLoad, setFirstLoad] = useState<boolean>(true);

    //useEffect below only gets run on initial render
    useEffect(() => {
        // *optional* passing the router into the viewmodel so we can navigate from there
        //console.log("First render");
        //This gets run when the page is exited
        return () => {
            // console.log("Unmounting");
            setFirstLoad(true);
        };
    }, []);

    useEffect(() => {
        if (firstLoad === false) {
            if (id === "") {
            } else if (id === "0") {
                // then we are loading a new one
                viewModel!.clear();
            } else {
                let promise = viewModel!.loadAdminUnitBehavioursAsync(id);

                promise.then((result) => {
                    if (result.wasSuccessful === true) {
                        // let items: UnitBehaviourItemViewModel[] = viewModel!.getUnitBehaviourItemViewModels;
                    }
                });
            }
        }
    }, [id]);

    const notFound = useLoadCall(
        async () => {
            if (id === "" || id.length === 0) {
                // Then we are loading one from the database
                setFirstLoad(false);
                return true;
            } else if (id === "0") {
                // then we are loading a new one
                setFirstLoad(false);
                return true;
            } else {
                // then we are loading an Admin one so load it.
                let promise = await viewModel!.loadAdminUnitBehavioursAsync(id);

                if (promise.wasSuccessful === true) {
                }

                setFirstLoad(false);
                //return !!viewModel!.getValue("id");
                // We have hacked out the id, so return true;
                return true;
            }
        },
        { customCheck: () => id === "0" || id === "" || isGuid(id) },
    );

    const onDeleteRow = () => {};

    const getRows = () => {
        return viewModel!.getUnitBehaviourItemViewModels.map((item: any) => {
            return (
                <UnitBehaviourItem
                    key={generateID()}
                    localViewModel={item}
                    onDelete={onDeleteRow}
                    drainageType={drainageType}
                    allowedit={viewModel !== undefined ? !viewModel!.disableControls : true}
                />
            );
        });
    };

    function renderPage() {
        if (viewModel === undefined || viewModel === null || (id.length !== 0 && notFound) || notFound) return <Loader />;

        return (
            <>
                {/*                 <DeleteModal
                    open={areYouSureOpen}
                    onClose={handleAreYouSureClose}
                    onDelete={handleSaveUnitBehaviour}
                    text="This unit behaviour set does not contain all the options. Do you want to continue?"
                    title="Do you want to continue?"
                /> */}
                <AdminDetailContainer>
                    <DetailCloseRow>
                        <DeleteCircle onClick={handleDelete} style={{ marginTop: "4px" }}>
                            X
                        </DeleteCircle>
                    </DetailCloseRow>
                    <DetailWrapper>
                        <NameSectionBox>
                            <RoofcareInput<UnitBehaviourAddEditDetailModel>
                                type="text"
                                label={"Unit Behaviour:"}
                                validateOnBlur={true}
                                viewModel={viewModel}
                                fieldName="name"
                                shrink={true}
                                maxLength={128}
                                inputProps={{
                                    disabled: viewModel !== undefined ? viewModel.disableControls : false,
                                }}
                            />
                        </NameSectionBox>
                        <ItemSectionBox>
                            <Grid container direction="column" spacing={1}>
                                <Grid container direction="row" spacing={1}>
                                    <Grid item xs={2} className="column-title">
                                        <Typography variant="h6">Name</Typography>
                                    </Grid>
                                    <Grid item xs={3} className="column-title">
                                        <Typography variant="h6">Condition</Typography>
                                    </Grid>
                                    <Grid item xs={2} className="column-title">
                                        <Typography variant="h6">Read (mins)</Typography>
                                    </Grid>
                                    <Grid item xs={1} className="column-title">
                                        <Typography variant="h6">Send</Typography>
                                    </Grid>
                                    <Grid item xs={1} className="column-title">
                                        <Typography variant="h6"></Typography>
                                    </Grid>
                                </Grid>
                                {getRows()}
                            </Grid>
                        </ItemSectionBox>
                    </DetailWrapper>
                </AdminDetailContainer>
            </>
        );
    }

    return useObserver(() => renderPage());
};
