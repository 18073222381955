export interface InstallationCommandNoteDTO {
    id: number;

    deviceDbId: number;

    authorId: string;

    note: string;

    publishDate: string;

    isDeleted: boolean;
}

export const DefaultCommandNote: InstallationCommandNoteDTO = {
    id: -1,
    deviceDbId: -1,
    authorId: "",
    note: "",
    publishDate: "",
    isDeleted: false,
};
