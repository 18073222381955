import { GenericIdRequest } from "Custom/Models";
import { ViewModelBase } from "Core/ViewModels/ViewModelBase";
import { action, computed, observable, runInAction } from "mobx";
import { FieldType } from "Core/Utils/Utils";
import { ApiResult, ApiResultErrorType } from "Core/Models";
import { Server } from "Custom/Globals/AppUrls";
import { AlertActionAddEditDetailModel, AlertActionAddEditDetailModelDTO } from "Custom/Views/Admin/AlertAction/AlertActionAddEditDetailModel";
import { AlertActionItemViewModel } from "Custom/Views/Admin/AlertAction/AlertActionItemViewModel";
import { AlertActionItemModel, AlertActionItemModelDTO } from "Custom/Views/Admin/AlertAction/AlertActionItemModel";
import { DeviceAlertActionItemModelDTO, DeviceAlertActionModelDTO, TabConfigurationModelDTO } from "../TabConfigurationModel";
import { InstallationStatusDataDTO, RoofType } from "Custom/Models/Domain";

//extend viewmodel base and passing your model as the generic type
export class AlertActionViewModel extends ViewModelBase<AlertActionAddEditDetailModel> {
    //Singleton instance of class
    private static _instance: AlertActionViewModel;
    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    @observable public errorMessage: string = "";

    @observable public isEditable: boolean = false;

    @computed public get getIsEditable(): boolean {
        return this.isEditable;
    }

    @action public setIsEditable = (val: boolean): void => {
        this.isEditable = val;
    };

    @observable public alertActions: AlertActionItemViewModel[] = [];

    @computed public get canAddAlertAction(): boolean {
        return this.alertActions.length < 4;
    }

    constructor() {
        //Pass in a new instance of your model
        //By passing in true as the second parameter, we make this model undoable which means we can use save and reset options on the model
        //If you make a change to the model you need to persist it with a saveModel() call
        //If you make changes to your model you can revert it back by calling resetModel()
        super(new AlertActionAddEditDetailModel(), false);
        //EN - Haven't figured out how to make this call work from the base model yet
        //This is only needed if you make use of the validation decorators
        this.setDecorators(AlertActionAddEditDetailModel);
    }

    @action
    addNewAlertAction() {
        let newAlertAction: AlertActionItemViewModel = new AlertActionItemViewModel();
        newAlertAction.model.setCommandIndex(this.alertActions.length);
        this.alertActions.push(newAlertAction);
    }

    @computed get getAlertActions() {
        return this.alertActions.slice().filter((a) => a.model.isDeleted === false);
    }

    @action public isModelAndChildrenValid = (statusData: InstallationStatusDataDTO | undefined): boolean => {
        if (statusData !== undefined) {
            let temp: AlertActionItemViewModel[] = this.alertActions.slice();
            // Need our working height.
            let maxWorkingHeightValue: number = Math.min(statusData.side1_Height, statusData.side2_Height);

            if (statusData.roofType === RoofType.Flat) {
                maxWorkingHeightValue = statusData.maxHeight;
            }
            const freeboardHeightValue: number = maxWorkingHeightValue * 0.7;

            for (let i: number = 0; i < temp.length; i++) {
                temp[i].setTransients(maxWorkingHeightValue, freeboardHeightValue, statusData!.p1, statusData!.p2);
            }
        }
        let retVal: boolean = this.isModelValid();

        if (retVal) {
            //Do stuff here
        } else {
            this.errorMessage = "Form is not valid";
        }

        for (let i: number = 0; i < this.alertActions.length; i++) {
            if (this.alertActions[i].isModelValidWithSiblings(i, this.alertActions) === false) {
                retVal = false;
                this.errorMessage = "Form is not valid";
            }
        }
        return retVal;
    };

    //isValid will check all fields to make sure they are in a valid state.
    public doSubmit = async (e: any) => {
        e.preventDefault();

        if (this.isModelValid()) {
            //Do stuff here
        } else {
            this.errorMessage = "Form is not valid";
        }
    };

    //This must be present in your viewmodel. Just return true if you dont need to validate anything.
    //keyof BlankModel & string lets you add type checking to the fieldName
    //I am using the validator package to make checking easier https://www.npmjs.com/package/validator
    public isFieldValid(fieldName: keyof FieldType<AlertActionAddEditDetailModel>, value: any): boolean {
        const { isValid, errorMessage } = this.validateDecorators(fieldName);

        //You need to this two properties after validation
        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public getAlertActionItemsDTO = (): DeviceAlertActionItemModelDTO[] => {
        const retVal: DeviceAlertActionItemModelDTO[] = [];

        for (let i: number = 0; i < this.alertActions.length; i++) {
            const model = this.alertActions[i].model;
            if ((model.id === null || model.id === undefined) && model.isDeleted === true) {
                // then it has not gone to the DB but has been deleted, so don't send it to the DB
            } else {
                // it may (existing) or may not (new) have an id.  But isdeleted is only true if it has an id.
                // so we need to send it to soft delete it in the DB
                const item: AlertActionItemModelDTO = model.toSendDto();
                const dto: DeviceAlertActionItemModelDTO = {
                    id: item.id,
                    rowVersion: item.rowVersion,
                    isDeleted: item.isDeleted,
                    createdBy: item.createdBy,
                    createdDate: item.createdDate,

                    name: item.name,
                    type: item.type,
                    value: item.value,
                    direction: item.direction,
                    units: item.units,
                    deviceAlertActionId: item.alertActionId,
                    email: item.email,
                    photo: item.photo,
                    sms: item.sms,
                    commandIndex: item.commandIndex,
                    alertColour: item.alertColour,
                    alertTextColour: item.alertTextColour,
                    calculatedValue: item.value, // won't use this se
                    count: 0, //item.count,
                    cutOff: item.value,
                };
                retVal.push(dto);
            }
        }

        return retVal;
    };

    @action
    public editAlertAction(): void {
        this.setIsEditable(!this.isEditable);
    }

    @action
    public upsertDeviceAlertAction = async (deviceId: number): Promise<ApiResult<TabConfigurationModelDTO>> => {
        let request: DeviceAlertActionModelDTO = {
            id: this.model.id,
            name: this.model.name,
            rowVersion: this.model.rowVersion,
            isDeleted: this.model.isDeleted,
            createdBy: this.model.createdBy,
            createdDate: this.model.createdDate,
            deviceId: deviceId,
            alertActionId: this.model.alertActionId,
            items: this.getAlertActionItemsDTO(),
        };

        const apiResult = await this.Post<TabConfigurationModelDTO>(Server.Api.Installation.upsertDeviceAlertAction, request);

        runInAction(() => {
            if (apiResult.wasSuccessful === true) {
                this.errorMessage = "";
            } else {
                this.errorMessage = "Failed to store the value.";

                apiResult.wasSuccessful = false;
                apiResult.errors = [
                    {
                        type: ApiResultErrorType.Basic,
                        message: "Failed to store the value.",
                    },
                ];
            }
        });

        return apiResult;
    };

    @action
    public setDeviceAlertActionIsDeleted = async (): Promise<ApiResult<TabConfigurationModelDTO>> => {
        const id = this.model.id;

        const apiResult = await this.Post<TabConfigurationModelDTO>(Server.Api.Installation.deleteDeviceAlertAction, { id });

        runInAction(() => {
            if (apiResult.wasSuccessful === true) {
                this.errorMessage = "";
            } else {
                this.errorMessage = "Failed to delete the value.";

                apiResult.wasSuccessful = false;
                apiResult.errors = [
                    {
                        type: ApiResultErrorType.Basic,
                        message: "Failed to delete the value.",
                    },
                ];
            }
        });

        return apiResult;
    };

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
