import { ApiResult } from "Core/Models/ApiResult";
import { isNullOrUndefined } from "./../../Utils/utils";
import { ViewModelBase } from "Core/ViewModels/ViewModelBase";
import { FieldType } from "Core/Utils/Utils";
import { Server } from "Custom/Globals/AppUrls";
import { runInAction, action, computed, observable, IObservableArray } from "mobx";
import { StoresInstance } from "Custom/Stores";
import {
    SensorValueDTO,
    InstallationStatusDataDTO,
    InstallationCommand,
    InstallationAndRelated,
    InstallationAndRelatedDTO,
    InstallationCommandNoteDTO,
    InstallationImage,
    InstallationListItem,
    InstallationImageFlipped,
    GetValuesByDateRequest,
    SensorValueExtended,
    DefaultCommandNote,
} from "Custom/Models/Domain/Installations";

import moment, { Moment } from "moment";
import { InstallationCommandHistoryDTO } from "Custom/Models/Domain/Installations/InstallationCommandHistory";
import { DeviceAlertActionModelDTO, DeviceConditionSetModelDTO } from "./TabConfigurationModel";
import { DefaultUnitNote, UnitNoteDTO } from "../Units/UnitNote";
import { InstallationImageViewModel2 } from "Custom/Components/Panels/InstallationImageViewModel";

const domainStores = StoresInstance.domain;
//extend viewmodel base and passing your model as the generic type
export class InstallationViewModel extends ViewModelBase<InstallationAndRelated> {
    //Singleton instance of class
    private static _instance: InstallationViewModel;

    @observable public reloadAverageData: boolean = false;

    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    @observable deviceDataLoading: boolean = false;

    @observable imageDataLoading: boolean = false;

    @computed public get getStatusData(): InstallationStatusDataDTO | undefined {
        let retVal: InstallationStatusDataDTO | undefined = undefined;

        if (this.model !== undefined && this.model !== null) {
            if (this.model.statusData !== null && this.model.statusData !== undefined) {
                retVal = this.model.statusData;
            }
        }
        return retVal;
    }

    @computed public get hasStatusData(): boolean {
        return this.getStatusData !== undefined;
    }

    @computed public get hasEmailAddresses(): boolean {
        let retVal: boolean = false;
        const data: InstallationStatusDataDTO | undefined = this.getStatusData;

        if (data !== undefined) {
            retVal = data.emailAddresses !== undefined && data.emailAddresses !== null && data.emailAddresses.length > 0;
        }

        return retVal;
    }

    @computed public get hasMobileNumbers(): boolean {
        let retVal: boolean = false;
        const data: InstallationStatusDataDTO | undefined = this.getStatusData;

        if (data !== undefined) {
            retVal = data.mobileNumbers !== undefined && data.mobileNumbers !== null && data.mobileNumbers.length > 0;
        }

        return retVal;
    }

    @action public setReloadAverageData = (value: boolean) => {
        this.reloadAverageData = value;
    };

    // Are we editing things?
    @observable isEditingAlertAction: boolean = false;
    @observable isEditingConditionSet: boolean = false;

    @action setIsEditingAlertAction = (state: boolean) => {
        this.isEditingAlertAction = state;
    };

    @action setIsEditingConditionSet = (state: boolean) => {
        this.isEditingConditionSet = state;
    };

    @action resetIsEditing() {
        this.isEditingAlertAction = false;
        this.isEditingConditionSet = false;
    }

    @computed get getIsEditing(): boolean {
        return this.isEditingAlertAction || this.isEditingConditionSet;
    }

    @computed
    public get device(): InstallationListItem | undefined {
        return this.model.device;
    }

    @computed public get getConditionSet(): DeviceConditionSetModelDTO | undefined {
        let retVal: DeviceConditionSetModelDTO | undefined = undefined;

        if (this.model.conditionSets !== null && this.model.conditionSets !== undefined) {
            if (this.model.conditionSets.length > 0) {
                retVal = this.model.conditionSets[0];
            }
        }

        return retVal;
    }

    @computed public get getAlertActions(): DeviceAlertActionModelDTO | undefined {
        let retVal: DeviceAlertActionModelDTO | undefined = undefined;

        if (this.model.alertActions !== null && this.model.alertActions !== undefined) {
            if (this.model.alertActions.length > 0) {
                retVal = this.model.alertActions[0];
            }
        }

        return retVal;
    }

    @computed
    public get images(): InstallationImage[] {
        return this.model.images ? this.model.images : [];
    }

    @computed
    public get readings(): SensorValueDTO[] {
        return this.model.values ? this.model.values : [];
    }

    @computed
    public get graphSensorValues(): SensorValueDTO[] {
        let retVal: SensorValueDTO[] = this.model.values ? this.model.values.slice().filter((a) => a.hideOnGraph === false) : [];
        return retVal;
    }

    @computed
    public get statusData(): InstallationStatusDataDTO | undefined {
        return this.model.statusData;
    }

    @computed
    public get deviceNotes(): UnitNoteDTO[] {
        return this.model.notes.slice(0).filter((a) => a.isDeleted === false);
    }

    @action
    public setDeviceNotes = (notes: UnitNoteDTO[]): any => {
        this.model.notes.clear();
        this.model.notes.replace(notes);
    };

    @computed
    public get commandHistory(): InstallationCommandHistoryDTO[] {
        return this.model.commandHistory.slice(0);
    }

    @computed
    public get commands(): InstallationCommand[] {
        return this.model.commands.slice(0);
    }

    @computed
    public get commandNotes(): InstallationCommandNoteDTO[] {
        return this.model.commandNotes.slice(0);
    }

    @action
    public updateIsHiddenResult(values: SensorValueExtended[]) {
        this.model.updateIsHiddenResult(values);
    }

    @action
    public updateDeviceDataToGraph = async (startDate: Moment, endDate: Moment, deviceId: number) => {
        try {
            if (this.deviceDataLoading === false) {
                this.deviceDataLoading = true;
                this.setIsLoading(true);

                if (startDate > endDate) {
                    // Swap the dates
                    const temp: Moment = startDate;
                    startDate = endDate;
                    endDate = temp;
                }

                const request: GetValuesByDateRequest = {
                    id: deviceId,
                    startDate: startDate.toISOString(),
                    endDate: endDate.toISOString(),
                };

                const apiResult = await this.Post<SensorValueDTO[]>(Server.Api.Installation.getReadingsForInstallationBetweenDates, request);

                runInAction(() => {
                    if (apiResult.wasSuccessful) {
                        this.deviceDataLoading = false;
                    } else {
                        // Error. What to do?
                        this.setIsErrored(true);
                        this.history.push("/");
                        this.deviceDataLoading = false;
                    }
                });
            }
        } catch (exception) {
            // Error. What to do?
            this.setIsErrored(true);
            this.history.push("/");
            this.deviceDataLoading = false;
        } finally {
            this.setIsLoading(false);
            this.deviceDataLoading = false;
        }
    };

    @action
    public updateFlippedFromModal(modalModels: InstallationImageViewModel2[]) {
        for (let x: number = 0; x < this.model.images.length; x++) {
            const found: InstallationImageViewModel2 | undefined = modalModels.find((a) => a.id === this.model.images[x].id);

            if (found !== undefined) {
                if (found.flipped !== this.model.images[x].flipped) {
                    this.model.images[x].flipped = found.flipped;
                }
            }
        }
    }

    constructor() {
        //Pass in a new instance of your model
        //By passing in true as the second parameter, we make this model undoable which means we can use save and reset options on the model
        //If you make a change to the model you need to persist it with a saveModel() call
        //If you make changes to your model you can revert it back by calling resetModel()
        super(new InstallationAndRelated(), false);
        //EN - Haven't figured out how to make this call work from the base model yet
        //This is only needed if you make use of the validation decorators
        this.setDecorators(InstallationAndRelated);
    }

    //This must be present in your viewmodel. Just return true if you dont need to validate anything.
    //keyof BlankModel & string lets you add type checking to the fieldName
    //I am using the validator package to make checking easier https://www.npmjs.com/package/validator
    public isFieldValid(fieldName: keyof FieldType<InstallationAndRelated>): boolean {
        const { isValid, errorMessage } = this.validateDecorators(fieldName);

        //You need to this two properties after validation
        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    @action
    public loadInstallation = async (id: number, periodDays: number): Promise<void> => {
        try {
            this.setIsLoading(true);

            const request = {
                id,
                periodDays,
            };

            const apiResult = await this.Post<InstallationAndRelatedDTO>(Server.Api.Installation.getInstallationAndRelated, request);

            if (apiResult.wasSuccessful) {
                runInAction(() => this.model.fromDto(apiResult.payload));
            } else {
                // Error. What to do?
                this.setIsErrored(true);
                this.history.push("/");
            }
        } catch (exception) {
            // Error. What to do?
            this.setIsErrored(true);
            this.history.push("/");
        } finally {
            this.setIsLoading(false);
        }
    };

    @computed
    public get getEarliestLegalDate(): Moment {
        let retVal: Moment =
            this.device !== null && this.device !== undefined && this.device!.earliestValue !== null && this.device!.earliestValue !== undefined
                ? moment.utc(this.device!.earliestValue!)
                : moment.utc("1990-01-01T00:00:00Z");

        if (this.statusData !== null && this.statusData !== undefined && this.statusData.commissionDate !== null) {
            const commissioned: Moment = moment.utc(this.statusData!.commissionDate!);

            if (retVal < commissioned) {
                retVal = commissioned;
            }
        }

        return retVal;
    }

    @action
    public updateImage = async (deviceImageFlipped: InstallationImageFlipped): Promise<void> => {
        try {
            this.setIsLoading(true);
            const apiResult = await this.Post<InstallationImage>(Server.Api.Installation.updateImageOrientation, deviceImageFlipped);

            if (apiResult.wasSuccessful) {
                runInAction(() => {
                    if (apiResult.payload !== null && apiResult.payload !== undefined) {
                        const foundImage = this.model.images?.find((a) => a.id === apiResult.payload.id);

                        if (isNullOrUndefined(foundImage) === false) {
                            foundImage!.flipped = apiResult.payload.flipped;
                        } else {
                            this.model.images?.push(apiResult.payload);
                        }
                    }
                });
            } else {
                // Error. What to do?
                this.setIsErrored(true);
                this.history.push("/");
            }
        } catch (exception) {
            // Error. What to do?
            this.setIsErrored(true);
            this.history.push("/");
        } finally {
            this.setIsLoading(false);
        }
    };

    @action
    public upsertInstallationNote = async (note: string): Promise<ApiResult<UnitNoteDTO[]>> => {
        this.setIsLoading(true);

        const request: UnitNoteDTO = DefaultUnitNote();
        request.deviceDbId = this.model.device?.id!;
        request.note = note;
        request.authorId = domainStores.AccountStore.GivenName;
        request.publishDate = moment.utc().toISOString();

        const apiResult = await this.Post<UnitNoteDTO[]>(Server.Api.Unit.upsertDeviceUnitNote, request);

        if (apiResult.wasSuccessful) {
            runInAction(() => {
                if (apiResult.payload !== null && apiResult.payload !== undefined) {
                    const notes: UnitNoteDTO[] = apiResult.payload;
                    this.model.notes.replace(notes);
                }
                this.setIsLoading(false);
            });
        } else {
            // Error. What to do?
            this.setIsLoading(false);
            this.setIsErrored(true);
            this.history.push("/");
        }

        return apiResult;
    };

    @action
    public addCommand = async (newCommand: InstallationCommand): Promise<void> => {
        try {
            this.setIsLoading(true);
            const apiResult = await this.Post<InstallationCommand>(Server.Api.Installation.upsertSimpleCommand, newCommand);

            if (apiResult.wasSuccessful) {
                runInAction(() => {
                    if (apiResult.payload !== null && apiResult.payload !== undefined) {
                        const command: InstallationCommand = apiResult.payload;
                        const existing: InstallationCommand | undefined = this.commands.find((a) => a.commandItem === command.commandItem && a.simId === command.simId);

                        if (existing !== undefined) {
                            existing.completed = command.completed;
                            existing.command = command.command;
                            existing.value = command.value;
                        } else {
                            this.commands.push(command);
                        }
                    }
                });
            } else {
                // Error. What to do?
                this.setIsErrored(true);
                this.history.push("/");
            }
        } catch (exception) {
            // Error. What to do?
            this.setIsErrored(true);
            this.history.push("/");
        } finally {
            this.setIsLoading(false);
        }
    };

    @action
    public upsertCommandNote = async (noteText: string): Promise<ApiResult<InstallationCommandNoteDTO[]>> => {
        this.setIsLoading(true);

        const request: InstallationCommandNoteDTO = DefaultCommandNote;
        request.deviceDbId = this.model.device?.id!;
        request.note = noteText;
        request.authorId = domainStores.AccountStore.GivenName;
        request.publishDate = moment.utc().toISOString();

        const apiResult = await this.Post<InstallationCommandNoteDTO[]>(Server.Api.Installation.upsertCommandNote, request);

        if (apiResult.wasSuccessful) {
            runInAction(() => {
                if (apiResult.payload !== null && apiResult.payload !== undefined) {
                    const notes: InstallationCommandNoteDTO[] = apiResult.payload;
                    this.model.commandNotes.replace(notes);
                }
                this.setIsLoading(false);
            });
        } else {
            // Error. What to do?
            this.setIsLoading(false);
            this.setIsErrored(true);
            this.history.push("/");
        }

        return apiResult;
    };

    @action
    public updateCommandHistory = (newCommands: any[], commands: InstallationCommand[]): void => {
        this.model.commandHistory.replace(newCommands);

        this.model.commands.replace(commands);
    };
}
