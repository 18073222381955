import { ViewModelBase } from "Core/ViewModels/ViewModelBase";
import { FieldType } from "Core/Utils/Utils";
import { AdminUnitFirmwareTabModel } from "./AdminUnitFirmwareTabModel";
import { action, computed, observable, runInAction } from "mobx";
import { ApiResult, ApiResultErrorType } from "Core/Models";
import { Server } from "Custom/Globals/AppUrls";
import { isNullOrUndefined } from "Custom/Utils/utils";
import { DeleteRequest } from "Custom/Views/User/DeleteUserRequest";
import { UnitFirmwareDTO } from "Custom/Views/Units/UnitFirmware";
import { GenericIdNumberRequest } from "Custom/Models";

//extend viewmodel base and passing your model as the generic type
export class AdminUnitFirmwareTabViewModel extends ViewModelBase<AdminUnitFirmwareTabModel> {
    //Singleton instance of class
    private static _instance: AdminUnitFirmwareTabViewModel;
    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    @observable public errorMessage: string = "";
    @observable public searchString: string = "";

    public items = observable<AdminUnitFirmwareTabModel>([]);

    @action public setSearchString(value: string) {
        this.searchString = value;
    }

    @action public clear() {
        this.items.clear();
    }

    @action public async loadList() {
        let apiResult: ApiResult<UnitFirmwareDTO[] | undefined> = {
            wasSuccessful: false,
            errors: [],
            headers: "",
            payload: undefined,
        };

        apiResult = await this.Post<UnitFirmwareDTO[]>(Server.Api.StaticData.getAllUnitFirmwareForList);

        runInAction(() => {
            if (apiResult.wasSuccessful === true) {
                //this.showError = false;
                this.errorMessage = "";
                //this.showSuccess = true;

                if (isNullOrUndefined(apiResult.payload) === false) {
                    this.items.replace(
                        apiResult.payload!.map((d) => {
                            const newItem = new AdminUnitFirmwareTabModel();
                            newItem.fromDto(d);
                            return newItem;
                        }),
                    );
                } else {
                    this.items.clear();
                }

                /*                 setTimeout(() => {
                        runInAction(() => {
                            // this.showSuccess = false;
                        });
                    }, 3000); */
            } else {
                // this.isLoading = false;
                //this.showAPIError = true;
                this.errorMessage = "Failed to get the list.";

                apiResult.wasSuccessful = false;
                apiResult.errors = [
                    {
                        type: ApiResultErrorType.Basic,
                        message: "Failed to get the list.",
                    },
                ];

                setTimeout(() => {
                    runInAction(() => {
                        //this.showAPIError = false;
                    });
                }, 5000);
            }
        });

        return apiResult;
    }

    public delete = async (selectedId: number): Promise<ApiResult<UnitFirmwareDTO>> => {
        const request: GenericIdNumberRequest = {
            id: selectedId,
        };
        const apiResult = await this.Post<UnitFirmwareDTO>(Server.Api.StaticData.deleteUnitFirmware, request);

        if (apiResult.wasSuccessful) {
            runInAction(() => {
                if (isNullOrUndefined(apiResult.payload) === false) {
                    const newItem = new AdminUnitFirmwareTabModel();
                    newItem.fromDto(apiResult.payload);

                    let item = this.items.find((a) => a.id === newItem.id);

                    if (item !== undefined) {
                        this.items.remove(item);
                    }
                } else {
                    this.items.clear();
                }
            });
        }
        return apiResult;
    };

    @computed public get getFilteredItems() {
        return this.items
            .slice()
            .filter((a) => a.id !== null && a.id! > 1 && (this.searchString.length === 0 || a.name.toLocaleLowerCase().indexOf(this.searchString.toLocaleLowerCase()) !== -1));
    }

    public getSearchString = () => {
        return computed(() => this.searchString);
    };

    constructor() {
        //Pass in a new instance of your model
        //By passing in true as the second parameter, we make this model undoable which means we can use save and reset options on the model
        //If you make a change to the model you need to persist it with a saveModel() call
        //If you make changes to your model you can revert it back by calling resetModel()
        super(new AdminUnitFirmwareTabModel(), false);
        //EN - Haven't figured out how to make this call work from the base model yet
        //This is only needed if you make use of the validation decorators
        this.setDecorators(AdminUnitFirmwareTabModel);
    }

    //isValid will check all fields to make sure they are in a valid state.
    public doSubmit = async (e: any) => {
        e.preventDefault();

        if (this.isModelValid()) {
            //Do stuff here
            this.errorMessage = "Form is valid";
        } else {
            this.errorMessage = "Form is not valid";
        }
    };

    //This must be present in your viewmodel. Just return true if you dont need to validate anything.
    //keyof BlankModel & string lets you add type checking to the fieldName
    //I am using the validator package to make checking easier https://www.npmjs.com/package/validator
    public isFieldValid(fieldName: keyof FieldType<AdminUnitFirmwareTabModel>): boolean {
        const { isValid, errorMessage } = this.validateDecorators(fieldName);

        //You need to this two properties after validation
        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
