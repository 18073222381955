import { ViewModelBase } from "Core/ViewModels/ViewModelBase";
import { ClientModel } from "Custom/Models/Domain/Clients/ClientModel";
import { FieldType } from "Core/Utils/Utils";
import { action, computed, observable, runInAction } from "mobx";
import { ApiResult } from "Core/Models";
import { GenericIdRequest } from "Custom/Models";
import { Client, Server } from "Custom/Globals/AppUrls";
import { AddressModel } from "Custom/Models/Domain/Address/AddressModel";
import { ClientAndContactsDTO, ClientAndContactsModel } from "Custom/Models/Domain/Clients/ClientAndContact";
import { ContactModel, ContactModelDTO } from "Custom/Models/Domain/Contacts/ContactModel";
import { ContactModel as ComponentContactModel } from "Custom/Components/Contact";
import validator from "validator";
import { History } from "history";
import { getHistory } from "Core/Utils/Utils";
import { StoresInstance } from "Custom/Stores/Stores";
import { formatAddress } from "Custom/Utils/format";

//extend viewmodel base and passing your model as the generic type
export class ClientViewModel extends ViewModelBase<ClientAndContactsModel> {
    public history: History;
    //Singleton instance of class
    private static _instance: ClientViewModel;

    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    @observable public errorMessage: string = "";

    @computed get getClient(): ClientModel {
        return this.model.client;
    }

    @computed get getClientContacts(): ContactModel[] {
        return this.model.contacts.slice();
    }

    @computed get getComponentContacts(): ComponentContactModel[] {
        let retVal: ComponentContactModel[] = [];
        let list = this.model.contacts
            .slice()
            .filter((c) => c.isDeleted === false)
            .sort((a: ContactModel, b: ContactModel) => {
                if (a === undefined && b === undefined) {
                    return 0;
                }

                if (a === undefined) {
                    return -1;
                }

                if (b === undefined) {
                    return 1;
                }

                if (a.firstName.toLocaleLowerCase() === b.firstName.toLocaleLowerCase()) {
                    return a.displayName.toLocaleLowerCase() < b.displayName.toLocaleLowerCase() ? -1 : 1;
                }

                return a.firstName.toLocaleLowerCase() < b.firstName.toLocaleLowerCase() ? -1 : 1;
            });

        list.forEach((contact) => {
            retVal.push(this.toComponentContactModel(contact));
        });

        return retVal;
    }

    @action
    public toComponentContactModel = (contact: ContactModel) => {
        const retVal = new ComponentContactModel();
        retVal.firstName = contact.firstName;
        retVal.lastName = contact.lastName;
        retVal.position = contact.position;
        retVal.email = contact.email;
        retVal.phone = contact.phone;
        retVal.phone2 = contact.phone2;
        retVal.rowVersion = contact.rowVersion;
        retVal.isDeleted = contact.isDeleted;
        retVal.createdDate = contact.createdDate;
        retVal.createdBy = contact.createdBy;
        retVal.id = contact.id;
        retVal.sourceId = contact.clientId;

        return retVal;
    };

    @action
    public fromComponentContactModel = (contact: ComponentContactModel) => {
        const retVal = new ContactModel();
        retVal.firstName = contact.firstName;
        retVal.lastName = contact.lastName;
        retVal.position = contact.position;
        retVal.email = contact.email;
        retVal.phone = contact.phone;
        retVal.phone2 = contact.phone2;
        retVal.rowVersion = contact.rowVersion;
        retVal.isDeleted = contact.isDeleted;
        retVal.createdDate = contact.createdDate;
        retVal.createdBy = contact.createdBy;
        retVal.id = contact.id;
        retVal.clientId = contact.sourceId;

        return retVal;
    };

    @computed get getClientContactLength(): number {
        const temp = this.getClientContacts;
        return temp.length;
    }

    @computed get getClientAddress(): AddressModel {
        return this.model.client.getAddress;
    }

    @computed
    public get getAddressString(): string {
        return formatAddress(this.model.client.getAddress, true);
    }

    @computed
    public get getPostcode(): string {
        return this.model.client.postcode;
    }

    @action
    public setClientName = (value: string) => {
        this.model.client.clientName = value;
    };

    @action
    public setClientAddress = (address: AddressModel): void => {
        this.model.client.setAddress(address);
    };

    constructor() {
        //Pass in a new instance of your model
        //By passing in true as the second parameter, we make this model undoable which means we can use save and reset options on the model
        //If you make a change to the model you need to persist it with a saveModel() call
        //If you make changes to your model you can revert it back by calling resetModel()
        super(new ClientAndContactsModel(), false);
        //EN - Haven't figured out how to make this call work from the base model yet
        //This is only needed if you make use of the validation decorators
        this.setDecorators(ClientAndContactsModel);
        this.history = getHistory();
    }

    public additionalChecks = (): boolean => {
        let retVal: boolean = true;

        return retVal;
    };

    //isValid will check all fields to make sure they are in a valid state.
    @action
    public doSubmit = async (e?: any, redirect: boolean = true) => {
        e?.preventDefault();
        if (this.isModelValid() && this.additionalChecks()) {
            //Do stuff here
            const apiResult = await this.saveClientAsync();
            if (apiResult.wasSuccessful) {
                if (redirect) {
                    this.history.push(Client.Main.Client.Root);
                }
                return apiResult;
            }
        } else {
            this.errorMessage = "Please fix the highighted errors";
            this.IsLoading = false;
        }
        return undefined;
    };

    @action
    public async saveClientAsync(): Promise<ApiResult<ClientAndContactsDTO>> {
        const request: ClientAndContactsDTO = {} as any;

        this.model.toDto(request);

        const apiResult = await this.Post<ClientAndContactsDTO>(Server.Api.Client.upsertClient, request);

        if (apiResult.wasSuccessful) {
            runInAction(() => {
                this.setClientAndContacts(apiResult.payload);
                console.log(StoresInstance.domain.ClientStore);
                StoresInstance.domain.ClientStore.addClient(this.model.client);

                if (apiResult.payload.contacts !== null && apiResult.payload.contacts !== undefined && apiResult.payload.contacts.length > 0) {
                    //RC075

                    let allContacts: ContactModel[] = StoresInstance.domain.ClientStore.getAllContacts();
                    let contacts = apiResult.payload.contacts;
                    //let found: boolean = false;

                    contacts.forEach((item, index) => {
                        let domainModel = allContacts.find((dm) => dm.id === item.id);

                        if (domainModel === null || domainModel === undefined) {
                            domainModel = new ContactModel();

                            domainModel.fromDto(item);
                            allContacts.push(domainModel);
                            //found = true;
                        }
                    });

                    //if (found === true) {
                    StoresInstance.domain.ClientStore.setClientContacts(allContacts);
                    //}
                }
            });
        } else {
            this.errorMessage = "Failed to save the client. The client might have changed since you opened it.  Reload and try again.";
        }

        return apiResult;
    }

    //This must be present in your viewmodel. Just return true if you dont need to validate anything.
    //keyof BlankModel & string lets you add type checking to the fieldName
    //I am using the validator package to make checking easier https://www.npmjs.com/package/validator
    public isFieldValid(fieldName: keyof FieldType<ClientAndContactsModel>, value: any): boolean {
        let { isValid, errorMessage } = this.validateDecorators(fieldName);

        if ((fieldName as any) === "client.clientName") {
            if (validator.isEmpty(value)) {
                errorMessage = "Client Name is required";
                isValid = false;
            } else if (value.toString().length > 128) {
                errorMessage = "Client Name needs to be less than 128 characters";
                isValid = false;
            }
        }

        if ((fieldName as any) === "client.addressLine1") {
            if (validator.isEmpty(value)) {
                errorMessage = "Address line 1 is required";
                isValid = false;
            } else if (value.toString().length > 128) {
                errorMessage = "Address line 1 needs to be less than 128 characters";
                isValid = false;
            }
        }

        if ((fieldName as any) === "client.addressLine2") {
            if (value.toString().length > 128) {
                errorMessage = "Address line 2 needs to be less than 128 characters";
                isValid = false;
            }
        }

        if ((fieldName as any) === "client.city") {
            if (validator.isEmpty(value)) {
                errorMessage = "City is required";
                isValid = false;
            } else if (value.toString().length > 128) {
                errorMessage = "City needs to be less than 128 characters";
                isValid = false;
            }
        }

        if ((fieldName as any) === "client.county") {
            if (value.toString().length > 128) {
                errorMessage = "County needs to be less than 128 characters";
                isValid = false;
            }
        }

        if ((fieldName as any) === "client.postcode") {
            if (validator.isEmpty(value)) {
                errorMessage = "Postcode is required";
                isValid = false;
            } else if (!validator.isPostalCode(value, "GB")) {
                errorMessage = "Postcode is not valid";
                isValid = false;
            }
        }

        //You need to this two properties after validation
        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    @action
    public setClientAndContacts(model: ClientAndContactsDTO) {
        this.model.fromDto(model);
    }

    @action
    public async loadClientAsync(id: string): Promise<ApiResult<ClientAndContactsDTO>> {
        const request: GenericIdRequest = {
            id: id,
        };
        const apiResult = await this.Post<ClientAndContactsDTO>(Server.Api.Client.getClient, request);

        if (apiResult.wasSuccessful) {
            runInAction(() => {
                this.setClientAndContacts(apiResult.payload);
            });
        }
        return apiResult;
    }

    @action
    addContact = (model: ComponentContactModel) => {
        let newContact = this.fromComponentContactModel(model);
        let index = this.model.contacts.findIndex((contact) => contact.email === newContact.email);
        if (index === -1) {
            let temp: ContactModel = new ContactModel();
            temp.fromDto(newContact);
            this.model.contacts.push(temp);
        }
    };

    @action
    updateContact = (model: ComponentContactModel) => {
        let newContact = this.fromComponentContactModel(model);
        let temp: ContactModel = new ContactModel();
        temp.fromDto(newContact);
        let index = this.model.contacts.findIndex((contact) => contact.id === model.id);
        if (index >= 0) {
            this.model.contacts[index] = temp;
        }
    };

    @action
    setAddress = (addr: AddressModel) => {
        this.model.client.setAddress(addr);
    };
}
