import { Centered } from "Core/Components";
import styled from "styled-components";
import { Box } from "@material-ui/core";
import { TabCommonBox } from "../TabCommonStyle.style";

import OverViewButtonImage from "Custom/Content/overviewbutton.svg";

export const OverviewBox: any = styled(TabCommonBox)`
    padding: 5px 15px;
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    max-width: 100%;

    .parent {
        margin-top: 30px;
        display: grid;
        grid-column-gap: 0px;
        grid-row-gap: 30px;

        @media screen and (min-width: 1325px) {
            grid-template-columns: repeat(4, 1fr);
            grid-template-rows: repeat(2, 1fr);
        }

        @media screen and (max-width: 1325px) {
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: repeat(3, 1fr);
        }

        @media screen and (max-width: 1026px) {
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(4, 1fr);
        }

        @media screen and (max-width: 715px) {
            grid-template-columns: repeat(1, 1fr);
            grid-template-rows: repeat(8, 1fr);
        }
    }

    /* 
    background-color: orange;*/
`;

/* export const TabOverviewRow: any = styled.div`
    display: flex;
    flex: 1 0 auto;
    width: 100%;
    max-width: 100%;
    flex-direction: row;
    justify-content: space-around;
    padding: 0 20px;
    margin-top: 30px;
`; */

export const TabOverviewCell: any = styled.div`
    display: flex;
    flex: 1 0 auto;
    flex-direction: row;
    margin: 0 auto;

    /* background-color: green; */
`;

export const TabOverviewCellBlock: any = styled.div`
    display: flex;
    flex: 1 0 auto;
    width: 100%;
    flex-direction: column;
    max-width: 304px;
    min-width: 304px;

    background: #3a3c41 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #000000c2;
    border-radius: 4px;
    opacity: 1;

    background-color: #3a3c41;

    .overviewTitleBox {
        max-height: 46px !important;
    }
`;
export const TabCellHeader: any = styled.div`
    background: #394f7e 0% 0% no-repeat padding-box;
    box-shadow: inset 0px 1px 1px #ffffff40;
    border-radius: 4px 4px 0px 0px;
    opacity: 1;
    width: 100%;
    height: 46px;
    max-height: 46px;
    padding: 13px 15px;

    .celltitle {
        display: flex;
        flex-direction: row;
        flex: 1 0 0;
        text-align: left;
        font: normal normal normal 14px Mont;
        letter-spacing: 0px;
        color: #ffffff;
        opacity: 1;

        .period {
            margin-top: 3px;
            padding-left: 10px;
            font: normal normal normal 10px Mont;
        }

        .celltitle-name {
            max-width: 155px;
            overflow: hidden;
            text-overflow: ellipsis;

            /*             background-color: green; */
            width: 155px;
        }
    }
`;

export const OverviewGraphBox: any = styled(Box)`
    display: flex;
    flex: 1 0 auto;
    border: "1px solid #F7F7F7";
    flex-direction: column;
    /* background-color: green; */
`;

export const OverViewButton: any = styled(Box)`
    width: 136px;
    height: 22px;

    background: url(${OverViewButtonImage}) no-repeat;
    background-size: cover;

    opacity: 1;
    margin-left: auto;
    margin-right: auto;
    cursor: default;
    :hover {
        cursor: pointer;
    }
    position: relative;

    .buttontext {
        margin-top: 5px;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        font: normal normal normal 10px/14px Mont;
        letter-spacing: 0px;
        color: #ffffff;
        opacity: 1;
    }
`;

export const RecentAlertWrapper: any = styled.div`
    display: flex;
    width: 100%;
    flex: 1 0 0;
    flex-direction: column;
    padding: 15px;
    max-height: 280px;
    overflow-y: auto;

    .noalerts {
        position: relative;
        margin-left: auto;
        margin-right: auto;
        text-align: middle;
    }
`;

export const RecentAlertRow: any = styled.div`
    display: flex;
    width: 100%;
    flex: 1 0 0;
    flex-direction: row;
    justify-content: space-between;
    height: 30px;
    box-shadow: 0px 3px 3px #00000073;
    border-radius: 100px;
    opacity: 1;

    padding: 0 15px;
    margin-bottom: 10px;

    text-align: left;
    font: normal normal normal 12px/24px Mont;
    letter-spacing: 0px;
    position: relative;
`;

export const SiphonicWrapper: any = styled.div`
    display: flex;
    width: 100%;
    flex: 1 0 0;
    flex-direction: column;
    padding: 15px;
    max-height: 280px;

    .nosiphonics {
        position: relative;
        margin-left: auto;
        margin-right: auto;
        text-align: middle;
    }
`;

export const StatusRow: any = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1 0 auto;
    padding: 7px 15px;

    &.first {
        padding-top: 30px !important;
    }

    &.last {
        padding-bottom: 15px !important;
    }

    .status-row-title {
        margin-top: 4px;
        width: 110px;
    }

    .status-row-sw {
        flex: 1 0 auto;
        flex-direction: row;
        max-width: 100%;
        margin: 0 auto;

        /* ackground-color: yellow; */
    }
`;
