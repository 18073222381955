import styled from "styled-components";
import { Box } from "@material-ui/core";
import { TabCommonBox } from "./TabCommonStyle.style";

export const DeviceAverageBox: any = styled(TabCommonBox)`
    /*     padding: 5px 15px;
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    width: 100%;
    background-color: #565758; */
`;

export const AverageRow: any = styled(Box)`
    display: block;
    padding: 0 15px;
    padding-top: 15px;
    width: 100%;
    min-width: 320px;
`;

export const AverageFlexRow: any = styled(Box)`
    display: flex;
    flex-direction: row;
    flex: 1 1 auto;
    padding: 15px;
    width: 100%;
    min-width: 320px;
    > div {
        padding-right: 15px;
    }

    .average-peak {
        display: flex;
        align-items: center;
    }

    .select-title {
        padding-top: 3px;
        padding-right: 15px;
    }

    .averages {
        display: flex;
        flex-direction: row;
        flex: 1 1 auto;

        > div {
            display: flex;
            flex-direction: row;
            padding: 7px 15px 7px 0;
            font-size: "1.5em";
        }

        .normal {
            font-size: 14px;
            padding-left: 10px;
            padding-top: 1px;
        }
    }
`;
