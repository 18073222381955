// Libs

import * as MobX from "mobx";

import { BaseStore } from "Custom/Stores/Domain/BaseStore";
import Moment from "moment";
import { Stores } from "Custom/Stores";
import { GenericIncludeDeleted, InitialState } from "Custom/Models";
import { ApiResult } from "Core/Models";
import { Server } from "Custom/Globals/AppUrls";
import { ProjectListItemModel, ProjectListItemModelDTO } from "Custom/Models/Domain/Projects/ProjectListItemModel";
import { ProjectsAndRelatedForList } from "Custom/Models/Domain/Projects/ProjectsAndRelatedForList";
// App

interface Filters {
    date: any;
    orderAsc: boolean;
    searchFilter: string;
}

export class ProjectStore extends BaseStore {
    private items = MobX.observable<ProjectListItemModel>([]);
    @MobX.observable private isLoadingData: boolean = false;
    @MobX.observable private filters: Filters = {
        date: Moment(),
        orderAsc: true,
        searchFilter: "",
    };

    @MobX.observable private lastUpdated: any = Moment();

    public constructor() {
        super();
    }

    public init(stores: Stores, initialState: InitialState) {}

    @MobX.computed get getCount(): number {
        return this.items.length;
    }

    /*     @MobX.action
    public setProjects(projects: ProjectDTO[]) {
        this.items.clear();
        projects.forEach((item, index) => {
            let domainModel = this.items.find((dm) => dm.id === item.id);

            if (!domainModel) {
                domainModel = new Project();

                domainModel.fromDto(item);
                this.items.push(domainModel);
            }
        });
        this.isLoadingData = false;
        this.lastUpdated = Moment();
    } */

    @MobX.action
    public setProjects(projects: ProjectListItemModelDTO[]) {
        this.items.clear();
        projects.forEach((item, index) => {
            let domainModel = this.items.find((dm) => dm.id === item.id);

            if (!domainModel) {
                domainModel = new ProjectListItemModel();

                domainModel.fromDto(item);
                this.items.push(domainModel);
            }
        });
        this.isLoadingData = false;
        this.lastUpdated = Moment();
    }

    @MobX.action
    public clearProjects() {
        this.items.clear();
    }

    @MobX.action
    public setIsLoadingData(loading: boolean) {
        this.isLoadingData = loading;
    }

    @MobX.computed
    public get getAll() {
        return this.items;
    }

    @MobX.computed
    public get getIsLoadingData() {
        return this.isLoadingData;
    }

    @MobX.computed
    public get getFilters() {
        return this.filters;
    }

    @MobX.computed
    public get getFiltered() {
        let slicedItems: ProjectListItemModel[] = this.items.slice();
        if (this.filters.searchFilter) {
            slicedItems = slicedItems.filter(
                (or) => or.name.toLowerCase().includes(this.filters.searchFilter) || or.number.toLowerCase().includes(this.filters.searchFilter),
                /* ||
                    or..toLowerCase().includes(this.filters.searchFilter), */
            );
        }
        return slicedItems;
    }

    @MobX.action
    public async loadProjectListAsync(): Promise<ApiResult<ProjectsAndRelatedForList>> {
        const includeGenericDeleted: GenericIncludeDeleted = {
            includeDeleted: true,
        };
        const apiResult: ApiResult<ProjectsAndRelatedForList> = await this.Post<ProjectsAndRelatedForList>(Server.Api.Project.getProjectsAndRelatedForList, includeGenericDeleted);

        if (apiResult.wasSuccessful) {
            MobX.runInAction(() => {
                this.setProjects(apiResult.payload.projects);
            });
        }

        return apiResult;
    }
}
