import styled from "styled-components";
import { withProps } from "../../../Core/Utils/React/WithProps";
import { Button } from "@material-ui/core";

const _AcceptBtn = withProps(Button)({
    color: "primary",
    variant: "contained",
});

export const AcceptBtn = styled(_AcceptBtn)`
    max-width: 179px;
    height: 34px;
    border-radius: 100px;
    box-shadow: 0px 3px 6px #00000059;
`;

export const AcceptExtBtn = styled(_AcceptBtn)`
    max-width: 250px;
    height: 34px;
    border-radius: 100px;
    box-shadow: 0px 3px 6px #00000059;
`;
