import { Box, Fade, Paper, Typography, useTheme } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useObserver } from "mobx-react-lite";
import ReactTooltip from "react-tooltip";

import { InstallationsViewModel } from "./InstallationsViewModel";
import { Loader } from "Core/Components";
import { MailCell, RoofcareTable, RoofcareTableBox, RoofcareTableWrapper } from "Custom/Components/Table/RoofcareTable";
import { ResetSearchIcon } from "Custom/Components/Table/ResetSearchIcon";
import { useRouter } from "Core/Utils";
import { DeleteModal } from "Custom/Components/Modal/DeleteModal";
import { Container } from "Custom/StylesAppSpecific/AppStyling";
import EmailLogo from "Custom/Content/mail.svg";
import { AppUrls } from "Custom/Globals";
import { UnitListToolTip, UnitStatusBox } from "Custom/Components";
import { UnitListItemModel } from "./UnitListItemModel";
import { MultiSelectView } from "Custom/Components/Select/MultipleSelect";
import { UnitFilterSection } from "./Installations.styles";
import * as RouterUtil from "Custom/Utils/routing";
import { ClickableImgCell } from "Custom/Components/Table/ClickableImgCell";
import EditLogo from "Custom/Content/editIcon.svg";
import PropertyLogo from "Custom/Content/home-225.svg";
import { Stores, StoresContext } from "Custom/Stores";
import { ProjectToolbarRowV3 } from "Custom/StylesAppSpecific/Controls/BoxAddWrapper";

import * as Defaults from "Custom/StylesAppSpecific/TableOptions";
import { getStandingWaterBackgroundColour, getStandingWaterTextColour, StandingWaterBox } from "Custom/Components/StandingWater/StandingWater";
import styled from "styled-components";

export const InstallationTabListContainer = styled.div`
    .device-removeProperty {
        display: flex;
        flex-grow: 1;
        padding: 10px 0;
        justify-content: center;

        //    background-color: orange !important;

        .MuiBox-root,
        img {
            height: 24px;
            max-height: 24px !important;
        }
    }
`;
interface IInstallationListTabViewProps {
    setEditInstallation: (id: string) => void;
}

export const InstallationListTabView: React.FunctionComponent<IInstallationListTabViewProps> = ({ setEditInstallation }) => {
    const store = React.useContext<Stores>(StoresContext);
    const [viewModel] = useState(() => InstallationsViewModel.Instance);
    const theme = useTheme();
    const router = useRouter();
    const [deleteOpen, setDeleteOpen] = React.useState(false);
    const [selectedDevice, selectDevice] = React.useState("");

    const [tabKey, setTabKey] = React.useState(0);

    const { history } = useRouter();

    //useEffect below only gets run on initial render
    useEffect(() => {
        /*         const apiResult = viewModel.loadForUnitViewAsync();

        apiResult.then((result) => {}); */

        return () => {
            // Don't do this on the tab switch
            // The parent funtion will do this when the page unloads
            // viewModel.cleanUp();
        };
    }, []);

    const handleEditClick = (id: string) => {
        setEditInstallation(id);
    };

    const handleDeleteOpen = () => {
        setDeleteOpen(true);
    };

    const handleDeleteClose = () => {
        setDeleteOpen(false);
        selectDevice("");
    };

    const handleDeleteFromProperty = () => {
        const deletedItem: string = selectedDevice;
        viewModel.removeDeviceFromProperty(deletedItem).then(() => {
            setDeleteOpen(false);
            selectDevice("");
        });
    };

    const setSearchString = (value: string) => {
        viewModel.setSearchString(value);
        viewModel.setPage(0);
    };

    const setRowsPerPage = (rows: number) => {
        viewModel.setRowsPerPage(rows);
    };

    const setPage = (page: number) => {
        viewModel.setPage(page);
    };

    const setOrderChange = (columnId: number, direction: any) => {
        viewModel.setOrderChange(columnId, direction);
    };

    const handleRowClick = (rowData: any) => {
        const { path } = router.match;
        let url: string = AppUrls.Client.Main.Device.Installation;

        switch (path) {
            case AppUrls.Client.ClientView.Device.DeviceRoot: {
                url = AppUrls.Client.ClientView.Device.Installation;
                break;
            }
            case AppUrls.Client.ContractorView.Device.DeviceRoot: {
                url = AppUrls.Client.ContractorView.Device.Installation;
                break;
            }
            case AppUrls.Client.Main.Device.Root:
            default: {
                url = AppUrls.Client.Main.Device.Installation;
                break;
            }
        }

        history.push(url.replace(":installId", rowData.id));
    };

    const isInError = (fieldName: string): boolean => {
        /*         let isValid = props.filtersViewModel.getValid(fieldName);
        return !isValid; */

        return false;
    };

    const getError = (fieldName: string): string => {
        /*         let retVal: string = props.filtersViewModel.getError(fieldName);
        return retVal; */
        return "";
    };

    const handleStatusChange = (values: string[] | number[] | null) => {
        const valueList: string[] = values as string[];
        const intValues: string[] = [];

        for (let i: number = 0; i < valueList.length; i++) {
            const item: string = valueList[i];
            intValues.push(item);
        }

        viewModel.setUnitStatusFilter(intValues);
    };

    const handleStandingWaterChange = (values: string[] | number[] | null) => {
        const valueList: string[] = values as string[];
        const intValues: string[] = [];

        for (let i: number = 0; i < valueList.length; i++) {
            const item: string = valueList[i];
            intValues.push(item);
        }

        viewModel.setStandingWaterFilter(intValues);
    };

    const handleDisplayChange = (values: number[] | string[] | null) => {
        const valueList: string[] = values as string[];
        const intValues: number[] = [];

        for (let i: number = 0; i < valueList.length; i++) {
            const item: number = parseInt(valueList[i], 10);
            intValues.push(item);
        }

        viewModel.setUnitDisplayFilter(intValues);
    };

    /**
     * Get the sort direction stored in the viewmodel for the provided column index.
     * Only use this on columns that have "sorting" set to true.
     */
    const getSortDirection = (columnIndex: number) => {
        return viewModel.sortColumnId === columnIndex ? viewModel.sortDirection : "";
    };

    const formatEdit = (id: string): any => {
        return <ClickableImgCell className="smaller" logo={EditLogo} text={"Edit"} onClick={handleEditClick} id={id} />;
    };

    const formatRemove = (id: string): any => {
        return <ClickableImgCell className="house smaller forceheight" logo={PropertyLogo} text={"Delete from property"} onClick={handleDeletePropertyClick} id={id} />;
    };

    const handleDeletePropertyClick = (id: string) => {
        setDeleteOpen(true);
        selectDevice(id);
    };

    const canShowAddInstallation: boolean =
        (RouterUtil.requireContractorAdminorAdminLogin(store) || RouterUtil.requireRegionalSalesManagerLogin(store)) && viewModel.model.okToAddInstallation === true;

    const commonColumns: any[] = [
        {
            title: "Status",
            field: "status",
            defaultSort: getSortDirection(0),
            sorting: true,
            filtering: true,
            render: (rowData: any) => {
                if (rowData !== null && rowData !== undefined && rowData.status !== undefined) {
                    return (
                        <UnitStatusBox className={"device-status"} backgroundColor={rowData.status.statusColour} textColour={rowData.status.statusTextColour}>
                            <div className="status-name">{rowData.status.name}</div>
                        </UnitStatusBox>
                    );
                }
            },
        },
        {
            title: "Standing Water",
            field: "standingWaterStatus",
            defaultSort: getSortDirection(1),
            sorting: true,
            filtering: true,
            render: (rowData: any) => {
                if (rowData !== null && rowData !== undefined && rowData.status !== undefined) {
                    return (
                        <StandingWaterBox
                            className={"standing-water overview"}
                            backgroundColor={getStandingWaterBackgroundColour(rowData.standingWaterEnum)}
                            textColour={getStandingWaterTextColour(rowData.standingWaterEnum)}
                        >
                            <div className="status-name">{rowData.standingWaterPercent + "% over " + rowData.standingWaterDays + " days"}</div>
                        </StandingWaterBox>
                    );
                }
            },
        },
        { title: "Unit name", field: "name", sorting: true, defaultSort: getSortDirection(2) },
        {
            title: "End user",
            field: "clientName",
            sorting: true,
            render: (rowData: UnitListItemModel) => {
                if (rowData !== null && rowData !== undefined) {
                    if (rowData.clientName !== null && rowData.clientName !== undefined && rowData.clientName.length > 0) {
                        return (
                            <MailCell
                                onClick={(e: any) => {
                                    ReactTooltip.hide();
                                    e.stopPropagation();
                                    //e.preventDefault();
                                }}
                            >
                                <img
                                    data-tip
                                    data-for={"clickclient-" + rowData.id}
                                    data-event="click"
                                    data-offset="{'top': 0, 'left': 2}"
                                    src={EmailLogo}
                                    alt="See end user details"
                                    onClick={(event) => {
                                        ReactTooltip.hide();
                                        ReactTooltip.rebuild();
                                        event.stopPropagation();
                                        event.preventDefault();
                                    }}
                                />
                                <div className="text">{rowData.clientName}</div>
                                <UnitListToolTip
                                    id={rowData.id}
                                    identifier="clickclient"
                                    companyName={rowData.clientName}
                                    contactName={rowData.clientContactName}
                                    contactEmail={rowData.clientContactEmail}
                                    contactPhone={rowData.clientContactPhone}
                                ></UnitListToolTip>
                            </MailCell>
                        );
                    } else {
                        return "";
                    }
                }
            },
            defaultSort: getSortDirection(3),
        },
        { title: "Property", field: "addressLine1", sorting: true, defaultSort: getSortDirection(4) },
        { title: "Town/city", field: "city", sorting: true, defaultSort: getSortDirection(5) },
        { title: "Postcode", field: "postcode", sorting: true, defaultSort: getSortDirection(6) },
        { title: "Contractor", field: "contractorName", sorting: true, defaultSort: getSortDirection(7) },
        {
            title: "Contractor contact",
            field: "contractorContactName",
            sorting: true,
            defaultSort: getSortDirection(8),
            render: (rowData: UnitListItemModel) => {
                if (rowData !== null && rowData !== undefined) {
                    if (rowData.contractorContactName !== null && rowData.contractorContactName !== undefined && rowData.contractorContactName.length > 0) {
                        return (
                            <MailCell
                                onClick={(event: any) => {
                                    ReactTooltip.rebuild();
                                    ReactTooltip.hide();
                                    event.stopPropagation();
                                    //event.preventDefault();
                                }}
                            >
                                <img
                                    data-tip
                                    data-for={"clickcontractor-" + rowData.id}
                                    data-event="click focus"
                                    data-offset="{'top': 0, 'left': 2}"
                                    src={EmailLogo}
                                    alt="See contact details"
                                    onClick={(event) => {
                                        ReactTooltip.hide();
                                        event.stopPropagation();
                                        event.preventDefault();
                                    }}
                                />
                                <div className="text">{rowData.contractorContactName}</div>
                                <UnitListToolTip
                                    id={rowData.id}
                                    identifier="clickcontractor"
                                    companyName={rowData.contractorName}
                                    contactName={rowData.contractorContactName}
                                    contactEmail={rowData.contractorContactEmail}
                                    contactPhone={rowData.contractorContactPhone}
                                />
                            </MailCell>
                        );
                    } else {
                        return "";
                    }
                }
            },
        },
    ];

    const abcColumns: any[] = [
        {
            maxWidth: "65px",
            title: "Alert",
            field: "hasAlertAction",
            sorting: true,
            defaultSort: getSortDirection(8),
            render: (rowData: UnitListItemModel) => {
                {
                    return rowData.hasAlertAction ? (
                        <div className="central-cell">
                            <div className="tick">
                                <span>&#10003;</span>
                            </div>
                            {rowData.hasValidEmailAddesses && <div className="alerticon tick fa fa-envelope"></div>}
                            {rowData.hasValidEmailAddesses == false && <div className="alerticon cross fa fa-envelope"></div>}
                            {rowData.hasValidMobileNumbers && <div className="alerticon tick fa fa-mobile"></div>}
                            {rowData.hasValidMobileNumbers == false && <div className="alerticon cross fa fa-mobile "></div>}
                        </div>
                    ) : (
                        <div className="central-cell">
                            <div className="cross">
                                <span>&#88;</span>
                            </div>
                            {rowData.hasValidEmailAddesses && <div className="alerticon tick fa fa-envelope"></div>}
                            {rowData.hasValidEmailAddesses == false && <div className="alerticon cross fa fa-envelope"></div>}
                            {rowData.hasValidMobileNumbers && <div className="alerticon tick fa fa-mobile"></div>}
                            {rowData.hasValidMobileNumbers == false && <div className="alerticon cross fa fa-mobile"></div>}
                        </div>
                    );
                }
            },
        },
        {
            maxWidth: "90px",
            title: "Behaviour",
            field: "hasUnitBehaviour",
            sorting: true,
            defaultSort: getSortDirection(9),
            render: (rowData: UnitListItemModel) => {
                {
                    return rowData.hasUnitBehaviour ? (
                        <div className="central-cell">
                            <div className="tick">
                                <span>&#10003;</span>
                            </div>
                        </div>
                    ) : (
                        <div className="central-cell">
                            <div className="cross">
                                <span>&#88;</span>
                            </div>
                        </div>
                    );
                }
            },
        },
        {
            maxWidth: "90px",
            title: "Condition",
            field: "hasConditionSet",
            sorting: true,
            defaultSort: getSortDirection(10),
            render: (rowData: UnitListItemModel) => {
                {
                    return rowData.hasConditionSet ? (
                        <div className="central-cell">
                            <div className="tick">
                                <span>&#10003;</span>
                            </div>
                        </div>
                    ) : (
                        <div className="central-cell">
                            <div className="cross">
                                <span>&#88;</span>
                            </div>
                        </div>
                    );
                }
            },
        },
    ];

    const statusColums: any[] = [
        {
            maxWidth: "105px",
            width: "auto",
            title: "",
            field: "status",
            sorting: false,
            filtering: false,
            render: (rowData: any) => {
                {
                    return <>{canShowAddInstallation && <div className="device-editinstallation">{formatEdit(rowData.id!.toString())}</div>}</>;
                }
            },
        },
    ];

    const allowedColumns: any[] = commonColumns;

    if (canShowAddInstallation === true) {
        statusColums.push({
            width: "auto",
            title: "",
            field: "status",
            sorting: false,
            filtering: false,
            render: (rowData: any) => {
                {
                    return <>{canShowAddInstallation && <div className="device-removeProperty ">{formatRemove(rowData.id!.toString())}</div>}</>;
                }
            },
        });

        allowedColumns.push(...abcColumns);
        allowedColumns.push(...statusColums);
    }

    const renderPage = () => {
        if (viewModel.IsLoading) return <Loader />;
        const tableOptions = Defaults.GetRoofcareTableOptions() as any;

        return (
            <Fade in timeout={1000}>
                <InstallationTabListContainer>
                    {
                        <>
                            <DeleteModal
                                open={deleteOpen}
                                onClose={handleDeleteClose}
                                onDelete={handleDeleteFromProperty}
                                text="Are you sure you want to remove this device from it's property? This will remove all the gutter information"
                                title="Remove Device From Property?"
                            />
                            <ProjectToolbarRowV3>
                                <UnitFilterSection>
                                    <Typography variant="h1" className="select-title" style={{ color: theme.palette.secondary.contrastText }}>
                                        Unit Status:
                                    </Typography>
                                    <Box className="multiselectbox">
                                        <MultiSelectView
                                            fieldName=""
                                            values={viewModel.getStatusFilter()}
                                            display="Status"
                                            useNumber={true}
                                            getOptions={viewModel.getUnitStatusKVPair}
                                            handleChange={handleStatusChange}
                                            getError={getError}
                                            getValid={isInError}
                                        />
                                    </Box>
                                </UnitFilterSection>
                                <UnitFilterSection>
                                    <Typography variant="h1" className="select-title" style={{ color: theme.palette.secondary.contrastText }}>
                                        Standing Water:
                                    </Typography>
                                    <Box className="multiselectbox">
                                        <MultiSelectView
                                            fieldName=""
                                            values={viewModel.getStandingWaterFilter()}
                                            display="Standing Water"
                                            useNumber={true}
                                            getOptions={viewModel.getStandingWaterKVPair}
                                            handleChange={handleStandingWaterChange}
                                            getError={getError}
                                            getValid={isInError}
                                        />
                                    </Box>
                                </UnitFilterSection>

                                <UnitFilterSection>
                                    <Typography variant="h1" className="select-title" style={{ color: theme.palette.secondary.contrastText }}>
                                        Unit Display Rules:
                                    </Typography>
                                    <Box className="multiselectbox">
                                        <MultiSelectView
                                            fieldName=""
                                            values={viewModel.getDisplayFilter()}
                                            display="Display"
                                            useNumber={true}
                                            getOptions={viewModel.getUnitDisplayKVPair}
                                            handleChange={handleDisplayChange}
                                            getError={getError}
                                            getValid={isInError}
                                            showTopAllNone={false}
                                        />
                                    </Box>
                                </UnitFilterSection>
                            </ProjectToolbarRowV3>
                            <RoofcareTableWrapper></RoofcareTableWrapper>
                            <RoofcareTableBox style={{ padding: 0 }}>
                                <RoofcareTable
                                    columns={commonColumns}
                                    options={{
                                        thirdSortClick: false,
                                        cellStyle: {
                                            fontSize: 10,
                                        },
                                        initialPage: viewModel.currentPage,
                                        pageSize: viewModel.pageSize,
                                        defaultGroupOrder: viewModel.sortColumnId,
                                        defaultGroupDirection: viewModel.sortDirection,
                                        pageSizeOptions: [10, 20, 50, 100],
                                        search: false,
                                        toolbar: false,
                                        emptyRowsWhenPaging: false,
                                        headerStyle: {
                                            ...tableOptions.headerStyle,
                                        },
                                        rowStyle: {
                                            ...tableOptions.rowStyle,
                                        },
                                        filterCellStyle: {
                                            ...tableOptions.filterCellStyle,
                                        },
                                        searchFieldAlignment: "left",
                                        searchFieldStyle: {
                                            border: "1px solid #333333",
                                            borderRadius: "5px",
                                            width: "400px",
                                        },
                                    }}
                                    icons={{
                                        ResetSearch: () => <ResetSearchIcon />,
                                    }}
                                    data={viewModel.getDevices}
                                    title="Units"
                                    components={{ Container: (props: any) => <Paper {...props} elevation={0} /> }}
                                    onSearchChange={(value: string) => {
                                        setSearchString(value);
                                    }}
                                    onOrderChange={(orderedColumnId: any, orderDirection: any) => {
                                        setOrderChange(orderedColumnId, orderDirection);
                                    }}
                                    onChangeRowsPerPage={(rows: number) => {
                                        setRowsPerPage(rows);
                                    }}
                                    onChangePage={(page: number) => {
                                        setPage(page);
                                    }}
                                    onRowClick={(_: any, rowData: any) => handleRowClick(rowData)}
                                />
                            </RoofcareTableBox>
                        </>
                    }
                </InstallationTabListContainer>
            </Fade>
        );
    };

    return useObserver(() => renderPage());
};
