import { action, computed, IObservableArray, observable, runInAction } from "mobx";

import { ViewModelBase } from "Core/ViewModels/ViewModelBase";
import { FieldType } from "Core/Utils/Utils";
import { InstallationImage, InstallationImageDto } from "Custom/Models/Domain";

export class GutterSensLatestImageViewModel extends ViewModelBase<any> {
    private static _instance: GutterSensLatestImageViewModel;

    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    // #region Constructors and Disposers

    @observable images: IObservableArray<InstallationImage> = observable([]);

    // 09/09/2022 - NK meeting, wants the latest images to show anything both the view from the sensor
    // so change it to image view 2
    @observable selectedView: number = 2;

    constructor() {
        super({});
    }

    @action public resetViewModel = () => {
        this.setImages([]);
        this.selectedView = 2;
    };

    @action public setImages = (images: InstallationImage[]) => {
        this.images.replace(images);
    };

    @action public setView = (view: number) => {
        this.selectedView = view;
    };

    @computed public get getImages(): InstallationImage[] {
        return this.images.slice(0);
    }

    @computed public get getSelectedView(): InstallationImage | undefined {
        return this.images.find((a) => a.view === this.selectedView);
    }

    @computed public get getUnSelectedViews(): InstallationImage[] {
        return this.images.filter((a) => a.view !== this.selectedView).slice(0);
    }

    // #region Constructors and Disposers

    // #region Properties

    @observable public IsDownloading: boolean = false;

    // #endregion Properties

    // #region Actions

    // #endregion Actions

    // #region Boilerplate

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    public isFieldValid(fieldName: keyof FieldType<any>): boolean {
        return true;
    }

    // #endregion Boilerplate
}
