import { validate } from "class-validator";
import { InstallationCommandHistoryDTO } from "Custom/Models/Domain/Installations/InstallationCommandHistory";
import * as MobX from "mobx";
import { runInAction, action, computed, observable } from "mobx";
import moment from "moment";

import { ViewModelBase } from "Core/ViewModels/ViewModelBase";
import { FieldType, sortByStringDates } from "Core/Utils/Utils";
import { Server } from "Custom/Globals/AppUrls";
import {
    InstallationImage,
    InstallationImageResult,
    GetValuesByDateRequest,
    InstallationImageDto,
    DaysOption,
    InstallationCommand,
    InstallationCommandHistoryResultDTO,
} from "Custom/Models/Domain/Installations";
import { KeyValuePairGeneric } from "Core/Models/KeyValuePair";
import { CollapsiblePanelViewModel } from "Custom/Components/Panels/CollapsiblePanelviewModel";
import { CollapsibleInstallationImagePanelViewModel } from "Custom/Components/Panels/CollapsibleInstallationImagePanelViewModel";
import { ApiResult } from "Core/Models";

//extend viewmodel base and passing your model as the generic type
export class InstallationImageViewModel extends ViewModelBase<InstallationImageResult> {
    constructor() {
        //Pass in a new instance of your model
        //By passing in true as the second parameter, we make this model undoable which means we can use save and reset options on the model
        //If you make a change to the model you need to persist it with a saveModel() call
        //If you make changes to your model you can revert it back by calling resetModel()
        super(new InstallationImageResult(), false);
        //EN - Haven't figured out how to make this call work from the base model yet
        //This is only needed if you make use of the validation decorators
        this.setDecorators(InstallationImageResult);
    }

    public onRequestImageCommand: ((commandHistory: InstallationCommandHistoryDTO[], commands: InstallationCommand[]) => void) | undefined = undefined;

    @observable
    public collapsiblePanelViewModels = observable<CollapsiblePanelViewModel>([]);

    @observable public sortImageDirection = false;

    @computed
    public get hasImages(): boolean {
        return this.collapsiblePanelViewModels.length > 0;
    }

    @MobX.observable imagesDateStart: moment.Moment = moment.utc().startOf("day").add(-7, "days");

    @MobX.observable imagesDateEnd: moment.Moment = moment.utc().startOf("day");

    @action
    public setSortImageDirection(newDirection: boolean) {
        this.sortImageDirection = newDirection;
        this.collapsiblePanelViewModels.replace(this.collapsiblePanelViewModels.reverse());
    }

    @computed public get getSortDirection(): boolean {
        return this.sortImageDirection;
    }

    //Singleton instance of class
    private static _instance: InstallationImageViewModel;

    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    @MobX.observable public daysOptions: DaysOption[] = [
        { label: "24 Hours", value: 1 },
        { label: "Week", value: 7 },
        { label: "Month", value: moment().daysInMonth() },
        { label: "Last 12 Months", value: moment().isLeapYear() ? 366 : 365 },
        { label: "Custom", value: -1 },
    ];

    @action
    public reset() {
        this.model.installationImages.length = 0;
    }

    @action
    public setStartDate(newDate: moment.Moment) {
        this.imagesDateStart = newDate;
    }

    @action
    public setEndDate(newDate: moment.Moment) {
        this.imagesDateEnd = newDate;
    }

    @MobX.computed
    public get deviceImages(): KeyValuePairGeneric<string, InstallationImage[]>[] {
        let retVal: KeyValuePairGeneric<string, InstallationImage[]>[] = [];
        if (this.model != undefined && this.model !== null) {
            retVal.push(...this.model.installationImages.slice(0));
        }

        return retVal;
    }

    @MobX.computed
    public get deviceImagesCount(): number {
        let retVal: number = 0;
        if (this.model != undefined && this.model !== null) {
            retVal = this.model.installationImages.slice(0).length;
        }

        return retVal;
    }

    //This must be present in your viewmodel. Just return true if you dont need to validate anything.
    //keyof BlankModel & string lets you add type checking to the fieldName
    //I am using the validator package to make checking easier https://www.npmjs.com/package/validator
    public isFieldValid(fieldName: keyof FieldType<InstallationImageResult>): boolean {
        const { isValid, errorMessage } = this.validateDecorators(fieldName);

        //You need to this two properties after validation
        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    @action
    public requestImage = async (deviceId: number, simId: string): Promise<void> => {
        try {
            if (this.IsLoading === false) {
                const request = {
                    deviceId: deviceId,
                    simId: simId,
                };

                const apiResult = await this.Post<InstallationCommandHistoryResultDTO>(Server.Api.Installation.sendPhotoCommand, request);

                if (apiResult.wasSuccessful) {
                    // Pass the data back

                    if (this.onRequestImageCommand !== undefined) {
                        this.onRequestImageCommand(apiResult.payload.commandHistory, apiResult.payload.commands);
                    }
                } else {
                    // Error. What to do?
                    this.setIsErrored(true);
                    this.history.push("/");
                }
            }
        } catch (exception) {
            // Error. What to do?
            this.setIsErrored(true);
            this.history.push("/");
        } finally {
            this.setIsLoading(false);
        }
    };

    @computed public get areDatesValid(): boolean {
        return this.imagesDateStart.isValid() == true && this.imagesDateEnd.isValid() == true;
    }

    @action
    public load = async (deviceId: number): Promise<ApiResult<InstallationImageDto[] | undefined>> => {
        let apiResult: ApiResult<InstallationImageDto[] | undefined> = {
            wasSuccessful: false,
            errors: [],
            headers: "",
            payload: undefined,
        };

        try {
            if (this.IsLoading === false && this.imagesDateStart.isValid() == true && this.imagesDateEnd.isValid() == true) {
                this.setIsLoading(true);

                if (this.imagesDateStart > this.imagesDateEnd) {
                    // Swap the dates
                    const temp: moment.Moment = this.imagesDateStart;
                    this.imagesDateStart = this.imagesDateEnd;
                    this.imagesDateEnd = temp;
                }

                const request: GetValuesByDateRequest = {
                    id: deviceId,
                    startDate: this.imagesDateStart.toISOString(),
                    endDate: this.imagesDateEnd.toISOString(),
                };

                const apiResult: ApiResult<InstallationImageDto[]> = await this.Post<InstallationImageDto[]>(Server.Api.Installation.getImagesForInstallationBetweenDates, request);

                if (apiResult.wasSuccessful) {
                    runInAction(() => this.model.fromDtoArr(apiResult.payload));

                    const result: KeyValuePairGeneric<string, InstallationImage[]>[] = this.model.installationImages.slice(0);

                    runInAction(() => this.collapsiblePanelViewModels.replace(this.processImages(result)));
                } else {
                    // Error. What to do?
                    this.setIsErrored(true);
                    this.history.push("/");
                }
            }
        } catch (exception) {
            // Error. What to do?
            this.setIsErrored(true);
            this.history.push("/");
        } finally {
            this.setIsLoading(false);
        }

        return apiResult;
    };

    @action private processImages(result: KeyValuePairGeneric<string, InstallationImage[]>[]): CollapsiblePanelViewModel[] {
        // Create our viewmodels.
        const collapsiblePanelViewModels: CollapsiblePanelViewModel[] = [];

        //BF158 Images not shown in view order, but date order
        const getTempList = (images: InstallationImage[]) => {
            let tempList: InstallationImage[] = images.sort((a: InstallationImage, b: InstallationImage) => {
                if (a === undefined && b === undefined) {
                    return 0;
                }

                if (a === undefined) {
                    return -1;
                }

                if (b === undefined) {
                    return 1;
                }

                return a.view < b.view ? -1 : 1;
            });
            return tempList;
        };

        if (this.sortImageDirection === true) {
            result = result.reverse();
        }

        for (let i: number = 0; i < result.length; i++) {
            // For each day.
            const day: KeyValuePairGeneric<string, InstallationImage[]> = result[i];

            // sort images by time within the reverse dates
            // 19/08/2022 - but make it reverse time as well See Unit010 16/08/2022 when there was lots of images.
            let sortedImages: InstallationImage[] = day.value.slice(0).sort((a: InstallationImage, b: InstallationImage) => {
                if (this.sortImageDirection === true) {
                    // asc
                    return sortByStringDates(a.timeStamp, b.timeStamp);
                } else {
                    // desc
                    return sortByStringDates(b.timeStamp, a.timeStamp);
                }
            });

            let sortedList: InstallationImage[] = [];

            if (sortedImages.length < 3) {
                // then we are missing images so just display what we have
                // Sort in view order
                let tempList: InstallationImage[] = getTempList(sortedImages);
                sortedList.push(...tempList);
            } else if (sortedImages.length === 3) {
                // Most of the time, there is one image dump a day, so sort into view order and display
                let tempList: InstallationImage[] = getTempList(sortedImages);
                sortedList.push(...tempList);
            } else {
                // We have more than 3 images so we need to sort by minute.
                let minutesMap = new Map();
                // Get the images per minute
                sortedImages.forEach((snapshot: InstallationImage) => {
                    let key: string = moment.utc(snapshot.timeStamp).format("YYYY-MM-DD HH:mm");

                    let val = minutesMap.get(key);
                    if (!val) {
                        minutesMap.set(key, { minute: [snapshot] });
                    } else {
                        val.minute.push(snapshot);
                    }
                });

                let bufferImages: InstallationImage[] = [];

                for (let [key, value] of minutesMap.entries()) {
                    let images: InstallationImage[] = value.minute;

                    //NOTE: Due to the images coming in not in view order, with view 2 coming in after view 3,
                    // the times will not always be in the right order
                    // E.G. Unit010 16/08/2022
                    // View 1 is 09:11.  View 3 id 09:11 and View 2 is 09:12 because it came in one minute later
                    // and we are sorting in view order, so the images are in the correct view column.

                    if (images.length < 3) {
                        // Then check the saved buffer
                        if (bufferImages.length > 0) {
                            let temp: InstallationImage[] = bufferImages;

                            if (bufferImages.length + images.length === 3) {
                                // then we have the three images we need
                                temp.push(...images);

                                images = temp;
                                bufferImages = [];
                            }
                        } else {
                            // Store the images for the next iteration
                            bufferImages = images;
                            continue;
                        }
                    } else if (images.length === 3) {
                        // fall through.
                    } else {
                        // we have a major problem a rogue image or two.
                        // We can either ignore them, or until this happens process as we normally would.
                        //let tempList: InstallationImage[] = getTempList(images);

                        //sortedList.push(...tempList);

                        bufferImages = [];
                    }
                    // Sort in view order
                    let tempList: InstallationImage[] = getTempList(images);

                    sortedList.push(...tempList);
                }
            }

            const title: string = day.key;
            let localItems: InstallationImageDto[] = sortedList.map((a: InstallationImage) => {
                return a as InstallationImageDto;
            });

            let panel: CollapsibleInstallationImagePanelViewModel = new CollapsibleInstallationImagePanelViewModel(title, `${title}`, false, localItems);
            collapsiblePanelViewModels.push(panel);
        }

        return collapsiblePanelViewModels;
    }
}
