import { ViewModelBase } from "Core/ViewModels/ViewModelBase";
import { action, computed, observable, runInAction } from "mobx";
import { FieldType } from "Core/Utils/Utils";
import { ApiResult } from "Core/Models";
import { Server } from "Custom/Globals/AppUrls";
import { InstallationAuditModel, InstallationAuditModelDTO } from "../Audit/InstallationAuditModel";

//extend viewmodel base and passing your model as the generic type
export class AuditModalViewModel extends ViewModelBase<InstallationAuditModel> {
    //Singleton instance of class
    private static _instance: AuditModalViewModel;
    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    @observable public errorMessage: string = "";

    @observable
    private auditItem: InstallationAuditModelDTO | undefined = undefined;

    @action
    public setItem(auditItem: InstallationAuditModelDTO | undefined) {
        this.auditItem = auditItem;
    }

    @computed public get getAuditItem(): InstallationAuditModelDTO | undefined {
        return this.auditItem;
    }

    constructor() {
        //Pass in a new instance of your model
        //By passing in true as the second parameter, we make this model undoable which means we can use save and reset options on the model
        //If you make a change to the model you need to persist it with a saveModel() call
        //If you make changes to your model you can revert it back by calling resetModel()
        super(new InstallationAuditModel(), false);
        //EN - Haven't figured out how to make this call work from the base model yet
        //This is only needed if you make use of the validation decorators
        this.setDecorators(InstallationAuditModel);
    }

    //isValid will check all fields to make sure they are in a valid state.
    public doSubmit = async (e: any) => {
        e.preventDefault();

        if (this.isModelValid()) {
            //Do stuff here
            this.errorMessage = "Form is valid";
        } else {
            this.errorMessage = "Form is not valid";
        }
    };

    @action
    public async loadData(): Promise<ApiResult<InstallationAuditModelDTO>> {
        let request = {
            id: this.auditItem?.id,
        };

        const apiResult = await this.Post<InstallationAuditModelDTO>(Server.Api.InstallationAudit.getInstallationAuditByInstallationAuditId, request);

        if (apiResult.wasSuccessful) {
            runInAction(() => {
                if (apiResult.payload !== null && apiResult.payload !== undefined) {
                    this.model.fromDto(apiResult.payload);
                }
                this.setIsLoading(false);
            });
        } else {
            // Error. What to do?
            this.setIsLoading(false);
            this.setIsErrored(true);
            this.history.push("/");
        }

        return apiResult;
    }

    @action
    public clear() {
        this.auditItem = undefined;
    }

    //This must be present in your viewmodel. Just return true if you dont need to validate anything.
    //keyof BlankModel & string lets you add type checking to the fieldName
    //I am using the validator package to make checking easier https://www.npmjs.com/package/validator
    public isFieldValid(fieldName: keyof FieldType<InstallationAuditModel>): boolean {
        const { isValid, errorMessage } = this.validateDecorators(fieldName);

        //You need to this two properties after validation
        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
