import { computed, observable } from "mobx";
import { ModelBase } from "Core/Models/ModelBase";
import { IsNotEmpty } from "class-validator";
import { TestGradeDTO } from "Custom/Views/Units/TestGrade";

//Base class expects the model type and the DTO model type
//If you are not using a DTO just pass in undefined as the DTO
export class TestGradeAddEditDetailModel extends ModelBase<TestGradeAddEditDetailModel, TestGradeDTO> {
    public id: number | null = null;
    public rowVersion: string | null = null;

    @observable
    @IsNotEmpty({ message: "You must give the unit status a name!!" })
    public name: string = "";

    public isDeleted: boolean = false;
    public createdBy: string | null = null;
    public createdDate: string | null = null;

    @observable
    public ordinal: number = 1;

    public author: string = "";
    public unitCount: number = 0;

    //fromDto is required but you can leave it blank
    fromDto(model: TestGradeDTO): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
    }

    //toDto is required but you can leave it blank
    toDto(model: TestGradeAddEditDetailModel): void {}
}
