import { ProjectRelatedModelDTO } from "./ProjectRelatedModel";
import * as MobX from "mobx";

import { FieldType, sortByString } from "Core/Utils/Utils";
import { GenericIdRequest, GenericIncludeDeleted, User } from "Custom/Models";
import { action, computed, observable } from "mobx";

import { ApiResult } from "Core/Models/ApiResult";
import { StoresInstance } from "Custom/Stores/Stores";
import { ViewModelBase } from "Core/ViewModels/ViewModelBase";
import { ProjectAddEditModel, ProjectAddEditModelDTO } from "Custom/Models/Domain/Projects/ProjectAddEditModel";
import { Client, Server } from "Custom/Globals/AppUrls";
import { ClientStore, PropertyStore, UserStore } from "Custom/Stores/Domain";
import { ProjectAndRelatedModelDTO } from "./ProjectAndRelatedModel";
import { ClientModel } from "Custom/Models/Domain/Clients";
import { ContactModel } from "Custom/Models/Domain/Contacts/ContactModel";
import { formatLowercaseAddress } from "Custom/Utils/format";
import { ContractorContactModelDTO, ContractorModelDTO, Property, PropertyDTO } from "Custom/Models/Domain";
import { AddressModel } from "Custom/Models/Domain/Address/AddressModel";
import { AutoCompleteitem, AutocompleteViewModel, autoselectPair } from "Custom/Components/Autocomplete/AutocompleteViewModel";
import { isThisHour } from "date-fns";

const domainStores = StoresInstance.domain;

export default class ProjectAddEditViewModel extends ViewModelBase<ProjectAddEditModel> {
    @observable private ascOrder = true;
    @observable public Valid: boolean = false;

    @observable public errorMessage: string = "";

    private clientStore: ClientStore = StoresInstance.domain.ClientStore;
    private propertyStore: PropertyStore = StoresInstance.domain.PropertyStore;
    private userStore: UserStore = StoresInstance.domain.UserStore;

    public contractors: MobX.IObservableArray<ContractorModelDTO> = observable([]);
    public contractorContacts: MobX.IObservableArray<ContractorContactModelDTO> = observable([]);

    constructor() {
        super(new ProjectAddEditModel());
        this.setDecorators(ProjectAddEditModel);
    }

    @computed
    public get getClients(): ClientModel[] {
        return this.clientStore.getClients;
    }

    @computed
    public get getSelectedClient(): ClientModel | undefined {
        let retVal: ClientModel | undefined = undefined;
        retVal = this.getClients.find((a: ClientModel) => a.id === (this.getClientId === undefined ? -1 : this.getClientId));

        return retVal;
    }

    @computed
    public get getClientLeads(): any[] {
        let retVal: AutoCompleteitem[] = [];
        this.clientStore.getClients.forEach((item, index) => {
            let newSelectItem: AutoCompleteitem = {
                id: item.id,
                label: item.displayName,
            };
            retVal.push(newSelectItem);
        });
        return retVal;
    }

    @computed
    public get getClientId() {
        return this.model.clientId;
    }

    @computed
    public get getContractorId() {
        return this.model.contractorId;
    }

    @computed
    public get getSearchString() {
        return this.model.searchProperty;
    }

    @computed
    public get AllProperties() {
        return this.propertyStore.getAll;
    }

    @computed
    public get SourceProperties() {
        const searchCriteria = this.getSearchString.toLocaleLowerCase();

        if (this.model.clientId === "") {
            return [];
        } else {
            let retVal = this.propertyStore.getAll.filter((a) => this.model.propertyIds.indexOf(a.id) === -1 && a.clientId === this.model.clientId);
            retVal = retVal.filter((a: Property) => formatLowercaseAddress(a, true).indexOf(searchCriteria) !== -1);
            return retVal;
        }
    }

    @computed
    public get DestinationProperties() {
        if (this.model.clientId === "") {
            return [];
        } else {
            return this.propertyStore.getAll.filter((a) => this.model.propertyIds.indexOf(a.id) !== -1 && a.clientId === this.model.clientId);
        }
    }

    @computed
    public get ClientContacts() {
        if (this.model.clientId === "") {
            return [];
        } else {
            let retVal: ContactModel[] = this.clientStore.getAllContactsForClient(this.model.clientId);
            retVal.sort((a: ContactModel, b: ContactModel) => {
                if (this.ascOrder) {
                    return sortByString(a.lastName, b.lastName);
                } else {
                    return sortByString(b.lastName, a.lastName);
                }
            });
            return retVal;
        }
    }

    @computed
    public get AllClients() {
        let retVal: ClientModel[] = this.clientStore.getAll.slice();
        retVal.sort((a: ClientModel, b: ClientModel) => {
            if (this.ascOrder) {
                return sortByString(a.clientName, b.clientName);
            } else {
                return sortByString(b.clientName, a.clientName);
            }
        });
        return retVal;
    }

    @computed
    public get AllContractors() {
        let retVal: ContractorModelDTO[] = this.contractors.slice();
        retVal.sort((a: ContractorModelDTO, b: ContractorModelDTO) => {
            if (this.ascOrder) {
                return sortByString(a.contractorName, b.contractorName);
            } else {
                return sortByString(b.contractorName, a.contractorName);
            }
        });
        return retVal;
    }

    @computed
    public get ContractorContacts() {
        if (this.model.contractorId === "") {
            return [];
        } else {
            let retVal: ContractorContactModelDTO[] = this.getAllContactsForContractor(this.model.contractorId);
            retVal.sort((a: ContractorContactModelDTO, b: ContractorContactModelDTO) => {
                if (this.ascOrder) {
                    return sortByString(a.lastName, b.lastName);
                } else {
                    return sortByString(b.lastName, a.lastName);
                }
            });
            return retVal;
        }
    }

    public getAllContactsForContractor(contractorId: string): ContractorContactModelDTO[] {
        return this.contractorContacts.filter((a) => a.contractorId === contractorId);
    }

    @computed
    public get AllRegionalManagers() {
        let retVal: User[] = this.userStore.getRegionalManagers.slice();
        retVal.sort((a: User, b: User) => {
            if (this.ascOrder) {
                return sortByString(a.lastName, b.lastName);
            } else {
                return sortByString(b.lastName, a.lastName);
            }
        });
        return retVal;
    }

    public get(fieldName: any) {
        return this.getValue(fieldName);
    }

    @action
    public set(fieldName: any, value: string | number | boolean | Date) {
        this.setValue(fieldName, value as string);
        this.isFieldValid(fieldName, value);
    }

    @action
    public setProperties(properties: string[]) {
        this.model.setValue("propertyIds", properties);
        this.isFieldValid("propertyIds", properties);
    }

    public isFieldValid(fieldName: keyof FieldType<ProjectAddEditModel>, value: any): boolean {
        const { isValid, errorMessage } = this.validateDecorators(fieldName);

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    @action
    public async loadProjectDetailsAsync(id: string): Promise<ApiResult<ProjectAndRelatedModelDTO>> {
        const request: GenericIdRequest = {
            id: id,
        };
        const apiResult = await this.Post<ProjectAndRelatedModelDTO>(Server.Api.Project.getProjectAndRelatedForEdit, request);

        if (apiResult.wasSuccessful && apiResult.payload) {
            MobX.runInAction(() => {
                this.model.fromDto(apiResult.payload.project);
                domainStores.ClientStore.setClients(apiResult.payload.related.clients);
                domainStores.ClientStore.setClientContacts(apiResult.payload.related.contacts);
                domainStores.PropertyStore.setProperties(apiResult.payload.related.properties);
                domainStores.UserStore.setUsers(apiResult.payload.related.users);
                this.setContractors(apiResult.payload.related.contractors);
                this.setContractorContacts(apiResult.payload.related.contractorContacts);
            });
        }

        return apiResult;
    }

    @action
    public async loadProjectRelatedAsync(): Promise<ApiResult<ProjectRelatedModelDTO>> {
        const includeGenericDeleted: GenericIncludeDeleted = {
            includeDeleted: false,
        };
        const apiResult = await this.Post<ProjectRelatedModelDTO>(Server.Api.Project.getAdminProjectRelated, includeGenericDeleted);

        if (apiResult.wasSuccessful) {
            MobX.runInAction(() => {
                domainStores.ClientStore.setClients(apiResult.payload.clients);
                domainStores.ClientStore.setClientContacts(apiResult.payload.contacts);
                domainStores.PropertyStore.setProperties(apiResult.payload.properties);
                domainStores.UserStore.setUsers(apiResult.payload.users);
                this.setContractors(apiResult.payload.contractors);
                this.setContractorContacts(apiResult.payload.contractorContacts);
            });
        }
        return apiResult;
    }

    @action
    public setContractors(contractors: ContractorModelDTO[]) {
        this.contractors.replace(contractors);
    }

    @action
    public setContractorContacts(contractorContacts: ContractorContactModelDTO[]) {
        this.contractorContacts.replace(contractorContacts);
    }

    @action
    public async upsertProperty(newAddress: AddressModel): Promise<boolean> {
        const request = newAddress.toPropertyDto();
        request.clientId = this.getClientId;
        request.okToAddInstallation = true;

        const apiResult = await this.Post<PropertyDTO>(Server.Api.Property.upsertProperty, request);

        MobX.runInAction(() => {
            if (apiResult.wasSuccessful) {
                domainStores.PropertyStore.addNewProperty(apiResult.payload);
            } else {
            }
        });

        return apiResult.wasSuccessful;
    }

    @action
    public AddPropertyResult = (newProperty: PropertyDTO): void => {
        domainStores.PropertyStore.addNewProperty(newProperty);
    };

    @computed get getIsLoadingData(): boolean {
        return this.IsLoading;
    }

    @action
    public setOrderAsc() {
        this.ascOrder = !this.ascOrder;
    }

    @computed get getOrderAsc(): boolean {
        return this.ascOrder;
    }

    @action
    public getUpsertModel(): ProjectAddEditModelDTO {
        let retVal: ProjectAddEditModelDTO = {
            createdBy: "",
            createdDate: "",
            id: undefined,
            number: "",
            name: "",
            propertyIds: [],
            regionalSalesManagerId: "",
            status: 0, // Project Status Enum Id = 0 is 'unknown'.
            paymentStatus: 1,
            clientId: "",
            contractorId: "",
            isDeleted: false,
            rowVersion: "",
            leadContactId: "",
            potentialUnits: 0,
            contractorLeadContactId: "",
        };
        this.model.toDto(retVal);
        return retVal;
    }

    @action
    public async postProjectDetailsAsync(): Promise<ApiResult<ProjectAddEditModelDTO>> {
        const request: ProjectAddEditModelDTO = this.getUpsertModel();

        let apiResult: ApiResult<ProjectAddEditModelDTO> = await this.Post<ProjectAddEditModelDTO>(Server.Api.Project.upsert, request);

        if (apiResult !== null) {
            if (apiResult.wasSuccessful) {
                MobX.runInAction(() => {
                    this.model.fromDto(apiResult.payload);
                });
            }
        }

        return apiResult;
    }

    @action
    public doSubmit = async (e: any) => {
        e.preventDefault();
        if (!this.isModelValid()) {
            this.errorMessage = "Please fix the validation errors and re-submit.";
        } else {
            this.errorMessage = "";

            this.postProjectDetailsAsync().then((apiResult) => {
                if (apiResult.wasSuccessful === true) {
                    this.history.push(Client.Main.Project.Root);
                } else {
                    MobX.runInAction(() => {
                        this.errorMessage = "Failed to save the project.";
                    });
                }
            });
        }
    };
}
