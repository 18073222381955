import styled from "styled-components";
import { TabCommonBox } from "./TabCommonStyle.style";

export const ConfigurationBox: any = styled(TabCommonBox)`
    /*     padding: 5px 15px;
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    width: 100%;
    background-color: #585858;
    color: white; */
`;
