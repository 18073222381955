import { action, observable } from "mobx";
import { ModelBase } from "Core/Models/ModelBase";
import { IsNotEmpty } from "class-validator";
import { DeviceUnitBehaviourItemModelDTO } from "Custom/Views/Projects/Details/Properties/Installations/DeviceUnitBehaviourItemModel";
import { ConditionType, ConditionUnits } from "Custom/Models/Domain";

//Base class expects the model type and the DTO model type
//If you are not using a DTO just pass in undefined as the DTO
export class UnitBehaviourItemModel extends ModelBase<UnitBehaviourItemModel, UnitBehaviourItemModelDTO> {
    public id: string | null = null;
    public rowVersion: string | null = null;
    public createdBy: string | null = null;
    public createdDate: string | null = null;
    public unitBehaviourId: string | null = null;

    @observable
    public commandIndex: number = 0;

    @observable public isDeleted: boolean = false;

    @observable
    @IsNotEmpty({ message: "You must give me a parameter name!!" })
    public name: string = "";

    @observable
    public type: ConditionType = ConditionType.H;

    @observable
    @IsNotEmpty({ message: "You must give me a value!!" })
    public value: number = 0;

    @observable
    public units: ConditionUnits = ConditionUnits.MM;

    @observable
    public frequency: number = 3; // choices are x3, x4, x6

    @observable
    public reading: number = 5; //this.commandIndex === 0 ? 1 : 5;

    public constructor() {
        super();
    }

    @action
    public setCommandIndex = (value: number) => {
        this.commandIndex = value;
        // Set the reading to the minimum value;
        //this.reading = this.commandIndex === 0 ? 1 : 5;
    };

    //fromDto is required but you can leave it blank
    fromDto(model: UnitBehaviourItemModelDTO): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
    }

    //toDto is required but you can leave it blank
    toDto(model: UnitBehaviourItemModel): void {}

    public toSendDto(): UnitBehaviourItemModelDTO {
        return {
            id: this.id,
            rowVersion: this.rowVersion,
            isDeleted: this.isDeleted,
            createdBy: this.createdBy,
            createdDate: this.createdDate,

            name: this.name,
            type: this.type,
            value: this.value,
            units: this.units,
            unitBehaviourId: this.unitBehaviourId,
            frequency: this.frequency,
            reading: this.reading,
            commandIndex: this.commandIndex,
        };
    }

    @action public fromDeviceDto(model: UnitBehaviourItemModelDTO): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] === "unitBehaviourId") {
                    this[key] = "deviceUnitBehaviourId";
                } else if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
    }

    public toSendDeviceDto(): DeviceUnitBehaviourItemModelDTO {
        return {
            id: this.id,
            rowVersion: this.rowVersion,
            isDeleted: this.isDeleted,
            createdBy: this.createdBy,
            createdDate: this.createdDate,

            name: this.name,
            type: this.type,
            value: this.value,
            units: this.units,
            deviceUnitBehaviourId: this.unitBehaviourId,
            frequency: this.frequency,
            reading: this.reading,
            commandIndex: this.commandIndex,
        };
    }
}

export type UnitBehaviourItemModelDTO = {
    id: string | null;
    rowVersion: string | null;
    isDeleted: boolean;
    createdBy: string | null;
    createdDate: string | null;

    name: string;
    type: ConditionType;
    value: number;
    units: ConditionUnits;
    unitBehaviourId: string | null;
    frequency: number;
    reading: number;
    commandIndex: number;
};
