import * as React from "react";
import { isGuid } from "./isGuid";
import { useRouter } from "../../Core/Base";

interface IOptions {
    checkGuidParamKey?: string;
    customCheck?: () => boolean;
}

export function useLoadCall(load: () => Promise<boolean>, opts?: IOptions) {
    const [isNotFound, setNotFound] = React.useState(false);

    const router = useRouter();

    React.useEffect(() => {
        if (opts?.checkGuidParamKey !== undefined) {
            if (!isGuid(router.match.params[opts.checkGuidParamKey])) {
                setNotFound(true);
                return;
            }
        } else if (opts?.customCheck !== undefined) {
            if (!opts.customCheck()) {
                setNotFound(true);
                return;
            }
        }

        load().then((isFound) => {
            if (!isFound) setNotFound(true);
        });
    }, []);

    return isNotFound;
}
