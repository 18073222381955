import { Breadcrumbs } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";

export const useBreadcrumbStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        height: "31px",
        /* backgroundColor: theme.app.colors.breadcrumb.main, */
        backgroundColor: "black",
        color: theme.app.colors.white.main,
        padding: "0 30px",
        /* background: "linearGradient(180deg, rgba(86,87,88,1) 0%, rgba(55,58,64,1) 35%)", */

        boxShadow: "0px 2px 3px #000000BF",
        opacity: "0.33",
    },
    lists: {
        backgroundColor: theme.palette.background.paper,
        color: theme.app.colors.white.main,
        paddingLeft: theme.spacing(4),
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));

export const RoofcareBreadcrumbs = styled(Breadcrumbs)`
    color: ${(props) => props.theme.app.colors.white.main};
    background: #3f4b65 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 3px #000000bf;
    opacity: 1;
    .MuiBreadcrumbs-ol,
    .MuiBreadcrumbs-li {
        a {
            color: ${(props) => props.theme.app.colors.white.main};
            font-size: 12px;
        }
    }

    .MuiBreadcrumbs-separator {
        color: ${(props) => props.theme.app.colors.orange.light};
        font-size: 12px;
        font-weight: 700;
        font-family: "Mont-Bold", "Montserrat", "Roboto Condensed", "Helvetica", "Arial", "sans-serif";
    }

    li {
        :not(:last-child) a {
            text-decoration: underline;
        }

        :last-child a {
            pointer-events: none;
        }
    }
`;
