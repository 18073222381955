import { ViewModelBase } from "Core/ViewModels/ViewModelBase";
import { ClientListItemModel, ClientListItemModelDTO } from "Custom/Models/Domain/Clients/ClientListModel";
import { FieldType } from "Core/Utils/Utils";
import { action, computed, IObservableArray, observable, runInAction } from "mobx";
import { ApiResult } from "Core/Models";
import { Server } from "Custom/Globals/AppUrls";
import { PropertyItemModel, PropertyItemModelDTO } from "Custom/Views/CommonViews/PropertyItemModel";
import { StoresInstance } from "Custom/Stores";
import { formatAddress } from "../../Utils/format";
import { PropertyMapItemModelDTO } from "Custom/Models/Domain/Properties/PropertyMapItemModel";
import { ClientPropertyListAndMapItems, InstallationMapModel, InstallationMapModelDTO } from "Custom/Models/Domain";

const domainStores = StoresInstance.domain;

//extend viewmodel base and passing your model as the generic type
export class ClientViewPropertyListViewModel extends ViewModelBase<PropertyItemModel> {
    private static _instance: ClientViewPropertyListViewModel;
    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    @observable public errorMessage: string = "";

    public properties: IObservableArray<PropertyItemModel> = observable<PropertyItemModel>([]);
    public installations: IObservableArray<InstallationMapModel> = observable<InstallationMapModel>([]);

    @computed get getInstallations(): InstallationMapModel[] {
        return this.installations.slice();
    }
    @computed get getProperties(): PropertyItemModel[] {
        return this.properties.slice();
    }

    @computed
    public get propertyTableData(): PropertyMapItemModelDTO[] {
        return this.properties.map((property: PropertyItemModel) => ({
            id: property.propertyId,
            projectId: property.projectId,
            propertyAddress: formatAddress(property, true),
            installationCount: property.installationCount,
            projectNumber: property.projectNumber,
            projectName: property.projectName,
            clientName: property.clientName,
            regionalSalesManagerName: property.regionalSalesManagerName,
        }));
    }

    constructor() {
        //Pass in a new instance of your model
        //By passing in true as the second parameter, we make this model undoable which means we can use save and reset options on the model
        //If you make a change to the model you need to persist it with a saveModel() call
        //If you make changes to your model you can revert it back by calling resetModel()
        super(new PropertyItemModel(), false);
        //EN - Haven't figured out how to make this call work from the base model yet
        //This is only needed if you make use of the validation decorators
        this.setDecorators(PropertyItemModel);
    }

    //This must be present in your viewmodel. Just return true if you dont need to validate anything.
    //keyof BlankModel & string lets you add type checking to the fieldName
    //I am using the validator package to make checking easier https://www.npmjs.com/package/validator
    public isFieldValid(fieldName: keyof FieldType<PropertyItemModel>): boolean {
        const { isValid, errorMessage } = this.validateDecorators(fieldName);

        //You need to this two properties after validation
        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    @action
    public setProperties(properties: PropertyItemModelDTO[]) {
        properties.forEach((item) => {
            const domainModel = new PropertyItemModel();

            domainModel.fromDto(item);
            this.properties.push(domainModel);
        });
    }

    @action
    public setInstallations(installations: InstallationMapModelDTO[]) {
        this.installations.clear();
        installations.forEach((item) => {
            const domainModel = new InstallationMapModel();
            domainModel.fromDto(item);
            this.installations.push(domainModel);
        });
    }

    @action
    public async loadPropertyListAsync(): Promise<ApiResult<PropertyItemModelDTO[]>> {
        const apiResult = await this.Post<PropertyItemModelDTO[]>(Server.Api.ClientView.getPropertiesForList);

        if (apiResult.wasSuccessful) {
            runInAction(() => {
                this.setProperties(apiResult.payload);
            });
        }
        return apiResult;
    }

    @action
    public async loadPropertyListAndDevicesAsync(): Promise<ApiResult<ClientPropertyListAndMapItems>> {
        const apiResult = await this.Post<ClientPropertyListAndMapItems>(Server.Api.Client.getPropertiesAndRelatedForListAndMap);

        if (apiResult.wasSuccessful) {
            runInAction(() => {
                this.setProperties(apiResult.payload.properties);
                this.setInstallations(apiResult.payload.installations);
            });
        }
        return apiResult;
    }
}
