import { UnitBehaviourItemModelDTO } from "Custom/Views/Admin/UnitBehaviour/UnitBehaviourItemModel";
import { DrainageType } from "Custom/Models/Domain/Installations/DrainageType";
import { RoofType } from "Custom/Models/Domain/Installations/RoofType";
import validator from "validator";
import { action, observable, computed, runInAction } from "mobx";
import { InstallationAddEdit, InstallationAddEditDTO } from "Custom/Models/Domain/Installations/InstallationAddEdit";
import { ViewModelBase } from "Core/ViewModels/ViewModelBase";
import { FieldType } from "Core/Utils/Utils";
import { ApiResult } from "Core/Models";
import { AppUrls } from "Custom/Globals";
import { Address, ConditionType, ConditionUnits, ContractorModel, ContractorModelDTO, DefaultAddress, InstallationStatusDataDTO, SideType } from "Custom/Models/Domain";
import { GenericIdRequest, GenericIncludeDeleted } from "Custom/Models";
import { InstallationNoPropertyDTO } from "./InstallationNoPropertyDTO";
import { InstallationRelatedDTO } from "Custom/Models/Domain/Installations/InstallationRelatedDTO";
import { calculateActualHeight, isNullOrUndefined } from "Custom/Utils/utils";
import { AdminUnitBehaviourTabModelDTO } from "Custom/Views/Admin/AdminUnitBehaviourTabModel";
import { UnitBehaviourItemModel } from "Custom/Views/Admin/UnitBehaviour/UnitBehaviourItemModel";
import { DeviceUnitBehaviourViewModel } from "./DeviceUnitBehaviourViewModel";
import { DeviceUnitBehaviourModel } from "./DeviceUnitBehaviourModel";
import { ProjectPropertyListItemDTO, ProjectPropertyListItemModel } from "Custom/Views/Installations/Modals/ProjectPropertyListItemModel";
import { LatLngBounds, LatLngExpression } from "leaflet";
import { DEFAULTBOUNDS } from "Custom/Globals/Globals";
import { getDefaultPropertyMarkerIcon } from "Custom/Utils/map";
import { IDraggableMarker } from "Custom/Components/Map/DraggablePin";
import { TestGradeDTO } from "Custom/Views/Units/TestGrade";
import { DeviceUnitStatusDTO } from "Custom/Views/Units/DeviceUnitStatus";
import { UnitVersionDTO } from "Custom/Views/Units/UnitVersion";
import { UnitFirmwareDTO } from "Custom/Views/Units/UnitFirmware";
import { InstallationAndRelatedForEditDTO } from "Custom/Models/Domain/Installations/InstallationAndRelatedForEdit";
import { SanitiseDataByCommissionDateRequest } from "./SanitiseDataByCommissionDateRequest";
//extend viewmodel base and passing your model as the generic type
export class InstallationAddViewModel extends ViewModelBase<InstallationAddEdit> {
    @observable public errorMessage: string = "";
    @observable public validMessage: string = "";

    public originalDrainageType: DrainageType | undefined = undefined;

    public contractors = observable<ContractorModel>([]);

    public devicesNoProperty = observable<InstallationNoPropertyDTO>([]);

    public adminUnitBehaviours = observable<AdminUnitBehaviourTabModelDTO>([]);

    public properties = observable<ProjectPropertyListItemModel>([]);

    public testGrade = observable<TestGradeDTO>([]);
    public unitStatus = observable<DeviceUnitStatusDTO>([]);
    public unitVersion = observable<UnitVersionDTO>([]);
    public unitFirmware = observable<UnitFirmwareDTO>([]);

    @observable
    public firstTime: boolean = true;

    @computed public get getProperties(): ProjectPropertyListItemModel[] {
        return this.properties.slice();
    }

    @observable public localUnitBehaviour: DeviceUnitBehaviourViewModel | undefined = undefined;

    @observable public adminUnitBehaviourId: string = "0";

    @observable public hasAUnitBehaviourObject: boolean = false;

    @computed public get isRoofcareAddressInError(): boolean {
        if (this.firstTime === false && (this.model.roofcareAddressId === null || this.model.roofcareAddressId === undefined || this.model.roofcareAddressId.length < 1)) {
            return true;
        }
        return false;
    }

    @computed public get getTestGrades(): TestGradeDTO[] {
        return this.testGrade.slice();
    }

    @computed public get getUnitStatus(): DeviceUnitStatusDTO[] {
        return this.unitStatus.slice();
    }

    @computed public get getVersions(): UnitVersionDTO[] {
        return this.unitVersion.slice();
    }

    @computed public get getFirmware(): UnitFirmwareDTO[] {
        return this.unitFirmware.slice();
    }

    @action public setFirmwareValue(value: number) {
        this.model.unitData.setValue("firmwareId", value);
    }

    @action public setDrainageTypeValue(value: number) {
        this.setValue("drainageType", value);

        // will be undefined if a new device
        if (this.originalDrainageType !== undefined) {
            if (this.originalDrainageType !== value) {
                //#7686 - reset unitbehaviour on drainage type change.
                this.model.unitBehaviour == undefined;
            }
        } else {
        }
    }

    @computed public get getWorkingHeightString(): string {
        let retVal: string = "";

        if (this.getWorkingHeightValue !== null && this.getWorkingHeightValue !== undefined) {
            retVal = this.getWorkingHeightValue.toFixed(2);
        }

        return retVal;
    }

    @computed public get hasAddress(): boolean {
        return this.model.roofcareAddressId !== null && this.model.roofcareAddressId !== undefined;
    }

    @computed public get getPropertyAddress(): Address {
        let retVal: Address = DefaultAddress;

        if (this.model.roofcareAddressId !== null && this.model.roofcareAddressId !== undefined) {
            const property: ProjectPropertyListItemModel | undefined = this.properties.find((a) => a.id === this.model.roofcareAddressId);

            if (property !== undefined) {
                retVal.addressLine1 = property.addressLine1;
                retVal.addressLine2 = property.addressLine2;
                retVal.city = property.city;
                retVal.county = property.county;
                retVal.postcode = property.postcode;
            }
        }

        return retVal;
    }

    @computed public get getWorkingHeightValue(): number {
        let retVal: number = 0;

        if (this.model.roofType === RoofType.Flat) {
            retVal = this.model.maxHeight;
        } else {
            // TODO CMR RC001 - Need Side 2 stuff for 4 sided gutters plus new calculate Actual height

            let side1_Height = calculateActualHeight(this.model.side1_Length, this.model.side1_Angle);
            let side2_Height = calculateActualHeight(this.model.side2_Length, this.model.side2_Angle);

            retVal = Math.min(side1_Height, side2_Height);
        }
        return retVal;
    }

    @computed public get getFreeboardString(): string {
        let retVal: string = "";

        if (this.getFreeboardValue !== null && this.getFreeboardValue !== undefined) {
            retVal = this.getFreeboardValue.toFixed(2);
        }

        return retVal;
    }

    @computed public get getFreeboardValue(): number {
        let retVal: number = 0;

        let freeboardPercent: number = 0.7;

        if (this.model.roofType === RoofType.Flat) {
            retVal = this.model.maxHeight * freeboardPercent;
        } else {
            let side1_Height = calculateActualHeight(this.model.side1_Length, this.model.side1_Angle);
            let side2_Height = calculateActualHeight(this.model.side2_Length, this.model.side2_Angle);

            let maxWorkingHeightValue = Math.min(side1_Height, side2_Height);
            retVal = maxWorkingHeightValue * 0.7;
        }

        return retVal;
    }

    @computed public get getUnitBehaviour(): DeviceUnitBehaviourViewModel | undefined {
        return this.localUnitBehaviour;
    }

    @computed public get hasUnitBehaviour(): boolean {
        return this.hasAUnitBehaviourObject;
    }

    @action public setUnitBehaviourItem = (value: string) => {
        this.adminUnitBehaviourId = value;
        this.localUnitBehaviour = new DeviceUnitBehaviourViewModel();
        this.model.unitBehaviour = new DeviceUnitBehaviourModel();
        this.model.unitBehaviour.InitItems();
        this.hasAUnitBehaviourObject = true;
    };

    @action public deleteUnitBehaviour = () => {
        this.adminUnitBehaviourId = "0";
        this.localUnitBehaviour = undefined;
        this.model.unitBehaviour = undefined;
        this.hasAUnitBehaviourObject = false;
    };

    @computed get getContractors() {
        return this.contractors.slice().filter((a) => a.isDeleted === false || a.id === this.model.contractorId);
    }

    @computed get getAdminUnitBehaviours() {
        let temp: AdminUnitBehaviourTabModelDTO[] = this.adminUnitBehaviours.slice();
        temp = temp.filter((a) => a.drainageType <= this.model.drainageType);

        // Need our working height.
        let maxWorkingHeightValue: number = Math.min(this.model.side1_Height, this.model.side2_Height);

        if (this.model.roofType === RoofType.Flat) {
            maxWorkingHeightValue = this.model.maxHeight;
        }
        const freeboardHeightValue: number = maxWorkingHeightValue * 0.7;

        let retVal: AdminUnitBehaviourTabModelDTO[] = [];
        for (let i: number = 0; i < temp.length; i++) {
            let valid: boolean = this.isAdminUnitBehaviourValid(maxWorkingHeightValue, freeboardHeightValue, this.model.p1, this.model.p2, temp[i]);

            if (valid === true) {
                retVal.push(temp[i]);
            }
        }

        return retVal;
    }

    public isAdminUnitBehaviourValid = (
        maxWorkingHeightValue: number,
        freeboardHeightValue: number,
        p1: number,
        p2: number,
        itemToCheck: AdminUnitBehaviourTabModelDTO,
    ): boolean => {
        let retVal: boolean = true;

        for (let j: number = 0; j < itemToCheck.items.length; j++) {
            let tempItem: UnitBehaviourItemModelDTO = itemToCheck.items[j];
            let conditionItemValue: number = 0;

            if (tempItem.type === ConditionType.H) {
                conditionItemValue = maxWorkingHeightValue;
            } else if (tempItem.type === ConditionType.FB) {
                conditionItemValue = freeboardHeightValue;
            } else if (tempItem.type === ConditionType.P1) {
                conditionItemValue = p1;
            } else if (tempItem.type === ConditionType.P2) {
                conditionItemValue = p2;
            }

            if (tempItem.units == ConditionUnits.MM) {
                conditionItemValue = conditionItemValue + tempItem.value;
            } else {
                // it equals %
                let percentValue: number = tempItem.value / 100;
                conditionItemValue = conditionItemValue + conditionItemValue * percentValue;
            }
            let calculatedValue: number = conditionItemValue;

            if (calculatedValue < 0 || calculatedValue > maxWorkingHeightValue) {
                retVal = false;
            }
        }

        return retVal;
    };

    @computed get getDevicesNoProperty() {
        return this.devicesNoProperty.slice();
    }

    @computed get getContractor(): string {
        return this.model.contractorId;
    }

    @computed get getIfLoading(): boolean {
        return this.IsLoading;
    }

    @computed get getSimId() {
        return this.model.simId;
    }

    @computed get getRoofcareAddressId() {
        return this.model.roofcareAddressId;
    }

    constructor() {
        //Pass in a new instance of your model
        //By passing in true as the second parameter, we make this model undoable which means we can use save and reset options on the model
        //If you make a change to the model you need to persist it with a saveModel() call
        //If you make changes to your model you can revert it back by calling resetModel()
        super(new InstallationAddEdit(), false);
        //EN - Haven't figured out how to make this call work from the base model yet
        //This is only needed if you make use of the validation decorators
        this.setDecorators(InstallationAddEdit);
    }

    public isUnitStatusDataModelValid(): boolean {
        let retVal: boolean = true;

        retVal =
            this.isFieldValid("install_Height") &&
            this.isFieldValid("side1_Length") &&
            this.isFieldValid("side2_Length") &&
            this.isFieldValid("side1_Angle") &&
            this.isFieldValid("side2_Angle") &&
            /*  TODO RC001           this.isFieldValid("side1_Length2") &&
            this.isFieldValid("side2_Length2") &&
            this.isFieldValid("side1_Angle2") &&
            this.isFieldValid("side2_Angle2") && */
            this.isFieldValid("p1") &&
            this.isFieldValid("p2") &&
            this.isFieldValid("maxHeight");

        return retVal;
    }

    //This must be present in your viewmodel. Just return true if you dont need to validate anything.
    //keyof BlankModel & string lets you add type checking to the fieldName
    //I am using the validator package to make checking easier https://www.npmjs.com/package/validator
    public isFieldValid(fieldName: keyof FieldType<InstallationAddEdit>): boolean {
        let { isValid, errorMessage } = this.validateDecorators(fieldName);

        if (fieldName === "emailAddresses") {
            if (this.model.emailAddresses.length > 0) {
                const split = this.model.emailAddresses.split(",");

                for (let i: number = 0; i < split.length; i++) {
                    let temp: string = split[i].trim();
                    if (temp.length > 0) {
                        if (validator.isEmail(temp) === false) {
                            errorMessage = "Not a valid list of email addresses";
                            isValid = false;
                        }
                    }
                }
            }
        }

        if (fieldName === "mobileNumbers") {
            if (this.model.mobileNumbers.length > 0) {
                const split = this.model.mobileNumbers.split(",");

                for (let i: number = 0; i < split.length; i++) {
                    let temp: string = split[i].trim();
                    if (temp.length > 0) {
                        if (validator.isMobilePhone(temp, "en-GB") === false) {
                            errorMessage = "Not a valid list of mobile numbers";
                            isValid = false;
                        }
                    }
                }
            }
        }

        if (fieldName === "simIdConfirm") {
            errorMessage = this.isSimIdComfirmValid;
            isValid = errorMessage === "";
        }

        if (fieldName === "deviceIdConfirm") {
            errorMessage = this.isDeviceIdConfirmValid;
            isValid = errorMessage === "";
        }

        if (fieldName === "unitBehaviour") {
            errorMessage = this.isUnitBehaviourValid;
            isValid = errorMessage === "";
        }

        if (fieldName === "roofcareAddressId") {
            errorMessage = this.isPropertyIdValid;
            isValid = errorMessage === "";
        }

        //You need to this two properties after validation
        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    @action
    public setSimId = (value: string) => {
        /*         const device = this.devicesNoProperty.find((a) => a.simId === value);

        if (device !== undefined) {
            this.loadFromNoPropertyDevice(device);
        } */

        this.setValue("simId", value);
    };

    public get isSimIdComfirmValid(): string {
        if (this.getValue("simId") !== this.getValue("simIdConfirm") && this.getValue("simId") !== "") {
            return "Sim Id must match";
        }
        return "";
    }

    public get isDeviceIdConfirmValid(): string {
        if (this.getValue("deviceId") !== this.getValue("deviceIdConfirm") && this.getValue("deviceId") !== "") {
            return "Device Id must match";
        }
        return "";
    }

    public get isPropertyIdValid(): string {
        const value = this.getValue("roofcareAddressId");
        if (value === null || value === undefined || value === "") {
            return "Property is required.";
        }
        return "";
    }

    public get isUnitBehaviourValid(): string {
        this.model.recalculateData();
        if (this.localUnitBehaviour === null || this.localUnitBehaviour === undefined) {
            return "Unit Behavior is required";
        } else {
            // we have unit behaviour
            let temp: AdminUnitBehaviourTabModelDTO[] = this.adminUnitBehaviours.slice();
            const id: string | null = this.localUnitBehaviour!.model.unitBehaviourId;
            temp = temp.filter((a) => a.id === id);

            // Need our working height.
            let maxWorkingHeightValue: number = Math.min(this.model.side1_Height, this.model.side2_Height);

            if (this.model.roofType === RoofType.Flat) {
                maxWorkingHeightValue = this.model.maxHeight;
            }
            const freeboardHeightValue: number = maxWorkingHeightValue * 0.7;

            let retVal: AdminUnitBehaviourTabModelDTO[] = [];
            for (let i: number = 0; i < temp.length; i++) {
                let valid: boolean = this.isAdminUnitBehaviourValid(maxWorkingHeightValue, freeboardHeightValue, this.model.p1, this.model.p2, temp[i]);

                if (valid === false) {
                    let retVal: string = "Unit Behavior is outside the bounds of 0 and the working height";

                    if (maxWorkingHeightValue !== null && maxWorkingHeightValue !== undefined) {
                        retVal = "Unit Behavior is outside the bounds of 0 and the working height of " + maxWorkingHeightValue.toFixed(0) + "mm";
                    }

                    return retVal;
                }
            }
        }
        return "";
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    @action
    public set(fieldName: any, value: string | number | boolean | Date) {
        this.setValue(fieldName, value as string);
        this.isFieldValid(fieldName);
    }

    @action
    public setRoofcareId(value: string | undefined) {
        this.setValue("roofcareAddressId", value);
        this.isFieldValid("roofcareAddressId");
    }

    @action
    public setProperty(value: ProjectPropertyListItemModel | undefined) {
        this.setValue("roofcareAddressId", value?.id);
        this.isFieldValid("roofcareAddressId");

        this.setValue("latitude", value?.latitude);
        this.setValue("longitude", value?.longitude);
        this.firstTime = false;
    }

    @action
    public async loadContractorsAsync(): Promise<ApiResult<ContractorModelDTO[]>> {
        const request: GenericIncludeDeleted = {
            includeDeleted: true,
        };
        const apiResult: ApiResult<ContractorModelDTO[]> = await this.Post<ContractorModelDTO[]>(AppUrls.Server.Api.Contractor.getContractors, request);

        if (apiResult.wasSuccessful) {
            runInAction(() => {
                this.setContractors(apiResult.payload);
            });
        }

        return apiResult;
    }

    @action
    public async loadRelatedAsync(): Promise<ApiResult<InstallationRelatedDTO>> {
        const request: GenericIncludeDeleted = {
            includeDeleted: true,
        };
        const apiResult: ApiResult<InstallationRelatedDTO> = await this.Post<InstallationRelatedDTO>(AppUrls.Server.Api.Installation.getInstallationRelated, request);

        if (apiResult.wasSuccessful) {
            runInAction(() => {
                this.setContractors(apiResult.payload.contractors);
                this.setDevicesNoProperty(apiResult.payload.devices);
                this.setAdminUnitBehaviours(apiResult.payload.unitBehaviours);
                this.setProperties(apiResult.payload.properties);

                this.setTestGrade(apiResult.payload.testGrade);
                this.setUnitFirmware(apiResult.payload.unitFirmware);
                this.setUnitStatus(apiResult.payload.unitStatus);
                this.setUnitVersion(apiResult.payload.unitVersion);
            });
        }

        return apiResult;
    }

    @action
    public async loadInstallationAsync(id: string): Promise<ApiResult<InstallationAndRelatedForEditDTO>> {
        const request: GenericIdRequest = {
            id: id,
        };
        this.originalDrainageType = undefined;
        this.setError("unitBehaviour", "");
        this.setValid("unitBehaviour", false);

        const apiResult: ApiResult<InstallationAndRelatedForEditDTO> = await this.Post<InstallationAndRelatedForEditDTO>(
            AppUrls.Server.Api.Installation.getInstallationAndRelatedForEdit,
            request,
        );

        if (apiResult.wasSuccessful) {
            runInAction(() => {
                this.setIsLoading(true);
                this.setContractors(apiResult.payload.contractors);
                this.setDevicesNoProperty(apiResult.payload.devices);
                this.setAdminUnitBehaviours(apiResult.payload.unitBehavioursList);
                this.setProperties(apiResult.payload.properties);
                this.setTestGrade(apiResult.payload.testGrade);
                this.setUnitFirmware(apiResult.payload.unitFirmware);
                this.setUnitStatus(apiResult.payload.unitStatus);
                this.setUnitVersion(apiResult.payload.unitVersion);

                this.model.fromInstallAndRelatedDto(apiResult.payload);
                this.firstTime = false; // we want validation

                if (apiResult.payload !== null && apiResult.payload !== undefined && apiResult.payload.statusData !== null && apiResult.payload.statusData !== undefined) {
                    this.originalDrainageType = apiResult.payload.statusData?.drainageType;
                }

                // TODO CMR We should rewrite this so localUnitBehaviour
                // uses the one in the model and not its own.
                this.localUnitBehaviour = undefined;

                if (this.model.unitBehaviour !== null && this.model.unitBehaviour !== undefined) {
                    this.localUnitBehaviour = new DeviceUnitBehaviourViewModel();

                    this.localUnitBehaviour.setModelData(this.model);

                    this.hasAUnitBehaviourObject = true;
                    this.adminUnitBehaviourId = "";
                    if (this.model.unitBehaviour !== null && this.model.unitBehaviour !== undefined) {
                        this.adminUnitBehaviourId = this.model.unitBehaviour.unitBehaviourId === null ? "" : this.model.unitBehaviour.unitBehaviourId;
                    }
                }
                this.setIsLoading(false);
            });
        } else {
            this.setIsErrored(true);
            this.setErrors("Failed to store the installation.  Please try again later");
        }

        return apiResult;
    }

    @action
    public fromUnitUpsert(statusData: InstallationStatusDataDTO) {
        this.model.setStatusData(statusData);
    }

    public toUnitRequestDTO(): InstallationStatusDataDTO {
        return this.model.getStatusData();
    }

    @action public updateUnitBehavioursFromVMs() {
        if (this.localUnitBehaviour !== undefined && this.localUnitBehaviour!.vmItems !== undefined) {
            for (let i: number = 0; i < this.localUnitBehaviour!.vmItems.length; i++) {
                if (this.model.unitBehaviour !== undefined) {
                    let vm = this.localUnitBehaviour!.vmItems[i].model;
                    this.model.updateItemFromVM(vm);
                }
            }
        }
    }

    setDevicesWithNoProperty(payload: InstallationNoPropertyDTO[]) {
        this.devicesNoProperty.replace(payload);
    }

    @action
    public async getInstallationDevicesWithNoProperty(): Promise<ApiResult<InstallationNoPropertyDTO[]>> {
        const apiResult: ApiResult<InstallationNoPropertyDTO[]> = await this.Get<InstallationNoPropertyDTO[]>(AppUrls.Server.Api.Installation.getInstallationDevicesWithNoProperty);

        if (apiResult.wasSuccessful) {
            runInAction(() => {
                this.setDevicesWithNoProperty(apiResult.payload);
            });
        } else {
            this.setIsErrored(true);
            this.setErrors("Failed to store the installation.  Please try again later");
        }

        return apiResult;
    }

    private getValueFromUnitBehaviour = (item: UnitBehaviourItemModel): number => {
        let retVal: number = 0;

        //item.type; 0 = H, 1 = FB, 2 = P1, 3 = P2
        //item.units; 0 = mm, 1 = percentage,
        //item.value; the actual value

        const itemValue: number = Number(item.value);

        // 1 = percentage, 0 = mm
        if (item.units === 1) {
            // percentage
            let percentValue: number = itemValue / 100;
            switch (item.type) {
                case 3: {
                    // p2
                    retVal = this.model.p2 + this.model.p2 * percentValue;
                    break;
                }
                case 2: {
                    // p1
                    retVal = this.model.p1 + this.model.p1 * percentValue;
                    break;
                }
                case 1: {
                    // Freeboard
                    retVal = this.getFreeboardValue + this.getFreeboardValue * percentValue;
                    break;
                }
                case 0: // height
                default: {
                    retVal = this.getWorkingHeightValue + this.getWorkingHeightValue * percentValue;
                    break;
                }
            }
        } else {
            switch (item.type) {
                case 3: {
                    // p2
                    retVal = this.model.p2 + itemValue;
                    break;
                }
                case 2: {
                    // p1
                    retVal = this.model.p1 + itemValue;
                    break;
                }
                case 1: {
                    // Freeboard
                    retVal = this.getFreeboardValue + itemValue;
                    break;
                }
                case 0: // height
                default: {
                    retVal = this.getWorkingHeightValue + itemValue;
                    break;
                }
            }
        }

        return Math.floor(retVal);
    };

    @action
    public async updateCommissionDate(commissionDate: string | null): Promise<ApiResult<string>> {
        const request: SanitiseDataByCommissionDateRequest = {
            deviceStatusDataId: this.model.deviceStatusItemId!,
            commissionDate: commissionDate,
        };

        const apiResult: ApiResult<string> = await this.Post<string>(AppUrls.Server.Api.Installation.sanitiseDataByCommissionDate, request);

        if (apiResult.wasSuccessful) {
            runInAction(() => {});
        }

        return apiResult;
    }

    @action
    public async upsertInstallation(): Promise<ApiResult<InstallationAndRelatedForEditDTO>> {
        const request: InstallationAddEditDTO = this.model.toDto();
        request.unitBehaviour = undefined;

        if (this.localUnitBehaviour !== undefined) {
            // Value Between
            // a1 = Warning level
            // a2 = Alarm level

            // Work out the item values
            // TODO CMR.  Should probably be searching by Command Index not hardcoding
            let item0Model: UnitBehaviourItemModel = this.localUnitBehaviour.vmItems[0].model;
            let item1Model: UnitBehaviourItemModel = this.localUnitBehaviour.vmItems[1].model;
            let item2Model: UnitBehaviourItemModel = this.localUnitBehaviour.vmItems[2].model;

            // let zeroValue: number = this.getValueFromUnitBehaviour(item0Model);
            let oneValue: number = this.getValueFromUnitBehaviour(item1Model);
            let twoValue: number = this.getValueFromUnitBehaviour(item2Model);

            // How to get three numbers into 2.
            // The top row type, value and units is ignored.  19/01/2022
            const a1: number = Math.floor(this.model.install_Height - oneValue);
            const a2: number = Math.floor(this.model.install_Height - twoValue);

            // Reading Values
            const s0: number = item0Model.reading;
            const s1: number = item1Model.reading;
            const s2: number = item2Model.reading;

            // upload Values
            const u0: number = s0 * item0Model.frequency;
            const u1: number = s1 * item1Model.frequency;
            const u2: number = s2 * item2Model.frequency;

            //e.g. G1=4,G2=6,G3=8,G4=10,G5=12,P0=60,S0=10,U0=20
            let intitialCommandValue = "A1=" + a1.toString();
            intitialCommandValue += ",A2=" + a2.toString();
            intitialCommandValue += ",S0=" + s0.toString();
            intitialCommandValue += ",S1=" + s1.toString();
            intitialCommandValue += ",S2=" + s2.toString();
            intitialCommandValue += ",U0=" + u0.toString();
            intitialCommandValue += ",U1=" + u1.toString();
            intitialCommandValue += ",U2=" + u2.toString();

            request.initialCommand = intitialCommandValue;
            request.unitBehaviour = this.localUnitBehaviour.model.toStoreDto();

            let ub: DeviceUnitBehaviourModel | undefined = this.model.unitBehaviour;

            if (ub != undefined) {
                let ubitems = ub.toStoreDto();
                if (ubitems.items.length > 0) {
                    request.unitBehaviour.items = ubitems.items;
                }
            }
        }

        const apiResult: ApiResult<InstallationAndRelatedForEditDTO> = await this.Post<InstallationAndRelatedForEditDTO>(
            AppUrls.Server.Api.Installation.upsertInstallation,
            request,
        );

        if (apiResult.wasSuccessful) {
            runInAction(() => {
                this.model.fromInstallAndRelatedDto(apiResult.payload);
                this.setError("unitBehaviour", "");
                this.setValid("unitBehaviour", false);
            });
        }

        return apiResult;
    }

    @action
    createNewUnitBehaviour = () => {
        this.localUnitBehaviour = new DeviceUnitBehaviourViewModel();
        this.model.unitBehaviour = new DeviceUnitBehaviourModel();
        this.model.unitBehaviour.InitItems();
        this.adminUnitBehaviourId = "0";
        this.hasAUnitBehaviourObject = true;
    };

    @action
    setContractors = (payload: ContractorModelDTO[]) => {
        this.contractors.replace(
            payload.map((p) => {
                const item: ContractorModel = new ContractorModel();
                item.fromDto(p);
                return item;
            }),
        );
    };

    @action
    setProperties = (payload: ProjectPropertyListItemDTO[]) => {
        this.properties.replace(
            payload.map((p) => {
                const item: ProjectPropertyListItemModel = new ProjectPropertyListItemModel();
                item.fromDto(p);
                return item;
            }),
        );
    };

    @action
    setTestGrade = (payload: TestGradeDTO[]) => {
        this.testGrade.replace(payload);
    };

    @action
    setUnitFirmware = (payload: UnitFirmwareDTO[]) => {
        this.unitFirmware.replace(payload);
    };

    @action
    setUnitStatus = (payload: DeviceUnitStatusDTO[]) => {
        this.unitStatus.replace(payload);
    };

    @action
    setUnitVersion = (payload: UnitVersionDTO[]) => {
        this.unitVersion.replace(payload);
    };

    @action
    setDevicesNoProperty = (payload: InstallationNoPropertyDTO[]) => {
        this.devicesNoProperty.replace(payload);
    };

    @action
    setAdminUnitBehaviours = (payload: AdminUnitBehaviourTabModelDTO[]) => {
        this.adminUnitBehaviours.replace(payload);
    };

    @action
    clearModel() {
        this.firstTime = true;
        this.deleteUnitBehaviour();
        this.model.clear();
    }

    @action
    public async okToUseSIMID(): Promise<ApiResult<boolean>> {
        const request = {
            id: this.model.id,
            simId: this.model.simId,
        };
        const apiResult: ApiResult<boolean> = await this.Post<boolean>(AppUrls.Server.Api.Installation.okToUseSIMID, request);

        if (apiResult.wasSuccessful) {
            runInAction(() => {
                if (apiResult.payload === false) {
                    this.setValid("simId", false);
                    this.setError("simId", "The Sim Id is already being used");
                }
            });
        }

        return apiResult;
    }

    @action
    public loadFromNoPropertyDevice(device: InstallationNoPropertyDTO) {
        this.setValue("id", device.id);
        this.setValue("deviceId", device.deviceId);

        if (device.name.length > 0) {
            this.setValue("name", device.name);
        }
        //this.setValue("lastUpdated", device.lastUpdated);

        if (isNullOrUndefined(device.deviceStatusDataId) === false && device.deviceStatusDataId! > 0) {
            this.setValue("deviceStatusItemId", device.deviceStatusDataId);
            this.setValue("emailAddresses", device.emailAddresses);
            this.setValue("install_Height", device.install_Height);
            this.setValue("mobileNumbers", device.mobileNumbers);
            this.setValue("side1_Angle", device.side1_Angle);
            this.setValue("side1_Height", device.side1_Height);
            this.setValue("side1_Length", device.side1_Length);
            this.setValue("side2_Angle", device.side2_Angle);
            this.setValue("side2_Height", device.side2_Height);
            this.setValue("side2_Length", device.side2_Length);

            this.setValue("side1_Angle2", device.side1_Angle2);
            this.setValue("side1_Length2", device.side1_Length2);
            this.setValue("side2_Angle2", device.side2_Angle2);
            this.setValue("side2_Length2", device.side2_Length2);
            this.setValue("side2_Length2", device.baseWidth);
            this.setValue("sideType", device.sideType);

            this.setValue("gutterSensId", device.gutterSensId);
            this.setValue("mobileNumbers", device.mobileNumbers);
            this.setValue("emailAddresses", device.emailAddresses);
            this.setValue("maxHeight", device.maxHeight);
            this.setValue("p1", device.p1);
            this.setValue("p1", device.p2);
            this.setValue("siphonicBrand", device.siphonicBrand);
            this.setValue("baseMeasurement", device.baseMeasurement);
            this.setValue("roofType", device.roofType);
            this.setValue("drainageType", device.drainageType);
        } else {
            this.setValue("emailAddresses", "");
            this.setValue("install_Height", 0);
            this.setValue("mobileNumbers", "");
            this.setValue("side1_Angle", 0);
            this.setValue("side1_Height", 0);
            this.setValue("side1_Length", 0);
            this.setValue("side2_Angle", 0);
            this.setValue("side2_Height", 0);
            this.setValue("side2_Length", 0);

            this.setValue("side1_Angle2", 0);
            this.setValue("side1_Length2", 0);
            this.setValue("side2_Angle2", 0);
            this.setValue("side2_Length2", 0);
            this.setValue("side2_Length2", 0);
            this.setValue("sideType", SideType.ThreeSideSym);

            this.setValue("gutterSensId", 0);
            this.setValue("deviceStatusItemId", 0);
            this.setValue("mobileNumbers", "");
            this.setValue("emailAddresses", "");
            this.setValue("maxHeight", 0);
            this.setValue("p1", 0);
            this.setValue("p1", 0);
            this.setValue("siphonicBrand", null);
            this.setValue("baseMeasurement", 0);
            this.setValue("roofType", 1);
            this.setValue("drainageType", 1);
        }
    }

    @action public setFirstTime = (value: boolean) => {
        this.firstTime = value;
    };

    public islocalModelValid = (): boolean => {
        let retVal: boolean = this.isModelValid();

        if (this.localUnitBehaviour !== undefined) {
            let isValid: boolean = this.localUnitBehaviour.isModelAndChildrenValid();

            if (isValid === true) {
                let errorMessage: string = this.isUnitBehaviourValid;
                isValid = errorMessage === "";

                this.setError("unitBehaviour", errorMessage);
                this.setValid("unitBehaviour", false);
            }

            if (isValid === false) {
                retVal = false;
            }
        } else {
            //You need to this two properties after validation
            this.setError("unitBehaviour", "Unit Behaviour is required");
            this.setValid("unitBehaviour", false);
        }

        return retVal;
    };

    @computed
    public get getMapBounds(): LatLngBounds {
        let center: LatLngExpression = {
            lat: this.model.latitude === undefined ? 0 : this.model.latitude!,
            lng: this.model.longitude === undefined ? 0 : this.model.longitude!,
        };

        let ne: LatLngExpression = {
            lat: center.lat - 0.0001,
            lng: center.lng + 0.0001,
        };

        let sw: LatLngExpression = {
            lat: center.lat + 0.0001,
            lng: center.lng - 0.0001,
        };

        let retVal: LatLngBounds = DEFAULTBOUNDS;

        let bounds: LatLngBounds = new LatLngBounds(sw, ne);

        const isValid: boolean = bounds.isValid();

        if (isValid) {
            retVal = bounds;
        }

        return retVal;
    }

    @action
    public onPositionChange = (id: string, lat: number, lng: number): any => {
        this.setValue("latitude", lat);
        this.setValue("longitude", lng);
    };

    @computed
    public get getMarkers(): IDraggableMarker[] {
        let retVal: IDraggableMarker[] = [];

        if ((isNullOrUndefined(this.model.latitude) === false || this.model.latitude === 0) && (isNullOrUndefined(this.model.longitude) === false || this.model.longitude === 0)) {
            let marker: IDraggableMarker = {
                id: this.model.id === undefined ? "tempPin" : this.model.id.toString(),
                position: [this.model.latitude!, this.model.longitude!],
                icon: getDefaultPropertyMarkerIcon(),
                draggable: true,
                onPositionChange: this.onPositionChange,
            };
            retVal.push(marker);
        }

        return retVal;
    }
}
