import MomentUtils from "@date-io/moment";
import { Box, MenuItem, Select, Typography } from "@material-ui/core";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { useObserver } from "mobx-react-lite";
import moment, { Moment } from "moment";
import { useState, useEffect, useContext } from "react";
import styled from "styled-components";

import { DeviceImagePanel, SortImagePanel } from "./TabImages.style";
import { DaysOption, InstallationCommand, InstallationImageDto } from "Custom/Models/Domain/Installations";
import { InstallationImageViewModel } from "./InstallationImageViewModel";

import { generateID, isNullOrUndefined, isValidDate, isValidDateString } from "Custom/Utils/utils";
import { DataCell, DeviceDataRow } from "./TabDeviceData.style";
import { Loader } from "Core/Components";
import { CollapsiblePanelViewModel } from "Custom/Components/Panels/CollapsiblePanelviewModel";
import { CollapsibleInstallationImagePanelViewModel } from "Custom/Components/Panels/CollapsibleInstallationImagePanelViewModel";
import { InstallationImagePanelContainer } from "Custom/Components/Panels/InstallationImagePanelContainer";
import { ProjectButton } from "../CustomComponents";
import { Stores, StoresContext } from "Custom/Stores";
import * as RouterUtil from "Custom/Utils/routing";
import { InstallationCommandHistoryDTO } from "Custom/Models/Domain/Installations/InstallationCommandHistory";
import { ApiResult } from "Core/Models";
import { SuccessBtn } from "Custom/StylesAppSpecific/Controls/SuccessBtn";
import { AlertSwitchOption } from "./DeviceSection.style";
interface TabImagesProps {
    deviceId: number;
    allowImageRequest: boolean;
    simId: string;
    minDate: Moment;
    onRequestImageCommand: ((commandHistory: InstallationCommandHistoryDTO[], commands: InstallationCommand[]) => void) | undefined;
}

export function TabImages(props: TabImagesProps) {
    const storesContext = useContext<Stores>(StoresContext);
    const { allowImageRequest, deviceId, onRequestImageCommand, simId } = props;

    const [viewModel] = useState(() => InstallationImageViewModel.Instance);
    const [startDate, setStartDate] = useState(moment.utc().startOf("day").add(-7, "days"));
    const [endDate, setEndDate] = useState(moment.utc().add(1, "days").startOf("day").add(-1, "minute"));
    const [counter, setCount] = useState(0);
    const [daysToGraph, setDaysToGraph] = useState<number>(7);

    useEffect(() => {
        const { deviceId } = props;
        const { deviceImages, IsLoading, load, imagesDateStart, imagesDateEnd, sortImageDirection } = viewModel;

        setStartDate(imagesDateStart);
        setEndDate(imagesDateEnd);

        if ((isNullOrUndefined(deviceImages) === true || deviceImages.length === 0) && IsLoading === false && deviceId > 0) {
            let promise = load(deviceId);

            promise.then(() => {
                let a = counter + 1;
                setCount(a);
            });
        }

        viewModel.onRequestImageCommand = onRequestImageCommand;

        return () => {
            // Clean up after yourself
        };
    }, []);

    useEffect(() => {
        const { deviceId } = props;
        const { deviceImages, IsLoading, load } = viewModel;

        if ((deviceImages === null || deviceImages === undefined || deviceImages.length === 0) && IsLoading === false && deviceId > 0) {
            load(deviceId);
        }
    }, [props.deviceId]);

    const updateStart = (date: any, value: string | null | undefined) => {
        const { minDate } = props;
        let mom: Moment = moment(value, "DD/MM/YYYY");
        if (value !== null && value !== undefined) {
            if (isValidDateString(value!) === true && value!.length === 10) {
                if (mom < minDate) {
                    mom = minDate;
                }
                viewModel.setStartDate(mom);
                setStartDate(mom);
            }
        }
    };

    const updateEnd = (date: any, value: string | null | undefined) => {
        const { minDate } = props;
        let mom: Moment = moment(value, "DD/MM/YYYY");
        if (value !== null && value !== undefined) {
            if (isValidDateString(value!) === true && value!.length === 10) {
                if (mom < minDate) {
                    mom = minDate;
                }
                viewModel.setEndDate(mom);
                setEndDate(mom);
            }
        }
    };

    const handleDaysToGraphDropDownOnChange = async (event: any) => {
        const { minDate } = props;
        const days: number = parseInt(event.target.value);
        setDaysToGraph(days);

        if (days > 0) {
            let mom: Moment = moment().add(days * -1, "days");
            let now: Moment = moment.utc().add(1, "days").startOf("day").add(-1, "minute");

            if (mom < minDate) {
                mom = minDate;
            }

            viewModel.setStartDate(mom);
            viewModel.setEndDate(now);
            setStartDate(mom);
            setEndDate(now);

            let promise: Promise<ApiResult<InstallationImageDto[] | undefined>> = viewModel.load(deviceId);

            promise.then(() => {
                let a = counter + 1;
                setCount(a);
            });
        }
    };

    const disableDates: boolean = daysToGraph > 0;

    const disableSearch: boolean = disableDates && viewModel.areDatesValid;

    const searchByCustomDate = (event: any): void => {
        viewModel.load(props.deviceId);
    };

    const requestImage = () => {
        if (allowImageRequest === true) {
            viewModel.requestImage(deviceId, simId);
        }
    };

    const setSortDirection = (newDirection: boolean) => {
        viewModel.setSortImageDirection(newDirection);
    };

    const showRequestImage: boolean = RouterUtil.requireAdminLogin(storesContext) && allowImageRequest === true;

    const renderPage = () => {
        const { minDate } = props;
        if (viewModel.IsLoading) return <Loader />;

        let btnAttributeClass: string = viewModel.sortImageDirection == false ? "notselected" : "selected";

        return (
            <DeviceImagePanel>
                <Box className="guttersens-admin-deviceimages">
                    <DeviceDataRow>
                        <Typography style={{ fontSize: "16px" }}>Device Images</Typography>
                    </DeviceDataRow>
                    <DeviceDataRow>
                        <DataCell style={{ marginLeft: "-15px" }}>
                            <Typography style={{ fontSize: "14px" }} className="select-title">
                                Show:
                            </Typography>
                            <Box maxWidth="140px" width="100%" className="selectBox">
                                <Select
                                    id="role-select"
                                    value={daysToGraph}
                                    onChange={handleDaysToGraphDropDownOnChange}
                                    MenuProps={{
                                        getContentAnchorEl: null,
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left",
                                        },
                                    }}
                                    fullWidth
                                >
                                    {viewModel.daysOptions.map((item: DaysOption, index: number) => {
                                        return (
                                            <MenuItem key={generateID()} value={item.value}>
                                                {item.label}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </Box>
                        </DataCell>
                        <DataCell>
                            <Typography style={{ fontSize: "14px", width: "100px" }}>Show from:</Typography>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    placeholder="01/01/2021"
                                    label={""}
                                    value={startDate}
                                    disabled={disableDates}
                                    mask={"__/__/____"}
                                    format="DD/MM/YYYY"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={updateStart}
                                    minDate={minDate}
                                />
                            </MuiPickersUtilsProvider>
                        </DataCell>
                        <DataCell>
                            <Typography style={{ fontSize: "14px", width: "75px" }}>Show to:</Typography>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    placeholder="01/01/2021"
                                    label={""}
                                    value={endDate}
                                    disabled={disableDates}
                                    mask={"__/__/____"}
                                    format="DD/MM/YYYY"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={updateEnd}
                                    minDate={minDate}
                                />
                            </MuiPickersUtilsProvider>
                        </DataCell>
                        <DataCell>
                            <SuccessBtn
                                placeholder="Update graph using custom dates"
                                id="customsearch"
                                variant="contained"
                                color="primary"
                                type="button"
                                disabled={disableSearch}
                                onClick={searchByCustomDate}
                            >
                                Search
                            </SuccessBtn>
                        </DataCell>
                        <DataCell>
                            {viewModel.IsLoading && <Box className={"downloadcsv "}>Processing</Box>}
                            {!viewModel.IsLoading && showRequestImage === true && (
                                <ProjectButton
                                    icon={() => <div>&#x21e9;</div>}
                                    style={{
                                        borderRadius: "5px",
                                    }}
                                    text="Request Image"
                                    onClick={() => requestImage()}
                                />
                            )}
                        </DataCell>
                    </DeviceDataRow>
                    {viewModel.hasImages && (
                        <DeviceDataRow>
                            <SortImagePanel>
                                <div className="slider-text">Reverse Images:</div>
                                <AlertSwitchOption id={"showlines-select-"} className={btnAttributeClass} onClick={() => setSortDirection(!viewModel.getSortDirection)} />
                            </SortImagePanel>
                        </DeviceDataRow>
                    )}
                    {!viewModel.hasImages && <Typography variant="caption">There are currently no images to show.</Typography>}
                    <CollapsibleContainerBox>
                        {viewModel.collapsiblePanelViewModels.map((collapsiblePanelViewModel, index) => (
                            <Box key={collapsiblePanelViewModel.id}>
                                <Box flex={1}>
                                    <InstallationImagePanelContainer viewModel={collapsiblePanelViewModel as CollapsibleInstallationImagePanelViewModel} />
                                </Box>
                            </Box>
                        ))}
                    </CollapsibleContainerBox>
                </Box>
            </DeviceImagePanel>
        );
    };

    return useObserver(() => {
        return <>{renderPage()}</>;
    });
}

const CollapsibleContainerBox = styled(Box)`
    padding: 15px 15px 5px 15px;
`;
