import { KeyValuePair } from "Core/Models/KeyValuePair";

export enum ContactType {
    //Unknown = 0,
    Client = 100,
    Contractor = 200,
}

export class ContactTypeHelpers {
    public static getText = (contactType: ContactType) => {
        switch (contactType) {
            case ContactType.Contractor: {
                return "Contractor";
            }
            default: {
                return "End User";
            }
        }
    };

    //public static getOptions = (ignoreUnknown: boolean = false) => {
    public static getKVPOptions = (): KeyValuePair[] => {
        const options: KeyValuePair[] = [];

        for (const key in ContactType) {
            const keyAsInt = parseInt(key);

            if (isNaN(keyAsInt) === false) {
                const item = keyAsInt as ContactType;

                //if (/* ignoreUnknown === false ||  */ item !== ContactType.Unknown) {
                options.push({
                    value: keyAsInt.toString(),
                    key: ContactTypeHelpers.getText(item),
                });
                //}
            }
        }

        return options;
    };

    public static getOptions = () => {
        const options: {
            key: string;
            value: string;
        }[] = [];

        for (const key in ContactType) {
            const keyAsInt = parseInt(key);

            if (isNaN(keyAsInt) === false) {
                const item = keyAsInt as ContactType;

                options.push({
                    key: ContactTypeHelpers.getText(item),
                    value: keyAsInt.toString(),
                });
            }
        }

        return options;
    };
}
