import styled from "styled-components";
import { BaseModalDialogPaper } from "./BaseModal.styles";

export const DeleteModalDialogPaper = styled(BaseModalDialogPaper)`
    box-shadow: 0px 3px 6px #000000c2;
    border-radius: 18px;
    min-width: 420px;
    color: white;

    .MuiDialogContent-root {
        p {
            padding-bottom: 40px;
            font-size: 14px;
        }
    }
`;
