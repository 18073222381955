import { ViewModelBase } from "Core/ViewModels/ViewModelBase";
import { FieldType } from "Core/Utils/Utils";
import { Server } from "Custom/Globals/AppUrls";
import { runInAction, action, observable, computed } from "mobx";
import { StoresInstance } from "Custom/Stores";
import { AverageSensorResult, AverageSensorValue, AverageSensorValueDTO, DaysOption } from "Custom/Models/Domain/Installations";
import { ApiResult } from "Core/Models";
import { FileDownloadResponseDTO } from "./FileDownloadResponseDTO";
import moment, { Moment } from "moment";

const domainStores = StoresInstance.domain;
//extend viewmodel base and passing your model as the generic type
export class InstallationAverageViewModel extends ViewModelBase<AverageSensorResult> {
    //Singleton instance of class
    private static _instance: InstallationAverageViewModel;
    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    @computed
    public get averageReadings() {
        let retVal: AverageSensorValue[] = [];
        if (this.model != undefined && this.model !== null) {
            retVal = this.model.averageSensorValues.length > 0 ? this.model.averageSensorValues.slice(0) : [];
        }

        return retVal;
    }

    @observable public IsDownloading: boolean = false;

    @observable public dateStart: Moment = moment.utc().startOf("day").add(-1, "days");

    @observable public dateEnd: Moment = moment.utc().startOf("day");

    @observable public daysToGraph = 1;

    @action
    public setStartDate = (start: Moment): void => {
        this.dateStart = start;
    };

    @action
    public setEndDate = (end: Moment): void => {
        this.dateEnd = end;
    };

    @action
    setDaysToGraph(value: number) {
        this.daysToGraph = value;
    }

    @computed
    public get getDaysToGraph(): number {
        return this.daysToGraph;
    }

    @observable public daysOptions: DaysOption[] = [
        { label: "24 Hours", value: 1 },
        { label: "Week", value: 7 },
        { label: "Month", value: moment().daysInMonth() },
        { label: "Last 12 Months", value: moment().isLeapYear() ? 366 : 365 },
        { label: "Custom", value: -1 },
    ];

    constructor() {
        //Pass in a new instance of your model
        //By passing in true as the second parameter, we make this model undoable which means we can use save and reset options on the model
        //If you make a change to the model you need to persist it with a saveModel() call
        //If you make changes to your model you can revert it back by calling resetModel()
        super(new AverageSensorResult(), false);
        //EN - Haven't figured out how to make this call work from the base model yet
        //This is only needed if you make use of the validation decorators
        this.setDecorators(AverageSensorResult);

        this.dateStart = moment.utc().startOf("day").add(-1, "days");
        this.dateEnd = moment.utc().startOf("day");
    }

    @action
    public reset = (): void => {
        this.dateStart = moment.utc().startOf("day").add(-1, "days");
        this.dateEnd = moment.utc().startOf("day");
        this.daysToGraph = 1;
    };

    //This must be present in your viewmodel. Just return true if you dont need to validate anything.
    //keyof BlankModel & string lets you add type checking to the fieldName
    //I am using the validator package to make checking easier https://www.npmjs.com/package/validator
    public isFieldValid(fieldName: keyof FieldType<AverageSensorResult>): boolean {
        const { isValid, errorMessage } = this.validateDecorators(fieldName);

        //You need to this two properties after validation
        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    @action
    public load = async (id: number): Promise<void> => {
        try {
            this.setIsLoading(true);

            if (this.dateStart > this.dateEnd) {
                // Swap the dates
                const temp: Moment = this.dateStart;
                this.dateStart = this.dateEnd;
                this.dateEnd = temp;
            }

            const request = {
                id,
                startDate: this.dateStart.toISOString(),
                endDate: this.dateEnd.toISOString(),
            };
            const apiResult: any = await this.Post<AverageSensorValueDTO>(Server.Api.Installation.getAverageDayReadingsForInstallationForPeriod, request);

            if (apiResult.wasSuccessful) {
                runInAction(() => {
                    this.setIsLoading(false);
                    this.model.fromDtoArr(apiResult.payload);
                });
            } else {
                // Error. What to do?
                this.setIsLoading(false);
                this.setIsErrored(true);
                this.history.push("/");
            }
        } catch (exception) {
            this.setIsLoading(false);
            // Error. What to do?
            console.log(exception);
            this.setIsErrored(true);
            this.history.push("/");
        } finally {
            this.setIsLoading(false);
        }
    };

    @action
    public downloadInstallationMinMaxCSV = async (id: number): Promise<ApiResult<FileDownloadResponseDTO>> => {
        let apiResult: ApiResult<FileDownloadResponseDTO> = {
            wasSuccessful: false,
            errors: [],
            headers: "",
            payload: { fileName: "", stringFile: "", contentType: "", byteFile: undefined },
        };

        try {
            if (this.IsDownloading === false && this.IsLoading === false) {
                this.IsDownloading = true;

                if (this.dateStart > this.dateEnd) {
                    // Swap the dates
                    const temp: Moment = this.dateStart;
                    this.dateStart = this.dateEnd;
                    this.dateEnd = temp;
                }

                const request = {
                    id,
                    startDate: this.dateStart.toISOString(),
                    endDate: this.dateEnd.toISOString(),
                };

                apiResult = await this.Post<any>(Server.Api.Installation.downloadMinMaxDataAsCsv, request);

                if (apiResult.wasSuccessful) {
                    const file: string = apiResult.payload.stringFile;
                    const type: string = apiResult.payload.contentType;
                    const fileName: string = apiResult.payload.fileName;

                    const url = window.URL.createObjectURL(new Blob([file], { type }));
                    const link = document.createElement("a");

                    link.href = url;
                    link.setAttribute("download", fileName);

                    document.body.appendChild(link);

                    link.click();
                }
            }
        } catch (exception) {
        } finally {
            runInAction(() => {
                this.IsDownloading = false;
            });
        }

        return apiResult;
    };
}
