import { computed, observable } from "mobx";
import { ModelBase } from "Core/Models/ModelBase";
import { IsNotEmpty } from "class-validator";
import { ConditionItemModel, ConditionItemModelDTO } from "./ConditionItemModel";
import { UnitListItemModelDTO } from "Custom/Views/Installations/InstallationList/UnitListItemModel";

//Base class expects the model type and the DTO model type
//If you are not using a DTO just pass in undefined as the DTO
export class ConditionAddEditDetailModel extends ModelBase<ConditionAddEditDetailModel, ConditionAddEditDetailModelDTO> {
    public id: string | null = null;
    public rowVersion: string | null = null;

    @observable
    @IsNotEmpty({ message: "You must give the condition set a name!!" })
    public name: string = "";

    public isDeleted: boolean = false;
    public createdBy: string | null = null;
    public createdDate: string | null = null;
    @observable public items: ConditionItemModel[] = [];
    @observable public unitListItems: UnitListItemModelDTO[] = [];

    // Used for the device records to link to the admin template.
    @observable public conditionSetId: string | null = null;

    @computed public get getConditionSetId(): string | null {
        return this.conditionSetId;
    }

    //public drainageType: DrainageType;

    @computed public get getItems() {
        return this.items.slice();
    }

    @computed public get getUnits(): UnitListItemModelDTO[] {
        return this.unitListItems.slice();
    }

    //fromDto is required but you can leave it blank
    fromDto(model: ConditionAddEditDetailModelDTO): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else if (key === "items") {
                    this.items = [];
                    for (let i: number = 0; i < model[key].length; i++) {
                        let newItem = new ConditionItemModel();
                        newItem.fromDto(model[key][i]);
                        this.items.push(newItem);
                    }
                } else {
                    this[key] = model[key];
                }
            }
        }

        this.conditionSetId = model.condtionSetId;
    }

    //toDto is required but you can leave it blank
    toDto(model: ConditionAddEditDetailModel): void {}
}

export type ConditionAddEditDetailModelDTO = {
    id: string | null;
    name: string;
    rowVersion: string | null;
    isDeleted: boolean;
    createdBy: string | null;
    createdDate: string | null;
    condtionSetId: string | null;
    //drainageType: DrainageType;
    items: ConditionItemModelDTO[];

    unitListItems: UnitListItemModelDTO[];
};
