import styled from "styled-components";
import { Box } from "@material-ui/core";
import { TabCommonBox } from "./TabCommonStyle.style";

export const DeviceDataBox: any = styled(TabCommonBox)`
    /*     padding: 5px 15px;
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    width: 100%; */
`;

export const DeviceDataGraphBox: any = styled(Box)`
    display: flex;
    flex: 1 0 auto;
    border: "1px solid #F7F7F7";
    min-width: 100%;
    width: 100%;
    padding-left: 15px;
    padding-top: 15px;
`;

export const DeviceDataRow: any = styled(Box)`
    display: flex;
    justify-content: space-between;
    padding: 0 15px;
    padding-top: 15px;
    width: 100%;
    min-width: 320px;
`;

export const DataCell: any = styled(Box)`
    display: flex;
    align-items: center;
    flex: 1 0 auto;
    flex-direction: row;
    padding: 0 15px;

    .datepicker,
    .selectBox {
        margin-top: -3px;
        padding-left: 10px;
        max-width: 200px;
    }

    .multiselectbox {
        margin-top: -3px;
        padding-left: 10px;
        max-width: 180px;
    }

    .guttersens-checkbox {
        margin-top: -7px;
        padding-left: 10px;
    }

    .selectBox {
        max-width: 150px;
    }

    .downloadcsv {
        margin-top: -1px;
        color: ${(props) => props.theme.app.colors.blue.light};
        cursor: default;
        font-size: 18px;

        i {
            padding-right: 10px;
        }
    }

    .downloadcsv:hover {
        cursor: pointer;
    }

    .disabledbutton {
        pointer-events: none;
        opacity: 0.4;
    }

    .Mui-selected {
        background-color: ${(props) => props.theme.app.colors.blue.roofcare};
    }
`;
