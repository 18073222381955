import { Box } from "@material-ui/core";
import React from "react";
import styled from "styled-components";

export const UnitFilterSection: any = styled(Box)`
    display: flex;
    flex: 1 0 0;
    flex-direction: row;
    padding: 0px 0px 0px 30px;
    width: 100%;
    min-width: 320px;

    .select-title {
        padding-top: 3px;
        padding-right: 15px;
    }
    .multiselectbox {
        max-width: 170px;
        min-width: 170px;
    }
`;
