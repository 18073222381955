import React, { useContext } from "react";
import { Stores, StoresContext } from "../../Stores";
import { PrivateRoute } from "../../../Core/Utils/React";
import * as RouterUtil from "Custom/Utils/routing";
import { AppUrls } from "Custom/Globals";
//import { Redirect } from "react-router";
import { UserListPage } from "./UserListPage";
import { UserDetailsPage } from "./UserDetailsPage";

export const UserRoutes: React.FC = () => {
    const store = useContext<Stores>(StoresContext);
    return (
        <>
            <PrivateRoute
                exact
                path={AppUrls.Client.Main.User.Root}
                component={UserListPage}
                isAllowed={() => {
                    return store.domain.AccountStore.IsLoggedIn && RouterUtil.requireAdminLogin(store);
                }}
            />
            <PrivateRoute
                exact
                path={AppUrls.Client.Main.User.UserDetail}
                component={UserDetailsPage}
                isAllowed={() => {
                    return store.domain.AccountStore.IsLoggedIn && RouterUtil.requireAdminLogin(store);
                }}
            />
        </>
    );
};
