/* eslint-disable prettier/prettier */
import { Box, Fade, Link, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { StoresInstance } from "Custom/Stores";

import { LoginViewModel } from "./LoginViewModel";
import { Redirect } from "react-router";
import { useRouter } from "Core/Utils";
import { CSSGrid } from "Core/Components/Styles";

import { PaperAccount, AccountImageBox } from "Custom/StylesAppSpecific/AccountStyling";
import { AcceptBtn } from "Custom/StylesAppSpecific/Controls/AcceptBtn";
import { Client } from "Custom/Globals/AppUrls";
import { BoxAccount, LoginForgotPasswordLink, LoginForgotPasswordBox, LogonTitle } from "./LoginPage.style";

import logoUrl from "Custom/Content/RCGlogo.svg";
import { RoofcareInput } from "Custom/StylesAppSpecific/Controls/RoofcareInput";

const domainStores = StoresInstance.domain;

export const LoginPage: React.FunctionComponent = () => {
    const [viewModel] = useState(() => new LoginViewModel());
    const { history, location, match } = useRouter();
    const [errorMessage, setErrorMessage] = useState("");
    const [isLoading, setIsLoading] = useState("");

    useEffect(() => {}, []);

    const doSubmit = async (e: any) => {
        e.preventDefault();
        if (viewModel.isModelValid()) {
            let apiResult = await viewModel.login();
            if (apiResult !== null) {
                if (apiResult.wasSuccessful) {
                    domainStores.AccountStore.getLoginState(apiResult.payload);
                    const { from } = (location.state as any) || { from: { pathname: Client.Root } };
                    if (from) {
                        history.push(from.pathname);
                    }
                } else {
                    if (apiResult && apiResult.errors && apiResult.errors.length > 0) {
                        setErrorMessage("* " + apiResult.errors[0].message);
                    } else {
                        setErrorMessage("* Unknown error has occurred.");
                    }
                }
            }
        }
    };

    const goToForgotPassword = () => {
        history.push(Client.Account.ForgotPassword);
    };

    const renderPage = () => {
        if (domainStores.AccountStore.IsLoggedIn) {
            return <Redirect to={Client.Root} />;
        }

        return (
            <Fade in timeout={1000}>
                <PaperAccount style={{ top: 0, padding: 0, height: "100vh" }}>
                    <BoxAccount>
                        <Box>
                            <LogonTitle>Roofcare portal</LogonTitle>
                        </Box>
                        <form onSubmit={doSubmit}>
                            {errorMessage !== "" && (
                                <Typography style={{ color: "red" }} variant="caption">
                                    {errorMessage}
                                </Typography>
                            )}
                            <CSSGrid columns={"1fr"} gap={"1em"}>
                                <RoofcareInput
                                    color="#fff"
                                    shrink={true}
                                    fieldName="emailAddress"
                                    label="Login:"
                                    type="email"
                                    validateOnBlur={true}
                                    viewModel={viewModel}
                                    inputProps={{ placeholder: "Enter your name here" }}
                                />
                                <RoofcareInput
                                    color="#fff"
                                    shrink={true}
                                    fieldName="password"
                                    label="Password:"
                                    type="password"
                                    viewModel={viewModel}
                                    inputProps={{ placeholder: "Enter your password here" }}
                                />
                                <LoginForgotPasswordBox>
                                    <LoginForgotPasswordLink onClick={() => goToForgotPassword()}>Forgot password?</LoginForgotPasswordLink>
                                </LoginForgotPasswordBox>
                                <AcceptBtn type="submit">{isLoading ? "Please Wait..." : "Login"}</AcceptBtn>
                            </CSSGrid>
                        </form>
                    </BoxAccount>
                </PaperAccount>
            </Fade>
        );
    };

    return renderPage();
};
