import * as MobX from "mobx";
import { ModelBase } from "Core/Models/ModelBase";
import { IsEmail, IsNotEmpty, ValidateIf, MinLength, MaxLength } from "class-validator";
import { generateID } from "Custom/Utils/utils";

import { ContactModel } from "Custom/Components/Contact";
import { ContactModel as ClientContacModel } from "Custom/Models/Domain/Contacts/ContactModel";

//Base class expects the model type and the DTO model type
//If you are not using a DTO just pass in undefined as the DTO
export class NewContactModel extends ModelBase<NewContactModel, NewContactModelDTO> {
    public id: string = "";

    @MobX.observable
    @IsNotEmpty({ message: "First name is required." })
    @MaxLength(50, { message: "Maximum first name length is 50 characters" })
    public firstName: string = "";

    @MobX.observable
    @IsNotEmpty({ message: "Last name is required" })
    @MaxLength(50, { message: "Maximum last name length is 50 characters" })
    public lastName: string = "";

    @MobX.observable
    @IsNotEmpty({ message: "Position is required" })
    @MaxLength(50, { message: "Maximum position length is 50 characters" })
    public position: string = "";

    @MobX.observable
    //@IsNotEmpty({ message: "Email is required if phone is empty" })
    //@IsEmail({ message: "Not a valid email address" })
    @MaxLength(256, { message: "Maximum email length is 256 characters" })
    public email: string | null = "";

    @MobX.observable
    //@IsNotEmpty({ message: "Phone is required if email is empty" })
    @MaxLength(50, { message: "Maximum phone length is 50 characters" })
    //@MinLength(6, { message: "Minimum phone length is 6 characters" })
    public phone: string | null = "";

    @MobX.observable
    //@IsNotEmpty({ message: "Phone is required if email is empty" })
    @MaxLength(50, { message: "Maximum phone length is 50 characters" })
    //@MinLength(6, { message: "Minimum phone length is 6 characters" })
    public phone2: string | null = "";

    @MobX.observable public rowVersion: string = "";
    @MobX.observable public isDeleted: boolean = false;
    @MobX.observable createdDate: string = "";
    @MobX.observable createdBy: string = "";
    @MobX.observable clientId: string = "";

    @MobX.observable generatedTempId: string = generateID();

    //fromDto is required but you can leave it blank
    @MobX.action
    fromDto(model: NewContactModelDTO): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
    }

    @MobX.computed
    public get displayName(): string {
        return this.firstName + " " + this.lastName;
    }

    //toDto is required but you can leave it blank
    toDto(model: NewContactModelDTO): void {
        model = this.getDto();
    }

    public getDto(): NewContactModelDTO {
        const retVal: NewContactModelDTO = {
            firstName: this.firstName,
            lastName: this.lastName,
            position: this.position,
            email: this.email,
            phone: this.phone,
            phone2: this.phone2,
            rowVersion: this.rowVersion,
            isDeleted: this.isDeleted,
            createdDate: this.createdDate,
            createdBy: this.createdBy,
            id: this.id,
            clientId: this.clientId,
        };
        return retVal;
    }

    public isEmailValid = () => {
        let retVal: boolean = true;

        return retVal;
    };

    public isPhoneValid = () => {
        let retVal: boolean = true;

        return retVal;
    };

    @MobX.action
    public copy = (model: NewContactModel) => {
        this.firstName = model.firstName;
        this.lastName = model.lastName;
        this.position = model.position;
        this.email = model.email;
        this.phone = model.phone;
        this.phone2 = model.phone2;
        this.rowVersion = model.rowVersion;
        this.isDeleted = model.isDeleted;
        this.createdDate = model.createdDate;
        this.createdBy = model.createdBy;
        this.id = model.id;
        this.clientId = model.clientId;
    };

    @MobX.action
    public toNewContactModelDTO = (contact: ContactModel) => {
        this.firstName = contact.firstName;
        this.lastName = contact.lastName;
        this.position = contact.position;
        this.email = contact.email;
        this.phone = contact.phone;
        this.phone2 = contact.phone2;
        this.rowVersion = contact.rowVersion;
        this.isDeleted = contact.isDeleted;
        this.createdDate = contact.createdDate;
        this.createdBy = contact.createdBy;
        this.id = contact.id;
        this.clientId = contact.sourceId;
    };

    @MobX.action
    public fromNewContactModelDTO = (contact: NewContactModelDTO) => {
        const retVal = new ClientContacModel();
        retVal.firstName = contact.firstName;
        retVal.lastName = contact.lastName;
        retVal.position = contact.position;
        retVal.email = contact.email;
        retVal.phone = contact.phone;
        retVal.phone2 = contact.phone2;
        retVal.rowVersion = contact.rowVersion;
        retVal.isDeleted = contact.isDeleted;
        retVal.createdDate = contact.createdDate;
        retVal.createdBy = contact.createdBy;
        retVal.id = contact.id;
        retVal.clientId = contact.clientId;

        return retVal;
    };
}

export type NewContactModelDTO = {
    firstName: string;
    lastName: string;
    position: string;
    email: string | null;
    phone: string | null;
    phone2: string | null;
    rowVersion: string;
    isDeleted: boolean;
    createdDate: string;
    createdBy: string;
    id: string;
    clientId: string;
};
