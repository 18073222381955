import { ViewModelBase } from "Core/ViewModels/ViewModelBase";
import { UnitBehaviourItemModel } from "./UnitBehaviourItemModel";
import { action, computed, observable } from "mobx";
import { FieldType } from "Core/Utils/Utils";
import { ConditionType } from "Custom/Models/Domain";

//extend viewmodel base and passing your model as the generic type
export class UnitBehaviourItemViewModel extends ViewModelBase<UnitBehaviourItemModel> {
    //Singleton instance of class
    /*     private static _instance: UnitBehaviourItemViewModel;
    public static get Instance() {
        return this._instance || (this._instance = new this());
    }
 */
    @observable public errorMessage: string = "";

    @computed public get getReadingPlaceHolder(): string {
        if (this.model.commandIndex === 0) {
            return "Range 1-240";
        } else {
            return "Range 5-60";
        }
    }

    @computed public get getMinReading(): number {
        let retVal: number = 5;
        if (this.model.commandIndex === 0) {
            retVal = 1;
        }
        return retVal;
    }
    @computed public get getMaxReading(): number {
        let retVal: number = 60;
        if (this.model.commandIndex === 0) {
            return 240;
        }
        return retVal;
    }

    constructor() {
        //Pass in a new instance of your model
        //By passing in true as the second parameter, we make this model undoable which means we can use save and reset options on the model
        //If you make a change to the model you need to persist it with a saveModel() call
        //If you make changes to your model you can revert it back by calling resetModel()
        super(new UnitBehaviourItemModel(), false);
        //EN - Haven't figured out how to make this call work from the base model yet
        //This is only needed if you make use of the validation decorators
        this.setDecorators(UnitBehaviourItemModel);
    }

    //isValid will check all fields to make sure they are in a valid state.
    public doSubmit = async (e: any) => {
        e.preventDefault();

        if (this.isModelValid()) {
            //Do stuff here
            this.errorMessage = "";
        } else {
            this.errorMessage = "Form is not valid";
        }
    };

    public isModelValidWithSiblings(index: number, UnitBehaviours: UnitBehaviourItemViewModel[]): boolean {
        if (this.model.isDeleted === true) {
            // Bail out if the record is deleted
            return true;
        }

        let retVal: boolean = this.isModelValid();
        const fieldName: keyof FieldType<UnitBehaviourItemModel> = "type";
        let { isValid, errorMessage } = this.validateDecorators(fieldName);
        /*  let models: UnitBehaviourItemModel[] = UnitBehaviours.map((a) => a.model);
        delete models[index];
        models = models.filter((a) => a.isDeleted === false);
        const existingTypes: number[] = models.map((a) => a.type);

        const currentType: number = this.model.type;

        const found: number | undefined = existingTypes.find((a) => a === currentType);

        if (found !== undefined) {
            errorMessage = "Type must be unique";
            isValid = false;
            retVal = false;
        } */

        //You need to this two properties after validation
        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return retVal;
    }

    //This must be present in your viewmodel. Just return true if you dont need to validate anything.
    //keyof UnitBehaviourItemModel & string lets you add type checking to the fieldName
    //I am using the validator package to make checking easier https://www.npmjs.com/package/validator
    public isFieldValid(fieldName: keyof FieldType<UnitBehaviourItemModel>, value: any): boolean {
        let { isValid, errorMessage } = this.validateDecorators(fieldName);

        if (fieldName == "type") {
            // Needs checking in isModelValidWithSiblings since it is dependent on its siblings
        }

        if (fieldName == "value") {
            if (this.model.units === 0) {
                // mm
                if (value > 1000) {
                    errorMessage = "mm value must be less than 1000";
                    isValid = false;
                } else if (value < -1000) {
                    errorMessage = "mm value must be more than -1000";
                    isValid = false;
                }
            } else {
                // %
                if (value > 100) {
                    errorMessage = "% value must be less than 100%";
                    isValid = false;
                } else if (value < -100) {
                    errorMessage = "% value must be more than -100%";
                    isValid = false;
                }
            }

            if (this.model.type === ConditionType.H) {
                if (value > 0) {
                    errorMessage = "Value must be less than 0 for values of H";
                    isValid = false;
                }
            }
        }

        //You need to this two properties after validation
        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
