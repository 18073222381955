import { observable } from "mobx";
import { ModelBase } from "Core/Models/ModelBase";
import { IsNotEmpty } from "class-validator";
import { ContactType } from "Custom/Models/Domain/Contacts/ContactType";

//Base class expects the model type and the DTO model type
//If you are not using a DTO just pass in undefined as the DTO
export class ContactListItem extends ModelBase<ContactListItem, ContactListItemDTO> {
    public sourceId: string | null = null;
    public contactType: ContactType = ContactType.Client;
    public contactName: string = "";
    public firstName: string = "";
    public lastName: string = "";
    public rowVersion: string = "";
    public createdBy: string = "";
    public createdDate: string = "";
    public isDeleted: boolean = false;
    public position: string = "";
    public email: string = "";
    public phone: string = "";
    public phone2: string = "";
    public parentId: string = "";
    public parent: string = "";
    public parentPostcode: string = "";

    //fromDto is required but you can leave it blank
    fromDto(model: ContactListItemDTO): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
    }

    //toDto is required but you can leave it blank
    toDto(model: ContactListItem): void {}
}

export interface ContactListItemDTO {
    sourceId: string | null;
    contactType: ContactType;
    contactName: string;
    position: string;
    email: string;
    phone: string;
    phone2: string;
    parentId: string;
    parent: string;
    parentPostcode: string;

    firstName: string;
    lastName: string;
    rowVersion: string;
    createdBy: string;
    createdDate: string;
    isDeleted: boolean;
}
