import { GenericIdNumberRequest } from "Custom/Models";
import { ViewModelBase } from "Core/ViewModels/ViewModelBase";
import { UnitFirmwareAddEditDetailModel } from "./UnitFirmwareAddEditDetailModel";
import { action, computed, observable, runInAction } from "mobx";
import { FieldType } from "Core/Utils/Utils";
import { ApiResult, ApiResultErrorType } from "Core/Models";
import { Server } from "Custom/Globals/AppUrls";
import { DEBOUNCE_VALUE_MS } from "Custom/Globals/Globals";
import { debounce } from "lodash-es";
import { UnitFirmwareDTO } from "Custom/Views/Units/UnitFirmware";

//extend viewmodel base and passing your model as the generic type
export class UnitFirmwareAddEditDetailViewModel extends ViewModelBase<UnitFirmwareAddEditDetailModel> {
    //Singleton instance of class
    private static _instance: UnitFirmwareAddEditDetailViewModel;
    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    @observable public errorMessage: string = "";

    @observable public pageSize: number = 50;
    @observable public currentPage: number = 0;

    @observable public searchString: string = "";
    @observable public filterSearchString: string = "";

    // By default this will be the first column (by index) and in ascending order.
    @observable public sortColumnId: number = 0;
    @observable public sortDirection: any = "asc";

    constructor() {
        //Pass in a new instance of your model
        //By passing in true as the second parameter, we make this model undoable which means we can use save and reset options on the model
        //If you make a change to the model you need to persist it with a saveModel() call
        //If you make changes to your model you can revert it back by calling resetModel()
        super(new UnitFirmwareAddEditDetailModel(), false);
        //EN - Haven't figured out how to make this call work from the base model yet
        //This is only needed if you make use of the validation decorators
        this.setDecorators(UnitFirmwareAddEditDetailModel);
    }

    @computed get getName() {
        return this.model.name;
    }

    @computed get getOrdinal() {
        return this.model.ordinal;
    }

    @action
    public setSearchString = (value: string) => {
        this.searchString = value;
        this.setFilterSearchString(value);
    };

    private setFilterSearchString = debounce(
        action((value: string) => {
            this.filterSearchString = value;
        }),
        DEBOUNCE_VALUE_MS,
    );

    @action
    public setOrderChange = (columnId: number, direction: any) => {
        this.sortColumnId = columnId;
        this.sortDirection = direction;
    };

    // #endregion Project Sorting

    @action
    public setRowsPerPage(rows: number) {
        this.pageSize = rows;
    }

    @action
    public setPage = (page: number) => {
        this.currentPage = page;
    };

    @action public isModelAndChildrenValid = (): boolean => {
        let retVal: boolean = this.isModelValid();

        if (retVal) {
            //Do stuff here
        } else {
            this.errorMessage = "Form is not valid";
        }
        return retVal;
    };

    //isValid will check all fields to make sure they are in a valid state.
    public doSubmit = async (e: any) => {
        e.preventDefault();

        if (this.isModelValid()) {
            //Do stuff here
        } else {
            this.errorMessage = "Form is not valid";
        }
    };

    //This must be present in your viewmodel. Just return true if you dont need to validate anything.
    //keyof BlankModel & string lets you add type checking to the fieldName
    //I am using the validator package to make checking easier https://www.npmjs.com/package/validator
    public isFieldValid(fieldName: keyof FieldType<UnitFirmwareAddEditDetailModel>, value: any): boolean {
        const { isValid, errorMessage } = this.validateDecorators(fieldName);

        //You need to this two properties after validation
        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    @action
    public upsertUnitFirmware = async (): Promise<ApiResult<UnitFirmwareDTO | undefined>> => {
        let apiResult: ApiResult<UnitFirmwareDTO | undefined> = {
            wasSuccessful: false,
            errors: [],
            headers: "",
            payload: undefined,
        };

        let request: UnitFirmwareDTO = {
            id: this.model.id,
            name: this.model.name,
            rowVersion: this.model.rowVersion,
            isDeleted: this.model.isDeleted,
            createdBy: this.model.createdBy,
            createdDate: this.model.createdDate,
            ordinal: this.model.ordinal,
            author: this.model.author,
            unitCount: this.model.unitCount,
            hasPictureCommand: this.model.hasPictureCommand,
        };

        apiResult = await this.Post<UnitFirmwareDTO>(Server.Api.StaticData.upsertUnitFirmware, request);

        runInAction(() => {
            if (apiResult.wasSuccessful === true) {
                //this.showError = false;
                this.errorMessage = "";

                if (apiResult.payload !== null && apiResult.payload !== undefined) {
                    this.model.fromDto(apiResult.payload!);
                }

                //this.showSuccess = true;
                /*                 setTimeout(() => {
                    runInAction(() => {
                        // this.showSuccess = false;
                    });
                }, 3000); */
            } else {
                // this.isLoading = false;
                //this.showAPIError = true;
                this.errorMessage = "Failed to store the value.";

                apiResult.wasSuccessful = false;
                apiResult.errors = [
                    {
                        type: ApiResultErrorType.Basic,
                        message: "Failed to store the value.",
                    },
                ];

                /*                 setTimeout(() => {
                    runInAction(() => {
                        //this.showAPIError = false;
                    });
                }, 5000); */
            }
        });

        return apiResult;
    };

    @action
    public loadUnitFirmwaresAsync = async (id: number): Promise<ApiResult<UnitFirmwareDTO | undefined>> => {
        let apiResult: ApiResult<UnitFirmwareDTO | undefined> = {
            wasSuccessful: false,
            errors: [],
            headers: "",
            payload: undefined,
        };

        let request: GenericIdNumberRequest = {
            id: id,
        };

        apiResult = await this.Post<UnitFirmwareDTO | undefined>(Server.Api.StaticData.getUnitFirmwareById, request);

        runInAction(() => {
            if (apiResult.wasSuccessful === true) {
                //this.showError = false;
                this.errorMessage = "";

                if (apiResult.payload !== null && apiResult.payload !== undefined) {
                    this.model.fromDto(apiResult.payload!);
                }
                //this.showSuccess = true;
                /*                 setTimeout(() => {
                    runInAction(() => {
                        // this.showSuccess = false;
                    });
                }, 3000); */
            } else {
                // this.isLoading = false;
                //this.showAPIError = true;
                this.errorMessage = "Failed to store the value.";

                apiResult.wasSuccessful = false;
                apiResult.errors = [
                    {
                        type: ApiResultErrorType.Basic,
                        message: "Failed to store the value.",
                    },
                ];

                /*                 setTimeout(() => {
                    runInAction(() => {
                        //this.showAPIError = false;
                    });
                }, 5000); */
            }
        });

        return apiResult;
    };

    /* @action
    public getIsUnitFirmwareValid = async (): Promise<ApiResult<number | undefined>> => {
        let apiResult: ApiResult<number | undefined> = {
            wasSuccessful: false,
            errors: [],
            headers: "",
            payload: undefined,
        };

        let request: UnitFirmwareDTO = {
            id: this.model.id,
            name: this.model.name,
            rowVersion: this.model.rowVersion,
            isDeleted: this.model.isDeleted,
            createdBy: this.model.createdBy,
            createdDate: this.model.createdDate,
            items: this.getUnitFirmwareItemsDTO(),
            unitListItems: [],
        };

        apiResult = await this.Post<number | undefined>(Server.Api.StaticData.getIsUnitFirmwareValid, request);

        runInAction(() => {
            if (apiResult.wasSuccessful === true) {
                this.errorMessage = "";
            } else {
                this.errorMessage = "Invalid condition.";
            }
        });

        return apiResult;
    }; */

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
