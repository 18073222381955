import * as React from "react";
import { ClientRoutes } from "../Views/Clients/ClientRoutes";
import { UserRoutes } from "../Views/User/UserRoutes";
import { ProjectRoutes } from "../Views/Projects/ProjectRoutes";
import { ContractorSetupRoutes } from "../Views/ContractorSetup/ContractorSetupRoutes";
import { InstallationRoutes } from "../Views/Installations/InstallationRoutes";
import { PropertyRoutes } from "../Views/Properties/PropertyRoutes";
import { EstateRoutes } from "Custom/Views/Estate/EstateRoutes";
import { UnitRoutes } from "../Views/Units/UnitRoutes";

export const MainRoutes: React.FC = () => {
    return (
        <>
            {/*             <PrivateRoute
                component={ProjectListPage}
                exact
                isAllowed={() => {
                    return StoresInstance.domain.AccountStore.IsLoggedIn && RouterUtil.requireAnyLoggedIn(StoresInstance);
                }}
                path={AppUrls.Client.Root}
            /> */}

            <ClientRoutes />
            <ProjectRoutes />
            <UserRoutes />
            <ContractorSetupRoutes />

            <PropertyRoutes />

            <InstallationRoutes />
            <UnitRoutes />
            <EstateRoutes />
        </>
    );
};
