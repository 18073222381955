import * as React from "react";
import * as MobXReact from "mobx-react-lite";
import { Stores, StoresContext } from "Custom/Stores";
import * as RouterUtil from "Custom/Utils/routing";
import { ClickableImgCell } from "Custom/Components/Table/ClickableImgCell";
import { DeleteModal } from "Custom/Components/Modal/DeleteModal";
import DeleteLogo from "Custom/Content/deleteIcon.svg";
import DownloadLogo from "Custom/Content/file-download-solid.svg";
import MaterialTable from "material-table";
import { Paper, useTheme } from "@material-ui/core";
import { DocumentListContainer } from "./ProjectDocuments.style";
import { formatDateTime } from "Custom/Utils/format";
import { DocumentCategory, ProjectDocumentModel } from "Custom/Models/Domain";
import { IDocumentViewModel, ProjectDetailsViewModel } from "../ProjectDetailsViewModel";
import { IDocumentModelDTO } from "Custom/Models";

interface IProps {
    viewModel: IDocumentViewModel;
}

export const ProjectDocumentList: React.FC<IProps> = ({ viewModel }) => {
    const theme = useTheme();
    const store = React.useContext<Stores>(StoresContext);
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [allowedToEdit, setAllowedToEdit] = React.useState(false);
    const [deleteOpen, setDeleteOpen] = React.useState(false);
    const [selectedDocument, selectDocument] = React.useState("");

    React.useEffect(() => {
        if (!viewModel.IsLoading) {
            setIsLoaded(true);
        }
    }, [viewModel.IsLoading]);

    React.useEffect(() => {
        const allowEdit: boolean = RouterUtil.requireAdminLogin(store) === true;
        setAllowedToEdit(allowEdit);

        return () => {};
    }, []);

    const handleDocumentClick = (id: string) => {
        viewModel.downloadDocument(id);
    };

    const handleDeleteOpen = () => {
        setDeleteOpen(true);
    };

    const handleDeleteClose = () => {
        setDeleteOpen(false);
        selectDocument("");
    };

    const handleDeleteContact = () => {
        viewModel.deleteDocument(selectedDocument);
        setDeleteOpen(false);
        selectDocument("");
    };

    const handleDeleteDocumentClick = (tempId: string) => {
        selectDocument(tempId);
        handleDeleteOpen();
    };

    return MobXReact.useObserver(() => (
        <>
            <DeleteModal
                open={deleteOpen}
                onClose={handleDeleteClose}
                onDelete={handleDeleteContact}
                title="Delete Document?"
                text="Are you sure you want to delete this document?"
            />
            <DocumentListContainer>
                <MaterialTable
                    columns={[
                        { title: "Filename", field: "filename", width: "auto" },
                        {
                            title: "UploadedDate",
                            field: "createdDate",
                            width: "auto",
                            render: (rowData: IDocumentModelDTO) => {
                                if (rowData !== null && rowData !== undefined) {
                                    return formatDateTime(rowData.createdDate);
                                }
                            },
                        },
                        {
                            title: "Category",
                            field: "documentCategoryId",
                            width: "auto",
                            render: (rowData: IDocumentModelDTO) => {
                                let retVal: string = "";
                                if (rowData !== null && rowData !== undefined) {
                                    if (viewModel.getDocumentCategories !== undefined && viewModel.getDocumentCategories.length > 0) {
                                        const cat: DocumentCategory | undefined = viewModel.getDocumentCategories.find((a) => a.id === rowData.documentCategoryId);
                                        if (cat !== undefined) {
                                            return cat.categoryName;
                                        }
                                    }
                                }
                                return retVal;
                            },
                        },
                        { title: "Uploader", field: "creator", width: "auto" },
                        {
                            title: "",
                            field: "filename",
                            sorting: false,
                            filtering: false,
                            width: 100,
                            render: (rowData: any) => {
                                return (
                                    <ClickableImgCell
                                        className="projectdoc-download "
                                        logo={DownloadLogo}
                                        text={"Download Document"}
                                        onClick={handleDocumentClick}
                                        id={rowData.id!}
                                    />
                                );
                            },
                        },
                        {
                            title: "",
                            field: "filename",
                            sorting: false,
                            filtering: false,
                            width: 100,
                            render: (rowData: any) => {
                                return (
                                    <ClickableImgCell
                                        className="projectnote-addedit"
                                        logo={DeleteLogo}
                                        text={"Delete Document"}
                                        onClick={handleDeleteDocumentClick}
                                        id={rowData.id!}
                                    />
                                );
                            },
                        },
                    ]}
                    data={viewModel.getDocuments}
                    title=""
                    options={{
                        search: false,
                        toolbar: false,
                        pageSize: 50,
                        pageSizeOptions: [10, 20, 50, 100],
                        emptyRowsWhenPaging: false,
                        headerStyle: {
                            background: " #495D89 0% 0% no-repeat padding-box",
                            boxShadow: " inset 0px 1px 1px #FFFFFF40",
                            color: "#fff",
                            fontFamily: ["Mont-Bold", "Montserrat", "Roboto Condensed", "Helvetica", "Arial", "sans-serif"].join(","),
                        },
                        rowStyle: {
                            backgroundColor: "#525355",
                            color: "#fff",
                            fontSize: 24,
                        },
                    }}
                    components={{ Container: (props) => <Paper {...props} elevation={0} /> }}
                />
            </DocumentListContainer>
        </>
    ));
};
