import * as MobX from "mobx";
import { runInAction, action, computed, observable } from "mobx";
import moment, { Moment } from "moment";

import { ViewModelBase } from "Core/ViewModels/ViewModelBase";
import { FieldType } from "Core/Utils/Utils";
import { Server } from "Custom/Globals/AppUrls";
import { DaysOption, GetValuesPLusYearByDateRequest } from "Custom/Models/Domain/Installations";
import { ApiResult } from "Core/Models";
import { InstallationAnalyticsResponseDto, InstallationAnalyticsResponseModel } from "./InstallationAnalyticsResponse";
import { DeviceAlertActionItemModelDTO } from "../TabConfigurationModel";

//extend viewmodel base and passing your model as the generic type
export class TabAnalyticsViewModel extends ViewModelBase<InstallationAnalyticsResponseModel> {
    constructor() {
        //Pass in a new instance of your model
        //By passing in true as the second parameter, we make this model undoable which means we can use save and reset options on the model
        //If you make a change to the model you need to persist it with a saveModel() call
        //If you make changes to your model you can revert it back by calling resetModel()
        super(new InstallationAnalyticsResponseModel(), false);
        //EN - Haven't figured out how to make this call work from the base model yet
        //This is only needed if you make use of the validation decorators
        this.setDecorators(InstallationAnalyticsResponseModel);
    }
    @MobX.observable imagesDateStart: Moment = moment.utc().startOf("day").add(-7, "days");
    //.add(-28, "days");
    @MobX.observable imagesDateEnd: Moment = moment.utc().startOf("day");

    //Singleton instance of class
    private static _instance: TabAnalyticsViewModel;

    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    @MobX.observable public daysOptions: DaysOption[] = [
        { label: "24 Hours", value: 1 },
        { label: "Week", value: 7 },
        { label: "Month", value: moment().daysInMonth() },
        { label: "Last 12 Months", value: moment().isLeapYear() ? 366 : 365 },
        { label: "Custom", value: -1 },
    ];

    @observable public daysToGraph: number = 7;

    @observable public hackCounter: number = 0;

    @action
    public reset() {
        this.daysToGraph = 7;

        this.imagesDateStart = moment.utc().startOf("day").add(-7, "days");
        this.imagesDateEnd = moment.utc().startOf("day");

        this.model.reset();
    }

    @action
    public setDaysToGraph(value: number) {
        this.daysToGraph = value;
    }

    @action
    public setStartDate(newDate: Moment) {
        this.imagesDateStart = newDate;
    }

    @action
    public setEndDate(newDate: Moment) {
        this.imagesDateEnd = newDate;
    }

    @MobX.computed
    public get HasValidData(): boolean {
        return this.model.deviceId > 0;
    }

    @MobX.computed
    public get Counter(): number {
        return this.hackCounter;
    }

    @MobX.computed
    public get AlertActionsMetrics(): DeviceAlertActionItemModelDTO[] {
        if (this.model.alertActionAnalytics === undefined) {
            return [];
        }

        return this.model.alertActionAnalytics.items.slice(0);
    }

    @MobX.computed
    public get GetYearAlertActionsMetrics(): DeviceAlertActionItemModelDTO[] {
        if (this.model.yearAlertActionAnalytics === undefined) {
            return [];
        }
        return this.model.yearAlertActionAnalytics.items.slice(0);
    }

    @MobX.computed
    public get TotalPeriodCount(): number {
        return this.model.totalPeriodCount;
    }

    @MobX.computed
    public get GetTotalYearCount(): number {
        return this.model.totalYearCount;
    }

    //This must be present in your viewmodel. Just return true if you dont need to validate anything.
    //keyof BlankModel & string lets you add type checking to the fieldName
    //I am using the validator package to make checking easier https://www.npmjs.com/package/validator
    public isFieldValid(fieldName: keyof FieldType<InstallationAnalyticsResponseModel>): boolean {
        const { isValid, errorMessage } = this.validateDecorators(fieldName);

        //You need to this two properties after validation
        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    @action
    loadCustomDate(deviceId: number): Promise<ApiResult<InstallationAnalyticsResponseDto | undefined>> {
        return this.load(this.imagesDateStart, this.imagesDateEnd, deviceId, false);
    }

    @action
    public load = async (startDate: Moment, endDate: Moment, deviceId: number, getYearData: boolean): Promise<ApiResult<InstallationAnalyticsResponseDto | undefined>> => {
        let apiResult: ApiResult<InstallationAnalyticsResponseDto | undefined> = {
            wasSuccessful: false,
            errors: [],
            headers: "",
            payload: undefined,
        };

        try {
            if (this.IsLoading === false) {
                this.setIsLoading(true);
                if (getYearData === true) {
                    this.model.reset();
                } else {
                    this.model.partialReset();
                }

                if (startDate > endDate) {
                    // Swap the dates
                    const temp: Moment = startDate;
                    startDate = endDate;
                    endDate = temp;
                }

                const request: GetValuesPLusYearByDateRequest = {
                    id: deviceId,
                    startDate: startDate.toISOString(),
                    endDate: endDate.toISOString(),
                    getYearData: getYearData,
                };

                const apiResult: ApiResult<InstallationAnalyticsResponseDto> = await this.Post<InstallationAnalyticsResponseDto>(
                    Server.Api.InstallationAnalytics.generateAlertActionAnalyticsBetweenDates,
                    request,
                );

                if (apiResult.wasSuccessful) {
                    let payload = apiResult.payload;
                    runInAction(() => {
                        this.model.fromDtoArr(apiResult.payload, getYearData);

                        this.hackCounter++;
                    });
                } else {
                    // Error. What to do?
                    this.setIsErrored(true);
                    this.history.push("/");
                }
            }
        } catch (exception) {
            // Error. What to do?
            this.setIsErrored(true);
            this.history.push("/");
        } finally {
            this.setIsLoading(false);
        }

        return apiResult;
    };
}
