import * as MobX from "mobx";
import { ModelBase } from "Core/Models/ModelBase";
import { IsNotEmpty } from "class-validator";

import { InstallationListItem } from "./InstallationListItem";
import { InstallationSimUpdated } from "./InstallationSimUpdated";
import { InstallationStatusDataDTO } from "./InstallationStatusData";
import { SensorValueDTO } from "./SensorValue";
import { isArrayNullOrUndefinedOrEmpty } from "Custom/Utils/array";
import { isNullOrUndefined } from "Custom/Utils/utils";

//Base class expects the model type and the DTO model type
//If you are not using a DTO just pass in undefined as the DTO
export class InstallationListResult extends ModelBase<InstallationListResult, InstallationListResultDTO> {
    @MobX.observable public deviceList: InstallationListItem[] = [];
    @MobX.observable public deviceSimUpdatedList: InstallationSimUpdated[] = [];
    @MobX.observable public statusData: InstallationStatusDataDTO[] = [];
    @MobX.observable public values: SensorValueDTO[] = [];

    //fromDto is required but you can leave it blank
    @MobX.action
    fromDto(model: InstallationListResultDTO): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (key === "deviceList") {
                    this.deviceList = [];
                    for (let i: number = 0; i < model.deviceList.length; i++) {
                        let temp: InstallationListItem = new InstallationListItem();
                        temp.fromDto(model.deviceList[i]);
                        this.deviceList.push(temp);
                    }
                } else if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }

        if (isArrayNullOrUndefinedOrEmpty(this.deviceList) === false) {
            for (let i: number = 0; i < this.deviceList.length; i++) {
                if (isArrayNullOrUndefinedOrEmpty(this.deviceSimUpdatedList) === false) {
                    const updated = this.deviceSimUpdatedList.find((a) => a.simId === this.deviceList[i].simId);

                    if (isNullOrUndefined(updated) === false) {
                        //	this.deviceList[i].latestReadingTime = updated!.updated;
                    }
                }
            }
        }
    }

    //toDto is required but you can leave it blank
    toDto(model: InstallationListResult): void {}
}

export type InstallationListResultDTO = {
    deviceList: InstallationListItem[];
    deviceSimUpdatedList: InstallationSimUpdated[];
};
