import styled from "styled-components";
import { Box } from "@material-ui/core";

export const InstallationHeaderPanel: any = styled(Box)`
    color: white;
    background-color: transparent;
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    width: 100%;
    padding: 0 30px;
    margin: 20px 0;

    div.guttersen-header {
        cursor: default;
        > div.device {
            > div.device-row {
                background-color: transparent;
                display: flex;
                align-items: center;

                > div {
                    padding: 0px 20px 0px;
                    font-size: 1.166666em;
                }

                > div.device-name {
                    padding: 0px 10px;
                    flex-grow: 1;
                    font-size: 18px;
                    font-weight: 700;
                    font-family: "Mont-Bold", "Montserrat", "Roboto Condensed", "Helvetica", "Arial", "sans-serif";
                }
            }

            > div.status-readings {
                padding: 5px 0;
                display: flex;
                flex-direction: row;
                justify-content: left;

                .device-status {
                    display: flex;
                    flex: 1 0 1;
                    width: 74px;
                    padding: 5px 0;
                    align-content: middle;
                    position: relative;
                    &.device_unknown {
                        color: ${(props) => props.theme.palette.common.white};
                        background-color: ${(props) => props.theme.app.colors.deviceWarning.unknown};
                    }

                    &.device_amberwarning {
                        color: ${(props) => props.theme.palette.common.white};
                        background-color: ${(props) => props.theme.app.colors.deviceWarning.amber};
                    }

                    &.device_redwarning {
                        color: ${(props) => props.theme.palette.common.white};
                        background-color: ${(props) => props.theme.app.colors.deviceWarning.red};
                    }

                    &.device_floodwarning {
                        color: ${(props) => props.theme.palette.common.white};
                        background-color: ${(props) => props.theme.app.colors.deviceWarning.flood};
                    }

                    &.device_greenwarning {
                        color: ${(props) => props.theme.palette.common.white};
                        background-color: ${(props) => props.theme.app.colors.deviceWarning.green};
                    }

                    > div {
                        margin-left: auto;
                        margin-right: auto;
                        position: relative;
                        font-size: 10px;
                    }
                }

                .device-temperature,
                .device-created,
                .device-lastreading {
                    display: flex;
                    justify-content: left;
                    flex: 1 0 1;
                    max-width: 320px;
                    padding: 10px 15px;
                }
            }
            .devicelist-title {
                font-family: "Mont-Bold", "Montserrat", "Roboto Condensed", "Helvetica", "Arial", "sans-serif";
                padding-right: 10px;
            }
        }
    }
`;

export const InstallationHeaderDivider: any = styled(Box)`
    border-top: 1px dashed #707070;
    margin: 10px 0;
`;
