import { observable } from "mobx";
import { ModelBase } from "Core/Models/ModelBase";
import { IsNotEmpty } from "class-validator";
import { AdminConditionTabModelDTO } from "../Admin/AdminConditionTabModel";
import { AdminAlertActionTabModelDTO } from "../Admin/AdminAlertActionTabModel";
import { UnitListItemModelDTO } from "./InstallationList/UnitListItemModel";

//Base class expects the model type and the DTO model type
//If you are not using a DTO just pass in undefined as the DTO
export class TabConfigurationModel extends ModelBase<TabConfigurationModel, TabConfigurationModelDTO> {
    @observable
    @IsNotEmpty({ message: "You must give me your name!!" })
    public firstName: string = "";

    //fromDto is required but you can leave it blank
    fromDto(model: TabConfigurationModelDTO): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
    }

    //toDto is required but you can leave it blank
    toDto(model: TabConfigurationModel): void {}
}

export type TabConfigurationModelDTO = {
    conditionSetOptions: AdminConditionTabModelDTO[];
    alertActionOptions: AdminAlertActionTabModelDTO[];
    deviceConditionSets: DeviceConditionSetModelDTO[];
    deviceAlertActions: DeviceAlertActionModelDTO[];
};

export type DeviceConditionSetModelDTO = {
    id: string | null;
    name: string;
    rowVersion: string | null;
    isDeleted: boolean;
    createdBy: string | null;
    createdDate: string | null;
    deviceId: number;
    conditionSetId: string | null;
    items: DeviceConditionItemModelDTO[];
    unitListItems: UnitListItemModelDTO[];
};

export type DeviceConditionItemModelDTO = {
    id: string | null;
    rowVersion: string | null;
    isDeleted: boolean;
    createdBy: string | null;
    createdDate: string | null;

    name: string;
    type: number;
    value: number;
    units: number;
    deviceConditionSetId: string | null;
    commandIndex: number;
    statusColour: string;
    statusTextColour: string;
    calculatedValue: number;
};

export interface CondiitonSetMetric extends DeviceConditionItemModelDTO {
    count: number;
}

export type DeviceAlertActionModelDTO = {
    id: string | null;
    name: string;
    rowVersion: string | null;
    isDeleted: boolean;
    createdBy: string | null;
    createdDate: string | null;
    deviceId: number;
    alertActionId: string | null;
    items: DeviceAlertActionItemModelDTO[];
};

export type DeviceAlertActionItemModelDTO = {
    id: string | null;
    rowVersion: string | null;
    isDeleted: boolean;
    createdBy: string | null;
    createdDate: string | null;

    cutOff: number;

    name: string;
    type: number;
    value: number;
    direction: number;
    units: number;
    deviceAlertActionId: string | null;
    photo: boolean;
    email: boolean;
    sms: boolean;
    commandIndex: number;
    alertColour: string;
    alertTextColour: string;
    calculatedValue: number;
    count: number;
};
