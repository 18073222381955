import React, { useEffect } from "react";
import { Box } from "@material-ui/core";
import { StoresInstance } from "Custom/Stores";
import { useObserver } from "mobx-react-lite";
import { autorun } from "mobx";

type AutoGridProps = {
    id?: string;
    className?: string;
    mobileArea?: string;
    tabletArea?: string;
    desktopArea?: string;
    children?: any;
    paddingTop?: string;
    onClick?: any;
    pb?: string | number;
    padding?: string;
    justifyContent?: "normal" | "start" | "end" | "flex-start" | "flex-end" | "left" | "right" | "stretch" | "center" | "space-between" | "space-around" | "space-evenly";
    justifySelf?: "normal" | "start" | "end" | "flex-start" | "flex-end" | "left" | "right" | "stretch" | "center" | "space-between" | "space-around" | "space-evenly";
    alignContent?: "normal" | "start" | "end" | "flex-start" | "flex-end" | "left" | "right" | "stretch" | "center" | "space-between" | "space-around" | "space-evenly";
    alignSelf?: "normal" | "start" | "end" | "flex-start" | "flex-end" | "left" | "right" | "stretch" | "center" | "space-between" | "space-around" | "space-evenly";
};
export const AutoCell: React.FC<AutoGridProps> = (props: AutoGridProps) => {
    const [area, setArea] = React.useState<string>(props.desktopArea!);
    const [view, setView] = React.useState("desktop");

    useEffect(() => {
        autorun(() => {
            if (StoresInstance.coreStore.isMobile) {
                setArea(props.mobileArea!);
                setView("mobile");
            } else if (StoresInstance.coreStore.isTablet) {
                setArea(props.tabletArea!);
                setView("tablet");
            } else if (StoresInstance.coreStore.isDesktop) {
                setArea(props.desktopArea!);
                setView("desktop");
            }
        });
        return () => {
            // Clean up after yourself
        };
    }, []);

    return useObserver(() => (
        <>
            <Box
                id={props.id ?? ""}
                className={view}
                onClick={props.onClick ?? undefined}
                paddingTop={props.paddingTop ?? undefined}
                pb={props.pb ?? undefined}
                padding={props.padding ?? undefined}
                alignContent={props.alignContent ?? undefined}
                alignSelf={props.alignSelf ?? undefined}
                justifyContent={props.justifyContent ?? undefined}
                justifySelf={props.justifySelf ?? undefined}
                gridArea={area}
            >
                {props.children}
            </Box>
        </>
    ));
};
AutoCell.defaultProps = {
    justifyContent: "normal",
    alignContent: "normal",
};
