import React, { useEffect, useState } from "react";
import { ContactModalDialogPaper, AddEditBoxRow, ContactULGrid } from "./ContactModal.Styles";

import { generateID } from "Core/Utils/Utils";
import { ContractorContactModel } from "Custom/Models/Domain/Contacts/ContractorContactModel";
import { ContactModel } from "./Contact.Model";
import EditLogo from "Custom/Content/editIcon.svg";
import { formatDelete } from "Custom/Components/AddEditControls";

import {
    AddNewBox,
    SearchBox,
    ContactListButton,
    ContactListPaper,
    ButtonWrapper,
    ContactListButtonWrapper,
    ListWrapper,
    InternalList,
    InternalListToo,
    ContactListPage,
    ContactListRow,
} from "Custom/StylesAppSpecific/Controls/ContactBoxes";
import { Typography } from "@material-ui/core";

interface IContactProps {
    contactsList: ContactModel[];
    onOpen(contact: ContactModel): void;
    onDelete(contact: ContactModel): void;
}

export const ContactListView: React.FC<IContactProps> = ({ contactsList, onOpen, onDelete }) => {
    const handleOpenModalClick = (contact: ContactModel) => {
        onOpen(contact);
    };

    const handleDeleteClick = (contact: ContactModel) => {
        onDelete(contact);
    };

    const renderContactsGrid = () => {
        return contactsList
            .filter((f: ContactModel) => f.isDeleted === false)
            .map((client: ContactModel, index: number) => {
                return (
                    <>
                        <ContactListRow key={generateID()} className={"row " + client.id}>
                            <div key={generateID()}>{client.firstName}</div>
                            <div key={generateID()}>{client.lastName}</div>
                            <div key={generateID()}>{client.position}</div>
                            <div key={generateID()}>{client.email !== null ? client.email : ""}</div>
                            <div key={generateID()}>{client.phone !== null && client.phone.length > 0 ? client.phone : client.phone2 !== null ? client.phone2 : ""}</div>
                            <div className="edit" key={generateID()}>
                                <div onClick={() => handleOpenModalClick(client)}>
                                    <img src={EditLogo} /> Edit
                                </div>
                            </div>
                            <div className="delete" key={generateID()}>
                                {formatDelete(client.generatedTempId, "", () => handleDeleteClick(client))}
                            </div>
                        </ContactListRow>
                    </>
                );
            });
    };

    return <>{renderContactsGrid()}</>;
};
