import { Installation, InstallationDTO } from "./../../../Models/Domain/Installations/Installation";
import { ViewModelBase } from "Core/ViewModels/ViewModelBase";
import { action, computed, observable, runInAction } from "mobx";
import { FieldType } from "Core/Utils/Utils";
import { ProjectPropertyListItemDTO, ProjectPropertyListItemModel, SwapPropertyRequest } from "./ProjectPropertyListItemModel";
import { ApiResult } from "Core/Models";
import { Server } from "Custom/Globals/AppUrls";
import { InstallationListItem } from "Custom/Models/Domain";

//extend viewmodel base and passing your model as the generic type
export class MovePropertyModalViewModel extends ViewModelBase<ProjectPropertyListItemModel> {
    //Singleton instance of class
    private static _instance: MovePropertyModalViewModel;
    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    @observable public errorMessage: string = "";

    @observable
    private projectProperties = observable<ProjectPropertyListItemModel>([]);

    @observable
    private device: InstallationListItem | undefined = undefined;

    @computed
    public get getProjectProperties() {
        return this.projectProperties.slice();
    }

    @action
    public setDevice(device: InstallationListItem | undefined) {
        this.device = device;
    }

    constructor() {
        //Pass in a new instance of your model
        //By passing in true as the second parameter, we make this model undoable which means we can use save and reset options on the model
        //If you make a change to the model you need to persist it with a saveModel() call
        //If you make changes to your model you can revert it back by calling resetModel()
        super(new ProjectPropertyListItemModel(), false);
        //EN - Haven't figured out how to make this call work from the base model yet
        //This is only needed if you make use of the validation decorators
        this.setDecorators(ProjectPropertyListItemModel);
    }

    //isValid will check all fields to make sure they are in a valid state.
    public doSubmit = async (e: any) => {
        e.preventDefault();

        if (this.isModelValid()) {
            //Do stuff here
            this.errorMessage = "Form is valid";
        } else {
            this.errorMessage = "Form is not valid";
        }
    };

    @action
    public setProjectProperties(items: ProjectPropertyListItemDTO[]) {
        this.projectProperties.clear();
        items.forEach((item, index) => {
            let domainModel = this.projectProperties.find((dm) => dm.id === item.id);

            if (!domainModel) {
                domainModel = new ProjectPropertyListItemModel();

                domainModel.fromDto(item);
                this.projectProperties.push(domainModel);
            }
        });
    }

    @action
    public async upsertDeviceProperty(propertySelected: ProjectPropertyListItemModel): Promise<ApiResult<InstallationDTO>> {
        const request: SwapPropertyRequest = {
            id: this.device!.id!,
            rowVersion: this.device!.rowVersion!,
            roofcareAddressId: propertySelected.id,
        };

        const apiResult = await this.Post<InstallationDTO>(Server.Api.Installation.swapPropertyForDevice, request);

        if (apiResult.wasSuccessful) {
            runInAction(() => {
                const device: InstallationDTO = apiResult.payload;
                if (device != undefined && device !== null && this.device != undefined && this.device !== null) {
                    this.device!.roofcareAddressId = device.roofcareAddressId;
                }
            });
        }

        return apiResult;
    }

    @action
    public async loadData(): Promise<ApiResult<ProjectPropertyListItemDTO[]>> {
        const apiResult = await this.Post<ProjectPropertyListItemDTO[]>(Server.Api.Installation.getAllProjectAddressesForSwapAddress);

        if (apiResult.wasSuccessful) {
            runInAction(() => {
                if (apiResult.payload !== null && apiResult.payload !== undefined) {
                    const projectProperties: ProjectPropertyListItemDTO[] = apiResult.payload;
                    this.setProjectProperties(projectProperties);
                }
                this.setIsLoading(false);
            });
        } else {
            // Error. What to do?
            this.setIsLoading(false);
            this.setIsErrored(true);
            this.history.push("/");
        }

        return apiResult;
    }

    @action
    public clear() {
        this.projectProperties.clear();
        this.device = undefined;
    }

    //This must be present in your viewmodel. Just return true if you dont need to validate anything.
    //keyof BlankModel & string lets you add type checking to the fieldName
    //I am using the validator package to make checking easier https://www.npmjs.com/package/validator
    public isFieldValid(fieldName: keyof FieldType<ProjectPropertyListItemModel>): boolean {
        const { isValid, errorMessage } = this.validateDecorators(fieldName);

        //You need to this two properties after validation
        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
