import { isThisHour } from "date-fns";
import { ContactType, ContactTypeHelpers } from "Custom/Models/Domain/Contacts/ContactType";
import { ViewModelBase } from "Core/ViewModels/ViewModelBase";
import { ContactListItem, ContactListItemDTO } from "./ContactListItem";
import { FieldType } from "Core/Utils/Utils";
import { action, computed, IComputedValue, observable, runInAction } from "mobx";
import { ContactListModel } from "./ContactListModel";
import { ApiResult } from "Core/Models";
import { Server } from "Custom/Globals/AppUrls";
import { debounce } from "lodash-es";
import { DEBOUNCE_VALUE_MS } from "Custom/Globals/Globals";
import { FileDownloadResponseDTO } from "../Installations/FileDownloadResponseDTO";
import { ContactModel, ContactModelDTO, ContactViewModel } from "Custom/Components/Contact/";
import { ContractorContactModelDTO } from "Custom/Models/Domain/Contacts";
import { ContactModelDTO as ClientContactModelDTO } from "Custom/Models/Domain/Contacts/ContactModel";
import { NewContactModel, NewContactModelDTO, NewContactViewModel } from "../Projects/Contact";
//extend viewmodel base and passing your model as the generic type
export class ContactListViewModel extends ViewModelBase<ContactListModel> {
    //Singleton instance of class
    private static _instance: ContactListViewModel;
    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    @observable public errorMessage: string = "";

    @observable public pageSize: number = 50;
    @observable public currentPage: number = 0;

    @observable public searchString: string = "";
    @observable public filterSearchString: string = "";

    // By default this will be the first column (by index) and in ascending order.
    @observable public sortColumnId: number = 0;
    @observable public sortDirection: any = "asc";

    @observable public contactTypeFilter: string[] = ["100", "200"];

    constructor() {
        //Pass in a new instance of your model
        //By passing in true as the second parameter, we make this model undoable which means we can use save and reset options on the model
        //If you make a change to the model you need to persist it with a saveModel() call
        //If you make changes to your model you can revert it back by calling resetModel()
        super(new ContactListModel(), false);
        //EN - Haven't figured out how to make this call work from the base model yet
        //This is only needed if you make use of the validation decorators
        this.setDecorators(ContactListModel);
    }

    @computed
    public get getContactTypeFilter(): IComputedValue<number[] | string[] | null> {
        return computed(() => this.contactTypeFilter);
    }

    @action
    public setContactTypeFilter(newValues: string[]) {
        let numbers: number[] = newValues.map((item) => parseInt(item));
        this.contactTypeFilter = newValues;
    }

    public clear() {
        this.model.clear();
    }

    public getSearchString = () => {
        return computed(() => this.searchString);
    };

    @action
    public setSearchString = (value: string) => {
        this.searchString = value;
        this.setFilterSearchString(value);
    };

    private setFilterSearchString = debounce(
        action((value: string) => {
            this.filterSearchString = value;
        }),
        DEBOUNCE_VALUE_MS,
    );

    @action
    public setOrderChange = (columnId: number, direction: any) => {
        this.sortColumnId = columnId;
        this.sortDirection = direction;
    };

    @computed
    public get getContactTypeFilterOptions(): any[] {
        return ContactTypeHelpers.getOptions();
    }

    // #endregion Project Sorting

    @action
    public setRowsPerPage(rows: number) {
        this.pageSize = rows;
    }

    @action
    public setPage = (page: number) => {
        this.currentPage = page;
    };

    @action
    public load = async (): Promise<any> => {
        const apiResult: ApiResult<ContactListItemDTO[]> = await this.Get<ContactListItemDTO[]>(Server.Api.Client.getALLContactsForList);

        if (apiResult.wasSuccessful) {
            runInAction(() => {
                this.model.fromDTO(apiResult.payload);
            });
        }
        return apiResult;
    };

    @action public updateContact = async (contact: ContactModel, contactType: ContactType): Promise<ApiResult<NewContactModelDTO[]>> => {
        let apiResult: ApiResult<NewContactModelDTO[]> = {
            wasSuccessful: false,
            errors: [],
            headers: "",
            payload: [],
        };

        if (contact !== null && contact !== undefined) {
            let local: ContactListItem | undefined = this.model.contacts.slice().find((a) => a.sourceId === contact.id);
            if (local !== undefined) {
                if (contactType === ContactType.Client) {
                    // Set the sourceId to the chosen ClientId
                    contact.sourceId = local.parentId;

                    const request: ClientContactModelDTO = contact.toClientContactModelDTO(local.parentId);

                    apiResult = await this.Post<NewContactModelDTO[]>(Server.Api.Client.upsertNewContact, request);

                    if (apiResult) {
                        if (apiResult.wasSuccessful) {
                            runInAction(() => {
                                let contacts: NewContactModelDTO[] = apiResult.payload;
                                if (contacts !== null && contacts !== undefined && contacts.length > 0) {
                                    let retVal: NewContactModelDTO | undefined = contacts.find((a) => a.id === contact.id);
                                    this.model.updateContact(retVal, ContactType.Client);
                                }
                            });
                        } else {
                            console.log(apiResult.errors);
                        }
                    }
                } else {
                    // Contractor Contact
                    // Set the sourceId to the chosen ClientId
                    contact.sourceId = local.parentId;
                    let request: ContractorContactModelDTO = contact.toContractorContactModelDTO(local.parentId);

                    apiResult = await this.Post<NewContactModelDTO[]>(Server.Api.Contractor.upsertContact, request);

                    if (apiResult) {
                        if (apiResult.wasSuccessful) {
                            runInAction(() => {
                                let contacts: NewContactModelDTO[] = apiResult.payload;
                                if (contacts !== null && contacts !== undefined && contacts.length > 0) {
                                    let retVal: NewContactModelDTO | undefined = contacts.find((a) => a.id === contact.id);
                                    this.model.updateContact(retVal, ContactType.Contractor);
                                }
                            });
                        } else {
                            console.log(apiResult.errors);
                        }
                    }
                }
            }
        }

        return apiResult;
    };

    /*     @computed public get getModalModel() {
        return this.contactVM;
    } */

    @action
    public getContactModel = (id: string, contactType: ContactType) => {
        let retVal = new ContactModel();

        let item: ContactListItem | undefined = this.model.contacts.find((a) => a.contactType === contactType && a.sourceId === id);

        if (item !== null && item !== undefined) {
            retVal.copyFromContactListItem(item);
        }

        return retVal;
    };

    @computed public get getContacts(): ContactListItem[] {
        let retVal: ContactListItem[] = this.model.contacts;

        if (this.searchString.length > 0) {
            retVal = retVal.filter(
                (or) =>
                    (or.email !== null && or.email !== undefined && or.email.toLowerCase().includes(this.searchString)) ||
                    (or.contactName !== null && or.contactName !== undefined && or.contactName.toLowerCase().includes(this.searchString)) ||
                    (or.parentPostcode !== null && or.parentPostcode !== undefined && or.parentPostcode.toLowerCase().includes(this.searchString)) ||
                    (or.phone !== null && or.phone !== undefined && or.phone.toLowerCase().includes(this.searchString)) ||
                    (or.phone2 !== null && or.phone2 !== undefined && or.phone2.toLowerCase().includes(this.searchString)) ||
                    (or.parent !== null && or.parent !== undefined && or.parent.toLowerCase().includes(this.searchString)),
            );
        }

        const filterByType = (item: ContactListItem): boolean => {
            if (this.contactTypeFilter.length === ContactTypeHelpers.getOptions().length) {
                return true;
            }

            let statusResponse: boolean = false;
            // Check 1 - see if it is device item only from the main list.
            let found: boolean = this.contactTypeFilter.includes(item.contactType.toString());
            // have we a match from Admin/Devices
            if (found) {
                statusResponse = true;
            }

            return statusResponse;
        };

        return retVal.filter((contact) => filterByType(contact));
    }

    //isValid will check all fields to make sure they are in a valid state.
    public doSubmit = async (e: any) => {
        e.preventDefault();

        if (this.isModelValid()) {
            //Do stuff here
            this.errorMessage = "Form is valid";
        } else {
            this.errorMessage = "Form is not valid";
        }
    };

    //This must be present in your viewmodel. Just return true if you dont need to validate anything.
    //keyof BlankModel & string lets you add type checking to the fieldName
    //I am using the validator package to make checking easier https://www.npmjs.com/package/validator
    public isFieldValid(fieldName: keyof FieldType<ContactListModel>): boolean {
        const { isValid, errorMessage } = this.validateDecorators(fieldName);

        //You need to this two properties after validation
        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public downloadCSV = async () => {
        const request = {
            searchString: this.searchString,
            contactTypeFilter: this.contactTypeFilter,
        };

        const apiResult: ApiResult<FileDownloadResponseDTO> = await this.Post<FileDownloadResponseDTO>(Server.Api.Client.downloadContactsAsCsv, request);

        if (apiResult.wasSuccessful) {
            runInAction(() => {
                let fileName: string = "contacts.csv";

                const file: string = apiResult.payload.stringFile;
                const type: string = apiResult.payload.contentType;
                fileName = apiResult.payload.fileName;

                const url = window.URL.createObjectURL(new Blob([file], { type }));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", fileName);
                document.body.appendChild(link);
                link.click();
            });
        } else {
            // Error. What to do?
            this.setIsErrored(true);
            if (apiResult.errors !== null && apiResult.errors !== undefined && apiResult.errors.length > 0) {
                this.Errors = apiResult.errors[0].message;
                this.history.push("/");
            }
        }
    };

    /*     @action
    public setContactModel = (source: ContactModel) => {
        this.contactVM.setContact(source);
    };

    @computed
    public get isContactModelValid(): boolean {
        return this.contactVM.isModelValid();
    } */

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
