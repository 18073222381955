export enum ActionDateTypeFilter {
    IGNOREDATES = 50,
    CREATED = 100,
    FOLLOWUP = 200,
    RESOLVED = 300,
}

export interface GetDownloadActionRequest {
    searchString: string;
    noFollowUpDate: boolean;
    hasFollowUpDateNotOverdue: boolean;
    hasFollowUpDateOverdue: boolean;
    resolved: boolean;
    actionDateTypeFilter: ActionDateTypeFilter;
    startDate: string;
    endDate: string;
}
