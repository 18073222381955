import React, { useRef, useEffect, useState } from "react";
import { useObserver } from "mobx-react-lite";
import { GutterSensTabContent } from "../GuttersensTabContent";
import { generateID } from "Core/Utils/Utils";
import { AnalyticsBox } from "Custom/Views/Installations/Analytics/TabAnalytics.style";
import { DataCell, DeviceDataRow } from "../TabDeviceData.style";
import { Box, MenuItem, Select, Typography } from "@material-ui/core";
import { DaysOption } from "Custom/Models/Domain";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { isValidDateString } from "Custom/Utils/utils";
import moment, { Moment } from "moment";
import { ApiResult } from "Core/Models";
import { TabAnalyticsViewModel } from "./TabAnalyticsViewModel";
import { SuccessBtn } from "Custom/StylesAppSpecific/Controls/SuccessBtn";
import { AlertActionAnalysisComponent } from "./AlertActionAnalysisComponent";
import { InstallationAnalyticsResponseDto } from "./InstallationAnalyticsResponse";
import { Loader } from "Core/Components";

interface ITabAnalyticsProps {
    deviceId: number;
    simId: string;
    minDate: Moment;
}

export function TabAnalytics(props: ITabAnalyticsProps) {
    const { deviceId, minDate } = props;
    const [viewModel] = useState(() => TabAnalyticsViewModel.Instance);
    const [startDate, setStartDate] = useState(moment.utc().startOf("day").add(-7, "days"));
    const [endDate, setEndDate] = useState(moment.utc().add(1, "days").startOf("day").add(-1, "minute"));
    const [counter, setCount] = useState(0);
    const componentRef: any = useRef(generateID());

    useEffect(() => {
        const { deviceId } = props;
        const { IsLoading, load, imagesDateStart, imagesDateEnd } = viewModel;

        setStartDate(imagesDateStart);
        setEndDate(imagesDateEnd);

        if (IsLoading === false && deviceId > 0) {
            let promise = load(imagesDateStart, imagesDateEnd, deviceId, true);

            promise.then(() => {
                let a = counter + 1;
                setCount(a);
            });
        }

        return () => {
            // Clean up after yourself
        };
    }, []);

    const updateStart = (date: any, value: string | null | undefined) => {
        let mom: Moment = moment(value, "DD/MM/YYYY");
        if (value !== null && value !== undefined) {
            if (isValidDateString(value!) === true && value!.length === 10) {
                if (mom < minDate) {
                    mom = minDate;
                }
                viewModel.setStartDate(mom);
                setStartDate(mom);
            }
        }
    };

    const updateEnd = (date: any, value: string | null | undefined) => {
        let mom: Moment = moment(value, "DD/MM/YYYY");
        if (value !== null && value !== undefined) {
            if (isValidDateString(value!) === true && value!.length === 10) {
                if (mom < minDate) {
                    mom = minDate;
                }
                viewModel.setEndDate(mom);
                setEndDate(mom);
            }
        }
    };

    const searchByCustomDate = (event: any): void => {
        let promise: Promise<ApiResult<InstallationAnalyticsResponseDto | undefined>> = viewModel.loadCustomDate(deviceId);

        promise.then(() => {
            let a = counter + 1;
            setCount(a);
        });
    };

    const handleDaysToGraphDropDownOnChange = async (event: any) => {
        const days: number = parseInt(event.target.value);
        viewModel.setDaysToGraph(days);

        if (days > 0) {
            const mom: Moment = moment().add(days * -1, "days");
            const now: Moment = moment.utc().add(1, "days").startOf("day").add(-1, "minute");
            viewModel.setStartDate(mom);
            viewModel.setEndDate(now);
            setStartDate(mom);
            setEndDate(now);

            let promise: Promise<ApiResult<InstallationAnalyticsResponseDto | undefined>> = viewModel.load(mom, now, deviceId, false);

            promise.then(() => {
                let a = counter + 1;
                setCount(a);
            });
        }
    };

    const disableDates: boolean = viewModel.daysToGraph > 0;

    function renderPage() {
        if (viewModel.IsLoading === true) return <Loader />;

        return (
            <GutterSensTabContent>
                <AnalyticsBox>
                    <DeviceDataRow>
                        <Typography style={{ fontSize: "16px" }}>Alert Analytics</Typography>
                    </DeviceDataRow>
                    <DeviceDataRow>
                        <DataCell style={{ marginLeft: "-15px" }}>
                            <Typography style={{ fontSize: "14px" }} className="select-title">
                                Show:
                            </Typography>
                            <Box maxWidth="140px" width="100%" className="selectBox">
                                <Select
                                    id="role-select"
                                    value={viewModel.daysToGraph}
                                    onChange={handleDaysToGraphDropDownOnChange}
                                    MenuProps={{
                                        getContentAnchorEl: null,
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left",
                                        },
                                    }}
                                    fullWidth
                                >
                                    {viewModel.daysOptions.map((item: DaysOption, index: number) => {
                                        return (
                                            <MenuItem key={generateID()} value={item.value}>
                                                {item.label}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </Box>
                        </DataCell>
                        <DataCell>
                            <Typography style={{ fontSize: "14px", width: "100px" }}>Show from:</Typography>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    placeholder="01/12/2021"
                                    label={""}
                                    value={startDate}
                                    disabled={disableDates}
                                    mask={"__/__/____"}
                                    format="DD/MM/YYYY"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={updateStart}
                                    minDate={minDate}
                                />
                            </MuiPickersUtilsProvider>
                        </DataCell>
                        <DataCell>
                            <Typography style={{ fontSize: "14px", width: "75px" }}>Show to:</Typography>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    placeholder="01/12/2021"
                                    label={""}
                                    value={endDate}
                                    disabled={disableDates}
                                    mask={"__/__/____"}
                                    format="DD/MM/YYYY"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={updateEnd}
                                    minDate={minDate}
                                />
                            </MuiPickersUtilsProvider>
                        </DataCell>
                        <DataCell>
                            <SuccessBtn
                                placeholder="Update graph using custom dates"
                                id="customsearch"
                                variant="contained"
                                color="primary"
                                type="button"
                                disabled={disableDates}
                                onClick={searchByCustomDate}
                            >
                                Search
                            </SuccessBtn>
                        </DataCell>
                    </DeviceDataRow>
                </AnalyticsBox>
                <AnalyticsBox>
                    <AlertActionAnalysisComponent
                        imageHeight={300}
                        imageWidth={800}
                        marginTop={30}
                        alertAnalytics={viewModel.AlertActionsMetrics}
                        total={viewModel.TotalPeriodCount}
                        yearAlertAnalytics={viewModel.GetYearAlertActionsMetrics}
                        yearTotal={viewModel.GetTotalYearCount}
                        isLoading={viewModel.IsLoading}
                        counter={viewModel.Counter}
                    />
                </AnalyticsBox>
            </GutterSensTabContent>
        );
    }
    return useObserver(() => renderPage());
}
