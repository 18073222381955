import { ContactModel, ContactModelDTO } from "Custom/Models/Domain/Contacts/ContactModel";
// Libs

import * as MobX from "mobx";

import { BaseStore } from "Custom/Stores/Domain/BaseStore";
import Moment from "moment";
import { Stores } from "Custom/Stores";
import { ClientModel, ClientModelDTO } from "Custom/Models/Domain/Clients";
import { GenericIncludeDeleted, InitialState } from "Custom/Models";
import { ApiResult } from "Core/Models";
import { Server } from "Custom/Globals/AppUrls";

// App

interface Filters {
    date: any;
    orderAsc: boolean;
    searchFilter: string;
}

export class ClientStore extends BaseStore {
    private items = MobX.observable<ClientModel>([]);
    private clientContacts = MobX.observable<ContactModel>([]);

    @MobX.observable private isLoadingData: boolean = false;
    @MobX.observable private filters: Filters = {
        date: Moment(),
        orderAsc: true,
        searchFilter: "",
    };

    @MobX.observable private lastUpdated: any = Moment();

    public constructor() {
        super();
    }

    public init(stores: Stores, initialState: InitialState) {}

    @MobX.computed get getClients(): ClientModel[] {
        return this.items.slice();
    }

    @MobX.computed get getCount(): number {
        return this.items.length;
    }

    @MobX.action
    public setClients(clients: ClientModelDTO[]) {
        this.items.clear();
        clients.forEach((item, index) => {
            let domainModel = this.items.find((dm) => dm.id === item.id);

            if (!domainModel) {
                domainModel = new ClientModel();

                domainModel.fromDto(item);
                this.items.push(domainModel);
            }
        });
        this.isLoadingData = false;
        this.lastUpdated = Moment();
    }

    @MobX.action
    public addClient(client: ClientModel) {
        let domainModel = this.items.find((dm) => dm.id === client.id);

        if (!domainModel) {
            this.items.push(client);
        }
    }

    @MobX.action
    public setClientContacts(contacts: ContactModelDTO[]) {
        this.clientContacts.clear();
        contacts.forEach((item, index) => {
            let domainModel = this.clientContacts.find((dm) => dm.id === item.id);

            if (!domainModel) {
                domainModel = new ContactModel();

                domainModel.fromDto(item);
                this.clientContacts.push(domainModel);
            }
        });
    }

    public getAllContactsForClient(clientId: string) {
        const retVal = this.clientContacts.filter((a) => a.clientId === clientId);
        return retVal;
    }

    public getAllContacts() {
        const retVal = this.clientContacts.slice(0);
        return retVal;
    }

    @MobX.action
    public clear() {
        this.items.clear();
    }

    @MobX.action
    public clearContacts() {
        this.clientContacts.clear();
    }

    @MobX.action
    public setIsLoadingData(loading: boolean) {
        this.isLoadingData = loading;
    }

    @MobX.computed
    public get getAll() {
        return this.items.slice();
    }

    @MobX.computed
    public get getIsLoadingData() {
        return this.isLoadingData;
    }

    @MobX.computed
    public get getFilters() {
        return this.filters;
    }

    @MobX.computed
    public get getFiltered() {
        let slicedItems: ClientModelDTO[] = this.items.slice();
        if (this.filters.searchFilter) {
            slicedItems = slicedItems.filter(
                (or) => or.clientName.toLowerCase().includes(this.filters.searchFilter),
                /*                     ||
                    or.firstName.toLowerCase().includes(this.filters.searchFilter) ||
                    or.lastName.toLowerCase().includes(this.filters.searchFilter), */
            );
        }
        return slicedItems;
    }

    @MobX.action
    public async loadAllClientsAsync(): Promise<ApiResult<ClientModelDTO[]>> {
        const includeGenericDeleted: GenericIncludeDeleted = {
            includeDeleted: false,
        };
        const apiResult = await this.Post<ClientModelDTO[]>(Server.Api.Client.getClients, includeGenericDeleted);

        if (apiResult.wasSuccessful) {
            MobX.runInAction(() => {
                this.setClients(apiResult.payload);
            });
        }
        return apiResult;
    }
}
