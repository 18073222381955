import React from "react";
import { Header } from "../Header";
import { Cell, CSSGrid } from "Core/Components/Styles/BaseStyles";
import { RoofcareClientViewBreadcrumb } from "Custom/Views/RoofcareClientViewBreadcrumb";

//EN: use empty generic type to get default react props
export const ClientLayout: React.FC<{}> = (props) => {
    if (!props) return <>Props undefined</>;
    return (
        <>
            <CSSGrid height="100%" columns={"1fr"} rows={"0 auto 1fr"} gap={"0px"} areas={["header", "breadcrumb", "content"]}>
                <Cell area={"header"}>
                    <Header />
                </Cell>
                <Cell marginTop={"70px"} area={"breadcrumb"} id={"breadcrumb"} className="roofcarebreadcrumbcell">
                    <RoofcareClientViewBreadcrumb />
                </Cell>
                <Cell area={"content"} id={"content"}>
                    {props && <div style={{ height: "100%" }}>{props.children}</div>}
                </Cell>
            </CSSGrid>
        </>
    );
};
