import styled from "styled-components";
import { Box } from "@material-ui/core";

export const MapTabContainer: any = styled(Box)`
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    padding-left: 50vw;
    flex: 1 0 auto;
    align-items: stretch;
    height: 100%;
    min-height: 77.5vh;

    div.mapsection {
        position: absolute;
        text-align: right;
        display: flex;
        flex-direction: column;
        flex-basis: 50%;
        bottom: 0;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;

        @media screen and (max-width: 1024px) {
        }

        @media screen and (max-width: 640px) {
            min-height: 320px;
        }

        section {
            border: none;
            height: 100%;
        }

        .leaflet-popup {
            //transform: translateY(10px) !important;
            //background-color: green;
        }

        .leaflet-popup-content-wrapper {
            border-radius: 0 !important;
            //background-color: yellow;
            padding: 6px 11px;

            .leaflet-popup-content {
                margin: 0;
                //background-color: orange;
            }
        }
        .leaflet-popup-close-button {
            //display: none !important;
            //background-color: pink;
        }
    }
`;

export const MapTabControl: any = styled(Box)`
    display: flex;
    flex-direction: row;
    position: relative;
    width: 100%;
    flex: 1 0 auto;
    justify-content: flex-end;
    height: 46px;
`;

export const MapTabUnitControl: any = styled(Box)`
    display: flex;
    flex-direction: row;
    position: relative;
    flex: 1 0 auto;
    height: 46px;
`;

export const MapTabLayerControl: any = styled(Box)`
    padding: 7px 30px;
    display: flex;
    flex-direction: row;
    position: relative;
    flex: 1 0 auto;
    height: 46px;
    max-width: 175px;
    background-color: #3867cc;
`;
