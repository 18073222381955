import { action, observable } from "mobx";
import { ModelBase } from "Core/Models/ModelBase";

//Base class expects the model type and the DTO model type
//If you are not using a DTO just pass in undefined as the DTO
export class ClientListItemModel extends ModelBase<ClientListItemModel, ClientListItemModelDTO> {
    @observable public id: string = "";
    @observable public clientName: string = "";
    @observable public projectCount: number = 0;
    @observable public leadCount: number = 0;
    @observable public activeCount: number = 0;
    @observable public wonCount: number = 0;
    @observable public lostCount: number = 0;
    @observable public propertyCount: number = 0;
    @observable public isDeleted: boolean = false;

    //fromDto is required but you can leave it blank
    @action
    fromDto(model: ClientListItemModelDTO): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
    }

    //toDto is required but you can leave it blank
    toDto(model: ClientListItemModel): void {}
}

export type ClientListItemModelDTO = {
    id: string;
    clientName: string;
    projectCount: number;
    leadCount: number;
    activeCount: number;
    wonCount: number;
    lostCount: number;
    isDeleted: boolean;
    propertyCount: number;
};
