import { useEffect, useState } from "react";
import { useObserver } from "mobx-react-lite";
import { GutterSensTabContent } from "../GuttersensTabContent";
import { generateID } from "Core/Utils/Utils";
import { AnalyticsBox } from "Custom/Views/Installations/Analytics/TabAnalytics.style";
import { DeviceDataRow } from "../TabDeviceData.style";
import { Typography } from "@material-ui/core";
import { TabInstallationViewModel } from "./TabInstallationViewModel";

import { Loader } from "Core/Components";
import { DeviceAttributeBox, DeviceAttributeCell } from "../TabAttributes.style";
import { GalleryWrapper, InstallationDivisor } from "./TabInstallation.style";
import { AttributeSummary } from "../AttributeSummary";
import { InstallationCommissionImageModal } from "./SimpleImageModal";
import { useRouter } from "Core/Utils";

interface ITabInstallationProps {
    deviceId: number;
}

interface IImageModalState {
    showImageModal: boolean;
    selectedId: number;
}

export function TabInstallation(props: ITabInstallationProps) {
    const [viewModel] = useState(() => TabInstallationViewModel.Instance);
    const [counter, setCount] = useState(0);
    const { match } = useRouter();
    const [showImageModal, setShowImageModal] = useState<IImageModalState>({ showImageModal: false, selectedId: -1 });
    const [showDrainageImageModal, setShowDrainageImageModal] = useState<IImageModalState>({ showImageModal: false, selectedId: -1 });

    useEffect(() => {
        const { deviceId } = props;
        const { IsLoading, load } = viewModel;

        let id: number = deviceId;

        if (id < 1) {
            const { installId } = match.params as any;

            id = installId;
        }

        if (IsLoading === false && id > 0) {
            let promise = load(id);

            promise.then(() => {
                let a = counter + 1;
                setCount(a);
            });
        }

        return () => {
            // Clean up after yourself
        };
    }, []);

    const showImage = (selectedItemId: number): void => {
        setShowImageModal({ showImageModal: true, selectedId: selectedItemId });
    };

    const showDrainageImage = (selectedItemId: number): void => {
        setShowDrainageImageModal({ showImageModal: true, selectedId: selectedItemId });
    };

    const closeImageModal = (): void => {
        setShowImageModal({ showImageModal: false, selectedId: -1 });
    };

    const closeDrainageModal = (): void => {
        setShowDrainageImageModal({ showImageModal: false, selectedId: -1 });
    };

    function renderPage() {
        if (viewModel.IsLoading === true) return <Loader />;

        return (
            <GutterSensTabContent>
                <AnalyticsBox>
                    <DeviceDataRow>
                        <Typography style={{ fontSize: "32px" }}>Installation</Typography>
                    </DeviceDataRow>
                    {(viewModel.model.installationCommissioned === null || viewModel.model.installationCommissioned === undefined) && (
                        <DeviceDataRow> No current installation data available for this device</DeviceDataRow>
                    )}

                    {viewModel.model.installationCommissioned !== null && viewModel.model.installationCommissioned !== undefined && (
                        <div>
                            <DeviceDataRow>
                                <DeviceAttributeCell key={generateID()}>
                                    <div className="main installheight">
                                        <div className="title">Commission Date:</div>
                                        <div className="data">{viewModel.getCommisionDate}</div>
                                    </div>
                                </DeviceAttributeCell>
                                <DeviceAttributeCell key={generateID()}>
                                    <div className="main installheight">
                                        <div className="title">Device:</div>
                                        <div className="data">{viewModel.getDeviceName}</div>
                                    </div>
                                </DeviceAttributeCell>
                                <DeviceAttributeCell key={generateID()}>
                                    <div className="main installheight">
                                        <div className="title">Project:</div>
                                        <div className="data">{viewModel.getProjectDetails}</div>
                                    </div>
                                </DeviceAttributeCell>
                            </DeviceDataRow>
                            <InstallationDivisor />
                            <DeviceDataRow>
                                <DeviceAttributeCell key={generateID()}>
                                    <div className="main installheight">
                                        <div className="title">Contractor:</div>
                                        <div className="data">{viewModel.getContractorName}</div>
                                    </div>
                                </DeviceAttributeCell>
                                <DeviceAttributeCell key={generateID()}>
                                    <div className="main installheight">
                                        <div className="title">Installer:</div>
                                        <div className="data">{viewModel.getInstaller}</div>
                                    </div>
                                </DeviceAttributeCell>
                            </DeviceDataRow>
                            <InstallationDivisor />
                            <DeviceDataRow>
                                <DeviceAttributeCell key={generateID()}>
                                    <div className="main installheight">
                                        <div className="title">End User:</div>
                                        <div className="data">{viewModel.getClient}</div>
                                    </div>
                                </DeviceAttributeCell>
                                <DeviceAttributeCell key={generateID()}>
                                    <div className="main installheight">
                                        <div className="title">Property Address:</div>
                                        <div className="data">{viewModel.getAddress}</div>
                                    </div>
                                </DeviceAttributeCell>
                            </DeviceDataRow>
                            <DeviceDataRow>
                                <DeviceAttributeCell key={generateID()}>
                                    <div className="main installheight">
                                        <div className="title">Sensor Location Description: </div>
                                        <div className="data">{viewModel.getSensorLocationText}</div>
                                    </div>
                                </DeviceAttributeCell>
                            </DeviceDataRow>
                            <InstallationDivisor />

                            <DeviceAttributeBox>
                                <AttributeSummary deviceStatusData={viewModel.getCommissionedStatusData} />
                            </DeviceAttributeBox>

                            <InstallationDivisor />
                            <DeviceDataRow>
                                <DeviceAttributeCell key={generateID()}>
                                    <div className="main installheight">
                                        <div className="title">Install Notes: </div>
                                        <div className="data">{viewModel.getInstallNotes}</div>
                                    </div>
                                </DeviceAttributeCell>
                            </DeviceDataRow>
                            <DeviceDataRow>
                                <DeviceAttributeCell key={generateID()}>
                                    <div className="main installheight">
                                        <div className="title">Photos: </div>
                                    </div>
                                    {viewModel.hasImages === false && <Typography variant="caption">There are currently no images to show.</Typography>}
                                    {viewModel.hasImages === true && (
                                        <>
                                            <GalleryWrapper>
                                                {viewModel.model.generalImages.map((photo, index) => (
                                                    <>
                                                        <div key={"genimg" + index} className="gallery-image-wrapper">
                                                            <img
                                                                src={photo.thumbnailImageUrl}
                                                                width="100%"
                                                                onClick={(e: any) => {
                                                                    showImage(photo.id);
                                                                }}
                                                                className={"device-image " + (photo.flipped === false ? "" : "rotateimg180")}
                                                            />
                                                        </div>
                                                    </>
                                                ))}
                                            </GalleryWrapper>
                                            {showImageModal.showImageModal === true && (
                                                <InstallationCommissionImageModal
                                                    images={viewModel.model.generalImages}
                                                    isOpen={showImageModal.showImageModal}
                                                    showFlipOption={true}
                                                    /*  className?: string; */

                                                    initialImageId={showImageModal.selectedId}
                                                    onHandleCloseModal={closeImageModal}
                                                />
                                            )}
                                        </>
                                    )}
                                </DeviceAttributeCell>
                            </DeviceDataRow>
                            <DeviceDataRow>
                                <DeviceAttributeCell key={generateID()}>
                                    <div className="main installheight">
                                        <div className="title">Drainage Photos:</div>
                                    </div>
                                    {viewModel.hasDrainageImages === false && <Typography variant="caption">There are currently no drainage images to show.</Typography>}
                                    {viewModel.hasDrainageImages === true && (
                                        <>
                                            <GalleryWrapper>
                                                {viewModel.model.drainageImages.map((photo, index) => (
                                                    <>
                                                        <div
                                                            key={"drimg" + index}
                                                            className="gallery-image-wrapper"
                                                            onClick={(e: any) => {
                                                                showDrainageImage(photo.id);
                                                            }}
                                                        >
                                                            <img
                                                                src={photo.thumbnailImageUrl}
                                                                width="100%"
                                                                className={"device-image " + (photo.flipped === false ? "" : "rotateimg180")}
                                                            />
                                                        </div>
                                                    </>
                                                ))}
                                            </GalleryWrapper>
                                            {showDrainageImageModal.showImageModal === true && (
                                                <InstallationCommissionImageModal
                                                    images={viewModel.model.drainageImages}
                                                    isOpen={showDrainageImageModal.showImageModal}
                                                    showFlipOption={true}
                                                    /*  className?: string; */

                                                    initialImageId={showDrainageImageModal.selectedId}
                                                    onHandleCloseModal={closeDrainageModal}
                                                />
                                            )}
                                        </>
                                    )}
                                </DeviceAttributeCell>
                            </DeviceDataRow>
                        </div>
                    )}

                    <DeviceDataRow></DeviceDataRow>
                </AnalyticsBox>
            </GutterSensTabContent>
        );
    }
    return useObserver(() => renderPage());
}
