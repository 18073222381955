import React from "react";
import { useTheme } from "@material-ui/core";
import { defaultTheme as theme } from "../../../Custom/StylesAppSpecific/AppStyling";
import { pointer } from "d3";

type Props = {
    width?: string | number;
};
export const ResetSearchIcon: React.FC<Props> = (props: Props) => {
    const theme = useTheme();

    return (
        <div
            style={{
                zIndex: 999,
                borderRadius: "0px 5px 5px 0px",
                fontSize: "16px",
                fontWeight: "normal",
                marginRight: "-1px",
                cursor: "pointer !important",
                height: "32px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "5px 10px",
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.common.white,
                boxShadow: "inset 0px 30px 6px #fffff3d",
            }}
        >
            <span>clear</span>
        </div>
    );
};
