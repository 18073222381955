import { useContext } from "react";
import * as React from "react";

import { Stores, StoresContext } from "Custom/Stores";

import { PrivateRoute } from "Core/Utils";
//import { InstallationView } from "./InstallationView";
import { Client } from "Custom/Globals/AppUrls";

//import { Redirect } from "react-router-dom";

import * as RouterUtil from "Custom/Utils/routing";
import { InstallationsView } from "Custom/Views/Installations/InstallationList/InstallationsView";
import { PropertyDetailsPage } from "../Properties/PropertyDetailsPage";
import { InstallationView } from "./InstallationView";

export const InstallationRoutes: React.FC = () => {
    const store = useContext<Stores>(StoresContext);

    return (
        <>
            <PrivateRoute
                component={InstallationsView}
                exact
                isAllowed={() => {
                    return store.domain.AccountStore.IsLoggedIn && RouterUtil.requireAdminLogin(store);
                }}
                path={Client.Main.Device.Root}
            />
            <PrivateRoute
                component={PropertyDetailsPage}
                exact
                isAllowed={() => {
                    return store.domain.AccountStore.IsLoggedIn && RouterUtil.requireAdminLogin(store);
                }}
                path={Client.Main.Device.PropertyDetail}
            />
            <PrivateRoute
                component={InstallationView}
                exact
                isAllowed={() => {
                    return store.domain.AccountStore.IsLoggedIn && RouterUtil.requireAdminLogin(store);
                }}
                path={Client.Main.Device.PropertyInstallation}
            />
            <PrivateRoute
                component={InstallationView}
                exact
                isAllowed={() => {
                    return store.domain.AccountStore.IsLoggedIn && RouterUtil.requireAdminLogin(store);
                }}
                path={Client.Main.Device.Installation}
            />
            {/*             <PrivateRoute
                component={InstallationView}
                exact
                isAllowed={() => {
                    return store.domain.AccountStore.IsLoggedIn && RouterUtil.requireAnyLoggedIn(store);
                }}
                path={Client.Project.Property.PropertyInstallation}
            /> */}
        </>
    );
};
