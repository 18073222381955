import React from "react";
import { Box, Typography, useMediaQuery } from "@material-ui/core";
import styled from "styled-components";

export const Main = styled.section`
    width: 100%;
    min-width: 320px;
    min-height: 320px;
    height: auto;

    border: 1px solid black;

    .leaflet-container {
        min-width: 100%;
        min-height: 100%;
    }

    .roofcontrol {
        z-index: 2000;
    }

    @media screen and (max-width: 1024px) {
    }

    @media screen and (max-width: 640px) {
    }

    .marker-with-number {
        position: relative;

        .marker-number {
            position: absolute;
            top: 10px;
            left: 14px;
            color: white;
        }
    }
`;
