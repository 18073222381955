import styled from "styled-components";
import SwitchLogo from "Custom/Content/listMapSelector.svg";

export const ProjectToolbarRow: any = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1 0 0;
    width: 100%;
    justify-content: space-evenly;

    .title {
        font-size: 18px;
        line-height: 28px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-top: 18px;
    }
`;

export const ProjectToolbarRowV2: any = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-height: 64px;

    & .search-title {
        font-size: 18px;
    }

    & .search-field {
        /* padding-left: 16px; */
        width: 420px;
    }

    & .download-csv {
    }

    & .add-new-project {
        margin-left: auto;
    }
`;

export const ProjectToolbarRowV3: any = styled.div`
    display: flex;
    margin-bottom: 15px;

    & > div {
        flex: 1;

        & > div {
            display: flex;

            & > div {
                min-width: 175px;
            }

            & h1 {
                margin-right: 6px;
            }
        }
    }

    .multiselectbox {
        margin: 0px;

        > div {
            margin-top: 0px;

            .MuiInputBase-input {
                padding-bottom: 6px;
            }
        }
    }
`;

export const ProjectToolbarDownload: any = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1 0 0;
    margin-top: 22px;
`;

export const ToolbarAddWrapper: any = styled.div`
    right: 30px;
    margin-top: 22px;
    // position: absolute;
    z-index: 100;
    display: flex;
    flex-direction: row;
    flex: 1 0 0;
    max-width: 140px;

    /*  background-color: green; */

    img {
        max-width: 18px;
        max-height: 18px;
    }
`;

export const ToolbarSearchSwitchWrapper: any = styled.div`
    //right: 30px;
    //font-sizemargin-top: 0px;
    position: relative;
    z-index: 100;
    display: flex;
    flex-direction: row;
    align-items: center;
    // background-color: yellow;

    padding: 30px;
`;

export const ToolbarAddInstalltionWrapper: any = styled.div`
    // margin-bottom: -30px;
    position: relative;
    z-index: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    // background-color: orange;
    // opacity: 0.3;

    padding: 0 30px 0 30px;
`;

export const AdminSearchWrapper: any = styled.div`
    position: relative;
    z-index: 100;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    // background-color: yellow;
    height: 93px;
    padding: 30px;
`;

export const ToolbarUnitOptions: any = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1 0 0;
    justify-content: flex-end;
`;

export const SwitchOption: any = styled.div`
    width: 68px;
    background-repeat: no-repeat;
    height: 40px;

    &.listView {
        background: url(${SwitchLogo}); // These make the image disappear no-repeat center center fixed
        // background-color: pink;
    }

    &.mapView {
        background: url(${SwitchLogo}) 68px 0;
        // background-color: red;
    }
`;

export const BoxAddWrapper: any = styled.div`
    right: 30px;
    margin-top: 20px;
    position: absolute;
    z-index: 100;
    display: flex;
    flex-direction: row;
`;

export const BoxAdd = styled.div`
    display: flex;
    flex-direction: row;
    align-items: left;
    flex: 1 0 0;
`;
