import { observable } from "mobx";
import { ModelBase } from "Core/Models/ModelBase";
import { IsNotEmpty } from "class-validator";

//Base class expects the model type and the DTO model type
//If you are not using a DTO just pass in undefined as the DTO
export class AdminProjectPaymentStatusTabModel extends ModelBase<AdminProjectPaymentStatusTabModel, ProjectsStatusForListItem> {
    public id: number = -1;

    @observable
    @IsNotEmpty({ message: "You must enter a status name!!" })
    public statusName: string = "";

    @observable
    @IsNotEmpty({ message: "You must enter a background colour!!" })
    public colorHex: string = "";

    @observable
    @IsNotEmpty({ message: "You must enter a text colour!!" })
    public textColorHex: string = "";

    public createdDate: string | null = null;
    public createdBy: string = "";
    @observable public isDeleted: boolean = false;
    @observable public ordinal: number = 0;

    public author: string = "";
    public projectCount: number = 0;
    public rowVersion: string | null = null;

    //fromDto is required but you can leave it blank
    fromDto(model: ProjectsStatusForListItem): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
    }

    //toDto is required but you can leave it blank
    toDto(model: AdminProjectPaymentStatusTabModel): void {}
}

export interface ProjectsStatusForListItem {
    id: number;
    enumId: number;
    statusName: string;
    colorHex: string;
    textColorHex: string;
    createdDate: string;
    createdBy: string | null;
    isDeleted: boolean;
    ordinal: number;
    author: string;
    projectCount: number;
}
