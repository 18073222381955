import * as React from "react";
import * as Mui from "@material-ui/core";

import { DeleteModalDialogPaper } from "./DeleteModal.styles";
import { CancelBtn } from "../../StylesAppSpecific/Controls/CancelBtn";
import { SuccessBtn } from "Custom/StylesAppSpecific/Controls/SuccessBtn";
import { BaseModal } from "./BaseModal";
import { useObserver } from "mobx-react-lite";
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { Typography } from "@material-ui/core";
import moment from "moment";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { BorderlessBox, ContractorBox, DeviceBox, DeviceCell } from "Custom/Views/Units/UnitEditDataModel.styles";

interface IProps {
    open?: boolean;
    onClose(): void;
    onCommission(commissionDate: string | null): void;
    title: string;
    minDate: string;

    commissionDateTime: string | null;
}

export const CommissionModal: React.FC<IProps> = ({ open, onClose, onCommission, title, minDate, commissionDateTime }) => {
    const [commissionDate, setCommissionDate] = React.useState<MaterialUiPickersDate | undefined>(undefined);

    React.useEffect(() => {
        return () => {
            // Clean up after yourself
            setCommissionDate(undefined);
        };
    }, []);

    React.useEffect(() => {
        if (commissionDateTime === null || commissionDateTime === undefined || commissionDateTime.length === 0) {
            setCommissionDate(undefined);
        } else {
            setCommissionDate(moment.utc(commissionDateTime).toDate());
        }
    }, [commissionDateTime]);

    return useObserver(() => (
        <BaseModal
            open={open}
            onClose={onClose}
            title={title}
            PaperComponent={DeleteModalDialogPaper}
            actions={
                <>
                    <SuccessBtn
                        onClick={() => onCommission(moment(commissionDate!).toISOString())}
                        color="secondary"
                        autoFocus
                        variant="contained"
                        disabled={commissionDate === undefined}
                    >
                        Ok
                    </SuccessBtn>
                    <CancelBtn onClick={onClose}>Cancel</CancelBtn>
                </>
            }
        >
            <DeviceBox>
                <Typography style={{ fontSize: "14px" }}>Commission Date / Time:</Typography>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDateTimePicker
                        placeholder="01/01/2021"
                        label={""}
                        value={commissionDate}
                        mask={"__-__-____"}
                        format="DD-MM-YYYY HH:mm"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        ampm={false}
                        onChange={setCommissionDate}
                        minDate={moment.utc(minDate)}
                        maxDate={moment.utc()}
                    />
                </MuiPickersUtilsProvider>
            </DeviceBox>
            <BorderlessBox>
                {/* <p className="client-modal-description">{text}</p> */}
                <p className="client-modal-description">
                    Note: Setting the commission date will hide all installation / unit data (images, sensor values, notes, audit trail etc) previous to the date set.
                </p>
            </BorderlessBox>
        </BaseModal>
    ));
};
