import * as React from "react";
import * as MobXReact from "mobx-react-lite";
import { PropertyViewModel } from "../../../../Properties/PropertyViewModel";
import { PropertyDetailViewModel } from "../../../../CommonViews/PropertyDetails/PropertyDetailViewModel";
import { SuccessBtn } from "../../../../../StylesAppSpecific/Controls/SuccessBtn";
import { TextareaAutosize } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { isNullOrEmpty } from "../../../../../../Core/Utils/Utils";

interface IProps {
    viewModel: PropertyViewModel | PropertyDetailViewModel;
    isNew?: boolean;
}

export const AddEditPropertyNote: React.FC<IProps> = ({ viewModel, isNew }) => {
    const formNoteRef = React.useRef(viewModel.getValue("note"));

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        viewModel.setValue("note", e.target.value);
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        viewModel.setValue("note", viewModel.getValue("note") || null);
        viewModel.upsert();
    };

    const isDisabled = () => {
        if (isNew) {
            return isNullOrEmpty(viewModel.getValue("note"));
        } else {
            return viewModel.getValue("note") === formNoteRef.current;
        }
    };

    return MobXReact.useObserver(() => (
        <form onSubmit={handleSubmit}>
            {isNew && (
                <Typography variant="h1" component="h3" className="add-note">
                    Add note
                </Typography>
            )}
            <TextareaAutosize value={viewModel.getValue("note")} onChange={handleChange} className="note-textarea" />
            <hr />
            <SuccessBtn className="add-btn" type="submit" disabled={isDisabled()}>
                {isNew ? "Add" : "Save"}
            </SuccessBtn>
        </form>
    ));
};
