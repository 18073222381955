import * as React from "react";
import { AuditModalDialogPaper, AuditModalWrapper } from "./AuditModal.style";
import { CancelBtn } from "Custom/StylesAppSpecific/Controls/CancelBtn";
import { BaseModal } from "Custom/Components/Modal/BaseModal";
import { AuditModalViewModel } from "./AuditModalViewModel";
import { useObserver } from "mobx-react-lite";
import { formatDateTime, formatPPAddress } from "Custom/Utils/format";
import { AuditType, InstallationAuditModelDTO } from "../Audit/InstallationAuditModel";
import { AuditEmailSection } from "../Audit/1000_SentAlertEmail/AuditEmail";
import { AuditInstallationSection } from "../Audit/100_Installation/AuditInstallation";
import { AuditTrippedAlertSection } from "../Audit/1050_AlertTripped/AuditTrippedAlert";
import { AuditSMSSection } from "../Audit/1010_SentSMS/AuditSMS";
import { IsAuditTypeAlerter } from "Custom/Utils/utils";
import { SuccessBtn } from "Custom/StylesAppSpecific/Controls/SuccessBtn";

interface IProps {
    open?: boolean;
    onClose(): void;
    title: string;
    auditItem: InstallationAuditModelDTO | undefined;
}

export const AuditModal: React.FC<IProps> = ({ open, onClose, title, auditItem }) => {
    const [viewModel] = React.useState(() => new AuditModalViewModel());

    React.useEffect(() => {
        let promise = viewModel.loadData();

        promise.then((result) => {});

        return () => {
            // Clean up after yourself
            viewModel.clear();
        };
    }, []);

    React.useEffect(() => {
        viewModel.setItem(auditItem);
    }, [auditItem]);

    const getItemJsonData = (auditTypeEnum: AuditType) => {
        if (viewModel.getAuditItem !== undefined) {
            switch (auditTypeEnum) {
                case AuditType.Cleaning: {
                    break;
                }
                case AuditType.Commissioned: {
                    break;
                }
                case AuditType.Decommissioned: {
                    break;
                }
                case AuditType.Installation: {
                    return <AuditInstallationSection data={viewModel.getAuditItem} />;
                    break;
                }
                case AuditType.Maintainance: {
                    break;
                }
                case AuditType.MovedUnit: {
                    break;
                }
                case AuditType.StandingWaterEmail:
                case AuditType.SentAlertEmail: {
                    return <AuditEmailSection data={viewModel.getAuditItem} />;
                }
                case AuditType.StandingWaterSMS:
                case AuditType.SentAlertSMS: {
                    return <AuditSMSSection data={viewModel.getAuditItem} />;
                    break;
                }
                case AuditType.SentPhotoCommand: {
                    break;
                }
                case AuditType.SentUnitCommand: {
                    break;
                }
                case AuditType.Cleaning: {
                    break;
                }

                case AuditType.UnitAlertTripped: {
                    return <AuditTrippedAlertSection data={viewModel.getAuditItem} />;
                }

                case AuditType.Unknown:
                default: {
                    break;
                }
            }
        }

        return <div>Error loading audit item</div>;
    };

    let author: string = "";

    if (auditItem !== undefined) {
        author = IsAuditTypeAlerter(auditItem.auditTypeEnum, auditItem.createdBy!) === true ? "Alerter" : auditItem.author;
    }

    return useObserver(() => (
        <BaseModal
            open={open}
            onClose={onClose}
            title={title}
            PaperComponent={AuditModalDialogPaper}
            actions={
                <>
                    <SuccessBtn onClick={onClose}>Close</SuccessBtn>
                </>
            }
        >
            {auditItem !== undefined && (
                <AuditModalWrapper>
                    <div className="row">
                        <div className="cell-left">{formatDateTime(auditItem.createdDate)}</div>
                        <div className="cell-right">{author}</div>
                    </div>
                    <div className="row">{getItemJsonData(auditItem.auditTypeEnum)}</div>
                    <div className="row auditrow">
                        <div className="title">Comment: </div>
                        <div>{auditItem.comments}</div>
                    </div>
                </AuditModalWrapper>
            )}
            {auditItem === undefined && <div> Error: Data is missing</div>}
        </BaseModal>
    ));
};
