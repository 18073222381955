import * as React from "react";
import * as Mui from "@material-ui/core";

import { MovePropertyModalDialogPaper } from "./MovePropertyModal.style";
import { CancelBtn } from "Custom/StylesAppSpecific/Controls/CancelBtn";
import { BaseModal } from "Custom/Components/Modal/BaseModal";
import { SuccessBtn } from "Custom/StylesAppSpecific/Controls/SuccessBtn";
import { MovePropertyModalViewModel } from "./MovePropertyModalViewModel";
import { InstallationDTO, InstallationListItem } from "Custom/Models/Domain";
import { useObserver } from "mobx-react-lite";
import { ProjectPropertyListItemModel } from "./ProjectPropertyListItemModel";
import { formatPPAddress } from "Custom/Utils/format";
import { Autocomplete } from "@material-ui/lab";

interface IProps {
    open?: boolean;
    onClose(): void;
    onMoveProperty(device: InstallationDTO): void;
    title: string;
    device: InstallationListItem | undefined;
}

export const MovePropertyModal: React.FC<IProps> = ({ open, onClose, onMoveProperty, title, device }) => {
    const [viewModel] = React.useState(() => new MovePropertyModalViewModel());
    const [propertySelected, setPropertySelected] = React.useState<ProjectPropertyListItemModel | undefined>(undefined);

    React.useEffect(() => {
        let promise = viewModel.loadData();

        promise.then((result) => {});

        return () => {
            // Clean up after yourself
            viewModel.clear();
        };
    }, []);

    React.useEffect(() => {
        viewModel.setDevice(device);
    }, [device]);

    let text: string = "No address set.";

    if (device !== undefined && device !== null) {
        let property: ProjectPropertyListItemModel | undefined = viewModel.getProjectProperties.find((a) => a.id === device.roofcareAddressId);

        if (property !== undefined) {
            text = "Current Address: " + formatPPAddress(property, true);
        }
    }

    const onSave = (): void => {
        if (propertySelected == undefined || (device !== null && device !== undefined && propertySelected.id === device!.roofcareAddressId)) {
            onClose();
        } else {
            let promise = viewModel.upsertDeviceProperty(propertySelected);

            promise.then((result) => {
                if (result.wasSuccessful === true) {
                    onMoveProperty(result.payload);
                }
            });
        }
    };

    const handlePropertyChange = (value: ProjectPropertyListItemModel): void => {
        if (value !== null && value !== undefined) {
            setPropertySelected(value);
        } else {
            setPropertySelected(undefined);
        }
    };

    return useObserver(() => (
        <BaseModal
            open={open}
            onClose={onClose}
            title={title}
            PaperComponent={MovePropertyModalDialogPaper}
            actions={
                <>
                    <SuccessBtn onClick={onSave}>Update</SuccessBtn>
                    <CancelBtn onClick={onClose}>Cancel</CancelBtn>
                </>
            }
        >
            <p className="pp-modal-description">{text}</p>
            <div style={{ marginBottom: "15px" }}>
                <Autocomplete
                    blurOnSelect
                    id="pp-autocomplete"
                    disableClearable
                    options={viewModel.getProjectProperties}
                    getOptionLabel={(option: ProjectPropertyListItemModel) => option.projectNumber + " : " + formatPPAddress(option, true)}
                    onChange={(event: any, value: ProjectPropertyListItemModel) => handlePropertyChange(value)}
                    value={viewModel.getProjectProperties.find((a) => a.id === (propertySelected === undefined ? -1 : propertySelected!.id))}
                    renderInput={(params: JSX.IntrinsicAttributes & Mui.TextFieldProps) => {
                        return (
                            <Mui.TextField
                                {...params}
                                variant="standard"
                                placeholder="Project - Properties"
                                InputProps={{
                                    ...params.InputProps,
                                    style: { padding: "0 10px" },
                                }}
                            />
                        );
                    }}
                />
            </div>
        </BaseModal>
    ));
};
