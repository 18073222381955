import * as React from "react";
import { InstallationListContainer } from "./InstallationList.styles";
import EditLogo from "Custom/Content/editIcon.svg";
import PropertyLogo from "Custom/Content/home-225.svg";
import * as MobXReact from "mobx-react-lite";
import { InstallationAddModal } from "./InstallationAddModal";
import { useRouter } from "../../../../../../Core/Utils/Hooks/UseReactRouter";
import { AppUrls } from "../../../../../Globals";
import { PropertyViewModel } from "../../../../Properties/PropertyViewModel";
import { Stores, StoresContext } from "Custom/Stores";
import * as RouterUtil from "Custom/Utils/routing";
import { formatDateTime } from "Custom/Utils/format";
import { ClickableImgCell } from "Custom/Components/Table/ClickableImgCell";
import { AddNewBtn } from "Custom/StylesAppSpecific/Controls/AddNewBtn";
import { Addeditsection } from "Custom/Views/Installations/NotesTableRow.style";
import { RoofcareTable, RoofcareTableWrapperFullWidth } from "Custom/Components/Table/RoofcareTable";
import { ResetSearchIcon } from "Custom/Components/Table/ResetSearchIcon";
import { Paper } from "@material-ui/core";
import { UnitStatusBox } from "Custom/Components";

import * as Defaults from "Custom/StylesAppSpecific/TableOptions";
import { DeleteModal } from "Custom/Components/Modal/DeleteModal";

interface IProps {
    viewModel: PropertyViewModel;
    reload: () => void;
}

export interface InstallationModalData {
    open: boolean;
    id: string;
}

export const InstallationList: React.FC<IProps> = ({ viewModel, reload }) => {
    const store = React.useContext<Stores>(StoresContext);
    const router = useRouter<{ projectId: string; propertyId: string; clientId?: string }>();
    const [newInstallationOpen, setNewInstallationOpen] = React.useState<InstallationModalData>({ open: false, id: "0" });
    const [deleteOpen, setDeleteOpen] = React.useState(false);
    const [selectedDevice, selectDevice] = React.useState("");

    const handleNewClick = () => {
        setNewInstallationOpen({ open: true, id: "0" });
    };

    const handleNewInstallationClose = (saved: boolean) => {
        setNewInstallationOpen({ open: false, id: "0" });

        if (saved === true) {
            reload();
        }
    };

    const tableOptions = Defaults.GetRoofcareTableOptions() as any;

    const handleDeleteOpen = () => {
        setDeleteOpen(true);
    };

    const handleDeleteClose = () => {
        setDeleteOpen(false);
        selectDevice("");
    };

    const handleDeleteFromProperty = () => {
        const deletedItem: string = selectedDevice;
        viewModel.removeDeviceFromProperty(deletedItem).then(() => {
            setDeleteOpen(false);
            selectDevice("");
        });
    };

    const handleDeviceClick = (deviceId: number) => {
        const { projectId, propertyId, clientId } = router.match.params;
        const { path } = router.match;
        let url: string = AppUrls.Client.Main.Project.Property.PropertyInstallation;

        switch (path) {
            case AppUrls.Client.ClientView.Project.PropertyDetail: {
                url = AppUrls.Client.ClientView.Project.PropertyInstallation;
                break;
            }
            case AppUrls.Client.Main.Client.Project.PropertyDetail: {
                url = AppUrls.Client.Main.Client.Project.PropertyInstallation.replace(":clientId", clientId!);
                break;
            }
            case AppUrls.Client.Main.Client.Property.ClientsDetailProperty: {
                url = AppUrls.Client.Main.Client.Property.PropertyInstallation.replace(":clientId", clientId!);
                break;
            }
            case AppUrls.Client.Main.Device.PropertyDetail: {
                url = AppUrls.Client.Main.Device.PropertyInstallation;
                break;
            }
            default: {
                break;
            }
        }

        router.history.push(
            url
                .replace(":projectId", projectId)
                .replace(":propertyId", propertyId)
                .replace(":installId", deviceId?.toString() || ""),
        );
    };

    const handleEditClick = (id: string) => {
        setNewInstallationOpen({ open: true, id: id });
    };

    const formatEdit = (id: string): any => {
        return <ClickableImgCell logo={EditLogo} text={"Edit installation"} onClick={handleEditClick} id={id} />;
    };

    const formatRemove = (id: string): any => {
        return <ClickableImgCell className="house" logo={PropertyLogo} text={"Delete from property"} onClick={handleDeletePropertyClick} id={id} />;
    };

    const handleDeletePropertyClick = (id: string) => {
        setDeleteOpen(true);
        selectDevice(id);
    };

    const handleRowClick = (row: any) => {
        handleDeviceClick(row.id);
    };

    let showABC: boolean = store.domain.AccountStore.containsRoles(["admin", "regionalsalesmanager", "contractoradmin"]);

    let commonColumns: any[] = [
        {
            width: "auto",
            title: "Name",
            field: "name",
            sorting: true,
            filtering: true,
            render: (rowData: any) => {
                if (rowData !== null && rowData !== undefined) {
                    return <div onClick={() => handleRowClick(rowData)}>{rowData.name}</div>;
                }
            },
        },
        {
            width: "auto",
            title: "Sim id",
            field: "simId",
            sorting: true,
            filtering: true,
            render: (rowData: any) => {
                if (rowData !== null && rowData !== undefined) {
                    return <div onClick={() => handleRowClick(rowData)}>{rowData.simId}</div>;
                }
            },
        },
        {
            width: "auto",
            title: "Contractor",
            field: "contractorName",
            sorting: true,
            filtering: true,
            render: (rowData: any) => {
                if (rowData !== null && rowData !== undefined) {
                    return <div onClick={() => handleRowClick(rowData)}>{rowData.contractorName}</div>;
                }
            },
        },
        {
            width: "auto",
            title: "Date Created",
            field: "createdDate",
            sorting: true,
            filtering: true,
            render: (rowData: any) => {
                if (rowData !== null && rowData !== undefined) {
                    return <div onClick={() => handleRowClick(rowData)}>{formatDateTime(rowData.createdDate)}</div>;
                }
            },
        },
        {
            width: "auto",
            title: "Last Reading",
            field: "getLatestReadingTime",
            sorting: true,
            filtering: true,
            render: (rowData: any) => {
                if (rowData !== null && rowData !== undefined) {
                    return <div onClick={() => handleRowClick(rowData)}>{formatDateTime(rowData.getLatestReadingTime)}</div>;
                }
            },
        },
    ];

    let abcColumns: any[] = [
        {
            maxWidth: "68px",
            title: "Alert",
            field: "hasAlertAction",
            sorting: true,
            /* defaultSort: getSortDirection(8), */
            render: (rowData: any) => {
                {
                    return rowData.hasAlertAction ? (
                        <div className="central-cell">
                            <div className="tick">
                                <span>&#10003;</span>
                            </div>
                            {rowData.hasValidEmailAddesses && <div className="alerticon tick fa fa-envelope"></div>}
                            {rowData.hasValidEmailAddesses == false && <div className="alerticon cross fa fa-envelope"></div>}
                            {rowData.hasValidMobileNumbers && <div className="alerticon tick fa fa-mobile"></div>}
                            {rowData.hasValidMobileNumbers == false && <div className="alerticon cross fa fa-mobile "></div>}
                        </div>
                    ) : (
                        <div className="central-cell">
                            <div className="cross">
                                <span>&#88;</span>
                            </div>
                            {rowData.hasValidEmailAddesses && <div className="alerticon tick fa fa-envelope"></div>}
                            {rowData.hasValidEmailAddesses == false && <div className="alerticon cross fa fa-envelope"></div>}
                            {rowData.hasValidMobileNumbers && <div className="alerticon tick fa fa-mobile"></div>}
                            {rowData.hasValidMobileNumbers == false && <div className="alerticon cross fa fa-mobile "></div>}
                        </div>
                    );
                }
            },
        },
        {
            maxWidth: "95px",
            title: "Behaviour",
            field: "hasUnitBehaviour",
            sorting: true,
            /* defaultSort: getSortDirection(9), */
            render: (rowData: any) => {
                {
                    return rowData.hasUnitBehaviour ? (
                        <div className="central-cell">
                            <div className="tick">
                                <span>&#10003;</span>
                            </div>
                        </div>
                    ) : (
                        <div className="central-cell">
                            <div className="cross">
                                <span>&#88;</span>
                            </div>
                        </div>
                    );
                }
            },
        },
        {
            maxWidth: "95px",
            title: "Condition",
            field: "hasConditionSet",
            sorting: true,
            /*  defaultSort: getSortDirection(10), */
            render: (rowData: any) => {
                {
                    return rowData.hasConditionSet ? (
                        <div className="central-cell">
                            <div className="tick">
                                <span>&#10003;</span>
                            </div>
                        </div>
                    ) : (
                        <div className="central-cell">
                            <div className="cross">
                                <span>&#88;</span>
                            </div>
                        </div>
                    );
                }
            },
        },
    ];

    let statusEditColumns: any[] = [
        {
            width: "auto",
            title: "Status",
            field: "status",
            sorting: true,
            filtering: true,
            render: (rowData: any) => {
                if (rowData !== null && rowData !== undefined) {
                    return (
                        <UnitStatusBox
                            onClick={() => handleRowClick(rowData)}
                            className={"device-status "}
                            backgroundColor={rowData.status.statusColour}
                            textColour={rowData.status.statusTextColour}
                        >
                            <div className="status-name">{rowData.status.name}</div>
                        </UnitStatusBox>
                    );
                }
            },
        },
        {
            width: "auto",
            title: "",
            field: "status",
            sorting: false,
            filtering: false,
            render: (rowData: any) => {
                {
                    return <>{canShowAddInstallation && <div className="device-editinstallation">{formatEdit(rowData.id!.toString())}</div>}</>;
                }
            },
        },
    ];

    if (showABC) {
        statusEditColumns.push({
            width: "auto",
            title: "",
            field: "status",
            sorting: false,
            filtering: false,
            render: (rowData: any) => {
                {
                    return <>{canShowAddInstallation && <div className="device-removeProperty">{formatRemove(rowData.id!.toString())}</div>}</>;
                }
            },
        });
    }
    let displayedColumns: any[] = commonColumns;

    if (showABC) {
        displayedColumns.push(...abcColumns);
    }

    displayedColumns.push(...statusEditColumns);

    const renderDevices = () => (
        <RoofcareTableWrapperFullWidth key="condition-set-table" paddingTop="5px !important">
            <RoofcareTable
                id="property-installation-table"
                className="property-installation-table"
                columns={displayedColumns}
                options={{
                    search: false,
                    toolbar: false,
                    thirdSortClick: false,
                    pageSize: 50,
                    pageSizeOptions: [10, 20, 50, 100],
                    emptyRowsWhenPaging: false,
                    headerStyle: {
                        ...tableOptions.headerStyle,
                    },
                    rowStyle: {
                        ...tableOptions.rowStyle,
                    },
                    filterCellStyle: {
                        ...tableOptions.filterCellStyle,
                    },
                    searchFieldAlignment: "left",
                    searchFieldStyle: {
                        border: "1px solid #333333",
                        borderRadius: "5px",
                        width: "400px",
                    },
                }}
                data={viewModel.getInstallations}
                title=""
                icons={{
                    ResetSearch: () => <ResetSearchIcon />,
                }}
                components={{ Container: (props: any) => <Paper {...props} elevation={0} /> }}
                localization={{ toolbar: { searchPlaceholder: "Start typing to search" } }}
            />
        </RoofcareTableWrapperFullWidth>
    );

    const canShowAddInstallation: boolean = RouterUtil.requireContractorAdminorAdminLogin(store) && viewModel.model.okToAddInstallation === true;

    return MobXReact.useObserver(() => (
        <>
            <InstallationAddModal open={newInstallationOpen.open} onClose={handleNewInstallationClose} propertyViewModel={viewModel} id={newInstallationOpen.id} />
            <DeleteModal
                open={deleteOpen}
                onClose={handleDeleteClose}
                onDelete={handleDeleteFromProperty}
                text="Are you sure you want to remove this device from it's property? This will remove all the gutter information"
                title="Remove Device From Property?"
            />
            <InstallationListContainer>
                {canShowAddInstallation && (
                    <div className="add-wrapper">
                        <div className="add" role="button" tabIndex={0} onClick={handleNewClick}>
                            <Addeditsection>
                                <AddNewBtn>
                                    <span className="btnText">Add new installation</span>
                                </AddNewBtn>
                            </Addeditsection>
                        </div>
                    </div>
                )}
                {viewModel.devices.length === 0 ? (
                    <p className="no-installations">
                        There are currently no installations at this property.{" "}
                        {canShowAddInstallation && (
                            <span className="dummy-list" role="button" tabIndex={0} onClick={handleNewClick}>
                                Click here to add a new installation
                            </span>
                        )}
                    </p>
                ) : (
                    renderDevices()
                )}
            </InstallationListContainer>
        </>
    ));
};
