import React, { useEffect, useState } from "react";
import * as Mui from "@material-ui/core";
import { useObserver } from "mobx-react-lite";
import { useRouter } from "Core/Utils";
import { ResetSearchIcon } from "Custom/Components/Table/ResetSearchIcon";
import { roofcareModalStyle } from "Custom/StylesAppSpecific/ModalStyling";
import { ContractorListViewModel } from "./ContractorListViewModel";

import { RoofcareTable } from "Custom/Components/Table/RoofcareTable";
import { Container } from "Custom/StylesAppSpecific/AppStyling";
import { BoxAddWrapper } from "Custom/StylesAppSpecific/Controls/BoxAddWrapper";
import { AppUrls } from "Custom/Globals";
import { createStyles, makeStyles } from "@material-ui/core";

import { formatDelete, formatEdit } from "Custom/Components/AddEditControls";
import { Loader } from "Core/Components";
import { ProjectButton } from "../CustomComponents";
import { ContractorListItemModel } from "Custom/Models/Domain";

import * as Defaults from "Custom/StylesAppSpecific/TableOptions";

const useModalStyles = makeStyles((theme: Mui.Theme) =>
    createStyles({
        paper: {
            position: "relative",
            top: "5%",
            width: "90%",
            height: "90%",
            marginLeft: "auto",
            marginRight: "auto",
            backgroundColor: theme.palette.background.paper,
            border: "1px solid #000",
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
            zIndex: 9000,
        },
    }),
);

export const ContractorListPage: React.FC = () => {
    const deleteModalClasses = roofcareModalStyle();
    const classes = useModalStyles();
    const [viewModel] = useState(() => new ContractorListViewModel());
    const theme = Mui.useTheme();
    const { history } = useRouter();

    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [deleteOpen, setDeleteOpen] = React.useState(false);
    const [selectedContractor, selectContractor] = React.useState("");

    const handleDeleteOpen = () => {
        setDeleteOpen(true);
    };

    const handleDeleteClose = () => {
        setDeleteOpen(false);
        selectContractor("");
    };

    const handleDeleteUser = () => {
        viewModel.deleteContractor(selectedContractor).then(() => {
            setDeleteOpen(false);
            selectContractor("");
        });
    };

    const deleteContractorClick = (id: string) => {
        selectContractor(id);
        handleDeleteOpen();
    };

    useEffect(() => {
        const apiResult = viewModel.loadContractorListAsync();
        apiResult.then((result) => {});

        return () => {
            // Clean up after yourself
        };
    }, []);

    const handleNewClick = () => {
        //handleOpen();
        // This is the correct way? history.push(AppUrls.Contractor.Contractor.ContractorsDetail.replace(":id", "0"));
        history.push(AppUrls.Client.Main.Contractor.ContractorAddEditDetail.replace(":id", "0"));
    };

    const editContractorClick = (id: string) => {
        history.push(AppUrls.Client.Main.Contractor.ContractorAddEditDetail.replace(":id", id));
    };

    const renderPage = () => {
        if (viewModel.IsLoading) return <Loader />;

        const tableOptions = Defaults.GetRoofcareTableOptions() as any;

        return (
            <Container>
                {
                    <>
                        <Mui.Dialog open={deleteOpen} onClose={handleDeleteClose} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
                            <Mui.Box className={deleteModalClasses.paper}>
                                <Mui.DialogTitle id="roofcare-dialog-title">Delete Contractor?</Mui.DialogTitle>
                                <Mui.DialogContent>
                                    <p id="client-modal-description">Are you sure you want to delete this contractor?</p>
                                </Mui.DialogContent>
                                <Mui.DialogActions>
                                    <Mui.Button onClick={handleDeleteClose} color="primary">
                                        Disagree
                                    </Mui.Button>
                                    <Mui.Button onClick={handleDeleteUser} color="secondary" autoFocus>
                                        Agree
                                    </Mui.Button>
                                </Mui.DialogActions>
                            </Mui.Box>
                        </Mui.Dialog>
                        <BoxAddWrapper>
                            <ProjectButton
                                icon={() => <span>&#43;</span>}
                                style={{
                                    marginBottom: "-15px",
                                }}
                                text="Add new contractor"
                                onClick={() => handleNewClick()}
                            />
                            {/* <BoxAdd onClick={() => handleNewClick()}>
                                        <img src={AddLogo} alt="Add new contractor" />
                                        <AddItemText> Add new contractor</AddItemText>
                                    </BoxAdd> */}
                        </BoxAddWrapper>

                        <RoofcareTable
                            columns={[
                                { title: "Company Name", field: "companyName", sorting: true, filtering: true },
                                { title: "Contact Name", field: "contactName", sorting: true, filtering: false },
                                { title: "Contact Number", field: "contactNumber", sorting: true, filtering: false },
                                { title: "Contact Email", field: "contactEmail", sorting: true, filtering: false },
                                { title: "Projects", field: "projectCount", sorting: true, filtering: false, width: "80px", maxWidth: "80px" },
                                {
                                    title: "",
                                    field: "id",
                                    sorting: false,
                                    filtering: false,
                                    render: (rowData: any) => {
                                        if (rowData !== null && rowData !== undefined) {
                                            return formatEdit(rowData.id, "contractor", editContractorClick);
                                        }
                                    },
                                },
                                {
                                    title: "",
                                    field: "id",
                                    sorting: false,
                                    filtering: false,
                                    render: (rowData: ContractorListItemModel) => {
                                        if (rowData !== null && rowData !== undefined && rowData.projectCount === 0) {
                                            return formatDelete(rowData.id, "contractor", deleteContractorClick);
                                        } else {
                                            return <div></div>;
                                        }
                                    },
                                },
                            ]}
                            style={{
                                backgroundColor: "#3B3C3B",
                                padding: 0,
                            }}
                            options={{
                                thirdSortClick: false,
                                cellStyle: {
                                    fontSize: 10,
                                },
                                pageSize: 50,
                                pageSizeOptions: [10, 20, 50, 100],
                                emptyRowsWhenPaging: false,
                                headerStyle: {
                                    ...tableOptions.headerStyle,
                                },
                                rowStyle: {
                                    ...tableOptions.rowStyle,
                                },
                                searchFieldAlignment: "left",
                                searchFieldStyle: {
                                    border: "1px solid #333333",
                                    borderRadius: "5px",
                                    width: "400px",
                                },
                            }}
                            data={viewModel.getAllowedContractors}
                            title=""
                            icons={{
                                ResetSearch: () => (
                                    <span style={{ marginRight: "-11px" }}>
                                        <ResetSearchIcon />
                                    </span>
                                ),
                            }}
                            components={{ Container: (props: any) => <Mui.Paper {...props} elevation={0} /> }}
                        />
                    </>
                }
            </Container>
        );
    };

    return useObserver(() => renderPage());
};
