import * as React from "react";
import * as MobXReact from "mobx-react-lite";
import * as MobX from "mobx";
import { PropertyDetailViewModel } from "./PropertyDetailViewModel";
import { Loader } from "Core/Components";
import { PropertyOverviewColumn, PropertyOverviewContainer } from "./PropertyOverview.style";
import { useRouter } from "Core/Utils";
import { PropertyOverviewViewModel } from "./PropertyOverviewViewModel";
import {
    OverViewButton,
    OverviewGraphBox,
    RecentAlertWrapper,
    StatusRow,
    TabCellHeader,
    TabOverviewCell,
    TabOverviewCellBlock,
} from "Custom/Views/Installations/Overview/TabOverview.style";
import { GutterSensOverviewGraph } from "Custom/Views/Installations/GutterSensOverviewGraph";
import { LineGraphState } from "Custom/Components/Graphs/BoxplotGraph";
import { generateID } from "Custom/Utils/utils";
import { CircularDisplay } from "Custom/Components/Displays/CircularDisplay";
import { UnitStatusBox } from "Custom/Components";
import { getStandingWaterBackgroundColour, getStandingWaterTextColour, StandingWaterBox } from "Custom/Components/StandingWater/StandingWater";
import { Button, MenuItem } from "@material-ui/core";
import { AppUrls } from "Custom/Globals";

interface IProps {
    parentViewModel: PropertyDetailViewModel;
}

export const PropertyOverview: React.FC<IProps> = ({ parentViewModel }) => {
    const [viewModel] = React.useState(() => PropertyOverviewViewModel.Instance);

    const router = useRouter<{ propertyId: string; projectId: string }>();
    const graphHeight: number = 260;
    const graphWidth: number = 285;
    const [counter, setCounter] = React.useState<number>(0);

    const [dimensions, setDimensions] = React.useState<LineGraphState>({
        width: graphWidth,
        height: graphHeight,
    });

    const componentRef: any = React.useRef(generateID());

    React.useEffect(() => {
        const { path, params } = router.match;
        const { propertyId, projectId } = router.match.params;
        viewModel.setProperty(propertyId);

        let promise = viewModel.loadAsync();

        promise.then((result) => {});

        return () => {
            // Clean up after yourself
        };
    }, []);

    React.useEffect(() => {
        viewModel.setNumberOfUnits(parentViewModel.getInstallations.length);
    }, [parentViewModel.getInstallations]);

    React.useEffect(() => {
        const count: number = viewModel.getRecordCount;
        setCounter(count);
    }, [viewModel.getRecordCount]);

    const gotoDevice = (unitid: number) => {
        const { propertyId, projectId } = router.match.params;
        const path = router.match.path;

        let url: string = AppUrls.Client.Main.EstateView.Property.PropertyInstallation;
        switch (path) {
            case AppUrls.Client.ClientView.Property.PropertyDetail: {
                url = AppUrls.Client.ClientView.Property.PropertyInstallation;
                break;
            }
            case AppUrls.Client.ContractorView.Property.PropertyDetail: {
                url = AppUrls.Client.ContractorView.Property.PropertyInstallation;
                break;
            }
            case AppUrls.Client.Main.EstateView.Property.PropertyDetail:
            default: {
                break;
            }
        }

        router.history.push(url.replace(":projectId", projectId).replace(":propertyId", propertyId).replace(":installId", unitid.toString()));
    };

    const renderPage = () => {
        if (parentViewModel.IsLoading || viewModel.IsLoading) return <Loader />;

        const disablePrev: boolean = viewModel.currentPage <= 0;
        const disableNext: boolean = viewModel.currentPage >= viewModel.totalPages - 1;

        return (
            <PropertyOverviewContainer>
                <Button variant="contained" color="primary" onClick={() => viewModel.prevPage()} disabled={disablePrev}>
                    <div className="circularbutton">
                        <i className="fa fa-long-arrow-left arrow" aria-hidden="true"></i>
                    </div>
                </Button>
                {viewModel.getUnits.map((unit, index) => (
                    <PropertyOverviewColumn className="">
                        {/*                         <TabOverviewCell className="overviewTitleBox">
                            <TabOverviewCellBlock className="overviewTitleCellBox">
                                <TabCellHeader>
                                    <div className="celltitle">{unit.name}</div>
                                </TabCellHeader>
                            </TabOverviewCellBlock>
                        </TabOverviewCell> */}
                        <TabOverviewCell>
                            <TabOverviewCellBlock>
                                <TabCellHeader className="tabcellName">
                                    <div className="celltitle" onClick={() => gotoDevice(unit.id)}>
                                        Current Status&nbsp;-&nbsp;<div className="celltitle-name">{unit.name}</div>
                                    </div>
                                </TabCellHeader>
                                <div style={{ height: "190px", paddingTop: "15px", paddingBottom: "15px", borderBottom: "1px dashed #585858" }}>
                                    <CircularDisplay
                                        centerData={unit.capacityValue.toString()}
                                        height={160}
                                        width={160}
                                        circleDiameter={160}
                                        fillColor="#3a3c41"
                                        strokeColor={unit.status !== null && unit.status !== undefined ? unit.status.statusColour : "#FFFFFF"}
                                        legendText="Capacity"
                                        legendInMiddle={true}
                                        arrow={unit.capacityArrow}
                                        strokeWidth={6}
                                        prefix="status"
                                    />
                                </div>
                                <div>
                                    <StatusRow className="first">
                                        <div className="status-row-title">Status</div>
                                        <div style={{ flex: "1 0 auto", maxWidth: "204px", margin: "0 auto" }}>
                                            {unit !== null && unit !== undefined && (
                                                <>
                                                    <UnitStatusBox
                                                        className={"device-status overview"}
                                                        backgroundColor={unit.status !== null && unit.status !== undefined ? unit.status.statusColour : "#FFFFFF"}
                                                        textColour={unit.status !== null && unit.status !== undefined ? unit.status.statusTextColour : "#000000"}
                                                    >
                                                        <div className="status-name">{unit.status !== null && unit.status !== undefined ? unit.status.name : "Unknown"}</div>
                                                    </UnitStatusBox>
                                                </>
                                            )}
                                        </div>
                                    </StatusRow>
                                    <StatusRow className="last">
                                        <div className="status-row-title">Standing Water</div>
                                        <div className="status-row-sw">
                                            {unit !== null && unit !== undefined && (
                                                <>
                                                    <StandingWaterBox
                                                        className={"standing-water overview"}
                                                        backgroundColor={unit !== null && unit !== undefined ? getStandingWaterBackgroundColour(unit.standingWaterEnum) : "#FFFFFF"}
                                                        textColour={unit !== null && unit !== undefined ? getStandingWaterTextColour(unit.standingWaterEnum) : "#000000"}
                                                    >
                                                        <div className="status-name">
                                                            {unit !== null && unit !== undefined ? unit.standingWaterPercent + "% over " + unit.standingWaterDays + " days" : ""}
                                                        </div>
                                                    </StandingWaterBox>
                                                </>
                                            )}
                                        </div>
                                    </StatusRow>
                                </div>
                            </TabOverviewCellBlock>
                        </TabOverviewCell>

                        <TabOverviewCell id="heightgraph-box">
                            <TabOverviewCellBlock id="heightgraph-cellblock">
                                <TabCellHeader>
                                    <div className="celltitle">
                                        Height Graph <div className="period">(last day)</div>
                                    </div>
                                </TabCellHeader>
                                {unit.readings.length === 0 && (
                                    <RecentAlertWrapper id="recentalertWrapper">
                                        <div className="noalerts">No sensor values for the period</div>
                                    </RecentAlertWrapper>
                                )}
                                {unit.readings.length > 0 && (
                                    <div style={{ height: "280px" }}>
                                        <OverviewGraphBox ref={componentRef} style={{ marginTop: "10px" }}>
                                            <GutterSensOverviewGraph
                                                graphHeight={dimensions.height}
                                                readings={unit.readings}
                                                graphWidth={dimensions.width}
                                                conditionSet={unit.conditionSet}
                                                deviceStatusData={unit.statusData}
                                                alertAction={undefined}
                                                showGraphHeader={false}
                                                showGraphLegend={false}
                                                backgroundColour={"#3a3c41"}
                                            />
                                        </OverviewGraphBox>
                                    </div>
                                )}
                                {/*                                 <OverViewButton onClick={() => changeTab(TAB_DEVICEDATA)}>
                                    <div className="buttontext">View Graph</div>
                                </OverViewButton> */}
                            </TabOverviewCellBlock>
                        </TabOverviewCell>
                    </PropertyOverviewColumn>
                ))}
                <Button variant="contained" color="primary" onClick={() => viewModel.nextPage()} disabled={disableNext}>
                    <div className="circularbutton">
                        <i className="fa fa-long-arrow-right arrow" aria-hidden="true"></i>
                    </div>
                </Button>
            </PropertyOverviewContainer>
        );
    };
    return MobXReact.useObserver(() => renderPage());
};
