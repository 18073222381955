import { computed, observable } from "mobx";
import { ModelBase } from "Core/Models/ModelBase";
import { IsNotEmpty } from "class-validator";

//Base class expects the model type and the DTO model type
//If you are not using a DTO just pass in undefined as the DTO
export class ProjectStatusAddEditDetailModel extends ModelBase<ProjectStatusAddEditDetailModel, ProjectsStatusForEdit> {
    public id: string | null = null;
    public rowVersion: string | null = null;

    @observable
    @IsNotEmpty({ message: "You must give the project status a name!!" })
    public statusName: string = "";

    public enumId: number = -1;

    public isDeleted: boolean = false;
    public createdBy: string | null = null;
    public createdDate: string | null = null;

    @observable
    public colorHex: string = "#FFFFFF";

    @observable
    public textColorHex: string = "#000000";

    @observable
    public ordinal: number = 1;

    public author: string = "";

    //fromDto is required but you can leave it blank
    fromDto(model: ProjectsStatusForEdit): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
    }

    //toDto is required but you can leave it blank
    toDto(model: ProjectStatusAddEditDetailModel): void {}
}

export interface ProjectsStatusForEdit {
    id: string | null;
    enumId: number;
    statusName: string;
    colorHex: string;
    textColorHex: string;
    createdDate: string | null;
    createdBy: string | null;
    isDeleted: boolean;
    ordinal: number;
    author: string;
    rowVersion: string | null;
}
