import { PrivateRoute } from "Core/Utils";
import * as React from "react";
import { AppUrls } from "Custom/Globals";
import { ContractorViewTabPage } from "Custom/Views/ContractorView/ContractorViewTabPage";
import * as RouterUtil from "Custom/Utils/routing";
import { StoresInstance } from "Core/Base";
import { ContractorLayout } from "Custom/Views/Layouts/ContractorLayout";
import { PropertyDetailPage } from "Custom/Views/CommonViews/PropertyDetails/PropertyDetailPage";
import { InstallationView } from "Custom/Views/Installations/InstallationView";
import { ProjectDetailsPage } from "Custom/Views/Projects/Details/ProjectDetailsPage";
import { ProjectAddEditPage } from "Custom/Views/Projects/ProjectAddEditPage";
import { ContractorDashboardView } from "Custom/Views/ContractorView/Dashboard/ContractorDashboardView";
import { InstallationsView } from "../Installations/InstallationList/InstallationsView";
import { PropertyDetailsPage } from "../Properties/PropertyDetailsPage";

export const ContractorViewRoutes: React.FC = () => {
    return (
        <>
            {/*********** PROPERTIES Admin and Not ***********/}
            <PrivateRoute
                path={AppUrls.Client.ContractorView.Root}
                exact
                isAllowed={() => {
                    return StoresInstance.domain.AccountStore.IsLoggedIn && RouterUtil.requireContractorLogin(StoresInstance);
                }}
                component={ContractorDashboardView}
                layout={ContractorLayout}
            />
            <PrivateRoute
                path={AppUrls.Client.ContractorView.Property.PropertyDetail}
                exact
                isAllowed={() => {
                    return StoresInstance.domain.AccountStore.IsLoggedIn && RouterUtil.requireContractorLogin(StoresInstance);
                }}
                component={PropertyDetailPage}
                layout={ContractorLayout}
            />
            <PrivateRoute
                path={AppUrls.Client.ContractorView.Property.PropertyInstallation}
                exact
                isAllowed={() => {
                    return StoresInstance.domain.AccountStore.IsLoggedIn && RouterUtil.requireContractorLogin(StoresInstance);
                }}
                component={InstallationView}
                layout={ContractorLayout}
            />
            {/*********** PROJECTS - Admin Only ***********/}
            <PrivateRoute
                path={AppUrls.Client.ContractorView.Project.ProjectDetail}
                exact
                isAllowed={() => {
                    return StoresInstance.domain.AccountStore.IsLoggedIn && RouterUtil.requireContractorAdminLogin(StoresInstance);
                }}
                component={ProjectDetailsPage}
                layout={ContractorLayout}
            />
            <PrivateRoute
                path={AppUrls.Client.ContractorView.Project.PropertyDetail}
                exact
                isAllowed={() => {
                    return StoresInstance.domain.AccountStore.IsLoggedIn && RouterUtil.requireContractorAdminLogin(StoresInstance);
                }}
                component={PropertyDetailPage}
                layout={ContractorLayout}
            />
            <PrivateRoute
                path={AppUrls.Client.ContractorView.Project.PropertyInstallation}
                exact
                isAllowed={() => {
                    return StoresInstance.domain.AccountStore.IsLoggedIn && RouterUtil.requireContractorAdminLogin(StoresInstance);
                }}
                component={InstallationView}
                layout={ContractorLayout}
            />
            <PrivateRoute
                path={AppUrls.Client.ContractorView.Project.ProjectAddEdit}
                exact
                isAllowed={() => {
                    return StoresInstance.domain.AccountStore.IsLoggedIn && RouterUtil.requireContractorAdminLogin(StoresInstance);
                }}
                component={ProjectAddEditPage}
                layout={ContractorLayout}
            />
            {/* Unit List Stuff */}
            <PrivateRoute
                component={InstallationsView}
                exact
                isAllowed={() => {
                    return StoresInstance.domain.AccountStore.IsLoggedIn && RouterUtil.requireContractorLogin(StoresInstance);
                }}
                path={AppUrls.Client.ContractorView.Device.DeviceRoot}
                layout={ContractorLayout}
            />
            <PrivateRoute
                component={PropertyDetailsPage}
                exact
                isAllowed={() => {
                    return StoresInstance.domain.AccountStore.IsLoggedIn && RouterUtil.requireContractorLogin(StoresInstance);
                }}
                path={AppUrls.Client.ContractorView.Device.PropertyDetail}
                layout={ContractorLayout}
            />
            <PrivateRoute
                component={InstallationView}
                exact
                isAllowed={() => {
                    return StoresInstance.domain.AccountStore.IsLoggedIn && RouterUtil.requireContractorLogin(StoresInstance);
                }}
                path={AppUrls.Client.ContractorView.Device.PropertyInstallation}
                layout={ContractorLayout}
            />
            <PrivateRoute
                component={InstallationView}
                exact
                isAllowed={() => {
                    return StoresInstance.domain.AccountStore.IsLoggedIn && RouterUtil.requireContractorLogin(StoresInstance);
                }}
                path={AppUrls.Client.ContractorView.Device.Installation}
                layout={ContractorLayout}
            />
        </>
    );
};
