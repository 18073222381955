import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const roofcareModalStyle = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            position: "relative",
            top: "50%",
            marginLeft: "auto",
            marginRight: "auto",
            backgroundColor: theme.palette.background.paper,
            border: "1px solid",
            borderColor: theme.app.colors.grey.border,
            boxShadow: theme.shadows[5],
            padding: 0,
            zIndex: 1000,
            maxWidth: "640px",
        },
        header: {
            color: "white",
            backgroundColor: "black",
        },
    }),
);
