import styled from "styled-components";
import { Box } from "@material-ui/core";

export interface LineGraphBoxProps {
    maxwidth: number;
    paddingTop: string;
}

export const LineGraphBox: any = styled(Box)<LineGraphBoxProps>`
    padding-top: ${(props) => props.paddingTop};
    max-width: 100%;

    .table-guttersens-overviewgraph-title,
    .table-guttersens-devicedatagraph-title {
        display: flex;
        flex: 1 0 auto;
        justify-content: center;
    }

    .line {
        fill: none;
        stroke: steelblue;
        stroke-width: 2px;
    }

    .graphaxistext {
        margin-left: -30px;
    }

    .svgGraph {
        z-index: -1 !important;
    }

    .vx-tooltip-portal {
        display: block;
        padding: 10px 15px !important;
        background: #1f78b4 !important;
        color: white !important;
        border: 1px solid transparent !important;
        border-radius: 6px !important;
        text-align: center !important;
        box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.3) !important;
        min-width: 135px;
        min-height: 50px;

        .graphTooltipWaterLevel {
            margin: 0 !important;
            font-size: 18px;
            font-weight: bold;
        }

        .graphTooltipDate {
            margin: 0 !important;
            font-size: 11px;
        }

        .vx-highlighter-tooltip {
            z-index: 2000;
        }
    }
`;
