import { isNullOrUndefined } from "Custom/Utils/utils";
import { ViewModelBase } from "Core/ViewModels/ViewModelBase";
import { FieldType } from "Core/Utils/Utils";
import { action, computed, IObservableArray, observable } from "mobx";
import { InstallationMapModel, Property } from "Custom/Models/Domain";
import { LatLngBounds } from "leaflet";
import { IMarker } from "Custom/Components/Map/Map";
import { markerIconDefault, markerIconFlood } from "Custom/Components/Map/icons";
import { DEFAULTBOUNDS } from "Custom/Globals/Globals";
import { getInstallationMarkerIcon } from "Custom/Utils/map";

//extend viewmodel base and passing your model as the generic type
export class PropertyMapViewModel extends ViewModelBase<InstallationMapModel> {
    //Singleton instance of class
    private static _instance: PropertyMapViewModel;
    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    @observable public errorMessage: string = "";

    public properties: IObservableArray<Property> = observable<Property>([]);

    @action
    setProperties(properties: Property[]) {
        this.properties.replace(properties);
    }

    @computed get getMappableProperties(): Property[] {
        return this.properties.filter((a: Property) => isNullOrUndefined(a.locationLatitude) === false && isNullOrUndefined(a.locationLongitude) === false);
    }

    public getProperty(id: string): Property | undefined {
        return this.properties.find((a) => a.id === id);
    }

    /*     public showingWarning = observable({
        [InstallationStatus.GreenWarning]: true,
        [InstallationStatus.AmberWarning]: true,
        [InstallationStatus.RedWarning]: true,
        [InstallationStatus.FloodWarning]: true,
    }); */

    constructor() {
        //Pass in a new instance of your model
        //By passing in true as the second parameter, we make this model undoable which means we can use save and reset options on the model
        //If you make a change to the model you need to persist it with a saveModel() call
        //If you make changes to your model you can revert it back by calling resetModel()
        super(new InstallationMapModel(), false);
        //EN - Haven't figured out how to make this call work from the base model yet
        //This is only needed if you make use of the validation decorators
        this.setDecorators(InstallationMapModel);
    }

    //This must be present in your viewmodel. Just return true if you dont need to validate anything.
    //keyof BlankModel & string lets you add type checking to the fieldName
    //I am using the validator package to make checking easier https://www.npmjs.com/package/validator
    public isFieldValid(fieldName: keyof FieldType<InstallationMapModel>): boolean {
        const { isValid, errorMessage } = this.validateDecorators(fieldName);

        //You need to this two properties after validation
        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    @computed
    public get mapBounds(): LatLngBounds {
        let bounds: LatLngBounds = new LatLngBounds(this.getMappableProperties.map((property) => [property.getValue("locationLatitude"), property.getValue("locationLongitude")]));

        const isValid: boolean = bounds.isValid();
        /*         if (isValid) {
            bounds = bounds.pad(10);
        } */
        return isValid ? bounds : DEFAULTBOUNDS;
    }

    /*     private getMarkerIcon(installation: InstallationMapModel) {
        switch (installation.getStatus) {
            case InstallationStatus.GreenWarning:
                return markerIconGreen;
            case InstallationStatus.AmberWarning:
                return markerIconAmber;
            case InstallationStatus.FloodWarning:
                return markerIconFlood;
            case InstallationStatus.RedWarning:
                return markerIconRed;
            default:
                return markerIconUnkown;
        }
    } */

    /*     private getMarkerIcon(property: Property) {
        return markerIconUnkown;
    } */

    @computed
    public get markers(): IMarker[] {
        return this.getMappableProperties.map((property) => ({
            id: property.getValue("id"),
            position: [property.getValue("locationLatitude"), property.getValue("locationLongitude")],
            icon: getInstallationMarkerIcon(property.status !== null && property.status !== undefined ? property.status.statusColour : "#ffffff"),
        }));
    }
}
