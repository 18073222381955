import styled from "styled-components";
import { BaseModalDialogPaper } from "Custom/Components/Modal/BaseModal.styles";

export const ChangelogModalDialogPaper = styled(BaseModalDialogPaper)`
    width: 75%;
    min-width: 300px;
    max-width: 640px;
    background: transparent linear-gradient(180deg, white 0%, white 100%) 0% 0% no-repeat padding-box;
    color: black;

    .MuiDialogContent-root {
        p {
            padding-bottom: 40px;
            font-size: 14px;
        }
    }

    display: flex;
    flex-direction: column;
    max-height: 90vh;

    .modal-changelog {
        color: black;
    }

    .modal-changelog-header {
        h4 {
            color: black;
        }
    }

    .modal-changelog-body {
        flex: 1 1 auto;
        padding: 0 10px;
        overflow-y: auto;
        color: white;

        &.whiteBackground {
            color: black;
            background-color: white;
        }

        :not(:last-child) {
            margin-bottom: 10px;
        }

        .modal-changelog-body-version {
            padding-bottom: 20px;

            h4 > small {
                color: ${(props) => props.theme.app.colors.red.button};
                //background-color: yellow;
            }

            ul {
                list-style: none;
                padding: 0;
                margin: 0;

                li {
                    padding-left: 1em;
                    text-indent: -0.7em;
                }

                li::before {
                    content: "● ";
                    color: ${(props) => props.theme.app.colors.red.button};
                }
            }
        }
    }
`;
