import * as Mui from "@material-ui/core";

import React, { useEffect, useState } from "react";

// App
import { ProjectListViewModel } from "./ProjectListViewModel";
import { ResetSearchIcon } from "Custom/Components/Table/ResetSearchIcon";
import { useObserver } from "mobx-react-lite";
import { useRouter } from "Core/Utils";
import { RoofcareTable, RoofcareTableWrapper } from "Custom/Components/Table/RoofcareTable";
import { ClickableImgCell } from "Custom/Components/Table/ClickableImgCell";

import EditLogo from "Custom/Content/editIcon.svg";
import DeleteLogo from "Custom/Content/deleteIcon.svg";
import { Container } from "Custom/StylesAppSpecific/AppStyling";
import { ProjectToolbarRowV2, ProjectToolbarRowV3 } from "Custom/StylesAppSpecific/Controls/BoxAddWrapper";
import { AppUrls } from "Custom/Globals";
import { DeleteModal } from "Custom/Components/Modal/DeleteModal";

import { ProjectStatusCell } from "Custom/Components/Status/ProjectStatusCell.styles";
import { Loader } from "Core/Components";
import { StoresInstance } from "../../Stores/Stores";
import { isNullOrUndefined } from "Custom/Utils/utils";
import { MultiSelectView } from "Custom/Components/Select/MultipleSelect";
import { ProjectListSearch } from "./ProjectListSearch";

//CUSTOM
import { ProjectButton } from "../CustomComponents";

export const ProjectListPage: React.FC = () => {
    const [viewModel] = useState(() => ProjectListViewModel.Instance);
    const { history } = useRouter();
    const theme = Mui.useTheme();

    const [deleteOpen, setDeleteOpen] = React.useState(false);
    const [selectedProject, selectProject] = React.useState("");

    const setSearchString = (value: string) => {
        viewModel.setSearchString(value);
        viewModel.setPage(0);
    };

    const setRowsPerPage = (rows: number) => {
        viewModel.setRowsPerPage(rows);
    };

    const setPage = (page: number) => {
        viewModel.setPage(page);
    };

    const setOrderChange = (columnId: number, direction: any) => {
        viewModel.setOrderChange(columnId, direction);
    };

    useEffect(() => {
        if (viewModel.IsLoading == false) {
            const response = viewModel.loadProjectsAsync();
            response.then(() => {});
        }

        return () => {
            // Clean up after yourself
        };
    }, []);

    const handleNewClick = () => {
        history.push(AppUrls.Client.Main.Project.ProjectAddEdit.replace(":projectId", "0"));
    };

    const formatEdit = (id: string): any => {
        return <ClickableImgCell logo={EditLogo} text={"Edit project"} onClick={editProjectClick} id={id} />;
    };

    const formatDelete = (id: string): any => {
        return <ClickableImgCell logo={DeleteLogo} text={"Delete project"} onClick={deleteProjectClick} id={id} className="delete" />;
    };

    const editProjectClick = (id: string) => {
        history.push(AppUrls.Client.Main.Project.ProjectAddEdit.replace(":projectId", id));
    };

    const deleteProjectClick = (id: string) => {
        selectProject(id);
        handleDeleteOpen();
    };

    const handleRowClick = (rowData: any) => {
        history.push(AppUrls.Client.Main.Project.ProjectDetail.replace(":projectId", rowData.id));
    };

    const handleCSVClick = (id: string) => {
        let ret = viewModel.downloadProjectsCommunicationCSV();

        ret.then((ret) => {
            //alert("Back");
        });
    };

    const handleClientsFilterChange2 = (values: number[] | string[] | null) => {
        viewModel.setClientFilter(values as string[]);
    };

    const handleRegManagerFilterChange2 = (values: number[] | string[] | null) => {
        viewModel.setRegManagerFilter(values as string[]);
    };

    const handleStatusChange2 = (values: number[] | string[] | null) => {
        const valueList: string[] = values as string[];
        const intValues: number[] = [];

        for (let i: number = 0; i < valueList.length; i++) {
            const item: number = parseInt(valueList[i], 10);
            intValues.push(item);
        }

        viewModel.setProjectStatusFilter(intValues);
    };

    const handlePaymentStatusChange = (values: number[] | string[] | null) => {
        const valueList: string[] = values as string[];
        const intValues: number[] = [];

        for (let i: number = 0; i < valueList.length; i++) {
            const item: number = parseInt(valueList[i], 10);
            intValues.push(item);
        }

        viewModel.setProjectPaymentStatusFilter(intValues);
    };

    const isInError = (fieldName: string): boolean => {
        /*         let isValid = props.filtersViewModel.getValid(fieldName);
        return !isValid; */

        return false;
    };

    const getError = (fieldName: string): string => {
        /*         let retVal: string = props.filtersViewModel.getError(fieldName);
        return retVal; */
        return "";
    };

    /**
     * Get the sort direction stored in the viewmodel for the provided column index.
     * Only use this on columns that have "sorting" set to true.
     */
    const getSortDirection = (columnIndex: number) => {
        return viewModel.sortColumnId === columnIndex ? viewModel.sortDirection : "";
    };

    const handleDeleteOpen = () => {
        setDeleteOpen(true);
    };

    const handleDeleteClose = () => {
        setDeleteOpen(false);
        selectProject("");
    };

    const handleDeleteProject = () => {
        const deletedProject: string = selectedProject;
        viewModel.deleteProject(deletedProject).then(() => {
            setDeleteOpen(false);
            selectProject("");
        });
    };

    function renderPage() {
        if ((viewModel.IsLoading || StoresInstance.domain.ProjectStore.isLoading) && viewModel.IsDownloading === false) return <Loader />;

        return (
            <Container>
                <DeleteModal
                    open={deleteOpen}
                    onClose={handleDeleteClose}
                    onDelete={handleDeleteProject}
                    text="Are you sure you want to delete this Project?"
                    title="Delete Project?"
                />
                <RoofcareTableWrapper>
                    <ProjectToolbarRowV2>
                        {/* <div className="search-title">
                            <Typography variant="h6" style={{ color: theme.palette.secondary.contrastText }} className="MuiTypography-root MuiTypography-h6 title">
                                Projects
                            </Typography>
                        </div> */}
                        <div className="search-field" style={{ width: "580px" }}>
                            <ProjectListSearch searchString={viewModel.getSearchString()} setSearchString={setSearchString} />
                        </div>
                        <div className="download-csv">
                            <ClickableImgCell className="projectnote-addedit" logo={EditLogo} text={"Download Communication CSV"} onClick={handleCSVClick} id={"0"} />
                        </div>
                        <div className="add-new-project">
                            <ProjectButton icon={() => <span>&#43;</span>} text="Add new project" onClick={() => handleNewClick()} />
                        </div>
                    </ProjectToolbarRowV2>
                    <ProjectToolbarRowV3 style={{ marginTop: "20px" }}>
                        <Mui.Box>
                            <Mui.Box>
                                <Mui.Typography style={{ color: theme.palette.secondary.contrastText }} variant="h2" className="select-title">
                                    End Users:
                                </Mui.Typography>
                                <Mui.Box className="multiselectbox">
                                    <MultiSelectView
                                        fieldName=""
                                        values={viewModel.getClientFilter()}
                                        display="End users"
                                        useNumber={true}
                                        getOptions={(isdeleted) => viewModel.getClientsKVPair}
                                        handleChange={handleClientsFilterChange2}
                                        getError={getError}
                                        getValid={isInError}
                                    />
                                </Mui.Box>
                            </Mui.Box>
                        </Mui.Box>
                        <Mui.Box>
                            <Mui.Box>
                                <Mui.Typography style={{ color: theme.palette.secondary.contrastText }} variant="h2" className="select-title">
                                    Regional Sales Manager:
                                </Mui.Typography>
                                <Mui.Box className="multiselectbox">
                                    <MultiSelectView
                                        fieldName=""
                                        values={viewModel.getRegManagerFilter()}
                                        display="RSM"
                                        useNumber={true}
                                        getOptions={(isdeleted) => viewModel.getRegManagerKVPair}
                                        handleChange={handleRegManagerFilterChange2}
                                        getError={getError}
                                        getValid={isInError}
                                    />
                                </Mui.Box>
                            </Mui.Box>
                        </Mui.Box>
                        <Mui.Box>
                            <Mui.Box>
                                <Mui.Typography style={{ color: theme.palette.secondary.contrastText }} variant="h2" className="select-title">
                                    Project Status:
                                </Mui.Typography>
                                <Mui.Box className="multiselectbox">
                                    <MultiSelectView
                                        fieldName=""
                                        values={viewModel.getStatusFilter()}
                                        display="Status"
                                        useNumber={true}
                                        getOptions={(isdeleted) => viewModel.getProjectStatusKVPair}
                                        handleChange={handleStatusChange2}
                                        getError={getError}
                                        getValid={isInError}
                                    />
                                </Mui.Box>
                            </Mui.Box>
                        </Mui.Box>
                        <Mui.Box>
                            <Mui.Box>
                                <Mui.Typography style={{ color: theme.palette.secondary.contrastText }} variant="h2" className="select-title">
                                    Project Payment Status:
                                </Mui.Typography>
                                <Mui.Box className="multiselectbox">
                                    <MultiSelectView
                                        fieldName=""
                                        values={viewModel.getPaymentStatusFilter()}
                                        display="Payment Status"
                                        useNumber={true}
                                        getOptions={(isdeleted) => viewModel.getProjectPaymentStatusKVPair}
                                        handleChange={handlePaymentStatusChange}
                                        getError={getError}
                                        getValid={isInError}
                                    />
                                </Mui.Box>
                            </Mui.Box>
                        </Mui.Box>
                    </ProjectToolbarRowV3>
                </RoofcareTableWrapper>

                <RoofcareTable
                    columns={[
                        {
                            title: "Project No.",
                            field: "number",
                            defaultSort: getSortDirection(0),
                            sorting: true,
                            filtering: false,
                        },
                        {
                            title: "Project Name",
                            field: "name",
                            defaultSort: getSortDirection(1),
                            sorting: true,
                            filtering: false,
                        },
                        {
                            title: "End User",
                            field: "clientName",
                            defaultSort: getSortDirection(2),
                            sorting: true,
                            filtering: false,
                            render: (rowData: any) => {
                                if (rowData !== null && rowData !== undefined) {
                                    return <div>{rowData.clientName}</div>;
                                } else {
                                    return <div></div>;
                                }
                            },
                        },
                        {
                            title: "Properties",
                            field: "propertyCount",
                            defaultSort: getSortDirection(3),
                            sorting: true,
                            filtering: false,
                        },
                        {
                            title: "Potential Units",
                            field: "potentialUnits",
                            defaultSort: getSortDirection(4),
                            sorting: true,
                            filtering: false,
                        },
                        {
                            title: "Regional Sales Manager",
                            field: "regionalSalesManager",
                            defaultSort: getSortDirection(5),
                            sorting: true,
                            filtering: false,
                            render: (rowData: any) => {
                                if (rowData !== null && rowData !== undefined) {
                                    return <div>{rowData.regionalSalesManager}</div>;
                                } else {
                                    return <div></div>;
                                }
                            },
                        },
                        {
                            title: "Status",
                            field: "status",
                            defaultSort: getSortDirection(6),
                            sorting: true,
                            filtering: true,
                            render: (rowData: any) => {
                                if (rowData !== null && rowData !== undefined && rowData.status !== undefined) {
                                    const projectStatus = viewModel.projectStatuses.find((ps) => ps.enumId === rowData.status);
                                    const color = isNullOrUndefined(projectStatus) ? "#000000" : projectStatus!.color;
                                    const textColor = isNullOrUndefined(projectStatus) ? "#FFFFFF" : projectStatus!.textColor;
                                    const text = isNullOrUndefined(projectStatus) ? "Unknown" : projectStatus!.displayName;

                                    return (
                                        <ProjectStatusCell statuscolor={color} textcolor={textColor}>
                                            {text}
                                        </ProjectStatusCell>
                                    );
                                } else {
                                    return <div></div>;
                                }
                            },
                        },
                        {
                            title: "Payment Status",
                            field: "paymentStatus",
                            defaultSort: getSortDirection(7),
                            sorting: true,
                            filtering: true,
                            render: (rowData: any) => {
                                if (rowData !== null && rowData !== undefined && rowData.paymentStatus !== undefined) {
                                    const paymenttStatus = viewModel.projectPaymentStatuses.find((ps) => ps.id === rowData.paymentStatus);
                                    const color = isNullOrUndefined(paymenttStatus) ? "#000000" : paymenttStatus!.color;
                                    const textColor = isNullOrUndefined(paymenttStatus) ? "#FFFFFF" : paymenttStatus!.textColor;
                                    const text = isNullOrUndefined(paymenttStatus) ? "Unknown" : paymenttStatus!.displayName;

                                    return (
                                        <ProjectStatusCell statuscolor={color} textcolor={textColor}>
                                            {text}
                                        </ProjectStatusCell>
                                    );
                                } else {
                                    return <div></div>;
                                }
                            },
                        },
                        {
                            title: "",
                            field: "id",
                            sorting: false,
                            filtering: false,
                            render: (rowData: any) => {
                                if (rowData !== null && rowData !== undefined) {
                                    return formatEdit(rowData.id);
                                }
                            },
                        },
                        {
                            title: "",
                            field: "id",
                            sorting: false,
                            filtering: false,
                            render: (rowData: any) => {
                                if (rowData !== null && rowData !== undefined) {
                                    return formatDelete(rowData.id);
                                }
                            },
                            cellStyle: {
                                width: "100px",
                            },
                        },
                    ]}
                    options={{
                        draggable: false,
                        thirdSortClick: false,
                        cellStyle: {
                            fontSize: 10,
                        },
                        filtering: false,
                        initialPage: viewModel.currentPage,
                        pageSize: viewModel.pageSize,
                        pageSizeOptions: [10, 20, 50, 100],
                        emptyRowsWhenPaging: false,
                        headerStyle: {
                            background: " #495D89 0% 0% no-repeat padding-box",
                            boxShadow: " inset 0px 1px 1px #FFFFFF40",
                            color: "#fff",
                            fontFamily: ["Mont-Bold", "Montserrat", "Roboto Condensed", "Helvetica", "Arial", "sans-serif"].join(","),
                            fontSize: "11px",
                            borderTop: "unset",
                            borderBottom: "unset",
                            marginBottom: "22px",
                        },
                        rowStyle: {
                            backgroundColor: "#525355",
                            color: "#fff",
                            fontSize: 24,
                            borderTop: "unset",
                            borderBottom: "#525355 1px solid",
                        },
                        filterCellStyle: {
                            color: "#495D89",
                        },
                        search: false,
                        defaultGroupOrder: viewModel.sortColumnId,
                        defaultGroupDirection: viewModel.sortDirection,
                        tableLayout: "fixed",
                    }}
                    data={viewModel.getAllowedProjects}
                    title=""
                    icons={{
                        ResetSearch: () => <ResetSearchIcon />,
                    }}
                    onSearchChange={(value: string) => {
                        setSearchString(value);
                    }}
                    onOrderChange={(orderedColumnId: any, orderDirection: any) => {
                        setOrderChange(orderedColumnId, orderDirection);
                    }}
                    onChangeRowsPerPage={(rows: number) => {
                        setRowsPerPage(rows);
                    }}
                    onChangePage={(page: number) => {
                        setPage(page);
                    }}
                    onRowClick={(_: any, rowData: any) => handleRowClick(rowData)}
                    components={{
                        Container: (props: any) => <Mui.Paper {...props} elevation={0} />,
                        Toolbar: (props: any) => <></>,
                    }}
                />
            </Container>
        );
    }

    return useObserver(() => renderPage());
};
