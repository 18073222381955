import styled from "styled-components";

interface ITabsContainerProps {
    tabWidth?: number;
    tabPadding?: string;
}

export const TabsContainer = styled.div<ITabsContainerProps>`
    display: flex;

    div.tabs-padding {
        min-width: 30px;
        /* border-bottom: 1px solid #d4d4d4; */

        &.right {
            flex-grow: 1;
        }
    }

    div.tab-back,
    div.tab {
        display: flex;
        align-items: center;
        justify-content: center;
        user-select: none;
        cursor: pointer;
        outline: none;
    }

    div.tab-back {
        padding-right: 30px;
        /* border-bottom: 1px solid #d4d4d4; */
        font-size: 10px;

        .back-icon {
            margin-left: -10px;
            color: ${(props) => props.theme.app.colors.red.dark};
            font-size: 18px;
            font-family: "Mont-Bold", "Montserrat", "Roboto Condensed", "Helvetica", "Arial", "sans-serif";
            display: flex;
            margin-bottom: 1px;
        }
    }

    div.tab {
        padding: ${(props) => (props.tabPadding === undefined ? "11px 19px" : props.tabPadding)};
        min-width: ${(props) => (props.tabWidth === undefined ? undefined : `${props.tabWidth}px`)};
        border: 1px solid #3b3b3b;
        border-bottom: none;
        font-size: 14px;
        color: white;
        background-color: #585858;
        border-radius: 2px 2px 0px 0px;

        box-shadow: rgba(0, 0, 0, 0.75) 0px -25px 36px -28px inset;
        &.selected {
            color: white;
            background-color: #585858;
            box-shadow: none;
            border-bottom-color: transparent;
        }
    }

    div.tab ~ div.tab {
        border-left: none;
    }
`;
