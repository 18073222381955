import { FormControlLabel, Grid, makeStyles, Radio, RadioGroup } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { ChromePicker, Color, ColorResult } from "react-color";
import { ColourPickerViewModel } from "./ColourPickerViewModel";
import { BaseModal } from "Custom/Components/Modal/BaseModal";
import { SuccessBtn } from "Custom/StylesAppSpecific/Controls/SuccessBtn";
import { CancelBtn } from "Custom/StylesAppSpecific/Controls/CancelBtn";
import {
    BackgroundContainer,
    ColourCell,
    ColourColumn,
    ColourPickerContainer,
    ColourPickerModalDialogPaper,
    ColourTable,
    ColumnTitle,
    DisplayContainer,
    TextContainer,
} from "./ColourPicker.styles";
import { useObserver } from "mobx-react-lite";

interface IColourPickerProps {
    text: string;
    openPicker: boolean;
    backgroundColour: string;
    textColour: string;
    onUpdate: (backgroundColour: string, textColour: string) => void;
    onClose: () => void;
}

export const ColourPicker: React.FunctionComponent<IColourPickerProps> = ({ openPicker, backgroundColour, text, textColour, onUpdate, onClose }) => {
    const [viewModel] = useState(() => new ColourPickerViewModel(backgroundColour, textColour));
    const [open, setOpen] = useState<boolean>(false);

    const handleClose = () => {
        // reset the colours back to what they were internally,
        // since opening / changing / cancel / open opens with the edited values not the originals?!!
        viewModel.updatePicker(backgroundColour, text, textColour);
        onClose();
        setOpen(false);
    };

    const onUpdateClicked = () => {
        setOpen(false);
        onUpdate(viewModel.getBackgroundColour, viewModel.getTextColour);
    };

    //useEffect below only gets run on initial render
    useEffect(() => {
        setOpen(openPicker);
        //This gets run when the page is exited
        return () => {
            //console.log("Unmounting");
        };
    }, []);

    useEffect(() => {
        viewModel.updatePicker(backgroundColour, text, textColour);
    }, [backgroundColour, text, textColour]);

    useEffect(() => {
        setOpen(openPicker);
    }, [openPicker]);

    const onTextColourChange = (e: any) => {
        viewModel.setTextColour(parseInt((e.target as HTMLInputElement).value));
    };

    const handleBackgroundColourChange = (hex: string) => {
        viewModel.setBackgroundColour(hex);
    };

    function renderPage() {
        const useStyles = makeStyles((theme) => ({
            colourBlockWrapper: {
                //paddingRight: "15px",
            },
            colourBlock: {
                verticalAlign: "middle",
                backgroundColor: viewModel.getBackgroundColour,
                color: viewModel.getTextColour,
                padding: "5px 16px",
                fontSize: "10px",
                textAlign: "center",
                marginTop: "5px",
            },
        }));

        const classes = useStyles();

        const handleChromeBackgroundColourChange = (color: ColorResult) => {
            viewModel.setBackgroundColour(color.hex);
        };

        return (
            <BaseModal
                title="Edit Colours"
                onClose={handleClose}
                open={open}
                PaperComponent={ColourPickerModalDialogPaper}
                actions={
                    <>
                        <SuccessBtn onClick={onUpdateClicked} autoFocus>
                            Update
                        </SuccessBtn>
                        <CancelBtn onClick={handleClose}>Cancel</CancelBtn>
                    </>
                }
            >
                <ColourPickerContainer>
                    <BackgroundContainer>
                        <ColumnTitle>Background Colour</ColumnTitle>
                        <ChromePicker onChange={handleChromeBackgroundColourChange} color={viewModel.getBackgroundColour} />
                        <ColourTable>
                            <ColourColumn className="lhs">
                                <ColourCell
                                    onClick={() => {
                                        handleBackgroundColourChange("#89FC9C");
                                    }}
                                    style={{ backgroundColor: "#89FC9C" }}
                                ></ColourCell>
                                <ColourCell onClick={() => handleBackgroundColourChange("#F1F207")} style={{ backgroundColor: "#F1F207" }}></ColourCell>
                                <ColourCell onClick={() => handleBackgroundColourChange("#F2B507")} style={{ backgroundColor: "#F2B507" }}></ColourCell>
                                <ColourCell onClick={() => handleBackgroundColourChange("#F207EB")} style={{ backgroundColor: "#F207EB" }}></ColourCell>
                                <ColourCell onClick={() => handleBackgroundColourChange("#F6FF95")} style={{ backgroundColor: "#F6FF95" }}></ColourCell>
                            </ColourColumn>
                            <ColourColumn className="rhs">
                                <ColourCell onClick={() => handleBackgroundColourChange("#db1717")} style={{ backgroundColor: "#db1717" }}></ColourCell>
                                <ColourCell onClick={() => handleBackgroundColourChange("#95FDFF")} style={{ backgroundColor: "#95FDFF" }}></ColourCell>
                                <ColourCell onClick={() => handleBackgroundColourChange("#EF5FCC")} style={{ backgroundColor: "#EF5FCC" }}></ColourCell>
                                <ColourCell onClick={() => handleBackgroundColourChange("#80777E")} style={{ backgroundColor: "#80777E" }}></ColourCell>
                                <ColourCell onClick={() => handleBackgroundColourChange("#F8F8F8")} style={{ backgroundColor: "#F8F8F8" }}></ColourCell>
                            </ColourColumn>
                        </ColourTable>
                    </BackgroundContainer>
                    <TextContainer>
                        <ColumnTitle>Text Colour</ColumnTitle>
                        <RadioGroup
                            row
                            aria-label="units"
                            defaultValue={viewModel.getTextColourId}
                            value={viewModel.getTextColourId}
                            name="radio-buttons-group"
                            onChange={onTextColourChange}
                        >
                            <FormControlLabel value={0} control={<Radio color="default" />} label="Black" />
                            <FormControlLabel value={1} control={<Radio color="default" />} label="White" />
                        </RadioGroup>
                        <DisplayContainer>
                            <ColumnTitle>Result</ColumnTitle>
                            <div className={classes.colourBlock}>{text}</div>
                        </DisplayContainer>
                    </TextContainer>
                </ColourPickerContainer>
            </BaseModal>
        );
    }

    return useObserver(() => renderPage());
};
