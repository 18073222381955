import styled from "styled-components";

export const PropertyNotesContainer = styled.div`
    background-color: ${(props) => props.theme.app.colors.tabContent.light};
    color: white;
    padding: 38px 30px 30px;
    display: flex;
    flex: 1 0 0;
    flex-direction: column;
    min-width: 100%;

    .add-note {
        padding-top: 10px;
    }

    .note-textarea {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
        min-height: 130px;
        max-height: 40vh;
        border-radius: 0;
        outline: none;
        padding: 5px;
        font-size: 1.4359em;
        font-family: "Mont-Regular", "Montserrat", "Roboto Condensed", "Helvetica", "Arial", "sans-serif";
        overflow-y: auto !important;
        margin-top: 9px;

        :focus {
            border-color: black;
        }
    }

    hr {
        border-top: none;
        border-right: none;
        border-bottom: 1px dashed black;
        border-left: none;
        margin: 25px 0 30px;
    }

    .add-btn {
        min-width: 137px;
    }

    p.property-note {
        margin-top: 24px;
    }

    span.edit-button {
        vertical-align: middle;
        color: ${(props) => props.theme.app.colors.blue.button};
        font-weight: normal;
        font-size: 0.777777em;
        display: inline-flex;
        align-items: center;
        margin-left: 30px;

        img {
            margin-right: 9px;
            padding-bottom: 1px;
        }
    }

    .leadcontact-wrapper {
        padding-top: 15px;
        display: flex;
        align-self: flex-start;
        /* background-color: green; */
    }

    .leadcontact-start {
        display: flex;
        align-self: flex-start;
        padding-right: 5px;
        /* background-color: yellow; */
    }

    .leadcontact-mid {
        display: flex;
        align-self: flex-start;
        padding-right: 15px;
        /* background-color: orange; */
    }

    .leadcontact-contact {
        display: flex;
        align-self: flex-start;
        padding-right: 15px;
    }
`;
