import { DisplayUnit, DisplayUnitHelpers } from "./../../../Models/Domain/Installations/DisplayUnit";
import { UnitListItemModel, UnitListItemModelDTO } from "./UnitListItemModel";
import { observable, action, runInAction, IObservableArray, computed } from "mobx";
import { ViewModelBase } from "Core/ViewModels";
import { FieldType, isEmptyOrWhitespace } from "Core/Utils/Utils";
import { ApiResult } from "Core/Models";
import { Server } from "Custom/Globals/AppUrls";
import { DeleteUserRequest } from "Custom/Views/User/DeleteUserRequest";
import { debounce } from "@material-ui/core";
import { KeyValuePair } from "Core/Models/KeyValuePair";
import { DEBOUNCE_VALUE_MS } from "Custom/Globals/Globals";

import { DeviceStatusModel, DeviceStatusModelDTO } from "Custom/Models/Domain/Installations/DeviceStatusModel";
import { DeviceStatusWithCommandModel, DeviceStatusWithCommandModelDTO } from "Custom/Models/Domain/Installations/DeviceStatusWithCommandModel";
import { InstallationsAndRelatedDto } from "./InstallationsAndRelatedDto";
import { DeviceStatusViewModel } from "./DeviceStatus.viewmodel";
import { DeviceStatusWithCommandViewModel } from "./DeviceStatusesWithCommandIdx.Viewmodel";
import { UnitStatus } from "Custom/Models/Domain";
import { FileDownloadResponseDTO } from "../FileDownloadResponseDTO";

export class InstallationsViewModel extends ViewModelBase<UnitListItemModel> {
    private static _instance: InstallationsViewModel;

    private IsDownloading: boolean = false;

    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    private firstTimeLoad: boolean = true;

    @observable public currentTab: string = "list";

    @observable public errorMessage: string = "";
    public devices: IObservableArray<UnitListItemModel> = observable<UnitListItemModel>([]);

    // Table Settings
    @observable public searchString: string = "";
    @observable public filterSearchString: string = "";
    @observable public pageSize: number = 50;
    @observable public currentPage: number = 0;
    // By default this will be the first column (by index) and in ascending order.
    @observable public sortColumnId: number = 0;
    @observable public sortDirection: any = "asc";

    public unitStatuses: KeyValuePair[] = [];
    public commandStatuses = observable<DeviceStatusWithCommandModel>([]);
    @observable public commandStatusFilter = observable<string>([]);
    @observable public statusFilter = observable<string>([]);
    @observable public filterStatusFilter = observable<string>([]);

    @observable public filterStandingWaterFilter = observable<string>(["0", "1", "3"]);

    public unitDisplay: KeyValuePair[] = DisplayUnitHelpers.getOptionsKeyValuePair();
    @observable public filterDisplay = observable<number>([1]);

    public constructor() {
        super(new UnitListItemModel(), false);
        this.setDecorators(UnitListItemModel);
    }

    // #region Installation Status Filter
    public getUnitStatusKVPair = (includeDeleted: boolean): KeyValuePair[] => {
        const retVal: KeyValuePair[] = [];

        for (let i: number = 0; i < this.unitStatuses.length; i++) {
            retVal.push({
                key: this.unitStatuses[i].key,
                value: this.unitStatuses[i].value,
            });
        }

        return this.unitStatuses.slice();
    };

    public getStatusFilter = () => {
        return computed(() => this.filterStatusFilter);
    };

    @action
    public setUnitStatusFilter = (values: string[]) => {
        this.filterStatusFilter.replace(values);
        this.setFilterUnitStatus(values);
    };

    private setFilterUnitStatus = debounce(
        action((values: string[]) => {
            this.filterStatusFilter.replace(values);
        }),
        DEBOUNCE_VALUE_MS,
    );
    // #endregion Installation Status Filter

    // #region Installation Standing Water Filter
    public getStandingWaterKVPair = (includeDeleted: boolean): KeyValuePair[] => {
        const retVal: KeyValuePair[] = [];

        retVal.push({
            key: "Unknown",
            value: "0",
        });

        retVal.push({
            key: "Green",
            value: "1",
        });

        retVal.push({
            key: "Red",
            value: "3",
        });

        return retVal;
    };

    public getStandingWaterFilter = () => {
        return computed(() => this.filterStandingWaterFilter);
    };

    @action
    public setStandingWaterFilter = (values: string[]) => {
        this.filterStandingWaterFilter.replace(values);
        this.setFilterStandingWaterFilter(values);
    };

    private setFilterStandingWaterFilter = debounce(
        action((values: string[]) => {
            this.filterStandingWaterFilter.replace(values);
        }),
        DEBOUNCE_VALUE_MS,
    );
    // #endregion Installation Standing Water Filter

    @action public setUnitDisplayFilter(values: number[]) {
        this.filterDisplay.replace(values);
        this.setFilterDisplay(values);
    }

    private setFilterDisplay = debounce(
        action((values: number[]) => {
            this.filterDisplay.replace(values);
        }),
        DEBOUNCE_VALUE_MS,
    );

    public getDisplayFilter = () => {
        return computed(() => this.filterDisplay);
    };

    public getUnitDisplayKVPair = (includeDeleted: boolean): KeyValuePair[] => {
        if (this.unitDisplay === undefined) {
            this.unitDisplay = DisplayUnitHelpers.getOptionsKeyValuePair();

            return this.unitDisplay;
        }

        return this.unitDisplay.slice();
    };

    @action
    public setCurrentTab = (tab: string) => {
        this.currentTab = tab;
    };

    @computed
    public get getCurrentTab() {
        return this.currentTab;
    }

    @computed public get getDevices(): UnitListItemModel[] {
        let retVal = this.devices.slice();

        if (this.filterDisplay.length === 3) {
            // then we all items
        } else if (this.filterDisplay.length === 0) {
            // we want no units
            retVal = [];
        } else {
            const wantProperties: boolean = this.filterDisplay.indexOf(DisplayUnit.WithProperty) !== -1;
            const withoutProperties: boolean = this.filterDisplay.indexOf(DisplayUnit.WithoutProperty) !== -1;
            const showHidden: boolean = this.filterDisplay.indexOf(DisplayUnit.Hidden) !== -1;

            if (wantProperties == true && withoutProperties == true) {
                // then don't do anything
            } else if (wantProperties == true) {
                retVal = retVal.filter((a) => a.roofcareAddressId !== null && a.roofcareAddressId !== undefined);
            } else if (withoutProperties == true) {
                retVal = retVal.filter((a) => a.roofcareAddressId === null || a.roofcareAddressId === undefined);
            }

            // finally filter out the hidden ones.
            if (showHidden === true) {
                // show the hidden ones
                retVal = retVal.filter((a) => a.isDeleted === true);
            } else {
                // filter out the hidden ones
                retVal = retVal.filter((a) => a.isDeleted === false);
            }
        }

        const filterBySearchString = (item: UnitListItemModel): boolean => {
            if (isEmptyOrWhitespace(this.filterSearchString)) {
                return true;
            }

            const searchStringUpperCase = this.filterSearchString.toUpperCase();

            return (
                item.clientName.toUpperCase().includes(searchStringUpperCase) ||
                item.addressLine1.toUpperCase().includes(searchStringUpperCase) ||
                item.postcode.toUpperCase().includes(searchStringUpperCase) ||
                item.city.toUpperCase().includes(searchStringUpperCase) ||
                item.clientContactEmail.toUpperCase().includes(searchStringUpperCase) ||
                item.contractorContactEmail.toUpperCase().includes(searchStringUpperCase) ||
                item.contractorContactName.toUpperCase().includes(searchStringUpperCase) ||
                item.contractorName.toUpperCase().includes(searchStringUpperCase) ||
                item.simId.toUpperCase().includes(searchStringUpperCase) ||
                item.deviceId.toUpperCase().includes(searchStringUpperCase) ||
                item.name.toUpperCase().includes(searchStringUpperCase) ||
                item.number.toUpperCase().includes(searchStringUpperCase)
            );
        };

        const filterByUnitStatus = (item: UnitListItemModel): boolean => {
            if (this.filterStatusFilter.length === this.unitStatuses.length) {
                return true;
            }

            let statusResponse: boolean = false;
            // Check 1 - see if it is device item only from the main list.
            let found: boolean = this.filterStatusFilter.includes(item.status.id);
            // have we a match from Admin/Devices
            if (found) {
                statusResponse = true;
            } else {
                // have we a match from Command set based on the admin condtiion sets
                if (this.commandStatuses !== undefined && this.commandStatuses.length > 0) {
                    let commandFound: DeviceStatusWithCommandModel | undefined = this.commandStatuses.find((a) => a.id === item.status.id);

                    if (commandFound !== undefined) {
                        let found: boolean = this.filterStatusFilter.includes(commandFound.adminConditionSetItemId);
                        if (found) {
                            statusResponse = true;
                        }
                    }
                }
            }

            return statusResponse;
        };

        const filterByStandingWater = (item: UnitListItemModel): boolean => {
            if (this.filterStandingWaterFilter.length === 3) {
                return true;
            }

            let retVal: boolean = false;
            // Check 1 - see if it is device item only from the main list.
            let found: boolean = this.filterStandingWaterFilter.includes(item.standingWaterEnum.toString());
            // have we a match from Admin/Devices
            if (found) {
                retVal = true;
            }
            return retVal;
        };

        return retVal /* .filter((a) => a.isDeleted === false) */
            .filter((a) => filterBySearchString(a))
            .filter((a) => filterByStandingWater(a))
            .filter((a) => filterByUnitStatus(a));
    }

    public getSearchString = () => {
        return computed(() => this.searchString);
    };

    @action
    public cleanUp = () => {
        // TODO Any Cleanup Code here. e.g. if  a user or project or client etc, wipe it from the instance on page shutdown
        this.devices.clear();
        this.model.clear();
    };

    public doSubmit = async (e: any) => {
        e.preventDefault();

        if (await this.isMyModelValid()) {
            //Do stuff here
            this.errorMessage = "Form is valid";
        } else {
            this.errorMessage = "Form is not valid";
        }
    };

    public isFieldValid(fieldName: keyof FieldType<UnitListItemModel>, value: any): boolean {
        let { isValid, errorMessage } = this.validateDecorators(fieldName);

        /*         if (fieldName === "firstName") {
            errorMessage = this.isFirstNameValid;
            isValid = errorMessage === "";
        } */

        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    /*     public get isFirstNameValid(): string {
        let error = "";

        const field: string = this.getValue<string>("firstName");

        if (isNullOrEmpty(field)) {
            error = "First name is required";
        } else if (field!.length > 128) {
            error = "First name needs to be less than 128 characters";
        }

        return error;
    } */

    private isMyModelValid = async (): Promise<boolean> => {
        let isValid = true;

        // If you have a child form, you can call it's own viewmodels validation from here.
        /*if ((await this.addressFormViewModel.isModelValid()) === false) {
            isValid = false;
        }*/

        // Could also perform this in a loop if you have a list of child forms.
        /*for (let i = 0; i < this.shiftViewModels.length; i++) {
            let shift = this.shiftViewModels[i];

            if (shift.model.isEmptyForm === false) {
                if ((await shift.isModelValid()) === false) {
                    isValid = false;
                }
            }
        }*/

        // this will automatically call isFieldValid.
        if ((await this.isModelValid()) === false) {
            isValid = false;
        }
        return isValid;
    };

    public afterUpdate: undefined;
    public beforeUpdate: undefined;

    @action
    public setDevices(units: UnitListItemModelDTO[]) {
        this.devices.clear();
        units.forEach((item, index) => {
            let domainModel = this.devices.find((dm: UnitListItemModel) => dm.id === item.id);

            if (!domainModel) {
                domainModel = new UnitListItemModel();

                domainModel.fromDto(item);
                this.devices.push(domainModel);
            }
        });
    }

    @action
    public removeDevice(device: string) {
        let devices: UnitListItemModel[] = [];

        const deviceId: number = parseInt(device, 10);

        for (let i: number = 0; i < this.devices.length; i++) {
            if (this.devices[i].id !== deviceId) {
                devices.push(this.devices[i]);
            }
        }

        this.devices.replace(devices);
    }

    @action public removeDeviceFromProperty = async (itemId: string): Promise<ApiResult<UnitListItemModelDTO[]>> => {
        const request: DeleteUserRequest = {
            id: itemId,
        };
        const apiResult = await this.Post<UnitListItemModelDTO[]>(Server.Api.Installation.removeDeviceFromProperty, request);

        if (apiResult.wasSuccessful) {
            runInAction(() => {
                this.removeDevice(itemId);
            });
        }
        return apiResult;
    };

    @action
    private setCommandStatuses = (statuses: DeviceStatusWithCommandModelDTO[]) => {
        this.commandStatusFilter.clear();
        this.commandStatuses.clear();

        const deviceCommandStatusVM = DeviceStatusWithCommandViewModel.Instance;
        deviceCommandStatusVM.setStatusesFromDTO(statuses);

        if (deviceCommandStatusVM.unitStatuses.length > 0) {
            // we have command statuses
            this.commandStatuses.replace(deviceCommandStatusVM.unitStatuses);
        }
    };

    @action
    private setUnitStatuses = (statuses: DeviceStatusModelDTO[]) => {
        this.unitStatuses = [];

        const deviceStatusVM = DeviceStatusViewModel.Instance;
        deviceStatusVM.setStatusesFromDTO(statuses);

        if (deviceStatusVM.unitStatuses.length > 0) {
            // we have statuses
            let temp = deviceStatusVM.unitStatuses;
            for (let i: number = 0; i < temp.length; i++) {
                this.unitStatuses.push({
                    key: temp[i].displayName,
                    value: temp[i].id,
                });

                // Populate filters
                if (this.firstTimeLoad === true) {
                    this.statusFilter.push(temp[i].id);
                    this.filterStatusFilter.push(temp[i].id);
                }
            }
            this.firstTimeLoad = false;
        }
    };

    @action
    public async loadForMainViewUnitViewAsync(): Promise<ApiResult<InstallationsAndRelatedDto>> {
        const apiResult = await this.Post<InstallationsAndRelatedDto>(Server.Api.Installation.getInstallationsForUnitList);
        if (apiResult.wasSuccessful) {
            runInAction(() => {
                this.setDevices(apiResult.payload.unitListItems);
                this.setUnitStatuses(apiResult.payload.deviceStatuses);
                this.setCommandStatuses(apiResult.payload.deviceStatusesWithCommandIdx);
            });
        }
        return apiResult;
    }

    @action
    public async loadForContractorViewUnitViewAsync(): Promise<ApiResult<UnitListItemModelDTO[]>> {
        const apiResult = await this.Post<UnitListItemModelDTO[]>(Server.Api.Installation.getContractorViewInstallationsForUnitList);

        if (apiResult.wasSuccessful) {
            runInAction(() => {
                this.setDevices(apiResult.payload);

                let statuses: DeviceStatusModelDTO[] = [];
                for (let i = 0; i < this.devices.length; i++) {
                    const status: UnitStatus = this.devices[i].status;

                    let temp: DeviceStatusModelDTO | undefined = statuses.find((a) => a.id === status.id);
                    if (temp === undefined) {
                        let dsm: DeviceStatusModelDTO = {
                            id: status.id,
                            displayName: status.name,
                            color: status.statusColour,
                            textColor: status.statusTextColour,
                        };

                        statuses.push(dsm);
                    }
                }
                this.setUnitStatuses(statuses);

                /* 
                Will need to replace the above, along with the DTO, with the following for filtering to work...
                this.setDevices(apiResult.payload.unitListItems);
                this.setUnitStatuses(apiResult.payload.deviceStatuses);
                this.setCommandStatuses(apiResult.payload.deviceStatusesWithCommandIdx); */
            });
        }
        return apiResult;
    }

    @action
    public async loadForClientViewUnitViewAsync(): Promise<ApiResult<UnitListItemModelDTO[]>> {
        const apiResult = await this.Post<UnitListItemModelDTO[]>(Server.Api.Installation.getClientViewInstallationsForUnitList);

        if (apiResult.wasSuccessful) {
            runInAction(() => {
                this.setDevices(apiResult.payload);

                let statuses: DeviceStatusModelDTO[] = [];
                for (let i = 0; i < this.devices.length; i++) {
                    const status: UnitStatus = this.devices[i].status;

                    let temp: DeviceStatusModelDTO | undefined = statuses.find((a) => a.id === status.id);
                    if (temp === undefined) {
                        let dsm: DeviceStatusModelDTO = {
                            id: status.id,
                            displayName: status.name,
                            color: status.statusColour,
                            textColor: status.statusTextColour,
                        };

                        statuses.push(dsm);
                    }
                }
                this.setUnitStatuses(statuses);

                /* 
                Will need to replace the above, along with the DTO, with the following for filtering to work...
                this.setDevices(apiResult.payload.unitListItems);
                this.setUnitStatuses(apiResult.payload.deviceStatuses);
                this.setCommandStatuses(apiResult.payload.deviceStatusesWithCommandIdx); */
            });
        }
        return apiResult;
    }

    @action
    public setSearchString = (value: string) => {
        this.searchString = value;
        this.setFilterSearchString(value);
    };

    private setFilterSearchString = debounce(
        action((value: string) => {
            this.filterSearchString = value;
        }),
        DEBOUNCE_VALUE_MS,
    );

    @action
    public setRowsPerPage(rows: number) {
        this.pageSize = rows;
    }

    @action
    public setPage = (page: number) => {
        this.currentPage = page;
    };

    @action
    public setOrderChange = (columnId: number, direction: any) => {
        this.sortColumnId = columnId;
        this.sortDirection = direction;
    };

    @action
    public getInstallationQRCode = async (deviceId: number) => {
        /*         const request = {
            id: deviceId,
        }; */

        window.open(Server.Api.Image.getInstallationQRCode + "?id=" + deviceId);

        /* const apiResult: ApiResult<FileDownloadResponseDTO> = await this.Post<FileDownloadResponseDTO>(Server.Api.Installation.getInstallationQRCode, request);

        if (apiResult.wasSuccessful) {
            runInAction(() => {
                let fileName: string = "test.bmp";

                const file: string = apiResult.payload.stringFile;
                const type: string = apiResult.payload.contentType;
                fileName = apiResult.payload.fileName;

                const url = window.URL.createObjectURL(new Blob([file], { type }));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", fileName);
                document.body.appendChild(link);
                link.click();
            });
        } else {
            // Error. What to do?
            this.setIsErrored(true);
            if (apiResult.errors !== null && apiResult.errors !== undefined && apiResult.errors.length > 0) {
                this.Errors = apiResult.errors[0].message;
                this.history.push("/");
            }
        } */
    };

    @action
    public downloadInstallationsAsCSV = async (): Promise<ApiResult<FileDownloadResponseDTO>> => {
        let apiResult: ApiResult<FileDownloadResponseDTO> = {
            wasSuccessful: false,
            errors: [],
            headers: "",
            payload: { fileName: "", stringFile: "", contentType: "", byteFile: undefined },
        };

        try {
            if (this.IsDownloading === false && this.IsLoading === false) {
                this.IsDownloading = true;

                apiResult = await this.Post<any>(Server.Api.Installation.downloadInstallationsAsCsv);

                if (apiResult.wasSuccessful) {
                    const file: string = apiResult.payload.stringFile;
                    const type: string = apiResult.payload.contentType;
                    const fileName: string = apiResult.payload.fileName;

                    const url = window.URL.createObjectURL(new Blob([file], { type }));
                    const link = document.createElement("a");

                    link.href = url;
                    link.setAttribute("download", fileName);

                    document.body.appendChild(link);

                    link.click();
                }
            }
        } catch (exception) {
        } finally {
            runInAction(() => {
                this.IsDownloading = false;
            });
        }

        return apiResult;
    };
}
