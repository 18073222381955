import { observable } from "mobx";
import { ModelBase } from "Core/Models/ModelBase";
import { IsNotEmpty } from "class-validator";
import { DrainageType } from "Custom/Models/Domain";
import { ConditionItemModelDTO } from "./Condition/ConditionItemModel";

//Base class expects the model type and the DTO model type
//If you are not using a DTO just pass in undefined as the DTO
export class AdminConditionTabModel extends ModelBase<AdminConditionTabModel, AdminConditionTabModelDTO> {
    @observable
    public name: string = "";
    public id: string = "";
    public drainageType: DrainageType = DrainageType.Gravity;
    public deviceCount: number = 0;

    //fromDto is required but you can leave it blank
    fromDto(model: AdminConditionTabModelDTO): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
    }

    //toDto is required but you can leave it blank
    toDto(model: AdminConditionTabModel): void {}
}

export type AdminConditionTabModelDTO = {
    id: string;
    name: string;
    drainageType: DrainageType;
    deviceCount: number;
    items: ConditionItemModelDTO[];
};
