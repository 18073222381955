import { GenericIdRequest } from "Custom/Models";
import { ViewModelBase } from "Core/ViewModels/ViewModelBase";
import { AlertActionAddEditDetailModel, AlertActionAddEditDetailModelDTO } from "./AlertActionAddEditDetailModel";
import { action, computed, observable, runInAction } from "mobx";
import { FieldType } from "Core/Utils/Utils";
import { AlertActionItemModel, AlertActionItemModelDTO } from "./AlertActionItemModel";
import { AlertActionItemViewModel } from "./AlertActionItemViewModel";
import { ApiResult, ApiResultErrorType } from "Core/Models";
import { Server } from "Custom/Globals/AppUrls";
import { UnitListItemModelDTO } from "Custom/Views/Installations/InstallationList/UnitListItemModel";
import { DEBOUNCE_VALUE_MS } from "Custom/Globals/Globals";
import { debounce } from "lodash-es";

//extend viewmodel base and passing your model as the generic type
export class AlertActionAddEditDetailViewModel extends ViewModelBase<AlertActionAddEditDetailModel> {
    //Singleton instance of class
    private static _instance: AlertActionAddEditDetailViewModel;
    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    @observable public errorMessage: string = "";

    @observable public items: AlertActionItemViewModel[] = [];

    @computed public get canAddAlertAction(): boolean {
        return this.items.length < 4;
    }

    @observable public units: UnitListItemModelDTO[] = [];

    @observable public pageSize: number = 50;
    @observable public currentPage: number = 0;

    @observable public searchString: string = "";
    @observable public filterSearchString: string = "";

    // By default this will be the first column (by index) and in ascending order.
    @observable public sortColumnId: number = 0;
    @observable public sortDirection: any = "asc";

    constructor() {
        //Pass in a new instance of your model
        //By passing in true as the second parameter, we make this model undoable which means we can use save and reset options on the model
        //If you make a change to the model you need to persist it with a saveModel() call
        //If you make changes to your model you can revert it back by calling resetModel()
        super(new AlertActionAddEditDetailModel(), false);
        //EN - Haven't figured out how to make this call work from the base model yet
        //This is only needed if you make use of the validation decorators
        this.setDecorators(AlertActionAddEditDetailModel);
    }

    @action
    public setSearchString = (value: string) => {
        this.searchString = value;
        this.setFilterSearchString(value);
    };

    private setFilterSearchString = debounce(
        action((value: string) => {
            this.filterSearchString = value;
        }),
        DEBOUNCE_VALUE_MS,
    );

    @action
    public setOrderChange = (columnId: number, direction: any) => {
        this.sortColumnId = columnId;
        this.sortDirection = direction;
    };

    // #endregion Project Sorting

    @action
    public setRowsPerPage(rows: number) {
        this.pageSize = rows;
    }

    @action
    public setPage = (page: number) => {
        this.currentPage = page;
    };

    @action
    addNewAlertAction() {
        let newItem: AlertActionItemViewModel = new AlertActionItemViewModel();
        newItem.model.setCommandIndex(this.items.length);
        this.items.push(newItem);
    }

    @computed get getUnits(): UnitListItemModelDTO[] {
        return this.units.slice();
    }

    @computed get getAlertActions() {
        return this.items
            .slice()
            .filter((a) => a.model.isDeleted === false)
            .sort((a: AlertActionItemViewModel, b: AlertActionItemViewModel) => {
                if (a === undefined && b === undefined) {
                    return 0;
                }

                if (a === undefined) {
                    return -1;
                }

                if (b === undefined) {
                    return 1;
                }

                return a.model.commandIndex < b.model.commandIndex ? -1 : 1;
            });
    }

    @action public isModelAndChildrenValid = (): boolean => {
        let retVal: boolean = this.isModelValid();

        if (retVal) {
            //Do stuff here
        } else {
            this.errorMessage = "Form is not valid";
        }

        for (let i: number = 0; i < this.items.length; i++) {
            if (this.items[i].isModelValidWithSiblings(i, this.items) === false) {
                retVal = false;
                this.errorMessage = "Form is not valid";
            }
        }
        return retVal;
    };

    //isValid will check all fields to make sure they are in a valid state.
    public doSubmit = async (e: any) => {
        e.preventDefault();

        if (this.isModelValid()) {
            //Do stuff here
        } else {
            this.errorMessage = "Form is not valid";
        }
    };

    //This must be present in your viewmodel. Just return true if you dont need to validate anything.
    //keyof BlankModel & string lets you add type checking to the fieldName
    //I am using the validator package to make checking easier https://www.npmjs.com/package/validator
    public isFieldValid(fieldName: keyof FieldType<AlertActionAddEditDetailModel>, value: any): boolean {
        const { isValid, errorMessage } = this.validateDecorators(fieldName);

        //You need to this two properties after validation
        this.setError(fieldName, errorMessage);
        this.setValid(fieldName, isValid);

        return isValid;
    }

    public getAlertActionItemsDTO = (): AlertActionItemModelDTO[] => {
        const retVal: AlertActionItemModelDTO[] = [];

        for (let i: number = 0; i < this.items.length; i++) {
            const model = this.items[i].model;
            if ((model.id === null || model.id === undefined) && model.isDeleted === true) {
                // then it has not gone to the DB but has been deleted, so don't send it to the DB
            } else {
                // it may (existing) or may not (new) have an id.  But isdeleted is only true if it has an id.
                // so we need to send it to soft delete it in the DB
                const dto: AlertActionItemModelDTO = model.toSendDto();
                retVal.push(dto);
            }
        }

        return retVal;
    };

    @action
    public upsertAlertAction = async (): Promise<ApiResult<AlertActionAddEditDetailModelDTO | undefined>> => {
        let apiResult: ApiResult<AlertActionAddEditDetailModelDTO | undefined> = {
            wasSuccessful: false,
            errors: [],
            headers: "",
            payload: undefined,
        };

        let request: AlertActionAddEditDetailModelDTO = {
            id: this.model.id,
            name: this.model.name,
            rowVersion: this.model.rowVersion,
            isDeleted: this.model.isDeleted,
            createdBy: this.model.createdBy,
            createdDate: this.model.createdDate,
            items: this.getAlertActionItemsDTO(),
            unitListItems: [],
        };

        apiResult = await this.Post<AlertActionAddEditDetailModelDTO>(Server.Api.AlertActions.upsertAlertAction, request);

        runInAction(() => {
            if (apiResult.wasSuccessful === true) {
                //this.showError = false;
                this.errorMessage = "";

                if (apiResult.payload !== null && apiResult.payload !== undefined) {
                    this.model.fromDto(apiResult.payload!);
                    this.items = [];
                    const items: AlertActionItemModel[] = this.model.getItems;
                    for (let i: number = 0; i < items.length; i++) {
                        let newVM = new AlertActionItemViewModel();
                        newVM.model = items[i];
                        newVM.model.setCommandIndex(i);
                        this.items.push(newVM);
                    }

                    this.units = [];
                    const units: UnitListItemModelDTO[] = this.model.getUnits;
                    this.units.push(...units);
                }

                //this.showSuccess = true;

                /*                 setTimeout(() => {
                    runInAction(() => {
                        // this.showSuccess = false;
                    });
                }, 3000); */
            } else {
                // this.isLoading = false;
                //this.showAPIError = true;
                this.errorMessage = "Failed to store the value.";

                apiResult.wasSuccessful = false;
                apiResult.errors = [
                    {
                        type: ApiResultErrorType.Basic,
                        message: "Failed to store the value.",
                    },
                ];

                /*                 setTimeout(() => {
                    runInAction(() => {
                        //this.showAPIError = false;
                    });
                }, 5000); */
            }
        });

        return apiResult;
    };

    @action
    public loadAlertActionsAsync = async (id: string): Promise<ApiResult<AlertActionAddEditDetailModelDTO | undefined>> => {
        let apiResult: ApiResult<AlertActionAddEditDetailModelDTO | undefined> = {
            wasSuccessful: false,
            errors: [],
            headers: "",
            payload: undefined,
        };

        let request: GenericIdRequest = {
            id: id,
        };

        apiResult = await this.Post<AlertActionAddEditDetailModelDTO | undefined>(Server.Api.AlertActions.getAlertActionById, request);

        runInAction(() => {
            if (apiResult.wasSuccessful === true) {
                //this.showError = false;
                this.errorMessage = "";

                if (apiResult.payload !== null && apiResult.payload !== undefined) {
                    this.model.fromDto(apiResult.payload!);
                    this.items = [];
                    const items: AlertActionItemModel[] = this.model.getItems;
                    for (let i: number = 0; i < items.length; i++) {
                        let newVM = new AlertActionItemViewModel();
                        newVM.model = items[i];
                        newVM.model.setCommandIndex(i);
                        this.items.push(newVM);
                    }

                    this.units = [];
                    const units: UnitListItemModelDTO[] = this.model.getUnits;
                    this.units.push(...units);
                }
                //this.showSuccess = true;
                /*                 setTimeout(() => {
                    runInAction(() => {
                        // this.showSuccess = false;
                    });
                }, 3000); */
            } else {
                // this.isLoading = false;
                //this.showAPIError = true;
                this.errorMessage = "Failed to store the value.";

                apiResult.wasSuccessful = false;
                apiResult.errors = [
                    {
                        type: ApiResultErrorType.Basic,
                        message: "Failed to store the value.",
                    },
                ];

                /*                 setTimeout(() => {
                    runInAction(() => {
                        //this.showAPIError = false;
                    });
                }, 5000); */
            }
        });

        return apiResult;
    };

    @action
    public getIsAlertActionValid = async (): Promise<ApiResult<number | undefined>> => {
        let apiResult: ApiResult<number | undefined> = {
            wasSuccessful: false,
            errors: [],
            headers: "",
            payload: undefined,
        };

        let request: AlertActionAddEditDetailModelDTO = {
            id: this.model.id,
            name: this.model.name,
            rowVersion: this.model.rowVersion,
            isDeleted: this.model.isDeleted,
            createdBy: this.model.createdBy,
            createdDate: this.model.createdDate,
            items: this.getAlertActionItemsDTO(),
            unitListItems: [],
        };

        apiResult = await this.Post<number | undefined>(Server.Api.AlertActions.getIsAlertActionValid, request);

        runInAction(() => {
            if (apiResult.wasSuccessful === true) {
                this.errorMessage = "";
            } else {
                this.errorMessage = "Invalid condition.";
            }
        });

        return apiResult;
    };

    public afterUpdate: undefined;
    public beforeUpdate: undefined;
}
