import React, { useContext, useEffect, useState } from "react";
import { useObserver } from "mobx-react-lite";
import { HideOnGraph, SensorValueExtended } from "Custom/Models/Domain/Installations";
import { Box, Fade, Paper, useTheme } from "@material-ui/core";
import { RoofcareTable, RoofcareTableBox } from "Custom/Components/Table/RoofcareTable";
import { formatDateTime } from "Custom/Utils/format";
import { Moment } from "moment";
import { UnitStatusBox } from "Custom/Components";

import * as Defaults from "Custom/StylesAppSpecific/TableOptions";
import { InstallationDataTableViewModel } from "./InstallationDataTableViewModel";

interface InstallationDataTableProps {
    installHeight: number;
    sensorData: SensorValueExtended[];
    updateIsHidden: (isHidden: HideOnGraph) => any;
    startDate: Moment;
    endDate: Moment;
}

export function InstallationDataTable(props: InstallationDataTableProps) {
    const [viewModel] = useState(() => InstallationDataTableViewModel.Instance);

    const { sensorData, updateIsHidden, installHeight, startDate, endDate } = props;
    const theme = useTheme();
    const tableOptions = Defaults.GetRoofcareTableOptions() as any;

    const displayTemperature = (rowData: SensorValueExtended): any => {
        // 2020-07-20 CMR changed to 100 from 40 after yesterdays highest ever temperature, pushed the
        // units above 40 degrees.   Old unit (pre sensor) database values are 151 degrees
        if (rowData.temperature! <= 100) {
            return <div>{rowData.temperature?.toFixed(2)}</div>;
        } else {
            return <div></div>;
        }
    };

    const displayBatteryPercentage = (rowData: SensorValueExtended): any => {
        let retVal: string | undefined = rowData.batteryPercentage?.toFixed(2);
        if (retVal !== undefined) {
            if (retVal === "-0.00") {
                return "0.00";
            } else {
                return <div>{rowData.batteryPercentage?.toFixed(2)}</div>;
            }
        } else {
            return <div></div>;
        }
    };

    const getSortDirection = (columnIndex: number) => {
        return viewModel.sortColumnId === columnIndex ? viewModel.sortDirection : "";
    };

    const setRowsPerPage = (rows: number) => {
        viewModel.setRowsPerPage(rows);
    };

    const setPage = (page: number) => {
        viewModel.setPage(page);
    };

    const setOrderChange = (columnId: number, direction: any) => {
        viewModel.setOrderChange(columnId, direction);
    };

    return useObserver(() => (
        <Fade in timeout={1000}>
            <RoofcareTableBox padding="15px">
                <RoofcareTable
                    columns={[
                        {
                            title: "Status",
                            field: "statusFilter",
                            sorting: true,
                            defaultSort: getSortDirection(0),
                            filtering: false,
                            render: (rowData: SensorValueExtended) => (
                                <UnitStatusBox className={"device-status "} backgroundColor={rowData.state.statusColour} textColour={rowData.state.statusTextColour}>
                                    <div className="status-name">{rowData.state.name}</div>
                                </UnitStatusBox>
                            ),
                        },
                        {
                            title: "Time of Reading",
                            field: "updated",
                            type: "date",
                            sorting: true,
                            defaultSort: getSortDirection(1),
                            filtering: false,
                            render: (rowData: SensorValueExtended) => <Box>{formatDateTime(rowData.updated)}</Box>,
                        },
                        {
                            title: "Received Time",
                            field: "createdDate",
                            type: "datetime",
                            sorting: true,
                            defaultSort: getSortDirection(2),
                            filtering: false,
                            width: 150,
                            render: (rowData: SensorValueExtended) => <Box>{formatDateTime(rowData.createdDate)}</Box>,
                        },
                        {
                            title: "Raw data",
                            field: "valuesA",
                            sorting: true,
                            defaultSort: getSortDirection(3),
                            filtering: false,
                        },
                        {
                            title: "Calculated Value (mm)",
                            field: "calculatedValue",
                            sorting: true,
                            defaultSort: getSortDirection(4),
                            filtering: false,
                            render: (rowData: SensorValueExtended) => {
                                if (rowData !== null && rowData !== undefined) {
                                    let cV: number = installHeight - rowData.calculatedValue > 0 ? installHeight - rowData.calculatedValue : 0;

                                    if (cV >= installHeight) {
                                        cV = 0;
                                    }
                                    const calculatedValue: string = cV.toFixed(2);
                                    return (
                                        <div className={"device-calculatedValue " /* TODO CMR + InstallationStatusHelpers.getClassName(rowData.status) */}>
                                            <div>{calculatedValue}</div>
                                        </div>
                                    );
                                }
                            },
                        },
                        {
                            title: "Hide on Graph",
                            field: "hideOnGraph",
                            sorting: true,
                            defaultSort: getSortDirection(5),
                            filtering: false,
                            render: (rowData: SensorValueExtended) => {
                                return (
                                    <input
                                        checked={rowData.hideOnGraph}
                                        className="form-control"
                                        name={"hideOnGraph"}
                                        onClick={() => {
                                            const newValue: HideOnGraph = {
                                                id: rowData.id,
                                                hideOnGraph: !rowData.hideOnGraph,
                                                startDate: startDate.toISOString(),
                                                endDate: endDate.toISOString(),
                                            };

                                            updateIsHidden(newValue);
                                        }}
                                        type="checkbox"
                                        value={rowData.hideOnGraph === true ? 1 : 0}
                                    />
                                );
                            },
                        },
                        { title: "Battery Voltage", field: "battery", sorting: true, filtering: false },
                        {
                            title: "Battery %",
                            field: "batteryPercentage",
                            sorting: true,
                            defaultSort: getSortDirection(6),
                            filtering: false,
                            render: (rowData: SensorValueExtended) => displayBatteryPercentage(rowData),
                        },
                        {
                            title: "Temperature",
                            field: "temperature",
                            sorting: true,
                            defaultSort: getSortDirection(7),
                            filtering: false,
                            render: (rowData: SensorValueExtended) => displayTemperature(rowData),
                        },
                    ]}
                    data={sensorData}
                    components={{ Container: (props: any) => <Paper {...props} elevation={0} /> }}
                    options={{
                        thirdSortClick: false,
                        sorting: true,
                        filtering: false,
                        search: false,
                        toolbar: false,
                        cellStyle: {
                            fontSize: 10,
                        },
                        initialPage: viewModel.currentPage,
                        pageSize: viewModel.pageSize,
                        pageSizeOptions: [10, 20, 50, 100],
                        emptyRowsWhenPaging: false,
                        headerStyle: {
                            ...tableOptions.headerStyle,
                        },
                        rowStyle: {
                            ...tableOptions.rowStyle,
                        },
                        tableLayout: "fixed",
                    }}
                    title=""
                    onOrderChange={(orderedColumnId: any, orderDirection: any) => {
                        setOrderChange(orderedColumnId, orderDirection);
                    }}
                    onChangeRowsPerPage={(rows: number) => {
                        setRowsPerPage(rows);
                    }}
                    onChangePage={(page: number) => {
                        setPage(page);
                    }}
                />
            </RoofcareTableBox>
        </Fade>
    ));
}
