import styled from "styled-components";

export const PropertyOverviewContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1 0 0;
    justify-content: space-around;
    margin-top: 15px;
    position: relative;
    width: 100%;
    padding: 0 15px;

    button {
        padding: 3px !important;

        .circularbutton {
            width: 50px;
            height: 50px;
            border-radius: 25px;
            background-color: rgba(255, 255, 255, 0.5);
            margin-top: -25px;
            .arrow {
                margin-top: 13px;
                font-size: 24px;
            }
        }
    }
`;

export const PropertyOverviewColumn = styled.div`
    display: flex;
    flex-direction: column;

    .tabcellName {
        .celltitle:hover {
            cursor: pointer;
        }
    }
`;
