import { Box, Fade, Paper, Typography, useTheme } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useObserver } from "mobx-react-lite";

import { Loader } from "Core/Components";
import { RoofcareTable, RoofcareTableBox, RoofcareTableWrapper } from "Custom/Components/Table/RoofcareTable";
import { ResetSearchIcon } from "Custom/Components/Table/ResetSearchIcon";
import { useRouter } from "Core/Utils";
import { Container } from "Custom/StylesAppSpecific/AppStyling";
import { AppUrls } from "Custom/Globals";
import { MultiSelectView } from "Custom/Components/Select/MultipleSelect";
import { UnitFilterSection } from "./units.styles";
import * as RouterUtil from "Custom/Utils/routing";
import { ClickableImgCell } from "Custom/Components/Table/ClickableImgCell";
import EditLogo from "Custom/Content/editIcon.svg";
import DeleteLogo from "Custom/Content/deleteIcon.svg";

import { Stores, StoresContext } from "Custom/Stores";
import { ProjectToolbarRowV2, ProjectToolbarRowV3 } from "Custom/StylesAppSpecific/Controls/BoxAddWrapper";

import * as Defaults from "Custom/StylesAppSpecific/TableOptions";
import { UnitsViewModel } from "./UnitsViewModel";
import { UnitListItemDTO } from "./UnitListItem";
import { formatBooleanYesNo, formatDate } from "Custom/Utils/format";
import { sortByString } from "Core/Utils/Utils";
import { DeleteModal } from "Custom/Components/Modal/DeleteModal";
import { getStandingWaterBackgroundColour, getStandingWaterTextColour, StandingWaterBox } from "Custom/Components/StandingWater/StandingWater";
import { UnitListItemModelDTO } from "../Installations/InstallationList/UnitListItemModel";

interface IUnitListTabViewProps {
    setEditUnit: (id: string) => void;
}

interface IState {
    areYouSureOpen: boolean;
    deviceId: number | undefined;
}

export const UnitListTabView: React.FunctionComponent<IUnitListTabViewProps> = ({ setEditUnit }) => {
    const store = React.useContext<Stores>(StoresContext);
    const [viewModel] = useState(() => UnitsViewModel.Instance);
    const theme = useTheme();
    const router = useRouter();
    const { history } = useRouter();
    const [state, updateState] = useState<IState>({ areYouSureOpen: false, deviceId: undefined });

    //useEffect below only gets run on initial render
    useEffect(() => {
        /*         const apiResult = viewModel.loadForUnitViewAsync();

        apiResult.then((result) => {}); */

        return () => {
            // Don't do this on the tab switch
            // The parent funtion will do this when the page unloads
            // viewModel.cleanUp();
        };
    }, []);

    const handleEditClick = (id: string) => {
        setEditUnit(id);
    };

    const handleDeleteClick = (id: string) => {
        updateState({ areYouSureOpen: true, deviceId: parseInt(id, 10) });
    };

    const setSearchString = (value: string) => {
        viewModel.setSearchString(value);
        viewModel.setPage(0);
    };

    const setRowsPerPage = (rows: number) => {
        viewModel.setRowsPerPage(rows);
    };

    const setPage = (page: number) => {
        viewModel.setPage(page);
    };

    const setOrderChange = (columnId: number, direction: any) => {
        viewModel.setOrderChange(columnId, direction);
    };

    const handleRowClick = (rowData: any) => {
        const { path } = router.match;
        let url: string = AppUrls.Client.Main.Unit.Installation;

        switch (path) {
            case AppUrls.Client.Main.Unit.Root: {
                url = AppUrls.Client.Main.Unit.Installation;
                break;
            }
            /* case AppUrls.Client.ContractorView.Device.DeviceRoot: {
                url = AppUrls.Client.ContractorView.Device.Installation;
                break;
            } 
            case AppUrls.Client.Main.Unit.Root:*/
            default: {
                url = AppUrls.Client.Main.Unit.Root;
                break;
            }
        }

        history.push(url.replace(":installId", rowData.id));
    };

    const isInError = (fieldName: string): boolean => {
        /*         let isValid = props.filtersViewModel.getValid(fieldName);
        return !isValid; */

        return false;
    };

    const getError = (fieldName: string): string => {
        /*         let retVal: string = props.filtersViewModel.getError(fieldName);
        return retVal; */
        return "";
    };

    const handleStatusChange = (values: string[] | number[] | null) => {
        const valueList: string[] = values as string[];
        const intValues: string[] = [];

        for (let i: number = 0; i < valueList.length; i++) {
            const item: string = valueList[i];
            intValues.push(item);
        }

        viewModel.setUnitStatusFilter(intValues);
    };

    const handleTestGradeChange = (values: string[] | number[] | null) => {
        const valueList: string[] = values as string[];
        const intValues: string[] = [];

        for (let i: number = 0; i < valueList.length; i++) {
            const item: string = valueList[i];
            intValues.push(item);
        }

        viewModel.setUnitTestGradesFilter(intValues);
    };

    const handleVersionChange = (values: string[] | number[] | null) => {
        const valueList: string[] = values as string[];
        const intValues: string[] = [];

        for (let i: number = 0; i < valueList.length; i++) {
            const item: string = valueList[i];
            intValues.push(item);
        }

        viewModel.setUnitVersionFilter(intValues);
    };

    const handleFirmwareChange = (values: string[] | number[] | null) => {
        const valueList: string[] = values as string[];
        const intValues: string[] = [];

        for (let i: number = 0; i < valueList.length; i++) {
            const item: string = valueList[i];
            intValues.push(item);
        }

        viewModel.setUnitFirmwareFilter(intValues);
    };

    /**
     * Get the sort direction stored in the viewmodel for the provided column index.
     * Only use this on columns that have "sorting" set to true.
     */
    const getSortDirection = (columnIndex: number) => {
        return viewModel.sortColumnId === columnIndex ? viewModel.sortDirection : "";
    };

    const formatEdit = (id: string): any => {
        return <ClickableImgCell logo={EditLogo} text={""} onClick={handleEditClick} id={id} />;
    };

    const formatDelete = (id: string): any => {
        return <ClickableImgCell logo={DeleteLogo} text={""} onClick={handleDeleteClick} id={id} />;
    };

    const canShowAddInstallation: boolean = RouterUtil.requireContractorAdminorAdminLogin(store) && viewModel.model.okToAddInstallation === true;

    const handleAreYouSureClose = (): any => {
        updateState({ areYouSureOpen: false, deviceId: undefined });
    };

    const handleDeleteUnitBehaviour = (): any => {
        let promise = viewModel.deleteUnit(state.deviceId);
        updateState({ areYouSureOpen: false, deviceId: undefined });
    };

    const handleStandingWaterChange = (values: string[] | number[] | null) => {
        const valueList: string[] = values as string[];
        const intValues: string[] = [];

        for (let i: number = 0; i < valueList.length; i++) {
            const item: string = valueList[i];
            intValues.push(item);
        }

        viewModel.setStandingWaterFilter(intValues);
    };

    const handleQRClick = (e: React.MouseEvent, data: UnitListItemDTO) => {
        e.stopPropagation();
        let promise = viewModel.getInstallationQRCode(data.id);
    };

    const renderPage = () => {
        if (viewModel.IsLoading) return <Loader />;
        const tableOptions = Defaults.GetRoofcareTableOptions() as any;

        return (
            <Fade in timeout={1000}>
                <Container>
                    {
                        <>
                            <ProjectToolbarRowV3>
                                <UnitFilterSection>
                                    <Typography variant="h1" className="select-title" style={{ color: theme.palette.secondary.contrastText }}>
                                        Test Grade:
                                    </Typography>
                                    <Box className="multiselectbox">
                                        <MultiSelectView
                                            fieldName=""
                                            values={viewModel.getTestGradesFilter()}
                                            display="Test Grade"
                                            useNumber={true}
                                            getOptions={viewModel.getTestGradeKVPair}
                                            handleChange={handleTestGradeChange}
                                            getError={getError}
                                            getValid={isInError}
                                        />
                                    </Box>
                                </UnitFilterSection>
                                <UnitFilterSection>
                                    <Typography variant="h1" className="select-title" style={{ color: theme.palette.secondary.contrastText }}>
                                        Version:
                                    </Typography>
                                    <Box className="multiselectbox">
                                        <MultiSelectView
                                            fieldName=""
                                            values={viewModel.getVersionFilter()}
                                            display="Version"
                                            useNumber={true}
                                            getOptions={viewModel.getVersionKVPair}
                                            handleChange={handleVersionChange}
                                            getError={getError}
                                            getValid={isInError}
                                        />
                                    </Box>
                                </UnitFilterSection>
                                <UnitFilterSection>
                                    <Typography variant="h1" className="select-title" style={{ color: theme.palette.secondary.contrastText }}>
                                        Firmware:
                                    </Typography>
                                    <Box className="multiselectbox">
                                        <MultiSelectView
                                            fieldName=""
                                            values={viewModel.getFirmwareFilter()}
                                            display="Firmware"
                                            useNumber={true}
                                            getOptions={viewModel.getFirmwareKVPair}
                                            handleChange={handleFirmwareChange}
                                            getError={getError}
                                            getValid={isInError}
                                        />
                                    </Box>
                                </UnitFilterSection>
                            </ProjectToolbarRowV3>
                            <ProjectToolbarRowV3>
                                <UnitFilterSection>
                                    <Typography variant="h1" className="select-title" style={{ color: theme.palette.secondary.contrastText }}>
                                        Current Status:
                                    </Typography>
                                    <Box className="multiselectbox">
                                        <MultiSelectView
                                            fieldName=""
                                            values={viewModel.getStatusFilter()}
                                            display="Status"
                                            useNumber={true}
                                            getOptions={viewModel.getUnitStatusKVPair}
                                            handleChange={handleStatusChange}
                                            getError={getError}
                                            getValid={isInError}
                                        />
                                    </Box>
                                </UnitFilterSection>
                                <UnitFilterSection>
                                    <Typography variant="h1" className="select-title" style={{ color: theme.palette.secondary.contrastText }}>
                                        Standing Water:
                                    </Typography>
                                    <Box className="multiselectbox">
                                        <MultiSelectView
                                            fieldName=""
                                            values={viewModel.getStandingWaterFilter()}
                                            display="Standing Water"
                                            useNumber={true}
                                            getOptions={viewModel.getStandingWaterKVPair}
                                            handleChange={handleStandingWaterChange}
                                            getError={getError}
                                            getValid={isInError}
                                        />
                                    </Box>
                                </UnitFilterSection>
                                <UnitFilterSection>{/*Next filter*/}</UnitFilterSection>
                            </ProjectToolbarRowV3>
                            <RoofcareTableBox style={{ padding: 0 }}>
                                <RoofcareTable
                                    columns={[
                                        { title: "Unit name", minWidth: "200px", maxWidth: "200px", field: "name", sorting: true, defaultSort: getSortDirection(0) },
                                        { width: "auto", title: "Sim Id", field: "simId", sorting: true, defaultSort: getSortDirection(1) },

                                        {
                                            width: "auto",
                                            title: "Delivery Date",
                                            field: "unitData",
                                            defaultSort: getSortDirection(2),
                                            sorting: true,
                                            filtering: true,
                                            render: (rowData: UnitListItemDTO) => {
                                                if (rowData !== null && rowData !== undefined && rowData.unitData !== undefined) {
                                                    if (rowData.unitData.deliveryDate === null || rowData.unitData.deliveryDate === undefined) {
                                                        return "";
                                                    } else {
                                                        return formatDate(rowData.unitData.deliveryDate);
                                                    }
                                                }
                                            },
                                        },
                                        {
                                            width: "auto",
                                            title: "Acceptance",
                                            field: "unitData",
                                            defaultSort: getSortDirection(3),
                                            sorting: true,
                                            filtering: true,
                                            render: (rowData: UnitListItemDTO) => {
                                                if (rowData !== null && rowData !== undefined && rowData.unitData !== undefined) {
                                                    if (rowData.unitData.acceptanceDate === null || rowData.unitData.acceptanceDate === undefined) {
                                                        return "";
                                                    } else {
                                                        return formatDate(rowData.unitData.acceptanceDate);
                                                    }
                                                }
                                            },
                                        },
                                        {
                                            width: "auto",
                                            title: "Test Grade",
                                            field: "unitData.testGrade",
                                            defaultSort: getSortDirection(4),
                                            customSort: (a: UnitListItemDTO, b: UnitListItemDTO) => sortByString(a.unitData.testGrade, b.unitData.testGrade),
                                            filtering: true,
                                            render: (rowData: UnitListItemDTO) => {
                                                if (rowData !== null && rowData !== undefined && rowData.unitData !== undefined) {
                                                    return rowData.unitData.testGrade;
                                                }
                                            },
                                        },
                                        {
                                            width: "auto",
                                            title: "Version",
                                            field: "unitData.version",
                                            defaultSort: getSortDirection(5),
                                            customSort: (a: UnitListItemDTO, b: UnitListItemDTO) => sortByString(a.unitData.version, b.unitData.version),
                                            filtering: true,
                                            render: (rowData: UnitListItemDTO) => {
                                                if (rowData !== null && rowData !== undefined && rowData.unitData !== undefined) {
                                                    return rowData.unitData.version;
                                                }
                                            },
                                        },
                                        {
                                            width: "auto",
                                            title: "Firmware",
                                            field: "unitData",
                                            defaultSort: getSortDirection(6),
                                            customSort: (a: UnitListItemDTO, b: UnitListItemDTO) => sortByString(a.unitData.firmware, b.unitData.firmware),
                                            filtering: true,
                                            render: (rowData: UnitListItemDTO) => {
                                                if (rowData !== null && rowData !== undefined && rowData.unitData !== undefined) {
                                                    return rowData.unitData.firmware;
                                                }
                                            },
                                        },
                                        {
                                            width: "auto",
                                            title: "Status",
                                            field: "unitData",
                                            defaultSort: getSortDirection(7),
                                            customSort: (a: UnitListItemDTO, b: UnitListItemDTO) => sortByString(a.unitData.currentStatus, b.unitData.currentStatus),

                                            filtering: true,
                                            render: (rowData: UnitListItemDTO) => {
                                                if (rowData !== null && rowData !== undefined && rowData.unitData !== undefined) {
                                                    return rowData.unitData.currentStatus;
                                                }
                                            },
                                        },
                                        {
                                            title: "Standing Water",
                                            field: "standingWaterEnum",
                                            defaultSort: getSortDirection(8),
                                            sorting: true,
                                            filtering: true,
                                            render: (rowData: UnitListItemDTO) => {
                                                if (rowData !== null && rowData !== undefined && rowData.standingWaterEnum !== undefined) {
                                                    return (
                                                        <StandingWaterBox
                                                            className={"standing-water overview"}
                                                            backgroundColor={getStandingWaterBackgroundColour(rowData.standingWaterEnum)}
                                                            textColour={getStandingWaterTextColour(rowData.standingWaterEnum)}
                                                        >
                                                            <div className="status-name">{rowData.standingWaterPercent + "% over " + rowData.standingWaterDays + " days"}</div>
                                                        </StandingWaterBox>
                                                    );
                                                }
                                            },
                                        },
                                        {
                                            width: "auto",
                                            title: "Address",
                                            field: "addressLine1",
                                            defaultSort: getSortDirection(9),
                                            sorting: true,
                                            filtering: false,
                                            render: (rowData: UnitListItemDTO) => {
                                                let retVal: string = "";
                                                let hasBuildingName: boolean = false;

                                                if (rowData.buildingName !== null && rowData.buildingName !== undefined && rowData.buildingName.length > 0) {
                                                    retVal = rowData.buildingName;
                                                    hasBuildingName = true;
                                                }

                                                if (rowData.addressLine1 !== null && rowData.addressLine1 !== undefined && rowData.addressLine1.length > 0) {
                                                    if (hasBuildingName === true) {
                                                        retVal += ", " + rowData.addressLine1;
                                                    } else {
                                                        retVal = rowData.addressLine1;
                                                    }
                                                }

                                                if (rowData.city !== null && rowData.city !== undefined && rowData.city.length > 0) {
                                                    retVal += ", " + rowData.city;
                                                }

                                                if (rowData.postcode !== null && rowData.postcode !== undefined && rowData.postcode.length > 0) {
                                                    retVal += ", " + rowData.postcode;
                                                }

                                                return retVal;
                                            },
                                        },
                                        {
                                            width: "50px",
                                            title: "QR",
                                            field: "unitData.qrProjectId",
                                            sorting: true,
                                            filtering: false,
                                            render: (rowData: UnitListItemDTO) => {
                                                if (
                                                    rowData.simId.length > 0 &&
                                                    rowData.deviceId.length > 0 &&
                                                    rowData.unitData.qrProjectId !== undefined &&
                                                    rowData.unitData.qrProjectId !== null
                                                ) {
                                                    return (
                                                        <div onClick={(e) => handleQRClick(e, rowData)}>
                                                            <p className="fa fa-qrcode"></p>
                                                        </div>
                                                    );
                                                } else {
                                                    return <div></div>;
                                                }
                                            },
                                        },
                                        {
                                            width: "auto",
                                            title: "Edit unit",
                                            field: "status",
                                            sorting: false,
                                            filtering: false,
                                            render: (rowData: UnitListItemDTO) => {
                                                return <>{canShowAddInstallation && <div className="device-editinstallation">{formatEdit(rowData.id!.toString())}</div>}</>;
                                            },
                                        },
                                        {
                                            width: "50px",
                                            title: "Hide unit",
                                            field: "status",
                                            sorting: false,
                                            filtering: false,
                                            render: (rowData: UnitListItemDTO) => {
                                                return <>{canShowAddInstallation && <div className="device-editinstallation">{formatDelete(rowData.id!.toString())}</div>}</>;
                                            },
                                        },
                                    ]}
                                    options={{
                                        thirdSortClick: false,
                                        cellStyle: {
                                            fontSize: 10,
                                        },
                                        initialPage: viewModel.currentPage,
                                        pageSize: viewModel.pageSize,
                                        defaultGroupOrder: viewModel.sortColumnId,
                                        defaultGroupDirection: viewModel.sortDirection,
                                        pageSizeOptions: [10, 20, 50, 100],
                                        search: false,
                                        toolbar: false,
                                        emptyRowsWhenPaging: false,
                                        headerStyle: {
                                            ...tableOptions.headerStyle,
                                        },
                                        rowStyle: {
                                            ...tableOptions.rowStyle,
                                        },
                                        filterCellStyle: {
                                            ...tableOptions.filterCellStyle,
                                        },
                                        searchFieldAlignment: "left",
                                        searchFieldStyle: {
                                            border: "1px solid #333333",
                                            borderRadius: "5px",
                                            width: "400px",
                                        },
                                    }}
                                    icons={{
                                        ResetSearch: () => <ResetSearchIcon />,
                                    }}
                                    data={viewModel.getUnits}
                                    title="Units"
                                    components={{ Container: (props: any) => <Paper {...props} elevation={0} /> }}
                                    onSearchChange={(value: string) => {
                                        setSearchString(value);
                                    }}
                                    onOrderChange={(orderedColumnId: any, orderDirection: any) => {
                                        setOrderChange(orderedColumnId, orderDirection);
                                    }}
                                    onChangeRowsPerPage={(rows: number) => {
                                        setRowsPerPage(rows);
                                    }}
                                    onChangePage={(page: number) => {
                                        setPage(page);
                                    }}
                                    onRowClick={(_: any, rowData: any) => handleRowClick(rowData)}
                                />
                            </RoofcareTableBox>
                        </>
                    }

                    <DeleteModal
                        open={state.areYouSureOpen}
                        onClose={handleAreYouSureClose}
                        onDelete={handleDeleteUnitBehaviour}
                        text="Are you sure you want to hid this unit?"
                        title="Hide unit?"
                    />
                </Container>
            </Fade>
        );
    };

    return useObserver(() => renderPage());
};
