import { ModelBase } from "Core/Models";
import { StandingWaterStatus, UnitStatus, UnitStatusDTO } from "Custom/Models/Domain";
import { action, observable } from "mobx";

//Base class expects the model type and the DTO model type
//If you are not using a DTO just pass in undefined as the DTO
export class UnitListUnitItemModel extends ModelBase<UnitListUnitItemModel, UnitListUnitItemModelDTO> {
    // DarwinModelBase
    // public id: string = "";
    // public createdDate: string = "";

    //@observable
    public status: UnitStatus = new UnitStatus();
    public latestReadingTime: string = "";
    public id: number = 0;
    public number: string = "";
    public deviceId: string = "";
    public simId: string = "";
    public name: string = "";
    public latitude: number = 0;
    public longitude: number = 0;
    public roofcareAddressId: string | null = null;
    public contractorId: string | null = null;
    public clientName: string = "";
    public clientId: string | null = null;
    public clientContactName: string = "";
    public clientContactPhone: string = "";
    public clientContactEmail: string = "";
    public addressLine1: string = "";
    public addressLine2: string = "";
    public city: string = "";
    public postcode: string = "";
    public locationLatitude: number | null = null;
    public locationLongitude: number | null = null;
    public contractorName: string = "";
    public contractorContactName: string = "";
    public contractorContactPhone: string = "";
    public contractorContactEmail: string = "";
    public okToAddInstallation: boolean = true;
    public isDeleted: boolean = false;

    public standingWaterEnum: StandingWaterStatus = StandingWaterStatus.Unknown;
    public standingWaterPercent: number = 10;
    public standingWaterDays: number = 5;

    @action public clear() {
        this.status = new UnitStatus();
        this.latestReadingTime = "";
        this.id = 0;
        this.number = "";
        this.deviceId = "";
        this.simId = "";
        this.name = "";
        this.latitude = 0;
        this.longitude = 0;
        this.roofcareAddressId = null;
        this.contractorId = null;
        this.clientName = "";
        this.clientId = null;
        this.clientContactName = "";
        this.clientContactPhone = "";
        this.clientContactEmail = "";
        this.addressLine1 = "";
        this.addressLine2 = "";
        this.city = "";
        this.postcode = "";
        this.locationLatitude = null;
        this.locationLongitude = null;
        this.contractorName = "";
        this.contractorContactName = "";
        this.contractorContactPhone = "";
        this.contractorContactEmail = "";
        this.standingWaterEnum = StandingWaterStatus.Unknown;
        this.standingWaterPercent = 10;
        this.standingWaterDays = 5;
    }

    //fromDto is required but you can leave it blank
    fromDto(model: UnitListUnitItemModelDTO): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
    }

    //toDto is required but you can leave it blank
    toDto(model: UnitListUnitItemModel): void {}
}

export type UnitListUnitItemModelDTO = {
    status: UnitStatusDTO;
    latestReadingTime: string;
    id: number;
    number: string;
    deviceId: string;
    simId: string;
    name: string;
    latitude: number;
    longitude: number;
    roofcareAddressId: string | null;
    contractorId: string | null;
    clientName: string;
    clientId: string | null;
    clientContactName: string;
    clientContactPhone: string;
    clientContactEmail: string;
    addressLine1: string;
    addressLine2: string;
    city: string;
    postcode: string;
    locationLatitude: number | null;
    locationLongitude: number | null;
    contractorName: string;
    contractorContactName: string;
    contractorContactPhone: string;
    contractorContactEmail: string;
    isDeleted: boolean;
    standingWaterEnum: StandingWaterStatus;
    standingWaterPercent: number;
    standingWaterDays: number;
};
