import { IsNotEmpty } from "class-validator";
import { ModelBase } from "Core/Models";
import { generateID } from "Core/Utils/Utils";
// import { IsGreaterThan } from "Custom/Utils/validators";
// import { DeviceAlertModel } from "Custom/Views/Installations/DeviceAlertModel";
import { action, computed, observable } from "mobx";
export class InstallationAlertModel extends ModelBase<InstallationAlertModel, InstallationAlertDTO> {
    @observable
    public MAXHEIGHTVALUE: number = 1000;

    public uniqueReference: string = generateID();

    public id: number = -1;

    public deviceDbId: number = -1;

    public authorId: string = "";

    @observable
    @IsNotEmpty({ message: "Lower value is required!" })
    public lowerBound: number = 0;

    @observable
    @IsNotEmpty({ message: "Upper value is required!" })
    // This just never fires. @IsGreaterThan("lowerBound", { message: "Upper value must be greater than the lower bound" })
    public upperBound: number = 0;

    public publishDate: string = "";

    @observable
    public photo: boolean = false;

    @observable
    public sms: boolean = false;

    @observable
    public email: boolean = false;

    //fromDto is required but you can leave it blank
    fromDto(model: InstallationAlertDTO): void {
        //this just iterates through every key assigning it to the model
        //Should only use if there is a direct mapping between dto and domain model
        //otherwise just map them yourself
        for (let key in model) {
            if (model.hasOwnProperty(key)) {
                if (this[key] instanceof Date) {
                    this[key] = new Date(model[key]);
                } else {
                    this[key] = model[key];
                }
            }
        }
    }

    //toDto is required but you can leave it blank
    toDto(model: InstallationAlertModel): void {}

    toDTO(): InstallationAlertDTO {
        const retVal: InstallationAlertDTO = {
            authorId: this.authorId,
            deviceDbId: this.deviceDbId,
            email: this.email,
            id: this.id,
            lowerBound: this.lowerBound,
            photo: this.photo,
            publishDate: this.publishDate,
            sms: this.sms,
            upperBound: this.upperBound,
            uniqueReference: this.uniqueReference,
        };

        return retVal;
    }
}

export interface InstallationAlertDTO {
    id: number | undefined;

    deviceDbId: number;

    authorId: string;

    lowerBound: number;

    upperBound: number;

    publishDate: string;

    photo: boolean;

    sms: boolean;

    email: boolean;

    uniqueReference: string;
}

export const DefaultInstallationAlert: InstallationAlertDTO = {
    authorId: "",
    deviceDbId: -1,
    id: -1,
    lowerBound: -1,
    publishDate: "",
    upperBound: -1,
    photo: false,
    sms: false,
    email: false,
    uniqueReference: generateID(),
};

export interface InstallationAlertError {
    reference: string;
    property: string;
    message: string;
    index: number;
}
