import * as React from "react";
import MaterialTable from "material-table";
import { ResetSearchIcon } from "Custom/Components/Table/ResetSearchIcon";
import { PropertyListContainer } from "./PropertyList.styles";
import { useObserver, useRouter } from "Core/Base";
import { AppUrls } from "Custom/Globals";
import { ProjectDetailsViewModel } from "../ProjectDetailsViewModel";
import { Paper, useTheme } from "@material-ui/core";
import { isNullOrUndefined } from "Custom/Utils/utils";
import { ClickableImgCell } from "Custom/Components/Table/ClickableImgCell";
import EditLogo from "Custom/Content/editIcon.svg";
import { PropertyAddModal } from "Custom/Views/Projects/PropertyAddModal";
import { AddressModel, Property } from "Custom/Models/Domain";
import { OSMLocation } from "Custom/Utils/OSM";
import { OSMViewModel } from "Custom/ViewModels/OSMViewModel";
import { UnitStatusBox } from "Custom/Components";
interface IProps {
    viewModel: ProjectDetailsViewModel;
}

export const PropertyList: React.FC<IProps> = ({ viewModel }) => {
    const router = useRouter<{ projectId?: string; clientId?: string }>();
    const [propertyOpen, setPropertyOpen] = React.useState(false);
    const [osmViewModel] = React.useState(() => OSMViewModel.Instance);
    const [propertyAddress, setPropertyAddress] = React.useState<AddressModel>(new AddressModel());

    console.log(viewModel.propertyListData);

    const theme = useTheme();
    const handleRowClick = (propertyId?: string) => {
        if (!propertyId) return;
        const { projectId, clientId } = router.match.params;
        const { path } = router.match;

        // Work out the url from the current route going from Project to Property
        let url: string = AppUrls.Client.Main.Project.Property.PropertyRoot;

        switch (path) {
            case AppUrls.Client.ClientView.Project.ProjectDetail: {
                url = AppUrls.Client.ClientView.Project.PropertyDetail;
                break;
            }
            case AppUrls.Client.Main.Client.Project.ClientsDetailProject: {
                url = AppUrls.Client.Main.Client.Project.PropertyDetail.replace(":clientId", clientId!);
                break;
            }
            case AppUrls.Client.ContractorView.Project.ProjectDetail: {
                url = AppUrls.Client.ContractorView.Project.PropertyDetail;
                break;
            }
            case AppUrls.Client.Main.Project.ProjectDetail:
            default: {
                break;
            }
        }
        router.history.push(url.replace(":projectId", isNullOrUndefined(projectId) ? ":projectId" : projectId!).replace(":propertyId", propertyId));
    };

    const formatEdit = (id: string): any => {
        return <ClickableImgCell logo={EditLogo} text={"Edit property"} onClick={editPropertyClick} id={id} />;
    };

    const editPropertyClick = (id: string) => {
        const editThis: Property | undefined = viewModel.getProperties.find((a) => a.id === id);

        if (editThis !== undefined) {
            setPropertyAddress(editThis.toAddressModel);
            setPropertyOpen(true);
        }
    };

    const handlePropertyClose = () => {
        setPropertyOpen(false);
    };

    const handleSaveProperty = async (addr: AddressModel) => {
        if (addr.locationLatitude === 0 && addr.locationLongitude === 0) {
            // Then search for the lat and long
            osmViewModel.getLocationForPostcode(addr.postcode).then(async (result: OSMLocation) => {
                addr.setValue("locationLatitude", result.latitude);
                addr.setValue("locationLongitude", result.longitude);

                const ret: boolean = await viewModel.upsertProperty(addr);

                if (ret === true) {
                    setPropertyOpen(false);
                }
            });
        } else {
            const retVal: boolean = await viewModel.upsertProperty(addr);

            if (retVal === true) {
                setPropertyOpen(false);
            }
        }
    };

    return useObserver(() => (
        <PropertyListContainer>
            <PropertyAddModal address={propertyAddress} onClose={handlePropertyClose} open={propertyOpen} onAdd={handleSaveProperty} onChange={setPropertyAddress} />
            <MaterialTable
                columns={[
                    { title: "Property", field: "address", width: "auto" },
                    {
                        title: "Status",
                        field: "status",
                        width: "auto",
                        sorting: true,
                        filtering: true,
                        render: (rowData: any) => {
                            if (rowData !== null && rowData !== undefined && rowData.status !== undefined) {
                                return (
                                    <div className="status-readings" style={{ display: "flex", justifyContent: "center", width: "75px" }}>
                                        <UnitStatusBox className={"device-status "} backgroundColor={rowData.status.statusColour} textColour={rowData.status.statusTextColour}>
                                            <div className="status-name">{rowData.status.name}</div>
                                        </UnitStatusBox>
                                    </div>
                                );
                            } else {
                                return <div></div>;
                            }
                        },
                    },
                    {
                        title: "",
                        field: "id",
                        sorting: false,
                        filtering: false,
                        width: 100,
                        render: (rowData: any) => {
                            if (rowData !== null && rowData !== undefined) {
                                return formatEdit(rowData.id);
                            }
                        },
                    },
                ]}
                data={viewModel.propertyListData}
                title={`Search ${viewModel.properties.length} ${viewModel.properties.length === 1 ? "property" : "properties"}`}
                localization={{ toolbar: { searchPlaceholder: "Start typing to search" } }}
                style={{
                    backgroundColor: "#525355",
                    padding: 0,
                }}
                options={{
                    searchFieldAlignment: "left",
                    searchFieldStyle: {
                        borderRadius: "5px",
                        width: "400px",
                    },
                    pageSize: 12,
                    pageSizeOptions: [],
                    headerStyle: {
                        background: " #495D89 0% 0% no-repeat padding-box",
                        boxShadow: " inset 0px 1px 1px #FFFFFF40",
                        color: "#fff",
                        fontFamily: ["Mont-Bold", "Montserrat", "Roboto Condensed", "Helvetica", "Arial", "sans-serif"].join(","),
                    },
                    rowStyle: {
                        backgroundColor: "#525355",
                        color: "#fff",
                        fontSize: 24,
                    },
                }}
                icons={{
                    ResetSearch: (() => <ResetSearchIcon />) as any,
                    Search: (() => null) as any,
                }}
                components={{ Container: (props) => <Paper {...props} elevation={0} /> }}
                onRowClick={(_, rowData) => handleRowClick(rowData?.id)}
            />
        </PropertyListContainer>
    ));
};
