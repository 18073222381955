/* eslint-disable prettier/prettier */
import React from "react";
import { Box } from "@material-ui/core";
import { ClickableCell, CellImgText } from "./ClickableImgCell.style";
import { isNullOrUndefined } from "Custom/Utils/utils";
import { isEmptyOrWhitespace } from "Core/Utils/Utils";

export interface IClickableImgCellProps {
    id: string;
    context?: string;
    logo: any;
    text: string;
    className?: string;
    disabled?: boolean;
    onClick?: (id: string) => any;
    onClickExt?: (id: string, context: string) => any;
}

export const ClickableImgCell: React.FC<IClickableImgCellProps> = (props: IClickableImgCellProps) => {
    const getIsDisabled = (): boolean => {
        return !isNullOrUndefined(props.disabled) ? props.disabled! : false;
    };

    const getClassNames = (): string => {
        const className = !isEmptyOrWhitespace(props.className) ? props.className! : "";
        const disabledClassName = getIsDisabled() ? "clickablecell-disabled" : "";

        return [className, disabledClassName].filter((cn) => !isEmptyOrWhitespace(cn)).join(" ");
    };

    const handleClick = (e: React.MouseEvent) => {
        e.stopPropagation();

        if (!getIsDisabled()) {
            if (props.onClick !== undefined) {
                props.onClick(props.id);
            }
            if (props.onClickExt !== undefined && props.context !== undefined) {
                props.onClickExt(props.id, props.context!);
            }
        }
    };

    return (
        <ClickableCell className={getClassNames()} onClick={handleClick}>
            <img src={props.logo} />
            <Box>
                <CellImgText className="cellImageText">{props.text}</CellImgText>
            </Box>
        </ClickableCell>
    );
};
