import { action, observable } from "mobx";

export class CollapsiblePanelViewModel {
    // #region Constructors and Disposers

    public onStateChange: ((state: boolean) => void) | undefined = undefined;

    constructor(id: string, title: string, isCollapsed: boolean, items: CollapsiblePanelViewModel[], onStateChange: ((state: boolean) => void) | undefined = undefined) {
        this.id = id;
        this.title = title;
        this.isCollapsed = isCollapsed;
        this.items.replace(items);
        this.onStateChange = onStateChange;
    }

    // #endregion Constructors and Disposers

    // #region Properties

    @observable
    public id: string = "";

    @observable
    public isCollapsed: boolean = true;

    @observable
    public title: string = "";

    @observable
    public items = observable<CollapsiblePanelViewModel>([]);

    // #endregion Properties

    // #region Actions

    @action
    public setCollapsed = (isCollapsed: boolean) => {
        this.isCollapsed = isCollapsed;
        if (this.onStateChange !== undefined) {
            this.onStateChange(isCollapsed);
        }
    };

    @action
    public toggleCollapsed = () => {
        this.isCollapsed = !this.isCollapsed;
        if (this.onStateChange !== undefined) {
            this.onStateChange(this.isCollapsed);
        }
    };

    @action
    public setTitle = (title: string) => {
        this.title = title;
    };

    @action
    public setItems = (items: CollapsiblePanelViewModel[]) => {
        this.items.replace(items);
    };

    // #endregion Actions
}
