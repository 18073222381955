import * as React from "react";
import ReactTooltip from "react-tooltip";
import EmailIcon from "Custom/Content/emal.svg";
import { UnitListTooltipContainer } from "./UnitListTooltipContainer";
import { ClipboardButton } from "Custom/Components";

interface IProps {
    id: number;
    identifier: string;

    companyName: string;
    contactName: string | undefined;
    contactEmail: string | undefined;
    contactPhone: string | undefined;
}

export const UnitListToolTip: React.FC<IProps> = ({ id, identifier, companyName, contactName, contactEmail, contactPhone }) => {
    return (
        <UnitListTooltipContainer>
            <ReactTooltip
                className="contractor-tooltip"
                id={identifier + "-" + id}
                place="top"
                effect="solid"
                clickable={true}
                backgroundColor="#1A79CC"
                aria-haspopup="true"
                globalEventOff="click"
            >
                <div className="tooltip-company">
                    <div className="section1">
                        <div className="data">
                            <div className="section1-companyname">{companyName}</div>
                            {contactName !== null && contactName !== undefined && contactName.length > 0 && <div className="section1-contact">{contactName}</div>}
                            {contactPhone !== null && contactPhone !== undefined && contactPhone.length > 0 && <div className="section1-phone">{contactPhone}</div>}
                        </div>
                    </div>
                    {contactEmail !== null && contactEmail !== undefined && contactEmail.length > 0 && (
                        <>
                            <div className="section2">
                                <img alt={"Email " + contactEmail} src={EmailIcon} />
                                <a style={{ textDecoration: "none", color: "white" }} href={"mailto:" + contactEmail}>
                                    EMAIL
                                </a>
                            </div>
                            <div className="section3">
                                <ClipboardButton
                                    textToDisplay="COPY EMAIL TO CLIPBOARD"
                                    type="Contractor Contact Email"
                                    text={contactEmail}
                                    backgroundControlColor="#1A79CC"
                                    textColor="white"
                                />
                            </div>
                        </>
                    )}
                </div>
            </ReactTooltip>
        </UnitListTooltipContainer>
    );
};
