import { useObserver } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import { InstallationHeader } from "./InstallationHeader";
import { InstallationCommand, SensorValueExtended, InstallationCommandHistoryDTO, InstallationCommandNoteDTO } from "Custom/Models/Domain/Installations";
import { InstallationViewModel } from "./InstallationViewModel";
import { TabData } from "Custom/Views/Installations/TabDeviceData";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import { TabOverview } from "Custom/Views/Installations/Overview/TabOverview";
import { TabAttributes } from "Custom/Views/Installations/TabAttributes";
import { TabAverage } from "Custom/Views/Installations/TabAverage";
import { TabConfiguration } from "Custom/Views/Installations/TabConfiguration";
import { useRouter } from "Core/Utils";
import { InstallationViewBox, NoteModalViewBox, InstallationModalDialogPaper } from "./InstallationView.style";
import { TabImages } from "Custom/Views/Installations/TabImages";
import { TabAdmin } from "Custom/Views/Installations/TabAdmin";
import { isNullOrUndefined } from "Custom/Utils/utils";
import { Client } from "Custom/Globals/AppUrls";
import { ITab, Tabs } from "Custom/Components/Tabs/Tabs";
import { AppUrls } from "Custom/Globals";
import { useHasRoutedTabs } from "Custom/Utils/useHasRoutedTabs";
import { Loader } from "Core/Components";
import { BackBox, BackToList, BackToListText } from "Custom/StylesAppSpecific/Controls/BackToList";
import { BaseModal } from "Custom/Components/Modal/BaseModal";
import { CancelBtn } from "Custom/StylesAppSpecific/Controls/CancelBtn";
import { SuccessBtn } from "Custom/StylesAppSpecific/Controls/SuccessBtn";
import { TextareaAutosize } from "@material-ui/core";
import { ApiResult } from "Core/Models";
import * as RouterUtil from "Custom/Utils/routing";
import { Stores, StoresContext } from "Custom/Stores";
import { InstallationDataViewModel } from "./InstallationDataViewModel";
import { MainBackToListWrapper } from "../Admin/Condition/Condition.style";
import { DeleteModal } from "Custom/Components/Modal/DeleteModal";
import { Action } from "history";
import { Prompt } from "react-router";
import { TabConfigurationViewModel } from "./TabConfigurationViewModel";
import { TabMetrics } from "./Metrics/TabMetrics";
import { TabAnalytics } from "./Analytics/TabAnalytics";
import { TabMetricsViewModel } from "./Metrics/TabMetricsViewModel";
import { InstallationDataTableViewModel } from "./InstallationDataTableViewModel";
import { TabAnalyticsViewModel } from "./Analytics/TabAnalyticsViewModel";
import { TabAudit } from "./Audit/TabAudit";
import { InstallationAuditViewModel } from "./Audit/InstallationAuditViewModel";
import { InstallationImageViewModel } from "./InstallationImageViewModel";
import { UnitNoteDTO } from "../Units/UnitNote";
import { InstallationImageViewModel2 } from "Custom/Components/Panels/InstallationImageViewModel";
import { InstallationImagePanelDialog } from "Custom/Components/Panels/InstallationImagePanelDialog";
import { CollapsibleInstallationImagePanelViewModel } from "Custom/Components/Panels/CollapsibleInstallationImagePanelViewModel";
import { TabInstallation } from "./TabInstallation/TabInstallation";
interface GutterSensState {
    showImageModal: boolean;
    editableDeviceNoteModal: boolean;
    editableCommandNoteModal: boolean;
    flippedInDb: boolean;
    imageToView: number | undefined;
    noteId: number;
    noteText: string;
    noteError: string;
    reCheckFlips: boolean;
}

export const TAB_OVERVIEW = "overview";
export const TAB_ATTRIBUTES = "attributes";
export const TAB_AVERAGE = "averageHeight";
export const TAB_METRICS = "metrics";
export const TAB_ANALYTICS = "analytics";
export const TAB_DEVICEDATA = "deviceData";

export const TAB_INSTALLATIONASSISTANT = "installationassistant";
export const TAB_IMAGES = "images";
export const TAB_CONFIGURATION = "configuration";
export const TAB_ADMIN = "admin";
export const TAB_AUDIT = "audit";

export const InstallationView: React.FunctionComponent = () => {
    const numberOfDays: number = 1;
    const [viewModel] = useState(() => new InstallationViewModel());
    const store: Stores = useContext<Stores>(StoresContext);
    const { history, match } = useRouter();
    const router = useRouter<{ projectId?: string; propertyId: string; installId: string; clientId?: string }>();

    const [returnText, setReturnText] = React.useState("Back");

    const [showMoveAwayModal, setShowMoveAwayModal] = React.useState(false);
    const [confirmNavigation, setConfirmNavigation] = React.useState(false);
    const [navigatingAway, setNavigatingAway] = React.useState(false);
    const [lastLocation, setLastLocation] = React.useState<Location | undefined>(undefined);

    const [imageModalViewModel, setImageModalViewModel] = React.useState<CollapsibleInstallationImagePanelViewModel>(
        new CollapsibleInstallationImagePanelViewModel("", "", false, viewModel.images),
    );

    useEffect(() => {
        if (confirmNavigation == true && lastLocation !== undefined) {
            setConfirmNavigation(false);
            setNavigatingAway(true);
            viewModel.resetIsEditing();
            let path: string = lastLocation.pathname;
            if (lastLocation.hash.length != 0) {
                path += lastLocation.hash;
            }

            history.push(path);
        }
    }, [lastLocation, confirmNavigation]);

    useEffect(() => {
        imageModalViewModel.setImages(viewModel.images);

        if (/* imageModalViewModel.activeInstallationViewModel === null && */ viewModel.images.length > 0) {
            imageModalViewModel.setActiveInstallationViewModel(imageModalViewModel.installationImageViewModels[0]);
        }
    }, [viewModel.images]);

    const getTabOptions: () => ITab[] = () => {
        let retVal: ITab[] = [
            { key: TAB_OVERVIEW, title: "Overview", hash: "overview" },
            { key: TAB_ATTRIBUTES, title: "Attributes", hash: "attributes" },
            { key: TAB_INSTALLATIONASSISTANT, title: "Installation", hash: "installationassistant" },
            { key: TAB_AVERAGE, title: "Average Height", hash: "averageHeight" },
            { key: TAB_DEVICEDATA, title: "Device Data", hash: "deviceData" },
            { key: TAB_IMAGES, title: "Images", hash: "images" },
            { key: TAB_METRICS, title: "Metrics", hash: TAB_METRICS },
            { key: TAB_ANALYTICS, title: "Analytics", hash: TAB_ANALYTICS },
            { key: TAB_CONFIGURATION, title: "Configuration", hash: "config" },
        ];

        if (RouterUtil.requireContractorAdminorAdminLogin(store)) {
            retVal.push({ key: TAB_ADMIN, title: "Admin", hash: "admin" });
            /* */
            retVal.push({ key: TAB_AUDIT, title: "Audit", hash: "audit" });
        }
        return retVal;
    };

    const routedTab = useHasRoutedTabs(getTabOptions());
    const [selectedTab, setSelectedTab] = React.useState(routedTab?.key || TAB_OVERVIEW);
    const [tabKey, setTabKey] = React.useState(0);

    const onConditionSetChange = () => {
        const { match } = router;
        const { installId } = match.params as any;
        viewModel.loadInstallation(installId, numberOfDays).then(() => {});
    };

    const handleTabClick = (tab: ITab) => {
        if (viewModel.getIsEditing === false) {
            setSelectedTab(tab.key);
            setTabKey(tabKey + 1);
        }
    };

    const getTabKey = () => {
        return `installationview-tab-${selectedTab}-${tabKey}`;
    };

    const changeTab = (hashKey: string): void => {
        setSelectedTab(hashKey);
    };

    const handleBack = () => {
        router.history.push(getReturnRoute());
    };

    const [dataCounter, setDataCounter] = React.useState(0);

    const [state, setState] = React.useState<GutterSensState>({
        showImageModal: false,
        editableDeviceNoteModal: false,
        editableCommandNoteModal: false,
        imageToView: undefined,
        flippedInDb: false,
        noteId: -1,
        noteText: "",
        noteError: "",
        reCheckFlips: false,
    });

    useEffect(() => {
        const { match } = router;
        const { installId } = match.params as any;
        viewModel.loadInstallation(installId, numberOfDays).then(() => {});

        // Base the return text on the path.
        if (
            match.path === AppUrls.Client.Main.Client.Property.PropertyInstallation ||
            match.path === AppUrls.Client.Main.Client.Project.PropertyInstallation ||
            match.path === AppUrls.Client.Main.Device.PropertyInstallation ||
            match.path === AppUrls.Client.Main.Project.Property.PropertyInstallation ||
            //match.path === AppUrls.Client.ContractorView.Project.PropertyInstallation
            match.path === AppUrls.Client.Main.EstateView.Property.PropertyInstallation
        ) {
            setReturnText("Back to property");
        } else if (match.path === AppUrls.Client.ClientView.Project.PropertyInstallation || match.path === AppUrls.Client.ContractorView.Property.PropertyInstallation) {
            setReturnText("Back to property");
        } else if (match.path === AppUrls.Client.Main.Unit.Installation) {
            setReturnText("Back to units");
        } else if (
            match.path === AppUrls.Client.Main.Device.Installation ||
            // match.path === AppUrls.Client.Main.EstateView.Device.Installation ||
            match.path === AppUrls.Client.ClientView.Device.Installation ||
            match.path === AppUrls.Client.ContractorView.Device.Installation
        ) {
            setReturnText("Back to installation");
        } else {
            setReturnText("Back");
        }

        return () => {
            // Clean up after yourself
            InstallationDataViewModel.Instance.reset();

            // close the collapsible panels
            TabConfigurationViewModel.Instance.clear();

            // ensure it reloads the next time we open it with new data
            TabMetricsViewModel.Instance.reset();
            TabAnalyticsViewModel.Instance.reset();

            // Device Data table reset
            InstallationDataTableViewModel.Instance.reset();

            //Images tab
            InstallationImageViewModel.Instance.reset();

            // Audit
            InstallationAuditViewModel.Instance.reset();

            if (imageModalViewModel.activeInstallationViewModel !== null && viewModel.images.length > 0) {
                imageModalViewModel.setActiveInstallationViewModel(null);
            }
        };
    }, []);

    useEffect(() => {
        if (router.match) {
            const { tabId } = router.match.params as any;
        }
    }, [router.match]);

    useEffect(() => {
        if (viewModel.images.length > 0 && state.imageToView !== undefined) {
            const found: InstallationImageViewModel2 | undefined = imageModalViewModel.getInstallationImageViewModels.find((a) => a.id === state.imageToView);

            if (found === undefined) {
                imageModalViewModel.setActiveInstallationViewModel(null);
            } else {
                imageModalViewModel.setActiveInstallationViewModel(found);
            }
        } else {
            imageModalViewModel.setActiveInstallationViewModel(null);
        }
    }, [state.imageToView]);

    useEffect(() => {
        // Needed for knowing when the image Modal is closed
        if (imageModalViewModel.canDisplayActiveInstallation === false) {
            closeModal();
        }
    }, [imageModalViewModel.canDisplayActiveInstallation]);

    useEffect(() => {
        // Needed for knowing when the image Modal is closed
    }, [state.reCheckFlips]);

    const showImageModal = (id: number): void => {
        setState({
            showImageModal: true,
            editableDeviceNoteModal: false,
            editableCommandNoteModal: false,
            imageToView: id,
            flippedInDb: false,
            noteId: 0,
            noteText: "",
            noteError: "",
            reCheckFlips: true,
        });
    };

    const showNoteModal = (id: number): void => {
        setState({
            showImageModal: false,
            editableDeviceNoteModal: true,
            editableCommandNoteModal: false,
            imageToView: undefined,
            flippedInDb: false,
            noteId: id,
            noteText: "",
            noteError: "",
            reCheckFlips: false,
        });
    };

    const showCommandNoteModal = (id: number): void => {
        setState({
            showImageModal: false,
            editableDeviceNoteModal: false,
            editableCommandNoteModal: true,
            imageToView: undefined,
            flippedInDb: false,
            noteId: id,
            noteText: "",
            noteError: "",
            reCheckFlips: false,
        });
    };

    const closeModal = (): void => {
        if (state.reCheckFlips === true) {
            // then we have opened the image modal, so need to check the flips
            viewModel.updateFlippedFromModal(imageModalViewModel.installationImageViewModels.slice(0));
        }
        // Now do the state stuff which should re-load the view.
        setState({
            showImageModal: false,
            editableDeviceNoteModal: false,
            editableCommandNoteModal: false,
            imageToView: undefined,
            flippedInDb: false,
            noteId: -1,
            noteText: "",
            noteError: "",
            reCheckFlips: false,
        });
    };

    const createNote = (): void => {
        if (state.noteText.length === 0) {
            setState({
                ...state,
                noteError: "Note text is a required field",
            });
        } else {
            let promise = viewModel.upsertInstallationNote(state.noteText);

            promise.then((result: ApiResult<UnitNoteDTO[]>) => {
                if (result.wasSuccessful === false) {
                    setState({
                        ...state,
                        noteError: "Failed to save the note. Please try again later",
                    });
                } else {
                    // Success
                    closeModal();
                }
            });
        }
    };

    const createCommandNote = (): void => {
        if (state.noteText.length === 0) {
            setState({
                ...state,
                noteError: "Note text is a required field",
            });
        } else {
            let promise = viewModel.upsertCommandNote(state.noteText);

            promise.then((result: ApiResult<InstallationCommandNoteDTO[]>) => {
                if (result.wasSuccessful === false) {
                    setState({
                        ...state,
                        noteError: "Failed to save the note. Please try again later",
                    });
                } else {
                    // Success
                    closeModal();
                }
            });
        }
    };

    const updateNote = (newnote: string): void => {
        setState({
            ...state,
            noteText: newnote,
            noteError: "",
        });
    };

    const addCommand = (newCommand: InstallationCommand): void => {
        viewModel.addCommand(newCommand);
    };

    const updateCommandHistory = (newCommands: InstallationCommandHistoryDTO[], commands: InstallationCommand[]): void => {
        viewModel.updateCommandHistory(newCommands, commands);
    };

    const deviceId: number =
        isNullOrUndefined(viewModel) === false && isNullOrUndefined(viewModel.device) === false && isNullOrUndefined(viewModel.device!.id) === false ? viewModel.device!.id! : -1;

    const simId: string =
        isNullOrUndefined(viewModel) === false && isNullOrUndefined(viewModel.device) === false && isNullOrUndefined(viewModel.device!.simId) === false
            ? viewModel.device!.simId!
            : "";

    let canEditNotes: boolean = false;
    if (RouterUtil.requireAdminLogin(store) || RouterUtil.requireContractorLogin(store)) {
        canEditNotes = true;
    }

    let updateIsHiddenResult = (values: SensorValueExtended[]): any => {
        viewModel.updateIsHiddenResult(values);
        viewModel.setReloadAverageData(true);
    };

    let setReloadAverageData = (value: boolean): any => {
        viewModel.setReloadAverageData(value);
    };

    const onRequestImageCommand = (commandHistory: InstallationCommandHistoryDTO[], commands: InstallationCommand[]): void => {
        viewModel.updateCommandHistory(commandHistory, commands);
    };

    const renderContent = () => {
        switch (selectedTab) {
            case TAB_OVERVIEW:
                return (
                    <TabOverview
                        key={getTabKey()}
                        deviceImages={viewModel.images}
                        deviceStatusData={viewModel.statusData}
                        conditionSet={viewModel.getConditionSet}
                        readings={viewModel.graphSensorValues}
                        showImageModal={showImageModal}
                        updateImage={viewModel.updateImage}
                        alertAction={viewModel.getAlertActions}
                        device={viewModel.model.device}
                        keyPerformance={viewModel.model.keyPerformance}
                        recentAlerts={viewModel.model.trippedAlertActions}
                        coreAlerts={viewModel.model.alertActionAnalytics.items}
                        conditionHistory={viewModel.model.conditionSetMetrics}
                        conditionHistoryTotal={viewModel.model.totalMetricPeriodCount}
                        siphonicList={viewModel.model.siphonicList}
                        changeTab={changeTab}
                    />
                );
            case TAB_ATTRIBUTES:
                return <TabAttributes key={getTabKey()} parentViewModel={viewModel} showNoteModal={showNoteModal} canAddNotes={canEditNotes} />;

            case TAB_INSTALLATIONASSISTANT:
                return <TabInstallation key={getTabKey()} deviceId={viewModel.device !== null && viewModel.device !== undefined ? viewModel.device!.id! : -1} />;

            case TAB_AVERAGE:
                return (
                    <TabAverage
                        key={getTabKey()}
                        deviceId={viewModel.device !== null && viewModel.device !== undefined ? viewModel.device!.id! : -1}
                        deviceStatusData={viewModel.statusData}
                        conditionSet={viewModel.getConditionSet}
                        alertAction={viewModel.getAlertActions}
                        reloadData={viewModel.reloadAverageData}
                        setReloadAverageData={setReloadAverageData}
                        minDate={viewModel.getEarliestLegalDate}
                    />
                );
            case TAB_DEVICEDATA:
                return (
                    <TabData
                        key={getTabKey()}
                        counter={dataCounter}
                        deviceId={viewModel.device !== null && viewModel.device !== undefined ? viewModel.device!.id! : -1}
                        minDate={viewModel.getEarliestLegalDate}
                        deviceStatusData={viewModel.statusData}
                        conditionSet={viewModel.getConditionSet}
                        updateIsHiddenResult={updateIsHiddenResult}
                        alertAction={viewModel.getAlertActions}
                    />
                );
            case TAB_IMAGES:
                return (
                    <TabImages
                        key={getTabKey()}
                        deviceId={deviceId}
                        allowImageRequest={viewModel.model.allowRequestImage}
                        simId={simId}
                        onRequestImageCommand={onRequestImageCommand}
                        minDate={viewModel.getEarliestLegalDate}
                    />
                );
            case TAB_METRICS:
                return <TabMetrics key={getTabKey()} deviceId={deviceId} simId={simId} minDate={viewModel.getEarliestLegalDate} />;
            case TAB_ANALYTICS:
                return <TabAnalytics key={getTabKey()} deviceId={deviceId} simId={simId} minDate={viewModel.getEarliestLegalDate} />;

            case TAB_ADMIN:
                return (
                    <TabAdmin
                        key={getTabKey()}
                        addCommand={addCommand}
                        commandNotes={viewModel.commandNotes}
                        deviceCommands={viewModel.commands}
                        deviceCommandHistory={viewModel.commandHistory}
                        deviceId={deviceId}
                        deviceStatusData={viewModel.statusData}
                        sensorGains={viewModel.device?.lastGainValues!}
                        showCommandNoteModal={showCommandNoteModal}
                        simId={viewModel.device?.simId!}
                        updateCommandHistory={updateCommandHistory}
                    />
                );
            case TAB_CONFIGURATION: {
                return <TabConfiguration key={getTabKey()} parentViewModel={viewModel} onConditionSetChange={onConditionSetChange} />;
            }
            case TAB_AUDIT: {
                return <TabAudit key={getTabKey()} deviceId={deviceId} />;
            }
        }
    };

    const renderPage = () => {
        if (viewModel.IsLoading) return <Loader />;

        return (
            <>
                <DeleteModal
                    open={showMoveAwayModal}
                    onClose={handleMoveAwayClose}
                    onDelete={handleMoveAwayAction}
                    text="You have unsaved data. Are you sure you want to navigate away?"
                    title="Do you want to continue?"
                />
                <Prompt when={viewModel.getIsEditing === true} message={handleBlockedNavigation} />
                <InstallationViewBox>
                    <MainBackToListWrapper>
                        <BackToList onClick={handleBack} tabIndex={0}>
                            <BackBox>
                                <NavigateBeforeIcon fontSize="large" />
                            </BackBox>
                            <BackToListText>{returnText}</BackToListText>
                        </BackToList>
                    </MainBackToListWrapper>
                    <InstallationHeader device={viewModel.model.device} statusData={viewModel.model.statusData} />
                    <Tabs tabs={getTabOptions()} onTabClick={handleTabClick} selected={selectedTab} minTabWidth={134} tabPadding="11px 19px" />
                    <div className="content">{renderContent()}</div>
                </InstallationViewBox>
                {state.showImageModal === true && imageModalViewModel.activeInstallationViewModel !== null && (
                    <InstallationImagePanelDialog viewModel={imageModalViewModel.activeInstallationViewModel!} />
                )}
                {state.editableDeviceNoteModal === true && (
                    <BaseModal
                        title="Add new note here"
                        onClose={closeModal}
                        open={state.editableDeviceNoteModal}
                        PaperComponent={InstallationModalDialogPaper}
                        actions={
                            <>
                                <SuccessBtn onClick={createNote} autoFocus disabled={state.noteText.length === 0}>
                                    Add note
                                </SuccessBtn>
                                <CancelBtn onClick={closeModal}>Cancel</CancelBtn>
                            </>
                        }
                    >
                        <NoteModalViewBox>
                            <Box style={{ color: "#ffffff" }}>Add your note below</Box>
                            <TextareaAutosize value={state.noteText} onChange={(e) => updateNote(e.target.value)} className="note-textarea" />
                            {state.noteError.length !== 0 && <div className="error">{state.noteError}</div>}
                        </NoteModalViewBox>
                    </BaseModal>
                )}
                {state.editableCommandNoteModal === true && (
                    <BaseModal
                        title="Add command note"
                        onClose={closeModal}
                        open={state.editableCommandNoteModal}
                        PaperComponent={InstallationModalDialogPaper}
                        actions={
                            <>
                                <SuccessBtn onClick={createCommandNote} autoFocus disabled={state.noteText.length === 0}>
                                    Add note
                                </SuccessBtn>
                                <CancelBtn onClick={closeModal}>Cancel</CancelBtn>
                            </>
                        }
                    >
                        <NoteModalViewBox>
                            <Box>Add your note below</Box>
                            <TextareaAutosize value={state.noteText} onChange={(e) => updateNote(e.target.value)} className="note-textarea" />
                            {state.noteError.length !== 0 && <div className="error">{state.noteError}</div>}
                        </NoteModalViewBox>
                    </BaseModal>
                )}
            </>
        );
    };

    const getReturnRoute = (): string => {
        const { match } = router;
        const { path } = match;
        const { propertyId, projectId, clientId } = match.params;
        let url: string = Client.Root;

        // For each path into this page, you'll need to create a route back.
        switch (path) {
            case AppUrls.Client.Main.EstateView.Property.PropertyInstallation: {
                url = AppUrls.Client.Main.EstateView.Property.PropertyDetail.replace(":propertyId", propertyId).replace(":projectId", projectId!);
                break;
            }
            /*             case AppUrls.Client.Main.EstateView.Device.Installation: {
                url = AppUrls.Client.Main.EstateView.Device.DeviceRoot;
                break;
            } */
            case AppUrls.Client.Main.Device.PropertyInstallation: {
                url = AppUrls.Client.Main.Device.PropertyDetail.replace(":propertyId", propertyId);
                break;
            }
            case AppUrls.Client.Main.Device.Installation: {
                url = AppUrls.Client.Main.Device.Root;
                break;
            }
            case AppUrls.Client.ClientView.Device.Installation: {
                url = AppUrls.Client.ClientView.Device.DeviceRoot;
                break;
            }
            case AppUrls.Client.ContractorView.Device.Installation: {
                url = AppUrls.Client.ContractorView.Device.DeviceRoot;
                break;
            }
            /*             case AppUrls.Client.Main.Map.Installation: {
                url = AppUrls.Client.Main.Map.Root;
                break;
            } */
            case AppUrls.Client.Main.Client.Property.PropertyInstallation: {
                url = AppUrls.Client.Main.Client.Property.ClientsDetailProperty.replace(":clientId", clientId!)
                    .replace(":projectId", projectId!)
                    .replace(":propertyId", propertyId);
                break;
            }
            case AppUrls.Client.Main.Client.Project.PropertyInstallation: {
                url = AppUrls.Client.Main.Client.Project.PropertyDetail.replace(":clientId", clientId!).replace(":projectId", projectId!).replace(":propertyId", propertyId);
                break;
            }
            case AppUrls.Client.Main.Project.Property.PropertyInstallation: {
                url = AppUrls.Client.Main.Project.Property.PropertyRoot.replace(":projectId", projectId!).replace(":propertyId", propertyId);
                break;
            }
            case AppUrls.Client.ClientView.Property.PropertyInstallation: {
                url = AppUrls.Client.ClientView.Property.PropertyDetail.replace(":projectId", projectId!).replace(":propertyId", propertyId);
                break;
            }
            case AppUrls.Client.ClientView.Project.PropertyInstallation: {
                url = AppUrls.Client.ClientView.Project.PropertyDetail.replace(":projectId", projectId!).replace(":propertyId", propertyId);
                break;
            }
            case AppUrls.Client.ContractorView.Property.PropertyInstallation: {
                url = AppUrls.Client.ContractorView.Property.PropertyDetail.replace(":projectId", projectId!).replace(":propertyId", propertyId);
                break;
            }
            case AppUrls.Client.ContractorView.Project.PropertyInstallation: {
                url = AppUrls.Client.ContractorView.Project.PropertyDetail.replace(":projectId", projectId!).replace(":propertyId", propertyId);
                break;
            }

            case AppUrls.Client.Main.Unit.Installation: {
                url = AppUrls.Client.Main.Unit.Root;
                break;
            }

            default: {
                break;
            }
        }
        return url;
    };

    const handleMoveAwayClose = (): void => {
        setShowMoveAwayModal(false);
    };

    const handleMoveAwayAction = (): void => {
        handleMoveAwayClose();
        setConfirmNavigation(true);

        setTimeout(() => {
            setNavigatingAway(false);
            if (lastLocation !== undefined && lastLocation.hash.length != 0) {
                setSelectedTab(lastLocation.hash.replace("#", ""));
                setTabKey(tabKey + 1);
            }
            setShowMoveAwayModal(false);
        }, 100);
    };

    const handleBlockedNavigation = (nextLocation: any /*should be Location*/, action: Action): string | boolean => {
        if (navigatingAway === false) {
            setShowMoveAwayModal(true);
            setLastLocation(nextLocation);
            return false;
        }
        return true;
    };

    return useObserver(() => <>{renderPage()}</>);
};
